import React, { createContext, useEffect, useReducer } from "react";
import { initLocations, initParams, initResponse } from "./constants";
import { reducer } from "./reducer";
import { ActionPayload, ActionType, State } from "./types";

const initState: State = {
  params: initParams,
  locations: initLocations,
  response: initResponse,
  displayMode: "list",
};

export const RealEstatesContext = createContext<{
  state: State;
  dispatch: React.Dispatch<{
    type: ActionType;
    payload: ActionPayload;
  }>;
}>({
  state: initState,
  dispatch: () => {},
});

export const RealEstatesContainer: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  return (
    <RealEstatesContext.Provider value={{ state, dispatch }}>
      {children}
    </RealEstatesContext.Provider>
  );
};
