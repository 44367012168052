import { Title } from "UI/shared/atoms/Title";
import React from "react";
import { ProfileAddressDto } from "routes/app/contacts/contact/components/models/profileAddressDto";
import { useServices } from "services";
import { useFrGouvGeocodingService } from "services/dataSources/geolocServices/useFrGouvGeocodingService";

import { MapModeType, ProfileMap } from "../../molecules/ProfileMap";

export interface ProfileLocalisationProps {
  address: ProfileAddressDto;
  mapHeight?: number;
}
export const ProfileLocalisation: React.FC<ProfileLocalisationProps> = ({
  address,
  mapHeight,
}) => {
  const {
    l10n: { t },
  } = useServices();

  const { position } = useFrGouvGeocodingService({
    addressString: `${address.street} ${address.city?.postalCodes[0]} ${address.city?.name}`,
  });

  const localisation = `${address.street || ""} ${
    address.city?.postalCodes[0]
  } ${address.city?.name}`;
  const mode = "point" as MapModeType;

  const profileMapProps = {
    ...(mapHeight && { mapHeight }),
    localisation,
    mode,
    position,
  };

  return (
    <>
      <Title title={t("LIEU(X)")} />
      <ProfileMap {...profileMapProps} />
    </>
  );
};
