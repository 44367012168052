import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { GridContainer } from "components/containers/GridContainer";
import ValidationTextField from "components/ValidationTextField";
import React, { FC, useMemo } from "react";
import { ListControl } from "routes/app/contacts/contact/components/Profiles/components/ListControls";
import { useServices } from "services";
import { EmailKindValues } from "services/portfolio/types";

import { useContactEmails } from "../hooks/useContactEmails";
import useStyles from "../styles";
import { ExistingContactMeanError } from "./ExistingContactMeanError";

export const ContactEmails: FC = () => {
  const {
    l10n: { t },
  } = useServices();

  const classes = useStyles();

  const {
    isDisabled,
    emails,
    errors,
    validateEmailAddressExistence,
    onEmailsChange: updateEmails,
    onEmailChange: handleEmailChange,
  } = useContactEmails();

  const options = useMemo(
    () =>
      EmailKindValues.map((o) => ({ kind: o, text: t(`EmailKind.${o}`) }))
        .sort(({ text: text1 }, { text: text2 }) => text1.localeCompare(text2))
        .map(({ kind, text }) => (
          <MenuItem value={kind} key={kind}>
            <Typography variant="body2" className={classes.emailTypeLabel}>
              {text}
            </Typography>
          </MenuItem>
        )),
    [t]
  );

  const onEmailBlur = (index: number) => {
    return index === 0
      ? validateEmailAddressExistence(index)
      : () => {
          return;
        };
  };

  return (
    <>
      <ListControl
        disabled={isDisabled}
        maxChildrenCount={5}
        hasAtLeastOneChildren
        areButtonsCentered
        onAdd={() => {
          updateEmails([...emails, { address: "", kind: "Professional" }]);
        }}
        onDelete={(i) => {
          const next = [...emails];
          next.splice(i, 1);
          updateEmails(next);
        }}
      >
        {emails &&
          emails.map((item, index) => {
            const txtProps: any = {
              className: classes.textField,
              disabled: isDisabled && !errors["emailAddressExistences"][index],
              error:
                !!errors["emails"][index] ||
                !!errors["emailAddressExistences"][index],
              helperText: errors["emails"][index],
              label: index === 0 ? "Email" : "Email (autre)",
              name: "address",
              onBlur: onEmailBlur(index),
              onChange: handleEmailChange(index),
              value: item.address,
              variant: "outlined",
            };
            return (
              <GridContainer key={index}>
                <Grid item xs={8}>
                  {index === 0 ? (
                    <ValidationTextField {...txtProps} />
                  ) : (
                    <TextField {...txtProps} />
                  )}
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    label="Type"
                    value={item.kind}
                    disabled={isDisabled}
                    select
                    name={"kind"}
                    onChange={handleEmailChange(index)}
                    InputProps={
                      index === 0
                        ? {
                            classes: {
                              notchedOutline: classes.notchedOutlineRequired,
                            },
                          }
                        : {}
                    }
                  >
                    {options}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  {errors["emailAddressExistences"][index] && (
                    <ExistingContactMeanError
                      contactId={errors["emailAddressExistences"][index]}
                      contactMeanType={"email"}
                    />
                  )}
                </Grid>
              </GridContainer>
            );
          })}
      </ListControl>
    </>
  );
};
