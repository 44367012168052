import { Grid, useMediaQuery } from "@material-ui/core";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import InsertCommentOutlinedIcon from "@material-ui/icons/InsertCommentOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import MiniCarteInfo from "components/MiniCarteInfo";
import L from "leaflet";
import moment from "moment";
import React, { useContext, useMemo } from "react";
import ResumeBiens from "routes/app/dashboard/components/ResumeBiens";
import { useEvenements } from "services/office365/useEvenements";

import TitrePage from "../../../components/TitrePage";
import { useServices } from "../../../services";
import { useFeatureFlags } from "../../../services/featureFlags";
import { useAgentNotifications, UserContext } from "../../../services/user";
import Agenda from "./components/Agenda";
import Assistance from "./components/Assistance";
import EspaceTravail from "./components/EspaceTravail";
import ImageEntete from "./components/ImageEntete";
import { NouveauxConseillers } from "./components/NouveauxConseillers/NouveauxConseillers";
import Portefeuille from "./components/Portefeuille";

const distanceEnMetres = 50000;
const prochains7Jours = {
  dateDebutUtc: moment().utc().format("YYYY-MM-DDTHH:mm:ss"),
  dateFinUtc: moment().add(7, "day").utc().format("YYYY-MM-DDTHH:mm:ss"),
};

const getTXBounds = (agentCoordinates) => {
  const positionConseiller = {
    lat: agentCoordinates.split(" ")[0],
    lng: agentCoordinates.split(" ")[1],
  };

  const center = L.latLng(positionConseiller);
  const leafletBounds = center.toBounds(distanceEnMetres);
  return {
    nw: `${leafletBounds.getNorthWest().lat}, ${
      leafletBounds.getNorthWest().lng
    }`,
    se: `${leafletBounds.getSouthEast().lat}, ${
      leafletBounds.getSouthEast().lng
    }`,
  };
};

export const Dashboard = () => {
  const {
    l10n: { t },
    authenticationService,
  } = useServices();

  const { newNotificationsCount } = useAgentNotifications();
  const { Notifications, DR } = useFeatureFlags();
  const {
    requestForInformationsService: { activeRequestsCount },
  } = useContext(UserContext);

  const TXBounds = useMemo(
    () => getTXBounds(authenticationService.getUserData().Coordonnees),
    [authenticationService.getUserData().Coordonnees]
  );

  const paramsBiensEnVente = useMemo(
    () => ({
      ...TXBounds,
      page: 1,
      size: 10,
      sortAsc: false,
      sortColumn: "dateCreation",
    }),
    [JSON.stringify(TXBounds)]
  );

  const paramsBiensVendus = useMemo(
    () => ({
      ...TXBounds,
      page: 1,
      size: 10,
      sortAsc: false,
      sortColumn: "dateEffectiveSignature",
    }),
    [JSON.stringify(TXBounds)]
  );

  const mobile = useMediaQuery("(max-width: 961px)");

  const { status: statusEvenements, items: evenements } = useEvenements({
    params: prochains7Jours,
  });

  return (
    <>
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            backgroundImage: `url("/static/images/airballon.png")`,
            backgroundSize: "cover",
            display: "flex",
            height: "200px",
            justifyContent: "flex-end",
            left: 0,
            position: "absolute",
            top: 0,
            width: "100%",
            zIndex: "-1",
          }}
        >
          &nbsp;
        </div>
        <div
          style={{
            position: "absolute",
            right: 30,
            top: 100,
          }}
        >
          <Assistance />
        </div>
        <TitrePage
          categorie={t("")}
          page={t("Tableau de bord")}
          filePath={"/static/markdowns/aideTableauDeBord.md"}
          color={"white"}
        />
        <div style={{ marginTop: mobile ? "80px" : "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <MiniCarteInfo
                    Icon={CalendarTodayOutlinedIcon}
                    valeur={
                      statusEvenements === "rejected"
                        ? "N/A"
                        : evenements.length
                    }
                    labelPrimaire={"Evénements"}
                    labelSecondaire={"7 prochains jours"}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  {Notifications && (
                    <MiniCarteInfo
                      Icon={NotificationsNoneOutlinedIcon}
                      valeur={newNotificationsCount}
                      labelPrimaire={"Notifications"}
                      labelSecondaire={" "}
                    />
                  )}
                </Grid>
                <Grid item xs={6} md={3}>
                  {DR && (
                    <MiniCarteInfo
                      Icon={InsertCommentOutlinedIcon}
                      valeur={activeRequestsCount}
                      labelPrimaire={"Demandes de renseignements"}
                      labelSecondaire={" "}
                    />
                  )}
                </Grid>
                <Grid item xs={6} md={3}>
                  {/*    <MiniCarteInfo
                                    Icon={MailOutlinedIcon}
                                    valeur={23}
                                    labelPrimaire={'Mails non lus'}
                                    labelSecondaire={' '}
                                />*/}
                </Grid>
                <Grid item xs={6} md={3}>
                  {/*  <MiniCarteInfo
                                    Icon={QuestionAnswerOutlinedIcon}
                                    valeur={3}
                                    labelPrimaire={'Rapprochements'}
                                    labelSecondaire={' '}
                                />*/}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              &nbsp;
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Agenda />
                </Grid>
                <Grid item xs={12}>
                  <ResumeBiens
                    mode={"dashboard"}
                    titreGeneral={"MON SECTEUR"}
                    titreBiensEnVente={
                      "Les 10 biens en vente ajoutés récemment sur mon secteur"
                    }
                    paramsBiensEnVente={paramsBiensEnVente}
                    titreBiensVendus={
                      "Les 10 biens vendus récemment sur mon secteur"
                    }
                    paramsBiensVendus={paramsBiensVendus}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={3} direction={"column"}>
                <Grid item xs={12}>
                  <div
                    style={{
                      alignItems: "flex-start",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ImageEntete
                      bu={authenticationService.getUserData().Instance}
                      mobile={mobile}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <EspaceTravail />
                </Grid>
                <Grid item xs={12}>
                  <Portefeuille />
                </Grid>
                <Grid item xs={12}>
                  <NouveauxConseillers mapBounds={TXBounds} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
