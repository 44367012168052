import { Theme, useMediaQuery } from "@material-ui/core";
import React, { ChangeEventHandler, useEffect, useState } from "react";
import { NoNetworkRealEstates } from "UI/features/profiles/molecules/NoNetworkRealEstates";
import { LoaderContainer } from "UI/shared/atoms/containers/LoaderContainer";
import { If } from "UI/shared/atoms/directives/If";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import { Text12px } from "UI/shared/atoms/typographies/Text12px";
import { Text14pxBold } from "UI/shared/atoms/typographies/Text14pxBold";
import { LabelledToniqueCheckbox } from "UI/shared/molecules/LabelledToniqueCheckbox";
import { realEstateAdapter } from "../adapter";
import { defaultSharingRealEstatesContext } from "../defaultValues";
import { SharingRealEstatesContext, SharingRealEstatesProps } from "../types";
import { SharingNetworkRealEstatesLarge } from "./index.large";
import { SharingNetworkRealEstatesSmall } from "./index.small";

export const SharingNetworkRealEstatesContext = React.createContext<
  SharingRealEstatesContext
>(defaultSharingRealEstatesContext);

export const SharingNetworkRealEstates: React.FC<SharingRealEstatesProps> = ({
  total,
  loadMore,
  loading,
  realEstates,
  open,
  isChecked,
  isShared,
  handleCheck,
  handleMultipleCheck,
  modalContainerId,
}) => {
  const { desktop, mobile } = {
    desktop: useMediaQuery((theme: Theme) => theme.breakpoints.up("md")),
    mobile: useMediaQuery((theme: Theme) => theme.breakpoints.down("md")),
  };

  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    setAllSelected(realEstates.every((r) => isChecked(r.id || "")));
  }, [realEstates]);

  const adaptedRealEstates = realEstates.map((realEstate) =>
    realEstateAdapter({
      realEstate,
      withAgent: true,
    })
  );

  const handleSelectAll: ChangeEventHandler<HTMLInputElement> = (event) => {
    handleMultipleCheck(
      adaptedRealEstates.map((r) => r.id || ""),
      event.target.checked
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <Text14pxBold>LES BIENS DU RÉSEAU</Text14pxBold>
      <VerticalSpace size={8} />
      <LabelledToniqueCheckbox
        checked={allSelected}
        onChange={handleSelectAll}
        name="select-all-network-realestates"
        label="Tout sélectionner"
      />
      <VerticalSpace size={8} />
      <LoaderContainer loading={loading}>
        <Text12px>{total} résultat(s)</Text12px>
        <VerticalSpace size={5} />
        <If condition={realEstates.length === 0}>
          <NoNetworkRealEstates />
        </If>
        <If condition={realEstates.length > 0}>
          <SharingNetworkRealEstatesContext.Provider
            value={{
              loadMore,
              realEstates: adaptedRealEstates,
              openDetails: open,
              handleCheck,
              isChecked,
              isShared,
              modalContainerId,
            }}
          >
            <If condition={mobile}>
              <SharingNetworkRealEstatesSmall />
            </If>
            <If condition={desktop}>
              <SharingNetworkRealEstatesLarge />
            </If>
          </SharingNetworkRealEstatesContext.Provider>
        </If>
      </LoaderContainer>
    </div>
  );
};
