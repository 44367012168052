import React from "react";

import { Refinement } from "../../../../../components/RefinementField/types";
import { AutoCompleteTextChip } from "../AutocompleteTextChip";
import { CheckboxChip } from "../CheckboxChip";
import { DateRangeChip } from "../DateRangeChip";
import { LocationsChip } from "../LocationsChip";
import { MultipleTextChip } from "../MultipleTextChip";
import { NumberRangeChip } from "../NumberRangeChip";
import { PhoneNumberChip } from "../PhoneNumberChip";
import { PriceChip } from "../PriceChip";
import { SingleTextChip } from "../SingleTextChip";

export interface RefinementChipProps<T extends Refinement> {
  value: T;
  onChange: (value: Refinement) => void;
  format: (value: any) => string;
}

export const RefinementChip = ({
  value,
  onChange,
  format,
}: RefinementChipProps<Refinement>): JSX.Element => {
  switch (value.kind) {
    case "single-text":
      return (
        <SingleTextChip value={value} format={format} onChange={onChange} />
      );
    case "multiple-text":
      return (
        <MultipleTextChip value={value} format={format} onChange={onChange} />
      );
    case "checkbox":
      return <CheckboxChip value={value} format={format} onChange={onChange} />;
    case "price":
      return <PriceChip value={value} format={format} onChange={onChange} />;
    case "number-range":
      return (
        <NumberRangeChip value={value} format={format} onChange={onChange} />
      );
    case "date-range":
      return (
        <DateRangeChip value={value} format={format} onChange={onChange} />
      );
    case "locations":
      return (
        <LocationsChip value={value} format={format} onChange={onChange} />
      );
    case "autocomplete-text":
      return (
        <AutoCompleteTextChip
          value={value}
          format={format}
          onChange={onChange}
        />
      );
    case "phone-number":
      return (
        <PhoneNumberChip value={value} format={format} onChange={onChange} />
      );
    default:
      return <></>;
  }
};
