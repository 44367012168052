import { ParkingAmenityType } from "routes/app/contacts/contact/components/models/Proposal/Features/Parking/parkingAmenityType";
import { ParkingProposalKind } from "routes/app/contacts/contact/components/models/Proposal/Features/Parking/parkingInfoKind";
import { ForRentParkingFeaturesDto } from "./../../../../../../../../routes/app/contacts/contact/components/models/Proposal/ForRent/Parking/ForRentParkingFeaturesDto";
import { NONE } from "./../../../../../../../../services/profiles/constants/exportProfileStatus";

export type Sections = {
  "Le bien": {
    Hauteur: string;
    Largeur: string;
    Profondeur: string;
    Surface: string;
    Type: ParkingProposalKind;
  };
  Loué: {
    "Loyer mensuel": string;
  };
  Équipements: {
    Prestations: string;
  };
};

const defaultSections: Sections = {
  "Le bien": {
    Hauteur: NONE,
    Largeur: NONE,
    Profondeur: NONE,
    Surface: NONE,
    Type: "Unknown" as ParkingProposalKind,
  },
  Loué: {
    "Loyer mensuel": NONE,
  },
  Équipements: {
    Prestations: "",
  },
};

export class RentalParkingCharacteristicsSummary {
  sections: Sections = defaultSections;
  translate: (v: string) => string;

  constructor(translate: (v: string) => string) {
    this.translate = translate;
  }

  withHeight(height?: number): RentalParkingCharacteristicsSummary {
    if (height) this.sections["Le bien"].Hauteur = `${height} m`;
    return this;
  }
  withWidth(width?: number): RentalParkingCharacteristicsSummary {
    if (width) this.sections["Le bien"].Largeur = `${width} m`;
    return this;
  }
  withDepth(depth?: number): RentalParkingCharacteristicsSummary {
    if (depth) this.sections["Le bien"].Profondeur = `${depth} m`;
    return this;
  }
  withArea(area?: number): RentalParkingCharacteristicsSummary {
    if (area) this.sections["Le bien"].Surface = `${area} m²`;
    return this;
  }
  withType(type: ParkingProposalKind): RentalParkingCharacteristicsSummary {
    this.sections["Le bien"].Type = type;
    return this;
  }
  withRent(rent?: number): RentalParkingCharacteristicsSummary {
    if (rent) this.sections.Loué["Loyer mensuel"] = `${rent} euros`;
    return this;
  }
  withAmenities(
    amenities?: ParkingAmenityType[]
  ): RentalParkingCharacteristicsSummary {
    if (!amenities || amenities.length === 0) {
      this.sections.Équipements.Prestations = NONE;
      return this;
    }

    this.sections.Équipements.Prestations = amenities
      .map((a) => this.translate(`amenity.${a}`))
      .join(", ");

    return this;
  }

  withoutNC(): Sections {
    const sectionsKeys = Object.keys(this.sections) as (keyof Sections)[];

    return sectionsKeys.reduce((nextSections, sectionsKey) => {
      const section = this.sections[sectionsKey];
      const sectionInfos = Object.keys(
        section
      ) as (keyof Sections[typeof sectionsKey])[];

      const filteredSection = sectionInfos.reduce((nextSectionInfos, info) => {
        return {
          ...nextSectionInfos,
          ...(section[info] !== NONE && { [info]: section[info] }),
        };
      }, {});

      if (Object.keys(filteredSection).length === 0) return nextSections;

      return { ...nextSections, [sectionsKey]: filteredSection };
    }, {} as Sections);
  }

  static from(
    forRentParkingFeatures: ForRentParkingFeaturesDto,
    t: (v: string) => string
  ): RentalParkingCharacteristicsSummary {
    const {
      parkingPropertyInfo,
      parkingRentedInfo,
      parkingAmenities,
      parkingKind,
    } = forRentParkingFeatures;
    return new RentalParkingCharacteristicsSummary(t)
      .withHeight(parkingPropertyInfo.height)
      .withWidth(parkingPropertyInfo.width)
      .withDepth(parkingPropertyInfo.length)
      .withArea(parkingPropertyInfo.squareMeter)
      .withRent(parkingRentedInfo.rent)
      .withType(parkingKind)
      .withAmenities(parkingAmenities);
  }
}
