import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import withRoot from "layouts/withRoot";
import Typography from "@material-ui/core/Typography";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/fr";

const useStyles = makeStyles((theme) => ({
  dateLine: {
    alignItems: "center",
    display: "flex",
    flex: 0,
    justifyContent: "flex-end",
    paddingLeft: 10,
    paddingRight: 10,
  },
  noPaddingForDatePicker: {
    padding: 0,
  },
  searchField: {
    width: "80px",
  },
}));

moment.locale("fr");

const TremplindataDate = (props) => {
  const { update, data } = props;

  const classes = useStyles();

  const handleDateChange = (type, date) => {
    const _data = { ...data };
    _data[type] = date.toISOString();
    update(_data, props.type);
  };

  return (
    <MuiPickersUtilsProvider
      utils={MomentUtils}
      locale="fr"
      libInstance={moment}
    >
      <div className={classes.dateLine}>
        <Typography variant="h6" style={{ marginRight: "10px" }}>
          Du
        </Typography>
        <DatePicker
          variant="inline"
          autoOk
          inputProps={{
            style: { padding: "0px" },
          }}
          className={classes.searchField}
          value={data.limiteInf}
          onChange={(value) => handleDateChange("limiteInf", value)}
          format="DD/MM/YYYY"
        />
        <Typography variant="h6" style={{ margin: "0 10px 0 10px" }}>
          au
        </Typography>
        <DatePicker
          autoOk
          inputProps={{
            style: { padding: "0px" },
          }}
          variant="inline"
          className={classes.searchField}
          value={data.limiteSup}
          onChange={(value) => handleDateChange("limiteSup", value)}
          format="DD/MM/YYYY"
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default TremplindataDate;
