import { makeStyles } from "@material-ui/core";

import { ITheme } from "../../../../theme/ts/main";

export const useStyles = makeStyles((theme: ITheme) => ({
  accessTime: {
    fontSize: "18px",
  },
  container: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  infos: {
    backgroundColor: theme.palette.greyLight,
    borderRadius: "0px 10px 10px 10px",
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  infosIcones: {
    marginLeft: "-4px",
    minWidth: "24px",
  },
  leftColumn: {
    display: "flex",
    flex: 2,
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  orangeWarningNoteContainer: {
    paddingTop: "20px",
  },
  propertyForSaleContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  receptionDate: {
    alignItems: "center",
    display: "flex",
  },
  reference: {
    display: "flex",
    justifyContent: "space-evenly",
    paddingBottom: theme.spacing(1),
    width: "100%",
  },
  rightColumn: {
    display: "flex",
    flex: 2,
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  status: {
    alignItems: "center",
    display: "flex",
  },
  topicTitle: {
    borderBottom: "1px solid",
    borderBottomColor: "#e2e2e2",
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));
