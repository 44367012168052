import villeAdapter from "services/rapprochement/Rapprochement/VilleAdapter";

import {
  array,
  bool,
  number,
  string,
} from "../../../../../api/DTOs/Common/Types";

class RechercheGridOuListeBO {
  constructor() {
    this.id = string;
    this.bu = string;
    this.dateAjout = string;
    this.dateModification = string;
    this.nombrePhotos = number;
    this.photoPrincipale = string;
    this.surface = number;
    this.surfaceTerrain = number;
    this.nbPieces = number;
    this.nbChambres = number;
    this.prix = number;
    this.familleBienEnum = string;
    this.typeAchatEnum = string;
    this.typeMandatEnum = string;
    this.typeAffaireEnum = string;
    this.codeAffaire = string;
    this.conseillers = array;
    this.villeAdapter = villeAdapter();
    this.prestige = bool;
    this.neuf = bool;
    this.exclusivite = bool;
    this.typeBienNom = string;
    this.isCE = bool;
    this.anneeConstruction = string;
    this.avecAscenseur = bool;
    this.avecStationnement = bool;
    this.withPool = bool;
    this.withSingleStorey = bool;
  }
}

export default RechercheGridOuListeBO;
