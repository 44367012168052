import React from "react";
import { Polygon } from "react-leaflet";
import useHTTPService from "services/dataSources/communicationServices/useHTTPService";

import { useServices } from "../../../../../services";
import geoPolygonsAdapter from "./geoPolygonsAdapter";

const localisationColors = {
  departements: "#37C2CE",
  quartiers: "#283FB2",
  regions: "#F4C500",
  villes: "#FF9E25",
};

const arrayToParams = (array, paramName) => {
  const paramsString = array.reduce(
    (params, elt, index) => `${params}&${paramName}[${index}]=${elt}`,
    ""
  );
  return `${paramsString}`;
};

const convertGeoPolygons2LeafletPolygons = (geoPolygons) =>
  geoPolygons.map((geoPolygon) => (
    <Polygon
      key={geoPolygon.id}
      positions={geoPolygon.wkt}
      interactive={false}
      color={localisationColors[geoPolygon.type]}
    />
  ));

const useGeoPolygons = (localisations) => {
  const {
    regions = [],
    departements = [],
    villes = [],
    quartiers = [],
  } = localisations;

  const regionsParams =
    regions.length > 0 ? arrayToParams(regions, "regionIds") : "";
  const departementsParams =
    departements.length > 0
      ? arrayToParams(departements, "departementIds")
      : "";
  const villesParams =
    villes.length > 0 ? arrayToParams(villes, "villeIds") : "";
  const quartiersParams =
    quartiers.length > 0 ? arrayToParams(quartiers, "quartierIds") : "";

  const { endpoints } = useServices();
  const { status, adaptedData } = useHTTPService({
    adapter: geoPolygonsAdapter,
    endpoint: endpoints.geoloc.obtenirLocalisationsParIds(
      `${regionsParams}${departementsParams}${villesParams}${quartiersParams}`
    ),
  });

  if (status !== "resolved") return { geoPolygons: [] };

  return { geoPolygons: adaptedData };
};

export { convertGeoPolygons2LeafletPolygons };
export default useGeoPolygons;
