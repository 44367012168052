import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { NumberField } from "components/NumberField";
import React, { useEffect } from "react";
import { useServices } from "services";

import { AnnexDto, AnnexKind } from "../../models/annexDto";
import { ListControl } from "../components/ListControls";
import { SubSectionTitle } from "../components/SectionTitle";
import useStyles from "../components/styles";
import { ProfileInputProps } from "../InputProps";

function validate(value: AnnexDto[]) {
  return (
    value.length === 0 ||
    (value.length > 0 && value.filter((v) => v.squareMeter === 0).length === 0)
  );
}

export const SaleAnnexes: React.FC<ProfileInputProps<AnnexDto[]>> = ({
  value,
  onChange,
}) => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();

  const isValid = validate(value);

  useEffect(() => {
    onChange(value, isValid);
  }, [isValid]);

  const updateKind = (index: number, kind: AnnexKind) => {
    const squareMeter = value[index].squareMeter;
    const newItem = { kind, squareMeter } as AnnexDto;
    const newValue = [
      ...value.slice(0, index),
      newItem,
      ...value.slice(index + 1),
    ];
    onChange(newValue, validate(newValue));
  };

  const updateSquareMeter = (index: number, squareMeter: number) => {
    const kind = value[index].kind;
    const newItem = { kind, squareMeter } as AnnexDto;
    const newValue = [
      ...value.slice(0, index),
      newItem,
      ...value.slice(index + 1),
    ];
    onChange(newValue, validate(newValue));
  };

  const options: AnnexKind[] = ["Balcony", "Cave", "Garden", "Terrace"];
  return (
    <>
      <SubSectionTitle title="Surfaces annexes" />
      <ListControl
        maxChildrenCount={10}
        onAdd={(i) => {
          const newValue = [...value];
          newValue.push({ kind: "Balcony", squareMeter: 0 });
          onChange(newValue, isValid);
        }}
        onDelete={(i) => {
          const newValue = [...value];
          newValue.splice(i, 1);
          onChange(newValue, isValid);
        }}
      >
        {value &&
          value.map((item, index) => {
            return (
              <div key={index}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      label="Type"
                      value={value[index].kind}
                      select
                      name={"type"}
                      error={!value[index].kind}
                      helperText={!value[index].kind ? "Selection requise" : ""}
                      onChange={(kind) =>
                        updateKind(index, kind.target.value as AnnexKind)
                      }
                    >
                      {options.map((_) => (
                        <MenuItem value={_} key={_}>
                          <Typography variant="body2">
                            {t(`annex.${_}`)}
                          </Typography>
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <NumberField
                      value={item.squareMeter}
                      helperText={
                        "La valeur numérique doit être supérieure à 0"
                      }
                      label="Surface"
                      adornement={"m²"}
                      onChange={(squareMeter) =>
                        updateSquareMeter(index, squareMeter || 0)
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            );
          })}
      </ListControl>
    </>
  );
};
