import { MicroServicesConfiguration } from "services/configuration/configuration";

import { EndpointsProvider } from "./interfaces/EndpointsProvider";

export interface BiensVendusEndpoints {
  rechercheConseiller: string;
  rechercheParIdCarte: string;
  rechercheGlobale: string;
  rechercheGridOuListe: string;
  rechercheCarte: string;
  rechercheParId: string;
}

const endpoints: EndpointsProvider<BiensVendusEndpoints> = (
  microServices: MicroServicesConfiguration
) => ({
  rechercheCarte: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/BiensVendus/RechercheCarte`,
  rechercheConseiller: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/BiensVendus/RechercheConseiller`,
  rechercheGlobale: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/BiensVendus/RechercheGlobale`,
  rechercheGridOuListe: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/BiensVendus/RechercheGridOuListe`,
  rechercheParId: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/BiensVendus/ObtenirParId`,
  rechercheParIdCarte: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/BiensVendus/ObtenirParIdCarte`,
});

export default endpoints;
