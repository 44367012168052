import { Card, Grid, IconButton, Menu, Typography } from "@material-ui/core";
import PhotoIcon from "@material-ui/icons/LocalSeeOutlined";
import MoreIcon from "@material-ui/icons/MoreVertOutlined";
import { StyledMenuItem } from "components/ActionsMenu/StyledMenuItem";
import TypeAnnonceur from "components/TypeAnnonceur";
import { default as React, MouseEventHandler } from "react";
import { useServices } from "services";
import { AnnonceInList } from "types";

import { showBloctel } from "../utils";
import { Caracteristiques } from "./AdCharacteristics";
import { AdConversion } from "./AdConversion";
import { Prix } from "./AdPrice";
import { AdVisibility } from "./AdVisibility";
import BloctelIcon from "./BloctelIcon";
import { Contact } from "./ContactCard";
import { EventsAd } from "./EventsAd";
import { HasEventsBookmark } from "./HasEventsBookmark/HasEventsBookmark";
import { OpenAd } from "./OpenAd";
import { PigeFavorite } from "./PigeFavorite";
import { useStyles } from "./styles";

export interface HitListeProps {
  hit: AnnonceInList;
  onClick: () => void;
  hasEvents: boolean;
  mobile: boolean;
}

export const HitListe: React.FC<HitListeProps> = ({
  hit,
  onClick,
  hasEvents,
  mobile,
}) => {
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles({
    pictureUrl: hit.photoPrincipaleUrl,
  });
  const mainPhoto =
    hit.estActive && hit.photoPrincipaleUrl !== undefined
      ? hit.photoPrincipaleUrl
      : "/static/images/no-image-box.png";

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickMore: MouseEventHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget as any);
  };

  const handleCloseMenu: MouseEventHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  const mainCardClass = {
    root: hit.estActive ? classes.cardPaper : classes.cardPaperDisabled,
  };

  return (
    <Card classes={mainCardClass} style={{ position: "relative" }}>
      <Grid container spacing={0} direction="row" alignItems="center">
        <Grid item xs={6} md={2}>
          <div
            onClick={onClick}
            style={{
              backgroundImage: `url(${mainPhoto}), url("/static/images/no-image-box.png")`,
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              cursor: "pointer",
              height: 120,
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                right: "-37px",
                top: 0,
                zIndex: 1,
              }}
            >
              <TypeAnnonceur
                type={hit.typeAnnonceur === "Pro" ? "PRO" : "PART"}
              />
            </div>
            <Typography className={classes.imageInformation}>
              <span className={classes.disabledHitText}>
                {!hit.estActive && t("ANNONCE DESACTIVEE")}
              </span>
              <span>{t("Voir la fiche détail")}</span>
            </Typography>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="center"
              style={{
                backgroundColor: "lightGrey",
                bottom: 0,
                position: "absolute",
                right: 0,
                width: 30,
              }}
            >
              <PhotoIcon style={{ color: "#000", fontSize: 12 }} />
              <Typography variant="body2" style={{ paddingLeft: 3 }}>
                {hit.nbPhotos}
              </Typography>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={6} md={6} style={{ paddingLeft: 10 }}>
          <Grid container alignItems={"center"}>
            <Grid item xs={6} md={8}>
              <Prix hit={hit} t={t} />
            </Grid>
            <Grid item xs={6} md={4}>
              <Caracteristiques hit={hit} />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={12} md={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                {showBloctel(hit.bloctel) && <BloctelIcon />}
              </Grid>
              <Grid item xs={6}>
                <Contact hit={hit} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item justify="flex-end" spacing={6} xs={12} md={2}>
          <PigeFavorite id={hit.id} mode={"button"} />
          <IconButton onClick={handleClickMore} aria-label="plus">
            <MoreIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {hit.source?.url && (
              <StyledMenuItem>
                <OpenAd url={hit.source.url} withText={!mobile} />
              </StyledMenuItem>
            )}
            <StyledMenuItem>
              <AdVisibility id={hit.id} withText={!mobile} />
            </StyledMenuItem>

            {hit.id && (
              <StyledMenuItem>
                <EventsAd id={hit.id} withText={!mobile} />
              </StyledMenuItem>
            )}
            {hit.id && (
              <StyledMenuItem>
                <AdConversion
                  id={hit.id}
                  withText={!mobile}
                  adKind={hit.typeAnnonce}
                  contactPhone={hit.telephoneContact}
                  contactEmail={hit.emailContact}
                />
              </StyledMenuItem>
            )}
          </Menu>
        </Grid>
        {hasEvents && <HasEventsBookmark offsetRight={100} />}
      </Grid>
    </Card>
  );
};
