import React from "react";
import capi from "assets/images/capi-actualites.png";
import oh from "assets/images/oh-actualites.png";
import reflex from "assets/images/reflex-actualites.png";

const ImageEntete = ({ bu, mobile }) => {
  let imageBU = capi;
  if (bu === "Refleximmo") imageBU = reflex;
  if (bu === "Optimhome") imageBU = oh;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: mobile ? "325px" : "400px",
        borderRadius: 25,
      }}
    >
      <img src={imageBU} alt={bu} width={"100%"} height={"auto"} />
    </div>
  );
};

export default ImageEntete;
