import { Grid } from "@material-ui/core";
import { If } from "UI/shared/atoms/directives/If";
import { SpacedLabelValue12Px } from "UI/shared/molecules/SpacedLabelValue";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import routesNames from "router/routesNames";
import { ReadSellerProfileMatchingNumber } from "routes/app/contacts/contact/components/Profiles/MatchingNumber/ReadSellerProfileMatchingNumber";
import { useTranslation } from "services/l10n/useTranslation";
import { MatchingProfileRequestDto } from "services/matching/MatchingProfileRequestDto";
import { ProfileItemDto } from "services/profiles/dto/ReadModelProfileDto";
import { CardPaperItem } from "UI/shared/atoms/containers/CardPaperItem";
import { BoldNumberUnitLabelNormalCaption } from "UI/shared/molecules/BoldNumberUnitLabelNormalCaption";
import { Maturity } from "routes/app/contacts/contact/components/Profiles/Maturity";
import { MatchingCriteria } from "routes/app/contacts/contact/components/Profiles/Search/MatchingCriteria";
import { ReadMatchingNumber } from "routes/app/contacts/contact/components/Profiles/Search/ReadMatchingNumber";
import { getMatchingCriteria } from "routes/app/profiles/adapters/criteriaAdapter";
import { getMatchingRequest } from "routes/app/profiles/adapters/matchingAdapter";
import {
  getBusinessType,
  getProfileType,
} from "routes/app/profiles/adapters/utils";
import { MonthIncome } from "UI/features/profiles/molecules/MonthIncome";
import { PriceCaption } from "UI/features/profiles/atoms/PriceCaption";
import { ProfileStatus } from "UI/features/profiles/molecules/ProfileStatus";
import { PropertyCategoryCaption } from "UI/features/profiles/molecules/PropertyCategoryCaption";
import { PropertyTypeCaption } from "UI/features/profiles/molecules/PropertyTypeCaption";
import { SurfaceCaption } from "UI/features/profiles/molecules/SurfaceCaption";
import { CitiesList } from "UI/features/profiles/organisms/CitiesList";
import { InfosChipsListProfile } from "UI/features/profiles/organisms/InfosChipsListProfile";
import { ProfileContact } from "UI/features/profiles/organisms/ProfileContact";
import { ProfileDetailsCaptionLarge } from "UI/features/profiles/organisms/ProfileDetailsCaption/index.large";
import { ProfileSummary } from "UI/features/profiles/organisms/ProfileSummary";

interface ProfileItemLargeCardProps {
  profileItem: ProfileItemDto;
  actionsMenu: React.ReactNode;
}

export const ProfileItemLargeCard: React.FC<ProfileItemLargeCardProps> = ({
  profileItem,
  actionsMenu,
}) => {
  const infosChipsList = (
    <InfosChipsListProfile
      businessType={getBusinessType(profileItem.kind)}
      propertyType={undefined}
      profileType={getProfileType(profileItem.kind)}
      propertyCategory={profileItem.propertyKind}
    />
  );

  const history = useHistory();
  const { t } = useTranslation();

  const [criteria, setCriteria] = useState<MatchingCriteria | undefined>(
    undefined
  );
  const [criteriaSale, setcriteriaSale] = useState<
    MatchingProfileRequestDto | undefined
  >(undefined);

  useEffect(() => {
    setCriteria(getMatchingCriteria(profileItem));
    setcriteriaSale(getMatchingRequest(profileItem));
  }, [JSON.stringify(profileItem)]);

  return (
    <div style={{ textDecoration: "none" }}>
      <CardPaperItem
        clickHandler={() =>
          history.push(
            routesNames.profilePageTab(
              profileItem.contactId,
              profileItem.id,
              "details"
            )
          )
        }
      >
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}>
            <div
              style={{
                left: 55,
                position: "relative",
                top: 5,
              }}
            >
              <SpacedLabelValue12Px
                label={"Référence"}
                spaceSize={5}
                value={profileItem.reference}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <ProfileContact
              gender={profileItem.contactGender}
              firstName={profileItem.contactFirstName}
              lastName={profileItem.contactLastName}
              mainPhoneNumber={profileItem.contactMainPhoneNumber}
              mainEmailAddress={profileItem.contactMainEmailAddress}
            />
          </Grid>
          <Grid item xs={1}>
            <Grid container justify="center">
              <Grid item>
                <Maturity value={profileItem.maturity} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <ProfileSummary
              profileDetailsCaption={
                <ProfileDetailsCaptionLarge
                  propertyCategoryCaption={
                    <PropertyCategoryCaption label={profileItem.propertyKind} />
                  }
                  propertyTypeCaption={
                    profileItem.propertyType !== "All" &&
                    profileItem.propertyType !== undefined && (
                      <PropertyTypeCaption
                        label={t(profileItem.propertyType)}
                      />
                    )
                  }
                  monthIncome={
                    profileItem.monthIncome ? (
                      <MonthIncome income={profileItem.monthIncome} />
                    ) : undefined
                  }
                  price={<PriceCaption price={profileItem.budgetMax} />}
                  surface={
                    profileItem.surfaceMin &&
                    profileItem.surfaceMax && (
                      <SurfaceCaption
                        surfaceMin={profileItem.surfaceMin ?? undefined}
                        surfaceMax={profileItem.surfaceMax ?? undefined}
                      />
                    )
                  }
                  bedroomsNumber={
                    profileItem.bedRooms ? (
                      <BoldNumberUnitLabelNormalCaption
                        total={profileItem.bedRooms}
                        unitLabel={
                          profileItem.profileType === "Search"
                            ? "+\xa0ch"
                            : "ch"
                        }
                      />
                    ) : undefined
                  }
                  roomsNumber={
                    profileItem.rooms ? (
                      <BoldNumberUnitLabelNormalCaption
                        total={profileItem.rooms}
                        unitLabel={
                          profileItem.profileType === "Search" ? "+\xa0p" : "p"
                        }
                      />
                    ) : undefined
                  }
                />
              }
              citiesList={
                <CitiesList
                  cities={profileItem.places.map((p) => ({
                    name: p.name,
                    zipcode: p.postalCode,
                  }))}
                />
              }
              infoChipsList={infosChipsList}
            />
          </Grid>
          <Grid item xs={1}>
            <ProfileStatus
              status={profileItem.archivingDate ? "Archived" : "Active"}
              date={
                new Date(
                  profileItem.archivingDate ?? profileItem.lastModificationDate
                )
              }
            />
          </Grid>
          <Grid item xs={2}>
            <If
              condition={
                profileItem.archivingDate == null &&
                getProfileType(profileItem.kind) === "Search"
              }
            >
              <ReadMatchingNumber
                profileId={profileItem.id}
                contactId={profileItem.contactId}
              />
            </If>
            <If
              condition={
                profileItem.archivingDate == null &&
                getProfileType(profileItem.kind) === "Seller"
              }
            >
              <ReadSellerProfileMatchingNumber
                criteria={criteriaSale}
                url={routesNames.profilePageTab(
                  profileItem.contactId,
                  profileItem.id,
                  "matching"
                )}
              />
            </If>
          </Grid>
          <Grid item xs={1}>
            {actionsMenu}
          </Grid>
        </Grid>
      </CardPaperItem>
    </div>
  );
};
