import { RequestForInformationDto } from "services/request-for-informations/dto/RequestForInformationDto";

export interface ITransformationState {
  selectedRequestForInformations?: RequestForInformationDto;
  contactId?: string;
  pending: boolean;

  hasError: boolean;

  isForANewContact: (state: ITransformationState) => boolean;
  isForAnExistingContact: (state: ITransformationState) => boolean;
}

export class TransformationState implements ITransformationState {
  selectedRequestForInformations?: RequestForInformationDto | undefined;
  contactId?: string;
  pending: boolean;
  hasError: boolean;
  constructor() {
    this.pending = false;
    this.hasError = false;
  }

  update(payload: Partial<TransformationState>) {
    const nextState = { ...this, ...payload };
    Object.setPrototypeOf(nextState, TransformationState.prototype);
    return nextState;
  }
  isForAnExistingContact() {
    return (
      this.contactId != null && this.selectedRequestForInformations != null
    );
  }
  isForANewContact() {
    return (
      this.contactId == null && this.selectedRequestForInformations != null
    );
  }
}
