import { Grid, Typography } from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/AssignmentOutlined";
import { useTheme } from "@material-ui/styles";
import SelectX from "components/SelectX";
import TitreBloc from "layoutContents/components/ContextualSideBar/components/titreBloc";
import React from "react";
import { ChipSelect } from "UI/shared/molecules/ChipSelect";

const Caracteristiques = ({
  bus,
  classes,
  familleBiens,
  onUpdateRefinements,
  t,
  typeAchat,
  typeAffaires,
  mode,
  constructionYear,
}) => {
  const theme = useTheme();

  const toggleYearConstruction = (array, current) => () => {
    const newArray = array.map((option) => {
      if (option.value === current.value) {
        return { ...option, isRefined: !option.isRefined };
      } else return option;
    });
    onUpdateRefinements("constructionYear", newArray);
  };

  const reseau =
    mode === "mesBiensEnVente" ? (
      <></>
    ) : (
      <Grid className={classes.gridFilter} item xs={12}>
        <SelectX
          className={classes.select}
          refinement={bus}
          label={t("Bu")}
          onChange={onUpdateRefinements}
          multiple
        />
      </Grid>
    );

  const constructionYearComponent = (
    <Grid className={classes.constructionYear} item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant={"h5"}>Année de construction </Typography>
        </Grid>
        {constructionYear.values.map((option) => (
          <Grid item xs={4} key={option.value}>
            <ChipSelect
              label={option.label}
              value={option.value}
              isChecked={option.isRefined}
              onClick={toggleYearConstruction(constructionYear.values, option)}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TitreBloc
          label={t("Caractéristiques")}
          icon={<AssignmentIcon style={{ color: theme.palette.tonique }} />}
        />
      </Grid>
      <Grid className={classes.gridFilter} item xs={12}>
        <SelectX
          className={classes.select}
          refinement={typeAffaires}
          label={t("Type affaire")}
          onChange={onUpdateRefinements}
          multiple
        />
      </Grid>
      <Grid className={classes.gridFilter} item xs={12}>
        <SelectX
          refinement={familleBiens}
          label={t("FamilleBiens")}
          onChange={onUpdateRefinements}
          multiple
        />
      </Grid>
      <Grid className={classes.gridFilter} item xs={12}>
        <SelectX
          className={classes.select}
          refinement={typeAchat}
          label={t("Type achat")}
          onChange={onUpdateRefinements}
          multiple
        />
      </Grid>
      {reseau}
      {constructionYearComponent}
    </Grid>
  );
};

export default Caracteristiques;
