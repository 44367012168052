import {
  AdaptedRefinements,
  AutoCompleteText,
} from "components/RefinementField/types";
import { initialState } from "services/profiles/useRefinements";

const defaultSearchValues = () => Promise.resolve<string[]>([]);

export const isAutoComplete = <T extends string>(
  adapptedRefinements: AdaptedRefinements<T>,
  criteria: keyof AdaptedRefinements<T>
) => adapptedRefinements[criteria].refinement.kind === "autocomplete-text";

export const restoreAutoComplete = <T extends string>(
  initial: AdaptedRefinements<T>,
  next: Partial<AdaptedRefinements<T>>,
  criteria: keyof AdaptedRefinements<T>
) => {
  const refinement = (next[criteria]?.refinement ||
    initial[criteria].refinement) as AutoCompleteText;

  refinement.getOptions = (initial[criteria]
    .refinement as AutoCompleteText).getOptions;

  return refinement;
};

export const resetAutoComplete = <T extends string>(
  adaptedRefinements: AdaptedRefinements<T>,
  nextAdaptedRefinements: Partial<AdaptedRefinements<T>>,
  criteria: keyof AdaptedRefinements<T>
) => {
  const emptyState = initialState(defaultSearchValues) as AdaptedRefinements<T>;
  const adaptedRefinement = adaptedRefinements[criteria];
  const refinement = adaptedRefinement.refinement as AutoCompleteText;
  const defaultAdaptedRefinement = emptyState[criteria];

  return {
    ...nextAdaptedRefinements,
    [criteria]: {
      ...emptyState[criteria],
      refinement: {
        ...defaultAdaptedRefinement.refinement,
        getOptions: refinement.getOptions,
      },
    },
  };
};
