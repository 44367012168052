import rechercheGridOuListeAdapter from "services/conseillers/dto/Conseillers/RechercheGridOuListe/rechercheGridOuListeAdapter";
import useHTTPService from "services/dataSources/communicationServices/useHTTPService";

import { useServices } from "../index";

export const useConseillersListe = ({ params, erreur = false }) => {
  const { endpoints } = useServices();
  const { status, adaptedData } = useHTTPService({
    adapter: rechercheGridOuListeAdapter,
    endpoint: endpoints.conseillers.rechercheGridOuListe,
    params,
  });
  return { items: adaptedData, status: status };
};
