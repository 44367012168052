import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";

import TitreSection from "../../../../../../layoutContents/Fiches/components/Details/components/TitreSection";

const Resume = ({ conseiller }) => {
  const dateEntreeFormatee = moment(conseiller.dateEntree).format("DD/MM/YYYY");
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TitreSection titre={"Résumé"} />
      </Grid>
      <Grid item xs={12}>
        <Rubrique titre={"Arrivé(e) le "} valeur={dateEntreeFormatee} />
        {conseiller.pack !== "" && (
          <Rubrique titre={"Pack actuel "} valeur={conseiller.pack} />
        )}
        <div style={{ height: "25px" }}>&nbsp;</div>
        <Rubrique titre={"Région "} valeur={conseiller.region} />
        <Rubrique titre={"Département"} valeur={conseiller.departement} />
      </Grid>
    </Grid>
  );
};

export default Resume;

const Rubrique = ({ titre, valeur }) => (
  <div style={{ alignItems: "baseline", display: "flex" }}>
    <Typography variant={"subtitle1"}>{titre}</Typography>
    <span style={{ flex: 1, overflow: "hidden" }}>
      ............................................................................................................................................................................
    </span>
    <Typography variant={"h4"}>{valeur}</Typography>
  </div>
);
