import { PropertyForSale } from "types";

import { MatchingProfileRequestDto } from "./MatchingProfileRequestDto";

const mapTypeAffaireToProfilKind = (typeAffaire: string) => {
  switch (typeAffaire.toLowerCase()) {
    case "vente":
      return "Purchase";
    case "location":
      return "Rent";
    case "viager":
      return "SearchLifeAnnuity";
    default:
      return undefined;
  }
};

const mapFamilleBienToFamilyKind = (familleBienEnum: string) => {
  switch (familleBienEnum.toLowerCase()) {
    case "appartement":
      return "Appartement";
    case "immeuble":
      return "Building";
    case "localbureau":
      return "Premises";
    case "maison":
      return "House";
    case "stationnement":
      return "Parking";
    case "fondscommerce":
      return "Goodwill";
    case "terrain":
      return "Land";
    default:
      return undefined;
  }
};

export const mapBienToMatchingProfileRequest = (bien: PropertyForSale) => {
  return new MatchingProfileRequestDto({
    budget: bien.prix,
    cityId: bien.localisation.villeId,
    constructionYear: bien.anneeConstruction,
    nbRooms: bien.nbPieces,
    profileKind: mapTypeAffaireToProfilKind(bien.typeAffaireEnum),
    propertyKind: mapFamilleBienToFamilyKind(bien.familleBienEnum),
    surface: bien.surface || bien.surfaceTerrain,
    withElevator: bien.avecAscenseur,
    withParking: bien.avecStationnement,
    withPool: bien.withPool,
  });
};
