import L from "leaflet";
import React from "react";
import { Marker } from "react-leaflet";
import { buColors, formatPriceAbove5k } from "utils/formatage";

import CircleTX from "../../../../components/MapX/Map/components/circleTX";

const MarkerBien = ({ item, zoom, handlePointClick }) => {
  if (zoom > 13) {
    const domElement = `<div style="width: 60px; margin-left: -25px; text-align: center; background-color: ${
      buColors[item.bu]
    }; border-radius: 10px; padding: 1px 5px 1px 5px; color: white;white-space: nowrap;">${formatPriceAbove5k(
      item.prix
    )}</div>`;
    const myIcon = L.divIcon({ className: "", html: domElement });
    return (
      <Marker
        key={`${item.id}-badge`}
        position={[item.lat, item.long]}
        icon={myIcon}
        onClick={(evt) => handlePointClick(item)}
      />
    );
  }
  return (
    <CircleTX
      item={item}
      radius={Math.ceil(
        (13 / zoom) * (zoom > 7 ? 100 : zoom > 4 ? 1000 : 10000)
      )}
    />
  );
};

export default MarkerBien;
