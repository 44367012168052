import { makeStyles } from "@material-ui/core";

import { ITheme } from "../../../theme/ts/main";

export const useStyles = makeStyles((theme: ITheme) => ({
  resetContainer: {
    alignItems: "center",
    background: "white",
    border: "1px solid black",
    borderRadius: "4px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
  toggleBoundsContainer: {
    height: 46,
    padding: "5px",
  },
}));
