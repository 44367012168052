import React from "react";

export interface ProfileSummaryProps {
  profileDetailsCaption: React.ReactNode;
  citiesList: React.ReactNode;
  infoChipsList: React.ReactNode;
}

export const ProfileSummary: React.FC<ProfileSummaryProps> = ({
  profileDetailsCaption,
  citiesList,
  infoChipsList,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {profileDetailsCaption}
      {citiesList}
      {infoChipsList}
    </div>
  );
};
