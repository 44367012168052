import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  buttonsIcon: {
    background: theme.palette.primary.main,
    marginRight: "7px",
  },
  icons: {
    color: "white",
    fontSize: "1.5em",
  },
}));
