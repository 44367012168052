import React from "react";
// import withRoot from "layouts/withRoot";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  "@media (max-width: 768px)": {
    checked: {
      fontSize: "2rem",
    },
    notChecked: {
      fontSize: "2rem",
    },
  },
  checked: {
    color: theme.palette.secondary.main,
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  notChecked: {
    color: theme.palette.primary.main,
    fontSize: "1.2rem",
  },
  refinementList: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  searchField: {
    width: "200px",
  },
}));

const CustomRefinementList = (props) => {
  const { attribute, setAttribute, title, type } = props;
  const classes = useStyles();

  const onClick = (event, item, label) => {
    event.preventDefault();
    const _attribute = { ...attribute };
    _attribute.values = _attribute.values.map((_item) => {
      if (_item.label === label) _item.isRefined = !_item.isRefined;
      return _item;
    });
    setAttribute(_attribute, type);
  };

  return (
    <div className={classes.refinementList}>
      <Typography>{title}</Typography>
      {attribute.values.map((item) => {
        const label =
          title === "Surface habitable"
            ? item.label
                .replace("[", "")
                .replace("]", " m2")
                .replace("@", " à ")
            : item.label;
        return (
          <div key={item.label}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.isRefined}
                  onClick={(e) => onClick(e, item, label)}
                />
              }
              label={`${item.label}`}
              key={item.label}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CustomRefinementList;
