import { makeStyles } from "@material-ui/core";
import { SquareMeter } from "UI/shared/atoms/SquareMeter";
import { Text16px } from "UI/shared/atoms/typographies/Text16px";
import { Text16pxBold } from "UI/shared/atoms/typographies/Text16pxBold";
import React from "react";

export interface SurfaceCaptionProps {
  surfaceMin?: number | null;
  surfaceMax?: number | null;
}

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
  },
}));

const isValid = (val?: number | null) => (val || 0) > 0;

export const SurfaceCaption: React.FC<SurfaceCaptionProps> = ({
  surfaceMin,
  surfaceMax,
}) => {
  const isValidSurfaceMin = isValid(surfaceMin);
  const isValidSurfaceMax = isValid(surfaceMax);

  const classes = useStyles();

  return (
    <div className={classes.container}>
      {isValidSurfaceMin && isValidSurfaceMax && surfaceMin !== surfaceMax ? (
        <>
          <Text16pxBold>{surfaceMin}</Text16pxBold>
          <Text16px>&nbsp;-&nbsp;</Text16px>{" "}
          <Text16pxBold>{surfaceMax}</Text16pxBold>
        </>
      ) : (
        <>
          {isValidSurfaceMin && <Text16pxBold>{surfaceMin}</Text16pxBold>}
          {!isValidSurfaceMin && isValidSurfaceMax && (
            <Text16pxBold>{surfaceMax}</Text16pxBold>
          )}
        </>
      )}
      {(isValidSurfaceMin || isValidSurfaceMax) && <SquareMeter />}
    </div>
  );
};
