import { Grid } from "@material-ui/core";
import React, { FC } from "react";

import { ValidationNumberField } from "../../../components/ValidationNumberField";
import { ProfileInputProps } from "../../../InputProps";

export interface ParkingDimensionProps {
  value?: number;
  label: string;
  adornement?: string;
}

export const ParkingSaleDimensionInput: FC<ProfileInputProps<
  ParkingDimensionProps
>> = ({ value, onChange, pristine, disabled = false }) => {
  return (
    <Grid item xs={12}>
      <ValidationNumberField
        disabled={disabled}
        pristine={pristine}
        label={value.label}
        onChange={(isValid, newValue) => {
          onChange(
            {
              adornement: value.adornement,
              label: value.label,
              value: newValue,
            },
            isValid
          );
        }}
        value={value.value || 0}
        adornement={value.adornement || ""}
      />
    </Grid>
  );
};
