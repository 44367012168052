import { Grid, Typography } from "@material-ui/core";
import PinIconOutlined from "@material-ui/icons/RoomOutlined";
import { DirectionMapButton } from "UI/shared/atoms/buttons/DirectionMapButton";
import { ResetMapButton } from "UI/shared/atoms/buttons/ResetMapButton";
import { useMap } from "hooks/useMap";
import React, { useCallback } from "react";
import { Map, Marker, TileLayer } from "react-leaflet";
import { useFrGouvGeocodingService } from "services/dataSources/geolocServices/useFrGouvGeocodingService";

const Adresse: React.FC<{
  content: {
    adresse: string;
    codePostal: string;
    ville: string;
    complementAdresse: string;
    pays: string;
  };
}> = ({ content }) => {
  const _addressString = `${content.adresse} ${content.codePostal} ${content.ville}`;
  const { position } = useFrGouvGeocodingService({
    addressString: _addressString,
  });
  const map = useMap();
  const resetMap = useCallback(() => {
    if (map.current && position.lat !== 0 && position.lng !== 0) {
      map.current.leafletElement.setView(
        {
          lat: position.lat,
          lng: position.lng,
        },
        13
      );
    }
  }, [position]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <PinIconOutlined />
        </Grid>

        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="body1">{content.adresse}</Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1">
                {content.complementAdresse}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1">{`${
                content.codePostal
              } ${content.ville.toUpperCase()}`}</Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1">
                {content.pays.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {content.ville.length > 0 && (
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Grid
            container
            justify="center"
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Map
              ref={map}
              center={position}
              zoom={13}
              zoomControl={false}
              style={{ height: "300px", width: "100%", zIndex: 0 }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={position} interactive={false} />
            </Map>
            <ResetMapButton tooltip={"Recentrer"} reset={resetMap} />
            {!!position?.lat && !!position?.lng && (
              <DirectionMapButton
                latitude={position.lat.toString()}
                longitude={position.lng.toString()}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Adresse;
