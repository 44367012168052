import { useCallback, useEffect, useState } from "react";
import { useServices } from "services";
import { decodeSlug } from "services/slugs/decodeSlug";
import { parseAsJson } from "services/slugs/parseAsJson";
import { Slugs } from "services/slugs/types";

import {
  AdaptedRefinements,
  MultipleText,
  Refinement,
  Refinements,
} from "../../components/RefinementField/types";
import { SLUG_STORAGE_KEY } from "../slugs/constants";
import { Aggregations, ContactsCriteria, ReadPortfolioDto } from "./types";

const initialState: () => AdaptedRefinements<ContactsCriteria> = () => ({
  consentStatuses: {
    displayMode: "list",
    moreFiltersCategory: "characteristics",
    rank: 4,
    refinement: {
      kind: "multiple-text",
      value: [],
    },
    size: 2,
  },
  locations: {
    displayMode: "moreFilters",
    moreFiltersCategory: "localisations",
    rank: 1,
    refinement: {
      kind: "locations",
      value: {
        departements: [],
        quartiers: [],
        regions: [],
        villes: [],
      },
    },
    size: 2,
  },
  profileKinds: {
    displayMode: "list",
    moreFiltersCategory: "characteristics",
    rank: 2,
    refinement: {
      kind: "multiple-text",
      value: [],
    },
    size: 2,
  },
  searchTerm: {
    displayMode: "list",
    moreFiltersCategory: undefined,
    rank: 1,
    refinement: {
      kind: "single-text",
      value: "",
    },
    size: 2,
  },
  sources: {
    displayMode: "list",
    moreFiltersCategory: "characteristics",
    rank: 3,
    refinement: {
      kind: "multiple-text",
      value: [],
    },
    size: 2,
  },
  updateDate: {
    displayMode: "moreFilters",
    moreFiltersCategory: "dates",
    rank: 1,
    refinement: {
      kind: "date-range",
      value: { max: undefined, min: undefined },
    },
    size: 2,
  },
  withArchivedContacts: {
    displayMode: "moreFilters",
    moreFiltersCategory: "display",
    rank: 1,
    refinement: {
      displayChip: true,
      displayedValue: "inclure les éléments archivés",
      kind: "checkbox",
      value: false,
    },
    size: 2,
  },
});

const getInitialState = () => getStoredState() || initialState();

const getStoredState: () => AdaptedRefinements<
  ContactsCriteria
> | null = () => {
  const storedState = sessionStorage.getItem(SLUG_STORAGE_KEY);
  if (storedState) {
    const { Portfolio } = parseAsJson<Slugs>()(storedState);
    if (Portfolio) {
      return decodeSlug<ContactsCriteria>(Portfolio);
    }
  }
  return null;
};

const updateRefinementsState = (
  current: AdaptedRefinements<ContactsCriteria>,
  { key, value }: { key: ContactsCriteria; value: Refinement }
) => {
  return {
    ...current,
    [key]: {
      ...current[key],
      refinement: { ...current[key].refinement, ...value },
    },
  };
};

const getRefinements = (
  adaptedRefinements: AdaptedRefinements<ContactsCriteria>
) =>
  Object.keys(adaptedRefinements).reduce(
    (acc, k) => ({
      ...acc,
      [k]: adaptedRefinements[k as ContactsCriteria].refinement,
    }),
    {} as Refinements<ContactsCriteria>
  );

const clearRefinements = (
  adaptedRefinements: AdaptedRefinements<ContactsCriteria>
): AdaptedRefinements<ContactsCriteria> => {
  const emptyState = initialState();
  return (Object.keys(adaptedRefinements) as ContactsCriteria[]).reduce(
    (acc, k) => {
      if (k === "sources" || k === "profileKinds" || k === "consentStatuses") {
        const refinement = adaptedRefinements[k].refinement as MultipleText;
        return {
          ...acc,
          [k]: {
            ...adaptedRefinements[k],
            refinement: {
              ...refinement,
              value: refinement.value.map((v) => ({
                ...v,
                isRefined: false,
              })),
            },
          },
        };
      }

      return {
        ...acc,
        [k]: emptyState[k],
      };
    },
    {}
  ) as AdaptedRefinements<ContactsCriteria>;
};

const updateRefinementsFromAggregations = (
  prev: AdaptedRefinements<ContactsCriteria>,
  aggs: Aggregations,
  labelTranslator: (_: string) => string = (_) => _
): AdaptedRefinements<ContactsCriteria> => {
  return (Object.keys(aggs) as ContactsCriteria[])
    .filter((k) => prev[k].refinement.kind === "multiple-text")
    .reduce((acc, k) => {
      const relatedRefinementValue = (
        refKey: ContactsCriteria,
        aggKey: string
      ) =>
        prev[refKey]
          ? (prev[refKey].refinement as MultipleText).value.find(
              (_) => _.value === aggKey
            )
          : null;

      return {
        ...acc,
        [k]: {
          ...acc[k],
          refinement: {
            ...acc[k].refinement,
            value: aggs[k].map(({ key, value }) => ({
              ...acc[k].refinement,
              count: value,
              isRefined: relatedRefinementValue(k, key)?.isRefined || false,
              label: labelTranslator(`${k}.${key}`),
              value: key,
            })),
          },
        },
      };
    }, prev);
};

export const usePortfolioRefinements = (pagedResult: ReadPortfolioDto) => {
  const {
    l10n: { t },
  } = useServices();
  const [adaptedRefinements, setAdaptedRefinements] = useState<
    AdaptedRefinements<ContactsCriteria>
  >(getInitialState());

  const onChange = ({
    key,
    value,
  }: {
    key: ContactsCriteria;
    value: Refinement;
  }) => {
    const nextState = updateRefinementsState(adaptedRefinements, {
      key,
      value,
    }) as AdaptedRefinements<ContactsCriteria>;

    if (JSON.stringify(nextState) === JSON.stringify(adaptedRefinements))
      return;

    setAdaptedRefinements(nextState);
  };

  const onFullChange = useCallback(
    (next: AdaptedRefinements<ContactsCriteria> | null) => {
      if (!next) return;
      setAdaptedRefinements(next);
    },
    []
  );

  const onClear = () => {
    setAdaptedRefinements((prev) => clearRefinements(prev));
  };

  useEffect(() => {
    if (!pagedResult || !pagedResult.aggregations) return;
    if (pagedResult.pageNumber === -1) return;

    const nextState = updateRefinementsFromAggregations(
      adaptedRefinements,
      pagedResult.aggregations,
      t
    );

    if (JSON.stringify(nextState) === JSON.stringify(adaptedRefinements))
      return;

    setAdaptedRefinements(nextState);
  }, [pagedResult.aggregations]);

  return {
    adaptedRefinements,
    onChange,
    onClear,
    onFullChange,
    refinements: getRefinements(adaptedRefinements),
  };
};
