import { CSSProperties } from "@material-ui/core/styles/withStyles";
import React from "react";

interface MarginContainerProps {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export const MarginContainer: React.FC<MarginContainerProps> = ({
  top,
  right,
  bottom,
  left,
  children,
}) => {
  const style: CSSProperties = {
    ...(top && { marginTop: top }),
    ...(bottom && { marginBottom: bottom }),
    ...(right && { marginRight: right }),
    ...(left && { marginLeft: left }),
  };

  return <div style={style}>{children}</div>;
};
