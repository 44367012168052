import { Hidden, makeStyles, Typography } from "@material-ui/core";
import React from "react";

import { ITheme } from "../../theme/ts/main";

export interface DisplayModeItem {
  key: string;
  label: string;
  icon: React.ReactNode;
  hideOnMobile: boolean;
}

interface DisplayModeSelectorProps {
  onChange: (mode: string) => void;
  selected: string;
  items: DisplayModeItem[];
}

const useStyles = makeStyles((theme: ITheme) => ({
  clickable: {
    cursor: "pointer",
    display: "flex",
  },
  clickableSelected: {
    color: theme.palette.tonique,
    display: "flex",
  },
  displayChoiceItem: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  displaySelectedChoiceItem: {
    color: theme.palette.tonique,
    fontWeight: "bold",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  icon: {
    color: theme.palette.default,
  },
  iconSelected: {
    color: theme.palette.tonique,
  },
  menu: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  root: {
    // ...theme.mixins.gutters(),
    // paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(2)
  },
}));

const DisplayModeSelector = ({
  onChange,
  selected,
  items,
}: DisplayModeSelectorProps) => {
  const classes = useStyles();

  const renderItem = (item: DisplayModeItem, isLast: boolean) => {
    return (
      <div
        key={item.key}
        className={
          selected === item.key ? classes.clickableSelected : classes.clickable
        }
        onClick={() => onChange(item.key)}
      >
        <div
          className={
            selected === item.key ? classes.iconSelected : classes.icon
          }
        >
          {item.icon}
        </div>

        <Typography
          className={
            selected === item.key
              ? classes.displaySelectedChoiceItem
              : classes.displayChoiceItem
          }
          variant={"body1"}
          style={
            isLast
              ? {
                  lineHeight: 1.7,
                }
              : {
                  borderRight: "1px solid black",
                  lineHeight: 1.7,
                  paddingRight: 10,
                }
          }
        >
          {" " + item.label + " "}
        </Typography>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.menu}>
        {items.map((item, index, arr) => {
          const isLastItem = index === arr.length - 1;
          if (item.hideOnMobile) {
            return (
              <Hidden smDown key={index}>
                {renderItem(item, isLastItem)}
              </Hidden>
            );
          } else {
            return renderItem(item, isLastItem);
          }
        })}
      </div>
    </div>
  );
};

export default DisplayModeSelector;
