import { TextField } from "@material-ui/core";
import React, { useState } from "react";

interface CommentProps {
  commentaire: string;
  setComment: (comment: string) => void;
}

export const Comment: React.FC<CommentProps> = ({
  commentaire,
  setComment,
}) => {
  const [isCommentError, setIsCommentError] = useState(false);
  const NB_CHAR_MAX_COMMENT = 300;

  const onCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length <= NB_CHAR_MAX_COMMENT) {
      setComment(e.currentTarget.value);
      setIsCommentError(false);
    } else {
      setIsCommentError(true);
    }
  };

  return (
    <>
      <TextField
        fullWidth
        label="Commentaire"
        variant="outlined"
        multiline={true}
        rows={4}
        value={commentaire}
        onChange={onCommentChange}
      />
      <div
        style={{
          color: isCommentError ? "red" : "inherit",
          textAlign: "right",
        }}
      >
        {commentaire.length}/{NB_CHAR_MAX_COMMENT}
      </div>
    </>
  );
};
