import { SortOption } from "UI/shared/molecules/SortInput";

//TODO - UPDATE
export function getSortFields(): SortOption[] {
  return [
    {
      isAscendingOrder: true,
      isDefault: false,
      label: "Date de Modification",
      query: "DateModification",
      value: "DateModification ascending",
    },
    {
      isAscendingOrder: false,
      isDefault: true,
      label: "Date de Modification",
      query: "DateModification",
      value: "DateModification descending",
    },
    {
      isAscendingOrder: true,
      isDefault: false,
      label: "Date de Création",
      query: "DateCreation",
      value: "DateCreation ascending",
    },
    {
      isAscendingOrder: false,
      isDefault: false,
      label: "Date de Création",
      query: "DateCreation",
      value: "DateCreation descending",
    },
    {
      isAscendingOrder: true,
      isDefault: false,
      label: "Prix",
      query: "Prix",
      value: "Prix ascending",
    },
    {
      isAscendingOrder: false,
      isDefault: false,
      label: "Prix",
      query: "Prix",
      value: "Prix descending",
    },
  ];
}
