import React from "react";
import { SectionTitle } from "UI/features/pige/molecules/SectionTitle";

interface InterfaceSectionProps {
  title: string;
  children: JSX.Element[] | JSX.Element;
}

const Section = ({ title, children }: InterfaceSectionProps) => {
  return (
    <div style={{ minHeight: "130px" }}>
      <SectionTitle title={title} />
      {children}
    </div>
  );
};

export default Section;
