import { Typography } from "@material-ui/core";
import React from "react";
import useConseillerObtenirParId from "routes/app/mon-reseau/conseillers/services/conseillerObtenirParId";
import { downloadVCard } from "UI/shared/molecules/Buttons/BoutonVCard";
import { CarteConseillerGalerie } from "../CarteConseillerGalerie";

const CarteConseillerGalerieParId = ({
  id,
  theme,
  t,
  classes,
  civiliteEnum,
}) => {
  const { error, loading, item } = useConseillerObtenirParId({
    id,
  });

  if (error) return <Typography variant={"h6"}>Erreur</Typography>;
  if (loading) return <Typography variant={"h6"}>Chargement...</Typography>;

  const vCardInfo = {
    facebook: item.lienFacebook,
    firstName: item.prenom,
    lastName: item.nom,
    namePrefix: item.civilite,
    note:
      item.specialites && Array.isArray(item.specialites)
        ? item.specialites.join(", ")
        : "",

    organization: item.bu,

    title: item.roles.join(" | "),

    url: item.urlMiniSite,

    workEmail: item.email,
    /*     urlPhoto: getUrlPhotoFromFileManager(item.bu, `50x50`, item.photo),*/
    workPhone: item.telephone,
  };

  const exportVCard = () => {
    downloadVCard({ item: vCardInfo });
  };

  return (
    <CarteConseillerGalerie
      conseiller={item}
      t={t}
      theme={theme}
      classes={classes}
      civiliteEnum={civiliteEnum}
      exportVCard={exportVCard}
    />
  );
};

export default CarteConseillerGalerieParId;
