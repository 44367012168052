import { CircularProgress, makeStyles } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Column } from "UI/shared/atoms/containers/Column";
import { Row } from "UI/shared/atoms/containers/Row";
import { Text12pxBold } from "UI/shared/atoms/typographies/Text12pxBold";
import {
  Text14pxBold,
  Text14pxBoldError,
} from "UI/shared/atoms/typographies/Text14pxBold";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import React from "react";
import { OptInForm } from "routes/app/contacts/components/ContactForm/components/OptInForm";
import { TRANSFORM_ERROR } from "services/request-for-informations/constants/errorMessages";
import { ConsentStatus } from "types";

interface CreateContactAndProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
  confirm: (consentStatus: ConsentStatus) => void;

  pending: boolean;
  hasError: boolean;
}

const useStyles = makeStyles((theme) => ({
  consentStatus: {
    "&:hover": {
      color: "#BFB9B9",
    },
    color: "#E3E3E3",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 500,
  },
}));

export const CreateContactAndProfileModal: React.FC<CreateContactAndProfileModalProps> = ({
  confirm,
  onClose,
  isOpen,
  pending,
  hasError,
}) => {
  const classes = useStyles();
  const modalStyle = {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  };

  return (
    <Modal open={isOpen} onClose={onClose} style={modalStyle}>
      <div className={classes.paper} style={{ textAlign: "center" }}>
        <Column itemJustify="center" columnJustify="center">
          <Text14pxBold>Transformation en potentiel acquéreur</Text14pxBold>
          <p>
            Un contact et un profil acquéreur vont être créés en se basant sur
            les informations de la DR et sur les caractéristiques du bien.
          </p>
        </Column>
        <VerticalSpace size={20} />
        <Column itemJustify="center" columnJustify="center">
          <Text12pxBold>Consentement de la personne:</Text12pxBold>
          <Text12pxBold>
            (Vous pouvez modifier cette valeur par la suite)
          </Text12pxBold>
        </Column>

        <VerticalSpace size={10} />
        {!pending && !hasError && (
          <OptInForm
            cancel={onClose}
            consentStatus={"Denied"}
            saveConsentStatus={confirm}
          />
        )}
        {pending && (
          <Row rowJustify="center">
            <CircularProgress />
          </Row>
        )}
        {hasError && (
          <Column itemJustify="center">
            <Text14pxBoldError>{TRANSFORM_ERROR}</Text14pxBoldError>
          </Column>
        )}
      </div>
    </Modal>
  );
};
