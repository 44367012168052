import React from "react";
import { Row } from "UI/shared/atoms/containers/Row";
import { HorizontalSpace } from "UI/shared/atoms/spacing/HorizontalSpace";
import { Text12px } from "UI/shared/atoms/typographies/Text12px";
import { Text12pxBold } from "UI/shared/atoms/typographies/Text12pxBold";
import { Text14px } from "UI/shared/atoms/typographies/Text14px";
import { Text14pxBold } from "UI/shared/atoms/typographies/Text14pxBold";

export interface SpacedLabelValueProps {
  label: string;
  value: string;
  spaceSize: number;
}

export const SpacedLabelValue12Px: React.FC<SpacedLabelValueProps> = ({
  label,
  value,
  spaceSize,
}) => {
  return (
    <Row>
      <Text12px>{label}</Text12px>
      <HorizontalSpace size={spaceSize} />
      <Text12pxBold>{value}</Text12pxBold>
    </Row>
  );
};

export const SpacedLabelValue14Px: React.FC<SpacedLabelValueProps> = ({
  label,
  value,
  spaceSize,
}) => {
  return (
    <Row>
      <Text14px>{label}</Text14px>
      <HorizontalSpace size={spaceSize} />
      <Text14pxBold>{value}</Text14pxBold>
    </Row>
  );
};
