import { SortOption } from "UI/shared/molecules/SortInput";
import { AdaptedRefinements } from "components/RefinementField/types";
import { filtersToQueryDto } from "routes/app/profiles/utils";
import { useServices } from "services";
import { isDefaultValue } from "services/profiles/utils/isDefaultValue";
import { ProfilesCriteria } from "types";

import { useTxHttp } from "../http/index";
import { PagedResultDto } from "./dto/PagedResultDto";
import { ProfilesFiltersDto } from "./dto/ProfilesFiltersDto";
import { ProfilesParamsDto } from "./dto/ProfilesParamsDto";
import { ProfileItemDto } from "./dto/ReadModelProfileDto";
import { getFiltersFromRefinements } from "./utils/getFiltersFromRefinements";

export interface ProfileSearchResponse {
  result: PagedResultDto<ProfileItemDto>;
  params: ProfilesParamsDto;
}
export const useProfilesSearch = () => {
  const { get } = useTxHttp();
  const { endpoints } = useServices();

  const hasUserRefinements = (
    adaptedRefinements: AdaptedRefinements<ProfilesCriteria>
  ) => {
    if (
      isDefaultValue(adaptedRefinements.sources.refinement.value) &&
      isDefaultValue(adaptedRefinements.familyKinds.refinement.value) &&
      isDefaultValue(adaptedRefinements.profileKinds.refinement.value) &&
      isDefaultValue(adaptedRefinements.contactName.refinement.value) &&
      isDefaultValue(adaptedRefinements.budgetMax.refinement.value) &&
      !adaptedRefinements.withArchivedProfiles
    ) {
      return false;
    }

    return true;
  };

  const getParams = (
    adaptedRefinements: AdaptedRefinements<ProfilesCriteria>,
    sortOption: SortOption,
    pageNumber: number
  ) => {
    if (hasUserRefinements(adaptedRefinements)) {
      const filters = getFiltersFromRefinements(adaptedRefinements);
      return {
        filters,
        pageNumber,
        size: 20,
        sortAsc: sortOption.isAscendingOrder,
        sortColumn: sortOption.query,
      } as ProfilesParamsDto;
    }
    return {} as ProfilesParamsDto;
  };

  const search = async (
    adaptedRefinements: AdaptedRefinements<ProfilesCriteria>,
    sortOption: SortOption,
    pageNumber: number
  ) => {
    try {
      const params = getParams(adaptedRefinements, sortOption, pageNumber);
      const requestParams = filtersToQueryDto(
        params,
        params.filters as ProfilesFiltersDto
      );

      const result = await get<PagedResultDto<ProfileItemDto>>(
        `${endpoints.profiles.allAgentProfiles}`,
        requestParams
      );
      return { params, result: result.data } as ProfileSearchResponse;
    } catch (e) {
      throw new Error("error reading CRM profiles.");
    }
  };

  return {
    search,
  };
};
