import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import { MoreVert } from "@material-ui/icons";
import React, { MouseEventHandler, useState } from "react";
import { Column } from "UI/shared/atoms/containers/Column";

export const MenuWithMoreIconButton: React.FC = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickMore: MouseEventHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget as any);
  };
  const handleCloseMenu: MouseEventHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  return (
    <>
      <Column columnJustify="flex-end">
        <IconButton onClick={handleClickMore}>
          <MoreVert />
        </IconButton>
      </Column>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
        }}
        onBlur={() => setAnchorEl(null)}
      >
        {children}
      </Menu>
    </>
  );
};
