import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { PipeSeparator } from "UI/shared/atoms/separators/PipeSeparator";
import { SquareMeter } from "UI/shared/atoms/SquareMeter";
import React from "react";

import { RoomsCaption } from "../../molecules/RoomsCaption";

interface SurfaceBedroomsNbRoomsNbCaptionProps {
  livingArea?: number;
  bedRoomsNumber?: number;
  roomsNumber?: number;
}

export const SurfaceBedroomsNbRoomsNbCaption: React.FC<SurfaceBedroomsNbRoomsNbCaptionProps> = ({
  livingArea,
  bedRoomsNumber,
  roomsNumber,
}) => {
  return (
    <Grid container direction="row" alignItems="flex-end">
      {!!livingArea && livingArea > 0 && (
        <>
          <Grid item>
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
              {livingArea}
            </Typography>
          </Grid>
          <SquareMeter />
          {((!!roomsNumber && roomsNumber > 0) ||
            (!!bedRoomsNumber && bedRoomsNumber > 0)) && <PipeSeparator />}
        </>
      )}
      {!!roomsNumber && roomsNumber > 0 ? (
        <>
          <RoomsCaption total={roomsNumber} type="room" />
          {!!bedRoomsNumber && bedRoomsNumber > 0 && <PipeSeparator />}
        </>
      ) : (
        <></>
      )}
      {!!bedRoomsNumber && bedRoomsNumber > 0 ? (
        <RoomsCaption total={bedRoomsNumber} type="bedroom" />
      ) : (
        <></>
      )}
      {(livingArea === undefined || livingArea === 0) &&
        (roomsNumber === undefined || roomsNumber === 0) &&
        (bedRoomsNumber === undefined || bedRoomsNumber === 0) && (
          <>
            <Typography variant="h3">&nbsp;</Typography>
            <Typography variant="h4">&nbsp;</Typography>
          </>
        )}
    </Grid>
  );
};
