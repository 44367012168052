import { string } from "api/DTOs/Common/Types";

export default function villeAdapter(item = {}) {
  return {
    codeDepartement: item.codeDepartement || string,
    codePostal:
      item.codesPostaux &&
      Array.isArray(item.codesPostaux) &&
      item.codesPostaux[0]
        ? item.codesPostaux[0]
        : string,
    codesPostaux: item.codesPostaux || string,
    departement: item.departement || string,
    region: item.region || string,
    villeId: item.id || string,
    villeNom: item.ville || string,
    rue: item.rue || string,
    numeroRue: item.numeroRue || string,
  };
}
