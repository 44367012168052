import { Grid, SwipeableDrawer, Typography, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AssignmentIcon from "@material-ui/icons/AssignmentOutlined";
import ChevronRightIcon from "@material-ui/icons/Close";
import ContactsIcon from "@material-ui/icons/ContactsOutlined";
import SurfaceIcon from "@material-ui/icons/CropFreeOutlined";
import DateRangeIcon from "@material-ui/icons/DateRangeOutlined";
import BudgetIcon from "@material-ui/icons/EuroOutlined";
import RoomIcon from "@material-ui/icons/KingBedOutlined";
import LocationIcon from "@material-ui/icons/LocationOnOutlined";
import AddIcon from "@material-ui/icons/TuneOutlined";
import React, { FC } from "react";
import { useFeatureFlags } from "services/featureFlags";

import { RefinementField } from "../../RefinementField";
import { Refinement, Refinements } from "../../RefinementField/types";
import { useMoreFiltersContext } from "../services/MoreFiltersProvider";
import { PigeCriteria } from "../types";
import styles from "./MoreFilters.module.css";

export const MoreFilters: FC<{
  total: number;
  refinements: Refinements<PigeCriteria>;
  onChange: (value: { key: PigeCriteria; value: Refinement }) => void;
}> = (props) => {
  const t = (_: string) => _;
  const theme = useTheme() as any;
  const { isOpen, setIsOpen } = useMoreFiltersContext();
  const { Pige_DisabledAd } = useFeatureFlags();

  return (
    <>
      <Button
        aria-label="more filter"
        variant={"contained"}
        color={"primary"}
        size={"small"}
        onClick={() => setIsOpen(true)}
        style={{
          fontSize: "16px",
          height: "45px",
          minWidth: "45px",
          padding: 0,
        }}
      >
        <AddIcon />
      </Button>

      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
      >
        <div
          className={styles.content}
          style={{ borderLeft: `5px solid ${theme.palette.tonique}` }}
        >
          <div className={styles.header}>
            <Typography variant="h2">{t("Recherche détaillée")}</Typography>

            <IconButton
              color="primary"
              aria-label="Close"
              onClick={() => setIsOpen(false)}
            >
              <ChevronRightIcon />
            </IconButton>
          </div>

          <Grid className={styles.body}>
            <Grid /*first column*/
              className={styles.column}
              container
              spacing={2}
              alignContent="flex-start"
            >
              <Grid item container spacing={1}>
                <Grid container item xs={12}>
                  <Grid item xs={12} style={{ paddingBottom: 8 }}>
                    <div className={styles.title}>
                      <DateRangeIcon
                        style={{
                          color: theme.palette.tonique,
                          marginRight: 5,
                        }}
                      />
                      <Typography variant={"h4"}>{t("Affichage")}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <RefinementField
                        onChange={(value) =>
                          props.onChange({ key: "favorisUniquement", value })
                        }
                        value={props.refinements.favorisUniquement}
                        label={t("Favoris")}
                      />
                      <RefinementField
                        onChange={(value) =>
                          props.onChange({
                            key: "avecSuiviUniquement",
                            value,
                          })
                        }
                        value={props.refinements.avecSuiviUniquement}
                        label={"Suivies"}
                      />
                      <RefinementField
                        onChange={(value) =>
                          props.onChange({ key: "pigeDuJour", value })
                        }
                        value={props.refinements.pigeDuJour}
                        label={t("Pige du jour")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <RefinementField
                      onChange={(value) =>
                        props.onChange({ key: "baissesDePrix", value })
                      }
                      value={props.refinements.baissesDePrix}
                      label={t("Baisses de prix")}
                    />
                    <RefinementField
                      onChange={(value) =>
                        props.onChange({
                          key: "typeAnnonceurParticuliers",
                          value,
                        })
                      }
                      value={props.refinements.typeAnnonceurParticuliers}
                      label={t("Particulier(s)")}
                    />

                    <RefinementField
                      onChange={(value) =>
                        props.onChange({ key: "typeAnnonceurPro", value })
                      }
                      value={props.refinements.typeAnnonceurPro}
                      label={t("Professionnel(s)")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RefinementField
                      onChange={(value) =>
                        props.onChange({
                          key: "avecNotesUniquement",
                          value,
                        })
                      }
                      value={props.refinements.avecNotesUniquement}
                      label={"Avec notes uniquement"}
                    />
                    <RefinementField
                      onChange={(value) =>
                        props.onChange({ key: "telephoneUniquement", value })
                      }
                      value={props.refinements.telephoneUniquement}
                      label={t("Avec tél. uniquement")}
                    />
                    {Pige_DisabledAd && (
                      <RefinementField
                        onChange={(value) =>
                          props.onChange({ key: "inactivesIncluses", value })
                        }
                        value={props.refinements.inactivesIncluses}
                        label={t("Inclure les annonces désactivées")}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <RefinementField
                      onChange={(value) =>
                        props.onChange({ key: "masqueesIncluses", value })
                      }
                      value={props.refinements.masqueesIncluses}
                      label={"Inclure les annonces masquées"}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <RefinementField
                      onChange={(value) =>
                        props.onChange({ key: "convertedOnly", value })
                      }
                      value={props.refinements.convertedOnly}
                      label={"Transformé en profil"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <div className={styles.title}>
                    <DateRangeIcon
                      style={{ color: theme.palette.tonique, marginRight: 5 }}
                    />
                    <Typography variant={"h4"}>{t("Dates")}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={(value) =>
                      props.onChange({ key: "dateDePublication", value })
                    }
                    value={props.refinements.dateDePublication}
                    label={t("1ère parution")}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <div className={styles.title}>
                    <AssignmentIcon
                      style={{ color: theme.palette.tonique, marginRight: 5 }}
                    />
                    <Typography variant={"h4"}>
                      {t("Caractéristiques")}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={(value) =>
                      props.onChange({ key: "typesAnnonce", value })
                    }
                    value={props.refinements.typesAnnonce}
                    label={t("Type d'affaire")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={(value) =>
                      props.onChange({ key: "typesDeBien", value })
                    }
                    value={props.refinements.typesDeBien}
                    label={t("Famille de biens")}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid /*second column*/
              className={styles.column}
              container
              spacing={2}
              alignContent="flex-start"
            >
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <div className={styles.title}>
                    <LocationIcon
                      style={{ color: theme.palette.tonique, marginRight: 5 }}
                    />
                    <Typography variant={"h4"}>{t("Lieu(x)")}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    value={props.refinements.zoneDeTravail}
                    onChange={(value) =>
                      props.onChange({ key: "zoneDeTravail", value })
                    }
                    label={t("Localisations")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.title}>
                    <SurfaceIcon
                      style={{ color: theme.palette.tonique, marginRight: 5 }}
                    />
                    <Typography variant={"h4"}>{t("Surfaces")}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={(value) =>
                      props.onChange({ key: "livingArea", value })
                    }
                    value={props.refinements.livingArea}
                    label={t("Surface hab")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={(value) =>
                      props.onChange({ key: "landArea", value })
                    }
                    value={props.refinements.landArea}
                    label={t("Surface terrain")}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <div className={styles.title}>
                    <BudgetIcon
                      style={{ color: theme.palette.tonique, marginRight: 5 }}
                    />
                    <Typography variant={"h4"}>{t("Bugdet")}</Typography>
                  </div>{" "}
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={(value) => props.onChange({ key: "prix", value })}
                    value={props.refinements.prix}
                    label={t("Prix")}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <div className={styles.title}>
                    <RoomIcon
                      style={{ color: theme.palette.tonique, marginRight: 5 }}
                    />
                    <Typography variant={"h4"}>
                      {t("Détails du bien")}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={(value) =>
                      props.onChange({ key: "nbPieces", value })
                    }
                    value={props.refinements.nbPieces}
                    label={t("Pièce(s)")}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <div className={styles.title}>
                    <ContactsIcon
                      style={{ color: theme.palette.tonique, marginRight: 5 }}
                    />
                    <Typography variant={"h4"}>{t("Contact")}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={(value) =>
                      props.onChange({ key: "contactPhone", value })
                    }
                    value={props.refinements.contactPhone}
                    label={t("Numéro de téléphone")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div className={styles.footer} onClick={() => setIsOpen(false)}>
            <Typography variant={"subtitle1"}>
              {props.total === 1 ? (
                <>
                  <span style={{ fontWeight: "bold" }}>1</span> bien correspond
                  à vos critères
                </>
              ) : (
                <>
                  <span style={{ fontWeight: "bold" }}>{props.total}</span>{" "}
                  biens correspondent à vos critères
                </>
              )}
            </Typography>
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
};
