import { useDeepEffect } from "hooks/useDeepEffect";
import { useCallback, useEffect, useState } from "react";
import { useConseiller } from "routes/app/conseiller/request";
import { useServices } from "services";
import obtenirConseillerParIdAdapter, {
  AdaptedAgent,
} from "services/conseillers/dto/Conseiller/ObtenirParId/obtenirConseillerParIdAdapter";
import { useTxHttp } from "services/http";
import {
  PagedRealEstatesDto,
  RealEstateDto,
} from "services/profiles/dto/RealEstateDto";
import { RealEstateMatchingDto } from "services/profiles/dto/RealEstateMatchingDto";
import { initialState } from "../constants";
import {
  Params,
  RealEstateMatchingOption,
  RealEstatesMatching,
} from "../types";
import { containsPayload, flatten } from "../utils";

export const useNetworkRealEstatesMatching = ({
  mode,
}: RealEstateMatchingOption) => {
  const { get } = useTxHttp();

  const {
    endpoints: { bienEnVente },
  } = useServices();

  const { adaptedData: agent } = useConseiller({
    adapter: obtenirConseillerParIdAdapter,
  }) as { adaptedData: AdaptedAgent | {} };

  const [state, setState] = useState<RealEstatesMatching>(initialState(mode));
  const memoizedState = useDeepEffect(state);

  const loadMore = useCallback(() => {
    setState((prev) => ({
      ...prev,
      params: { ...prev.params, page: +prev.params.page + 1 },
    }));
  }, []);

  const updateParams = (matching?: Partial<RealEstateMatchingDto>) => {
    setState((prev) => ({
      ...prev,
      params: { ...prev.params, ...matching },
    }));
  };

  const search = useCallback(
    async (params: Params) => {
      const currentAgent = agent as AdaptedAgent;
      const searchParams = {
        ...params,
        excludedAgents: [currentAgent.id],
      } as Params;

      const response = await get<PagedRealEstatesDto>(
        bienEnVente.rechercheGridOuListe,
        searchParams
      );

      if (!response) return;

      const {
        data: { data: realEstates },
      } = response;

      setState((prev) => ({
        ...prev,
        pages: {
          ...prev.pages,
          [params.page]: realEstates.map((realEstate) => ({
            ...realEstate,
            page: params.page,
          })),
        },
        total: response.data.total,
        lastIds: realEstates.map((realEstate) => realEstate.id),
        currentPage: params.page,
      }));
    },
    [agent, bienEnVente.rechercheGridOuListe]
  );

  useEffect(() => {
    const isValidAgent = Object.keys(agent).length !== 0;

    if (!isValidAgent) return;
    if (!memoizedState?.params) return;
    if (!containsPayload(memoizedState.params)) return;

    search(memoizedState.params);
  }, [memoizedState?.params, agent]);

  return {
    loadMore,
    updateParams,
    realEstates: flatten(state.pages) as RealEstateDto[],
    total: state.total,
    lastIds: state.lastIds,
    page: state.currentPage,
  };
};
