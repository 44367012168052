import { makeStyles } from "@material-ui/core/styles";

import { ITheme } from "../../../../theme/ts/main";

export const useStyles = makeStyles((theme: ITheme) => ({
  card: {
    width: "100%",
  },
  colonneContact: {
    width: "33%",
  },
  displayContact: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
  },
  fab: {
    backgroundColor: theme.palette.tonique,
    bottom: theme.spacing(2),
    color: "white",
    position: "fixed",
    right: theme.spacing(2),
  },
  gras: {
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  icons: {
    fontSize: "20px",
    [theme.breakpoints.up("md")]: {
      fontSize: "30px",
    },
  },
  link: {
    fontSize: "1.5rem",
  },
  media: {
    height: 200,
  },
  normal: {
    fontSize: "1rem",
  },
  normalPreWrap: {
    fontSize: "1rem",
    whiteSpace: "pre-wrap",
  },
  root: {},
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: "80%",
  },
}));
