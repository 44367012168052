// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
import React, { useEffect } from "react";
import { Route, RouteProps } from "react-router-dom";

import { useServices } from "../index";

export const PrivateRoute: React.FC<
  RouteProps & {
    analytics?: (params: Record<string, string>) => string;
    computedMatch?: Record<string, Record<string, string>>;
  }
> = ({ analytics, ...rest }) => {
  sessionStorage.setItem("page courante", rest.location?.pathname || "");
  const params = rest.computedMatch?.params;
  const {
    trackingService: { sendPageViewEventToGoogle },
  } = useServices();
  useEffect(() => {
    if (analytics) sendPageViewEventToGoogle(analytics(params || {}));
  }, [analytics, params, sendPageViewEventToGoogle]);
  const { authenticationService } = useServices();
  if (authenticationService.isAuthenticated()) {
    return <Route {...rest} />;
  }

  authenticationService.login();
  return <></>;
};
