import { useTheme } from "@material-ui/core/styles";
import ReactEcharts from "echarts-for-react";
import React from "react";
import {
  HistoriquePrix,
  MiseAJourPrix,
} from "services/piges/adapters/historiquePrixBienPigeAdapter";
import { ITheme } from "theme/ts/main";
import { formatIntegerPriceAbove5K } from "../../../../../../utils/formatage";

export interface PriceHistoryChartProps {
  historiquePrix: HistoriquePrix;
}

export default function PriceHistoryChart({
  historiquePrix,
}: PriceHistoryChartProps) {
  return (
    <ReactEcharts
      option={getOption(historiquePrix.misesAJourPrix, useTheme())}
    />
  );
}

interface FormatterParam {
  name: string;
  value: number;
}

const makeTooltip = (
  params: echarts.EChartOption.Tooltip.Format[],
  theme: ITheme
) =>
  `${params[0]?.name}<br />
  <span style="margin-top:5px;display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
    theme.palette.tonique
  };"></span>
  <span>Prix: ${formatIntegerPriceAbove5K(params[0]?.value)}</span>`;

const getOption: (
  series: MiseAJourPrix[],
  theme: ITheme
) => echarts.EChartOption<echarts.EChartOption.Series> = (series, theme) => {
  return {
    grid: {
      bottom: 80,
      containLabel: true,
      left: "0%",
      right: "10%",
    },
    legend: {
      data: ["Prix"],
      textStyle: {
        color: theme.palette.primary.main,
      },
    },
    series: [
      {
        data: Array.isArray(series) ? series.map((item) => item.prix) : [],
        itemStyle: {
          normal: {
            color: theme.palette.tonique,
          },
        },
        label: {
          normal: {
            formatter: (params: FormatterParam) =>
              formatIntegerPriceAbove5K(params.value),
            show: true,
          },
        },
        name: "Prix",
        type: "line",
      },
    ],
    tooltip: {
      axisPointer: {
        animation: false,
        label: {
          backgroundColor: "#505765",
        },
        type: "cross",
      },
      formatter: (params) =>
        makeTooltip(params as echarts.EChartOption.Tooltip.Format[], theme),
      trigger: "axis",
    },
    xAxis: [
      {
        axisLabel: {
          color: theme.palette.primary.main,
        },
        axisLine: { onZero: false },
        boundaryGap: false,
        data: Array.isArray(series)
          ? series.map((item) =>
              new Date(item.date).toLocaleDateString("fr-FR", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })
            )
          : [],
        splitLine: {
          lineStyle: {
            color: theme.palette.secondary.main,
          },
        },
        type: "category",
      },
    ],
    yAxis: {
      axisLabel: {
        color: theme.palette.primary.main,
        formatter: (value: number) => formatIntegerPriceAbove5K(value),
      },
      axisPointer: {
        label: {
          formatter: function (params: FormatterParam) {
            return formatIntegerPriceAbove5K(params.value);
          },
        },
      },
      name: "Prix",
      scale: true,
      splitArea: {
        areaStyle: {
          color: ["#ffffff"],
        },
        show: true,
      },
      splitLine: {
        lineStyle: {
          color: "#E2E2E2",
        },
      },
      splitNumber: 3,
      type: "value",
    },
  };
};
