import { Grid, Typography } from "@material-ui/core";
import TimeIcon from "@material-ui/icons/AccessTimeOutlined";
import moment from "moment";
import React from "react";

import { useServices } from "../../../../../../../../../services";
import { ContactStatusType } from "../../../../../../../../../services/portfolio/types";

interface PrintableOtherInfosProps {
  content: {
    dateCreation: string;
    dateModification: string;
    status: ContactStatusType;
  };
}

export const PrintableOtherInfos = ({ content }: PrintableOtherInfosProps) => {
  const {
    l10n: { t },
  } = useServices();
  return (
    <Grid container spacing={1}>
      <Grid item>
        <TimeIcon />
      </Grid>

      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="body1">{`${t("Créé le ")} ${moment(
              content.dateCreation
            ).format("DD/MM/YYYY")}`}</Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1">{`${
              content.status === "Archived"
                ? t("Archivé le ")
                : "Mis à jour le "
            } ${moment(content.dateModification).format(
              "DD/MM/YYYY"
            )}`}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
