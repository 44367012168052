import { Radio } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";

import { ITheme } from "../../../../../../theme/ts/main";

export interface SelectChipProps<T> {
  icon: JSX.Element;
  checkedIcon: JSX.Element;
  value: T;
  disabled?: boolean;
  onChange?: (value: T, checked: boolean) => void;
  checked?: boolean;
}

export const SelectChip: FC<SelectChipProps<unknown>> = ({
  value,
  checkedIcon,
  icon,
  disabled,
  onChange,
  checked,
}) => {
  const classes = useStyles();
  const _onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onChange) {
      onChange(value, !checked);
    }
  };

  if (checked !== undefined) {
    return (
      <Radio
        value={value}
        color="primary"
        icon={icon}
        checkedIcon={checkedIcon}
        disabled={disabled}
        onClick={_onClick}
        checked={checked}
        className={classes.radioRoot}
        style={{ backgroundColor: "transparent" }}
      />
    );
  }

  return (
    <Radio
      value={value}
      color="primary"
      icon={icon}
      checkedIcon={checkedIcon}
      disabled={disabled}
      className={classes.radioRoot}
      style={{ backgroundColor: "transparent" }}
    />
  );
};

const useStyles = makeStyles((theme: ITheme) => {
  return {
    radioRoot: {
      "& span": {
        "& input": {
          "&:hover + div": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
          },
        },
        padding: "0px 2px 0px 2px",
      },
      marginLeft: 0,
      padding: "0.378rem",
    },
  };
});
