import qs from "qs";
import { ProfilesFiltersDto } from "services/profiles/dto/ProfilesFiltersDto";
import { ProfilesParamsDto } from "services/profiles/dto/ProfilesParamsDto";
import { CityInfos } from "UI/features/profiles/organisms/CitiesList";

export const getLocations = (locations: CityInfos[]) => {
  return locations.map((city) => `${city.name}`).join(", ");
};

export const filtersToQueryDto = (
  params: ProfilesParamsDto,
  filters: ProfilesFiltersDto
) => {
  return {
    ...filters,
    pageNumber: params.pageNumber || 1,
    size: params.size || 20,
    sortAsc: params.sortAsc,
    sortColumn: params.sortColumn,
  };
};

export const paramsToQueryString = (
  params: ProfilesParamsDto,
  filters: ProfilesFiltersDto
) => {
  const requestParams = filtersToQueryDto(params, filters);
  let strParams = qs.stringify(requestParams);
  while (strParams.endsWith("&"))
    strParams = strParams.substring(0, strParams.length - 1);
  return strParams;
};
