import React from "react";
// import PropType from 'prop-types'
import Loading from "react-loading-skeleton";
import PaperX from "../../../../components/PaperX";
import { Grid } from "@material-ui/core";

export default function HitReduitSkeleton() {
  return (
    <PaperX style={{ padding: 0 }}>
      <Grid container spacing={1}>
        <Grid style={{ paddingBottom: 0, paddingTop: 0 }} item md={2}>
          <Loading height={120} width={"100%"} />
        </Grid>
        <Grid
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
          item
          md={10}
        >
          <Grid
            style={{ height: "100%" }}
            container
            spacing={2}
            alignItems={"center"}
          >
            <Grid item xs={12}>
              <Loading
                style={{
                  marginBottom: "2px",
                  marginTop: "2px",
                }}
                count={4}
                width={"100%"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PaperX>
  );
}
