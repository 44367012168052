export type TypeGeoEnum = "Region" | "Departement" | "Quartier" | "Ville";

export interface WithoutRaw {
  parent: string;
  nom: string;
  raw?: { id: string; nom: string }[];
  typeGeoEnum: TypeGeoEnum;
  id: string;
}

interface VilleAvecArrondissements {
  parent: string;
  nom: string;
  raw: { id: string; nom: string }[];
  typeGeoEnum: "VilleAvecArrondissements";
}

export type Zone = WithoutRaw | VilleAvecArrondissements;

export interface SelectOption {
  kind: "SelectOption";
  value: string;
  zone: Zone;
}

export interface GroupSelectOptions {
  label: string;
  options: SelectOption[];
}

export function toSelectOptions(
  options: Zone[],
  excludedTypes: TypeGeoEnum[] = []
): GroupSelectOptions[] {
  const t = (_: string) => _;

  const _options = (options || []).map<SelectOption>((zone) => ({
    kind: "SelectOption",
    value:
      zone.typeGeoEnum === "VilleAvecArrondissements"
        ? zone.raw.map((_) => _.id).join("-")
        : zone.id,
    zone,
  }));

  const regions = _options.filter(
    (option) => option.zone.typeGeoEnum === "Region"
  );

  const departements = _options.filter(
    (option) => option.zone.typeGeoEnum === "Departement"
  );

  const villes = _options.filter(
    (option) => option.zone.typeGeoEnum === "Ville" && !option.zone.raw
  );

  const quartiers = _options.filter(
    (option) => option.zone.typeGeoEnum === "Quartier"
  );

  const arrondissements = _options
    .filter((option) => option.zone.typeGeoEnum === "Ville" && option.zone.raw)
    .sort((a, b) => a.zone.nom.localeCompare(b.zone.nom));

  const ArrondissementsAsVilles: SelectOption[] = [];
  for (let i = 0, villeCourante = ""; i < arrondissements.length; i++) {
    const arrondissement = arrondissements[i];
    const nomVille = arrondissement.zone.nom.split(" ")[0];
    if (villeCourante !== nomVille) {
      ArrondissementsAsVilles.push({
        kind: "SelectOption",
        value: ((arrondissement.zone as WithoutRaw).raw || [])
          .map((_) => _.id)
          .join("-"),
        zone: {
          nom: `${nomVille} (${t("Tous arrondissements")})`,
          parent: arrondissement.zone.parent,
          raw: (arrondissement.zone as WithoutRaw).raw || [],
          typeGeoEnum: "VilleAvecArrondissements",
        },
      });

      villeCourante = nomVille;
    }
    ArrondissementsAsVilles.push(arrondissement);
  }

  const regionOptions = excludedTypes.includes("Region")
    ? []
    : [{ label: t("Régions"), options: regions }];

  const cityOptions = excludedTypes.includes("Ville")
    ? []
    : [{ label: t("Villes"), options: villes.concat(ArrondissementsAsVilles) }];

  const neighboorhoodOptions = excludedTypes.includes("Quartier")
    ? []
    : [{ label: t("Quartiers"), options: quartiers }];

  const departmentOptions = excludedTypes.includes("Departement")
    ? []
    : [{ label: t("Départements"), options: departements }];

  return [
    ...regionOptions,
    ...departmentOptions,
    ...cityOptions,
    ...neighboorhoodOptions,
  ];
}

export function toCityOptions(options: Zone[]): GroupSelectOptions[] {
  const t = (_: string) => _;
  return toSelectOptions(options).filter((o) => o.label === t("Villes"));
}
