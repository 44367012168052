import React, { createContext, useCallback, useEffect, useState } from "react";
import { useServices } from "services";
import { useFeatureFlags } from "services/featureFlags";
import { useTxHttp } from "services/http";

export interface PigeAdEventsContextProps {
  updateAdsWithEvents: () => void;
  adHasEvents: (id?: string) => boolean;
}

export const PigeAdEventsContext = createContext<PigeAdEventsContextProps>(
  {} as PigeAdEventsContextProps
);

export const PigeAdEventsContextProvider: React.FC = ({ children }) => {
  const { get } = useTxHttp();
  const { endpoints } = useServices();
  const { Pige } = useFeatureFlags();
  const [adsWithEvents, setAdsWithEvents] = useState<string[]>([]);

  const getAdsWithEvents = useCallback(
    () =>
      get<string[]>(endpoints.pige.adsWithEvents).then((response) =>
        setAdsWithEvents(response.data)
      ),
    [endpoints.pige.adsWithEvents, get]
  );

  const adHasEvents = (id?: string) => {
    if (!id) return false;
    return adsWithEvents.includes(id);
  };

  const updateAdsWithEvents = () => {
    getAdsWithEvents();
  };

  useEffect(() => {
    if (Pige) {
      getAdsWithEvents();
    }
  }, [getAdsWithEvents, Pige]);

  return (
    <PigeAdEventsContext.Provider value={{ adHasEvents, updateAdsWithEvents }}>
      {children}
    </PigeAdEventsContext.Provider>
  );
};
