import { ForRentParkingProfileReadDto } from "./../../contacts/contact/components/models/Proposal/ForRent/Parking/ForRentParkingProfileReadDto";
import { MaturityType } from "routes/app/contacts/contact/components/models/maturityType";
import { ProfileReadDto } from "routes/app/contacts/contact/components/models/profilesReadDto";
import { ForRentApartmentProfileReadDto } from "routes/app/contacts/contact/components/models/Proposal/ForRent/Apartment/forRentApartmentProfileReadDto";
import { ForRentHouseProfileReadDto } from "routes/app/contacts/contact/components/models/Proposal/ForRent/House/forRentHouseProfileReadDto";
import { SaleApartmentProfileReadDto } from "routes/app/contacts/contact/components/models/Proposal/ForSale/Apartment/saleApartmentProfileReadDto";
import { SaleHouseProfileReadDto } from "routes/app/contacts/contact/components/models/Proposal/ForSale/House/saleHouseProfileReadDto";
import { SaleLandProfileReadDto } from "routes/app/contacts/contact/components/models/Proposal/ForSale/Land/saleLandProfileReadDto";
import { SaleParkingProfileReadDto } from "routes/app/contacts/contact/components/models/Proposal/ForSale/Parking/saleParkingProfileReadDto";
import { PurchaseApartmentProfileReadDto } from "routes/app/contacts/contact/components/models/Search/purchaseApartmentProfileReadDto";
import { PurchaseBuildingProfileReadDto } from "routes/app/contacts/contact/components/models/Search/purchaseBuildingProfileReadDto";
import { PurchaseGoodWillProfileReadDto } from "routes/app/contacts/contact/components/models/Search/purchaseGoodWillProfileReadDto";
import { PurchaseHouseProfileReadDto } from "routes/app/contacts/contact/components/models/Search/purchaseHouseProfileReadDto";
import { PurchaseLandProfileReadDto } from "routes/app/contacts/contact/components/models/Search/purchaseLandProfileReadDto";
import { PurchaseParkingProfileReadDto } from "routes/app/contacts/contact/components/models/Search/purchaseParkingProfileReadDto";
import { PurchasePremisesProfileReadDto } from "routes/app/contacts/contact/components/models/Search/purchasePremisesProfileReadDto";
import { RentApartmentProfileReadDto } from "routes/app/contacts/contact/components/models/Search/rentApartmentProfileReadDto";
import { RentHouseProfileReadDto } from "routes/app/contacts/contact/components/models/Search/rentHouseProfileReadDto";
import { RentLandProfileReadDto } from "routes/app/contacts/contact/components/models/Search/rentLandProfileReadDto";
import { RentParkingProfileReadDto } from "routes/app/contacts/contact/components/models/Search/rentParkingProfileReadDto";
import { RentPremisesProfileReadDto } from "routes/app/contacts/contact/components/models/Search/rentPremisesProfileReadDto";
import { SearchLifeAnnuityApartmentProfileReadDto } from "routes/app/contacts/contact/components/models/Search/searchLifeAnnuityApartmentProfileReadDto";
import { SearchLifeAnnuityBuildingProfileReadDto } from "routes/app/contacts/contact/components/models/Search/searchLifeAnnuityBuildingProfileReadDto";
import { SearchLifeAnnuityHouseProfileReadDto } from "routes/app/contacts/contact/components/models/Search/searchLifeAnnuityHouseProfileReadDto";
import { SearchLifeAnnuityLandProfileReadDto } from "routes/app/contacts/contact/components/models/Search/searchLifeAnnuityLandProfileReadDto";
import { SearchLifeAnnuityPremisesProfileReadDto } from "routes/app/contacts/contact/components/models/Search/searchLifeAnnuityPremisesProfileReadDto";

import { SaleBuildingProfileReadDto } from "../../contacts/contact/components/models/Proposal/ForSale/Building/saleBuildingProfileReadDto";
import { SalePremisesProfileReadDto } from "../../contacts/contact/components/models/Proposal/ForSale/Premises/salePremisesProfileReadDto";
import { CityInfos } from "UI/features/profiles/organisms/CitiesList";

export type ProfileAccountType = "Professional" | "Customer";
export type BusinessType = "Location" | "Viager" | "Achat" | "Vente" | "Bail";
export type ProfileType = "Search" | "Seller";
export type ProfileStatusType = "Archived" | "Active";
export type PropertyCategoryType =
  | "House"
  | "Apartment"
  | "Building"
  | "Goodwill"
  | "Land"
  | "Parking"
  | "Premises";

export interface DisplayedProfileForDetails {
  id: string;
  profileType: ProfileType;
  bedroomsNumber?: number;
  budgetMax: number;
  propertyCategory: PropertyCategoryType;
  propertyType?: string;
  roomsNumber?: number;
  surfaceMax?: number;
  surfaceMin?: number;
  monthIncome?: number;
  maturity?: MaturityType;
  locations: CityInfos[];
  businessType: BusinessType;
  status: ProfileStatusType;
  archivedOrUpdatedDate: Date;
  notes?: string;
  creationDate: Date;
  profileAccount?: ProfileAccountType;
  spacesNumber?: number;
  jointOwnership?: boolean;
  boxableParking?: boolean;
  reference?: string;
}

export const profileAdapterDetails = (
  profile: ProfileReadDto
): DisplayedProfileForDetails => {
  switch (profile.kind) {
    case "SearchLifeAnnuityApartmentProfile":
      return searchLifeAnnuityApartmentAdapter(profile);
    case "SearchLifeAnnuityBuildingProfile":
      return searchLifeAnnuityBuildingAdapter(profile);
    case "SearchLifeAnnuityHouseProfile":
      return searchLifeAnnuityHouseAdapter(profile);
    case "SearchLifeAnnuityLandProfile":
      return searchLifeAnnuityLandAdapter(profile);
    case "SearchLifeAnnuityPremisesProfile":
      return searchLifeAnnuityPremisesAdapter(profile);
    case "PurchaseApartmentProfile":
      return searchPurchaseApartmentAdapter(profile);
    case "PurchaseBuildingProfile":
      return searchPurchaseBuildingAdapter(profile);
    case "PurchaseGoodwillProfile":
      return searchPurchaseGoodWillAdapter(profile);
    case "PurchaseHouseProfile":
      return searchPurchaseHouseAdapter(profile);
    case "PurchaseParkingProfile":
      return searchPurchaseParkingAdapter(profile);
    case "PurchaseLandProfile":
      return searchPurchaseLandAdapter(profile);
    case "PurchasePremisesProfile":
      return searchPurchasePremisesAdapter(profile);
    case "RentApartmentProfile":
      return searchRentalApartmentAdapter(profile);
    case "RentHouseProfile":
      return searchRentalHouseAdapter(profile);
    case "RentLandProfile":
      return searchRentalLandAdapter(profile);
    case "RentParkingProfile":
      return searchRentalParkingAdapter(profile);
    case "RentPremisesProfile":
      return searchRentalPremisesAdapter(profile);
    case "SaleApartmentProfile":
      return apartmentForSaleAdapter(profile);
    case "SaleHouseProfile":
      return houseForSaleAdapter(profile);
    case "SaleBuildingProfile":
      return buildingForSaleAdapter(profile);
    case "SaleLandProfile":
      return landForSaleAdapter(profile);
    case "SaleParkingProfile":
      return parkingForSaleAdapter(profile);
    case "SalePremisesProfile":
      return premisesForSaleAdapter(profile);
    case "ForRentApartmentProfile":
      return apartmentForRentAdapter(profile);
    case "ForRentParkingProfile":
      return parkingForRentAdapter(profile);
    case "ForRentHouseProfile":
      return houseForRentAdapter(profile);
    default:
      throw new Error(
        `Not implemented profile kind : ${(profile as any).kind as string}`
      );
  }
};

const apartmentForSaleAdapter = (
  profile: SaleApartmentProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  bedroomsNumber: profile.features.parts.summaries.bedRooms?.count,
  budgetMax: profile.features.financialInfo.price || 0,
  businessType: "Vente",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: [
    {
      name: profile.features.address.city?.name || "",
      zipcode: profile.features.address.city?.postalCodes[0] || "",
    },
  ],
  maturity: profile.maturity,
  notes: profile.notes,
  profileType: "Seller",
  propertyCategory: "Apartment",
  propertyType: profile.features.propertyInfo.built || "",
  roomsNumber: profile.features.parts.summaries.rooms?.count,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.features.propertyInfo.squareMeter,
});

const houseForSaleAdapter = (
  profile: SaleHouseProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  bedroomsNumber: profile.features.parts.summaries.bedRooms?.count,
  budgetMax: profile.features.financialInfo.price || 0,
  businessType: "Vente",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: [
    {
      name: profile.features.address.city?.name || "",
      zipcode: profile.features.address.city?.postalCodes[0] || "",
    },
  ],
  maturity: profile.maturity,
  notes: profile.notes,
  profileType: "Seller",
  propertyCategory: "House",
  propertyType: profile.features.propertyInfo.built || "",
  roomsNumber: profile.features.parts.summaries.rooms?.count,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.features.propertyInfo.squareMeter,
});

const parkingForSaleAdapter = (
  profile: SaleParkingProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  boxableParking: profile.features.parkingPropertyInfo.boxableParking,
  budgetMax: profile.features.financialInfo.price || 0,
  businessType: "Vente",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  jointOwnership: profile.features.parkingPropertyInfo.parkingJoinOwnership,
  locations: [
    {
      name: profile.features.address.city?.name || "",
      zipcode: profile.features.address.city?.postalCodes[0] || "",
    },
  ],
  maturity: profile.maturity,
  notes: profile.notes,
  profileType: "Seller",
  propertyCategory: "Parking",
  propertyType: profile.features.parkingKind || "",
  spacesNumber: profile.features.parkingPropertyInfo.spacesNumber,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.features.parkingPropertyInfo.squareMeter,
});

const premisesForSaleAdapter = (
  profile: SalePremisesProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.features.financialInfo.price || 0,
  businessType: "Vente",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: [
    {
      name: profile.features.address.city?.name || "",
      zipcode: profile.features.address.city?.postalCodes[0] || "",
    },
  ],
  maturity: profile.maturity,
  notes: profile.notes,
  profileType: "Seller",
  propertyCategory: "Premises",
  propertyType: profile.features.location || "",
  spacesNumber: profile.features.propertyInfo.lotSize,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.features.propertyInfo.totalArea,
});

const apartmentForRentAdapter = (
  profile: ForRentApartmentProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax:
    profile.features.financialInfo.rentChargesExcluded! +
      profile.features.financialInfo.charges! || 0,
  businessType: "Location",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: [
    {
      name: profile.features.address.city?.name || "",
      zipcode: profile.features.address.city?.postalCodes[0] || "",
    },
  ],
  maturity: profile.maturity,
  notes: profile.notes,
  profileType: "Seller",
  propertyCategory: "Apartment",
  propertyType: profile.features.propertyInfo.built || "",
  roomsNumber: profile.features.parts.summaries.rooms?.count,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.features.propertyInfo.squareMeter,
});

const parkingForRentAdapter = ({
  id,
  features,
  lastModificationDate,
  archivingDate,
  creationDate,
  maturity,
  notes,
}: ForRentParkingProfileReadDto): DisplayedProfileForDetails => {
  return {
    archivedOrUpdatedDate: archivingDate || lastModificationDate,
    budgetMax:
      (features.financialInfo.charges || 0) +
      (features.financialInfo.rentChargesExcluded || 0),
    businessType: "Location",
    creationDate: creationDate || lastModificationDate,
    id,
    locations: [
      {
        name: features.address.city?.name || "",
        zipcode: features.address.city?.postalCodes[0] || "",
      },
    ],
    maturity,
    notes,
    profileType: "Seller",
    propertyCategory: "Parking",
    status: archivingDate ? "Archived" : "Active",
    surfaceMax: features.parkingPropertyInfo.squareMeter,
  };
};
const houseForRentAdapter = (
  profile: ForRentHouseProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax:
    profile.features.financialInfo.rentChargesExcluded! +
      profile.features.financialInfo.charges! || 0,
  businessType: "Location",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: [
    {
      name: profile.features.address.city?.name || "",
      zipcode: profile.features.address.city?.postalCodes[0] || "",
    },
  ],
  maturity: profile.maturity,
  notes: profile.notes,
  profileType: "Seller",
  propertyCategory: "House",
  propertyType: profile.features.propertyInfo.built || "",
  roomsNumber: profile.features.parts.summaries.rooms?.count,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.features.propertyInfo.squareMeter,
});

const landForSaleAdapter = (
  profile: SaleLandProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.features.financialInfo.price || 0,
  businessType: "Vente",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: [
    {
      name: profile.features.address.city?.name || "",
      zipcode: profile.features.address.city?.postalCodes[0] || "",
    },
  ],
  maturity: profile.maturity,
  notes: profile.notes,
  profileType: "Seller",
  propertyCategory: "Land",
  propertyType: profile.features.landKind || "",
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.features.landPropertyInfo.squareMeter,
});

const buildingForSaleAdapter = (
  profile: SaleBuildingProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.features.financialInfo.price || 0,
  businessType: "Vente",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  jointOwnership: profile.features.buildingPropertyInfo.jointOwnership,
  locations: [
    {
      name: profile.features.address.city?.name || "",
      zipcode: profile.features.address.city?.postalCodes[0] || "",
    },
  ],
  maturity: profile.maturity,
  notes: profile.notes,
  profileType: "Seller",
  propertyCategory: "Building",
  propertyType: profile.features.buildingKind || "",
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.features.buildingPropertyInfo.squareMeter,
});

const searchLifeAnnuityApartmentAdapter = (
  profile: SearchLifeAnnuityApartmentProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  bedroomsNumber: profile.criteria.minBedrooms,

  budgetMax: profile.criteria.budget.bouquet.max || 0,
  businessType: "Viager",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  monthIncome: profile.criteria.budget.income.max,
  notes: profile.notes,
  profileType: "Search",
  propertyCategory: "Apartment",
  propertyType: profile.criteria.vacancy,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchLifeAnnuityHouseAdapter = (
  profile: SearchLifeAnnuityHouseProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  bedroomsNumber: profile.criteria.minBedrooms,

  budgetMax: profile.criteria.budget.bouquet.max || 0,
  businessType: "Viager",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  monthIncome: profile.criteria.budget.income.max,
  notes: profile.notes,
  profileType: "Search",
  propertyCategory: "House",
  propertyType: profile.criteria.vacancy,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchLifeAnnuityBuildingAdapter = (
  profile: SearchLifeAnnuityBuildingProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.bouquet.max || 0,
  businessType: "Viager",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  monthIncome: profile.criteria.budget.income.max,
  notes: profile.notes,
  profileType: "Search",
  propertyCategory: "Building",
  propertyType: profile.criteria.vacancy,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchLifeAnnuityLandAdapter = (
  profile: SearchLifeAnnuityLandProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.bouquet.max || 0,
  businessType: "Viager",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  monthIncome: profile.criteria.budget.income.max,
  notes: profile.notes,
  profileType: "Search",
  propertyCategory: "Land",
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.lotSize.max,
  surfaceMin: profile.criteria.lotSize.min,
});

const searchLifeAnnuityPremisesAdapter = (
  profile: SearchLifeAnnuityPremisesProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.bouquet.max || 0,
  businessType: "Viager",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  monthIncome: profile.criteria.budget.income.max,
  notes: profile.notes,
  profileType: "Search",
  propertyCategory: "Premises",
  propertyType: profile.criteria.vacancy,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchPurchaseApartmentAdapter = (
  profile: PurchaseApartmentProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  bedroomsNumber: profile.criteria.minBedrooms,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Achat",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  notes: profile.notes,
  profileType: "Search",
  propertyCategory: "Apartment",
  propertyType: profile.criteria.propertyAge,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchPurchaseHouseAdapter = (
  profile: PurchaseHouseProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  bedroomsNumber: profile.criteria.minBedrooms,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Achat",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  notes: profile.notes,
  profileType: "Search",
  propertyCategory: "House",
  propertyType: profile.criteria.propertyAge,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchPurchaseBuildingAdapter = (
  profile: PurchaseBuildingProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Achat",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  notes: profile.notes,
  profileType: "Search",
  propertyCategory: "Building",
  propertyType: profile.criteria.propertyAge,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchPurchaseGoodWillAdapter = (
  profile: PurchaseGoodWillProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Achat",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  notes: profile.notes,
  profileType: "Search",
  propertyCategory: "Goodwill",
  propertyType: profile.criteria.withPremises ? "WithWalls" : undefined,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchPurchasePremisesAdapter = (
  profile: PurchasePremisesProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Achat",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  notes: profile.notes,
  profileType: "Search",
  propertyCategory: "Premises",
  propertyType: profile.criteria.propertyAge,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchPurchaseLandAdapter = (
  profile: PurchaseLandProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Achat",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  notes: profile.notes,
  profileType: "Search",
  propertyCategory: "Land",
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.lotSize.max,
  surfaceMin: profile.criteria.lotSize.min,
});

const searchPurchaseParkingAdapter = (
  profile: PurchaseParkingProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Achat",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  notes: profile.notes,
  profileType: "Search",
  propertyCategory: "Parking",
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchRentalApartmentAdapter = (
  profile: RentApartmentProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  bedroomsNumber: profile.criteria.minBedrooms,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Location",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  notes: profile.notes,
  profileType: "Search",
  propertyCategory: "Apartment",
  propertyType: profile.criteria.furniture === "With" ? "Meublé" : undefined,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchRentalHouseAdapter = (
  profile: RentHouseProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  bedroomsNumber: profile.criteria.minBedrooms,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Location",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  notes: profile.notes,
  profileType: "Search",
  propertyCategory: "House",
  propertyType: profile.criteria.furniture === "With" ? "Meublé" : undefined,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchRentalPremisesAdapter = (
  profile: RentPremisesProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Location",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  notes: profile.notes,
  profileType: "Search",
  propertyCategory: "Premises",
  propertyType: profile.criteria.furniture === "With" ? "Meublé" : undefined,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchRentalLandAdapter = (
  profile: RentLandProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Location",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  notes: profile.notes,
  profileType: "Search",
  propertyCategory: "Land",
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.lotSize.max,
  surfaceMin: profile.criteria.lotSize.min,
});

const searchRentalParkingAdapter = (
  profile: RentParkingProfileReadDto
): DisplayedProfileForDetails => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Location",
  creationDate: profile.creationDate || profile.lastModificationDate,
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  notes: profile.notes,
  profileType: "Search",
  propertyCategory: "Parking",
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});
