import { useEffect, useState } from "react";

import { useTxHttp } from "../../http";
import { useServices } from "../../index";

export interface PropertyForSaleByAdReferenceDto {
  codeAffaire: string;
  codePostal: string;
  conseillerId: string;
  id: string;
  nbChambres: number;
  nbPieces: number;
  prix: number;
  surface: number;
  typeAffaireEnum: string;
  typeBienNom: string;
  ville: string;
  isExclusive: boolean;
  isNew: boolean;
  isPrestige: boolean;
  photo: string;
}

type PropertySearchStatus = "Found" | "Not Found" | "Not Known";

export interface PropertyForSale {
  propertyForSale: PropertyForSaleByAdReferenceDto | undefined;
  status: PropertySearchStatus;
}

export const useGetPropertyForSaleByAdReference = (
  id: string
): PropertyForSale => {
  const { get } = useTxHttp();
  const { endpoints } = useServices();
  const [propertyForSale, setPropertyForSale] = useState<PropertyForSale>({
    propertyForSale: undefined,
    status: "Not Known",
  });

  useEffect(() => {
    get<PropertyForSaleByAdReferenceDto>(
      endpoints.bienEnVente.rechercheParRefAnnonce,
      {
        adReference: id,
      }
    )
      .then((response) =>
        setPropertyForSale({
          propertyForSale: response.data,
          status: response.data ? "Found" : "Not Found",
        })
      )
      .catch(() =>
        setPropertyForSale({ propertyForSale: undefined, status: "Not Found" })
      );
  }, [endpoints.bienEnVente.rechercheParRefAnnonce, get, id]);

  return propertyForSale;
};
