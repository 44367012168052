import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
  },
  chipsContainer: {
    display: "flex",
    justifyContent: "space-between",
    overflow: "hidden",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      marginTop: "50px",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const inputProps = {
  id: "select-multiple-" + Math.round(Math.random() * 1000),
};

const BaseRender = ({ children, onClear, showClear }) => {
  const onClick = (e) => {
    e.stopPropagation();
    onClear();
  };
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {children}
      {showClear && (
        <IconButton onClick={onClick} style={{ padding: 0 }} size={"small"}>
          <Clear />
        </IconButton>
      )}
    </div>
  );
};

const ChipsRender = ({ selected, onDelete, onClear }) => {
  const classes = useStyles();
  return (
    <BaseRender onClear={onClear} showClear={selected.length > 1}>
      <div className={classes.chipsContainer}>
        {selected.length > 1 ? (
          <span>{selected.length} choix</span>
        ) : (
          <div className={classes.chips}>
            {selected.map((item) => (
              <Chip
                key={item.value}
                label={item.label}
                size={"small"}
                onDelete={() => onDelete(item)}
              />
            ))}
          </div>
        )}
      </div>
    </BaseRender>
  );
};

function DefaultRender({ selected, onClear }) {
  return (
    <BaseRender onClear={onClear} showClear={selected.length > 1}>
      {selected.length > 1 ? (
        <span>{selected.length} choix</span>
      ) : (
        <div>
          {selected.map((item) => (
            <Typography key={item.value} variant={"body1"}>
              {item.label}
            </Typography>
          ))}
        </div>
      )}
    </BaseRender>
  );
}

const SelectX = ({ refinement, onChange, style, label }) => {
  const selection = refinement.values.filter((item) => item.isRefined);
  let ref = null;

  function setRef(input) {
    if (input) {
      ref = input;
    }
  }

  function clearFocus() {
    if (ref) ref.focus();
  }

  function handleChange(items) {
    const { ...cpy } = refinement;
    if (refinement.options.multiple) {
      cpy.values = cpy.values.map((item) => {
        if (items.find((selected) => item.label === selected.label))
          return { ...item, isRefined: true };
        else return { ...item, isRefined: false };
      });
      onChange(cpy, refinement.query);
    } else onChange(items, refinement.query);
  }

  function getRenderValue(variant, value) {
    switch (variant) {
      case "chips":
        return (
          <ChipsRender
            selected={value}
            onDelete={handleDelete}
            onClear={() => {
              handleChange([]);
              clearFocus();
            }}
          />
        );
      default:
        return (
          <DefaultRender
            selected={value}
            onClear={() => {
              handleChange([]);
              clearFocus();
            }}
          />
        );
    }
  }

  function handleDelete(item) {
    clearFocus();
    const { ...cpy } = refinement;
    cpy.values = cpy.values.map((current) => {
      if (item.label === current.label) return { ...current, isRefined: false };
      else return current;
    });
    onChange(cpy, refinement.query);
  }

  return (
    <TextField
      style={{ height: "60px" }}
      fullWidth
      select
      SelectProps={{
        MenuProps,
        inputProps,
        multiple: refinement.options.multiple,
        onChange: (evt) => handleChange(evt.target.value),
        renderValue: (value) =>
          getRenderValue(refinement.options.renderValue, value),
        style,
        value: selection,
      }}
      label={label}
      variant={"outlined"}
      inputRef={setRef}
    >
      {refinement.values.map((item) => (
        <MenuItem key={item.label} value={item} selected={!!item.isRefined}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
SelectX.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  // options: PropTypes.object,
  refinement: PropTypes.object.isRequired,
  style: PropTypes.object,
};

export default SelectX;
