import synthese from "mockups/reporting/synthese.json";
import useHTTPService from "services/dataSources/communicationServices/useHTTPService";
import useTestService from "services/dataSources/testServices/useTestService";
import syntheseAdapter from "services/reporting/dto/Reporting/Synthese/syntheseAdapterSR";

import { useServices } from "../index";

export const useSyntheseAPI = ({ params, erreur = false }) => {
  const { endpoints } = useServices();

  const { status, adaptedData } = useHTTPService({
    adapter: syntheseAdapter,
    endpoint: endpoints.reporting.synthese,
    params,
  });
  return { items: adaptedData, status: status };
};

export const useSyntheseTest = ({ params, erreur = false }) => {
  const { status, adaptedData } = useTestService({
    adapter: syntheseAdapter,
    erreur,
    mockup: synthese,
    params,
  });
  return { items: adaptedData, status: status };
};

export const useSynthese = window.testTX ? useSyntheseTest : useSyntheseAPI;
