import { Map } from "./Map";

export interface BienForMap {
  bu: "Digitregroup" | "Capifrance" | "Optimhome" | "Refleximmo";
  latitude: string;
  longitude: string;
  localisation: {
    codePostal: string;
    villeNom: string;
    adresse: string;
  };
}

export default Map;
