import { Typography } from "@material-ui/core";
import React from "react";

export const Text14pxBold: React.FC = ({ children }) => {
  return <Typography variant="h5">{children}</Typography>;
};

export const Text14pxBoldError: React.FC = ({ children }) => {
  return (
    <Typography variant="h5" color="error">
      {children}
    </Typography>
  );
};
