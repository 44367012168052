import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useServices } from "services";

const useStyles = makeStyles(() => ({
  background: {
    backgroundColor: "#F2F2F2",
  },
  chipLabel: {
    color: "#0d92d2",
    fontSize: "12px",
    fontWeight: "bold",
  },
}));

export const SousCompromis: React.FC = () => {
  const {
    l10n: { t },
  } = useServices();

  const classes = useStyles({
    fontSize: "0.9rem",
  });

  return (
    <Chip
      label={t("Sous compromis")}
      size="small"
      className={classes.background}
      classes={{ label: classes.chipLabel }}
    />
  );
};
