import React, { FC, useEffect, useState } from "react";
import { useDebounce } from "react-use";

import { NumberField } from "../NumberField";
import { Price } from "../types";

interface NumberProps {
  value: Price;
  onChange: (refinement: Price) => void;
  label: string;
}

export const NumberRefinement: FC<NumberProps> = (props) => {
  const [val, setVal] = useState<number>();
  const [debouncedValue, setDebouncedValue] = useState<number>();
  const onChange = () => {
    setDebouncedValue(val);

    if (!val) return;

    props.onChange({
      kind: "price",
      value: val || undefined,
    });
  };

  useEffect(() => {
    const n = props.value.value;
    setVal(n);
    setDebouncedValue(n);
  }, [props.value.value]);

  const [, cancel] = useDebounce(onChange, 500, [val]);
  const onBlur = () => {
    cancel();
    onChange();
  };

  return (
    <NumberField
      isLoading={debouncedValue !== val}
      label={props.label}
      onBlur={onBlur}
      onChange={setVal}
      unit="€"
      value={val}
    />
  );
};
