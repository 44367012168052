import PaginationX from "components/PaginationX";
import moment from "moment";
import PropTypes from "prop-types";
import qs from "qs";
import React, { createContext, useEffect, useState } from "react";

import { useTxHttp } from "../../services/http";
import NoResult from "./components/NoResult";
import SearchBar from "./components/SearchBar";
import TremplinFourchetteDate from "./components/SearchBar/components/TremplinFourchetteDate";

export const REFINEMENT_TYPE = {
  multipleSelection: {
    checkbox: "refinementMultipleSelectionCheckbox",
    chips: "refinementMultipleSelectionChips",
  },
  range: {
    date: "refinementRangeDate",
    select: "refinementRangeSelect",
    slider: "refinementRangeSlider",
    textfield: "refinementRangeTextfield",
  },
  textSearch: "refinementTextSearch",
};

export const DataProviderContext = createContext({});
export const generateQuery = (_refinements) => {
  let cpyParams = {};
  let param;
  for (param in _refinements) {
    let type = _refinements[param].type;
    if (
      type === REFINEMENT_TYPE.multipleSelection.checkbox ||
      type === REFINEMENT_TYPE.multipleSelection.chips
    ) {
      cpyParams[param] = _refinements[param].values
        .filter((item) => item.isRefined)
        .map((item) => item.value);
    } else if (
      type === REFINEMENT_TYPE.range.textfield ||
      type === REFINEMENT_TYPE.range.slider ||
      type === REFINEMENT_TYPE.range.select
    ) {
      const limiteInf = Number(_refinements[param].limiteInf);
      const limiteSup = Number(_refinements[param].limiteSup);

      if (!Number.isNaN(limiteInf) && limiteInf > 0)
        cpyParams[_refinements[param].labelLimiteInf] = limiteInf;
      if (!Number.isNaN(limiteSup) && limiteSup >= limiteInf && limiteSup > 0)
        cpyParams[_refinements[param].labelLimiteSup] = limiteSup;
    } else if (type === REFINEMENT_TYPE.range.date) {
      cpyParams[_refinements[param].labelLimiteInf] = moment(
        _refinements[param].limiteInf
      ).format("YYYY-MM-DD");

      cpyParams[_refinements[param].labelLimiteSup] = moment(
        _refinements[param].limiteSup
      ).format("YYYY-MM-DD");
    } else if (type === REFINEMENT_TYPE.textSearch) {
      cpyParams[_refinements[param].query] = _refinements[param].value;
    } else {
      cpyParams[param] = _refinements[param];
      // throw new Error("Type refinement inconnu : " + _refinements[param].type);
    }
  }
  return cpyParams;
};

export const DataProvider = React.memo(
  ({ url, refinements, noCache, autoUpdate, ...props }) => {
    const { get } = useTxHttp();

    const _initialRefinements = refinements ? JSON.parse(refinements) : {};
    const [providerData, setProviderData] = useState(false);
    const [currentRefinements, setCurrentRefinements] = useState(
      _initialRefinements
    );
    const [localUrl, setLocalUrl] = useState(url);

    const clearRefinements = () => {
      setCurrentRefinements(JSON.parse(refinements));
    };

    const appelAPI = async (url, params) => {
      return get(url, params).then((_) => _.data?.result ?? _.data);
    };

    const dataInStorage = (fullPath) => {
      const d = sessionStorage.getItem(fullPath);
      if (d) {
        // Récupération des données en cache
        let _data = d;
        _data = JSON.parse(_data);
        // vérification que les données stockées ne sont pas toutes vides
        // si oui on relance l'appel API - compensation problème BI -
        let property;
        let totalElements = 0;
        for (property in _data) {
          if (Array.isArray(_data[property]))
            totalElements += _data[property].length;
        }
        if (totalElements === 0) return false;
        // ---
        return _data;
      } else return false;
    };

    useEffect(() => {
      if (currentRefinements !== _initialRefinements) {
        if (currentRefinements.preventUpdate) {
          delete currentRefinements.preventUpdate;
          return;
        }
        const params = generateQuery(currentRefinements);
        const _localUrl = localUrl.replace(/page=\d+/g, "page=1");
        const fullPath = `${_localUrl}?${JSON.stringify(params)}`;
        const storedData = dataInStorage(fullPath);
        if (!noCache && storedData) setProviderData(storedData);
        else {
          setProviderData(false);
          appelAPI(`${_localUrl}`, params)
            .then((data) => {
              setProviderData(data);
              if (!noCache)
                sessionStorage.setItem(fullPath, JSON.stringify(data));
            })
            .catch((e) => {
              setProviderData({ error: e.toString() });
            });
        }
      }

      // eslint-disable-next-line
    }, [currentRefinements]);

    useEffect(() => {
      const params = generateQuery(currentRefinements);
      const fullPath = `${localUrl}?${JSON.stringify(params)}`;
      const storedData = dataInStorage(fullPath);
      if (!noCache && storedData) setProviderData(storedData);
      else {
        setProviderData(false);
        appelAPI(`${localUrl}`, params)
          .then((data) => {
            setProviderData(data);
            if (!noCache)
              sessionStorage.setItem(fullPath, JSON.stringify(data));
          })
          .catch((e) => {
            setProviderData({ error: e.toString() });
          });
      }
      // eslint-disable-next-line
    }, [localUrl]);

    function onChangePagination(page) {
      const [baseUrl, queryString] = localUrl.split("?");
      let params = qs.parse(queryString);
      params = { ...params, page };
      setLocalUrl(`${baseUrl}?${qs.stringify(params)}`);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    function updateRefinements(data, type) {
      let _refinements = { ...currentRefinements };
      _refinements[type] = data;
      setCurrentRefinements({ ..._refinements });
    }

    const totalResultat =
      providerData && providerData.total ? providerData.total : 0;

    return (
      <DataProviderContext.Provider
        value={{
          components: {
            dateRange: ({ subtitle }) => (
              <div
                style={{
                  alignItems: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TremplinFourchetteDate
                  update={updateRefinements}
                  data={currentRefinements.dateEvt}
                  type="dateEvt"
                />
                {subtitle(currentRefinements.dateEvt)}
              </div>
            ),
            noResult: (props) => <NoResult {...props} />,
            pagination: (props) => (
              <>
                {providerData && providerData.currentPageNumber && (
                  <PaginationX
                    {...props}
                    page={providerData.currentPageNumber}
                    nbPages={providerData.totalPage}
                    onChange={onChangePagination}
                  />
                )}
              </>
            ),
            searchBar: (props) => (
              <SearchBar
                {...props}
                initialState={
                  JSON.stringify(currentRefinements) === refinements
                }
                refinements={currentRefinements}
                setRefinements={updateRefinements}
                clearRefinements={clearRefinements}
                totalResultat={totalResultat}
              />
            ),
          },
          currentRefinements,
          providerData,
          setCurrentRefinements,
          setLocalUrl,
        }}
      >
        {props.children}
      </DataProviderContext.Provider>
    );
  }
);

DataProvider.propTypes = {
  autoUpdate: PropTypes.number,
  noCache: PropTypes.bool,
  refinements: PropTypes.string,
  url: PropTypes.string.isRequired,
};

DataProvider.defaultProps = {
  autoUpdate: 0,
  noCache: false,
};
