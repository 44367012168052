import { Grid } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useServices } from "services";
import { formatPrix } from "utils/formatage";

import { BlocContainer } from "../../../../../../../shared/atoms/containers/BlocContainer";
import { Column } from "../../../../../../../shared/atoms/containers/Column";
import { PaddingContainer } from "../../../../../../../shared/atoms/containers/PaddingContainer";
import { Row } from "../../../../../../../shared/atoms/containers/Row";
import { HorizontalSpace } from "../../../../../../../shared/atoms/spacing/HorizontalSpace";
import { Text12pxBold } from "../../../../../../../shared/atoms/typographies/Text12pxBold";
import { Text14px } from "../../../../../../../shared/atoms/typographies/Text14px";
import { Text14pxBold } from "../../../../../../../shared/atoms/typographies/Text14pxBold";
import { VerticalSpace } from "../../../../../../../shared/atoms/spacing/VerticalSpace";
import { LabelledValue } from "../../../../../../../shared/molecules/LabelledValue";
import { TitleAndHorizontalDivider } from "../../../../../../../shared/molecules/TitleAndHorizontalDivider";
import { ProfileOriginType } from "../../../../../../../../routes/app/contacts/contact/components/models/profileOriginType";
import { BuildingFeaturesDto } from "../../../../../../../../routes/app/contacts/contact/components/models/Proposal/Features/Building/buildingFeaturesDto";
import { BuildingForSaleInfoKind } from "../../../../../../../../routes/app/contacts/contact/components/models/Proposal/Features/Building/buildingInfoKind";
import { SaleBuildingProfileReadDto } from "../../../../../../../../routes/app/contacts/contact/components/models/Proposal/ForSale/Building/saleBuildingProfileReadDto";
import { ProfileLocalisation } from "../../../../../organisms/ProfileLocalisation";
import { ProfileProject } from "../../../../../organisms/ProfileProject";
import { ProfileStatusCard } from "../../../../../organisms/ProfileStatusCard";

export interface BuildingForSaleProfileDetailsProps {
  readProfile: SaleBuildingProfileReadDto;
}

const getPriceSection: (a: BuildingFeaturesDto) => Record<string, string> = (
  features
) => ({
  ...{
    "Prix (net vendeur)": `${formatPrix(
      (features.financialInfo.price || 0) /
        (1 + (features.financialInfo.agentFees || 0) / 100)
    )}`,
    "Prix de vente": `${formatPrix(features.financialInfo.price)}`,
    "Taux honoraires": `${features.financialInfo.agentFees || "NC"}%`,
  },
  ...(features.financialInfo.taxProperty && {
    "Taxe foncière": `${formatPrix(features.financialInfo.taxProperty)}/an`,
  }),
  ...(features.financialInfo.expensesEstimation && {
    "Charges prévisionnelles": `${formatPrix(
      features.financialInfo.expensesEstimation
    )}/an`,
  }),
});

const getBuildingTypeValue = (
  buildingType: BuildingForSaleInfoKind,
  t: (_: string) => string
): string => {
  switch (buildingType) {
    case "Building":
      return t("buildingForSaleInfoKind.Building");
    case "OfficeBuilding":
      return t("buildingForSaleInfoKind.OfficeBuilding");
    case "InvestmentBuilding":
      return t("buildingForSaleInfoKind.InvestmentBuilding");
    case "BuildingToCut":
      return t("buildingForSaleInfoKind.BuildingToCut");
    default:
      return t("NC");
  }
};

const getCharacteristicSections: (
  a: BuildingFeaturesDto,
  t: (_: string) => string
) => Record<string, Record<string, string | number | undefined>> = (
  features,
  t
) => ({
  Annexes: {
    "Nombre de caves": features.buildingAnnexes?.basementsNumber ?? "NC",
    "Nombre de chambres de service":
      features.buildingAnnexes?.serviceRoomsNumber ?? "NC",
  },
  "Le bien": {
    "Année de construction":
      features.buildingPropertyInfo.constructionYear ?? "",
    Copropriété: features.buildingPropertyInfo.jointOwnership
      ? "Oui"
      : undefined,
    "Date de dernier ravalement": features.buildingPropertyInfo
      .lastRenovationDate
      ? moment(features.buildingPropertyInfo.lastRenovationDate).format(
          "DD/MM/YYYY"
        )
      : "",
    "Nombre d'étages": features.buildingPropertyInfo.floorsNumber,
    "Nombre de lots": features.buildingPropertyInfo.lotsNumber,
    "Procédure en cours": features.buildingPropertyInfo.ongoingProcedure
      ? "Oui"
      : undefined,
    Surface: `${features.buildingPropertyInfo.squareMeter} m²`,
    Type: getBuildingTypeValue(features.buildingKind, t),
    "Types de lot": (features.lots || [])
      .map((a) => t(`forSaleBuilding.lotsType.${a}`))
      .join(", "),
    "État général": t(
      `generalStateType.${features.buildingPropertyInfo.generalState}`
    ),
  },
  Loué: features.buildingRentedInfo.rented
    ? {
        "Revenu brut total": `${features.buildingRentedInfo.totalGrossIncome} euros/an`,
        "dont revenu commercial": `${
          features.buildingRentedInfo.commercialGrossIncome || 0
        } euros/an`,
        "dont revenu habitation": `${
          features.buildingRentedInfo.residentialGrossIncome || 0
        } euros/an`,
      }
    : { "Revenu brut total": "NC" },
  Stationnements: {
    "Garage fermé": features.parkingInfo.hasBox ? "Oui" : "NC",
    Nombre: features.parkingInfo.count || "NC",
    "Parking couvert": features.parkingInfo.hasInteriorParking ? "Oui" : "NC",
    "Parking extérieur": features.parkingInfo.hasExteriorParking ? "Oui" : "NC",
  },
  Équipements: {
    Prestations: (features.amenities || [])
      .map((a) => t(`amenity.${a}`))
      .join(", "),
  },
});

export const BuildingForSaleProfileDetails: React.FC<BuildingForSaleProfileDetailsProps> = ({
  readProfile: {
    features,
    archivingDate,
    lastModificationDate,
    maturity,
    origin,
    account,
    creationDate,
    reference,
  },
}) => {
  const {
    l10n: { t },
  } = useServices();

  const priceSection = getPriceSection(features);

  const characteristicSections = getCharacteristicSections(features, t);

  const filteredCharacteristicSections: Record<
    string,
    Record<string, string | number>
  > = Object.keys(characteristicSections).reduce((acc, key) => {
    const property = Object.keys(characteristicSections[key]).reduce(
      (propAcc, propertyKey) => {
        const val = characteristicSections[key][propertyKey];
        return !!val && val !== "NC"
          ? { ...propAcc, [propertyKey]: val }
          : propAcc;
      },
      {}
    );
    return Object.keys(property).length > 0 ? { ...acc, [key]: property } : acc;
  }, {});

  return (
    <PaddingContainer left={16} top={16} right={12}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <ProfileStatusCard
            status={archivingDate ? "Archived" : "Active"}
            creationDate={creationDate || lastModificationDate}
            updateDate={archivingDate || lastModificationDate}
            maturity={maturity}
            origin={origin as ProfileOriginType}
            reference={reference}
          />

          <ProfileProject
            separatorSize={20}
            chipDiameter={56}
            profileAccount={account}
            businessType="Vente"
            propertyCategory="Building"
          />
          <VerticalSpace size={16} />
          <BlocContainer title={t("PRIX")}>
            <Row rowJustify="flex-start">
              <Column itemJustify="flex-end" columnJustify="flex-end">
                {Object.keys(priceSection).map((k) => (
                  <Text14px key={k}>{t(k)}</Text14px>
                ))}
              </Column>
              <HorizontalSpace size={24} />
              <Column itemJustify="flex-start" columnJustify="flex-start">
                {Object.values(priceSection).map((v) => (
                  <Text14pxBold key={v}>{v}</Text14pxBold>
                ))}
              </Column>
            </Row>
          </BlocContainer>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ProfileLocalisation address={features.address} mapHeight={225} />
          <VerticalSpace size={8} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TitleAndHorizontalDivider
            title="CARACTÉRISTIQUES"
            spaceBetween={8}
            dividerColor={"#8E8E8E"}
          />
          {Object.keys(filteredCharacteristicSections).map((section) => {
            return (
              <div key={section}>
                <PaddingContainer top={8}>
                  <Text12pxBold>{section}</Text12pxBold>
                </PaddingContainer>
                <BlocContainer>
                  {Object.keys(filteredCharacteristicSections[section]).map(
                    (k) => (
                      <LabelledValue
                        key={k}
                        label={t(k)}
                        value={filteredCharacteristicSections[section][k]}
                        size={"14"}
                      />
                    )
                  )}
                </BlocContainer>
              </div>
            );
          })}
        </Grid>
      </Grid>
    </PaddingContainer>
  );
};
