import { Grid } from "@material-ui/core";
import React, { useCallback } from "react";
import { RealEstateDto } from "services/profiles/dto/RealEstateDto";
import { RealEstatePropertyCard } from "UI/features/realEstates/organisms/RealEstatePropertyCard";
import { InfiniteScrollList } from "UI/shared/organisms/InfiniteScrollList/InfiniteScrollList";
import { generateListKeys } from "utils/component-lists/generateKey";
import { SearchResultProps } from "../../props";
import { toRealEstatePropertyCardProps } from "../adapters/toRealEstatePropertyCardProps";

const SEARCH_RESULT_CONTAINER_ID = "map-real-estates";

export const RealEstatesMap: React.FC<
  SearchResultProps & { height: string | number }
> = ({ items, loadMore, height, onClick }) => {
  const uniqueKey = generateListKeys<RealEstateDto>({
    feature: "realestates",
    customName: "map",
  });

  const handleClick = useCallback(
    (id: string) => () => {
      onClick?.(id);
    },
    [onClick]
  );

  return (
    <div>
      <Grid
        container
        spacing={2}
        style={{ overflowY: "auto", height }}
        id={SEARCH_RESULT_CONTAINER_ID}
      >
        <InfiniteScrollList
          pageSize={10}
          loadMore={loadMore}
          wrapItems={false}
          containerId={SEARCH_RESULT_CONTAINER_ID}
        >
          {items.map((item) => (
            <Grid item md={6} xs={12} key={uniqueKey(item)}>
              <RealEstatePropertyCard
                {...toRealEstatePropertyCardProps(item, handleClick(item.id))}
              />
            </Grid>
          ))}
        </InfiniteScrollList>
      </Grid>
    </div>
  );
};
