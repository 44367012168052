import { array, bool, number, string } from "api/DTOs/Common/Types";
import conseillerAdapter from "services/rapprochement/Rapprochement/ConseillerAdapter";
import villeAdapter from "services/rapprochement/Rapprochement/VilleAdapter";
import { PropertyForSale } from "types";

function obtenirParIdAdapter(result: any = {}): PropertyForSale {
  return {
    anneeConstruction: result.anneeConstruction || number,
    annonceDesc: result.texte || string,
    bienEnCopropriete: result.bienEnCopropriete || bool,
    bu: result.bu || string,
    chargesCopropriete: result.chargesCopropriete || number,
    codeAffaire: result.codeAffaire || string,
    conseillerPrincipal:
      result.conseillers && result.conseillers.length > 0
        ? conseillerAdapter(result.conseillers[0])
        : conseillerAdapter(),
    conseillers: result.conseillers
      ? result.conseillers.map((conseiller: any) =>
          conseillerAdapter(conseiller)
        )
      : array,
    dateAjout: result.dateAjout || string,
    dateModification: result.dateModification || string,
    dpeLettre: result.dpeLettre || string,
    dpeValeur: result.dpeValeur || number,
    exclusivite: result.exclusivite || bool,
    exposition: result.exposition || string,
    externalId: result.externalId || string,
    familleBienEnum: result.familleBienEnum || string,
    gesLettre: result.gesLettre || string,
    gesValeur: result.gesValeur || number,
    id: result.id || number,
    isCE: result.isCE || bool,
    lat: result.lat || number,
    lienAnnonce: result.lienAnnonce || string,
    localisation: result.localisation
      ? villeAdapter(result.localisation)
      : villeAdapter(),
    long: result.long || number,
    nbChambres: result.nbChambres || number,
    nbLotsCopropriete: result.nbLotsCopropriete || number,
    nbPieces: result.nbPieces || number,
    nbSallesDeBain: result.nbSallesDeBains || number,
    nbSallesDeau: result.nbSallesDeau || number,
    nbWc: result.nbWc || number,
    neuf: result.neuf || bool,
    numMandat: result.numeroMandat || string,
    optionMandatEnum: result.typeMandatEnum || string,
    hasOffer: result.hasOffer || bool,
    photoCover: result.photoCover || string,
    avecAscenseur: result.avecAscenseur || bool,
    photoGuid: result.photosPubliees || array,
    avecStationnement: result.avecStationnement || bool,
    prestige: result.prestige || bool,
    dateDernierAvenant: "",
    prix: result.prix || number,
    dateEffectiveCompromis: "",
    surface: result.surface || number,
    dateEffectiveSignature: "",
    surfaceTerrain: result.surfaceTerrain || number,
    debutMandat: "",

    surfacesInterieures: {
      "Surface Carrez": result.surfaceCarrez || number,
      "Surface Séjour": result.surfaceSejour || number,
      "Surface habitable": result.surface || number,
    },
    etatGeneral: "",
    typeAchatEnum: result.typeAchatEnum || string,
    isTvb: bool,
    typeAffaireEnum: result.typeAffaireEnum || string,
    latitude: 0,
    underOfferToSell: result.underOfferToSell || bool,
    longitude: 0,
    loyerCC: 0,
    photoNeufGuid: "",
    prestations: {},
    prixInitial: 0,
    proximite: {},
    withSingleStorey: result.withSingleStorey,
    withPool: result.withPool,
    statutTransactionEnum: "",
    surfaceHabitable: 0,
    surfaceTotale: 0,
    typeBienNom: "",
    typeMandatEnum: "",
  };
}

export default obtenirParIdAdapter;
