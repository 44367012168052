import { Link, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { SvgIconComponent } from "@material-ui/icons";
import React, { SyntheticEvent } from "react";
import { ITheme } from "theme/ts/main";

const useStyles = makeStyles((theme: ITheme) => ({
  clickable: {
    color: theme.palette.tonique,
    display: "flex",
    fontSize: "16px",
    marginRight: "10px",
  },
  clickableText: {
    "&:hover": {
      color: theme.palette.tonique,
    },
    cursor: "pointer",
  },
}));

export interface LinkWithTooltipProps {
  tooltip: string;
  icon?: SvgIconComponent;
  content: JSX.Element | string;
  href: string;
  textSize?: "small" | "large";
}

export const LinkWithTooltip: React.FC<LinkWithTooltipProps> = ({
  tooltip,
  icon: Icon,
  content,
  href,
  textSize = "large",
}) => {
  const classes = useStyles();

  const linkContainerStyle: CSSProperties = {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  };

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexWrap: "nowrap",
      }}
    >
      <div style={{ width: "100%" }}>
        <Tooltip title={tooltip} placement="right">
          <Link
            href={href}
            target="_blank"
            rel="noreferrer"
            underline="none"
            onClick={(e: SyntheticEvent) => e.stopPropagation()}
          >
            <div style={linkContainerStyle}>
              {Icon ? (
                <Icon classes={{ root: classes.clickable }} />
              ) : (
                <span className={classes.clickable} />
              )}
              <Typography
                className={classes.clickableText}
                variant={textSize === "small" ? "body2" : "body1"}
                noWrap
              >
                {content as string}
              </Typography>
            </div>
          </Link>
        </Tooltip>
      </div>
    </div>
  );
};
