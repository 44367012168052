import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { DataProvider, DataProviderContext } from "components/DataProvider";
import Loading from "components/Loading/LoadingSkeleton";
import PaperX from "components/PaperX";
import TitrePage from "components/TitrePage";
import ReactEcharts from "echarts-for-react";
import React, { useContext } from "react";
import { useServices } from "services";
import { ErrorPanel } from "UI/shared/templates/Panels/ErrorPanel";
import Title from "../components/PaperTitle";
import Bubble from "./components/Bubble";
import MoodX from "./components/Mood";
import { getOptionRadar } from "./logic/chartsOptions";
import { itemsWithChart } from "./logic/itemsWithChart";

const useStyles = makeStyles((theme) => ({
  root: {
    // ...theme.mixins.gutters(),
  },
  top: {
    marginBottom: theme.spacing(3),
  },
}));

const Synthese = () => {
  const { endpoints } = useServices();

  return (
    <DataProvider url={endpoints.reporting.synthese}>
      <SyntheseComp />
    </DataProvider>
  );
};

export default Synthese;

const classementTitle = (t) => ({
  Departement: t("ClassementDepartemental"),
  National: t("ClassementNational"),
  Region: t("ClassementRegional"),
});

const getSubtitle = (subtitle, rank) => (rank ? subtitle : "Pas de données");

const getMood = (rang, nbConseillers) => {
  if (!rang) return <></>;

  const classement = (rang / nbConseillers) * 100;
  const min = 20;
  const max = 80;
  if (classement <= min) return <MoodX type={"good"} />;
  else if (classement >= max) return <MoodX type={"bad"} />;
  else return <MoodX type={"neutral"} />;
};

const SyntheseComp = (props) => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();
  const { providerData } = useContext(DataProviderContext);
  const theme = useTheme();
  /* const { showSnack, hideSnack } = useNotifier();

   if (providerData && providerData.error) {
        showSnack({
            message: `${t('NouvelleTentative')} ${providerData.error}`,
            variant: 'error',
            withLoader: true,
        });
    } else {
        hideSnack();
    }*/

  if (providerData && providerData.error)
    return (
      <ErrorPanel
        title={"Données non disponibles."}
        text={"Nous n'avons pas reçu les données statistiques."}
      />
    );

  const chartsStyle = { height: "400px", width: "100%" };
  const chartsStyleSmall = { height: "100px" };

  return (
    <div className={classes.root}>
      <TitrePage
        categorie="Reporting"
        page={t("Synthese")}
        filePath={"/static/markdowns/Reporting/reportingSynthese.md"}
      />
      {providerData && !providerData.error ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
            <PaperX style={{ height: "100%" }}>
              <Grid
                justify={"space-between"}
                container
                direction={"column"}
                spacing={3}
              >
                <Grid item xs={12}>
                  <Title
                    title={
                      providerData.stockEnCours[0] &&
                      providerData.stockEnCours[0].stockCACompromis
                        ? `${providerData.stockEnCours[0].stockCACompromis
                            .toString()
                            .replace(
                              /(\d)(?=(\d{3})+(?!\d))/g,
                              "$1 "
                            )} ${String.fromCharCode(0x20ac)}`
                        : `0 ${String.fromCharCode(0x20ac)}`
                    }
                    subtitle={t("StockCompromisEnCours")}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Title
                    title={
                      providerData.stockEnCours[0] &&
                      providerData.stockEnCours[0].nombreMandatsActifs
                        ? `${providerData.stockEnCours[0].nombreMandatsActifs}`
                        : `Inconnu`
                    }
                    subtitle={t("MandatsActifs")}
                  />
                </Grid>
              </Grid>
            </PaperX>
          </Grid>

          {itemsWithChart(providerData, t, theme).map((item, index) => {
            return (
              <Grid key={"withChart-" + index} item xs={12} md={3} sm={6}>
                <PaperX>
                  <Grid container direction={"column"} spacing={1}>
                    <Grid item xs={12}>
                      <Title
                        title={item.title
                          .toString()
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}
                        subtitle={item.subtitle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ReactEcharts
                        option={item.chartOptions}
                        style={chartsStyleSmall}
                      />
                    </Grid>
                  </Grid>
                </PaperX>
              </Grid>
            );
          })}
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              {providerData &&
                providerData.classement.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <PaperX>
                      <Grid container justify={"space-around"} spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant={"h5"}>
                            {classementTitle(t)[item.cible]} (
                            {item.nombreConseillers}{" "}
                            {(t("Conseiller") + "s").toLowerCase()})
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Bubble
                            number={item.classementCA}
                            subtitle={
                              <>
                                {getSubtitle(t("CaTTC"), item.classementCA)}
                                {getMood(
                                  item.classementCA,
                                  item.nombreConseillers
                                )}
                              </>
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Bubble
                            number={item.classementMandats}
                            subtitle={
                              <>
                                {getSubtitle(
                                  t("MandatsPris"),
                                  item.classementMandats
                                )}
                                {getMood(
                                  item.classementMandats,
                                  item.nombreConseillers
                                )}
                              </>
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Bubble
                            number={item.classementPourcentageExclu}
                            subtitle={
                              <>
                                {getSubtitle(
                                  t("PourcentageExclu").toLocaleLowerCase(),
                                  item.classementPourcentageExclu
                                )}
                                {getMood(
                                  item.classementPourcentageExclu,
                                  item.nombreConseillers
                                )}
                              </>
                            }
                          />
                        </Grid>
                      </Grid>
                    </PaperX>
                  </Grid>
                ))}
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <PaperX>
              <div>
                {props.errorRadarBiens ? (
                  <Typography variant="h3">Problème de chargement</Typography>
                ) : (
                  <>
                    <Typography variant="h5">Performance</Typography>
                    <ReactEcharts
                      option={getOptionRadar(t, theme, providerData)}
                      style={chartsStyle}
                    />
                  </>
                )}
              </div>
            </PaperX>
          </Grid>
        </Grid>
      ) : providerData.error ? (
        <div>
          Erreur de chargement des données ... veuillez réessayer plus tard
        </div>
      ) : (
        <Loader />
      )}{" "}
    </div>
  );
};

// const Loader = () => (
//   <Grid container spacing={1}>
//     <Grid item xs={12} lg={6}>
//       <Grid container spacing={1}>
//         <Grid item xs={12} md={3} sm={6} lg={6}>
//           <Loading />
//         </Grid>
//         <Grid item xs={12} md={3} sm={6} lg={6}>
//           <Loading />
//         </Grid>
//         <Grid item xs={12} md={3} sm={6} lg={6}>
//           <Loading />
//         </Grid>
//         <Grid item xs={12} md={3} sm={6} lg={6}>
//           <Loading />
//         </Grid>
//       </Grid>
//     </Grid>
//     <Grid item xs={12} lg={6}>
//       <Grid container spacing={1}>
//         <Grid item xs={12}>
//           <Loading />
//         </Grid>
//       </Grid>
//     </Grid>
//     <Grid item xs={12}>
//       <Loading />
//     </Grid>
//   </Grid>
// );

const Loader = () => (
  <Grid container spacing={5}>
    <Grid item xs={12} md={3} sm={6}>
      <Loading styles={{ height: 188 }} />
    </Grid>
    <Grid item xs={12} md={3} sm={6}>
      <Loading styles={{ height: 188 }} />
    </Grid>
    <Grid item xs={12} md={3} sm={6}>
      <Loading styles={{ height: 188 }} />
    </Grid>
    <Grid item xs={12} md={3} sm={6}>
      <Loading styles={{ height: 188 }} />
    </Grid>

    <Grid item xs={12} lg={6}>
      <Loading styles={{ height: 454 }} />
    </Grid>

    <Grid item xs={12} lg={6}>
      <Loading styles={{ height: 454 }} />
    </Grid>
  </Grid>
);
