import React from "react";
import PropType from "prop-types";
import Grid from "@material-ui/core/Grid";
import PaperKpi from "../../components/PaperKpi";

export default function Kpi({ kpi, t }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <PaperKpi
          title={t("CaActesHT")}
          n={kpi.caActesHT || 0}
          nMoins1={kpi.caActesHTN1 || 0}
          titleType={"money"}
          t={t}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PaperKpi
          title={t("CaActesTTC")}
          n={kpi.caActesTTC || 0}
          nMoins1={kpi.caActesTTCN1 || 0}
          titleType={"money"}
          t={t}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PaperKpi
          title={t("CaCompromisHT")}
          n={kpi.caCompromisHT || 0}
          nMoins1={kpi.caCompromisHTN1 || 0}
          titleType={"money"}
          t={t}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PaperKpi
          title={t("CaCompromisTTC")}
          n={kpi.caCompromisTTC || 0}
          nMoins1={kpi.caCompromisTTCN1 || 0}
          titleType={"money"}
          t={t}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PaperKpi
          title={t("NombreActes")}
          n={kpi.nombreActes || 0}
          nMoins1={kpi.nombreActesN1 || 0}
          t={t}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PaperKpi
          title={t("NombreCompromis")}
          n={kpi.nombreCompromis || 0}
          nMoins1={kpi.nombreCompromisN1 || 0}
          t={t}
        />
      </Grid>
      <Grid item xs={12}>
        <PaperKpi
          title={t("DelaiMoyenDeVente")}
          n={kpi.delaiVente || 0}
          nMoins1={kpi.delaiVenteN1 || 0}
          t={t}
        />
      </Grid>
    </Grid>
  );
}

Kpi.propTypes = {
  kpi: PropType.object,
};
