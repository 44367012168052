import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { NumberField } from "components/NumberField";
import React, { FC, useEffect, useState } from "react";

import useNotifier from "../../../../../../../../../components/Notifier/useNotifier";
import { useServices } from "../../../../../../../../../services";
import { useProfiles } from "../../../../../../../../../services/profiles";
import { AccountType } from "../../../../models/accountType";
import { ProfileReadDto } from "../../../../models/profilesReadDto";
import { ForRentHouseProfileReadDto } from "../../../../models/Proposal/ForRent/House/forRentHouseProfileReadDto";
import { ForRentHouseProfileWriteDto } from "../../../../models/Proposal/ForRent/House/forRentHouseProfileWriteDto";
import { SectionTitle } from "../../../components/SectionTitle";
import { SelectMaturity } from "../../../Maturity";
import { NotesInput } from "../../../Notes";
import { SelectFurniture } from "../../../Search/SelectFurniture";
import { Write } from "../../../Write";
import { SelectJoinOwnershipType } from "../../JointOwnership";
import { SaleAnnexes } from "../../SaleAnnexes";
import { SaleHouseEquipments } from "../../SaleHouseEquipments";
import { SaleParking } from "../../SaleParking";
import { SalePlaceInput } from "../../SalePlace";
import { SaleRoomsInput } from "../../SaleRooms";
import { SaleSizeInput } from "../../SaleSize";
import { SaleSummariesInput } from "../../SaleSummaries";
import { SelectExposureType } from "../../SelectExposure";
import { SelectGeneralStateType } from "../../SelectGeneralStateType";
import { RentBudgetHouseInput } from "./RentBudgetHouse";
import { useChanges } from "./useChanges";
import classes from "./Write.module.css";

export const HouseForRentProfileWrite: FC<{
  contactId: string;
  account: AccountType;
  initial?: ForRentHouseProfileReadDto;
  more: boolean;
  showMore: () => void;
  sendUpdatedProfile?: (profile: ProfileReadDto | undefined) => void;
  handleAfterUpdate?: () => void;
}> = ({
  contactId,
  account,
  initial,
  more,
  showMore,
  sendUpdatedProfile,
  handleAfterUpdate,
  children,
}) => {
  const { add, update } = useProfiles(contactId);
  const [payload, setPayload] = useState(create(account, initial));
  const [state, setState] = useState<"none" | "saving" | "saved" | "error">(
    "none"
  );
  const { modal } = useServices();
  const { showSnack } = useNotifier();
  const mode = initial ? "update" : "add";
  const [pristine, setPristine] = useState(true);
  const [isFormValid, setIsFormValid] = useState({
    address: false,
    annexes: true,
    financialInfo: false,
    parts: false,
    propertyInfo: false,
  });

  const disabled = state === "saving";
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    onAmenitiesChange,
    onAnnexesChange,
    onBudgetChange,
    onExposureChange,
    onFurnitureChange,
    onGeneralStateChange,
    onJoinOwnershipChange,
    onMaturityChange,
    onNotesChange,
    onParkingsChange,
    onPlaceChange,
    onSizeChange,
    onSummariesChange,
    onYearBuiltChange,
  } = useChanges(setPayload, setIsFormValid);

  useEffect(() => {
    switch (state) {
      case "none":
        break;
      case "saving":
        if (Object.entries(isFormValid).some(([_, b]) => !b)) {
          setPristine(false);
          setState("none");
        } else {
          (mode === "add"
            ? add("ForRentHouseProfile", payload)
            : update(
                "ForRentHouseProfile",
                initial!.id,
                payload,
                sendUpdatedProfile
              )
          )
            .then(() => {
              if (handleAfterUpdate) handleAfterUpdate();
              setState("saved");
            })
            .catch(() => setState("error"));
        }
        break;
      case "saved":
        modal.close();
        showSnack({ message: "Le profil est enregistré.", variant: "success" });
        break;
      case "error":
        showSnack({ message: "Une erreur est survenue.", variant: "danger" });
        break;
    }
  }, [state]);

  return (
    <Write
      pending={state === "saving"}
      save={() => setState("saving")}
      mode={mode}
      more={more}
      showMore={showMore}
    >
      <div className={more && !mediaQuery ? classes.container : ""}>
        <div className={more ? classes.itemA : ""}>
          {children}
          <Grid item xs={12}>
            <SelectFurniture
              value={payload.features.furniture}
              onChange={onFurnitureChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <SalePlaceInput
              value={payload.features?.address}
              onChange={onPlaceChange}
              disabled={disabled}
              pristine={pristine}
            />
          </Grid>
        </div>

        <div className={more ? classes.itemB : ""}>
          <Grid item xs={12}>
            <RentBudgetHouseInput
              pristine={pristine}
              more={more}
              value={payload.features.financialInfo}
              onChange={onBudgetChange}
            />
          </Grid>

          <SectionTitle title="Quelles sont les caractéristiques ?" />
          {more && (
            <>
              <Grid item xs={12}>
                <NumberField
                  label="Année de construction"
                  onChange={onYearBuiltChange}
                  value={payload.features.propertyInfo.buildingInfo.yearBuilt}
                  format={"####"}
                  adornement={""}
                />
              </Grid>
              <SelectGeneralStateType
                value={payload.features.propertyInfo.generalState}
                onChange={onGeneralStateChange}
              />
              <SelectExposureType
                value={payload.features.propertyInfo.exposure}
                onChange={onExposureChange}
              />
            </>
          )}
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <SaleSizeInput
                    pristine={pristine}
                    value={payload.features.propertyInfo}
                    onChange={onSizeChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SaleRoomsInput
                    pristine={pristine}
                    value={payload.features.parts.summaries}
                    onChange={onSummariesChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SaleSummariesInput
                pristine={pristine}
                value={payload.features.parts.summaries}
                more={more}
                onChange={onSummariesChange}
              />
            </Grid>
            {more && (
              <Grid item xs={12}>
                <SelectJoinOwnershipType
                  disabled={false}
                  value={payload.features.propertyInfo.joinOwnership}
                  onChange={onJoinOwnershipChange}
                />
              </Grid>
            )}
            {more && (
              <Grid item xs={12}>
                <SaleHouseEquipments
                  value={payload.features.amenities ?? []}
                  onChange={onAmenitiesChange}
                />
              </Grid>
            )}
          </Grid>
        </div>

        <div className={more ? classes.itemC : ""}>
          {more && (
            <>
              <SaleParking
                value={payload.features.parts.summaries.parkings}
                onChange={onParkingsChange}
              />
              <Grid item xs={12}>
                <SaleAnnexes
                  onChange={onAnnexesChange}
                  value={payload.features.parts.annexes || []}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <NotesInput value={payload.notes} onChange={onNotesChange} />
          </Grid>

          <Grid item xs={12}>
            <SelectMaturity
              onChange={onMaturityChange}
              value={payload.maturity}
              disabled={disabled}
            />
          </Grid>
        </div>
      </div>
    </Write>
  );
};

function create(
  account: AccountType,
  initial?: ForRentHouseProfileReadDto
): ForRentHouseProfileWriteDto {
  return {
    account,
    creationDate: new Date(),
    features: initial
      ? initial.features
      : {
          address: {},
          amenities: [],
          financialInfo: {},
          furniture: "All",
          parts: {
            annexes: [],
            summaries: {
              bathRooms: { count: 0 },
              bedRooms: { count: 0 },
              kitchen: {},
              levels: { count: 0 },
              livingRoom: { squareMeter: 0 },
              parkings: {
                hasBox: false,
                hasExteriorParking: false,
                hasInteriorParking: false,
              },
              rooms: { count: 0 },
              toilets: { count: 0 },
            },
          },
          propertyInfo: { buildingInfo: {} },
        },
    maturity: initial?.maturity || "None",
    notes: initial?.notes || "",
    origin: initial?.origin || "TXProfile",
    reference: initial?.reference || "",
  };
}
