import { Menu } from "@material-ui/core";
import React from "react";

import { StyledMenuItem } from "./StyledMenuItem";

export interface ActionsMenuProps {
  actions: JSX.Element[];
  anchor: (EventTarget & HTMLButtonElement) | null;
  onClose: () => void;
}

export const ActionsMenu: React.FC<ActionsMenuProps> = ({
  actions,
  anchor,
  onClose,
}) => {
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchor}
      keepMounted
      open={Boolean(anchor)}
      onClose={onClose}
    >
      {actions.map((action, index) => (
        <StyledMenuItem key={index} onClick={onClose}>
          {action}
        </StyledMenuItem>
      ))}
    </Menu>
  );
};
