import { MatchingProfileRequestDto } from "services/matching/MatchingProfileRequestDto";

import { PremisesFeaturesDto } from "../../../../models/Proposal/ForSale/Premises/premisesFeaturesDto";

export default (features: PremisesFeaturesDto): MatchingProfileRequestDto => {
  return new MatchingProfileRequestDto({
    budget: features.financialInfo.price,
    cityId: features.address.city?.id,
    constructionYear: undefined,
    nbRooms: undefined,
    profileKind: "Purchase",
    propertyKind: "Premises",
    surface: features.propertyInfo.totalArea,
    withElevator: undefined,
    withParking: features.parkingInfo.numberOfParking > 0,
    withPool: undefined,
  });
};
