import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React, { FC } from "react";

import { YesNo } from "../types";

interface CheckboxProps {
  value: YesNo;
  onChange: (refinement: YesNo) => void;
  label: string;
}

export const CheckboxRefinement: FC<CheckboxProps> = (props) => {
  const onChange = (event: any) => {
    props.onChange({
      kind: "checkbox",
      value: event.target.checked,
    });
  };

  const theme = useTheme();

  return (
    <FormControlLabel
      control={
        <Checkbox
          style={{ color: theme.palette.primary.main }}
          checked={!!props.value.value}
          onChange={onChange}
          inputProps={{ "aria-label": "checkbox" }}
        />
      }
      label={props.label}
    />
  );
};
