import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/Close";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import IconButton from "@material-ui/core/IconButton";
import PropType from "prop-types";

function useStyles(width) {
  return makeStyles((theme) => ({
    content: {
      flex: 1,
      flexGrow: 1,
      height: "calc(100% - 78px)",
      padding: "8px",
    },
    drawer: {
      flexShrink: 0,
      width,
    },
    drawerClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      }),
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    drawerOpen: {
      borderLeft: "5px solid " + theme.palette.tonique,
      transition: theme.transitions.create("width", {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.sharp,
      }),
      width,
    },
    fond: {
      background: "white",
    },
    listItem: {
      textDecoration: "none",
    },
    toolbar: {
      alignItems: "center",
      display: "flex",
      justifyContent: "flex-start",
    },
  }));
}

const ContextualSideBar = ({
  contextualOpen,
  setContextualOpen,
  width,
  withCloseBtn,
  titre = "",
  children,
}) => {
  let classes = useStyles(width)();
  return (
    <SwipeableDrawer
      anchor="right"
      open={contextualOpen}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: contextualOpen,
        [classes.drawerClose]: !contextualOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: contextualOpen,
          [classes.drawerClose]: !contextualOpen,
        }),
        paperAnchorRight: classes.fond,
      }}
      onClose={() => setContextualOpen(false)}
      onOpen={() => setContextualOpen(true)}
    >
      <div style={{ background: "white", height: "100%" }}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 8px 10px 0px",
          }}
        >
          <Typography variant="h2" style={{ paddingLeft: 16 }}>
            {titre}
          </Typography>
          {withCloseBtn && (
            <div className={classes.toolbar}>
              <IconButton
                className={classes.button}
                color="primary"
                aria-label="Close"
                onClick={() => setContextualOpen(false)}
              >
                <ChevronRightIcon className={classes.svgIcon} />
              </IconButton>
            </div>
          )}
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    </SwipeableDrawer>
  );
};

ContextualSideBar.propTypes = {
  contextualOpen: PropType.bool,
  setContextualOpen: PropType.func,
  width: PropType.string,
  withCloseBtn: PropType.bool,
};

ContextualSideBar.defaultProps = {
  withCloseBtn: true,
};

export default ContextualSideBar;
