import { Grid, makeStyles } from "@material-ui/core";
import { SvgIconComponent } from "@material-ui/icons";
import { LinkWithTooltip } from "UI/shared/molecules/LinkWithTooltip";
import React from "react";
import { ITheme } from "theme/ts/main";

const useStyles = makeStyles((theme: ITheme) => ({
  mainContainer: {
    alignItems: "center",
    display: "flex",
  },
}));

export interface LinksListProps {
  icon: SvgIconComponent;
  value: { href: string; content: string }[];
  tooltip: string;
}

export const LinksList: React.FC<LinksListProps> = (props) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      {props.value.map(({ href, content }, index) => (
        <Grid key={index} item xs={12} className={classes.mainContainer}>
          <LinkWithTooltip
            tooltip={props.tooltip}
            href={href}
            content={
              <span style={{ fontWeight: index === 0 ? "bold" : "normal" }}>
                {content}
              </span>
            }
            icon={index === 0 ? props.icon : undefined}
          />
        </Grid>
      ))}
    </Grid>
  );
};
