import { Grid } from "@material-ui/core";
import PanneauResultatsVides from "UI/shared/templates/Panels/NoItemsPanel";
import { HitsSkeleton } from "layoutContents/components/HitsContainer/HitsSkeleton";
import React from "react";

import CarteConseiller from "./components/CarteConseiller";

const RenderHit = ({
  items,
  displayMode,
  firstRef,
  triggerRef,
  loading,
  pageSize = 0,
  clearRefinements,
  setOpenDrawer,
}) => {
  const hitReduitGalerie = ({ item, ref = null }) => (
    <Grid key={item.id} item xs={12} sm={6} md={3} lg={2} ref={ref}>
      <CarteConseiller type={"galerie"} item={item} />
    </Grid>
  );

  const hitReduitMap = ({ item, ref = null }) => (
    <Grid key={item.id} item xs={6} md={12} lg={6} ref={ref}>
      <CarteConseiller type={"galerie"} item={item} />
    </Grid>
  );

  const hitListe = ({ item, ref = null }) => (
    <Grid item xs={12} key={item.id} ref={ref}>
      <CarteConseiller type={"liste"} item={item} />
    </Grid>
  );

  let _items = items;
  if (JSON.stringify(items) === JSON.stringify({})) _items = { data: [] };

  if (_items.data.length < 1 && !loading)
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-around",
          marginTop: "25px",
        }}
      >
        <PanneauResultatsVides
          clearRefinements={clearRefinements}
          setOpenDrawer={setOpenDrawer}
        />
      </div>
    );

  if (displayMode === "map")
    return modeAAfficher({
      _items,
      component: hitReduitMap,
      displayMode,
      firstRef,
      loading,
      pageSize,
      triggerRef,
    });

  if (displayMode === "gallery")
    return modeAAfficher({
      _items,
      component: hitReduitGalerie,
      displayMode,
      firstRef,
      loading,
      pageSize,
      triggerRef,
    });

  return modeAAfficher({
    _items,
    component: hitListe,
    displayMode,
    firstRef,
    loading,
    pageSize,
    triggerRef,
  });
};

export default RenderHit;

const modeAAfficher = ({
  component,
  _items,
  pageSize,
  triggerRef,
  firstRef,
  displayMode,
  loading,
}) => (
  <>
    {_items.data.map((item, index) => {
      if (_items.data.length - Math.ceil(pageSize / 2) === index + 1)
        return component({ item, ref: triggerRef });
      if (index === 0) return component({ item, ref: firstRef });
      return component({ item });
    })}
    {loading && HitsSkeleton(1, displayMode)}
  </>
);
