import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  DataProvider,
  DataProviderContext,
  REFINEMENT_TYPE,
} from "components/DataProvider";
import Loading from "components/Loading/LoadingSkeleton";
import TitrePage from "components/TitrePage";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useServices } from "services";
import { ErrorPanel } from "UI/shared/templates/Panels/ErrorPanel";
import ListeConseillers from "./components/ListeConseillers";
import TremplinFourchetteDate from "./components/TremplinFourchetteDate";

const useStyles = makeStyles(() => ({
  root: {},
  textField: {
    // marginLeft: theme.spacing(2),
    // marginRight: theme.spacing(2),
    // width: "80%"
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const Classements = () => {
  const refinements = {
    dateEvt: {
      count: 0,
      labelLimiteInf: "dateDebut",
      labelLimiteSup: "dateFin",
      limiteInf: moment().startOf("year").format("YYYY-MM-DD"),
      limiteSup: moment().format("YYYY-MM-DD"),
      query: "periodeClassement",
      type: REFINEMENT_TYPE.range.date,
    },
  };

  const { endpoints } = useServices();

  return (
    <DataProvider
      refinements={JSON.stringify(refinements)}
      url={endpoints.reporting.classement}
    >
      <ClassementsComp />
    </DataProvider>
  );
};

export default Classements;

const ClassementsComp = () => {
  const classes = useStyles();
  const {
    l10n: { t },
    authenticationService,
  } = useServices();
  const userData = authenticationService.getUserData();

  const { providerData, setCurrentRefinements } = useContext(
    DataProviderContext
  );
  const [domaine, setDomaine] = useState("Actes");
  const [periode, setPeriode] = useState("Annuel");
  const [fourchetteDate, setFourchetteDate] = useState({
    dateDebut: moment().startOf("year"),
    dateFin: moment(),
  });
  const [listePresentation, setListePresentation] = useState(null);

  const handleChangeDomaine = (event) => {
    setDomaine(event.target.value);
    switch (event.target.value) {
      case "Actes":
        setListePresentation(providerData.acte);
        break;
      case "Compromis":
        setListePresentation(providerData.compromis);
        break;
      default:
        setListePresentation(providerData.mandat);
    }
  };

  const handleChangePeriode = (event) => {
    setPeriode(event.target.value);
    switch (event.target.value) {
      case "Annuel":
        setFourchetteDate({
          dateDebut: moment().startOf("year"),
          dateFin: moment(),
        });
        break;
      case "Mensuel":
        setFourchetteDate({
          dateDebut: moment().startOf("month"),
          dateFin: moment(),
        });
        break;
      default:
    }
  };

  const handleChangeCurrentRefinement = (newRefinement) => {
    setListePresentation(null);
    let periode = "Libre";
    if (
      JSON.stringify(newRefinement) ===
      JSON.stringify({
        dateDebut: moment().startOf("year"),
        dateFin: moment(),
      })
    )
      periode = "Annuel";
    if (
      JSON.stringify(newRefinement) ===
      JSON.stringify({
        dateDebut: moment().startOf("month"),
        dateFin: moment(),
      })
    )
      periode = "Mensuel";
    setPeriode(periode);
    setFourchetteDate(newRefinement);
  };

  useEffect(() => {
    if (providerData) {
      switch (domaine) {
        case "Actes":
          setListePresentation(providerData.acte);
          break;
        case "Compromis":
          setListePresentation(providerData.compromis);
          break;
        default:
          setListePresentation(providerData.mandat);
      }
    }
  }, [providerData]);

  useEffect(() => {
    const refinements = {
      dateEvt: {
        count: 0,
        labelLimiteInf: "dateDebut",
        labelLimiteSup: "dateFin",
        limiteInf: fourchetteDate.dateDebut,
        limiteSup: fourchetteDate.dateFin,
        query: "periodeClassement",
        type: REFINEMENT_TYPE.range.date,
      },
    };
    setCurrentRefinements(refinements);
  }, [JSON.stringify(fourchetteDate)]);

  if (providerData && providerData.error)
    return (
      <ErrorPanel
        title={"Données non disponibles."}
        text={"Nous n'avons pas reçu les données statistiques."}
      />
    );

  return (
    <div className={classes.root}>
      <TitrePage
        categorie="Reporting"
        page={t("Classements")}
        nbResultats=""
        filePath={"/static/markdowns/Reporting/reportingClassement.md"}
        Composant={() => (
          <TremplinFourchetteDate
            setFourchette={handleChangeCurrentRefinement}
            fourchette={fourchetteDate}
          />
        )}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            select
            id="domaine"
            label={t("FiltrerPar")}
            className={classes.textField}
            value={domaine}
            onChange={handleChangeDomaine}
            variant="outlined"
          >
            <MenuItem value="Actes">{t("Actes")}</MenuItem>
            <MenuItem value="Compromis">{t("Compromis")}</MenuItem>
            <MenuItem value="Mandats">{t("Mandats")}</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            select
            id="domaine"
            label={t("FiltrerParPeriode")}
            className={classes.textField}
            value={periode}
            onChange={handleChangePeriode}
            variant="outlined"
          >
            <MenuItem value="Annuel">{t("AnneeEnCours")}</MenuItem>
            <MenuItem value="Mensuel">{t("MoisEnCours")}</MenuItem>
            <MenuItem value="Libre">{t("DatesLibre")}</MenuItem>
          </TextField>
        </Grid>
        <Grid container spacing={2} alignContent="center">
          <Grid item sm={4} xs={12}>
            {!listePresentation && <Loading styles={{ height: 800 }} />}
            {listePresentation && (
              <ListeConseillers
                titre={t("Top50National")}
                liste={
                  listePresentation.find((elt) => elt.cible === "National")
                    ?.classement || []
                }
                doc={domaine}
              />
            )}
          </Grid>
          {userData.Instance !== "Refleximmo" && (
            <Grid item sm={4} xs={12}>
              {!listePresentation && <Loading styles={{ height: 800 }} />}
              {listePresentation && (
                <ListeConseillers
                  titre={t("Top20Regional")}
                  liste={
                    listePresentation.find((elt) => elt.cible === "Region")
                      ?.classement || []
                  }
                  doc={domaine}
                />
              )}
            </Grid>
          )}
          <Grid item sm={4} xs={12}>
            {!listePresentation && <Loading styles={{ height: 800 }} />}
            {listePresentation && (
              <ListeConseillers
                titre={t("Top5Departemental")}
                liste={
                  listePresentation.find((elt) => elt.cible === "Departement")
                    ?.classement || []
                }
                doc={domaine}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
