import React from "react";
import { useTheme, Typography } from "@material-ui/core";

const NombreDeResultats = ({
  nombreDeResultats,
  labelPluriel,
  labelSingulier,
}) => {
  const theme = useTheme();
  return (
    <Typography
      variant={"subtitle1"}
      style={{
        color: theme.palette.common.black,
        textAlign: "center",
      }}
    >
      <span style={{ fontWeight: "bold" }}>{`${
        !isNaN(nombreDeResultats) ? nombreDeResultats : ""
      } ${
        !isNaN(nombreDeResultats)
          ? nombreDeResultats > 1
            ? labelPluriel
            : labelSingulier
          : ""
      }`}</span>{" "}
      {!isNaN(nombreDeResultats)
        ? nombreDeResultats > 1
          ? "correspondent à vos critères"
          : "correspond à vos critères"
        : "chargement..."}
    </Typography>
  );
};

export default NombreDeResultats;
