import Grid from "@material-ui/core/Grid";
import PaginationX from "components/PaginationX";
import React from "react";
import { BuyerMatchingProfilePresenter } from "services/matching/BuyerMatchingProfilePresenter";
import { breakpoint } from "theme/ts/main";
import {
  AgentMatchingAcquirer,
  AgentMatchingAcquirerProps,
} from "UI/features/profiles/molecules/MatchingAcquirer/Agent";
import { AgentAcquirers } from "UI/features/profiles/types";

const toAgentMatchingAcquirerProps: (
  acquirer: BuyerMatchingProfilePresenter
) => AgentMatchingAcquirerProps = (acquirer) => ({
  phoneNumber: acquirer.contactTelephone || "",
  mail: acquirer.contactEmail || "",
  name: acquirer.contactLastName || "",
  firstName: acquirer.contactFirstName || "",
  civility: acquirer.contactGender || "",
  opportunityId: acquirer.profileReference || "",
  contactUniqueId: acquirer.contactId || "",
  lastModificationDate: acquirer.lastModificationDate || "",
});

export const AgentMatchingAcquirers: React.FC<AgentAcquirers> = ({
  acquirers,
  currentPage,
  handlePage,
  pages,
}) => {
  const maxOnScreen = () => {
    const { xs, smAndDown } = breakpoint();
    switch (true) {
      case xs:
        return 1;
      case smAndDown:
        return 5;
      default:
        return 7;
    }
  };

  return (
    <Grid container spacing={2}>
      {acquirers.map((acquirer) => (
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          key={`agent-acquirer-${acquirer.profileReference}`}
        >
          <AgentMatchingAcquirer {...toAgentMatchingAcquirerProps(acquirer)} />
        </Grid>
      ))}

      <Grid item xs={12}>
        <div style={{ marginTop: 20 }}>
          <PaginationX
            page={currentPage}
            nbPages={pages}
            onChange={handlePage}
            maxOnScreen={maxOnScreen()}
          />
        </div>
      </Grid>
    </Grid>
  );
};
