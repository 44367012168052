import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "unset",
    width: "100%",
  },
}));

const LargePaperContainer: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Paper elevation={1} className={classes.root}>
      {children}
    </Paper>
  );
};

export default LargePaperContainer;
