import biensVendusGridOuListe from "mockups/biens/biensVendusListe_occitanie.json";
import useHTTPService from "services/dataSources/communicationServices/useHTTPService";
import useTestService from "services/dataSources/testServices/useTestService";
import rechercheGlobaleAdapter from "services/rapprochement/Rapprochement/BiensVendus/RechercheGlobale/rechercheGlobaleAdapter";

import { useServices } from "../index";

export const useBiensVendusRechercheGlobaleAPI = ({
  params,
  erreur = false,
}) => {
  const { endpoints } = useServices();
  const { status, adaptedData } = useHTTPService({
    adapter: rechercheGlobaleAdapter,
    endpoint: endpoints.biensVendus.rechercheGlobale,
    params,
  });
  return { items: adaptedData, status: status };
};

export const useBiensVendusRechercheGlobaleTest = ({
  params,
  erreur = false,
}) => {
  const { status, adaptedData } = useTestService({
    adapter: rechercheGlobaleAdapter,
    erreur,
    mockup: biensVendusGridOuListe,
    params,
  });
  return { items: adaptedData, status: status };
};

export const useBiensVendusRechercheGlobale = window.testTX
  ? useBiensVendusRechercheGlobaleTest
  : useBiensVendusRechercheGlobaleAPI;
