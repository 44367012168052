import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import capi from "assets/images/logos/logo_capifrance.png";
import oh from "assets/images/logos/logo_optimhome.png";
import reflex from "assets/images/logos/logo_refleximmo.svg";
import PrintablePage from "components/PrintablePage";
import moment from "moment";
import React, { Ref } from "react";
import { toFullName } from "services/portfolio/helpers";
import { ReadContactDto } from "services/portfolio/types";
import { Agent } from "types";

import { PrintableAgentDetails } from "../../../../../../layoutContents/Fiches/components/PrintableAgentDetails/PrintableAgentDetails";
import { useServices } from "../../../../../../services";
import { PrintableContactCard } from "./components/PrintableContactCard/PrintableContactCard";
import { PrintableContactNotes } from "./components/PrintableContactNotes/PrintableContactNotes";
import { PrintableIdentity } from "./components/PrintableIdentity/PrintableIdentity";
import { PrintablePropertySearchProfiles } from "./components/PrintablePropertySearchProfiles/PrintablePropertySearchProfiles";

interface PrintableContactSheetProps {
  contact: ReadContactDto;
  componentToPrintRef: Ref<HTMLDivElement>;
  titre: JSX.Element;
  numberOfSearchProfiles: number;
}

export const PrintableContactSheet = ({
  contact,
  componentToPrintRef,
  titre,
  numberOfSearchProfiles,
}: PrintableContactSheetProps): JSX.Element => {
  const { authenticationService } = useServices();

  const userData = authenticationService.getUserData();

  const conseillerPhotoGUID = (userData.picture as string).split("/").pop();
  const conseiller: Partial<Agent> = {
    bu: userData.Instance as string,
    email: userData.email as string,
    estActif: true as boolean,
    nom: userData.family_name as string,
    photo: conseillerPhotoGUID as string,
    prenom: userData.given_name as string,
    telephone: userData.phone_number as string,
  };

  let logo;
  switch (conseiller.bu) {
    case "Optimhome":
      logo = oh;
      break;
    case "Refleximmo":
      logo = reflex;
      break;
    default:
      logo = capi;
  }

  const header = (
    <div className={"divHeader"}>
      <img src={logo} width={"auto"} height={50} alt={"logo"} />
    </div>
  );

  const footer = (
    <Typography>
      <span style={{ fontWeight: "bold" }}>
        TremplinX - CRM - Contact - {toFullName(contact.identity)}
      </span>{" "}
      - {moment().format("DD-MM-YYYY HH:mm:ss")}
    </Typography>
  );

  return (
    <div style={{ display: "none" }}>
      <div ref={componentToPrintRef}>
        <PrintablePage header={header} footer={footer}>
          {titre}
          <PrintableContactCard
            contact={contact}
            numberOfSearchProfiles={numberOfSearchProfiles}
          />
          <div style={{ height: "25px" }} />
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item xs={6}>
              <PrintableIdentity contact={contact} />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <PrintableAgentDetails
                  photo={conseiller.photo}
                  telephone={conseiller.telephone}
                  email={conseiller.email}
                  estActif={conseiller.estActif}
                  bu={conseiller.bu}
                  nom={conseiller.nom}
                  prenom={conseiller.prenom}
                />
                {contact.notes && (
                  <PrintableContactNotes notes={contact.notes} />
                )}
              </div>
            </Grid>
          </Grid>
        </PrintablePage>
        <PrintablePropertySearchProfiles
          contactId={contact.id}
          header={header}
          footer={footer}
        />
      </div>
    </div>
  );
};
