import { useTxHttp } from "../http";
import { useServices } from "../index";

export interface EmailSupportDto {
  objet: string;
  message: string;
  captureEcran: string;
}

export const useSendEmailToSupport = (): {
  send: (emailSupport: EmailSupportDto) => Promise<boolean>;
} => {
  const { post } = useTxHttp();
  const { endpoints } = useServices();

  const send = async (emailSupport: EmailSupportDto) => {
    await post<{ result: string | null; success: boolean }>(
      endpoints.office365.sendEmailToSupport,
      {
        content: emailSupport,
        contentType: "application/json",
      }
    );
    return true;
  };

  return {
    send,
  };
};
