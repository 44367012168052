import React from "react";
import PropType from "prop-types";
import { makeStyles } from "@material-ui/core";
import { Mood, MoodBad, SentimentSatisfied } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  moodBad: {
    fill: theme.palette.error.main,
  },
  moodGood: {
    fill: theme.palette.success.main,
  },
  moodNeutral: {
    fill: theme.palette.warning.main,
  },
}));

function MoodX({ type }) {
  const classes = useStyles();
  if (type === "good") return <Mood className={classes.moodGood} />;
  else if (type === "bad") return <MoodBad className={classes.moodBad} />;
  return <SentimentSatisfied className={classes.moodNeutral} />;
}

export default MoodX;

MoodX.propTypes = {
  type: PropType.oneOf(["good", "bad", "neutral"]).isRequired,
};
