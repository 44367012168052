import React from "react";
import { ElasticAdapter } from "../../../../api/DTOs/Common/ElasticSearch/ElasticAdapters";
import { TitleWithNumberBadge } from "../../../../UI/shared/atoms/Badges/TitleWithNumberBadge";
import { NetworkMatchingProfilePresenter } from "../../../../services/matching/NetworkMatchingProfilePresenter";
import { BuyerMatchingProfilePresenter } from "../../../../services/matching/BuyerMatchingProfilePresenter";
import Buyers from "../../biens/mon-bien-en-vente/components/Buyers";

export const getBuyersTab = (
  consultantBuyers: ElasticAdapter<BuyerMatchingProfilePresenter>,
  networkBuyers: ElasticAdapter<NetworkMatchingProfilePresenter>,
  handleConsultantBuyersChange: (pageRank: number) => Promise<void>,
  handleNetworkBuyersChange: (pageRank: number) => Promise<void>,
  t: (value: string) => string
) => ({
  content: (
    <Buyers
      key={1}
      consultantBuyers={consultantBuyers}
      networkBuyers={networkBuyers}
      handleConsultantBuyersChange={handleConsultantBuyersChange}
      handleNetworkBuyersChange={handleNetworkBuyersChange}
    />
  ),
  titre: (
    <TitleWithNumberBadge
      number={consultantBuyers.total + networkBuyers.total}
      title={t("Rapprochements")}
    />
  ),
  value: "matching",
});
