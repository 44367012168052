import { Button, makeStyles } from "@material-ui/core";
import Modal from "@material-ui/core/Modal/Modal";
import { DescriptionOutlined } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import { RealEstateMatchingDto } from "services/profiles/dto/RealEstateMatchingDto";
import { useAgentRealEstatesMatching } from "services/profiles/realEstatesMatching/agent";
import { useNetworkRealEstatesMatching } from "services/profiles/realEstatesMatching/network";
import {
  RealEstateSharingStep,
  useRealEstatesSharing,
} from "services/profiles/realEstatesSharing";
import { ITheme } from "theme/ts/main";
import { defaultSharingRealEstatesProps } from "UI/features/profiles/templates/realEstates/defaultValues";
import { SharingRealEstatesProps } from "UI/features/profiles/templates/realEstates/types";
import { ButtonWithStartIcon } from "UI/shared/atoms/buttons/ButtonWithStartIcon";
import { SelectionStep } from "./SelectionStep";

const useStyles = makeStyles((theme: ITheme) => ({
  main: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  modalContainer: {
    width: "85%",
    height: "90%",
    overflowY: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 5,
  },
}));

const MODAL_CONTAINER_ID = "real-estates-sharing-modal";

export const SharingRealEstatesModalContext = React.createContext<{
  byNetwork: SharingRealEstatesProps;
  byAgent: SharingRealEstatesProps;
  selectionTotal: number;
  handleStepChange: (step: RealEstateSharingStep) => void;
  closeModal: () => void;
}>({
  byAgent: defaultSharingRealEstatesProps,
  byNetwork: defaultSharingRealEstatesProps,
  selectionTotal: 0,
  handleStepChange: (step) => {
    return;
  },
  closeModal: () => {
    return;
  },
});

export const RealEstatesSharingModal: React.FC<{
  profileId: string;
  matching: RealEstateMatchingDto;
  loading: boolean;
}> = ({ matching, loading, profileId }) => {
  const [isOpened, setIsOpened] = useState(false);

  const closeModal = () => setIsOpened(false);
  const openModal = () => setIsOpened(true);

  const {
    step,
    handleCheck,
    handleMultipleCheck,
    handleStepChange,
    isChecked,
    isShared,
    selectionTotal,
    getSharedRealEstates,
  } = useRealEstatesSharing(profileId);

  const agentMatching = useAgentRealEstatesMatching({ mode: "list" });
  const networkMatching = useNetworkRealEstatesMatching({ mode: "list" });
  const classes = useStyles();

  useEffect(() => {
    agentMatching.updateParams(matching);
    networkMatching.updateParams(matching);
  }, [matching]);

  useEffect(() => {
    if (agentMatching.lastIds.length === 0) return;
    getSharedRealEstates(agentMatching.page, agentMatching.lastIds);
  }, [agentMatching.lastIds]);

  useEffect(() => {
    if (networkMatching.lastIds.length === 0) return;
    getSharedRealEstates(networkMatching.page, networkMatching.lastIds);
  }, [networkMatching.lastIds]);

  const sharingRealEstatesProps = useMemo(
    () => ({
      byAgent: {
        ...agentMatching,
        open: (id: string) => {
          return;
        },
        loading,
        handleCheck: handleCheck("agent"),
        isChecked: isChecked("agent"),
        isShared,
        handleMultipleCheck: handleMultipleCheck("agent"),
        modalContainerId: MODAL_CONTAINER_ID,
      },
      byNetwork: {
        ...networkMatching,
        open: (id: string) => {
          return;
        },
        loading,
        handleCheck: handleCheck("network"),
        isChecked: isChecked("network"),
        isShared,
        handleMultipleCheck: handleMultipleCheck("network"),
        modalContainerId: MODAL_CONTAINER_ID,
      },
    }),
    [agentMatching, networkMatching]
  );

  return (
    <div>
      <ButtonWithStartIcon
        onClick={openModal}
        noUppercaseLabel={true}
        label={"Fiches commerciales"}
        icon={<DescriptionOutlined />}
        bold={false}
      />
      <Modal open={isOpened} onClose={closeModal} className={classes.main}>
        <div className={classes.modalContainer} id={MODAL_CONTAINER_ID}>
          <SharingRealEstatesModalContext.Provider
            value={{
              ...sharingRealEstatesProps,
              closeModal,
              handleStepChange,
              selectionTotal,
            }}
          >
            {step === "selection" && <SelectionStep />}
            {step === "mail" && (
              <>
                <p>Mails step coming soon...</p>
                <Button onClick={() => handleStepChange("selection")}>
                  Annuler
                </Button>
              </>
            )}
          </SharingRealEstatesModalContext.Provider>
        </div>
      </Modal>
    </div>
  );
};
