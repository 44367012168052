import { Card, CardHeader } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import React from "react";
import { EmailLink } from "UI/shared/molecules/EmailLink";
import { PhoneLink } from "UI/shared/molecules/PhoneLink";
import { ContactAvatar } from "../../molecules/ContactAvatar";
import { ContactFullName } from "../../molecules/ContactFullName";
import { ProfileContactProps } from "../ProfileContact";

export interface ProfileContactCardProps {
  contact: ProfileContactProps;
}

const useStyles = makeStyles(() => ({
  card: {
    paddingBottom: 0,
  },
  cardContent: {
    "&:last-child": {
      paddingBottom: 8,
    },
    padding: 0,
    paddingLeft: 16,
    paddingRight: 0,
  },
  cardHeaderContent: {
    height: "90px",
    overflowX: "hidden",
    textOverflow: "ellipsis",
  },
  root: {
    "&:hover": {
      boxShadow:
        "0px 3px 3px 0px rgba(0,0,0,0.34), 0px 1px 1px -1px rgba(0,0,0,0.1)",
    },
    position: "relative",
  },
}));

export const ProfileContactCard: React.FC<ProfileContactCardProps> = ({
  contact,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.root} style={{ zIndex: 0 }}>
      <CardHeader
        avatar={
          <div>
            <ContactAvatar
              firstName={contact.firstName}
              lastName={contact.lastName}
            />
          </div>
        }
        title={
          <div
            style={{
              cursor: "pointer",
              height: "100%",
              position: "relative",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <ContactFullName
                gender={contact.gender}
                firstName={contact.firstName}
                lastName={contact.lastName}
              />
            </div>

            <PhoneLink
              value={
                parsePhoneNumberFromString(
                  contact.mainPhoneNumber || ""
                )?.formatInternational() || ""
              }
            />
            <EmailLink value={contact.mainEmailAddress || ""} />
          </div>
        }
        titleTypographyProps={{ noWrap: true, variant: "h4" }}
        className={classes.card}
        classes={{
          content: classes.cardHeaderContent,
        }}
      />
    </Card>
  );
};
