import {
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HoverLessMenuItem } from "UI/shared/atoms/menu/HoverLessMenuItem";
import { MenuOption } from "UI/shared/atoms/menu/MenuOption";
import { MenuWithMoreIconButton } from "UI/shared/molecules/MenuWithMoreIconButton";
import React from "react";

const useStyles = makeStyles(() => ({
  inlinediv: {
    alignItems: "center",
    display: "inline-flex",
  },
}));

export interface ActionMenuItemProps {
  icon: React.ReactNode;
  label: string;
  action: () => void;
}

export interface ActionsMenuProps {
  menuOptions: ActionMenuItemProps[];
  minToCollapse?: number;
}

export const ActionsMenu: React.FC<React.PropsWithChildren<
  ActionsMenuProps
>> = ({ menuOptions, children, minToCollapse = 1 }) => {
  const responsiveMobile = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();

  const handleClickByAction: (
    action: () => void
  ) => React.MouseEventHandler<HTMLDivElement> = (action) => (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    action();
  };

  return (
    <div>
      {!(children && (children as any).length > 0) ? (
        <div>
          {minToCollapse > 0 && menuOptions.length === minToCollapse ? (
            <div>
              {responsiveMobile ? (
                <Tooltip title={menuOptions[0].label} placement="top">
                  <IconButton onClick={menuOptions[0].action}>
                    {menuOptions[0].icon}
                  </IconButton>
                </Tooltip>
              ) : (
                <div
                  className={classes.inlinediv}
                  onClick={handleClickByAction(menuOptions[0].action)}
                >
                  <IconButton>{menuOptions[0].icon}</IconButton>
                  <Typography variant="body1">
                    {menuOptions[0].label}
                  </Typography>
                </div>
              )}
            </div>
          ) : (
            <MenuWithMoreIconButton>
              {menuOptions.map((menuOption) => (
                <HoverLessMenuItem
                  key={menuOption.label}
                  onClick={handleClickByAction(menuOption.action)}
                >
                  <MenuOption icon={menuOption.icon} label={menuOption.label} />
                </HoverLessMenuItem>
              ))}
            </MenuWithMoreIconButton>
          )}
        </div>
      ) : (
        <MenuWithMoreIconButton>
          {(children as any).map((child: any, index: number) => (
            <HoverLessMenuItem key={index}>{child}</HoverLessMenuItem>
          ))}
        </MenuWithMoreIconButton>
      )}
    </div>
  );
};
