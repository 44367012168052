import { Refinement, Refinements } from "components/RefinementField/types";
import React, { FC } from "react";
import { DisplayMode } from "routes/app/real-estates/containers/network/types";
import SearchBar from "UI/shared/organisms/searchbar/SearchBar";
import { MoreFilters } from "../MoreFilters";
import { RealEstatesCriteria } from "../SearchInput";
import styles from "./Searchbar.module.css";
import { useAdaptedRefinements } from "./useAdaptedRefinements";

export interface SearchbarProps {
  refinements: Refinements<RealEstatesCriteria>;
  onChange: (value: { key: RealEstatesCriteria; value: Refinement }) => void;
  className?: string;
  total?: number;
  displayMode: DisplayMode;
}

export const Searchbar: FC<SearchbarProps> = ({
  refinements,
  onChange,
  className,
  total,
  displayMode,
}) => {
  const adaptedRefinements = useAdaptedRefinements(refinements, displayMode);

  return (
    <div className={className}>
      <div className={styles.searchbar}>
        <div className={styles.searchbarItemFull}>
          <SearchBar
            entityName={"RealEstates"}
            adaptedRefinements={adaptedRefinements}
            displayMode={"list"}
            onChange={onChange}
          />
        </div>
        <div className={styles.searchbarItemSmall}>
          <MoreFilters
            total={total || 0}
            refinements={refinements}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};
