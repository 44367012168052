import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { Text14pxBold } from "UI/shared/atoms/typographies/Text14pxBold";
import React from "react";
import { useServices } from "services";

interface ConfirmationDialogProps {
  text: string;
  handleConfirmation: () => void;
  handleCancel: () => void;
  openDialog: boolean;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  text,
  openDialog,
  handleCancel,
  handleConfirmation,
}) => {
  const {
    l10n: { t },
  } = useServices();
  return (
    <Dialog
      open={openDialog}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Text14pxBold>{text}</Text14pxBold>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e: any) => {
            handleCancel();
            e.stopPropagation();
          }}
          color="primary"
        >
          {`${t("Annuler")} `}
        </Button>
        <Button
          onClick={(e: any) => {
            handleConfirmation();
            e.stopPropagation();
          }}
          variant="contained"
          color="primary"
        >
          {`${t("Confirmer")} `}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
