import { ContactPhone } from "services/portfolio/types";
import { usePortfolioSearch } from "services/portfolio/usePortfolioSearch";

import { useContactForm } from "../context/ContactFormContext";

export const useContactPhones = () => {
  const {
    errors,
    isDisabled,
    setIsDisabled,
    setPhoneNumberExistences,
    contactDto,
    setContactDto,
  } = useContactForm();
  const { getContactIdByPhoneNumber } = usePortfolioSearch();

  const { phones } = contactDto.identity;

  const onPhonesChange = (phones: ContactPhone[]) => {
    setContactDto({
      ...contactDto,
      identity: { ...contactDto.identity, phones },
    });
  };

  const onPhoneChange = (index: number) => ({
    target: { name, value },
  }: {
    target: { name: string; value: string };
  }) => {
    const newPhones = [...contactDto.identity.phones];
    newPhones[index] = { ...newPhones[index], [name]: value };

    setIsDisabled(false);
    setPhoneNumberExistences((prv) => {
      const next = [...prv];
      next[index] = "";
      return next;
    });

    setContactDto({
      ...contactDto,
      identity: { ...contactDto.identity, phones: newPhones },
    });
  };

  const validatePhoneNumberExistence = (index: number) => async () => {
    if (errors.phones[index] || contactDto.identity.phones.length === 0) return;
    const phoneNumber = contactDto.identity.phones[index].number;

    if (!phoneNumber) return;

    const existingPhoneContactId = await getContactIdByPhoneNumber(
      contactDto.id,
      phoneNumber
    );

    if (existingPhoneContactId) {
      setIsDisabled(true);
    }

    setPhoneNumberExistences((prv) => {
      const next = [...prv];
      next[index] = existingPhoneContactId || "";
      return next;
    });
  };

  return {
    errors,
    isDisabled,
    onPhoneChange,
    onPhonesChange,
    phones,
    validatePhoneNumberExistence,
  };
};
