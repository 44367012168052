import { makeStyles } from "@material-ui/styles";
import React from "react";

import {
  CampaignState,
  CampaignType,
} from "../../../../../services/campagnes/types";
import { ITheme } from "../../../../../theme/ts/main";
import { CampaignCreationDate } from "../../atoms/CampaignCreationDate";
import { CampaignStatus } from "../../atoms/CampaignStatus";

const useStyles = makeStyles((theme: ITheme) => ({
  container: {
    backgroundColor: theme.palette.greyLight,
    borderRadius: "5px",
    padding: "8px",
  },
}));
export const CampaignStatusPanel: React.FC<{
  status: CampaignState;
  type: CampaignType;
  creationDate: Date;
}> = ({ status, type, creationDate }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CampaignStatus status={status} type={type} />
      <CampaignCreationDate creationDate={creationDate} />
    </div>
  );
};
