import { MenuItem } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { FC } from "react";

import { GridContainer } from "../../../../../../components/containers/GridContainer";
import { TXSelectMono } from "../../../../../../components/formControls/TXSelectMono";
import { TXTextField } from "../../../../../../components/formControls/TXTextField";
import { useServices } from "../../../../../../services";
import { useContactForm } from "../context/ContactFormContext";
import { SectionTitle } from "./Sections";

export const Identity: FC = () => {
  const {
    l10n: { t },
  } = useServices();
  const { isDisabled, setContactDto, contactDto } = useContactForm();

  const onChange = ({
    target: { name, value },
  }: {
    target: { name: string; value: string };
  }) => {
    setContactDto({
      ...contactDto,
      identity: { ...contactDto.identity, [name]: value.trimStart().trimEnd() },
    });
  };

  const genders = [
    { label: "None", value: "None" },
    { label: "Monsieur", value: "Male" },
    { label: "Madame", value: "Female" },
  ];

  return (
    <div>
      <SectionTitle withoutPadding title={"Identité"} mode="obligatoire" />
      <GridContainer>
        <Grid item xs={12}>
          <GridContainer>
            <Grid item xs={12} md={3}>
              <TXSelectMono
                id={"civilité"}
                name={"gender"}
                label={t("Civilité")}
                value={contactDto.identity.gender}
                onChange={onChange}
                disabled={isDisabled}
              >
                {genders.map((gender) => (
                  <MenuItem key={gender.value} value={gender.value}>
                    {t(gender.label)}
                  </MenuItem>
                ))}
              </TXSelectMono>
            </Grid>
            <Grid item xs={12} md={4}>
              <TXTextField
                id="prenom"
                name="firstName"
                label={t("Prenom")}
                value={contactDto.identity.firstName}
                onChange={onChange}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TXTextField
                id="nom"
                name="lastName"
                label={t("Nom")}
                value={contactDto.identity.lastName}
                onChange={onChange}
                disabled={isDisabled}
              />
            </Grid>
          </GridContainer>
        </Grid>
      </GridContainer>
    </div>
  );
};
