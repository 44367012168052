import { makeStyles } from "@material-ui/core/styles";
import { ITheme } from "theme/ts/main";

interface StylesProps {
  offsetRight: number;
}

export const useStyles = makeStyles((theme: ITheme) => ({
  bookmarkIcon: (props: StylesProps) => ({
    color: theme.palette.tonique,
    position: "absolute",
    right: props.offsetRight,
    top: -5,
  }),
  container: { position: "relative" },
  insideBookmarkIcon: {
    color: theme.palette.tonique,
    height: 30,
    left: 0,
    padding: 3,
    position: "absolute",
    top: 0,
    width: 30,
  },
  outsideBookmarkIcon: {
    color: theme.palette.white,
    height: 30,
    left: 0,
    position: "absolute",
    top: 0,
    width: 30,
  },
}));
