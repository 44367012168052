import { getPages } from "utils/array";

const rangeArray = (range: number) => [...Array.from(Array(range).keys())];

/**
 *
 * @param data - Data to split in buckets
 * @param size - Buckets size
 * @returns All possible buckets of N (size) items, and a buckets with L modulo N (L is data length) items
 */
export const useBuckets = <T>({ data, size }: { data: T[]; size: number }) => {
  const pages = getPages(data.length, size);

  return rangeArray(pages).reduce((result, key) => {
    return [...result, data.slice(key * size, (key + 1) * size)];
  }, [] as T[][]);
};
