import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import { useServices } from "services";
import { ITheme } from "theme/ts/main";
import { ParkingsDto } from "../../../../models/partSummariesDto";
import { ProfileInputProps } from "../../../InputProps";

const useStyles = makeStyles((theme: ITheme) => {
  return {
    checkbox: {
      color: theme.palette.primary.main,
    },
  };
});

type ParkingOption = "hasBox" | "hasExteriorParking" | "hasInteriorParking";

export const SelectParkingKind: React.FC<ProfileInputProps<ParkingsDto>> = ({
  onChange,
  value,
}) => {
  const {
    l10n: { t },
  } = useServices();

  const onCheck = useCallback(
    (option: ParkingOption) => () => {
      onChange({ ...value, [option]: !value[option] }, true);
    },
    [onChange]
  );

  const checkboxes: { id: ParkingOption; label: string }[] = useMemo(
    () => [
      { id: "hasBox", label: t("parkingKind.Box") },
      { id: "hasExteriorParking", label: t("parkingKind.ExteriorParking") },
      { id: "hasInteriorParking", label: t("parkingKind.InteriorParking") },
    ],
    [t]
  );

  const classes = useStyles();

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {checkboxes.map((checkbox) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                color={"default"}
                classes={{ root: classes.checkbox }}
                checked={value[checkbox.id]}
                onChange={onCheck(checkbox.id)}
                inputProps={{ "aria-label": "checkbox" }}
              />
            }
            label={<Typography variant={"body2"}>{checkbox.label}</Typography>}
          />
        );
      })}
    </div>
  );
};
