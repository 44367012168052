import { FormControlLabel, Grid, Typography } from "@material-ui/core";
import { Checkbox } from "components/Checkbox";
import React, { MouseEventHandler } from "react";
import {
  CheckedCriteria,
  ForRentParkingFeaturesDto,
} from "routes/app/contacts/contact/components/models/Proposal/ForRent/Parking/ForRentParkingFeaturesDto";
import { CheckBoxDescription } from "routes/app/contacts/contact/components/Profiles/Search/shared/types";

interface Props {
  features: ForRentParkingFeaturesDto;
  checkBoxes: CheckBoxDescription<CheckedCriteria>[];
  handleCheck: (criteriaKey: CheckedCriteria) => MouseEventHandler;
}
export const CriteriaCheckBoxes = ({
  features,
  checkBoxes,
  handleCheck,
}: Props) => {
  return (
    <Grid container spacing={2}>
      {checkBoxes.map((checkBox) => (
        <Grid item xs={12} md={6} key={`frp-${checkBox.criteriaKey}`}>
          <div style={{ alignItems: "center", display: "flex" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={features.parkingPropertyInfo[checkBox.criteriaKey]}
                  color={"default"}
                  onClick={handleCheck(checkBox.criteriaKey)}
                />
              }
              label={
                <Typography variant={"body2"}>{checkBox.label}</Typography>
              }
            />
          </div>
        </Grid>
      ))}
    </Grid>
  );
};
