import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";

import AspectsFinanciers from "./components/AspectsFinanciers";
import Composition from "./components/Composition";
import DPEGES from "./components/DPEGES";
import General from "./components/General";
import Prestations from "./components/Prestations";
import Proximites from "./components/Proximites";
import Surfaces from "./components/Surfaces";
import TitreSection from "./components/TitreSection";

const Details = (props) => {
  const { bien, t } = props;
  const details = {
    aspectsFinanciers: {
      loyerCC: bien.loyerCC,
      prix: bien.prix,
    },
    composition: {
      nbChambres: bien.nbChambres,
      nbPieces: bien.nbPieces,
    },
    descriptif: bien.annonceDesc,
    dpeges: {
      dpe: `${bien.dpeLettre} | ${bien.dpeValeur}`,
      ges: `${bien.gesLettre} | ${bien.gesValeur}`,
    },
    general: {
      anneeConstruction:
        bien.anneeConstruction > 0 ? bien.anneeConstruction : "",
      exposition: bien.exposition,
      reference: bien.numMandat,
      singleStorey: bien.singleStorey,
      withPool: bien.withPool,
    },
    surfaces: {
      superficieTerrain: bien.surfaceTerrain,
      surfaceHabitable: bien.surface,
      surfacesInterieures: Object.entries(bien.surfacesInterieures),
    },
    typeBienNom: bien.typeBienNom,
  };

  if (bien.proximite) details.proximites = Object.entries(bien.proximite);
  if (bien.bienEnCopropriete) {
    details.aspectsFinanciers = {
      ...details.aspectsFinanciers,
      bienEnCopropriete: bien.bienEnCopropriete,
      chargesCopropriete: bien.chargesCopropriete,
      nbLotsCopropriete: bien.nbLotsCopropriete,
    };
  }

  if (bien.prestations) details.prestations = Object.entries(bien.prestations);

  if (bien.dateEffectiveSignature)
    details.aspectsFinanciers.dateDeVente = moment(
      bien.dateEffectiveSignature
    ).format("DD/MM/YYYY");

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={4}>
        <TitreSection titre="Descriptif" />
        <Typography variant="subtitle1" align="left">
          {details.descriptif}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={4} className={"page-break"}>
        <TitreSection titre="Général" />
        <General
          t={t}
          general={details.general}
          familleBienEnum={bien.familleBienEnum}
        />
        {(details.surfaces.surfaceHabitable > 0 ||
          details.surfaces.superficieTerrain > 0 ||
          details.surfaces.surfacesInterieures.length > 0) && (
          <>
            <TitreSection titre="Surfaces" />
            <Surfaces t={t} surfaces={details.surfaces} isCE={bien.isCE} />
          </>
        )}
        <TitreSection titre="Aspects financiers" />
        <AspectsFinanciers
          t={t}
          aspectsFinanciers={details.aspectsFinanciers}
        />
        {details.proximites && details.proximites.length > 0 && (
          <>
            <TitreSection titre="Proximités" />
            <Proximites t={t} proximites={details.proximites} />
          </>
        )}
      </Grid>
      <Grid item xs={12} lg={4}>
        {details.composition.nbPieces > 0 &&
          details.composition.nbChambres > 0 && (
            <>
              <TitreSection titre="Composition" />
              <Composition t={t} composition={details.composition} />
            </>
          )}
        {details.prestations && details.prestations.length > 0 && (
          <>
            <TitreSection titre="Prestations" />
            <Prestations t={t} prestations={details.prestations} />
          </>
        )}
        {details.dpeges.dpe !== " | " && (
          <>
            <TitreSection titre="DPE/GES" />
            <DPEGES t={t} dpeges={details.dpeges} />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Details;
