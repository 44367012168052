import { Grid } from "@material-ui/core";
import React, { FC } from "react";

import { GridContainer } from "../../../../../../components/containers/GridContainer";
import { ContactEmails } from "./ContactEmails";
import { ContactPhones } from "./ContactPhones";
import { OnePhoneOrMailMandatoryMessage } from "./OnePhoneOrMailMandatoryMessage";
import { SectionTitle } from "./Sections";

export const ContactMeans: FC = () => {
  return (
    <div>
      <SectionTitle
        title={"Moyens de contact*"}
        mode="obligatoire"
        mention={"(1 obligatoire)"}
      />
      <GridContainer>
        <Grid item xs={12}>
          <ContactPhones />
        </Grid>
        <Grid item xs={12}>
          <ContactEmails />
        </Grid>
        <Grid item xs={12}>
          <OnePhoneOrMailMandatoryMessage />
        </Grid>
      </GridContainer>
    </div>
  );
};
