import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import CheckCircleIcon from "@material-ui/core/SvgIcon/SvgIcon";
import { amber, green } from "@material-ui/core/colors";

import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: "2rem",
  },
  iconVariant: {
    marginRight: theme.spacing(1),
    opacity: 0.9,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  root: {
    padding: theme.spacing(2, 2),
  },
  success: {
    backgroundColor: green[600],
  },
  warning: {
    backgroundColor: amber[700],
  },
}));

const variantIcon = {
  error: ErrorIcon,
  info: InfoIcon,
  success: CheckCircleIcon,
  warning: WarningIcon,
};

export default function NoResult({ title, subtitle, type }) {
  const classes = useStyles();
  const Icon = variantIcon[type];
  return (
    <div>
      <Paper className={clsx(classes[type], classes.root)}>
        <div style={{ display: "flex" }}>
          <div style={{ alignItems: "center", display: "flex" }}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant={"body1"}>{title}</Typography>
            <Typography component="p">{subtitle}</Typography>
          </div>
        </div>
      </Paper>
    </div>
  );
}

NoResult.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

NoResult.defaultProps = {
  subtitle: "",
  title: "Ooops! No data to display",
  type: "warning",
};
