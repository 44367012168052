import React, { useMemo } from "react";
import { Text14px } from "../typographies/Text14px";

interface Props {
  name: string;
  count: number;
}
export const SelectedItems: React.FC<Props> = ({ name, count }) => {
  const [namePart, selectionPart] = useMemo(() => {
    const end = count > 1 ? "s" : "";
    return [`${name}${end}`, `sélectionné${end}`];
  }, [name, count]);

  return (
    <Text14px>
      <strong>{count}</strong>&nbsp;
      {namePart}&nbsp;{selectionPart}
    </Text14px>
  );
};
