import { MatchingProfileRequestDto } from "services/matching/MatchingProfileRequestDto";

import { ProfileItemDto } from "./../../../../services/profiles/dto/ReadModelProfileDto";
import { FamilyKind } from "./utils";

const mapSaleKindToSearchKind = (typeAffaire: string) => {
  switch (typeAffaire.toLowerCase()) {
    case "sale":
      return "Purchase";
    case "rental":
      return "Rent";
    case "lifeannuity":
      return "SearchLifeAnnuity";
    default:
      return typeAffaire;
  }
};

export const getMatchingRequest = (
  profile: ProfileItemDto
): MatchingProfileRequestDto => {
  return {
    budget: profile.budgetMax,
    cityId: profile.places[0]?.id,
    constructionYear: +profile?.constructionYear?.length
      ? +profile.constructionYear[0]
      : undefined,
    nbRooms: profile.rooms ?? undefined,
    profileKind: <"Purchase" | "Rent" | "SearchLifeAnnuity" | undefined>(
      mapSaleKindToSearchKind(profile.kind)
    ),
    propertyKind: <FamilyKind>profile.propertyKind,
    surface: profile.surfaceMax ?? undefined,
    withElevator: Boolean(profile.withElevator),
    withParking: Boolean(profile.withParking),
    withPool: Boolean(profile.withPool),
  };
};
