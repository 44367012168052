import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  mail: {
    color: (theme.palette as any).default,
  },
  sitePicto: {
    backgroundColor: "#BFB7B7",
    borderRadius: 5,
    height: 10,
    marginRight: 5,
    width: 10,
  },
  telephone: {
    color: (theme.palette as any).default,
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
}));
