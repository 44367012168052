import { Typography } from "@material-ui/core";
import React from "react";

import useStyles from "../styles";

const TitreSection: React.FC<{ titre: string }> = ({ titre }) => {
  const classes = useStyles();
  return (
    <Typography variant="h4" className={classes.titreSection}>
      {titre.toUpperCase()}
    </Typography>
  );
};

export default TitreSection;
