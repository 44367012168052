import { Theme, Typography, withStyles } from "@material-ui/core";
import { EmailOutlined, PhoneOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { ITheme } from "theme/ts/main";

const unknownIconStyle = {
  fontSize: 18,
  marginRight: 10,
};

const UnknownEmailIcon = withStyles((theme: Theme) => ({
  root: { ...unknownIconStyle, color: (theme as ITheme).palette.common.dark },
}))(EmailOutlined);

const UnknownPhoneIcon = withStyles((theme: Theme) => ({
  root: { ...unknownIconStyle, color: (theme as ITheme).palette.common.dark },
}))(PhoneOutlined);

interface Props {
  type: "email" | "phone";
}

const useStyles = makeStyles((theme: ITheme) => ({
  label: {
    "&:hover": {
      color: theme.palette.common.dark,
    },
    color: theme.palette.common.dark,
  },
  icon: {
    color: theme.palette.common.dark,
  },
}));

export const UnknownPhoneOrEmail: React.FC<Props> = ({ type }) => {
  const classes = useStyles();

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {type === "email" ? <UnknownEmailIcon /> : <UnknownPhoneIcon />}
      <Typography variant="body1" noWrap className={classes.label}>
        Non renseigné
      </Typography>
    </div>
  );
};
