import { Grid, RadioGroup } from "@material-ui/core";
import {
  Apartment,
  ApartmentOutlined,
  Business,
  BusinessOutlined,
  DirectionsCar,
  DirectionsCarOutlined,
  House,
  HouseOutlined,
  Landscape,
  LandscapeOutlined,
  Loyalty,
  LoyaltyOutlined,
  Store,
  StoreOutlined,
  VpnKey,
  VpnKeyOutlined,
} from "@material-ui/icons";
import { SelectOption, SelectOptionProps } from "UI/shared/atoms/SelectOptions";
import { first } from "lodash";
import React, { FC, useState } from "react";
import { useServices } from "services";

import { AccountType } from "../models/accountType";
import { ProfileKind, ProfileReadDto } from "../models/profilesReadDto";
import { BusinessType, SelectAccountType } from "./AccountChip";
import { ComingIcon } from "./components/CustomProfilRadio";
import { SectionTitle, SubSectionTitle } from "./components/SectionTitle";
import { ApartmentForRentProfileWrite } from "./Proposal/ForRent/Apartment/Write";
import { HouseForRentProfileWrite } from "./Proposal/ForRent/House/Write";
import { ApartmentForSaleProfileWrite } from "./Proposal/ForSale/Apartment/Write";
import { BuildingForSaleProfileWrite } from "./Proposal/ForSale/Building/Write";
import { HouseForSaleProfileWrite } from "./Proposal/ForSale/House/Write";
import { LandForSaleProfileWrite } from "./Proposal/ForSale/Land/Write";
import { ParkingForSaleProfileWrite } from "./Proposal/ForSale/Parking/Write";
import { PremisesForSaleProfileWrite } from "./Proposal/ForSale/Premises/Write";
import { Write } from "./Write";
import { ParkingForRentWrite } from "./Proposal/ForRent/Parking/Write";

export const ForSaleForm: FC<{
  contactId: string;
  profile?: ProfileReadDto;
  mode?: string;
  sendUpdatedProfile?: (profile: ProfileReadDto | undefined) => void;
  handleAfterUpdate?: () => void;
}> = ({
  contactId,
  profile,
  mode,
  sendUpdatedProfile = () => {
    return;
  },
  handleAfterUpdate,
}) => {
  const [state, setState] = useState<{
    account: AccountType;
    business: BusinessType;
    kind?: ProfileKind;
  }>({
    account: profile?.account || "Customer",
    business: getBusiness(profile?.kind) || "Sale",
    kind: profile?.kind || "SaleApartmentProfile",
  });

  const [more, showMore] = useState<boolean>(mode === "update");
  const options = profileOptions(state.account, state.business);
  const selected =
    options.find((_) => _.value === state.kind)?.value || first(options)?.value;

  const disabledOptions: AccountType[] = [];
  return (
    <WriteProfile
      contactId={contactId}
      value={profile}
      account={state.account}
      kind={selected}
      more={more}
      showMore={() => showMore(true)}
      sendUpdatedProfile={sendUpdatedProfile}
      handleAfterUpdate={handleAfterUpdate}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SelectAccountType
            value={state.account}
            onChange={(account) =>
              setState(() => ({
                account,
                business: "Sale",
                kind: undefined,
              }))
            }
            disabledOptions={disabledOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectBusinessType
            account={state.account}
            value={state.business}
            onChange={(business) =>
              setState((previous) => ({
                ...previous,
                business,
                kind: undefined,
              }))
            }
          />
        </Grid>
        <Grid item xs={12}>
          <SelectKind
            options={options}
            value={selected}
            onChange={(kind) => setState((previous) => ({ ...previous, kind }))}
          />
        </Grid>
      </Grid>
    </WriteProfile>
  );
};

const SelectBusinessType: React.FC<{
  account: AccountType;
  value?: BusinessType;
  onChange: (value: BusinessType) => void;
}> = ({ account, value, onChange }) => {
  const {
    l10n: { t },
  } = useServices();

  const options: SelectOptionProps<BusinessType>[] = [
    {
      checkedIcon: <LoyaltyOutlined />,
      icon: <Loyalty />,
      value: "Sale",
    },
    {
      checkedIcon: <VpnKeyOutlined />,
      icon: <VpnKey />,
      value: "Rental",
    },
  ];

  if (account === "Customer") {
    options.push({
      checkedIcon: <ComingIcon />,
      disabled: true,
      icon: <ComingIcon />,
      value: "LifeAnnuity",
    });
  }

  return (
    <>
      <SubSectionTitle title="Type d'affaire" />
      <RadioGroup
        row
        aria-label="type d'affaire"
        value={
          options.find((_) => _.value === value)?.value ||
          first(options)?.value ||
          ""
        }
        onChange={(_) => onChange(_.target.value as BusinessType)}
      >
        {options.map(({ value, icon, checkedIcon, disabled }) => (
          <SelectOption
            key={value}
            icon={icon}
            checkedIcon={checkedIcon}
            value={value}
            label={t(`business.${value}`)}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    </>
  );
};

const SelectKind: React.FC<{
  options: SelectOptionProps<ProfileKind>[];
  value?: ProfileKind;
  onChange: (value: ProfileKind) => void;
}> = ({ options, value, onChange }) => {
  const {
    l10n: { t },
  } = useServices();

  return (
    <>
      <SectionTitle title="Quel type de bien est concerné ?" />
      <RadioGroup
        row
        aria-label="Quel type de bien est concerné ?"
        value={value}
        onChange={(_) => onChange(_.target.value as ProfileKind)}
      >
        {options.map(({ value, icon, checkedIcon, disabled }) => (
          <SelectOption
            key={value}
            icon={icon}
            checkedIcon={checkedIcon}
            value={value}
            label={t(`profile.${value}`)}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    </>
  );
};

function profileOptions(account: AccountType, business: BusinessType) {
  const options: SelectOptionProps<ProfileKind>[] = [];

  if (account === "Customer") {
    if (business === "Sale") {
      options.push(
        {
          checkedIcon: <ApartmentOutlined />,
          icon: <Apartment />,
          value: "SaleApartmentProfile",
        },
        {
          checkedIcon: <HouseOutlined />,
          icon: <House />,
          value: "SaleHouseProfile",
        },
        {
          checkedIcon: <BusinessOutlined />,
          icon: <Business />,
          value: "SaleBuildingProfile",
        },
        {
          checkedIcon: <StoreOutlined />,
          icon: <Store />,
          value: "SalePremisesProfile",
        },
        {
          checkedIcon: <LandscapeOutlined />,
          icon: <Landscape />,
          value: "SaleLandProfile",
        },
        {
          checkedIcon: <DirectionsCarOutlined />,
          icon: <DirectionsCar />,
          value: "SaleParkingProfile",
        }
      );
    } else if (business == "Rental") {
      options.push({
        checkedIcon: <ApartmentOutlined />,
        icon: <Apartment />,
        value: "ForRentApartmentProfile",
      });

      options.push({
        checkedIcon: <DirectionsCarOutlined />,
        icon: <DirectionsCar />,
        value: "ForRentParkingProfile",
      });

      options.push({
        checkedIcon: <HouseOutlined />,
        icon: <House />,
        value: "ForRentHouseProfile",
      });
    }
  } else if (account === "Professional" && business === "Sale") {
    options.push({
      checkedIcon: <StoreOutlined />,
      icon: <Store />,
      value: "SalePremisesProfile",
    });
  }
  return options;
}

const WriteProfile: FC<{
  contactId: string;
  account: AccountType;
  kind?: ProfileKind;
  value?: ProfileReadDto;
  more: boolean;
  showMore: () => void;
  sendUpdatedProfile?: (profile: ProfileReadDto | undefined) => void;
  handleAfterUpdate?: () => void;
}> = ({
  contactId,
  account,
  kind,
  value,
  more,
  showMore,
  sendUpdatedProfile = () => {
    return;
  },
  handleAfterUpdate,
  children,
}) => {
  const initial = value?.kind === kind ? value : undefined;

  const props = {
    contactId,
    initial: initial as any,
    account,
    more,
    showMore,
    sendUpdatedProfile,
    handleAfterUpdate,
  };

  switch (kind) {
    case "SaleApartmentProfile":
      return (
        <ApartmentForSaleProfileWrite {...props}>
          {children}
        </ApartmentForSaleProfileWrite>
      );
    case "SaleHouseProfile":
      return (
        <HouseForSaleProfileWrite {...props}>
          {children}
        </HouseForSaleProfileWrite>
      );
    case "SaleParkingProfile":
      return (
        <ParkingForSaleProfileWrite {...props}>
          {children}
        </ParkingForSaleProfileWrite>
      );
    case "SaleLandProfile":
      return (
        <LandForSaleProfileWrite {...props}>{children}</LandForSaleProfileWrite>
      );
    case "SaleBuildingProfile":
      return (
        <BuildingForSaleProfileWrite {...props}>
          {children}
        </BuildingForSaleProfileWrite>
      );
    case "SalePremisesProfile":
      return (
        <PremisesForSaleProfileWrite {...props}>
          {children}
        </PremisesForSaleProfileWrite>
      );

    case "ForRentApartmentProfile":
      return (
        <ApartmentForRentProfileWrite {...props}>
          {children}
        </ApartmentForRentProfileWrite>
      );

    case "ForRentParkingProfile":
      return <ParkingForRentWrite {...props}>{children}</ParkingForRentWrite>;
    case "ForRentHouseProfile":
      return (
        <HouseForRentProfileWrite {...props}>
          {children}
        </HouseForRentProfileWrite>
      );
  }
  return (
    <Write
      pending={false}
      more={more}
      showMore={showMore}
      save={() => void 0}
      mode={initial ? "update" : "add"}
      matching={<></>}
    >
      {children}
    </Write>
  );
};

function getBusiness(kind?: ProfileKind): BusinessType | undefined {
  switch (kind) {
    case "SaleApartmentProfile":
    case "SaleHouseProfile":
    case "SaleBuildingProfile":
    case "SaleLandProfile":
    case "SaleParkingProfile":
    case "SalePremisesProfile":
      return "Sale";
    case "ForRentApartmentProfile":
    case "ForRentParkingProfile":
    case "ForRentHouseProfile":
      return "Rental";
  }
}
