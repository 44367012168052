export default function getImage(url) {
  const image = new Image();
  image.crossOrigin = "anonymous";
  const canvas = document.createElement("canvas");
  const canvasContext = canvas.getContext("2d");
  image.onload = function () {
    canvas.width = image.width;
    canvas.height = image.height;

    canvasContext.drawImage(image, 0, 0, image.width, image.height);
    console.log(canvas.toDataURL());
    return canvas.toDataURL();
  };

  image.onerror = function (e) {
    console.log(e);
  };

  /* image.src = `${url}?_=${(new Date()).getTime()}`;*/
  image.src = url;
}
