import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";

export interface RubriqueProps {
  bouton: JSX.Element;
  texte: string;
}

export const Rubrique: React.FC<RubriqueProps> = ({ bouton, texte }) => (
  <Grid container spacing={2}>
    <Grid item xs={4}>
      <Grid container justify={"flex-end"}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "50%",
          }}
        >
          {bouton}
        </div>
      </Grid>
    </Grid>
    <Grid item xs={8}>
      <Typography variant={"subtitle1"}>{texte}</Typography>
    </Grid>
  </Grid>
);
