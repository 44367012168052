import {useCallback, useEffect, useState} from "react";
import {useTxHttp} from "../http";
import {useServices} from "../index";
import {AdsDefaultSearchLocationsSettingsDto} from "./dto/AdsDefaultSearchLocationsSettingDto";

const FETCH_ERROR_MESSAGE =
    "Error while fetching pige search locations settings";
const UPDATE_ERROR_MESSAGE =
    "Error while updating current pige search locations settings";

const useAdsDefaultSearchLocationsSettingsCenter = () => {
    const {get, post} = useTxHttp();
    const {endpoints} = useServices();


    const [adsDefaultSearchLocations, setAdsDefaultSearchLocations] = useState(
        [] as AdsDefaultSearchLocationsSettingsDto
    );

    const getAdsDefaultSearchLocationsSettingsCenter = useCallback(
        () =>
            get<AdsDefaultSearchLocationsSettingsDto>(
                endpoints.pige.defaultSearchLocationsSettings
            ),
        [get]
    );

    const loadAdsDefaultSearchLocationsSettingsCenter = () => {
        getAdsDefaultSearchLocationsSettingsCenter()
            .then((response) => {
                setAdsDefaultSearchLocations(response.data);
                console.log('setAdsDefaultSearchLocations');
            })
            .catch(() => {
                console.error(FETCH_ERROR_MESSAGE);
            });
    };
    
    const updateAdsDefaultSearchLocationsSettingsCenter = useCallback(
        (locations: AdsDefaultSearchLocationsSettingsDto) =>
            post<AdsDefaultSearchLocationsSettingsDto>(
                endpoints.pige.defaultSearchLocationsSettings,
                {content: locations, contentType: "application/json"}
            )
                .then(async () => {
                    await loadAdsDefaultSearchLocationsSettingsCenter()
                })
                .catch(() => {
                    console.error(UPDATE_ERROR_MESSAGE);
                }),
        [post]
    );
   
    useEffect(() => {
        loadAdsDefaultSearchLocationsSettingsCenter();
    }, [getAdsDefaultSearchLocationsSettingsCenter]);

    return {
        adsDefaultSearchLocations,
        set: updateAdsDefaultSearchLocationsSettingsCenter,
    };
};

export default useAdsDefaultSearchLocationsSettingsCenter;
