import { breakpoint } from "theme/main";

import obtenirParIdAdapter from "../../../../../services/conseillers/dto/Conseillers/ObtenirParId/obtenirParIdAdapter";
import { Refinements } from "../models/refinements";

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_ZOOM_LEVEL = 6;
const DEFAULT_MAP_CENTER = { lat: 46.71109, lng: 1.7191036 };

export const initialState = {
  boundingBox: {
    nw: `90, -180`,
    se: `-90, 180`,
  },
  carrousel: [],
  center: DEFAULT_MAP_CENTER,
  displayDetailedItem: false,
  displayMode: "list",
  drawer: false,
  firstIntersection: true,
  hit: obtenirParIdAdapter(),
  loading: false,
  page: DEFAULT_PAGE_NUMBER,
  rechercheBounds: false,
  refinements: Refinements,
  shouldUpdateMapBounds: true,
  smAndDown: breakpoint().smAndDown,
  zoom: DEFAULT_ZOOM_LEVEL,
  mapPoints: [],
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case "firstIntersection":
      return {
        ...state,
        firstIntersection: payload,
      };
    case "setLoading":
      return { ...state, loading: payload };
    case "setRefinements":
      return { ...state, page: 1, refinements: payload };
    case "setDisplayMode":
      return { ...state, displayMode: payload, page: 1 };
    case "toggleDrawer":
      return { ...state, drawer: !state.drawer };
    case "setSmAndDown":
      return { ...state, smAndDown: breakpoint().smAndDown };
    case "saveMapConfig":
      return { ...state, ...payload };
    case "updateBoundingBox":
      return { ...state, ...payload, loading: true };
    case "restoreSavedState":
      return { ...state, ...payload };
    case "changePage":
      return { ...state, page: payload };
    case "displayCarrousel":
      return { ...state, carrousel: payload };
    case "hideCarrousel":
      return { ...state, carrousel: [] };
    case "initialiseAvecParametres":
      return {
        ...state,
        boundingBox: payload.boundingBox,
        displayMode: payload.displayMode,
        refinements: payload.refinements,
      };
    case "setMapPoints":
      return { ...state, mapPoints: payload, loading: false };
    default:
      return { ...state };
  }
};
