import { MuiThemeProvider } from "@material-ui/core";
import React, { useContext } from "react";
import { AuthenticationContext } from "services/authentication/authentication";

import themeCapi from "../../theme/ts/capifrance";
import ThemeX from "../../theme/ts/main";
import themeOpti from "../../theme/ts/optimhome";

export const ThemeProvider: React.FC = ({ children }) => {
  const { bu } = useContext(AuthenticationContext);
  const buTheme = bu === "Optimhome" ? themeOpti : themeCapi;
  return (
    <ThemeX>
      <MuiThemeProvider theme={buTheme}>{children}</MuiThemeProvider>
    </ThemeX>
  );
};
