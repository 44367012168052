import biensDisponiblesGridOuListe from "mockups/biens/biensDisponiblesListe.json";
import useHTTPService from "services/dataSources/communicationServices/useHTTPService";
import useTestService from "services/dataSources/testServices/useTestService";
import { rechercheGridOuListeAdapter } from "services/rapprochement/Rapprochement/BiensEnVente/RechercheGridOuListe/RechercheGridOuListeAdapter";

import { useServices } from "../index";

export const useBiensDisponiblesAPI = ({ params, erreur = false }) => {
  const { endpoints } = useServices();

  const { status, adaptedData } = useHTTPService({
    adapter: rechercheGridOuListeAdapter,
    endpoint: endpoints.bienEnVente.rechercheGridOuListe,
    params,
  });
  return { items: adaptedData, status: status };
};

export const useBiensDisponiblesTest = ({ params, erreur = false }) => {
  const { status, adaptedData } = useTestService({
    adapter: rechercheGridOuListeAdapter,
    erreur,
    mockup: biensDisponiblesGridOuListe,
    params,
  });
  return { items: adaptedData, status: status };
};

export const useBiensDisponibles = window.testTX
  ? useBiensDisponiblesTest
  : useBiensDisponiblesAPI;
