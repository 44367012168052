import { useContext } from "react";
import { NotifierContext } from "./index";

const useNotifier = () => {
  const dispatch = useContext(NotifierContext);

  function showSnack(options) {
    // message, variant = 'success', autoHideTime = 5000
    if (options.message === "Cannot read property 'result' of undefined") {
      options.message = "Chargement des données";
      options.variant = "success";
    }
    dispatch({ options, type: "show" });
  }

  function hideSnack(event, reason) {
    if (reason === "clickaway") return;
    dispatch({ type: "hide" });
  }

  return {
    hideSnack,
    showSnack,
  };
};

export default useNotifier;
