import { Grid } from "@material-ui/core";
import PanneauResultatsVides from "UI/shared/templates/Panels/NoItemsPanel";
import HitListe from "layoutContents/Biens/components/Hit/HitListe";
import HitReduit from "layoutContents/components/CarteBienReduite";
import { HitsSkeleton } from "layoutContents/components/HitsContainer/HitsSkeleton";
import React from "react";

const adapterHitReduit = (item) => ({
  ...item,
  prixNormalized: item.prix,
  villeCodePostal: item.localisation.codePostal,
  villeNom: item.localisation.villeNom,
});

/*const _onPrint = (id, onHitClick) => () => {onHitClick({id, print: true})};
const _onCLick = (id, onHitClick) => () => {onHitClick({id})};*/

const RenderHit = ({
  onHitClick = () => {},
  items,
  displayMode,
  firstRef = null,
  BienAvecAcquereurs = <></>,
  triggerRef = null,
  loading,
  pageSize = 0,
  mode = "biensVendus",
  clearRefinements = () => {},
  setOpenDrawer = () => {},
  onDetailPageURL = () => {},
}) => {
  let hitReduit = (item, mode) => (
    <HitReduit
      onClick={() => onHitClick({ id: item.id })}
      hit={adapterHitReduit(item)}
      mode={mode}
      detailedPageURL={onDetailPageURL({ id: item.id, print: false })}
    />
  );
  let hitListe = (item, mode) => (
    <HitListe
      hit={item}
      mode={mode}
      onClick={() => onHitClick({ id: item.id })}
      onPrint={() => onHitClick({ id: item.id, print: true })}
      detailedPageURL={onDetailPageURL({ id: item.id, print: false })}
    />
  );

  if (mode === "mesBiensEnVente") {
    hitReduit = function hitReduit(item, mode) {
      return (
        <BienAvecAcquereurs
          onClick={() => onHitClick({ id: item.id })}
          detailedPageURL={onDetailPageURL({ id: item.id })}
          Component={HitReduit}
          hit={adapterHitReduit(item)}
          mode={mode}
        />
      );
    };
    hitListe = function hitListe(item, mode) {
      return (
        <BienAvecAcquereurs
          onClick={() => onHitClick({ id: item.id })}
          detailedPageURL={onDetailPageURL({ id: item.id })}
          Component={HitListe}
          hit={item}
          mode={mode}
          onPrint={() => onHitClick({ id: item.id, print: true })}
        />
      );
    };
  }

  let _biens = items;

  if (JSON.stringify(items) === JSON.stringify({})) _biens = { data: [] };

  if (_biens.data.length < 1 && !loading)
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-around",
          marginTop: "25px",
        }}
      >
        <PanneauResultatsVides
          clearRefinements={clearRefinements}
          setOpenDrawer={setOpenDrawer}
        />
      </div>
    );

  switch (displayMode) {
    case "map":
      return (
        <>
          {_biens.data.map((item, index) => {
            if (
              _biens.data.length - Math.ceil(pageSize / 2) === index + 1 &&
              triggerRef
            ) {
              return (
                <Grid key={item.id} item xs={6} md={12} lg={6} ref={triggerRef}>
                  {hitReduit(item, mode)}
                </Grid>
              );
            } else {
              if (index === 0 && firstRef) {
                return (
                  <Grid key={item.id} item xs={6} md={12} lg={6} ref={firstRef}>
                    {hitReduit(item, mode)}
                  </Grid>
                );
              } else {
                return (
                  <Grid key={item.id} item xs={6} md={12} lg={6}>
                    {hitReduit(item, mode)}
                  </Grid>
                );
              }
            }
          })}
          {loading && HitsSkeleton(1, displayMode)}
        </>
      );
    case "gallery":
      return (
        <>
          {_biens.data.map((item, index) => {
            if (
              _biens.data.length - Math.ceil(pageSize / 2) === index + 1 &&
              triggerRef
            ) {
              return (
                <Grid
                  key={item.id}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  ref={triggerRef}
                >
                  {hitReduit(item, mode)}
                </Grid>
              );
            } else {
              if (index === 0 && firstRef) {
                return (
                  <Grid
                    key={item.id}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    ref={firstRef}
                  >
                    {hitReduit(item, mode)}
                  </Grid>
                );
              } else {
                return (
                  <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                    {hitReduit(item, mode)}
                  </Grid>
                );
              }
            }
          })}
          {loading && HitsSkeleton(1, displayMode)}
        </>
      );
    default:
      return (
        <>
          {_biens.data.map((item, index) => {
            if (_biens.data.length - Math.ceil(pageSize / 2) === index + 1) {
              return (
                <Grid item xs={12} key={item.id} ref={triggerRef}>
                  {hitListe(item, mode)}
                </Grid>
              );
            } else {
              if (index === 0) {
                return (
                  <Grid item xs={12} key={item.id} ref={firstRef}>
                    {hitListe(item, mode)}
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={12} key={item.id}>
                    {hitListe(item, mode)}
                  </Grid>
                );
              }
            }
          })}
          {loading && HitsSkeleton(1, displayMode)}
        </>
      );
  }
};

export default RenderHit;
