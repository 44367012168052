import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/TuneOutlined";
import SearchbarX from "components/SearchbarX";
import SelectX from "components/SelectX";
import Localisation from "layoutContents/components/ContextualSideBar/components/localisation";
import React, { useEffect, useReducer } from "react";
import { breakpoint } from "theme/main";

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: "16px",
    height: "45px",
    margin: "3px 3px 3px 3px",
    minWidth: "45px",
    padding: 0,
  },
  chambre: {
    margin: "3px 3px 3px 3px",
    maxWidth: "150px",
    minWidth: "120px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 3px 10px 3px",
      maxWidth: "100%",
    },
  },
  locationSelectRoot: {
    margin: 0,
  },
  prixContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  prixRange: {
    margin: "3px 3px 3px 3px",
    maxWidth: "100px",
    minWidth: "90px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 3px 10px 3px",
      maxWidth: "100%",
    },
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  search: {
    margin: "3px 3px 3px 3px",
    maxWidth: "400px",
    minWidth: "200px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 3px 10px 3px",
      maxWidth: "100%",
    },
  },
}));

let timeout;

const reducer = (state, action) => {
  switch (action.query) {
    case "fullText":
      return { ...state, search: action.value };
    case "initialize":
      return action.value;
    default:
      return { ...state };
  }
};

function Searchbar({
  refinements,
  onChange,
  t,
  onClickMore,
  displayMode,
  onUpdateLocalisations,
}) {
  const { roles, fullText, specialites } = refinements;
  const classes = useStyles();
  const initialState = {
    search: fullText.value,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const onChangeDelayed = (query, value) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      onChange(query, value);
    }, 600);
    dispatch({ query, value });
  };

  useEffect(() => {
    dispatch({
      query: "initialize",
      value: {
        search: fullText.value,
      },
    });
  }, [fullText.value]);

  return (
    <SearchbarX>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={10} sm={10} md={displayMode !== "map" ? 2 : 10}>
          <TextField
            fullWidth
            value={state.search}
            onChange={(evt) =>
              onChangeDelayed(fullText.query, evt.target.value)
            }
            variant={"outlined"}
            label={t("Rechercher")}
            type={"search"}
          />
        </Grid>
        {displayMode !== "map" && !breakpoint().smAndDown ? (
          <Grid item md={8}>
            <Grid container spacing={1} alignItems="center">
              <Grid item md={6}>
                <Localisation
                  selectRootClassName={classes.locationSelectRoot}
                  withLabel={false}
                  refinementsObject={refinements}
                  t={t}
                  gridFilter={classes.location}
                  onUpdateLocalisations={onUpdateLocalisations}
                  showSeparator={false}
                  shouldGatherSelection
                />
              </Grid>

              <Grid item md={3}>
                <SelectX
                  refinement={roles}
                  label={t("Rôles")}
                  onChange={onChange}
                  multiple
                />
              </Grid>

              <Grid item md={3}>
                <SelectX
                  refinement={specialites}
                  label={t("Spécialités")}
                  onChange={onChange}
                  multiple
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        <Grid item xs={2} sm={2}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              variant={"contained"}
              color={"primary"}
              size={"small"}
              onClick={onClickMore}
              className={classes.button}
            >
              <AddIcon />
            </Button>
          </div>
        </Grid>
      </Grid>
    </SearchbarX>
  );
}

export default Searchbar;
