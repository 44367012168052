import getReportingEndpoints from "./ReportingEndpoints";
import { MicroServicesConfiguration } from "../configuration/configuration";
import getBienEnVenteEndpoints from "./BienEnVenteEndpoints";
import getBienNotesEndpoints from "./BienNotesEndpoints";
import getBienVendusEndpoints from "./BiensVendusEndpoints";
import getConseillerEndpoints from "./ConseillerEndpoints";
import getContactEndpoints from "./ContactEndpoints";
import getGeolocEndpoints from "./GeolocEndpoints";
import { Endpoints } from "./interfaces/Endpoints";
import getLineup7Endpoints from "./Lineup7Endpoints";
import getManagerEndpoints from "./ManagerEndpoints";
import {
  endpoints as getMarketingCampaignsEndpoints,
  endpointsV2 as getMarketingCampaignsEndpointsV2,
} from "./MarketingCampaignsEndpoints";
import getMesBiensEnVenteEndpoints from "./MesBiensEnVenteEndpoints";
import getNotificationsEndpoints from "./NotificationsEndpoints";
import getOffice365Endpoints from "./Office365Endpoints";
import getPigeEndpoints from "./PigeEndpoints";
import getProfilesEndpoints from "./ProfilesEndpoints";
import getRapprochementEndpoints from "./RapprochementEndpoints";
import getRcuEndpoints from "./RcuEndpoints";
import getRequestForInformationsEndpoints from "./RequestForInformationsEndpoints";

export const endpoints = (
  microServices: MicroServicesConfiguration
): Endpoints => {
  return {
    bienEnVente: getBienEnVenteEndpoints(microServices),
    bienNotes: getBienNotesEndpoints(microServices),
    biensVendus: getBienVendusEndpoints(microServices),
    conseillers: getConseillerEndpoints(microServices),
    geoloc: getGeolocEndpoints(microServices),
    lineup7: getLineup7Endpoints(microServices),
    manager: getManagerEndpoints(microServices),
    marketingCampaigns: getMarketingCampaignsEndpoints(microServices),
    marketingCampaignsV2: getMarketingCampaignsEndpointsV2(microServices),
    mesBiensEnVente: getMesBiensEnVenteEndpoints(microServices),
    notifications: getNotificationsEndpoints(microServices),
    office365: getOffice365Endpoints(microServices),
    pige: getPigeEndpoints(microServices),
    portfolio: getContactEndpoints(microServices),
    profiles: getProfilesEndpoints(microServices),
    rapprochement: getRapprochementEndpoints(microServices),
    rcu: getRcuEndpoints(microServices),
    requestForInformations: getRequestForInformationsEndpoints(microServices),
    reporting: getReportingEndpoints(microServices),
  };
};
