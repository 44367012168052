import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { emphasize, makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import { ClassValue } from "classnames/types";
import React, { useState } from "react";
import {
  ActionTypes,
  components,
  ControlProps,
  GroupedOptionsType,
  MenuProps,
  OptionProps,
  PlaceholderProps,
} from "react-select";
import Select from "react-select/async";

import { useGeoloc } from "../../../services/geoloc/useGeoloc";
import { LocationItem, Locations, Refinement } from "../types";
import {
  GroupSelectOptions,
  SelectOption,
  toSelectOptions,
  TypeGeoEnum,
  Zone,
} from "./toSelectOptions";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  divider: {
    height: theme.spacing(2),
  },
  input: {
    display: "flex",
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  paper: {
    left: 0,
    marginTop: theme.spacing(1),
    position: "absolute",
    right: 0,
    zIndex: 2,
  },
  placeholder: {
    bottom: 22,
    left: 17,
    position: "absolute",
  },
  required: {
    borderColor: "red !important",
    borderStyle: "solid",
    borderWidth: 2,
  },
  root: {
    flexGrow: 1,
    padding: 1,
  },
  valueContainer: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
  },
}));

// TODO: check if this could be simplify
const inputComponent = ({ inputRef, ...props }: any) => {
  return <div ref={inputRef} {...props} />;
};
// TODO: check if this could be simplify
const Control: React.FC<ControlProps<any>> = (props) => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      style={{ paddingLeft: 2, width: "100%" }}
      InputProps={{
        inputComponent,
        inputProps: {
          children,
          className: classes.input,
          ref: innerRef,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
};
// TODO: check if this could be simplify
const Option: React.FC<OptionProps<any>> = (props) => {
  const zone = (props.data as SelectOption).zone;
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      {...props.innerProps}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
    >
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" noWrap>
          {zone.nom}
        </Typography>
        <Typography variant="body2" style={{ color: "#8e8e8e" }} noWrap>
          {zone.parent}
        </Typography>
      </div>
    </MenuItem>
  );
};

const Placeholder: React.FC<PlaceholderProps<any>> = (props: any) => {
  return (
    <Typography
      color="textSecondary"
      variant="body1"
      style={{ paddingLeft: 10 }}
    >
      {props.children}
    </Typography>
  );
};

function SingleValue(props: any) {
  return (
    <Typography
      variant="subtitle1"
      {...props.innerProps}
      style={{ paddingLeft: 10 }}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props: any) {
  return (
    <div
      className={props.selectProps.classes.valueContainer}
      style={{ alignItems: "center", display: "flex" }}
    >
      {props.children}
    </div>
  );
}

function MultiValue(props: any) {
  // TODO: handle `&nbsp;`
  return (
    <Typography variant={"body1"} key={props.data.id} tabIndex={-1} noWrap>
      {props.children}&nbsp;&nbsp;
    </Typography>
  );
}

const MultiValueChoicesGathered = (props: any) => {
  const {
    getValue,
    selectProps: { menuIsOpen },
    index,
  } = props;
  const values = getValue() || [{}];
  const label = `${values.length} choix`;

  return index === 0 ? (
    <components.SingleValue {...props}>
      {!menuIsOpen ? label : ""}
    </components.SingleValue>
  ) : null;
};

const Menu: React.FC<MenuProps<any>> = (props) => {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
      style={{ minWidth: "fit-content" }}
    >
      {props.children}
    </Paper>
  );
};

const Input = (props: any) => {
  return (
    <div>
      <components.Input {...props} />
    </div>
  );
};

const ClearIndicator = (props: any) => {
  const {
    children,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      {/* same clear icon as selectX here ? */}
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

const NoOptionsMessage = (props: any) => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      &nbsp;
    </Typography>
  );
};

interface RefinementReactSelectProps {
  label: string;
  onChange: (
    action: ActionTypes,
    options: (SelectOption | SelectedValue)[]
  ) => void;
  value?: SelectedValue[];
  options: (search: string) => Promise<GroupSelectOptions[]>;
  disabled?: boolean;
  showSeparator?: boolean;
  shouldGatherSelection?: boolean;
  rootClassName?: ClassValue;
}

export const RefinementReactSelect: React.FC<RefinementReactSelectProps> = ({
  label,
  onChange,
  value,
  options,
  disabled = false,
  showSeparator = true,
  shouldGatherSelection = false,
  rootClassName,
}) => {
  const [cachedOptions, setCachedOptions] = useState<GroupSelectOptions[]>([]);

  const classes = useStyles();
  const theme = useTheme();
  const selectStyles = {
    indicatorSeparator: () => ({
      display: showSeparator ? "inherit" : "none",
    }),
    input: (base: any) => ({
      ...base,
      "& input": {
        font: "inherit",
      },
      color: theme.palette.text.primary,
      height: "20px",
      paddingLeft: "10px",
    }),
  };

  const handleOptions = async (input: string) => {
    const result = await options(input);
    setCachedOptions(result);
    return result;
  };

  return (
    <div className={classnames(classes.root, rootClassName)}>
      <Select
        inputId={"Locations-refinement"}
        input
        components={{
          Control,
          Input,
          Menu,
          MultiValue: shouldGatherSelection
            ? MultiValueChoicesGathered
            : MultiValue,
          NoOptionsMessage,
          Option,
          Placeholder,
          SingleValue,
          ValueContainer,
          ...(shouldGatherSelection ? ClearIndicator : {}),
        }}
        isDisabled={disabled}
        variant="outlined"
        classes={classes}
        styles={selectStyles}
        TextFieldProps={{
          InputLabelProps: {
            htmlFor: "Locations-refinement",
            shrink: true,
          },
          label,
          variant: "outlined",
        }}
        placeholder=""
        value={value}
        onChange={(_: any, action) => {
          onChange(action.action, _ as (SelectOption | SelectedValue)[]);
        }}
        loadOptions={handleOptions}
        closeMenuOnSelect={false}
        cacheOptions={true}
        defaultOptions={cachedOptions}
        isMulti={true}
      />
    </div>
  );
};

interface SelectLocationsProps {
  value: Locations;
  onChange: (value: Refinement) => void;
  label: string;
  excludedTypes?: TypeGeoEnum[];
}

export interface SelectedValue {
  kind: "SelectedValue";
  label: string;
  value: string;
}

const toSelectedValue = ({
  label,
  value,
}: {
  label: string;
  value: string;
}): SelectedValue => ({
  kind: "SelectedValue",
  label,
  value,
});

const onlyRefined = (locationItem: LocationItem) => locationItem.isRefined;

function toSelectedValues(location: Locations): SelectedValue[] {
  const departements = location.value.departements
    .filter(onlyRefined)
    .map(toSelectedValue);

  const villes = location.value.villes.filter(onlyRefined).map(toSelectedValue);

  const quartiers = location.value.quartiers
    .filter(onlyRefined)
    .map(toSelectedValue);

  const regions = location.value.regions
    .filter(onlyRefined)
    .map(toSelectedValue);

  return [...villes, ...quartiers, ...regions, ...departements];
}

const getDistinctAndRefined = (items: LocationItem[]) =>
  [...Array.from(new Map(items.map((v) => [v.value, v])).values())].filter(
    (item) => item.isRefined
  );

const getNextLocationState = (currentValue: Locations, zone: Zone) => {
  const nextLocationState = (
    villes: LocationItem[],
    departements: LocationItem[] = [],
    regions: LocationItem[] = [],
    quartiers: LocationItem[] = []
  ) => {
    return {
      ...currentValue,
      value: {
        ...currentValue.value,
        departements: getDistinctAndRefined(departements),
        quartiers: getDistinctAndRefined(quartiers),
        regions: getDistinctAndRefined(regions),
        villes: getDistinctAndRefined(villes),
      },
    };
  };

  switch (zone.typeGeoEnum) {
    case "Departement": {
      const nextState = nextLocationState(
        currentValue.value.villes,
        [
          ...currentValue.value.departements,
          { isRefined: true, label: zone.nom, value: zone.id },
        ],
        currentValue.value.regions,
        currentValue.value.quartiers
      );
      return nextState;
    }
    case "Region":
      return nextLocationState(
        currentValue.value.villes,
        currentValue.value.departements,
        [
          ...currentValue.value.regions,
          { isRefined: true, label: zone.nom, value: zone.id },
        ],
        currentValue.value.quartiers
      );
    case "Quartier":
      return nextLocationState(
        currentValue.value.villes,
        currentValue.value.departements,
        currentValue.value.regions,
        [
          ...currentValue.value.quartiers,
          { isRefined: true, label: zone.nom, value: zone.id },
        ]
      );
    case "Ville":
      return nextLocationState(
        [
          ...currentValue.value.villes,
          { isRefined: true, label: zone.nom, value: zone.id },
        ],
        currentValue.value.departements,
        currentValue.value.regions,
        currentValue.value.quartiers
      );

    case "VilleAvecArrondissements":
      return nextLocationState([
        ...currentValue.value.villes,
        ...(zone.raw ?? []).map(({ id, nom }) => ({
          isRefined: true,
          label: nom,
          value: id,
        })),
      ]);

    default:
      return currentValue;
  }
};

function toLocationsRefinement(
  value: Locations,
  action: ActionTypes,
  options: (SelectOption | SelectedValue)[]
): Locations {
  switch (action) {
    case "select-option": {
      const selectedOption = options.find(
        (option) => option.kind === "SelectOption"
      ) as SelectOption;

      if (selectedOption) {
        const nextLocationValue = getNextLocationState(
          value,
          selectedOption.zone
        );
        return nextLocationValue as Locations;
      }
      return value;
    }
    case "clear":
      return {
        kind: "locations",
        value: { departements: [], quartiers: [], regions: [], villes: [] },
      };

    case "deselect-option":
    case "remove-value":
    case "pop-value":
    case "set-value":
    case "create-option":
      return value;
  }
}

export const LocationRefinement: React.FC<SelectLocationsProps> = ({
  label,
  value,
  onChange,
  excludedTypes,
}) => {
  const { search } = useGeoloc();

  const getOptions = (criteria: string) => {
    return search({ autocomplete: criteria, size: 10 }).then((r) => {
      return toSelectOptions(r, excludedTypes);
    });
  };

  return (
    <RefinementReactSelect
      shouldGatherSelection={true}
      label={label}
      value={toSelectedValues(value)}
      options={getOptions}
      onChange={(action, options) => {
        onChange(toLocationsRefinement(value, action, options));
      }}
    />
  );
};
