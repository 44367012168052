import { MatchingProfileRequestDto } from "services/matching/MatchingProfileRequestDto";

import { BuildingFeaturesDto } from "../../../../models/Proposal/Features/Building/buildingFeaturesDto";

export default (features: BuildingFeaturesDto): MatchingProfileRequestDto => {
  return new MatchingProfileRequestDto({
    budget: features.financialInfo.price,
    cityId: features.address.city?.id,
    constructionYear: features.buildingPropertyInfo.constructionYear,
    nbRooms: undefined,
    profileKind: "Purchase",
    propertyKind: "Building",
    surface: features.buildingPropertyInfo.squareMeter,
    withElevator: features.amenities
      ? features.amenities.indexOf("Elevator") >= 0
      : undefined,
    withParking: undefined,
    withPool: undefined,
  });
};
