import { useEffect, useState } from "react";

import { useTxHttp } from "../../http";

type State<TResolvedPayload> = {
  status: "pending" | "resolved" | "rejected";
  adaptedData?: TResolvedPayload;
};

interface useHTTPServiceProps<TAdapter, TParams> {
  endpoint: string;
  adapter: (res: any) => TAdapter;
  useAccessToken?: boolean;
  shouldExecute?: boolean;
  params?: TParams;
}

const useHTTPService = <TAdapter, TParams>({
  endpoint,
  adapter,
  useAccessToken = true,
  shouldExecute = true,
  params = undefined,
}: useHTTPServiceProps<TAdapter, TParams>) => {
  const [state, setState] = useState<State<TAdapter>>({
    adaptedData: {} as any,
    status: "pending",
  });
  const { get } = useTxHttp();

  useEffect(() => {
    let _params = params ? params : {};
    if (useAccessToken) {
      _params = {
        ..._params,
      };
    }

    if (shouldExecute) {
      get<Record<string, any>>(endpoint, _params, undefined, {
        apiKey: "EE6AB995-406F-4615-98ED-CE2797063347",
      })
        .then((res) => {
          if (res.data?.result) {
            setState({
              adaptedData: adapter(res.data.result),
              status: "resolved",
            });
          } else if (res.data) {
            setState({
              adaptedData: adapter(res.data),
              status: "resolved",
            });
          } else {
            setState({ status: "rejected" });
          }
        })
        .catch((e) => {
          console.log(e);
          setState({ status: "rejected" });
        });
    }
  }, [endpoint, params]);

  return state;
};

export default useHTTPService;
