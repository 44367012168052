import { Grid, Typography, useTheme } from "@material-ui/core";
import { BusinessCenterOutlined } from "@material-ui/icons";
import DataProvider from "components/DataProviderSR";
import React from "react";
import { useHistory } from "react-router-dom";
import routesNames from "router/routesNames";
import { useSynthese } from "services/reporting/useSynthese";
import { formatPrix } from "utils/formatage";

import { PanelRechercheGlobale } from "../../../recherche-globale/components/PanneauRechercheGlobale";

const entete = {
  titre: "Mon Portefeuille",
};

const WrappedPortefeuille = ({ items }) => {
  const history = useHistory();

  const allerAuReporting = () => {
    localStorage.setItem(
      "page courante",
      `${routesNames.app}${routesNames.reporting}${routesNames.synthese}`
    );
    history.push(
      `${routesNames.app}${routesNames.reporting}${routesNames.synthese}`
    );
  };

  const CATTC =
    items.radar.length > 0 &&
    items.radar.filter((elt) => elt.cible === "Conseiller").length > 0
      ? items.radar.filter((elt) => elt.cible === "Conseiller")[0].caActes || 0
      : 0;
  const tauxMandatsExclusifs =
    items.radar.length > 0 &&
    items.radar.filter((elt) => elt.cible === "Conseiller").length > 0
      ? items.radar.filter((elt) => elt.cible === "Conseiller")[0]
          .pourcentageExclus || 0
      : 0;
  const anneeEnCours = new Date().getFullYear();
  return (
    <PanelRechercheGlobale
      Icone={BusinessCenterOutlined}
      entete={entete}
      voirTout={allerAuReporting}
      titreVoirTout={"Reporting"}
    >
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <MiniKPI
              libelle={"Mandats actifs"}
              valeur={items.stockEnCours.nombreMandatsActifs}
            />
          </Grid>
          <Grid item xs={6}>
            <MiniKPI
              libelle={`Taux mandats exclusifs en ${anneeEnCours}`}
              valeur={`${Math.round(tauxMandatsExclusifs)} %`}
            />
          </Grid>
          <Grid item xs={6}>
            <MiniKPI
              libelle={"Compromis en cours"}
              valeur={formatPrix(items.stockEnCours.stockCACompromis)}
            />
          </Grid>
          <Grid item xs={6}>
            <MiniKPI
              libelle={`CA TTC en ${anneeEnCours}`}
              valeur={formatPrix(CATTC)}
            />
          </Grid>
        </Grid>
      </div>
    </PanelRechercheGlobale>
  );
};

const MiniKPI = ({ libelle = "", valeur = "" }) => {
  const theme = useTheme();
  return (
    <div>
      <Typography variant={"h1"} style={{ color: theme.palette.tonique }}>
        {valeur}
      </Typography>
      <Typography variant={"h4"}>{libelle}</Typography>
    </div>
  );
};

const params = {};
const Portefeuille = () => (
  <DataProvider
    dataReader={useSynthese}
    erreur={{ mini: true, texte: "Serveur non disponible.", titre: "Synthèse" }}
    params={params}
  >
    <WrappedPortefeuille />
  </DataProvider>
);

export default Portefeuille;
