import { AppBar, Box, Grid, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArchiveOutlined } from "@material-ui/icons";
import SettingsIcon from "@material-ui/icons/Settings";
import { Tab as DetailTab } from "components/Fiche/DetailedEntityTabsLayout";
import TitrePage from "components/TitrePage";
import { useTabs } from "hooks/useTabs";
import { HitsSkeleton } from "layoutContents/components/HitsContainer/HitsSkeleton";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import routesNames from "router/routesNames";
import { useServices } from "services";
import { NotificationDto } from "services/notifications/Dto/notificationsDto";
import {
  ARCHIVED,
  NEW_OR_READ,
} from "services/notifications/notificationStates";
import { useAgentNotifications } from "services/user";
import { ITheme } from "theme/ts/main";
import { ActionsMenu } from "UI/shared/organisms/ActionsMenu";
import { ErrorPanel } from "UI/shared/templates/Panels/ErrorPanel";
import EmptyNotifications from "./EmptyNotifications";
import { NotificationsList } from "./NotificationsList";

const useStyles = makeStyles((theme: ITheme) => ({
  indicator: {
    backgroundColor: theme.palette.tonique,
  },
  root: { background: "#FFFFFF", height: "100%" },
  tabRoot: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  tabsRoot: {
    backgroundColor: theme.palette.common.white,
  },
  titre: {
    alignItems: "center",
    display: "flex",
    marginBottom: 10,
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

enum NotificationTabNames {
  Archive = "archive",
  Home = "home",
}

type TabName = "archive" | "home" | undefined;

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export const NotificationsCenter: React.FC = () => {
  const { tab } = useParams<{
    tab: TabName | undefined;
  }>();

  const classes = useStyles();

  const {
    newAndReadNotificationsPages,
    archivedNotificationsPages,
    markAsRead,
    status,
    newAndReadLoadNewPage,
    archivedLoadNewPage,
  } = useAgentNotifications();

  const { markAllAsArchived } = useAgentNotifications();

  const {
    l10n: { t },
  } = useServices();

  useEffect(() => {
    const markAsReadHandler = () => {
      markAsRead();
    };
    window.addEventListener("beforeunload", markAsReadHandler);

    return () => {
      window.removeEventListener("beforeunload", markAsReadHandler);
    };
  }, [markAsRead]);

  useEffect(() => markAsRead, [markAsRead]);

  const tabInfos: DetailTab[] = [
    {
      content: <></>,
      titre: "",
      value: String(NotificationTabNames.Home),
    },
    {
      content: <></>,
      titre: "",
      value: String(NotificationTabNames.Archive),
    },
  ];

  const activeNotificationsCount = newAndReadNotificationsPages[0]?.total ?? 0;
  const archivedNotificationsCount = archivedNotificationsPages[0]?.total ?? 0;

  const tabs = [
    `${t("Notifications")} (${activeNotificationsCount})`,
    `${t("Archivées")} (${archivedNotificationsCount})`,
  ];

  const { activeTabIndex, onSelectedTabChanged } = useTabs<TabName>(
    tabInfos,
    tab || NotificationTabNames.Home,
    (tabValue) => {
      if (tabValue === NotificationTabNames.Home) {
        return `${routesNames.app}${routesNames.notifications}/`;
      }
      return `${routesNames.app}${routesNames.notifications}/${tabValue}`;
    }
  );

  const onChange = (_: React.ChangeEvent<unknown>, newValue: number) => {
    onSelectedTabChanged({ tabIndex: newValue });
  };

  const history = useHistory();

  const settingsAction = {
    action: () => {
      history.push("/app/conseiller/monProfil/parametres");
    },
    icon: <SettingsIcon />,
    label: "Paramètres",
  };

  const archiveAllAction = {
    action: () => {
      markAllAsArchived();
    },
    icon: <ArchiveOutlined />,
    label: "Archiver tout",
  };

  const actions =
    tab === undefined || ""
      ? [settingsAction, archiveAllAction]
      : [settingsAction];

  const newOrReadNotifications = newAndReadNotificationsPages.reduce(
    (aggregate, page) => aggregate.concat(page.documents),
    [] as NotificationDto[]
  );

  const archivedNotifications = archivedNotificationsPages.reduce(
    (aggregate, page) => aggregate.concat(page.documents),
    [] as NotificationDto[]
  );

  const displayNewOrReadTabsSkeleton =
    status === "pending" && newOrReadNotifications.length === 0;

  const displayArchivedTabsSkeleton =
    status === "pending" && archivedNotifications.length === 0;

  const getTabPanelContent = (
    notifications: NotificationDto[],
    state: string,
    loadPage: () => void,
    displaySkeleton: boolean
  ) => {
    if (displaySkeleton) {
      return HitsSkeleton(1, "list");
    }

    if (notifications.length > 0) {
      return (
        <NotificationsList notifications={notifications} loadPage={loadPage} />
      );
    }

    return <EmptyNotifications notificationState={state} />;
  };

  if (status === "rejected") {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <ErrorPanel title={"Centre des notifications"} text={"indisponible"} />
      </div>
    );
  }

  return (
    <div>
      <div>
        <TitrePage
          categorie=""
          page={"Centre de notifications"}
          nbResultats=""
          filePath={"/static/markdowns/Notifications/notifications.md"}
        />

        <Grid item xs={12} className={classes.root}>
          <AppBar position="static" color="default">
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
              }}
            >
              <Tabs
                value={activeTabIndex}
                classes={{
                  indicator: classes.indicator,
                  root: classes.tabsRoot,
                }}
                aria-label={"notifications"}
                onChange={onChange}
              >
                {tabs.map((currentTab) => (
                  <Tab
                    key={currentTab}
                    label={currentTab}
                    id={`full-width-tab-${currentTab}`}
                    classes={{ root: classes.tabRoot }}
                  />
                ))}
              </Tabs>

              <ActionsMenu menuOptions={actions} />
            </div>
          </AppBar>
          <TabPanel value={activeTabIndex} index={0}>
            {getTabPanelContent(
              newOrReadNotifications,
              NEW_OR_READ,
              newAndReadLoadNewPage,
              displayNewOrReadTabsSkeleton
            )}
          </TabPanel>
          <TabPanel value={activeTabIndex} index={1}>
            {getTabPanelContent(
              archivedNotifications,
              ARCHIVED,
              archivedLoadNewPage,
              displayArchivedTabsSkeleton
            )}
          </TabPanel>
        </Grid>
      </div>
    </div>
  );
};
