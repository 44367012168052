import { IconButton, makeStyles, useMediaQuery } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { DEFAULT_SCROLL_CONTAINER_ID } from "UI/shared/organisms/InfiniteScrollList/InfiniteScrollList";
import React, { CSSProperties, useState } from "react";
import { InView } from "react-intersection-observer";
import { ITheme } from "theme/ts/main";
import { Maybe } from "types/utilityTypes";

const useStyles = makeStyles((theme) => ({
  goUpButton: {
    backgroundColor: "rgb(0,0,0,0.5)",
    bottom: (props: CSSProperties) => props.bottom,
    color: "white",
    position: (props: CSSProperties) => props.position,
    left: (props: CSSProperties) => props.left,
    zIndex: 900,
  },
  goUpButtonMap: {
    backgroundColor: "rgb(0,0,0,0.5)",
    bottom: theme.spacing(2),
    color: "white",
    position: "sticky",
    right: theme.spacing(2),
  },
}));

const getTabParent = (startElement: Maybe<HTMLElement>) => {
  if (!startElement) return null;

  let current: HTMLElement | null | undefined = startElement;

  while (!(current?.parentElement?.id || "").match(/tabpanel/i)) {
    current = current?.parentElement;
  }

  return current;
};

export const ScrollToTopButton: React.FC<{
  inTabs?: boolean;
  containerId?: string;
}> = ({ inTabs, containerId = DEFAULT_SCROLL_CONTAINER_ID }) => {
  const isSmall = useMediaQuery((theme: ITheme) =>
    theme.breakpoints.down("sm")
  );

  const classes = useStyles({
    position: isSmall ? "fixed" : "sticky",
    bottom: 16,
    left: 16,
  });

  const getContainer = () => {
    const id = inTabs ? "ScrollToTopButton" : containerId;
    const element = document.getElementById(id);

    return inTabs ? getTabParent(element) : element;
  };

  const handleScrollToTop = () => {
    const container = getContainer();
    const scrollOptions: ScrollToOptions = {
      behavior: "smooth",
      top: 0,
    };

    if (container) {
      container.scrollTo(scrollOptions);
      return;
    }

    window.scrollTo(scrollOptions);
  };

  return (
    <IconButton
      id="ScrollToTopButton"
      aria-label="go up"
      size="medium"
      className={classes.goUpButton}
      onClick={handleScrollToTop}
      style={{ height: "50px", width: "50px" }}
    >
      <ArrowUpwardIcon />
    </IconButton>
  );
};

export const ScrollToLeftButton: React.FC<{ containerId?: string }> = ({
  containerId,
}) => {
  const classes = useStyles({ position: "relative", bottom: 80, left: 8 });
  const handleScrollToLeft = () => {
    const container = document.getElementById(
      containerId ?? DEFAULT_SCROLL_CONTAINER_ID
    );

    if (container) {
      container.scrollTo({ behavior: "smooth", left: 0 });
      return;
    }

    window.scrollTo({ behavior: "smooth", left: 0 });
  };

  return (
    <IconButton
      id="ScrollToLeftButton"
      aria-label="go beginning"
      size="medium"
      className={classes.goUpButton}
      onClick={handleScrollToLeft}
      style={{ height: "50px", width: "50px" }}
    >
      <ArrowBackIcon />
    </IconButton>
  );
};

export const useInfiniteScroll = () => {
  const [firstVisible, setFirstVisible] = useState(true);
  const [lastVisible, setLastVisible] = useState(false);

  const onViewChangeFirst = (inView: boolean) => {
    setFirstVisible(inView);
  };

  const onViewChangeLast = (inView: boolean) => {
    setLastVisible(inView);
  };

  const ViewObserverFirst: React.FC = ({ children }) => (
    <InView as="div" onChange={onViewChangeFirst}>
      {children}
    </InView>
  );

  const ViewObserverLast: React.FC = ({ children }) => (
    <InView as="div" onChange={onViewChangeLast}>
      {children}
    </InView>
  );

  return {
    ViewObserverFirst,
    ViewObserverLast,
    firstVisible,
    lastVisible,
  };
};
