import { Grid, useTheme } from "@material-ui/core";
import { AssignmentOutlined, Euro, PersonOutlined, ImportantDevicesOutlined } from "@material-ui/icons";
import { RefinementField } from "components/RefinementField";
import {
  AdaptedRefinements,
  AutoCompleteText,
  Refinement,
} from "components/RefinementField/types";
import React from "react";
import { useServices } from "services";
import { ITheme } from "theme/ts/main";
import { MoreFiltersCategory, ProfilesCriteria } from "types";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";
import { WithToniqueColor } from "UI/shared/atoms/WithToniqueColor";
import { SwipeableContainer } from "UI/shared/molecules/SwipeableContainer/SwipeableContainer";
import { TitleWithIcon } from "UI/shared/molecules/TitleWithIcon";
import { MoreFiltersFooter } from "./MoreFiltersFooter";

export interface MoreFiltersProps {
  adaptedRefinements: AdaptedRefinements<ProfilesCriteria>;
  searchResultCount: number;
  onChange: (value: { key: ProfilesCriteria; value: Refinement }) => void;
}

const getRefinementsSection = (
  key: MoreFiltersCategory,
  adaptedRefinements: AdaptedRefinements<ProfilesCriteria>
) => {
  return (Object.keys(adaptedRefinements) as ProfilesCriteria[]).filter(
    (k) => adaptedRefinements[k].moreFiltersCategory === key
  );
};

const getL10nCriteriaKey = (key: ProfilesCriteria) => `Profile.Criteria.${key}`;

export const MoreFilters: React.FC<MoreFiltersProps> = ({
  searchResultCount,
  adaptedRefinements,
  onChange,
}) => {
  const {
    l10n: { t },
  } = useServices();

  const theme = useTheme() as ITheme;
  
  const getAutocompleteProps = (refinement: AutoCompleteText) => ({
    getOptions: refinement.getOptions,
  });

  const Section = React.useCallback(
    (
      sectionKey: MoreFiltersCategory,
      adaptedRefinements: AdaptedRefinements<ProfilesCriteria>
    ) => (
      <>
        {getRefinementsSection(sectionKey, adaptedRefinements).map(
          (key: ProfilesCriteria) => {
            return (
              <div style={{ padding: 10 }} key={`more-filters-${key}`}>
                <RefinementField
                  onChange={(v) => onChange({ key, value: v })}
                  value={adaptedRefinements[key].refinement}
                  label={t(getL10nCriteriaKey(key))}
                  autoComplete={
                    adaptedRefinements[key].refinement.kind ===
                    "autocomplete-text"
                      ? getAutocompleteProps(
                          adaptedRefinements[key].refinement as AutoCompleteText
                        )
                      : undefined
                  }
                />
              </div>
            );
          }
        )}
      </>
    ),
    [adaptedRefinements]
  );

  return (
    <SwipeableContainer
      anchor="right"
      width={700}
      headerTitle={"Recherche détaillée"}
      borderColor={theme.palette.tonique}
    >
      <div style={{ display: "flex", height: "100%" }}>
      <Grid item xs={6}>
        <PaddingContainer bottom={10} top={10}>
              <TitleWithIcon
                icon={WithToniqueColor(ImportantDevicesOutlined)}
                title={"Affichage"}
              />
              {Section("display", adaptedRefinements)}
        </PaddingContainer>
      </Grid>
        <Grid item xs={6}>
          <PaddingContainer bottom={10} top={10}>
            <TitleWithIcon
              icon={WithToniqueColor(AssignmentOutlined)}
              title={"Caractéristiques"}
            ></TitleWithIcon>
            {Section("characteristics", adaptedRefinements)}
          </PaddingContainer>
        </Grid>

        <Grid item xs={6}>
          <PaddingContainer bottom={10} top={10}>
            <TitleWithIcon
              icon={WithToniqueColor(PersonOutlined)}
              title={"Contact"}
            ></TitleWithIcon>
            {Section("contact", adaptedRefinements)}
          </PaddingContainer>

          <PaddingContainer bottom={10} top={15}>
            <TitleWithIcon
              icon={WithToniqueColor(Euro)}
              title={"Budget"}
            ></TitleWithIcon>
            {Section("budget", adaptedRefinements)}
          </PaddingContainer>
        </Grid>
      </div>

      <MoreFiltersFooter
        entityName={t(
          `Profile.EntityName.${searchResultCount <= 1 ? "singular" : "plural"}`
        )}
        total={searchResultCount}
        onClose={() => {}}
      />
    </SwipeableContainer>
  );
};
