import DownloadIcon from "@material-ui/icons/GetAppOutlined";
import React from "react";

import { ButtonWithStartIcon } from "./ButtonWithStartIcon";

interface ExportButtonProps {
  clickHandler: () => void;
  label: string;
}

export const ExportButton: React.FC<ExportButtonProps> = ({
  label,
  clickHandler,
}) => {
  return (
    <ButtonWithStartIcon
      onClick={clickHandler}
      label={label}
      icon={<DownloadIcon />}
      noUppercaseLabel={true}
      variant="outlined"
      color="primary"
      backColor="white"
    />
  );
};
