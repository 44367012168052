import { makeStyles } from "@material-ui/core/styles";
import { ITheme } from "../../theme/ts/main";

export const useStyles = makeStyles((theme: ITheme) => ({
  avatar: {
    height: 75,
    width: 75,
    [theme.breakpoints.down("sm")]: {
      height: 30,
      width: 30,
    },
  },
  badge: {
    cursor: "pointer",
    margin: theme.spacing(2),
  },
  badgeColor: {
    backgroundColor: theme.palette.tonique,
  },
  bonjour: {
    color: theme.palette.default,
    lineHeight: 1,
  },
  boutonBeige: {
    backgroundColor: theme.palette.beige,
    color: "white",
  },
  card: {
    display: "flex",
    height: "100%",
    padding: 0,
  },
  container: {
    display: "flex",
    flexDirection: "row",
  },
  content: {
    width: "100%",
  },
  contentMarkdown: {
    flex: "1 0 auto",
    fontFamily: '"Gill Sans", sans-serif',
  },
  controls: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  cover: {
    width: 151,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  iconBouton: {
    marginRight: 10,
  },
  icons: {
    color: theme.palette.default,
    height: 22,
    width: 22,
  },
  iconsContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "0.5em",
  },
  nom: {
    color: theme.palette.default,
    fontWeight: "bold",
    lineHeight: 1,
  },
  photo: {
    cursor: "pointer",
  },
  radioBeige: {
    color: theme.palette.beige,
  },
  texteSimple: {
    color: theme.palette.greyMedium,
    lineHeight: 1,
  },
  textes: {
    alignItems: "flex-end",
    borderLeft: "0.1em solid black",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0.5em",
  },
}));
