import { Grid, IconButton, Typography, useTheme } from "@material-ui/core";
import {
  BookmarkBorderOutlined,
  GradeOutlined,
  GroupAddOutlined,
  ScatterPlotOutlined,
} from "@material-ui/icons";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import routesNames from "router/routesNames";
import { PanelRechercheGlobale } from "routes/app/recherche-globale/components/PanneauRechercheGlobale";
import { useFeatureFlags } from "services/featureFlags";

const entete = {
  sousTitre: "",
  titre: "Mon Espace de travail",
};

const EspaceTravail = () => {
  const history = useHistory();
  const allerACreationContact = useCallback(() => {
    const route = `${routesNames.app}${routesNames.contacts}/creation`;
    localStorage.setItem("page courante", route);
    history.push(route);
  }, [history]);
  const allerAClassement = useCallback(() => {
    const route = `${routesNames.app}${routesNames.reporting}${routesNames.classement}`;
    localStorage.setItem("page courante", route);
    history.push(route);
  }, [history]);

  const gotoPige = useCallback(() => {
    const route = `${routesNames.listeBiensPige}?slug=full-list`;
    localStorage.setItem("page courante", route);
    history.push(route);
  }, [history]);

  const features = useFeatureFlags();

  return (
    <PanelRechercheGlobale Icone={BookmarkBorderOutlined} entete={entete}>
      <Grid container spacinf={2}>
        <Grid item xs={4}>
          <BoutonCouleur
            icone={
              <GroupAddOutlined
                style={{ color: "white", fontSize: 50, padding: 10 }}
              />
            }
            libelle={"Créer un contact"}
            onClick={allerACreationContact}
            enabled={true}
          />
        </Grid>
        <Grid item xs={4}>
          <BoutonCouleur
            icone={
              <GradeOutlined
                style={{ color: "white", fontSize: 50, padding: 10 }}
              />
            }
            libelle={"Mon classement"}
            onClick={allerAClassement}
            enabled={true}
          />
        </Grid>
        <Grid item xs={4}>
          <BoutonCouleur
            icone={
              <ScatterPlotOutlined
                style={{
                  color: "white",
                  fontSize: 50,
                  padding: 10,
                }}
              />
            }
            libelle={"Consulter la pige"}
            enabled={features.Pige}
            onClick={gotoPige}
          />
        </Grid>
      </Grid>
    </PanelRechercheGlobale>
  );
};

export default EspaceTravail;

const BoutonCouleur = ({ icone, libelle, onClick = () => {}, enabled }) => {
  const theme = useTheme();
  return (
    <Grid
      container
      direction={"column"}
      justify={"center"}
      alignItems={"center"}
    >
      <Grid item xs={12}>
        <IconButton
          onClick={onClick}
          style={{
            backgroundColor: enabled
              ? theme.palette.tonique
              : theme.palette.notSelected.grey,
          }}
        >
          {icone}
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h5"}>{libelle}</Typography>
      </Grid>
    </Grid>
  );
};
