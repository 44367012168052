import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import { useServices } from "services";

const useStyles = makeStyles((theme) => ({
  chipLabel: (props) => ({
    color: "#FF7B1E",
    fontSize: props.fontSize,
    fontWeight: "bold",
  }),
  exclusif: {
    backgroundColor: "#FFD2B3",
    border: "solid 1px #FFD2B3",
    marginTop: 2,
  },
}));

const Exclusivite = (props) => {
  const { mode = "normal" } = props;

  const {
    l10n: { t },
  } = useServices();

  const classes = useStyles({
    fontSize: mode === "mini" ? "0.6rem" : "0.9rem",
  });

  return (
    <Chip
      label={t("Exclusivité")}
      size="small"
      className={classes.exclusif}
      classes={{ label: classes.chipLabel }}
    />
  );
};

export default Exclusivite;
