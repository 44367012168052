import { Grid, Typography } from "@material-ui/core";
import React from "react";

import { useServices } from "../../../../../services";

const Composition = (props) => {
  const { composition } = props;
  const {
    l10n: { t },
  } = useServices();
  return (
    <Grid container spacing={0}>
      {composition.nbPieces > 0 && (
        <>
          <Grid item xs={6}>
            <Typography variant="subtitle1" align="right">
              {t("Nb de pièces")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h4">{composition.nbPieces}</Typography>
          </Grid>
        </>
      )}
      {composition.nbChambres > 0 && (
        <>
          <Grid item xs={6}>
            <Typography variant="subtitle1" align="right">
              {t("Nb de chambres")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h4">{composition.nbChambres}</Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Composition;
