import { Grid } from "@material-ui/core";
import { useBuckets } from "hooks/useBuckets";
import React, { useCallback } from "react";
import { RealEstatePropertyCard } from "UI/features/realEstates/organisms/RealEstatePropertyCard";
import { InfiniteScrollList } from "UI/shared/organisms/InfiniteScrollList/InfiniteScrollList";
import { generateUniqueKey } from ".";
import { SearchResultProps } from "../../props";
import { toRealEstatePropertyCardProps } from "../adapters/toRealEstatePropertyCardProps";

export const RealEstatesGalleryLarge: React.FC<SearchResultProps> = ({
  items,
  loadMore,
  onClick,
}) => {
  const handleClick = useCallback(
    (id: string) => () => {
      onClick?.(id);
    },
    [onClick]
  );

  const itemsBuckets = useBuckets<any>({ data: items, size: 4 });
  return (
    <InfiniteScrollList pageSize={10} loadMore={loadMore} wrapItems={true}>
      {itemsBuckets.map((bucket, i) => (
        <Grid container spacing={2} key={`real-estates-bucket-${i}`}>
          {bucket.map((item: any) => (
            <Grid item xs={3} key={generateUniqueKey(item)}>
              <RealEstatePropertyCard
                {...toRealEstatePropertyCardProps(item, handleClick(item.id))}
              />
            </Grid>
          ))}
        </Grid>
      ))}
    </InfiniteScrollList>
  );
};
