export class MatchingProfileRequestDto {
  profileKind?: "Purchase" | "Rent" | "SearchLifeAnnuity";
  propertyKind?:
    | "Appartement"
    | "Goodwill"
    | "Building"
    | "Premises"
    | "House"
    | "Land"
    | "Parking";
  cityId?: string;
  budget?: number;
  surface?: number;
  nbRooms?: number;
  constructionYear?: number;
  withParking?: boolean;
  withElevator?: boolean;
  withPool?: boolean;
  withSingleStorey?: boolean;
  constructor(init?: Partial<MatchingProfileRequestDto>) {
    Object.assign(this, init);
  }
}
