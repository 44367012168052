import jwt from "jsonwebtoken";
import OIDC, { UserManagerSettings } from "oidc-client";
import React, { createContext, useState } from "react";
import { useServices } from "services";

interface AuthenticationContextProps {
  access_token: string | null;
  bu: string | null;
}
export const AuthenticationContext = createContext<AuthenticationContextProps>({
  access_token: null,
  bu: null,
});

export const AuthenticationContextProvider: React.FC = ({ children }) => {
  const { authenticationService } = useServices();
  const [state, setState] = useState<AuthenticationContextProps>({
    access_token: authenticationService.getUserData().access_token,
    bu: authenticationService.getUserData().Instance,
  });

  authenticationService.setCallback(setState);
  return (
    <AuthenticationContext.Provider
      value={{ access_token: state.access_token, bu: state.bu }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export interface IAuthenticationConfiguration {
  authority: string;
  automaticSilentRenew: boolean;
  client_id: string;
  post_logout_redirect_uri: string;
  redirect_uri: string;
  response_type: string;
  revokeAccessTokenOnSignout: boolean;
  scope: string;
  silent_redirect_uri: string;
  loadUserInfo: boolean;
}

export interface IStore {
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
}

type UserFromTremplinX = any;

// TODO : replace with right type
export type IUserData = any;

export interface SigninResponse {
  user: UserFromTremplinX;
  from: string;
}

export interface AuthenticationService {
  getUserData(): Partial<IUserData>;
  isAuthenticated(): boolean;
  login(): void;
  logout(): void;
  processSigninResponse(): Promise<SigninResponse>;
  setCallback(callback: (state: AuthenticationContextProps) => void): void;
}

export class OidcAuthenticationService implements AuthenticationService {
  private readonly authenticationConfiguration: UserManagerSettings;
  private readonly store: IStore;
  private readonly userManager: OIDC.UserManager;
  private static readonly STORAGE_KEY_NAME = "UserFromTremplinX";
  private callback?: (state: AuthenticationContextProps) => void;

  setCallback(callback: (state: AuthenticationContextProps) => void): void {
    this.callback = callback;
  }
  getStorage(): IStore {
    return this.store;
  }

  public setUserData(oidcUser: OIDC.User): void {
    const userData: IUserData = {
      ...oidcUser,
      ...(jwt.decode(oidcUser.access_token) as { [p: string]: any }),
      language: "fr",
    };

    this.getStorage().setItem(
      OidcAuthenticationService.STORAGE_KEY_NAME,
      JSON.stringify(userData)
    );
    if (this.callback)
      this.callback({
        access_token: userData.access_token,
        bu: userData.Instance,
      });
  }

  public getUserData(): Partial<IUserData> {
    const userData = this.getStorage().getItem(
      OidcAuthenticationService.STORAGE_KEY_NAME
    );
    if (userData) {
      return JSON.parse(userData);
    }

    return {};
  }

  public removeUserData(): void {
    this.getStorage().removeItem(OidcAuthenticationService.STORAGE_KEY_NAME);
    if (this.callback) this.callback({ access_token: null, bu: null });
  }

  public login(): void {
    if (this.isAuthenticated()) {
      return;
    }
    this.userManager
      .createSigninRequest()
      .then((response) => {
        window.location.href = response.url;
      })
      .catch((e) => console.log(e));
  }

  public logout(): void {
    this.removeUserData();
    sessionStorage.clear();
    this.userManager.clearStaleState().then(() =>
      this.userManager
        .signoutRedirect()
        .then(() => {
          this.userManager.removeUser();
        })
        .catch((error) => console.log(error))
    );
  }

  constructor(
    authenticationConfiguration: UserManagerSettings,
    store: IStore = window.localStorage
  ) {
    this.authenticationConfiguration = authenticationConfiguration;
    this.store = store;
    this.userManager = new OIDC.UserManager(authenticationConfiguration);

    this.getUserData = this.getUserData.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.getStorage = this.getStorage.bind(this);
    this.callback = undefined;

    this.userManager.events.addAccessTokenExpiring(() => {
      this.userManager
        .signinSilent({
          response_type: authenticationConfiguration.response_type,
          scope: authenticationConfiguration.scope,
        })
        .then((user: any) => {
          console.log("signinSilent::success");
          this.setUserData(user);
        })
        .catch((error: Error) => {
          console.log("signinSilent::error");
          this.userManager.getUser().then((user: any) => {
            this.setUserData(user);
          });
        });
    });
  }

  public isAuthenticated(): boolean {
    const userData = this?.getUserData();

    if (!userData) return false;

    if (new Date(userData.expires_at * 1000) <= new Date()) {
      return false;
    }

    return userData.access_token != null;
  }

  public async processSigninResponse(): Promise<SigninResponse> {
    let user: IUserData;
    try {
      user = await this.userManager.signinRedirectCallback();
    } catch (e) {
      this.login();
    }
    if (user) {
      this.setUserData(user);
      return Promise.resolve({
        from: this.getPageCourante(),
        user: this.getUserData(),
      });
    }

    return Promise.reject("erreur signinResponse");
  }

  private getPageCourante() {
    return sessionStorage.getItem("page courante") || "";
  }
}
