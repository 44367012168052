import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Mood, MoodBad, SentimentSatisfied } from "@material-ui/icons";
import MyDivider from "components/CarteInfo/Divider";
import React from "react";
import Skeleton from "react-loading-skeleton";

import { useServices } from "../../services";

const useStyles = makeStyles((theme) => ({
  bad: {
    fill: "red",
    fontSize: "5rem",
    marginTop: "20px",
  },
  card: {
    height: "100%",
    marginTop: "10px",
    padding: theme.spacing(1),
    textAlign: "center",
    width: "100%",
  },
  cardContent: {
    margin: "auto",
    textAlign: "center",
  },
  good: {
    fill: "green",
    fontSize: "5rem",
    marginTop: "20px",
  },
  middle: {
    fill: "orange",
    fontSize: "5rem",
    marginTop: "20px",
  },
  pos: {
    marginBottom: 12,
  },
  rang: {
    fontSize: "2rem",
    marginBottom: "20px",
  },
  title: {
    fontSize: 14,
  },
}));

const CarteInfo = (props) => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();
  // Si c'est une petite carte classement, calcul des % suivant les seuils fournis
  let rang = null;
  let moodClassement = <SentimentSatisfied className={classes.middle} />;

  if (props.classement && props.value !== null) {
    switch (props.type) {
      case "mandat":
        rang = props.value.classementMandats;
        break;
      case "ca":
        rang = props.value.classementCA;
        break;
      default:
        rang = props.value.classementPourcentageExclu;
    }
    let classement = (rang / props.value.nombreConseillers) * 100;
    moodClassement =
      classement <= props.seuils[0] ? (
        <Mood className={classes.good} />
      ) : (
        moodClassement
      );
    moodClassement =
      classement >= props.seuils[1] ? (
        <MoodBad className={classes.bad} />
      ) : (
        moodClassement
      );
  }
  // fin de calcul des seuils pour carte classement

  return (
    <Card className={classes.card}>
      {!(props.classement && props.value) ? (
        props.children
      ) : (
        <div>
          <Typography variant="h5" className={classes.rang}>
            {rang || <Skeleton />} {t("Eme")}
          </Typography>
          <MyDivider title={props.title || <Skeleton />} />
          {moodClassement || <Skeleton />}
        </div>
      )}
    </Card>
  );
};

export default CarteInfo;
