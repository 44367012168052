import { IconButton, Tooltip } from "@material-ui/core";
import { Launch } from "@material-ui/icons";
import React from "react";
import { useServices } from "services";

export const OpenAd: React.FC<{ url: string; withText?: boolean }> = ({
  url,
  withText,
}) => {
  const {
    l10n: { t },
  } = useServices();

  const onClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(url);
  };

  return !withText ? (
    <>
      <Tooltip title={t("Ouvrir le site de publication")}>
        <IconButton onClick={onClick}>
          <Launch />
        </IconButton>
      </Tooltip>
    </>
  ) : (
    <>
      <div onClick={onClick}>
        <IconButton>
          <Launch />
        </IconButton>
        <span>{t("Consulter")}</span>
      </div>
    </>
  );
};
