import ElasticAdapter from "api/DTOs/Common/ElasticSearch/ElasticAdapter";
import { array, bool, number, string } from "api/DTOs/Common/Types";

import conseillerAdapter from "../../ConseillerAdapter";
import villeAdapter from "../../VilleAdapter";

function RechercheGlobaleAdapter(result = {}) {
  const elasticResult = ElasticAdapter.toBO(result);
  elasticResult.data = elasticResult.data.map((item) => itemToBO(item));
  return elasticResult;
}

function itemToBO(item = {}) {
  const bo = {
    bu: item.bu || string,
    dateAjout: item.dateAjout || string,
    dateModification: item.dateModification || string,
    familleBienEnum: item.familleBienEnum || string,
    id: item.id || string,
    nbChambres: item.nbChambres || number,
    codeAffaire: item.codeAffaire || string,
    nbPieces: item.nbPieces || number,
    conseillerPrincipal:
      item.conseillers.length > 0
        ? conseillerAdapter(
            item.conseillers.filter(
              (conseiller) => conseiller.estPrincipal === true
            )[0]
          )
        : conseillerAdapter(),
    nombrePhotos: item.nombrePhotos || number,
    conseillers:
      item.conseillers.length > 0
        ? item.conseillers.map((conseiller) => conseillerAdapter(conseiller))
        : array,
    photoPrincipale: item.photoPrincipale || string,
    exclusivite: item.exclusivite || bool,
    prix: item.prix || number,
    localisation: item.localisation
      ? villeAdapter(item.localisation)
      : item.localisation,
    surface: item.surface || number,
    neuf: item.neuf || bool,
    surfaceTerrain: item.surfaceTerrain || number,
    prestige: item.prestige || bool,
    typeAchatEnum: item.typeAchatEnum || string,
    typeAffaireEnum: item.typeAffaireEnum || string,
    typeBienNom: item.typeBienNom || string,
    typeMandatEnum: item.typeMandatEnum || string,
    isCE: item.isCE || bool,
  };
  return bo;
}

export default RechercheGlobaleAdapter;
