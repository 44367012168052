import { buColors } from "utils/formatage";
import L from "leaflet";

const iconMapConseiller = ({ bu, role }) => {
  const iconConseillerSVG = `<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M12,2C8.14,2,5,5.14,5,9c0,5.25,7,13,7,13s7-7.75,7-13C19,5.14,15.86,2,12,2z M12,4c1.1,0,2,0.9,2,2c0,1.11-0.9,2-2,2 s-2-0.89-2-2C10,4.9,10.9,4,12,4z M12,14c-1.67,0-3.14-0.85-4-2.15c0.02-1.32,2.67-2.05,4-2.05s3.98,0.73,4,2.05 C15.14,13.15,13.67,14,12,14z" fill="${buColors[bu]}"/></svg>`;
  const iconStaffSVG = `<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M12 11c1.33 0 4 .67 4 2v.16c-.97 1.12-2.4 1.84-4 1.84s-3.03-.72-4-1.84V13c0-1.33 2.67-2 4-2zm0-1c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6 .2C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z" fill="${buColors[bu]}"/></svg>`;
  const iconGroupeSVG = `<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" fill="${buColors["Digitregroup"]}"/></svg>`;

  let selectedIconSVG;

  switch (role) {
    case "Négociateur":
    case "Conseiller":
      selectedIconSVG = iconConseillerSVG;
      break;
    case "Groupe":
      selectedIconSVG = iconGroupeSVG;
      break;
    default:
      selectedIconSVG = iconStaffSVG;
  }

  selectedIconSVG = encodeURI("data:image/svg+xml," + selectedIconSVG).replace(
    "#",
    "%23"
  );

  // console.log(selectedIconSVG);

  return new L.Icon({
    iconUrl: selectedIconSVG,
    iconRetinaUrl: selectedIconSVG,
    iconAnchor: [12, 24],
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [24, 24],
    // className: 'leaflet-div-icon'
  });
};

export default iconMapConseiller;
