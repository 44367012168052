const labelsMois = (t) => [
  t("Jan"),
  t("Fev"),
  t("Mar"),
  t("Avr"),
  t("Mai"),
  t("Juin"),
  t("Jui"),
  t("Aout"),
  t("Sept"),
  t("Oct"),
  t("Nov"),
  t("Dec"),
];

const colors = [
  "#37C2CE",
  "#283FB2",
  "#FF9E25",
  "#D84466",
  "#F4C500",
  "#5473E8",
];

export const getOptionPieBiens = (t, theme, title, color, serie) => ({
  color,

  series: [
    {
      animation: true,
      animationDelay: 0,
      animationDelayUpdate: 0,
      animationDuration: 1000,
      animationDurationUpdate: 300,
      animationEasing: "cubicOut",
      animationEasingUpdate: "cubicOut",
      animationThreshold: 2000,
      animationType: "expansion",
      center: ["50%", "50%"],
      data: serie.data.map((item) => ({
        name: t(item.famille),
        value: item.nombre,
      })),
      itemStyle: {
        emphasis: {
          shadowBlur: 10,
          shadowColor: "rgba(0, 0, 0, 0.5)",
          shadowOffsetX: 5,
        },
      },
      label: {
        normal: {
          formatter: "{b}：{c}  ({d}%)  ",
        },
      },
      name: t(serie.name),
      radius: "55%",
      selectedMode: "single",
      type: "pie",
    },
  ],
  title: title,
  toolbox: {
    feature: {
      dataView: {
        readOnly: false,
        show: false,
        title: "Voir les données",
      },
      mark: { show: false },
      restore: { show: true, title: "Réinitialiser" },
      saveAsImage: { show: true, title: "Télécharger image" },
    },
    show: true,
  },
  tooltip: {
    formatter: "{a} <br/>{b} : {c} ({d}%)",
    trigger: "item",
  },
});

export const getOptionRadarSynthese = (t, theme, title, color, serie) => ({
  color: colors,
  legend: {
    data: serie.data.map((item) => t(item.cible)),
    orient: "vertical",
    textStyle: {
      color: theme.palette.primary.dark,
    },
    x: "right",
    y: "bottom",
  },
  polar: [
    {
      center: ["50%", "50%"],
      indicator: [
        {
          color: theme.palette.primary.dark,
          max: Math.max(...serie.data.map((elt) => elt.caActes)),
          text: t("CaActesTTC"),
        },
        {
          color: theme.palette.primary.dark,
          max: Math.max(...serie.data.map((elt) => elt.nombreActes)),
          text: t("NombreActes"),
        },
        {
          color: theme.palette.primary.dark,
          max: Math.max(...serie.data.map((elt) => elt.caCompromis)),
          text: t("CaCompromisTTC"),
        },
        {
          color: theme.palette.primary.dark,
          max: Math.max(...serie.data.map((elt) => elt.nombreCompromis)),
          text: t("NombreCompromis"),
        },
        {
          color: theme.palette.primary.dark,
          max: Math.max(...serie.data.map((elt) => elt.nombreMandats)),
          text: t("NombreMandats"),
        },
        {
          color: theme.palette.primary.dark,
          max: Math.max(...serie.data.map((elt) => elt.pourcentageExclus)),
          text: t("PourcentageExclu"),
        },
      ],
      radius: "70%",
      textStyle: {
        color: theme.palette.primary.dark,
      },
    },
  ],
  series: [
    {
      animationEasing: "elasticOut",
      animationType: "scale",
      data: serie.data.map((item) => ({
        name: t(item.cible),
        value: [
          item.caActes,
          item.nombreActes,
          item.caCompromis,
          item.nombreCompromis,
          item.nombreMandats,
          item.pourcentageExclus,
        ],
      })),
      itemStyle: {
        emphasis: {
          shadowBlur: 10,
          shadowColor: "rgba(0, 0, 0, 0.5)",
          shadowOffsetX: 5,
        },
      },
      label: {
        normal: {
          formatter: "{b}：{c}  ({d}%)  ",
        },
      },
      name: serie.name,
      selectedMode: "single",
      tooltip: {
        formatter: (params) => {
          return `${t(params.seriesName)} <br /> ${params.name} : <br /> ${t(
            "CA Actes"
          )} : ${Number.parseFloat(params.value[0]).toFixed(2)} € <br /> ${t(
            "Nb Actes"
          )} : ${Number.parseFloat(params.value[1]).toFixed(2)} <br /> ${t(
            "CA Compromis"
          )} : ${Number.parseFloat(params.value[2]).toFixed(2)} € <br /> ${t(
            "Nb Compromis"
          )} : ${Number.parseFloat(params.value[3]).toFixed(2)}  <br /> ${t(
            "Nb Mandats"
          )} : ${Number.parseFloat(params.value[4]).toFixed(2)}  <br /> ${t(
            "% Exclus"
          )} : ${Number.parseFloat(params.value[5]).toFixed(2)} %`;
        },
        trigger: "item",
      },
      type: "radar",
    },
  ],
  title: title,
  toolbox: {
    feature: {
      dataView: {
        readOnly: false,
        show: false,
        title: "Voir les données",
      },
      mark: { show: false },
      restore: { show: true, title: "Réinitialiser" },
      saveAsImage: { show: true, title: "Télécharger image" },
    },
    show: true,
  },
  tooltip: {
    trigger: "item",
  },
});

export const getOptionBarSynthese = (t, color, serie) => ({
  color,
  grid: {
    // left: ,
    bottom: "20%",
    // right: 5,
    top: "10%",
  },
  series: [
    {
      data: serie ? serie.map((elt) => elt.valeur) : [],
      label: {
        formatter: (params) => {
          if (params.data > 999) return `${params.data / 1000}K`;
          return params.data;
        },
        show: true,
      },
      name: "valeur",
      type: "bar",
    },
  ],
  toolbox: {
    feature: {
      dataView: {
        readOnly: false,
        show: false,
        title: "Voir les données",
      },
      mark: { show: false }, //TODO: vu plusieurs fois
      restore: { show: false, title: "Réinitialiser" },
      saveAsImage: { show: false, title: "Télécharger image" },
    },
    show: false,
  },
  tooltip: {
    trigger: "axis",
  },
  xAxis: {
    data: serie ? serie.map((elt) => elt.annee) : [],
    type: "category",
  },
  yAxis: {
    axisLabel: {
      formatter: (number) => {
        if (number > 999) return `${number / 1000}K`;
        return number;
      },
    },
    offset: -5,
    type: "value",
  },
  // center: ["50%", "50%"]
});

export const getOptionRadarBiens = (t, theme, title, color, serie) => ({
  color,
  legend: {
    data: serie.data.map((item) => t(item.cible)),
    orient: "vertical",
    textStyle: {
      color: theme.palette.primary.dark,
    },
    x: "right",
    y: "bottom",
  },
  polar: [
    {
      center: ["50%", "50%"],
      indicator: [
        {
          color: theme.palette.primary.dark,
          max: Math.max(...serie.data.map((elt) => elt.prixDeVenteMoyen)),
          text: t("PrixVenteMoyen"),
        },
        {
          color: theme.palette.primary.dark,
          max: Math.max(...serie.data.map((elt) => elt.surfaceMoyenne)),
          text: t("SurfaceMoyenne"),
        },
        {
          color: theme.palette.primary.dark,
          max: Math.max(...serie.data.map((elt) => elt.nombreDeBiens)),
          text: t("NombreBiens"),
        },
        {
          color: theme.palette.primary.dark,
          max: Math.max(
            ...serie.data.map(
              (elt) => elt.prixDeVenteMoyen / elt.surfaceMoyenne
            )
          ),
          text: t("PrixMoyenM2"),
        },
      ],
      radius: "55%",
      textStyle: {
        color: theme.palette.primary.dark,
      },
    },
  ],
  series: [
    {
      animationEasing: "elasticOut",
      animationType: "scale",
      data: serie.data.map((item) => ({
        name: t(item.cible),
        value: [
          item.prixDeVenteMoyen,
          item.surfaceMoyenne,
          item.nombreDeBiens,
          item.prixDeVenteMoyen / item.surfaceMoyenne,
        ],
      })),
      itemStyle: {
        emphasis: {
          shadowBlur: 10,
          shadowColor: "rgba(0, 0, 0, 0.5)",
          shadowOffsetX: 5,
        },
      },
      label: {
        normal: {
          formatter: "{b}：{c}  ({d}%)  ",
        },
      },
      name: serie.name,
      selectedMode: "single",
      tooltip: {
        formatter: (params) => {
          return `${params.seriesName} <br /> ${
            params.name
          } : <br /> Prix de vente moyen : ${Number.parseFloat(
            params.value[0]
          ).toFixed(2)} € <br /> Surface moyenne : ${
            params.value[1]
          } m2 <br /> Nombre de biens : ${
            params.value[2]
          } <br /> Prix moyen au m2 : ${Number.parseFloat(
            params.value[0] / params.value[1]
          ).toFixed(2)} €`;
        },
        trigger: "item",
      },
      type: "radar",
    },
  ],
  title: title,
  toolbox: {
    feature: {
      dataView: {
        readOnly: false,
        show: false,
        title: "Voir les données",
      },
      mark: { show: false },
      restore: { show: true, title: "Réinitialiser" },
      saveAsImage: { show: true, title: "Télécharger image" },
    },
    show: true,
  },
  tooltip: {
    trigger: "item",
  },
});

export const getOptionRadarMandats = (t, theme, serie) => ({
  color: colors,
  grid: {
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
  },
  legend: {
    data: serie.data.map((item) => t(item.cible)),
    orient: "vertical",
    textStyle: {
      color: theme.palette.primary.dark,
    },
    x: "right",
    y: "bottom",
  },
  polar: [
    {
      center: ["50%", "50%"],
      indicator: [
        {
          color: theme.palette.primary.dark,
          max: Math.max(...serie.data.map((elt) => elt.nombreMandatPris)),
          text: t("MandatsPris"),
        },
        {
          color: theme.palette.primary.dark,
          max: Math.max(...serie.data.map((elt) => elt.pourcentageExclu)),
          text: t("PourcentageExclu"),
        },
        {
          color: theme.palette.primary.dark,
          max: Math.max(...serie.data.map((elt) => elt.tauxDeTransfo)),
          text: t("PourcentageTransfo"),
        },
        {
          color: theme.palette.primary.dark,
          max: Math.max(
            ...serie.data.map((elt) => elt.pourcentageElectronique)
          ),
          text: t("PourcentageMandatsElectroniques"),
        },
        {
          color: theme.palette.primary.dark,
          max: Math.max(...serie.data.map((elt) => elt.nombreMandatsActifs)),
          text: t("MandatsActifs"),
        },
        {
          color: theme.palette.primary.dark,
          max: Math.max(...serie.data.map((elt) => elt.honorairesMoyens)),
          text: t("PourcentageHonorairesMoyens"),
        },
        {
          color: theme.palette.primary.dark,
          max: Math.max(...serie.data.map((elt) => elt.nombreMandatExcluPris)),
          text: t("MandatsExcluPris"),
        },
      ],
      radius: "55%",
      textStyle: {
        color: theme.palette.primary.dark,
      },
    },
  ],
  series: [
    {
      animationEasing: "elasticOut",
      animationType: "scale",
      data: serie.data.map((item) => ({
        name: t(item.cible),
        value: [
          item.nombreMandatPris,
          item.pourcentageExclu,
          item.tauxDeTransfo,
          item.pourcentageElectronique,
          item.nombreMandatsActifs,
          item.honorairesMoyens,
          item.nombreMandatExcluPris,
        ],
      })),
      itemStyle: {
        // normal: {
        //   areaStyle: {
        //     type: 'default'
        //   },
        // },
        emphasis: {
          shadowBlur: 10,
          shadowColor: "rgba(0, 0, 0, 0.5)",
          shadowOffsetX: 5,
        },
      },
      label: {
        normal: {
          formatter: "{b}：{c}  ({d}%)  ",
        },
      },
      name: "Mandats",
      selectedMode: "single",
      tooltip: {
        formatter: (params) => {
          return `${t(params.seriesName)} <br /> ${params.name} : <br /> ${t(
            "MandatsPris"
          )} : ${Number.parseFloat(params.value[0]).toFixed(2)} <br /> ${t(
            "PourcentageExclu"
          )} : ${Number.parseFloat(params.value[1]).toFixed(2)} % <br />  ${t(
            "PourcentageTransfo"
          )} : ${Number.parseFloat(params.value[2]).toFixed(2)} % <br /> ${t(
            "PourcentageMandatsElectroniques"
          )} : ${Number.parseFloat(params.value[3]).toFixed(2)} % <br /> ${t(
            "Mandats actifs"
          )} : ${Number.parseFloat(params.value[4]).toFixed(2)} <br /> ${t(
            "% hon. moyens"
          )} : ${Number.parseFloat(params.value[5]).toFixed(2)} % <br /> ${t(
            "Mandats exclu."
          )} : ${Number.parseFloat(params.value[6]).toFixed(2)}`;
        },
        trigger: "item",
      },
      type: "radar",
    },
  ],
  title: {
    text: "",
    x: "center",
  },
  toolbox: {
    feature: {
      dataView: {
        readOnly: false,
        show: false,
        title: "Voir les données",
      },
      mark: { show: false },
      restore: { show: true, title: "Réinitialiser" },
      saveAsImage: { show: true, title: "Télécharger image" },
    },
    show: true,
  },
  tooltip: {
    trigger: "item",
  },
});

export const getOptionParMoisMandats = (
  t,
  annee,
  theme,
  statsPresentation
) => ({
  color: colors,
  legend: {
    data: [t("Conseiller"), t("Departement"), t("Region")],
  },
  series: [
    {
      data: statsPresentation
        ? statsPresentation
            .find((elt) => elt.annee === annee)
            .mois.map(
              (elt) => Math.round(elt.nombreMandatsConseillers * 100) / 100
            )
        : [],
      label: {
        normal: {
          show: true,
        },
      },
      markLine: {
        data: [{ name: t("Moyenne"), type: "average" }],
      },
      name: t("Conseiller"),
      type: "bar",
    },
    {
      data: statsPresentation
        ? statsPresentation
            .find((elt) => elt.annee === annee)
            .mois.map(
              (elt) => Math.round(elt.nombreMandatsDepartement * 100) / 100
            )
        : [],
      label: {
        normal: {
          show: true,
        },
      },
      markLine: {
        data: [{ name: t("Moyenne"), type: "average" }],
      },
      name: t("Departement"),
      type: "bar",
    },
    {
      data: statsPresentation
        ? statsPresentation
            .find((elt) => elt.annee === annee)
            .mois.map((elt) => Math.round(elt.nombreMandatsRegion * 100) / 100)
        : [],
      label: {
        normal: {
          show: true,
        },
      },
      markLine: {
        data: [{ name: t("Moyenne"), type: "average" }],
      },
      name: t("Region"),
      type: "bar",
    },
  ],
  toolbox: {
    feature: {
      dataView: {
        readOnly: false,
        show: false,
        title: "Voir les données",
      },
      mark: { show: false },
      restore: { show: true, title: "Réinitialiser" },
      saveAsImage: { show: true, title: "Télécharger image" },
    },
    show: true,
  },
  tooltip: {
    trigger: "axis",
  },
  xAxis: [
    {
      data: statsPresentation
        ? statsPresentation
            .find((elt) => elt.annee === annee)
            .mois.map((item) => labelsMois(t)[item.mois - 1])
        : [],
      type: "category",
    },
  ],
  yAxis: [
    {
      type: "value",
    },
  ],
});

export const getOptionBarParAnneeMandats = (t, theme, statsPresentation) => ({
  color: colors,
  legend: {
    data: [t("Conseiller"), t("Departement"), t("Region")],
  },
  series: [
    {
      data: statsPresentation
        ? statsPresentation.map((item) =>
            item.mois.reduce(
              (sommeMandats, moisCourant) =>
                sommeMandats + Math.round(moisCourant.nombreMandatsConseillers),
              0
            )
          )
        : [],
      markLine: {
        data: [{ name: t("Moyenne"), type: "average" }],
      },
      markPoint: {
        data: [
          { name: t("Max"), type: "max" },
          { name: t("Min"), type: "min" },
        ],
      },
      name: t("Conseiller"),
      type: "bar",
    },
    {
      data: statsPresentation
        ? statsPresentation.map((item) =>
            item.mois.reduce(
              (sommeMandats, moisCourant) =>
                sommeMandats + Math.round(moisCourant.nombreMandatsDepartement),
              0
            )
          )
        : [],
      markLine: {
        data: [{ name: t("Moyenne"), type: "average" }],
      },
      markPoint: {
        data: [
          { name: t("Max"), type: "max" },
          { name: t("Min"), type: "min" },
        ],
      },
      name: t("Departement"),
      type: "bar",
    },
    {
      data: statsPresentation
        ? statsPresentation.map((item) =>
            item.mois.reduce(
              (sommeMandats, moisCourant) =>
                sommeMandats + Math.round(moisCourant.nombreMandatsRegion),
              0
            )
          )
        : [],
      markLine: {
        data: [{ name: t("Moyenne"), type: "average" }],
      },
      markPoint: {
        data: [
          { name: t("Max"), type: "max" },
          { name: t("Min"), type: "min" },
        ],
      },
      name: t("Region"),
      type: "bar",
    },
  ],
  toolbox: {
    feature: {
      dataView: {
        readOnly: false,
        show: false,
        title: "Voir les données",
      },
      mark: { show: false },
      restore: { show: true, title: "Réinitialiser" },
      saveAsImage: { show: true, title: "Télécharger image" },
    },
    show: true,
  },
  tooltip: {
    trigger: "axis",
  },
  xAxis: [
    {
      data: statsPresentation
        ? statsPresentation.map((item) => item.annee)
        : [],
      type: "category",
    },
  ],
  yAxis: [
    {
      type: "value",
    },
  ],
});

export const getOptionRadarActes = (t, theme, radarPresentation) => ({
  color: colors,
  grid: {
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
  },
  legend: {
    data: [t("Conseiller"), t("Departement"), t("Region")],
    orient: "vertical",
    textStyle: {
      color: theme.palette.primary.dark,
    },
    x: "right",
    y: "bottom",
  },
  polar: [
    {
      center: ["50%", "50%"],
      indicator: radarPresentation
        ? [
            {
              color: theme.palette.primary.dark,
              max: Math.max(...radarPresentation.map((elt) => elt.nombreActes)),
              text: t("NombreActes"),
            },
            {
              color: theme.palette.primary.dark,
              max: Math.max(...radarPresentation.map((elt) => elt.caActesTTC)),
              text: t("CaActesTTC"),
            },
            {
              color: theme.palette.primary.dark,
              max: Math.max(
                ...radarPresentation.map((elt) => elt.nombreCompromis)
              ),
              text: t("NombreCompromis"),
            },
            {
              color: theme.palette.primary.dark,
              max: Math.max(
                ...radarPresentation.map((elt) => elt.caCompromisTTC)
              ),
              text: t("CaCompromisTTC"),
            },
            {
              color: theme.palette.primary.dark,
              max: Math.max(...radarPresentation.map((elt) => elt.delaiVente)),
              text: t("DelaisVente"),
            },
            {
              color: theme.palette.primary.dark,
              max: Math.max(
                ...radarPresentation.map((elt) => elt.pourcentageHonoraires)
              ),
              text: t("PourcentHonoraires"),
            },
          ]
        : [{ max: 100, text: t("Rien") }],
      radius: "55%",
      textStyle: {
        color: theme.palette.primary.dark,
      },
    },
  ],
  series: [
    {
      animationEasing: "elasticOut",
      animationType: "scale",
      data: radarPresentation
        ? radarPresentation.map((item) => ({
            name: t(item.cible),
            value: [
              item.nombreActes,
              item.caActesTTC,
              item.nombreCompromis,
              item.caCompromisTTC,
              item.delaiVente,
              item.pourcentageHonoraires,
            ],
          }))
        : [{ name: t("Rien"), value: [0] }],
      itemStyle: {
        emphasis: {
          shadowBlur: 10,
          shadowColor: "rgba(0, 0, 0, 0.5)",
          shadowOffsetX: 5,
        },
      },
      label: {
        normal: {
          formatter: "{b}：{c}  ({d}%)  ",
        },
      },
      name: t("Actes"),
      selectedMode: "single",
      tooltip: {
        formatter: (params) => {
          return `${params.seriesName} <br /> ${params.name} : <br /> ${t(
            "NombreActes"
          )} : ${Number.parseFloat(params.value[0]).toFixed(2)} <br /> ${t(
            "CaActesTTC"
          )} : ${Number.parseFloat(params.value[1]).toFixed(2)} € <br />  ${t(
            "NombreCompromis"
          )} : ${Number.parseFloat(params.value[2]).toFixed(2)} <br /> ${t(
            "CaCompromisTTC"
          )} : ${Number.parseFloat(params.value[3]).toFixed(2)} € <br /> ${t(
            "DelaisVente"
          )} : ${params.value[4]} ${t("Jours")} <br /> ${t(
            "PourcentHonoraires"
          )} : ${Number.parseFloat(params.value[5]).toFixed(2)} %`;
        },
        trigger: "item",
      },
      type: "radar",
    },
  ],
  title: {
    text: "",
    x: "center",
  },
  toolbox: {
    feature: {
      dataView: {
        readOnly: false,
        show: false,
        title: t("ToolboxDataView"),
      },
      mark: { show: false },
      restore: { show: true, title: t("ToolboxRestore") },
      saveAsImage: {
        show: true,
        title: t("toolboxDownload"),
      },
    },
    show: true,
  },
  tooltip: {
    trigger: "item",
  },
});

export const getOptionBarParAnneeActes = (
  t,
  theme,
  champ,
  statsPresentation
) => ({
  color: colors,
  legend: {
    data: [t("Conseiller"), t("Departement"), t("Region")],
  },
  series: [
    {
      data: statsPresentation
        ? statsPresentation.map((item) =>
            item.mois.reduce(
              (somme, moisCourant) =>
                somme + Math.round(moisCourant[`${champ}Interlocuteur`] || 0),
              0
            )
          )
        : [],
      markLine: {
        data: [{ name: t("Moyenne"), type: "average" }],
      },
      markPoint: {
        data: [
          { name: t("Max"), type: "max" },
          { name: t("Min"), type: "min" },
        ],
      },
      name: t("Conseiller"),
      type: "bar",
    },
    {
      data: statsPresentation
        ? statsPresentation.map((item) =>
            item.mois.reduce(
              (somme, moisCourant) =>
                somme + Math.round(moisCourant[`${champ}Departement`] || 0),
              0
            )
          )
        : [],
      markLine: {
        data: [{ name: t("Moyenne"), type: "average" }],
      },
      markPoint: {
        data: [
          { name: t("Max"), type: "max" },
          { name: t("Min"), type: "min" },
        ],
      },
      name: t("Departement"),
      type: "bar",
    },
    {
      data: statsPresentation
        ? statsPresentation.map((item) =>
            item.mois.reduce(
              (somme, moisCourant) =>
                somme + Math.round(moisCourant[`${champ}Region`] || 0),
              0
            )
          )
        : [],
      markLine: {
        data: [{ name: t("Moyenne"), type: "average" }],
      },
      markPoint: {
        data: [
          { name: t("Min"), type: "min" },
          { name: t("Max"), type: "max" },
        ],
      },
      name: t("Region"),
      type: "bar",
    },
  ],
  toolbox: {
    feature: {
      dataView: {
        readOnly: false,
        show: false,
        title: t("ToolboxDataView"),
      },
      mark: { show: false },
      restore: { show: true, title: t("ToolboxRestore") },
      saveAsImage: {
        show: true,
        title: t("toolboxDownload"),
      },
    },
    show: true,
  },
  tooltip: {
    trigger: "axis",
  },
  xAxis: [
    {
      data: statsPresentation
        ? statsPresentation.map((item) => item.annee)
        : [],
      type: "category",
    },
  ],
  yAxis: [
    {
      type: "value",
    },
  ],
});

export const getOptionParMoisActes = (
  t,
  theme,
  annee,
  champ,
  statsPresentation
) => ({
  color: colors,
  legend: {
    data: [t("Conseiller"), t("Departement"), t("Region")],
  },
  series: [
    {
      data: statsPresentation
        ? statsPresentation
            .find((elt) => elt.annee === annee)
            .mois.map(
              (elt) =>
                Math.round((elt[`${champ}Interlocuteur`] || 0) * 100) / 100
            )
        : [],
      label: {
        normal: {
          show: true,
        },
      },
      markLine: {
        data: [{ name: t("Moyenne"), type: "average" }],
      },
      name: t("Conseiller"),
      type: "bar",
    },
    {
      data: statsPresentation
        ? statsPresentation
            .find((elt) => elt.annee === annee)
            .mois.map(
              (elt) => Math.round((elt[`${champ}Departement`] || 0) * 100) / 100
            )
        : [],
      label: {
        normal: {
          show: true,
        },
      },
      markLine: {
        data: [{ name: t("Moyenne"), type: "average" }],
      },
      name: t("Departement"),
      type: "bar",
    },
    {
      data: statsPresentation
        ? statsPresentation
            .find((elt) => elt.annee === annee)
            .mois.map(
              (elt) => Math.round((elt[`${champ}Region`] || 0) * 100) / 100
            )
        : [],
      label: {
        normal: {
          show: true,
        },
      },
      markLine: {
        data: [{ name: t("Moyenne"), type: "average" }],
      },
      name: t("Region"),
      type: "bar",
    },
  ],
  toolbox: {
    feature: {
      dataView: {
        readOnly: false,
        show: false,
        title: t("ToolboxDataView"),
      },
      mark: { show: false },
      restore: { show: true, title: t("ToolboxRestore") },
      saveAsImage: {
        show: true,
        title: t("toolboxDownload"),
      },
    },
    show: true,
  },
  tooltip: {
    trigger: "axis",
  },
  xAxis: [
    {
      data: statsPresentation
        ? statsPresentation
            .find((elt) => elt.annee === annee)
            .mois.map((item) => labelsMois(t)[item.mois - 1])
        : [],
      type: "category",
    },
  ],
  yAxis: [
    {
      type: "value",
    },
  ],
});

export const getOptionStatsPublication = (t, theme, series) => ({
  calculable: true,
  dataZoom: [
    {
      end: 100,
      realtime: true,
      show: true,
      start: 0,
    },
    {
      end: 100,
      realtime: true,
      start: 0,
      type: "inside",
    },
  ],
  grid: {
    bottom: 80,
    containLabel: true,
    left: "0%",
    right: "10%",
  },
  legend: {
    data: [t("NbClics"), t("NbVisites"), t("NbDemandes")],
    textStyle: {
      color: theme.palette.primary.main,
    },
  },
  series: [
    {
      data: series.data.map((item) => item.nombreDeClics),
      itemStyle: {
        normal: {
          color: theme.palette.success.main,
        },
      },
      label: {
        normal: {
          show: true,
        },
      },
      markLine: {
        data: [{ name: "Moyenne", type: "average" }],
      },
      name: t("NbClics"),
      type: "line",
    },
    {
      data: series.data.map((item) => item.nombreDeVisites),
      itemStyle: {
        normal: {
          color: theme.palette.primary.main,
        },
      },
      label: {
        normal: {
          show: true,
        },
      },
      markLine: {
        data: [{ name: t("average"), type: "average" }],
      },
      name: t("NbVisites"),
      type: "line",
      yAxisIndex: 1,
    },
    {
      data: series.data.map((item) => item.nombreDeDemandes),
      itemStyle: {
        normal: {
          color: theme.palette.error.main,
        },
      },
      label: {
        normal: {
          show: true,
        },
      },
      markLine: {
        data: [{ name: t("average"), type: "average" }],
      },
      name: t("NbDemandes"),
      type: "line",
      yAxisIndex: 2,
    },
  ],
  toolbox: {
    feature: {
      dataView: {
        readOnly: false,
        show: false,
        title: t("ToolboxDataView"),
      },
      mark: { show: false },
      restore: {
        show: false,
        title: t("ToolboxRestore"),
      },
      saveAsImage: {
        show: true,
        title: t("ToolboxSaveAsImage"),
      },
    },
    show: true,
  },
  tooltip: {
    axisPointer: {
      animation: false,
      label: {
        backgroundColor: "#505765",
      },
      type: "cross",
    },
    trigger: "axis",
  },
  xAxis: [
    {
      axisLabel: {
        textStyle: {
          color: theme.palette.primary.main,
        },
      },
      axisLine: { onZero: false },
      boundaryGap: false,
      data: [
        ...new Set(
          series.data.map((item) =>
            new Date(item.date).toLocaleDateString(t("FormatDateLocal"), {
              month: "long",
              year: "numeric",
            })
          )
        ),
      ],
      splitLine: {
        lineStyle: {
          color: theme.palette.secondary.main,
        },
      },
      type: "category",
    },
  ],
  yAxis: [
    {
      axisLabel: {
        textStyle: {
          color: theme.palette.primary.main,
        },
      },
      name: t("Clics"),
      splitArea: {
        areaStyle: {
          color: "#ffffff",
        },
        show: true,
      },
      splitLine: {
        lineStyle: {
          color: theme.palette.secondary.main,
        },
      },
      splitNumber: "3",
      type: "value",
    },
    {
      axisLabel: {
        textStyle: {
          color: theme.palette.primary.main,
        },
      },
      name: t("Visites"),
      type: "value",
    },
    {
      axisLabel: {
        textStyle: {
          color: theme.palette.primary.main,
        },
      },
      name: t("Demandes"),
      offset: 70,
      type: "value",
    },
  ],
});
