import {
  Avatar,
  Card,
  CardHeader,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import SubjectIcon from "@material-ui/icons/SubjectOutlined";
import { useAvatarOrQuestionMark } from "hooks/useAvatarOrQuestionMark";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import routesNames from "router/routesNames";
import { useTranslation } from "services/l10n/useTranslation";
import { ITheme } from "theme/ts/main";
import { LastUpdate } from "UI/shared/atoms/LastUpdate";
import { EmailLink } from "UI/shared/molecules/EmailLink";
import { PhoneLink } from "UI/shared/molecules/PhoneLink";
import { SpacedLabelValue14Px } from "UI/shared/molecules/SpacedLabelValue";
import { UnknownPhoneOrEmail } from "UI/shared/molecules/UnknownPhoneOrEmail";
import { toPascalCase } from "utils/string";

const useStyles = makeStyles((theme: ITheme) => ({
  avatar: {
    backgroundColor: theme.palette.default,
    cursor: "pointer",
  },
  card: {
    backgroundColor: theme.palette.greyLight,
    padding: 14,
  },
}));

export interface AgentMatchingAcquirerProps {
  phoneNumber: string;
  mail: string;
  lastModificationDate: string;
  name: string;
  firstName: string;
  civility: string;
  opportunityId: string;
  contactUniqueId: string;
}
export const AgentMatchingAcquirer: React.FC<AgentMatchingAcquirerProps> = ({
  phoneNumber,
  mail,
  lastModificationDate,
  name = "",
  firstName = "",
  civility,
  opportunityId,
  contactUniqueId = 0,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const avatarInfo = useAvatarOrQuestionMark(firstName, name);
  const civilityInfo = useMemo(() => {
    const gender = civility === "None" ? "" : t(civility);
    const contactName = `${toPascalCase(firstName)} ${(
      name || ""
    ).toUpperCase()}`;

    return `${gender} ${contactName}`;
  }, [civility, firstName, name]);

  const openContactDetails = () => {
    if (contactUniqueId === 0) return;

    history.push(
      `${routesNames.app}${routesNames.contact}/${contactUniqueId}/profils/view`
    );
  };

  const phoneInfo = useMemo(
    () =>
      phoneNumber ? (
        <PhoneLink value={phoneNumber} />
      ) : (
        <UnknownPhoneOrEmail type={"phone"} />
      ),
    [phoneNumber]
  );

  const emailInfo = useMemo(
    () =>
      mail ? <EmailLink value={mail} /> : <UnknownPhoneOrEmail type="email" />,
    [mail]
  );

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar
            aria-label={name}
            className={classes.avatar}
            onClick={openContactDetails}
          >
            {avatarInfo}
          </Avatar>
        }
        title={
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-end"
          >
            <Grid item xs={10}>
              <>
                <Typography
                  variant="h4"
                  style={{ cursor: "pointer", marginBottom: "5px" }}
                  onClick={openContactDetails}
                >
                  {civilityInfo}
                </Typography>
                <SpacedLabelValue14Px
                  label={"Référence"}
                  value={opportunityId}
                  spaceSize={5}
                />
                {phoneInfo}
                {emailInfo}
                <LastUpdate date={new Date(lastModificationDate)} />
              </>
            </Grid>

            <Grid item xs={2}>
              <Tooltip title={t("VoirLaFicheContact")} placement="right">
                <IconButton onClick={openContactDetails} aria-label="edit">
                  <SubjectIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        }
        titleTypographyProps={{ noWrap: true, variant: "h4" }}
        className={classes.card}
      />
    </Card>
  );
};
