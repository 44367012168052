/* eslint-disable react/prop-types */
import { Grid, Hidden, useMediaQuery } from "@material-ui/core";
import Map from "components/MapX/Map";
import { flattenGeolocRefinementsValues } from "components/MapX/Map/utils/flattenGeolocRefinementsValues";
import TitrePage from "components/TitrePage";
import BiensDisplayMode from "layoutContents/Biens/components/BiensDisplayMode";
import ContextualSideBar from "layoutContents/Biens/components/ContextualSideBar";
import { createPropertiesMarkers } from "layoutContents/Biens/components/MapComponents/createPropertiesMarkers";
import Searchbar from "layoutContents/Biens/components/Searchbar";
import RenderHits from "layoutContents/components/HitsContainer/renderHits";
import { pageSizeFromMode } from "layoutContents/components/HitsContainer/utils";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import routesNames from "router/routesNames";
import { useServices } from "services";
import { useTxHttp } from "services/http";
import rechercheCarteAdapter from "services/rapprochement/Rapprochement/BiensEnVente/RechercheCarte/rechercheCarteAdapter";
import obtenirParIdAdapter from "services/rapprochement/Rapprochement/BiensVendus/ObtenirParId/obtenirParIdAdapter";
import obtenirParIdCarteAdapter from "services/rapprochement/Rapprochement/BiensVendus/ObtenirParIdCarte/obtenirParIdCarteAdapter";
import RechercheGridOuListeAdapter from "services/rapprochement/Rapprochement/BiensVendus/RechercheGridOuListe/RechercheGridOuListeAdapter";
import useInfiniteScroll from "services/useInfiniteScroll";
import useItemsSearch from "services/useItemsSearch";
import { breakpoint } from "theme/main";
import { ErrorPanel } from "UI/shared/templates/Panels/ErrorPanel";
import { worldBoundingBox } from "utils/formatage";

import {
  handleMapConfigSaveBuilder,
  handleMapInitializationBuilder,
  onMapSelectItemFactory,
  resetPageNumberBuilder,
  setDisplayModeBuilder,
} from "../utils/commonLogic";
import { filterOptions } from "../utils/filterOptions";
import {
  computedHitsStyles,
  computedMapPaneBreakpoint,
  computedTotalItemsTitle,
  DEFAULT_MAP_CENTER,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_ZOOM_LEVEL,
  isSmallList,
  isSmallScreenMap,
} from "../utils/formatter";
import { getScrollToTopButton } from "../utils/getScrollToTopButton";
import { getToggleHitsPaneButton } from "../utils/getToggleHitsPaneButton";
import {
  clearRefinementsBuilder,
  handleRefinementsFromParameters,
  onUpdateLocalisationsBuilder,
  onUpdateRefinementsBuilder,
  onUpdateSortingBuilder,
} from "../utils/refinements";
import { reducer } from "./logic/reducer";
import { Refinements } from "./models/refinements";
import { useStyles } from "./style";

const SNAPSHOT_KEY = "Snapshot State Biens Vendus";
const PAGE_BASE_URL = `${routesNames.app}${routesNames.biens}${routesNames.biensVendus}`;

const formatOptionsListeConseillers = (result) => {
  return result.aggregation.Conseiller.map((option) => {
    const _option = JSON.parse(option.key);
    const _count = option.value;
    return {
      ..._option,
      label: `${_option.NomComplet} (${_count})`,
      value: _option.Id,
    };
  });
};

const initialState = {
  boundingBox: worldBoundingBox,
  center: DEFAULT_MAP_CENTER,
  displayMode: "list",
  drawer: false,
  firstIntersection: true,
  hit: obtenirParIdAdapter(),
  loading: false,
  map: null,
  mapPoints: [],
  page: DEFAULT_PAGE_NUMBER,
  realtyInformationToFocus: null,
  rechercheBounds: false,
  refinements: Refinements,
  shouldUpdateMapBounds: true,
  smAndDown: breakpoint().smAndDown,
  zoom: DEFAULT_ZOOM_LEVEL,
};

export const BiensVendus = (props) => {
  const history = useHistory();
  const handleFilterOptions = filterOptions(history.location.pathname);

  const { params, mode } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);

  const [hideHitPane, setHideHitPane] = useState(false);
  const toggleHitPane = () => {
    setHideHitPane(!hideHitPane);
  };

  const mobile = useMediaQuery("(max-width: 768px)");
  const containerHeight = window.innerHeight - 150;

  const classes = useStyles({
    containerHeight,
  });
  const {
    endpoints,
    l10n: { t },
  } = useServices();

  const { get } = useTxHttp();

  async function fetchPropertiesForSaleMapData() {
    dispatch({ payload: true, type: "setLoading" });
    const refinementsParams = {
      ...state.refinements.createParams(state.rechercheBounds),
      ...state.boundingBox,
    };
    const points = await get(
      endpoints.biensVendus.rechercheCarte,
      refinementsParams
    );
    if (points.data) return rechercheCarteAdapter(points.data);
    return [];
  }

  useEffect(() => {
    if (state.displayMode === "map")
      fetchPropertiesForSaleMapData().then((responsePoints) => {
        dispatch({ payload: responsePoints.data, type: "setMapPoints" });
      });
  }, [state.refinements, state.boundingBox, state.displayMode]);

  const resetPageNumber = useCallback(resetPageNumberBuilder(dispatch), []);

  const onUpdateLocalisations = useCallback(
    onUpdateLocalisationsBuilder(state, dispatch, props, PAGE_BASE_URL),
    [state.refinements, state.displayMode, props.history]
  );

  const onUpdateRefinements = useCallback(
    onUpdateRefinementsBuilder(state, dispatch, props, PAGE_BASE_URL),
    [state.refinements, state.displayMode, props.history]
  );
  const onUpdateSorting = useCallback(
    onUpdateSortingBuilder(state, dispatch, props, PAGE_BASE_URL),
    [props.history, state.displayMode, state.refinements]
  );

  const clearRefinements = useCallback(
    clearRefinementsBuilder(state, dispatch, props, PAGE_BASE_URL),
    []
  );

  const setDisplayMode = useCallback(
    setDisplayModeBuilder(state, dispatch, props, PAGE_BASE_URL),
    [props.history, state.refinements]
  );

  const setOpenDrawer = useCallback(() => {
    dispatch({ type: "toggleDrawer" });
  }, []);

  const handleMapConfigSave = handleMapConfigSaveBuilder(
    state,
    dispatch,
    SNAPSHOT_KEY
  );

  const handleMapInitialization = handleMapInitializationBuilder(dispatch);

  const handleDisplayOfDetailedRealty = ({ id, print = false }) => {
    history.replace(computeDetailedRealtyURL({ id, print }));
  };

  const computeDetailedRealtyURL = ({ id, print = false }) => {
    return `${routesNames.app}${routesNames.biens}${
      routesNames.bienVendu
    }/${id}/details${print ? "/print" : "/view"}`;
  };

  const CustomSearchbar = (
    <Searchbar
      displayMode={state.displayMode}
      onChange={onUpdateRefinements}
      onClickMore={() => {
        dispatch({ type: "toggleDrawer" });
      }}
      onUpdateLocalisations={onUpdateLocalisations}
      prixMin={state.refinements.prixMin}
      prixMax={state.refinements.prixMax}
      refinements={state.refinements.toObject()}
      t={t}
    />
  );

  useEffect(() => {
    const size = () => dispatch({ type: "setSmAndDown" });
    window.addEventListener("resize", size);
    handleRefinementsFromParameters(
      params,
      mode,
      Refinements,
      dispatch,
      SNAPSHOT_KEY
    );

    return function cleanup() {
      window.removeEventListener("resize", size);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // hook pour chargement des biens
  const { error, hasMore, loading, items } = useItemsSearch({
    adapter: (res) => RechercheGridOuListeAdapter.toBO(res),
    boundingBox: state.displayMode === "map" ? state.boundingBox : null,
    pageNumber: state.page,
    pageSize: pageSizeFromMode.get(state.displayMode).size,
    rechercheBounds: state.rechercheBounds,
    refinements: state.refinements,
    resetPageNumber,
    url: endpoints.biensVendus.rechercheGridOuListe,
  });

  if (isSmallList(window.innerWidth, state.displayMode)) {
    setDisplayMode("gallery");
  }

  const handleChangePage = useCallback(
    function () {
      dispatch({
        payload: state.page + 1,
        type: "changePage",
      });
    },
    [state.page]
  );

  const setFirstIntersection = useCallback(function (intersection) {
    dispatch({
      payload: intersection,
      type: "firstIntersection",
    });
  }, []);

  const { firstRef, triggerRef } = useInfiniteScroll({
    handleChangePage,
    hasMore,
    loading,
    setFirstIntersection,
  });

  // ====================================

  const hitsRef = React.useRef(null);
  const hitsToDisplay = !isSmallScreenMap(state) ? (
    <Grid
      style={computedHitsStyles(state.displayMode, containerHeight)}
      item
      xs
      ref={hitsRef}
    >
      <RenderHits
        onHitClick={handleDisplayOfDetailedRealty}
        filterOptions={handleFilterOptions(t)}
        onUpdateSorting={onUpdateSorting}
        clearRefinements={clearRefinements}
        containerHeight={containerHeight}
        displayMode={state.displayMode}
        firstRef={firstRef}
        triggerRef={triggerRef}
        loading={loading}
        mode={"biensVendus"}
        onUpdateRefinements={onUpdateRefinements}
        items={items}
        refinements={state.refinements}
        searchbar={CustomSearchbar}
        smAndDown={state.smAndDown}
        setOpenDrawer={setOpenDrawer}
        onDetailPageURL={computeDetailedRealtyURL}
        data-testid="biensVendusRenderHitsId"
      />
    </Grid>
  ) : (
    <></>
  );

  const setBoundingBox = useCallback(({ boundingBox, rechercheBounds }) => {
    dispatch({
      payload: { boundingBox, rechercheBounds },
      type: "updateBoundingBox",
    });
  }, []);

  if (error)
    return (
      <ErrorPanel title={"Biens Vendus"} text={"Serveur non disponible."} />
    );

  return (
    <div className={classes.root}>
      <TitrePage
        categorie={t("Biens")}
        page={t("BiensVendus")}
        nbResultats={computedTotalItemsTitle(t, items.total)}
        filePath={"/static/markdowns/biensVendus.md"}
        Composant={() => (
          <BiensDisplayMode
            onChange={setDisplayMode}
            selected={state.displayMode}
          />
        )}
      />
      <Grid container spacing={2}>
        {state.displayMode === "map" && (
          <Grid
            item
            xs={12}
            md={computedMapPaneBreakpoint(hideHitPane)}
            className={classes.toggleSidePaneButtonContainer}
          >
            <Hidden mdUp>{CustomSearchbar}</Hidden>
            <Map
              onPopupClick={handleDisplayOfDetailedRealty}
              onMarkerClick={onMapSelectItemFactory(
                endpoints.biensVendus.rechercheParIdCarte,
                get,
                t,
                obtenirParIdCarteAdapter
              )}
              onMapConfigSave={handleMapConfigSave}
              onMapInitialization={handleMapInitialization}
              zoom={state.zoom}
              boundingBox={state.boundingBox}
              sendBoundingBoxToParent={setBoundingBox}
              center={state.center}
              points={state.mapPoints}
              toggleBoundsSearchLabel={`Voir les biens autour de ma zone`}
              selectedLocations={flattenGeolocRefinementsValues({
                refinements: state.refinements,
              })}
              loading={state.loading}
              createMarkers={createPropertiesMarkers}
              fullscreen={hideHitPane}
            />
            {getToggleHitsPaneButton(
              hideHitPane,
              toggleHitPane,
              mobile,
              classes
            )}
          </Grid>
        )}
        {!hideHitPane && hitsToDisplay}
      </Grid>

      {getScrollToTopButton(
        state.firstIntersection,
        state.displayMode,
        classes,
        hitsRef.current
      )}
      <ContextualSideBar
        biensTotal={items.total}
        drawer={state.drawer}
        setOpenDrawer={setOpenDrawer}
        refinements={state.refinements}
        clearRefinements={clearRefinements}
        t={t}
        onUpdateRefinements={onUpdateRefinements}
        onUpdateLocalisations={onUpdateLocalisations}
        classes={classes}
        mode={"biensVendus"}
        endPointConseiller={endpoints.biensVendus.rechercheConseiller}
        formatOptionsListe={formatOptionsListeConseillers}
      />
    </div>
  );
};
