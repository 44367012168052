import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paperNumber: {
    color: theme.palette.tonique,
  },
}));
export default ({ title, subtitle, className }) => {
  const classes = useStyles();
  return (
    <div className={className}>
      <Typography className={classes.paperNumber} variant="h1">
        {title}
      </Typography>
      <Typography variant="h5">{subtitle}</Typography>
    </div>
  );
};
