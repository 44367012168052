import { makeStyles, useTheme } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import { ITheme } from "theme/ts/main";

export interface BackIconProps {
  diameter?: number;
}

const useStylesIcon = makeStyles(() => {
  return {
    icon: {
      alignItems: "center",
      backgroundColor: "white",
      borderRadius: ({ diameter }: BackIconProps) => diameter || 0 / 2,
      color: "white",
      display: "flex",
      height: ({ diameter }: BackIconProps) => diameter,
      justifyContent: "center",
      width: ({ diameter }: BackIconProps) => diameter,
    },
  };
});

export const BackIcon: React.FC<BackIconProps> = (props) => {
  const themeColor = (useTheme() as ITheme).palette.tonique;
  const classes = useStylesIcon({ diameter: props.diameter ?? 35 });
  return (
    <div className={classes.icon}>
      <ArrowBackIcon style={{ color: themeColor }} />
    </div>
  );
};
