import { Card, Grid, IconButton, Typography } from "@material-ui/core";
import PhotoIcon from "@material-ui/icons/LocalSeeOutlined";
import SubjectIcon from "@material-ui/icons/Subject";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { networkRealEstatesRoutes } from "router/networkRealEstatesRoutes";
import { useServices } from "services";
import CommerceEntreprise from "UI/shared/atoms/Badges/C&E";
import Exclusivite from "UI/shared/atoms/Badges/Exclusivite";
import { SousCompromis } from "UI/shared/atoms/Badges/SousCompromis";
import { SousOffre } from "UI/shared/atoms/Badges/SousOffre";
import { Column } from "UI/shared/atoms/containers/Column";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";
import { Row } from "UI/shared/atoms/containers/Row";
import { If } from "UI/shared/atoms/directives/If";
import { LastUpdate } from "UI/shared/atoms/LastUpdate";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import { BusinessTypeCaption } from "UI/shared/molecules/BusinessTypeCaption";
import BoutonImpression from "UI/shared/molecules/Buttons/BoutonImpression";
import { LocationCaption } from "UI/shared/organisms/LocationCaption";

import Neuf from "../../../../shared/atoms/Badges/Neuf/index";
import Prestige from "../../../../shared/atoms/Badges/Prestige";
import { Characteristic } from "../../atoms/Characteristic";
import { RealEstateAgent, RealEstateAgentProps } from "../../molecules/Agent";
import { useStyles } from "./styles";

export interface RealEstatePropertyProps {
  id?: string;
  bu?: string;
  surface: number;
  bedroomsNb?: number;
  roomsNb?: number;
  price: number;
  cityName: string;
  cityZipCode?: string;
  businessType: string;
  propertyType: string;
  isNew?: boolean;
  isPrestige?: boolean;
  isCE?: boolean;
  isExlusive?: boolean;
  isUnderOfferToSell: boolean;
  hasOffer?: boolean;
  photo?: string;
  nbPictures?: number;
  date?: string;
  listeTags?: JSX.Element[];
  actions?: JSX.Element[];

  agent?: RealEstateAgentProps;
}

export const RealEstateProperty: React.FC<RealEstatePropertyProps> = ({
  id,
  bu,
  surface,
  bedroomsNb,
  roomsNb,
  price,
  cityName,
  cityZipCode,
  businessType,
  propertyType,
  isNew,
  isPrestige,
  isCE,
  isExlusive,
  hasOffer,
  date,
  isUnderOfferToSell,
  photo,
  nbPictures,
  agent,
}) => {
  const classes = useStyles();
  const detailPage = "";
  const {
    l10n: { t },
  } = useServices();

  const history = useHistory();

  const picture = (
    <Link to={detailPage} style={{ textDecoration: "none" }}>
      <div
        style={{
          backgroundImage: `url(${photo}), url("/static/images/no-image-box.png")`,
        }}
        className={classes.image}
      >
        <Typography className={classes.imageInformation}>
          {t("Voir la fiche détail")}
        </Typography>

        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          className={classes.picturesContainer}
        >
          <PhotoIcon style={{ color: "#000", fontSize: 12 }} />
          <Typography variant="body2" style={{ paddingLeft: 3 }}>
            {nbPictures}
          </Typography>
        </Grid>
      </div>
    </Link>
  );

  const tags = (
    <Grid container direction="row" spacing={1} alignItems="center">
      {isExlusive && (
        <div className={classes.tagItem}>
          <Exclusivite t={t} />
        </div>
      )}
      {isPrestige && (
        <div className={classes.tagItem}>
          <Prestige />
        </div>
      )}
      {isNew && (
        <div className={classes.tagItem}>
          <Neuf />
        </div>
      )}
      {isCE && (
        <div className={classes.tagItem}>
          <CommerceEntreprise />
        </div>
      )}
    </Grid>
  );
  const priceComponent = (
    <Typography variant="h2" align="right" noWrap>{`${price
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} €`}</Typography>
  );

  const isUnderOfferToSellComponent = isUnderOfferToSell && (
    <PaddingContainer top={5}>
      <Row rowJustify={"flex-end"}>
        <SousCompromis />
      </Row>
    </PaddingContainer>
  );

  const hasofferComponent = hasOffer && (
    <PaddingContainer top={5}>
      <Row rowJustify="flex-end">
        <SousOffre />
      </Row>
    </PaddingContainer>
  );

  const pricePerMeter = surface > 0 && (
    <Typography variant="body2">
      Prix au m<sup>2</sup>{" "}
      {` : ${Math.ceil(price / surface)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} €`}
    </Typography>
  );

  const onClickDetails = () => {
    if (!id) return;
    history.push(networkRealEstatesRoutes.details(id));
  };

  const onClickPrint = () => {
    if (!id) return;
    history.push(networkRealEstatesRoutes.print(id));
  };

  const goToDetails = id && (
    <IconButton onClick={onClickDetails} style={{ cursor: "pointer" }}>
      <SubjectIcon />
    </IconButton>
  );
  const print = <BoutonImpression onPrint={onClickPrint} />;

  return (
    <Card className={classes.root}>
      <Grid container spacing={2} direction="row" alignItems="center">
        <Grid
          item
          xs={6}
          md={2}
          onClick={onClickDetails}
          style={{ padding: 0 }}
        >
          {picture}
        </Grid>

        <Grid item md={8} style={{ paddingLeft: 10 }}>
          <Grid container>
            <Grid item xs={6} md={5}>
              <Grid container direction="row" spacing={2} alignItems="center">
                <Grid item xs={6} md={6}>
                  <BusinessTypeCaption
                    type={businessType.replace(/([A-Z])/g, " $1").trim()}
                  />
                  <Typography variant="body1" className={classes.propertyType}>
                    {propertyType}
                  </Typography>
                  <LocationCaption city={cityName} zipcode={cityZipCode} />
                  {tags}
                </Grid>

                <Grid item md={4}>
                  <Grid container justify={"flex-end"}>
                    {priceComponent}
                    {date && <LastUpdate date={new Date(date)} />}
                    {isUnderOfferToSellComponent}
                    {hasofferComponent}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid container direction="column" spacing={0}>
                {surface > 0 ? (
                  <Characteristic label={"Surface"} value={`${surface} m²`} />
                ) : null}

                {roomsNb && roomsNb > 0 && !isCE ? (
                  <Characteristic label={"Pièces"} value={roomsNb} />
                ) : null}

                {bedroomsNb && bedroomsNb > 0 ? (
                  <Characteristic label={"Chambres"} value={bedroomsNb} />
                ) : null}

                {pricePerMeter}
              </Grid>
            </Grid>

            {agent && (
              <Grid item xs={12} md={4}>
                <RealEstateAgent {...agent!} />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item md={2}>
          <Grid container justify="flex-end" style={{ paddingRight: 20 }}>
            {goToDetails}
            {print}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
