import { MultiCheckbox } from "UI/shared/molecules/MultiCheckbox";
import React from "react";

import { ParkingAmenityType } from "../../../../models/Proposal/Features/Parking/parkingAmenityType";
import { SubSectionTitle } from "../../../components/SectionTitle";
import { ProfileInputProps } from "../../../InputProps";

export const ParkingForSaleEquipments: React.FC<ProfileInputProps<
  ParkingAmenityType[]
>> = ({ onChange, value }) => {
  const options = [
    "Power",
    "Light",
    "Water",
    "MotorizedGate",
    "ManualGate",
    "GroundSecurityLock",
    "UndergroundLocation",
    "ParkingBarrier",
  ];
  return (
    <>
      <SubSectionTitle title="Prestations" mode={"non obligatoire"} />
      <MultiCheckbox
        translationPrefix={"amenity"}
        options={options}
        selectedOptions={value}
        onChange={onChange}
      />
    </>
  );
};
