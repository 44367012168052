import React, { createContext, useReducer } from "react";
import { NotifierSnackbar } from "./components/NotifierSnackbar";

export const NotifierContext = createContext(() => null);

const initial = {
  autoHideTime: 5000,
  message: null,
  variant: "success",
  visible: false,
  withLoader: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "show":
      return { ...initial, visible: true, ...action.options };
    case "hide":
      return initial;
    default:
      throw new Error();
  }
}

export const NotifierProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initial);
  const { message, visible, variant, autoHideTime, withLoader } = state;
  return (
    <NotifierContext.Provider value={dispatch}>
      <NotifierSnackbar
        key={message}
        message={message}
        open={visible}
        variant={variant}
        autoHideDuration={autoHideTime > 0 ? autoHideTime : null}
        withloader={withLoader ? "true" : undefined}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      />
      {props.children}
    </NotifierContext.Provider>
  );
};
