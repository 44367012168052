import { Grid, Typography } from "@material-ui/core";
import React, { FC, useState } from "react";
import { formatPrix } from "utils/formatage";

import { RentApartmentFinancialInfoDto } from "../../../../models/Proposal/ForRent/Apartment/rentApartmentFinancialInfoDto";
import { SectionTitle } from "../../../components/SectionTitle";
import { ValidationNumberField } from "../../../components/ValidationNumberField";
import { ProfileInputProps } from "../../../InputProps";

function isDefinedAndGreateThanZero(candidate: number | undefined) {
  return candidate !== undefined && candidate > 0;
}

export const RentBudgetApartment: FC<{
  financialInfo: RentApartmentFinancialInfoDto;
}> = ({ financialInfo }) => (
  <>
    {isDefinedAndGreateThanZero(financialInfo.rentChargesExcluded) && (
      <Typography variant="h5">
        Loyer mensuel HC : {formatPrix(financialInfo.rentChargesExcluded)}
      </Typography>
    )}
    {isDefinedAndGreateThanZero(financialInfo.charges) && (
      <Typography variant="h5">
        Provision mensuelle de charges : {formatPrix(financialInfo.charges)}
      </Typography>
    )}
    {isDefinedAndGreateThanZero(financialInfo.rentChargesExcluded) && (
      <Typography variant="body1">
        Loyer mensuel TTC :{" "}
        {formatPrix(
          (financialInfo.rentChargesExcluded ?? 0) +
            (financialInfo.charges ?? 0)
        )}
      </Typography>
    )}
  </>
);

interface RentBudgetApartmentValidation {
  rentChargesExcluded: boolean;
  charges: boolean;
}

const getGlobalValidation = (globalValidation: RentBudgetApartmentValidation) =>
  globalValidation.rentChargesExcluded && globalValidation.charges;

export const RentBudgetApartmentInput: FC<ProfileInputProps<
  RentApartmentFinancialInfoDto
>> = ({ pristine, value, onChange, more, disabled = false }) => {
  const [globalValidation, setGlobalValidation] = useState({
    charges: true,
    rentChargesExcluded: true,
  });

  return (
    <>
      <SectionTitle title="Quel est le prix de vente ?" />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ValidationNumberField
            disabled={disabled}
            pristine={pristine}
            label="Loyer mensuel HC"
            onChange={(isValid, rentChargesExcluded) => {
              const _globalValidation = {
                ...globalValidation,
                price: isValid,
              };
              setGlobalValidation(_globalValidation);
              onChange(
                {
                  ...value,
                  rentChargesExcluded,
                },
                getGlobalValidation(_globalValidation)
              );
            }}
            required={true}
            value={value.rentChargesExcluded}
            adornement={"€"}
            minLimit={1}
          />
        </Grid>
        <Grid item xs={6}>
          <ValidationNumberField
            disabled={disabled}
            pristine={pristine}
            label="Provision mensuelle de charges"
            onChange={(isValid, charges) => {
              const _globalValidation = {
                ...globalValidation,
                charges: isValid,
              };
              setGlobalValidation(_globalValidation);
              onChange(
                { ...value, charges },
                getGlobalValidation(_globalValidation)
              );
            }}
            required={true}
            value={value.charges}
            adornement={"€"}
            minLimit={1}
          />
        </Grid>
      </Grid>
      <Typography variant={"body2"}>
        {"Loyer mensuel TTC :\u00a0"}
        <span style={{ fontWeight: "bold" }}>
          {!isNaN(value.rentChargesExcluded!) &&
            !isNaN(value.charges!) &&
            formatPrix(Math.round(value.charges! + value.rentChargesExcluded!))}
        </span>
      </Typography>
    </>
  );
};
