import { Typography } from "@material-ui/core";
import {
  DetailedEntityTabsLayout,
  Tab,
} from "components/Fiche/DetailedEntityTabsLayout";
import BoutonImpression from "UI/shared/molecules/Buttons/BoutonImpression";
import TitrePage from "components/TitrePage";
import moment from "moment";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import obtenirParIdAdapter from "services/rapprochement/Rapprochement/BiensVendus/ObtenirParId/obtenirParIdAdapter";
import { breakpoint } from "theme/main";
import BienCarteReduite from "../../../../layoutContents/Fiches/components/BienCarteReduite";
import BienConseiller from "../../../../layoutContents/Fiches/components/BienConseiller";
import Details from "../../../../layoutContents/Fiches/components/Details";
import { PrintablePropertyDetails } from "../../../../layoutContents/Fiches/components/PrintablePropertyDetails/PrintablePropertyDetails";
import { useServices } from "../../../../services";
import { useTxHttp } from "../../../../services/http";

const etiquetteCarte = <></>;

export const BienVendu = () => {
  const { get } = useTxHttp();

  const { id, display } = useParams<{ id: any; display: any }>();
  const [bien, setBien] = useState(obtenirParIdAdapter());
  const {
    l10n: { t },
    authenticationService,
    endpoints,
  } = useServices();
  const userData = authenticationService.getUserData();

  const componentToPrintRef = React.useRef(null);

  const history = useHistory();

  const handleBackButtonAction = () => {
    history.goBack();
  };

  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  React.useEffect(() => {
    const url = endpoints.biensVendus.rechercheParId;

    get(url, { id })
      .then((response: any) => {
        setBien(obtenirParIdAdapter(response.data));
        if (display === "print" && handlePrint) handlePrint();
      })
      .catch((error) => {
        setBien(obtenirParIdAdapter({ id: -1 }));
      });
  }, [id, display]);

  let titreSurface,
    titreVille,
    dateVente,
    titre = "",
    tabs: Tab[] = [],
    actions;

  if (bien.id !== "") {
    titreSurface = bien.surface > 0 ? ` ${bien.surface} m\xB2 ` : "";
    titreVille = ` ${t("à")} ${bien.localisation.villeNom}`;
    dateVente = ` ${t("le")} ${moment(bien.dateEffectiveSignature).format(
      "DD/MM/YYYY"
    )}`;
    titre = `${bien.typeAffaireEnum} ${t(
      bien.familleBienEnum
    )}${titreSurface}${titreVille}${dateVente}`;

    tabs = [
      {
        content: <Details bien={bien} t={t} />,
        titre: breakpoint().smAndDown ? t("DET.") : t("DETAILS"),
      },
    ];

    actions =
      handlePrint !== null
        ? [<BoutonImpression key={0} onPrint={handlePrint} />]
        : [];
  }

  if (bien.id === "") return <Typography variant="h2">Loading ...</Typography>;
  // if (bien.id === -1)
  //   return <Typography variant="h2">Non Disponible.</Typography>;

  return (
    <>
      <PrintablePropertyDetails
        bien={bien}
        componentToPrintRef={componentToPrintRef}
        titre={
          <TitrePage
            categorie="Biens | Biens Vendus"
            page={"Fiche"}
            nbResultats=""
          />
        }
      />
      <DetailedEntityTabsLayout
        composantsBarreGauche={[
          {
            composant: (
              <BienCarteReduite
                bien={bien}
                mapMode={"précis"}
                mapPointLabel={etiquetteCarte}
                shouldDisplayCardActions
              />
            ),
          },
          {
            composant: <div style={{ marginBottom: 20 }} />,
          },
          {
            composant: (
              <BienConseiller
                bien={bien}
                t={t}
                userData={userData}
                source={"Fic. BienVendu"}
              />
            ),
          },
        ]}
        titre={
          <TitrePage
            categorie="Biens | Biens vendus"
            page={titre}
            nbResultats=""
            filePath={"/static/markdowns/ficheBienVendu.md"}
          />
        }
        tabs={tabs}
        actions={actions}
        tabActiveIndex={0}
        onClose={handleBackButtonAction}
      />
    </>
  );
};
