import { REFINEMENT_TYPE } from "components/DataProvider";
import useNotifier from "components/Notifier/useNotifier";
import React, { createContext, useEffect, useState } from "react";

import { useTxHttp } from "../http";
import { useServices } from "../index";

export const ReferentielContext = createContext({});

export const ReferentielProvider = (props) => {
  const {
    endpoints,
    l10n: { t },
  } = useServices();

  const { get } = useTxHttp();

  const [referentiel, setReferentiel] = useState({});
  const storageKey = "referentiel";
  const { showSnack, hideSnack } = useNotifier();

  //TODO: langues
  const labels = [
    {
      key: "familleBien",
      title: "Famille de biens",
    },
    {
      key: "optionMandat",
      title: "Option du mandat",
    },
    {
      key: "origineAffaire",
      title: "Origine de l'affaire",
    },
    {
      key: "typeAffaire",
      title: "Type de l'affaire",
    },
  ];

  const convert = (reference, label, type, title) => {
    return {
      count: 0,
      options: {
        multiple: true,
        renderValue: "chips",
        title,
      },
      query: label,
      type: type,
      values: reference.map((item) => ({
        count: 0,
        isRefined: false,
        label: t(item.nom),
        value: item.id,
      })),
    };
  };

  useEffect(() => {
    if (sessionStorage.getItem(storageKey)) {
      setReferentiel(JSON.parse(sessionStorage.getItem(storageKey)));
    } else {
      showSnack({
        autoHideTime: 0,
        message: `${t("RecuperationFiltres")}...`,
        variant: "info",
        withLoader: true,
      });
      (async () => {
        const promises = (url) => [
          get(url, { referentiel: "RefFamilleBien" }),
          get(url, { referentiel: "RefOptionMandat" }),
          get(url, { referentiel: "RefOrigineAffaire" }),
          get(url, { referentiel: "RefTypeAffaire" }),
        ];
        try {
          const responses = await Promise.all(
            promises(endpoints.reporting.referentiel)
          );

          const baseref = responses
            .filter((item, index) => {
              item.label = labels[index].key;
              item.title = labels[index].title;
              return item.data;
            })
            .reduce(
              (acc, current) =>
                Object.assign(acc, {
                  [current.label]: convert(
                    current.data,
                    current.label,
                    REFINEMENT_TYPE.multipleSelection.chips,
                    current.title
                  ),
                }),
              {}
            );
          setReferentiel(baseref);

          hideSnack();
        } catch (e) {
          hideSnack();
          console.log("ERROR REFERENTIEL", e);
        }
      })();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Object.keys(referentiel).length > 0) {
      if (
        Object.values(referentiel).findIndex(
          (item) => typeof item === "string" && item.includes("Error")
        ) < 0 &&
        Object.keys(referentiel).length === labels.length
      ) {
        sessionStorage.setItem(storageKey, JSON.stringify(referentiel));
      }
    }
  }, [referentiel, labels]);

  return (
    <ReferentielContext.Provider value={referentiel}>
      {props.children}
    </ReferentielContext.Provider>
  );
};
