import Typography from "@material-ui/core/Typography";
import React from "react";

const TypeAnnonceur = ({ type }: { type: string }) => {
  const typeLabel = type.toUpperCase();
  return (
    <div
      style={{
        alignItems: "center",
        backgroundColor: typeLabel === "PRO" ? "#53868D" : "#41B0B6",
        borderRadius: "0 0 5px 0",
        color: "white",
        display: "flex",
        height: 17,
        justifyContent: "center",
        width: 37,
      }}
    >
      <Typography variant={"h5"}>{typeLabel}</Typography>
    </div>
  );
};

export default TypeAnnonceur;
