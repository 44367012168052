import { makeStyles, MenuItem } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  menuItem: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    padding: 4,
  },
}));

export const StyledMenuItem = React.forwardRef<
  HTMLElement,
  {
    children: React.ReactNode;
    onClick?: () => void;
  }
>(function MyMenuItem(props, ref) {
  const classes = useStyles();
  return (
    <MenuItem
      onClick={props.onClick}
      classes={{
        root: classes.menuItem,
      }}
      innerRef={ref}
    >
      {props.children}
    </MenuItem>
  );
});
