import React from "react";
import { RealEstatesContainer } from "./containers/network";
import { RealEstatesPage } from "./pages/RealEstatesPage";

export const RealEstates: React.FC = () => {
  return (
    <RealEstatesContainer>
      <RealEstatesPage />
    </RealEstatesContainer>
  );
};
