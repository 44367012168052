import {
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@material-ui/core";
import { RadioProps } from "@material-ui/core/Radio/Radio";
import moment, { isDate } from "moment";
import React, { FC, useState } from "react";

import DatePicker from "../../DatePicker";
import { DateRange, DateRangeValue } from "../types";
import styles from "./DateRangeRefinement.module.css";

const DateRadio: FC<RadioProps> = (props) => {
  const theme = useTheme();
  return (
    <Radio
      className={styles.radio}
      color={"primary"}
      icon={
        <div className={styles.icon}>
          <Typography variant={"subtitle1"}>{props.value as string}</Typography>
          <Typography variant={"subtitle1"} style={{ fontSize: 10 }}>
            mois
          </Typography>
        </div>
      }
      checkedIcon={
        <div
          className={styles.icon}
          style={{
            backgroundColor: theme.palette.primary.main,
            color: "white",
          }}
        >
          <Typography variant={"subtitle1"}>{props.value as string}</Typography>
          <Typography variant={"subtitle1"} style={{ fontSize: 10 }}>
            mois
          </Typography>
        </div>
      }
      {...props}
    />
  );
};

export interface DateRangeProps {
  value: DateRange;
  onChange: (refinement: DateRange) => void;
  label: string;
}

export const DateRangeRefinement: FC<DateRangeProps> = (props) => {
  const [radio, setRadio] = useState("");

  const onChange = (value: DateRangeValue) => {
    props.onChange({ kind: "date-range", value });
  };

  function onClick(event: any) {
    const v = event.target.value;
    if (v === radio) {
      setRadio("");
      onChange({});
    } else {
      setRadio(v);
      switch (v) {
        case "<1":
          onChange({
            max: moment().endOf("day").toDate(),
            min: moment().startOf("day").add(-1, "month").toDate(),
          });
          break;
        case "<2":
          onChange({
            max: moment().endOf("day").toDate(),
            min: moment().startOf("day").add(-2, "month").toDate(),
          });
          break;
        case "<3":
          onChange({
            max: moment().endOf("day").toDate(),
            min: moment().startOf("day").add(-3, "month").toDate(),
          });
          break;
      }
    }
  }
  const formattedValue = {
    max: props.value.value?.max ? new Date(props.value.value?.max) : undefined,
    min: props.value.value?.min ? new Date(props.value.value?.min) : undefined,
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={styles.radioGroup}>
          <Typography variant={"h5"} style={{ flex: "auto" }}>
            {props.label}
          </Typography>
          <RadioGroup row value={radio}>
            <DateRadio onClick={onClick} value="<1" />
            <DateRadio onClick={onClick} value="<2" />
            <DateRadio onClick={onClick} value="<3" />
          </RadioGroup>
        </div>
      </Grid>
      <Grid item xs={12}>
        <DatePicker
          fourchette={{
            dateDebut: formattedValue.min,
            dateFin: formattedValue.max,
          }}
          setFourchette={({
            dateDebut,
            dateFin,
          }: {
            dateDebut?: Date | moment.Moment;
            dateFin?: Date | moment.Moment;
          }) => {
            onChange({
              max: !dateFin
                ? moment().endOf("day").toDate()
                : isDate(dateFin)
                ? dateFin
                : dateFin.endOf("day").toDate(),
              min: !dateDebut
                ? moment().subtract(6, "months").startOf("day").toDate()
                : isDate(dateDebut)
                ? dateDebut
                : dateDebut.startOf("day").toDate(),
            });
          }}
          dateMinimale={moment().subtract(6, "months").startOf("day").toDate()}
          dateMaximale={moment().endOf("day").format("YYYY-MM-DD")}
        />
      </Grid>
    </Grid>
  );
};
