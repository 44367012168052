import { FormControlLabel, Grid, Typography } from "@material-ui/core";
import { Checkbox } from "components/Checkbox";
import React, { MouseEventHandler } from "react";
import {
  CheckedCriteria,
  SearchLifeAnnuityHouseCriteriaDto,
} from "../../../../models/Search/searchLifeAnnuityHouseCriteriaDto";
import { CheckBoxDescription } from "../../shared/types";

export const CriteriaCheckBoxes: React.FC<{
  criteria: SearchLifeAnnuityHouseCriteriaDto;
  checkBoxes: CheckBoxDescription<CheckedCriteria>[];
  handleCheck: (criteriaKey: CheckedCriteria) => MouseEventHandler;
}> = ({ criteria, checkBoxes, handleCheck }) => {
  return (
    <Grid container spacing={2}>
      {checkBoxes.map((checkBox) => (
        <Grid item xs={12} md={6} key={`lah-${checkBox.criteriaKey}`}>
          <div style={{ alignItems: "center", display: "flex" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={criteria[checkBox.criteriaKey]}
                  color={"default"}
                  onClick={handleCheck(checkBox.criteriaKey)}
                />
              }
              label={
                <Typography variant={"body2"}>{checkBox.label}</Typography>
              }
            />
          </div>
        </Grid>
      ))}
    </Grid>
  );
};
