import React from "react";

import { RadioChip } from "UI/shared/molecules/RadioChip";
import { GeneralStateType } from "../../models/generalStateType";
import { SubSectionTitle } from "../components/SectionTitle";

export const SelectGeneralStateType: React.FC<{
  value?: GeneralStateType;
  onChange: (value: GeneralStateType) => void;
}> = ({ value, onChange }) => {
  const options: GeneralStateType[] = [
    "New",
    "Good",
    "ToUpdate",
    "WithRepairs",
  ];

  return (
    <>
      <SubSectionTitle title="Etat général" mode={"non obligatoire"} />
      <RadioChip
        translationPrefix={"generalStateType"}
        options={options}
        value={value}
        onChange={onChange}
      />
    </>
  );
};
