import { Grid, makeStyles, useTheme } from "@material-ui/core";
import { SquareStatusIcon } from "UI/shared/atoms/SquareStatusIcon";
import { StatusInfo } from "UI/shared/atoms/StatusInfo";
import React from "react";
import { ProfileStatusType } from "routes/app/contacts/contact/components/models/profileStatusType";
import { ITheme } from "theme/ts/main";

import { useServices } from "../../../../../services";

const useStyles = makeStyles(() => ({
  leftContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "10px!important",
    width: "50px",
  },
}));

export interface ProfileStatusChipProps {
  status?: ProfileStatusType;
}

export const ProfileStatusChip: React.FC<ProfileStatusChipProps> = ({
  status = "Archived",
}) => {
  const {
    l10n: { t },
  } = useServices();
  const theme = useTheme<ITheme>();
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item className={classes.leftContainer}>
        <SquareStatusIcon
          color={
            status === "Active"
              ? theme.palette.status.active
              : theme.palette.status.archived
          }
        />
      </Grid>

      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <StatusInfo value={t(status)} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
