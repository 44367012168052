import RadarBO from "services/reporting/dto/Reporting/Synthese/sub/RadarBO";

class RadarAdapter {
  static toBO(array = []) {
    return array.map((item) => this.itemToBO(item));
  }

  static itemToBO(item = {}) {
    const bo = new RadarBO();
    bo.cible = item.cible || bo.cible;
    bo.nombreConseillers = item.nombreConseillers || bo.nombreConseillers;
    bo.caActes = item.caActes || bo.caActes;
    bo.caCompromis = item.caCompromis || bo.caCompromis;
    bo.nombreActes = item.nombreActes || bo.nombreActes;
    bo.nombreCompromis = item.nombreCompromis || bo.nombreCompromis;
    bo.nombreMandats = item.nombreMandats || bo.nombreMandats;
    bo.pourcentageExclus = item.pourcentageExclus || bo.pourcentageExclus;

    return bo;
  }
}

export default RadarAdapter;
