import Grid, { GridJustification, GridSize } from "@material-ui/core/Grid";
import React from "react";

interface ColumnProps {
  columnJustify?: GridJustification;
  itemJustify?: GridJustification;
  itemSize?: GridSize;
}

export const Column: React.FC<ColumnProps> = ({
  columnJustify,
  itemJustify,
  itemSize,
  children,
}) => {
  return (
    <Grid container direction="row" justify={columnJustify}>
      <Grid item xs={12} sm={12} md={"auto"}>
        <Grid container direction="column">
          <Grid item xs={12}>
            {React.Children.map(children, (el) => (
              <Grid container justify={itemJustify}>
                <Grid item xs={itemSize ?? 12} sm={itemSize ?? 12} md={"auto"}>
                  {el}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
