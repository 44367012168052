import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";
import { Text12px } from "UI/shared/atoms/typographies/Text12px";

const useStyles = makeStyles(() => ({
  container: {
    alignItems: "center",
    display: "flex",
  },
  iconContainer: {
    padding: "5px 10px 5px 5px",
  },
}));

export const CampaignCreationDate: React.FC<{ creationDate: Date }> = ({
  creationDate,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        <AccessTimeIcon style={{ fontSize: "15px" }} />
      </div>
      <PaddingContainer bottom={5}>
        <Text12px>{`${"Mis à disposition le "}${creationDate.toLocaleDateString(
          "fr-FR",
          {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          }
        )}`}</Text12px>
      </PaddingContainer>
    </div>
  );
};
