import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React from "react";

interface LastUpdateProps {
  date: Date;
}

export const LastUpdate: React.FC<LastUpdateProps> = ({ date }) => (
  <Typography variant="body2" color="textSecondary">
    Mis à jour le {moment(date).format("DD/MM/YYYY")}
  </Typography>
);
