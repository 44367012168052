import React from "react";
import moment from "moment";
import {
  Grid,
  Typography,
  FormControlLabel,
  RadioGroup,
} from "@material-ui/core";
import DatePicker from "components/DatePicker";
import RadioPeriode from "components/RadioPeriode";

const dateMinimale = "2000-01-01";
const dateMaximale = moment().format("YYYY-MM-DD");

const SearchDates = ({
  t,
  dateMin,
  dateMax,
  handleChangeDate,
  libelle = "",
  periodes = [
    {
      libelle: "",
      montantMin: 0,
      montantMax: 10,
      unite: "years",
    },
  ],
}) => {
  return (
    <>
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant={"h5"}>{t(libelle)}&nbsp;</Typography>
          <Periode
            handleChangeDates={handleChangeDate}
            datesInState={{
              dateDebut: dateMin.value,
              dateFin: dateMax.value,
            }}
            periodes={periodes}
          />
        </div>
      </Grid>

      <Grid item xs={12} style={{ paddingBottom: 20, paddingTop: 0 }}>
        <DatePicker
          fourchette={{
            dateDebut: dateMin.value,
            dateFin: dateMax.value,
          }}
          setFourchette={handleChangeDate}
          dateMinimale={dateMinimale}
          dateMaximale={dateMaximale}
        />
      </Grid>
    </>
  );
};

export default SearchDates;

export const Periode = ({ handleChangeDates, datesInState, periodes }) => {
  const [periode, setPeriode] = React.useState(periodes[0]);

  const handlePeriodeChange = (periode) => {
    setPeriode(periode);
    const chosenDates = {
      ...datesInState,
    };
    chosenDates.dateFin = moment().format("YYYY-MM-DD");

    for (let i = 0; i < periodes.length; i++) {
      if (periode.libelle === periodes[i].libelle) {
        chosenDates.dateFin = moment(chosenDates.dateFin)
          .subtract(periodes[i].montantMin, periodes[i].unite)
          .format("YYYY-MM-DD");
        chosenDates.dateDebut =
          i === 0
            ? moment(dateMinimale)
            : moment(chosenDates.dateFin)
                .subtract(periodes[i].montantMax, periodes[i].unite)
                .format("YYYY-MM-DD");
      }
    }

    handleChangeDates(chosenDates);
  };

  const handleAlreadySelected = ({ valeur, valeurChoisie }) => {
    if (valeur === valeurChoisie) {
      handlePeriodeChange(periodes[0]);
    } else {
      const nouvellePeriode = periodes.findIndex(
        (periode) => periode.libelle === valeur
      );
      handlePeriodeChange(periodes[nouvellePeriode]);
    }
  };

  return (
    <RadioGroup
      row
      value={periode.libelle}
      onChange={() => {}}
      style={{
        display: "flex",
        justifyContent: "flex-between",
      }}
    >
      {periodes.map((_periode, index) =>
        index === 0 ? (
          <div key={"periode_hidden"}>&nbsp;</div>
        ) : (
          <FormControlLabel
            key={_periode.libelle}
            value={_periode.libelle}
            control={
              <RadioPeriode
                color="primary"
                nom={_periode.libelle}
                valeur={_periode.libelle}
                valeurChoisie={periode.libelle}
                handleAlreadySelected={handleAlreadySelected}
              />
            }
            label={""}
          />
        )
      )}
    </RadioGroup>
  );
};
