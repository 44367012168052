import { EndpointsProvider } from "./interfaces/EndpointsProvider";

interface NotificationsCenterEndpoints {
  newNotifications: string;
  readNotifications: string;
  archivedNotifications: string;
  newAndReadNotifications: string;
}

export interface NotificationsEndpoints {
  notificationsCenter: NotificationsCenterEndpoints;
  markAsRead: string;
  markAsArchived: (id: string) => string;
  markAllAsArchived: string;
  notificationSettingsCenter: string;
  updateNotificationSettingsCenter: string;
}

const endpoints: EndpointsProvider<NotificationsEndpoints> = (
  microServices
) => ({
  markAllAsArchived: `${microServices.tx(
    "notifications"
  )}/v1/notificationsCenter/notifications/archiveAll`,
  markAsArchived: (id) =>
    `${microServices.tx(
      "notifications"
    )}/v1/notificationsCenter/notifications/${id}/archive`,
  markAsRead: `${microServices.tx(
    "notifications"
  )}/v1/notificationsCenter/notifications/markAsRead`,
  notificationSettingsCenter: `${microServices.tx(
    "notifications"
  )}/v1/notificationSettingsCenter`,
  notificationsCenter: {
    archivedNotifications: `${microServices.tx(
      "notifications"
    )}/v1/notificationsCenter/notifications/archived`,
    newAndReadNotifications: `${microServices.tx(
      "notifications"
    )}/v1/notificationsCenter/notifications/newAndRead`,
    newNotifications: `${microServices.tx(
      "notifications"
    )}/v1/notificationsCenter/notifications/new`,
    readNotifications: `${microServices.tx(
      "notifications"
    )}/v1/notificationsCenter/notifications/read`,
  },
  updateNotificationSettingsCenter: `${microServices.tx(
    "notifications"
  )}/v1/notificationSettingsCenter/update`,
});

export default endpoints;
