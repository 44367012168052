import { PAGE_SIZE_GALLERY, PAGE_SIZE_LIST, PAGE_SIZE_MAP } from "vars/global";

export const serialize = (params) => {
  return btoa(encodeURIComponent(JSON.stringify(params)));
};

export const deserialize = (params) =>
  JSON.parse(decodeURIComponent(atob(params)));

export const pageSizeFromMode = new Map([
  ["map", { size: PAGE_SIZE_MAP }],
  ["gallery", { size: PAGE_SIZE_GALLERY }],
  ["list", { size: PAGE_SIZE_LIST }],
]);
