import { Chip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import React, { FC } from "react";

interface DeleteChipProps {
  onClear: () => void;
}

export const DeleteChip: React.FC<DeleteChipProps> = ({ onClear }) => (
  <Chip
    style={{ marginBottom: 6 }}
    icon={<DeleteIcon fontSize="small" />}
    size="small"
    label="Effacer"
    color="primary"
    aria-label="delete"
    onClick={onClear}
  />
);
