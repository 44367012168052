import { DialogProps } from "@material-ui/core";

export type ModalRegisterProps = {
  open: boolean;
  fullScreen: boolean;
  body: JSX.Element;
  maxWidth: ModalSize;
};

export interface IModalService {
  show(element: JSX.Element): void;

  close(): void;

  register(
    setState: (
      set: (previous: ModalRegisterProps) => ModalRegisterProps
    ) => void
  ): void;

  resize(maxWidth: ModalSize): void;
}

export function ModalService(): IModalService {
  let setState: (
    set: (previous: ModalRegisterProps) => ModalRegisterProps
  ) => void;

  return {
    close() {
      setState((previous) => ({ ...previous, open: false }));
    },
    register(
      setter: (
        set: (previous: ModalRegisterProps) => ModalRegisterProps
      ) => void
    ) {
      setState = setter;
    },
    resize(maxWidth: ModalSize) {
      setState((previous) => ({
        ...previous,
        maxWidth,
      }));
    },
    show(Component: JSX.Element) {
      setState((previous) => ({
        ...previous,
        body: Component,
        fullScreen: document.body.clientWidth < 600,
        maxWidth: "sm",
        open: true,
      }));
    },
  };
}

export type ModalSize = ReturnType<typeof f1>;

function f1(arg: Pick<DialogProps, "maxWidth">) {
  return arg.maxWidth || false;
}
