import { Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import { CallMade, CallReceived } from "@material-ui/icons";
import ListeCriteres from "components/ListeCriteres";
import SortSelect from "components/SortSelect";
import PropType from "prop-types";
import React, { forwardRef, useEffect, useState } from "react";

import { useServices } from "../../../services";

const useStyles = makeStyles((theme) => ({
  sortSelectRoot: {
    alignSelf: "flex-end",
    borderBottom: ({ sortSelectFocused }) =>
      `1px solid ${
        sortSelectFocused
          ? theme.palette.primary.main
          : theme.palette.notSelected.grey
      }`,
    height: "fit-content",
    marginBottom: "10px",
    marginLeft: "auto",
  },
}));

const renderSortSelectOption = ({ label, isAscendingOrder }) => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
      }}
    >
      {label}
      {isAscendingOrder ? (
        <CallMade style={{ fontSize: "1.2em", marginLeft: "10px" }} />
      ) : (
        <CallReceived
          style={{
            fontSize: "1.2em",
            marginLeft: "10px",
            transform: "rotate(-90deg)",
          }}
        />
      )}
    </div>
  );
};

// eslint-disable-next-line react/display-name
const HitsContainer = forwardRef(
  (
    {
      searchbar,
      refinements,
      clearRefinements,
      onChangeSorting,
      onChange,
      sortSelectSize = "small",
      filterOptions = [],
      ...props
    },
    ref
  ) => {
    const {
      l10n: { t },
    } = useServices();
    const refinementsValues = refinements.toObject();
    const [defaultSortSelect, setDefaultSortSelect] = useState(null);
    const [sortSelectFocused, setSortSelectFocused] = useState(false);
    const classes = useStyles({ sortSelectFocused });

    const handleSelectedOptionChange = ({ option }) => {
      const { isAscendingOrder, query } = filterOptions.find(
        (filter) => filter.value === option.value
      );

      return onChangeSorting({
        sortOptions: [
          {
            query: "sortColumn",
            value: query,
          },
          {
            query: "sortAsc",
            value: isAscendingOrder,
          },
        ],
      });
    };
    const handleSortSelectFocus = () => {
      setSortSelectFocused(true);
    };
    const handleSortSelectBlur = () => {
      setSortSelectFocused(false);
    };

    useEffect(() => {
      const defaultSortSelect =
        refinementsValues &&
        refinementsValues.sortColumn &&
        refinementsValues.sortAsc
          ? filterOptions.find(
              (filter) =>
                filter.isAscendingOrder === refinementsValues.sortAsc.value &&
                filter.query === refinementsValues.sortColumn.value
            )
          : filterOptions[0];

      setDefaultSortSelect(defaultSortSelect);
    }, [
      refinementsValues.sortColumn,
      refinementsValues.sortAsc,
      filterOptions,
      refinementsValues,
    ]);

    return (
      <>
        <div style={{ width: "100%" }}>{searchbar}</div>

        <Grid container>
          <Hidden smDown>
            <Grid
              item
              xs={12}
              sm={sortSelectSize === "big" ? 12 : 6}
              md={sortSelectSize === "big" ? 12 : 9}
            >
              <ListeCriteres
                items={refinementsValues}
                clearRefinements={clearRefinements}
                onChange={onChange}
              />
            </Grid>
          </Hidden>

          <Grid
            item
            xs={12}
            sm={sortSelectSize === "big" ? 12 : 6}
            md={sortSelectSize === "big" ? 12 : 3}
            container
            alignItems={"center"}
            direction={"row"}
            className={classes.sortSelectRoot}
          >
            <Typography style={{ fontSize: "12px", marginBottom: "1px" }}>
              {t("Trier par : ")}
            </Typography>

            <SortSelect
              onFocus={handleSortSelectFocus}
              onBlur={handleSortSelectBlur}
              containerStyle={{
                flex: 1,
              }}
              controlStyle={{
                background: "transparent",
                border: "none",
              }}
              filters={filterOptions}
              onSelect={handleSelectedOptionChange}
              value={defaultSortSelect}
              customFilterOption={renderSortSelectOption}
            />
          </Grid>
        </Grid>

        <Grid ref={ref} container spacing={2}>
          {props.children}
        </Grid>
      </>
    );
  }
);

HitsContainer.propTypes = {
  searchbar: PropType.object,
};

export default HitsContainer;
