import { MicroServicesConfiguration } from "services/configuration/configuration";

import { EndpointsProvider } from "./interfaces/EndpointsProvider";

export interface BienEnVenteEndpoints {
  rechercheParIdCarte: string;
  rechercheGlobale: string;
  rechercheGridOuListe: string;
  rechercheCarte: string;
  rechercheParId: string;
  modifier: string;
  rechercheParRefAnnonce: string;
}

const endpoints: EndpointsProvider<BienEnVenteEndpoints> = (
  microServices: MicroServicesConfiguration
) => ({
  modifier: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/BiensEnVente/Modifier`,
  rechercheCarte: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/BiensEnVente/RechercheCarte`,
  rechercheGlobale: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/BiensEnVente/RechercheGlobale`,
  rechercheGridOuListe: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/BiensEnVente/RechercheGridOuListe`,
  rechercheParId: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/BiensEnVente/ObtenirParId`,
  rechercheParIdCarte: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/BiensEnVente/ObtenirParIdCarte`,
  rechercheParRefAnnonce: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/BiensEnVente/ObtenirParReferenceAnnonce`,
});

export default endpoints;
