import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";

import { Row } from "UI/shared/atoms/containers/Row";
import { ITheme } from "theme/ts/main";
import { PremisesParkingInfoDto } from "../../../../models/Proposal/ForSale/Premises/premisesParkingInfoDto";
import { SubSectionTitle } from "../../../components/SectionTitle";
import { ValidationNumberField } from "../../../components/ValidationNumberField";
import { ProfileInputProps } from "../../../InputProps";

const getOnChange = (
  key: "isCovered" | "isOutdoor" | "isClosed",
  onChange: (value: PremisesParkingInfoDto, isValid: boolean) => void,
  value: PremisesParkingInfoDto
) => {
  return () => {
    onChange({ ...value, [key]: !value[key] }, true);
  };
};

const useStyles = makeStyles((theme: ITheme) => {
  return {
    checkbox: {
      color: theme.palette.primary.main,
    },
  };
});

export const PremisesParkingInfo: React.FC<ProfileInputProps<
  PremisesParkingInfoDto
>> = ({ onChange, value }) => {
  const classes = useStyles();
  return (
    <>
      <SubSectionTitle title="Stationnements" mode={"non obligatoire"} />
      <Row rowAlignItems={"center"} rowJustify={"flex-start"}>
        <Typography
          variant={"body2"}
          style={{ marginRight: 5, verticalAlign: "middle" }}
        >
          Nombre de stationnements privés
        </Typography>
        <ValidationNumberField
          disabled={false}
          label={""}
          required={false}
          onChange={(isValid, numberOfParking) => {
            onChange({ ...value, numberOfParking: numberOfParking || 0 }, true);
          }}
          value={value.numberOfParking}
          minLimit={0}
          maxLimit={1000000}
        />
      </Row>
      <FormControlLabel
        control={
          <Checkbox
            color={"default"}
            classes={{ root: classes.checkbox }}
            checked={value.isClosed}
            onChange={getOnChange("isClosed", onChange, value)}
            inputProps={{ "aria-label": "checkbox" }}
          />
        }
        label={<Typography variant={"body2"}>Garage fermé</Typography>}
      />
      <FormControlLabel
        control={
          <Checkbox
            color={"default"}
            classes={{ root: classes.checkbox }}
            checked={value.isCovered}
            onChange={getOnChange("isCovered", onChange, value)}
            inputProps={{ "aria-label": "checkbox" }}
          />
        }
        label={<Typography variant={"body2"}>Parking couvert</Typography>}
      />
      <FormControlLabel
        control={
          <Checkbox
            color={"default"}
            classes={{ root: classes.checkbox }}
            checked={value.isOutdoor}
            onChange={getOnChange("isOutdoor", onChange, value)}
            inputProps={{ "aria-label": "checkbox" }}
          />
        }
        label={<Typography variant={"body2"}>Parking extérieur</Typography>}
      />
    </>
  );
};
