import React, { useEffect, useState } from "react";
import CarteConseiller from "../../components/Hit/components/CarteConseiller";
import AvatarPetit from "../../components/Hit/components/AvatarPetit";

export default function useObtenirTousLesConseillersDUnLieu({ items }) {
  const [cartesConseillers, setCartesConseillers] = useState([]);

  useEffect(() => {
    setCartesConseillers(
      items.map((item) => ({
        conseiller: item,
        avatarPetit: <AvatarPetit key={item.id} id={item.id} />,
        carteConseiller: (
          <CarteConseiller key={item.id} type={"obtenirParId"} item={item} />
        ),
      }))
    );
    // eslint-disable-next-line
  }, []);

  return { cartesConseillers };
}
