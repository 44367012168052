import React, { createContext, FC, useState } from "react";

export const ExportFormContext = createContext<{
  selectedContacts: string[];
  setSelectedContacts: React.Dispatch<React.SetStateAction<string[]>>;
}>({
  selectedContacts: [],
  setSelectedContacts: () => {
    return;
  },
});

export const ExportFormContextProvider: FC = ({ children }) => {
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);

  return (
    <ExportFormContext.Provider
      value={{
        selectedContacts,
        setSelectedContacts,
      }}
    >
      {children}
    </ExportFormContext.Provider>
  );
};
