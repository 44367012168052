import React from "react";
import { RealEstateDto } from "services/profiles/dto/RealEstateDto";
import { RealEstateProperty } from "UI/features/realEstates/organisms/RealEstateProperty";
import { toRealEstatePropertyProps } from "../../templates/SearchResult/adapters/toRealEstatePropertyProps";

interface InfiniteScrollItemProps {
  item: RealEstateDto;
  itemIndex: number;
  triggerIndex: number;
  ViewObserverFirst: React.FC;
  ViewObserverLast: React.FC;
}

export const InfiniteScrollItem: React.FC<InfiniteScrollItemProps> = ({
  item,
  itemIndex,
  triggerIndex,
  ViewObserverFirst,
  ViewObserverLast,
}) => {
  const isFirstObservedItem = itemIndex === 0;
  const isLastObservedItem = triggerIndex !== 0 && itemIndex === triggerIndex;

  const realEstateProperty = (
    <RealEstateProperty {...toRealEstatePropertyProps(item)} />
  );

  switch (true) {
    case isFirstObservedItem:
      return (
        <div key={`${item.id}-first`}>
          {realEstateProperty}
          <ViewObserverFirst>
            <></>
          </ViewObserverFirst>
        </div>
      );
    case isLastObservedItem:
      return (
        <div key={`${item.id}-last`}>
          <ViewObserverLast>{realEstateProperty}</ViewObserverLast>
        </div>
      );
    default:
      return <div key={`${item.id}`}>{realEstateProperty}</div>;
  }
};
