import { useMemo, useState } from "react";
import { useServices } from "services";
import { useTxHttp } from "services/http";

export type RealEstateSharingStep = "selection" | "mail";
type State = {
  agent: string[];
  network: string[];
};

export const useRealEstatesSharing = (profileId: string) => {
  const [selection, setSelection] = useState<State>({ agent: [], network: [] });
  const [sharedRealEstates, setSharedRealEstates] = useState<
    Record<number, string[]>
  >({});

  const [step, setStep] = useState<RealEstateSharingStep>("selection");
  const { get } = useTxHttp();
  const { endpoints } = useServices();

  const getSharedRealEstates = async (
    page: number,
    realEstateIds: string[]
  ) => {
    const { data } = await get<string[]>(
      endpoints.profiles.sharedRealEstates(profileId),
      { realEstateIds }
    );

    if (!data) return;

    setSharedRealEstates((prev) => ({ ...prev, [page]: data }));
  };

  const handleCheck = (selectionType: keyof State) => (
    id: string,
    checked: boolean
  ) => {
    setSelection((prev) => ({
      ...prev,
      [selectionType]: checked
        ? [...prev[selectionType], id]
        : prev[selectionType].filter((selected) => selected !== id),
    }));
  };

  const handleMultipleCheck = (selectionType: keyof State) => (
    ids: string[],
    checked: boolean
  ) => {
    setSelection((prev) => ({
      ...prev,
      ...(checked && { [selectionType]: ids }),
      ...(!checked && { [selectionType]: [] }),
    }));
  };

  const handleStepChange = (nextStep: RealEstateSharingStep) => {
    setStep(nextStep);
  };

  const isChecked = (selectionType: keyof State) => (id: string) =>
    selection[selectionType].includes(id);

  const isShared = (page: number, id: string) =>
    sharedRealEstates[page]?.includes(id);

  const selectionTotal = useMemo(
    () => selection.agent.length + selection.network.length,
    [selection.agent.length, selection.network.length]
  );

  return {
    getSharedRealEstates,
    step,
    selection,
    selectionTotal,
    handleCheck,
    handleStepChange,
    handleMultipleCheck,
    isChecked,
    isShared,
  };
};
