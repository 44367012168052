import React from "react";
import { BudgetLifeAnnuityDto } from "routes/app/contacts/contact/components/models/budgetLifeAnnuityDto";
import { ConstructionYears } from "../../../../models/constructionYears";
import { MaturityType } from "../../../../models/maturityType";
import { PlaceDto } from "../../../../models/placeDto";
import { CheckedCriteria } from "../../../../models/Search/searchLifeAnnuityHouseCriteriaDto";
import { SearchLifeAnnuityHouseProfileWriteDto } from "../../../../models/Search/searchLifeAnnuityHouseProfileWriteDto";
import { VacancyType } from "../../../../models/vacancyType";
export type FormValidity = {
  budget: boolean;
  homeSize: boolean;
  lotSize: boolean;
  places: boolean;
};

const vacancyUpdater: (
  vacancy: VacancyType
) => React.SetStateAction<SearchLifeAnnuityHouseProfileWriteDto> = (
  vacancy
) => (prev) => ({
  ...prev,
  criteria: {
    ...prev.criteria,
    vacancy,
  },
});

const constructionYearsUpdater: (
  constructionYers: ConstructionYears
) => React.SetStateAction<SearchLifeAnnuityHouseProfileWriteDto> = (
  constructionYears
) => (prev) => ({
  ...prev,
  criteria: {
    ...prev.criteria,
    constructionYears,
  },
});

const placesUpdater: (
  places: PlaceDto[]
) => React.SetStateAction<SearchLifeAnnuityHouseProfileWriteDto> = (places) => (
  prev
) => ({
  ...prev,
  criteria: { ...prev.criteria, places },
});

const formValidityUpdater: (
  key: keyof FormValidity,
  isValid: boolean
) => React.SetStateAction<FormValidity> = (key, isValid) => (prev) => ({
  ...prev,
  [key]: isValid,
});

const budgetUpdater: (
  budget: BudgetLifeAnnuityDto
) => React.SetStateAction<SearchLifeAnnuityHouseProfileWriteDto> = (budget) => (
  prev
) => ({
  ...prev,
  criteria: { ...prev.criteria, budget },
});

const homeSizeUpdater: (homeSize: {
  min?: number;
  max?: number;
}) => React.SetStateAction<SearchLifeAnnuityHouseProfileWriteDto> = (
  homeSize
) => (prev) => ({
  ...prev,
  criteria: { ...prev.criteria, homeSize },
});

const lotSizeUpdater: (lotSize: {
  min?: number;
  max?: number;
}) => React.SetStateAction<SearchLifeAnnuityHouseProfileWriteDto> = (
  lotSize
) => (prev) => ({
  ...prev,
  criteria: { ...prev.criteria, lotSize },
});

const minRoomsUpdater: (
  minRooms: number
) => React.SetStateAction<SearchLifeAnnuityHouseProfileWriteDto> = (
  minRooms
) => (prev) => ({
  ...prev,
  criteria: { ...prev.criteria, minRooms },
});

const minBeedRoomsUpdater: (
  minBedRooms: number
) => React.SetStateAction<SearchLifeAnnuityHouseProfileWriteDto> = (
  minBedRooms
) => (prev) => ({
  ...prev,
  criteria: { ...prev.criteria, minBedRooms },
});

const checkedCriteriaUpdater: (
  key: CheckedCriteria
) => React.SetStateAction<SearchLifeAnnuityHouseProfileWriteDto> = (key) => (
  prev
) => ({
  ...prev,
  criteria: {
    ...prev.criteria,
    [key]: !prev.criteria[key],
  },
});

const maturityTypeUpdater: (
  maturity: MaturityType
) => React.SetStateAction<SearchLifeAnnuityHouseProfileWriteDto> = (
  maturity
) => (prev) => ({
  ...prev,
  criteria: { ...prev.criteria, maturity },
});

export const useChanges = (
  setPayload: (
    value: React.SetStateAction<SearchLifeAnnuityHouseProfileWriteDto>
  ) => void,
  setFormValidity: (value: React.SetStateAction<FormValidity>) => void
) => {
  const onVacancyChange = React.useCallback(
    (vacancy: VacancyType) => {
      setPayload(vacancyUpdater(vacancy));
    },
    [setPayload]
  );

  const onConstructionYearsChange = React.useCallback(
    (constructionYears: ConstructionYears) => {
      setPayload(constructionYearsUpdater(constructionYears));
    },
    [setPayload]
  );

  const onPlacesChange = React.useCallback(
    (places: PlaceDto[], isValid: boolean) => {
      setFormValidity(formValidityUpdater("places", isValid));
      setPayload(placesUpdater(places));
    },
    [setPayload, setFormValidity]
  );

  const onBudgetChange = React.useCallback(
    (budget: BudgetLifeAnnuityDto, isValid: boolean) => {
      setFormValidity(formValidityUpdater("budget", isValid));
      setPayload(budgetUpdater(budget));
    },
    [setPayload, setFormValidity]
  );

  const onHomeSizeChange = React.useCallback(
    (homeSize: { min?: number; max?: number }, isValid: boolean) => {
      setFormValidity(formValidityUpdater("homeSize", isValid));
      setPayload(homeSizeUpdater(homeSize));
    },
    [setPayload, setFormValidity]
  );

  const onLotSizeChange = React.useCallback(
    (lotSize: { min?: number; max?: number }, isValid: boolean) => {
      setFormValidity(formValidityUpdater("lotSize", isValid));
      setPayload(lotSizeUpdater(lotSize));
    },
    [setPayload, setFormValidity]
  );

  const onMinRoomsChange = React.useCallback(
    (minRooms: number) => {
      setPayload(minRoomsUpdater(minRooms));
    },
    [setPayload]
  );

  const onMinBedRoomsChange = React.useCallback(
    (minBedRooms: number) => {
      setPayload(minBeedRoomsUpdater(minBedRooms));
    },
    [setPayload]
  );

  const onCheckedCriteriaChange = React.useCallback(
    (key: CheckedCriteria) => {
      setPayload(checkedCriteriaUpdater(key));
    },
    [setPayload]
  );

  const onNotesChange: (notes: string) => void = React.useCallback(
    (notes) => {
      setPayload((prev) => ({ ...prev, notes }));
    },
    [setPayload]
  );

  const onMaturityChange = React.useCallback(
    (maturity: MaturityType) => {
      setPayload(maturityTypeUpdater(maturity));
    },
    [setPayload]
  );

  return {
    onPlacesChange,
    onVacancyChange,
    onBudgetChange,
    onCheckedCriteriaChange,
    onConstructionYearsChange,
    onHomeSizeChange,
    onLotSizeChange,
    onMaturityChange,
    onNotesChange,
    onMinBedRoomsChange,
    onMinRoomsChange,
  };
};
