import React from "react";
import { Grid } from "@material-ui/core";
import InformationLine from "../InformationLine";
import Section from "../Section";

export interface Information {
  key: string;
  value?: string;
}

interface ILSectionProps {
  title: string;
  informationsList: Information[];
}

const InformationLinesSection = ({
  title,
  informationsList,
}: ILSectionProps) => {
  if (informationsList.length === 0) return <></>;
  return (
    <Section title={title}>
      <Grid container spacing={0} direction={"column"}>
        {informationsList.map((information) => (
          <InformationLine
            key={information.key}
            customKey={information.key}
            value={information.value}
          />
        ))}
      </Grid>
    </Section>
  );
};

export default InformationLinesSection;
