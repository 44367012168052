import { Grid, Typography } from "@material-ui/core";
import React from "react";

import { useServices } from "../../../../../services";

const Surfaces = (props) => {
  const { surfaces, isCE = false } = props;
  const {
    l10n: { t },
  } = useServices();
  return (
    <Grid container spacing={0}>
      {surfaces.surfacesInterieures.map((surfaceInterieure) => (
        <Grid item xs={12} key={surfaceInterieure[0]}>
          {parseFloat(surfaceInterieure[1]) > 0 && (
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography variant="subtitle1" align="right">
                  {t(
                    surfaceInterieure[0] === "Surface habitable" && isCE
                      ? "Surface totale"
                      : surfaceInterieure[0]
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                &nbsp;
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h4">
                  {parseFloat(surfaceInterieure[1]) > 0
                    ? `${surfaceInterieure[1]} m\xB2`
                    : "N.C."}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      ))}
      {surfaces.superficieTerrain > 0 && (
        <>
          <Grid item xs={6}>
            <Typography variant="subtitle1" align="right">
              {t("Superficie Terrain")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h4">
              {surfaces.superficieTerrain > 0
                ? `${surfaces.superficieTerrain} m\xB2`
                : "N.C."}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Surfaces;
