import { parsePhoneNumberFromString } from "libphonenumber-js";

export const validatePhoneNumber = (value: string): string => {
  let _value = value;
  if (value === "") return "";
  if (value.charAt(0) !== "+") _value = `+33${value}`;
  if (value.substring(0, 2) === "00") _value = `+${value.substring(2)}`;
  const phoneNumber = parsePhoneNumberFromString(_value);
  if (!phoneNumber || (phoneNumber && !phoneNumber.isValid()))
    return "Le numéro de téléphone n'est pas valide.";
  return "";
};

export const validateEmail = (value: string): string => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!emailRegex.test(value)) return "L'email n'est pas bien formé";
  return "";
};
