import { Grid, Typography } from "@material-ui/core";
import SwitchOuiNon from "components/SwitchOuiNon";
import React from "react";

import TitreSection from "../../../../../../../../layoutContents/Fiches/components/Details/components/TitreSection";

const TableauDeBord = ({ conseiller }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TitreSection titre={"tableau de bord"} />
      </Grid>
      <Grid item xs={12}>
        <Rubrique titre={"Calendrier"}>
          Afficher le widget calendrier sur le tableau de bord.
        </Rubrique>
        <Rubrique titre={"Biens Récents sur mon secteur"}>
          Afficher le widget des biens publiés depuis une semaine sur mon
          secteur.
        </Rubrique>
      </Grid>
    </Grid>
  );
};

export default TableauDeBord;

const Rubrique = ({ titre, children }) => (
  <Grid container spacing={1} alignItems={"center"}>
    <Grid item xs={4} md={2}>
      <SwitchOuiNon />
    </Grid>
    <Grid item xs={8} md={10}>
      <Typography variant={"h5"}>{titre}</Typography>
      <Typography variant={"h6"} style={{ fontWeight: "normal" }}>
        {children}
      </Typography>
    </Grid>
  </Grid>
);
