import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { FeaturesContextProvider } from "services/featureFlags";
import { UserContextProvider } from "services/user";
import { PrivateRoute } from "../services/routing/PrivateRoute";
import { AppPage } from "./app/AppPage";
import { CallbackPage } from "./callback/CallbackPage";
import { Onboarding } from "./onboarding/Onboarding";

export const Home = () => {
  return (
    <FeaturesContextProvider>
      <UserContextProvider>
        <Switch>
          <PrivateRoute path="/onboarding" component={Onboarding} />
          <PrivateRoute path="/app" component={AppPage} />
          <Route path={"/callback"} component={CallbackPage} />
          <Route render={() => <GoToLogin />} />
        </Switch>
      </UserContextProvider>
    </FeaturesContextProvider>
  );
};

const GoToLogin = () => {
  const pageToRedirectTo = localStorage.getItem("isOnboarded")
    ? "/app/dashboard"
    : "/onboarding";
  return <Redirect to={pageToRedirectTo} />;
};
