import { Grid, Typography } from "@material-ui/core";
import React, { FC, useState } from "react";
import { formatPourcent, formatPrix } from "utils/formatage";

import { ApartmentFinancialInfoDto } from "../../../../models/Proposal/ForSale/Apartment/apartmentFinancialInfoDto";
import { SectionTitle } from "../../../components/SectionTitle";
import { ValidationNumberField } from "../../../components/ValidationNumberField";
import { ProfileInputProps } from "../../../InputProps";

function isDefinedAndGreateThanZero(candidate: number | undefined) {
  return candidate !== undefined && candidate > 0;
}

export const SaleBudgetApartement: FC<{
  financialInfo: ApartmentFinancialInfoDto;
}> = ({ financialInfo }) => (
  <>
    {isDefinedAndGreateThanZero(financialInfo.price) && (
      <Typography variant="h5">
        Prix (honoraires inclus) : {formatPrix(financialInfo.price)}
      </Typography>
    )}
    {isDefinedAndGreateThanZero(financialInfo.agentFees) && (
      <Typography variant="h5">
        Taux honoraires personnalisé : {formatPourcent(financialInfo.agentFees)}
      </Typography>
    )}
    {isDefinedAndGreateThanZero(financialInfo.taxProperty) && (
      <Typography variant="body1">
        Taxe foncière : {formatPrix(financialInfo.taxProperty)}
      </Typography>
    )}
    {isDefinedAndGreateThanZero(financialInfo.condominiumFees) && (
      <Typography variant="body1">
        Charges de copropriété {formatPrix(financialInfo.condominiumFees)}
      </Typography>
    )}
  </>
);

interface SaleBudgetApartmentValidation {
  price: boolean;
  agentFees: boolean;
  taxProperty: boolean;
  condominiumFees: boolean;
}

const getGlobalValidation = (globalValidation: SaleBudgetApartmentValidation) =>
  globalValidation.price &&
  globalValidation.agentFees &&
  globalValidation.taxProperty &&
  globalValidation.condominiumFees;

export const SaleBudgetApartmentInput: FC<ProfileInputProps<
  ApartmentFinancialInfoDto
>> = ({ pristine, value, onChange, more, disabled = false }) => {
  const [globalValidation, setGlobalValidation] = useState({
    agentFees: true,
    condominiumFees: true,
    price: true,
    taxProperty: true,
  });

  return (
    <>
      <SectionTitle title="Quel est le prix de vente ?" />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ValidationNumberField
            disabled={disabled}
            pristine={pristine}
            label="Prix (honoraires inclus)"
            onChange={(isValid, price) => {
              const _globalValidation = {
                ...globalValidation,
                price: isValid,
              };
              setGlobalValidation(_globalValidation);
              onChange(
                {
                  ...value,
                  price,
                },
                getGlobalValidation(_globalValidation)
              );
            }}
            required={true}
            value={value.price}
            adornement={"€"}
            minLimit={1}
          />
        </Grid>
        <Grid item xs={6}>
          <ValidationNumberField
            disabled={disabled}
            pristine={pristine}
            label="Taux honoraires"
            onChange={(isValid, agentFees) => {
              const _globalValidation = {
                ...globalValidation,
                agentFees: isValid,
              };
              setGlobalValidation(_globalValidation);
              onChange(
                { ...value, agentFees },
                getGlobalValidation(_globalValidation)
              );
            }}
            required={true}
            value={value.agentFees}
            adornement={"%"}
            decimalScale={2}
            minLimit={0.01}
            maxLimit={100}
          />
        </Grid>
      </Grid>
      <Typography variant={"body2"}>
        {"Prix (net vendeur) :\u00a0"}
        <span style={{ fontWeight: "bold" }}>
          {!isNaN(value.price!) &&
            !isNaN(value.agentFees!) &&
            formatPrix(Math.round(value.price! / (1 + value.agentFees! / 100)))}
        </span>
      </Typography>
      {more && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ValidationNumberField
              disabled={disabled}
              pristine={pristine}
              label="Taxe foncière"
              onChange={(isValid, taxProperty) => {
                const _globalValidation = {
                  ...globalValidation,
                  taxProperty: isValid,
                };
                setGlobalValidation(_globalValidation);
                onChange(
                  { ...value, taxProperty },
                  getGlobalValidation(_globalValidation)
                );
              }}
              value={value.taxProperty}
              adornement={"€/an"}
            />
          </Grid>
          <Grid item xs={6}>
            <ValidationNumberField
              disabled={disabled}
              pristine={pristine}
              label="Charges copropriété"
              onChange={(isValid, condominiumFees) => {
                const _globalValidation = {
                  ...globalValidation,
                  condominiumFees: isValid,
                };
                setGlobalValidation(_globalValidation);
                onChange(
                  { ...value, condominiumFees },
                  getGlobalValidation(_globalValidation)
                );
              }}
              value={value.condominiumFees}
              adornement={"€/an"}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
