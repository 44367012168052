import { useServices } from "services";
import useHTTPService from "services/dataSources/communicationServices/useHTTPService";

import lieuxMockup from "./lieuxMockup.json";

function useConseillerAPI({ adapter }) {
  const { endpoints } = useServices();
  // TODO: use `useTxHttp() hook`
  return useHTTPService({
    adapter,
    endpoint: endpoints.conseillers.monProfil,
  });
}

function useLieuMockup({ id, adapter }) {
  const lieuTrouve = lieuxMockup.find((conseiller) => conseiller.id === id);
  if (lieuTrouve)
    return { adaptedData: adapter(lieuTrouve), status: "resolved" };
  return { adaptedData: adapter(), status: "error" };
}

export const useConseiller = useConseillerAPI;

export const useLieu = useLieuMockup;
