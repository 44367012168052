import { makeStyles } from "@material-ui/core";
import { ITheme } from "theme/ts/main";

export const useStyles = makeStyles((theme: ITheme) => {
  const baseFab = {
    backgroundColor: theme.palette.tonique,
    bottom: -25,
    color: "white",
    position: "absolute",
    right: -10,
  };

  return {
    avatar: {
      backgroundColor: theme.palette.default,
    },
    chaud: {
      color: "red",
    },
    chipLabel: {
      fontSize: "0.6rem",
      marginLeft: 7,
      marginRight: 7,
    },
    clickable: {
      color: theme.palette.tonique,
      fontSize: 16,
      marginRight: 10,
    },
    clickableText: {
      "&:hover": {
        color: theme.palette.tonique,
      },
    },
    divider: {
      backgroundColor: theme.palette.common.main,
    },
    // fab: baseFab,
    fabTab: {
      ...baseFab,
      bottom: 50,
      left: "inherit",
      position: "fixed",
      right: 30,
    },
    froid: {
      color: "blue",
    },
    neutre: {
      color: theme.palette.common.main,
    },
    particulierChip: {
      backgroundColor: "#47B0B5",
      color: "white",
      marginRight: 5,
    },
    proChip: {
      backgroundColor: "#53868D",
      color: "white",
      marginRight: 5,
    },
    profil: {
      background: theme.palette.common.light,
      padding: 16,
    },
    sellerChip: {
      backgroundColor: "#004680",
      color: "white",
      marginRight: 5,
    },
    tiede: {
      color: "orange",
    },
    titreSection: {
      borderBottom: "1px solid",
      borderColor: `${theme.palette.common.main} !important`,
      marginBottom: 20,
      paddingBottom: 5,
      paddingTop: 15,
      width: "100%",
    },
  };
});

export default useStyles;
