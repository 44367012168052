import { SortOption } from "UI/shared/molecules/SortInput";
import {
  AdaptedRefinements,
  DateRange,
  Locations,
  MultipleText,
} from "components/RefinementField/types";
import { useCallback } from "react";
import { ProfileKind } from "routes/app/contacts/contact/components/models/profilesReadDto";
import { useServices } from "services";
import { isDefaultValue } from "services/profiles/utils/isDefaultValue";
import { ConsentStatus } from "types";

import { useTxHttp } from "../http/index";
import {
  ContactsCriteria,
  ContactSourceType,
  PortfolioSortColumn,
  ReadContactDto,
  ReadPortfolioDto,
} from "./types";

const PAGE_SIZE = 20;
export interface PortfolioSearchResponse {
  result: ReadPortfolioDto;
  params: PortforlioSearchParams;
}

export interface PortforlioSearchParams {
  sortAsc: boolean;
  sortColumn: PortfolioSortColumn;
  from?: Date;
  to?: Date;
  withArchivedContacts?: boolean;
  searchTerm?: string;
  profileKinds?: ProfileKind[];
  sources?: ContactSourceType[];
  consentStatuses?: ConsentStatus[];
  villes?: string[];
  regions?: string[];
  departements?: string[];
  size: number;
  page: number;
}
export interface PortfolioSearchResponse {
  result: ReadPortfolioDto;
  params: PortforlioSearchParams;
}
export const usePortfolioSearch = () => {
  const { get } = useTxHttp();
  const { endpoints } = useServices();

  const hasUserRefinements = (
    adaptedRefinements: AdaptedRefinements<ContactsCriteria>
  ) => {
    if (
      isDefaultValue(adaptedRefinements.sources.refinement.value) &&
      isDefaultValue(adaptedRefinements.consentStatuses.refinement.value) &&
      isDefaultValue(adaptedRefinements.profileKinds.refinement.value) &&
      !adaptedRefinements.withArchivedContacts
    ) {
      return false;
    }

    return true;
  };

  const getFiltersFromRefinements = (
    adaptedRefinements: AdaptedRefinements<ContactsCriteria>
  ) => {
    return (Object.keys(adaptedRefinements) as ContactsCriteria[]).reduce(
      (acc, k) => {
        if (isDefaultValue(adaptedRefinements[k].refinement.value)) return acc;

        if (adaptedRefinements[k].refinement.kind === "multiple-text") {
          const refinedValues = (adaptedRefinements[k]
            .refinement as MultipleText).value
            .filter((_) => _.isRefined)
            .map((_) => _.value);

          return {
            ...acc,
            [k]: refinedValues,
          };
        }

        if (adaptedRefinements[k].refinement.kind === "date-range") {
          const min = (adaptedRefinements[k].refinement as DateRange).value
            ?.min;
          const max = (adaptedRefinements[k].refinement as DateRange).value
            ?.max;

          return {
            ...acc,
            from: min,
            to: max,
          };
        }

        if (adaptedRefinements[k].refinement.kind === "locations") {
          return {
            ...acc,
            departements:
              (adaptedRefinements[k]
                .refinement as Locations).value.departements.map(
                (e) => e.value
              ) || [],
            quartiers:
              (adaptedRefinements[k]
                .refinement as Locations).value.quartiers.map((e) => e.value) ||
              [],
            regions:
              (adaptedRefinements[k].refinement as Locations).value.regions.map(
                (e) => e.value
              ) || [],
            villes:
              (adaptedRefinements[k].refinement as Locations).value.villes.map(
                (e) => e.value
              ) || [],
          };
        }
        return { ...acc, [k]: adaptedRefinements[k].refinement.value };
      },
      {}
    ) as PortforlioSearchParams;
  };

  const getParams = useCallback(
    (
      adaptedRefinements: AdaptedRefinements<ContactsCriteria>,
      sortOption: SortOption,
      pageNumber: number,
      size: number = PAGE_SIZE
    ) => {
      if (hasUserRefinements(adaptedRefinements)) {
        const filters = getFiltersFromRefinements(adaptedRefinements);
        return {
          ...filters,
          page: pageNumber,
          size: size,
          sortAsc: sortOption.isAscendingOrder,
          sortColumn: sortOption.query,
        } as PortforlioSearchParams;
      }
      return {
        page: pageNumber,
        size: PAGE_SIZE,
        sortAsc: sortOption.isAscendingOrder,
        sortColumn: sortOption.query,
        withArchivedContacts: false,
      } as PortforlioSearchParams;
    },
    []
  );

  const search = useCallback(
    async (
      adaptedRefinements: AdaptedRefinements<ContactsCriteria>,
      sortOption: SortOption,
      pageNumber: number,
      size: number = PAGE_SIZE
    ) => {
      try {
        const filters = getParams(
          adaptedRefinements,
          sortOption,
          pageNumber,
          size
        );
        const result = await get<ReadPortfolioDto>(
          `${endpoints.portfolio.get}`,
          filters
        );

        return {
          params: filters,
          result: result.data,
        } as PortfolioSearchResponse;
      } catch (e) {
        throw new Error("error reading CRM contacts.");
      }
    },
    [endpoints.portfolio.get, get, getParams]
  );

  const getContactIdByEmailAddress = useCallback(
    async (contactId: string, emailAddress: string) => {
      try {
        const response = await get<string>(
          endpoints.portfolio.contacts.byEmailAddress,
          {
            contactId,
            emailAddress,
          }
        );

        return response.data;
      } catch (e: any) {
        if (e?.response?.status === 404) {
          return undefined;
        }
      }
    },
    [endpoints.portfolio, get]
  );

  const getContactIdByPhoneNumber = useCallback(
    async (contactId: string, phoneNumber: string) => {
      try {
        const response = await get<string>(
          endpoints.portfolio.contacts.byPhoneNumber,
          {
            contactId,
            phoneNumber,
          }
        );
        return response.data;
      } catch (e: any) {
        if (e?.response?.status === 404) {
          return undefined;
        }
      }
    },
    [endpoints.portfolio, get]
  );

  const getContactIdByPhoneNumberOrEmailAddress = useCallback(
    async (phoneNumber: string, emailAddress: string) => {
      const tryGetByPhoneNumber = async () => {
        const byPhoneNumberResponse = get<string | undefined>(
          endpoints.portfolio.contacts.byPhoneNumber,
          { contactId: undefined, phoneNumber }
        );

        try {
          return (await byPhoneNumberResponse).data;
        } catch (e) {
          return undefined;
        }
      };

      const tryGetByEmailAddress = async () => {
        const byEmailAddressResponse = get<string | undefined>(
          endpoints.portfolio.contacts.byEmailAddress,
          { contactId: undefined, emailAddress }
        );

        try {
          return (await byEmailAddressResponse).data;
        } catch (e) {
          return undefined;
        }
      };

      return (await tryGetByPhoneNumber()) || (await tryGetByEmailAddress());
    },
    [
      endpoints.portfolio.contacts.byPhoneNumber,
      endpoints.portfolio.contacts.byEmailAddress,
    ]
  );

  const getActiveContactCount = useCallback(() => {
    return get<ReadPortfolioDto>(endpoints.portfolio.get, {
      consentStatuses: [],
      departements: [],
      from: null,
      page: 1,
      profileKinds: [],
      quartiers: [],
      regions: [],
      searchTerm: null,
      size: PAGE_SIZE,
      sortAsc: false,
      sortColumn: "updateDate",
      sources: [],
      to: null,
      villes: [],
      withArchivedContacts: false,
    }).then((response) => {
      return response.data.total;
    });
  }, [get, endpoints.portfolio.get]);

  const getContact = useCallback(
    async (id: string) => {
      try {
        const response = await get<ReadContactDto>(
          endpoints.portfolio.contacts.contact.get(id)
        );

        return response.data;
      } catch (e: any) {
        if (e?.response?.status === 404) {
          return undefined;
        }
      }
    },
    [endpoints.portfolio.contacts.contact, get]
  );

  return {
    getActiveContactCount,
    getContact,
    getContactIdByEmailAddress,
    getContactIdByPhoneNumber,
    getContactIdByPhoneNumberOrEmailAddress,
    search,
  };
};
