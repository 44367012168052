import { getProfileType } from "routes/app/profiles/adapters/utils";
import { Grid, makeStyles, useTheme } from "@material-ui/core";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import routesNames from "router/routesNames";
import { ReadSellerProfileMatchingNumber } from "routes/app/contacts/contact/components/Profiles/MatchingNumber/ReadSellerProfileMatchingNumber";
import { Maturity } from "routes/app/contacts/contact/components/Profiles/Maturity";
import { ReadMatchingNumber } from "routes/app/contacts/contact/components/Profiles/Search/ReadMatchingNumber";
import { getMatchingRequest } from "routes/app/profiles/adapters/matchingAdapter";
import { profileAdapter } from "routes/app/profiles/adapters/profileAdapter";
import { useTranslation } from "services/l10n/useTranslation";
import { MatchingProfileRequestDto } from "services/matching/MatchingProfileRequestDto";
import { ProfileItemDto } from "services/profiles/dto/ReadModelProfileDto";
import { ITheme } from "theme/ts/main";
import { CitiesList } from "UI/features/profiles/organisms/CitiesList";
import { CardPaperItemSmall } from "UI/shared/atoms/containers/CardPaperItem/index.small.tsx";
import { If } from "UI/shared/atoms/directives/If";
import { HorizontalDivider } from "UI/shared/atoms/HorizontalDivider";
import { BoldNumberUnitLabelNormalCaption } from "UI/shared/molecules/BoldNumberUnitLabelNormalCaption";
import { SpacedLabelValue12Px } from "UI/shared/molecules/SpacedLabelValue";
import { MonthIncome } from "UI/features/profiles/molecules/MonthIncome";
import { PriceCaption } from "UI/features/profiles/atoms/PriceCaption";
import { ProfileStatus } from "UI/features/profiles/molecules/ProfileStatus";
import { PropertyCategoryCaption } from "UI/features/profiles/molecules/PropertyCategoryCaption";
import { PropertyTypeCaption } from "UI/features/profiles/molecules/PropertyTypeCaption";
import { SurfaceCaption } from "UI/features/profiles/molecules/SurfaceCaption";
import { InfosChipsListProfile } from "UI/features/profiles/organisms/InfosChipsListProfile";
import { ProfileContact } from "UI/features/profiles/organisms/ProfileContact";
import { ProfileDetailsCaptionSmall } from "UI/features/profiles/organisms/ProfileDetailsCaption/index.small";
import { ProfileSummary } from "UI/features/profiles/organisms/ProfileSummary";

const useStyles = makeStyles(() => ({
  contactContainer: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  profileContainer: {
    padding: 8,
  },
}));

interface ProfileItemSmallCardProps {
  profileItem: ProfileItemDto;
  actionsMenu: React.ReactNode;
}

export const ProfileItemSmallCard: React.FC<ProfileItemSmallCardProps> = ({
  profileItem,
  actionsMenu,
}) => {
  const classes = useStyles();
  const theme: ITheme = useTheme();
  const displayedProfileItem = profileAdapter(profileItem);
  const infosChipsList = (
    <InfosChipsListProfile
      businessType={displayedProfileItem.businessType}
      propertyType={displayedProfileItem.propertyType}
      profileType={displayedProfileItem.profileType}
      propertyCategory={displayedProfileItem.propertyCategory}
    />
  );

  const history = useHistory();
  const { t } = useTranslation();

  const criteriaSale: MatchingProfileRequestDto | undefined = useMemo(
    () => getMatchingRequest(profileItem),
    [profileItem.id]
  );

  return (
    <CardPaperItemSmall
      clickHandler={() =>
        history.push(
          routesNames.profilePageTab(
            profileItem.contactId,
            profileItem.id,
            "details"
          )
        )
      }
    >
      <div className={classes.profileContainer}>
        <Grid container spacing={1}>
          {displayedProfileItem.reference && (
            <Grid item xs={12}>
              <SpacedLabelValue12Px
                label={"Référence"}
                spaceSize={5}
                value={displayedProfileItem.reference}
              />
            </Grid>
          )}

          <Grid item xs={11}>
            <ProfileSummary
              profileDetailsCaption={
                <ProfileDetailsCaptionSmall
                  propertyCategoryCaption={
                    <PropertyCategoryCaption
                      label={displayedProfileItem.propertyCategory}
                    />
                  }
                  propertyTypeCaption={
                    displayedProfileItem.propertyType !== "All" &&
                    displayedProfileItem.propertyType !== undefined && (
                      <PropertyTypeCaption
                        label={t(displayedProfileItem.propertyType)}
                      />
                    )
                  }
                  monthIncome={
                    displayedProfileItem.monthIncome ? (
                      <MonthIncome income={displayedProfileItem.monthIncome} />
                    ) : undefined
                  }
                  price={
                    <PriceCaption price={displayedProfileItem.budgetMax} />
                  }
                  surface={
                    displayedProfileItem.surfaceMin !== undefined &&
                    displayedProfileItem.surfaceMax !== undefined && (
                      <SurfaceCaption
                        surfaceMin={displayedProfileItem.surfaceMin}
                        surfaceMax={displayedProfileItem.surfaceMax}
                      />
                    )
                  }
                  bedroomsNumber={
                    displayedProfileItem.bedroomsNumber ? (
                      <BoldNumberUnitLabelNormalCaption
                        total={displayedProfileItem.bedroomsNumber}
                        unitLabel={
                          displayedProfileItem.profileType === "Search"
                            ? "+\xa0ch"
                            : "ch"
                        }
                      />
                    ) : undefined
                  }
                  roomsNumber={
                    displayedProfileItem.roomsNumber ? (
                      <BoldNumberUnitLabelNormalCaption
                        total={displayedProfileItem.roomsNumber}
                        unitLabel={
                          displayedProfileItem.profileType === "Search"
                            ? "+\xa0p"
                            : "p"
                        }
                      />
                    ) : undefined
                  }
                />
              }
              citiesList={
                <CitiesList
                  cities={displayedProfileItem.locations}
                  visibleItemsCount={1}
                />
              }
              infoChipsList={infosChipsList}
            />
          </Grid>
          <Grid item xs={1}>
            <Maturity value={displayedProfileItem.maturity} />
          </Grid>
          <Grid item xs={12}>
            <HorizontalDivider height={1} color={theme.palette.common.dark} />
            <div className={classes.contactContainer}>
              <ProfileContact
                gender={profileItem.contactGender}
                firstName={profileItem.contactFirstName}
                lastName={profileItem.contactLastName}
                mainPhoneNumber={profileItem.contactMainPhoneNumber}
                mainEmailAddress={profileItem.contactMainEmailAddress}
                withAvatar={false}
              />
            </div>
            <HorizontalDivider height={1} color={theme.palette.common.dark} />
          </Grid>
          <Grid item xs={12}>
            <If
              condition={
                profileItem.archivingDate == null &&
                getProfileType(profileItem.kind) === "Search"
              }
            >
              <ReadMatchingNumber
                profileId={profileItem.id}
                contactId={profileItem.contactId}
              />
            </If>
            <If
              condition={
                profileItem.archivingDate == null &&
                getProfileType(profileItem.kind) === "Seller"
              }
            >
              <ReadSellerProfileMatchingNumber
                criteria={criteriaSale}
                url={routesNames.profilePageTab(
                  profileItem.contactId,
                  profileItem.id,
                  "matching"
                )}
              />
            </If>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <ProfileStatus
                  status={profileItem.archivingDate ? "Archived" : "Active"}
                  date={displayedProfileItem.archivedOrUpdatedDate}
                />
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                  }}
                >
                  {actionsMenu}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </CardPaperItemSmall>
  );
};
