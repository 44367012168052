import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { MatchingProfileRequestDto } from "services/matching/MatchingProfileRequestDto";

import useNotifier from "../../../../../../../../../components/Notifier/useNotifier";
import { useServices } from "../../../../../../../../../services";
import { useProfiles } from "../../../../../../../../../services/profiles";
import { ITheme } from "../../../../../../../../../theme/ts/main";
import { AccountType } from "../../../../models/accountType";
import { ProfileOriginType } from "../../../../models/profileOriginType";
import { ProfileReadDto } from "../../../../models/profilesReadDto";
import { SaleBuildingProfileReadDto } from "../../../../models/Proposal/ForSale/Building/saleBuildingProfileReadDto";
import { SaleBuildingProfileWriteDto } from "../../../../models/Proposal/ForSale/Building/saleBuildingProfileWriteDto";
import { SectionTitle } from "../../../components/SectionTitle";
import { WriteSellerMatchingNumber } from "../../../MatchingNumber/WriteSellerMatchingNumber";
import { SelectMaturity } from "../../../Maturity";
import { NotesInput } from "../../../Notes";
import { Write } from "../../../Write";
import { SaleEquipments } from "../../SaleEquipments";
import { SaleParking } from "../../SaleParking";
import { SalePlaceInput } from "../../SalePlace";
import { BuildingAnnexes } from "./BuildingAnnexes";
import { BuildingPropertyInfoInput } from "./BuildingPropertyInfo";
import { LotsType } from "./LotsType";
import MatchingAdaptater from "./MatchingAdapter";
import { RentedLots } from "./RentedLots";
import { SaleBudgetBuildingInput } from "./SaleBudgetBuilding";
import { SelectBuildingForSaleKind } from "./SelectBuildingForSaleKind";
import classes from "./Write.module.css";

const useStyles = makeStyles((theme: ITheme) => {
  return {
    checkbox: {
      color: theme.palette.primary.main,
    },
  };
});

export const BuildingForSaleProfileWrite: FC<{
  contactId: string;
  account: AccountType;
  initial?: SaleBuildingProfileReadDto;
  more: boolean;
  showMore: () => void;
  sendUpdatedProfile?: (profile: ProfileReadDto | undefined) => void;
  handleAfterUpdate?: () => void;
}> = ({
  contactId,
  account,
  initial,
  more,
  showMore,
  sendUpdatedProfile,
  handleAfterUpdate,
  children,
}) => {
  const styles = useStyles();
  const { add, update } = useProfiles(contactId);
  const [payload, setPayload] = useState<SaleBuildingProfileWriteDto>(
    create(account, initial)
  );
  const [state, setState] = useState<"none" | "saving" | "saved" | "error">(
    "none"
  );
  const {
    modal,
    l10n: { t },
  } = useServices();
  const { showSnack } = useNotifier();
  const mode = initial ? "update" : "add";
  const [pristine, setPristine] = useState(true);
  const [isFormValid, setIsFormValid] = useState({
    address: false,
    financialInfo: false,
    buildingPropertyInfo: false,
  });

  const disabled = state === "saving";
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down("sm"));

  const [matchingCriteria, setMatchingCriteria] = useState<
    MatchingProfileRequestDto | undefined
  >(undefined);
  useEffect(() => {
    setMatchingCriteria(MatchingAdaptater(payload.features));
  }, [payload]);

  useEffect(() => {
    switch (state) {
      case "none":
        break;
      case "saving":
        if (Object.entries(isFormValid).some(([_, b]) => !b)) {
          setPristine(false);
          setState("none");
        } else {
          (mode === "add"
            ? add("SaleBuildingProfile", payload)
            : update(
                "SaleBuildingProfile",
                initial!.id,
                payload,
                sendUpdatedProfile
              )
          )
            .then(() => {
              if (handleAfterUpdate) handleAfterUpdate();
              setState("saved");
            })
            .catch(() => setState("error"));
        }
        break;
      case "saved":
        modal.close();
        showSnack({ message: "Le profil est enregistré.", variant: "success" });
        break;
      case "error":
        showSnack({ message: "Une erreur est survenue.", variant: "danger" });
        break;
    }
  }, [state]);

  return (
    <Write
      pending={state === "saving"}
      save={() => setState("saving")}
      mode={mode}
      more={more}
      showMore={showMore}
      matching={<WriteSellerMatchingNumber criteria={matchingCriteria} />}
    >
      <div className={more && !mediaQuery ? classes.container : ""}>
        <div className={more ? classes.itemA : ""}>
          {children}
          <Grid item xs={12}>
            <SelectBuildingForSaleKind
              onChange={(buildingKind) => {
                setPayload((prev: SaleBuildingProfileWriteDto) => ({
                  ...prev,
                  features: {
                    ...prev.features,
                    buildingKind,
                  },
                }));
              }}
              disabled={false}
              value={payload.features.buildingKind}
            />
          </Grid>
          <Grid item xs={12}>
            <SalePlaceInput
              value={payload.features?.address}
              onChange={(address, isValid) => {
                setIsFormValid((previous) => ({
                  ...previous,
                  address: isValid,
                }));
                setPayload((prev) => ({
                  ...prev,
                  features: {
                    ...prev.features,
                    address,
                  },
                }));
              }}
              disabled={disabled}
              pristine={pristine}
            />
          </Grid>
        </div>

        <div className={more ? classes.itemB : ""}>
          <Grid item xs={12}>
            <SaleBudgetBuildingInput
              pristine={pristine}
              more={more}
              value={payload.features.financialInfo}
              onChange={(financialInfo, isValid) => {
                setIsFormValid((previous) => ({
                  ...previous,
                  financialInfo: isValid,
                }));
                setPayload((prev) => ({
                  ...prev,
                  features: {
                    ...prev.features,
                    financialInfo,
                  },
                }));
              }}
            />
          </Grid>

          <SectionTitle title="Quelles sont les caractéristiques ?" />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BuildingPropertyInfoInput
                pristine={pristine}
                value={payload.features.buildingPropertyInfo}
                onChange={(buildingPropertyInfo, isValid) => {
                  setIsFormValid((previous) => ({
                    ...previous,
                    buildingPropertyInfo: isValid,
                  }));
                  setPayload((prev) => ({
                    ...prev,
                    features: {
                      ...prev.features,
                      buildingPropertyInfo,
                    },
                  }));
                }}
                more={more}
              />
            </Grid>
            {more && (
              <Grid item xs={12}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color={"primary"}
                        classes={{ root: styles.checkbox }}
                        checked={
                          payload.features.buildingPropertyInfo
                            .jointOwnership || false
                        }
                        onChange={() => {
                          setPayload((prev) => ({
                            ...prev,
                            features: {
                              ...prev.features,
                              buildingPropertyInfo: {
                                ...prev.features.buildingPropertyInfo,
                                jointOwnership: !payload.features
                                  .buildingPropertyInfo.jointOwnership,
                              },
                            },
                          }));
                        }}
                        inputProps={{ "aria-label": "checkbox" }}
                      />
                    }
                    label={
                      <Typography variant={"body2"}>
                        {t("forSaleBuilding.propertyInfo.jointOwnership")}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color={"primary"}
                        classes={{ root: styles.checkbox }}
                        checked={
                          payload.features.buildingPropertyInfo
                            .ongoingProcedure || false
                        }
                        onChange={() => {
                          setPayload((prev) => ({
                            ...prev,
                            features: {
                              ...prev.features,
                              buildingPropertyInfo: {
                                ...prev.features.buildingPropertyInfo,
                                ongoingProcedure: !payload.features
                                  .buildingPropertyInfo.ongoingProcedure,
                              },
                            },
                          }));
                        }}
                        inputProps={{ "aria-label": "checkbox" }}
                      />
                    }
                    label={
                      <Typography variant={"body2"}>
                        {t("forSaleBuilding.propertyInfo.ongoingProcedure")}
                      </Typography>
                    }
                  />
                </div>
                <SaleEquipments
                  value={payload.features.amenities || []}
                  onChange={(amenities) =>
                    setPayload((prev) => ({
                      ...prev,
                      features: {
                        ...prev.features,
                        amenities,
                      },
                    }))
                  }
                />
                <LotsType
                  value={payload.features.lots || []}
                  onChange={(lots) =>
                    setPayload((prev) => ({
                      ...prev,
                      features: {
                        ...prev.features,
                        lots,
                      },
                    }))
                  }
                />
              </Grid>
            )}
          </Grid>
        </div>

        <div className={more ? classes.itemC : ""}>
          {more && (
            <>
              <SaleParking
                value={payload.features.parkingInfo}
                onChange={(newValue) =>
                  setPayload((prev) => ({
                    ...prev,
                    features: {
                      ...prev.features,
                      parkingInfo: newValue,
                    },
                  }))
                }
              />
              <BuildingAnnexes
                value={
                  payload.features.buildingAnnexes || {
                    basementsNumber: 0,
                    serviceRoomsNumber: 0,
                  }
                }
                onChange={(newValue) =>
                  setPayload((prev) => ({
                    ...prev,
                    features: {
                      ...prev.features,
                      buildingAnnexes: newValue,
                    },
                  }))
                }
              />
              <RentedLots
                value={payload.features.buildingRentedInfo}
                onChange={(newValue) =>
                  setPayload((prev) => ({
                    ...prev,
                    features: {
                      ...prev.features,
                      buildingRentedInfo: newValue,
                    },
                  }))
                }
                salePrice={payload.features.financialInfo.price || 0}
              />
            </>
          )}
          <Grid item xs={12}>
            <NotesInput
              value={payload.notes}
              onChange={(notes) => setPayload((prev) => ({ ...prev, notes }))}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectMaturity
              onChange={(maturity) => {
                setPayload((prev) => ({
                  ...prev,
                  maturity,
                }));
              }}
              value={payload.maturity}
              disabled={disabled}
            />
          </Grid>
        </div>
      </div>
    </Write>
  );
};

function create(
  account: AccountType,
  initial?: SaleBuildingProfileReadDto
): SaleBuildingProfileWriteDto {
  return {
    account,
    features: initial
      ? initial.features
      : {
          address: {},
          amenities: [],
          buildingAnnexes: { basementsNumber: 0, serviceRoomsNumber: 0 },
          financialInfo: {
            agentFees: undefined,
            price: 0,
          },
          buildingKind: "All",
          buildingPropertyInfo: { squareMeter: 0 },
          buildingRentedInfo: { rented: false },
          lots: [],
          parkingInfo: {
            count: 0,
            hasBox: false,
            hasExteriorParking: false,
            hasInteriorParking: false,
          },
        },
    creationDate: new Date(),
    maturity: initial?.maturity || "None",
    notes: initial?.notes || "",
    origin: initial?.origin
      ? (initial?.origin as ProfileOriginType)
      : "TXProfile",
    reference: initial?.reference || "",
  };
}
