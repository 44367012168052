import { Text16pxBold } from "UI/shared/atoms/typographies/Text16pxBold";
import React from "react";

import { formatPrix } from "utils/formatage";

export interface PriceCaptionProps {
  price: number;
}

export const PriceCaption: React.FC<PriceCaptionProps> = ({ price }) => {
  return <Text16pxBold>{formatPrix(price)}</Text16pxBold>;
};
