import { Fab } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import React from "react";

export interface ClearRefinementsButtonProps {
  handleClear: () => void;
}

export const ClearRefinementsButton: React.FC<ClearRefinementsButtonProps> = ({
  handleClear,
}) => {
  return (
    <Fab
      variant="extended"
      size="small"
      color="primary"
      aria-label="delete"
      style={{
        boxShadow: "none",
        fontSize: "0.61rem",
        height: 24,
        padding: "0px 8px",
      }}
      onClick={handleClear}
    >
      <DeleteIcon fontSize="small" />
      Effacer
    </Fab>
  );
};
