import { LabelledValue } from "UI/shared/molecules/LabelledValue";
import React, { FC } from "react";
import { PropertyAge } from "routes/app/contacts/contact/components/models/propertyAge";
import { useServices } from "services";

export interface BuildAgeProps {
  value: PropertyAge;
}
export const BuildAge: FC<BuildAgeProps> = ({ value }) => {
  const {
    l10n: { t },
  } = useServices();

  return value ? (
    <LabelledValue label={"Complément"} value={t(`buildAge.${value}`)} />
  ) : (
    <></>
  );
};
