import React from "react";

import { CreateMarkersProps } from "../../../../routes/app/mon-reseau/conseillers/components/MapComponents/createAgentsMarkers";
import MarkerBien from "./markerBien";

export const createPropertiesMarkers = ({
  items,
  map,
  handlePointClick,
}: CreateMarkersProps) => {
  if (!map) return [];
  const _zoom = map?.leafletElement?.getZoom();
  return items.map((item: any) => ({
    component: (
      <MarkerBien
        key={item.id}
        item={item}
        handlePointClick={handlePointClick}
        zoom={_zoom ? _zoom : 6}
      />
    ),
    item,
    type: "normal",
  }));
};
