import { BorderedContainer } from "UI/shared/atoms/containers/BorderedContainer";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";
import { Text16pxBold } from "UI/shared/atoms/typographies/Text16pxBold";
import React from "react";
export const SectionTitle: React.FC<{ title: string }> = ({ title }) => {
  return (
    <div style={{ marginBottom: 10 }}>
      <BorderedContainer bottom={1} style="solid" color={"#e2e2e2"}>
        <PaddingContainer top={15} bottom={5}>
          <Text16pxBold>{title.toUpperCase()}</Text16pxBold>
        </PaddingContainer>
      </BorderedContainer>
    </div>
  );
};
