import { createMuiTheme } from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";
import { fade } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import { baseTheme, IThemeOptions } from "./main";

export default createMuiTheme(({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    secondaire: "#707070",
    secondary: {
      dark: blue[700],
      light: blue[300],
      main: blue[500],
      transparent: fade(blue[700], 0.6),
    },
    tertiary: {
      dark: red[300],
      light: red[300],
      main: red[300],
    },
    tonique: "#FF7B1E",
  },
} as unknown) as IThemeOptions);
