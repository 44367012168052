import React from "react";
import { Typography } from "@material-ui/core";

interface InformationLineProps {
  customKey: string;
  value?: string;
}

const InformationLine = ({ customKey, value }: InformationLineProps) => {
  if (value === undefined) return <></>;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="subtitle1">{customKey}</Typography>
      <div
        style={{
          flex: 1,
          borderBottom: "1px dotted",
          height: "12px",
          marginLeft: 10,
          marginRight: 10,
        }}
      />
      <Typography variant="subtitle2">{value}</Typography>
    </div>
  );
};

export default InformationLine;
