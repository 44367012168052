import { Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { breakpoint } from "theme/main";

import useAideContextuelle from "../useAideContextuelle";

export const useStyles = makeStyles(() => ({
  top: {
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    marginBottom: 15,
    marginTop: 15,
    zIndex: 1,
    // lineHeight: 20,
  },
}));

const TitrePage = ({
  categorie,
  page,
  nbResultats = "",
  Composant = () => undefined,
  filePath = "",
  color = "inherit",
}) => {
  const classes = useStyles();
  const { dialogComponent, iconeAide } = useAideContextuelle({
    color,
    filePath,
  });

  const titreChild = (
    <>
      {categorie !== "" && (
        <Typography variant="h4">
          <span style={{ color, fontWeight: "normal" }}>
            {`${categorie} | `}
          </span>
          <span style={{ color, fontWeight: "bold" }}>{`${page}`}</span>
        </Typography>
      )}
      {categorie === "" && (
        <Typography variant="h4" style={{ color }}>
          &nbsp; {page}
        </Typography>
      )}
    </>
  );

  const _titre = Composant() ? (
    <Hidden smDown>{titreChild}</Hidden>
  ) : (
    titreChild
  );

  return (
    <div className={classes.top}>
      <Grid container>
        <Grid item xs={Composant() ? 4 : 12} sm={9}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flex: 1,
            }}
          >
            {_titre}
            <Typography
              variant="body1"
              style={{
                color,
                fontSize: breakpoint().xs ? "10px" : "14px",
              }}
            >
              &nbsp; {nbResultats}
            </Typography>
            {filePath !== "" && iconeAide}
          </div>
        </Grid>
        {Composant() && (
          <Grid item xs={8} sm={3}>
            <Grid container justify="flex-end">
              {Composant()}
            </Grid>
          </Grid>
        )}
      </Grid>
      {dialogComponent}
    </div>
  );
};

export default TitrePage;
