import { Card, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PlaceIcon from "@material-ui/icons/PlaceOutlined";
import CommerceEntreprise from "UI/shared/atoms/Badges/C&E";
import Exclusivite from "UI/shared/atoms/Badges/Exclusivite";
import Neuf from "UI/shared/atoms/Badges/Neuf";
import Prestige from "UI/shared/atoms/Badges/Prestige";
import { SousOffre } from "UI/shared/atoms/Badges/SousOffre";
import { BU } from "components/BU";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { LocationCaption } from "UI/shared/organisms/LocationCaption";
import { formatPrix } from "utils/formatage";
import getUrlPhoto from "utils/UrlImage";

import { SousCompromis } from "../../../UI/shared/atoms/Badges/SousCompromis";
import { useServices } from "../../../services";

const useStyles = makeStyles((theme) => ({
  buttonsIcon: {
    background: theme.palette.primary.main,
    marginRight: "7px",
  },
  buttonsIconContainer: {
    bottom: "-15px",
    position: "absolute",
    right: 0,
  },
  cardContent: {
    marginTop: "10px",
  },
  emphase: {
    color: theme.palette.emphase,
  },
  fab: {
    bottom: "50%",
    left: "calc(100% - 30px)",
  },
  icons: {
    color: "white",
    fontSize: "1.5em",
  },
  imageInformation: {
    visibility: "hidden",
  },
  legend: {
    background: "rgba(0,0,0,0.4)",
    color: "#fff",
    marginTop: "-50px",
    position: "relative",
    textAlign: "center",
    width: "100%",
  },
  nbPhotos: {
    color: "#fff",
    position: "absolute",
    right: 10,
    textAlign: "center",
    top: -140,
    width: "25%",
  },
  root: {
    "&:hover": {
      boxShadow:
        "0px 3px 3px 0px rgba(0,0,0,0.34), 0px 1px 1px -1px rgba(0,0,0,0.1)",
    },
    "&:hover $imageInformation": {
      alignItems: "center",
      background: "#00000053",
      color: "white",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      visibility: "inherit",
    },
    position: "relative",
    width: "190px",
  },
  separator: {
    borderColor: theme.palette.greyLight,
    margin: "auto",
    opacity: "50%",
    width: "90%",
  },
  tagItem: {
    paddingRight: "5px",
  },
  texte: {
    height: "30px",
    marginBottom: "10px",
    marginTop: "10px",
    overflow: "hidden",
  },
  typeBienVille: {
    fontSize: "1rem",
  },
}));

const adapterHitReduit = (item) => ({
  ...item,
  prixNormalized: item.prix,
  villeCodePostal: item.localisation.codePostal,
  villeNom: item.localisation.villeNom,
});

const HitBien = ({ hit, onClick }) => {
  const {
    l10n: { t },
  } = useServices();

  const classes = useStyles({ isClickable: true });
  const bien = adapterHitReduit(hit);
  return (
    <Card className={classes.root}>
      <Grid container spacing={0} direction="row" alignItems="center">
        <Grid item xs={12}>
          <Link to={onClick() || ""} style={{ textDecoration: "none" }}>
            <div
              style={{
                backgroundImage: `url(${getUrlPhoto(
                  bien.bu,
                  "300x300",
                  bien.photoPrincipale
                )}), url("/static/images/no-image-box.png")`,
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                cursor: "pointer",
                height: 100,
                position: "relative",
              }}
              onClick={onClick}
            >
              <Typography className={classes.imageInformation}>
                {t("Voir la fiche détail")}
              </Typography>

              <Grid
                container
                direction="row"
                alignItems="center"
                style={{
                  left: 7,
                  position: "absolute",
                  top: 2,
                  width: 150,
                }}
              >
                {bien.neuf === true && (
                  <Grid item xs={12}>
                    <Neuf className={classes.tagItem} t={t} />
                  </Grid>
                )}

                {bien.prestige === true && (
                  <Grid item xs={12}>
                    <Prestige className={classes.tagItem} t={t} />
                  </Grid>
                )}

                {bien.isCE === true && (
                  <Grid item xs={12}>
                    <CommerceEntreprise className={classes.tagItem} />
                  </Grid>
                )}
              </Grid>
            </div>
          </Link>
        </Grid>

        <Grid item xs={12} style={{ padding: "10px 10px 10px 15px" }}>
          <Grid container direction="column">
            <Grid container item xs={12} direction="column" spacing={0}>
              <Typography variant="body2" style={{ color: "#8E8E8E" }}>
                {bien.typeAffaireEnum
                  .replace(/([A-Z])/g, " $1")
                  .trim()
                  .toUpperCase()}
              </Typography>
              <Typography variant="body2" style={{ color: "#8E8E8E" }}>
                {t(bien.typeBienNom || bien.familleBienEnum)}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <LocationCaption
              city={bien.villeNom}
              zipcode={bien.villeCodePostal}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <div style={{ display: "flex" }}>
                  <Typography variant="h2" align="left" noWrap>
                    {formatPrix(bien.prixNormalized)}
                  </Typography>
                  {hit.underOfferToSell && (
                    <div style={{ marginLeft: 5 }}>
                      <SousCompromis />
                    </div>
                  )}
                  {hit.hasOffer && (
                    <div style={{ marginLeft: 5 }}>
                      <SousOffre />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="flex-end">
              <Grid item xs={12}>
                <Grid container>
                  {bien.surface > 0 && (
                    <>
                      <Typography variant="h2">{bien.surface}</Typography>
                      <Typography variant="h3">
                        &nbsp;{`m\xB2`}&nbsp;
                        {(bien.nbPieces > 0 || bien.nbChambres > 0) && "|"}
                        &nbsp;
                      </Typography>
                    </>
                  )}
                  {bien.nbPieces > 0 && (
                    <>
                      <Typography variant="h2">{bien.nbPieces}</Typography>
                      <Typography variant="h3">
                        &nbsp;p&nbsp;
                        {bien.nbChambres > 0 && "|"}
                        &nbsp;
                      </Typography>
                    </>
                  )}
                  {bien.nbChambres > 0 && (
                    <>
                      <Typography variant="h2">{bien.nbChambres}</Typography>
                      <Typography variant="h3">&nbsp;ch</Typography>
                    </>
                  )}
                  {bien.surface === 0 &&
                    bien.nbPieces === 0 &&
                    bien.nbChambres === 0 && (
                      <>
                        <Typography variant="h2">&nbsp;</Typography>
                        <Typography variant="h3">&nbsp;</Typography>
                      </>
                    )}
                </Grid>
              </Grid>

              <Grid
                item
                xs={6}
                container
                justify="flex-start"
                style={{ paddingTop: 5 }}
              >
                {bien.exclusivite === true ||
                bien.optionMandatEnum === "Exclusif" ? (
                  <Exclusivite t={t} mode={"mini"} />
                ) : (
                  <div style={{ height: 24 }} />
                )}
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <BU bu={bien.bu} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default withRouter(HitBien);
