import ElasticAdapter from "api/DTOs/Common/ElasticSearch/ElasticAdapter";
import { number, string } from "api/DTOs/Common/Types";

function rechercheCarteAdapter(result = {}) {
  const elasticResult = ElasticAdapter.toBO(result);
  elasticResult.data = elasticResult.data.map((item) => itemToBO(item));
  return elasticResult;
}

function itemToBO(result = {}) {
  return {
    id: result.id || string,
    lat: result.lat || number,
    long: result.long || number,
    position:
      result.lat && result.long
        ? { lat: result.lat, lng: result.long }
        : { lat: 0, lng: 0 },
    bu: result.bu || string,
    roles: result.roles || [],
  };
}

export default rechercheCarteAdapter;
