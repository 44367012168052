import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 3,
    position: "relative",
    width: "100%",
    cursor: (props) => (props.isGlobalSearch ? "pointer" : ""),
    // "&::before": {
    //   position: "absolute",
    //   top: 0,
    //   left: 0,
    //   height: "100%",
    //   width: "5px",
    //   content: "''",
    //   backgroundColor: theme.palette.tonique
    // }
  },
}));

const ConteneurCarte = ({
  children,
  onClick = () => {},
  isGlobalSearch = false,
  backgroundColor = "white",
  padding = null,
}) => {
  const classes = useStyles({ isGlobalSearch });

  return (
    <Paper
      elevation={1}
      className={classes.root}
      onClick={onClick}
      style={{
        backgroundColor: backgroundColor,
        padding: padding ? padding : "10px 7px 5px 12px",
      }}
    >
      {children}
    </Paper>
  );
};

export default ConteneurCarte;
