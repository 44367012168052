import ElasticAdapter from "api/DTOs/Common/ElasticSearch/ElasticAdapter";
import { number, string } from "api/DTOs/Common/Types";

function rechercheGridOuListeAdapter(result = {}) {
  const elasticResult = ElasticAdapter.toBO(result);
  elasticResult.data = elasticResult.data.map((item) => itemToBO(item));
  return elasticResult;
}

function itemToBO(result = {}) {
  return {
    id: result.id || number,
    bu: result.bu || string,
    dateAjout: result.dateAjout || string,
    dateModification: result.dateModification || string,
    dateEntreeDansLeReseau: result.dateEntreeDansLeReseau || string,
    moisAnciennete: result.moisAnciennete || 0,
    prenom: result.prenom || string,
    photo: result.photo || string,
    nom: result.nom || string,
    civilite: result.civilite || string,
    telephone: result.telephone || string,
    email: result.adresseEmail || string,
    roles: Array.isArray(result.roles) ? result.roles : [],
    specialites: Array.isArray(result.specialites) ? result.specialites : [],
    ville: result.ville || string,
    codePostal: result.codePostal || string,
    latitude: result.latitude || number,
    longitude: result.longitude || number,
    urlMiniSite: result.miniSiteUrl || string,
    lienFacebook: result.lienFacebook || string,
    position:
      result.latitude && result.longitude
        ? { lat: result.latitude, lng: result.longitude }
        : { lat: 0, lng: 0 },
  };
}

export default rechercheGridOuListeAdapter;
