import { Card, Grid, Typography } from "@material-ui/core";
import PlaceIcon from "@material-ui/icons/PlaceOutlined";
import { BU } from "components/BU";
import TypeAnnonceur from "components/TypeAnnonceur";
import React, { CSSProperties } from "react";
import { typeEtatBien } from "services/piges/types/typeEtatBien";
import { Bloctel } from "types";
import PriceEvolution from "UI/features/pige/atoms/PriceEvolution";
import Neuf from "UI/shared/atoms/Badges/Neuf";
import { LocationCaption } from "UI/shared/organisms/LocationCaption";
import { SurfaceBedroomsNbRoomsNbCaption } from "UI/shared/organisms/SurfaceBedroomsNbRoomsNbCaption";
import { formatPrix } from "utils/formatage";

import { BottomRightBloctelIcon } from "../../Pige/BloctelIcon";
import { HasEventsBookmark } from "../../Pige/HasEventsBookmark/HasEventsBookmark";
import { showBloctel } from "../../utils";
import { useStyles } from "./styles";

export interface CarteBienReduiteProps {
  adId: string;
  bu?: string;
  surface: number;
  nbPieces?: number;
  nbChambres?: number;
  prix: number;
  villeNom: string;
  villeCodePostal?: string;
  typeAffaire: string;
  typeAnnonceur?: string;
  typeBien: string;
  typeEtatBien?: typeEtatBien;
  photo?: string;
  onClick?: ({ id }: { id: string }) => void;
  listeTags?: JSX.Element[];
  actions?: JSX.Element[];
  estActive: boolean;
  galerie: boolean;
  hasEvents: boolean;
  typeDerniereEvolutionPrix?: string;
  bloctel?: Bloctel;
  onGlobalSearchDisplay?: boolean;
}

const photoCSS: (onSearchDisplay: boolean) => CSSProperties = (
  onSearchDisplay = false
) => ({
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  cursor: "auto",
  height: onSearchDisplay ? 100 : 200,
  position: "relative",
});

export const CarteBienReduite = ({
  adId,
  bu,
  surface,
  nbPieces,
  nbChambres,
  prix,
  villeNom,
  villeCodePostal,
  typeAffaire,
  typeAnnonceur,
  typeBien,
  typeEtatBien,
  photo,
  listeTags,
  onClick,
  actions = [],
  estActive,
  galerie,
  hasEvents,
  typeDerniereEvolutionPrix,
  bloctel,
  onGlobalSearchDisplay = false,
}: CarteBienReduiteProps) => {
  const isClickable = onClick !== undefined;
  const classes = useStyles({ isClickable });
  const mainPhoto =
    estActive && photo !== undefined
      ? photo
      : "/static/images/no-image-box.png";

  const selectAdIdHandler = () => {
    onClick?.({ id: adId });
  };

  return (
    <Card
      onClick={() => {
        onClick?.({ id: adId });
      }}
      className={galerie && !estActive ? classes.rootDisabled : classes.root}
    >
      <Grid container spacing={0} direction="row" alignItems="center">
        <Grid item xs={12}>
          <div
            style={{
              backgroundImage: `url(${mainPhoto}), url("/static/images/no-image-box.png")`,
              ...photoCSS(onGlobalSearchDisplay),
              cursor: isClickable ? "pointer" : "auto",
            }}
          >
            {isClickable && (
              <a className={classes.photoClickDiv} onClick={selectAdIdHandler}>
                <Typography className={classes.imageInformation}>
                  {"Voir la fiche détail"}
                </Typography>
              </a>
            )}
            {hasEvents && <HasEventsBookmark offsetRight={50} />}
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                left: 7,
                position: "absolute",
                top: 2,
                width: 150,
              }}
            >
              <Grid item xs={12}>
                {typeEtatBien === "Neuf" && <Neuf />}
              </Grid>
              {typeAnnonceur && (
                <TypeAnnonceur
                  type={typeAnnonceur === "Pro" ? "PRO" : "PART"}
                />
              )}
            </Grid>

            {estActive && (
              <div className={classes.buttonsIconContainer}>
                {actions?.map((action) => action)}
              </div>
            )}
          </div>
        </Grid>

        <Grid item xs={12} style={{ padding: "10px 10px 10px 15px" }}>
          <Grid container direction="column">
            <Grid container item xs={12} direction="row" spacing={0}>
              <Typography variant="body2" style={{ color: "#8E8E8E" }}>
                {`${typeAffaire.toUpperCase()} ${typeBien}`}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <LocationCaption city={villeNom} zipcode={villeCodePostal} />
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h2" align="left" noWrap>
                  <PriceEvolution evolutionType={typeDerniereEvolutionPrix} />{" "}
                  {formatPrix(prix)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="flex-end">
              <Grid item xs={12}>
                <SurfaceBedroomsNbRoomsNbCaption
                  livingArea={surface}
                  roomsNumber={nbPieces}
                  bedRoomsNumber={nbChambres}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "flex-start",
                      marginTop: 5,
                    }}
                  >
                    {listeTags && listeTags.length > 0 ? (
                      listeTags.map((tag: JSX.Element, index: number) => (
                        <div key={index} className={classes.tagItem}>
                          {tag}
                        </div>
                      ))
                    ) : (
                      <div style={{ height: 24 }} />
                    )}
                    {bu && <BU bu={bu} />}
                    {showBloctel(bloctel) && (
                      <BottomRightBloctelIcon right={10} bottom={10} />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CarteBienReduite;
