import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

import { ITheme } from "../../../../theme/ts/main";

export interface OrangeTitleProps {
  label: string;
}

const useStyles = makeStyles((theme: ITheme) => ({
  titleColor: {
    color: theme.palette.orangeColorTitle,
    fontSize: "9px",
  },
}));

export const OrangeTitle: React.FC<OrangeTitleProps> = ({ label }) => {
  const classes = useStyles();
  return (
    <Typography variant={"h6"} className={classes.titleColor}>
      {label}
    </Typography>
  );
};
