import { Avatar, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";

import { useServices } from "../../../../../services";

const PotentialBuyersInfo = ({ numberOfBuyers, onClick }) => {
  const {
    l10n: { t },
  } = useServices();

  return (
    <Paper
      onClick={onClick}
      elevation={1}
      style={{ cursor: "pointer", padding: 10 }}
    >
      <Grid container alignItems="center">
        <Avatar
          aria-label="nombre acquéreurs"
          style={{ background: "#718496" }}
        >
          <Typography variant="h1">{numberOfBuyers}</Typography>
        </Avatar>
        <Typography variant="body1" style={{ marginLeft: 20 }}>
          {t("Acquéreur(s) potentiel(s)")}
        </Typography>
      </Grid>
    </Paper>
  );
};

export default PotentialBuyersInfo;
