import { Grid, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  DataProvider,
  DataProviderContext,
  REFINEMENT_TYPE,
} from "components/DataProvider";
import Loading from "components/Loading/LoadingSkeleton";
import TitrePage from "components/TitrePage";
import ReactEcharts from "echarts-for-react";
import React, { useContext, useState } from "react";
import { ReferentielContext } from "services/reporting/Referentiel";
import {
  getOptionBarParAnneeMandats,
  getOptionParMoisMandats,
  getOptionRadarMandats,
} from "theme/EChartsStyles";
import PaperX from "components/PaperX";
import { useServices } from "services";
import { ErrorPanel } from "UI/shared/templates/Panels/ErrorPanel";
import DateRangeSubtitle from "../components/DateRangeSubtitle";
import Kpi from "./components/Kpi";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Mandats = () => {
  const { endpoints } = useServices();

  const referentiel = useContext(ReferentielContext);

  let initialRefinements = false;

  if (Object.keys(referentiel).length > 0) {
    initialRefinements = {
      ...referentiel,
      dateEvt: {
        count: 0,
        labelLimiteInf: "dateDebut",
        labelLimiteSup: "dateFin",
        limiteInf: new Date(new Date().getFullYear(), 0, 1),
        limiteSup: new Date(),
        query: "dateAffaire",
        type: REFINEMENT_TYPE.range.date,
      },
    };
  } else
    return (
      <ErrorPanel
        title={"Données non disponibles."}
        text={"Nous n'avons pas reçu les données statistiques."}
      />
    );

  return (
    <>
      {initialRefinements && (
        <DataProvider
          refinements={JSON.stringify(initialRefinements)}
          url={endpoints.reporting.mandat}
        >
          <MandatsComp />
        </DataProvider>
      )}
    </>
  );
};

export default Mandats;

const chartsStyleRadar = { height: "500px", width: "100%" };
const chartsStyle = { height: "500px", width: "100%" };

const MandatsComp = (props) => {
  const classes = useStyles();
  const { providerData, components } = useContext(DataProviderContext);
  const {
    l10n: { t },
  } = useServices();
  const [mensuel, setMensuel] = useState(false);
  const theme = useTheme();

  const restore = (params) => {
    setMensuel(false);
  };

  const barSelected = (params) => {
    if (mensuel) {
      setMensuel(false);
    } else {
      setMensuel(parseInt(params.name));
    }
  };

  if (providerData && providerData.error)
    return (
      <ErrorPanel
        title={"Données non disponibles."}
        text={"Nous n'avons pas reçu les données statistiques."}
      />
    );

  return (
    <div className={classes.root}>
      <TitrePage
        categorie="Reporting"
        page={t("Mandats")}
        filePath={"/static/markdowns/Reporting/reportingMandats.md"}
        Composant={() =>
          components.dateRange({
            subtitle: (date) => <DateRangeSubtitle date={date} />,
          })
        }
      />
      <div>{components.searchBar()}</div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          {providerData && !providerData.error ? (
            <Kpi kpi={providerData.kpi} t={t} />
          ) : (
            <Loading styles={{ height: 500 }} />
          )}
        </Grid>
        <Grid item xs={12} lg={8}>
          {providerData && !providerData.error ? (
            <PaperX>
              <Typography variant="h5">Performance</Typography>
              <ReactEcharts
                option={getOptionRadarMandats(t, theme, {
                  data: providerData.radar,
                  name: "Mandats",
                })}
                style={chartsStyleRadar}
              />
            </PaperX>
          ) : (
            <Loading styles={{ height: 500 }} />
          )}
        </Grid>
        <Grid item xs={12}>
          {providerData && !providerData.error ? (
            <PaperX>
              <Typography variant="h5">{t("MandatsPris")}</Typography>
              <ReactEcharts
                option={
                  mensuel
                    ? getOptionParMoisMandats(
                        t,
                        mensuel,
                        theme,
                        providerData.nombreParAnneeEtMois
                      )
                    : getOptionBarParAnneeMandats(
                        t,
                        theme,
                        providerData.nombreParAnneeEtMois
                      )
                }
                style={chartsStyle}
                onEvents={{
                  click: barSelected,
                  restore: restore,
                }}
              />
            </PaperX>
          ) : (
            <Loading styles={{ height: 500 }} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
