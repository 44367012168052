import { useRef, useState, useCallback, useEffect } from "react";
import { deepEquality } from "utils/objects/deepEquality";

export const useDeepEffect = <T>(state: T) => {
  const ref = useRef<T>();
  const [current, setCurrent] = useState<T | undefined>();

  const update = useCallback(() => {
    ref.current = state;
    setCurrent(state);
  }, [state]);

  useEffect(() => {
    if (deepEquality(ref.current, state)) return;
    update();
  }, [state]);

  return current;
};
