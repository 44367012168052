import React from "react";

import { ToggleHitsPaneButton } from "UI/shared/atoms/buttons/ToggleHitsPaneButton";

export const getToggleHitsPaneButton = (
  hideHitPane: boolean,
  toggleHitPane: () => void,
  mobile: boolean,
  classes: any
): React.ReactNode =>
  !mobile ? (
    <div className={classes.toggleSidePaneButton}>
      <ToggleHitsPaneButton
        toggleHandler={toggleHitPane}
        toggled={hideHitPane}
      />
    </div>
  ) : (
    <></>
  );
