import {
  AdaptedRefinement,
  AdaptedRefinements,
  MultipleText,
  MultipleValue,
} from "components/RefinementField/types";

const emptyMultipleText: () => MultipleText = () => ({
  kind: "multiple-text",
  value: [],
});

const getMultipleText = (adaptedRefinement: AdaptedRefinement) =>
  <MultipleText>adaptedRefinement.refinement || emptyMultipleText();

const toUnrefined = (v: MultipleValue<string>) => ({ ...v, isRefined: false });

export const isMultipleText = <T extends string>(
  adaptedRefinements: AdaptedRefinements<T>,
  key: keyof AdaptedRefinements<T>
) => adaptedRefinements[key].refinement.kind === "multiple-text";

export const resetMultipleText = <T extends string>(
  adaptedRefinements: AdaptedRefinements<T>,
  nextAdaptedRefinements: Partial<AdaptedRefinements<T>>,
  criteria: keyof AdaptedRefinements<T>
) => {
  const adaptedRefinement = adaptedRefinements[criteria];
  const refinement = getMultipleText(adaptedRefinement);

  return {
    ...nextAdaptedRefinements,
    [criteria]: {
      ...adaptedRefinement,
      refinement: {
        ...refinement,
        value: refinement.value.map(toUnrefined),
      },
    },
  };
};

export const byMultipleText = <T extends string>(
  adaptedRefinements: AdaptedRefinements<T>
) => (criteria: keyof AdaptedRefinements<T>) =>
  adaptedRefinements[criteria].refinement.kind === "multiple-text";
