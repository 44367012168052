const profilePageTabUrl = (
  contactId: string,
  profileId: string,
  tab: "details" | "matching"
): string =>
  `/app/profils/contact/${contactId}/profil/${profileId}/${tab}/view`;

export default {
  app: "/app",
  bienEnVente: "/bien-en-vente",
  bienVendu: "/bien-vendu",
  biens: "/biens",
  biensEnVente: "/biens-disponibles",
  availableProperties: "/biens-disponibles-new",
  biensVendus: "/biens-vendus",
  classement: "/classements",
  conseiller: "/conseiller",
  conseillers: "/conseillers",
  contact: "/contacts/contact",
  contactV1: "/contacts-old/contact",
  contacts: "/contacts",
  contactsV1: "/contacts-old",
  dashboard: "/dashboard",
  demandesRenseignements: "/demandes-renseignements",
  listeBiensPige: "/app/biens/pige",
  mesBiensEnVente: "/mes-biens",
  monBienEnVente: "/mon-bien-en-vente",
  monProfil: "/monProfil",
  monReseau: "/mon-reseau",
  notifications: "/notifications",
  pigeDetail: (id: string, redirectUrl?: string): string => {
    const redirectPart = redirectUrl ? `&redirectUrl=${redirectUrl}` : "";
    return `/app/biens/pige?id=${id}${redirectPart}`;
  },
  profilePageTab: profilePageTabUrl,
  profilePageTabFunc: (contactId: string, profileId: string) => (
    tab: "details" | "matching"
  ): string => profilePageTabUrl(contactId, profileId, tab),
  profilesPage: "/profils",
  profilesPageDetails: "/profil",
  rechercheGlobale: "/recherche-globale",
  reporting: "/reporting",
  synthese: "/synthese",
};
