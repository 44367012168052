export const firstLetter = (value?: string) => (value || "").substring(0, 1);

export const uppercasedFirstLetter = (value?: string) =>
  firstLetter(value).toUpperCase();

export const toPascalCase = (value: string) => {
  if (value.length <= 0) return value;

  const initialPart = value.substring(0, 1);
  const remainingPart = value.substring(1);

  return `${initialPart}${remainingPart}`;
};
