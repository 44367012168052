import { SortOption } from "UI/shared/molecules/SortInput";
import { AdaptedRefinements } from "components/RefinementField/types";
import { useCallback, useEffect, useRef, useState } from "react";
import { RequestForInformationsCriteria } from "types";
import { deepEquality } from "utils/objects/deepEquality";

import { useFeatureFlags } from "../featureFlags";
import { useTxHttp } from "../http";
import { useServices } from "../index";
import { Status } from "../user";
import { PagedResultDto } from "./dto/PagedResultDto";
import { RequestForInformationAggregationsDto } from "./dto/RequestForInformationAggregationsDto";
import { RequestForInformationDto } from "./dto/RequestForInformationDto";
import { RequestForInformationsFiltersDto } from "./dto/RequestForInformationsFiltersDto";
import { TransformRequestForInformationDto } from "./dto/TransformRequestForInformationDto";
import { getFiltersFromRefinements } from "./utils/getFiltersFromRefinements";
import { toParams } from "./utils/toParams";

interface RequestForInformationsParamsDto {
  pageNumber: number;
  size: number;
  sortAsc?: boolean;
  sortColumn?: string;
  filters?: RequestForInformationsFiltersDto | null | undefined;
}

const useRequestForInformations = (): any => {
  const { get, put, post } = useTxHttp();
  const { endpoints } = useServices();

  const { DR } = useFeatureFlags();

  const [activeRequestsCount, setActiveRequestsCount] = useState(0);
  const [status, setStatus] = useState<Status>("pending");
  const [requestForInformations, setRequestForInformations] = useState<
    PagedResultDto<RequestForInformationDto>
  >({
    aggregations: {} as RequestForInformationAggregationsDto,
    count: 0,
    currentPageNumber: -1,
    data: [],
    hasMore: false,
    pageSize: 10,
    total: 0,
    totalPage: 1,
  });

  const [params, setParams] = useState<RequestForInformationsParamsDto>({
    pageNumber: 1,
    size: 20,
  });

  const loadMore = useCallback(() => {
    if (!requestForInformations.hasMore) return;
    setParams((prev) => ({
      ...prev,
      pageNumber: prev.pageNumber + 1,
    }));
  }, [params, requestForInformations]);

  const paramsRef = useRef(params);
  const pagesRef = useRef<Record<string | number, RequestForInformationDto[]>>(
    {}
  );

  const getRequestForInformations = useCallback(
    (specificPage?: number) => {
      setStatus("pending");
      const filterParams = toParams(paramsRef.current.filters);
      const requestParams = {
        pageNumber: paramsRef.current.pageNumber,
        size: paramsRef.current.size,
        sortAsc: paramsRef.current.sortAsc,
        sortColumn: paramsRef.current.sortColumn,
        ...filterParams,
        ...(!!specificPage && { pageNumber: specificPage }),
      };

      get<PagedResultDto<RequestForInformationDto>>(
        endpoints.requestForInformations.agentRequestForInformations,
        requestParams
      )
        .then(({ data: pagedResultDto }) => {
          const currentPageData = pagedResultDto.data.map((d) => ({
            ...d,
            page: pagedResultDto.currentPageNumber,
          }));

          const nextPages = {
            ...(paramsRef.current.pageNumber !== 1 && pagesRef.current),
            [pagedResultDto.currentPageNumber]: currentPageData,
          };

          const nextRequestForInformations = (
            prev: PagedResultDto<RequestForInformationDto>
          ) => ({
            ...prev,
            aggregations: pagedResultDto.aggregations,
            count: pagedResultDto.count,
            currentPageNumber: pagedResultDto.currentPageNumber,
            data: Object.values(nextPages).flat(),
            hasMore: pagedResultDto.hasMore,
            total: pagedResultDto.total,
            totalPage: pagedResultDto.totalPage,
          });

          setStatus("resolved");
          setRequestForInformations(nextRequestForInformations);
          pagesRef.current = nextPages;
        })
        .catch((e) => {
          setStatus("rejected");
        });
    },
    [
      get,
      paramsRef.current,
      pagesRef.current,
      requestForInformations,
      endpoints.requestForInformations.agentRequestForInformations,
    ]
  );

  const archiveRequestForInformation = useCallback(
    (id: string, page: number) => {
      put<{ result: any; success: boolean }>(
        endpoints.requestForInformations.archiveRequestForInformation(id)
      )
        .then((response) => {
          if (response.status === 200) {
            getRequestForInformations(page);
            getActiveRequestForInformationsCount();
          }
        })
        .catch((e) => {
          return;
        });
    },
    [
      put,
      paramsRef.current,
      endpoints.requestForInformations.archiveRequestForInformation,
    ]
  );

  const transformRequestForInformation = useCallback(
    (id: string, page: number, payload: TransformRequestForInformationDto) => {
      return new Promise((resolve, reject) => {
        post<boolean>(
          endpoints.requestForInformations.transformRequestForInformations(id),
          { content: payload, contentType: "application/json" }
        ).then(
          (response) => {
            if (response.status === 200) {
              getRequestForInformations(page);
              getActiveRequestForInformationsCount();
            }
            resolve(id);
          },
          (error) => {
            reject();
          }
        );
      });
    },
    [post, endpoints.requestForInformations]
  );

  const activateRequestForInformation = useCallback(
    (id: string, page: number) => {
      put<{ result: any; success: boolean }>(
        endpoints.requestForInformations.activateRequestForInformation(id)
      )
        .then((response) => {
          if (response.status === 200) {
            getRequestForInformations(page);
            getActiveRequestForInformationsCount();
          }
        })
        .catch((e) => {
          return;
        });
    },
    [
      put,
      paramsRef.current,
      endpoints.requestForInformations.activateRequestForInformation,
    ]
  );

  const getActiveRequestForInformationsCount = useCallback(() => {
    get<number>(
      endpoints.requestForInformations.agentActiveRequestForInformationsCount
    )
      .then(({ data: number }) => {
        setActiveRequestsCount(number);
      })
      .catch((e) => {
        return;
      });
  }, [
    get,
    paramsRef.current,
    endpoints.requestForInformations.agentActiveRequestForInformationsCount,
  ]);

  const updateParamsWithFilters = useCallback(
    (
      adaptedRefinements: AdaptedRefinements<RequestForInformationsCriteria>
    ) => {
      const filters = getFiltersFromRefinements(adaptedRefinements);
      const isFiltersEmpty = Object.keys(filters).length === 0;

      if (
        (!params.filters || Object.keys(params.filters).length === 0) &&
        isFiltersEmpty
      ) {
        return;
      }

      if (deepEquality(filters!, params.filters)) return;

      setParams((prev) => ({
        ...prev,
        filters,
        pageNumber: 1,
      }));
    },
    [paramsRef.current]
  );

  const updateParamsWithActiveSortOption = useCallback(
    (sortOption: SortOption) => {
      setParams({
        ...paramsRef.current,
        pageNumber: 1,
        sortAsc: sortOption.isAscendingOrder,
        sortColumn: sortOption.query,
      });
    },
    [paramsRef.current]
  );

  useEffect(() => {
    if (!DR) return;
    if (deepEquality(paramsRef.current, params)) return;
    paramsRef.current = params;

    getRequestForInformations();
  }, [params, DR]);

  useEffect(() => {
    if (!DR) return;
    getActiveRequestForInformationsCount();
    const interval = setInterval(
      () => getActiveRequestForInformationsCount(),
      10 * 60 * 1000
    );
    return () => clearInterval(interval);
  }, [DR, getActiveRequestForInformationsCount]);

  return {
    activateRequestForInformation,
    activeRequestsCount,
    archiveRequestForInformation,
    loadMore,
    requestForInformations,
    status,
    transformRequestForInformation,
    updateParamsWithActiveSortOption,
    updateParamsWithFilters,
  };
};

export default useRequestForInformations;
