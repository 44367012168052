import Snackbar from "@material-ui/core/Snackbar";
import React from "react";
import { NotifierSnackbarContent } from "./NotifierSnackbarContent";
import Slide from "@material-ui/core/Slide";
import useNotifier from "../useNotifier";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const SnackWrapper = React.forwardRef((props, ref) => {
  const { hideSnack } = useNotifier();
  return (
    <Snackbar
      {...props}
      ref={ref}
      TransitionComponent={SlideTransition}
      onClose={hideSnack}
    >
      <NotifierSnackbarContent {...props} />
    </Snackbar>
  );
});

const snackref = React.createRef();

export const NotifierSnackbar = (props) => (
  <SnackWrapper {...props} ref={snackref} />
);

NotifierSnackbar.propTypes = { ...Snackbar.propTypes };
