import { makeStyles } from "@material-ui/core";

import { ITheme } from "../../theme/ts/main";

export const useStyles = makeStyles((theme: ITheme) => ({
  closeFiche: {
    alignItems: "center",
    bottom: 15,
    cursor: "pointer",
    display: "flex",
    left: 100,
    position: "fixed",
    zIndex: 2,
  },
  indicator: {
    backgroundColor: theme.palette.tonique,
  },
  retourListing: {
    color: theme.palette.tonique,
    cursor: "pointer",
    fontSize: "16px",
    height: "25px",
    marginRight: "10px",
  },
  root: { background: "#f2f2f2", height: "100%" },
  tabRoot: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  tabsRoot: {
    backgroundColor: theme.palette.common.white,
  },
  titre: {
    alignItems: "center",
    display: "flex",
    marginBottom: 10,
  },
}));
