import testEvenements from "mockups/office365-evenements/office365-evenements.json";
import useHTTPService from "services/dataSources/communicationServices/useHTTPService";
import useTestService from "services/dataSources/testServices/useTestService";

import { useServices } from "../index";
import calendrierObtenirLesEvenementsAdapter from "./dto/Calendrier/ObtenirLesEvenements/CalendrierObtenirLesEvenementsAdapter";

export const useEvenementsAPI = ({ params, erreur = false }) => {
  const { endpoints } = useServices();
  const { status, adaptedData } = useHTTPService({
    adapter: calendrierObtenirLesEvenementsAdapter,
    endpoint: endpoints.office365.obtenirLesEvenements,
    params,
  });
  return { items: adaptedData, status: status };
};

export const useEvenementsTest = ({ params, erreur = false }) => {
  const { status, adaptedData } = useTestService({
    adapter: calendrierObtenirLesEvenementsAdapter,
    erreur,

    mockup: testEvenements,
    // TODO : sélection suivant params ?
    params,
  });
  return { items: adaptedData, status: status };
};

export const useEvenements = window.testTX
  ? useEvenementsTest
  : useEvenementsAPI;
