import axios, { Canceler, CancelToken } from "axios";
import { serialize } from "layoutContents/components/HitsContainer/utils";
import { omit, range } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useServices } from "services";
import { useTxHttp } from "services/http";
import { deepEquality } from "utils/objects/deepEquality";

import { useGeoloc } from "../../../../../../../services/geoloc/useGeoloc";
import { AccountType } from "../../models/accountType";
import { ConstructionYears } from "../../models/constructionYears";
import { BusinessType } from "../AccountChip";
import { MatchingCriteria } from "./MatchingCriteria";

export const usePropertiesMatching = (
  criteria: MatchingCriteria | undefined
): {
  loading: boolean;
  loadProperties: () => Promise<Canceler | undefined>;
  nombreDePiges: number;
  propertiesCount: number;
  slug: unknown;
} => {
  const { endpoints } = useServices();
  const { get } = useTxHttp();
  const nombreDePiges = 0;
  const [propertiesCount, setPropertiesCount] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [slug, setSlug] = useState({});
  const { kind } = useGeoloc();

  const fetchProperties = React.useCallback(
    async function fetchProperties(
      params: Omit<
        MatchingCriteria,
        | "familleBiens"
        | "nbPiecesRange"
        | "propertyAge"
        | "places"
        | "business"
        | "account"
        | "purchaseConstructionYears"
      > & {
        typeAffaire: string[];
        familleBiens: string[];
        nbPiecesRange: string[];
        nbChambresRange: string[];
        typeAchat?: string[];
        constructionYear?: string[];
        departements?: string[];
        quartiers?: string[];
        regions?: string[];
        villes?: string[];
        page: number;
        size: number;
      },
      cancelToken: CancelToken
    ) {
      const _ = await get<{ total: number }>(
        endpoints.bienEnVente.rechercheGridOuListe,
        params,
        cancelToken
      );
      return _.data;
    },
    [endpoints.bienEnVente.rechercheGridOuListe, get]
  );

  const loadProperties = React.useCallback(async () => {
    if (criteria === undefined) return;
    setLoading(true);
    const source = axios.CancelToken.source();
    // TODO debounce this
    const kinds = await kind(
      (criteria?.places || []).map((_) => _.id),
      source.token
    );
    const params = (function () {
      const result = omit(criteria, [
        "familleBiens",
        "nbChambresRange",
        "propertyAge",
        "places",
        "business",
        "account",
        "purchasePropertyAge",
        "purchaseConstructionYears",
      ]);
      result.prixMin = result.prixMin && Math.round(result.prixMin);
      result.prixMax = result.prixMax && Math.round(result.prixMax);
      return result;
    })();

    const familleBiens = criteria.familleBiens ? [criteria.familleBiens] : [];

    const nbPiecesRange = criteria.minRooms
      ? range(criteria.minRooms, 7).map((_) => `${_}`)
      : [];

    const nbChambresRange = criteria.minBedrooms
      ? range(criteria.minBedrooms, 7).map((_) => `${_}`)
      : [];

    const typeAffaire = getTypeAffaire(criteria.account, criteria.business);

    const constructionYear = getConstructionYear(
      criteria.purchaseConstructionYears
    );

    const places = {
      departements: kinds.departements.map(({ value }) => value),
      quartiers: kinds.quartiers.map(({ value }) => value),
      regions: kinds.regions.map(({ value }) => value),
      villes: kinds.villes.map(({ value }) => value),
    };

    const typeAchat =
      criteria.purchasePropertyAge === "All"
        ? []
        : criteria.purchasePropertyAge === "New"
        ? ["Neuf"]
        : criteria.purchasePropertyAge === "Old"
        ? ["Ancien"]
        : undefined;

    try {
      const refinements = adapter({
        ...params,
        ...kinds,
        constructionYear,
        familleBiens,
        nbChambresRange,
        nbPiecesRange,
        typeAchat,
        typeAffaire,
      });

      setSlug(serialize({ refinements }));

      const response = await fetchProperties(
        {
          ...params,
          ...places,
          constructionYear: constructionYear.map((_) => _.value),
          familleBiens,
          nbChambresRange,
          nbPiecesRange,
          page: 1,
          size: 1,
          typeAchat,
          typeAffaire,
        },
        source.token
      );

      setPropertiesCount(response.total);

      return source.cancel;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [criteria, fetchProperties, kind]);

  const criteriaRef = useRef<MatchingCriteria | undefined>();
  useEffect(() => {
    if (deepEquality(criteriaRef.current, criteria)) return;

    criteriaRef.current = criteria;

    loadProperties();
  }, [criteria, loadProperties]);

  return {
    loadProperties,
    loading,
    nombreDePiges,
    propertiesCount,
    slug,
  };
};

const adapter = (params: any) => {
  const adaptedRefinements: any = [
    { query: "sortColumn", value: "DateModification" },
  ];
  let param;
  for (param in params) {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(param) && params[param]) {
      switch (param) {
        case "withElevator":
        case "withParking":
        case "prixMin":
        case "prixMax":
        case "surfaceHabitableMin":
        case "surfaceHabitableMax":
        case "surfaceTerrainMin":
        case "surfaceTerrainMax":
          adaptedRefinements.push({
            query: param,
            value: params[param],
          });
          break;
        case "regions":
        case "departements":
        case "villes":
        case "quartiers":
          adaptedRefinements.push({ query: param, values: params[param] });
          break;
        case "constructionYear":
          adaptedRefinements.push({
            query: param,
            values: params[param],
          });
          break;
        case "familleBiens":
        case "nbPiecesRange":
        case "typeAchat":
        case "typeAffaire":
          adaptedRefinements.push({
            query: param,
            values: params[param].map((item: any) => ({
              label: item,
              value: item,
            })),
          });
          break;
        default:
      }
    }
  }
  return adaptedRefinements;
};

function getTypeAffaire(
  account: AccountType,
  business: BusinessType | ""
): string[] {
  if (business === "SearchLifeAnnuity" || business === "LifeAnnuity")
    return ["VenteEnViager"];
  if (account === "Professional" && business === "Sale")
    return ["CessionDroitAuBail"];
  if (business === "Rent" || business === "Rental") return ["MiseEnLocation"];
  if (business === "Purchase" || business === "Sale") return ["Vente"];
  return [];
}

function getConstructionYear(
  value?: ConstructionYears
): { label: string; value: string }[] {
  return (value || []).map((constructionYear) =>
    constructionYear === "Before2000"
      ? { label: "avant 2000", value: "[0@2000]" }
      : constructionYear === "Between2000and2010"
      ? { label: "2000-2010", value: "[2000@2010]" }
      : { label: "après 2010", value: "[2010+]" }
  );
}
