import { Grid } from "@material-ui/core";
import React, { FC } from "react";
import { ProfileReadDto } from "routes/app/contacts/contact/components/models/profilesReadDto";
import { SearchLifeAnnuityLandCriteriaDto } from "routes/app/contacts/contact/components/models/Search/searchLifeAnnuityLandCriteriaDto";
import { SearchLifeAnnuityLandProfileReadDto } from "routes/app/contacts/contact/components/models/Search/searchLifeAnnuityLandProfileReadDto";
import { ProfilePlaces } from "UI/features/profiles/organisms/ProfilePlaces";
import { ProfileProject } from "UI/features/profiles/organisms/ProfileProject";
import { ProfileStatusCard } from "UI/features/profiles/organisms/ProfileStatusCard";
import { useServices } from "services";
import { L10nService } from "services/l10n";

import { LifeAnnuityBudget } from "../../../../../organisms/Search/LifeAnnuityBudget";
import { MoreInfos } from "../../../../../organisms/Search/MoreInfos";

export interface SearchLifeAnnuityLandProfileDetailsProps {
  readProfile: ProfileReadDto;
}
export const SearchLifeAnnuityLandProfileDetails: FC<SearchLifeAnnuityLandProfileDetailsProps> = ({
  readProfile,
}) => {
  const { l10n } = useServices();
  const profile = readProfile as SearchLifeAnnuityLandProfileReadDto;
  const moreInformations = more(l10n, profile.criteria);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={4}>
        <ProfileStatusCard
          status={profile.archivingDate ? "Archived" : "Active"}
          creationDate={profile.creationDate || profile.lastModificationDate}
          updateDate={profile.archivingDate || profile.lastModificationDate}
          maturity={profile.criteria.maturity}
          origin={profile.origin}
          reference={profile.reference}
        />
        <ProfileProject
          profileAccount={profile.account}
          businessType={"Viager"}
          propertyCategory={"Land"}
        />
        <LifeAnnuityBudget budget={profile.criteria.budget} />
      </Grid>
      <Grid item xs={12} lg={8}>
        {profile.criteria.places && (
          <ProfilePlaces places={profile.criteria.places} />
        )}
        <MoreInfos
          title={"Informations supplémentaires"}
          value={moreInformations}
        />
      </Grid>
    </Grid>
  );
};

function more(
  { t, formatSurfacesRange }: L10nService,
  criteria: SearchLifeAnnuityLandCriteriaDto
) {
  return [
    {
      label: t("Surface terrain"),
      value: formatSurfacesRange(criteria.lotSize.min, criteria.lotSize.max),
    },
  ];
}
