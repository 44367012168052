import { useCallback } from "react";
import { ConsentStatus } from "types";

import { useTxHttp } from "../http";
import { useServices } from "../index";
import { ReadPortfolioDto, WriteContactDto } from "./types";

export const usePortfolioActions = () => {
  const { put, post } = useTxHttp();
  const { endpoints } = useServices();

  const activate = useCallback(
    async (id: string) => {
      await put<ReadPortfolioDto>(
        endpoints.portfolio.contacts.contact.activateContact(id)
      );
    },
    [endpoints.portfolio.contacts.contact, put]
  );

  const archive = useCallback(
    async (id: string, content: { consentStatus: ConsentStatus }) => {
      const _contentDto = {
        consentStatus: content.consentStatus,
      };
      await put<ReadPortfolioDto>(
        endpoints.portfolio.contacts.contact.archiveContact(id),
        {
          content: _contentDto,
          contentType: "application/json",
        }
      );
    },
    [endpoints.portfolio.contacts.contact, put]
  );

  const updateContactConsent = useCallback(
    async (id: string, consentStatus: ConsentStatus) => {
      const content = { consentStatus };
      await put<ReadPortfolioDto>(
        endpoints.portfolio.contacts.contact.consentstatus(id),
        {
          content,
          contentType: "application/json",
        }
      );
    },
    [endpoints.portfolio.contacts.contact, put]
  );

  const acceptConsent = useCallback(
    (id: string) => {
      return updateContactConsent(id, "Approved");
    },
    [updateContactConsent]
  );

  const denyConsent = useCallback(
    (id: string) => {
      return updateContactConsent(id, "Denied");
    },
    [updateContactConsent]
  );

  const add = useCallback(
    async (content: WriteContactDto) => {
      const response = await post<string>(endpoints.portfolio.contacts.all, {
        content,
        contentType: "application/json",
      });
      if (response.status >= 200 && response.status < 300)
        return Promise.resolve(response.data);
      else Promise.reject(response.data);
    },
    [endpoints.portfolio.contacts.all, post]
  );

  const update = useCallback(
    async (id: string, content: WriteContactDto) => {
      const response = await put<string>(
        endpoints.portfolio.contacts.contact.get(id),
        {
          content,
          contentType: "application/json",
        }
      );
      if (response.status >= 200 && response.status < 300)
        return Promise.resolve(response.data);
      else Promise.reject(response.data);
    },
    [endpoints.portfolio.contacts.contact, put]
  );

  return {
    acceptConsent,
    activate,
    add,
    archive,
    denyConsent,
    update,
  };
};
