import { makeStyles } from "@material-ui/core/styles";
import { baseTheme } from "theme/main";

export const useStyles = makeStyles((theme) => ({
  photo: {
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: 200,
    position: "relative",
  },
  photoCliquable: {
    cursor: "pointer",
  },
  buttonsIcon: {
    background: theme.palette.primary.main,
    marginRight: "7px",
  },
  buttonsIconContainer: {
    bottom: "-15px",
    position: "absolute",
    right: 0,
  },
  cardContent: {
    marginTop: "10px",
  },
  emphase: {
    color: baseTheme.palette.emphase,
  },
  fab: {
    bottom: "50%",
    left: "calc(100% - 30px)",
  },
  icons: {
    color: "white",
    fontSize: "1.5em",
  },
  imageInformation: {
    visibility: "hidden",
  },
  legend: {
    background: "rgba(0,0,0,0.4)",
    color: "#fff",
    marginTop: "-50px",
    position: "relative",
    textAlign: "center",
    width: "100%",
  },
  nbPhotos: {
    color: "#fff",
    position: "absolute",
    right: 10,
    textAlign: "center",
    top: -140,
    width: "25%",
  },
  root: {
    position: "relative",
    width: "100%",
    borderRadius: "10px",
    overflow: "hidden",
  },
  separator: {
    borderColor: baseTheme.palette.greyLight,
    margin: "auto",
    opacity: "50%",
    width: "90%",
  },
  texte: {
    height: "30px",
    marginBottom: "10px",
    marginTop: "10px",
    overflow: "hidden",
  },
  typeBienVille: {
    fontSize: "1rem",
  },
}));
