import { SortOption } from "UI/shared/molecules/SortInput";
import { AdaptedRefinements } from "components/RefinementField/types";
import { Status } from "services/user";
import { RequestForInformationsCriteria } from "types";
import { PagedResultDto } from "../dto/PagedResultDto";
import { RequestForInformationDto } from "../dto/RequestForInformationDto";
import { TransformRequestForInformationDto } from "../dto/TransformRequestForInformationDto";

export interface RequestForInformationsContext {
  activeRequestsCount: number;
  requestForInformations: PagedResultDto<RequestForInformationDto>;
  archiveRequestForInformation: (id: string, page: number) => void;
  activateRequestForInformation: (id: string, page: number) => void;
  transformRequestForInformation: (
    id: string,
    page: number,
    payload: TransformRequestForInformationDto
  ) => Promise<unknown>;
  loadMore: () => void;
  updateParamsWithFilters: (
    adaptedRefinements: AdaptedRefinements<RequestForInformationsCriteria>
  ) => void;
  updateParamsWithActiveSortOption: (sortOption: SortOption) => void;
  status: Status;
}

export const defaultRequestForInformationsContext = {
  activateRequestForInformation: (id: string, page: number) => {
    return;
  },
  activeRequestsCount: 0,
  archiveRequestForInformation: (id: string, page: number) => {
    return;
  },
  transformRequestForInformation: (
    id: string,
    page: number,
    payload: TransformRequestForInformationDto
  ) => {
    return Promise.resolve();
  },
  loadMore: () => {
    return;
  },
  requestForInformations: {} as PagedResultDto<RequestForInformationDto>,
  status: "pending" as Status,
  updateParamsWithActiveSortOption: (sortOption: SortOption) => {
    return;
  },
  updateParamsWithFilters: (
    adaptedRefinements: AdaptedRefinements<RequestForInformationsCriteria>
  ) => {
    return;
  },
};
