import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PlaceIcon from "@material-ui/icons/PlaceOutlined";
import React from "react";
import getUrlPhoto from "utils/UrlImage";

import { useServices } from "../../../../../services";
import {
  ScrollToTopButton,
  useInfiniteScroll,
} from "../../../../../services/useInfiniteScrollSR";

const useStyles = makeStyles((theme) => ({
  avatarLarge: {
    // margin: 5,
    // marginLeft: 10,
    height: 50,
    width: 50,
  },
  colorTonique: {
    color: theme.palette.tonique,
  },
  conseiller: {
    padding: 14,
    position: "relative",
    width: "100%",
  },
  photo: {
    borderRadius: "25px",
    height: "50px",
    margin: "5px",
    width: "50px",
  },
  titre: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    padding: "10px",
  },
}));

const Conseiller = ({ conseiller, doc }) => {
  const classes = useStyles();
  const theme = useTheme();
  const tonique = theme.palette.tonique;
  const { authenticationService } = useServices();
  const userData = authenticationService.getUserData();
  //TODO: utiliser le pattern adapter
  conseiller.prenom = conseiller.prenom ? conseiller.prenom : "Inconnu";
  conseiller.nom = conseiller.nom ? conseiller.nom : "Inconnu";
  conseiller.classement = conseiller.classement ? conseiller.classement : "0";
  conseiller.departement = conseiller.departement
    ? conseiller.departement
    : "Inconnu";
  conseiller.resultat = conseiller.resultat ? conseiller.resultat : "0";
  conseiller.resultatSecondaire = conseiller.resultatSecondaire
    ? conseiller.resultatSecondaire
    : "0";
  const photo = conseiller.photo
    ? getUrlPhoto(userData.Instance, "70x70", conseiller.photo.toLowerCase())
    : "";

  return (
    <Paper
      elevation={1}
      className={classes.conseiller}
      style={
        userData.family_name === conseiller.nom &&
        userData.given_name === conseiller.prenom
          ? {
              borderColor: tonique,
              borderStyle: "solid",
              borderWidth: "2px",
            }
          : { borderStyle: "none" }
      }
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={4} md={3} lg={2}>
          <Grid container justify="center">
            <Avatar
              aria-label="photo conseiller"
              className={classes.avatarLarge}
            >
              <img
                src={photo}
                width="50"
                height="auto"
                alt="face"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/static/images/face.png";
                }}
              />
            </Avatar>
          </Grid>
        </Grid>

        <Grid item xs={6} md={7} lg={8}>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={8}>
              <Typography variant="h4" noWrap>
                {conseiller.prenom} {conseiller.nom}
              </Typography>
              <Grid
                container
                direction="row"
                style={{
                  display: "inlineFlex",
                  flexWrap: "nowrap",
                  marginLeft: "-3px",
                  verticalAlign: "middle",
                }}
              >
                <PlaceIcon
                  style={{
                    color: "#8E8E8E",
                    fontSize: 14,
                    marginTop: 3,
                  }}
                />
                <Typography variant="body2" noWrap style={{ lineHeight: 1.6 }}>
                  {conseiller.departement}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.colorTonique}>
                  <Typography variant="h6" noWrap style={{ paddingRight: 5 }}>
                    {doc === "Actes" || doc === "Compromis"
                      ? `${Number.parseFloat(conseiller.resultat)
                          .toFixed(2)
                          .toString()
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} € HT`
                      : `${Number.parseFloat(
                          conseiller.resultat
                        ).toString()} mandat(s) exclusif(s)`}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      borderLeft: "1px solid",
                      paddingLeft: 5,
                    }}
                  >
                    {`${conseiller.resultatSecondaire} ${doc}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} className={classes.colorTonique}>
          <Typography variant="h1">{conseiller.classement}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

const ListeConseillers = ({ titre, liste, doc }) => {
  const classes = useStyles();
  const { ViewObserverFirst, firstVisible } = useInfiniteScroll();
  return (
    <>
      <Typography variant="h5" className={classes.titre}>
        {titre}
      </Typography>

      <Grid container spacing={2}>
        {!liste ||
          (liste.length === 0 && (
            <Grid item xs={12}>
              <Paper elevation={1} className={classes.conseiller}>
                Aucun résultat disponible
              </Paper>
            </Grid>
          ))}
        {liste &&
          liste.length > 0 &&
          liste.map((conseiller, index) => {
            if (index === 0) {
              return (
                <Grid key={index} item xs={12}>
                  <ViewObserverFirst>
                    <Conseiller
                      conseiller={conseiller}
                      classes={classes}
                      doc={doc}
                    />
                  </ViewObserverFirst>
                </Grid>
              );
            }
            return (
              <Grid key={index} item xs={12}>
                <Conseiller
                  conseiller={conseiller}
                  classes={classes}
                  doc={doc}
                />
              </Grid>
            );
          })}
      </Grid>
      {!firstVisible && <ScrollToTopButton />}
    </>
  );
};

export default ListeConseillers;
