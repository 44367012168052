import { Grid, Typography } from "@material-ui/core";
import React from "react";

import { ParkingsDto } from "../../models/partSummariesDto";
import { SubSectionTitle } from "../components/SectionTitle";
import { ValidationNumberField } from "../components/ValidationNumberField";
import { ProfileInputProps } from "../InputProps";
import { SelectParkingKind } from "./Features/Parking/SelectParkingKind";

export const SaleParking: React.FC<ProfileInputProps<ParkingsDto>> = ({
  pristine,
  onChange,
  value,
  disabled = false,
}) => {
  return (
    <>
      <SubSectionTitle title="Stationnement" />
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item xs={8} md={6}>
          <Typography variant={"body2"}>
            Nombre de stationnements privés :{" "}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <ValidationNumberField
            label={""}
            disabled={disabled}
            pristine={pristine}
            onChange={(isValid, count) => {
              onChange({ ...value, count }, isValid);
            }}
            value={value.count}
          />
        </Grid>
      </Grid>
      <SelectParkingKind
        value={value}
        onChange={(newValue) => {
          onChange(
            {
              ...value,
              hasBox: newValue.hasBox,
              hasExteriorParking: newValue.hasExteriorParking,
              hasInteriorParking: newValue.hasInteriorParking,
            },
            true
          );
        }}
      />
    </>
  );
};
