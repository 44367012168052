import { RequestForInformationsFiltersDto } from "../dto/RequestForInformationsFiltersDto";

export const toParams = (
  filters: RequestForInformationsFiltersDto | null | undefined
) => {
  if (!filters) return {};
  return (Object.keys(
    filters
  ) as (keyof RequestForInformationsFiltersDto)[]).reduce((acc, key) => {
    if (key === "receivedAt") {
      return {
        ...acc,
        receivedAtMax: filters[key]?.max,
        receivedAtMin: filters[key]?.min,
      };
    }

    return { ...acc, [key]: filters[key] };
  }, {});
};
