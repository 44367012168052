import { Grid } from "@material-ui/core";
import { useBuckets } from "hooks/useBuckets";
import React, { useContext } from "react";
import { CheckableRealEstatePropertyCard } from "UI/features/realEstates/organisms/CheckableRealEstatePropertyCard";
import { RealEstatePropertyCardProps } from "UI/features/realEstates/organisms/RealEstatePropertyCard";
import { InfiniteScrollList } from "UI/shared/organisms/InfiniteScrollList/InfiniteScrollList";

import { SharingNetworkRealEstatesContext } from "./index";

export const SharingNetworkRealEstatesLarge: React.FC = () => {
  const {
    realEstates,
    isChecked,
    isShared,
    handleCheck,
    loadMore,
    modalContainerId,
  } = useContext(SharingNetworkRealEstatesContext);

  const realEstatesBuckets = useBuckets<RealEstatePropertyCardProps>({
    data: realEstates,
    size: 3,
  });

  return (
    <InfiniteScrollList
      pageSize={1}
      loadMore={loadMore}
      inTabs={false}
      wrapItems={true}
      containerId={modalContainerId}
    >
      {realEstatesBuckets.map((realEstateBucket, i) => (
        <Grid container spacing={2} key={`network-realestates-lg-bucket-${i}`}>
          {realEstateBucket.map((realEstate) => (
            <Grid
              item
              xs={4}
              key={`sharing-network-realestates-lg-${realEstate.id}`}
            >
              <CheckableRealEstatePropertyCard
                realEstateProps={realEstate}
                id={realEstate.id || ""}
                handleCheck={handleCheck}
                isChecked={isChecked(realEstate.id || "")}
                isShared={isShared(realEstate.page || 0, realEstate.id || "")}
              />
            </Grid>
          ))}
        </Grid>
      ))}
    </InfiniteScrollList>
  );
};
