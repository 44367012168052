import { Typography } from "@material-ui/core";
import React from "react";

import { useStyles } from "UI/features/requestForInformations/style";

export const TopicTitle: React.FC<{ label: string }> = ({ label }) => {
  const classes = useStyles();
  return (
    <div className={classes.topicTitle}>
      <Typography variant={"h4"}>{label.toUpperCase()}</Typography>
    </div>
  );
};
