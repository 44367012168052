import { MaturityType } from "routes/app/contacts/contact/components/models/maturityType";
import { ProfileAddressDto } from "routes/app/contacts/contact/components/models/profileAddressDto";
import { ParkingAmenityType } from "routes/app/contacts/contact/components/models/Proposal/Features/Parking/parkingAmenityType";
import { ParkingProposalKind } from "routes/app/contacts/contact/components/models/Proposal/Features/Parking/parkingInfoKind";
import { ParkingPropertyInfoDto } from "routes/app/contacts/contact/components/models/Proposal/Features/Parking/parkingPropertyInfoDto";
import { ParkingRentedInfoDto } from "routes/app/contacts/contact/components/models/Proposal/Features/Parking/parkingRentedInfoDto";
import { ForRentParkingFinancialInfo } from "routes/app/contacts/contact/components/models/Proposal/ForRent/Parking/ForRentParkingFinancialInfoDto";
import { Complementary } from "types/utilityTypes";
import { FormValidity, PropertyInfoCheckBox, StateHandler } from "../types";

export const maturityTypeUpdater: (maturity: MaturityType) => StateHandler = (
  maturity
) => (prev) => ({
  ...prev,
  maturity,
});

export const notesUpdater: (notes: string) => StateHandler = (notes) => (
  prev
) => ({
  ...prev,
  notes,
});

export const addressUpdater: (address: ProfileAddressDto) => StateHandler = (
  address
) => (prev) => ({
  ...prev,
  features: {
    ...prev.features,
    address,
  },
});

export const parkingKindUpdater: (
  parkingKind: ParkingProposalKind
) => StateHandler = (parkingKind) => (prev) => ({
  ...prev,
  features: {
    ...prev.features,
    parkingKind,
  },
});

export const propertyInfoUpdater: (
  dimension: number | undefined,
  info: Complementary<ParkingPropertyInfoDto, PropertyInfoCheckBox>
) => StateHandler = (dimension, info) => (prev) => ({
  ...prev,
  features: {
    ...prev.features,
    parkingPropertyInfo: {
      ...prev.features.parkingPropertyInfo,
      [info]: dimension,
    },
  },
});

export const propertyInfoCheckboxUpdater: (
  info: PropertyInfoCheckBox
) => StateHandler = (info) => (prev) => ({
  ...prev,
  features: {
    ...prev.features,
    parkingPropertyInfo: {
      ...prev.features.parkingPropertyInfo,
      [info]: !prev.features.parkingPropertyInfo[info],
    },
  },
});

export const financialInfoUpdater: (
  financialInfo: ForRentParkingFinancialInfo
) => StateHandler = (financialInfo) => (prev) => ({
  ...prev,
  features: {
    ...prev.features,
    financialInfo,
  },
});

export const amenitiesUpdater: (
  parkingAmenities: ParkingAmenityType[]
) => StateHandler = (parkingAmenities) => (prev) => ({
  ...prev,
  features: { ...prev.features, parkingAmenities },
});

export const rentedInfoUpdater: (
  parkingRentedInfo: ParkingRentedInfoDto
) => StateHandler = (parkingRentedInfo) => (prev) => ({
  ...prev,
  features: {
    ...prev.features,
    parkingRentedInfo,
  },
});

export const formValidityUpdater: (
  key: keyof FormValidity,
  isValid: boolean
) => React.SetStateAction<FormValidity> = (key, isValid) => (prev) => ({
  ...prev,
  [key]: isValid,
});
