import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  consentStatus: {
    "&:hover": {
      color: "#BFB9B9",
    },
    color: "#E3E3E3",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 500,
  },
}));
