import React from "react";

const defaultContext = {
  isOpen: false,
  setIsOpen: (value: boolean) => {
    return;
  },
};

interface Value {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

const MoreFiltersContext = React.createContext<Value>(defaultContext);

export const useMoreFiltersContext = () => {
  const { isOpen, setIsOpen } = React.useContext(MoreFiltersContext);
  return { isOpen, setIsOpen };
};

export const MoreFiltersProvider: React.FC = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <MoreFiltersContext.Provider value={{ isOpen, setIsOpen }}>
      {props.children}
    </MoreFiltersContext.Provider>
  );
};
