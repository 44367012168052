import { AdaptedRefinements } from "components/RefinementField/types";
import React, { useEffect, useRef, useState } from "react";
import { PagedResultDto } from "services/profiles/dto/PagedResultDto";
import { ProfilesAggregationsDto } from "services/profiles/dto/ProfilesAggregationsDto";
import { ProfilesParamsDto } from "services/profiles/dto/ProfilesParamsDto";
import { useProfilesSearch } from "services/profiles/useProfilesSearch";
import { useAdaptedRefinements } from "services/profiles/useRefinements";
import { useSortOptions } from "services/profiles/useSortOptions";
import { INITIAL_SLUG } from "services/slugs/constants";
import { useSlugs } from "services/slugs/useSlugs";
import { ProfilesQueryParams, useQuery } from "services/useQuery";
import { deepEquality } from "utils/objects/deepEquality";

import { ProfileItemDto } from "../../../services/profiles/dto/ReadModelProfileDto";
import { ReadModelProfilesContextProvider } from "../../../services/profiles/readModelProfilesContext";
import { ProfilesCriteria } from "../../../types";
import { SortOption } from "../../../UI/shared/molecules/SortInput";
import { ProfilesListPage } from "./pages/ProfileListPage";

const ProfilesHandler: React.FC = () => {
  const [pagedProfiles, setPagedProfiles] = useState<
    PagedResultDto<ProfileItemDto>
  >({
    aggregations: {} as ProfilesAggregationsDto,
    count: 0,
    documents: [],
    hasMore: false,
    pageNumber: 1,
    pageSize: 0,
    pending: true,
    total: 0,
    totalPage: 1,
  });

  const query = useQuery<ProfilesQueryParams>(["slug"]);
  const { storeSlugs, decodeSlug } = useSlugs<ProfilesCriteria>("Profiles");

  const {
    adaptedRefinements,
    refinements,
    onChange: onChangeRefinements,
    onFullChange: onFullChangeRefinements,
    onClear: onClearRefinements,
  } = useAdaptedRefinements(pagedProfiles);

  const {
    sortOptions,
    activeSortOption,
    onChange: changeSortOptions,
  } = useSortOptions();

  const { search } = useProfilesSearch();

  const [params, setParams] = useState<ProfilesParamsDto>({
    pageNumber: 1,
    size: 20,
    sortAsc: false,
    sortColumn: "LastModificationDate",
  });

  useEffect(() => {
    if (query.slug && query.slug !== INITIAL_SLUG) {
      onFullChangeRefinements(decodeSlug(query.slug));
    }
  }, []);

  const loadData = async (pageNumber: number) => {
    const response = await search(
      adaptedRefinements,
      activeSortOption,
      params.pageNumber
    );

    const documents =
      pageNumber === 1
        ? response.result.documents
        : [...pagedProfiles.documents, ...response.result.documents];

    setPagedProfiles({
      ...response.result,
      documents: documents,
      pending: false,
    });

    setParams({
      ...params,
      pageNumber,
    });
  };

  const adaptedRefinementsRef = useRef<AdaptedRefinements<ProfilesCriteria>>();
  const activeSortOptionRef = useRef<SortOption>();

  useEffect(() => {
    if (
      deepEquality(adaptedRefinementsRef.current, adaptedRefinements) &&
      deepEquality(activeSortOptionRef.current, activeSortOption)
    )
      return;
    adaptedRefinementsRef.current = { ...adaptedRefinements };
    activeSortOptionRef.current = { ...activeSortOption };
    storeSlugs(adaptedRefinements);
    loadData(1);
  }, [adaptedRefinements, activeSortOption]);

  useEffect(() => {
    loadData(params.pageNumber);
  }, [params.pageNumber]);

  const loadMore = () => {
    if (pagedProfiles.pageNumber < pagedProfiles.totalPage) {
      setParams({
        ...params,
        pageNumber: params.pageNumber + 1,
      });
    }
  };

  return (
    <ProfilesListPage
      pending={pagedProfiles.pending}
      profiles={pagedProfiles}
      totalCount={pagedProfiles.total}
      loadMore={loadMore}
      pageSize={params.size}
      adaptedRefinements={adaptedRefinements}
      refinements={refinements}
      onClearRefinements={onClearRefinements}
      onChangeRefinements={onChangeRefinements}
      sortOptions={sortOptions}
      onChangeSortOptions={changeSortOptions}
      selectedSortOptionValue={activeSortOption.value}
    />
  );
};

export const ProfilesFeatures: React.FC = () => (
  <ReadModelProfilesContextProvider>
    <ProfilesHandler />
  </ReadModelProfilesContextProvider>
);
