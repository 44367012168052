import { CancelToken } from "axios";
import { useCallback } from "react";

import { PigeSearchResult } from "../../types";
import { useTxHttp } from "../http";
import { useServices } from "../index";

export interface PigesSearchParams {
  [key: string]: unknown;

  size?: number;
  searchAfter?: string[];
  prixMin?: number;
  prixMax?: number;
  livingAreaMin?: number;
  livingAreaMax?: number;
  landAreaMin?: number;
  landAreaMax?: number;
  nbPieces?: string[];
  nbChambres?: string[];
  typesDeBien?: string[];
  typesEtatBien?: string[];
  typesAnnonceur?: string[];
  typesAnnonce?: string[];
  dateDePublicationMin?: Date;
  dateDePublicationMax?: Date;
  fullText?: string;
  favorisUniquement?: boolean;
  masqueesIncluses?: boolean;
  avecSuiviUniquement?: boolean;
  avecNotesUniquement?: boolean;
  telephoneUniquement?: boolean;
  pigeDuJour?: boolean;
  typeAnnonceurParticuliers?: boolean;
  typeAnnonceurPro?: boolean;
  inactivesIncluses?: boolean;
  baissesDePrix?: boolean;
  sortAsc?: boolean;
  sortColumn?: string;
  villes?: string[];
  convertedOnly?: boolean;
  contactPhone?: string;
}

export const PigeEventErrors = {
  OFFICE365_ERROR: "Office365Error",
  TREMPLINX_ERROR: "TremplinXError",
};

export const usePiges = () => {
  const { get } = useTxHttp();
  const { endpoints } = useServices();

  return {
    search: useCallback(
      (params: PigesSearchParams, cancelToken: CancelToken) =>
        get<PigeSearchResult>(
          endpoints.pige.recherche,
          params,
          cancelToken
        ).then((response) => {
          return response.data;
        }),
      [get]
    ),
  };
};
