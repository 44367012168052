import { EndpointsProvider } from "./interfaces/EndpointsProvider";

export interface PigeEndpoints {
  historiquePrix: (id: string) => string;
  suivi: (id: string) => string;
  detail: (id: string) => string;
  favoris: (id?: string) => string;
  adsWithEvents: string;
  recherche: string;
  rechercheGlobale: string;
  exclusions: (id?: string) => string;
  conversions: string;
  convert: string;
  defaultSearchLocationsSettings: string;
  notes: (id: string) => string;
  acquirersMatching: (id: string) => string;
}

const pathFromId = (id?: string) => (id ? `/${id}` : "");

const endpoints: EndpointsProvider<PigeEndpoints> = (microServices) => ({
  adsWithEvents: `${microServices.tx("pige")}/v1/annonces/Suivi`,
  conversions: `${microServices.tx("pige")}/v1/conversion`,
  convert: `${microServices.tx("pige")}/v1/conversion`,
  defaultSearchLocationsSettings: `${microServices.tx(
    "pige"
  )}/v1/DefaultSearchLocationsSettings`,
  detail: (id) => `${microServices.tx("pige")}/v1/Annonces/${id}`,
  exclusions: (id) =>
    `${microServices.tx("pige")}/v1/Exclusions${pathFromId(id)}`,
  favoris: (id) => `${microServices.tx("pige")}/v1/favoris${pathFromId(id)}`,
  historiquePrix: (id) => `${microServices.tx("pige")}/v1/Annonces/${id}/prix`,
  notes: (id) => `${microServices.tx("pige")}/v1/notes/${id}`,
  recherche: `${microServices.tx("pige")}/v1/Annonces`,
  rechercheGlobale: `${microServices.tx("pige")}/v1/Annonces/RechercheGlobale`,
  suivi: (id) => `${microServices.tx("pige")}/v1/annonces/Suivi/${id}`,
  acquirersMatching: (id) =>
    `${microServices.tx("pige")}/v1/matching?adId=${id}`,
});

export default endpoints;
