import { Grid, Paper, useTheme } from "@material-ui/core";
import PlaceIcon from "@material-ui/icons/RoomOutlined";
import { DirectionMapButton } from "UI/shared/atoms/buttons/DirectionMapButton";
import { ResetMapButton } from "UI/shared/atoms/buttons/ResetMapButton";
import { Text16px } from "UI/shared/atoms/typographies/Text16px";
import { Text16pxBold } from "UI/shared/atoms/typographies/Text16pxBold";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import { useMap } from "hooks/useMap";
import React, { useCallback, useEffect } from "react";
import { Map, Marker, Polygon, TileLayer } from "react-leaflet";
import { useServices } from "services";
import { ITheme } from "theme/ts/main";
import Wkt from "wicket/wicket-leaflet";

export type MapModeType = "zone" | "point";
export interface ProfileMapProps {
  mode?: MapModeType;
  polygon?: string;
  mapHeight?: number;
  localisation: string;
  position?: any;
}
export const ProfileMap: React.FC<ProfileMapProps> = ({
  polygon,
  mapHeight,
  localisation,
  position,
  mode = "zone",
}) => {
  const {
    l10n: { t },
  } = useServices();
  const theme = useTheme<ITheme>();
  const wkt = new Wkt.Wkt();
  let computedPolygon: any = null;
  if (mode === "zone") {
    try {
      wkt.read(polygon);
      computedPolygon = wkt.toObject()._latlngs;
    } catch (e) {
      console.log(e);
    }
  }

  const map = useMap();

  const resetMap = useCallback(() => {
    if (mode === "zone") {
      map?.current?.leafletElement.fitBounds([...computedPolygon]);
    } else if (position?.lat && position?.lng) {
      map?.current?.leafletElement.fitBounds([position]);
    }
  }, [computedPolygon, mode, position]);

  useEffect(() => {
    resetMap();
  }, [resetMap]);

  return (
    <>
      <Grid item xs={12} style={{ position: "relative" }}>
        <Map
          ref={map}
          zoom={6}
          zoomControl={false}
          style={{ height: mapHeight || 200, width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {computedPolygon && (
            <Polygon
              positions={computedPolygon}
              color={theme.palette.tonique}
            />
          )}
          {mode === "point" && <Marker position={position} />}
        </Map>

        {mode === "zone" && (
          <Paper
            elevation={1}
            style={{
              alignItems: "center",
              background: "white",
              bottom: 10,
              display: "flex",
              justifyContent: "space-between",
              left: 10,
              padding: 10,
              position: "absolute",
              width: 190,
              zIndex: 900,
            }}
          >
            <div
              style={{
                backgroundColor: `${theme.palette.tonique}80`,
                borderColor: theme.palette.tonique,
                borderRadius: 15,
                borderStyle: "solid",
                borderWidth: 3,
                height: 30,
                width: 40,
              }}
            />
            <Text16pxBold>{t("Zone de recherche")}</Text16pxBold>
          </Paper>
        )}
        <ResetMapButton
          tooltip={t("Recentrer sur mes critères")}
          reset={resetMap}
        />
        {!!position?.lat && !!position?.lng && (
          <DirectionMapButton
            latitude={position.lat.toString()}
            longitude={position.lng.toString()}
          />
        )}
      </Grid>
      <VerticalSpace size={8} />
      <Grid container spacing={0}>
        <PlaceIcon />
        <Text16px>{localisation}</Text16px>
      </Grid>
    </>
  );
};
