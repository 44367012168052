import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  DataProvider,
  DataProviderContext,
  REFINEMENT_TYPE,
} from "components/DataProvider";
import Loading from "components/Loading/LoadingSkeleton";
import PaperX from "components/PaperX";
import TitrePage from "components/TitrePage";
import ReactEcharts from "echarts-for-react";
import React, { useContext, useState } from "react";
import { useServices } from "services";
import { ReferentielContext } from "services/reporting/Referentiel";
import {
  getOptionBarParAnneeActes,
  getOptionParMoisActes,
  getOptionRadarActes,
} from "theme/EChartsStyles";
import { ErrorPanel } from "UI/shared/templates/Panels/ErrorPanel";
import DateRangeSubtitle from "../components/DateRangeSubtitle";
import Kpi from "./components/Kpi";

const useStyles = makeStyles((theme) => ({
  root: {
    // ...theme.mixins.gutters(),
  },
}));

const Actes = () => {
  const { endpoints } = useServices();
  const referentiel = useContext(ReferentielContext);

  let initialRefinements = false;

  if (Object.keys(referentiel).length > 0) {
    initialRefinements = {
      ...referentiel,
      dateEvt: {
        count: 0,
        labelLimiteInf: "dateDebut",
        labelLimiteSup: "dateFin",
        limiteInf: new Date(new Date().getFullYear(), 0, 1),
        limiteSup: new Date(),
        query: "dateAffaire",
        type: REFINEMENT_TYPE.range.date,
      },
    };
  } else
    return (
      <ErrorPanel
        title={"Données non disponibles."}
        text={"Nous n'avons pas reçu les données statistiques."}
      />
    );

  return (
    <div>
      {initialRefinements && (
        <DataProvider
          refinements={JSON.stringify(initialRefinements)}
          url={endpoints.reporting.acte}
        >
          <ActesComp />
        </DataProvider>
      )}
    </div>
  );
};

export default Actes;

const chartsStyleRadar = { height: "500px", width: "100%" };
const chartsStyle = { height: "500px", width: "100%" };

const ActesComp = (props) => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();
  const { providerData, components } = useContext(DataProviderContext);
  const [mensuel, setMensuel] = useState(false);

  const theme = useTheme();
  const [champ, setChamp] = useState("caActesTTC");

  const restore = (params) => {
    setMensuel(false);
    setChamp("caActesTTC");
  };

  const barSelected = (params) => {
    if (mensuel) {
      setMensuel(false);
    } else {
      setMensuel(parseInt(params.name));
    }
  };

  const handleChangeChamp = (event) => {
    setChamp(event.target.value);
  };

  const getStatsParAnnee = (annee) =>
    getOptionParMoisActes(t, theme, annee, champ, providerData.histo);

  const getStatsGlobales = () =>
    getOptionBarParAnneeActes(t, theme, champ, providerData.histo);

  if (providerData && providerData.error)
    return (
      <ErrorPanel
        title={"Données non disponibles."}
        text={"Nous n'avons pas reçu les données statistiques."}
      />
    );

  return (
    <div className={classes.root}>
      <TitrePage
        categorie="Reporting"
        page={t("Actes")}
        filePath={"/static/markdowns/Reporting/reportingActes.md"}
        Composant={() =>
          components.dateRange({
            subtitle: (date) => <DateRangeSubtitle date={date} />,
          })
        }
      />
      <div>{components.searchBar()}</div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          {providerData && !providerData.error ? (
            <Kpi kpi={providerData.kpi} t={t} />
          ) : (
            <Loading styles={{ height: 500 }} />
          )}
        </Grid>
        <Grid item xs={12} lg={8}>
          {providerData && !providerData.error ? (
            <PaperX>
              <Typography variant="h5">Performance</Typography>
              <ReactEcharts
                option={getOptionRadarActes(t, theme, providerData.radar)}
                style={chartsStyleRadar}
              />
            </PaperX>
          ) : (
            <Loading styles={{ height: 500 }} />
          )}
        </Grid>
        <Grid item xs={12}>
          {providerData && !providerData.error ? (
            <PaperX>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={2}>
                  <FormControl component="fieldset" style={{ width: "100%" }}>
                    <Typography variant="h5">Actes</Typography>
                    <RadioGroup
                      aria-label="gender"
                      name="gender2"
                      value={champ}
                      onChange={handleChangeChamp}
                    >
                      <FormControlLabel
                        value="caActesTTC"
                        control={<Radio color="primary" />}
                        label={t("CaActesTTC")}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="caActesHT"
                        control={<Radio color="primary" />}
                        label={t("CaActesHT")}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="caCompromisTTC"
                        control={<Radio color="primary" />}
                        label={t("CaCompromisTTC")}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="caCompromisHT"
                        control={<Radio color="primary" />}
                        label={t("CaCompromisHT")}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="nombreActes"
                        control={<Radio color="primary" />}
                        label={t("NombreActes")}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="nombreCompromis"
                        control={<Radio color="primary" />}
                        label={t("NombreCompromis")} //TODO: mettre toutes les clé au format enum
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <ReactEcharts
                    option={
                      mensuel ? getStatsParAnnee(mensuel) : getStatsGlobales()
                    }
                    style={chartsStyle}
                    onEvents={{
                      click: barSelected,
                      restore: restore,
                    }}
                  />
                </Grid>
              </Grid>
            </PaperX>
          ) : (
            <Loading styles={{ height: 500 }} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
