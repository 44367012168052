import { Grid } from "@material-ui/core";
import PaginationX from "components/PaginationX";
import React from "react";
import { breakpoint } from "theme/main";
import { AgentMatchingAcquirer } from "UI/features/profiles/molecules/MatchingAcquirer/Agent";

const ConsultantBuyers = ({
  buyers,
  currentPageNumber,
  totalPage,
  handleConsultantBuyersChange,
}) => {
  return (
    <Grid container spacing={2}>
      {buyers.map(
        ({
          lastModificationDate,
          contactFirstName,
          contactLastName,
          contactGender,
          contactTelephone,
          contactEmail,
          profileReference,
          contactId,
        }) => (
          <Grid key={profileReference} item xs={12} md={6} lg={4}>
            <AgentMatchingAcquirer
              phoneNumber={contactTelephone}
              mail={contactEmail}
              name={contactLastName}
              firstName={contactFirstName}
              civility={contactGender}
              opportunityId={profileReference}
              contactUniqueId={contactId}
              lastModificationDate={lastModificationDate}
            />
          </Grid>
        )
      )}

      <Grid item xs={12}>
        <div style={{ marginTop: 20 }}>
          <PaginationX
            page={currentPageNumber || 1}
            nbPages={totalPage || 1}
            onChange={handleConsultantBuyersChange}
            maxOnScreen={breakpoint().xs ? 1 : breakpoint().smAndDown ? 5 : 7}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default ConsultantBuyers;
