import { Grid } from "@material-ui/core";
import React from "react";
import { useServices } from "services";
import { formatPrix } from "utils/formatage";

import { BlocContainer } from "../../../../../../../shared/atoms/containers/BlocContainer";
import { Column } from "../../../../../../../shared/atoms/containers/Column";
import { PaddingContainer } from "../../../../../../../shared/atoms/containers/PaddingContainer";
import { Row } from "../../../../../../../shared/atoms/containers/Row";
import { HorizontalSpace } from "../../../../../../../shared/atoms/spacing/HorizontalSpace";
import { Text12pxBold } from "../../../../../../../shared/atoms/typographies/Text12pxBold";
import { Text14px } from "../../../../../../../shared/atoms/typographies/Text14px";
import { Text14pxBold } from "../../../../../../../shared/atoms/typographies/Text14pxBold";
import { VerticalSpace } from "../../../../../../../shared/atoms/spacing/VerticalSpace";
import { LabelledValue } from "../../../../../../../shared/molecules/LabelledValue";
import { TitleAndHorizontalDivider } from "../../../../../../../shared/molecules/TitleAndHorizontalDivider";
import { ProfileOriginType } from "../../../../../../../../routes/app/contacts/contact/components/models/profileOriginType";
import { PremisesFeaturesDto } from "../../../../../../../../routes/app/contacts/contact/components/models/Proposal/ForSale/Premises/premisesFeaturesDto";
import { PremisesLocation } from "../../../../../../../../routes/app/contacts/contact/components/models/Proposal/ForSale/Premises/PremisesLocation";
import { PremisesLocationAttractiveness } from "../../../../../../../../routes/app/contacts/contact/components/models/Proposal/ForSale/Premises/PremisesLocationAttractiveness";
import { SalePremisesProfileReadDto } from "../../../../../../../../routes/app/contacts/contact/components/models/Proposal/ForSale/Premises/salePremisesProfileReadDto";
import { ProfileLocalisation } from "../../../../../organisms/ProfileLocalisation";
import { ProfileProject } from "../../../../../organisms/ProfileProject";
import { ProfileStatusCard } from "../../../../../organisms/ProfileStatusCard";

export interface PremisesForSaleProfileDetailsProps {
  readProfile: SalePremisesProfileReadDto;
}

const getPriceSection: (a: PremisesFeaturesDto) => Record<string, string> = (
  features
) => ({
  ...{
    "Honoraires acquéreur": `${
      features.financialInfo.agentPurchaserFees || "NC"
    }%`,
    "Honoraires vendeur": `${features.financialInfo.agentSellerFees || "NC"}%`,
    "Prix (net vendeur)": `${formatPrix(
      (features.financialInfo.price || 0) /
        (1 +
          (features.financialInfo.agentSellerFees! +
            features.financialInfo.agentPurchaserFees!) /
            100)
    )}`,
    "Prix de vente": `${formatPrix(features.financialInfo.price)}`,
  },
});

const getPremisesLocation = (
  location: PremisesLocation,
  t: (_: string) => string
): string => {
  switch (location) {
    case "CommercialZone":
      return t("premisesLocation.CommercialZone");
    case "ActivityZone":
      return t("premisesLocation.ActivityZone");
    case "Downtown":
      return t("premisesLocation.Downtown");
    case "IndustrialZone":
      return t("premisesLocation.IndustrialZone");
    default:
      return t("NC");
  }
};

const getPremisesLocationAttractiveness = (
  locationAttractiveness: PremisesLocationAttractiveness,
  t: (_: string) => string
): string => {
  switch (locationAttractiveness) {
    case "Zone1":
      return t("premisesLocationAttractiveness.Zone1");
    case "Zone1Bis":
      return t("premisesLocationAttractiveness.Zone1Bis");
    case "Zone2":
      return t("premisesLocationAttractiveness.Zone2");
    default:
      return t("NC");
  }
};

const getCharacteristicSections: (
  a: PremisesFeaturesDto,
  t: (_: string) => string
) => Record<string, Record<string, string | number | undefined>> = (
  features,
  t
) => ({
  Autres: {
    Prestations: (features.amenities || [])
      .map((a) => t(`amenity.${a}`))
      .join(", "),
    Proximités: (features.proximities || [])
      .map((a) => t(`proximity.${a}`))
      .join(", "),
  },
  "Le bien": {
    "Année de construction": t(
      `buildAge.${features.propertyInfo.built || "NC"}`
    ),
    "Attractivité de l'emplacement": getPremisesLocationAttractiveness(
      features.locationAttractiveness,
      t
    ),
    "Hauteur plafond": features.propertyInfo.height
      ? `${features.propertyInfo.height} m`
      : "NC",
    "Nombre d'espaces": features.propertyInfo.lotSize
      ? `${features.propertyInfo.lotSize}`
      : "NC",
    "Nombre de niveaux": features.propertyInfo.numberOfLevels
      ? `${features.propertyInfo.numberOfLevels}`
      : "NC",
    Situation: getPremisesLocation(features.location, t),
    "Superficie totale": features.propertyInfo.totalArea
      ? `${features.propertyInfo.totalArea} m²`
      : "NC",
    "Surface pièce principale": features.propertyInfo.mainRoomArea
      ? `${features.propertyInfo.mainRoomArea} m²`
      : "NC",
    "Type de local": t(`premisesType.${features.propertyInfo?.type}`),
    "État général": t(
      `generalStateType.${features.propertyInfo.generalState || "NC"}`
    ),
  },
  Loué: features.rentedInfo.rented
    ? {
        "Loyer mensuel": `${features.rentedInfo.rent} euros`,
      }
    : { "Loyer mensuel": "NC" },
  Stationnements: {
    "Garage fermé": features.parkingInfo.isClosed ? "Oui" : "NC",
    Nombre: features.parkingInfo.numberOfParking || "NC",
    "Parking couvert": features.parkingInfo.isCovered ? "Oui" : "NC",
    "Parking extérieur": features.parkingInfo.isOutdoor ? "Oui" : "NC",
  },
});

export const PremisesForSaleProfileDetails: React.FC<PremisesForSaleProfileDetailsProps> = ({
  readProfile: {
    features,
    archivingDate,
    lastModificationDate,
    maturity,
    origin,
    account,
    creationDate,
    reference,
  },
}) => {
  const {
    l10n: { t },
  } = useServices();

  const priceSection = getPriceSection(features);

  const characteristicSections = getCharacteristicSections(features, t);

  const filteredCharacteristicSections: Record<
    string,
    Record<string, string | number>
  > = Object.keys(characteristicSections).reduce((acc, key) => {
    const property = Object.keys(characteristicSections[key]).reduce(
      (propAcc, propertyKey) => {
        const val = characteristicSections[key][propertyKey];
        return !!val && val !== "NC"
          ? { ...propAcc, [propertyKey]: val }
          : propAcc;
      },
      {}
    );
    return Object.keys(property).length > 0 ? { ...acc, [key]: property } : acc;
  }, {});

  return (
    <PaddingContainer left={16} top={16} right={12}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <ProfileStatusCard
            status={archivingDate ? "Archived" : "Active"}
            creationDate={creationDate || lastModificationDate}
            updateDate={archivingDate || lastModificationDate}
            maturity={maturity}
            origin={origin as ProfileOriginType}
            reference={reference}
          />

          <ProfileProject
            separatorSize={20}
            chipDiameter={56}
            profileAccount={account}
            businessType="Vente"
            propertyCategory="Parking"
          />
          <VerticalSpace size={16} />
          <BlocContainer title={t("PRIX")}>
            <Row rowJustify="flex-start">
              <Column itemJustify="flex-end" columnJustify="flex-end">
                {Object.keys(priceSection).map((k) => (
                  <Text14px key={k + "key"}>{t(k)}</Text14px>
                ))}
              </Column>
              <HorizontalSpace size={24} />
              <Column itemJustify="flex-start" columnJustify="flex-start">
                {Object.values(priceSection).map((v) => (
                  <Text14pxBold key={v + "value"}>{v}</Text14pxBold>
                ))}
              </Column>
            </Row>
          </BlocContainer>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ProfileLocalisation address={features.address} mapHeight={225} />
          <VerticalSpace size={8} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TitleAndHorizontalDivider
            title="CARACTÉRISTIQUES"
            spaceBetween={8}
            dividerColor={"#8E8E8E"}
          />
          {Object.keys(filteredCharacteristicSections).map((section) => {
            return (
              <div key={section}>
                <PaddingContainer top={8}>
                  <Text12pxBold>{section}</Text12pxBold>
                </PaddingContainer>
                <BlocContainer>
                  {Object.keys(filteredCharacteristicSections[section]).map(
                    (k) => (
                      <LabelledValue
                        key={k}
                        label={t(k)}
                        value={filteredCharacteristicSections[section][k]}
                        size={"14"}
                      />
                    )
                  )}
                </BlocContainer>
              </div>
            );
          })}
        </Grid>
      </Grid>
    </PaddingContainer>
  );
};
