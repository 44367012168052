import React, { FC } from "react";
import { MultipleText, MultipleValue } from "../types";
import { Chip, IconButton, TextField, Typography } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "./MultipleTextRefinement.module.css";

interface InputRenderProps {
  selected: MultipleValue<string>[];
  onDelete: (item: MultipleValue<string>[]) => void;
}

const ChipsRender = ({ selected, onDelete }: InputRenderProps) => {
  const isMultiple = selected.length > 1;

  return (
    <div className={styles.inputValue}>
      {isMultiple ? (
        <>
          <Typography variant="body1">{selected.length} choix</Typography>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(selected);
            }}
          >
            <Clear className={styles.clearIcon} />
          </IconButton>
        </>
      ) : (
        <>
          {selected.map((item) => (
            <Chip
              key={item.value}
              label={item.label}
              size="small"
              style={{ height: 19 }}
              onDelete={(e) => {
                e.stopPropagation();
                onDelete([item]);
              }}
            />
          ))}
        </>
      )}
    </div>
  );
};

const DefaultRender = ({ selected, onDelete }: InputRenderProps) => {
  const isMultiple = selected.length > 1;

  return (
    <div className={styles.inputValue}>
      {isMultiple ? (
        <>
          <Typography variant="body1">{selected.length} choix</Typography>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(selected);
            }}
          >
            <Clear className={styles.clearIcon} />
          </IconButton>
        </>
      ) : (
        <>
          {selected.map((item) => (
            <div className={styles.inputValue} key={item.value}>
              <Typography key={item.value} variant={"body1"}>
                {item.label}
              </Typography>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete([item]);
                }}
              >
                <Clear className={styles.clearIcon} />
              </IconButton>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

function getRenderValue(
  variant: "chips" | "no-chips",
  props: InputRenderProps
) {
  switch (variant) {
    case "chips":
      return <ChipsRender {...props} />;
    default:
      return <DefaultRender {...props} />;
  }
}

export interface SelectProps {
  value: MultipleText;
  onChange: (refinement: MultipleText) => void;
  label: string;
}

export const MultipleTextRefinement: FC<SelectProps> = ({
  label,
  value,
  onChange,
}) => {
  const selection = value.value.filter((item) => item.isRefined);

  function handleChange(items: MultipleValue<string>[]) {
    const nextValue = value.value.map((item) => {
      return items.find((selected) => item.value === selected.value)
        ? { ...item, isRefined: true }
        : { ...item, isRefined: false };
    });

    onChange({ kind: "multiple-text", value: nextValue });
  }

  function onDelete(items: MultipleValue<string>[]) {
    onChange({
      ...value,
      value: value.value.map((current) =>
        items.some((_) => _.label === current.label)
          ? { ...current, isRefined: false }
          : current
      ),
    });
  }

  return (
    <TextField
      fullWidth
      label={label}
      variant={"outlined"}
      select
      SelectProps={{
        multiple: true,
        onChange: (event) =>
          handleChange(event.target.value as MultipleValue<string>[]),
        renderValue: (selected: any) =>
          getRenderValue("no-chips", { onDelete, selected }),
        value: selection,
      }}
    >
      {value.value.map((item) => (
        <MenuItem key={item.value} value={item as any}>
          {`${item.label} (${item.count})`}
        </MenuItem>
      ))}
    </TextField>
  );
};
