import { makeStyles, Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

interface BusinessTypeCaptionProps {
  type: string;
}

export const BusinessTypeCaption: React.FC<BusinessTypeCaptionProps> = ({
  type,
}) => {
  const useStyles = makeStyles((theme: Theme) => ({
    businessType: {
      color: theme.palette.grey[500],
    },
  }));

  const classes = useStyles();
  return (
    <Typography
      variant="body1"
      className={classes.businessType}
      style={{ marginBottom: -5 }}
    >
      {type.toUpperCase()}
    </Typography>
  );
};
