import React from "react";
import { RadioChip } from "UI/shared/molecules/RadioChip";
import { JoinOwnershipType } from "../../models/JoinOwnershipType";
import { SubSectionTitle } from "../components/SectionTitle";

export const SelectJoinOwnershipType: React.FC<{
  value?: JoinOwnershipType;
  onChange: (value: JoinOwnershipType) => void;
  disabled: boolean;
}> = ({ value, onChange }) => {
  const options: JoinOwnershipType[] = ["None", "One", "Two", "Three"];

  return (
    <>
      <SubSectionTitle title="Mitoyenneté" mode={"non obligatoire"} />
      <RadioChip
        translationPrefix={"JoinOwnershipType"}
        options={options}
        value={value}
        onChange={onChange}
      />
    </>
  );
};
