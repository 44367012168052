import {
  deserialize,
  serialize,
} from "layoutContents/components/HitsContainer/utils";

export const handleRefinementsFromParameters = (
  params,
  mode,
  Refinements,
  dispatch,
  SNAPSHOT_KEY
) => {
  try {
    const parameters = deserialize(params);
    const newRefinements = Refinements;
    newRefinements.fromURLParams(parameters.refinements);
    dispatch({ payload: newRefinements, type: "setRefinements" });
    // restauration du mode précédent en retour d'une fiche biens seulement sur mode map
    // if (mode !== "map") sessionStorage.removeItem(SNAPSHOT_KEY);
    // if (sessionStorage.getItem(SNAPSHOT_KEY) !== null) {
    //   const savedState = deserialize(sessionStorage.getItem(SNAPSHOT_KEY));
    //   const newState = {
    //     ...savedState,
    //     refinements: Refinements,
    //   };
    //   newState.refinements.fromObject(savedState.refinements);
    //   sessionStorage.removeItem(SNAPSHOT_KEY);
    //   dispatch({ payload: newState, type: "restoreSavedState" });
    // }
    dispatch({
      payload: mode,
      type: "setDisplayMode",
    });
  } catch (e) {
    if (sessionStorage.getItem(SNAPSHOT_KEY) !== null) {
      const savedState = deserialize(sessionStorage.getItem(SNAPSHOT_KEY));
      const newState = {
        ...savedState,
        refinements: Refinements,
      };
      newState.refinements.fromObject(savedState.refinements);
      sessionStorage.removeItem(SNAPSHOT_KEY);
      dispatch({ payload: newState, type: "restoreSavedState" });
    }
  }
};

export const onUpdateLocalisationsBuilder = (state, dispatch, props, url) => ({
  regions,
  departements,
  villes,
  quartiers,
}) => {
  const _refinements = { ...state.refinements };

  _refinements.update("regions", regions);
  _refinements.update("departements", departements);
  _refinements.update("villes", villes);
  _refinements.update("quartiers", quartiers);
  const parameters = serialize({
    refinements: _refinements.toURLParams(),
  });
  dispatch({ payload: _refinements, type: "setRefinements" });
  props.history.push({
    pathname: `${url}/${state.displayMode}/${parameters}`,
  });
};

export const onUpdateRefinementsBuilder = (state, dispatch, props, url) => (
  query,
  value
) => {
  const _refinements = {
    ...state.refinements,
  };
  _refinements.update(query, value);
  const parameters = serialize({
    displayMode: state.displayMode,
    refinements: _refinements.toURLParams(),
  });
  props.history.push({
    pathname: `${url}/${state.displayMode}/${parameters}`,
  });
  dispatch({
    payload: _refinements,
    type: "setRefinements",
  });
};

export const onUpdateSortingBuilder = (state, dispatch, props, url) => ({
  sortOptions,
}) => {
  const _refinements = {
    ...state.refinements,
  };

  sortOptions.forEach(({ query, value }) => {
    _refinements.update(query, value);
  });
  const parameters = serialize({
    refinements: _refinements.toURLParams(),
  });
  props.history.push({
    pathname: `${url}/${state.displayMode}/${parameters}`,
  });
  dispatch({
    payload: _refinements,
    type: "setRefinements",
  });
};

export const clearRefinementsBuilder = (state, dispatch, props, url) => () => {
  const _refinements = {
    ...state.refinements,
  };
  _refinements.clear();
  dispatch({
    payload: _refinements,
    type: "setRefinements",
  });
  props.history.push({
    pathname: `${url}/${state.displayMode}/full-list`,
  });
};
