import React from "react";
import PropType from "prop-types";
import { NavLink } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core";
// import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.white,
    minWidth: 40,
  },
  listItem: {
    "&:hover": {
      color: theme.palette.tonique,
    },
    color: theme.palette.white,
    paddingBottom: 0,
    paddingTop: 0,
  },
  listItemSelected: {
    "&::before": {
      backgroundColor: theme.palette.tonique,
      content: "''",
      height: "100%",
      left: 0,
      position: "absolute",
      top: 0,
      width: "5px",
    },
    backgroundColor: `${theme.palette.buttonSelected} !important`,
    color: theme.palette.tonique,
    paddingBottom: 0,
    paddingTop: 0,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedShort: {
    paddingLeft: theme.spacing(4),
  },
  notNested: {
    height: 44,
  },
  // subMenuText: (extraStyle) => extraStyle.linkTextSize(theme),
}));

export default function PathLink(props) {
  const { route, nested, dispatch, location, extraStyle } = props;
  const classes = useStyles(extraStyle);

  let classListItem = nested ? classes.nested : classes.notNested;

  function handleClick() {
    sessionStorage.setItem("page courante", route.path);
    dispatch({ type: "clic pathlink" });
  }

  return (
    <NavLink
      to={route.path}
      style={{ textDecoration: "none" }}
      onClick={handleClick}
    >
      <ListItem
        button
        classes={{
          root: classes.listItem,
          selected: classes.listItemSelected,
        }}
        className={classListItem}
        selected={location.pathname === route.path}
      >
        {!nested && (
          <ListItemIcon
            classes={{
              root: classes.icon,
            }}
          >
            {route.icon}
          </ListItemIcon>
        )}
        <ListItemText
          primary={route.name}
          classes={{ primary: classes.subMenuText }}
        />
      </ListItem>
    </NavLink>
  );
}

PathLink.propTypes = {
  nested: PropType.bool,
  route: PropType.object,
};
