import routesNames from "router/routesNames";
const { app, monBienEnVente, mesBiensEnVente, biens } = routesNames;

export interface AgentRealEstatesRoutes {
  list: string;
  details: (id: string) => string;
}

export const agentRealEstatesRoutes: AgentRealEstatesRoutes = {
  list: `${app}${biens}${mesBiensEnVente}/list/full-list`,
  details: (id: string) => `${app}${biens}${monBienEnVente}/${id}/details/view`,
};
