import { useCallback } from "react";

export const REAL_ESTATES_STORAGE_KEY = "TX_REAL_ESTATES_SLUG";

export const useRealEstatesSlug = () => {
  const storeSlug: (slug: string) => void = useCallback((slug: string) => {
    sessionStorage.setItem(REAL_ESTATES_STORAGE_KEY, slug);
  }, []);

  const getStoredSlug: () => string | null = useCallback(() => {
    return sessionStorage.getItem(REAL_ESTATES_STORAGE_KEY);
  }, []);

  const clearSlug: () => void = () => {
    sessionStorage.removeItem(REAL_ESTATES_STORAGE_KEY);
  };

  return {
    getStoredSlug,
    storeSlug,
    clearSlug,
  };
};
