import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { SectionTitle } from "routes/app/contacts/components/ContactForm/components/Sections";
import { ForRentParkingFinancialInfo } from "routes/app/contacts/contact/components/models/Proposal/ForRent/Parking/ForRentParkingFinancialInfoDto";
import { ValidationNumberField } from "routes/app/contacts/contact/components/Profiles/components/ValidationNumberField";
import { ProfileInputProps } from "routes/app/contacts/contact/components/Profiles/InputProps";
import { Maybe } from "types/utilityTypes";
import { formatPrix } from "utils/formatage";

type ParkingForRentValidation = {
  [P in keyof ForRentParkingFinancialInfo]: boolean;
};

const rentIncludingCharges = (
  rentExcludingCharges?: number,
  charges?: number
) => {
  if (!rentExcludingCharges || !charges) return "";

  const total = Math.round(charges + rentExcludingCharges);
  return formatPrix(total);
};

export const ParkingForRentBudgetInput: React.FC<ProfileInputProps<
  ForRentParkingFinancialInfo
>> = ({ pristine, value, onChange, more, disabled = false }) => {
  const [validity, setValidity] = useState<ParkingForRentValidation>({
    charges: true,
    rentChargesExcluded: true,
  });

  const handleChange = (key: keyof ForRentParkingFinancialInfo) => (
    isValid: boolean,
    changed: Maybe<number>
  ) => {
    const nextValidity = { ...validity, [key]: isValid };
    const nextIsValid = Object.values(validity).every((_) => _);
    setValidity((prev) => nextValidity);
    onChange({ ...value, [key]: changed }, nextIsValid);
  };

  return (
    <>
      <SectionTitle title={"Quel est le prix de vente?"} />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ValidationNumberField
            disabled={disabled}
            pristine={pristine}
            label={"Loyer mensuel HC"}
            onChange={handleChange("rentChargesExcluded")}
            required={true}
            value={value.rentChargesExcluded}
            adornement={"€"}
            minLimit={1}
          />
        </Grid>
        <Grid item xs={6}>
          <ValidationNumberField
            disabled={disabled}
            pristine={pristine}
            label={"Provision mensuelle de charges"}
            onChange={handleChange("charges")}
            required={true}
            value={value.charges}
            adornement={"€"}
            minLimit={1}
          />
        </Grid>
      </Grid>
      <Typography variant={"body2"}>
        {"Loyer mensuel TTC :\u00a0"}
        <span>
          {" "}
          {rentIncludingCharges(value.rentChargesExcluded, value.charges)}
        </span>
      </Typography>
    </>
  );
};
