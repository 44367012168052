import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import useNotifier from "components/Notifier/useNotifier";
import { range } from "lodash";
import React, {
  FC,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useServices } from "services";
import { useProfiles } from "services/profiles";

import { AccountType } from "../../../../models/accountType";
import { CheckedCriteria } from "../../../../models/Search/purchaseHouseCriteriaDto";
import { PurchaseHouseProfileReadDto } from "../../../../models/Search/purchaseHouseProfileReadDto";
import { PurchaseHouseProfileWriteDto } from "../../../../models/Search/purchaseHouseProfileWriteDto";
import {
  SectionTitle,
  SubSectionTitle,
} from "../../../components/SectionTitle";
import { SelectMaturity } from "../../../Maturity";
import { NotesInput } from "../../../Notes";
import { SelectPLaces } from "../../../Places";
import { SelectMin } from "../../../SelectMin";
import { ProfileProps } from "../../../types";
import { Write } from "../../../Write";
import { MatchingCriteria } from "../../MatchingCriteria";
import { CheckBoxDescription, FormValidity } from "../../shared/types";
import { SizeInput } from "../../SizeInput";
import { WriteMatchingNumber } from "../../WriteMatchingNumber";
import { PurchaseBudgetInput } from "../PurchaseBudget";
import { SelectPropertyAge } from "../SelectPropertyAge";
import { SelectYearOfConstruction } from "../SelectYearOfConstruction";
import { useChanges } from "./changeHandler";
import { CriteriaCheckBoxes } from "./CriteriaCheckBoxes";
import classes from "./Write.module.css";

export const SearchPurchaseHouseProfileWrite: FC<ProfileProps<
  PurchaseHouseProfileReadDto
>> = ({
  contactId,
  account,
  initial,
  more,
  showMore,
  sendUpdatedProfile,
  handleAfterUpdate,
  children,
}) => {
  const { add, update } = useProfiles(contactId);
  const [payload, setPayload] = useState(create(account, initial));
  const [state, setState] = useState<"none" | "saving" | "saved" | "error">(
    "none"
  );
  const { modal } = useServices();
  const { showSnack } = useNotifier();
  const mode = initial ? "update" : "add";

  const [isFormValid, setIsFormValid] = useState<FormValidity>({
    budget: false,
    homeSize: true,
    lotSize: true,
    places: false,
  });

  const [pristine, setPristine] = useState(mode === "add");
  const disabled = state === "saving";
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    onBudgetChange,
    onCheckedCriteriaChange,
    onConstructionYearsChange,
    onHomeSizeChange,
    onLotSizeChange,
    onMaturityChange,
    onMinBedRoomsChange,
    onMinRoomsChange,
    onNotesChange,
    onPlacesChange,
    onPropertyAgeChange,
  } = useChanges(setPayload, setIsFormValid);
  useEffect(() => {
    switch (state) {
      case "none":
        break;
      case "saving":
        if (Object.entries(isFormValid).some(([_, b]) => !b)) {
          setPristine(false);
          setState("none");
        } else {
          (mode === "add"
            ? add("PurchaseHouseProfile", payload)
            : update(
                "PurchaseHouseProfile",
                initial!.id,
                payload,
                sendUpdatedProfile
              )
          )
            .then(() => {
              if (handleAfterUpdate) handleAfterUpdate();
              setState("saved");
            })
            .catch(() => setState("error"));
        }
        break;
      case "saved":
        modal.close();
        showSnack({ message: "Le profil est enregistré.", variant: "success" });
        break;
      case "error":
        showSnack({ message: "Une erreur est survenue.", variant: "error" });
        break;
    }
  }, [state]);

  const criteria: MatchingCriteria = useMemo(
    () => ({
      account: payload.account,
      business: "Purchase",
      familleBiens: "Maison",
      minBedrooms: payload.criteria.minBedrooms,
      minRooms: payload.criteria.minRooms,
      places: payload.criteria.places,
      prixMax: payload.criteria.budget.maxMatching,
      prixMin: payload.criteria.budget.min,
      purchaseConstructionYears: payload.criteria.constructionYears,
      surfaceHabitableMax: payload.criteria.homeSize.max,
      surfaceHabitableMin: payload.criteria.homeSize.min,
      withElevator: payload.criteria.withElevator,
      withFiber: payload.criteria.withFiber,
      withParking: payload.criteria.withParking,
      withPool: payload.criteria.withPool,
      withSingleStorey: payload.criteria.withSingleStorey,
    }),
    [
      payload.account,
      payload.criteria.budget.maxMatching,
      payload.criteria.budget.min,
      payload.criteria.homeSize.min,
      payload.criteria.homeSize.max,
      payload.criteria.constructionYears,
      payload.criteria.minRooms,
      payload.criteria.minBedrooms,
      payload.criteria.places,
      payload.criteria.withElevator,
      payload.criteria.withSingleStorey,
      payload.criteria.withParking,
      payload.criteria.withPool,
      payload.criteria.withFiber,
    ]
  );

  const handleCheckedCriteria: (key: CheckedCriteria) => MouseEventHandler = (
    key
  ) => () => {
    onCheckedCriteriaChange(key);
  };

  const checkBoxes: CheckBoxDescription<CheckedCriteria>[] = [
    { criteriaKey: "withElevator", label: "Ascenseur obligatoire" },
    { criteriaKey: "withSingleStorey", label: "Plain-Pied" },
    { criteriaKey: "withParking", label: "Stationnement" },
    { criteriaKey: "withPool", label: "Piscine" },
    { criteriaKey: "withFiber", label: "Fibre" },
  ];

  return (
    <Write
      pending={state === "saving"}
      showMore={showMore}
      more={more}
      save={() => setState("saving")}
      mode={mode}
      matching={<WriteMatchingNumber criteria={criteria} />}
    >
      <div className={more && !mediaQuery ? classes.container : ""}>
        <div className={more ? classes.itemA : ""}>
          {children}

          <Grid item xs={12}>
            <SelectPropertyAge
              value={payload.criteria.propertyAge}
              onChange={onPropertyAgeChange}
              disabled={disabled}
            />
            {more && (
              <SelectYearOfConstruction
                values={payload.criteria.constructionYears}
                onChange={onConstructionYearsChange}
              />
            )}
          </Grid>
        </div>

        <div className={more ? classes.itemB : ""}>
          <Grid item xs={12}>
            <SelectPLaces
              pristine={pristine}
              value={payload.criteria.places}
              onChange={onPlacesChange}
            />
          </Grid>

          <Grid item xs={12}>
            <PurchaseBudgetInput
              pristine={pristine}
              value={payload.criteria.budget}
              onChange={onBudgetChange}
            />
          </Grid>
          {more && (
            <>
              <Grid item xs={12}>
                <SectionTitle
                  title={"Informations facultatives"}
                  mode="non obligatoire"
                />
              </Grid>

              <Grid item xs={12}>
                <SubSectionTitle
                  title={"Surface habitable"}
                  mode="non obligatoire"
                />
                <SizeInput
                  pristine={pristine}
                  value={payload.criteria.homeSize}
                  onChange={onHomeSizeChange}
                />
              </Grid>

              <Grid item xs={12}>
                <SubSectionTitle
                  title={"Surface terrain"}
                  mode="non obligatoire"
                />
                <SizeInput
                  pristine={pristine}
                  value={payload.criteria.lotSize}
                  onChange={onLotSizeChange}
                />
              </Grid>
            </>
          )}
        </div>
        <div className={more ? classes.itemC : ""}>
          {more && (
            <>
              <Grid item xs={12}>
                <SelectMin
                  disabled={disabled}
                  value={payload.criteria.minRooms}
                  label={"Nombre de pièces"}
                  options={range(1, 7)}
                  onChange={onMinRoomsChange}
                />
              </Grid>

              <Grid item xs={12}>
                <SelectMin
                  disabled={disabled}
                  value={payload.criteria.minBedrooms}
                  label={"Nombre de chambres"}
                  options={range(1, 7)}
                  onChange={onMinBedRoomsChange}
                />
              </Grid>

              <Grid item xs={12}>
                <SubSectionTitle title={"Autres"} mode={"non obligatoire"} />
              </Grid>
              <CriteriaCheckBoxes
                checkBoxes={checkBoxes}
                handleCheck={handleCheckedCriteria}
                criteria={payload.criteria}
              />
            </>
          )}
          <Grid item xs={12}>
            <NotesInput value={payload.notes} onChange={onNotesChange} />
          </Grid>

          <Grid item xs={12}>
            <SelectMaturity
              onChange={onMaturityChange}
              value={payload.criteria.maturity}
              disabled={disabled}
            />
          </Grid>
        </div>
      </div>
    </Write>
  );
};

function create(
  account: AccountType,
  initial?: PurchaseHouseProfileReadDto
): PurchaseHouseProfileWriteDto {
  return {
    account,
    creationDate: new Date(),
    criteria: initial
      ? initial.criteria
      : {
          budget: { max: 0, maxMatching: 0, min: 0 },
          constructionYears: [],
          homeSize: {},
          lotSize: {},
          maturity: "None",
          minBedrooms: 0,
          minRooms: 0,
          places: [],
          propertyAge: "All",
          withElevator: false,
          withFiber: false,
          withParking: false,
          withPool: false,
          withSingleStorey: false,
        },
    notes: initial?.notes || "",
    origin: initial?.origin || "TXProfile",
    reference: initial?.reference || "",
  };
}
