import React from "react";
import { ProfileReadDto } from "routes/app/contacts/contact/components/models/profilesReadDto";
import { ProfileDetailsMenu } from "UI/features/profiles/organisms/ProfileDetailsMenu";

export const getProfileMenuForArchivedProfile = (
  contactId: string,
  readProfile: ProfileReadDto,
  reloadProfile: () => void
) => [
  <ProfileDetailsMenu
    key={0}
    contactId={contactId}
    profile={
      ({
        ...readProfile,
      } as ProfileReadDto) || ({} as ProfileReadDto)
    }
    handleAfterUpdate={reloadProfile}
  />,
];
