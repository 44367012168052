import moment from "moment";
import { PropertyForSale } from "types";

export const detailsAdapter = (bien: PropertyForSale | any) => {
  const details = {
    aspectsFinanciers: {
      loyerCC: bien.loyerCC,
      prix: bien.prix,
      bienEnCopropriete: undefined,
      chargesCopropriete: undefined,
      nbLotsCopropriete: undefined,
      dateDeVente: "",
    },
    composition: {
      nbChambres: bien.nbChambres,
      nbPieces: bien.nbPieces,
    },
    descriptif: bien.annonceDesc,
    dpeges: {
      dpe: `${bien.dpeLettre} | ${bien.dpeValeur}`,
      ges: `${bien.gesLettre} | ${bien.gesValeur}`,
    },
    general: {
      anneeConstruction:
        bien.anneeConstruction > 0 ? bien.anneeConstruction : "",
      exposition: bien.exposition,
      reference: bien.numMandat,
    },
    surfaces: {
      superficieTerrain: bien.surfaceTerrain,
      surfaceHabitable: bien.surface,
      surfacesInterieures: Object.entries(bien.surfacesInterieures),
    },
    typeBienNom: bien.typeBienNom,
    proximites: {},
    prestations: {},
  };

  if (bien.proximite) details.proximites = Object.entries(bien.proximite);
  if (bien.bienEnCopropriete) {
    details.aspectsFinanciers = {
      ...details.aspectsFinanciers,
      bienEnCopropriete: bien.bienEnCopropriete,
      chargesCopropriete: bien.chargesCopropriete,
      nbLotsCopropriete: bien.nbLotsCopropriete,
    };
  }

  if (bien.prestations) details.prestations = Object.entries(bien.prestations);

  if (bien.dateEffectiveSignature)
    details.aspectsFinanciers.dateDeVente = moment(
      bien.dateEffectiveSignature
    ).format("DD/MM/YYYY");

  return details;
};
