import GridIcon from "@material-ui/icons/AppsOutlined";
import ListIcon from "@material-ui/icons/ViewHeadlineOutlined";
import DisplayModeSelector, {
  DisplayModeItem,
} from "components/DisplayModeSelector";
import { ErrorPanel } from "UI/shared/templates/Panels/ErrorPanel";
import TitrePage from "components/TitrePage";
import { HitsSkeleton } from "layoutContents/components/HitsContainer/HitsSkeleton";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { pigeRoutes } from "router/pigeRoutes";
import { useServices } from "services";
import {
  AdsDefaultSearchLocation,
  AdsDefaultSearchLocationKind,
} from "services/adsDefaultSearchLocations/dto/AdsDefaultSearchLocationsSettingDto";
import { ModalProvider } from "services/modal/provider";
import usePigeData, { DataState } from "services/piges/usePigeData";
import { usePigeSlug } from "services/piges/usePigeSlug";
import { deepEquality } from "utils/objects/deepEquality";
import { PigeGlobalContext } from "..";
import { Locations } from "../../../../../components/RefinementField/types";
import { MoreFiltersProvider } from "../../../../../components/Searchbar/services/MoreFiltersProvider";
import useAdsDefaultSearchLocationsSettingsCenter from "../../../../../services/adsDefaultSearchLocations";
import { FicheBienPige } from "../FicheBienPige";
import {
  DisplayMode,
  initLocations,
  initParams,
  ListState,
} from "../states/list/State";
import { SearchInput } from "./SearchInput";
import { paramsToSlug } from "./SearchInput/slugHandler";
import { SearchResult } from "./SearchResult";
import { PigeContextProvider } from "../contexts/PigeContextProvider";
import styles from "./Pige.module.css";

const displayModes: DisplayModeItem[] = [
  {
    hideOnMobile: true,
    icon: <ListIcon />,
    key: DisplayMode.List,
    label: "Liste",
  },
  {
    hideOnMobile: true,
    icon: <GridIcon />,
    key: DisplayMode.Gallery,
    label: "Galerie",
  },
];

const withKind = (kind: AdsDefaultSearchLocation["kind"]) => (
  location: AdsDefaultSearchLocation
) => location.kind === kind;

const toId = (location: AdsDefaultSearchLocation) => location.id;

const toRefined = (location: AdsDefaultSearchLocation) => ({
  isRefined: true,
  label: location.label,
  value: location.id,
});

const getDisplayedResultsTotal = ({ result }: DataState) => {
  const over10kLabel = "+ de 10000";

  if (!result.total) return 0;

  return result.total > 10000 ? over10kLabel : result.total;
};

export const PigeItemsList: React.FC = () => {
  const {
    state: { selectedAdId, redirectUrl, scrollPosition, slug },
  } = useContext(PigeGlobalContext);

  const previousSelectedAdId = useRef(selectedAdId);

  const history = useHistory();

  const {
    l10n: { t },
  } = useServices();

  const {
    adsDefaultSearchLocations,
  } = useAdsDefaultSearchLocationsSettingsCenter();

  const [state, setState] = useState<ListState>(
    new ListState(slug || "full-list")
  );

  const data = usePigeData(state.params, state.searchEnabled);

  const { clearSlug, getStoredSlug, storeSlug } = usePigeSlug();

  const onLocationChange = React.useCallback(
    ({
      villes = [],
      departements = [],
      regions = [],
    }: Partial<Locations["value"]>) => {
      if (state.params) {
        const nextLocations = ListState.nextLocations(state, {
          departements,
          regions,
          villes,
        });

        setState(
          ListState.next({
            ...state,
            locations: nextLocations,
            paginationCall: false,
          })
        );
      }
    },
    [state.params]
  );

  const onChange = React.useCallback((next) => {
    const hasKeys = Object.keys(next).length > 0;

    if (!hasKeys) {
      clearSlug();
      setState(
        ListState.next({
          locations: initLocations,
          paginationCall: false,
          params: initParams,
        })
      );

      return;
    }

    if (hasKeys && deepEquality(next, initParams)) return;

    const nextParams = ListState.nextParams(state, {
      ...next,
      searchAfter: [],
      ...(hasKeys && next.sortAsc && { sortAsc: next.sortAsc }),
      ...(hasKeys && next.sortColumn && { sortColumn: next.sortColumn }),
    });

    const nextState = {
      paginationCall: false,
      params: nextParams,
    };

    setState(ListState.next(nextState));
  }, []);

  const initializeFromAdsDefaultSearchLocations = () => {
    const kinds: AdsDefaultSearchLocationKind[] = [
      "Departement",
      "Region",
      "Ville",
    ];

    const [departments, regions, cities] = kinds.map((kind) =>
      adsDefaultSearchLocations.filter(withKind(kind))
    );

    onLocationChange({
      departements: departments.map(toRefined),
      regions: regions.map(toRefined),
      villes: cities.map(toRefined),
    });

    const cityIds = cities.map(toId);
    const regionIds = regions.map(toId);
    const departmentIds = departments.map(toId);

    const params = {
      ...initParams,
      ...state.params,
      ...(departmentIds.length > 0 && { departements: departmentIds }),
      ...(cityIds.length > 0 && { villes: cityIds }),
      ...(regionIds.length > 0 && { regions: regionIds }),
    };

    setState(
      ListState.next({
        params,
        searchEnabled: true,
      })
    );
  };

  const initializeFromSlug = (initialSlug: string) => {
    setState(
      ListState.next({
        ...new ListState(initialSlug),
        searchEnabled: true,
      })
    );
  };

  useEffect(() => {
    if (deepEquality(state.params, initParams)) return;

    const nextSlug = paramsToSlug(state.params, state.locations);
    storeSlug(nextSlug);

    history.push(pigeRoutes.onList(nextSlug, selectedAdId, redirectUrl));
  }, [state.params]);

  useEffect(() => {
    if (selectedAdId === previousSelectedAdId.current) return;
    previousSelectedAdId.current = selectedAdId;

    if (!selectedAdId) {
      window.scrollTo({ behavior: "auto", top: scrollPosition });
    }

    history.push(
      pigeRoutes.onList(slug || "full-list", selectedAdId, redirectUrl)
    );
  }, [selectedAdId]);

  useEffect(() => {
    const storedSlug = getStoredSlug();

    switch (true) {
      case slug && slug !== "full-list":
        initializeFromSlug(slug!);
        return;
      case storedSlug && storedSlug !== "full-list":
        initializeFromSlug(storedSlug!);
        return;
      default:
        initializeFromAdsDefaultSearchLocations();
        return;
    }
  }, [slug, adsDefaultSearchLocations]);

  if (data.hasError)
    return <ErrorPanel text="Le service est indisponible" title="Pige" />;

  const hasNextPage = data.items.length < (data.result.total || 0);

  const loadMore = () => {
    const params = ListState.nextParams(state, {
      searchAfter: data.result.count === 0 ? [] : data.result?.searchAfter,
    });

    setState(ListState.next({ paginationCall: true, params }));
  };

  const onClear = () => {
    onChange({});
  };

  const displayModeHandler = (mode: string) => {
    setState(ListState.next({ currentDisplayMode: mode }));
  };

  const resultsTotal = getDisplayedResultsTotal(data);

  if (selectedAdId) return <FicheBienPige />;

  return (
    <MoreFiltersProvider>
      <PigeContextProvider>
        <div>
          <div>
            <TitrePage
              categorie={t("Biens")}
              page={t("Pige")}
              nbResultats={`${resultsTotal} ${t("résultat(s)")}`}
              filePath={"/static/markdowns/biensPige.md"}
              Composant={
                (() => (
                  <DisplayModeSelector
                    onChange={displayModeHandler}
                    selected={String(state.currentDisplayMode)}
                    items={displayModes}
                  />
                )) as () => any
              }
            />
          </div>

          <SearchInput
            className={styles.searchInput}
            params={state.params}
            locations={state.locations}
            result={data.result}
            onChange={onChange}
            onLocationChange={onLocationChange}
          />
          {data.pending && !state.paginationCall ? (
            HitsSkeleton(1, "list")
          ) : (
            <ModalProvider>
              <SearchResult
                hiddenIncluded={!!state.params.masqueesIncluses}
                favoritesOnly={!!state.params.favorisUniquement}
                items={data.items}
                hasNextPage={hasNextPage}
                loadMore={loadMore}
                onClear={onClear}
                forceGallery={state.currentDisplayMode === DisplayMode.Gallery}
              />
            </ModalProvider>
          )}
        </div>
      </PigeContextProvider>
    </MoreFiltersProvider>
  );
};
