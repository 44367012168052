import { Paper, Typography } from "@material-ui/core";
import Notes from "UI/shared/atoms/Notes";
import { DetailedEntityTabsLayout } from "components/Fiche/DetailedEntityTabsLayout";
import TitrePage from "components/TitrePage";
import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import {
  BienNotes,
  useBienNotes,
} from "services/rapprochement/Rapprochement/BienNotes/useBienNotes";
import obtenirParIdAdapter from "services/rapprochement/Rapprochement/BiensEnVente/ObtenirParId/obtenirParIdAdapter";
import { breakpoint } from "theme/main";
import BoutonImpression from "UI/shared/molecules/Buttons/BoutonImpression";
import BienCarteReduite from "layoutContents/Fiches/components/BienCarteReduite";
import BienConseiller from "layoutContents/Fiches/components/BienConseiller";
import Details from "layoutContents/Fiches/components/Details";
import PrintablePropertyDetails from "layoutContents/Fiches/components/PrintablePropertyDetails";
import { useServices } from "services";
import { useTxHttp } from "services/http";
import { networkRealEstatesRoutes } from "router/networkRealEstatesRoutes";

const etiquetteCarte = (
  <Paper
    elevation={1}
    style={{
      background: "white",
      padding: 10,
    }}
  >
    <Typography
      variant="h4"
      style={{
        color: "#303d4a",
      }}
    >
      Le bien se situe dans cette zone.
    </Typography>
  </Paper>
);

export const BienEnVente: React.FC = () => {
  const { id, display } = useParams<{
    id: string;
    display: "view" | "print";
  }>();

  const { get } = useTxHttp();
  const {
    loadBienNotes,
    handleDeleteNote,
    handleEditNotes,
    getDefaultNotes,
  } = useBienNotes();
  const [bienNotes, setBienNotes] = React.useState<BienNotes>(
    getDefaultNotes()
  );
  const [bien, setBien] = React.useState(obtenirParIdAdapter());
  const {
    endpoints,
    l10n: { t },
  } = useServices();
  const componentToPrintRef = React.useRef(null);

  const history = useHistory();

  const handleBackButtonAction = () => {
    history.push(networkRealEstatesRoutes.list);
  };

  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  const onHandleDeleteNote = useCallback(() => {
    handleDeleteNote(bienNotes).then((notes) => {
      setBienNotes(notes);
    });
  }, [bienNotes]);

  const onHandleEditNotes = useCallback(
    (notes: string) => {
      handleEditNotes({ ...bienNotes, Notes: notes }).then((notes) => {
        setBienNotes(notes);
      });
    },
    [bienNotes]
  );

  React.useEffect(() => {
    const url = endpoints.bienEnVente.rechercheParId;
    get<any>(url, { id })
      .then((response) => {
        setBien(obtenirParIdAdapter(response.data));
        loadBienNotes(response.data.codeAffaire).then((notes) =>
          setBienNotes(notes)
        );
        if (display === "print" && handlePrint) {
          handlePrint();
        }
      })
      .catch((error) => {
        setBien(obtenirParIdAdapter({ id: -1 }));
      });
  }, [id, display]);

  if (!bien) return <Typography variant="h2">Non Disponible.</Typography>;
  if (bien.id === "") return <Typography variant="h2">Loading ...</Typography>;

  const titreSurface = bien.surface > 0 ? ` ${bien.surface} m\xB2 ` : "";
  const titreVille = ` ${t("à")} ${bien.localisation.villeNom}`;
  const titre = `${bien.typeAffaireEnum} ${t(
    bien.familleBienEnum
  )}${titreSurface}${titreVille}`;

  const tabs = [
    {
      content: <Details bien={bien} t={t} />,
      titre: breakpoint().smAndDown ? t("DET.") : t("DETAILS"),
      value: "details",
    },
  ];

  const actions =
    handlePrint !== null
      ? [<BoutonImpression key={0} onPrint={handlePrint} />]
      : [];

  return (
    <>
      <PrintablePropertyDetails
        bien={bien}
        componentToPrintRef={componentToPrintRef}
        titre={
          <TitrePage
            categorie="Biens | Biens Disponibles"
            page={"Fiche"}
            nbResultats=""
          />
        }
      />
      <DetailedEntityTabsLayout
        composantsBarreGauche={[
          {
            composant: (
              <BienCarteReduite
                bien={bien}
                mapMode={"diffus"}
                mapPointLabel={etiquetteCarte}
                shouldDisplayCardActions
              />
            ),
          },
          {
            composant: <div style={{ marginBottom: 20 }} />,
          },
          {
            composant: (
              <BienConseiller
                bien={bien}
                t={t}
                source={"Fiche Bien En Vente"}
              />
            ),
          },
          {
            composant: <div style={{ marginBottom: 20 }} />,
          },
          {
            composant: (
              <Notes
                notes={bienNotes.Notes}
                editNotes={onHandleEditNotes}
                deleteNotes={onHandleDeleteNote}
                t={t}
              />
            ),
          },
        ]}
        titre={
          <TitrePage
            categorie="Biens | Biens Disponibles"
            page={titre}
            nbResultats=""
            filePath={"/static/markdowns/ficheBienEnVente.md"}
          />
        }
        tabs={tabs}
        actions={actions}
        tabActiveIndex={0}
        onClose={handleBackButtonAction}
      />
    </>
  );
};
