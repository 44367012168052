import {
  DefaultElasticAdapter,
  ElasticAdapter,
} from "api/DTOs/Common/ElasticSearch/ElasticAdapters";
import { useCallback, useState } from "react";
import {
  isSellerProfile,
  ProfileReadDto,
  SellerReadProfileDto,
} from "routes/app/contacts/contact/components/models/profilesReadDto";
import { SaleApartmentProfileReadDto } from "routes/app/contacts/contact/components/models/Proposal/ForSale/Apartment/saleApartmentProfileReadDto";
import { SaleBuildingProfileReadDto } from "routes/app/contacts/contact/components/models/Proposal/ForSale/Building/saleBuildingProfileReadDto";
import { SaleHouseProfileReadDto } from "routes/app/contacts/contact/components/models/Proposal/ForSale/House/saleHouseProfileReadDto";
import { SaleLandProfileReadDto } from "routes/app/contacts/contact/components/models/Proposal/ForSale/Land/saleLandProfileReadDto";
import { SaleParkingProfileReadDto } from "routes/app/contacts/contact/components/models/Proposal/ForSale/Parking/saleParkingProfileReadDto";
import { useAcquirerProfilesMatching } from "services/matching/useAcquirerProfilesMatching";
import { NetworkMatchingProfilePresenter } from "services/matching/NetworkMatchingProfilePresenter";
import { BuyerMatchingProfilePresenter } from "services/matching/BuyerMatchingProfilePresenter";
import { MatchingProfileRequestDto } from "services/matching/MatchingProfileRequestDto";

import { SalePremisesProfileReadDto } from "../../contacts/contact/components/models/Proposal/ForSale/Premises/salePremisesProfileReadDto";

export const useMatchingProfiles = (
  property: ProfileReadDto | undefined
): {
  consultantBuyers: ElasticAdapter<BuyerMatchingProfilePresenter>;
  handleConsultantBuyersChange: (pageRank: number) => Promise<void>;
  handleNetworkBuyersChange: (pageRank: number) => Promise<void>;
  loadMatchingContacts: (profile: ProfileReadDto | undefined) => () => void;
  networkBuyers: ElasticAdapter<NetworkMatchingProfilePresenter>;
} => {
  const {
    getConsultantRealEstate,
    getNetworkRealEstate,
  } = useAcquirerProfilesMatching();
  const PAGE_SIZE_CONSULTANT_BUYERS = 6;
  const PAGE_SIZE_NETWORK_BUYERS = 6;
  const [state, setState] = useState<{
    consultantBuyers: ElasticAdapter<BuyerMatchingProfilePresenter>;
    networkBuyers: ElasticAdapter<NetworkMatchingProfilePresenter>;
  }>({
    consultantBuyers: DefaultElasticAdapter,
    networkBuyers: DefaultElasticAdapter,
  });

  const toMatchingProfileRequestPayload = (
    profile: SellerReadProfileDto
  ): MatchingProfileRequestDto | undefined => {
    switch (profile.kind) {
      case "SaleApartmentProfile": {
        const apartment: SaleApartmentProfileReadDto = profile;
        return new MatchingProfileRequestDto({
          budget: apartment.features.financialInfo.price,
          cityId: apartment.features.address.city?.id,
          constructionYear:
            apartment.features.propertyInfo.buildingInfo.yearBuilt,
          nbRooms: apartment.features.parts.summaries.rooms?.count,
          profileKind: "Purchase",
          propertyKind: "Appartement",
          surface: apartment.features.propertyInfo.squareMeter,
          withElevator: apartment.features.amenities?.some(
            (a) => a === "Elevator"
          ),
          withParking:
            (apartment.features.parts.summaries.parkings?.count ?? 0) > 0,
        });
      }

      case "SaleBuildingProfile": {
        const building: SaleBuildingProfileReadDto = profile;
        return new MatchingProfileRequestDto({
          budget: building.features.financialInfo.price,
          cityId: building.features.address.city?.id,
          constructionYear:
            building.features.buildingPropertyInfo.constructionYear,
          profileKind: "Purchase",
          propertyKind: "Building",
          surface: building.features.buildingPropertyInfo.squareMeter,
          withElevator: building.features.amenities?.some(
            (a) => a === "Elevator"
          ),
          withParking: (building.features.parkingInfo.count ?? 0) > 0,
        });
      }

      case "SaleHouseProfile": {
        const house: SaleHouseProfileReadDto = profile;

        return new MatchingProfileRequestDto({
          budget: house.features.financialInfo.price,
          cityId: house.features.address.city?.id,
          constructionYear: house.features.propertyInfo.buildingInfo.yearBuilt,
          profileKind: "Purchase",
          propertyKind: "House",
          surface: house.features.propertyInfo.squareMeter,
          withElevator: house.features.amenities?.some((a) => a === "Elevator"),
          withParking: (house.features.parts.summaries.parkings.count ?? 0) > 0,
          withPool: house.features.amenities?.some((a) => a === "Pool"),
        });
      }

      case "SaleParkingProfile": {
        const parking: SaleParkingProfileReadDto = profile;
        return new MatchingProfileRequestDto({
          budget: parking.features.financialInfo.price,
          cityId: parking.features.address.city?.id,
          profileKind: "Purchase",
          propertyKind: "Parking",
          surface: parking.features.parkingPropertyInfo.squareMeter,
        });
      }
      case "SaleLandProfile": {
        const land: SaleLandProfileReadDto = profile;
        return new MatchingProfileRequestDto({
          budget: land.features.financialInfo.price,
          cityId: land.features.address.city?.id,
          profileKind: "Purchase",
          propertyKind: "Land",
          surface: land.features.landPropertyInfo.squareMeter,
        });
      }

      case "SalePremisesProfile": {
        const premises: SalePremisesProfileReadDto = profile;
        return new MatchingProfileRequestDto({
          budget: premises.features.financialInfo.price,
          cityId: premises.features.address.city?.id,
          profileKind: "Purchase",
          propertyKind: "Premises",
          surface: premises.features.propertyInfo.totalArea,
        });
      }

      default:
        return undefined;
    }
  };

  const handleNetworkBuyersChange = useCallback(
    async (pageRank: number) => {
      if (isSellerProfile(property) && property?.id) {
        const sellerProfile = property as SellerReadProfileDto;
        const payload = toMatchingProfileRequestPayload(sellerProfile);
        if (payload) {
          try {
            const response = await getNetworkRealEstate(
              payload,
              pageRank,
              PAGE_SIZE_NETWORK_BUYERS
            );
            setState((prev) => {
              return {
                ...prev,
                networkBuyers: response.data,
              };
            });
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
          }
        }
      }
    },
    [getNetworkRealEstate, property]
  );

  const handleConsultantBuyersChange = useCallback(
    async (pageRank: number) => {
      if (isSellerProfile(property) && property?.id) {
        const sellerProfile = property as SellerReadProfileDto;
        const payload = toMatchingProfileRequestPayload(sellerProfile);
        if (payload) {
          try {
            const response = await getConsultantRealEstate(
              payload,
              pageRank,
              PAGE_SIZE_CONSULTANT_BUYERS
            );
            setState((prev) => {
              return {
                ...prev,
                consultantBuyers: response.data,
              };
            });
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
          }
        }
      }
    },
    [getConsultantRealEstate, property]
  );

  const loadMatchingContacts = useCallback(
    (profile: ProfileReadDto | undefined) => () => {
      if (profile) {
        handleConsultantBuyersChange(1);
        handleNetworkBuyersChange(1);
      }
    },
    [handleConsultantBuyersChange, handleNetworkBuyersChange]
  );

  return {
    consultantBuyers: state.consultantBuyers,
    handleConsultantBuyersChange,
    handleNetworkBuyersChange,
    loadMatchingContacts,
    networkBuyers: state.networkBuyers,
  };
};
