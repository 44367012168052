import { Typography } from "@material-ui/core";
import React from "react";

export const SectionTitle: React.FC<{
  title: string;
  mention?: string;
  mode?: "obligatoire" | "non obligatoire";
  withoutPadding?: boolean;
}> = (props) => {
  const {
    title,
    mode = "obligatoire",
    withoutPadding = false,
    mention = "",
  } = props;

  const mutualStyle = {
    paddingBottom: 10,
    paddingTop: withoutPadding ? 0 : 20,
    width: "100%",
  };

  const style =
    mode === "non obligatoire"
      ? {
          ...mutualStyle,
          color: "#8e8e8e",
        }
      : mutualStyle;

  return (
    <div style={style}>
      <Typography variant="h4">
        {title} <span style={{ fontWeight: "normal" }}>{mention}</span>
      </Typography>
    </div>
  );
};

export const SubSectionTitle: React.FC<{
  title: string;
  mode?: "obligatoire" | "non obligatoire";
  withoutPadding?: boolean;
}> = (props) => {
  const { title, mode = "obligatoire" } = props;

  let style = {
    color: "inherit",
    paddingBottom: 5,
    paddingTop: 15,
  };
  if (mode === "non obligatoire") {
    style = { ...style, color: "#8e8e8e" };
  }

  return (
    <Typography variant="h6" style={style}>
      {title}
    </Typography>
  );
};
