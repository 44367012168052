import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { range } from "../../utils/ArrayHelper";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import Right from "@material-ui/icons/ChevronRight";
import Left from "@material-ui/icons/ChevronLeft";
import MoreHoriz from "@material-ui/icons/MoreHoriz";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "white",
    boxShadow: "none",
    height: "30px",
    marginLeft: "2px",
    marginRight: "2px",
    minWidth: "30px",
    width: "30px",
  },
  colorBeige: {
    color: theme.palette.beige,
    cursoir: "pointer",
  },
  container: {
    alignItems: "center",
    display: "flex",
    justifyContent: (props) => props.align,
  },
  firstButton: {
    marginLeft: 0,
  },
  lastButton: {
    marginRight: 0,
  },
}));

const StandardButton = ({
  children,
  disabled,
  onClick,
  hasActiveStyle = null,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Button
      className={classes.button}
      disabled={disabled}
      variant="contained"
      size="small"
      classes={{ root: classes.colorBeige }}
      onClick={onClick}
      style={
        hasActiveStyle !== null
          ? hasActiveStyle
            ? {
                backgroundColor: theme.palette.default,
                boxShadow:
                  "0px 2px 2px 0px rgba(0,0,0,0.3), 0px 1px 1px -1px rgba(0,0,0,0.3)",
                color: "white",
              }
            : { backgroundColor: "white" }
          : {}
      }
    >
      {children}
    </Button>
  );
};

const defaultPagesNumberButtons = ({ nbPages, maxOnScreen }) =>
  nbPages === 1
    ? [1]
    : range(1, nbPages >= maxOnScreen ? maxOnScreen : nbPages);

const getPagesNumberButtons = ({ page, nbPages, maxOnScreen }) => {
  const half = (maxOnScreen - 1) / 2;

  if (page + half > nbPages) {
    return range(nbPages - maxOnScreen + 1, nbPages);
  } else if (page - half < 1) {
    return defaultPagesNumberButtons({ maxOnScreen, nbPages });
  } else {
    return range(page - half, page + half);
  }
};

const PaginationX = ({
  page,
  nbPages,
  onChange,
  translations,
  maxOnScreen,
  align,
}) => {
  const classes = useStyles({ align });
  // TODO: this check should be made outside of this component as this component should only exist if we have pagination...
  const hasPagination = nbPages > 1;
  const hasNavigation = nbPages > 2;

  const handlePreviousPageButtonClick = () => {
    onChange(page - 1);
  };
  const handleNextPageButtonClick = () => {
    onChange(page + 1);
  };
  const handleFirstPageButtonClick = () => {
    onChange(1);
  };
  const handleLastPageButtonClick = () => {
    onChange(nbPages);
  };

  let pagesNumberButtons = defaultPagesNumberButtons({
    maxOnScreen,
    nbPages,
  });

  if (
    (!pagesNumberButtons.includes(page + 1) && page !== nbPages) ||
    (!pagesNumberButtons.includes(page - 1) && page !== 1)
  ) {
    pagesNumberButtons = getPagesNumberButtons({
      maxOnScreen,
      nbPages,
      page,
    });
  }

  return (
    <>
      {hasPagination && (
        <div className={classes.container}>
          {hasNavigation && (
            <StandardButton
              disabled={page === 1}
              onClick={handlePreviousPageButtonClick}
            >
              {translations.previous}
            </StandardButton>
          )}

          {!pagesNumberButtons.includes(1) && (
            <>
              <StandardButton onClick={handleFirstPageButtonClick}>
                1
              </StandardButton>

              <MoreHoriz classes={{ root: classes.colorBeige }} />
            </>
          )}

          {pagesNumberButtons.map((item) => (
            <StandardButton
              key={item}
              onClick={() => onChange(item)}
              hasActiveStyle={item === page}
            >
              {item}
            </StandardButton>
          ))}

          {!pagesNumberButtons.includes(nbPages) && (
            <>
              {hasNavigation && (
                <MoreHoriz classes={{ root: classes.colorBeige }} />
              )}

              <StandardButton
                onClick={handleLastPageButtonClick}
                hasActiveStyle={!hasNavigation && nbPages === page}
              >
                {nbPages}
              </StandardButton>
            </>
          )}

          {hasNavigation && (
            <StandardButton
              disabled={page === nbPages}
              onClick={handleNextPageButtonClick}
            >
              {translations.next}
            </StandardButton>
          )}
        </div>
      )}
    </>
  );
};

PaginationX.propTypes = {
  align: PropTypes.string,
  maxOnScreen: PropTypes.number,
  nbPages: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  page: PropTypes.number,
  translations: PropTypes.object,
};
PaginationX.defaultProps = {
  align: "center",
  maxOnScreen: 9,
  translations: {
    first: "«",
    last: "»",
    next: <Right />,
    previous: <Left />,
  },
};

export default PaginationX;
