import {
  isMultipleText,
  resetMultipleText,
} from "utils/adaptedRefinements/multipleText";
import { AdaptedRefinements } from "../../../components/RefinementField/types";
import { RequestForInformationsCriteria } from "../../../types";
import { initialState } from "../constants/initialState";

const getCriteriaKeys = (
  adaptedRefinements: AdaptedRefinements<RequestForInformationsCriteria>
) => <RequestForInformationsCriteria[]>Object.keys(adaptedRefinements);
export const clearRefinements = (
  adaptedRefinements: AdaptedRefinements<RequestForInformationsCriteria>
): AdaptedRefinements<RequestForInformationsCriteria> => {
  const criteriaKeys = getCriteriaKeys(adaptedRefinements);
  return criteriaKeys.reduce((current, criteria) => {
    if (isMultipleText(adaptedRefinements, criteria))
      return resetMultipleText(adaptedRefinements, current, criteria);

    return {
      ...current,
      [criteria]: initialState[criteria],
    };
  }, {}) as AdaptedRefinements<RequestForInformationsCriteria>;
};
