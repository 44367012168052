import { InfiniteScrollList } from "UI/shared/organisms/InfiniteScrollList/InfiniteScrollList";
import { RealEstatePropertyCard } from "UI/features/realEstates/organisms/RealEstatePropertyCard";
import React, { useContext } from "react";
import { NetworkRealEstatesContext } from ".";

const SCROLL_CONTAINER = "network-matchings-small-infinite-scroll-view";

export const NetworkRealEstatesSmall: React.FC = () => {
  const { realEstates = [], loadMore } = useContext(NetworkRealEstatesContext);
  return (
    <InfiniteScrollList
      pageSize={10}
      loadMore={loadMore}
      horizontal={true}
      containerId={SCROLL_CONTAINER}
    >
      {realEstates.map((realEstate) => (
        <div key={realEstate.id} style={{ margin: 5, minWidth: 300 }}>
          <RealEstatePropertyCard {...realEstate} />
        </div>
      ))}
    </InfiniteScrollList>
  );
};
