import React from "react";

import { RadioChip } from "UI/shared/molecules/RadioChip";
import { PremisesLocationAttractiveness } from "../../../../models/Proposal/ForSale/Premises/PremisesLocationAttractiveness";
import { SubSectionTitle } from "../../../components/SectionTitle";

export const PremisesLocationAttractivenessSelection: React.FC<{
  pristine?: boolean;
  value?: PremisesLocationAttractiveness;
  onChange: (isValid: boolean, value: PremisesLocationAttractiveness) => void;
}> = ({ value, onChange, pristine = false }) => {
  const options: PremisesLocationAttractiveness[] = [
    "Zone1",
    "Zone1Bis",
    "Zone2",
  ];

  return (
    <>
      <SubSectionTitle title="Attractivité de l'emplacement* :" />
      <RadioChip
        translationPrefix={"premisesLocationAttractiveness"}
        options={options}
        pristine={pristine}
        required={true}
        value={value}
        onChange={(newValue) => onChange(true, newValue)}
      />
    </>
  );
};
