import { Display, PigeGlobalAction, PigeState, TabName } from "./index";
export const selectAdId: (id: string) => PigeGlobalAction = (id) => ({
  type: "SELECT_AD_ID",
  payload: id,
});

export const selectDisplay: (display: Display) => PigeGlobalAction = (
  display
) => ({
  type: "SELECT_DISPLAY",
  payload: display,
});

export const selectTab: (tab: TabName) => PigeGlobalAction = (tab) => ({
  type: "SELECT_TAB",
  payload: tab,
});

export const saveScrollPosition: (y: number) => PigeGlobalAction = (y) => ({
  type: "SAVE_SCROLL_POSITION",
  payload: y,
});

export const backToList: () => PigeGlobalAction = () => ({
  type: "BACK_TO_LIST",
  payload: undefined,
});

export const updateSlug: (slug: string) => PigeGlobalAction = (slug) => ({
  type: "UPDATE_SLUG",
  payload: slug,
});

export const updateRedirectUrl: (url: string) => PigeGlobalAction = (url) => ({
  type: "UPDATE_REDIRECT_URL",
  payload: url,
});

export const multipleUpdates: (
  payload: Partial<PigeState>
) => PigeGlobalAction = (payload) => ({
  type: "MULTIPLE_UPDATES",
  payload,
});
