/* eslint-disable react/no-unescaped-entities */
import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetAppOutlined";
import createVCard from "UI/shared/molecules/Buttons/BoutonVCard/createVCard";
import saveAs from "file-saver";
import QRCode from "qrcode.react";
import React from "react";

import TitreSection from "../../../../../../../layoutContents/Fiches/components/Details/components/TitreSection";

const QRCodeProfil = ({ profil }) => {
  const theme = useTheme();

  const adaptedData = {
    birthday: "",
    facebook: profil.lienFacebook,
    firstName: profil.prenom,
    lastName: profil.nom,
    middleName: "",
    namePrefix: profil.civilite,
    note:
      profil.specialites && Array.isArray(profil.specialites)
        ? profil.specialites.join(", ")
        : "",
    organization: profil.bu,
    title: profil.roles.join(", "),
    url: profil.miniSiteUrl,
    urlPhoto: "",
    workEmail: profil.email,
    workPhone: profil.telephone,
  };

  const vCardConseiller = createVCard(adaptedData);

  const mobile = useMediaQuery("(max-width: 600px");

  const telechargerLaVersionImprimable = () => {
    try {
      const canvas = document.getElementById("qrcode-imprimable");
      canvas.toBlob(function (blob) {
        saveAs(blob, "ma-vcard.png");
      });
    } catch (e) {
      console.log("Blob not supported !");
    }
  };
  const vcardTextStyle = {
    fontSize: 9,
  };
  return (
    <div style={{ overflow: "hidden", position: "relative" }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TitreSection titre={"Votre vCard"} />
        </Grid>
        <Grid item xs={12}>
          <div style={vcardTextStyle}>
            1. Flashez le QR Code à l'aide de votre appareil photo mobile ou une
            application de lecture de QR code
          </div>
          <div style={vcardTextStyle}>
            2. Retrouvez les informations de votre profil sur votre téléphone
          </div>
          <div style={vcardTextStyle}>
            3. Enregistrez votre VCard sur votre mobile et partagez la à vos
            clients
          </div>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <QRCode value={vCardConseiller} size={mobile ? 200 : 150} />
            <div
              style={{
                alignItems: "center",
                cursor: "pointer",
                display: "flex",
                marginTop: 20,
              }}
            >
              <GetAppIcon
                style={{
                  color: theme.palette.tonique,
                  fontSize: 16,
                }}
              />
              <Typography
                variant={"h6"}
                onClick={telechargerLaVersionImprimable}
              >
                Télécharger la version imprimable.
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
      <div style={{ left: "200vw", position: "absolute", top: 0 }}>
        <QRCode value={vCardConseiller} size={600} id={"qrcode-imprimable"} />
      </div>
    </div>
  );
};

export default QRCodeProfil;
