import React, { useEffect, useMemo } from "react";

import {
  ScrollToLeftButton,
  ScrollToTopButton,
  useInfiniteScroll,
} from "../../../../services/useInfiniteScrollSR";
import { If } from "../../atoms/directives/If";
import { Container } from "./Container";
import { UnwrappedObservedItem } from "./UnwrappedObservedItem";
import { WrappedObservedItem } from "./WrappedObservedItem";

export const DEFAULT_SCROLL_CONTAINER_ID = "InfiniteScrollView";

interface Props {
  pageSize: number;
  loadMore: () => void;
  horizontal?: boolean;
  inTabs?: boolean;
  containerId?: string;
  wrapItems?: boolean;
}

export const InfiniteScrollList: React.FC<Props> = ({
  children,
  loadMore,
  pageSize,
  containerId = DEFAULT_SCROLL_CONTAINER_ID,
  inTabs = false,
  horizontal = false,
  wrapItems = false,
}) => {
  const {
    firstVisible,
    lastVisible,
    ViewObserverFirst,
    ViewObserverLast,
  } = useInfiniteScroll();

  useEffect(() => {
    if (lastVisible) loadMore();
  }, [lastVisible, loadMore]);

  const last = useMemo(
    () => pageSize * Math.ceil(React.Children.count(children) / pageSize) - 1,
    [children, pageSize]
  );

  return (
    <>
      <Container horizontal={horizontal} id={containerId}>
        {React.Children.map(children, (child, index) => {
          switch (index) {
            case 0:
              return wrapItems
                ? WrappedObservedItem(child, ViewObserverFirst)
                : UnwrappedObservedItem(child, ViewObserverFirst);
            case last:
              return wrapItems
                ? WrappedObservedItem(child, ViewObserverLast)
                : UnwrappedObservedItem(child, ViewObserverLast);
            default:
              return child;
          }
        })}
      </Container>

      <If condition={!firstVisible && horizontal}>
        <ScrollToLeftButton containerId={containerId} />
      </If>
      <If condition={!firstVisible && !horizontal}>
        <ScrollToTopButton inTabs={inTabs} containerId={containerId} />
      </If>
    </>
  );
};
