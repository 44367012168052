import { Typography } from "@material-ui/core";
import React from "react";
import { useTheme } from "@material-ui/core";
import { ChevronRightOutlined } from "@material-ui/icons";

const Index = ({ Icone, entete, voirTout, titreVoirTout }) => {
  const theme = useTheme();
  const tonique = theme.palette.tonique;

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icone
            style={{
              color: tonique,
              fontSize: 23,
              marginRight: 10,
            }}
          />
          <Typography variant={"h2"}>{entete.titre.toUpperCase()}</Typography>
        </div>
        {voirTout && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={voirTout}
          >
            <Typography variant={"h5"}>{titreVoirTout}</Typography>
            <ChevronRightOutlined />
          </div>
        )}
      </div>
      <Typography variant={"body1"}>{entete.sousTitre}</Typography>
    </div>
  );
};

export default Index;
