import { Grid, useTheme } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRangeOutlined";
import moment from "moment";
import React from "react";

import { useServices } from "../../../../../services";
import { ITheme } from "../../../../../theme/ts/main";
import SearchDates from "../../../../components/ContextualSideBar/components/searchDates";
import TitreBloc from "../../../../components/ContextualSideBar/components/titreBloc";

export interface DatesProps {
  mode: string;
  onUpdateRefinements: (queryDate: string, dateValue: string) => void;
  dateVenteMin: string;
  dateVenteMax: string;
  dateMajMin: string;
  dateMajMax: string;
  dateMiseEnVenteMin: string;
  dateMiseEnVenteMax: string;
}

export const Dates: React.FC<DatesProps> = ({
  mode,
  onUpdateRefinements,
  dateMajMax,
  dateMajMin,
  dateMiseEnVenteMax,
  dateMiseEnVenteMin,
  dateVenteMax,
  dateVenteMin,
}) => {
  const theme: ITheme = useTheme();
  const {
    l10n: { t },
  } = useServices();

  let _dateMajMin = "";
  let _dateMajMax = "";
  let _dateMiseEnVenteMin = "";
  let _dateMiseEnVenteMax = "";
  let queryDateMiseEnVenteMin = "";
  let queryDateMiseEnVenteMax = "";
  const queryDateMajMin = "dateMajMin";
  const queryDateMajMax = "dateMajMax";

  if (mode === "biensVendus") {
    _dateMajMin = "2010-01-01";
    _dateMajMax = "2010-01-01";
    _dateMiseEnVenteMin = dateVenteMin;
    _dateMiseEnVenteMax = dateVenteMax;
    queryDateMiseEnVenteMin = "dateVenteMin";
    queryDateMiseEnVenteMax = "dateVenteMax";
  } else {
    _dateMajMin = dateMajMin;
    _dateMajMax = dateMajMax;
    _dateMiseEnVenteMin = dateMiseEnVenteMin;
    _dateMiseEnVenteMax = dateMiseEnVenteMax;
    queryDateMiseEnVenteMin = "dateMiseEnVenteMin";
    queryDateMiseEnVenteMax = "dateMiseEnVenteMax";
  }

  const handleChangeDateMaj = ({
    dateDebut,
    dateFin,
  }: {
    dateDebut: string;
    dateFin: string;
  }) => {
    if (dateDebut !== "")
      onUpdateRefinements(
        queryDateMajMin,
        moment(dateDebut).format("YYYY-MM-DD")
      );
    if (dateFin !== "")
      onUpdateRefinements(
        queryDateMajMax,
        moment(dateFin).format("YYYY-MM-DD")
      );
  };

  const handleChangeDateMiseEnVente = ({
    dateDebut,
    dateFin,
  }: {
    dateDebut: string;
    dateFin: string;
  }) => {
    if (dateDebut !== "")
      onUpdateRefinements(
        queryDateMiseEnVenteMin,
        moment(dateDebut).format("YYYY-MM-DD")
      );
    if (dateFin !== "")
      onUpdateRefinements(
        queryDateMiseEnVenteMax,
        moment(dateFin).format("YYYY-MM-DD")
      );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TitreBloc
          label={t("Dates")}
          icon={
            <DateRangeIcon
              style={{
                color: theme.palette.tonique,
              }}
            />
          }
        />
      </Grid>

      {mode !== "biensVendus" && (
        <SearchDates
          dateMin={_dateMajMin}
          dateMax={_dateMajMax}
          handleChangeDate={handleChangeDateMaj}
          libelle={"Date de MàJ"}
          periodes={[
            {
              libelle: "",
              montantMax: 10,
              montantMin: 0,
              unite: "years",
            },
            {
              libelle: "<1 mois",
              montantMax: 1,
              montantMin: 0,
              unite: "months",
            },
            {
              libelle: "<3 mois",
              montantMax: 3,
              montantMin: 0,
              unite: "months",
            },
            {
              libelle: "<6 mois",
              montantMax: 6,
              montantMin: 0,
              unite: "months",
            },
          ]}
          t={t}
        />
      )}
      <SearchDates
        dateMin={_dateMiseEnVenteMin}
        dateMax={_dateMiseEnVenteMax}
        handleChangeDate={handleChangeDateMiseEnVente}
        libelle={mode !== "biensVendus" ? "Mise en vente" : "Date de vente"}
        periodes={[
          {
            libelle: "",
            montantMax: 10,
            montantMin: 0,
            unite: "years",
          },
          {
            libelle: "<1 mois",
            montantMax: 1,
            montantMin: 0,
            unite: "months",
          },
          {
            libelle: "<3 mois",
            montantMax: 3,
            montantMin: 0,
            unite: "months",
          },
          {
            libelle: "<6 mois",
            montantMax: 6,
            montantMin: 0,
            unite: "months",
          },
        ]}
        t={t}
      />
    </Grid>
  );
};
