import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { ITheme } from "../../../theme/ts/main";
import { TitleAndSummary } from "./TitleAndSummary";

export interface DescriptionProps {
  newNotification: boolean;
  title: string;
  summary: string;
  detail: string;
  mobile: boolean;
}
const useStylesForDescription = makeStyles((theme: ITheme) => ({
  detail: {
    color: theme.palette.common.dark,
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-all",
    overflow: "hidden",
  },
  noPuce: {
    backgroundColor: "transparent",
    borderRadius: 5,
    height: 10,
    margin: 5,
    width: 10,
  },
  puceNew: {
    backgroundColor: theme.palette.tonique,
    borderRadius: 5,
    height: 10,
    margin: 5,
    width: 10,
  },
}));
export const Description: React.FC<DescriptionProps> = ({
  newNotification,
  title,
  summary,
  detail,
  mobile,
}) => {
  const classes = useStylesForDescription();
  return (
    <Grid container spacing={0}>
      <Grid item>
        <div className={newNotification ? classes.puceNew : classes.noPuce} />
      </Grid>
      <Grid item xs={11}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <TitleAndSummary title={title} summary={summary} mobile={mobile} />
          </Grid>
          {!mobile && (
            <Grid item xs={12}>
              <Typography
                variant={"body1"}
                color="textSecondary"
                className={classes.detail}
              >
                {detail}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
