import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import SingleCitySelect, { City } from "components/SingleCitySelect";
import moment from "moment";
import React, { FC } from "react";
import { toCity } from "services/portfolio/types";

import { GridContainer } from "../../../../../../components/containers/GridContainer";
import { TXTextField } from "../../../../../../components/formControls/TXTextField";
import { useContactForm } from "../context/ContactFormContext";
import useStyles from "../styles";
import { Profession } from "./Profession";
import { SectionTitle } from "./Sections";

export const OtherInformations: FC = () => {
  const classes = useStyles();
  const { isDisabled, setContactDto, contactDto } = useContactForm();
  const { birthDate, professionalInfos } = contactDto.identity;

  const chosenBirthPlace = toCity(contactDto.identity?.birthPlace);

  const onProfessionalInfosChange = ({
    target: { name, value },
  }: {
    target: { name: string; value: string };
  }) => {
    setContactDto({
      ...contactDto,
      identity: {
        ...contactDto.identity,
        professionalInfos: {
          ...contactDto.identity.professionalInfos,
          [name]: value,
        },
      },
    });
  };

  const onBirthDateChange = (date?: Date) => {
    setContactDto({
      ...contactDto,
      identity: {
        ...contactDto.identity,
        birthDate: date,
      },
    });
  };

  const onBirthCityChange = (city: City) => {
    setContactDto({
      ...contactDto,
      identity: {
        ...contactDto.identity,
        birthPlace: {
          ...(contactDto.identity?.birthPlace || {}),
          id: city.id,
          inseeCode: city.codeInsee || "",
          name: city.nom || "",
          postalCodes: city.codesPostaux,
        },
      },
    });
  };

  return (
    <>
      <SectionTitle title={"Autres informations"} mode={"non obligatoire"} />
      <GridContainer>
        <Grid item xs={12} md={6}>
          <div style={{ paddingTop: "8px" }}>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              locale="fr"
              libInstance={moment}
            >
              <DatePicker
                cancelLabel={"Annuler"}
                clearLabel={"Effacer"}
                clearable
                disabled={isDisabled}
                disableFuture
                openTo="year"
                label="Date de naissance"
                inputVariant="outlined"
                autoOk
                views={["year", "month", "date"]}
                className={classes.datePicker}
                value={birthDate || null}
                onChange={(e) => onBirthDateChange(e?.toDate())}
                format="DD/MM/YYYY"
              />
            </MuiPickersUtilsProvider>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <SingleCitySelect
            label="Lieu de naissance"
            villeChoisie={chosenBirthPlace}
            setVille={onBirthCityChange}
            disabled={isDisabled}
          />
        </Grid>
        <Profession />
        <Grid item xs={12} md={6}>
          <TXTextField
            label="Société"
            name="company"
            disabled={isDisabled}
            value={professionalInfos?.company || ""}
            className={classes.textField}
            onChange={onProfessionalInfosChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TXTextField
            label="Société-Rôle"
            name="companyRole"
            disabled={isDisabled}
            value={professionalInfos?.companyRole || ""}
            className={classes.textField}
            onChange={onProfessionalInfosChange}
          />
        </Grid>
      </GridContainer>
    </>
  );
};
