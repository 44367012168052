import { SaleParkingFeaturesDto } from "./../../../../models/Proposal/ForSale/Parking/SaleParkingFeaturesDto";
import { MatchingProfileRequestDto } from "services/matching/MatchingProfileRequestDto";

export default (
  features: SaleParkingFeaturesDto
): MatchingProfileRequestDto => {
  return new MatchingProfileRequestDto({
    budget: features.financialInfo.price,
    cityId: features.address.city?.id,
    constructionYear: undefined,
    nbRooms: undefined,
    profileKind: "Purchase",
    propertyKind: "Parking",
    surface: features.parkingPropertyInfo.squareMeter,
    withElevator: undefined,
    withParking: true,
    withPool: undefined,
  });
};
