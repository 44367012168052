import { Grid } from "@material-ui/core";
import { useBuckets } from "hooks/useBuckets";
import React, { useContext } from "react";
import {
  RealEstatePropertyCard,
  RealEstatePropertyCardProps,
} from "UI/features/realEstates/organisms/RealEstatePropertyCard";
import { InfiniteScrollList } from "UI/shared/organisms/InfiniteScrollList/InfiniteScrollList";

import { NetworkRealEstatesContext } from ".";

export const NetworkRealEstatesLarge: React.FC = () => {
  const { realEstates, loadMore } = useContext(NetworkRealEstatesContext);

  const realEstatesBuckets = useBuckets<RealEstatePropertyCardProps>({
    data: realEstates,
    size: 3,
  });

  return (
    <InfiniteScrollList
      pageSize={1}
      loadMore={loadMore}
      inTabs={true}
      wrapItems={true}
    >
      {realEstatesBuckets.map((realEstateBucket, i) => (
        <Grid container spacing={2} key={`network-realestates-lg-bucket-${i}`}>
          {realEstateBucket.map((realEstate) => (
            <Grid item xs={4} key={`network-lg-${realEstate.id}`}>
              <RealEstatePropertyCard {...realEstate} />
            </Grid>
          ))}
        </Grid>
      ))}
    </InfiniteScrollList>
  );
};
