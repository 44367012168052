import { makeStyles } from "@material-ui/core";

export const useBaseStyles = makeStyles((theme) => ({
  "@media (max-width: 768px)": {
    paperSearches: {
      width: "100%",
    },
  },
  constructionYear: {
    display: "flex",
    padding: "15px!important",
  },
  drawer: {
    height: "100%",
  },
  fab: {
    bottom: theme.spacing(2),
    position: "fixed",
    right: theme.spacing(2),
    zIndex: 1000,
  },
  filterGroupDivider: {
    height: "20px",
  },
  goUpButton: {
    backgroundColor: "rgb(0,0,0,0.5)",
    bottom: theme.spacing(2),
    color: "white",
    position: "fixed",
    [theme.breakpoints.down("md")]: {
      left: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      left: ({ drawerOpen }) =>
        drawerOpen ? theme.spacing(32) : theme.spacing(9),
    },
  },
  goUpButtonMap: {
    backgroundColor: "rgb(0,0,0,0.5)",
    bottom: theme.spacing(2),
    color: "white",
    position: "fixed",
    right: theme.spacing(2),
  },
  gridFilter: {
    padding: 0,
  },
  hits: {
    zIndex: 1,
  },
  root: {},
  select: {
    margin: "10px 3px 10px 3px",
  },
  toggleSidePaneButton: {
    bottom: "50%",
    position: "absolute",
    right: "-10px",
    top: "50%",
    zIndex: 10,
  },
  toggleSidePaneButtonContainer: {
    position: "relative",
    zIndex: 1250,
  },
  top: {
    alignItems: "flex-end",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.only("xs")]: {
      marginBottom: 0,
    },
    marginTop: 10,
  },
}));
