import "react-day-picker/lib/style.css";
import "./customCSS.css";

import { Dialog, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { CalendarTodayOutlined } from "@material-ui/icons";
import DataProvider from "components/DataProviderSR";
import moment from "moment";
import React, { useState } from "react";
import DayPicker, { ModifiersUtils } from "react-day-picker";
import { PanelRechercheGlobale } from "routes/app/recherche-globale/components/PanneauRechercheGlobale";
import { useEvenements } from "services/office365/useEvenements";

import JourneeSimplifiee from "./components/JourneeSimplifiee";
import PlanningJour from "./components/PlanningJour";
import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from "./fr";

const aujourdhui = new Date();

const WrappedAgenda = ({ items, surChangementMois = () => {} }) => {
  const evenements = items;
  const [selectedDay, setSelectedDay] = useState([]);
  const theme = useTheme();
  const modifiersStyles = {
    aujourdhui: {
      backgroundColor: `${theme.palette.tonique}80`,
      color: "white",
    },
    rendezVous: {
      backgroundColor: `${theme.palette.tonique}55`,
    },
  };

  const entete = {
    sousTitre: "",
    titre: "Agenda",
  };

  const handleDayClick = (jour) => {
    setSelectedDay(
      evenements
        .filter((evt) => {
          return moment(jour).isBetween(
            evt.dateDebut,
            evt.dateFin,
            "day",
            "[]"
          );
        })
        .sort(function (a, b) {
          return new Date(a.dateDebut) - new Date(b.dateDebut);
        })
    );
  };

  const handleDayOpenClose = () => setSelectedDay([]);

  const modifiers = {
    aujourdhui: new Date(),
    rendezVous: evenements.map((evt) => new Date(evt.dateDebut)),
  };

  function renderDay(day) {
    const date = day.getDate();
    const eventsCount = evenements.filter((evt) => {
      return moment(day).isBetween(evt.dateDebut, evt.dateFin, "day", "[]");
    }).length;

    const cellStyle = {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      position: "relative",
    };

    let style = { backgroundColor: "transparent" };

    if (ModifiersUtils.dayMatchesModifier(day, modifiers.rendezVous)) {
      style = modifiersStyles.rendezVous;
    }

    if (ModifiersUtils.dayMatchesModifier(day, modifiers.aujourdhui))
      style = modifiersStyles.aujourdhui;
    const eventsCountTip = eventsCount > 0 && (
      <div
        style={{
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: "50%",
          color: "#02A9B9",
          display: "flex",
          fontSize: "10px",
          height: 15,
          justifyContent: "center",
          marginBottom: "50%",
          marginLeft: "50%",
          position: "absolute",
          width: 15,
        }}
      >
        {eventsCount}
      </div>
    );
    return (
      <div style={cellStyle}>
        <div
          style={{
            alignItems: "center",
            borderRadius: "50%",
            display: "flex",
            height: 40,
            justifyContent: "center",
            width: 40,
            ...style,
          }}
        >
          {date}
        </div>
        {eventsCountTip}
      </div>
    );
  }

  const noAgenda = useMediaQuery("(max-width: 650px)");

  return (
    <PanelRechercheGlobale Icone={CalendarTodayOutlined} entete={entete}>
      <Grid container spacing={2}>
        {!noAgenda && (
          <Grid item xs={12} lg={6}>
            <DayPicker
              initialMonth={aujourdhui}
              modifiers={{
                rendezVous: evenements.map((evt) => new Date(evt.dateDebut)),
              }}
              onDayClick={handleDayClick}
              locale="fr"
              months={MONTHS}
              weekdaysLong={WEEKDAYS_LONG}
              weekdaysShort={WEEKDAYS_SHORT}
              firstDayOfWeek={1}
              renderDay={renderDay}
              onMonthChange={surChangementMois}
            />
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <PlanningJour styles={modifiersStyles} />
        </Grid>
      </Grid>
      <Dialog open={selectedDay.length > 0} onClose={handleDayOpenClose}>
        {selectedDay.length > 0 && (
          <JourneeSimplifiee
            jour={selectedDay}
            onClose={handleDayOpenClose}
            styles={modifiersStyles}
          />
        )}
      </Dialog>
    </PanelRechercheGlobale>
  );
};

const initParams = {
  dateDebutUtc: moment()
    .subtract(1, "month")
    .startOf("month")
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss"),
  dateFinUtc: moment()
    .add(1, "month")
    .endOf("month")
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss"),
};

const Agenda = () => {
  const [plageDates, setPlageDates] = useState(initParams);

  const surChangementMois = (date) => {
    const newParams = {
      dateDebutUtc: moment(date)
        .subtract(1, "month")
        .startOf("month")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss"),
      dateFinUtc: moment(date)
        .add(1, "month")
        .endOf("month")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss"),
    };
    setPlageDates(newParams);
  };

  return (
    <DataProvider
      dataReader={useEvenements}
      params={plageDates}
      erreur={{
        mini: true,
        texte: "Serveur non disponible.",
        titre: "Agenda",
      }}
    >
      <WrappedAgenda surChangementMois={surChangementMois} />
    </DataProvider>
  );
};

export default Agenda;
