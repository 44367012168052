import { makeStyles } from "@material-ui/core/styles";
import { ITheme } from "theme/ts/main";

export const useStyles = makeStyles((theme: ITheme) => ({
  affFam: {
    color: theme.palette.common.dark,
  },
  bu: {
    borderRadius: 5,
    height: 10,
    marginLeft: 5,
    marginRight: 5,
    width: 10,
  },
  cardContent: {
    marginTop: "10px",
  },
  cardPaper: {
    "&:hover": {
      boxShadow:
        "0px 3px 3px 0px rgba(0,0,0,0.34), 0px 1px 1px -1px rgba(0,0,0,0.1)",
    },
    "&:hover $imageInformation": {
      alignItems: "center",
      background: "#00000053",
      color: "white",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      visibility: "inherit",
    },
  },
  cardPaperDisabled: {
    "&:hover": {
      boxShadow:
        "0px 3px 3px 0px rgba(0,0,0,0.34), 0px 1px 1px -1px rgba(0,0,0,0.1)",
    },
    "&:hover $imageInformation": {
      alignItems: "center",
      background: "#00000053",
      color: "white",
      display: "grid",
      height: "100%",
      justifyContent: "center",
      visibility: "inherit",
    },
    opacity: 0.5,
  },
  disabledHitText: {
    color: theme.palette.tonique,
    float: "left",
    fontWeight: "bold",
  },
  emphase: {
    color: theme.palette.emphase,
  },
  fab: {
    bottom: "50%",
    left: "calc(100% - 30px)",
  },
  imageInformation: {
    visibility: "hidden",
  },
  legend: {
    background: "rgba(0,0,0,0.4)",
    color: "#fff",
    marginTop: "-50px",
    position: "relative",
    textAlign: "center",
    width: "100%",
  },
  mail: {
    color: theme.palette.default,
  },
  nbPhotos: {
    color: "#fff",
    position: "absolute",
    right: 10,
    textAlign: "center",
    top: -140,
    width: "25%",
  },
  tagItem: {
    paddingRight: "5px",
  },
  telephone: {
    color: theme.palette.default,
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
  texte: {
    height: "30px",
    marginBottom: "10px",
    marginTop: "10px",
    overflow: "hidden",
  },
  typeBienVille: {
    fontSize: "1rem",
  },
}));
