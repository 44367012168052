import { RadioGroup } from "@material-ui/core";
import React from "react";
import { useServices } from "services";

import { BuildingForSaleInfoKind } from "../../../../models/Proposal/Features/Building/buildingInfoKind";
import { ComplementIcon } from "../../../components/RadioChip";
import { SubSectionTitle } from "../../../components/SectionTitle";
import { SelectChip } from "../../../SelectChip";

export const SelectBuildingForSaleKind: React.FC<{
  value?: BuildingForSaleInfoKind;
  onChange: (value: BuildingForSaleInfoKind) => void;
  disabled: boolean;
}> = ({ value, onChange }) => {
  const {
    l10n: { t },
  } = useServices();

  const options: BuildingForSaleInfoKind[] = [
    "All",
    "Building",
    "BuildingToCut",
    "OfficeBuilding",
    "InvestmentBuilding",
  ];
  return (
    <>
      <SubSectionTitle title="Type d'immeuble : " mode={"obligatoire"} />
      <RadioGroup
        row
        value={value}
        onChange={(_) => onChange(_.target.value as BuildingForSaleInfoKind)}
      >
        {options.map((option) => (
          <SelectChip
            key={option}
            icon={
              <ComplementIcon
                label={t(`buildingForSaleInfoKind.${option}`)}
                checked={false}
              />
            }
            checkedIcon={
              <ComplementIcon
                label={t(`buildingForSaleInfoKind.${option}`)}
                checked={true}
              />
            }
            value={option}
          />
        ))}
      </RadioGroup>
    </>
  );
};
