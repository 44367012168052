import { Avatar, Grid } from "@material-ui/core";
import React from "react";
import { Column } from "UI/shared/atoms/containers/Column";
import { EmailLink } from "UI/shared/molecules/EmailLink";
import { PhoneLink } from "UI/shared/molecules/PhoneLink";

import { BU } from "../../../../../components/BU";
import { Text16pxBold } from "../../../../shared/atoms/typographies/Text16pxBold";

export interface RealEstateAgentProps {
  bu: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  photo: string;
}
export const RealEstateAgent: React.FC<RealEstateAgentProps> = ({
  bu,
  firstName,
  lastName,
  email,
  phoneNumber,
  photo,
}) => {
  return (
    <Grid
      container
      direction="row"
      spacing={1}
      alignItems={"center"}
      wrap={"nowrap"}
    >
      <Grid item>
        <Avatar
          alt={`${firstName} ${lastName}`}
          src={photo}
          style={{ height: "70px", width: "70px" }}
        />
      </Grid>
      <Grid item>
        <Column>
          {bu && <BU bu={bu} />}
          <Text16pxBold>
            {firstName} {lastName}
          </Text16pxBold>
          <PhoneLink value={phoneNumber} textSize="large" />
          <EmailLink value={email} textSize="small" />
        </Column>
      </Grid>
    </Grid>
  );
};
