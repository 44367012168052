import { Grid, SwipeableDrawer, Typography, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AssignmentIcon from "@material-ui/icons/AssignmentOutlined";
import ChevronRightIcon from "@material-ui/icons/Close";
import ContactsIcon from "@material-ui/icons/ContactsOutlined";
import SurfaceIcon from "@material-ui/icons/CropFreeOutlined";
import DateRangeIcon from "@material-ui/icons/DateRangeOutlined";
import BudgetIcon from "@material-ui/icons/EuroOutlined";
import RoomIcon from "@material-ui/icons/KingBedOutlined";
import LocationIcon from "@material-ui/icons/LocationOnOutlined";
import AddIcon from "@material-ui/icons/TuneOutlined";
import { RefinementField } from "components/RefinementField";
import {
  Radio,
  Refinement,
  Refinements,
} from "components/RefinementField/types";
import { useMoreFiltersContext } from "components/Searchbar/services/MoreFiltersProvider";
import React, { FC, useCallback, useMemo } from "react";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";
import { TitleWithIcon } from "UI/shared/molecules/TitleWithIcon";
import { ConstructionYearOption } from "../../types/ConstructionYear";
import { RealEstatesCriteria } from "../SearchInput";
import styles from "./MoreFilters.module.css";

const constructionYearsOptions: ConstructionYearOption[] = [
  { label: "Avant 2000", value: "[0@2000]" },
  { label: "2000-2010", value: "[2000@2010]" },
  { label: "Après 2010", value: "[2010+]" },
];

export const MoreFilters: FC<{
  total: number;
  refinements: Refinements<RealEstatesCriteria>;
  onChange: (value: { key: RealEstatesCriteria; value: Refinement }) => void;
}> = (props) => {
  const t = (_: string) => _;
  const theme = useTheme() as any;

  const { isOpen, setIsOpen } = useMoreFiltersContext();

  const onChange = useCallback(
    (key: RealEstatesCriteria) => (value: Refinement) => {
      props.onChange({ key, value });
    },
    [props.onChange]
  );

  const sectionTitleProps = useMemo(
    () => ({
      style: {
        color: theme.palette.tonique,
      },
    }),
    [theme.palette.tonique]
  );

  const toConstructionYearsRefinements = (
    options: ConstructionYearOption[]
  ) => {
    const { value: refinedConstructionYears = [] } = props.refinements
      .constructionYear as Radio;

    const isRefined = (option: ConstructionYearOption) =>
      refinedConstructionYears.find(
        (constructionYear) => constructionYear.value === option.value
      ) != null;
    return options.map((option) => ({
      ...option,
      isRefined: isRefined(option),
      count: 1,
    }));
  };

  return (
    <>
      <Button
        aria-label="more filter"
        variant={"contained"}
        color={"primary"}
        size={"small"}
        onClick={() => setIsOpen(true)}
        style={{
          fontSize: "16px",
          height: "45px",
          minWidth: "45px",
          padding: 0,
        }}
      >
        <AddIcon />
      </Button>

      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
      >
        <div
          className={styles.content}
          style={{ borderLeft: `5px solid ${theme.palette.tonique}` }}
        >
          <div className={styles.header}>
            <Typography variant="h2">{t("Recherche détaillée")}</Typography>
            <IconButton
              color="primary"
              aria-label="Close"
              onClick={() => setIsOpen(false)}
            >
              <ChevronRightIcon />
            </IconButton>
          </div>

          <Grid className={styles.body}>
            <Grid /*first column*/
              className={styles.column}
              container
              spacing={2}
              alignContent="flex-start"
            >
              <Grid item container spacing={1}>
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <TitleWithIcon
                      icon={<DateRangeIcon {...sectionTitleProps} />}
                      title={t("Affichage")}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <RefinementField
                        onChange={onChange("withNotes")}
                        value={props.refinements.withNotes}
                        label={t("Avec notes")}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <TitleWithIcon
                    icon={<DateRangeIcon {...sectionTitleProps} />}
                    title={t("Dates")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={onChange("dateMaj")}
                    value={props.refinements.dateMaj}
                    label={t("Date de màj")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={onChange("dateMiseEnVente")}
                    value={props.refinements.dateMiseEnVente}
                    label={t("Mise en vente")}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <TitleWithIcon
                    icon={<AssignmentIcon {...sectionTitleProps} />}
                    title={t("Caractéristiques")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={onChange("typeAffaires")}
                    value={props.refinements.typeAffaires}
                    label={t("Type d'affaire")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={onChange("familleBiens")}
                    value={props.refinements.familleBiens}
                    label={t("Famille de biens")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={onChange("typeAchat")}
                    value={props.refinements.typeAchat}
                    label={t("Type achat")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={onChange("bus")}
                    value={props.refinements.bus}
                    label={t("Réseau")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PaddingContainer top={8} bottom={8}>
                    <RefinementField
                      label={t("Année de construction")}
                      value={{
                        kind: "radio",
                        value: toConstructionYearsRefinements(
                          constructionYearsOptions
                        ),
                      }}
                      onChange={onChange("constructionYear")}
                    />
                  </PaddingContainer>
                </Grid>
                <Grid item xs={12}>
                  <TitleWithIcon
                    icon={<RoomIcon {...sectionTitleProps} />}
                    title={t("Pièces")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={onChange("nbPiecesRange")}
                    value={props.refinements.nbPiecesRange}
                    label={t("Pièces")}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid /*second column*/
              className={styles.column}
              container
              spacing={2}
              alignContent="flex-start"
            >
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <TitleWithIcon
                    icon={<LocationIcon {...sectionTitleProps} />}
                    title={t("Lieu(x)")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    value={props.refinements.locations}
                    onChange={onChange("locations")}
                    label={t("Localisations")}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TitleWithIcon
                    icon={<SurfaceIcon {...sectionTitleProps} />}
                    title={t("Surfaces")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={onChange("surfaceHabitable")}
                    value={props.refinements.surfaceHabitable}
                    label={t("Surface hab")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={onChange("surfaceTerrain")}
                    value={props.refinements.surfaceTerrain}
                    label={t("Surface terrain")}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <TitleWithIcon
                    icon={<BudgetIcon {...sectionTitleProps} />}
                    title={t("Budget")}
                  />{" "}
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={onChange("prix")}
                    value={props.refinements.prix}
                    label={t("Prix")}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <TitleWithIcon
                    icon={<ContactsIcon {...sectionTitleProps} />}
                    title={t("Autre")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={onChange("withParking")}
                    value={props.refinements.withParking}
                    label={t("Stationnement")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={onChange("withElevator")}
                    value={props.refinements.withElevator}
                    label={t("Ascenseur")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={onChange("withSingleStorey")}
                    value={props.refinements.withSingleStorey}
                    label={t("Plain-Pied")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RefinementField
                    onChange={onChange("withPool")}
                    value={props.refinements.withPool}
                    label={t("Piscine")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div className={styles.footer} onClick={() => setIsOpen(false)}>
            <Typography variant={"subtitle1"}>
              {props.total === 1 && (
                <>
                  <span style={{ fontWeight: "bold" }}>1</span> bien correspond
                  à vos critères
                </>
              )}

              {props.total !== 1 && (
                <>
                  <span style={{ fontWeight: "bold" }}>{props.total}</span>{" "}
                  biens correspondent à vos critères
                </>
              )}
            </Typography>
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
};
