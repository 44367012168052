import React from "react";
import { makeStyles } from "@material-ui/core";

interface BorderedContainerProps {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  all?: number;
  style?: string;
  color: string;
  radius?: number;
}

const useStyles = makeStyles(() => ({
  borderedContainer: {
    borderWidth: ({ top, right, bottom, left }: BorderedContainerProps) =>
      `${top}px ${right}px ${bottom}px ${left}px`,
    borderColor: ({ color }: BorderedContainerProps) => color,
    borderStyle: ({ style }: BorderedContainerProps) => style,
    borderRadius: ({ radius }: BorderedContainerProps) => radius,
    width: "100%",
  },
}));

export const BorderedContainer: React.FC<BorderedContainerProps> = ({
  all,
  top = 0,
  bottom = 0,
  left = 0,
  right = 0,
  style = "solid",
  color,
  radius = 0,
  children,
}) => {
  const styleProps: BorderedContainerProps = {
    top: all || top || 0,
    bottom: all || bottom || 0,
    left: all || left || 0,
    right: all || right || 0,
    style,
    color,
    radius,
  };

  const classes = useStyles(styleProps);
  return <div className={classes.borderedContainer}>{children}</div>;
};
