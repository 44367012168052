import vCardsJS from "vcards-js";
import getImage from "utils/getImage";

function createVCard({
  firstName = "",
  middleName = "",
  lastName = "",
  organization = "",
  urlPhoto = "",
  workPhone = "",
  workEmail = "",
  birthday = "",
  title = "",
  url = "",
  namePrefix = "",
  note = "",
  facebook = "",
}) {
  const vCard = vCardsJS();
  const imageString = urlPhoto !== "" ? getImage(urlPhoto) : "";

  vCard.version = "3.0";
  vCard.namePrefix = namePrefix;
  vCard.firstName = firstName;
  vCard.middleName = middleName;
  vCard.lastName = lastName;
  vCard.organization = organization;
  if (urlPhoto !== "") vCard.photo.embedFromString(imageString, "image/png");
  vCard.workPhone = workPhone;
  vCard.workEmail = workEmail;
  if (birthday !== "") vCard.birthday = new Date(birthday);
  vCard.title = title;
  vCard.url = url;
  vCard.note = note;
  vCard.socialUrls["facebook"] = facebook;
  return vCard.getFormattedString();
}

export default createVCard;
