import { Criterias } from "UI/shared/molecules/Criterias";
import { SortInput } from "UI/shared/molecules/SortInput";
import {
  LocationItem,
  Locations,
  Refinement,
} from "components/RefinementField/types";
import { Searchbar } from "components/Searchbar";
import { PigeCriteria } from "components/Searchbar/types";
import React, { FC } from "react";
import { PigesSearchParams } from "services/piges";
import { PigeSearchResult } from "types";
import styles from "./SearchInput.module.css";
import { getSortFields } from "./sortFields";
import { toParams } from "./toParams";
import { toRefinements } from "./toRefinements";

export interface SearchInputProps {
  result: PigeSearchResult;
  params: PigesSearchParams;
  locations?: Locations;

  onChange(params: PigesSearchParams): void;

  onLocationChange?(
    value: {
      villes: LocationItem[];
      departements: LocationItem[];
      regions: LocationItem[];
    },
    isDeletion?: boolean
  ): void;

  className?: string;
}

export const SearchInput: FC<SearchInputProps> = (props) => {
  const state = toRefinements(props.result, props.params, props.locations);
  const onChange = (_: { key: PigeCriteria; value: Refinement }) => {
    if (_.key === "zoneDeTravail") {
      const villes = (_.value as Locations).value?.villes;
      const departements = (_.value as Locations).value?.departements;
      const regions = (_.value as Locations).value?.regions;

      if (props.onLocationChange)
        props.onLocationChange({ departements, regions, villes });
    }

    props.onChange({ ...props.params, ...toParams(_.key, _.value) });
  };

  const onClear = () => props.onChange({});

  const sortOptions = getSortFields();
  const sortSelectedOptionDirection = props.params.sortAsc
    ? "ascending"
    : "descending";
  const defaultOption = "DateModification descending";
  return (
    <div className={props.className}>
      <Searchbar
        className={styles.searchbar}
        displayMode="list"
        refinements={state}
        onChange={onChange}
        total={props.result.total}
      />
      <div>
        <Criterias refinements={state} onChange={onChange} onClear={onClear} />
        <SortInput
          options={sortOptions}
          onChange={onChange}
          selectedOption={
            props.params.sortAsc !== undefined &&
            props.params.sortColumn !== undefined
              ? `${props.params.sortColumn} ${sortSelectedOptionDirection}`
              : defaultOption
          }
        />
      </div>
    </div>
  );
};
