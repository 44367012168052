import ReactSelect from "components/Searchable";
import React from "react";

import { useServices } from "../../services";
import { useTxHttp } from "../../services/http";

let noOptionMessage = "";

interface CityFromService {
  codeInsee: string;
  codesPostaux: string[];
  id: string;
  nom: string;
  departement?: Departement;
}

export interface City {
  codeInsee?: string;
  codesPostaux: string[];
  id: string;
  nom?: string;
  ville?: string;
  departement?: Departement;
}

export interface Departement {
  id: string;
  nom?: string;
  region?: Region;
}

export interface Region {
  id: string;
  nom?: string;
}

const SingleCitySelect: React.FC<{
  villeChoisie: City;
  setVille: (city: City) => void;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  isClearable?: boolean;
}> = ({ villeChoisie, setVille, disabled, required, label, isClearable }) => {
  const {
    endpoints,
    l10n: { t },
  } = useServices();

  const { get } = useTxHttp();
  const [villes, setVilles] = React.useState<CityFromService[]>([]);
  const [value, setValue] = React.useState<string>();

  React.useEffect(() => {
    if (value) {
      get<{ data: CityFromService[] }>(
        endpoints.geoloc.obtenirLesVillesParAutoComplete,
        {
          page: 1,
          q: value,
          size: 10,
        }
      )
        .then((_) => _.data)
        .then((response) => {
          if (response) setVilles(response.data);
        })
        // eslint-disable-next-line no-console
        .catch((e) => console.log(e));
    }
    if ((value && value.length < 1) || value === null) {
      setVilles([]);
      noOptionMessage = "";
    }
  }, [value, get, endpoints.geoloc.obtenirLesVillesParAutoComplete]);

  function handleSearchChange(inputValue = "") {
    setValue(inputValue);
  }

  const handleChangeReactSelect = (data: CityFromService) => {
    if (data !== null) {
      setVille({
        codeInsee: data.codeInsee,
        codesPostaux: data.codesPostaux,
        departement: data.departement,
        id: data.id,
        nom: data.nom,
      });
    } else {
      setVille({
        codeInsee: "",
        codesPostaux: [],
        id: "",
        nom: "",
      });
    }
  };

  if (value && value.length > 0) noOptionMessage = "Pas de résultats.";

  return (
    <ReactSelect
      disabled={disabled}
      placeholder={""}
      label={t(label || "Ville")}
      messageNoOptions={noOptionMessage}
      onChangeInput={handleSearchChange}
      onChange={handleChangeReactSelect}
      required={required}
      isMulti={false}
      isClearable={isClearable}
      values={villes.map((item) => ({
        codeInsee: item.codeInsee,
        codesPostaux: item.codesPostaux,
        departement: item.departement,
        id: item.id,
        label: `${item.nom} (${item.codesPostaux.join(", ")})`,
        nom: item.nom,
      }))}
      valuesDejaChoisies={
        villeChoisie.ville !== ""
          ? [
              {
                codeInsee: villeChoisie.codeInsee,
                codesPostaux: villeChoisie.codesPostaux,
                departement: villeChoisie.departement,
                label: `${villeChoisie.ville} (${villeChoisie.codesPostaux.join(
                  ", "
                )})`,
                nom: villeChoisie.nom,
                value: villeChoisie.id,
              },
            ]
          : []
      }
    />
  );
};

export default SingleCitySelect;
