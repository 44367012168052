import { IconButton } from "@material-ui/core";
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import CardsCarousel from "components/CardsCarousel";
import React, { useEffect, useRef, useState } from "react";
import { ReactElement } from "react-transition-group/node_modules/@types/react";

interface CarouselProps {
  items: ReactElement[];
  onItemClick: () => void;
  mode?: string;
}

export const Carousel: React.FC<CarouselProps> = ({
  items,
  onItemClick,
  mode,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [carouselItemsCount, setCarouselItemsCount] = useState(4);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const adjustCarouselItemsCount = () => {
      if (
        containerRef?.current &&
        carouselItemsCount !==
          Math.floor(containerRef.current.getBoundingClientRect().width / 200)
      ) {
        setCarouselItemsCount(
          Math.floor(containerRef.current.getBoundingClientRect().width / 200)
        );
      }
    };

    window.addEventListener("resize", adjustCarouselItemsCount);

    adjustCarouselItemsCount();

    return () => {
      window.removeEventListener("resize", adjustCarouselItemsCount);
    };
  }, [carouselItemsCount]);

  const leftChevron = (
    <IconButton>
      <ChevronLeftOutlinedIcon />
    </IconButton>
  );
  const rightChevron = (
    <IconButton>
      <ChevronRightOutlinedIcon />
    </IconButton>
  );

  return (
    <div style={{ height: "328px" }} ref={containerRef}>
      <CardsCarousel
        requestToChangeActive={setActiveIndex}
        activeItemIndex={activeIndex}
        activePosition={"center"}
        numberOfCards={carouselItemsCount}
        enablePlaceholder={false}
        numberOfPlaceholderItems={carouselItemsCount}
        minimumPlaceholderTime={1000}
        placeholderItem={
          <div
            style={{
              height: 320,
              width: 169,
              background: "#555",
            }}
          >
            Placeholder
          </div>
        }
        gutter={0}
        showSlither={true}
        firstAndLastGutter={true}
        freeScrolling={false}
        chevronWidth={42}
        leftChevron={leftChevron}
        rightChevron={rightChevron}
        outsideChevron={false}
      >
        {items.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </CardsCarousel>
    </div>
  );
};
