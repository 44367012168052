import { RefinementsFactory } from "factory/RefinementFactory";

export const Refinements = RefinementsFactory()
  .create()
  .addMultipleValue({ aggregation: "Bu", query: "bus", values: [] })
  .addMultipleValue({
    aggregation: "Roles",
    query: "roles",
    values: [],
  })
  .addMultipleValue({
    aggregation: "Specialites",
    query: "specialites",
    values: [],
  })
  .addMultipleValue({
    aggregation: "none",
    query: "directeursRegoniaux",
    values: [],
  })
  .addMultipleValue({
    aggregation: "none",
    query: "conseillers",
    values: [],
  })
  .addMultipleValue({
    aggregation: "none",
    query: "quartiers",
    values: [],
  })
  .addMultipleValue({
    aggregation: "none",
    query: "regions",
    values: [],
  })
  .addMultipleValue({
    aggregation: "none",
    query: "villes",
    values: [],
  })
  .addMultipleValue({
    aggregation: "none",
    query: "departements",
    values: [],
  })
  .addValue({ query: "fullText" })
  .addValue({ query: "sortColumn", value: "Nom" })
  .addValue({ query: "sortAsc", value: true })
  .addValue({ query: "dateDentreeMin", value: "" })
  .addValue({ query: "dateDentreeMax", value: "" })
  .addValue({ query: "telephone", value: "" })
  .addValue({ query: "topPerformer", value: "" });
