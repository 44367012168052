import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import withRoot from "layouts/withRoot";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import ExpandMoreIcon from "@material-ui/icons/KeyboardArrowDown";
import ExpandLessIcon from "@material-ui/icons/KeyboardArrowUp";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  bouton: {
    color: theme.palette.beige,
  },
  checked: {
    color: theme.palette.secondary.main,
    fontSize: "1rem",
    fontWeight: "bold",
  },
  menuitem: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  muiRippleCircle: {
    display: "none",
  },
  notChecked: {
    color: theme.palette.primary.main,
    fontSize: "1rem",
  },
  refinementList: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  searchField: {
    width: "200px",
  },
}));

const CustomMenu = (props) => {
  const { title, attribute } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="outlined"
        classes={{ root: classes.bouton }}
        onClick={handleClick}
      >
        <Badge
          badgeContent={
            attribute.values.filter((refinement) => refinement.isRefined).length
          }
          color="primary"
        >
          <div style={{ marginRight: 10 }}>{title}</div>
          {anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Badge>
      </Button>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem className={classes.menuitem}>{props.children}</MenuItem>
      </Menu>
    </>
  );
};

export default CustomMenu;
