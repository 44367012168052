import { MultiCheckbox } from "UI/shared/molecules/MultiCheckbox";
import React from "react";

import { AmenityType } from "../../models/amenityType";
import { SubSectionTitle } from "../components/SectionTitle";
import { ProfileInputProps } from "../InputProps";

export const SaleEquipments: React.FC<ProfileInputProps<AmenityType[]>> = ({
  onChange,
  value,
}) => {
  const options = [
    "DigitCode",
    "Elevator",
    "Fiber",
    "Garden",
    "Interphone",
    "BuildingCaretaker",
    "Pool",
  ];
  return (
    <>
      <SubSectionTitle title="Equipements communs" mode={"non obligatoire"} />
      <MultiCheckbox
        translationPrefix={"amenity"}
        options={options}
        selectedOptions={value}
        onChange={onChange}
      />
    </>
  );
};
