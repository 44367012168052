import { Fab } from "@material-ui/core";
import { LocationOn } from "@material-ui/icons";
import ModalMap from "components/ModalMap";
import React, { ReactElement, useState } from "react";

import { BienForMap } from ".";
import { useStyles } from "./styles";

export interface MapProps {
  title: string;
  hit: BienForMap;
  polygon?: ReactElement;
}

export const Map = (props: MapProps) => {
  const [isModalMapDisplayed, setModalMapDisplay] = useState(false);
  const toggleMapButtonClick = () => {
    setModalMapDisplay(!isModalMapDisplayed);
  };
  const classes = useStyles();
  return (
    <>
      <Fab className={classes.buttonsIcon} onClick={toggleMapButtonClick}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <LocationOn className={classes.icons} />
        </div>
      </Fab>

      <ModalMap
        label={"hit-map"}
        mapMode="précis"
        isDisplayed={isModalMapDisplayed}
        point={props.hit}
        onHide={toggleMapButtonClick}
        mapPointLabel=""
        polygon={props.polygon}
      />
    </>
  );
};
