import { KeysOfType } from "./../../../../../../../../../types/utilityTypes";
import { ParkingFeaturesDto } from "./../../Features/Parking/parkingFeaturesDto";
import { ForRentParkingFinancialInfo } from "./ForRentParkingFinancialInfoDto";

export type ForRentParkingFeaturesDto = ParkingFeaturesDto<
  ForRentParkingFinancialInfo
>;

export const emptyFeatures: () => ForRentParkingFeaturesDto = () => ({
  address: {},
  parkingAmenities: [],
  financialInfo: {
    charges: 0,
    rentChargesExcluded: 0,
    price: 0,
  },
  parkingKind: "Unknown",
  parkingPropertyInfo: {},
  parkingRentedInfo: {
    rent: 0,
    rented: false,
  },
});

export type CheckedCriteria = KeysOfType<
  ForRentParkingFeaturesDto["parkingPropertyInfo"],
  boolean | undefined
>;
