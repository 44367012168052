import { LabelledValue } from "UI/shared/molecules/LabelledValue";
import React, { FC } from "react";
import { useServices } from "services";

export interface VacancyProps {
  value: string;
}
export const Vacancy: FC<VacancyProps> = ({ value }) => {
  const {
    l10n: { t },
  } = useServices();

  return value ? (
    <LabelledValue label={"Complément"} value={t(`vacancy.${value}`)} />
  ) : (
    <></>
  );
};
