import {
  Button,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useState } from "react";

import { ITheme } from "../../../theme/ts/main";

export interface ButtonOption {
  subtitle: string;
  action: () => void;
}

export interface ButtonWithOptionsProps {
  title: string;
  icon: React.ReactNode;
  options: ButtonOption[];
  mainAction: () => void;
  disabled?: boolean;
}

export const ButtonWithOptions: React.FC<ButtonWithOptionsProps> = (props) => {
  const { title, icon, options, mainAction, disabled = false } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => setAnchorEl(null);

  return (
    <>
      <Button
        disabled={disabled}
        variant="contained"
        color="primary"
        startIcon={icon}
        endIcon={
          <Carret
            up={anchorEl !== null}
            onClick={anchorEl === null ? openMenu : closeMenu}
          />
        }
        onClick={mainAction}
      >
        {title}
      </Button>
      {anchorEl !== null && (
        <ButtonMenu
          mainTitle={title}
          menuItems={options}
          anchorEl={anchorEl}
          onClose={closeMenu}
        />
      )}
    </>
  );
};

const Carret: React.FC<{
  up: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}> = ({ up, onClick }) => (
  <div
    style={{
      borderLeft: "1px solid white",
      display: "flex",
      justifyContent: "center",
      paddingLeft: "10px",
      width: "100%",
    }}
    onClick={onClick}
  >
    {up ? <ExpandLess /> : <ExpandMore />}
  </div>
);

const useStyles = makeStyles((theme: ITheme) => ({
  button: {
    backgroundColor: theme.palette.secondaryButton.backgroundColor,
    color: theme.palette.secondaryButton.textColor,
  },
  menuItem: {
    borderBottom: "1px solid white",
  },
  menuList: {
    padding: 0,
  },
  menuPaper: {
    backgroundColor: theme.palette.secondaryButton.backgroundColor,
    width: 190,
  },
  secondaryButtonContainer: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
}));
const ButtonMenu: React.FC<{
  mainTitle?: string;
  menuItems: ButtonOption[];
  anchorEl: HTMLElement;
  onClose: () => void;
}> = ({ menuItems, anchorEl, onClose, mainTitle = "" }) => {
  const classes = useStyles();
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      transformOrigin={{
        horizontal: 142,
        vertical: "bottom",
      }}
      classes={{ list: classes.menuList, paper: classes.menuPaper }}
    >
      {menuItems.map((menuItem) => (
        <MenuItem
          key={menuItem.subtitle}
          onClick={(e) => {
            e.stopPropagation();
            menuItem.action();
          }}
          className={classes.menuItem}
        >
          <div className={classes.secondaryButtonContainer}>
            <Typography variant={"body1"} className={classes.button}>
              {mainTitle.toUpperCase()}
            </Typography>
            <Typography
              variant={"body1"}
              className={classes.button}
              style={{ fontSize: "10px", marginTop: "-5px" }}
            >
              {menuItem.subtitle}
            </Typography>
          </div>
        </MenuItem>
      ))}
    </Menu>
  );
};
