import {
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  useTheme,
} from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRangeOutlined";
import ExtensionIcon from "@material-ui/icons/ExtensionOutlined";
import RoomIcon from "@material-ui/icons/KingBedOutlined";
import AgentIcon from "@material-ui/icons/PeopleOutlined";
import ContextualSideBar from "components/ContextualSideBar/ContextualSideBar";
import ListeCriteres from "components/ListeCriteres";
import SelectX from "components/SelectX";
import Conseillers from "layoutContents/components/ContextualSideBar/components/conseillers";
import Localisation from "layoutContents/components/ContextualSideBar/components/localisation";
import NombreDeResultats from "layoutContents/components/ContextualSideBar/components/nombreDeResultats";
import TitreBloc from "layoutContents/components/ContextualSideBar/components/titreBloc";
import React from "react";

import Budget from "./components/budget";
import Caracteristiques from "./components/caracteristiques";
import { Dates } from "./components/Dates";
import Surfaces from "./components/surfaces";

const ContextualSideBarBiens = ({
  drawer,
  setOpenDrawer,
  onUpdateRefinements,
  onUpdateLocalisations,
  refinements,
  clearRefinements,
  t,
  biensTotal,
  classes,
  mode,
  endPointConseiller = "",
  formatOptionsListe,
}) => {
  const theme = useTheme();

  const {
    bus,
    familleBiens,
    typeAchat,
    typeAffaires,
    nbChambresRange,
    nbPiecesRange,
    prixMin,
    prixMax,
    surfaceHabitableMin,
    surfaceHabitableMax,
    surfaceTerrainMin,
    surfaceTerrainMax,
    withParking,
    withElevator,
    withPool,
    withSingleStorey,
    withNotes,
    constructionYear,
  } = refinements.toObject();

  const handleCheckboxChange = (event) => {
    onUpdateRefinements(event.target.name, event.target.checked);
  };
  const handleChangeNotes = (event) => {
    onUpdateRefinements(event.target.name, event.target.checked);
  };
  const _refinementsObject = refinements.toObject();
  return (
    <ContextualSideBar
      contextualOpen={drawer}
      setContextualOpen={setOpenDrawer}
      width={window.innerWidth > 800 ? "800px" : window.innerWidth.toString()}
      withCloseBtn={true}
      titre={t("Recherche détaillée")}
    >
      <div className={classes.drawer} style={{ position: "relative" }}>
        <Grid container spacing={1} alignItems={"flex-start"}>
          <Grid item xs={12}>
            <Hidden mdUp>
              <>
                <ListeCriteres
                  items={_refinementsObject}
                  clearRefinements={clearRefinements}
                  onChange={onUpdateRefinements}
                />
              </>
            </Hidden>
          </Grid>
          <Grid item xs={12} md={6}>
            {withNotes != null && (
              <div>
                <TitreBloc
                  label={t("Affichage")}
                  icon={
                    <DateRangeIcon style={{ color: theme.palette.tonique }} />
                  }
                  first={true}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: theme.palette.primary.main }}
                      onChange={handleChangeNotes}
                      name="withNotes"
                      inputProps={{ "aria-label": "checkbox" }}
                      defaultChecked={withNotes?.value}
                    />
                  }
                  label={"Avec Notes"}
                />
              </div>
            )}
            <Dates
              mode={mode}
              onUpdateRefinements={onUpdateRefinements}
              dateVenteMin={refinements.toObject().dateVenteMin}
              dateVenteMax={refinements.toObject().dateVenteMax}
              dateMajMin={refinements.toObject().dateMajMin}
              dateMajMax={refinements.toObject().dateMajMax}
              dateMiseEnVenteMin={refinements.toObject().dateMiseEnVenteMin}
              dateMiseEnVenteMax={refinements.toObject().dateMiseEnVenteMax}
            />
            <Caracteristiques
              familleBiens={familleBiens}
              bus={bus}
              onUpdateRefinements={onUpdateRefinements}
              typeAchat={typeAchat}
              typeAffaires={typeAffaires}
              constructionYear={constructionYear}
              t={t}
              classes={classes}
              mode={mode}
            />
            {nbChambresRange && (
              <>
                <TitreBloc
                  label={t("Chambres")}
                  icon={
                    <RoomIcon
                      style={{
                        color: theme.palette.tonique,
                      }}
                    />
                  }
                />
                <SelectX
                  refinement={nbChambresRange}
                  label={t("Chambres")}
                  onChange={onUpdateRefinements}
                  multiple
                />
              </>
            )}
            {nbPiecesRange && (
              <>
                <TitreBloc
                  label={t("Pièces")}
                  icon={
                    <RoomIcon
                      style={{
                        color: theme.palette.tonique,
                      }}
                    />
                  }
                />
                <SelectX
                  refinement={nbPiecesRange}
                  label={t("Pièces")}
                  onChange={onUpdateRefinements}
                  multiple
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {endPointConseiller !== "" && (
              <>
                <TitreBloc
                  label={t("Conseillers")}
                  icon={
                    <AgentIcon
                      style={{
                        color: theme.palette.tonique,
                      }}
                    />
                  }
                />

                <Conseillers
                  refinementsObject={_refinementsObject}
                  t={t}
                  gridFilter={classes.gridFilter}
                  onUpdateRefinements={onUpdateRefinements}
                  endPoint={endPointConseiller}
                  formatOptionsListe={formatOptionsListe}
                />
              </>
            )}
            <Localisation
              refinementsObject={_refinementsObject}
              t={t}
              gridFilter={classes.gridFilter}
              onUpdateLocalisations={onUpdateLocalisations}
            />
            <Surfaces
              surfaceHabitableMin={surfaceHabitableMin}
              surfaceHabitableMax={surfaceHabitableMax}
              surfaceTerrainMin={surfaceTerrainMin}
              surfaceTerrainMax={surfaceTerrainMax}
              t={t}
              classes={classes}
              onUpdateRefinements={onUpdateRefinements}
            />
            <Budget
              prixMin={prixMin}
              prixMax={prixMax}
              t={t}
              classes={classes}
              onUpdateRefinements={onUpdateRefinements}
            />
            <TitreBloc
              label={"Autre"}
              icon={
                <ExtensionIcon
                  style={{
                    color: theme.palette.tonique,
                  }}
                />
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: theme.palette.primary.main }}
                  onChange={handleCheckboxChange}
                  name="withParking"
                  inputProps={{ "aria-label": "checkbox" }}
                  defaultChecked={withParking.value}
                />
              }
              label={"Stationnement"}
            />

            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: theme.palette.primary.main }}
                  onChange={handleCheckboxChange}
                  name="withElevator"
                  inputProps={{ "aria-label": "checkbox" }}
                  defaultChecked={withElevator.value}
                />
              }
              label={"Ascenseur"}
            />
            {withSingleStorey != null && (
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: theme.palette.primary.main }}
                    onChange={handleCheckboxChange}
                    name="withSingleStorey"
                    inputProps={{ "aria-label": "checkbox" }}
                    defaultChecked={withSingleStorey.value}
                  />
                }
                label={"Plain-Pied"}
              />
            )}
            {withPool != null && (
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: theme.palette.primary.main }}
                    onChange={handleCheckboxChange}
                    name="withPool"
                    inputProps={{ "aria-label": "checkbox" }}
                    defaultChecked={withPool.value}
                  />
                }
                label={"Piscine"}
              />
            )}
          </Grid>
        </Grid>

        <div style={{ height: "20px" }}>&nbsp;</div>

        <div
          style={{
            alignItems: "center",
            backgroundColor: "white",
            borderTop: "1px solid #091722",
            bottom: "0px",
            display: "flex",
            height: "50px",
            justifyContent: "center",
            position: "sticky",
            width: "100%",
            zIndex: 999,
          }}
        >
          <NombreDeResultats
            nombreDeResultats={biensTotal}
            labelSingulier={"bien"}
            labelPluriel={"biens"}
          />
        </div>
      </div>
    </ContextualSideBar>
  );
};

export default ContextualSideBarBiens;
