import { Grid, useMediaQuery } from "@material-ui/core";
import React from "react";
import { useServices } from "services";
import { ITheme } from "theme/ts/main";

import { RefinementField } from "../../../../components/RefinementField";
import {
  AdaptedRefinement,
  AdaptedRefinementDisplay,
  AdaptedRefinements,
  AutoCompleteText,
  Refinement,
} from "../../../../components/RefinementField/types";

export interface SearchBarProps<T extends string> {
  entityName: string;
  adaptedRefinements: Record<T, AdaptedRefinement>;
  displayMode: AdaptedRefinementDisplay;
  onChange: (value: { key: T; value: Refinement }) => void;
}

const getL10nCriteriaKey = (entityName: string) => <T extends string>(key: T) =>
  `${entityName}.Criteria.${key}`;

const SearchBar = <T extends string>({
  adaptedRefinements,
  entityName,
  displayMode,
  onChange,
}: SearchBarProps<T>) => {
  const isMediumOrSmaller = useMediaQuery((theme: ITheme) =>
    theme.breakpoints.down("md")
  );

  const {
    l10n: { t },
  } = useServices();

  const sortedRefinementsKeys = (Object.keys(adaptedRefinements) as T[])
    .filter((k) => adaptedRefinements[k].displayMode === displayMode)
    .sort(
      (a, b) =>
        adaptedRefinements[a as T].rank - adaptedRefinements[b as T].rank
    );

  const displayedRefinementsKeys = isMediumOrSmaller
    ? [sortedRefinementsKeys[0]]
    : sortedRefinementsKeys;

  const getAutocompleteProps = (refinement: AutoCompleteText) => ({
    getOptions: refinement.getOptions,
  });

  return (
    <Grid container spacing={1}>
      {displayedRefinementsKeys.map((k) => (
        <Grid
          item
          xs={
            isMediumOrSmaller
              ? 12
              : adaptedRefinements[k as keyof AdaptedRefinements<T>].size
          }
          key={`search-bar-${k}`}
        >
          <RefinementField
            onChange={(v) => onChange({ key: k as T, value: v })}
            value={adaptedRefinements[k as T].refinement}
            label={t(getL10nCriteriaKey(entityName)(k) as T)}
            autoComplete={
              adaptedRefinements[k as T].refinement.kind === "autocomplete-text"
                ? getAutocompleteProps(
                    adaptedRefinements[k as T].refinement as AutoCompleteText
                  )
                : undefined
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default SearchBar;
