import { byMultipleText } from "utils/adaptedRefinements/multipleText";
import {
  AdaptedRefinements,
  MultipleText,
} from "../../../components/RefinementField/types";
import { ProfilesCriteria } from "../../../types";
import { ProfilesAggregationsDto } from "../dto/ProfilesAggregationsDto";

export const updateRefinementsFromAggregations = (
  prev: AdaptedRefinements<ProfilesCriteria>,
  aggs: ProfilesAggregationsDto,
  labelTranslator: (_: string) => string = (_) => _
): AdaptedRefinements<ProfilesCriteria> => {
  const criterias = Object.keys(aggs) as ProfilesCriteria[];
  return criterias.filter(byMultipleText(prev)).reduce((current, criteria) => {
    const relatedRefinementValue = (refKey: ProfilesCriteria, aggKey: string) =>
      prev[refKey]
        ? (prev[refKey].refinement as MultipleText).value.find(
            (_) => _.value === aggKey
          )
        : null;

    const updated: AdaptedRefinements<ProfilesCriteria> = {
      ...current,
      [criteria]: {
        ...current[criteria],
        refinement: {
          ...current[criteria].refinement,
          value: aggs[criteria].map(({ key, value }) => ({
            ...current[criteria].refinement,
            count: value,
            isRefined:
              relatedRefinementValue(criteria, key)?.isRefined || false,
            label: labelTranslator(key),
            value: key,
          })),
        },
      },
    };

    return updated;
  }, prev);
};
