import axios, { AxiosInstance, AxiosResponse, CancelToken } from "axios";
import Qs from "qs";

import { IHttpService, Payload } from "./index";

export class HttpService implements IHttpService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create();
    // Request interceptor for API calls
    this.axiosInstance.interceptors.request.use(
      async (config) => {
        const value = await window.localStorage.getItem("UserFromTremplinX");
        if (value) {
          const keys = JSON.parse(value);
          config.headers = {
            Accept: "application/json",
            Authorization: `Bearer ${keys.access_token}`,
          };
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }

  del<T>(
    endpoint: string,
    payload: Payload | undefined
  ): Promise<AxiosResponse<T>> {
    return payload
      ? this.axiosInstance.delete<T>(endpoint, {
          data: payload.content,
          headers: {
            ...this.axiosInstance.defaults.headers,
            "Content-Type": payload.contentType,
          },
        })
      : this.axiosInstance.delete<T>(endpoint);
  }

  get<T>(
    endpoint: string,
    params: unknown | undefined,
    cancelToken: CancelToken | undefined,
    headers: Record<string, string> | undefined
  ): Promise<AxiosResponse<T>> {
    return this.axiosInstance.get<T>(endpoint, {
      cancelToken: cancelToken,
      headers: {
        ...this.axiosInstance.defaults.headers,
        ...headers,
      },
      params,
      paramsSerializer: (_params) => Qs.stringify(_params, { skipNulls: true }),
    });
  }

  post<T>(
    endpoint: string,
    payload: Payload,
    headers: Record<string, string> | undefined
  ): Promise<AxiosResponse<T>> {
    return this.axiosInstance.post<T>(endpoint, payload.content, {
      headers: {
        ...this.axiosInstance.defaults.headers,
        "Content-Type": payload.contentType,
        ...headers,
      },
    });
  }

  put<T>(
    endpoint: string,
    payload: Payload | undefined,
    headers: Record<string, string> | undefined
  ): Promise<AxiosResponse<T>> {
    return this.axiosInstance.put<T>(endpoint, payload?.content, {
      headers: {
        ...this.axiosInstance.defaults.headers,
        "Content-Type": payload?.contentType,
        ...headers,
      },
    });
  }
}
