import React from "react";

interface SquareStatusIconProps {
  color: string;
}

export const SquareStatusIcon: React.FC<SquareStatusIconProps> = ({
  color,
}) => {
  return (
    <div
      style={{ width: 10, height: 10, borderRadius: 3, background: color }}
    ></div>
  );
};
