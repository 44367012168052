import { EndpointsProvider } from "./interfaces/EndpointsProvider";

export interface ManagerEndpoints {
  features: string;
}

const endpoints: EndpointsProvider<ManagerEndpoints> = (microServices) => ({
  features: `${microServices.tx("manager")}/v1/Features`,
});

export default endpoints;
