import { Grid } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { BU } from "components/BU";
import React from "react";
import { useTranslation } from "services/l10n/useTranslation";
import { EmailLink } from "UI/shared/molecules/EmailLink";
import { PhoneLink } from "UI/shared/molecules/PhoneLink";
import getUrlPhoto from "utils/UrlImage";
import { AgentProps } from "./AgentProps";

const Agent: React.FC<AgentProps> = ({
  lastname,
  firstname,
  phoneNumber,
  photo,
  email,
  isActive,
  businessUnit,
}) => {
  const { t } = useTranslation();
  const urlPhoto = getUrlPhoto(businessUnit, "100x100", photo);

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={2} lg={3}>
          <Avatar
            aria-label="photo conseiller"
            style={{ width: 50, height: 50 }}
          >
            <img src={urlPhoto} width="50" height="auto" alt="face" />
          </Avatar>
        </Grid>
        <Grid item xs={10} lg={9}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <BU bu={businessUnit} />
            </Grid>
            <Grid item xs={12}>
              {" "}
              <Typography variant="h4">{`${firstname} ${lastname}`}</Typography>
            </Grid>
            {isActive && (
              <Grid item xs={12}>
                <PhoneLink value={phoneNumber} />
              </Grid>
            )}
            {isActive && (
              <Grid item xs={12}>
                {" "}
                <EmailLink value={email} textSize="small" />
              </Grid>
            )}
            {!isActive && (
              <Grid item xs={12}>
                <Typography variant="body1">{t("Inactif")}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Agent;
