import { useTheme } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { TitleWithNumberBadge } from "UI/shared/atoms/Badges/TitleWithNumberBadge";
import { DetailedEntityTabsLayout } from "components/Fiche/DetailedEntityTabsLayout";
import useNotifier from "components/Notifier/useNotifier";
import TitrePage from "components/TitrePage";
import { useTabs } from "hooks/useTabs";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import { agentRealEstatesRoutes } from "router/agentRealEstatesRoutes";
import { networkRealEstatesRoutes } from "router/networkRealEstatesRoutes";
import routesNames from "router/routesNames";
import {
  isSellerProfile,
  ProfileReadDto,
} from "routes/app/contacts/contact/components/models/profilesReadDto";
import Notes from "routes/app/contacts/contact/components/Notes";
import { Form } from "routes/app/contacts/contact/components/Profiles/Form";
import { ForSaleForm } from "routes/app/contacts/contact/components/Profiles/ForSaleForm";
import { DisplayedProfileForList } from "routes/app/profiles/adapters/profileAdapter";
import {
  DisplayedProfileForDetails,
  profileAdapterDetails,
} from "routes/app/profiles/adapters/profileAdapterDetails";
import { useServices } from "services";
import { useFeatureFlags } from "services/featureFlags";
import { FeaturesName } from "services/featureFlags/types";
import { useProfiles } from "services/profiles";
import {
  DONE,
  FAILED,
  IN_PROGRESS,
  NONE,
} from "services/profiles/constants/exportProfileStatus";
import { ProfileExportDto } from "services/profiles/dto/PorfileExportDto";
import {
  ReadModelProfilesContextProvider,
  useReadModelProfiles,
} from "services/profiles/readModelProfilesContext";
import { useAgentRealEstatesMatching } from "services/profiles/realEstatesMatching/agent";
import { useNetworkRealEstatesMatching } from "services/profiles/realEstatesMatching/network";
import { useMatching } from "services/profiles/realEstatesMatching/useMatching";
import { ITheme } from "theme/ts/main";
import { EditProfileButton } from "UI/features/profiles/organisms/EditProfileButton";
import { ProfileContactCard } from "UI/features/profiles/organisms/ProfileContactCard";
import { ProfileSummaryCard } from "UI/features/profiles/organisms/ProfileSummaryCard";
import { RealEstates } from "UI/features/profiles/templates/realEstates";
import { ColoredPaperButton } from "UI/shared/atoms/buttons/ColoredPaperButton";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";
import { ErrorPanel } from "UI/shared/templates/Panels/ErrorPanel";
import { ProfileStatusType } from "../../contacts/contact/components/models/profileStatusType";
import {
  DisplayedContactForList,
  fromReadModelContact,
} from "../adapters/contactAdapter";
import { getBuyersTab } from "../utils/getBuyersTab";
import { getHelpPage } from "../utils/getHelpPage";
import { getPageTitle } from "../utils/getPageTitle";
import { getProfileDetailsActionButtonProps } from "../utils/getProfileDetailsActionButtonProps";
import { getProfileDetailsTab } from "../utils/getProfileDetailsTab";
import { getProfileMenuForActiveProfile } from "../utils/getProfileMenuForActiveProfile";
import { getProfileMenuForArchivedProfile } from "../utils/getProfileMenuForArchivedProfile";
import { useMatchingProfiles } from "./useMatchingProfiles";
import { RealEstateMatchingDto } from "services/profiles/dto/RealEstateMatchingDto";

export const ProfileDetailsPage: React.FC = () => {
  return (
    <ReadModelProfilesContextProvider>
      <ProfileDetails />
    </ReadModelProfilesContextProvider>
  );
};

type TabName = "details" | "matching";

const displayExportButton = (
  featureFlags: Record<FeaturesName, boolean>,
  profile?: DisplayedProfileForDetails
) => {
  const isPremisesSeller =
    profile?.profileType === "Seller" &&
    profile?.propertyCategory === "Premises";

  const isRentalSeller =
    profile?.profileType === "Seller" && profile?.businessType === "Location";

  switch (true) {
    case isPremisesSeller:
      return featureFlags.Export_Sale_Premises;
    case isRentalSeller:
      return featureFlags.Export_Rental_Apartment;
    default:
      return true;
  }
};

export const ProfileDetails: React.FC = () => {
  const history = useHistory();
  const featureFlags = useFeatureFlags();
  const theme = useTheme() as ITheme;
  const {
    modal,
    authenticationService: { getUserData },
    l10n: { t, formatSurfaces, formatSurface },
  } = useServices();

  const redirectToPreviousPage = useCallback(() => {
    history.goBack();
  }, [history]);

  const { id: profileId, contactId } = useParams() as any;
  const bu = getUserData()?.Instance;

  const { getById } = useReadModelProfiles();

  const {
    updateNotes,
    exportProfile,
    getProfileExport,
    openLegacyProfile,
  } = useProfiles(contactId);

  const [profile, setProfile] = useState<
    DisplayedProfileForDetails | undefined
  >();

  const [matchingLoading, setMatchingLoading] = useState(false);
  const [matching, setMatching] = useState<RealEstateMatchingDto | undefined>();
  const [contact, setContact] = useState<DisplayedContactForList | undefined>();
  const [readProfile, setReadProfile] = useState<ProfileReadDto | undefined>();
  const [exportStatus, setExportStatus] = useState<{
    legacyId: string;
    status: string;
  }>();
  const [checkExportStatus, setCheckExportStatus] = useState(false);
  const [loadProfileError, setLoadProfileError] = useState(false);
  const { showSnack, hideSnack } = useNotifier();
  const exportStatusPolling = useRef<number | undefined>();

  const updateExportStatus = async () => {
    const response = await getProfileExport(profileId);
    let _status = NONE;
    let _checkStatusExport = true;
    let _legacyId: string | null = null;
    switch (getExportationStatus(response)) {
      case NO_EXPORTATION:
        _checkStatusExport = false;
        break;
      case EXPORTATION_DONE:
        _checkStatusExport = false;
        _legacyId = response.data.legacyId;
        _status = response.data.status;
        break;
      case EXPORTATION_IN_PROGRESS:
        _legacyId = response.data.legacyId;
        _status = response.data.status;
        break;
    }
    setCheckExportStatus(_checkStatusExport);
    setExportStatus((prev: any) => ({
      ...prev,
      ...(_legacyId && { legacyId: _legacyId }),
      status: _status,
    }));
  };

  const loadProfile = useCallback(async () => {
    try {
      setLoadProfileError(false);

      const getByIdResponse = await getById(profileId, contactId);
      updateExportStatus();

      return getByIdResponse.data;
    } catch (e) {
      setLoadProfileError(true);
      setCheckExportStatus(false);
    }
  }, [contactId, getById, profileId]);

  const saveNotes = (notes: string) => {
    updateNotes(profileId, notes).then(() => reloadProfile());
  };

  const {
    consultantBuyers,
    networkBuyers,
    handleConsultantBuyersChange,
    handleNetworkBuyersChange,
    loadMatchingContacts,
  } = useMatchingProfiles(readProfile);

  const matchingService = useMatching();
  const agentMatching = useAgentRealEstatesMatching({ mode: "list" });
  const networkMatching = useNetworkRealEstatesMatching({ mode: "list" });

  const initMatching = useCallback(async () => {
    if (!contactId || !profileId) return;

    setMatchingLoading(true);
    const matchingPayload = await matchingService.fromProfile(
      profileId,
      contactId
    );
    setMatching(matchingPayload);
    setMatchingLoading(false);

    agentMatching.updateParams(matchingPayload);
    networkMatching.updateParams(matchingPayload);
  }, [profileId, contactId]);

  const openAgentRealEstate = (id: string) => {
    history.push(agentRealEstatesRoutes.details(id));
  };

  const openNetworkRealEstate = (id: string) => {
    history.push(networkRealEstatesRoutes.details(id));
  };

  const reloadProfile = useCallback(async () => {
    const readModelProfile = await loadProfile();
    if (!readModelProfile) return;
    setReadProfile(readModelProfile.profile);
    setContact(fromReadModelContact(readModelProfile.contact));
    setProfile(profileAdapterDetails(readModelProfile.profile));
  }, [loadProfile]);

  const profileCard = getProfileCard(profile);
  const contactCard = getContactCard(history, contact);

  useEffect(() => {
    initMatching();
  }, [profileId, contactId]);

  useEffect(() => {
    switch (true) {
      case !profile:
        return;
      case !checkExportStatus:
        stopExportStatusPolling(exportStatusPolling.current);
        return;
      case checkExportStatus && !exportStatusPolling.current:
        exportStatusPolling.current = window.setInterval(() => {
          updateExportStatus();
        }, 1000);
        return;
      default:
        return;
    }
  }, [profile, checkExportStatus]);

  useEffect(() => {
    return () => stopExportStatusPolling(exportStatusPolling.current);
  }, []);

  useEffect(loadMatchingContacts(readProfile), [readProfile]);

  useEffect(() => {
    reloadProfile();
  }, [reloadProfile]);

  useEffect(() => {
    if (exportStatus?.status === IN_PROGRESS) {
      showSnack({
        message: "l'export du profil est en cours",
        variant: "info",
        withLoader: true,
      });
      return;
    }
    hideSnack();
  }, [exportStatus?.status]);

  const buyersTab = isSellerProfile(readProfile)
    ? [
        getBuyersTab(
          consultantBuyers,
          networkBuyers,
          handleConsultantBuyersChange,
          handleNetworkBuyersChange,
          t
        ),
      ]
    : [];

  const realEstateTabProps = {
    profileId,
    byAgent: {
      ...agentMatching,
      open: openAgentRealEstate,
      loading: matchingLoading,
    },
    byNetwork: {
      ...networkMatching,
      open: openNetworkRealEstate,
      loading: matchingLoading,
    },
    matching,
  };

  const realEstatesTab = !isSellerProfile(readProfile)
    ? [
        {
          content: <RealEstates {...realEstateTabProps} />,
          titre: (
            <TitleWithNumberBadge
              number={agentMatching.total + networkMatching.total}
              title={t("Rapprochements")}
            />
          ),
          value: "matching",
        },
      ]
    : [];

  const tabs = [
    getProfileDetailsTab(readProfile, profileId, contactId, t),
    ...buyersTab,
    ...realEstatesTab,
  ];

  const { tab } = useParams<{ tab: TabName }>();

  const { activeTabIndex, onSelectedTabChanged, onTabsChange } = useTabs<
    TabName
  >(tabs, tab, routesNames.profilePageTabFunc(contactId, profileId));

  const notesCard = useMemo(
    () => <Notes content={profile?.notes || ""} saveContent={saveNotes} />,
    [profile?.notes]
  );

  const openEditProfileModal = async () => {
    const readModelProfile = await loadProfile();
    if (!readModelProfile) return;

    const profileToUpdate = {
      ...readModelProfile.profile,
    } as ProfileReadDto;

    const profileForm = getCorrespondingForm(
      profile?.profileType,
      contactId,
      profileToUpdate,
      reloadProfile
    );

    modal.show(profileForm);
  };

  const getActionsFromProfileStatus = (status: ProfileStatusType) => {
    if (status === "Archived") {
      return getProfileMenuForArchivedProfile(
        contactId,
        readProfile as ProfileReadDto,
        reloadProfile
      );
    }

    return getProfileMenuForActiveProfile(
      contactId,
      readProfile as ProfileReadDto,
      reloadProfile,
      openEditProfileModal
    );
  };

  const actions = profile ? getActionsFromProfileStatus(profile.status) : [];

  const exportButton =
    displayExportButton(featureFlags, profile) && exportStatus ? (
      <ColoredPaperButton
        {...getProfileDetailsActionButtonProps(
          exportStatus.status,
          profileId,
          bu,
          t,
          theme,
          openLegacyProfile,
          exportProfile,
          setCheckExportStatus,
          exportStatus,
          profile
        )}
      />
    ) : (
      <></>
    );

  useEffect(() => {
    onTabsChange();
  }, [onTabsChange, tabs.length]);

  if (loadProfileError)
    return (
      <ErrorPanel
        title={"Service CRM Profils"}
        text={"Impossible de lire ce profil."}
      />
    );

  return (
    <div>
      <DetailedEntityTabsLayout
        composantsBarreGauche={[
          {
            composant: profileCard,
          },
          {
            composant: <div style={{ marginBottom: 20 }} />,
          },
          {
            composant: contactCard,
          },
          {
            composant: <div style={{ marginBottom: 20 }} />,
          },
          {
            composant: exportButton,
          },
          {
            composant: <div style={{ marginBottom: 20 }} />,
          },
          {
            composant: notesCard,
          },
        ]}
        titre={
          <PaddingContainer top={5} left={5}>
            <TitrePage
              categorie="CRM | Profil"
              page={getPageTitle(formatSurfaces, formatSurface, t, profile)}
              filePath={getHelpPage(profile?.profileType, bu, activeTabIndex)}
            />
          </PaddingContainer>
        }
        tabs={tabs}
        actions={actions}
        tabActiveIndex={activeTabIndex}
        onClose={redirectToPreviousPage}
        onSelectedTabChanged={onSelectedTabChanged}
      />
      {profile && profile.status !== "Archived" && (
        <EditProfileButton onClick={openEditProfileModal} />
      )}
    </div>
  );
};

const getProfileCard = (profile?: DisplayedProfileForList) =>
  profile ? <ProfileSummaryCard {...profile} /> : <></>;

const getContactCard = (
  history: RouteComponentProps["history"],
  contact?: DisplayedContactForList
) =>
  contact ? (
    <div
      style={{ textDecoration: "none" }}
      onClick={() =>
        history.push(
          `${routesNames.app}${routesNames.contact}/${contact?.id}/identity/view`
        )
      }
    >
      <ProfileContactCard contact={contact} />
    </div>
  ) : (
    <></>
  );

const stopExportStatusPolling = (exportStatusPolling?: number) => {
  if (exportStatusPolling) window.clearInterval(exportStatusPolling);
};

const NO_EXPORTATION = "No exportation";
const EXPORTATION_IN_PROGRESS = "Exportation in progress";
const EXPORTATION_DONE = "Exportation done";

const getExportationStatus = (
  profileExportResponse: AxiosResponse<ProfileExportDto>
) => {
  if (!profileExportResponse.data) return NO_EXPORTATION;
  if (
    profileExportResponse.data &&
    [NONE, DONE, FAILED].includes(profileExportResponse.data.status)
  )
    return EXPORTATION_DONE;
  return EXPORTATION_IN_PROGRESS;
};

const getCorrespondingForm = (
  profileType: string | undefined,
  contactId: string,
  profileToUpdate: ProfileReadDto,
  reloadProfile: () => void
) =>
  profileType === "Search" ? (
    <Form
      contactId={contactId}
      profile={profileToUpdate}
      mode="update"
      handleAfterUpdate={reloadProfile}
    />
  ) : (
    <ForSaleForm
      contactId={contactId}
      profile={profileToUpdate}
      mode="update"
      handleAfterUpdate={reloadProfile}
    />
  );
