import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((_) => ({
  customPanelCard: {
    width: 478,
  },
  miniPanelCard: { width: "100%" },
  miniPanelCardContent: { textAlign: "left" },
  miniPanelCardContentInner: { display: "flex" },
  miniPanelImgContainer: { textAlign: "center", width: "30%" },
  miniPanelTypoContainer: { textAlign: "left" },
  panelCard: {
    height: 415,
    width: 315,
  },
  panelCardContent: { textAlign: "center" },
  panelContainer: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "relative",
    width: "100%",
  },
  panelImg: {
    height: "auto",
    paddingTop: 20,
    width: "205px",
  },
  panelImgContainer: { textAlign: "center", width: "100%" },
  panelLastTypo: { marginBottom: 20, marginTop: 20 },
  smallPanelImg: {
    height: "auto",
    width: "130px",
  },
}));

export interface ErrorPanelProps {
  title: string;
  text: string;
  mini?: boolean;
}

export const ErrorPanel: React.FC<ErrorPanelProps> = ({
  title,
  text,
  mini = false,
}) => {
  return mini ? (
    <MiniErrorPanel title={title} text={text} />
  ) : (
    <StandardErrorPanel title={title} text={text} />
  );
};

export interface CustomErrorPanelProps {
  title: string;
  children: React.ReactNode;
}

export const CustomErrorPanel: React.FC<CustomErrorPanelProps> = ({
  title,
  children,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.panelContainer}>
      <Card className={classes.customPanelCard}>
        <div className={classes.panelImgContainer}>
          <img
            src={"/static/images/erreur.png"}
            alt={"erreur"}
            className={classes.panelImg}
          />
        </div>
        <CardContent className={classes.panelCardContent}>
          <Typography variant={"h2"}>OUPS !</Typography>
          <Typography variant={"h2"}>{title}</Typography>
          {children}
        </CardContent>
      </Card>
    </div>
  );
};

const StandardErrorPanel: React.FC<ErrorPanelProps> = ({ title, text }) => {
  const classes = useStyles();
  return (
    <div className={classes.panelContainer}>
      <Card className={classes.panelCard}>
        <div className={classes.panelImgContainer}>
          <img
            src={"/static/images/erreur.png"}
            alt={"erreur"}
            className={classes.panelImg}
          />
        </div>
        <CardContent className={classes.panelCardContent}>
          <Typography variant={"h2"}>OUPS !</Typography>
          <Typography variant={"h2"}>{title}</Typography>
          <Typography variant={"h3"}>{text}</Typography>
          <Typography variant={"h2"} className={classes.panelLastTypo}>
            Vous pouvez continuer à utiliser les autres fonctionnalités de
            l&apos;outil.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

const MiniErrorPanel: React.FC<ErrorPanelProps> = ({ title, text }) => {
  const classes = useStyles();
  return (
    <div className={classes.panelContainer}>
      <Card className={classes.miniPanelCard}>
        <CardContent>
          <div className={classes.miniPanelCardContentInner}>
            <div className={classes.miniPanelImgContainer}>
              <img
                src={"/static/images/erreur.png"}
                alt={"erreur"}
                className={classes.smallPanelImg}
              />
            </div>
            <div className={classes.miniPanelTypoContainer}>
              <Typography variant={"h6"}>OUPS !</Typography>
              <Typography variant={"h5"}>{title}</Typography>
              <Typography variant={"h6"}>{text}</Typography>
              <Typography variant={"h6"} className={classes.panelLastTypo}>
                Vous pouvez continuer à utiliser les autres fonctionnalités de
                l&apos;outil.
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
