import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import ConteneurCarte from "components/ConteneurCarte";
import React from "react";

const MiniCarteInfo = ({
  Icon,
  valeur,
  labelPrimaire,
  onClick,
  isGlobalSearch,
  labelSecondaire = "",
}) => {
  const theme = useTheme();
  const tailleIcone = useMediaQuery("(max-width:600px)") ? 18 : 24;
  const classes = {
    conteneur: {
      backgroundColor: theme.palette.default,
    },
    icone: {
      color: "white",
      fontSize: tailleIcone,
    },
    label: {
      color: "white",
      height: labelSecondaire === " " ? "23px" : "inherit",
    },
    valeur: {
      color: theme.palette.tonique,
      textAlign: "center",
    },
  };
  return (
    <ConteneurCarte
      backgroundColor={theme.palette.default}
      isGlobalSearch={isGlobalSearch}
      onClick={onClick}
    >
      <Grid container spacing={2} alignItems={"flex-start"}>
        {Icon && (
          <Grid item xs={2}>
            <Icon style={classes.icone} />
          </Grid>
        )}
        <Grid item xs={Icon ? 10 : 12}>
          <Grid container direction={"column"} spacing={0}>
            <Typography
              variant={"h3"}
              style={{ ...classes.label, fontWeight: "bold" }}
            >
              {labelPrimaire}
            </Typography>
            {labelSecondaire !== "" && (
              <Typography variant={"body1"} style={classes.label}>
                {labelSecondaire}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={0} justify={"center"} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography variant={"h1"} style={classes.valeur}>
            {valeur}
          </Typography>
        </Grid>
      </Grid>
    </ConteneurCarte>
  );
};

export default MiniCarteInfo;
