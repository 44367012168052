import { Typography } from "@material-ui/core";
import React from "react";

import { OrangeContainer } from "UI/shared/atoms/containers/OrangeContainer";
import { OrangeTitle } from "UI/shared/atoms/OrangeTitle";

export interface OrangeWarningNoteProps {
  title: string;
  content: string;
}

export const OrangeWarningNote: React.FC<OrangeWarningNoteProps> = ({
  title,
  content,
}) => {
  return (
    <OrangeContainer>
      <OrangeTitle label={title} />
      <div style={{ paddingTop: "5px" }}>
        <Typography variant={"body2"} align={"justify"}>
          {content}
        </Typography>
      </div>
    </OrangeContainer>
  );
};
