import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "initial",
  },
  dialogCustomizedWidth: {
    "max-width": "40%",
  },
  displayContact: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
  },
  fab: {
    backgroundColor: theme.palette.tonique,
    bottom: theme.spacing(2),
    color: "white",
    position: "fixed",
    right: theme.spacing(2),
  },
  formButtonWrapper: {
    alignSelf: "center",
    display: "flex",
    paddingBottom: "15px",
  },
  goUpButton: {
    backgroundColor: "rgb(0,0,0,0.5)",
    bottom: theme.spacing(2),
    color: "white",
    position: "fixed",
    [theme.breakpoints.down("md")]: {
      left: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      left: ({ drawerOpen }) =>
        drawerOpen ? theme.spacing(32) : theme.spacing(9),
    },
  },
  gras: {
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  icons: {
    fontSize: "20px",
    [theme.breakpoints.up("md")]: {
      fontSize: "30px",
    },
  },
  link: {
    fontSize: "1.5rem",
  },
  normal: {
    fontSize: "1rem",
  },
  normalPreWrap: {
    fontSize: "1rem",
    whiteSpace: "pre-wrap",
  },
  sortSelectRoot: {
    alignSelf: "flex-end",
    borderBottom: ({ sortSelectFocused }) =>
      `1px solid ${
        sortSelectFocused
          ? theme.palette.primary.main
          : theme.palette.notSelected.grey
      }`,
    height: "fit-content",
    marginBottom: "10px",
    marginLeft: "auto",
  },
}));

export default useStyles;
