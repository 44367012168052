import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import { useServices } from "../../../../../services";

const useStyles = makeStyles(() => ({
  background: {
    backgroundColor: "#FFFFFF",
    border: "solid 1px #C0A176",
    marginTop: 2,
    width: 80,
  },
  chipLabel: {
    color: "#C0A176",
    fontSize: "0.9rem",
    fontWeight: "bold",
  },
}));

const Prestige = () => {
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles();

  return (
    <Chip
      label={t("Prestige")}
      size="small"
      className={classes.background}
      classes={{ label: classes.chipLabel }}
    />
  );
};

export default Prestige;
