import React, { createContext } from "react";

import { PigeAdEventsContextProvider } from "./PigeAdEventsContextProvider";
import { PigeConversionContextProvider } from "./PigeConversionContextProvider";
import { PigeFavorisContextProvider } from "./PigeFavorisContextProvider";
import { PigeVisibilityContextProvider } from "./PigeVisibilityContextProvider";

export const PigeContext = createContext({});

export const PigeContextProvider: React.FC = ({ children }) => {
  return (
    <PigeContext.Provider value={{}}>
      <PigeFavorisContextProvider>
        <PigeVisibilityContextProvider>
          <PigeAdEventsContextProvider>
            <PigeConversionContextProvider>
              {children}
            </PigeConversionContextProvider>
          </PigeAdEventsContextProvider>
        </PigeVisibilityContextProvider>
      </PigeFavorisContextProvider>
    </PigeContext.Provider>
  );
};
