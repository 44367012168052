import React, { CSSProperties } from "react";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const priceEvolutions = {
  REDUCTION: "Baisse",
  NOT_CONCERNED: "NonConcerne",
  RISE: "Hausse",
};

interface PriceEvolutionProps {
  evolutionType?: string;
}

const styles: Record<string, CSSProperties> = {
  priceReduction: {
    position: "relative",
    top: "0.25rem",
    color: "#22bb33",
  },
};

const PriceEvolution: React.FC<PriceEvolutionProps> = ({
  evolutionType = "",
}) => {
  return evolutionType === priceEvolutions.REDUCTION ? (
    <ArrowDownwardIcon fontSize="small" style={styles.priceReduction} />
  ) : (
    <></>
  );
};

export default PriceEvolution;
