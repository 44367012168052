import React from "react";
import "./applocking.css";
import { Dialog, Paper, Typography } from "@material-ui/core";

const AppLocking = ({ children, label, locked }) => {
  return (
    <>
      {locked && (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          fullScreen={false}
          scroll="body"
          open={true}
          onClose={() => {}}
          aria-labelledby="Application Bloquée"
        >
          <Paper
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: 30,
            }}
          >
            <Typography variant={"h2"}>{label}</Typography>
          </Paper>
        </Dialog>
      )}

      {children}
    </>
  );
};

export default AppLocking;
