import { Typography } from "@material-ui/core";
import React from "react";
import useConseillerObtenirParId from "routes/app/mon-reseau/conseillers/services/conseillerObtenirParId";

import AvatarConseiller from "../Avatar";

const AvatarPetit = ({ id }) => {
  const { error, loading, item: conseiller } = useConseillerObtenirParId({
    id,
  });

  if (error) return <Typography variant={"h6"}>Erreur</Typography>;
  if (loading) return <Typography variant={"h6"}>Chargement...</Typography>;

  return <AvatarConseiller conseiller={conseiller} taille={30} />;
};

export default AvatarPetit;
