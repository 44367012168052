import { Grid } from "@material-ui/core";
import React, { FC } from "react";

import { SummariesDto } from "../../models/summariesDto";
import { ValidationNumberField } from "../components/ValidationNumberField";
import { ProfileInputProps } from "../InputProps";

export const SaleRoomsInput: FC<ProfileInputProps<SummariesDto>> = ({
  value,
  onChange,
  pristine,
  disabled = false,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <ValidationNumberField
          disabled={disabled}
          pristine={pristine}
          label="Nombre de pièces"
          onChange={(isValid, roomsCount) => {
            onChange({ ...value, rooms: { count: roomsCount || 0 } }, isValid);
          }}
          value={value.rooms?.count}
          required={true}
          minLimit={1}
          maxLimit={100}
        />
      </Grid>
    </>
  );
};
