import React from "react";
import { useServices } from "services";
import { DeleteChip } from "UI/shared/atoms/chips/DeleteChip";
import { RefinementChip } from "UI/shared/atoms/chips/RefinementChip";
import { If } from "UI/shared/atoms/directives/If";

import {
  Locations,
  Refinement,
  Refinements,
} from "../../../../components/RefinementField/types";

export interface CriteriasProps<T extends string> {
  refinements: Refinements<T>;
  onChange: (params: { key: T; value: Refinement }) => void;
  onClear: () => void;
}

const hasRefinements = <T extends string>(refinements: Refinements<T>) => {
  return (!!refinements && (Object.values(refinements) as Refinement[])).some(
    (refinement) => {
      switch (refinement.kind) {
        case "single-text":
          return (refinement.value || "").trim().length > 0;
        case "multiple-text":
          return refinement.value.some((_) => _.isRefined);
        case "checkbox":
          return !!refinement.value;
        case "price":
          return !!refinement.value;
        case "number-range":
        case "date-range":
          return !!refinement.value?.min || !!refinement.value?.max;
        case "locations":
          return (
            !!refinement &&
            !!refinement.value &&
            Object.keys(refinement.value).some((key) => {
              const currentKey = key as keyof Locations["value"];
              return (
                refinement.value[currentKey].length > 0 &&
                refinement.value[currentKey].some(
                  (valueItem) => !!valueItem && valueItem.isRefined
                )
              );
            })
          );
        case "autocomplete-text":
          return (refinement.value || "").trim().length > 0;
        case "phone-number":
          return (refinement.value || "").trim().length > 0;
        default:
          return false;
      }
    }
  );
};
export const Criterias = <T extends string>({
  refinements,
  onChange,
  onClear,
}: CriteriasProps<T>): JSX.Element => {
  const onChangeHandler = (key: T) => (value: Refinement) => {
    onChange({ key, value });
  };
  const {
    l10n: { formatDateRange, formatBudget, formatSurfacesRange },
  } = useServices();

  const refinementKeys = Object.keys(refinements) as T[];
  const displayedChips = refinementKeys.filter(
    (r) => refinements[r].displayChip !== false
  );

  const getFormatterFromKind = (
    kind: Refinement["kind"]
  ): ((value: any) => string) => {
    switch (true) {
      case kind === "date-range":
        return (value) => formatDateRange(value.min, value.max);
      case kind === "number-range":
        return (value) =>
          value.unit === "m²"
            ? formatSurfacesRange(value.min, value.max)
            : formatBudget(value.min, value.max);
      default:
        return (value) => value;
    }
  };

  return (
    <div style={{ flex: 1 }}>
      {displayedChips.map((key) => {
        const formatter = getFormatterFromKind(refinements[key].kind);
        return (
          <RefinementChip
            key={`refinement-chip-${key}`}
            value={refinements[key]}
            onChange={onChangeHandler(key)}
            format={formatter}
          />
        );
      })}

      <If condition={hasRefinements(refinements)}>
        <DeleteChip onClear={onClear} />
      </If>
    </div>
  );
};
