import {
  AdaptedRefinements,
  MultipleText,
} from "../../../components/RefinementField/types";
import { RequestForInformationsCriteria } from "../../../types";
import { RequestForInformationsFiltersDto } from "../dto/RequestForInformationsFiltersDto";
import { isDefaultValue } from "./isDefaultValue";

export const getFiltersFromRefinements = (
  adaptedRefinements: AdaptedRefinements<RequestForInformationsCriteria>
) => {
  return (Object.keys(
    adaptedRefinements
  ) as RequestForInformationsCriteria[]).reduce((acc, k) => {
    if (isDefaultValue(adaptedRefinements[k].refinement.value)) return acc;

    if (adaptedRefinements[k].refinement.kind === "multiple-text") {
      const refinedValues = (adaptedRefinements[k]
        .refinement as MultipleText).value
        .filter((_) => _.isRefined)
        .map((_) => _.value);

      return {
        ...acc,
        [k]: refinedValues,
      };
    }
    return { ...acc, [k]: adaptedRefinements[k].refinement.value };
  }, {}) as RequestForInformationsFiltersDto;
};
