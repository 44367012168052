import { Typography } from "@material-ui/core";
import { NumberField } from "components/NumberField";
import { isUndefined } from "lodash";
import React, { FC, useEffect } from "react";
import { useServices } from "services";
import { formatPrix } from "utils/formatage";

import { BudgetPurchaseDto } from "../../../models/budgetPurchaseDto";
import {
  isGreaterThanOrEquals,
  isLessThanOrEqualsThreshold,
  isMandatory,
  isStrictlyPositive,
} from "../../components/formValidators";
import { SectionTitle } from "../../components/SectionTitle";
import { ProfileInputProps } from "../../InputProps";

export const PurchaseBudget: FC<{ budget: BudgetPurchaseDto }> = ({
  budget,
}) => (
  <>
    {budget.min! > 0 && (
      <Typography variant="h5">
        Budget min (TTC) : {formatPrix(budget.min)}
      </Typography>
    )}
    {budget.max! > 0 && (
      <Typography variant="h5">
        Budget (TTC) : {formatPrix(budget.max)}
      </Typography>
    )}
    {budget.maxMatching! > 0 && (
      <Typography variant="body1">
        Budget max (TTC) : {formatPrix(budget.maxMatching)}
      </Typography>
    )}
  </>
);

function validate(t: (s: string) => string, value: BudgetPurchaseDto) {
  return {
    max: {
      isValid:
        isStrictlyPositive(value.max) === "" ||
        isLessThanOrEqualsThreshold(30000000, value.max) === "",
      reason:
        isStrictlyPositive(value.max) === ""
          ? isLessThanOrEqualsThreshold(30000000, value.max)
          : isStrictlyPositive(value.max),
    },
    maxMatching: {
      isValid:
        isUndefined(value.maxMatching) ||
        value.maxMatching === 0 ||
        isGreaterThanOrEquals(value.max, value.maxMatching) === "" ||
        isLessThanOrEqualsThreshold(30000000, value.maxMatching) === "",
      reason:
        isUndefined(value.maxMatching) || value.maxMatching === 0
          ? ""
          : isGreaterThanOrEquals(value.max, value.maxMatching) === ""
          ? isLessThanOrEqualsThreshold(30000000, value.maxMatching)
          : isGreaterThanOrEquals(value.max, value.maxMatching),
    },
    min: {
      isValid:
        isUndefined(value.min) ||
        value.min === 0 ||
        isGreaterThanOrEquals(value.min, value.max) === "" ||
        isLessThanOrEqualsThreshold(30000000, value.min) === "",
      reason:
        isUndefined(value.min) || value.min === 0
          ? ""
          : isGreaterThanOrEquals(value.min, value.max) === ""
          ? isLessThanOrEqualsThreshold(30000000, value.min)
          : isGreaterThanOrEquals(value.min, value.max),
    },
  };
}

export const PurchaseBudgetInput: FC<ProfileInputProps<BudgetPurchaseDto>> = ({
  pristine,
  value,
  onChange,
}) => {
  const { l10n } = useServices();

  const errors = validate(l10n.t, value);

  const isValid =
    errors.max.isValid && errors.maxMatching.isValid && errors.min.isValid;

  useEffect(() => {
    onChange(value, isValid);
  }, [isValid]);

  return (
    <>
      <SectionTitle title="Quel est votre budget ?" />

      <NumberField
        helperText={pristine ? isMandatory(value.max) : errors.max.reason}
        error={pristine ? false : !errors.max.isValid}
        label="Budget (TTC)"
        onChange={(max) => {
          onChange(
            {
              max,
              maxMatching: max ? max * 1.2 : max,
              min: max ? max * 0.8 : max,
            },
            isValid
          );
        }}
        required={true}
        value={value.max}
        adornement={"€"}
      />

      <NumberField
        helperText={pristine ? "" : errors.min.reason}
        error={pristine ? false : !errors.min.isValid}
        label="Budget min. (TTC)"
        onChange={(min) => onChange({ ...value, min }, isValid)}
        value={value.min}
        adornement={"€"}
      />

      <NumberField
        helperText={pristine ? "" : errors.maxMatching.reason}
        error={pristine ? false : !errors.maxMatching.isValid}
        label="Budget max. pour rapprochement (TTC)"
        onChange={(maxMatching) => onChange({ ...value, maxMatching }, isValid)}
        value={value.maxMatching}
        adornement={"€"}
      />
    </>
  );
};
