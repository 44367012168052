import { array, bool, number, object, string } from "api/DTOs/Common/Types";
import conseillerAdapter from "services/rapprochement/Rapprochement/ConseillerAdapter";
import villeAdapter from "services/rapprochement/Rapprochement/VilleAdapter";
import { PropertyForSale } from "types";

function obtenirParIdAdapter(result: any = {}): PropertyForSale {
  return {
    anneeConstruction: result.anneeConstruction || number,
    annonceDesc: result.texte || string,
    bu: result.bu || string,
    conseillerPrincipal:
      result.conseillers && result.conseillers.length > 0
        ? conseillerAdapter(result.conseillers[0])
        : conseillerAdapter(),
    conseillers: result.conseillers
      ? result.conseillers.map((conseiller: any) =>
          conseillerAdapter(conseiller)
        )
      : array,
    dateAjout: result.dateAjout || string,
    dateModification: result.dateModification || string,
    debutMandat: result.debutMandat || string,
    dpeLettre: result.dpeLettre || string,
    dpeValeur: result.dpeValeur || string,
    etatGeneral: result.etatGeneral || string,
    exposition: result.exposition || string,
    externalId: result.externalId || string,
    familleBienEnum: result.familleBienEnum || string,
    gesLettre: result.gesLettre || string,
    gesValeur: result.gesValeur || string,
    id: result.id || number,
    isTvb: result.isTvb || bool,
    latitude: result.latitude || number,
    localisation: result.localisation
      ? villeAdapter(result.localisation)
      : villeAdapter(),
    longitude: result.longitude || number,
    loyerCC: result.loyerCC || number,
    nbChambres: result.nbChambres || number,
    nbPieces: result.nbPieces || number,
    isCE: result.isCE || bool,
    neuf: result.neuf || bool,
    numMandat: result.numMandat || string,
    optionMandatEnum: result.optionMandatEnum || string,
    photoGuid: result.photoGuid || array,
    photoCover: result.photoNeufGuid || string,
    photoNeufGuid: result.photoNeufGuid || string,
    prestations: result.prestations || object,
    prestige: result.prestige || bool,
    prix: result.prix || number,
    prixInitial: result.prixInitial || number,
    proximite: result.proximite || object,
    statutTransactionEnum: result.statutTransactionEnum || string,
    surface: result.surface || number,
    surfaceHabitable: result.surfaceHabitable || number,
    surfaceTerrain: result.surfaceTerrain || number,
    surfaceTotale: result.surfaceTotale || number,
    surfacesInterieures: result.surfacesInterieures || object,
    typeAchatEnum: result.typeAchatEnum || string,
    typeAffaireEnum: result.typeAffaireEnum || string,
    typeBienNom: result.typeBienNom || string,
    typeMandatEnum: result.typeMandatEnum || string,
    lat: result.latitude || number,
    long: result.longitude || number,
    dateDernierAvenant: result.dateDernierAvenant || string,
    dateEffectiveCompromis: result.dateEffectiveCompromis || string,
    dateEffectiveSignature: result.dateEffectiveSignature || string,
    bienEnCopropriete: false,
    chargesCopropriete: 0,
    codeAffaire: "",
    exclusivite: result.exclusivite || bool,
    lienAnnonce: "",
    nbLotsCopropriete: 0,
    nbSallesDeBain: 0,
    nbSallesDeau: 0,
    nbWc: 0,
    underOfferToSell: result.underOfferToSell || bool,
    hasOffer: result.hasOffer || bool,
    avecAscenseur: result.avecAscenseur || bool,
    avecStationnement: result.avecStationnement || bool,
    withPool: result.withPool,
    withSingleStorey: result.withSingleStorey,
  };
}

export default obtenirParIdAdapter;
