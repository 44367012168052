import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { PigesSearchParams, usePiges } from "services/piges";
import { AnnonceInList, PigeSearchResult } from "types";

export interface DataState {
  items: AnnonceInList[];
  result: PigeSearchResult;
  hasError: boolean;
  pending: boolean;
}

const initialState = {
  hasError: false,
  items: [],
  pending: false,
  result: {},
};

const hasInvalidPrices = ({ prixMin, prixMax }: PigesSearchParams) => {
  return prixMax !== undefined && prixMin !== undefined && prixMax < prixMin;
};

const isLastPage = (searchAfter?: string[]) => (searchAfter || []).length === 0;

const usePigeData = (params: PigesSearchParams, searchEnabled: boolean) => {
  const { search } = usePiges();
  const [data, setData] = useState<DataState>(initialState);
  const [isFirst, setIsFirst] = useState(true);
  const paramsRef = useRef<PigesSearchParams>();

  const prepareSearch = () => {
    setData((prev) => ({
      ...prev,
      hasError: false,
      pending: true,
    }));
  };

  const endSearch = (searchResult: PigeSearchResult) => {
    const nextAds = searchResult.data || [];
    setData((prev) => {
      return {
        ...prev,
        items: isLastPage(params.searchAfter)
          ? nextAds
          : [...prev.items, ...nextAds],
        pending: false,
        result: searchResult,
      };
    });
  };

  const resetData = () => {
    setData(initialState);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (searchEnabled) {
      switch (true) {
        case isFirst:
          setIsFirst(false);
          return;
        case JSON.stringify(paramsRef.current) === JSON.stringify(params):
          return;
        case hasInvalidPrices(params):
          resetData();
          return;
        default:
          break;
      }

      paramsRef.current = params;

      prepareSearch();

      search(params, source.token)
        .then((searchResult: PigeSearchResult) => {
          endSearch(searchResult);
        })
        .catch((e: any) => {
          if (axios.isCancel(e)) return;
          resetData();
        });
    }
  }, [params, search, searchEnabled]);

  return data;
};

export default usePigeData;
