import React, { useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import SwitchOuiNon from "components/SwitchOuiNon";
import { useServices } from "../../../../../../../services";
import { NotificationSettingsCenterContext } from "./NotificationSettingsCenterProvider";

export interface NotificationSettingProps {
  type: string;
  title: string;
  description: string;
  enabled: boolean;
}

const NotificationSetting: React.FC<NotificationSettingProps> = ({
  type,
  title,
  description,
  enabled,
}) => {
  const { update } = useContext(NotificationSettingsCenterContext);
  const {
    trackingService: { sendCustomEventToGoogle },
  } = useServices();
  return (
    <Grid container spacing={1} alignItems={"center"}>
      <Grid item xs={4} md={3} lg={2}>
        <SwitchOuiNon
          value={enabled}
          toggle={(nextEnabled: any) => {
            sendCustomEventToGoogle(
              `Toggle notification ${type} to ${nextEnabled}`
            );
            update({ type, enabled: nextEnabled });
          }}
        />
      </Grid>
      <Grid item xs={8} md={9} lg={10}>
        <Typography variant={"h5"}>{title}</Typography>
        <Typography variant={"h6"} style={{ fontWeight: "normal" }}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NotificationSetting;
