import { Fab, IconButton } from "@material-ui/core";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import React, { FC, MouseEventHandler } from "react";

export interface FavoriteButtonProps {
  isFavorite: boolean;
  onClick: MouseEventHandler;
}

export const FavoriteButton: FC<FavoriteButtonProps> = ({
  isFavorite,
  onClick,
}) => (
  <IconButton onClick={onClick} style={{ cursor: "pointer" }}>
    {isFavorite ? <Favorite /> : <FavoriteBorder />}
  </IconButton>
);

export const FavoriteFab: FC<FavoriteButtonProps> = ({
  isFavorite,
  onClick,
}) => (
  <Fab color="primary" onClick={onClick}>
    {isFavorite ? <Favorite /> : <FavoriteBorder />}
  </Fab>
);
