import React from "react";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";

const handleChange = (evt, refinement, onChange) => {
  const cpy = { ...refinement };
  cpy.value = evt.target.value;
  onChange(cpy, refinement.label);
};

const CustomSearchBox = (props) => {
  const { refinement, onChange, options, value } = props;

  return (
    <TextField
      value={value}
      onChange={(evt) => handleChange(evt, refinement, onChange)}
      variant={"outlined"}
      InputProps={{ style: {} }}
      {...options}
    />
  );
};

CustomSearchBox.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.object,
  refinement: PropTypes.object.isRequired,
};

export default CustomSearchBox;
