import React from "react";
import { RadioChip } from "UI/shared/molecules/RadioChip";
import { KitchenInfoType } from "../../models/kitchenInfoType";
import { SubSectionTitle } from "../components/SectionTitle";

export const SelectKitchenType: React.FC<{
  value?: KitchenInfoType;
  onChange: (value: KitchenInfoType) => void;
  disabled: boolean;
}> = ({ value, onChange }) => {
  const options: KitchenInfoType[] = ["OpenPlan", "Separate", "SemiOpenPlan"];

  return (
    <>
      <SubSectionTitle title="Cuisine" mode={"non obligatoire"} />
      <RadioChip
        translationPrefix={"kitchenInfoType"}
        options={options}
        value={value}
        onChange={onChange}
      />
    </>
  );
};
