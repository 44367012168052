import ClassementAdapter from "services/reporting/dto/Reporting/Synthese/sub/ClassementAdapter";
import RadarAdapter from "services/reporting/dto/Reporting/Synthese/sub/RadarAdapter";
import ResultatParAnneeAdapter from "services/reporting/dto/Reporting/Synthese/sub/ResultatParAnneeAdapter";

const syntheseAdapter = (result = {}) => ({
  classement: result.classement
    ? ClassementAdapter.toBO(result.classement)
    : [],
  radar: result.radar ? RadarAdapter.toBO(result.radar) : [],
  resultatParAnnee: result.resultatParAnnee
    ? ResultatParAnneeAdapter.toBO(result.resultatParAnnee)
    : [],
  stockEnCours:
    result.stockEnCours && result.stockEnCours.length > 0
      ? stockEnCoursAdapter(result.stockEnCours[0])
      : { nombreMandatsActifs: 0, stockCACompromis: 0 },
});

export default syntheseAdapter;

const stockEnCoursAdapter = (stockEnCours) => ({
  nombreMandatsActifs: stockEnCours.nombreMandatsActifs || 0,
  stockCACompromis: stockEnCours.stockCACompromis || 0,
});
