import { ArchiveOutlined, UnarchiveOutlined } from "@material-ui/icons";
import {
  ActionMenuItemProps,
  ActionsMenu,
} from "UI/shared/organisms/ActionsMenu";
import React from "react";
import {
  ProfileKind,
  ProfileReadDto,
} from "routes/app/contacts/contact/components/models/profilesReadDto";
import { usePortfolioSearch } from "services/portfolio/usePortfolioSearch";
import { useProfiles } from "services/profiles";

import { ConfirmationDialog } from "UI/shared/organisms/ConfirmationDialog";
import { useServices } from "services";

export const ProfileDetailsMenu: React.FC<{
  profile: ProfileReadDto;
  contactId: string;
  handleAfterUpdate: () => void;
}> = ({ profile, contactId, handleAfterUpdate }) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const { archive, reactivate } = useProfiles(contactId);
  const { getContact } = usePortfolioSearch();
  const {
    l10n: { t },
  } = useServices();
  const menuOptions: ActionMenuItemProps[] = [];

  const reactivateProfile = (kind: ProfileKind, id: string) => {
    getContact(contactId).then((contact) => {
      if (contact?.status === "Archived") {
        setOpenDialog(true);
      } else {
        reactivate(id, contactId).then(() => {
          handleAfterUpdate();
        });
      }
    });
  };

  const archiveProfile = (kind: ProfileKind, id: string) => {
    archive(id, contactId).then(() => {
      handleAfterUpdate();
    });
  };

  const handleCancel = () => {
    setOpenDialog(false);
  };

  const handleConfirmation = () => {
    setOpenDialog(false);
    reactivate(profile.id, contactId).then(() => {
      handleAfterUpdate();
    });
  };

  if (profile.archivingDate) {
    menuOptions.push({
      action: () => reactivateProfile(profile.kind, profile.id),
      icon: <UnarchiveOutlined />,
      label: "Réactiver",
    });
  } else {
    menuOptions.push({
      action: () => archiveProfile(profile.kind, profile.id),
      icon: <ArchiveOutlined />,
      label: "Archiver",
    });
  }

  return (
    <>
      <ConfirmationDialog
        text={t("ReactivationProfil")}
        handleCancel={handleCancel}
        handleConfirmation={handleConfirmation}
        openDialog={openDialog}
      />
      <ActionsMenu menuOptions={menuOptions} minToCollapse={0} />
    </>
  );
};
