export interface ElasticAdapter<T> {
  count: number;
  total: number;
  totalPage: number;
  pageSize: number;
  pageNumber: number;
  hasMore: boolean;
  documents: T[];
  aggregations: Record<string, AggregationOption[]>;
}

export const DefaultElasticAdapter = {
  aggregations: {},
  count: 0,
  documents: [],
  hasMore: false,
  pageNumber: 0,
  pageSize: 0,
  total: 0,
  totalPage: 0,
};

export interface AggregationOption {
  key: string;
  value: number;
}
