import { Status } from "services/user";
import { PagedNotificationsDto } from "..";

export interface NotificationsContext {
  newAndReadNotificationsPages: PagedNotificationsDto[];
  archivedNotificationsPages: PagedNotificationsDto[];
  markAsRead: () => void;
  markAsArchived: (id: string) => void;
  markAllAsArchived: () => void;
  status: Status;
  newAndReadLoadNewPage: () => void;
  archivedLoadNewPage: () => void;
  getNewNotificationsCount: () => void;
  newNotificationsCount: number;
}

export const defaultNotificationsContext = {
  archivedLoadNewPage: () => {
    return;
  },
  archivedNotificationsPages: ([] as unknown) as PagedNotificationsDto[],
  getNewNotificationsCount: () => {
    return;
  },
  markAllAsArchived: () => {
    return;
  },
  markAsArchived: (id: string) => {
    return id;
  },
  markAsRead: () => {
    return;
  },
  newAndReadLoadNewPage: () => {
    return;
  },
  newAndReadNotificationsPages: ([] as unknown) as PagedNotificationsDto[],
  newNotificationsCount: 0,
  status: "pending" as Status,
};
