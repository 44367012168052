import { Tab } from "components/Fiche/DetailedEntityTabsLayout";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export function useTabs<TabName>(
  tabs: Tab[],
  initTab: TabName,
  urlToTab: (tabName: TabName) => string
): {
  activeTabIndex: number;
  onSelectedTabChanged: ({ tabIndex }: { tabIndex: number }) => void;
  onTabsChange: () => void;
  openTab: (tabName: TabName) => void;
} {
  const getTabIndex = (tabName: TabName) => {
    return Math.max(
      tabs.findIndex((tab) => tab.value === `${tabName}`),
      0
    );
  };

  useEffect(() => {
    setActiveTabIndex(getTabIndex(initTab));
  }, [tabs.length]);

  const onTabsChange = useCallback(() => {
    setActiveTabIndex(getTabIndex(initTab));
  }, [initTab]);

  const [activeTabIndex, setActiveTabIndex] = useState(getTabIndex(initTab));

  const history = useHistory();

  const redirectToSelectedTab = useCallback(
    (tabValue) => {
      if (!tabValue) return;

      const currentPage = urlToTab(tabValue);
      if (currentPage.length === 0) return;

      localStorage.setItem("page courante", currentPage);
      history.replace(currentPage);
    },
    [history, urlToTab]
  );

  const onSelectedTabChanged = useCallback(
    ({ tabIndex }: { tabIndex: number }) => {
      setActiveTabIndex(tabIndex);
      redirectToSelectedTab(tabs[tabIndex].value);
    },
    [redirectToSelectedTab, tabs]
  );

  const openTab = useCallback(
    (tabName: TabName) =>
      onSelectedTabChanged({ tabIndex: getTabIndex(tabName) }),
    [getTabIndex, onSelectedTabChanged]
  );

  return { activeTabIndex, onSelectedTabChanged, onTabsChange, openTab };
}
