import { ProfileKind } from "services/profiles/dto/ReadModelProfileDto";

import { ProfileBusinessType } from "./profileAdapter";

export const getBusinessType: (type: string) => ProfileBusinessType = (
  type
) => {
  switch (true) {
    case !!type.match(/sale/i):
      return "Vente";
    case !!type.match(/lifeannuity/i):
      return "Viager";
    case !!type.match(/rent/i):
      return "Location";
    case !!type.match(/purchase/i):
      return "Achat";
    default:
      return "";
  }
};

export const getProfileType = (kind: ProfileKind) => {
  const proposalProfileKinds: ProfileKind[] = [
    "Rental",
    "Sale",
  ];
  const isProposal = proposalProfileKinds.find((k) => kind.match(k)) != null;
  return isProposal ? "Seller" : "Search";
};

export const getValueOrUndefined = <T>(val: T | null) =>
  val != null ? val : undefined;

export type SearchProfileKind =
  | "SearchLifeAnnuity"
  | "SearchPurchase"
  | "SearchRental";

export type FamilyKind =
  | "Appartement"
  | "Goodwill"
  | "Building"
  | "Premises"
  | "House"
  | "Land"
  | "Parking"
  | undefined;

export type PropertyType =
  | "Appartement"
  | "Immeuble"
  | "LocalBureau"
  | "Maison"
  | "Stationnement"
  | "FondsCommerce"
  | "Terrain";
