import { Grid, Typography } from "@material-ui/core";
import OptOutIcon from "@material-ui/icons/Close";
import OptInIcon from "@material-ui/icons/Done";
import React from "react";
import { ConsentStatus } from "types";

import { useServices } from "../../../../../../../../../services";

interface PrintableOptInProps {
  optIn: ConsentStatus;
}

export const PrintableOptIn: React.FC<PrintableOptInProps> = ({ optIn }) => {
  const {
    l10n: { t },
  } = useServices();
  let Icon, label;

  switch (optIn) {
    case "Approved":
      Icon = OptInIcon;
      label = t("Accepte d'être sollicité(e)");
      break;
    default:
      Icon = OptOutIcon;
      label = t("N'accepte pas d'être sollicité(e)");
      break;
  }

  return (
    <Grid container spacing={1} style={{ marginLeft: 20, marginTop: 14 }}>
      {Icon !== null && (
        <>
          <div
            style={{
              alignItems: "center",
              cursor: "pointer",
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <Icon style={{ fontSize: 16, marginRight: 10 }} />
            <Typography variant="body2" noWrap>
              {label}
            </Typography>
          </div>
        </>
      )}
    </Grid>
  );
};
