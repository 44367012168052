import { EndpointsProvider } from "./interfaces/EndpointsProvider";

export interface RcuEndpoints {
  obtenirIdentifiantRCU: string;
  obtenirJeton: string;
}

const endpoints: EndpointsProvider<RcuEndpoints> = (microServices) => ({
  obtenirIdentifiantRCU: `${microServices.tx(
    "rcu"
  )}/v1/Statistiques/ObtenirIdentifiantRcu`,
  obtenirJeton: `${microServices.tx("rcu")}/v1/Connexion/ObtenirJetonRcu`,
});

export default endpoints;
