import { Grid, Typography } from "@material-ui/core";
import React, { FC, useState } from "react";
import { formatPourcent, formatPrix } from "utils/formatage";

import { LandFinancialInfoDto } from "../../../../models/Proposal/ForSale/Land/landFinancialInfoDto";
import { SectionTitle } from "../../../components/SectionTitle";
import { ValidationNumberField } from "../../../components/ValidationNumberField";
import { ProfileInputProps } from "../../../InputProps";

export const SaleBudgetLand: FC<{
  financialInfo: LandFinancialInfoDto;
}> = ({ financialInfo }) => (
  <>
    {financialInfo.price! > 0 && (
      <Typography variant="h5">
        Prix (honoraires inclus) : {formatPrix(financialInfo.price)}
      </Typography>
    )}
    {financialInfo.agentFees! > 0 && (
      <Typography variant="h5">
        Taux honoraires personnalisé : {formatPourcent(financialInfo.agentFees)}
      </Typography>
    )}
    {financialInfo.taxProperty! > 0 && (
      <Typography variant="body1">
        Taxe foncière : {formatPrix(financialInfo.taxProperty)}
      </Typography>
    )}
    {financialInfo.condominiumFees! > 0 && (
      <Typography variant="body1">
        Charges de copropriété {formatPrix(financialInfo.condominiumFees)}
      </Typography>
    )}
  </>
);

interface SaleBudgetLandValidation {
  price: boolean;
  agentFees: boolean;
  taxProperty: boolean;
  condominiumFees: boolean;
}

const getGlobalValidation = (globalValidation: SaleBudgetLandValidation) =>
  globalValidation.price &&
  globalValidation.agentFees &&
  globalValidation.taxProperty &&
  globalValidation.condominiumFees;

export const SaleBudgetLandInput: FC<ProfileInputProps<
  LandFinancialInfoDto
>> = ({ pristine, value, onChange, more, disabled = false }) => {
  const [globalValidation, setGlobalValidation] = useState({
    agentFees: true,
    condominiumFees: true,
    price: true,
    taxProperty: true,
  });

  const priceChanged = (isValid: boolean, price: number | undefined) => {
    const _globalValidation = {
      ...globalValidation,
      price: isValid,
    };
    setGlobalValidation(_globalValidation);
    onChange(
      {
        ...value,
        price,
      },
      getGlobalValidation(_globalValidation)
    );
  };

  const agentFeesChanged = (
    isValid: boolean,
    agentFees: number | undefined
  ) => {
    const _globalValidation = {
      ...globalValidation,
      agentFees: isValid,
    };
    setGlobalValidation(_globalValidation);
    onChange({ ...value, agentFees }, getGlobalValidation(_globalValidation));
  };

  return (
    <>
      <SectionTitle title="Quel est le prix de vente ?" />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ValidationNumberField
            disabled={disabled}
            pristine={pristine}
            label="Prix (honoraires inclus)"
            onChange={priceChanged}
            required={true}
            value={value.price}
            adornement={"€"}
            minLimit={1}
          />
        </Grid>
        <Grid item xs={6}>
          <ValidationNumberField
            disabled={disabled}
            pristine={pristine}
            label="Taux honoraires"
            onChange={agentFeesChanged}
            required={true}
            value={value.agentFees}
            adornement={"%"}
            decimalScale={2}
            minLimit={0.01}
            maxLimit={100}
          />
        </Grid>
      </Grid>
      <Typography variant={"body2"}>
        {"Prix (net vendeur) :\u00a0"}
        <span style={{ fontWeight: "bold" }}>
          {!isNaN(value.price!) &&
            !isNaN(value.agentFees!) &&
            formatPrix(Math.round(value.price! / (1 + value.agentFees! / 100)))}
        </span>
      </Typography>
    </>
  );
};
