import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { FC, useState } from "react";

import { useServices } from "../../../../../../../../../services";
import { ITheme } from "../../../../../../../../../theme/ts/main";
import { formatPourcent } from "../../../../../../../../../utils/formatage";
import { SubSectionTitle } from "../../../components/SectionTitle";
import { ValidationNumberField } from "../../../components/ValidationNumberField";
import { ProfileInputProps } from "../../../InputProps";

interface RentedLotsProps {
  rented: boolean;
  lotsNumber?: number;
  totalGrossIncome?: number;
  residentialGrossIncome?: number;
  commercialGrossIncome?: number;
}

const useStyles = makeStyles((theme: ITheme) => {
  return {
    checkbox: {
      color: theme.palette.primary.main,
    },
  };
});

interface RentedLotsValidation {
  lotsNumber: boolean;
  totalGrossIncome: boolean;
  residentialGrossIncome: boolean;
  commercialGrossIncome: boolean;
}

const getGlobalValidation = (globalValidation: RentedLotsValidation) =>
  globalValidation.lotsNumber &&
  globalValidation.totalGrossIncome &&
  globalValidation.residentialGrossIncome &&
  globalValidation.commercialGrossIncome;

export const RentedLots: FC<
  ProfileInputProps<RentedLotsProps> & { salePrice: number }
> = ({ pristine, value, onChange, salePrice, disabled = false }) => {
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles();
  const [globalValidation, setGlobalValidation] = useState({
    commercialGrossIncome: true,
    lotsNumber: true,
    residentialGrossIncome: true,
    totalGrossIncome: true,
  });

  return (
    <>
      <SubSectionTitle title="Location" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color={"default"}
                classes={{ root: classes.checkbox }}
                checked={value.rented}
                onChange={() => {
                  onChange(
                    {
                      ...value,
                      rented: !value.rented,
                    },
                    true
                  );
                }}
                inputProps={{ "aria-label": "checkbox" }}
              />
            }
            label={
              <Typography variant={"body2"}>
                {t("forSaleBuilding.RentedLots")}
              </Typography>
            }
          />
        </Grid>
        {value.rented && (
          <>
            <Grid item xs={12} md={6}>
              <ValidationNumberField
                disabled={disabled}
                pristine={pristine}
                label={t("Nb de lots loués")}
                onChange={(isValid, newValue) => {
                  const _globalValidation = {
                    ...globalValidation,
                    lotsNumber: isValid,
                  };
                  setGlobalValidation(_globalValidation);
                  onChange(
                    { ...value, lotsNumber: newValue || 0 },
                    getGlobalValidation(_globalValidation)
                  );
                }}
                value={value.lotsNumber || 0}
                maxLimit={100000}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <ValidationNumberField
                  disabled={disabled}
                  pristine={pristine}
                  label={t("Revenu brut")}
                  onChange={(isValid, newValue) => {
                    const _globalValidation = {
                      ...globalValidation,
                      totalGrossIncome: isValid,
                    };
                    setGlobalValidation(_globalValidation);
                    onChange(
                      { ...value, totalGrossIncome: newValue },
                      getGlobalValidation(_globalValidation)
                    );
                  }}
                  value={value.totalGrossIncome || 0}
                  adornement={"€/an"}
                />
                {value.totalGrossIncome !== undefined && salePrice > 0 && (
                  <Typography variant={"body2"}>
                    {"Rentabilité brute :\u00a0"}
                    <span style={{ fontWeight: "bold" }}>
                      {formatPourcent(
                        ((value.totalGrossIncome || 0) / salePrice) * 100
                      )}
                    </span>
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <ValidationNumberField
                disabled={disabled}
                pristine={pristine}
                label={t("dont habitation")}
                onChange={(isValid, newValue) => {
                  const _globalValidation = {
                    ...globalValidation,
                    residentialGrossIncome: isValid,
                  };
                  setGlobalValidation(_globalValidation);
                  onChange(
                    { ...value, residentialGrossIncome: newValue || 0 },
                    getGlobalValidation(_globalValidation)
                  );
                }}
                value={value.residentialGrossIncome || 0}
                adornement={"€/an"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ValidationNumberField
                disabled={disabled}
                pristine={pristine}
                label={t("dont commercial")}
                onChange={(isValid, newValue) => {
                  const _globalValidation = {
                    ...globalValidation,
                    commercialGrossIncome: isValid,
                  };
                  setGlobalValidation(_globalValidation);
                  onChange(
                    { ...value, commercialGrossIncome: newValue },
                    getGlobalValidation(_globalValidation)
                  );
                }}
                value={value.commercialGrossIncome || 0}
                adornement={"€/an"}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
