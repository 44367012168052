import routesNames from "router/routesNames";
const { app, biensEnVente, bienEnVente, biens } = routesNames;

export interface NetworkRealEstatesRoutes {
  list: string;
  details: (id: string) => string;
  print: (id: string) => string;
}

export const networkRealEstatesRoutes: NetworkRealEstatesRoutes = {
  list: `${app}${biens}${biensEnVente}-new`,
  details: (id: string) => `${app}${biens}${bienEnVente}/${id}/details/view`,
  print: (id: string) => `${app}${biens}${bienEnVente}/${id}/details/print`,
};
