import Grid from "@material-ui/core/Grid";
import Loading from "components/Loading/LoadingSkeleton";
import TitrePage from "components/TitrePage";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import routesNames from "router/routesNames";
import { useServices } from "services";
import { usePortfolioSearch } from "services/portfolio/usePortfolioSearch";
import { RequestForInformationStatus } from "services/request-for-informations/constants/request-for-informations-status";
import { PagedResultDto } from "services/request-for-informations/dto/PagedResultDto";
import { RequestForInformationDto } from "services/request-for-informations/dto/RequestForInformationDto";
import { useAdaptedRefinements } from "services/request-for-informations/useRefinements";
import { useRequestForInformationsMenu } from "services/request-for-informations/useRequestForInformationsMenu";
import { useSelectedRequestForInformations } from "services/request-for-informations/useSelectedRequestForInformations";
import { useSortOptions } from "services/request-for-informations/useSortOptions";
import { INITIAL_SLUG } from "services/slugs/constants";
import { useSlugs } from "services/slugs/useSlugs";
import {
  ScrollToTopButton,
  useInfiniteScroll,
} from "services/useInfiniteScrollSR";
import { RequestForInformationQueryParams, useQuery } from "services/useQuery";
import { ConsentStatus, RequestForInformationsCriteria } from "types";
import { Column } from "UI/shared/atoms/containers/Column";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";
import { Criterias } from "UI/shared/molecules/Criterias";
import { SortInput } from "UI/shared/molecules/SortInput";
import { ErrorPanel } from "UI/shared/templates/Panels/ErrorPanel";
import { RequestForInformationsProps } from "UI/features/requestForInformations/interfaces/RequestForInformationsProps";
import { RFIMenuOption } from "UI/features/requestForInformations/interfaces/RFIMenuOption";
import { TransformationState } from "UI/features/requestForInformations/interfaces/TransformationState";
import { AddProfileToExistingContactModal } from "UI/features/requestForInformations/organisms/modals/AddProfileToExistingContactModal";
import { CreateContactAndProfileModal } from "UI/features/requestForInformations/organisms/modals/CreateContactAndProfileModal";
import { RequestForInformationsDetailsModal } from "UI/features/requestForInformations/organisms/modals/RequestForInformationDetails";
import { MoreFilters } from "UI/features/requestForInformations/organisms/MoreFilters/MoreFilters";
import { NoItemsPanelWithMoreFilters } from "UI/features/requestForInformations/organisms/NoItemsPanelWithMoreFilters";
import { RequestForInformationsSearchBar } from "UI/features/requestForInformations/organisms/SearchBar";
import { RequestForInformationResponsiveItem } from "UI/features/requestForInformations/templates/list";

const getResultsTotal = (
  requestForInformations: PagedResultDto<RequestForInformationDto>,
  t: (word: string) => string
) => {
  return `${
    requestForInformations.total
      ? requestForInformations.total > 10000
        ? "+ de 10000"
        : requestForInformations.total
      : 0
  } ${t("résultat(s)")}`;
};

export const RequestForInformations: React.FC<RequestForInformationsProps> = ({
  requestForInformations,
  archiveRequestForInformation,
  activateRequestForInformation,
  transformRequestForInformation,
  updateParamsWithFilters,
  updateParamsWithActiveSortOption,
  loadMore,
  useGetPropertyForSaleByAdReference,
  status,
}) => {
  const {
    l10n: { t },
    authenticationService: { getUserData },
  } = useServices();

  const query = useQuery<RequestForInformationQueryParams>(["id", "slug"]);
  const history = useHistory();
  const { slugs, storeSlugs, decodeSlug } = useSlugs<
    RequestForInformationsCriteria
  >("DR");

  const { getContactIdByPhoneNumberOrEmailAddress } = usePortfolioSearch();

  const requestForInformationsFromRedirect = useSelectedRequestForInformations(
    query.id
  );

  const {
    ViewObserverFirst,
    ViewObserverLast,
    firstVisible,
    lastVisible,
  } = useInfiniteScroll();

  const [selectedRFI, setSelectedRFI] = useState<
    RequestForInformationDto | undefined | null
  >(undefined);

  const {
    adaptedRefinements,
    refinements,
    onChange,
    onFullChange,
    onClear,
  } = useAdaptedRefinements(requestForInformations);

  const userBu = getUserData()?.Instance;

  const computedMoreFilters = useMemo(
    () => (
      <MoreFilters
        adaptedRefinements={adaptedRefinements}
        searchResultCount={requestForInformations.total}
        onChange={onChange}
      />
    ),
    [adaptedRefinements, requestForInformations.total]
  );

  const {
    sortOptions,
    activeSortOption,
    onChange: changeSortOptions,
  } = useSortOptions();

  const [transformation, setTransformation] = useState<TransformationState>(
    () => new TransformationState()
  );
  useEffect(() => {
    setSelectedRFI(requestForInformationsFromRedirect);
  }, [requestForInformationsFromRedirect]);

  useEffect(() => {
    if (query.slug && query.slug !== INITIAL_SLUG) {
      onFullChange(decodeSlug(query.slug));
      return;
    }
  }, []);

  useEffect(() => {
    storeSlugs(adaptedRefinements);
    updateParamsWithFilters(adaptedRefinements);
  }, [adaptedRefinements]);

  useEffect(() => {
    if (!activeSortOption) return;
    updateParamsWithActiveSortOption(activeSortOption);
  }, [activeSortOption]);

  useEffect(() => {
    if (lastVisible) loadMore();
  }, [lastVisible]);

  const handleCloseTransformationModal = () => {
    setTransformation(new TransformationState());
  };

  const handleConsult = (rfi: RequestForInformationDto) => {
    setSelectedRFI(rfi);
  };

  const handleArchive = (rfi: RequestForInformationDto) => {
    archiveRequestForInformation(rfi.id, rfi.page);
  };

  const handleActivate = (rfi: RequestForInformationDto) => {
    activateRequestForInformation(rfi.id, rfi.page);
  };

  const handleTransform = async (rfi: RequestForInformationDto) => {
    const { email, phoneNumber } = rfi.client;

    const existingContactId = await getContactIdByPhoneNumberOrEmailAddress(
      phoneNumber,
      email
    );

    const nextTransformation: Partial<TransformationState> = {
      selectedRequestForInformations: rfi,
      ...(existingContactId && { contactId: existingContactId }),
    };

    setTransformation((prev) => prev.update(nextTransformation));
  };

  const handleTransformConfirmation = async (
    rfi?: RequestForInformationDto,
    contactId?: string,
    consentStatus: ConsentStatus = "Denied"
  ) => {
    if (!rfi) return;
    setTransformation((prev) => prev.update({ pending: true }));

    const transformResponse = await transformRequestForInformation(
      rfi.id,
      rfi.page,
      {
        consentStatus,
        contactId,
      }
    ).catch((e) => {
      setTransformation((prev) =>
        prev.update({
          hasError: true,
          pending: false,
        })
      );
    });

    if (transformResponse) {
      setTransformation((prev) => new TransformationState());
    }
  };

  const handleOpenProfile = async ({
    client: { email, phoneNumber },
  }: RequestForInformationDto) => {
    const contactId = await getContactIdByPhoneNumberOrEmailAddress(
      phoneNumber,
      email
    );

    if (!contactId) return;

    history.push(
      `${routesNames.app}${routesNames.contact}/${contactId}/profils/view`
    );
  };

  const { options } = useRequestForInformationsMenu({
    activate: handleActivate,
    archive: handleArchive,
    consult: handleConsult,
    openProfile: handleOpenProfile,
    transform: handleTransform,
  });

  const actions: (
    rfi: RequestForInformationDto
  ) => RFIMenuOption[] = useCallback(
    (rfi) => options(rfi.status as RequestForInformationStatus) || [],
    []
  );

  const renderRFI = useCallback(
    (rfi: RequestForInformationDto) => (
      <RequestForInformationResponsiveItem
        requestForInformation={rfi}
        actions={actions(rfi).map((action) => ({
          action: () => action.action(rfi),
          icon: action.icon,
          label: action.label,
        }))}
        clickHandler={() => {
          setSelectedRFI(rfi);
        }}
      />
    ),
    [actions]
  );

  const displayedResultsTotal = getResultsTotal(requestForInformations, t);

  const getHelpPage = (bu: string) => {
    if (bu === "Optimhome") return "/static/markdowns/CRM/rfi_oh.md";
    if (bu === "Capifrance") return "/static/markdowns/CRM/rfi_capi.md";
    return "";
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TitrePage
            categorie="CRM"
            page={"Demandes de renseignements"}
            nbResultats={displayedResultsTotal}
            filePath={getHelpPage(userBu)}
          />
        </Grid>

        {!!adaptedRefinements && (
          <>
            <PaddingContainer top={10} bottom={2}>
              <RequestForInformationsSearchBar
                searchResultCount={requestForInformations.total}
                adaptedRefinements={adaptedRefinements}
                onChange={onChange}
              />
            </PaddingContainer>
            <Grid container alignItems="center">
              <Criterias
                refinements={refinements}
                onChange={onChange}
                onClear={onClear}
              />
              <SortInput
                options={sortOptions}
                onChange={changeSortOptions}
                selectedOption={activeSortOption.value}
              />{" "}
            </Grid>
          </>
        )}

        {requestForInformations.currentPageNumber < 0 ? (
          <Loading />
        ) : status === "rejected" ? (
          <ErrorPanel
            title={"Service des demandes de renseignement"}
            text={"indisponible"}
          />
        ) : requestForInformations.data.length > 0 ? (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {requestForInformations.data.map(
                (rfi: RequestForInformationDto, index) => {
                  const length = requestForInformations.data.length;
                  const renderedRFI = renderRFI(rfi);
                  return (
                    <Grid item xs={12} sm={4} md={12} key={rfi.id}>
                      {index === 0 && (
                        <ViewObserverFirst>{renderedRFI}</ViewObserverFirst>
                      )}
                      {length > 1 &&
                        index === requestForInformations.data.length - 1 && (
                          <ViewObserverLast>{renderedRFI}</ViewObserverLast>
                        )}
                      {index > 0 &&
                        index < requestForInformations.data.length - 1 &&
                        renderedRFI}
                    </Grid>
                  );
                }
              )}
              {!firstVisible && <ScrollToTopButton />}{" "}
            </Grid>
          </Grid>
        ) : (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              height: "70vh",
              width: "100%",
            }}
          >
            <Column columnJustify="center">
              <NoItemsPanelWithMoreFilters
                moreFiltersComponent={computedMoreFilters}
                clearRefinements={onClear}
              />
            </Column>
          </div>
        )}
      </Grid>
      {!!selectedRFI && (
        <RequestForInformationsDetailsModal
          rfi={selectedRFI}
          onClose={() => {
            setSelectedRFI(undefined);
            history.replace({ search: `?slug=${slugs.DR}` });
          }}
          useGetPropertyForSaleByAdReference={
            useGetPropertyForSaleByAdReference
          }
        />
      )}
      <CreateContactAndProfileModal
        isOpen={transformation.isForANewContact()}
        pending={transformation.pending}
        hasError={transformation.hasError}
        confirm={(consentStatus: ConsentStatus) =>
          handleTransformConfirmation(
            transformation.selectedRequestForInformations,
            undefined,
            consentStatus
          )
        }
        onClose={handleCloseTransformationModal}
      />
      <AddProfileToExistingContactModal
        isOpen={transformation.isForAnExistingContact()}
        confirm={() =>
          handleTransformConfirmation(
            transformation.selectedRequestForInformations,
            transformation.contactId
          )
        }
        onClose={handleCloseTransformationModal}
        pending={transformation.pending}
        hasError={transformation.hasError}
      />
    </>
  );
};

export default RequestForInformations;
