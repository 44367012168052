import { Gender, IdentityDto } from "./types";

export function genderAdapter(gender: Gender | undefined): string {
  switch (gender) {
    case "Female":
      return "Mme";
    case "Male":
      return "M";
    case "None":
    default:
      return "";
  }
}

export function toAvatarContent(identity: IdentityDto): string {
  const first = identity?.firstName?.substring(0, 1) || "";
  const second = identity?.lastName?.substring(0, 1) || "";

  return first || second ? (first + second).toUpperCase() : "?";
}

export function toAvatarContentSimplier(
  firstName?: string,
  lastName?: string
): string {
  const first = firstName?.substring(0, 1) || "";
  const second = lastName?.substring(0, 1) || "";

  return first || second ? (first + second).toUpperCase() : "?";
}

export function toFullName(identity: IdentityDto | undefined): string {
  return `${genderAdapter(identity?.gender)} ${identity?.firstName || ""} ${
    identity?.lastName || ""
  }`;
}
