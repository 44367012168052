import {
  getOptionCA,
  getOptionMandat,
  getOptionPourcentageExclu,
} from "./chartsOptions";

export const itemsWithChart = (providerData, t, theme) => {
  if (!providerData) return [];
  const { resultatParAnnee } = providerData;
  return [
    {
      chartOptions: getOptionCA(t, theme, providerData),
      subtitle: t("CaTTC"),
      title:
        resultatParAnnee[resultatParAnnee.length - 1] &&
        resultatParAnnee[resultatParAnnee.length - 1].ca
          ? `${
              resultatParAnnee[resultatParAnnee.length - 1].ca
            } ${String.fromCharCode(0x20ac)}`
          : "Inconnu",
    },
    {
      chartOptions: getOptionMandat(t, theme, providerData),
      subtitle: t("MandatsPris"),
      title:
        resultatParAnnee[resultatParAnnee.length - 1] &&
        resultatParAnnee[resultatParAnnee.length - 1].nbMandat
          ? `${resultatParAnnee[resultatParAnnee.length - 1].nbMandat}`
          : "Inconnu",
    },
    {
      chartOptions: getOptionPourcentageExclu(t, theme, providerData),
      subtitle: t("MandatExclusifs"),
      title:
        resultatParAnnee[resultatParAnnee.length - 1] &&
        resultatParAnnee[resultatParAnnee.length - 1].pourcentageExclu
          ? `${resultatParAnnee[
              resultatParAnnee.length - 1
            ].pourcentageExclu.toFixed()} %`
          : "Inconnu",
    },
  ];
};
