import { MultiCheckbox } from "UI/shared/molecules/MultiCheckbox";
import React from "react";

import { PremisesProximityType } from "../../../../models/Proposal/ForSale/Premises/premisesProximityType";
import { SubSectionTitle } from "../../../components/SectionTitle";
import { ProfileInputProps } from "../../../InputProps";

export const PremisesProximitiesSelection: React.FC<ProfileInputProps<
  PremisesProximityType[]
>> = ({ onChange, value }) => {
  const options = [
    "Airports",
    "Animations",
    "Beach",
    "Downtown",
    "Highways",
    "Shops",
    "Subway",
    "Tramway",
    "BusStation",
    "TrainStation",
  ];
  return (
    <>
      <SubSectionTitle
        title="A proximité (moins de 500m)"
        mode={"non obligatoire"}
      />
      <MultiCheckbox
        translationPrefix={"proximity"}
        options={options}
        selectedOptions={value}
        onChange={onChange}
      />
    </>
  );
};
