import { SharingRealEstatesContext, SharingRealEstatesProps } from "./types";

export const defaultSharingRealEstatesContext: SharingRealEstatesContext = {
  loadMore: () => {
    return;
  },
  handleCheck: (id, checked) => {
    return;
  },
  isChecked: (id: string) => true,
  isShared: (page: number, id: string) => true,
  realEstates: [],
  openDetails: (id: string) => {
    return;
  },
  modalContainerId: "",
};

export const defaultSharingRealEstatesProps: SharingRealEstatesProps = {
  loading: false,

  loadMore: () => {
    return;
  },
  handleCheck: (id, checked) => {
    return;
  },
  handleMultipleCheck: (ids, checked) => {
    return;
  },
  isChecked: (id: string) => true,
  isShared: (page: number, id: string) => true,
  open: (id: string) => {
    return;
  },
  realEstates: [],
  total: 0,
  modalContainerId: "",
};
