import React from "react";
import { BudgetPurchaseDto } from "../../../../models/budgetPurchaseDto";
import { ConstructionYears } from "../../../../models/constructionYears";
import { MaturityType } from "../../../../models/maturityType";
import { PlaceDto } from "../../../../models/placeDto";
import { PropertyAge } from "../../../../models/propertyAge";
import { CheckedCriteria } from "../../../../models/Search/purchaseHouseCriteriaDto";
import { PurchaseHouseProfileWriteDto } from "../../../../models/Search/purchaseHouseProfileWriteDto";
import { FormValidity } from "../../shared/types";

const propertyAgeUpdater: (
  propertyAge: PropertyAge
) => React.SetStateAction<PurchaseHouseProfileWriteDto> = (propertyAge) => (
  prev
) => ({
  ...prev,
  criteria: {
    ...prev.criteria,
    propertyAge,
  },
});

const constructionYearsUpdater: (
  constructionYears: ConstructionYears
) => React.SetStateAction<PurchaseHouseProfileWriteDto> = (
  constructionYears
) => (prev) => ({
  ...prev,
  criteria: {
    ...prev.criteria,
    constructionYears,
  },
});

const placesUpdater: (
  places: PlaceDto[]
) => React.SetStateAction<PurchaseHouseProfileWriteDto> = (places) => (
  prev
) => ({
  ...prev,
  criteria: { ...prev.criteria, places },
});

const formValidityUpdater: (
  key: keyof FormValidity,
  isValid: boolean
) => React.SetStateAction<FormValidity> = (key, isValid) => (prev) => ({
  ...prev,
  [key]: isValid,
});

const budgetUpdater: (
  budget: BudgetPurchaseDto
) => React.SetStateAction<PurchaseHouseProfileWriteDto> = (budget) => (
  prev
) => ({
  ...prev,
  criteria: { ...prev.criteria, budget },
});

const homeSizeUpdater: (homeSize: {
  min?: number;
  max?: number;
}) => React.SetStateAction<PurchaseHouseProfileWriteDto> = (homeSize) => (
  prev
) => ({
  ...prev,
  criteria: { ...prev.criteria, homeSize },
});

const lotSizeUpdater: (lotSize: {
  min?: number;
  max?: number;
}) => React.SetStateAction<PurchaseHouseProfileWriteDto> = (lotSize) => (
  prev
) => ({
  ...prev,
  criteria: { ...prev.criteria, lotSize },
});

const minRoomsUpdater: (
  minRooms: number
) => React.SetStateAction<PurchaseHouseProfileWriteDto> = (minRooms) => (
  prev
) => ({
  ...prev,
  criteria: { ...prev.criteria, minRooms },
});

const minBedRoomsUpdater: (
  minBedRooms: number
) => React.SetStateAction<PurchaseHouseProfileWriteDto> = (minBedrooms) => (
  prev
) => ({
  ...prev,
  criteria: { ...prev.criteria, minBedrooms },
});

const checkedCriteriaUpdater: (
  key: CheckedCriteria
) => React.SetStateAction<PurchaseHouseProfileWriteDto> = (key) => (prev) => ({
  ...prev,
  criteria: {
    ...prev.criteria,
    [key]: !prev.criteria[key],
  },
});

const maturityTypeUpdater: (
  maturity: MaturityType
) => React.SetStateAction<PurchaseHouseProfileWriteDto> = (maturity) => (
  prev
) => ({
  ...prev,
  criteria: { ...prev.criteria, maturity },
});

export const useChanges = (
  setPayload: (
    value: React.SetStateAction<PurchaseHouseProfileWriteDto>
  ) => void,
  setFormValidity: (value: React.SetStateAction<FormValidity>) => void
) => {
  const onPropertyAgeChange = React.useCallback(
    (propertyAge: PropertyAge) => {
      setPayload(propertyAgeUpdater(propertyAge));
    },
    [setPayload]
  );

  const onConstructionYearsChange = React.useCallback(
    (constructionYears: ConstructionYears) => {
      setPayload(constructionYearsUpdater(constructionYears));
    },
    [setPayload]
  );

  const onPlacesChange = React.useCallback(
    (places: PlaceDto[], isValid: boolean) => {
      setFormValidity(formValidityUpdater("places", isValid));
      setPayload(placesUpdater(places));
    },
    [setPayload, setFormValidity]
  );

  const onBudgetChange = React.useCallback(
    (budget: BudgetPurchaseDto, isValid: boolean) => {
      setFormValidity(formValidityUpdater("budget", isValid));
      setPayload(budgetUpdater(budget));
    },
    [setPayload, setFormValidity]
  );

  const onHomeSizeChange = React.useCallback(
    (homeSize: { min?: number; max?: number }, isValid: boolean) => {
      setFormValidity(formValidityUpdater("homeSize", isValid));
      setPayload(homeSizeUpdater(homeSize));
    },
    [setPayload, setFormValidity]
  );

  const onLotSizeChange = React.useCallback(
    (lotSize: { min?: number; max?: number }, isValid: boolean) => {
      setFormValidity(formValidityUpdater("lotSize", isValid));
      setPayload(lotSizeUpdater(lotSize));
    },
    [setPayload, setFormValidity]
  );

  const onMinRoomsChange = React.useCallback(
    (minRooms: number) => {
      setPayload(minRoomsUpdater(minRooms));
    },
    [setPayload]
  );

  const onMinBedRoomsChange = React.useCallback(
    (minBedRooms: number) => {
      setPayload(minBedRoomsUpdater(minBedRooms));
    },
    [setPayload]
  );

  const onCheckedCriteriaChange = React.useCallback(
    (key: CheckedCriteria) => {
      setPayload(checkedCriteriaUpdater(key));
    },
    [setPayload]
  );

  const onNotesChange: (notes: string) => void = React.useCallback(
    (notes) => {
      setPayload((prev) => ({ ...prev, notes }));
    },
    [setPayload]
  );

  const onMaturityChange = React.useCallback(
    (maturity: MaturityType) => {
      setPayload(maturityTypeUpdater(maturity));
    },
    [setPayload]
  );

  return {
    onPlacesChange,
    onPropertyAgeChange,
    onBudgetChange,
    onCheckedCriteriaChange,
    onConstructionYearsChange,
    onHomeSizeChange,
    onLotSizeChange,
    onMaturityChange,
    onNotesChange,
    onMinBedRoomsChange,
    onMinRoomsChange,
  };
};
