import { Button, makeStyles, RadioGroup } from "@material-ui/core";
import { Close, CloseOutlined, Done, DoneOutlined } from "@material-ui/icons";
import { SelectOption, SelectOptionProps } from "UI/shared/atoms/SelectOptions";
import React, { ChangeEvent, useState } from "react";

import { useServices } from "../../../../../../services";
import { ITheme } from "../../../../../../theme/ts/main";
import { ConsentStatus } from "../../../../../../types";

type OptInType = "Accepte" | "N'accepte pas";

export interface OptInFormProps {
  consentStatus: ConsentStatus;
  saveConsentStatus: (consentStatus: ConsentStatus) => void;
  cancel: () => void;
}

const optInToDomain = (option: OptInType): ConsentStatus =>
  option === "Accepte" ? "Approved" : "Denied";

const optInDto = (optIn: ConsentStatus): OptInType =>
  optIn === "Approved" ? "Accepte" : "N'accepte pas";

const labels: { [key: string]: string } = {
  Accepte: "Accepte d'être sollicité(e)",
  "N'accepte pas": "N'accepte pas d'être sollicité(e)",
};

const useStyles = makeStyles((theme: ITheme) => ({
  buttonWrapper: {
    padding: theme.spacing(1),
    position: "relative",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
    width: "100%",
  },
  notDefined: {
    backgroundColor: "transparent",
    color: theme.palette.default,
  },
  notDefinedChecked: {
    backgroundColor: theme.palette.default,
    color: theme.palette.greyMedium,
  },
  radioButton: {
    width: "25%",
  },
  radioButtons: {
    display: "flex",
    justifyContent: "space-around",
    textAlign: "center",
    width: "100%",
  },
}));

export const OptInForm: React.FC<OptInFormProps> = ({
  consentStatus,
  saveConsentStatus,
  cancel,
}) => {
  const {
    l10n: { t },
  } = useServices();
  const [selectedValue, setSelectedValue] = useState<ConsentStatus>(
    consentStatus
  );
  const _shouldBeContacted = optInDto(selectedValue);

  const onChangeSelectedValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(optInToDomain(e.target.value as OptInType));
  };

  const submit = () => {
    saveConsentStatus(selectedValue);
  };

  const classes = useStyles();
  const options: SelectOptionProps<OptInType>[] = [
    {
      checkedIcon: <DoneOutlined />,
      icon: <Done />,
      value: "Accepte",
    },
    {
      checkedIcon: <CloseOutlined />,
      icon: <Close />,
      value: "N'accepte pas",
    },
  ];
  return (
    <>
      <RadioGroup
        row
        aria-label="sollicitation"
        value={_shouldBeContacted}
        onChange={onChangeSelectedValue}
      >
        <div className={classes.radioButtons}>
          {options.map(({ value, icon, checkedIcon }) => (
            <div key={value} className={classes.radioButton}>
              <SelectOption
                icon={icon}
                checkedIcon={checkedIcon}
                value={value}
                label={t(labels[value])}
              />
            </div>
          ))}
        </div>
      </RadioGroup>
      <div className={classes.buttonsContainer}>
        <div className={classes.buttonWrapper}>
          <Button color="primary" variant="outlined" onClick={cancel}>
            {t("Annuler")}
          </Button>
        </div>
        <div className={classes.buttonWrapper}>
          <Button color="primary" variant="contained" onClick={submit}>
            {t("Confirmer")}
          </Button>
        </div>
      </div>
    </>
  );
};
