import React from "react";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import PropTypes from "prop-types";

let timeout = null;

export default function CustomSlider(props) {
  const { title, onChange, refinement, sliderOptions } = props;

  const handleChange = (evt, value) => {
    const localRefinement = refinement;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      onChange(localRefinement, refinement.query);
    }, 1500);
    localRefinement.limiteSup =
      localRefinement.maxIsNoLimit && localRefinement.max === value[1]
        ? null
        : value[1];
    localRefinement.limiteInf = value[0];
  };

  return (
    <>
      <Typography gutterBottom>{title}</Typography>
      <Slider {...sliderOptions} onChange={handleChange} />
    </>
  );
}

CustomSlider.propTypes = {
  onChange: PropTypes.func,
  textDisplay: PropTypes.func,
  title: PropTypes.string,
};

CustomSlider.defaultProps = {
  textDisplay: (value) => value,
};
