import { Locations } from "components/RefinementField/types";
import { RealEstatesSearchParams } from "services/realEstates/types";

function unicodeToA(str: string) {
  return btoa(encodeURIComponent(str));
}
function aToUnicode(str: string) {
  return decodeURIComponent(atob(str));
}
export const paramsToSlug = (
  params: RealEstatesSearchParams,
  locations?: Locations
): string => {
  return unicodeToA(JSON.stringify({ locations: locations, params: params }));
};

export const slugToParams = (
  slug: string
): { params: RealEstatesSearchParams; locations: Locations } => {
  return JSON.parse(aToUnicode(slug));
};
