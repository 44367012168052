import { Theme, useMediaQuery } from "@material-ui/core";
import React, { useMemo } from "react";
import { RealEstateDto } from "services/profiles/dto/RealEstateDto";
import { generateListKeys } from "utils/component-lists/generateKey";
import { SearchResultProps } from "../../props";
import { RealEstatesGalleryLarge } from "./index.large";
import { RealEstatesGallerySmall } from "./index.small";

export const generateUniqueKey = generateListKeys<RealEstateDto>({
  feature: "realestates",
  customName: "gallery",
});

export const RealEstatesGallery: React.FC<SearchResultProps> = ({
  items,
  loadMore,
  onClick,
}) => {
  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("lg")
  );

  const props = useMemo(() => ({ items, loadMore, onClick }), [
    items,
    loadMore,
    onClick,
  ]);

  return isLargeScreen ? (
    <RealEstatesGalleryLarge {...props} />
  ) : (
    <RealEstatesGallerySmall {...props} />
  );
};
