import { LabelledValue } from "UI/shared/molecules/LabelledValue";
import React, { FC } from "react";
import { FurnitureType } from "routes/app/contacts/contact/components/models/furnitureType";
import { useServices } from "services";

export interface FurnitureProps {
  value: FurnitureType;
}
export const Furniture: FC<FurnitureProps> = ({ value }) => {
  const {
    l10n: { t },
  } = useServices();

  return value ? (
    <LabelledValue label={"Complément"} value={t(`furniture.${value}`)} />
  ) : (
    <></>
  );
};
