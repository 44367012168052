import { Grid } from "@material-ui/core";
import ConteneurCarte from "components/ConteneurCarte";
import DataProvider from "components/DataProviderSR";
import { DetailedEntityTabsLayout } from "components/Fiche/DetailedEntityTabsLayout";
import Map from "components/Map";
import Photos from "components/Photos";
import TitrePage from "components/TitrePage";
import { useTabs } from "hooks/useTabs";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAcquirers } from "services/matching/useAcquirers";
import { usePigeEvents } from "services/piges/events";
import { useMatchingAdapter } from "services/piges/matching/useMatchingAdapter";
import { PigeNotes, usePigeNotes } from "services/piges/notes/usePigeNotes";
import { Annonce } from "services/piges/types/Annonce";
import { useDetailBienPige } from "services/piges/useDetailBienPige";
import { breakpoint } from "theme/main";
import { PigeMatching } from "UI/features/pige/templates/matching";
import CommerceEntreprise from "UI/shared/atoms/Badges/C&E";
import { TitleWithNumberBadge } from "UI/shared/atoms/Badges/TitleWithNumberBadge";
import Notes from "UI/shared/atoms/Notes";
import { getPages } from "utils/array";

import AgenciesUnwanted from "../../../../../components/AgenciesUnwanted/AgenciesUnwanted";
import { useServices } from "../../../../../services";
import { usePigeBienForMap } from "../../../../../services/piges/usePigeBienForMap";
import { PigeGlobalContext } from "..";
import { PigeAdEventsContext } from "../contexts/PigeAdEventsContextProvider";
import { PigeContextProvider } from "../contexts/PigeContextProvider";
import { AdConversion } from "../Pige/AdConversion";
import { AdVisibility } from "../Pige/AdVisibility";
import { OpenAd } from "../Pige/OpenAd";
import { PigeFavorite } from "../Pige/PigeFavorite";
import { backToList, selectTab } from "../states/global/actions";
import { AdActions } from "./AdActions/AdActions";
import CarteBienReduite from "./CarteBienReduite";
import { CarteContactPige } from "./CarteContactPige";
import AnnonceTypeSection from "./Details/sectionsCategories/AnnonceTypeSection";
import InformationLinesSection from "./Details/sectionsCategories/InformationLinesSection";
import TextSection from "./Details/sectionsCategories/TextSection";
import {
  getAnnexes,
  getAspectsFinanciers,
  getComposition,
  getSurfaces,
  getTitle,
} from "./formatters";
import PriceHistory from "./PriceHistory/PriceHistory";

type DisplayType = "view" | "print";

interface WrappedFicheBienPigeProps {
  annonce: Annonce;
  display: DisplayType;
  tab: string;
}

interface EventsContextProps {
  events: any[];
  add: (id: string, data: any) => Promise<void>;
  deleteAd: (id: string, data: any) => Promise<void>;
  update: (id: string, data: any) => Promise<void>;
}

export const EventsContext = createContext<EventsContextProps>(
  {} as EventsContextProps
);

type TabName = "details" | "historique-prix" | "suivi";

const WrappedFicheBienPige = ({ annonce }: WrappedFicheBienPigeProps) => {
  const {
    l10n: { t },
  } = useServices();

  const {
    dispatch,
    state: { redirectUrl },
  } = useContext(PigeGlobalContext);

  const history = useHistory();

  const { bienForMap, polygon } = usePigeBienForMap(annonce);

  const { matching } = useMatchingAdapter(annonce.id);

  const {
    byAgent,
    byNetwork,
    handleAgentAcquirersPage,
    handleNetworkAcquirersPage,
    pageSize,
  } = useAcquirers(matching);

  const bienPigeActions = [
    <PigeFavorite key={0} id={annonce.id} mode={"button"} />,
    <AdVisibility key={1} id={annonce.id} />,
    <>
      {annonce.publication.source?.url && (
        <OpenAd key={2} url={annonce.publication.source.url} />
      )}
    </>,
    <AdConversion
      key={3}
      id={annonce.id}
      adKind={annonce.infosFinancieres.typeAnnonce}
      contactPhone={annonce.contact.telephone}
      contactEmail={annonce.contact.email}
    />,
  ];

  const { add, update, deleteAd, events } = usePigeEvents(annonce.id, true);

  const tabs = [
    {
      content: <Details annonce={annonce} />,
      helpFilePath: "/static/markdowns/fichePige.md",
      titre: t("Détails"),
      value: "details",
    },
    {
      content: (
        <PriceHistory
          annonceId={annonce.id}
          typeAnnonce={annonce.publication.typeAnnonceur}
          datePublication={new Date(annonce.publication.dateActivation)}
          dateMiseAJour={new Date(annonce.publication.dateMiseAJour)}
          estActive={annonce.publication.estActive}
        />
      ),
      helpFilePath: "/static/markdowns/fichePige-HistoriquePrix.md",
      titre: breakpoint().smAndDown ? t("Prix") : t("Historique prix"),
      value: "historique-prix",
    },
    {
      content: (
        <PigeMatching
          typeAnnonce={annonce.publication.typeAnnonceur}
          datePublication={new Date(annonce.publication.dateActivation)}
          dateMiseAJour={new Date(annonce.publication.dateMiseAJour)}
          estActive={annonce.publication.estActive}
          byAgent={{
            acquirers: byAgent.acquirers,
            currentPage: byAgent.currentPage,
            handlePage: handleAgentAcquirersPage,
            pages: getPages(byAgent.total, pageSize),
            total: byAgent.total,
          }}
        />
      ),
      titre: (
        <>
          <TitleWithNumberBadge
            number={byAgent.total}
            title={t("Mes acquéreurs")}
          />
        </>
      ),
    },
    {
      content: (
        <AdActions
          annonceId={annonce.id}
          estActive={annonce.publication.estActive}
          simplified={false}
        />
      ),
      helpFilePath: "/static/markdowns/fichePige-Suivi.md",
      titre: (
        <>
          <TitleWithNumberBadge number={events.length} title={t("Suivi")} />
        </>
      ),
      value: "suivi",
    },
  ];

  const { tab = "details" }: { tab: TabName } = useParams();

  const { activeTabIndex, onSelectedTabChanged } = useTabs<TabName>(
    tabs,
    tab,
    (nextTab: TabName) => {
      dispatch(selectTab(nextTab));
      return "";
    }
  );

  const { adHasEvents } = useContext(PigeAdEventsContext);

  const {
    loadPigeNotes,
    handleDeleteNote,
    handleEditNotes,
    getDefaultNotes,
  } = usePigeNotes();

  const [pigeNotes, setPigeNotes] = useState<PigeNotes>(getDefaultNotes());

  const onHandleEditNotes = useCallback(
    (notes: string) => {
      handleEditNotes({ ...pigeNotes, Notes: notes }).then((pigeNotes) =>
        setPigeNotes(pigeNotes)
      );
    },
    [pigeNotes]
  );

  const onHandleDeleteNotes = useCallback(() => {
    handleDeleteNote(pigeNotes).then((notes) => setPigeNotes(notes));
  }, [pigeNotes]);

  const loadNotes = useCallback(() => {
    loadPigeNotes(annonce.id).then((notes) => setPigeNotes(notes));
  }, [annonce]);

  useEffect(() => {
    loadNotes();
  }, []);

  if (annonce.id === null) {
    return <></>;
  }

  const handleBackButtonAction = () => {
    if (!redirectUrl) {
      dispatch(backToList());

      return;
    }

    if (redirectUrl === "current") return;

    history.push(redirectUrl as string);
  };

  const hasEvents = adHasEvents(annonce.id);
  const titre = getTitle(annonce, t);

  const actions = [
    <Photos key="photos" title={titre} urls={annonce.publication.photos} />,
  ];

  if (!!bienForMap.latitude && !!bienForMap.longitude)
    actions.push(
      <Map key="map" title={titre} hit={bienForMap} polygon={polygon} />
    );

  const tagsList = [];
  if (annonce.contact.joignableParAgence === "NonJoignable")
    tagsList.push(<AgenciesUnwanted key={0} />);
  if (annonce.bien.isCE) tagsList.push(<CommerceEntreprise key={0} />);

  return (
    <>
      <EventsContext.Provider value={{ add, deleteAd, events, update }}>
        <DetailedEntityTabsLayout
          onSelectedTabChanged={onSelectedTabChanged}
          composantsBarreGauche={[
            {
              composant: (
                <CarteBienReduite
                  adId={annonce.id}
                  surface={annonce.bien.surfaces.habitable}
                  nbPieces={annonce.bien.composition.nbPieces}
                  nbChambres={annonce.bien.composition.nbChambres}
                  prix={annonce.infosFinancieres.prixActuel}
                  villeNom={annonce.bien.ville}
                  typeAffaire={annonce.infosFinancieres.typeAnnonce}
                  typeBien={annonce.bien.type}
                  photo={annonce.publication.photos[0]}
                  typeEtatBien={annonce.bien.typeEtatBien}
                  actions={actions}
                  estActive={annonce.publication.estActive}
                  galerie={false}
                  hasEvents={hasEvents}
                  listeTags={tagsList}
                />
              ),
            },
            {
              composant: <div style={{ marginBottom: 20 }} />,
            },
            {
              composant: (
                <ConteneurCarte>
                  <CarteContactPige
                    sourceName={annonce.publication.source.nom}
                    sourceUrl={annonce.publication.source.url}
                    name={annonce.contact.pseudo}
                    email={annonce.contact.email}
                    phone={annonce.contact.telephone}
                    bloctel={annonce.bloctel}
                    isDetailsPage={true}
                  />
                </ConteneurCarte>
              ),
            },
            {
              composant: <div style={{ marginBottom: 20 }} />,
            },
            {
              composant: (
                <Notes
                  notes={pigeNotes.Notes}
                  deleteNotes={onHandleDeleteNotes}
                  editNotes={onHandleEditNotes}
                  t={t}
                />
              ),
            },
          ]}
          titre={
            <TitrePage
              categorie="Biens | Pige"
              page={titre}
              nbResultats=""
              filePath={tabs[activeTabIndex].helpFilePath}
            />
          }
          tabs={tabs}
          actions={bienPigeActions}
          tabActiveIndex={activeTabIndex}
          onClose={handleBackButtonAction}
        />
      </EventsContext.Provider>
    </>
  );
};

export const FicheBienPige: React.FC = () => {
  const {
    state: { selectedAdId: id, tab, display },
  } = useContext(PigeGlobalContext);

  const params = { id };

  return (
    <PigeContextProvider>
      <DataProvider
        dataReader={useDetailBienPige}
        erreur={{
          mini: false,
          texte: "Serveur non disponible.",
          titre: "Détail Bien Pige",
        }}
        params={params}
      >
        {(items: Annonce) => (
          <WrappedFicheBienPige annonce={items} tab={tab} display={display} />
        )}
      </DataProvider>
    </PigeContextProvider>
  );
};

const Details = ({ annonce }: { annonce: Annonce }) => (
  <Grid container spacing={4}>
    <Grid item xs={12} lg={4}>
      <AnnonceTypeSection
        typeAnnonce={annonce.publication.typeAnnonceur}
        datePublication={new Date(annonce.publication.dateActivation)}
        dateMiseAJour={new Date(annonce.publication.dateMiseAJour)}
        estActive={annonce.publication.estActive}
      />
      <TextSection
        title={"Descriptif"}
        content={annonce.publication.description}
      />
    </Grid>
    <Grid item xs={12} lg={4}>
      <InformationLinesSection
        title={"Surfaces"}
        informationsList={getSurfaces(annonce)}
      />
      <InformationLinesSection
        title={"Aspects Financiers"}
        informationsList={getAspectsFinanciers(annonce)}
      />
    </Grid>
    <Grid item xs={12} lg={4}>
      <InformationLinesSection
        title={"Composition"}
        informationsList={getComposition(annonce)}
      />
      <InformationLinesSection
        title={"Annexes"}
        informationsList={getAnnexes(annonce)}
      />
    </Grid>
  </Grid>
);
