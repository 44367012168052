import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { FC } from "react";

import { useServices } from "../../../../../../services";
import { useContactForm } from "../context/ContactFormContext";
import useStyles from "../styles";
import { SectionTitle } from "./Sections";

export const Notes: FC = () => {
  const {
    l10n: { t },
  } = useServices();
  const { setContactDto, isDisabled, contactDto } = useContactForm();
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12}>
        <SectionTitle title={"Notes"} mode="non obligatoire" />
        <TextField
          id="notes"
          name="notes"
          multiline
          rows="2"
          rowsMax="4"
          label={t("Notes")}
          className={classes.narrowTextField}
          value={contactDto.notes}
          onChange={(e) =>
            setContactDto({ ...contactDto, notes: e.target.value })
          }
          margin="normal"
          variant="outlined"
          disabled={isDisabled}
        />
      </Grid>
    </>
  );
};
