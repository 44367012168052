import { Button, Grid, Link } from "@material-ui/core";
import {
  CallReceived,
  DescriptionOutlined,
  PublicOutlined,
} from "@material-ui/icons";
import precisioLogoPng from "assets/images/logos/precisio-logo.png";
import valorizeLogoPng from "assets/images/logos/valorize-logo.png";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";
import { Row } from "UI/shared/atoms/containers/Row";
import { Text14px } from "UI/shared/atoms/typographies/Text14px";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import { WithToniqueColor } from "UI/shared/atoms/WithToniqueColor";
import useNotifier from "components/Notifier/useNotifier";
import React from "react";
import { useServices } from "services";

import { useEstimation } from "../../templates/profileDetails/useEstimation";

export type EstimationStatus = "NotCreated" | "CreationInProgress" | "Created";

interface EstimationProps {
  contactId: string;
  profileId: string;
}

export const Estimation: React.FC<EstimationProps> = ({
  contactId,
  profileId,
}) => {
  const { status, estimation, create } = useEstimation(contactId, profileId);

  const {
    authenticationService: { getUserData },
    l10n: { formatBudget },
  } = useServices();
  const { showSnack, hideSnack } = useNotifier();

  if (status === "Creating") {
    showSnack({
      message: "Votre estimation est en cours d'initialisation",
      variant: "info",
      withLoader: true,
    });
  } else {
    hideSnack();
  }
  return (
    <PaddingContainer top={8}>
      <Row>
        <img
          src={
            getUserData().Instance === "Optimhome"
              ? valorizeLogoPng
              : precisioLogoPng
          }
          alt="logo"
        />
      </Row>
      <Row>
        {estimation && (
          <Grid container spacing={2}>
            {(estimation.priceMin || estimation.priceMax) && (
              <Grid item xs={12}>
                <Text14px>
                  {formatBudget(estimation.priceMin, estimation.priceMax)}
                </Text14px>
              </Grid>
            )}
            {(estimation.reportPdfUrl || estimation.reportWebUrl) && (
              <Grid container item xs={12} spacing={2}>
                {estimation.reportPdfUrl && (
                  <Grid item>
                    <Link href={estimation.reportPdfUrl} target="_blank">
                      <Row rowAlignItems="center">
                        {WithToniqueColor(DescriptionOutlined)}
                        <Text14px>Rapport PDF</Text14px>
                      </Row>
                    </Link>
                  </Grid>
                )}
                {estimation.reportWebUrl && (
                  <Grid item>
                    <Link href={estimation.reportWebUrl} target="_blank">
                      <Row rowAlignItems="center">
                        {WithToniqueColor(PublicOutlined)}
                        <Text14px>Rapport en ligne</Text14px>
                      </Row>
                    </Link>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )}
      </Row>
      <VerticalSpace size={10} />
      <Row>
        {(status === "Creating" || status === "LoadingFailed") && (
          <Button
            variant="outlined"
            color="primary"
            onClick={create}
            disabled={status === "Creating"}
          >
            <CallReceived
              style={{
                marginLeft: -4,
                marginRight: 8,
                transform: "rotate(-180deg)",
              }}
            />
            <Text14px>COMMENCER UNE ESTIMATION</Text14px>
          </Button>
        )}
        {estimation && estimation.url && (
          <Button
            variant="outlined"
            color="primary"
            href={estimation.url}
            target="_blank"
          >
            <CallReceived
              style={{
                marginLeft: -4,
                marginRight: 8,
                transform: "rotate(-180deg)",
              }}
            />
            <Text14px>OUVRIR</Text14px>
          </Button>
        )}
      </Row>
    </PaddingContainer>
  );
};
