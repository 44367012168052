import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { includes } from "lodash";
import React from "react";
import { useTranslation } from "services/l10n/useTranslation";
import { ITheme } from "theme/ts/main";

const useStyles = makeStyles((theme: ITheme) => {
  return {
    checkbox: {
      color: theme.palette.primary.main,
    },
  };
});

const toggleCheckbox = (selectedOptions: string[], changedOption: string) => {
  const index = selectedOptions.indexOf(changedOption);

  if (index === -1) return [...selectedOptions, changedOption];
  return selectedOptions.filter((opt) => opt !== changedOption);
};

export const MultiCheckbox: React.FC<{
  translationPrefix: string;
  options: string[];
  selectedOptions: string[];
  onChange: (value: any, isValid: boolean) => void;
}> = ({ translationPrefix, options, onChange, selectedOptions }) => {
  const { t } = useTranslation();

  const classes = useStyles();
  return (
    <>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {options.map((option) => (
          <FormControlLabel
            key={option}
            control={
              <Checkbox
                color={"default"}
                classes={{ root: classes.checkbox }}
                checked={includes(selectedOptions, option)}
                onChange={() => {
                  onChange(toggleCheckbox(selectedOptions, option), true);
                }}
                inputProps={{ "aria-label": "checkbox" }}
              />
            }
            label={
              <Typography variant={"body2"}>
                {translationPrefix
                  ? t(`${translationPrefix}.${option}`)
                  : t(option)}
              </Typography>
            }
          />
        ))}
      </div>
    </>
  );
};
