import { string, number } from "api/DTOs/Common/Types";

function obtenirParIdAdapter(result = {}) {
  return {
    id: result.id || number,
    bu: result.bu || string,
    dateAjout: result.dateAjout || string,
    dateModification: result.dateModification || string,
    dateEntreeDansLeReseau: result.dateEntreeDansLeReseau || string,
    nom: result.nom || string,
    prenom: result.prenom || string,
    photo: result.photo || string,
    civilite: result.civilite || string,
    moisAnciennete: result.moisAnciennete || 0,
    telephone: result.telephone || string,
    email: result.adresseEmail || string,
    urlMiniSite: result.miniSiteUrl || string,
    lienFacebook: result.lienFacebook || string,
    roles: Array.isArray(result.roles) ? result.roles : [],
    specialites: Array.isArray(result.specialites)
      ? result.specialites.map((specialite) =>
          specialite === "Habilité à gérer des ventes en viager"
            ? "Viager"
            : specialite
        )
      : [],
    ville: result.ville || string,
    codePostal: result.codePostal || string,
    latitude: result.latitude || number,
    longitude: result.longitude || number,
    position:
      result.latitude && result.longitude
        ? { lat: result.latitude, lng: result.longitude }
        : { lat: 0, lng: 0 },
  };
}

export default obtenirParIdAdapter;
