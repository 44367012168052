import { CircularProgress } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Column } from "UI/shared/atoms/containers/Column";
import { Row } from "UI/shared/atoms/containers/Row";
import { Text12pxBold } from "UI/shared/atoms/typographies/Text12pxBold";
import { Text14pxBold } from "UI/shared/atoms/typographies/Text14pxBold";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import React from "react";
import { OptInForm } from "routes/app/contacts/components/ContactForm/components/OptInForm";
import { ConsentStatus } from "types";

import { Error } from "./Error";
import { useStyles } from "./styles";

interface CreateContactAndProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
  confirm: (consentStatus: ConsentStatus) => void;

  pending: boolean;
  hasError: boolean;
}

export const CreateContactAndProfileModal: React.FC<CreateContactAndProfileModalProps> = ({
  confirm,
  onClose,
  isOpen,
  pending,
  hasError,
}) => {
  const classes = useStyles();
  const modalStyle = {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  };

  return (
    <Modal open={isOpen} onClose={onClose} style={modalStyle}>
      <div className={classes.paper} style={{ textAlign: "center" }}>
        <Column itemJustify="center" columnJustify="center">
          <Text14pxBold>Transformation en potentiel vendeur</Text14pxBold>
          <p>
            Un contact et un profil vendeur vont être créés en se basant sur les
            caractéristiques du bien de la pige.
          </p>
        </Column>
        <VerticalSpace size={20} />
        <Column itemJustify="center" columnJustify="center">
          <Text12pxBold>Consentement de la personne:</Text12pxBold>
          <Text12pxBold>
            (Vous pouvez modifier cette valeur par la suite)
          </Text12pxBold>
        </Column>

        <VerticalSpace size={10} />
        {!pending && !hasError && (
          <OptInForm
            cancel={onClose}
            consentStatus={"Denied"}
            saveConsentStatus={confirm}
          />
        )}
        {pending && (
          <Row rowJustify="center">
            <CircularProgress />
          </Row>
        )}
        {hasError && <Error onClose={onClose} />}
      </div>
    </Modal>
  );
};
