import { SvgIcon } from "@material-ui/core";
import React, { CSSProperties } from "react";

import { CampaignDetailsSection } from "../..";

const TriggerIcon: React.FC<{ style: CSSProperties }> = ({ style }) => (
  <SvgIcon style={style}>
    <path d="M12,4c4.41,0,8,3.59,8,8s-3.59,8-8,8s-8-3.59-8-8S7.59,4,12,4 M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10 s10-4.48,10-10C22,6.48,17.52,2,12,2L12,2z M11,8H9v8h2V8z M17,12l-5-4v8L17,12z" />
  </SvgIcon>
);

export const CampaignTriggerEventSection: React.FC<{ content: string }> = ({
  content,
}) => (
  <CampaignDetailsSection
    icon={<TriggerIcon style={{ fontSize: "16px" }} />}
    title={"Déclencheur"}
    content={content}
  />
);
