import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

interface MoreFiltersFooterProps {
  entityName: string;
  total: number;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  footer: {
    bottom: "0px",
    position: "sticky",
    zIndex: 999,
    backgroundColor: "white",
    borderTop: "1px solid #091722",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "50px",
    flexDirection: "column",
  },
}));

export const MoreFiltersFooter: React.FC<MoreFiltersFooterProps> = ({
  entityName,
  total,
  onClose,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.footer} onClick={onClose}>
      <Typography variant={"subtitle1"}>
        <span style={{ fontWeight: "bold" }}>
          {total} {entityName.toLocaleLowerCase()}
        </span>
      </Typography>
      <Typography variant={"subtitle1"}>
        &nbsp;correspond{total <= 1 ? "" : "ent"} à vos critères
      </Typography>
    </div>
  );
};
