import { FormControlLabel } from "@material-ui/core";
import {
  ApartmentOutlined,
  BusinessOutlined,
  DirectionsCarOutlined,
  HouseOutlined,
  LocalFloristOutlined,
  StoreOutlined,
} from "@material-ui/icons";
import { CircleIcon } from "UI/shared/atoms/icons/CircleIcon";
import React from "react";

import { PropertyCategoryType } from "routes/app/profiles/adapters/profileAdapterDetails";

export interface PropertyCategoryChipProps {
  value: PropertyCategoryType;
  chipDiameter?: number;
}

export const PropertyCategoryChip: React.FC<PropertyCategoryChipProps> = ({
  value,
  chipDiameter,
}) => {
  const circleIconProps = { ...(chipDiameter && { diameter: chipDiameter }) };
  const house = (
    <FormControlLabel
      value={value}
      control={
        <CircleIcon {...circleIconProps}>
          <HouseOutlined />
        </CircleIcon>
      }
      label={"Maison"}
      labelPlacement="bottom"
      style={{ fontSize: chipDiameter || 48 / 4 }}
    />
  );

  const apartment = (
    <FormControlLabel
      value={value}
      control={
        <CircleIcon {...circleIconProps}>
          <ApartmentOutlined />
        </CircleIcon>
      }
      label={"Appartement"}
      labelPlacement="bottom"
    />
  );

  const building = (
    <FormControlLabel
      value={value}
      control={
        <CircleIcon>
          <BusinessOutlined />
        </CircleIcon>
      }
      label={"Immeuble"}
      labelPlacement="bottom"
    />
  );

  const premise = (
    <FormControlLabel
      value={value}
      control={
        <CircleIcon>
          <StoreOutlined />
        </CircleIcon>
      }
      label={"Local/Bureau"}
      labelPlacement="bottom"
    />
  );

  const goodwill = (
    <FormControlLabel
      value={value}
      control={
        <CircleIcon>
          <StoreOutlined />
        </CircleIcon>
      }
      label={"Fonds de commerce"}
      labelPlacement="bottom"
      style={{ textAlign: "center" }}
    />
  );

  const land = (
    <FormControlLabel
      value={value}
      control={
        <CircleIcon>
          <LocalFloristOutlined />
        </CircleIcon>
      }
      label={"Terrain"}
      labelPlacement="bottom"
    />
  );

  const parking = (
    <FormControlLabel
      value={value}
      control={
        <CircleIcon>
          <DirectionsCarOutlined />
        </CircleIcon>
      }
      label={"Stationnement"}
      labelPlacement="bottom"
    />
  );

  if (value === "House") return house;
  else if (value === "Apartment") return apartment;
  else if (value === "Building") return building;
  else if (value === "Premises") return premise;
  else if (value === "Parking") return parking;
  else if (value === "Land") return land;
  else if (value === "Goodwill") return goodwill;
  else return apartment;
};
