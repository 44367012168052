import { Grid, Typography } from "@material-ui/core";
import React from "react";

import { useServices } from "../../../../../services";

const DPEGES = (props) => {
  const { dpeges } = props;
  const {
    l10n: { t },
  } = useServices();
  return (
    <Grid container spacing={0}>
      <Grid item xs={6}>
        <Typography variant="subtitle1" align="right">
          {t("DPE")}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        &nbsp;
      </Grid>
      <Grid item xs={5}>
        <Typography variant="h4">{dpeges.dpe}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1" align="right">
          {t("GES")}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        &nbsp;
      </Grid>
      <Grid item xs={5}>
        <Typography variant="h4">{dpeges.ges}</Typography>
      </Grid>
    </Grid>
  );
};

export default DPEGES;
