import {
  Card,
  CardActions,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import PreviousIcon from "@material-ui/icons/ArrowBackIos";
import NextIcon from "@material-ui/icons/ArrowForwardIos";
import React from "react";
import SwipeableViews from "react-swipeable-views";
import { buColors } from "utils/formatage";

import useObtenirTousLesConseillersDUnLieu from "../../services/obtenirTousLesConseillersDUnLieu";

const CarrouselConseillers = ({ items }) => {
  const [visible, setVisible] = React.useState(0);
  const theme = useTheme();

  const suivant = () => setVisible(visible + 1);
  const precedent = () => setVisible(visible - 1);
  const handleChangeIndex = (index) => {
    setVisible(index);
  };

  const { cartesConseillers } = useObtenirTousLesConseillersDUnLieu({
    items,
  });

  const mobile = useMediaQuery("(max-width: 768px)");
  const screenSize = mobile ? 260 : 300;
  const avatarTaille = 30;
  let recouvrement =
    cartesConseillers.length > Math.ceil(screenSize / avatarTaille)
      ? avatarTaille - Math.ceil(screenSize / (cartesConseillers.length + 3))
      : 0;
  recouvrement = recouvrement === 0 ? 10 : recouvrement;
  const styleRecouvrement = `-${recouvrement}px`;
  const largeurCarte = `${screenSize + 16}px`;
  const largeurSwipeable = `${screenSize}px`;

  return (
    <Card style={{ width: largeurCarte }}>
      <CardContent style={{ padding: "0px 8px 8px" }}>
        {cartesConseillers.length > 1 ? (
          <div
            style={{
              borderBottom: "1px solid #e2e2e2",
              display: "flex",
              justifyContent: "flex-start",
              paddingBottom: 8,
              paddingTop: 8,
            }}
          >
            {cartesConseillers.map((item, index) => (
              <div
                key={item.conseiller.id}
                style={{
                  borderColor:
                    index === visible
                      ? `${buColors[item.conseiller.bu]}`
                      : "transparent",
                  borderRadius: "25px",
                  borderStyle: "solid",
                  borderWidth: "2px",
                  marginRight: styleRecouvrement,
                  zIndex: 10 + index,
                }}
              >
                {item.avatarPetit}
              </div>
            ))}
          </div>
        ) : (
          <div style={{ paddingTop: 8 }}>&nbsp;</div>
        )}
        <SwipeableViews
          index={visible}
          onChangeIndex={handleChangeIndex}
          style={{ paddingTop: 8, width: largeurSwipeable }}
        >
          {cartesConseillers.map((item) => (
            <div key={item.conseiller.id}>{item.carteConseiller}</div>
          ))}
        </SwipeableViews>
      </CardContent>
      {items.length > 1 && (
        <CardActions style={{ borderTop: "1px solid #e2e2e2" }}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ width: "30%" }}>
              {visible > 0 ? (
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: "5px",
                  }}
                  onClick={precedent}
                >
                  <PreviousIcon style={{ fontSize: "18px" }} />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: "5px",
                  }}
                >
                  <PreviousIcon
                    style={{
                      color: "transparent",
                      fontSize: "18px",
                    }}
                  />
                </div>
              )}
            </div>
            <div style={{ width: "20%" }}>
              <Typography
                variant={"h5"}
                style={{
                  color: theme.palette.greyMedium,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {visible + 1} / {items.length}
              </Typography>
            </div>
            <div style={{ width: "30%" }}>
              {visible < items.length - 1 ? (
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "5px",
                  }}
                  onClick={suivant}
                >
                  <NextIcon style={{ fontSize: "18px" }} />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "5px",
                  }}
                >
                  <NextIcon
                    style={{
                      color: "transparent",
                      fontSize: "18px",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </CardActions>
      )}
    </Card>
  );
};

export default CarrouselConseillers;
