import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  a: {
    "&:visited": {
      color: "inherit",
    },
    color: "inherit",
    textDecoration: "none",
  },
  avatar: {
    backgroundColor: "#303d4a", // theme.palette.default,
  },
  card: {
    paddingBottom: 0,
  },
  cardContent: {
    "&:last-child": {
      paddingBottom: 8,
    },
    padding: 0,
    paddingLeft: 16,
    paddingRight: 0,
  },
  cardHeaderContent: {
    overflowX: "hidden",
    textOverflow: "ellipsis",
  },
  chipLabel: {
    fontSize: "0.6rem",
    marginLeft: 7,
    marginRight: 7,
  },
  clickable: {
    color: theme.palette.tonique,
    fontSize: 16,
    marginRight: 10,
  },
  clickableText: {
    "&:hover": {
      color: theme.palette.tonique,
    },
  },
  filetChip: {
    borderColor: "#47B0B5",
  },
  icons: {
    color: theme.palette.default,
  },
  menuItem: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    padding: 4,
  },
  notClickable: {
    color: theme.palette.common.dark,
    fontSize: 16,
    marginRight: 10,
  },
  notClickableText: {
    "&:hover": {
      color: theme.palette.common.dark,
    },
    color: theme.palette.common.dark,
  },
  particulierChip: {
    color: "#47B0B5",
    marginRight: 5,
  },
  proChip: {
    color: "#53868D",
    marginRight: 5,
  },
  root: {
    backgroundColor: theme.palette.common.light,
    boxShadow: "unset",
    "&:hover": {
      boxShadow:
        "0px 3px 3px 0px rgba(0,0,0,0.34), 0px 1px 1px -1px rgba(0,0,0,0.1)",
    },
    position: "relative",
  },
}));
