import { Theme, useMediaQuery } from "@material-ui/core";
import GridIcon from "@material-ui/icons/AppsOutlined";
import MapIcon from "@material-ui/icons/MapOutlined";
import ListIcon from "@material-ui/icons/ViewHeadlineOutlined";
import axios from "axios";
import DisplayModeSelector, {
  DisplayModeItem,
} from "components/DisplayModeSelector";
import { Locations } from "components/RefinementField/types";
import { MoreFiltersProvider } from "components/Searchbar/services/MoreFiltersProvider";
import TitrePage from "components/TitrePage";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useServices } from "services";
import { RealEstatesSearchParams } from "services/realEstates/types";
import { useRealEstates } from "services/realEstates/useRealEstates";
import { useRealEstatesSlug } from "services/realEstates/useRealEstatesSlug";
import { ListAndGalleryTemplate } from "UI/features/realEstates/templates/ListAndGallery";
import { MapTemplate } from "UI/features/realEstates/templates/Map";
import { deepEquality } from "utils/objects/deepEquality";
import { withDefinedValues } from "utils/objects/withDefinedValues";

import { RealEstatesContext } from "../containers/network";
import {
  clearLocations,
  clearParams,
  updateDisplayMode,
  updateLocations,
  updateParams,
  updateResponse,
} from "../containers/network/actions";
import { initParams } from "../containers/network/constants";
import { DisplayMode, RealEstatesResponse } from "../containers/network/types";
import { paramsToSlug, slugToParams } from "../slugHandler";

const displayModes: DisplayModeItem[] = [
  {
    hideOnMobile: false,
    icon: <MapIcon />,
    key: "map",
    label: "Carte",
  },
  {
    hideOnMobile: true,
    icon: <ListIcon />,
    key: "list",
    label: "Liste",
  },
  {
    hideOnMobile: false,
    icon: <GridIcon />,
    key: "galery",
    label: "Galerie",
  },
];

const getDisplayedResultsTotal = (result: RealEstatesResponse) => {
  const over10kLabel = "+ de 10000";

  if (!result.total) return 0;

  return result.total > 10000 ? over10kLabel : result.total;
};

export const RealEstatesPage: React.FC = () => {
  const {
    l10n: { t },
  } = useServices();

  const { state, dispatch } = useContext(RealEstatesContext);
  const { clearSlug, storeSlug, getStoredSlug } = useRealEstatesSlug();
  const { search } = useRealEstates();
  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );

  const paramsRef = useRef<RealEstatesSearchParams>();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const onChange = React.useCallback(
    (next) => {
      const cleanNext = withDefinedValues(next);
      const hasKeys = Object.keys(cleanNext).length > 0;

      if (!hasKeys) {
        clearSlug();
        dispatch(clearParams());
        return;
      }

      if (hasKeys && deepEquality(cleanNext, initParams)) return;
      dispatch(updateParams(next));
      storeSlug(paramsToSlug(state.params, state.locations));
    },
    [state.params]
  );

  const onLocationChange = React.useCallback(
    ({
      villes = [],
      departements = [],
      regions = [],
      quartiers = [],
    }: Partial<Locations["value"]>) => {
      const nextLocations = {
        ...state.locations,
        value: {
          ...state.locations?.value,
          ...{
            departements,
            quartiers,
            regions,
            villes,
          },
        },
      } as Locations;
      dispatch(updateLocations(nextLocations));
      storeSlug(paramsToSlug(state.params, nextLocations));
    },
    [state.locations]
  );

  const loadData = useCallback(async () => {
    const params = withDefinedValues({
      ...state.params,
      page: currentPage,
    }) as RealEstatesSearchParams;
    if (deepEquality(paramsRef.current, params)) return;

    paramsRef.current = params;

    if (currentPage === 1) setLoading(true);

    const result = await search(params, axios.CancelToken.source().token);

    const data =
      currentPage === 1
        ? [...result.data]
        : [...state.response.data, ...result.data];

    setLoading(false);

    dispatch(updateResponse({ ...result, data }));
  }, [state.params, search, currentPage]);

  const loadMore = useCallback(() => {
    if (!state.response.hasMore) return;
    setCurrentPage(currentPage + 1);
  }, [state.response.hasMore, currentPage]);

  const onClear = useCallback(() => {
    clearSlug();
    dispatch(clearParams());
    dispatch(clearLocations());
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    if (state.displayMode === "map") return;
    if (isLargeScreen) {
      dispatch(updateDisplayMode("list"));
      return;
    }

    dispatch(updateDisplayMode("galery"));
  }, [isLargeScreen]);

  useEffect(() => {
    const storedSlug = getStoredSlug();
    if (!storedSlug) return;
    const paramsFromSlug = slugToParams(storedSlug);
    dispatch(updateParams(paramsFromSlug.params));
    dispatch(updateLocations(paramsFromSlug.locations));
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [state.params.sortAsc, state.params.sortColumn]);

  useEffect(() => {
    const nextSlug = paramsToSlug(state.params, state.locations);
    storeSlug(nextSlug);
    setCurrentPage(1);
    if (currentPage === 1) loadData();
  }, [state.params, state.locations, storeSlug]);

  useEffect(() => {
    loadData();
  }, [currentPage]);
  const displayModeHandler = useCallback(
    (mode: string) => {
      dispatch(updateDisplayMode(mode as DisplayMode));
    },
    [dispatch]
  );

  const displayModeSelector = useCallback(
    () =>
      (
        <DisplayModeSelector
          onChange={displayModeHandler}
          selected={String(state.displayMode)}
          items={displayModes}
        />
      ) as any,
    [displayModeHandler, state.displayMode]
  );

  const resultsTotal = getDisplayedResultsTotal(state.response);
  const showMap = state.displayMode === "map";

  return (
    <MoreFiltersProvider>
      <div
        style={{
          maxHeight: "100%",
          overflow: state.displayMode === "map" ? "hidden" : "inherit",
        }}
      >
        <div>
          <TitrePage
            categorie={t("Biens")}
            page={t("Biens disponibles")}
            nbResultats={`${resultsTotal} ${t("résultat(s)")}`}
            filePath={"/static/markdowns/biensEnVente.md"}
            Composant={displayModeSelector}
          />
        </div>

        {!showMap && (
          <ListAndGalleryTemplate
            displayMode={state.displayMode}
            searchResponse={state.response}
            locations={state.locations}
            params={state.params}
            onChange={onChange}
            onClear={onClear}
            onLocationChange={onLocationChange}
            loadMore={loadMore}
            loading={loading}
          />
        )}

        {showMap && (
          <MapTemplate
            displayMode={state.displayMode}
            searchResponse={state.response}
            locations={state.locations}
            params={state.params}
            onChange={onChange}
            onClear={onClear}
            onLocationChange={onLocationChange}
            loadMore={loadMore}
            loading={loading}
          />
        )}
      </div>
    </MoreFiltersProvider>
  );
};
