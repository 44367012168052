import { Text16px } from "UI/shared/atoms/typographies/Text16px";
import React from "react";
import { useServices } from "services";

export interface PropertyTypeCaptionProps {
  label: string;
}

export const PropertyTypeCaption: React.FC<PropertyTypeCaptionProps> = ({
  label,
}) => {
  const {
    l10n: { t },
  } = useServices();
  return <Text16px>{t(label).replace(" ", "\xa0")}</Text16px>;
};
