import { makeStyles } from "@material-ui/core/styles";

import { ITheme } from "../../../../theme/ts/main";

export const useStyles = makeStyles((theme: ITheme) => ({
  content: {
    overflowX: "hidden",
    padding: "20px",
  },
  contentBody: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "10px",
    paddingTop: "10px",
  },
  contentTitle: {
    paddingBottom: "10px",
  },
  explanations: {
    paddingTop: "30px",
  },
  footer: {
    borderTop: "1px solid",
    borderTopColor: theme.palette.default,
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px",
    width: "100%",
  },
  header: {
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.greyMedium,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  headerTitle: {
    ["@media (max-width: 700px)"]: {
      alignItems: "flex-start",
      flexDirection: "column",
    },
    alignItems: "center",
    display: "flex",
    padding: "20px",
  },
  statTitle: {
    color: theme.palette.default,
    textAlign: "center",
  },
  statValue: {
    color: theme.palette.tonique,
  },
}));
