import React from "react";

import { TXTextField } from "../TXTextField";

export interface TXSelectMonoProps {
  id: string;
  name: string;
  label: string;
  value: any;
  onChange: (value: any) => void;
  disabled: boolean;
}

export const TXSelectMono: React.FC<TXSelectMonoProps> = (props) => {
  const { id, name, label, value, onChange, disabled } = props;
  return (
    <TXTextField
      select
      id={id}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      {props.children}
    </TXTextField>
  );
};
