import { AdaptedRefinements } from "../../../components/RefinementField/types";
import { RequestForInformationsCriteria } from "../../../types";

export const initialState: AdaptedRefinements<RequestForInformationsCriteria> = {
  source: {
    refinement: {
      kind: "multiple-text",
      value: [],
    },
    rank: 3,
    moreFiltersCategory: "display",
    size: 3,
    displayMode: "list",
  },
  contact: {
    refinement: {
      kind: "single-text",
      value: "",
    },
    rank: 2,
    size: 3,
    moreFiltersCategory: "characteristics",
    displayMode: "list",
  },
  adReference: {
    refinement: {
      kind: "single-text",
      value: "",
    },
    rank: 1,
    size: 3,
    moreFiltersCategory: "characteristics",
    displayMode: "list",
  },
  status: {
    refinement: {
      kind: "multiple-text",
      value: [
        {
          isRefined: true,
          label: "A traiter",
          value: "ToBeTreated",
          count: 0,
        },
      ],
    },
    size: 3,
    rank: 4,
    moreFiltersCategory: "characteristics",
    displayMode: "list",
  },
  receivedAt: {
    refinement: {
      kind: "date-range",
      value: {
        min: undefined,
        max: undefined,
      },
    },
    rank: 5,
    size: 12,
    moreFiltersCategory: "dates",
    displayMode: "moreFilters",
  },
};
