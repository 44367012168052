import { useServices } from "services";
import { useTxHttp } from "services/http";
import { string } from "api/DTOs/Common/Types";

export interface PigeNotes {
  Id: string;
  AnnonceId: string;
  Notes: string;
  AgentId: string;
}

function pigeNotesAdapter(result: any = {}): PigeNotes {
  return {
    Id: result.id || string,
    AnnonceId: result.annonceId || string,
    Notes: result.notes || string,
    AgentId: result.agentId || string,
  };
}

export const usePigeNotes = () => {
  const { authenticationService, endpoints } = useServices();
  const { get, put, del } = useTxHttp();

  const getDefaultNotes = () =>
    pigeNotesAdapter({ id: "00000000-0000-0000-0000-000000000000" });

  const loadPigeNotes = (pigeId: string) => {
    const url = endpoints.pige.notes(pigeId);
    return get<any>(url)
      .then((response) => pigeNotesAdapter({ ...response.data, pigeId }))
      .catch((error) =>
        pigeNotesAdapter({
          id: "00000000-0000-0000-0000-000000000000",
          pigeId,
        })
      );
  };

  const handleDeleteNote = (pigeNotes: PigeNotes) => {
    return del<void>(endpoints.pige.notes(pigeNotes.AnnonceId), {
      content: JSON.stringify(pigeNotes),
      contentType: "application/json",
    }).then(() =>
      pigeNotesAdapter({
        id: "00000000-0000-0000-0000-000000000000",
        annonceId: pigeNotes.AnnonceId,
      })
    );
  };

  const handleEditNotes = (pigeNotes: PigeNotes) => {
    const userData = authenticationService.getUserData();
    pigeNotes.AgentId = userData.UniqueId;
    return put(endpoints.pige.notes(pigeNotes.AnnonceId), {
      content: JSON.stringify(pigeNotes),
      contentType: "application/json",
    }).then(() => {
      return pigeNotes;
    });
  };

  return {
    getDefaultNotes,
    loadPigeNotes,
    handleEditNotes,
    handleDeleteNote,
  };
};
