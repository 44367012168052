import { Box } from "@material-ui/core";
import React from "react";
import { breakpoint } from "theme/main";

export const TabPanel = ({ children, value, index, extraStyle, ...other }) => (
  <div
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
    style={{ height: "calc(100vh - 200px)" }}
  >
    {value === index && (
      <Box
        p={2}
        style={{
          ...(breakpoint().xs
            ? {
                padding: "16px",
              }
            : {}),
          height: "100%",
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    )}
  </div>
);

export const Panel = ({ tabValue, content, id }) => (
  <TabPanel value={tabValue} index={id} dir="y">
    {content}
  </TabPanel>
);
