import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    // borderLeft: '5px solid ' + theme.palette.tonique,
    // borderRadius: '0px',
    padding: theme.spacing(2),
  },
}));

export default function PaperX({ ...props }) {
  const classes = useStyles();
  return <Paper className={classes.paper} {...props} />;
}
