import { Avatar, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useServices } from "services";
import { toAvatarContent } from "services/portfolio/helpers";
import { IdentityDto } from "services/portfolio/types";

import useStyles from "../../../styles";

export const Identity: React.FC<{
  identity: IdentityDto;
}> = ({ identity }) => {
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles();

  const gender = identity.gender === "None" ? "" : t(identity.gender);

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={2}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Avatar aria-label={identity.lastName} className={classes.avatar}>
            {toAvatarContent(identity)}
          </Avatar>
        </div>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="body1">{gender}</Typography>
        <Typography variant="h4">{`${identity.firstName || ""} ${(
          identity.lastName || ""
        ).toUpperCase()}`}</Typography>
      </Grid>
    </Grid>
  );
};
