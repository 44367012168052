import React from "react";
import { Checkbox, Theme, withStyles } from "@material-ui/core";
import { ITheme } from "theme/ts/main";

export const ToniqueCheckbox = withStyles((theme: Theme) => ({
  root: {
    "&.Mui-checked": {
      color: (theme as ITheme).palette.tonique,
    },
  },
}))(Checkbox);
