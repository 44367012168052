import { Circle } from "react-leaflet";
import { buColors } from "utils/formatage";
import React from "react";

const CircleTX = ({ item, radius = 10 }) => (
  <Circle
    key={`${item.id}-circle`}
    radius={radius}
    center={[item.lat, item.long]}
    fill="true"
    color={buColors[item.bu]}
    interactive={false}
  />
);

export default CircleTX;
