import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import SendIcon from "@material-ui/icons/EmailOutlined";
import PhoneIcon from "@material-ui/icons/LocalPhoneOutlined";
import React from "react";

import { useServices } from "../../../../../../services";
import AvatarConseiller from "../../../../mon-reseau/conseillers/components/Hit/components/Avatar";

const useStyles = makeStyles((theme) => ({
  a: {
    "&:visited": {
      color: "inherit",
    },
    color: "inherit",
    textDecoration: "none",
  },
  avatar: {
    backgroundColor: theme.palette.default,
    height: 50,
    width: 50,
  },
  card: {
    backgroundColor: "#F2F2F2",
    paddingBottom: 0,
  },
  cardContent: {
    "&:last-child": {
      paddingBottom: 8,
    },
    backgroundColor: "#F2F2F2",
    padding: 0,
    paddingLeft: 16,
    paddingRight: 0,
  },
  cardHeaderContent: {
    overflowX: "hidden",
    textOverflow: "ellipsis",
  },
  chipLabel: {
    fontSize: "0.6rem",
    marginLeft: 7,
    marginRight: 7,
  },
  clickable: {
    color: theme.palette.tonique,
    fontSize: 16,
    marginRight: 10,
  },
  clickableText: {
    "&:hover": {
      color: theme.palette.tonique,
    },
  },
  filetChip: {
    borderColor: "#47B0B5",
  },
  icons: {
    color: theme.palette.default,
  },
  menuItem: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    padding: 4,
  },
  notClickable: {
    color: theme.palette.common.dark,
    fontSize: 16,
    marginRight: 10,
  },
  notClickableText: {
    "&:hover": {
      color: theme.palette.common.dark,
    },
    color: theme.palette.common.dark,
  },
  particulierChip: {
    color: "#47B0B5",
    marginRight: 5,
  },
  proChip: {
    color: "#53868D",
    marginRight: 5,
  },
  root: {
    "&:hover": {
      boxShadow:
        "0px 3px 3px 0px rgba(0,0,0,0.34), 0px 1px 1px -1px rgba(0,0,0,0.1)",
    },
    position: "relative",
  },
}));

const CarteContactPrivilegie = ({ contact, type = "" }) => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();

  if (!contact) return <></>;

  const mailLink = `mailto:${contact.adresseEmail}`;

  const handleMailClick = (e) => {
    e.preventDefault();
    const a = document.createElement("a");
    a.target = "_blank";
    a.href = mailLink;
    a.rel = "noreferrer noopener";
    a.click();
  };

  const telephoneLink = `tel:${contact.telephone}`;

  const handleTelephoneClick = (e) => {
    e.preventDefault();
    const a = document.createElement("a");
    a.target = "_blank";
    a.href = telephoneLink;
    a.rel = "noreferrer noopener";
    a.click();
  };

  return (
    <Card className={classes.root} style={{ zIndex: 0 }}>
      <CardHeader
        avatar={<AvatarConseiller conseiller={contact} taille={50} />}
        title={
          <div
            style={{
              height: "100%",
              position: "relative",
              width: "100%",
            }}
          >
            <Typography
              variant="h4"
              style={{
                color: "#5A6776",
              }}
            >
              {type}
            </Typography>
            <Typography
              variant="h4"
              style={{
                marginBottom: "5px",
              }}
            >
              {`${contact.civilite !== "None" ? t(contact.civilite) : ""} ${
                contact.prenom
              } ${contact.nom.toUpperCase()}`}
              {contact.prenom.length + contact.nom.length === 0 && (
                <span style={{ color: "#8e8e8e" }}>{t("Non renseigné")}</span>
              )}
            </Typography>
          </div>
        }
        titleTypographyProps={{ noWrap: true, variant: "h4" }}
        className={classes.card}
        classes={{
          content: classes.cardHeaderContent,
        }}
      />

      <CardContent classes={{ root: classes.cardContent }}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flex: 1,
            justifyContent: "flex-start",
            marginRight: "10px",
          }}
        >
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Tooltip title={t("Envoyer un mail")} placement="top">
              <IconButton
                className={classes.icons}
                onClick={handleMailClick}
                aria-label="mail"
              >
                <SendIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title={t("Appeler")} placement="top">
              <IconButton
                className={classes.icons}
                onClick={handleTelephoneClick}
                aria-label="appeler"
              >
                <PhoneIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};

export default CarteContactPrivilegie;
