import { saveAs } from "file-saver";
import { useCallback } from "react";
import { useServices } from "services";
import { useTxHttp } from "services/http";

export const useExport = () => {
  const { post } = useTxHttp();
  const { endpoints } = useServices();

  const download = useCallback(
    (payload: string[], fileName: string) => {
      post<Blob>(
        endpoints.portfolio.contacts.export,
        {
          content: payload,
          contentType: "application/json",
        },
        {},
        "blob"
      ).then((response) => {
        saveAs(response.data, fileName);
      });
    },
    [endpoints.portfolio.contacts.export, post]
  );

  return {
    download,
  };
};
