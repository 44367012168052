import { useCallback, useEffect, useState } from "react";
import { useServices } from "services";
import { useTxHttp } from "services/http";
import { MatchingProfileRequestDto } from "services/matching/MatchingProfileRequestDto";

interface PigeMatchingQueryState {
  matching?: MatchingProfileRequestDto;
  loading: boolean;
  error?: any;
}

export const useMatchingAdapter = (adId: string) => {
  const {
    endpoints: { pige },
  } = useServices();

  const { get } = useTxHttp();

  const [state, setState] = useState<PigeMatchingQueryState>({
    loading: false,
  });

  const startQuery = useCallback(() => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
  }, []);

  const endQueryWithMatching = useCallback(
    (matching: MatchingProfileRequestDto) => {
      setState((prev) => ({
        ...prev,
        loading: false,
        matching,
      }));
    },
    []
  );

  const endQueryWithError = useCallback((error?: any) => {
    setState((prev) => ({
      ...prev,
      loading: false,
      error,
    }));
  }, []);

  const getMatching = useCallback(
    async (id: string) => {
      startQuery();
      const url = pige.acquirersMatching(id);
      const matchingResponse = await get<MatchingProfileRequestDto>(url);

      if (!matchingResponse) {
        endQueryWithError({
          message: `An error occured while trying to get matching for ad ${id}`,
        });
        return;
      }

      endQueryWithMatching(matchingResponse.data);
    },
    [get, pige.acquirersMatching]
  );

  useEffect(() => {
    if (!adId) return;
    getMatching(adId);
  }, [adId]);

  return state;
};
