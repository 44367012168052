import React, { ReactElement, useEffect, useState } from "react";
import { Polygon } from "react-leaflet";

import { BienForMap } from "../../components/Map";
import useGeoPolygon from "../../components/MapX/Map/services/useGeoPolygons/useGeoPolygon";
import getPolygonIsobarycenter from "../../components/MapX/Map/utils/getPolygonIsobarycenter";
import { useServices } from "..";
import { useGeoloc } from "../geoloc/useGeoloc";
import { Annonce } from "./types/Annonce";

const COULEUR_VILLE = "#FF9E25";

export const usePigeBienForMap = (annonce: Annonce) => {
  const [bienForMap, setBienForMap] = useState<BienForMap>({
    bu: "Digitregroup",
    latitude: annonce.bien.localisationGPS?.latitude,
    localisation: {
      adresse: annonce.bien.adresse,
      codePostal: annonce.bien.codePostal,
      villeNom: annonce.bien.ville,
    },
    longitude: annonce.bien.localisationGPS?.longitude,
  });

  const [location, setLocation] = useState<string>();
  const [polygon, setPolygon] = useState<ReactElement>();

  const { search: geolocSearch } = useGeoloc();
  const {
    endpoints: {
      geoloc: { obtenirUneVilleParId },
    },
  } = useServices();

  useEffect(() => {
    const isWithoutLocation = annonce.bien.adresse === "";

    if (isWithoutLocation) {
      if (annonce.bien.ville) {
        geolocSearch({ autocomplete: annonce.bien.ville, size: 1 }).then(
          (localisations) => {
            const matchingResult = localisations.find(
              (localisation: any) => localisation.typeGeoEnum === "Ville"
            );

            if (matchingResult) setLocation(matchingResult.id);
          }
        );
      }
    }
  }, [annonce]);

  const { geoPolygon } = useGeoPolygon({
    endpoint: obtenirUneVilleParId,
    guid: location,
  });

  useEffect(() => {
    if (geoPolygon && geoPolygon.wkt.length > 0) {
      const { latitude, longitude } = getPolygonIsobarycenter(
        geoPolygon.wkt.flat()
      );

      if (!!latitude && !!longitude) {
        setBienForMap((prev: any) => {
          return {
            ...prev,
            latitude,
            longitude,
          };
        });

        setPolygon(
          <Polygon
            interactive={false}
            color={COULEUR_VILLE}
            positions={geoPolygon.wkt}
            key={location}
          />
        );
      }
    }
  }, [geoPolygon]);

  return { bienForMap, polygon };
};
