export const withDefinedValues = <T extends Object>(obj: T) => {
  const keys = Object.keys(obj) as (keyof T)[];
  const validKeys = keys.filter((key) => obj[key] != null);

  if (validKeys.length === keys.length) return obj;

  return validKeys.reduce((acc, key) => {
    return {
      ...acc,
      [key]: obj[key],
    };
  }, {}) as Partial<T>;
};
