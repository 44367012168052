import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AutoCompleteAddress from "components/AutoCompleteAddress";
import SingleCitySelect, { City } from "components/SingleCitySelect";
import React, { FC } from "react";
import { toCity, toPlace } from "services/portfolio/types";

import { GridContainer } from "../../../../../../components/containers/GridContainer";
import { TXTextField } from "../../../../../../components/formControls/TXTextField";
import { useServices } from "../../../../../../services";
import { useContactForm } from "../context/ContactFormContext";
import useStyles from "../styles";
import { SectionTitle } from "./Sections";

export const Address: FC<{
  titleWithPadding?: boolean;
}> = ({ titleWithPadding = false }) => {
  const {
    l10n: { t },
  } = useServices();
  const { isDisabled, contactDto, setContactDto } = useContactForm();

  const isCityChosen = !!contactDto.address?.city?.inseeCode;
  const address = contactDto.address;
  const selectedAddress = toPlace(contactDto.address);
  const chosenCity = toCity(contactDto.address?.city);

  const handleCityUpdate = (city: City) => {
    setContactDto({
      ...contactDto,
      address: {
        ...contactDto.address,
        city: {
          ...(contactDto.address?.city || {}),
          id: city.id,
          inseeCode: city.codeInsee || "",
          name: city.nom || "",
          postalCodes: city.codesPostaux,
        },
        departement: {
          id: city?.departement?.id || "",
          name: city?.departement?.nom || "",
        },
        region: {
          id: city?.departement?.region?.id || "",
          name: city?.departement?.region?.nom || "",
        },
      },
    });
  };

  const saveAutomaticAddress = ({ address }: { address: string }) => {
    setContactDto({
      ...contactDto,
      address: { ...contactDto.address, value: address },
    });
  };

  const saveManualAddress = ({
    target: { name, value },
  }: {
    target: { name: string; value: string };
  }) => {
    setContactDto({
      ...contactDto,
      address: { ...contactDto.address, [name]: value },
    });
  };

  const classes = useStyles();
  return (
    <GridContainer>
      <Grid item xs={12}>
        <SectionTitle
          withoutPadding={!titleWithPadding}
          title={"Adresse"}
          mode="non obligatoire"
        />
        <Typography variant={"h6"} className={classes.addressText}>
          Commencez par saisir une ville parmi le référentiel officiel de
          l&apos; Etat:
        </Typography>
        <SingleCitySelect
          villeChoisie={chosenCity}
          setVille={handleCityUpdate}
          disabled={isDisabled}
          isClearable={true}
        />
      </Grid>
      {isCityChosen && (
        <Grid item xs={12}>
          <GridContainer>
            <Grid item xs={12}>
              <AutoCompleteAddress
                selectedAddress={selectedAddress}
                setSelectedAddress={saveAutomaticAddress}
                disabled={isDisabled || !isCityChosen}
                isClearable={true}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={"h6"} className={classes.addressText}>
                Si l&apos; adresse complète n&apos; est pas encore référencée
                par l&apos; Etat, vous pouvez la modifier manuellement:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TXTextField
                id="adresse"
                name="value"
                label={t("Adresse manuelle")}
                value={selectedAddress.value}
                onChange={saveManualAddress}
              />
            </Grid>
            <Grid item xs={12}>
              <TXTextField
                id="complement-adresse"
                name="supplement"
                label={t("Complément adresse")}
                value={address?.supplement || ""}
                onChange={saveManualAddress}
              />
            </Grid>
          </GridContainer>
        </Grid>
      )}
    </GridContainer>
  );
};
