import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { NumberField } from "components/NumberField";
import React, { FC, useEffect, useState } from "react";
import { MatchingProfileRequestDto } from "services/matching/MatchingProfileRequestDto";

import useNotifier from "../../../../../../../../../components/Notifier/useNotifier";
import { useServices } from "../../../../../../../../../services";
import { useProfiles } from "../../../../../../../../../services/profiles";
import { AccountType } from "../../../../models/accountType";
import { ProfileReadDto } from "../../../../models/profilesReadDto";
import { SaleHouseProfileReadDto } from "../../../../models/Proposal/ForSale/House/saleHouseProfileReadDto";
import { SaleHouseProfileWriteDto } from "../../../../models/Proposal/ForSale/House/saleHouseProfileWriteDto";
import { SectionTitle } from "../../../components/SectionTitle";
import { WriteSellerMatchingNumber } from "../../../MatchingNumber/WriteSellerMatchingNumber";
import { SelectMaturity } from "../../../Maturity";
import { NotesInput } from "../../../Notes";
import { SelectPropertyAge } from "../../../Search/Purchase/SelectPropertyAge";
import { Write } from "../../../Write";
import { SelectJoinOwnershipType } from "../../JointOwnership";
import { SaleAnnexes } from "../../SaleAnnexes";
import { SaleBudgetHouseInput } from "./SaleBudgetHouse";
import { SaleHouseEquipments } from "../../SaleHouseEquipments";
import { SaleParking } from "../../SaleParking";
import { SalePlaceInput } from "../../SalePlace";
import { SaleRoomsInput } from "../../SaleRooms";
import { SaleSizeInput } from "../../SaleSize";
import { SaleSummariesInput } from "../../SaleSummaries";
import { SelectExposureType } from "../../SelectExposure";
import { SelectGeneralStateType } from "../../SelectGeneralStateType";
import MatchingAdaptater from "./MatchingAdapter";
import classes from "./Write.module.css";

export const HouseForSaleProfileWrite: FC<{
  contactId: string;
  account: AccountType;
  initial?: SaleHouseProfileReadDto;
  more: boolean;
  showMore: () => void;
  sendUpdatedProfile?: (profile: ProfileReadDto | undefined) => void;
  handleAfterUpdate?: () => void;
}> = ({
  contactId,
  account,
  initial,
  more,
  showMore,
  sendUpdatedProfile,
  handleAfterUpdate,
  children,
}) => {
  const { add, update } = useProfiles(contactId);
  const [payload, setPayload] = useState(create(account, initial));
  const [state, setState] = useState<"none" | "saving" | "saved" | "error">(
    "none"
  );
  const { modal } = useServices();
  const { showSnack } = useNotifier();
  const mode = initial ? "update" : "add";
  const [pristine, setPristine] = useState(true);
  const [isFormValid, setIsFormValid] = useState({
    address: false,
    annexes: true,
    financialInfo: false,
    parts: false,
    propertyInfo: false,
  });

  const disabled = state === "saving";
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down("sm"));

  const [matchingCriteria, setMatchingCriteria] = useState<
    MatchingProfileRequestDto | undefined
  >(undefined);
  useEffect(() => {
    setMatchingCriteria(MatchingAdaptater(payload.features));
  }, [payload]);

  useEffect(() => {
    switch (state) {
      case "none":
        break;
      case "saving":
        if (Object.entries(isFormValid).some(([_, b]) => !b)) {
          setPristine(false);
          setState("none");
        } else {
          (mode === "add"
            ? add("SaleHouseProfile", payload)
            : update(
                "SaleHouseProfile",
                initial!.id,
                payload,
                sendUpdatedProfile
              )
          )
            .then(() => {
              if (handleAfterUpdate) handleAfterUpdate();
              setState("saved");
            })
            .catch(() => setState("error"));
        }
        break;
      case "saved":
        modal.close();
        showSnack({ message: "Le profil est enregistré.", variant: "success" });
        break;
      case "error":
        showSnack({ message: "Une erreur est survenue.", variant: "danger" });
        break;
    }
  }, [state]);

  return (
    <Write
      pending={state === "saving"}
      save={() => setState("saving")}
      mode={mode}
      more={more}
      matching={<WriteSellerMatchingNumber criteria={matchingCriteria} />}
      showMore={showMore}
    >
      <div className={more && !mediaQuery ? classes.container : ""}>
        <div className={more ? classes.itemA : ""}>
          {children}
          <Grid item xs={12}>
            <SelectPropertyAge
              value={payload.features?.propertyInfo?.built || "All"}
              onChange={(built) =>
                setPayload((prev) => ({
                  ...prev,
                  features: {
                    ...prev.features,
                    propertyInfo: {
                      ...prev.features.propertyInfo,
                      built: built,
                    },
                  },
                }))
              }
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <SalePlaceInput
              value={payload.features?.address}
              onChange={(address, isValid) => {
                setIsFormValid((previous) => ({
                  ...previous,
                  address: isValid,
                }));
                setPayload((prev) => ({
                  ...prev,
                  features: {
                    ...prev.features,
                    address,
                  },
                }));
              }}
              disabled={disabled}
              pristine={pristine}
            />
          </Grid>
        </div>

        <div className={more ? classes.itemB : ""}>
          <Grid item xs={12}>
            <SaleBudgetHouseInput
              pristine={pristine}
              more={more}
              value={payload.features.financialInfo}
              onChange={(financialInfo, isValid) => {
                setIsFormValid((previous) => ({
                  ...previous,
                  financialInfo: isValid,
                }));
                setPayload((prev) => ({
                  ...prev,
                  features: {
                    ...prev.features,
                    financialInfo,
                  },
                }));
              }}
            />
          </Grid>

          <SectionTitle title="Quelles sont les caractéristiques ?" />
          {more && (
            <>
              <Grid item xs={12}>
                <NumberField
                  label="Année de construction"
                  onChange={(yearBuilt) => {
                    setPayload((prev) => ({
                      ...prev,
                      features: {
                        ...prev.features,
                        propertyInfo: {
                          ...prev.features.propertyInfo,
                          buildingInfo: {
                            ...prev.features.propertyInfo.buildingInfo,
                            yearBuilt,
                          },
                        },
                      },
                    }));
                  }}
                  value={payload.features.propertyInfo.buildingInfo.yearBuilt}
                  format={"####"}
                  adornement={""}
                />
              </Grid>
              <SelectGeneralStateType
                value={payload.features.propertyInfo.generalState}
                onChange={(generalState) => {
                  setPayload((prev) => ({
                    ...prev,
                    features: {
                      ...prev.features,
                      propertyInfo: {
                        ...prev.features.propertyInfo,
                        generalState,
                      },
                    },
                  }));
                }}
              />
              <SelectExposureType
                value={payload.features.propertyInfo.exposure}
                onChange={(exposure) => {
                  setPayload((prev) => ({
                    ...prev,
                    features: {
                      ...prev.features,
                      propertyInfo: {
                        ...prev.features.propertyInfo,
                        exposure,
                      },
                    },
                  }));
                }}
              />
            </>
          )}
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <SaleSizeInput
                    pristine={pristine}
                    value={payload.features.propertyInfo}
                    onChange={(propertyInfo, isValid) => {
                      setIsFormValid((previous) => ({
                        ...previous,
                        propertyInfo: isValid,
                      }));
                      setPayload((prev) => ({
                        ...prev,
                        features: {
                          ...prev.features,
                          propertyInfo,
                        },
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SaleRoomsInput
                    pristine={pristine}
                    value={payload.features.parts.summaries}
                    onChange={(summaries, isValid) => {
                      setIsFormValid((previous) => ({
                        ...previous,
                        parts: isValid,
                      }));
                      setPayload((prev) => ({
                        ...prev,
                        features: {
                          ...prev.features,
                          parts: { ...prev.features.parts, summaries },
                        },
                      }));
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SaleSummariesInput
                pristine={pristine}
                value={payload.features.parts.summaries}
                more={more}
                onChange={(summaries, isValid) => {
                  setIsFormValid((previous) => ({
                    ...previous,
                    parts: isValid,
                  }));
                  setPayload((prev) => ({
                    ...prev,
                    features: {
                      ...prev.features,
                      parts: { ...prev.features.parts, summaries },
                    },
                  }));
                }}
              />
            </Grid>
            {more && (
              <Grid item xs={12}>
                <SelectJoinOwnershipType
                  disabled={false}
                  value={payload.features.propertyInfo.joinOwnership}
                  onChange={(joinOwnership) => {
                    setPayload((prev) => ({
                      ...prev,
                      features: {
                        ...prev.features,
                        propertyInfo: {
                          ...prev.features.propertyInfo,
                          joinOwnership,
                        },
                      },
                    }));
                  }}
                />
              </Grid>
            )}
            {more && (
              <Grid item xs={12}>
                <SaleHouseEquipments
                  value={payload.features.amenities ?? []}
                  onChange={(amenities) => {
                    setPayload((prev) => ({
                      ...prev,
                      features: {
                        ...prev.features,
                        amenities,
                      },
                    }));
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>

        <div className={more ? classes.itemC : ""}>
          {more && (
            <>
              <SaleParking
                value={payload.features.parts.summaries.parkings}
                onChange={(parkings) => {
                  setPayload((prev) => ({
                    ...prev,
                    features: {
                      ...prev.features,
                      parts: {
                        ...prev.features.parts,
                        summaries: {
                          ...prev.features.parts.summaries,
                          parkings,
                        },
                      },
                    },
                  }));
                }}
              />
              <Grid item xs={12}>
                <SaleAnnexes
                  onChange={(annexes, isValid) => {
                    setIsFormValid((previous) => ({
                      ...previous,
                      annexes: isValid,
                    }));

                    setPayload((prev) => ({
                      ...prev,
                      features: {
                        ...prev.features,
                        parts: {
                          ...prev.features.parts,
                          annexes,
                        },
                      },
                    }));
                  }}
                  value={payload.features.parts.annexes || []}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <NotesInput
              value={payload.notes}
              onChange={(notes) => setPayload((prev) => ({ ...prev, notes }))}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectMaturity
              onChange={(maturity) => {
                setPayload((prev) => ({
                  ...prev,
                  maturity,
                }));
              }}
              value={payload.maturity}
              disabled={disabled}
            />
          </Grid>
        </div>
      </div>
    </Write>
  );
};

function create(
  account: AccountType,
  initial?: SaleHouseProfileReadDto
): SaleHouseProfileWriteDto {
  return {
    account,
    creationDate: new Date(),
    features: initial
      ? initial.features
      : {
          address: {},
          amenities: [],
          financialInfo: {},
          parts: {
            annexes: [],
            summaries: {
              bathRooms: { count: 0 },
              bedRooms: { count: 0 },
              kitchen: {},
              levels: { count: 0 },
              livingRoom: { squareMeter: 0 },
              parkings: {
                hasBox: false,
                hasExteriorParking: false,
                hasInteriorParking: false,
              },
              rooms: { count: 0 },
              toilets: { count: 0 },
            },
          },
          propertyInfo: { buildingInfo: {} },
        },
    maturity: initial?.maturity || "None",
    notes: initial?.notes || "",
    origin: initial?.origin || "TXProfile",
    reference: initial?.reference || "",
  };
}
