import { Grid } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";

import { Tab } from "./DetailedEntityTabsLayout";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
    },
    summary: {
      fontWeight: "bold",
    },
  })
);

export const MobileMenus: React.FC<{ tabs: Tab[] }> = ({ tabs }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid item xs={12}>
      <div className={classes.root}>
        {tabs.map((tab, index) => {
          const panel = `panel${index}`;
          return (
            <Accordion
              key={index}
              expanded={expanded === panel}
              onChange={handleChange(panel)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.summary}
              >
                {tab.titre}
              </AccordionSummary>
              <AccordionDetails>{tab.content}</AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </Grid>
  );
};
