import useNotifier from "components/Notifier/useNotifier";
import { EventsDetailedView } from "UI/shared/organisms/Events/EventsDetailedView";
import { emptyContactEventSent } from "UI/shared/organisms/Events/stateHandler";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useServices } from "services";
import {
  ContactEventReceived,
  ContactEventSent,
  EventKind,
} from "services/contactEventsTracking/dto/ContactEvent";
import { PigeEventErrors } from "services/piges";
import { EventsContext } from "..";
import { PigeAdEventsContext } from "../../contexts/PigeAdEventsContextProvider";

import { RelationEvent, TypePriseContact } from "./types";

interface AdActionsProps {
  annonceId: string;
  estActive: boolean;
  simplified: boolean;
  onCancelModal?: () => void;
}

export function AdActions({
  annonceId,
  simplified,
  onCancelModal,
}: AdActionsProps): JSX.Element {
  const { showSnack } = useNotifier();
  const {
    trackingService: { sendCustomEventToGoogle },
  } = useServices();

  const { events, add, deleteAd, update } = useContext(EventsContext);

  const { updateAdsWithEvents } = useContext(PigeAdEventsContext);

  const savePigeEvent = async (data: ContactEventSent) => {
    const _data = {
      commentaire: data.comment,
      date: moment(data.date).toISOString(),
      estAjouteAuCalendrier: data.haveToBeAddedToCalendar,
      typePriseContact: getTypePriseDeContactFromEventKind(data.kind),
    };

    showSnack({
      message: "Enregistrement en cours.",
      variant: "info",
      withLoader: true,
    });

    add(annonceId, _data)
      .then(() => {
        const customEvent = data.haveToBeAddedToCalendar
          ? "Ajout événement Outlook sur bien pige"
          : "Ajout événement simple sur bien pige";

        sendCustomEventToGoogle(customEvent);
        updateAdsWithEvents();
        showSnack({
          message: "Votre prise de contact a bien été ajoutée",
          variant: "success",
        });
      })
      .catch((error: Error) => {
        showSnack({
          message: t(error.message),
          variant:
            error.message === PigeEventErrors.TREMPLINX_ERROR
              ? "error"
              : "warning",
        });
      });
  };

  const updatePigeEvent = async (data: ContactEventSent) => {
    const _data = {
      calendrierId: data.calendarEventId || "",
      commentaire: data.comment,
      date: moment(data.date).toISOString(),
      estAjouteAuCalendrier: data.haveToBeAddedToCalendar,
      id: data.id,
      typePriseContact: getTypePriseDeContactFromEventKind(data.kind),
    };
    showSnack({
      message: "Enregistrement en cours.",
      variant: "info",
      withLoader: true,
    });

    update(annonceId, _data)
      .then(() => {
        const customEvent = "Modification événement sur bien pige";

        sendCustomEventToGoogle(customEvent);
        showSnack({
          message: "Votre prise de contact a bien été modifiée",
          variant: "success",
        });
      })
      .catch((error: Error) => {
        showSnack({
          message: t(error.message),
          variant:
            error.message === PigeEventErrors.TREMPLINX_ERROR
              ? "error"
              : "warning",
        });
      });
  };

  const deletePigeEvent = async (data: ContactEventReceived) => {
    const _data = {
      calendrierId: data.calendarEventId || "",
      commentaire: data.comment,
      date: moment(data.date).toISOString(),
      estAjouteAuCalendrier: data.calendarEventId !== "",
      id: data.id,
      typePriseContact: getTypePriseDeContactFromEventKind(data.kind),
    };
    showSnack({
      message: "Enregistrement en cours.",
      variant: "info",
      withLoader: true,
    });

    deleteAd(annonceId, _data)
      .then(() => {
        const customEvent =
          data.calendarEventId !== ""
            ? "Suppression événement Outlook sur bien pige"
            : "Suppression événement simple sur bien pige";

        sendCustomEventToGoogle(customEvent);
        updateAdsWithEvents();
        showSnack({
          message: "Votre prise de contact a bien été supprimée",
          variant: "success",
        });
      })
      .catch((error: Error) => {
        showSnack({
          message: t(error.message),
          variant:
            error.message === PigeEventErrors.TREMPLINX_ERROR
              ? "error"
              : "warning",
        });
      });
  };

  const [currentEvent, setCurrentEvent] = useState<ContactEventSent>(
    emptyContactEventSent
  );

  const {
    l10n: { t },
  } = useServices();

  const selectPigeEvent = (event: ContactEventReceived) => {
    const _selectedEvent: ContactEventSent = {
      ...event,
      haveToBeAddedToCalendar: event.calendarEventId !== "",
    };
    setCurrentEvent(_selectedEvent);
  };

  return (
    <EventsDetailedView
      events={events.map(EventContactReceivedFromRelationEvent)}
      selectedEvent={currentEvent}
      addEvent={savePigeEvent}
      updateEvent={updatePigeEvent}
      selectEvent={selectPigeEvent}
      deleteEvent={deletePigeEvent}
      simplifiedView={simplified}
      onCancel={onCancelModal}
    />
  );
}

const EventContactReceivedFromRelationEvent = (
  relationEvent: RelationEvent
): ContactEventReceived => ({
  calendarEventId: relationEvent.calendrierExterneEvenementId,
  comment: relationEvent.commentaire,
  date: relationEvent.date,
  id: relationEvent.id,
  kind: getEventKindFromTypePriseDeContact(relationEvent.typePriseContact),
});

const getEventKindFromTypePriseDeContact = (
  typePriseDeContact: TypePriseContact
) => {
  switch (typePriseDeContact) {
    case "AppelTelephonique":
      return "PhoneCall";
    case "EnvoiCourrier":
      return "SendMail";
    case "EnvoiMail":
      return "SendEmail";
    case "EnvoiSms":
      return "SendSms";
    case "RendezVous":
      return "Appointment";
    case "Autre":
      return "Other";
  }
};

const getTypePriseDeContactFromEventKind = (eventKind: EventKind) => {
  switch (eventKind) {
    case "PhoneCall":
      return "AppelTelephonique";
    case "SendMail":
      return "EnvoiCourrier";
    case "SendEmail":
      return "EnvoiMail";
    case "SendSms":
      return "EnvoiSms";
    case "Appointment":
      return "RendezVous";
    case "Other":
      return "Autre";
  }
};
