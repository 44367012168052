import { Box, ListItem, ListItemText, Typography } from "@material-ui/core";
import { Checkbox } from "components/Checkbox";
import { InfiniteScrollList } from "UI/shared/organisms/InfiniteScrollList/InfiniteScrollList";
import React from "react";

import useStyles from "./styles";

export interface CheckboxItem {
  id: string;
  label: string;
}
export interface CheckboxListProps {
  values: CheckboxItem[];
  loadMore: () => void;
  onChange: (data: string[]) => void;
  getAll: () => Promise<CheckboxItem[]>;
  entities: string;
  total: number;
}

export const CheckboxList: React.FC<CheckboxListProps> = ({
  values,
  loadMore,
  onChange,
  getAll,
  entities,
  total,
}) => {
  const classes = useStyles();

  const [checked, setChecked] = React.useState<string[]>([]);

  const handleToggle = (id: string) => () => {
    const currentIndex = checked.indexOf(id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    onChange(newChecked);
  };

  const selectAll = () => {
    if (checked.length > 0) {
      setChecked((_prev) => []);
      return;
    }
    getAll().then((data) => {
      const newChecked = [
        ...data.map((v) => {
          return v.id;
        }),
      ];
      setChecked((_prev) => newChecked);
      onChange(newChecked);
    });
  };

  const getIntermediate =
    checked.length > 0 &&
    checked.length !== total &&
    checked.length !== values.length;
  const getAllChecked = checked.length === total;

  return (
    <>
      <div className={classes.container}>
        <Typography
          variant={"subtitle1"}
          className={classes.selectedItemsCountContainer}
        >
          <span className={classes.selectedItemsCount}>{checked.length}</span>
          <span>{` ${entities} sélectionnés`}</span>
        </Typography>
        <ListItem>
          <ListItemText
            primary={"Tout désélectionner"}
            className={classes.selectAll}
          />
          <Checkbox
            edge="end"
            color={"default"}
            checked={getAllChecked}
            indeterminate={getIntermediate}
            onChange={selectAll}
          />
        </ListItem>
      </div>
      <div>
        <Box className={classes.items}>
          <InfiniteScrollList loadMore={loadMore} pageSize={20}>
            {values.map((item) => {
              const labelId = `checkbox-list-secondary-label-${item.id}`;
              return (
                <ListItem key={item.id} className={classes.item}>
                  <ListItemText id={labelId} primary={item.label} />
                  <Checkbox
                    edge="end"
                    onChange={handleToggle(item.id)}
                    checked={checked.indexOf(item.id) !== -1}
                    inputProps={{ "aria-labelledby": labelId }}
                    color={"default"}
                  />
                </ListItem>
              );
            })}
          </InfiniteScrollList>
        </Box>
      </div>
    </>
  );
};
