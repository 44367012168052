import { makeStyles, TextField } from "@material-ui/core";
import AutoComplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import { useDebounce } from "react-use";

import { onlySpaces } from "../../../utils/onlySpaces";
import { AutoCompleteText } from "../types";

export interface AutoCompleteRefinementProps {
  getOptions: (search: string) => Promise<string[]>;
  onChange: (refinement: AutoCompleteText) => void;
  label: string;
}

interface AutoCompleteRefinementState {
  options: string[];
  current: string;
  inputValue: string;
  debouncedValue: string;
}

const useStyles = makeStyles(() => ({
  input: {
    height: 6,
  },
}));

export const AutoCompleteRefinement: React.FC<AutoCompleteRefinementProps> = ({
  label,
  onChange,
  getOptions,
}: AutoCompleteRefinementProps) => {
  const [state, setState] = useState<AutoCompleteRefinementState>({
    current: "",
    debouncedValue: "",
    inputValue: "",
    options: [],
  });

  const updateState = (obj: Partial<AutoCompleteRefinementState>) => {
    setState((prev) => ({ ...prev, ...obj }));
  };

  const handleGetOptions = async (nextInputValue: string) => {
    if (nextInputValue.length < 3) {
      updateState({
        current: "",
        inputValue: nextInputValue,
        options: [],
      });

      return;
    }

    const nextOptions = await getOptions(nextInputValue);

    updateState({
      options: nextOptions,
    });
  };

  const handleSelectedValue = (
    _: React.ChangeEvent<unknown>,
    value: string | null
  ) => {
    updateState({ current: value ?? "" });
    onChange({
      getOptions,
      kind: "autocomplete-text",
      value: value ?? "",
    });
  };

  const handleInputChange = (_: React.ChangeEvent<unknown>, input: string) => {
    if (onlySpaces(input)) {
      return;
    }
    updateState({ debouncedValue: input, inputValue: input });
  };

  const handleBlur = () => {
    cancel();
    handleGetOptions("");
  };

  const [, cancel] = useDebounce(
    () => {
      handleGetOptions(state.debouncedValue);
    },
    500,
    [state.inputValue]
  );

  const classes = useStyles();

  return (
    <AutoComplete
      id={`autocomplete-refinement-${label}`}
      options={state.options}
      onBlur={handleBlur}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" />
      )}
      onChange={handleSelectedValue}
      onInputChange={handleInputChange}
      value={state.current}
      inputValue={state.inputValue}
      classes={{ input: classes.input }}
      noOptionsText={"Aucun résultat"}
    />
  );
};
