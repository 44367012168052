import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";

import { ReferentielProvider } from "../../../services/reporting/Referentiel";
import { PrivateRoute } from "../../../services/routing/PrivateRoute";
import Actes from "./Actes";
import Biens from "./Biens";
import Classements from "./Classements/Classements";
import Mandats from "./Mandats";
import Publication from "./StatsPublication";
import Synthese from "./Synthese";

export const Reporting: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute path={`${path}/synthese`} component={Synthese} />
      <PrivateRoute
        path={`${path}/biens`}
        component={() => (
          <ReferentielProvider>
            <Biens />
          </ReferentielProvider>
        )}
        analytics={() => "Reporting Biens"}
      />
      <PrivateRoute
        path={`${path}/mandats`}
        component={() => (
          <ReferentielProvider>
            <Mandats />
          </ReferentielProvider>
        )}
        analytics={() => "Reporting Mandats"}
      />

      <PrivateRoute
        path={`${path}/actes`}
        component={() => (
          <ReferentielProvider>
            <Actes />
          </ReferentielProvider>
        )}
        analytics={() => "Reporting Actes"}
      />
      <PrivateRoute
        path={`${path}/publications`}
        component={Publication}
        analytics={() => "Reporting Stats de Publication"}
      />
      <PrivateRoute
        path={`${path}/classements`}
        component={Classements}
        analytics={() => "Reporting Classements"}
      />
    </Switch>
  );
};
