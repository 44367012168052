import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { ITheme } from "theme/ts/main";

const useStyles = makeStyles((theme: ITheme) => ({
  avatar: {
    backgroundColor: theme.palette.default,
  },
}));
export const AvatarText: React.FC = ({ children }) => {
  const classes = useStyles();
  return <Avatar className={classes.avatar}>{children}</Avatar>;
};
