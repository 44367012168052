import { IconButton, Tooltip } from "@material-ui/core";
import EditContactIcon from "@material-ui/icons/EditOutlined";
import React from "react";
import { ProfileReadDto } from "routes/app/contacts/contact/components/models/profilesReadDto";
import { ProfileDetailsMenu } from "UI/features/profiles/organisms/ProfileDetailsMenu";

export const getProfileMenuForActiveProfile = (
  contactId: string,
  readProfile: ProfileReadDto,
  reloadProfile: () => void,
  openEditProfileModal: () => void
) => [
  <Tooltip key={0} title={"Modifier"} placement={"bottom"}>
    <IconButton onClick={openEditProfileModal} aria-label="edit">
      <EditContactIcon />
    </IconButton>
  </Tooltip>,
  <ProfileDetailsMenu
    key={1}
    contactId={contactId}
    profile={
      ({
        ...readProfile,
      } as ProfileReadDto) || ({} as ProfileReadDto)
    }
    handleAfterUpdate={reloadProfile}
  />,
];
