import { Campaign, SubscriptionResponse } from "services/campagnes/types";
import { Endpoints } from "services/endpoints/interfaces/Endpoints";
import { IHttpService } from "../http";
import { ITrackingService } from "../tracking/tracking";

export const campaignsService = (
  endpoints: Endpoints,
  sendCustomEventToGoogle: ITrackingService["sendCustomEventToGoogle"],
  httpService: IHttpService,
  version: "V1" | "V2"
): ICampaignsService => {
  const getCurrentCampaignsEndpoint =
    version === "V1"
      ? endpoints.marketingCampaigns.getCurrentCampaigns
      : endpoints.marketingCampaignsV2.getCurrentCampaigns;
  const subscribeOrUnsubscribeEndpoint =
    version === "V1"
      ? endpoints.marketingCampaigns.subscribeOrUnsubscribe
      : endpoints.marketingCampaignsV2.subscribeOrUnsubscribe;

  // ------------------------------------------------------------- récupération des campagnes disponibles
  const getCurrentCampaigns = async () => {
    const campaignsResponse = await httpService.get<Campaign[]>(
      getCurrentCampaignsEndpoint
    );
    return campaignsResponse?.data;
  };

  const subscribe = async (campaignId: string) => {
    const subscriptionResponse = await httpService.post<SubscriptionResponse>(
      subscribeOrUnsubscribeEndpoint(campaignId),
      {
        content: {},
        contentType: "application/json",
      }
    );
    sendCustomEventToGoogle("Abonnement campagne");
    return subscriptionResponse?.data;
  };

  const unsubscribe = async (campaignId: string) => {
    const subscriptionResponse = await httpService.del<SubscriptionResponse>(
      subscribeOrUnsubscribeEndpoint(campaignId)
    );
    sendCustomEventToGoogle("Désabonnement campagne");
    return subscriptionResponse?.data;
  };

  return { getCurrentCampaigns, subscribe, unsubscribe };
};

export interface ICampaignsService {
  getCurrentCampaigns: () => Promise<Campaign[] | undefined>;
  subscribe: (campaignId: string) => Promise<SubscriptionResponse | undefined>;
  unsubscribe: (
    campaignId: string
  ) => Promise<SubscriptionResponse | undefined>;
}
