import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import PhoneIcon from "@material-ui/icons/LocalPhoneOutlined";
import SendIcon from "@material-ui/icons/MailOutlined";
import { makeStyles } from "@material-ui/styles";
import { BU } from "components/BU";
import IconAnchorAction from "components/IconAnchorAction";
import { useAvatarOrQuestionMark } from "hooks/useAvatarOrQuestionMark";
import React from "react";
import getUrlPhoto from "utils/UrlImage";

import { useServices } from "../../services";

const useStyles = makeStyles((theme) => ({
  mail: {
    color: theme.palette.default,
  },
  telephone: {
    color: theme.palette.default,
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
}));

const Conseiller = (props) => {
  const {
    l10n: { t },
    trackingService: { sendCustomEventToGoogle },
  } = useServices();
  const { conseiller, bu, source = "" } = props;
  let urlPhoto = getUrlPhoto(bu, "100x100", conseiller.photo);
  const classes = useStyles();
  const avatarInfo = useAvatarOrQuestionMark(conseiller.prenom, conseiller.nom);

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexWrap: "nowrap",
        overflowX: "hidden",
      }}
    >
      <div>
        <Avatar
          aria-label="photo conseiller"
          style={{ height: 50, marginRight: 12, width: 50 }}
          src={bu && conseiller.photo && urlPhoto}
        >
          {avatarInfo}
        </Avatar>
      </div>
      <div style={{ overflowX: "hidden" }}>
        <BU bu={bu} />
        <Typography variant="h4">
          {`${conseiller.prenom} ${conseiller.nom}`}
        </Typography>

        {conseiller.estActif === true ? (
          <>
            <IconAnchorAction
              link={conseiller.telephone}
              icon={<PhoneIcon style={{ fontSize: 12 }} />}
              action="tel"
              tooltip={t("Appeler")}
              onClick={() =>
                sendCustomEventToGoogle(`téléphone Conseiller de ${source}`)
              }
            >
              <Typography variant="body1" className={classes.telephone}>
                {conseiller.telephone}
              </Typography>
            </IconAnchorAction>

            <IconAnchorAction
              link={conseiller.email}
              icon={<SendIcon style={{ fontSize: 12 }} />}
              action="mailto"
              tooltip={t("Envoyer un mail")}
              onClick={() =>
                sendCustomEventToGoogle(`email Conseiller de ${source}`)
              }
            >
              <Typography variant="body2" noWrap className={classes.mail}>
                {conseiller.email}
              </Typography>
            </IconAnchorAction>
          </>
        ) : (
          <Typography variant="body1">{t("Inactif")}</Typography>
        )}
      </div>
    </div>
  );
};

export default Conseiller;
