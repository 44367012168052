import {
  FavoriteButton,
  FavoriteFab,
} from "UI/shared/molecules/Buttons/FavoriteButton";
import React, { useContext } from "react";
import { PigeFavorisContext } from "../../contexts/PigeFavorisContextProvider";
export interface PigeFavoriteProps {
  id?: string;
  mode: "button" | "fab";
}

export const PigeFavorite: React.FC<PigeFavoriteProps> = ({ id, mode }) => {
  const { isFavorite, toggle } = useContext(PigeFavorisContext);
  const onClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    toggle(id);
  };
  return mode === "button" ? (
    <FavoriteButton isFavorite={isFavorite(id)} onClick={onClick} />
  ) : (
    <FavoriteFab isFavorite={isFavorite(id)} onClick={onClick} />
  );
};
