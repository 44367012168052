import { Grid } from "@material-ui/core";
import { useBuckets } from "hooks/useBuckets";
import React, { useContext } from "react";
import { CheckableRealEstatePropertyCard } from "UI/features/realEstates/organisms/CheckableRealEstatePropertyCard";
import { RealEstatePropertyCardProps } from "UI/features/realEstates/organisms/RealEstatePropertyCard";
import { InfiniteScrollList } from "UI/shared/organisms/InfiniteScrollList/InfiniteScrollList";

import { SharingAgentRealEstatesContext } from "./index";

export const SharingAgentRealEstatesLarge: React.FC = () => {
  const {
    realEstates,
    loadMore,
    isChecked,
    isShared,
    handleCheck,
    modalContainerId,
  } = useContext(SharingAgentRealEstatesContext);

  const realEstatesBuckets = useBuckets<RealEstatePropertyCardProps>({
    data: realEstates,
    size: 3,
  });

  return (
    <InfiniteScrollList
      pageSize={1}
      loadMore={loadMore}
      inTabs={false}
      wrapItems={true}
      containerId={modalContainerId}
    >
      {realEstatesBuckets.map((realEstateBucket, index) => (
        <Grid
          container
          spacing={2}
          key={`sharing-agent-realestates-lg-bucket-${index}`}
        >
          {realEstateBucket.map((realEstate) => (
            <Grid item xs={4} key={`sharing-lg-${realEstate.id}`}>
              <CheckableRealEstatePropertyCard
                id={realEstate.id || ""}
                realEstateProps={realEstate}
                isChecked={isChecked(realEstate.id || "")}
                isShared={isShared(realEstate.page || 0, realEstate.id || "")}
                handleCheck={handleCheck}
              />
            </Grid>
          ))}
        </Grid>
      ))}
    </InfiniteScrollList>
  );
};
