import {
  ContactEventSent,
  EventKind,
} from "services/contactEventsTracking/dto/ContactEvent";

export type ActionType =
  | { type: "setKind"; payload: EventKind }
  | { type: "setComment"; payload: string }
  | { type: "setDate"; payload: Date }
  | { type: "setHaveToBeAddedToCalendar"; payload: boolean }
  | { type: "setEvent"; payload: ContactEventSent };

export const emptyContactEventSent: ContactEventSent = {
  calendarEventId: "",
  comment: "",
  date: new Date(),
  haveToBeAddedToCalendar: false,
  id: "",
  kind: "PhoneCall",
};

export const reducer = (
  state: ContactEventSent,
  action: ActionType
): ContactEventSent => {
  switch (action.type) {
    case "setKind":
      return { ...state, kind: action.payload };
    case "setComment":
      return {
        ...state,
        comment: action.payload,
      };
    case "setDate":
      return {
        ...state,
        date: action.payload,
      };
    case "setHaveToBeAddedToCalendar":
      return { ...state, haveToBeAddedToCalendar: action.payload };
    case "setEvent":
      return { ...action.payload };
    default:
      return state;
  }
};
