import { Locations } from "components/RefinementField/types";
import { RealEstatesResponse } from "./types";

export const initParams = {
  page: 1,
  size: 20,
  withElevator: false,
  withNotes: false,
  withParking: false,
  withPool: false,
  withSingleStorey: false,
};

export const initLocations: Locations = {
  kind: "locations",
  value: {
    departements: [],
    quartiers: [],
    regions: [],
    villes: [],
  },
};
export const initResponse: RealEstatesResponse = {
  aggregation: [],
  count: 0,
  currentPageNumber: 0,
  data: [],
  elapsedMilliseconds: 0,
  hasMore: false,
  pageSize: 20,
  total: 0,
  totalPage: 0,
};
