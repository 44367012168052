import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { emphasize, makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import Select, { components } from "react-select";
import { useServices } from "../../services";
import { useTxHttp } from "../../services/http";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  divider: {
    height: theme.spacing(2),
  },
  input: {
    display: "flex",
    height: "auto",
    padding: 4,
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  paper: {
    left: 0,
    marginTop: theme.spacing(1),
    position: "absolute",
    right: 0,
    zIndex: 2,
  },
  placeholder: {
    bottom: 22,
    left: 17,
    position: "absolute",
  },
  required: {
    borderColor: "red !important",
    borderStyle: "solid",
    borderWidth: 2,
  },
  root: {
    flexGrow: 1,
    marginBottom: 8,
    marginTop: 6,
    padding: 1,
  },
  valueContainer: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
  },
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      style={{ paddingLeft: 2, width: "100%" }}
      InputProps={{
        inputComponent,
        inputProps: {
          children,
          className: classes.input,
          ref: innerRef,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {
  const item = props.data;
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      {...props.innerProps}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
    >
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" noWrap>
          {item.label}
        </Typography>
        <Typography variant="body2" style={{ color: "#8e8e8e" }} noWrap>
          {item.parent}
        </Typography>
      </div>
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      variant="body1"
      style={{ paddingLeft: 10 }}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
  return (
    <Typography
      variant="subtitle1"
      {...props.innerProps}
      style={{ paddingLeft: 10 }}
    >
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
  return (
    <div
      className={props.selectProps.classes.valueContainer}
      style={{ alignItems: "center", display: "flex" }}
    >
      {props.children}
    </div>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
  return (
    <Typography variant={"body1"} key={props.data.id} tabIndex={-1} noWrap>
      {props.children}&nbsp;&nbsp;
    </Typography>
  );
}

const MultiValueChoicesGathered = (props) => {
  const {
    getValue,
    selectProps: { menuIsOpen },
    index,
  } = props;
  const values = getValue() || [{}];
  const label = `${values.length} choix`;

  return index === 0 ? (
    <components.SingleValue {...props}>
      {!menuIsOpen ? label : ""}
    </components.SingleValue>
  ) : null;
};

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
      style={{ minWidth: "fit-content" }}
    >
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

const Input = (props) => {
  return (
    <div>
      <components.Input {...props} />
    </div>
  );
};

const ClearIndicator = (props) => {
  const {
    children,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      {/* same clear icon as selectX here ? */}
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

const formatOptionsListe = (options) => {
  const _options = options.map((zone) => ({
    ...zone,
    label: zone.nom,
    value: zone.id,
  }));

  const regions = _options.filter((zone) => zone.typeGeoEnum === "Region");
  const departements = _options.filter(
    (zone) => zone.typeGeoEnum === "Departement"
  );
  const villes = _options.filter(
    (zone) => zone.typeGeoEnum === "Ville" && !zone.raw
  );

  const quartiers = _options.filter((zone) => zone.typeGeoEnum === "Quartier");
  const arrondissements = _options.filter((zone) => zone.raw);

  let villeCourante = "";
  let nouveauxArrondissements = [];

  for (let i = 0; i < arrondissements.length; i++) {
    let nomVille = arrondissements[i].nom.split(" ")[0];
    if (villeCourante !== nomVille) {
      nouveauxArrondissements.push({
        id: nomVille,
        label: `${nomVille} (${"Tous arrondissements"}) `,
        nom: `${nomVille} (${"Tous arrondissements"})`,
        parent: arrondissements[i].parent,
        raw: arrondissements[i].raw,
        typeGeoEnum: "VilleAvecArrondissements",
        value: arrondissements[i].raw,
      });
      villeCourante = nomVille;
    }
    nouveauxArrondissements.push({
      ...arrondissements[i],
      villeId: villeCourante,
    });
  }

  return [
    {
      label: "Régions",
      options: regions,
    },
    {
      label: "Départements",
      options: departements,
    },
    {
      label: "Villes",
      options: [...villes, ...arrondissements],
    },
    {
      label: "Quartiers",
      options: quartiers,
    },
  ];
};

export const ReactSelect = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { endpoints } = useServices();
  const { get } = useTxHttp();
  const {
    onChange,
    styles,
    inputId,
    messageNoOptions,
    selection,
    disabled = false,
    showSeparator = true,
    shouldGatherSelection = false,
    rootClassName: rootClassNameProp,
    error = false,
    helperText,
  } = props;

  const [locations, setLocations] = React.useState([]);
  const [searchText, setSearchText] = React.useState(null);

  const debounce = useRef();

  React.useEffect(() => {
    if (searchText && searchText.length > 0) {
      setLocations([]);
      get(endpoints.geoloc.AutoComplete, { autocomplete: searchText, size: 10 })
        .then((response) => {
          const formattedOptions = formatOptionsListe(response.data);
          if (response.data) setLocations(formattedOptions);
        })
        .catch((e) => console.log(e));
    }
    if ((searchText && searchText.length < 1) || searchText === null) {
      setLocations([]);
    }
  }, [searchText, get, endpoints.geoloc.AutoComplete]);

  function customNoOptionsMessage(props) {
    return (
      <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
      >
        {messageNoOptions}
      </Typography>
    );
  }

  const customComponents = {
    Control,
    Input,
    Menu,
    MultiValue: shouldGatherSelection ? MultiValueChoicesGathered : MultiValue,
    NoOptionsMessage: customNoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    ...(shouldGatherSelection ? ClearIndicator : {}),
  };

  function handleChange(_values) {
    onChange(_values);
  }

  function handleInputChange(_value) {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setSearchText(_value);
    }, 300);
  }

  const selectStyles = {
    ...styles,
    indicatorSeparator: () => ({
      display: showSeparator ? "inherit" : "none",
    }),
    input: (base) => ({
      ...base,
      "& input": {
        font: "inherit",
      },
      color: theme.palette.text.primary,
      height: "20px",
      paddingLeft: "10px",
    }),
  };

  return (
    <div className={classnames(classes.root, rootClassNameProp)}>
      <Select
        isDisabled={disabled}
        variant="outlined"
        classes={classes}
        styles={selectStyles}
        inputId={inputId}
        TextFieldProps={{
          InputLabelProps: {
            htmlFor: inputId,
            shrink: true,
          },
          label: "Localisation",
          variant: "outlined",
          helperText,
          error,
        }}
        filterOption={false}
        placeholder={""}
        options={locations}
        components={customComponents}
        value={selection}
        onInputChange={handleInputChange}
        onChange={handleChange}
        closeMenuOnSelect={false}
        defaultOptions={locations}
        isMulti={true}
      />
    </div>
  );
};

ReactSelect.propTypes = {
  data: PropTypes.array,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  selection: PropTypes.array,
};

export default ReactSelect;
