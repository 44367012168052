import { CircularProgress } from "@material-ui/core";
import React from "react";
export const LoaderContainer: React.FC<{ loading: boolean }> = ({
  children,
  loading,
}) => {
  if (loading) {
    return (
      <CircularProgress
        size={12}
        style={{
          margin: "auto",
        }}
      />
    );
  }

  return <>{children}</>;
};
