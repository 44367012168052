import { Sort } from "components/RefinementField/types";
import { useCallback, useState } from "react";
import { SortOption } from "UI/shared/molecules/SortInput";

export const useSortOptions = (
  sortOptions: SortOption[],
  defaultOption: SortOption
) => {
  const [activeSortOption, setActiveSortOption] = useState<SortOption>(
    defaultOption
  );

  const onChange = useCallback(
    ({ value }: { key: "sort"; value: Sort }) => {
      const sortOption = sortOptions.find(
        (so) =>
          so.query === value.value.column &&
          so.isAscendingOrder === value.value.ascending
      );

      if (!sortOption) return;

      setActiveSortOption(sortOption);
    },
    [sortOptions]
  );

  return {
    activeSortOption,
    onChange,
    sortOptions,
  };
};
