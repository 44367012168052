import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { PigeItemsList } from "routes/app/biens/pige/Pige/PigeItemsList";
import { useServices } from "services";
import { PigeQueryParams, useQuery } from "services/useQuery";
import { IPigeState, PigeGlobalAction, PigeState } from "./states/global";
import { multipleUpdates } from "./states/global/actions";
import { reducer } from "./states/global/reducer";

const PIGE_ADS_PAGE = "Pige Annonces";
const PIGE_DETAIL_PAGE = "Fiche Annonce Pige";

export const PigeGlobalContext = React.createContext<{
  state: IPigeState;
  dispatch: React.Dispatch<PigeGlobalAction>;
}>({
  dispatch: (action: PigeGlobalAction) => {
    return;
  },
  state: { display: "view", tab: "details" },
});

export const Pige: React.FC = () => {
  const {
    trackingService: { sendPageViewEventToGoogle },
  } = useServices();

  const sendAnalytics = (currentId?: string) => {
    const title = currentId ? PIGE_DETAIL_PAGE : PIGE_ADS_PAGE;
    sendPageViewEventToGoogle(title);
  };

  const { slug = "full-list", redirectUrl, id } = useQuery<PigeQueryParams>([
    "redirectUrl",
    "slug",
    "id",
  ]);

  const { id: urlId } = useParams<{ id: string }>();

  const [state, dispatch] = React.useReducer(
    reducer,
    new PigeState(slug, redirectUrl, id || urlId)
  );

  useEffect(() => {
    sendAnalytics(urlId || id);
  }, [id, urlId]);

  return (
    <PigeGlobalContext.Provider value={{ dispatch, state }}>
      <PigeItemsList />
    </PigeGlobalContext.Provider>
  );
};
