import { makeStyles } from "@material-ui/core/styles";

import { ITheme } from "../../../../../theme/ts/main";

export const useStyles = makeStyles((theme: ITheme) => ({
  actions: {
    borderTop: "1px solid",
    borderTopColor: theme.palette.greyLight,
    display: "flex",
    justifyContent: "flex-end",
  },
  card: {
    "&:hover": {
      boxShadow:
        "0px 3px 3px 0px rgba(0,0,0,0.34), 0px 1px 1px -1px rgba(0,0,0,0.1)",
    },
    "&:hover $imageInformation": {
      alignItems: "center",
      background: "#00000053",
      color: "white",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      visibility: "inherit",
    },
    display: "flex",
    width: "100%",
  },
  cardMobile: {
    "&:hover": {
      boxShadow:
        "0px 3px 3px 0px rgba(0,0,0,0.34), 0px 1px 1px -1px rgba(0,0,0,0.1)",
    },
    "&:hover $imageInformation": {
      alignItems: "center",
      background: "#00000053",
      color: "white",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      visibility: "inherit",
    },
    width: "100%",
  },
  imageInformation: {
    left: 0,
    position: "absolute",
    top: 0,
    visibility: "hidden",
    width: "100%",
  },
  media: {
    cursor: "pointer",
    position: "relative",
    width: 200,
  },
  mediaMobile: {
    backgroundSize: "cover",
    cursor: "pointer",
    height: 100,
    position: "relative",
  },
  switchContainer: {
    alignItems: "center",
    display: "flex",
    height: 38,
    justifyContent: "flex-end",
  },
}));
