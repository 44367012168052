import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import { useServices } from "../../../../../services";
import { ITheme } from "../../../../../theme/ts/main";

const useStyles = makeStyles((theme: ITheme) => ({
  background: {
    backgroundColor: "#FDEDAF",
    border: "solid 1px #FDEDAF",
    marginTop: 2,
  },
  chipLabel: {
    color: theme.palette.default,
    fontSize: "12px",
    fontWeight: "normal",
  },
}));

export const SubscribedByDefault: React.FC = () => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();
  return (
    <Chip
      label={t("Activée par défaut")}
      size="small"
      className={classes.background}
      classes={{
        label: classes.chipLabel,
      }}
    />
  );
};
