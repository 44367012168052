import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useServices } from "services";

export interface InfoChipProps {
  color: string;
  label: string;
}

const useStyles = ({ color }: { color: string }) =>
  makeStyles(() => ({
    chip: {
      borderColor: color,
    },
    chipLabel: {
      color: color,
      fontSize: "0.7rem",
      fontWeight: "bolder",
      marginLeft: 7,
      marginRight: 7,
    },
  }));

export const InfoChip: React.FC<InfoChipProps> = ({ label, color }) => {
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles({ color })();
  return (
    <Chip
      label={t(label)}
      size="small"
      classes={{
        label: classes.chipLabel,
        root: classes.chip,
      }}
      variant="outlined"
    />
  );
};
