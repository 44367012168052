import { Fab, makeStyles } from "@material-ui/core";
import EditContactIcon from "@material-ui/icons/EditOutlined";
import React from "react";
import { ITheme } from "theme/ts/main";

const useStyles = makeStyles((theme: ITheme) => {
  const baseFab = {
    "&:hover": {
      backgroundColor: theme.palette.tonique,
    },
    backgroundColor: theme.palette.tonique,
    color: "white",
  };
  return {
    fab: baseFab,
    fabTab: {
      ...baseFab,
      bottom: 30,
      left: "inherit",
      position: "fixed",
      right: 30,
      zIndex: 1000,
    },
    root: {
      flexGrow: 1,
      height: "100%",
      transform: "translate(0%, -100%, 0px)",
    },
    speedDial: {
      bottom: 30,
      position: "fixed",
      right: 30,
    },
  };
});

export interface EditProfileButtonProps {
  onClick: () => void;
}
export const EditProfileButton: React.FC<EditProfileButtonProps> = ({
  onClick,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Fab
        size="large"
        aria-label="edit profile"
        className={classes.fabTab}
        onClick={onClick}
      >
        <EditContactIcon />
      </Fab>
    </div>
  );
};
