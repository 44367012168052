import { RadioGroup } from "@material-ui/core";
import React from "react";
import { useServices } from "services";

import { PropertyAge } from "../../../models/propertyAge";
import { ComplementIcon } from "../../components/RadioChip";
import { SubSectionTitle } from "../../components/SectionTitle";
import { SelectChip } from "../../SelectChip";

export const SelectPropertyAge: React.FC<{
  value: PropertyAge;
  onChange: (value: PropertyAge) => void;
  disabled: boolean;
}> = ({ disabled, value, onChange }) => {
  const {
    l10n: { t },
  } = useServices();

  const options: PropertyAge[] = ["All", "Old", "New"];

  return (
    <>
      <SubSectionTitle title="Complément :" />
      <RadioGroup
        row
        value={value}
        onChange={(_) => onChange(_.target.value as PropertyAge)}
      >
        {options.map((option) => (
          <SelectChip
            key={option}
            icon={
              <ComplementIcon label={t(`buildAge.${option}`)} checked={false} />
            }
            checkedIcon={
              <ComplementIcon label={t(`buildAge.${option}`)} checked={true} />
            }
            value={option}
          />
        ))}
      </RadioGroup>
    </>
  );
};
