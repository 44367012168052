import { ReadModelProfileDto } from "./../../../../services/profiles/dto/ReadModelProfileDto";
import {
  ProfileItemDto,
  ReadModelContactDto,
} from "../../../../services/profiles/dto/ReadModelProfileDto";

export interface DisplayedContactForList {
  id: string;
  gender: "Male" | "Female" | "None";
  firstName: string;
  lastName: string;
  mainEmailAddress: string;
  mainPhoneNumber: string;
}

export const contactAdapter = (
  profile: ProfileItemDto
): DisplayedContactForList => {
  return {
    id: profile.contactId,
    gender: profile.contactGender,
    firstName: profile.contactFirstName || "",
    lastName: profile.contactLastName || "",
    mainEmailAddress: profile.contactMainEmailAddress || "",
    mainPhoneNumber: profile.contactMainPhoneNumber || "",
  };
};

export const fromReadModelContact: (
  contact: ReadModelContactDto
) => DisplayedContactForList = (contact) => ({
  id: contact.id,
  gender: contact.gender,
  firstName: contact.firstName || "",
  lastName: contact.lastName || "",
  mainEmailAddress: contact.mainEmailAddress || "",
  mainPhoneNumber: contact.mainPhoneNumber || "",
});
