import { makeStyles } from "@material-ui/core";
import { Text16pxBoldEllipsis } from "UI/shared/atoms/typographies/Text16pxBoldEllipsis";
import React from "react";

import { useServices } from "../../../../../services";
import { genderAdapter } from "../../../../../services/portfolio/helpers";
import { Gender } from "../../../../../services/portfolio/types";
import { ITheme } from "../../../../../theme/ts/main";

export interface ContactFullNameProps {
  gender?: Gender;
  firstName?: string;
  lastName?: string;
}

const useStyles = makeStyles((theme: ITheme) => ({
  unknown: {
    color: theme.palette.common.dark,
  },
}));

export const ContactFullName: React.FC<ContactFullNameProps> = ({
  gender,
  firstName,
  lastName,
}) => {
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles();
  return (
    <Text16pxBoldEllipsis>
      {`${genderAdapter(gender)} ${firstName || ""} ${(
        lastName || ""
      ).toUpperCase()}`}
      {!firstName && !lastName && (
        <span className={classes.unknown}>{t("Non renseigné")}</span>
      )}
    </Text16pxBoldEllipsis>
  );
};
