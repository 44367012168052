import { Chip } from "@material-ui/core";
import React from "react";

import { Locations } from "../../../../../components/RefinementField/types";
import { RefinementChipProps } from "../RefinementChip";

export const LocationsChip = ({
  value: { value },
  format,
  onChange,
}: RefinementChipProps<Locations>) => {
  return (
    <>
      {Object.keys(value).map((key) => {
        const currentKey = key as keyof Locations["value"];
        return (
          <div key={key} style={{ display: "inline" }}>
            {value[currentKey].length > 0 &&
              value[currentKey]
                .filter((item) => item.isRefined)
                .map((item) => {
                  return (
                    <Chip
                      key={`selected-${currentKey}-${item.value}`}
                      style={{ marginBottom: 6, marginRight: 6 }}
                      label={format(item.label)}
                      size="small"
                      onDelete={() => {
                        const nextState = [...value[currentKey]].map((v) => {
                          if (v.value === item.value) v.isRefined = false;
                          return v;
                        });
                        onChange({
                          kind: "locations",
                          value: {
                            ...value,
                            [currentKey]: nextState,
                          },
                        } as Locations);
                      }}
                    />
                  );
                })}
          </div>
        );
      })}
    </>
  );
};
