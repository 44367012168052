import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { emphasize, makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import clsx from "clsx";
import React from "react";
import Select, { components } from "react-select";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  divider: {
    height: theme.spacing(2),
  },
  input: {
    display: "flex",
    height: "auto",
    padding: 4,
  },
  inputAlt: {
    display: "flex",
    height: "auto",
    padding: 3,
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  paper: {
    left: 0,
    marginTop: theme.spacing(1),
    position: "absolute",
    right: 0,
    zIndex: 2,
  },
  placeholder: {
    bottom: 22,
    left: 17,
    position: "absolute",
  },
  root: {
    flexGrow: 1,
    marginBottom: 8,
    marginTop: 6,
  },
  rootAlt: {
    flexGrow: 1,
    marginTop: 8,
  },
  valueContainer: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    overflow: "hidden",
  },
}));

const InputComponent: React.FC<{
  inputRef: any;
}> = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
};

const Control: React.FC<{
  children: any;
  innerProps: any;
  innerRef: any;
  selectProps: any;
}> = (props) => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps, required },
  } = props;

  const classesinput = makeStyles({
    notchedOutlineNotRequired: {
      borderColor: "#c4c4c4",
      borderWidth: 1,
    },
    notchedOutlineRequired: {
      borderColor: "#303d4a",
      borderWidth: 2,
    },
  })();

  return (
    <TextField
      style={{
        width: "100%",
      }}
      InputProps={{
        classes: {
          notchedOutline: required
            ? classesinput.notchedOutlineRequired
            : classesinput.notchedOutlineNotRequired,
        },
        inputComponent: InputComponent,
        inputProps: {
          children,
          className: TextFieldProps.alt ? classes.inputAlt : classes.input,
          ref: innerRef,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
};

const Option: React.FC<{
  children: any;
  innerProps: any;
  innerRef: any;
  isFocused: boolean;
  isSelected: boolean;
}> = (props) => (
  <MenuItem
    ref={props.innerRef}
    selected={props.isFocused}
    component="div"
    style={{
      fontWeight: props.isSelected ? 500 : 400,
    }}
    {...props.innerProps}
  >
    {props.children}
  </MenuItem>
);

const Placeholder: React.FC<{
  children: any;
  innerProps: any;
  selectProps: any;
}> = (props) => {
  return (
    <Typography
      color="textSecondary"
      variant="body1"
      style={{ paddingLeft: 10 }}
    >
      {props.children}
    </Typography>
  );
};

const SingleValue: React.FC<{
  children: any;
  innerProps: any;
  selectProps: any;
}> = (props) => {
  return (
    <Typography
      variant="body1"
      {...props.innerProps}
      style={{ paddingLeft: 10 }}
    >
      {props.children}
    </Typography>
  );
};

const ValueContainer: React.FC<{
  children: any;
  selectProps: any;
}> = (props) => {
  return (
    <div
      className={props.selectProps.classes.valueContainer}
      style={{ alignItems: "center", display: "flex" }}
    >
      {props.children}
    </div>
  );
};

const MultiValue: React.FC<{
  children: any;
  isFocused: boolean;
  removeProps: any;
  selectProps: any;
}> = (props) => {
  return (
    <Chip
      key={props.children.replace(
        /([A-Za-z -']+) (\(\d+\)) (\(\d+\))/g,
        "$1 $2"
      )}
      tabIndex={-1}
      label={props.children.replace(
        /([A-Za-z -']+) (\(\d+\)) (\(\d+\))/g,
        "$1 $2"
      )} // suppression du nombre de résultat pour affichage chip
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
};

const Menu: React.FC<{
  children: any;
  innerProps: any;
  selectProps: any;
}> = (props) => {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
};

const Input: React.FC<any> = (props) => {
  return (
    <div>
      <components.Input {...props} />
    </div>
  );
};

const ReactSelect: React.FC<{
  data?: any[];
  label?: string;
  multiple?: boolean;
  onChange: (_: any) => void;
  placeholder?: string;
  styles?: any;
  inputId?: string;
  alt?: string;
  messageNoOptions?: string;
  values: any;
  onChangeInput?: any;
  valuesDejaChoisies?: any;
  disabled?: boolean;
  isMulti: boolean;
  required?: boolean;
  isClearable?: boolean;
}> = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    placeholder,
    label,
    onChange,
    styles,
    inputId,
    messageNoOptions,
    values,
    onChangeInput,
    valuesDejaChoisies,
    disabled,
    isMulti,
    required,
    isClearable,
  } = props;
  const [multi, setMulti] = React.useState(valuesDejaChoisies || null);

  function customNoOptionsMessage(props: any) {
    return (
      <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
      >
        {messageNoOptions}
      </Typography>
    );
  }

  const customComponents = {
    Control,
    Input,
    Menu,
    MultiValue,
    NoOptionsMessage: customNoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
  };

  function handleChange(_values: any) {
    setMulti(_values);
    onChange(_values);
  }

  function handleInputChange(_value: any) {
    onChangeInput(_value);
  }

  const selectStyles = {
    ...styles,
    input: (base: any) => ({
      ...base,
      "& input": {
        font: "inherit",
      },
      color: theme.palette.text.primary,
      height: "20px",
      paddingLeft: "10px",
    }),
  };

  return (
    <div className={props.alt ? classes.rootAlt : classes.root}>
      <Select
        isDisabled={disabled}
        isClearable={isClearable}
        variant="outlined"
        classes={classes}
        styles={selectStyles}
        inputId={inputId}
        TextFieldProps={{
          InputLabelProps: {
            htmlFor: inputId,
            shrink: true,
          },
          alt: props.alt,
          label: label,
          variant: "outlined",
        }}
        options={values}
        placeholder={placeholder}
        components={customComponents}
        value={multi}
        onInputChange={handleInputChange}
        onChange={handleChange}
        closeMenuOnSelect={!isMulti}
        autoBlur={!isMulti}
        defaultOptions={true}
        cacheOptions={true}
        isMulti={isMulti}
        required={required}
      />
    </div>
  );
};

export default ReactSelect;
