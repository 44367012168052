import { Paper, Grid } from "@material-ui/core";
import React from "react";
import { CloseButton } from "UI/shared/atoms/buttons/CloseButton";
import { Row } from "UI/shared/atoms/containers/Row";
import { HorizontalSpace } from "UI/shared/atoms/spacing/HorizontalSpace";
import { Text14pxBold } from "UI/shared/atoms/typographies/Text14pxBold";

export const RealEstatesSharingModalHeader: React.FC<{ close: () => void }> = ({
  close,
}) => (
  <Paper elevation={2} style={{ borderRadius: 0 }}>
    <Grid container alignItems="center">
      <Grid item xs={6}>
        <Row rowJustify="flex-start">
          <HorizontalSpace size={16} />
          <Text14pxBold>Fiches commerciales</Text14pxBold>
        </Row>
      </Grid>
      <Grid item xs={6}>
        <Row rowJustify="flex-end">
          <CloseButton clickHandler={close} />
          <HorizontalSpace size={8} />
        </Row>
      </Grid>
    </Grid>
  </Paper>
);
