import { EndpointsProvider } from "./interfaces/EndpointsProvider";

export interface Office365Endpoints {
  obtenirLesEvenements: string;
  sendEmailToSupport: string;
}

const endpoints: EndpointsProvider<Office365Endpoints> = (microServices) => ({
  obtenirLesEvenements: `${microServices.tx(
    "office365"
  )}/v1/Calendrier/Evenements`,
  sendEmailToSupport: `${microServices.tx("office365")}/v1/Email/AuSupport`,
});

export default endpoints;
