import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { includes } from "lodash";
import React from "react";
import { useServices } from "services";
import { ITheme } from "theme/ts/main";

import { LotType } from "../../../../models/Proposal/Features/Building/lotType";
import { SubSectionTitle } from "../../../components/SectionTitle";
import { ProfileInputProps } from "../../../InputProps";

const toggleAmenity = (array: LotType[], value: LotType) => {
  const index = array.indexOf(value);
  if (index === -1) array.push(value);
  else array.splice(index, 1);
  return array;
};

const useStyles = makeStyles((theme: ITheme) => {
  return {
    checkbox: {
      color: theme.palette.primary.main,
    },
  };
});

export const LotsType: React.FC<ProfileInputProps<LotType[]>> = ({
  onChange,
  value,
}) => {
  const {
    l10n: { t },
  } = useServices();

  const classes = useStyles();
  return (
    <>
      <SubSectionTitle title="Types de lot" mode={"non obligatoire"} />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <FormControlLabel
          control={
            <Checkbox
              color={"default"}
              classes={{ root: classes.checkbox }}
              checked={includes(value, "Apartment")}
              onChange={() => {
                onChange(toggleAmenity(value, "Apartment"), true);
              }}
              inputProps={{ "aria-label": "checkbox" }}
            />
          }
          label={
            <Typography variant={"body2"}>
              {t("forSaleBuilding.lotsType.Apartment")}
            </Typography>
          }
        />

        <FormControlLabel
          control={
            <Checkbox
              color={"default"}
              classes={{ root: classes.checkbox }}
              checked={includes(value, "Shop")}
              onChange={() => {
                onChange(toggleAmenity(value, "Shop"), true);
              }}
              inputProps={{ "aria-label": "checkbox" }}
            />
          }
          label={
            <Typography variant={"body2"}>
              {t("forSaleBuilding.lotsType.Shop")}
            </Typography>
          }
        />

        <FormControlLabel
          control={
            <Checkbox
              color={"default"}
              classes={{ root: classes.checkbox }}
              checked={includes(value, "CommercialPremises")}
              onChange={() => {
                onChange(toggleAmenity(value, "CommercialPremises"), true);
              }}
              inputProps={{ "aria-label": "checkbox" }}
            />
          }
          label={
            <Typography variant={"body2"}>
              {t("forSaleBuilding.lotsType.CommercialPremises")}
            </Typography>
          }
        />

        <FormControlLabel
          control={
            <Checkbox
              color={"default"}
              classes={{ root: classes.checkbox }}
              checked={includes(value, "IndustrialPremises")}
              onChange={() => {
                onChange(toggleAmenity(value, "IndustrialPremises"), true);
              }}
              inputProps={{ "aria-label": "checkbox" }}
            />
          }
          label={
            <Typography variant={"body2"}>
              {t("forSaleBuilding.lotsType.IndustrialPremises")}
            </Typography>
          }
        />

        <FormControlLabel
          control={
            <Checkbox
              color={"default"}
              classes={{ root: classes.checkbox }}
              checked={includes(value, "ProfessionalPremises")}
              onChange={() => {
                onChange(toggleAmenity(value, "ProfessionalPremises"), true);
              }}
              inputProps={{ "aria-label": "checkbox" }}
            />
          }
          label={
            <Typography variant={"body2"}>
              {t("forSaleBuilding.lotsType.ProfessionalPremises")}
            </Typography>
          }
        />
      </div>
    </>
  );
};
