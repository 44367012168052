import { makeStyles } from "@material-ui/core";
import React from "react";

interface PaddingContainerProps {
  width?: string;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

const useStyles = makeStyles(() => ({
  paddingContainer: {
    paddingBottom: ({ bottom }: PaddingContainerProps) => bottom,
    paddingLeft: ({ left }: PaddingContainerProps) => left,
    paddingRight: ({ right }: PaddingContainerProps) => right,
    paddingTop: ({ top }: PaddingContainerProps) => top,
    width: ({ width }: PaddingContainerProps) => width,
  },
}));

export const PaddingContainer: React.FC<PaddingContainerProps> = ({
  top = 0,
  bottom = 0,
  left = 0,
  right = 0,
  width = "100%",
  children,
}) => {
  const classes = useStyles({ bottom, left, right, top, width });
  return <div className={classes.paddingContainer}>{children}</div>;
};
