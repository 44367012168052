import { useMediaQuery } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import SideBar from "components/SideBar";
import React, { createContext, useState } from "react";

import AppBarX from "../../components/AppBarX";
import AppLocking from "../../components/AppLocking";
import { SimpleSnackbarContent } from "../../components/Notifier/components/NotifierSnackbarContent";
import { useFeatureFlags } from "../../services/featureFlags";
import { AppRouter } from "./AppRouter";

const useStyles = makeStyles((theme) => ({
  content: {
    height: "100%",
    paddingBottom: "35px",
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 66,
    position: "relative",
    width: ({ drawerOpen, drawerWidth }) =>
      drawerOpen
        ? `calc(100% - ${drawerWidth[0]}px)`
        : `calc(100% - ${drawerWidth[1]}px)`,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: 56,
    },
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 36,
  },
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
  },
  toolbar: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 8px",
    position: "relative",
    ...theme.mixins.toolbar,
  },
}));

const sidebarInitialState = {
  currentSubmenu: null,
  drawerOpen: false,
  fullText: "",
};

const sidebarReducer = (state, action) => {
  switch (action.type) {
    case "clic submenu":
      if (action.load === state.currentSubmenu) action.load = null;
      return { ...state, currentSubmenu: action.load, drawerOpen: true };
    case "clic pathlink":
      return {
        ...state,
        currentSubmenu: null,
        drawerOpen: false,
        fullText: "",
      };
    case "clic carret":
      return {
        ...state,
        currentSubmenu: null,
        drawerOpen: !state.drawerOpen,
      };
    case "setFullText":
      return {
        ...state,
        fullText: action.load,
      };
    default:
      return { ...state };
  }
};

// eslint-disable-next-line react/prop-types
const Toast = ({ autoHideDuration, variant, message, anchorOrigin }) => {
  const [open, setOpen] = useState(true);
  const onClose = () => setOpen(false);
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      open={open}
    >
      <SimpleSnackbarContent
        onClose={onClose}
        message={message}
        variant={variant}
      />
    </Snackbar>
  );
};

export const AppPage = (props) => {
  const { Maintenance, Fusion_inProgress } = useFeatureFlags();

  return (
    <>
      {Fusion_inProgress && (
        <Toast
          autoHideDuration={null}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          variant="warning"
          message="Maintenance en cours, vous pouvez rencontrer des difficultés pour effectuer des rapprochements"
        />
      )}

      <AppLocking
        label={
          "Mise à jour en cours, veuillez vous reconnecter plus tard, merci."
        }
        locked={Maintenance}
      >
        <Content location={props.location} history={props.history} />
      </AppLocking>
    </>
  );
};

export const SideBarContext = createContext();

const Content = (props) => {
  const [sidebarState, dispatchSidebarState] = React.useReducer(
    sidebarReducer,
    sidebarInitialState
  );
  const grandEcran = useMediaQuery("(min-width:961px)");
  const drawerWidth = grandEcran ? [240, 55] : [240, 0];
  const classes = useStyles({
    drawerOpen: sidebarState.drawerOpen,
    drawerWidth: grandEcran ? drawerWidth : [0, 0],
  });

  React.useEffect(() => {
    if (sidebarState.fullText.length > 2) {
      sessionStorage.setItem("fulltext", sidebarState.fullText);
      props.history.push(`/app/recherche-globale/${sidebarState.fullText}`);
    }
  }, [props.history, sidebarState.fullText]);

  const handleFullTextChange = (value) => {
    dispatchSidebarState({ load: value, type: "setFullText" });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBarX
        drawerOpen={sidebarState.drawerOpen}
        drawerWidth={drawerWidth}
        grandEcran={grandEcran}
        openMenu={() =>
          dispatchSidebarState({ load: null, type: "clic carret" })
        }
        setFullText={handleFullTextChange}
        fullText={sidebarState.fullText}
      />
      <SideBar
        brand="TREMPLINX"
        sidebarState={sidebarState}
        dispatch={dispatchSidebarState}
        drawerWidth={drawerWidth}
        grandEcran={grandEcran}
      />
      <main className={classes.content} id="capture">
        <SideBarContext.Provider
          value={{
            currentSubmenu: sidebarState.currentSubmenu,
            drawerOpen: sidebarState.drawerOpen,
            drawerWidth: drawerWidth,
          }}
        >
          <AppRouter />
        </SideBarContext.Provider>
      </main>
    </div>
  );
};
