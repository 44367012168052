import { useLocation } from "react-router-dom";

export type RequestForInformationQueryParams = "id" | "slug";
export type PigeQueryParams = "redirectUrl" | "slug" | "id";
export type ProfilesQueryParams = "slug";
export type PortfolioQueryParams = "slug";
export type RealEstatesQueryParams = "slug";

/***
 * Union of all params type, according to services using this hook.
 * TxQueryParams is the type in which we can add all query params types.
 */
export type TxQueryParams = RequestForInformationQueryParams | PigeQueryParams;

/**
 * This hook is useful to parse query string parameters on url.
 */
export const useQuery = <T extends TxQueryParams>(
  params: T[]
): Record<T, string> => {
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const query = params.reduce((acc, p) => {
    return { ...acc, [p]: urlSearchParams.get(p) };
  }, {});

  return query as Record<T, string>;
};
