import Grid from "@material-ui/core/Grid";
import PropType from "prop-types";
import React from "react";

import PaperKpi from "../../components/PaperKpi";

export default function Kpi({ kpi, t }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <PaperKpi
          title={t("MandatsPris")}
          n={kpi?.nombreMandatPris || 0}
          nMoins1={kpi?.nombreMandatPrisN1 || 0}
          t={t}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PaperKpi
          title={t("MandatExclusifs")}
          n={kpi?.pourcentageExclu || 0}
          nMoins1={kpi?.pourcentageExcluN1 || 0}
          titleType={"percent"}
          t={t}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PaperKpi
          title={t("TauxDeTransformation")}
          n={kpi?.tauxDeTransfo || 0}
          nMoins1={kpi?.tauxDeTransfoN1 || 0}
          titleType={"percent"}
          t={t}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PaperKpi
          title={t("PourcentageMandatsElectroniques")}
          n={kpi?.pourcentageElectronique || 0}
          nMoins1={kpi?.pourcentageElectroniqueN1 || 0}
          titleType={"percent"}
          t={t}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PaperKpi
          title={t("HonoraireMoyen")}
          n={kpi?.honorairesMoyens || 0}
          nMoins1={kpi?.honorairesMoyensN1 || 0}
          titleType={"percent"}
          t={t}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PaperKpi
          title={t("MandatsActifs")}
          n={kpi?.nombreMandatsActifs || 0}
          t={t}
        />
      </Grid>
    </Grid>
  );
}

Kpi.propTypes = {
  kpi: PropType.object,
};
