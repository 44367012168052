import { makeStyles } from "@material-ui/core";
import { ITheme } from "theme/ts/main";

export const useStyles = makeStyles((theme: ITheme) => {
  return {
    container: {
      backgroundColor: theme.palette.divider,
      color: theme.palette.common.black,
      display: "flex",
    },
    item: { borderBottom: `1px solid ${theme.palette.divider}` },
    items: { display: "flex", flexDirection: "column" },
    selectAll: { paddingLeft: "35%" },
    selectedItemsCount: { fontWeight: "bold" },
    selectedItemsCountContainer: {
      alignContent: "center",
      paddingLeft: "3%",
      paddingTop: "3%",
      width: "100%",
    },
  };
});

export default useStyles;
