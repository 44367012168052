import ElasticBO from "./ElasticBO";

class ElasticAdapter {
  static toBO(result = {}) {
    const bo = new ElasticBO();
    bo.count = result.count || bo.count;
    bo.aggregation = result.aggregation || bo.aggregation;
    bo.currentPageNumber = result.currentPageNumber || bo.currentPageNumber;
    bo.data = result.data || bo.data;
    bo.elapsedMilliseconds =
      result.elapsedMilliseconds || bo.elapsedMilliseconds;
    bo.hasMore = result.hasMore || bo.hasMore;
    bo.pageSize = result.pageSize || bo.pageSize;
    bo.stats = result.stats || bo.stats;
    if (result.totalPage * result.pageSize >= 10000) {
      result.totalPage = result.totalPage - 1;
    }
    bo.total = result.total || bo.total;
    bo.totalPage = result.totalPage || bo.totalPage;
    return bo;
  }
}

export default ElasticAdapter;
