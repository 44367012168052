import React from "react";
import { Grid } from "@material-ui/core";
import CarteLieu from "./carteLieu";

const Lieux = ({ conseiller }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {conseiller.lieux.map((lieuId) => (
            <Grid item xs={12} md={6} key={lieuId}>
              <CarteLieu id={lieuId} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Lieux;
