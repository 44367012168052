import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Status } from "services/user";

import { useFeatureFlags } from "../featureFlags";
import { useTxHttp } from "../http";
import { useServices } from "../index";
import { NotificationDto } from "./Dto/notificationsDto";

interface NotificationsService {
  newAndReadNotificationsPages: PagedNotificationsDto[];
  archivedNotificationsPages: PagedNotificationsDto[];
  markAsRead: () => void;
  markAsArchived: (id: string) => void;
  markAllAsArchived: () => void;
  status: Status;
  newAndReadLoadNewPage: () => void;
  archivedLoadNewPage: () => void;
  getNewNotificationsCount: () => void;
  newNotificationsCount: number;
}

export interface PagedNotificationsDto {
  count: number;
  documents: NotificationDto[];
  hasMore: boolean;
  pageNumber: number;
  pageSize: number;
  total: number;
  totalPage: number;
}

const PAGE_SIZE = 20;

export const useNotifications = (): NotificationsService => {
  const [
    newAndReadNotificationsPages,
    setNewAndReadNotificationsPages,
  ] = useState<PagedNotificationsDto[]>([]);

  const [newAndReadCurrentPage, setNewAndReadCurrentPage] = useState({
    pageNumber: 1,
  });

  const [archivedNotificationsPages, setArchivedNotificationsPages] = useState<
    PagedNotificationsDto[]
  >([]);

  const [archivedCurrentPage, setArchivedCurrentPage] = useState({
    pageNumber: 1,
  });

  const [status, setStatus] = useState<Status>("pending");

  const [newNotificationsCount, setnewNotificationsCount] = useState(0);
  const { get, put } = useTxHttp();
  const { endpoints } = useServices();
  const { Notifications } = useFeatureFlags();

  const newAndReadLoadNewPage = useCallback(() => {
    if (
      newAndReadNotificationsPages.length > 0 &&
      !newAndReadNotificationsPages[newAndReadNotificationsPages.length - 1]
        .hasMore
    ) {
      return;
    }
    setNewAndReadCurrentPage((prev) => ({
      ...prev,
      pageNumber: prev.pageNumber + 1,
    }));
  }, [newAndReadNotificationsPages]);

  const archivedLoadNewPage = useCallback(() => {
    if (
      archivedNotificationsPages.length > 0 &&
      !archivedNotificationsPages[archivedNotificationsPages.length - 1].hasMore
    ) {
      return;
    }
    setArchivedCurrentPage((prev) => ({
      ...prev,
      pageNumber: prev.pageNumber + 1,
    }));
  }, [archivedNotificationsPages]);

  const getNewNotificationsCount = useCallback(() => {
    get<number>(endpoints.notifications.notificationsCenter.newNotifications)
      .then((response) => {
        setnewNotificationsCount(response.data);
      })
      .catch(() => {
        setStatus("rejected");
      });
  }, [endpoints.notifications.notificationsCenter.newNotifications, get]);

  const getArchivedNotifications = useCallback(
    async (pageNumber, pageSize) => {
      setStatus("pending");

      const response = await get<PagedNotificationsDto>(
        endpoints.notifications.notificationsCenter.archivedNotifications,
        {
          pageNumber,
          pageSize,
        }
      );

      if (response.status === 200) {
        setStatus("resolved");
        return response.data;
      }

      throw new Error("Unable to get archived notifications");
    },
    [get, endpoints.notifications.notificationsCenter.archivedNotifications]
  );

  const markAsRead = useCallback(() => {
    const newOrReadNotifications = newAndReadNotificationsPages.reduce(
      (aggregate, page) => aggregate.concat(page.documents),
      [] as NotificationDto[]
    );
    const convertedTimestampsArray = newOrReadNotifications
      .filter((notification) => notification.state === "New")
      .map((notification) => new Date(notification.timestamp).getTime());
    if (convertedTimestampsArray.length > 0) {
      const upToDate = new Date(Math.max.apply(null, convertedTimestampsArray));

      put<{ result: any; success: boolean }>(
        `${endpoints.notifications.markAsRead}?upTo=${moment(upToDate)
          .utc()
          .toISOString()}`
      ).then(() => {
        getNewNotificationsCount();
        const _newAndReadNotificationsPages: PagedNotificationsDto[] = newAndReadNotificationsPages.map(
          (page) => ({
            ...page,
            documents: page.documents.map((notification) => ({
              ...notification,
              state: "Read",
            })),
          })
        );
        setNewAndReadNotificationsPages(_newAndReadNotificationsPages);
      });
    }
  }, [
    endpoints.notifications.markAsRead,
    newAndReadNotificationsPages,
    getNewNotificationsCount,
    put,
  ]);

  const getNewAndReadNotifications = useCallback(
    async (pageNumber: number, pageSize) => {
      setStatus("pending");

      const response = await get<PagedNotificationsDto>(
        endpoints.notifications.notificationsCenter.newAndReadNotifications,
        {
          pageNumber,
          pageSize,
        }
      );

      if (response.status === 200) {
        setStatus("resolved");
        return response.data;
      }

      throw new Error("Unable to get new and read notifications");
    },
    [endpoints.notifications.notificationsCenter.newAndReadNotifications, get]
  );

  const markAsArchived = useCallback(
    async (id: string) => {
      return await put<{ result: any; success: boolean }>(
        endpoints.notifications.markAsArchived(id)
      ).then(() => {
        getNewNotificationsCount();
        setNewAndReadCurrentPage({ pageNumber: 1 });
        setArchivedCurrentPage({ pageNumber: 1 });
      });
    },
    [endpoints.notifications, put]
  );

  const markAllAsArchived = useCallback(() => {
    put<{ result: any; success: boolean }>(
      endpoints.notifications.markAllAsArchived
    ).then(() => {
      getNewNotificationsCount();
      setNewAndReadCurrentPage({ pageNumber: 1 });
      setArchivedCurrentPage({ pageNumber: 1 });
    });
  }, [endpoints.notifications.markAllAsArchived, put]);

  useEffect(() => {
    getNewAndReadNotifications(newAndReadCurrentPage.pageNumber, PAGE_SIZE)
      .then((response) => {
        if (newAndReadCurrentPage.pageNumber === 1) {
          setNewAndReadNotificationsPages([response]);
        } else {
          setNewAndReadNotificationsPages((prev) => [...prev, response]);
        }
      })
      .catch((reason) => setStatus("rejected"));
  }, [getNewAndReadNotifications, newAndReadCurrentPage]);

  useEffect(() => {
    getArchivedNotifications(archivedCurrentPage.pageNumber, PAGE_SIZE)
      .then((response) => {
        if (archivedCurrentPage.pageNumber === 1) {
          setArchivedNotificationsPages([response]);
        } else {
          setArchivedNotificationsPages((prev) => [...prev, response]);
        }
      })
      .catch(() => {
        setStatus("rejected");
      });
  }, [getArchivedNotifications, archivedCurrentPage]);

  useEffect(() => {
    if (!Notifications) return;
    getNewNotificationsCount();
    const timer = setInterval(() => {
      setNewAndReadCurrentPage({ pageNumber: 1 });
    }, 10 * 60 * 1000); // 10 minutes
    return () => {
      clearInterval(timer);
    };
  }, [Notifications, getNewNotificationsCount]);

  return {
    archivedLoadNewPage,
    archivedNotificationsPages,
    getNewNotificationsCount,
    markAllAsArchived,
    markAsArchived,
    markAsRead,
    newAndReadLoadNewPage,
    newAndReadNotificationsPages,
    newNotificationsCount,
    status,
  };
};
