import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { MatchingProfileRequestDto } from "services/matching/MatchingProfileRequestDto";

import useNotifier from "../../../../../../../../../components/Notifier/useNotifier";
import { useServices } from "../../../../../../../../../services";
import { useProfiles } from "../../../../../../../../../services/profiles";
import { ITheme } from "../../../../../../../../../theme/ts/main";
import { AccountType } from "../../../../models/accountType";
import { ProfileOriginType } from "../../../../models/profileOriginType";
import { ProfileReadDto } from "../../../../models/profilesReadDto";
import { SaleParkingProfileReadDto } from "../../../../models/Proposal/ForSale/Parking/saleParkingProfileReadDto";
import { SaleParkingProfileWriteDto } from "../../../../models/Proposal/ForSale/Parking/saleParkingProfileWriteDto";
import { SectionTitle } from "../../../components/SectionTitle";
import { WriteSellerMatchingNumber } from "../../../MatchingNumber/WriteSellerMatchingNumber";
import { SelectMaturity } from "../../../Maturity";
import { NotesInput } from "../../../Notes";
import { Write } from "../../../Write";
import { ParkingForSaleEquipments } from "../../Features/Parking/ParkingForSaleEquipments";
import { ParkingForSaleRentedInput } from "../../Features/Parking/ParkingForSaleRented";
import { ParkingSaleDimensionInput } from "../../Features/Parking/ParkingSaleDimension";
import { ParkingKind } from "../../Features/Parking/SelectParkingForSaleKind";
import { SalePlaceInput } from "../../SalePlace";
import MatchingAdaptater from "./MatchingAdapter";
import { SaleBudgetParkingInput } from "./SaleBudgetParking";
import classes from "./Write.module.css";

const useStyles = makeStyles((theme: ITheme) => {
  return {
    checkbox: {
      color: theme.palette.primary.main,
    },
  };
});

export const ParkingForSaleProfileWrite: FC<{
  contactId: string;
  account: AccountType;
  initial?: SaleParkingProfileReadDto;
  more: boolean;
  showMore: () => void;
  sendUpdatedProfile?: (profile: ProfileReadDto | undefined) => void;
  handleAfterUpdate?: () => void;
}> = ({
  contactId,
  account,
  initial,
  more,
  showMore,
  sendUpdatedProfile,
  handleAfterUpdate,
  children,
}) => {
  const styles = useStyles();
  const { add, update } = useProfiles(contactId);
  const [payload, setPayload] = useState(create(account, initial));
  const [state, setState] = useState<"none" | "saving" | "saved" | "error">(
    "none"
  );
  const {
    modal,
    l10n: { t },
  } = useServices();
  const { showSnack } = useNotifier();
  const mode = initial ? "update" : "add";
  const [pristine, setPristine] = useState(true);
  const [isFormValid, setIsFormValid] = useState({
    address: false,
    financialInfo: false,
    parkingPropertyInfo: true,
    parkingRentedInfo: true,
  });

  const disabled = state === "saving";
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down("sm"));

  const [matchingCriteria, setMatchingCriteria] = useState<
    MatchingProfileRequestDto | undefined
  >(undefined);
  useEffect(() => {
    setMatchingCriteria(MatchingAdaptater(payload.features));
  }, [payload]);

  useEffect(() => {
    switch (state) {
      case "none":
        break;
      case "saving":
        if (Object.entries(isFormValid).some(([_, b]) => !b)) {
          setPristine(false);
          setState("none");
        } else {
          (mode === "add"
            ? add("SaleParkingProfile", payload)
            : update(
                "SaleParkingProfile",
                initial!.id,
                payload,
                sendUpdatedProfile
              )
          )
            .then(() => {
              if (handleAfterUpdate) handleAfterUpdate();
              setState("saved");
            })
            .catch(() => setState("error"));
        }
        break;
      case "saved":
        modal.close();
        showSnack({ message: "Le profil est enregistré.", variant: "success" });
        break;
      case "error":
        showSnack({ message: "Une erreur est survenue.", variant: "danger" });
        break;
    }
  }, [state]);

  return (
    <Write
      pending={state === "saving"}
      save={() => setState("saving")}
      mode={mode}
      more={more}
      matching={<WriteSellerMatchingNumber criteria={matchingCriteria} />}
      showMore={showMore}
    >
      <div className={more && !mediaQuery ? classes.container : ""}>
        <div className={more ? classes.itemA : ""}>
          {children}
          <Grid item xs={12}>
            <ParkingKind
              onChange={(parkingKind) => {
                setPayload((prev) => ({
                  ...prev,
                  features: {
                    ...prev.features,
                    parkingKind,
                  },
                }));
              }}
              options={["Box", "ExteriorParking", "InteriorParking", "Unknown"]}
              disabled={false}
              value={payload.features.parkingKind}
            />
          </Grid>
          <Grid item xs={12}>
            <SalePlaceInput
              value={payload.features?.address}
              onChange={(address, isValid) => {
                setIsFormValid((previous) => ({
                  ...previous,
                  address: isValid,
                }));
                setPayload((prev) => ({
                  ...prev,
                  features: {
                    ...prev.features,
                    address,
                  },
                }));
              }}
              disabled={disabled}
              pristine={pristine}
            />
          </Grid>
        </div>

        <div className={more ? classes.itemB : ""}>
          <Grid item xs={12}>
            <SaleBudgetParkingInput
              pristine={pristine}
              more={more}
              value={payload.features.financialInfo}
              onChange={(financialInfo, isValid) => {
                setIsFormValid((previous) => ({
                  ...previous,
                  financialInfo: isValid,
                }));
                setPayload((prev) => ({
                  ...prev,
                  features: {
                    ...prev.features,
                    financialInfo,
                  },
                }));
              }}
            />
          </Grid>

          {more && (
            <>
              <SectionTitle title="Quelles sont les caractéristiques ?" />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ParkingSaleDimensionInput
                    value={{
                      adornement: "m\xB2",
                      label: t("Surface totale"),
                      value:
                        payload.features.parkingPropertyInfo.squareMeter || 0,
                    }}
                    onChange={(dimension, isValid) => {
                      setIsFormValid((previous) => ({
                        ...previous,
                        parkingPropertyInfo: isValid
                          ? previous.parkingPropertyInfo && isValid
                          : isValid,
                      }));
                      setPayload((prev) => ({
                        ...prev,
                        features: {
                          ...prev.features,
                          parkingPropertyInfo: {
                            ...prev.features.parkingPropertyInfo,
                            squareMeter: dimension.value,
                          },
                        },
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ParkingSaleDimensionInput
                    value={{
                      adornement: "",
                      label: t("Nombre de places"),
                      value:
                        payload.features.parkingPropertyInfo.spacesNumber || 1,
                    }}
                    onChange={(dimension, isValid) => {
                      setIsFormValid((previous) => ({
                        ...previous,
                        parkingPropertyInfo: isValid
                          ? previous.parkingPropertyInfo && isValid
                          : isValid,
                      }));
                      setPayload((prev) => ({
                        ...prev,
                        features: {
                          ...prev.features,
                          parkingPropertyInfo: {
                            ...prev.features.parkingPropertyInfo,
                            spacesNumber: dimension.value,
                          },
                        },
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ParkingSaleDimensionInput
                    value={{
                      adornement: "m",
                      label: t("Hauteur"),
                      value: payload.features.parkingPropertyInfo.height || 0,
                    }}
                    onChange={(dimension, isValid) => {
                      setIsFormValid((previous) => ({
                        ...previous,
                        parkingPropertyInfo: isValid
                          ? previous.parkingPropertyInfo && isValid
                          : isValid,
                      }));
                      setPayload((prev) => ({
                        ...prev,
                        features: {
                          ...prev.features,
                          parkingPropertyInfo: {
                            ...prev.features.parkingPropertyInfo,
                            height: dimension.value,
                          },
                        },
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ParkingSaleDimensionInput
                    value={{
                      adornement: "m",
                      label: t("Largeur"),
                      value: payload.features.parkingPropertyInfo.width || 0,
                    }}
                    onChange={(dimension, isValid) => {
                      setIsFormValid((previous) => ({
                        ...previous,
                        parkingPropertyInfo: isValid
                          ? previous.parkingPropertyInfo && isValid
                          : isValid,
                      }));
                      setPayload((prev) => ({
                        ...prev,
                        features: {
                          ...prev.features,
                          parkingPropertyInfo: {
                            ...prev.features.parkingPropertyInfo,
                            width: dimension.value,
                          },
                        },
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ParkingSaleDimensionInput
                    value={{
                      adornement: "m",
                      label: t("Profondeur"),
                      value: payload.features.parkingPropertyInfo.length || 0,
                    }}
                    onChange={(dimension, isValid) => {
                      setIsFormValid((previous) => ({
                        ...previous,
                        pparkingPropertyInfo: isValid
                          ? previous.parkingPropertyInfo && isValid
                          : isValid,
                      }));
                      setPayload((prev) => ({
                        ...prev,
                        features: {
                          ...prev.features,
                          parkingPropertyInfo: {
                            ...prev.features.parkingPropertyInfo,
                            length: dimension.value,
                          },
                        },
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  &nbsp;
                </Grid>
                <Grid item xs={12}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color={"primary"}
                          classes={{ root: styles.checkbox }}
                          checked={
                            payload.features.parkingPropertyInfo
                              .parkingJoinOwnership || false
                          }
                          onChange={() => {
                            setPayload((prev) => ({
                              ...prev,
                              features: {
                                ...prev.features,
                                parkingPropertyInfo: {
                                  ...prev.features.parkingPropertyInfo,
                                  parkingJoinOwnership: !payload.features
                                    .parkingPropertyInfo.parkingJoinOwnership,
                                },
                              },
                            }));
                          }}
                          inputProps={{ "aria-label": "checkbox" }}
                        />
                      }
                      label={
                        <Typography variant={"body2"}>
                          {t("parkingPropertyInfo.joinOwnership")}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color={"primary"}
                          classes={{ root: styles.checkbox }}
                          checked={
                            payload.features.parkingPropertyInfo
                              .boxableParking || false
                          }
                          onChange={() => {
                            setPayload((prev) => ({
                              ...prev,
                              features: {
                                ...prev.features,
                                parkingPropertyInfo: {
                                  ...prev.features.parkingPropertyInfo,
                                  boxableParking: !payload.features
                                    .parkingPropertyInfo.boxableParking,
                                },
                              },
                            }));
                          }}
                          inputProps={{ "aria-label": "checkbox" }}
                        />
                      }
                      label={
                        <Typography variant={"body2"}>
                          {t("parkingPropertyInfo.boxableParking")}
                        </Typography>
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </div>

        <div className={more ? classes.itemC : ""}>
          {more && (
            <>
              <ParkingForSaleEquipments
                value={payload.features.parkingAmenities || []}
                onChange={(parkingAmenities) => {
                  setPayload((prev) => ({
                    ...prev,
                    features: {
                      ...prev.features,
                      parkingAmenities,
                    },
                  }));
                }}
              />
              <ParkingForSaleRentedInput
                pristine={pristine}
                value={{
                  ...payload.features.parkingRentedInfo,
                  salePrice: payload.features.financialInfo.price || 0,
                }}
                onChange={(value, isValid) => {
                  setIsFormValid((previous) => ({
                    ...previous,
                    parkingRentedInfo: isValid,
                  }));
                  setPayload((prev) => ({
                    ...prev,
                    features: {
                      ...prev.features,
                      parkingRentedInfo: {
                        rent: value.rent,
                        rented: value.rented,
                      },
                    },
                  }));
                }}
              />
            </>
          )}
          <Grid item xs={12}>
            <NotesInput
              value={payload.notes}
              onChange={(notes) => setPayload((prev) => ({ ...prev, notes }))}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectMaturity
              onChange={(maturity) => {
                setPayload((prev) => ({
                  ...prev,
                  maturity,
                }));
              }}
              value={payload.maturity}
              disabled={disabled}
            />
          </Grid>
        </div>
      </div>
    </Write>
  );
};

function create(
  account: AccountType,
  initial?: SaleParkingProfileReadDto
): SaleParkingProfileWriteDto {
  return {
    account,
    creationDate: new Date(),
    maturity: initial?.maturity || "None",
    notes: initial?.notes || "",
    origin: initial?.origin
      ? (initial?.origin as ProfileOriginType)
      : "TXProfile",
    features: initial
      ? initial.features
      : {
          address: {},
          parkingAmenities: [],
          financialInfo: {
            agentFees: 0,
            price: 0,
          },
          parkingKind: "Unknown",
          parkingPropertyInfo: {},
          parkingRentedInfo: {
            rent: 0,
            rented: false,
          },
        },
    reference: initial?.reference || "",
  };
}
