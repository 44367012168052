import {
  AdaptedRefinements,
  Refinements,
} from "../../../components/RefinementField/types";
import { RequestForInformationsCriteria } from "../../../types";

export const getRefinements = (
  adaptedRefinements: AdaptedRefinements<RequestForInformationsCriteria>
) =>
  Object.keys(adaptedRefinements).reduce(
    (acc, k) => ({
      ...acc,
      [k]: adaptedRefinements[k as RequestForInformationsCriteria].refinement,
    }),
    {} as Refinements<RequestForInformationsCriteria>
  );
