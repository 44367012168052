import { IconButton, Tooltip } from "@material-ui/core";
import { CallMade } from "@material-ui/icons";
import sellerIcon from "assets/icons/primary_seller_icon.svg";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import routesNames from "router/routesNames";
import { useServices } from "services";
import { usePortfolioSearch } from "services/portfolio/usePortfolioSearch";

import { useFeatureFlags } from "services/featureFlags";
import { PigeConversionContext } from "../../contexts/PigeConversionContextProvider";

export interface AdConversionProps {
  id?: string;
  withText?: boolean;
  adKind?: string;
  contactPhone?: string;
  contactEmail?: string;
}

export const SellerIcon: React.FC = () => (
  <img alt="sellerIcon" src={sellerIcon} width={"30px"} height={"auto"} />
);

export const AdConversion: React.FC<AdConversionProps> = ({
  id,
  withText,
  adKind,
  contactPhone,
  contactEmail,
}) => {
  const { handleTransform, isConverted } = useContext(PigeConversionContext);
  const { getContactIdByPhoneNumberOrEmailAddress } = usePortfolioSearch();
  const {
    l10n: { t },
  } = useServices();
  const history = useHistory();

  const { Pige_Conversion } = useFeatureFlags();

  const isAdConverted = isConverted(id);

  const onClick = async () => {
    const contactId = await getContactIdByPhoneNumberOrEmailAddress(
      contactPhone ?? "",
      contactEmail ?? ""
    );
    if (isAdConverted && !!contactId) {
      const redirectUrl = `${routesNames.app}${routesNames.contact}/${contactId}/profils/view`;
      history.push(redirectUrl);
    } else {
      handleTransform(id ?? "", contactEmail, contactPhone);
    }
  };

  if (!Pige_Conversion) return <></>;
  if (adKind?.toLocaleUpperCase() !== "VENTE") return <></>;
  if (contactPhone === undefined && contactEmail === undefined) return <></>;

  const withTextButton = (
    <div color="primary" onClick={onClick}>
      <IconButton>{isAdConverted ? <CallMade /> : <SellerIcon />}</IconButton>
      <span>{isAdConverted ? t("Ouvrir") : t("Transformer")}</span>
    </div>
  );

  const withoutTextButton = (
    <Tooltip title={isAdConverted ? t("Ouvrir") : t("Transformer")}>
      <IconButton color="primary" onClick={onClick}>
        {isAdConverted ? <CallMade /> : <SellerIcon />}
      </IconButton>
    </Tooltip>
  );
  return !withText ? withoutTextButton : withTextButton;
};
