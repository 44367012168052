import biensDisponiblesGridOuListe from "mockups/biens/biensDisponiblesListe.json";
import useHTTPService from "services/dataSources/communicationServices/useHTTPService";
import useTestService from "services/dataSources/testServices/useTestService";
import rechercheGlobaleAdapter from "services/rapprochement/Rapprochement/BiensEnVente/RechercheGlobale/rechercheGlobaleAdapter";

import { useServices } from "../index";

export const useBiensDisponiblesRechercheGlobaleAPI = ({
  params,
  erreur = false,
}) => {
  const { endpoints } = useServices();
  const { status, adaptedData } = useHTTPService({
    adapter: rechercheGlobaleAdapter,
    endpoint: endpoints.bienEnVente.rechercheGlobale,
    params,
  });
  return { items: adaptedData, status: status };
};

export const useBiensDisponiblesRechercheGlobaleTest = ({
  params,
  erreur = false,
}) => {
  const { status, adaptedData } = useTestService({
    adapter: rechercheGlobaleAdapter,
    erreur,
    mockup: biensDisponiblesGridOuListe,
    params,
  });
  return { items: adaptedData, status: status };
};

export const useBiensDisponiblesRechercheGlobale = window.testTX
  ? useBiensDisponiblesRechercheGlobaleTest
  : useBiensDisponiblesRechercheGlobaleAPI;
