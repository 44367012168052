import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

interface CloseButtonProps {
  clickHandler: () => void;
}

export const CloseButton: React.FC<CloseButtonProps> = ({ clickHandler }) => {
  return (
    <IconButton color="primary" aria-label="Close" onClick={clickHandler}>
      <CloseIcon />
    </IconButton>
  );
};
