import React, { Ref } from "react";
import getUrlPhoto from "utils/UrlImage";
import Grid from "@material-ui/core/Grid";
import { PropertyForSale } from "types";
import { SmallPrintablePropertyCardAdapted } from "../SmallPrintablePropertyCard/SmallPrintablePropertyCardAdapted";
import { DescriptionSection } from "../PrintableDetails/DescriptionSection";
import { PrintableAgentDetails } from "../PrintableAgentDetails/PrintableAgentDetails";
import { OthersSections } from "../PrintableDetails/OthersSections";
import { detailsAdapter } from "../PrintableDetails/detailsAdapter";
import TitreSection from "../Details/components/TitreSection";
import { Typography } from "@material-ui/core";
import moment from "moment";
import PrintablePage from "components/PrintablePage";
import capi from "assets/images/logos/logo_capifrance.png";
import oh from "assets/images/logos/logo_optimhome.png";
import reflex from "assets/images/logos/logo_refleximmo.svg";

const pictureSize = 200;

interface PrintablePropertyForSaleDetailsProps {
  bien: PropertyForSale;
  componentToPrintRef: Ref<HTMLDivElement>;
  titre: JSX.Element;
}

export const PrintablePropertyDetails = ({
  bien,
  componentToPrintRef,
  titre,
}: PrintablePropertyForSaleDetailsProps): JSX.Element => {
  let logo;
  switch (bien.bu) {
    case "Optimhome":
      logo = oh;
      break;
    case "Refleximmo":
      logo = reflex;
      break;
    default:
      logo = capi;
  }

  const photoPages = [];
  let arrayInter = [];
  for (let i = 1; i < bien.photoGuid.length + 1; i++) {
    arrayInter.push(bien.photoGuid[i - 1]);
    if (i % 8 === 0) {
      photoPages.push(arrayInter);
      arrayInter = [];
    }
  }
  if (arrayInter.length > 0) photoPages.push(arrayInter);

  const header = (
    <div className={"divHeader"}>
      <img src={logo} width={"auto"} height={50} alt={"logo"} />
    </div>
  );

  const footer = (
    <Typography>
      <span style={{ fontWeight: "bold" }}>
        TremplinX - réf. {bien.codeAffaire}{" "}
      </span>{" "}
      - {moment().format("DD-MM-YYYY HH:mm:ss")}
    </Typography>
  );

  return (
    <div style={{ display: "none" }}>
      <div ref={componentToPrintRef}>
        <PrintablePage header={header} footer={footer}>
          {titre}
          <SmallPrintablePropertyCardAdapted bien={bien} />
          <div style={{ height: "25px" }} />
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item xs={6}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <PrintableAgentDetails
                  photo={bien.conseillerPrincipal.photo}
                  telephone={bien.conseillerPrincipal.telephone}
                  email={bien.conseillerPrincipal.email}
                  estActif={bien.conseillerPrincipal.estActif}
                  bu={bien.bu}
                  nom={bien.conseillerPrincipal.nom}
                  prenom={bien.conseillerPrincipal.prenom}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              &nbsp;
            </Grid>
            <Grid item xs={12}>
              <DescriptionSection descriptif={bien.annonceDesc} />
            </Grid>
          </Grid>
        </PrintablePage>
        <PrintablePage header={header} footer={footer}>
          <OthersSections details={detailsAdapter(bien)} />
        </PrintablePage>

        {photoPages.map((photoPage, index) => (
          <PrintablePage header={header} footer={footer} key={index}>
            <>
              {index === 0 && <TitreSection titre={"le bien en images"} />}
              <Grid
                container
                spacing={2}
                justify={"center"}
                alignItems={"center"}
              >
                {photoPage.map((photoGuid) => (
                  <Grid item xs={6} key={photoGuid}>
                    <div
                      style={{
                        backgroundImage: `url(${getUrlPhoto(
                          bien.bu,
                          `${pictureSize}x${pictureSize}`,
                          photoGuid
                        )}), url("/static/images/no-image-box.png")`,
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: pictureSize,
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          </PrintablePage>
        ))}
      </div>
    </div>
  );
};
