import { RadioGroup } from "@material-ui/core";
import React from "react";
import { useTranslation } from "services/l10n/useTranslation";

import { ComplementIcon } from "../../../../routes/app/contacts/contact/components/Profiles/components/RadioChip";
import { SelectChip } from "../../../../routes/app/contacts/contact/components/Profiles/SelectChip";
import { PaddingContainer } from "../../atoms/containers/PaddingContainer";
import { Text12px, Text12pxError } from "../../atoms/typographies/Text12px";

const checkIfValueIsValidOption = (value: any, options: any[]) => {
  return options.indexOf(value) >= 0;
};

export const RadioChip: React.FC<{
  required?: boolean;
  pristine?: boolean;
  translationPrefix: string;
  options: any[];
  value: any;
  onChange: (value: any) => void;
}> = ({
  translationPrefix,
  options,
  onChange,
  value,
  required = false,
  pristine = false,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <RadioGroup row value={value} onChange={(_) => onChange(_.target.value)}>
        {options.map((option) => (
          <SelectChip
            key={option}
            icon={
              <ComplementIcon
                label={
                  translationPrefix
                    ? t(`${translationPrefix}.${option}`)
                    : t(option)
                }
                checked={false}
              />
            }
            checkedIcon={
              <ComplementIcon
                label={
                  translationPrefix
                    ? t(`${translationPrefix}.${option}`)
                    : t(option)
                }
                checked={true}
              />
            }
            value={option}
          />
        ))}
      </RadioGroup>
      {required && (
        <>
          <PaddingContainer top={5} bottom={5}>
            {!checkIfValueIsValidOption(value, options) && !pristine ? (
              <Text12pxError>*Champ obligatoire</Text12pxError>
            ) : (
              <Text12px>*Champ obligatoire</Text12px>
            )}
          </PaddingContainer>
        </>
      )}
    </>
  );
};
