import { FormControlLabel } from "@material-ui/core";
import {
  LocalOfferOutlined,
  LoyaltyOutlined,
  VpnKeyOutlined,
} from "@material-ui/icons";
import { CircleIcon } from "UI/shared/atoms/icons/CircleIcon";
import React from "react";
import { BusinessType } from "routes/app/profiles/adapters/profileAdapterDetails";

export interface ProfileBusinessTypeChipProps {
  value: BusinessType;
  chipDiameter?: number;
}

export const ProfileBusinessTypeChip: React.FC<ProfileBusinessTypeChipProps> = ({
  value,
  chipDiameter,
}) => {
  const circleIconProps = { ...(chipDiameter && { diameter: chipDiameter }) };
  const rent = (
    <FormControlLabel
      value={value}
      control={
        <CircleIcon {...circleIconProps}>
          <VpnKeyOutlined />
        </CircleIcon>
      }
      label={"Location"}
      labelPlacement="bottom"
    />
  );

  const lifeAnnuity = (
    <FormControlLabel
      value={value}
      control={
        <CircleIcon {...circleIconProps}>
          <LocalOfferOutlined />
        </CircleIcon>
      }
      label={"Viager"}
      labelPlacement="bottom"
    />
  );

  const sale = (
    <FormControlLabel
      value={value}
      control={
        <CircleIcon {...circleIconProps}>
          <LoyaltyOutlined />
        </CircleIcon>
      }
      label={"Vente"}
      labelPlacement="bottom"
    />
  );

  const purchase = (
    <FormControlLabel
      value={value}
      control={
        <CircleIcon>
          <LoyaltyOutlined />
        </CircleIcon>
      }
      label={"Achat"}
      labelPlacement="bottom"
    />
  );
  const rental = (
    <FormControlLabel
      value={value}
      control={
        <CircleIcon {...circleIconProps}>
          <VpnKeyOutlined />
        </CircleIcon>
      }
      label={"Bail"}
      labelPlacement="bottom"
    />
  );
  if (value === "Achat") return purchase;
  else if (value === "Location") return rent;
  else if (value === "Vente") return sale;
  else if (value === "Bail") return rental;
  else return lifeAnnuity;
};
