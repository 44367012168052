import { makeStyles, TextField, TextFieldProps } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  textField: {
    width: "100%",
  },
}));

export const TXTextField: React.FC<TextFieldProps> = (props) => {
  const classes = useStyles();
  return (
    <TextField {...props} variant="outlined" className={classes.textField}>
      {props.children}
    </TextField>
  );
};
