import { IconButton } from "@material-ui/core";
import React from "react";

export interface ActionButtonProps {
  icon: JSX.Element;
  onClick: () => void;
  text?: string;
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  icon,
  onClick,
  text,
}) => {
  return (
    <div onClick={onClick} style={{ alignItems: "center", display: "flex" }}>
      <IconButton color="primary">{icon}</IconButton>
      {text && <span>{text}</span>}
    </div>
  );
};
