import { useEffect, useState } from "react";
import { useTxHttp } from "../../../../../services/http";

interface UseRechercheGlobaleProps {
  fullText: string;
  url: string;
  adapter: any;
  pageNumber: number;
  pageSize: number;
}

export default function useRechercheGlobale({
  fullText,
  url,
  adapter = () => {},
  pageNumber,
  pageSize,
}: UseRechercheGlobaleProps) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [items, setItems] = useState([]);

  const { get } = useTxHttp();

  useEffect(() => {
    setLoading(true);
    setError(false);
    get<any>(url, {
      fullText,
      page: pageNumber,
      size: pageSize,
    })
      .then((res) => {
        if (res.data?.result) {
          const receivedItems = adapter(res.data.result);
          setItems(receivedItems);
        } else if (res.data) {
          const receivedItems = adapter(res.data);
          setItems(receivedItems);
        }
      })
      .catch((e) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fullText]);

  return { error, items, loading };
}
