import { Dialog, IconButton, makeStyles, Typography } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Close } from "@material-ui/icons";
import React from "react";

import { Carousel } from "./Carousel";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    color: theme.palette.default,
    cursor: "pointer",
  },
  closeButtonContainer: {
    position: "absolute",
    right: 20,
    top: 20,
    zIndex: 10,
  },
  dialogContent: {
    height: "100%",
    padding: 0,
  },
  dialogTitle: {
    alignItems: "center",
    backgroundColor: "white",
    boxShadow: "0PX 5px 5px rgba(0,0,0,.4)",
    color: theme.palette.default,
    display: "flex",
    height: 70,
    padding: 0,
    width: "100%",
    zIndex: 1,
  },
  paperFullScreen: {
    background: "#000000",
    color: "white",
  },
  titleContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: 4,
    width: "100%",
  },
}));

const ModalCarousel = ({ urls, onHide, description, isDisplayed, label }) => {
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      fullWidth
      aria-labelledby={label}
      open={isDisplayed}
      onClose={onHide}
      onClick={onHide}
      classes={{ paperFullScreen: classes.paperFullScreen }}
    >
      <DialogTitle className={classes.dialogTitle} disableTypography={true}>
        <div className={classes.titleContainer}>
          <div style={{ display: "flex", paddingLeft: "20px" }}>
            <Typography variant={"h2"}>Photos |&nbsp;</Typography>
            <Typography
              onClick={(event) => {
                event.stopPropagation();
              }}
              style={{ fontSize: "1.5em" }}
            >
              {description}
            </Typography>
          </div>
          <IconButton onClick={onHide} className={classes.closeButton}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Carousel
          onClick={(event) => {
            event.stopPropagation();
          }}
          onChange={(_) => _}
          urls={urls}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ModalCarousel;
