import React from "react";
import Typography from "@material-ui/core/Typography";
import { SpaceBetweenContainer } from "UI/shared/atoms/containers/SpaceBetweenContainer";
import { CloseButton } from "UI/shared/atoms/buttons/CloseButton";

export interface HeaderWithCloseButtonProps {
  title: string;
  closeHandler: () => void;
}

export const HeaderWithCloseButton: React.FC<HeaderWithCloseButtonProps> = ({
  title,
  closeHandler,
}) => {
  return (
    <SpaceBetweenContainer>
      <Typography variant="h2" noWrap>
        {title}
      </Typography>
      <CloseButton clickHandler={closeHandler} />
    </SpaceBetweenContainer>
  );
};
