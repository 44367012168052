const createValue = ({ query = "", value = "" } = {}) => ({
  createParams() {
    if (this.value === null || this.value === "") return {};
    return { [this.query]: this.value };
  },
  query,
  update(value) {
    this.value = value;
  },
  value,
});

const createMultipleValue = ({
  query = "",
  aggregation = "",
  values = [],
  count = 0,
} = {}) => ({
  aggregation,
  count,
  createParams() {
    return {
      [this.query]: this.values
        .filter((item) => item.isRefined)
        .map((item) => item.value),
    };
  },
  query,
  update(values) {
    this.values = values;
  },
  values,
});

export function RefinementsFactory() {
  return {
    create() {
      return Refinements();
    },
  };
}

function Refinements() {
  const refinements = [];
  return {
    add(creator) {
      if (!creator.hasOwnProperty("createParams"))
        throw new Error('Missing "createParams" function in creator');
      if (!creator.hasOwnProperty("update"))
        throw new Error('Missing "update" function in creator');
      refinements.push(creator);
      return this;
    },
    addMultipleValue({
      query = "",
      aggregation = "",
      values = [],
      count = 0,
    } = {}) {
      this.add(createMultipleValue({ aggregation, count, query, values }));
      return this;
    },
    addValue({ query = "", value = "" } = {}) {
      this.add(createValue({ query, value }));
      return this;
    },
    clear() {
      refinements.map((refinement) => {
        if (
          refinement.query !== "sortColumn" &&
          refinement.query !== "sortAsc"
        ) {
          if (refinement.value !== undefined) {
            if (
              ["withParking", "withElevator", "withNotes"].includes(
                refinement.query
              )
            ) {
              refinement.value = false;
            } else {
              refinement.value = "";
            }
          } else {
            refinement.values = refinement.values.map((value) => {
              value.isRefined = false;
              return value;
            });
          }
        }
        return refinement;
      });
    },
    createParams(withoutGeoloc = false) {
      return refinements.reduce((acc, current) => {
        if (
          withoutGeoloc &&
          ["regions", "departements", "villes", "quartiers"].includes(
            current.query
          )
        )
          return { ...acc };
        return { ...acc, ...current.createParams() };
      }, {});
    },
    fromObject(object = {}) {
      const findIdxFunc = (property) => (elt) => elt.query === property;

      let property;
      for (property in object) {
        const refinementIdx = refinements.findIndex(findIdxFunc(property));
        if (refinementIdx > -1) {
          refinements[refinementIdx].update(
            object[property].values
              ? object[property].values
              : object[property].value
          );
        }
      }
    },
    fromURLParams(params = []) {
      const findRefinement = (query) => (elt) => elt.query === query;
      params.forEach((param) => {
        const refinementIdx = refinements.findIndex(
          findRefinement(param.query)
        );
        if (refinementIdx > -1) {
          if (param.values) {
            if (Array.isArray(param.values)) {
              const newValues = param.values.map((element) => ({
                isRefined: true,
                label: element.label,
                value: element.value,
              }));
              refinements[refinementIdx].update(newValues);
            } else {
              console.warn(`param.values: ${param.values} is used as an array`);
            }
          } else {
            refinements[refinementIdx].update(param.value);
          }
        }
      });
    },
    get() {
      return refinements;
    },
    setValues(query, values) {
      const index = refinements.findIndex((item) => item.query === query);
      if (index > -1) {
        refinements[index].values = values;
      }
    },
    toObject() {
      return refinements.reduce(
        (acc, current) => ({ ...acc, [current.query]: current }),
        {}
      );
    },
    toURLParams() {
      const params = refinements.map((refinement) => {
        //case of multiple values
        if (refinement.values)
          return {
            query: refinement.query,
            values: refinement.values
              .filter((element) => element.isRefined === true)
              .map((element) => ({
                label: element.label,
                value: element.value,
              })),
          };
        // case of single value
        return {
          query: refinement.query,
          value: refinement.value,
        };
      });
      const paramsWithoutEmptySingleValueRefinement = params.filter(
        (param) => param.value && param.value !== ""
      );
      const paramsWithoutEmptyMultipleValuesRefinements = params.filter(
        (param) => param.values && param.values.length > 0
      );
      const meaningfullParams = paramsWithoutEmptySingleValueRefinement.concat(
        paramsWithoutEmptyMultipleValuesRefinements
      );

      return meaningfullParams;
    },
    update(query, value) {
      const index = refinements.findIndex((item) => item.query === query);
      if (index > -1) {
        refinements[index].update(value);
      }
    },
  };
}
