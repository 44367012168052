import { Radio, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { MouseEventHandler } from "react";
import { useServices } from "services";
import { ITheme } from "theme/ts/main";

export interface RadioChipProps {
  label: string;
  value?: string;
  color: "default" | "primary" | "secondary" | undefined;
  disabled: boolean;
  onClick?: MouseEventHandler;
}

export interface ComplementIconProps {
  label: string;
  checked: boolean;
}

const useStyles = makeStyles((theme: ITheme) => {
  const commonStyle = {
    borderRadius: "20px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    padding: "2px 15px 2px 15px",
  };
  return {
    complement: {
      ...commonStyle,
      backgroundColor: theme.palette.common.main,
      color: theme.palette.primary.main,
    },
    complementChecked: {
      ...commonStyle,
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    radioRoot: {
      "& span": {
        "& input": {
          "&:hover + div": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
          },
        },
        padding: "0px 2px 0px 2px",
      },
      marginLeft: 0,
      padding: "0.378rem",
    },
  };
});

export const ComplementIcon: React.FunctionComponent<ComplementIconProps> = ({
  label,
  checked,
}) => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();

  return (
    <div className={checked ? classes.complementChecked : classes.complement}>
      <Typography variant={"h5"}>{t(label)}</Typography>
    </div>
  );
};

export const RadioChip: React.FC<RadioChipProps> = ({
  color,
  value,
  label,
  disabled,
  onClick,
}) => {
  const classes = useStyles();
  const icon = <ComplementIcon label={label || "Tous"} checked={false} />;
  const checkedIcon = <ComplementIcon label={label || "Tous"} checked={true} />;

  return (
    <Radio
      value={value}
      color={color}
      icon={icon}
      checkedIcon={checkedIcon}
      disabled={disabled}
      className={classes.radioRoot}
      style={{ backgroundColor: "transparent" }}
      onClick={onClick}
    />
  );
};
export default RadioChip;
