import React from "react";
import PropType from "prop-types";
import MapIcon from "@material-ui/icons/MapOutlined";
import GridIcon from "@material-ui/icons/AppsOutlined";
import ListIcon from "@material-ui/icons/ViewHeadlineOutlined";
import { Hidden, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  clickable: {
    cursor: "pointer",
    display: "flex",
  },
  clickableSelected: {
    color: theme.palette.tonique,
    display: "flex",
  },
  displayChoiceItem: {
    fontWeight: "regular",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  displaySelectedChoiceItem: {
    color: theme.palette.tonique,
    fontWeight: "bold",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  icon: {
    color: theme.palette.default,
  },
  iconSelected: {
    color: theme.palette.tonique,
  },
  menu: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  root: {
    // ...theme.mixins.gutters(),
    // paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(2)
  },
}));

export default function BiensDisplayMode({ onChange, selected }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.menu}>
        <div
          className={
            selected === "map" ? classes.clickableSelected : classes.clickable
          }
          onClick={() => onChange("map")}
        >
          <MapIcon
            classes={
              selected === "map"
                ? { root: classes.iconSelected }
                : { root: classes.icon }
            }
          />
          <Typography
            className={
              selected === "map"
                ? classes.displaySelectedChoiceItem
                : classes.displayChoiceItem
            }
            variant={"body1"}
            style={{
              borderRight: "1px solid black",
              lineHeight: 1.7,
              paddingRight: 10,
            }}
          >
            Carte
          </Typography>
        </div>
        <Hidden smDown>
          <div
            className={
              selected === "list"
                ? classes.clickableSelected
                : classes.clickable
            }
            onClick={() => onChange("list")}
          >
            <ListIcon
              className={
                selected === "list" ? classes.iconSelected : classes.icon
              }
            />
            <Typography
              className={
                selected === "list"
                  ? classes.displaySelectedChoiceItem
                  : classes.displayChoiceItem
              }
              variant={"body1"}
              style={{
                borderRight: "1px solid black",
                lineHeight: 1.7,
                paddingRight: 10,
              }}
            >
              {" "}
              Liste{" "}
            </Typography>
          </div>
        </Hidden>
        <div
          className={
            selected === "gallery"
              ? classes.clickableSelected
              : classes.clickable
          }
          onClick={() => onChange("gallery")}
        >
          <GridIcon
            className={
              selected === "gallery" ? classes.iconSelected : classes.icon
            }
          />
          <Typography
            className={
              selected === "gallery"
                ? classes.displaySelectedChoiceItem
                : classes.displayChoiceItem
            }
            variant={"body1"}
            style={{ lineHeight: 1.7 }}
          >
            {" "}
            Galerie{" "}
          </Typography>
        </div>
      </div>
    </div>
  );
}

BiensDisplayMode.propTypes = {
  onChange: PropType.func,
};
