import {
  IPigeState,
  PigeGlobalAction,
  PigeGlobalActionName,
  Display,
  TabName,
  PigeState,
} from "../global";

export const reducer = (state: IPigeState, action: PigeGlobalAction) => {
  const reducerSwitch: Record<PigeGlobalActionName, IPigeState> = {
    SELECT_AD_ID: { ...state, selectedAdId: action.payload as string },
    SELECT_DISPLAY: { ...state, display: action.payload as Display },
    SELECT_TAB: { ...state, tab: action.payload as TabName },
    SAVE_SCROLL_POSITION: {
      ...state,
      scrollPosition: action.payload as number,
    },
    BACK_TO_LIST: { ...state, selectedAdId: undefined },
    UPDATE_SLUG: { ...state, slug: action.payload as string },
    UPDATE_REDIRECT_URL: { ...state, redirectUrl: action.payload as string },
    MULTIPLE_UPDATES: { ...state, ...(action.payload as Partial<PigeState>) },
  };

  return reducerSwitch[action.type] || state;
};
