import React from "react";

export const PrintablePage: React.FC<{
  header: JSX.Element;
  footer: JSX.Element;
  children: JSX.Element | JSX.Element[];
}> = ({
  header,
  footer,
  children,
}: {
  header: JSX.Element;
  footer: JSX.Element;
  children: JSX.Element | JSX.Element[];
}) => (
  <div className={"printable-page"}>
    {header}
    <div style={{ flex: 1 }}>{children}</div>
    {footer}
  </div>
);
