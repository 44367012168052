import { formatSurfaceRange } from "utils/formatage";
import clearRefinementsArray from "utils/cleanArray";

export default function handleRefinements(refinements, receivedAggregation, t) {
  let refinement;
  const formattedRefinements = refinements.toObject();

  for (refinement in formattedRefinements) {
    if (
      formattedRefinements[refinement].values &&
      formattedRefinements[refinement].aggregation !== "none"
    ) {
      const refinementToProcess = formattedRefinements[refinement];
      let previousSelected = refinementToProcess.values
        .filter((item) => item.isRefined && item.isRefined === true)
        .map((item) => item.value);
      let aggregation = receivedAggregation[refinementToProcess.aggregation];

      if (receivedAggregation[`global_${refinementToProcess.aggregation}`]) {
        aggregation = aggregation.concat(
          receivedAggregation[`global_${refinementToProcess.aggregation}`]
        );
      }

      let updateFunction;
      let triFunction;

      switch (refinementToProcess.aggregation) {
        case "RegionStr":
          triFunction = (a, b) => a.tri.localeCompare(b.tri);
          updateFunction = (item) => ({
            count: item.value,
            isRefined: previousSelected.includes(JSON.parse(item.key).Id),
            label: `${JSON.parse(item.key).Nom}`,
            tri: `${JSON.parse(item.key).Nom}`,
            value: JSON.parse(item.key).Id,
          });
          break;
        case "DepartementStr":
          triFunction = (a, b) => a.tri.localeCompare(b.tri);
          updateFunction = (item) => ({
            count: item.value,
            isRefined: previousSelected.includes(JSON.parse(item.key).Id),
            label: `${JSON.parse(item.key).Code} - ${JSON.parse(item.key).Nom}`,
            tri: `${JSON.parse(item.key).Code} - ${JSON.parse(item.key).Nom}`,
            value: JSON.parse(item.key).Id,
          });
          break;
        case "VilleStr":
          triFunction = (a, b) => a.tri.localeCompare(b.tri);
          updateFunction = (item) => ({
            count: item.value,
            isRefined: previousSelected.includes(JSON.parse(item.key).Id),
            label: `${JSON.parse(item.key).Nom} - ${
              JSON.parse(item.key).CodesPostaux[0]
            }`,
            tri: `${JSON.parse(item.key).Nom} - ${
              JSON.parse(item.key).CodesPostaux[0]
            }`,
            value: JSON.parse(item.key).Id,
          });
          break;
        case "SurfaceTerrainRange":
        case "SurfaceHabitableRange":
        case "SurfaceRange":
          triFunction = (a, b) => a.tri - b.tri;
          updateFunction = (item) => ({
            count: item.value,
            isRefined: previousSelected.includes(item.key),
            label: formatSurfaceRange(item.key),
            tri:
              item.key === "NC"
                ? 0
                : parseInt(item.key.replace(/(^\[|^\[@)(\d+)([@+].+$)/i, "$2")),
            value: item.key,
          });
          break;
        case "Conseiller":
          triFunction = (a, b) => a.tri.localeCompare(b.tri);
          updateFunction = (item) => ({
            count: item.value,
            isRefined: previousSelected.includes(item.key),
            label: item.key,
            tri: item.key,
            value: item.key,
          });
          break;
        default:
          triFunction = (a, b) => a.tri.localeCompare(b.tri);
          updateFunction = (item) => ({
            count: item.value,
            isRefined: previousSelected.includes(item.key),
            label: t(item.key),
            tri: item.key,
            value: item.key,
          });
      }
      const _aggregation = clearRefinementsArray(aggregation);
      refinements.setValues(
        refinementToProcess.query,
        _aggregation.map(updateFunction).sort(triFunction)
      );
    }
  }
}
