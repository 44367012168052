import React from "react";

export const Container: React.FC<{ horizontal: boolean; id: string }> = ({
  children,
  horizontal,
  id,
}) =>
  horizontal ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        overflow: "auto",
      }}
      id={id}
    >
      {" "}
      {children}
    </div>
  ) : (
    <>{children}</>
  );
