import { Grid, Hidden, Typography, useTheme } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRangeOutlined";
import ContextualSideBar from "components/ContextualSideBar/ContextualSideBar";
import ListeCriteres from "components/ListeCriteres";
import Localisation from "layoutContents/components/ContextualSideBar/components/localisation";
import NombreDeResultats from "layoutContents/components/ContextualSideBar/components/nombreDeResultats";
import SearchDates from "layoutContents/components/ContextualSideBar/components/searchDates";
import TitreBloc from "layoutContents/components/ContextualSideBar/components/titreBloc";
import moment from "moment";
import React from "react";

import { CheckboxRefinement } from "../../../../../../components/RefinementField/CheckboxRefinement";
import styles from "../../../../../../components/Searchbar/MoreFilters/MoreFilters.module.css";
import Caracteristiques from "./components/caracteristiques";

const ContextualSideBarConseillers = ({
  drawer,
  setOpenDrawer,
  onUpdateRefinements,
  onUpdateLocalisations,
  refinements,
  clearRefinements,
  t,
  totalItems,
  classes,
  endPointConseiller = "",
  formatOptionsListe,
}) => {
  const {
    bus,
    roles,
    specialites,
    telephone,
    topPerformer,
  } = refinements.toObject();
  const theme = useTheme();

  let dateEntreeMin,
    dateEntreeMax,
    queryDateEntreeMin,
    queryDateEntreeMax = "";

  queryDateEntreeMin = "dateDentreeMin";
  queryDateEntreeMax = "dateDentreeMax";

  dateEntreeMin = refinements.toObject().dateDentreeMin;
  dateEntreeMax = refinements.toObject().dateDentreeMax;

  const handleChangeDateEntree = ({ dateDebut, dateFin }) => {
    if (dateDebut !== "")
      onUpdateRefinements(
        queryDateEntreeMin,
        moment(dateDebut).format("YYYY-MM-DD")
      );
    if (dateFin !== "")
      onUpdateRefinements(
        queryDateEntreeMax,
        moment(dateFin).format("YYYY-MM-DD")
      );
  };

  const _refinementsObject = refinements.toObject();

  return (
    <ContextualSideBar
      contextualOpen={drawer}
      setContextualOpen={setOpenDrawer}
      width={window.innerWidth > 400 ? "400px" : window.innerWidth.toString()}
      withCloseBtn={true}
      titre={t("Recherche détaillée")}
    >
      <div className={classes.drawer} style={{ position: "relative" }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Hidden mdUp>
              <>
                <ListeCriteres
                  items={_refinementsObject}
                  clearRefinements={clearRefinements}
                  onChange={onUpdateRefinements}
                />
              </>
            </Hidden>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} style={{ paddingBottom: 8 }}>
                <div className={styles.title}>
                  <DateRangeIcon
                    style={{
                      color: theme.palette.tonique,
                      marginRight: 5,
                    }}
                  />
                  <Typography variant={"h4"}>{t("Affichage")}</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <CheckboxRefinement
                  onChange={(value) =>
                    onUpdateRefinements("topPerformer", value.value)
                  }
                  label={t("Top performers")}
                  value={topPerformer || false}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TitreBloc
                  label={t("Dates")}
                  icon={
                    <DateRangeIcon
                      style={{
                        color: theme.palette.tonique,
                      }}
                    />
                  }
                  first={true}
                />
              </Grid>
              <SearchDates
                dateMin={dateEntreeMin}
                dateMax={dateEntreeMax}
                handleChangeDate={handleChangeDateEntree}
                libelle={"Date d'entrée"}
                periodes={[
                  {
                    libelle: "",
                    montantMax: 20,
                    montantMin: 0,
                    unite: "years",
                  },
                  {
                    libelle: "<1 an",
                    montantMax: 1,
                    montantMin: 0,
                    unite: "years",
                  },
                  {
                    libelle: "1 à 2 ans",
                    montantMax: 2,
                    montantMin: 1,
                    unite: "years",
                  },
                  {
                    libelle: ">2 ans",
                    montantMax: 10,
                    montantMin: 2,
                    unite: "years",
                  },
                ]}
                t={t}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Caracteristiques
              roles={roles}
              bus={bus}
              specialites={specialites}
              onUpdateRefinements={onUpdateRefinements}
              t={t}
              classes={classes}
              _refinementsObject={_refinementsObject}
              endPointConseiller={endPointConseiller}
              formatOptionsListe={formatOptionsListe}
              telephone={telephone}
            />
          </Grid>

          <Grid item xs={12}>
            <Localisation
              refinementsObject={_refinementsObject}
              t={t}
              gridFilter={classes.gridFilter}
              onUpdateLocalisations={onUpdateLocalisations}
            />
          </Grid>
        </Grid>

        <div
          style={{
            alignItems: "center",
            backgroundColor: "white",
            borderTop: "1px solid #091722",
            display: "flex",
            height: "50px",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <NombreDeResultats
            nombreDeResultats={totalItems}
            labelPluriel={"personnes"}
            labelSingulier={"personne"}
          />
        </div>
      </div>
    </ContextualSideBar>
  );
};

export default ContextualSideBarConseillers;
