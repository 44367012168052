import { Locations, Refinements } from "components/RefinementField/types";
import { RealEstatesSearchParams } from "services/realEstates/types";
import { RealEstatesSearchResult } from "types";

import { MultipleValue } from "./../../../../../components/RefinementField/types";
import { RealEstatesCriteria } from ".";

//TODO - UPDATE
export function toRefinements(
  items: RealEstatesSearchResult,
  params: RealEstatesSearchParams,
  locations?: Locations
): Refinements<RealEstatesCriteria> {
  return {
    bus: {
      kind: "multiple-text",
      value: ExtractMultipleTextValue(
        items.aggregation?.["global_Bu"] || items.aggregation?.["Bu"],
        params.bus
      ),
    },
    constructionYear: {
      kind: "radio",
      value: (params.constructionYear || []).map((year) => ({
        count: 1,
        isRefined: true,
        label: "",
        value: year,
      })),
    },
    dateMaj: {
      kind: "date-range-options",
      value: {
        max: params.dateMajMax,
        min: params.dateMajMin,
        options: [1, 3, 6],
      },
    },
    dateMiseEnVente: {
      kind: "date-range-options",
      value: {
        max: params.dateMiseEnVenteMax,
        min: params.dateMiseEnVenteMin,
        options: [1, 3, 6],
      },
    },

    familleBiens: {
      kind: "multiple-text",
      value: ExtractMultipleTextValue(
        items.aggregation?.["global_FamilleBienEnum"] ||
          items.aggregation?.["FamilleBienEnum"],
        params.familleBiens
      ),
    },

    fullText: {
      kind: "single-text",
      value: params?.fullText,
    },

    locations: {
      kind: "locations",
      value: {
        departements: locations?.value?.departements || [],
        quartiers: locations?.value?.quartiers || [],
        regions: locations?.value?.regions || [],
        villes: locations?.value?.villes || [],
      },
    },
    nbPiecesRange: {
      kind: "multiple-text",
      value: ExtractMultipleTextValue(
        items.aggregation?.["NbPiecesRange"],
        params.nbPiecesRange
      ),
    },
    prix: {
      displayChip: true,
      kind: "number-range",
      value: {
        max: params.prixMax,
        min: params.prixMin,
        unit: "€",
      },
    },
    prixMax: {
      displayChip: false,
      kind: "price",
      value: params.prixMax,
    },
    sort: {
      kind: "sort",
      value: {
        ascending: false,
        column: "DateModification",
      },
    },
    surfaceHabitable: {
      kind: "number-range",
      value: {
        max: params.surfaceTerrainMin,
        min: params.surfaceHabitableMax,
        unit: "m²",
      },
    },
    surfaceTerrain: {
      kind: "number-range",
      value: {
        max: params.surfaceTerrainMin,
        min: params.surfaceTerrainMax,
        unit: "m²",
      },
    },
    typeAchat: {
      kind: "multiple-text",
      value: ExtractMultipleTextValue(
        items.aggregation?.["global_TypeAchatEnum"] ||
          items.aggregation?.["TypeAchatEnum"],
        params.typeAchat
      ),
    },
    typeAffaires: {
      kind: "multiple-text",
      value: ExtractMultipleTextValue(
        items.aggregation?.["global_TypeAffaireEnum"] ||
          items.aggregation?.["TypeAffaireEnum"],
        params.typeAffaires
      ),
    },
    withElevator: {
      displayedValue: "Ascenseur",
      kind: "checkbox",
      value: params.withElevator,
    },
    withNotes: {
      displayedValue: "Avec notes",
      kind: "checkbox",
      value: params.withNotes,
    },
    withParking: {
      displayedValue: "Stationnement",
      kind: "checkbox",
      value: params.withParking,
    },
    withPool: {
      displayedValue: "Piscine",
      kind: "checkbox",
      value: params.withPool,
    },
    withSingleStorey: {
      displayedValue: "Plain-pied",
      kind: "checkbox",
      value: params.withSingleStorey,
    },
  };
}

function ExtractMultipleTextValue(
  aggregation?: { key: string; value: number }[],
  params?: string[],
  label: (key: string) => string = (_) => _
) {
  const result = [];
  const keys: Record<string, boolean> = {};
  for (const { key, value } of aggregation || []) {
    result.push({
      count: value,
      isRefined: params?.includes(key) || false,
      label: label(key),
      value: key,
    });
    keys[key] = true;
  }

  const emptyParams = (params || [])
    .filter((_) => !keys[_])
    .map((key) => ({
      count: 0,
      isRefined: true,
      label: label(key),
      value: key,
    }));

  return result.concat(emptyParams);
}
