import { Grid, Paper, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddProfileIcon from "@material-ui/icons/HowToRegOutlined";
import { makeStyles } from "@material-ui/styles";
import React from "react";

import { useFeatureFlags } from "../../../../../../services/featureFlags";
import { ITheme } from "../../../../../../theme/ts/main";

export const useStyles = makeStyles((theme: ITheme) => ({
  notchedOutlineNotRequired: {
    borderColor: "inherit",
    borderWidth: "inherit",
  },
  notchedOutlineRequired: {
    borderColor: "#303d4a",
    borderWidth: 2,
  },
  profil: {
    backgroundColor: theme.palette.common.light,
    minHeight: "310px",
    padding: 16,
    width: "325px",
  },
}));

interface ProfilIncentiveProps {
  onOpenFormulaireSimple: {
    buyerSimpleForm: () => void;
    sellerSimpleForm: () => void;
  };
}

export const ProfilesIncentive = ({
  onOpenFormulaireSimple: { buyerSimpleForm, sellerSimpleForm },
}: ProfilIncentiveProps) => {
  const sellerProfileFlag = useFeatureFlags()?.Seller_profile;
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6} lg={4} xl={3}>
      <Paper elevation={1} className={classes.profil}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <AddProfileIcon style={{ fontSize: "60px", marginBottom: "20px" }} />
          <Typography variant={"h2"}>
            Créer un profil pour ce contact
          </Typography>
          <Typography variant={"h2"}>et lancer les rapprochements</Typography>
          {sellerProfileFlag && (
            <Button
              style={{ marginTop: "20px" }}
              variant="contained"
              size="large"
              color="primary"
              onClick={sellerSimpleForm}
            >
              Créer un profil vendeur
            </Button>
          )}
          <Button
            style={{ marginTop: "20px" }}
            variant="contained"
            size="large"
            color="primary"
            onClick={buyerSimpleForm}
          >
            Créer un profil acquereur
          </Button>
        </div>
      </Paper>
    </Grid>
  );
};
