import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import CustomSelect from "./CustomSelect";

export default function RangeSelection({ onChange, refinement, style }) {
  // const classes = useStyles();

  const handleChange = (item, type) => {
    const { ...localRefinement } = refinement;
    if (type === "min") localRefinement.limiteInf = item.value;
    // else localRefinement.limiteSup = localRefinement.maxIsNoLimit && options.values[options.values.length - 1].value === evt.target.value ? null : evt.target.value;
    else localRefinement.limiteSup = item.value;

    onChange(localRefinement, refinement.query);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs>
        <CustomSelect
          style={style}
          label={refinement.options.labelMin}
          refinement={refinement}
          onChange={(item) => handleChange(item, "min")}
        />
      </Grid>
      <Grid item xs>
        <CustomSelect
          style={style}
          label={refinement.options.labelMax}
          refinement={refinement}
          onChange={(item) => handleChange(item, "max")}
        />
      </Grid>
    </Grid>
  );
}
RangeSelection.propTypes = {
  onChange: PropTypes.func,
  refinement: PropTypes.object.isRequired,
  style: PropTypes.object,
};
