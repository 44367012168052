import { Grid, TextField, Typography } from "@material-ui/core";
import AutoCompleteAddress, {
  AutocompletePlace,
} from "components/AutoCompleteAddress";
import SingleCitySelect from "components/SingleCitySelect";
import React, { FC, useEffect } from "react";
import { useServices } from "services";

import { ProfileAddressDto } from "../../models/profileAddressDto";
import { SectionTitle } from "../components/SectionTitle";
import useStyles from "../components/styles";
import { ProfileInputProps } from "../InputProps";

export interface Place {
  codeInsee: string;
  codesPostaux: string[];
  id: string;
  latitude: number;
  longitude: number;
  nom: string;
  address: string;
  polygone: string;
}

export const SalePlace: FC<{ place: ProfileAddressDto }> = ({ place }) => {
  return (
    <>
      <Typography variant={"body1"}>{place.street}</Typography>
      <div
        key={place.city?.id}
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Typography
          variant="h5"
          style={{
            color:
              place.city?.id === "00000000-0000-0000-0000-000000000000"
                ? "red"
                : "inherit",
          }}
        >
          {place.city?.name}
        </Typography>
        {place.city?.id === "00000000-0000-0000-0000-000000000000" && (
          <Typography variant="body1" style={{ color: "red" }}>
            cette ville saisie à partir de Tremplin n&apos;est plus valide,
            veuillez la corriger.
          </Typography>
        )}
        {place.city?.postalCodes.map((postalCode: string, index: number) => (
          <Typography key={index} variant="body1">
            &nbsp;({postalCode})
          </Typography>
        ))}
      </div>
    </>
  );
};

export const SalePlaceInput: FC<ProfileInputProps<ProfileAddressDto>> = ({
  disabled,
  onChange,
  value,
  pristine,
}) => {
  const classes = useStyles();
  const locationValue: Place = {
    address: "",
    codeInsee: value.city?.inseeCode || "",
    codesPostaux: value.city?.postalCodes || [],
    id: value.city?.id || "",
    latitude: 0,
    longitude: 0,
    nom: value.city?.name || "",
    polygone: "",
  };

  const selectedAddress: AutocompletePlace = {
    adresse: locationValue.address,
    codePostal: locationValue.codesPostaux[0],
    label: locationValue.nom,
    value: locationValue.codeInsee,
    ville: {
      codeInsee: locationValue.codeInsee,
      nom: locationValue.nom,
    },
  };

  const isValid = locationValue.id !== "";

  useEffect(() => {
    onChange(value, isValid);
  }, [isValid]);

  const {
    l10n: { t },
  } = useServices();

  const onLocationChange = (location: Place) => {
    onChange(
      {
        city: {
          id: location.id,
          inseeCode: location.codeInsee,
          name: location.nom,
          postalCodes: location.codesPostaux,
        },
        name: location.nom,
        street: location.address,
      },
      isValid
    );
  };

  const onAddressChange = (address: { address: string }) => {
    onLocationChange({
      ...locationValue,
      address: address.address,
    });
  };

  return (
    <Grid container>
      <SectionTitle title="Où se situe le bien ?" />
      <Typography
        variant={"h6"}
        style={{
          fontWeight: "normal",
          paddingBottom: 5,
          paddingTop: 15,
          width: "100%",
        }}
      >
        Commencez par saisir une ville parmi le référentiel officiel de
        l&apos;Etat :
      </Typography>
      <Grid item xs={12}>
        <SingleCitySelect
          villeChoisie={
            locationValue.nom === ""
              ? {
                  codesPostaux: [],
                  id: "",
                  ville: "",
                }
              : {
                  codesPostaux: locationValue.codesPostaux,
                  id: locationValue.id,
                  ville: locationValue.nom,
                }
          }
          setVille={(value: any) => {
            onLocationChange(value);
          }}
          isClearable={true}
          disabled={disabled}
          required={true}
        />
        <Typography
          variant={"h6"}
          style={{
            color: !pristine && !isValid ? "red" : "rgba(0, 0, 0, 0.54)",
            fontWeight: "normal",
            marginLeft: 14,
            paddingBottom: 15,
            width: "100%",
          }}
        >
          {"*Champ obligatoire"}
        </Typography>
      </Grid>
      {selectedAddress.ville.codeInsee !== "" && (
        <Grid container>
          <Grid item xs={12}>
            <AutoCompleteAddress
              selectedAddress={selectedAddress}
              setSelectedAddress={onAddressChange}
              disabled={value.city?.inseeCode === ""}
              isClearable={true}
            />
          </Grid>
        </Grid>
      )}
      {
        <>
          <Grid item xs={12}>
            <Typography
              variant={"h6"}
              style={{
                fontWeight: "normal",
                paddingBottom: 5,
                paddingTop: 15,
                width: "100%",
              }}
            >
              Si l&apos;adresse complète n&apos;est pas encore référencée par
              l&apos;Etat, vous pouvez la modifier manuellement :
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{ shrink: true }}
              id="adresse"
              name="adresse"
              label={t("Adresse : saisie manuelle")}
              className={classes.textField}
              value={value.street || ""}
              onChange={(e) =>
                onAddressChange({ address: e.target.value.toString() })
              }
              margin="normal"
              variant="outlined"
            />
          </Grid>
        </>
      }
    </Grid>
  );
};
