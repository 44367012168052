import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { ITheme } from "theme/ts/main";

export interface ChipSelectProps {
  label: string;
  value: string;
  isChecked: boolean;
  onClick: () => void;
  disabled: boolean;
}

const useStyles = makeStyles((theme: ITheme) => ({
  checked: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  common: {
    borderRadius: "20px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    padding: 5,
  },
  disabled: { cursor: "inherit" },
  notChecked: {
    backgroundColor: theme.palette.common.main,
    color: theme.palette.primary.main,
  },
}));

export const ChipSelect: React.FC<ChipSelectProps> = ({
  disabled,
  isChecked,
  label,
  onClick,
}) => {
  const classes = useStyles();

  if (disabled) {
    return (
      <div
        className={
          isChecked
            ? `${classes.common} ${classes.checked} ${classes.disabled}`
            : `${classes.common} ${classes.notChecked}  ${classes.disabled}`
        }
      >
        <Typography variant={"h6"}>{label}</Typography>
      </div>
    );
  }

  return (
    <div
      onClick={onClick}
      className={
        isChecked
          ? `${classes.common} ${classes.checked}`
          : `${classes.common} ${classes.notChecked}`
      }
    >
      <Typography variant={"h6"}>{label}</Typography>
    </div>
  );
};
