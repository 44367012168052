import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  DataProvider,
  DataProviderContext,
  REFINEMENT_TYPE,
} from "components/DataProvider";
import Loading from "components/Loading/LoadingSkeleton";
import TitrePage from "components/TitrePage";
import echarts from "echarts";
import React, { useContext, useEffect, useState } from "react";
import { useServices } from "services";
import { ReferentielContext } from "services/reporting/Referentiel";
import { ErrorPanel } from "UI/shared/templates/Panels/ErrorPanel";
import DateRangeSubtitle from "../components/DateRangeSubtitle";
import GraphRadar from "./components/charts/RadarBiens";
import GraphRepartionBiens from "./components/charts/RepartitionBiens";
import { getOptionPieActifs, getOptionPieVendus } from "./logic/chartsOptions";

const useStyles = makeStyles((theme) => ({
  root: {
    // ...theme.mixins.gutters(),
  },
}));

const Biens = () => {
  const { endpoints } = useServices();
  const referentiel = useContext(ReferentielContext);

  let initialRefinements = false;

  if (Object.keys(referentiel).length > 0) {
    initialRefinements = {
      ...referentiel,
      dateEvt: {
        count: 0,
        labelLimiteInf: "dateDebut",
        labelLimiteSup: "dateFin",
        limiteInf: new Date(new Date().getFullYear(), 0, 1),
        limiteSup: new Date(),
        query: "dateAffaire",
        type: REFINEMENT_TYPE.range.date,
      },
    };
  } else
    return (
      <ErrorPanel
        title={"Données non disponibles."}
        text={"Nous n'avons pas reçu les données statistiques."}
      />
    );

  return (
    <div>
      {initialRefinements && (
        <DataProvider
          refinements={JSON.stringify(initialRefinements)}
          url={endpoints.reporting.bien}
        >
          <BiensComp />
        </DataProvider>
      )}
    </div>
  );
};

export default Biens;

const chartsStyle = { height: "300px", width: "100%" };

const BiensComp = () => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();
  const { providerData, components } = useContext(DataProviderContext);
  const [typesDeBiens, setTypesDeBiens] = useState(false);

  const theme = useTheme();

  // séléection du sous-niveau à présenter lors du clic sur une portion
  // du graphique "Pie"
  const sousFamilles = (params) => {
    const selectionBiensActifs = providerData.repartition.actifs.find(
      (item) => t(item.famille) === params.name
    );
    const biensActifs = selectionBiensActifs
      ? selectionBiensActifs.typesDeBien.map((item) => ({
          famille: item.type,
          nombre: item.nombre,
        }))
      : [{ famille: "rien", nombre: 1 }];
    const selectionBiensVendus = providerData.repartition.vendus.find(
      (item) => t(item.famille) === params.name
    );
    const biensVendus = selectionBiensVendus
      ? selectionBiensVendus.typesDeBien.map((item) => ({
          famille: item.type,
          nombre: item.nombre,
        }))
      : [];
    return { actifs: biensActifs, vendus: biensVendus };
  };

  // fonctions appelées par les graphiques pour exportation image
  // et Reset des données
  const restore = (params) => {
    setTypesDeBiens(false);
  };

  const pieSelected = (params) => {
    if (typesDeBiens) {
      setTypesDeBiens(false);
    } else {
      setTypesDeBiens(params);
    }
  };

  useEffect(() => {
    function showPies() {
      if (providerData && !providerData.error) {
        const pieActifs = echarts.init(document.getElementById("pie_actifs"));
        const pieVendus = echarts.init(document.getElementById("pie_vendus"));

        // specify chart configuration item and data
        const option = getOptionPieActifs(
          t,
          theme,
          typesDeBiens ? sousFamilles(typesDeBiens) : providerData.repartition
        );

        const optionVendus = getOptionPieVendus(
          t,
          theme,
          typesDeBiens ? sousFamilles(typesDeBiens) : providerData.repartition
        );

        // use configuration item and data specified to show chart
        pieActifs.setOption(option);
        pieActifs.on("pieselectchanged", pieSelected);
        pieActifs.on("restore", restore);
        pieActifs.resize();

        pieVendus.setOption(optionVendus);
        pieVendus.on("pieselectchanged", pieSelected);
        pieVendus.on("restore", restore);
        pieVendus.resize();
      }
    }

    window.addEventListener("resize", showPies);
    showPies();
    return () => window.removeEventListener("resize", showPies);
    // eslint-disable-next-line
  }, [providerData, typesDeBiens]);

  if (providerData && providerData.error)
    return (
      <ErrorPanel
        title={"Données non disponibles."}
        text={"Nous n'avons pas reçu les données statistiques."}
      />
    );

  return (
    <div className={classes.root}>
      <TitrePage
        categorie="Reporting"
        page={t("Biens")}
        filePath={"/static/markdowns/Reporting/reportingBiens.md"}
        Composant={() =>
          components.dateRange({
            subtitle: (date) => <DateRangeSubtitle date={date} />,
          })
        }
      />
      <div className={classes.searchBarFilters}>
        {components.searchBar({ autoUpdate: 1 })}
      </div>
      {providerData && !providerData.error ? (
        <GraphRepartionBiens
          theme={theme}
          pieSelected={pieSelected}
          // restore={restore}
          data={
            typesDeBiens ? sousFamilles(typesDeBiens) : providerData.repartition
          }
          chartsStyle={chartsStyle}
        />
      ) : (
        <Loading styles={{ height: 332 }} />
      )}
      <div style={{ height: 16 }}>&nbsp;</div>
      {providerData && !providerData.error ? (
        <GraphRadar
          theme={theme}
          data={providerData.radar}
          chartsStyle={chartsStyle}
        />
      ) : (
        <Loading styles={{ height: 332 }} />
      )}
    </div>
  );
};
