import { Grid } from "@material-ui/core";
import PaginationX from "components/PaginationX";
import React from "react";
import { breakpoint } from "theme/main";
import NetworkMatchingAcquirer from "UI/features/profiles/molecules/MatchingAcquirer/Network";

const NetworkBuyers = ({
  buyers,
  currentPageNumber,
  totalPage,
  handleNetworkBuyersChange,
}) => {
  const conseiller = (info) => {
    return {
      nom: info.agentFirstName,
      prenom: info.agentLastName,
      telephone: info.agentTelephone,
      email: info.agentEmail,
      photo: info.agentPhoto,
      estActif: info.isActive,
    };
  };

  return (
    <Grid container spacing={2}>
      {buyers.map(
        ({
          lastModificationDate,
          profileReference,
          bu,
          agentFirstName,
          agentLastName,
          agentTelephone,
          agentEmail,
          agentPhoto,
          isActive,
        }) => (
          <Grid key={profileReference} item xs={12} md={6}>
            <NetworkMatchingAcquirer
              reference={profileReference}
              lastModificationDate={lastModificationDate}
              agent={conseiller({
                agentFirstName,
                agentLastName,
                agentTelephone,
                agentEmail,
                agentPhoto,
                isActive,
              })}
              businessUnit={bu}
            />
          </Grid>
        )
      )}

      <Grid item xs={12}>
        <div style={{ marginTop: 20 }}>
          <PaginationX
            page={currentPageNumber || 1}
            nbPages={totalPage || 1}
            onChange={handleNetworkBuyersChange}
            maxOnScreen={breakpoint().xs ? 1 : breakpoint().smAndDown ? 5 : 7}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default NetworkBuyers;
