import { makeStyles, Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

export const PropertyTypeCaption: React.FC<{ type: string }> = ({ type }) => {
  const useStyles = makeStyles((theme: Theme) => ({
    propertyForSaleType: {
      color: theme.palette.grey[500],
    },
  }));

  const classes = useStyles();
  return (
    <Typography variant="body1" className={classes.propertyForSaleType}>
      {type}
    </Typography>
  );
};
