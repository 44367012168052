import { Grid, useTheme } from "@material-ui/core";
import {
  AssignmentOutlined,
  ImportantDevicesOutlined,
} from "@material-ui/icons";
import DateRangeIcon from "@material-ui/icons/DateRangeOutlined";
import LocationIcon from "@material-ui/icons/LocationOnOutlined";
import {
  AdaptedRefinements,
  Refinement,
} from "components/RefinementField/types";
import React from "react";
import { useServices } from "services";
import { ContactsCriteria } from "services/portfolio/types";
import { ITheme } from "theme/ts/main";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";
import { WithToniqueColor } from "UI/shared/atoms/WithToniqueColor";
import { SwipeableContainer } from "UI/shared/molecules/SwipeableContainer/SwipeableContainer";
import { TitleWithIcon } from "UI/shared/molecules/TitleWithIcon";
import { MoreFiltersFooter } from "./MoreFiltersFooter";
import { MoreFiltersSection } from "./MoreFiltersSection";

export interface MoreFiltersProps {
  adaptedRefinements: AdaptedRefinements<ContactsCriteria>;
  searchResultCount: number;
  onChange: (value: { key: ContactsCriteria; value: Refinement }) => void;
}

export const MoreFilters: React.FC<MoreFiltersProps> = ({
  searchResultCount,
  adaptedRefinements,
  onChange,
}) => {
  const {
    l10n: { t },
  } = useServices();

  const theme = useTheme() as ITheme;
  return (
    <SwipeableContainer
      anchor="right"
      width={400}
      headerTitle={"Recherche détaillée"}
      borderColor={theme.palette.tonique}
    >
      <div style={{ display: "flex", height: "100%" }}>
        <Grid item xs={12}>
          <PaddingContainer bottom={10} top={10}>
            <TitleWithIcon
              icon={WithToniqueColor(ImportantDevicesOutlined)}
              title={"Affichage"}
            />
            <MoreFiltersSection
              sectionKey={"display"}
              adaptedRefinements={adaptedRefinements}
              onChange={onChange}
            />
          </PaddingContainer>

          <PaddingContainer bottom={10}>
            <TitleWithIcon
              icon={WithToniqueColor(DateRangeIcon)}
              title={"Dates"}
            />
            <MoreFiltersSection
              sectionKey={"dates"}
              adaptedRefinements={adaptedRefinements}
              onChange={onChange}
            />
          </PaddingContainer>

          <PaddingContainer bottom={10} top={10}>
            <TitleWithIcon
              icon={WithToniqueColor(AssignmentOutlined)}
              title={"Caractéristiques"}
            />
            <MoreFiltersSection
              sectionKey={"characteristics"}
              adaptedRefinements={adaptedRefinements}
              onChange={onChange}
            />
          </PaddingContainer>

          <PaddingContainer bottom={10} top={10}>
            <TitleWithIcon
              icon={WithToniqueColor(LocationIcon)}
              title={"Lieu(x)"}
            />
            <MoreFiltersSection
              sectionKey={"localisations"}
              adaptedRefinements={adaptedRefinements}
              onChange={onChange}
            />
          </PaddingContainer>
        </Grid>
      </div>

      <MoreFiltersFooter
        entityName={t(
          `Portfolio.EntityName.${
            searchResultCount <= 1 ? "singular" : "plural"
          }`
        )}
        total={searchResultCount}
        onClose={() => {
          return;
        }}
      />
    </SwipeableContainer>
  );
};
