import React, { useEffect } from "react";
import { mapBienToMatchingProfileRequest } from "services/matching/buildingToMatchingRequest";
import { useAcquirerProfilesMatching } from "services/matching/useAcquirerProfilesMatching";

const BienAvecAcquereurs = (props) => {
  const { Component, hit } = props;
  const [numberOfBuyers, setNumberOfBuyers] = React.useState(null);
  const { getCount } = useAcquirerProfilesMatching();

  useEffect(() => {
    let cancelRequest = false;

    getCount(mapBienToMatchingProfileRequest(hit)).then((response) => {
      if (!cancelRequest) {
        setNumberOfBuyers(response ? response : 0);
      }
    });

    return () => {
      cancelRequest = true;
    };
  }, [hit.id]);

  return <Component {...props} numberOfBuyers={numberOfBuyers} />;
};

export default BienAvecAcquereurs;
