import ElasticAdapter from "api/DTOs/Common/ElasticSearch/ElasticAdapter";
import { number, string } from "api/DTOs/Common/Types";

function RechercheCarteAdapter(result) {
  if (!result) return [];
  const elasticResult = ElasticAdapter.toBO(result);
  elasticResult.data = elasticResult.data.map((item) => itemToBO(item));
  return elasticResult;
}

function itemToBO(item = {}) {
  return {
    bu: item.bu || string,
    id: item.id || string,
    lat: item.lat || number,
    long: item.long || number,
    prix: item.prix || number,
    prixNormalized: item.prixNormalized || number,
  };
}

export default RechercheCarteAdapter;
