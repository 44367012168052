import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CustomRefinementList from "./components/CustomRefinementList";
import CustomMenu from "./components/CustomMenu";
import { REFINEMENT_TYPE } from "../../index";
import Typography from "@material-ui/core/Typography";
import CustomSlider from "./components/CustomSlider";
import RangeSelection from "./components/RangeSelection";
import CustomSearchBox from "./components/CustomSearchBox";
import SelectX from "./components/CustomSelect";
import PropTypes from "prop-types";
import RangeTextfield from "./components/RangeTextfield";
import ListeCriteres from "components/ListeCriteres";

const useStyles = makeStyles((theme) => ({
  "@media (max-width: 768px)": {
    searchBarFilters: {
      maxHeight: "80%",
    },
    title: {
      fontSize: "1.2rem",
    },
  },
  button: {
    color: theme.palette.secondaryLighter,
  },
  containerBelow: {
    height: "100vh",
    left: "0px",
    position: "absolute",
    top: "0px",
    width: "100%",
    zIndex: "0",
  },
  searchBar: {
    backgroundColor: "transparent",
  },
  searchBarFilters: {
    width: "100%",
    zIndex: "10",
  },
}));

const excludedKey = ["dateEvt", "fullTextSearch"];
const excludeRefinements = (key) => !excludedKey.includes(key);

// let timeout = null;

const SearchBar = ({
  refinements,
  setRefinements,
  fullTextSearch,
  clearRefinements,
}) => {
  const classes = useStyles();

  let localRefinements = refinements;
  const handleRefinementsChange = (query, values) => {
    const refinement = refinements[query];
    refinement.values = values;
    setRefinements(refinement, query);
  };

  return (
    <div className={classes.searchBar}>
      <Grid alignItems={"flex-end"} container spacing={0}>
        {fullTextSearch && (
          <Grid item xs={"auto"}>
            <CustomSearchBox
              refinement={localRefinements.fullTextSearch}
              onChange={setRefinements}
              options={{
                ...localRefinements.fullTextSearch.options,
                style: {},
              }}
              autoUpdate={0}
            />
          </Grid>
        )}
      </Grid>
      {Object.keys(localRefinements).length > 0 && (
        <Grid container spacing={1}>
          {Object.keys(localRefinements)
            .filter(excludeRefinements)
            .map((key) => (
              <Grid key={key} item xs={12} sm={6} md={2}>
                {localRefinements[key].type ===
                  REFINEMENT_TYPE.multipleSelection.checkbox && (
                  <CustomMenu
                    title={localRefinements[key].title}
                    attribute={localRefinements[key]}
                  >
                    <CustomRefinementList
                      title=""
                      attribute={localRefinements[key]}
                      type={key}
                      setAttribute={setRefinements}
                    />
                  </CustomMenu>
                )}
                {localRefinements[key].type ===
                  REFINEMENT_TYPE.multipleSelection.chips && (
                  <SelectX
                    style={{ width: "100%" }}
                    refinement={localRefinements[key]}
                    onChange={setRefinements}
                    options={localRefinements[key].options}
                    label={localRefinements[key].options.title}
                  />
                )}
                {localRefinements[key].type ===
                  REFINEMENT_TYPE.range.slider && (
                  <>
                    <Typography gutterBottom>
                      {localRefinements[key].title}
                    </Typography>
                    <CustomSlider
                      refinement={localRefinements[key]}
                      sliderOptions={localRefinements[key].sliderOptions}
                      onChange={setRefinements}
                    />
                  </>
                )}
                {localRefinements[key].type ===
                  REFINEMENT_TYPE.range.select && (
                  <RangeSelection
                    style={{ width: "100%" }}
                    refinement={localRefinements[key]}
                    onChange={setRefinements}
                  />
                )}
                {localRefinements[key].type ===
                  REFINEMENT_TYPE.range.textfield && (
                  <RangeTextfield
                    refinement={localRefinements[key]}
                    onChange={setRefinements}
                    options={{
                      ...localRefinements[key].options,
                      width: "100%",
                    }}
                  />
                )}
              </Grid>
            ))}
          <Grid item xs={12}>
            <ListeCriteres
              items={localRefinements}
              clearRefinements={clearRefinements}
              onChange={handleRefinementsChange}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

SearchBar.propTypes = {
  clear: PropTypes.func,
  refinements: PropTypes.object.isRequired,
  setRefinements: PropTypes.func,
};
export default React.memo(SearchBar);
