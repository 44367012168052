import {
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React from "react";

export interface MenuOptionProps {
  icon: React.ReactNode;
  label: string;
}

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    padding: "0px 10px",
  },
  inlinediv: {
    display: "inline-flex",
    alignItems: "center",
  },
}));

export const MenuOption: React.FC<MenuOptionProps> = ({ icon, label }) => {
  const responsiveMobile = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();

  return (
    <div>
      {responsiveMobile ? (
        <Tooltip title={label} placement="left">
          <IconButton className={classes.container}>{icon}</IconButton>
        </Tooltip>
      ) : (
        <div className={classes.inlinediv}>
          <IconButton className={classes.container}>{icon}</IconButton>
          <Typography variant="body1">{label}</Typography>
          <div style={{ width: "10px" }} />
        </div>
      )}
    </div>
  );
};
