import { deserialize } from "layoutContents/components/HitsContainer/utils";

export const handleRefinementsFromParameters = (
  params,
  mode,
  worldBoundingBox,
  Refinements,
  dispatch,
  SNAPSHOT_KEY
) => {
  try {
    const parameters = deserialize(params);
    const newRefinements = Refinements;
    newRefinements.fromURLParams(parameters.refinements);
    dispatch({
      payload: {
        boundingBox: parameters.boundingBox || worldBoundingBox,
        displayMode: mode,
        refinements: newRefinements,
      },
      type: "initialiseAvecParametres",
    });
    // restauration du mode précédent en retour d'une fiche conseiller seulement sur mode map
    if (mode !== "map") sessionStorage.removeItem(SNAPSHOT_KEY);
    if (sessionStorage.getItem(SNAPSHOT_KEY) !== null) {
      const savedState = deserialize(sessionStorage.getItem(SNAPSHOT_KEY));
      const newState = {
        ...savedState,
        refinements: Refinements,
      };
      newState.refinements.fromObject(savedState.refinements);
      sessionStorage.removeItem(SNAPSHOT_KEY);
      dispatch({ payload: newState, type: "restoreSavedState" });
    }
  } catch (e) {
    if (sessionStorage.getItem(SNAPSHOT_KEY) !== null) {
      const savedState = deserialize(sessionStorage.getItem(SNAPSHOT_KEY));
      const newState = {
        ...savedState,
        refinements: Refinements,
      };
      newState.refinements.fromObject(savedState.refinements);
      sessionStorage.removeItem(SNAPSHOT_KEY);
      dispatch({ payload: newState, type: "restoreSavedState" });
    }
  }
};
