import Grid from "@material-ui/core/Grid";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import React from "react";
import { TO_BE_TREATED } from "services/request-for-informations/constants/request-for-informations-status";
import { CardPaperItem } from "UI/shared/atoms/containers/CardPaperItem";
import { Column } from "UI/shared/atoms/containers/Column";
import { LastUpdate } from "UI/shared/atoms/LastUpdate";
import { NameInformation } from "UI/shared/atoms/NameInformation";
import { ReceptionDate } from "UI/shared/atoms/ReceptionDate";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import { BusinessTypeCaption } from "UI/shared/molecules/BusinessTypeCaption";
import { EmailLink } from "UI/shared/molecules/EmailLink";
import { PhoneLink } from "UI/shared/molecules/PhoneLink";
import { PriceCaption } from "UI/shared/molecules/PriceCaption";
import { PropertyTypeCaption } from "UI/shared/molecules/PropertyTypeCaption";
import { Source } from "UI/shared/molecules/Source";
import { Status } from "UI/shared/molecules/Status";
import { ActionsMenu } from "UI/shared/organisms/ActionsMenu";
import { LocationCaption } from "UI/shared/organisms/LocationCaption";
import { SurfaceBedroomsNbRoomsNbCaption } from "UI/shared/organisms/SurfaceBedroomsNbRoomsNbCaption";
import { RequestForInformationItemProps } from "UI/features/requestForInformations/interfaces/RequestForInformationItemProps";

export const MediumRequestForInformationItem: React.FC<RequestForInformationItemProps> = React.memo(
  ({ requestForInformation, actions, clickHandler }) => {
    return (
      <CardPaperItem key={requestForInformation.id} clickHandler={clickHandler}>
        <Grid item md={4}>
          <Column columnJustify="flex-start">
            <BusinessTypeCaption
              type={requestForInformation.propertyForSale.businessType}
            />
            <PropertyTypeCaption
              type={requestForInformation.propertyForSale.type}
            />
            <LocationCaption
              city={requestForInformation.propertyForSale.city}
              zipcode={requestForInformation.propertyForSale.zipcode}
            />
            <PriceCaption price={requestForInformation.propertyForSale.price} />
            <SurfaceBedroomsNbRoomsNbCaption
              livingArea={requestForInformation.propertyForSale.livingArea}
              bedRoomsNumber={
                requestForInformation.propertyForSale.bedRoomsNumber
              }
              roomsNumber={requestForInformation.propertyForSale.roomsNumber}
            />
          </Column>
        </Grid>
        <Grid item md={4}>
          <Column columnJustify="flex-start" itemJustify="flex-start">
            <Source title={requestForInformation.source} bgColor={"#BFB7B7"} />
            {requestForInformation.status === TO_BE_TREATED && (
              <ReceptionDate
                date={new Date(requestForInformation.receivedAt)}
              />
            )}
            {requestForInformation.status !== TO_BE_TREATED && (
              <LastUpdate date={new Date(requestForInformation.updatedAt)} />
            )}
            <VerticalSpace size={5} />
            <div>
              <NameInformation value={requestForInformation.emailSender} />
              <PhoneLink
                value={
                  parsePhoneNumberFromString(
                    requestForInformation.client.phoneNumber || ""
                  )?.formatInternational() || ""
                }
              />
              <EmailLink value={requestForInformation.client.email} />
            </div>
          </Column>
        </Grid>
        <Grid item md={2}>
          <Column columnJustify="flex-start">
            <Status status={requestForInformation.status} />
          </Column>
        </Grid>
        <Grid item md={2}>
          <Column columnJustify="flex-end" itemJustify="flex-end">
            <ActionsMenu menuOptions={actions} minToCollapse={0} />
          </Column>
        </Grid>
      </CardPaperItem>
    );
  },
  (prev, next) =>
    next.requestForInformation.id === prev.requestForInformation.id &&
    next.requestForInformation.status === prev.requestForInformation.status
);
