import { Grid, Typography } from "@material-ui/core";
import React from "react";

import TitreSection from "../../../../../../../../layoutContents/Fiches/components/Details/components/TitreSection";

const RapprochementBien = ({ conseiller }) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <TitreSection titre={"rapprochement bien"} />
    </Grid>
    <Grid item xs={12}>
      <Typography variant={"h6"} style={{ fontWeight: "normal" }}>
        paramètres divers
      </Typography>
    </Grid>
  </Grid>
);

export default RapprochementBien;
