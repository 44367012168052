import React from "react";
import Grid from "@material-ui/core/Grid";

export const SpaceBetweenContainer: React.FC = ({ children }) => (
  <Grid container spacing={2} alignItems="center" justify="space-between">
    {React.Children.map(children, (el) => {
      return <Grid item>{el}</Grid>;
    })}
  </Grid>
);
