import { Grid } from "@material-ui/core";
import React, { FC } from "react";
import { ProfileReadDto } from "routes/app/contacts/contact/components/models/profilesReadDto";
import { PurchaseParkingCriteriaDto } from "routes/app/contacts/contact/components/models/Search/purchaseParkingCriteriaDto";
import { PurchaseParkingProfileReadDto } from "routes/app/contacts/contact/components/models/Search/purchaseParkingProfileReadDto";
import { ProfilePlaces } from "UI/features/profiles/organisms/ProfilePlaces";
import { ProfileProject } from "UI/features/profiles/organisms/ProfileProject";
import { ProfileStatusCard } from "UI/features/profiles/organisms/ProfileStatusCard";
import { MoreInfos } from "UI/features/profiles/organisms/Search/MoreInfos";
import { useServices } from "services";
import { L10nService } from "services/l10n";

import { PurchaseBudget } from "../../../../../organisms/Search/PurchaseBudget";

export interface SearchPurchaseParkingProfileDetailsProps {
  readProfile: ProfileReadDto;
}
export const SearchPurchaseParkingProfileDetails: FC<SearchPurchaseParkingProfileDetailsProps> = ({
  readProfile,
}) => {
  const { l10n } = useServices();
  const profile = readProfile as PurchaseParkingProfileReadDto;
  const moreInformations = more(l10n, profile.criteria);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={4}>
        <ProfileStatusCard
          status={profile.archivingDate ? "Archived" : "Active"}
          updateDate={profile.archivingDate || profile.lastModificationDate}
          creationDate={profile.creationDate || profile.lastModificationDate}
          maturity={profile.criteria.maturity}
          origin={profile.origin}
          reference={profile.reference}
        />
        <ProfileProject
          profileAccount={profile.account}
          businessType={"Achat"}
          propertyCategory={"Parking"}
        />
        <PurchaseBudget budget={profile.criteria.budget} />
      </Grid>
      <Grid item xs={12} lg={8}>
        {profile.criteria.places && (
          <ProfilePlaces places={profile.criteria.places} />
        )}
        <MoreInfos
          title={"Informations supplémentaires"}
          value={moreInformations}
        />
      </Grid>
    </Grid>
  );
};

function more(
  { t, formatSurfacesRange }: L10nService,
  criteria: PurchaseParkingCriteriaDto
) {
  return [
    {
      label: t("Surface"),
      value: formatSurfacesRange(criteria.homeSize.min, criteria.homeSize.max),
    },
  ];
}
