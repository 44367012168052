import Grid from "@material-ui/core/Grid";
import { SourcePictography } from "UI/shared/atoms/SourcePictography";
import { SourceTitle } from "UI/shared/atoms/SourceTitle";
import React from "react";

export interface SourceProps {
  title: string;
  bgColor: string;
}

export const Source: React.FC<SourceProps> = ({ title, bgColor }) => (
  <Grid container direction="row" alignItems="center">
    <SourcePictography bgColor={bgColor} />
    <SourceTitle title={title} />
  </Grid>
);
