import { Feature, Point } from "geojson";
import { useEffect, useState } from "react";

import { useNoAuthHttp } from "../../http";

const ENDPOINT = "https://api-adresse.data.gouv.fr/search/?q=";
const RESULTS_LIMIT = "&limit=10";

type Status = "pending" | "resolved" | "rejected";

interface ResponseFrGouvGeocoding {
  attribution: string;
  features: CustomFeature[];
  licence: string;
  limit: number;
  query: string;
  type: string;
  version: string;
}

interface CustomGeometry extends Point {
  coordinates: number[];
}

interface CustomFeature extends Feature {
  geometry: CustomGeometry;
}

export const useFrGouvGeocodingService = ({
  addressString,
  cityCode,
}: {
  addressString: string;
  cityCode?: string;
}) => {
  const [foundAddresses, setFoundAddresses] = useState<CustomFeature[]>([]);
  const [position, setPosition] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  });
  const [status, setStatus] = useState<Status>("pending");
  const { get } = useNoAuthHttp();

  useEffect(() => {
    const _cityCode = cityCode ? `&citycode=${cityCode}` : "";
    const finalQuery = `${ENDPOINT}${addressString}${_cityCode}${RESULTS_LIMIT}`;
    if (addressString.length > 2) {
      setStatus("pending");
      if (addressString.length > 2) {
        get<ResponseFrGouvGeocoding>(finalQuery)
          .then((response) => {
            setStatus("resolved");
            if (response.data.features) {
              setFoundAddresses(response.data.features);
              if (response.data.features.length > 0) {
                setPosition({
                  lat: response.data.features[0].geometry.coordinates[1],
                  lng: response.data.features[0].geometry.coordinates[0],
                });
              }
            }
          })
          .catch(() => setStatus("rejected"));
      }
    }
  }, [addressString]);

  return {
    foundAddresses,
    position,
    status,
  };
};
