import { Grid, useMediaQuery } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { MouseEventHandler } from "react-select";
import { ParkingForSaleEquipments } from "routes/app/contacts/contact/components/Profiles/Proposal/Features/Parking/ParkingForSaleEquipments";
import { useTranslation } from "services/l10n/useTranslation";
import { ITheme } from "theme/ts/main";
import { Column } from "UI/shared/atoms/containers/Column";
import { If } from "UI/shared/atoms/directives/If";
import { ParkingProposalKind } from "../../../../models/Proposal/Features/Parking/parkingInfoKind";
import { CheckedCriteria } from "../../../../models/Proposal/ForRent/Parking/ForRentParkingFeaturesDto";
import {
  ForRentParkingFeaturesDtoBuilder,
  ForRentParkingProfileReadDto,
} from "../../../../models/Proposal/ForRent/Parking/ForRentParkingProfileReadDto";
import { SectionTitle } from "../../../components/SectionTitle";
import { SelectMaturity } from "../../../Maturity";
import { NotesInput } from "../../../Notes";
import { CheckBoxDescription } from "../../../Search/shared/types";
import { ProfileProps } from "../../../types";
import { Write } from "../../../Write";
import { ParkingSaleDimensionInput } from "../../Features/Parking/ParkingSaleDimension";
import { ParkingKind } from "../../Features/Parking/SelectParkingForSaleKind";
import { SalePlaceInput } from "../../SalePlace";
import { CriteriaCheckBoxes } from "./form/inputs/CriteriaCheckBoxes";
import { ParkingForRentBudgetInput } from "./form/inputs/ParkingForRentBudget";
import { useChanges } from "./form/inputs/useChanges";
import { FormValidity } from "./form/types";
import { useFormActions } from "./form/useFormActions";
import classes from "./Write.module.css";

export const ParkingForRentWrite: React.FC<ProfileProps<
  ForRentParkingProfileReadDto
>> = ({
  contactId,
  account,
  initial,
  more,
  showMore,
  sendUpdatedProfile,
  handleAfterUpdate,
  children,
}) => {
  const [formValidity, setFormValidity] = useState<FormValidity>({
    address: false,
    financialInfo: false,
    parkingPropertyInfo: true,
  });

  const isFormValid = useMemo(
    () => Object.values(formValidity).every((_) => _),
    [formValidity]
  );

  const mode = useMemo(() => (initial ? "update" : "add"), [initial]);

  const isSmallScreen = useMediaQuery((theme: ITheme) =>
    theme.breakpoints.down("sm")
  );

  const [payload, setPayload] = useState(
    ForRentParkingFeaturesDtoBuilder.create(account, initial)
  );

  const { pristine, state, setSaving } = useFormActions({
    isFormValid,
    profileOptions: {
      initialId: initial?.id,
      kind: "ForRentParkingProfile",
      payload,
      actions: {
        handleAfterUpdate,
        sendUpdatedProfile,
      },
    },
    contactId,
    mode,
  });

  const { t } = useTranslation();

  const disabled = useMemo(() => state === "saving", [state]);

  const {
    onAddressChange,
    onCheck,
    onDimensionChange,
    onFinancialInfoChange,
    onMaturityTypeChange,
    onNotesChange,
    onAmenitiesChange,
    onParkingKindChange,
  } = useChanges(setPayload, setFormValidity);

  const containerClass = more && !isSmallScreen ? classes.container : "";
  const itemAClass = more ? classes.itemA : "";
  const itemBClass = more ? classes.itemB : "";
  const itemCClass = more ? classes.itemC : "";

  const parkingKinds: ParkingProposalKind[] = [
    "Box",
    "ExteriorParking",
    "InteriorParking",
    "Unknown",
  ];

  const handleCheckedCriteria: (
    info: CheckedCriteria
  ) => MouseEventHandler = useCallback(
    (info) => () => {
      onCheck(info);
    },
    [onCheck]
  );

  const checkBoxes: CheckBoxDescription<CheckedCriteria>[] = [
    {
      criteriaKey: "boxableParking",
      label: t("parkingPropertyInfo.boxableParking"),
    },
    {
      criteriaKey: "parkingJoinOwnership",
      label: t("parkingPropertyInfo.joinOwnership"),
    },
  ];

  return (
    <Write
      pending={state === "saving"}
      save={setSaving}
      mode={mode}
      more={more}
      showMore={showMore}
    >
      <div className={containerClass}>
        <div className={itemAClass}>
          {children}
          <Grid item xs={12}>
            <ParkingKind
              onChange={onParkingKindChange}
              options={parkingKinds}
              disabled={false}
              value={payload.features.parkingKind}
            />
          </Grid>
          <Grid item xs={12}>
            <SalePlaceInput
              value={payload.features?.address}
              onChange={onAddressChange}
              disabled={disabled}
              pristine={pristine}
            />
          </Grid>
        </div>

        <div className={itemBClass}>
          <Grid item xs={12}>
            <ParkingForRentBudgetInput
              pristine={pristine}
              more={more}
              value={payload.features.financialInfo}
              onChange={onFinancialInfoChange}
            />
          </Grid>

          <SectionTitle title="Quelles sont les caractéristiques ?" />
          <If condition={more}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <ParkingSaleDimensionInput
                  onChange={onDimensionChange("squareMeter")}
                  value={{
                    adornement: "m\xB2",
                    label: t("Surface totale"),
                    value:
                      payload.features.parkingPropertyInfo.squareMeter || 0,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ParkingSaleDimensionInput
                  onChange={onDimensionChange("spacesNumber")}
                  value={{
                    adornement: "",
                    label: t("Nombre de places"),
                    value:
                      payload.features.parkingPropertyInfo.spacesNumber || 1,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ParkingSaleDimensionInput
                  onChange={onDimensionChange("height")}
                  value={{
                    adornement: "m",
                    label: t("Hauteur"),
                    value: payload.features.parkingPropertyInfo.height || 0,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ParkingSaleDimensionInput
                  onChange={onDimensionChange("width")}
                  value={{
                    adornement: "m",
                    label: t("Largeur"),
                    value: payload.features.parkingPropertyInfo.width || 0,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ParkingSaleDimensionInput
                  onChange={onDimensionChange("length")}
                  value={{
                    adornement: "m",
                    label: t("Profondeur"),
                    value: payload.features.parkingPropertyInfo.length || 0,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                &nbsp;
              </Grid>
              <Grid item xs={12}>
                <Column>
                  <CriteriaCheckBoxes
                    features={payload.features}
                    handleCheck={handleCheckedCriteria}
                    checkBoxes={checkBoxes}
                  />
                </Column>
              </Grid>
            </Grid>
          </If>
        </div>

        <div className={itemCClass}>
          <If condition={more}>
            <ParkingForSaleEquipments
              value={payload.features.parkingAmenities || []}
              onChange={onAmenitiesChange}
            />
          </If>

          <Grid item xs={12}>
            <NotesInput value={payload.notes} onChange={onNotesChange} />
          </Grid>

          <Grid item xs={12}>
            <SelectMaturity
              onChange={onMaturityTypeChange}
              value={payload.maturity}
              disabled={disabled}
            />
          </Grid>
        </div>
      </div>
    </Write>
  );
};
