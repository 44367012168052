import Loading from "components/Loading/LoadingSkeleton";
import React from "react";
import { HistoriquePrix } from "services/piges/adapters/historiquePrixBienPigeAdapter";
import { useHistoriquePrixBienPige } from "services/piges/usehistoriquePrixBienPige";

import { PriceHistoryPage } from "./PriceHistoryPage";

export interface PriceHistoryProps {
  annonceId: string;
  typeAnnonce: string;
  datePublication: Date;
  dateMiseAJour: Date;
  estActive: boolean;
}

export default function PriceHistory({
  annonceId,
  typeAnnonce,
  datePublication,
  dateMiseAJour,
  estActive,
}: PriceHistoryProps) {
  const params = { id: annonceId };
  const { status, items: historiquePrix } = useHistoriquePrixBienPige({
    erreur: false,
    params,
  });

  if (status === "pending") return <Loading />;

  if (historiquePrix)
    return (
      <PriceHistoryPage
        historiquePrix={historiquePrix as HistoriquePrix}
        typeAnnonce={typeAnnonce}
        datePublication={datePublication}
        dateMiseAJour={dateMiseAJour}
        estActive={estActive}
      />
    );

  return <>Aucun historique existant</>;
}
