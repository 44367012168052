import React from "react";
import { RadioChip } from "UI/shared/molecules/RadioChip";
import { PremisesType } from "../../../../models/Proposal/ForSale/Premises/premisesType";
import { SubSectionTitle } from "../../../components/SectionTitle";

export const PremisesTypeSelection: React.FC<{
  pristine?: boolean;
  value?: PremisesType;
  onChange: (isValid: boolean, value: PremisesType) => void;
}> = ({ value, onChange, pristine = false }) => {
  const options: PremisesType[] = [
    "Commercial",
    "Artisanal",
    "Industrial",
    "Business",
    "Shop",
    "Offices",
    "Cellar",
    "IndustrialShed",
    "Warehouse",
    "AgriculturalShed",
  ];

  return (
    <>
      <SubSectionTitle title="Type de local* :" />
      <RadioChip
        translationPrefix={"premisesType"}
        options={options}
        pristine={pristine}
        required={true}
        value={value}
        onChange={(newValue) => onChange(true, newValue)}
      />
    </>
  );
};
