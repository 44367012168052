import { Typography } from "@material-ui/core";
import { ElasticAdapter } from "api/DTOs/Common/ElasticSearch/ElasticAdapters";
import { AxiosResponse } from "axios";
import Notes from "UI/shared/atoms/Notes";
import {
  DetailedEntityTabsLayout,
  Tab,
} from "components/Fiche/DetailedEntityTabsLayout";
import BoutonImpression from "UI/shared/molecules/Buttons/BoutonImpression";
import TitrePage from "components/TitrePage";
import { useTabs } from "hooks/useTabs";
import BienCarteReduite from "layoutContents/Fiches/components/BienCarteReduite";
import Details from "layoutContents/Fiches/components/Details";
import PrintablePropertyDetails from "layoutContents/Fiches/components/PrintablePropertyDetails";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useAcquirerProfilesMatching } from "services/matching/useAcquirerProfilesMatching";
import { NetworkMatchingProfilePresenter } from "services/matching/NetworkMatchingProfilePresenter";
import { BuyerMatchingProfilePresenter } from "services/matching/BuyerMatchingProfilePresenter";
import { mapBienToMatchingProfileRequest } from "services/matching/buildingToMatchingRequest";
import {
  BienNotes,
  useBienNotes,
} from "services/rapprochement/Rapprochement/BienNotes/useBienNotes";
import obtenirParIdAdapter from "services/rapprochement/Rapprochement/BiensEnVente/ObtenirParId/obtenirParIdAdapter";
import { TitleWithNumberBadge } from "UI/shared/atoms/Badges/TitleWithNumberBadge";
import { useServices } from "services";
import Buyers from "./components/Buyers";
import PotentialBuyersInfo from "./components/PotentialBuyersInfo";
import { useMonBienEnVente } from "./request";

const PAGE_SIZE_CONSULTANT_BUYERS = 6;
const PAGE_SIZE_NETWORK_BUYERS = 6;

const {
  GET_REAL_ESTATE_SUCCEED,
  GET_REAL_ESTATE_FAILED,
  GET_CONSULTANT_BUYERS_PROPERTIES_SUCCEED,
  GET_NETWORK_BUYERS_PROPERTIES_SUCCEED,
} = Object.freeze({
  GET_CONSULTANT_BUYERS_PROPERTIES_SUCCEED:
    "gettingConsultantBuyersPropertiesSucceed",
  GET_NETWORK_BUYERS_PROPERTIES_SUCCEED:
    "gettingNetworkBuyersPropertiesSucceed",
  GET_REAL_ESTATE_FAILED: "gettingRealEstateFailed",
  GET_REAL_ESTATE_SUCCEED: "gettingRealEstateSucceed",
});

const initialState = {
  consultantBuyers: { data: [], total: 0 },
  networkBuyers: { data: [], total: 0 },
  realEstate: obtenirParIdAdapter(),
};

const reducer = (
  prevState: any,
  { type, payload }: { type: string; payload?: any }
) => {
  switch (type) {
    case GET_CONSULTANT_BUYERS_PROPERTIES_SUCCEED: {
      return {
        ...prevState,
        consultantBuyers: payload,
      };
    }
    case GET_NETWORK_BUYERS_PROPERTIES_SUCCEED:
      return {
        ...prevState,
        networkBuyers: payload,
      };
    case GET_REAL_ESTATE_SUCCEED: {
      return { ...prevState, realEstate: obtenirParIdAdapter(payload) };
    }
    case GET_REAL_ESTATE_FAILED:
      return {
        ...prevState,
        realEstate: obtenirParIdAdapter({ id: -1 }),
      };
    default:
      return { ...prevState };
  }
};

type TabName = "details" | "acquereurs";

export const MonBienEnVente = () => {
  const { id, tab, display } = useParams<{
    id: any;
    tab: TabName;
    display: any;
  }>();

  const { getRealEstate } = useMonBienEnVente();

  const {
    getConsultantRealEstate,
    getNetworkRealEstate,
  } = useAcquirerProfilesMatching();

  const {
    l10n: { t },
  } = useServices();

  const {
    loadBienNotes,
    handleDeleteNote,
    handleEditNotes,
    getDefaultNotes,
  } = useBienNotes();
  const [bienNotes, setBienNotes] = useState<BienNotes>(getDefaultNotes());

  const [
    { realEstate, consultantBuyers, networkBuyers },
    dispatch,
  ] = useReducer(reducer, initialState);

  const history = useHistory();

  const componentToPrintRef = React.useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  const onHandleEditNotes = useCallback(
    (notes: string) => {
      handleEditNotes({ ...bienNotes, Notes: notes }).then((notes) =>
        setBienNotes(notes)
      );
    },
    [bienNotes]
  );

  const onHandleDeleteNotes = useCallback(() => {
    handleDeleteNote(bienNotes).then((notes) => setBienNotes(notes));
  }, [bienNotes]);

  const handleConsultantBuyersChange = useCallback(
    (page) => {
      if (realEstate.id !== 0) {
        getConsultantRealEstate(
          mapBienToMatchingProfileRequest(realEstate),
          page,
          PAGE_SIZE_CONSULTANT_BUYERS
        )
          .then(
            (
              response: AxiosResponse<
                ElasticAdapter<BuyerMatchingProfilePresenter>
              >
            ) => {
              dispatch({
                payload: response.data,
                type: GET_CONSULTANT_BUYERS_PROPERTIES_SUCCEED,
              });
            }
          )
          .catch((error) => console.log(error));
      }
    },
    [realEstate.id]
  );

  const handleNetworkBuyersChange = useCallback(
    (page) => {
      if (realEstate.id === 0) return;
      getNetworkRealEstate(
        mapBienToMatchingProfileRequest(realEstate),
        page,
        PAGE_SIZE_NETWORK_BUYERS
      )
        .then(
          (
            response: AxiosResponse<
              ElasticAdapter<NetworkMatchingProfilePresenter>
            >
          ) => {
            dispatch({
              payload: response.data,
              type: GET_NETWORK_BUYERS_PROPERTIES_SUCCEED,
            });
          }
        )
        .catch((error) => console.log(error));
    },
    [realEstate.id]
  );

  const handleBackButtonAction = () => {
    history.goBack();
  };

  let titreSurface,
    titreVille,
    titre = "",
    tabs: Tab[] = [],
    actions;

  if (realEstate.id !== 0) {
    titreSurface =
      realEstate.surface > 0 ? ` ${realEstate.surface} m\xB2 ` : "";
    titreVille = ` ${t("à")} ${realEstate.localisation.villeNom}`;
    titre = `${realEstate.typeAffaireEnum} ${t(
      realEstate.familleBienEnum
    )}${titreSurface}${titreVille}`;

    tabs = [
      {
        content: <Details bien={realEstate} t={t} />,
        name: "Details",
        titre: t("DETAILS"),
        value: "details",
      },
      {
        content: (
          <Buyers
            consultantBuyers={consultantBuyers}
            networkBuyers={networkBuyers}
            handleConsultantBuyersChange={handleConsultantBuyersChange}
            handleNetworkBuyersChange={handleNetworkBuyersChange}
          />
        ),
        name: "Buyers",
        titre: (
          <TitleWithNumberBadge
            number={consultantBuyers.total + networkBuyers.total}
            title={t("ACQUEREURS")}
          />
        ),
        value: "acquereurs",
      },
    ];

    actions =
      handlePrint !== null
        ? [<BoutonImpression key={0} onPrint={handlePrint} />]
        : [];
  }

  const { onSelectedTabChanged, openTab, activeTabIndex } = useTabs<TabName>(
    tabs,
    tab,
    (tab) => `/app/biens/mon-bien-en-vente/${id}/${tab}/view`
  );

  useEffect(() => {
    getRealEstate(id)
      .then((response: any) => {
        loadBienNotes(response.codeAffaire).then((notes) => {
          setBienNotes(notes);
        });
        dispatch({
          payload: response,
          type: GET_REAL_ESTATE_SUCCEED,
        });
        if (display === "print" && handlePrint) handlePrint();
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_REAL_ESTATE_FAILED,
        });
      });
  }, [id, display]);

  useEffect(() => {
    if (realEstate !== 0 && realEstate.id !== -1) {
      handleConsultantBuyersChange(1);
      handleNetworkBuyersChange(1);
    }
  }, [handleConsultantBuyersChange, handleNetworkBuyersChange, realEstate]);

  if (realEstate.id === 0) {
    return <Typography variant="h2">Chargement...</Typography>;
  }
  if (realEstate.id === -1) {
    return <Typography variant="h2">Non Disponible.</Typography>;
  }

  return (
    <>
      <PrintablePropertyDetails
        bien={realEstate}
        componentToPrintRef={componentToPrintRef}
        titre={
          <TitrePage
            categorie="Biens | Mes Biens"
            page={"Fiche"}
            nbResultats=""
          />
        }
      />
      <DetailedEntityTabsLayout
        composantsBarreGauche={[
          {
            composant: (
              <BienCarteReduite
                bien={realEstate}
                mapMode={"precis"}
                mapPointLabel={<></>}
                shouldDisplayCardActions
              />
            ),
          },
          {
            composant: <div style={{ marginBottom: 20 }} />,
          },
          {
            composant: (
              <PotentialBuyersInfo
                onClick={() => openTab("acquereurs")}
                numberOfBuyers={consultantBuyers.total + networkBuyers.total}
              />
            ),
          },
          {
            composant: <div style={{ marginBottom: 20 }} />,
          },
          {
            composant: (
              <Notes
                notes={bienNotes.Notes}
                editNotes={onHandleEditNotes}
                deleteNotes={onHandleDeleteNotes}
                t={t}
              />
            ),
          },
        ]}
        titre={
          <TitrePage
            categorie="Biens | Mes Biens"
            page={titre}
            nbResultats=""
            filePath={"/static/markdowns/ficheMesBiensEnVente.md"}
          />
        }
        tabs={tabs}
        actions={actions}
        tabActiveIndex={activeTabIndex}
        onSelectedTabChanged={onSelectedTabChanged}
        onClose={handleBackButtonAction}
      />
    </>
  );
};
