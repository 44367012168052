import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/TuneOutlined";
import React from "react";

interface SwipeableContainerButtonProps {
  openHandler: () => void;
}

export const SwipeableContainerButton: React.FC<SwipeableContainerButtonProps> = ({
  openHandler,
}) => (
  <Button
    aria-label="more filter"
    variant={"contained"}
    color={"primary"}
    size={"small"}
    onClick={openHandler}
    style={{
      fontSize: "16px",
      height: "45px",
      minWidth: "45px",
      padding: 0,
    }}
  >
    <AddIcon />
  </Button>
);
