import Grid from "@material-ui/core/Grid";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import React from "react";
import { TO_BE_TREATED } from "services/request-for-informations/constants/request-for-informations-status";
import { CardPaperItem } from "UI/shared/atoms/containers/CardPaperItem";
import { Column } from "UI/shared/atoms/containers/Column";
import { Row } from "UI/shared/atoms/containers/Row";
import { HorizontalDivider } from "UI/shared/atoms/HorizontalDivider";
import { LastUpdate } from "UI/shared/atoms/LastUpdate";
import { NameInformation } from "UI/shared/atoms/NameInformation";
import { ReceptionDate } from "UI/shared/atoms/ReceptionDate";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import { BusinessTypeCaption } from "UI/shared/molecules/BusinessTypeCaption";
import { EmailLink } from "UI/shared/molecules/EmailLink";
import { PhoneLink } from "UI/shared/molecules/PhoneLink";
import { PriceCaption } from "UI/shared/molecules/PriceCaption";
import { PropertyTypeCaption } from "UI/shared/molecules/PropertyTypeCaption";
import { Source } from "UI/shared/molecules/Source";
import { Status } from "UI/shared/molecules/Status";
import { ActionsMenu } from "UI/shared/organisms/ActionsMenu";
import { LocationCaption } from "UI/shared/organisms/LocationCaption";
import { SurfaceBedroomsNbRoomsNbCaption } from "UI/shared/organisms/SurfaceBedroomsNbRoomsNbCaption";
import { RequestForInformationItemProps } from "UI/features/requestForInformations/interfaces/RequestForInformationItemProps";

export const SmallRequestForInformationItem: React.FC<RequestForInformationItemProps> = React.memo(
  ({ requestForInformation, actions, clickHandler }) => {
    return (
      <Grid container>
        <CardPaperItem
          key={requestForInformation.id}
          clickHandler={clickHandler}
        >
          <Grid item xs={12}>
            <Column columnJustify="flex-start">
              <BusinessTypeCaption
                type={requestForInformation.propertyForSale.businessType}
              />
              <PropertyTypeCaption
                type={requestForInformation.propertyForSale.type}
              />
              <LocationCaption
                city={requestForInformation.propertyForSale.city}
                zipcode={requestForInformation.propertyForSale.zipcode}
              />
              <PriceCaption
                price={requestForInformation.propertyForSale.price}
              />
              <SurfaceBedroomsNbRoomsNbCaption
                livingArea={requestForInformation.propertyForSale.livingArea}
                bedRoomsNumber={
                  requestForInformation.propertyForSale.bedRoomsNumber
                }
                roomsNumber={requestForInformation.propertyForSale.roomsNumber}
              />
            </Column>
          </Grid>
          <VerticalSpace size={10} />
          <HorizontalDivider height={1} color={"#8E8E8E"} />
          <VerticalSpace size={10} />
          <Grid item xs={12}>
            <Column>
              <Source
                title={requestForInformation.source}
                bgColor={"#BFB7B7"}
              />
              {requestForInformation.status === TO_BE_TREATED && (
                <ReceptionDate
                  date={new Date(requestForInformation.receivedAt)}
                />
              )}
              {requestForInformation.status !== TO_BE_TREATED && (
                <LastUpdate date={new Date(requestForInformation.updatedAt)} />
              )}
              <VerticalSpace size={5} />
              <NameInformation value={requestForInformation.emailSender} />
              <PhoneLink
                value={
                  parsePhoneNumberFromString(
                    requestForInformation.client.phoneNumber || ""
                  )?.formatInternational() || ""
                }
              />
              <EmailLink value={requestForInformation.client.email} />
              <Row rowAlignItems="center" rowJustify="space-between">
                <Status status={requestForInformation.status} />
                <ActionsMenu menuOptions={actions} minToCollapse={0} />
              </Row>
            </Column>
          </Grid>
        </CardPaperItem>
      </Grid>
    );
  },
  (prev, next) =>
    next.requestForInformation.id === prev.requestForInformation.id &&
    next.requestForInformation.status === prev.requestForInformation.status
);
