import React from "react";
import { makeStyles } from "@material-ui/core";

interface HorizontalSpaceProps {
  size: number;
}

const useStyles = makeStyles(() => ({
  spaceDiv: {
    width: ({ size }: HorizontalSpaceProps) => `${size}px`,
  },
}));

export const HorizontalSpace: React.FC<HorizontalSpaceProps> = ({ size }) => {
  const classes = useStyles({ size });
  return <div className={classes.spaceDiv}></div>;
};
