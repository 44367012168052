import { AxiosResponse } from "axios";
import { orderBy } from "lodash";
import React, { createContext, useCallback, useContext, useState } from "react";
import { ProfileType } from "routes/app/profiles/adapters/profileAdapterDetails";

import {
  ProfileKind,
  ProfileReadDto,
  ProfilesReadDto,
} from "../../routes/app/contacts/contact/components/models/profilesReadDto";
import { ProfileWriteDto } from "../../routes/app/contacts/contact/components/models/profileWriteDto";
import { useTxHttp } from "../http";
import { useServices } from "../index";
import { ProfileExportDto } from "./dto/PorfileExportDto";
import { ProfileItemDto } from "./dto/ReadModelProfileDto";

export const useProfiles = (contactId: string) => {
  const {
    add,
    archive,
    reactivate,
    update,
    updateNotes,
    exportProfile,
    getProfileExport,
    openLegacyProfile,
  } = useContext(ProfilesContext);

  return {
    add: add(contactId),
    archive,
    exportProfile: exportProfile(contactId),
    getProfileExport: getProfileExport(contactId),
    openLegacyProfile,
    reactivate,
    update: update(contactId),
    updateNotes: updateNotes(contactId),
  };
};

interface IProfilesContext {
  add: (
    contactId: string
  ) => (kind: ProfileKind, payload: ProfileWriteDto) => Promise<void>;
  archive: (profileId: string, contactId: string) => Promise<void>;
  reactivate: (profileId: string, contactId: string) => Promise<void>;

  update: (
    contactId: string
  ) => (
    kind: ProfileKind,
    profileId: string,
    payload: ProfileWriteDto,
    sendUpdatedProfile?: (profile: ProfileReadDto | undefined) => void
  ) => Promise<void>;
  updateNotes: (
    contactId: string
  ) => (profileId: string, notes: string) => Promise<AxiosResponse<string>>;
  getProfileExport: (
    contactId: string
  ) => (profileId: string) => Promise<AxiosResponse<ProfileExportDto>>;
  exportProfile: (contactId: string) => (profileId: string) => Promise<any>;
  openLegacyProfile: (legacyId: string, bu: string, type: ProfileType) => void;
  getAll: (contactId: string) => () => Promise<void>;
  profiles: ProfileItemDto[];
  summary: { [key: string]: Record<ProfileGlobalKind, number> };
  status: "done" | "loading" | "error";
  getSummary: () => void;
}

export type ProfileGlobalKind = "Search" | "ForSale";
export const ProfilesContext = createContext<IProfilesContext>(null as any);

export const ProfilesContextProvider: React.FC = (props) => {
  const { put, post, get } = useTxHttp();
  const { endpoints } = useServices();
  const [profiles, setProfiles] = useState<ProfileItemDto[]>([]);
  const [summary, setSummary] = useState<{
    [key: string]: Record<ProfileGlobalKind, number>;
  }>({});

  const getAll = useCallback(
    (contactId: string) => async () => {
      const response = await get<ProfileItemDto[]>(
        endpoints.profiles.all(contactId)
      );
      if (response) {
        setProfiles(
          orderBy(response.data, (_) => _.lastModificationDate, "desc")
        );
      }
    },
    [endpoints.profiles, get]
  );

  const add = useCallback(
    (contactId: string) => (kind: ProfileKind, payload: ProfileWriteDto) =>
      post<ProfilesReadDto>(
        endpoints.profiles.profile(contactId, getKind(kind)),
        {
          content: payload,
          contentType: "application/json",
        }
      ).then(() => {
        getAll(contactId)();
      }),
    [getAll, endpoints.profiles, post]
  );

  const archive = useCallback(
    (profileId: string, contactId: string) =>
      put<ProfilesReadDto>(endpoints.profiles.archive(profileId, contactId), {
        content: {},
        contentType: "application/json",
      }).then(() => {
        getAll(contactId)();
      }),
    [endpoints.profiles, getAll, put]
  );

  const reactivate = useCallback(
    (profileId: string, contactId: string) =>
      put<ProfilesReadDto>(
        endpoints.profiles.reactivate(profileId, contactId),
        {
          content: {},
          contentType: "application/json",
        }
      ).then(() => {
        getAll(contactId)();
      }),
    [endpoints.profiles, getAll, put]
  );

  const updateNotes = useCallback(
    (contactId: string) => async (profileId: string, notes: string) =>
      await put<string>(
        `${endpoints.profiles.updateNotes(contactId, profileId)}${notes}`
      ),
    [endpoints.profiles, put]
  );

  const update = useCallback(
    (contactId: string) => (
      kind: ProfileKind,
      profileId: string,
      payload: ProfileWriteDto
    ) =>
      put<ProfilesReadDto>(
        endpoints.profiles.profile(contactId, `${getKind(kind)}/${profileId}`),
        {
          content: payload,
          contentType: "application/json",
        }
      ).then(() => {
        getAll(contactId)();
      }),
    [endpoints.profiles, getAll, put]
  );

  const exportProfile = useCallback(
    (contactId: string) => async (profileId: string) =>
      await post<any>(endpoints.profiles.export(contactId, profileId), {
        content: null,
        contentType: "application/json",
      }).catch((e) => JSON.parse(JSON.stringify(e))),
    [post, endpoints.profiles]
  );

  const getProfileExport = useCallback(
    (contactId: string) => async (profileId: string) =>
      await get(endpoints.profiles.getExport(contactId, profileId)).catch((e) =>
        JSON.parse(JSON.stringify(e))
      ),
    [get, endpoints.profiles]
  );

  const openLegacyProfile = useCallback(
    (legacyId: string, bu: string, type: ProfileType) => {
      window.open(
        endpoints.profiles.legacyProfile(legacyId, bu, type),
        "_blank"
      );
    },
    [endpoints.profiles]
  );

  const [status, setStatus] = useState<"done" | "loading" | "error">("done");

  const getSummary = useCallback(() => {
    setStatus("loading");
    get<{ [key: string]: Record<string, number> }>(endpoints.profiles.summary)
      .then((response) => setSummary(response.data))
      .catch(() => setStatus("error"))
      .finally(() => setStatus("done"));
  }, [endpoints.profiles, get]);

  return (
    <ProfilesContext.Provider
      value={{
        add,
        archive,
        exportProfile,
        getAll,
        getProfileExport,
        getSummary,
        openLegacyProfile,
        profiles,
        reactivate,
        status,
        summary,
        update,
        updateNotes,
      }}
    >
      {props.children}
    </ProfilesContext.Provider>
  );
};

export const getKind = (kind: ProfileKind): string => {
  switch (kind) {
    case "SearchLifeAnnuityApartmentProfile":
      return "life-annuity/apartments";
    case "SearchLifeAnnuityBuildingProfile":
      return "life-annuity/buildings";
    case "SearchLifeAnnuityHouseProfile":
      return "life-annuity/houses";
    case "SearchLifeAnnuityLandProfile":
      return "life-annuity/lands";
    case "SearchLifeAnnuityPremisesProfile":
      return "life-annuity/premises";
    case "PurchaseApartmentProfile":
      return "purchase/apartments";
    case "PurchaseBuildingProfile":
      return "purchase/buildings";
    case "PurchaseGoodwillProfile":
      return "purchase/goodwills";
    case "PurchaseHouseProfile":
      return "purchase/houses";
    case "PurchaseParkingProfile":
      return "purchase/parkings";
    case "PurchaseLandProfile":
      return "purchase/lands";
    case "PurchasePremisesProfile":
      return "purchase/premises";
    case "RentApartmentProfile":
      return "rental/apartments";
    case "RentHouseProfile":
      return "rental/houses";
    case "RentLandProfile":
      return "rental/lands";
    case "RentParkingProfile":
      return "rental/parkings";
    case "RentPremisesProfile":
      return "rental/premises";
    case "SaleApartmentProfile":
      return "for-sale/apartments";
    case "SaleHouseProfile":
      return "for-sale/houses";
    case "SaleBuildingProfile":
      return "for-sale/building";
    case "SalePremisesProfile":
      return "for-sale/premises";
    case "SaleLandProfile":
      return "for-sale/land";
    case "SaleParkingProfile":
      return "for-sale/parking";
    case "ForRentApartmentProfile":
      return "for-rent/apartments";
    case "ForRentParkingProfile":
      return "for-rent/parking";
    case "ForRentHouseProfile":
      return "for-rent/houses";
    default:
      return "";
  }
};
