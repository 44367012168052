import conseillerAdapter from "services/rapprochement/Rapprochement/ConseillerAdapter";
import villeAdapter from "services/rapprochement/Rapprochement/VilleAdapter";

import ElasticAdapter from "../../../../../api/DTOs/Common/ElasticSearch/ElasticAdapter";
import RechercheGridOuListeBO from "./RechercheGridOuListeBO";

class RechercheGridOuListeAdapter {
  static toBO(result = {}) {
    const elasticResult = ElasticAdapter.toBO(result);
    elasticResult.data = elasticResult.data.map((item) => this.itemToBO(item));
    return elasticResult;
  }

  static itemToBO(item = {}) {
    const bo = new RechercheGridOuListeBO();
    bo.id = item.id || bo.id;
    bo.bu = item.bu || bo.bu;
    bo.nombrePhotos = item.nombrePhotos || bo.nombrePhotos;
    bo.photoPrincipale = item.photoPrincipale || bo.photoPrincipale;
    bo.surface = item.surface || bo.surface;
    bo.surfaceTerrain = item.surfaceTerrain || bo.surfaceTerrain;
    bo.nbPieces = item.nbPieces || bo.nbPieces;
    bo.nbChambres = item.nbChambres || bo.nbChambres;
    bo.prix = item.prix || bo.prix;
    bo.familleBienEnum = item.familleBienEnum || bo.familleBienEnum;
    bo.typeAchatEnum = item.typeAchatEnum || bo.typeAchatEnum;
    bo.optionMandatEnum = item.optionMandatEnum || bo.optionMandatEnum;
    bo.typeAffaireEnum = item.typeAffaireEnum || bo.typeAffaireEnum;
    bo.conseillers =
      item.conseillers.length > 0
        ? item.conseillers.map((conseiller) => conseillerAdapter(conseiller))
        : bo.conseillers;
    bo.conseillerPrincipal =
      bo.conseillers.length > 0
        ? bo.conseillers.filter(
            (conseiller) => conseiller.estPrincipal === true
          )[0]
        : conseillerAdapter();
    bo.localisation = item.localisation
      ? villeAdapter(item.localisation)
      : bo.localisation;
    bo.prestige = item.prestige || bo.prestige;
    bo.dateEffectiveCompromis =
      item.dateEffectiveCompromis || bo.dateEffectiveCompromis;
    bo.dateEffectiveSignature =
      item.dateEffectiveSignature || bo.dateEffectiveSignature;
    bo.neuf = item.neuf || bo.neuf;
    bo.typeBienNom = item.typeBienNom || bo.typeBienNom;
    bo.isCE = item.isCE || bo.isCE;
    return bo;
  }
}

export default RechercheGridOuListeAdapter;

const itemToBO = (item = {}) => {
  const bo = new RechercheGridOuListeBO();
  bo.id = item.id || bo.id;
  bo.bu = item.bu || bo.bu;
  bo.nombrePhotos = item.nombrePhotos || bo.nombrePhotos;
  bo.photoPrincipale = item.photoPrincipale || bo.photoPrincipale;
  bo.surface = item.surface || bo.surface;
  bo.surfaceTerrain = item.surfaceTerrain || bo.surfaceTerrain;
  bo.nbPieces = item.nbPieces || bo.nbPieces;
  bo.nbChambres = item.nbChambres || bo.nbChambres;
  bo.prix = item.prix || bo.prix;
  bo.familleBienEnum = item.familleBienEnum || bo.familleBienEnum;
  bo.typeAchatEnum = item.typeAchatEnum || bo.typeAchatEnum;
  bo.optionMandatEnum = item.optionMandatEnum || bo.optionMandatEnum;
  bo.typeAffaireEnum = item.typeAffaireEnum || bo.typeAffaireEnum;
  bo.conseillers =
    item.conseillers.length > 0
      ? item.conseillers.map((conseiller) => conseillerAdapter(conseiller))
      : bo.conseillers;
  bo.conseillerPrincipal =
    bo.conseillers.length > 0
      ? bo.conseillers.filter(
          (conseiller) => conseiller.estPrincipal === true
        )[0]
      : conseillerAdapter();
  bo.localisation = item.localisation
    ? villeAdapter(item.localisation)
    : bo.localisation;
  bo.prestige = item.prestige || bo.prestige;
  bo.dateEffectiveCompromis =
    item.dateEffectiveCompromis || bo.dateEffectiveCompromis;
  bo.dateEffectiveSignature =
    item.dateEffectiveSignature || bo.dateEffectiveSignature;
  bo.neuf = item.neuf || bo.neuf;
  bo.typeBienNom = item.typeBienNom || bo.typeBienNom;
  bo.isCE = item.isCE || bo.isCE;
  return bo;
};

export const rechercheGridOuListeAdapter = (result = {}) => {
  const elasticResult = ElasticAdapter.toBO(result);
  elasticResult.data = elasticResult.data.map((item) => itemToBO(item));
  return elasticResult;
};
