import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { ContactEventSent } from "services/contactEventsTracking/dto/ContactEvent";

import { EventForm } from "./EventForm";
import { emptyContactEventSent } from "./stateHandler";

export interface EventsSimplifiedViewProps {
  onAddEvent: (contactEvent: ContactEventSent) => Promise<void>;
  onClose: () => void;
}

export const EventsSimplifiedView: React.FC<EventsSimplifiedViewProps> = ({
  onAddEvent,
  onClose,
}) => {
  return (
    <Grid item xs={12} md={12} style={{ padding: "30px" }}>
      <Grid item xs={12}>
        <Typography variant="h2">Ajoutez une action de suivi</Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EventForm
            initialValues={emptyContactEventSent}
            saveEvent={onAddEvent}
            displayCancel={true}
            onCancel={onClose}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
