import { makeStyles } from "@material-ui/core";
import PlaceIcon from "@material-ui/icons/PlaceOutlined";
import { Text16pxBoldEllipsis } from "UI/shared/atoms/typographies/Text16pxBoldEllipsis";
import React from "react";

import { ITheme } from "../../../../theme/ts/main";

interface LocationCaptionProps {
  city: string;
  zipcode?: string;
  withIcon?: boolean;
}

const useStyles = makeStyles((theme: ITheme) => ({
  locationContainer: {
    alignItems: "center",
    display: "flex",
  },
  placeIcon: {
    color: theme.palette.common.dark,
    fontSize: 16,
    marginLeft: -4,
  },
  zipCode: {
    fontSize: 10,
    fontWeight: "normal",
  },
}));

export const LocationCaption: React.FC<LocationCaptionProps> = ({
  city,
  zipcode,
  withIcon = true,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.locationContainer}>
      {withIcon && <PlaceIcon className={classes.placeIcon} />}
      <Text16pxBoldEllipsis>
        {city && <span>{city.toUpperCase()} </span>}
        {zipcode && <span className={classes.zipCode}>{` (${zipcode})`}</span>}
      </Text16pxBoldEllipsis>
    </div>
  );
};
