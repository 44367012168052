import { Fab, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/PersonAddOutlined";
import { Column } from "UI/shared/atoms/containers/Column";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";
import { Criterias } from "UI/shared/molecules/Criterias";
import { SortInput, SortOption } from "UI/shared/molecules/SortInput";
import { AdaptedRefinements } from "components/RefinementField/types";
import PanneauResultatsVides from "UI/shared/templates/Panels/NoItemsPanel";
import React from "react";
import { PagedResultDto } from "services/profiles/dto/PagedResultDto";
import { ProfilesCriteria } from "types";
import Loading from "components/Loading/LoadingSkeleton";
import TitrePage from "components/TitrePage/index";
import { useServices } from "services";
import { ProfileItemDto } from "services/profiles/dto/ReadModelProfileDto";
import { ITheme } from "theme/ts/main";
import { ContactForm } from "routes/app/contacts/components/ContactForm";
import { ProfilesList } from "UI/features/profiles/templates/profilesList/ProfilesList";
import { ProfilesSearchBar } from "UI/features/profiles/organisms/searchBar/ProfilesSearchBar";
import { MoreFilters } from "UI/features/profiles/organisms/MoreFilters/MoreFilters";

export interface ProfilesListPageProps {
  profiles: PagedResultDto<ProfileItemDto>;
  totalCount: number;
  loadMore: () => void;
  pageSize: number;
  pending: boolean;
  adaptedRefinements: AdaptedRefinements<ProfilesCriteria>;
  refinements: any;
  onClearRefinements: any;
  onChangeRefinements: any;
  sortOptions: SortOption[];
  onChangeSortOptions: any;
  selectedSortOptionValue: any;
}

const useStyles = makeStyles((theme: ITheme) => ({
  fab: {
    backgroundColor: theme.palette.tonique,
    bottom: theme.spacing(2),
    color: "white",
    position: "fixed",
    right: theme.spacing(2),
  },
}));

export const ProfilesListPage: React.FC<ProfilesListPageProps> = ({
  profiles,
  totalCount,
  loadMore,
  pageSize,
  pending,
  adaptedRefinements,
  refinements,
  onClearRefinements,
  onChangeRefinements,
  sortOptions,
  onChangeSortOptions,
  selectedSortOptionValue,
}) => {
  const {
    l10n: { t },
    modal,
  } = useServices();

  const classes = useStyles();
  
  const displayedResultsTotal = `${
    totalCount ? (totalCount >= 10000 ? "+ de 10000" : totalCount) : 0
  } ${t("résultat(s)")}`;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TitrePage
            categorie="CRM"
            page={"Profils"}
            nbResultats={displayedResultsTotal}
            filePath={"/static/markdowns/CRM/profiles.md"}
          />
        </Grid>

        <Grid item xs={12}>
          <PaddingContainer top={10} bottom={4}>
            <ProfilesSearchBar
              searchResultCount={totalCount}
              adaptedRefinements={adaptedRefinements}
              onChange={onChangeRefinements}
            />
          </PaddingContainer>
        </Grid>
        <Grid container alignItems="center">
          <Criterias
            refinements={refinements}
            onChange={onChangeRefinements}
            onClear={onClearRefinements}
          />
          <SortInput
            options={sortOptions}
            onChange={onChangeSortOptions}
            selectedOption={selectedSortOptionValue}
          />{" "}
        </Grid>
        <Grid item xs={12}>
          {profiles.documents.length < 1 ? (
            pending ? (
              <Loading />
            ) : (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Column columnJustify="center">
                  <PanneauResultatsVides
                    clearRefinements={onClearRefinements}
                    moreFilters={
                      <MoreFilters
                        adaptedRefinements={adaptedRefinements}
                        onChange={onChangeRefinements}
                        searchResultCount={totalCount}
                      />
                    }
                  />
                </Column>
              </div>
            )
          ) : (
            <ProfilesList
              profiles={profiles.documents}
              loadMore={loadMore}
              pageSize={pageSize}
            />
          )}
        </Grid>
      </Grid>
      <Fab
        size="large"
        aria-label="Add"
        className={classes.fab}
        onClick={() => {
          modal.show(<ContactForm closeDialog={modal.close} />);
        }}
      >
        <AddIcon />
      </Fab>
    </>
  );
};
