import { Typography } from "@material-ui/core";
import React from "react";

const TitreBloc = ({ label, icon }) => {
  return (
    <div style={{ display: "flex", paddingTop: 10 }}>
      {icon}
      <div style={{ width: 5 }} />
      <Typography variant={"h4"}>{label}</Typography>
    </div>
  );
};
export default TitreBloc;
