import { Grid, Typography } from "@material-ui/core";
import PlaceIcon from "@material-ui/icons/PlaceOutlined";
import AgenciesUnwanted from "components/AgenciesUnwanted/AgenciesUnwanted";
import CommerceEntreprise from "UI/shared/atoms/Badges/C&E";
import Neuf from "UI/shared/atoms/Badges/Neuf";
import moment from "moment";
import React from "react";
import { AnnonceInList } from "types";
import PriceEvolution from "UI/features/pige/atoms/PriceEvolution";
import { LocationCaption } from "UI/shared/organisms/LocationCaption";
import { formatPrix } from "utils/formatage";

import { useStyles } from "../styles";

export const Prix = ({
  hit,
  t,
}: {
  hit: AnnonceInList;
  t: (value: string) => string;
}) => {
  const classes = useStyles();
  const areAgenciesUnwanted = hit.joignableParAgence === "NonJoignable";
  return (
    <Grid container direction="row" spacing={2} alignItems="center">
      <Grid item xs={6} md={6}>
        <div>
          <Typography
            variant="body1"
            className={classes.affFam}
            style={{ marginBottom: -5, marginTop: 10 }}
          >
            {hit.typeAnnonce ? hit.typeAnnonce.toUpperCase() : "N/C"}
          </Typography>
          <Typography variant="body1" className={classes.affFam}>
            {hit.typeBien ? t(hit.typeBien) : "N/C"}
          </Typography>
          <LocationCaption city={hit.ville || ""} zipcode={hit.codePostal} />
          <Grid item xs={12}>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-start",
                marginTop: 5,
              }}
            >
              {areAgenciesUnwanted && (
                <div className={classes.tagItem}>
                  <AgenciesUnwanted />
                </div>
              )}
              {hit.typeEtatBien === "Neuf" && (
                <div className={classes.tagItem}>
                  <Neuf />
                </div>
              )}
              {hit.isCE && (
                <div className={classes.tagItem}>
                  <CommerceEntreprise />
                </div>
              )}
            </div>
          </Grid>
        </div>
      </Grid>

      <Grid item md={4}>
        <Typography variant="h2" align="right" noWrap>
          <PriceEvolution evolutionType={hit.typeDerniereEvolutionPrix} />
          {`${hit.prix !== undefined ? formatPrix(hit.prix) : "N/C"}`}
        </Typography>
        <Typography variant="body2" align="right">{`${t("Paru le ")}${moment(
          hit.dateActivation
        ).format("DD/MM/YYYY")}`}</Typography>
        <Typography variant="body2" align="right">{`${t(
          "Mise à jour le "
        )}${moment(hit.dateMiseAJour).format("DD/MM/YYYY")}`}</Typography>
      </Grid>
    </Grid>
  );
};
