import { Dialog, Grid } from "@material-ui/core";
import { EmailsList } from "UI/shared/molecules/EmailsList";
import { PhonesList } from "UI/shared/molecules/PhonesList";
import React, { useState } from "react";
import { ContactForm } from "routes/app/contacts/components/ContactForm";
import { ReadContactDto } from "services/portfolio/types";

import TitreSection from "../TitreSection";
import Address from "./components/adresse";
import { Identity } from "./components/Identity";
import OptIn from "./components/optIn";
import { OtherInformations } from "./components/OtherInformations";
import { SectionTitle } from "./components/SectionTitle";
import StatusInfos from "./components/StatusInfos";

const Identite: React.FC<{
  contact: ReadContactDto;
}> = ({ contact }) => {
  const [editContactFormDisplayed, setEditContactFormDisplayed] = useState(
    false
  );
  const hideEditContactForm = () => {
    setEditContactFormDisplayed(false);
  };

  return (
    <div
      style={{
        height: "100%",
        width: "calc(100% - 32px)",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} lg={4}>
          <StatusInfos
            contactStatus={contact.status}
            dateCreation={contact.creationDate}
            dateModification={contact.updateDate}
            source={contact.source}
          />
          <SectionTitle title="Identité" />
          <Identity identity={contact.identity} />
          <SectionTitle title="Moyens de contact" />
          <PhonesList
            value={contact.identity.phones.map((_) => ({
              kind: _.kind,
              value: _.number,
            }))}
          />
          <EmailsList
            value={contact.identity.emails.map((_) => ({
              kind: _.kind,
              value: _.address,
            }))}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <TitreSection titre="Consentement" />
          <OptIn optIn={contact.consentStatus} />
          <TitreSection titre="Autres informations" />
          <OtherInformations contact={contact} />
        </Grid>

        <Grid item xs={12} lg={4}>
          <TitreSection titre="Adresse" />
          <Address
            content={{
              adresse: contact.address?.value || "",
              codePostal: contact.address?.city?.postalCodes[0]
                ? contact.address?.city?.postalCodes[0]
                : "",
              complementAdresse: contact.address?.supplement || "",
              pays: "france",
              ville: contact.address?.city?.name || "",
            }}
          />
        </Grid>
      </Grid>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        scroll="body"
        open={editContactFormDisplayed}
        onClose={hideEditContactForm}
        aria-labelledby="Formulaire contact"
        aria-describedby="Modification d'un contact"
      >
        <div style={{ overflow: "inherit" }}>
          <ContactForm contact={contact} closeDialog={hideEditContactForm} />
        </div>
      </Dialog>
    </div>
  );
};

export default Identite;
