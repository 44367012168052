export interface PigeRoutes {
  onList: (slug: string, adId?: string, redirectUrl?: string) => string;
  toDetail: (id: string, redirectUrl?: string) => string;
}

const part = (key: "id" | "redirectUrl", value?: string, isFirst = false) =>
  value ? `${!isFirst && "&"}${key}=${value}` : "";

export const pigeRoutes: PigeRoutes = {
  onList: (slug: string, adId?: string, redirectUrl?: string) => {
    const idPart = part("id", adId);
    const redirectUrlPart = part("redirectUrl", redirectUrl);
    const slugPart = `slug=${slug}`;

    return `/app/biens/pige?${slugPart}${idPart}${redirectUrlPart}`;
  },
  toDetail: (id: string, redirectUrl?: string): string => {
    const redirectPart = redirectUrl ? `&redirectUrl=${redirectUrl}` : "";
    return `/app/biens/pige?id=${id}${redirectPart}`;
  },
};
