import IconButton from "@material-ui/core/IconButton";
import { PrintOutlined } from "@material-ui/icons";
import React from "react";

const BoutonImpression = ({ onPrint, children }) => (
  <div style={{ display: "flex", alignItems: "center" }} onClick={onPrint}>
    <IconButton style={{ cursor: "pointer" }}>
      <PrintOutlined />
    </IconButton>
    {children}
  </div>
);

export default BoutonImpression;
