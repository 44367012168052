import { RealEstateDto } from "../../../../../services/profiles/dto/RealEstateDto";
import { RealEstatePropertyCardProps } from "UI/features/realEstates/organisms/RealEstatePropertyCard";
import getUrlPhoto from "utils/UrlImage";

export interface RealEstateAdapterOptions {
  realEstate: RealEstateDto;
  clickHandler?: () => void;
  withAgent?: boolean;
}

export const realEstateAdapter: (
  options: RealEstateAdapterOptions
) => RealEstatePropertyCardProps = ({
  realEstate,
  clickHandler: onClick,
  withAgent = false,
}) => {
  const agent = withAgent ? realEstate.conseillers[0] : undefined;

  return {
    page: realEstate.page,
    bedroomsNb: realEstate.nbChambres,
    bu: realEstate.bu,
    businessType: realEstate.typeAffaireEnum,
    cityName: realEstate.localisation.ville,
    cityZipCode: realEstate.localisation.codesPostaux[0] || "",
    id: realEstate.id,
    onClick,
    photo: getUrlPhoto(realEstate.bu, "300x300", realEstate.photoPrincipale),
    price: realEstate.prix,
    propertyType: realEstate.familleBienEnum,
    roomsNb: realEstate.nbPieces,
    surface: realEstate.surface,
    ...(agent && {
      agent: {
        firstName: agent.prenom,
        lastName: agent.nom,
        photo: getUrlPhoto(realEstate.bu, "300x300", agent.photo),
        email: agent.email,
        phoneNumber: agent.telephone,
      },
    }),
  };
};
