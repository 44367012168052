import { AuthenticationService } from "../authentication/authentication";

declare global {
  interface Window {
    gtag: any;
  }
}

export interface ITrackingConfiguration {
  googleTagKey: string;
}

export type BUType = "Capifrance" | "Optimhome" | "Refleximmo";

interface IGoogleCustomDimensions {
  BU: BUType;
  email: string;
}

export interface ITrackingService {
  sendPageViewEventToGoogle: (page_title: string) => void;
  sendCustomEventToGoogle: (
    eventName: string,
    values?: IGoogleCustomDimensions
  ) => void;
}

export function TrackingService(
  trackingConfiguration: ITrackingConfiguration,
  auth: AuthenticationService
): ITrackingService {
  const gtag = window.gtag;
  gtag("config", trackingConfiguration.googleTagKey);

  return {
    sendCustomEventToGoogle(
      eventName: string,
      values?: IGoogleCustomDimensions
    ): void {
      const _values = values || {
        BU: auth.getUserData()?.Instance,
        email: auth.getUserData()?.name,
      };
      gtag("event", eventName, _values);
    },
    sendPageViewEventToGoogle(page_title: string): void {
      gtag("event", "page_view", {
        page_location: page_title,
        page_title,
      });
    },
  };
}
