import { number, array, object, bool } from "../Types";

class ElasticBO {
  constructor() {
    this.aggregation = object;
    this.count = number;
    this.currentPageNumber = number;
    this.data = array;
    this.elapsedMilliseconds = number;
    this.hasMore = bool;
    this.pageSize = number;
    this.stats = object;
    this.total = number;
    this.totalPage = number;
  }
}

export default ElasticBO;
