import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PhotoIcon from "@material-ui/icons/LocalSeeOutlined";
import PlaceIcon from "@material-ui/icons/PlaceOutlined";
import SubjectIcon from "@material-ui/icons/Subject";
import CommerceEntreprise from "UI/shared/atoms/Badges/C&E";
import Exclusivite from "UI/shared/atoms/Badges/Exclusivite";
import Neuf from "UI/shared/atoms/Badges/Neuf";
import Prestige from "UI/shared/atoms/Badges/Prestige";
import { SousOffre } from "UI/shared/atoms/Badges/SousOffre";
import Conseiller from "components/Conseiller";
import BoutonImpression from "UI/shared/molecules/Buttons/BoutonImpression";
import SeeButton from "UI/shared/molecules/Buttons/SeeButton/SeeButton";
import moment from "moment";
import React from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import getUrlPhoto from "utils/UrlImage";

import { SousCompromis } from "../../../../../UI/shared/atoms/Badges/SousCompromis";
import routesNames from "../../../../../router/routesNames";
import { useServices } from "../../../../../services";

const useStyles = makeStyles((theme) => ({
  affFam: {
    color: theme.palette.common.dark,
  },
  bu: {
    borderRadius: 5,
    height: 10,
    marginLeft: 5,
    marginRight: 5,
    width: 10,
  },
  cardContent: {
    marginTop: "10px",
  },
  cardPaper: {
    "&:hover": {
      boxShadow:
        "0px 3px 3px 0px rgba(0,0,0,0.34), 0px 1px 1px -1px rgba(0,0,0,0.1)",
    },
    "&:hover $imageInformation": {
      alignItems: "center",
      background: "#00000053",
      color: "white",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      visibility: "inherit",
    },
  },
  emphase: {
    color: theme.palette.emphase,
  },
  fab: {
    bottom: "50%",
    left: "calc(100% - 30px)",
  },
  image: {
    backgroundImage: ({ pictureSource, pictureUrl }) =>
      `url(${getUrlPhoto(
        pictureSource,
        "300x300",
        pictureUrl
      )}), url("/static/images/no-image-box.png")`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    cursor: "pointer",
    height: ({ innerWidth }) => (innerWidth <= 960 ? 240 : 120),
    overflow: "hidden",
    position: "relative",
  },
  imageInformation: {
    visibility: "hidden",
  },
  legend: {
    background: "rgba(0,0,0,0.4)",
    color: "#fff",
    marginTop: "-50px",
    position: "relative",
    textAlign: "center",
    width: "100%",
  },
  nbPhotos: {
    color: "#fff",
    position: "absolute",
    right: 10,
    textAlign: "center",
    top: -140,
    width: "25%",
  },
  tagItem: {
    paddingRight: "5px",
  },
  texte: {
    height: "30px",
    marginBottom: "10px",
    marginTop: "10px",
    overflow: "hidden",
  },
  typeBienVille: {
    fontSize: "1rem",
  },
}));

const Hit = ({
  numberOfBuyers = null,
  mode,
  innerWidth,
  hit,
  onClick = () => {
    // nothing to do by default
  },
  onPrint = false,
  detailedPageURL = "",
}) => {
  const {
    l10n: { t },
    authenticationService,
  } = useServices();
  const userData = authenticationService.getUserData();
  const classes = useStyles({
    innerWidth,
    pictureSource: hit.bu,
    pictureUrl: hit.photoPrincipale,
  });

  const history = useHistory();

  const handleNbrOfBuyersClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    history.push(
      `${routesNames.app}${routesNames.biens}${routesNames.monBienEnVente}/${hit.id}/acquereurs/view`
    );
  };

  // adapter : 3 modes [biensEnVente, mesBiensEnVente, biensVendus]
  const biensEnVenteMode =
    mode === "mesBiensEnVente" || mode === "biensEnVente";
  const mentionDate = biensEnVenteMode ? "Màj le " : "Vendu le ";

  const dateAffichee = biensEnVenteMode
    ? hit.dateModification
    : hit.dateEffectiveSignature;
  const mentionPrix = biensEnVenteMode ? "" : "FAI inclus";
  // fin adapter

  return (
    <Card
      classes={{
        root: classes.cardPaper,
      }}
      style={{ position: "relative" }}
    >
      <Grid container spacing={0} direction="row" alignItems="center">
        <Grid item xs={6} md={2} onClick={onClick}>
          <Link to={detailedPageURL} style={{ textDecoration: "none" }}>
            <div
              style={{
                backgroundImage: `url(${getUrlPhoto(
                  hit.bu,
                  "300x300",
                  hit.photoPrincipale
                )}), url("/static/images/no-image-box.png")`,
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: innerWidth <= 960 ? 240 : 120,
                overflow: "hidden",
                position: "relative",
              }}
              className={classes.image}
            >
              <Typography className={classes.imageInformation}>
                {t("Voir la fiche détail")}
              </Typography>

              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                style={{
                  backgroundColor: "lightGrey",
                  bottom: 0,
                  position: "absolute",
                  right: 0,
                  width: 30,
                }}
              >
                <PhotoIcon style={{ color: "#000", fontSize: 12 }} />
                <Typography variant="body2" style={{ paddingLeft: 3 }}>
                  {hit.nombrePhotos}
                </Typography>
              </Grid>
            </div>
          </Link>
        </Grid>

        <Grid item md={6} style={{ paddingLeft: 10 }}>
          <Grid container>
            <Grid item xs={6} md={8}>
              <Grid container direction="row" spacing={2} alignItems="center">
                <Grid item xs={6} md={6}>
                  <Typography
                    variant="body1"
                    className={classes.affFam}
                    style={{ marginBottom: -5 }}
                  >
                    {hit.typeAffaireEnum
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .toUpperCase()}
                  </Typography>
                  <Typography variant="body1" className={classes.affFam}>
                    {t(hit.typeBienNom)}
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    style={{
                      display: "inlineFlex",
                      flexWrap: "nowrap",
                      marginLeft: "-3px",
                      verticalAlign: "middle",
                    }}
                  >
                    <PlaceIcon
                      style={{
                        color: "#8E8E8E",
                        fontSize: 16,
                        marginTop: 3,
                      }}
                    />
                    <Typography variant="h4" noWrap style={{ lineHeight: 1.6 }}>
                      {hit.localisation.villeNom.toUpperCase()}{" "}
                      <span
                        style={{
                          fontSize: 10,
                          fontWeight: "regular",
                        }}
                      >{` (${hit.localisation.codePostal})`}</span>
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    {(hit.optionMandatEnum === "Exclusif" ||
                      hit.exclusivite === true) && (
                      <div className={classes.tagItem}>
                        <Exclusivite t={t} />
                      </div>
                    )}
                    {hit.prestige === true && (
                      <div className={classes.tagItem}>
                        <Prestige t={t} />
                      </div>
                    )}
                    {hit.neuf === true && (
                      <div className={classes.tagItem}>
                        <Neuf t={t} />
                      </div>
                    )}
                    {hit.isCE && (
                      <div className={classes.tagItem}>
                        <CommerceEntreprise />
                      </div>
                    )}
                  </Grid>
                </Grid>

                <Grid item md={4}>
                  <Typography
                    variant="h2"
                    align="right"
                    noWrap
                  >{`${hit.prix
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} €`}</Typography>
                  <div
                    style={{
                      alignItems: "flex-end",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                  >
                    {mentionPrix}
                  </div>
                  <Typography
                    variant="body2"
                    align="right"
                  >{`${mentionDate}${moment(dateAffichee).format(
                    "DD/MM/YYYY"
                  )}`}</Typography>
                  {hit.underOfferToSell && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 5,
                      }}
                    >
                      <SousCompromis />
                    </div>
                  )}
                  {hit.hasOffer && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 5,
                      }}
                    >
                      <SousOffre />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid container direction="column" spacing={0}>
                {hit.surface > 0 && (
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                    }}
                  >
                    {`Surface : ${hit.surface} m`}
                    <sup>2</sup>
                  </Typography>
                )}

                {hit.nbPieces > 0 && !hit.isCE && (
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                    }}
                  >
                    {`Pièces : ${hit.nbPieces}`}
                  </Typography>
                )}
                {hit.nbChambres > 0 && (
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                    }}
                  >
                    {`Chambres : ${hit.nbChambres}`}
                  </Typography>
                )}

                {hit.surface > 0 && (
                  <Typography variant="body2">
                    Prix au m<sup>2</sup>{" "}
                    {` : ${Number.parseInt(Math.ceil(hit.prix / hit.surface))
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} €`}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={2}>
          <Grid container direction="row">
            <Grid item xs={6} md={12}>
              {mode === "mesBiensEnVente" ? (
                numberOfBuyers !== null ? (
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h2" align="center">
                      {numberOfBuyers}
                    </Typography>
                    <Typography variant="body2" align="center">
                      {`Acquéreur${numberOfBuyers > 1 ? "s" : ""} potentiel${
                        numberOfBuyers > 1 ? "s" : ""
                      }`}
                    </Typography>
                    {numberOfBuyers > 0 && (
                      <SeeButton
                        style={{ marginTop: "4px" }}
                        $smaller={true}
                        onClick={handleNbrOfBuyersClick}
                      >
                        <Typography variant="body2" align="center">
                          {t("Voir la liste")}
                        </Typography>
                      </SeeButton>
                    )}
                  </div>
                ) : (
                  <CircularProgress
                    style={{
                      marginLeft: "50%",
                    }}
                  />
                )
              ) : (
                <Conseiller
                  conseiller={hit.conseillerPrincipal}
                  t={t}
                  bu={hit.bu}
                  source={mode}
                  userData={userData}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={2}>
          <Grid container justify="flex-end" style={{ paddingRight: 20 }}>
            <IconButton onClick={onClick} style={{ cursor: "pointer" }}>
              <SubjectIcon />
            </IconButton>

            <BoutonImpression onPrint={onPrint} />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default withRouter(withTheme(Hit));
