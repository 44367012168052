import { Button } from "@material-ui/core";
import MailIcon from "@material-ui/icons/MailOutline";
import useNotifier from "components/Notifier/useNotifier";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useServices } from "services";

import { useSendEmailToSupport } from "../../../../../services/office365/useSendEmailToSupport";
import StyledDialog from "../../../../../components/StyledDialog/index";
import { contactSupport } from "./contactSupport";
import { FormMessage } from "./FormMessage";
import { getBrowserInfos } from "./utils/getBrowserInfos";
import { getScreenshot } from "./utils/getScreenshot";

export const ContactSupportButton: React.FC = () => {
  const [message, setMessage] = useState<string>("");
  const {
    l10n: { t },
    authenticationService: { getUserData },
  } = useServices();
  const { send } = useSendEmailToSupport();
  const location = useLocation();
  const { showSnack } = useNotifier();

  const { dialogComponent, switchStatus } = StyledDialog({
    content: <FormMessage onChange={setMessage} />,
  });

  const askForMessage = () => switchStatus();

  useEffect(() => {
    if (message.length === 0) return;
    switchStatus();
    if (message === "CLOSE_MODALE") {
      return setMessage("");
    }
    const appInfos = {
      agentLoggedIn: `${getUserData().given_name} ${
        getUserData().family_name
      }, <${getUserData().email}>`,
      pathname: location.pathname,
    };

    getScreenshot()
      .then((response) => {
        return contactSupport({
          appInfos,
          browserInfos: getBrowserInfos(),
          message,
          screenshot: response,
          send,
        }).then((messageSent) => {
          if (messageSent) {
            showSnack({
              message: t("Votre demande de support a bien été envoyée."),
              variant: "success",
            });
          } else {
            showSnack({
              message: t(
                "Votre demande de support a rencontré un problème. Veuillez contacter le support téléphonique."
              ),
              variant: "error",
            });
          }
        });
      })
      .catch((e) => {
        return contactSupport({
          appInfos,
          browserInfos: getBrowserInfos(),
          message,
          screenshot: "",
          send,
        }).then((messageSent) => {
          if (messageSent) {
            showSnack({
              message: t(
                "Erreur de capture d'écran, toutefois votre demande de support a bien été envoyée."
              ),
            });
          } else {
            showSnack({
              message: t(
                "Votre demande de support a rencontré un problème. Veuillez contacter le support téléphonique."
              ),
              variant: "danger",
            });
          }
        });
      });
  }, [contactSupport, message]);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={askForMessage}
      >
        <MailIcon
          style={{
            color: "#E7C534",
            height: "18px",
            marginRight: "3px",
            width: "18px",
          }}
        />
        {t("Mail à la DRC")}
      </Button>
      {dialogComponent}
    </>
  );
};
