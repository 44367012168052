import { Chip } from "@material-ui/core";
import React from "react";

import { PhoneNumber } from "../../../../../components/RefinementField/types";
import { RefinementChipProps } from "../RefinementChip";

export const PhoneNumberChip = ({
  value,
  format,
  onChange,
}: RefinementChipProps<PhoneNumber>) => (
  <>
    {(value.value || "").trim().length > 0 && (
      <Chip
        style={{ marginBottom: 6, marginRight: 6 }}
        label={format(value.value)}
        size="small"
        onDelete={() => onChange({ kind: "phone-number", value: "" })}
      />
    )}
  </>
);
