import { number, string } from "api/DTOs/Common/Types";

class ClassementBO {
  constructor() {
    this.cible = string;
    this.nombreConseillers = number;
    this.classementCA = number;
    this.classementMandats = number;
    this.classementPourcentageExclu = number;
  }
}

export default ClassementBO;
