import {
  Button,
  Divider,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";

import { useServices } from "../../../../../services";

interface FormMessageProps {
  onChange: (value: string) => void;
}

const useStyles = makeStyles(() => ({
  paragraph: {
    paddingBottom: 5,
    paddingTop: 5,
  },
}));

export const FormMessage: React.FC<FormMessageProps> = ({ onChange }) => {
  const [messageLocal, setMessageLocal] = useState<string>("");
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles();

  const onValidate = () => {
    onChange(messageLocal);
  };

  const onClose = () => {
    onChange("CLOSE_MODALE");
  };

  return (
    <>
      <Typography variant="h2">{t("Mail à la DRC")}</Typography>
      <Typography variant="h3" className={classes.paragraph}>
        {t("Ce formulaire envoie un mail à la DRC.")}
      </Typography>
      <Typography variant="subtitle1" className={classes.paragraph}>
        {t(
          "Afin de faciliter le traitement de vos retours, des informations techniques seront automatiquement transmises (navigateur utilisé, résolution d'écran, page de l'application concernée, etc.)."
        )}
      </Typography>
      <Typography variant="subtitle1" className={classes.paragraph}>
        {t(
          "Une capture d'écran de TremplinX sera automatiquement ajoutée en pièce-jointe du mail."
        )}
      </Typography>
      <Typography variant="subtitle1" className={classes.paragraph}>
        {t(
          "Vous pourrez retrouver ce mail dans votre dossier [Envoyés] Outlook."
        )}
      </Typography>
      <Typography variant="subtitle1" className={classes.paragraph}>
        {t("Saisissez votre message :")}
      </Typography>
      <TextField
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        onChange={(e) => {
          setMessageLocal(e.target.value);
        }}
        fullWidth
        multiline
        rows={5}
        variant="outlined"
        label={"Descriptif"}
      >
        {messageLocal}
      </TextField>
      <Divider style={{ marginBottom: 10, marginTop: 10 }} />
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}
      >
        <div style={{ marginRight: 10 }}>
          <Button variant="outlined" color="primary" onClick={onClose}>
            {t("ANNULER")}
          </Button>
        </div>
        <Button variant="contained" color="primary" onClick={onValidate}>
          {t("CONFIRMER")}
        </Button>
      </div>
    </>
  );
};
