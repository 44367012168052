import { ArchiveOutlined, Notes, UnarchiveOutlined } from "@material-ui/icons";
import EditOutlined from "@material-ui/icons/EditOutlined";
import {
  ActionMenuItemProps,
  ActionsMenu,
} from "UI/shared/organisms/ActionsMenu";
import { ConfirmationDialog } from "UI/shared/organisms/ConfirmationDialog";
import React from "react";
import routesNames from "router/routesNames";
import { useServices } from "services";
import { usePortfolioSearch } from "services/portfolio/usePortfolioSearch";
import { useProfiles } from "services/profiles";
import { ProfileItemDto } from "services/profiles/dto/ReadModelProfileDto";
import { useReadModelProfiles } from "services/profiles/readModelProfilesContext";

import { Form } from "./Form";
import { ForSaleForm } from "./ForSaleForm";

export const ProfileMenu: React.FC<{
  profile: ProfileItemDto;
  contactId: string;
  updateLocalContact: () => void;
}> = ({ profile, contactId, updateLocalContact }) => {
  const {
    modal,
    l10n: { t },
  } = useServices();
  const { archive, reactivate } = useProfiles(contactId);
  const menuOptions: ActionMenuItemProps[] = [];

  const [openDialog, setOpenDialog] = React.useState(false);

  const { getContact } = usePortfolioSearch();
  const { getById } = useReadModelProfiles();

  const handleClickOpen = () => {
    getContact(contactId).then((_) => {
      if (_?.status === "Archived") setOpenDialog(true);
      else {
        reactivate(profile.id, contactId);
      }
    });
  };

  const handleCancel = () => {
    setOpenDialog(false);
  };

  const reactivateProfile = async (profileId: string, contactId: string) => {
    await reactivate(profileId, contactId);
    updateLocalContact();
  };

  const handleConfirmation = () => {
    setOpenDialog(false);
    reactivateProfile(profile.id, contactId);
  };

  menuOptions.push({
    action: () => {
      window.open(
        routesNames.profilePageTab(contactId, profile.id, "details"),
        "_self"
      );
    },
    icon: <Notes />,
    label: "Consulter",
  });

  if (profile.archivingDate) {
    menuOptions.push({
      action: () => handleClickOpen(),
      icon: <UnarchiveOutlined />,
      label: "Réactiver",
    });
  } else {
    menuOptions.push({
      action: async () => {
        try {
          const { data } = await getById(profile.id, profile.contactId);

          modal.show(
            profile.kind.match("Sale") ? (
              <ForSaleForm
                contactId={contactId}
                profile={data?.profile}
                mode={"update"}
              />
            ) : (
              <Form
                contactId={contactId}
                profile={data?.profile}
                mode="update"
              />
            )
          );
          // eslint-disable-next-line no-empty
        } catch (e) {}
      },
      icon: <EditOutlined />,
      label: "Modifier",
    });
    menuOptions.push({
      action: () => {
        archive(profile.id, contactId);
      },
      icon: <ArchiveOutlined />,
      label: "Archiver",
    });
  }

  return (
    <>
      <ConfirmationDialog
        text={t("ReactivationProfil")}
        handleCancel={handleCancel}
        handleConfirmation={handleConfirmation}
        openDialog={openDialog}
      />
      <ActionsMenu menuOptions={menuOptions} />
    </>
  );
};
