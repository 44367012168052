import React from "react";
import { ActionTypes } from "react-select";
import { useGeoloc } from "services/geoloc/useGeoloc";

import { RefinementReactSelect } from "../LocationsRefinement";
import {
  GroupSelectOptions,
  SelectOption,
  toSelectOptions,
  Zone,
} from "../LocationsRefinement/toSelectOptions";
import { Cities, LocationItem, Refinement } from "../types";

interface SelectCitiesProps {
  value: Cities;
  onChange: (value: Refinement) => void;
  label: string;
}

export interface SelectedValue {
  kind: "SelectedValue";
  label: string;
  value: string;
}

const toSelectedValue = ({
  label,
  value,
}: {
  label: string;
  value: string;
}): SelectedValue => ({
  kind: "SelectedValue",
  label,
  value,
});

function toCityValues(location: Cities): SelectedValue[] {
  return location.value.cities.filter((v) => v.isRefined).map(toSelectedValue);
}

const getDistinctAndRefined = (items: LocationItem[]) =>
  [...Array.from(new Map(items.map((v) => [v.value, v])).values())].filter(
    (item) => item.isRefined
  );

const getNextCityState = (currentValue: Cities, zone: Zone) => {
  const nextLocationState = (cities: LocationItem[]) => {
    return {
      ...currentValue,
      value: {
        ...currentValue.value,
        cities: getDistinctAndRefined(cities),
      },
    };
  };

  switch (zone.typeGeoEnum) {
    case "Ville":
      return nextLocationState([
        ...currentValue.value.cities,
        { isRefined: true, label: zone.nom, value: zone.id },
      ]);

    case "VilleAvecArrondissements":
      return nextLocationState([
        ...currentValue.value.cities,
        ...(zone.raw ?? []).map(({ id, nom }) => ({
          isRefined: true,
          label: nom,
          value: id,
        })),
      ]);

    default:
      return currentValue;
  }
};

function toCitiesRefinement(
  value: Cities,
  action: ActionTypes,
  options: (SelectOption | SelectedValue)[]
): Cities {
  switch (action) {
    case "select-option":
      {
        const selectedOption = options.find(
          (option) => option.kind === "SelectOption"
        ) as SelectOption;

        if (selectedOption) {
          const nextLocationValue = getNextCityState(
            value,
            selectedOption.zone
          );
          return nextLocationValue as Cities;
        }
      }
      return value;
    case "clear":
      return {
        kind: "cities",
        value: { cities: [] },
      };

    case "deselect-option":
    case "remove-value":
    case "pop-value":
    case "set-value":
    case "create-option":
      return value;
  }
}

function toCityOptions(options: Zone[]): GroupSelectOptions[] {
  const t = (_: string) => _;
  return toSelectOptions(options).filter((o) => o.label === t("Villes"));
}

export const CityRefinement: React.FC<SelectCitiesProps> = ({
  label,
  onChange,
  value,
}) => {
  const { search } = useGeoloc();

  const getOptions = (criteria: string) => {
    return search({ autocomplete: criteria, size: 10 }).then((r) => {
      return toCityOptions(r);
    });
  };

  return (
    <RefinementReactSelect
      shouldGatherSelection={true}
      label={label}
      value={toCityValues(value)}
      options={getOptions}
      onChange={(action, options) => {
        onChange(toCitiesRefinement(value, action, options));
      }}
    />
  );
};
