import useHTTPService from "services/dataSources/communicationServices/useHTTPService";

import { useServices } from "../index";
import { pigeBienDetailAdapter } from "./adapters/detailBienPigeAdapter";

interface UseDetailBienPigeAPIProps {
  params: { id: string };
  erreur: boolean;
}

export const useDetailBienPige = ({
  params,
  erreur = false,
}: UseDetailBienPigeAPIProps) => {
  const { endpoints } = useServices();
  const { status, adaptedData } = useHTTPService({
    adapter: pigeBienDetailAdapter,
    endpoint: endpoints.pige.detail(params.id),
    params: null,
  });
  return { items: adaptedData, status: status };
};
