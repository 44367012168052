import { Grid, Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import {
  Group,
  PersonPinCircle,
  PersonPinCircleOutlined,
} from "@material-ui/icons";
import Map from "components/MapX/Map";
import { flattenGeolocRefinementsValues } from "components/MapX/Map/utils/flattenGeolocRefinementsValues";
import TitrePage from "components/TitrePage";
import DisplayMode from "layoutContents/Biens/components/BiensDisplayMode";
import RenderHits from "layoutContents/components/HitsContainer/renderHits";
import {
  pageSizeFromMode,
  serialize,
} from "layoutContents/components/HitsContainer/utils";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import routesNames from "router/routesNames";
import { useServices } from "services";
import rechercheCarteAdapter from "services/conseillers/dto/Conseillers/RechercheCarte/rechercheCarteAdapter";
import rechercheGridOuListeAdapter from "services/conseillers/dto/Conseillers/RechercheGridOuListe/rechercheGridOuListeAdapter";
import { useTxHttp } from "services/http";
import useInfiniteScroll from "services/useInfiniteScroll";
import useItemsSearch from "services/useItemsSearch";
import { ErrorPanel } from "UI/shared/templates/Panels/ErrorPanel";
import { worldBoundingBox } from "utils/formatage";
import { useBaseStyles as useStyles } from "../../biens/style/styleBase";
import {
  computedHitsStyles,
  computedMapPaneBreakpoint,
  computedTotalItemsTitle,
  isSmallList,
  isSmallScreenMap,
} from "../../biens/utils/formatter";
import { getScrollToTopButton } from "../../biens/utils/getScrollToTopButton";
import { getToggleHitsPaneButton } from "../../biens/utils/getToggleHitsPaneButton";
import CarrouselConseillers from "./components/carrouselConseillers";
import ContextualSideBar from "./components/ContextualSideBar";
import { createAgentsMarkers } from "./components/MapComponents/createAgentsMarkers";
import Searchbar from "./components/Searchbar";
import { initialState, reducer } from "./logic/reducer";
import { handleRefinementsFromParameters } from "./logic/refinements";
import { Refinements } from "./models/refinements";
import { filterOptions, formatOptionsListeConseillers } from "./utils";

const _Searchbar = (props) => <Searchbar {...props} />;
const SNAPSHOT_KEY = "Snapshot State Conseillers";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Conseillers = (props) => {
  const { endpoints } = useServices();
  const { params, mode } = useParams();
  // eslint-disable-next-line react/prop-types
  const { history } = props;
  const { get } = useTxHttp();

  const [hideHitPane, setHideHitPane] = useState(false);
  const toggleHitPane = () => {
    setHideHitPane(!hideHitPane);
  };

  const mobile = useMediaQuery("(max-width: 768px)");

  async function fetchAgentsMapData() {
    dispatch({ payload: true, type: "setLoading" });
    const refinementsParams = {
      ...state.refinements.createParams(state.rechercheBounds),
      ...state.boundingBox,
    };
    const points = await get(
      endpoints.conseillers.rechercheCarte,
      refinementsParams
    );
    if (points.data) return rechercheCarteAdapter(points.data);
    return [];
  }

  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();

  const [state, dispatch] = useReducer(reducer, initialState);
  const containerHeight = useMemo(() => window.innerHeight - 150, []);

  useEffect(() => {
    if (state.displayMode === "map")
      fetchAgentsMapData().then((responsePoints) => {
        dispatch({ payload: responsePoints.data, type: "setMapPoints" });
      });
  }, [state.refinements, state.boundingBox, state.displayMode]);

  const resetPageNumber = () => {
    dispatch({ payload: 1, type: "changePage" });
  };

  const onUpdateLocalisations = useCallback(
    ({ regions, departements, villes, quartiers }) => {
      const _refinements = { ...state.refinements };
      _refinements.update("regions", regions);
      _refinements.update("departements", departements);
      _refinements.update("villes", villes);
      _refinements.update("quartiers", quartiers);
      const parameters = serialize({
        refinements: _refinements.toURLParams(),
      });

      dispatch({ payload: _refinements, type: "setRefinements" });
      history.push({
        pathname: `${routesNames.app}${routesNames.monReseau}${routesNames.conseillers}/${state.displayMode}/${parameters}`,
      });
    },
    [state.refinements, state.displayMode, history]
  );

  const onUpdateRefinements = useCallback(
    (query, value) => {
      const _refinements = { ...state.refinements };
      _refinements.update(query, value);
      const parameters = serialize({
        refinements: _refinements.toURLParams(),
      });

      dispatch({ payload: _refinements, type: "setRefinements" });

      history.push({
        pathname: `${routesNames.app}${routesNames.monReseau}${routesNames.conseillers}/${state.displayMode}/${parameters}`,
      });
    },
    [state.refinements, state.displayMode, history]
  );
  const onUpdateSorting = useCallback(
    ({ sortOptions }) => {
      const _refinements = { ...state.refinements };

      sortOptions.forEach(({ query, value }) => {
        _refinements.update(query, value);
      });
      const parameters = serialize({
        refinements: _refinements.toURLParams(),
      });
      history.push({
        pathname: `${routesNames.app}${routesNames.monReseau}${routesNames.conseillers}/${state.displayMode}/${parameters}`,
      });
      dispatch({ payload: _refinements, type: "setRefinements" });
    },
    [history, state.displayMode, state.refinements]
  );

  const clearRefinements = () => {
    const _refinements = { ...state.refinements };
    _refinements.clear();
    dispatch({ payload: _refinements, type: "setRefinements" });
    history.push({
      pathname: `${routesNames.app}${routesNames.monReseau}${routesNames.conseillers}/${state.displayMode}/full-list`,
    });
  };

  const setDisplayMode = useCallback(
    (_mode) => {
      const parameters = serialize({
        refinements: state.refinements.toURLParams(),
      });
      history.push({
        pathname: `${routesNames.app}${routesNames.monReseau}${routesNames.conseillers}/${_mode}/${parameters}`,
      });
      dispatch({ payload: _mode, type: "setDisplayMode" });
    },
    [history, state.refinements]
  );

  const setOpenDrawer = useCallback(() => {
    dispatch({ type: "toggleDrawer" });
  }, []);

  const handleDisplayOfDetailedItem = (item) => {
    dispatch({ payload: item.items, type: "displayCarrousel" });
    return new Promise(() => ({
      circleRadius: 0,
      defer: true,
      html: undefined,
    }));
  };

  const handleCarrouselClose = () => {
    dispatch({ type: "hideCarrousel" });
  };

  const handleMapConfigSave = ({
    boundingBox,
    center,
    shouldUpdateMapBounds,
    zoom,
  }) => {
    const newState = {
      ...state,
      boundingBox,
      center,
      shouldUpdateMapBounds,
      zoom,
    };
    sessionStorage.setItem(SNAPSHOT_KEY, serialize(newState));
    dispatch({
      payload: {
        center,
        shouldUpdateMapBounds,
        zoom,
      },
      type: "saveMapConfig",
    });
  };

  const handleMapInitialization = ({ map }) => {
    dispatch({
      payload: {
        map,
      },
      type: "initializeMap",
    });
  };

  useEffect(() => {
    const size = () => dispatch({ type: "setSmAndDown" });
    window.addEventListener("resize", size);
    handleRefinementsFromParameters(
      params,
      mode,
      worldBoundingBox,
      Refinements,
      dispatch,
      SNAPSHOT_KEY
    );

    return function cleanup() {
      window.removeEventListener("resize", size);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchbar = _Searchbar({
    displayMode: state.displayMode,
    onChange: onUpdateRefinements,
    onClickMore: () => {
      dispatch({ type: "toggleDrawer" });
    },
    onUpdateLocalisations,
    refinements: state.refinements.toObject(),
    t,
  });

  if (isSmallList(window.innerWidth, state.displayMode))
    setDisplayMode("gallery");

  const { error, hasMore, loading, items } = useItemsSearch({
    adapter: rechercheGridOuListeAdapter,
    boundingBox: state.displayMode === "map" ? state.boundingBox : null,
    pageNumber: state.page,
    pageSize: pageSizeFromMode.get(state.displayMode).size,
    rechercheBounds: state.rechercheBounds,
    refinements: state.refinements,
    resetPageNumber,
    url: endpoints.conseillers.rechercheGridOuListe,
  });

  const handleChangePage = useCallback(
    function () {
      dispatch({ payload: state.page + 1, type: "changePage" });
    },
    [state.page]
  );

  const setFirstIntersection = useCallback(function (intersection) {
    dispatch({ payload: intersection, type: "firstIntersection" });
  }, []);

  const { firstRef, triggerRef } = useInfiniteScroll({
    handleChangePage,
    hasMore,
    loading,
    setFirstIntersection,
  });

  // ====================================

  const hitsRef = React.useRef(null);
  let hitsToDisplay = !isSmallScreenMap(state) ? (
    <Grid
      style={computedHitsStyles(state.displayMode, containerHeight)}
      item
      xs
      ref={hitsRef}
    >
      <RenderHits
        onHitClick={handleDisplayOfDetailedItem}
        filterOptions={filterOptions(t)}
        onUpdateSorting={onUpdateSorting}
        clearRefinements={clearRefinements}
        containerHeight={containerHeight}
        displayMode={state.displayMode}
        firstRef={firstRef}
        triggerRef={triggerRef}
        loading={loading}
        onUpdateRefinements={onUpdateRefinements}
        items={items}
        refinements={state.refinements}
        searchbar={searchbar}
        smAndDown={state.smAndDown}
        setOpenDrawer={setOpenDrawer}
        mode={"conseillers"}
      />
    </Grid>
  ) : (
    <></>
  );

  const setBoundingBox = useCallback(({ boundingBox, rechercheBounds }) => {
    dispatch({
      payload: { boundingBox, rechercheBounds },
      type: "updateBoundingBox",
    });
  }, []);

  if (error)
    return (
      <ErrorPanel title={"Conseillers"} text={"Serveur non disponible."} />
    );

  return (
    <div className={classes.root}>
      <TitrePage
        categorie={t("Mon Réseau")}
        page={t("Conseillers")}
        nbResultats={computedTotalItemsTitle(t, items.total)}
        filePath={"/static/markdowns/conseillers.md"}
        Composant={() => (
          <DisplayMode onChange={setDisplayMode} selected={state.displayMode} />
        )}
      />
      <Grid container spacing={2}>
        {state.displayMode === "map" && (
          <Grid
            item
            xs={12}
            md={computedMapPaneBreakpoint(hideHitPane)}
            className={classes.toggleSidePaneButtonContainer}
          >
            <Hidden mdUp>{searchbar}</Hidden>
            <Map
              points={state.mapPoints}
              onMarkerClick={handleDisplayOfDetailedItem}
              onMapConfigSave={handleMapConfigSave}
              onMapInitialization={handleMapInitialization}
              zoom={state.zoom}
              sendBoundingBoxToParent={setBoundingBox}
              center={state.center}
              shouldUpdateMapBounds={state.shouldUpdateMapBounds}
              t={t}
              toggleBoundsSearchLabel={`Voir les conseillers autour de ma zone`}
              selectedLocations={flattenGeolocRefinementsValues({
                refinements: state.refinements,
              })}
              loading={state.loading}
              bottomLeftPanel={<AgentsMapLegend />}
              createMarkers={createAgentsMarkers}
              fullscreen={hideHitPane}
            />
            {getToggleHitsPaneButton(
              hideHitPane,
              toggleHitPane,
              mobile,
              classes
            )}
            <Dialog
              onClose={handleCarrouselClose}
              open={state.carrousel.length > 0}
              scroll={"body"}
            >
              <CarrouselConseillers items={state.carrousel} />
            </Dialog>
          </Grid>
        )}
        {!hideHitPane && hitsToDisplay}
      </Grid>
      {getScrollToTopButton(
        state.firstIntersection,
        state.displayMode,
        classes,
        hitsRef.current
      )}
      <ContextualSideBar
        totalItems={items.total}
        drawer={state.drawer}
        setOpenDrawer={setOpenDrawer}
        refinements={state.refinements}
        clearRefinements={clearRefinements}
        t={t}
        onUpdateRefinements={onUpdateRefinements}
        onUpdateLocalisations={onUpdateLocalisations}
        classes={classes}
        endPointConseiller={endpoints.conseillers.rechercheConseiller}
        formatOptionsListe={formatOptionsListeConseillers}
      />
    </div>
  );
};

const AgentsMapLegend = () => {
  const theme = useTheme();
  const colorStyle = {
    color: theme.palette.default,
  };
  const containerStyle = {
    alignItems: "center",
    display: "flex",
    marginRight: "20px",
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={containerStyle}>
        <PersonPinCircle style={{ fontSize: "24px" }} />
        <Typography variant="h6" style={colorStyle}>
          Conseillers
        </Typography>
      </div>
      <div style={containerStyle}>
        <PersonPinCircleOutlined style={{ fontSize: "24px" }} />
        <Typography variant="h6" style={colorStyle}>
          STAFF
        </Typography>
      </div>
      <div style={{ alignItems: "center", display: "flex" }}>
        <Group style={{ fontSize: "24px", marginRight: "5px" }} />
        <Typography variant="h6" style={colorStyle}>
          Groupe
        </Typography>
      </div>
    </div>
  );
};
