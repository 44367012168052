import { Avatar, Card, Grid, Typography } from "@material-ui/core";
import { BU } from "components/BU";
import React, { CSSProperties } from "react";
import { useHistory } from "react-router-dom";
import { If } from "UI/shared/atoms/directives/If";
import { HorizontalDivider } from "UI/shared/atoms/HorizontalDivider";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import { Text12pxBold } from "UI/shared/atoms/typographies/Text12pxBold";
import { EmailLink } from "UI/shared/molecules/EmailLink";
import { PhoneLink } from "UI/shared/molecules/PhoneLink";

import Neuf from "../../../../shared/atoms/Badges/Neuf/index";
import Prestige from "../../../../shared/atoms/Badges/Prestige";
import { SousCompromis } from "../../../../shared/atoms/Badges/SousCompromis";
import { SousOffre } from "../../../../shared/atoms/Badges/SousOffre";
import { Text16pxBold } from "../../../../shared/atoms/typographies/Text16pxBold";
import { BusinessTypeCaption } from "../../../../shared/molecules/BusinessTypeCaption";
import { PriceCaption } from "../../../../shared/molecules/PriceCaption";
import { PropertyTypeCaption } from "../../../../shared/molecules/PropertyTypeCaption";
import { LocationCaption } from "../../../../shared/organisms/LocationCaption";
import { SurfaceBedroomsNbRoomsNbCaption } from "../../../../shared/organisms/SurfaceBedroomsNbRoomsNbCaption";
import { useStyles } from "./styles";

interface RealEstateAgentProps {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  photo: string;
}

export interface RealEstatePropertyCardProps {
  page?: number;
  id?: string;
  bu?: string;
  surface: number;
  bedroomsNb?: number;
  roomsNb?: number;
  price: number;
  cityName: string;
  cityZipCode?: string;
  businessType: string;
  propertyType: string;
  isNew?: boolean;
  isPrestige?: boolean;
  photo?: string;
  onClick?: () => void;
  listeTags?: JSX.Element[];
  actions?: JSX.Element[];
  agent?: RealEstateAgentProps;
  isUnderOfferToSell?: boolean;
  hasOffer?: boolean;
}

const photoCSS: CSSProperties = {
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  cursor: "auto",
  height: 150,
  position: "relative",
};

const RealEstateAgent: React.FC<RealEstateAgentProps> = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  photo,
}) => {
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={2}>
        <Avatar alt={`${firstName} ${lastName}`} src={photo} />
      </Grid>
      <Grid item xs={10}>
        <Grid container>
          <Grid item xs={12}>
            <Text16pxBold>
              {firstName} {lastName}
            </Text16pxBold>
          </Grid>
          <Grid item xs={12}>
            <EmailLink value={email} textSize="small" />
          </Grid>
          <Grid item xs={12}>
            <PhoneLink value={phoneNumber} textSize="small" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const RealEstatePropertyCard: React.FC<RealEstatePropertyCardProps> = ({
  bu,
  surface,
  bedroomsNb,
  roomsNb,
  price,
  cityName,
  cityZipCode,
  businessType,
  propertyType,
  isNew,
  isPrestige,
  photo,
  onClick,
  listeTags,
  agent,
  isUnderOfferToSell,
  hasOffer,
}) => {
  const isClickable = onClick !== undefined;
  const classes = useStyles({ isClickable });
  const mainPhoto =
    photo !== undefined ? photo : "/static/images/no-image-box.png";

  return (
    <Card onClick={onClick} className={classes.root}>
      <Grid container spacing={0} direction="row" alignItems="center">
        <Grid item xs={12}>
          <div
            style={{
              backgroundImage: `url(${mainPhoto}), url("/static/images/no-image-box.png")`,
              ...photoCSS,
              cursor: isClickable ? "pointer" : "auto",
            }}
          >
            {isClickable && (
              <Typography className={classes.imageInformation}>
                {"Voir la fiche détail"}
              </Typography>
            )}
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                left: 7,
                position: "absolute",
                top: 2,
                width: 150,
              }}
            >
              <Grid item xs={12}>
                {isNew && <Neuf />}
              </Grid>
              <Grid item xs={12}>
                {isPrestige && <Prestige />}
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} className={classes.propertyInfosContainer}>
          <Grid item xs={12}>
            <div style={{ display: "flex" }}>
              <BusinessTypeCaption type={businessType} /> &nbsp;
              <PropertyTypeCaption type={propertyType} />
            </div>
          </Grid>
          <LocationCaption city={cityName} zipcode={cityZipCode || ""} />
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <PriceCaption price={price} />
                  {isUnderOfferToSell && (
                    <div style={{ marginLeft: 5 }}>
                      <SousCompromis />
                    </div>
                  )}
                  {hasOffer && (
                    <div style={{ marginLeft: 5 }}>
                      <SousOffre />
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <SurfaceBedroomsNbRoomsNbCaption
                  livingArea={surface}
                  bedRoomsNumber={bedroomsNb}
                  roomsNumber={roomsNb}
                />
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 5,
                  }}
                >
                  {listeTags && listeTags.length > 0 ? (
                    listeTags.map((tag: JSX.Element, index: number) => (
                      <div key={index} className={classes.tagItem}>
                        {tag}
                      </div>
                    ))
                  ) : (
                    <div style={{ height: 24 }} />
                  )}
                  {bu && <BU bu={bu} />}
                </div>
              </Grid>
              <If condition={agent != null}>
                <VerticalSpace size={8} />
                <HorizontalDivider color={"black"} height={1} />
                <VerticalSpace size={8} />
                <RealEstateAgent {...agent!} />
              </If>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
