export const isDefaultValue = (value: any) => {
  if (!value) return true;
  if (typeof value === "string") {
    return value === "";
  }
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  if (typeof value === "object") {
    return (Object.keys(value as { min?: Date; max?: Date }) as (keyof {
      min?: Date;
      max?: Date;
    })[]).every((subKey) => !value[subKey]);
  }
};
