import { Grid, useMediaQuery } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {
  AccountBoxOutlined,
  HomeWorkOutlined,
  PeopleAltOutlined,
} from "@material-ui/icons";
import CarteContact from "components/CarteContact";
import { Carousel } from "UI/shared/organisms/Carousel";
import CarrouselBiens from "layoutContents/components/CarrouselBiens";
import TitrePanneau from "layoutContents/components/TitrePanneau";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { pigeRoutes } from "router/pigeRoutes";
import routesNames from "router/routesNames";
import CarteBienReduite from "routes/app/biens/pige/FicheBienPige/CarteBienReduite";
import CarteConseiller from "routes/app/mon-reseau/conseillers/components/Hit/components/CarteConseiller";
import useRechercheGlobale from "../services/useRechercheGlobale";

const getPigeItemCard = (
  {
    id,
    estActive,
    surface,
    nbPieces,
    prix,
    ville,
    typeBien,
    typeAnnonce,
    photoPrincipaleUrl,
    codePostal,
  },
  onRedirect = (redirectUrl) => {
    return;
  }
) => {
  const cardProps = {
    adId: id,
    estActive,
    nbPieces,
    onGlobalSearchDisplay: true,
    photo: photoPrincipaleUrl,
    prix,
    surface,
    typeAffaire: typeAnnonce,
    typeBien,
    ville,
    villeCodePostal: codePostal,
    villeNom: ville,
  };

  return (
    <div style={{ padding: 10, width: 200 }}>
      <CarteBienReduite
        {...cardProps}
        onClick={() => {
          const redirect = routesNames.dashboard;
          const url = pigeRoutes.toDetail(id, redirect);
          onRedirect(url);
        }}
      />
    </div>
  );
};

const PanneauRechercheGlobale = ({
  fullText,
  url,
  urlGridOuListe = "",
  pageSize,
  pageNumber,
  adapter,
  mode,
  history,
  setCompteur = () => {},
  onItemClick = () => {},
}) => {
  const { items } = useRechercheGlobale({
    adapter,
    fullText,
    pageNumber,
    pageSize,
    url,
  });

  let entete = { sousTitre: "rien", titre: "aucun titre" };
  let hits = <></>;
  let Icone = AccountBoxOutlined;

  const voirTout = (e) => {
    history.push(urlGridOuListe);
  };

  useEffect(() => {
    if (items.data) setCompteur(items.total);
    // eslint-disable-next-line
  }, [items]);

  const tablette = useMediaQuery("(max-width: 961px)");
  const redirect = (nextUrl) => {
    history.push(nextUrl);
  };

  switch (mode) {
    case "biensEnVente":
      entete = {
        sousTitre: `${items.data ? items.data.length : 0} premiers résultats`,
        titre: `Biens Disponibles (${items.data ? items.total : 0})`,
      };
      Icone = HomeWorkOutlined;
      hits = (
        <CarrouselBiens
          items={items}
          onItemClick={onItemClick}
          mode={"biensEnVente"}
        />
      );
      break;
    case "biensVendus":
      entete = {
        sousTitre: `${items.data ? items.data.length : 0} premiers résultats`,
        titre: `Biens Vendus (${items.data ? items.total : 0})`,
      };
      Icone = HomeWorkOutlined;
      hits = (
        <CarrouselBiens
          items={items}
          onItemClick={onItemClick}
          mode={"biensVendus"}
        />
      );
      break;
    case "pige":
      entete = {
        sousTitre: `${items.data ? items.data.length : 0} premiers résultats`,
        titre: `Biens Pige (${items.data ? items.total : 0})`,
      };
      Icone = HomeWorkOutlined;
      hits = (
        <Carousel
          items={
            items.data &&
            items.data.map((item) => <>{getPigeItemCard(item, redirect)}</>)
          }
          onItemClick={onItemClick}
        />
      );
      break;
    case "conseillers":
      entete = {
        sousTitre: `${items.data ? items.data.length : 0} premiers résultats`,
        titre: `Conseillers (${items.data ? items.total : 0})`,
      };
      Icone = PeopleAltOutlined;
      hits = items.data ? (
        <Grid container spacing={1}>
          {items.data.map((conseiller) =>
            !tablette ? (
              <Grid key={conseiller.id} item xs={12}>
                <CarteConseiller type={"liste"} item={conseiller} mini />
              </Grid>
            ) : (
              <Grid key={conseiller.id} item xs={12} sm={6}>
                <CarteConseiller type={"galerieMini"} item={conseiller} />
              </Grid>
            )
          )}
        </Grid>
      ) : (
        <></>
      );
      break;
    case "contacts":
      entete = {
        sousTitre:
          items.data && items.data.length > 1
            ? `${items.data.length} premiers résultats`
            : "",
        titre: `Contacts (${items.data ? items.total : 0})`,
      };
      Icone = AccountBoxOutlined;
      hits = items.data ? (
        <Grid container spacing={1}>
          {items.data.map((contact) => (
            <Grid key={contact.id} item xs={12} sm={6} lg={4}>
              <div onClick={() => onItemClick(contact)}>
                <Paper elevation={0}>
                  <CarteContact contact={contact} avatar={true} />
                </Paper>
              </div>
            </Grid>
          ))}
        </Grid>
      ) : (
        <></>
      );
      break;
    default:
      hits = <></>;
  }

  return (
    <PanelRechercheGlobale entete={entete} Icone={Icone} voirTout={voirTout}>
      <>{items.data && hits}</>
    </PanelRechercheGlobale>
  );
};

const PanelRechercheGlobale = ({
  Icone,
  entete,
  voirTout,
  titreVoirTout = "Voir tout",
  children,
}) => (
  <Paper elevation={1} style={{ padding: 8 }}>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TitrePanneau
          Icone={Icone}
          entete={entete}
          voirTout={voirTout}
          titreVoirTout={titreVoirTout}
        />
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  </Paper>
);

export { PanelRechercheGlobale };

export default withRouter(PanneauRechercheGlobale);
