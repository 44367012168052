import routesNames from "router/routesNames";
const { app, profilesPage } = routesNames;

export interface ProfileRoutes {
  matching: (profileId: string, contactId: string) => string;
}
export const profileRoutes: ProfileRoutes = {
  matching: (profileId: string, contactId: string) =>
    `${app}${profilesPage}/contact/${contactId}/profil/${profileId}/matching/view`,
};
