import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Column } from "UI/shared/atoms/containers/Column";
import { Row } from "UI/shared/atoms/containers/Row";
import { HorizontalSpace } from "UI/shared/atoms/spacing/HorizontalSpace";
import { Text12px } from "UI/shared/atoms/typographies/Text12px";
import {
  Text14pxBold,
  Text14pxBoldError,
} from "UI/shared/atoms/typographies/Text14pxBold";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import { TRANSFORM_ERROR } from "services/request-for-informations/constants/errorMessages";
import React from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5,
  },
  consentStatus: {
    "&:hover": {
      color: "#BFB9B9",
    },
    color: "#E3E3E3",
  },
}));

interface AddProfileToExistingContactModalProps {
  confirm: () => void;
  onClose: () => void;
  isOpen: boolean;

  pending: boolean;

  hasError: boolean;
}

export const AddProfileToExistingContactModal: React.FC<AddProfileToExistingContactModalProps> = ({
  confirm,
  onClose,
  isOpen,
  pending,
  hasError,
}) => {
  const classes = useStyles();
  const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose} style={modalStyle}>
        <div className={classes.paper} style={{ textAlign: "center" }}>
          <Column itemJustify="center">
            <Text14pxBold>Le contact existe déjà dans votre CRM</Text14pxBold>
            <Text12px>
              Un contact avec le même numéro et/ou la même adresse email existe
              déjà dans votre base CRM.
            </Text12px>
          </Column>
          <VerticalSpace size={20} />
          <Column>
            <Text14pxBold>
              Souhaitez-vous qu'un nouveau profil acquéreur soit associé à ce
              contact?
            </Text14pxBold>
            <Text12px>
              Le profil acquéreur est créé en se basant sur les informations de
              la DR et les caractéirsituqes du bien.
            </Text12px>
          </Column>
          <VerticalSpace size={20} />
          <Text12px>
            (Si le contact concerné était archivé, il sera réactivé
            automatiquement lors de l'ajout du profil)
          </Text12px>

          <VerticalSpace size={32} />
          {pending && (
            <Row rowJustify="center">
              <CircularProgress />
            </Row>
          )}
          {!pending && !hasError && (
            <Row rowJustify="flex-end">
              <Button color="primary" variant="outlined" onClick={onClose}>
                {"Annuler"}
              </Button>
              <HorizontalSpace size={8} />
              <Button color="primary" variant="contained" onClick={confirm}>
                {"Confirmer"}
              </Button>
            </Row>
          )}
          {hasError && (
            <Column itemJustify="center">
              <Text14pxBoldError>{TRANSFORM_ERROR}</Text14pxBoldError>
            </Column>
          )}
        </div>
      </Modal>
    </>
  );
};
