import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useServices } from "services";
import { CampaignState, CampaignType } from "services/campagnes/types";
import { SquareStatusIcon } from "UI/shared/atoms/SquareStatusIcon";
import { Text12pxBold } from "UI/shared/atoms/typographies/Text12pxBold";
import { SubscribedByDefault } from "../../molecules/SubscribedByDefault";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  status: {
    alignItems: "center",
    display: "flex",
    flex: 1,
  },
}));
export const CampaignStatus: React.FC<{
  status: CampaignState;
  type: CampaignType;
}> = ({ status, type }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {status !== "NotSubscribed" ? <ActiveCampaign /> : <InactiveCampaign />}
      {type === "ParticipativeRegisteredByDefault" ? (
        <SubscribedByDefault />
      ) : (
        <div />
      )}
    </div>
  );
};

const ActiveCampaign: React.FC = () => {
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles();
  return (
    <div className={classes.status}>
      <div style={{ padding: 10 }}>
        <SquareStatusIcon color={"green"} />
      </div>
      <Text12pxBold>{t("Actif")}</Text12pxBold>
    </div>
  );
};

const InactiveCampaign: React.FC = () => {
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles();
  return (
    <div className={classes.status}>
      <div style={{ padding: 10 }}>
        <SquareStatusIcon color={"grey"} />
      </div>
      <Text12pxBold>{t("Inactif")}</Text12pxBold>
    </div>
  );
};
