import { Text12pxBold } from "UI/shared/atoms/typographies/Text12pxBold";
import React from "react";

export const CampaignPreview: React.FC<{ pictureUrl?: string }> = ({
  pictureUrl,
}) => (
  <div
    style={{
      display: "flex",
      flex: 1,
      justifyContent: "start",
      padding: "8px",
    }}
  >
    {pictureUrl && pictureUrl !== "" ? (
      <img
        alt="prévisualisation campagne"
        src={pictureUrl}
        width="100%"
        height="auto"
      />
    ) : (
      <Text12pxBold>Aucune prévisualisation disponible.</Text12pxBold>
    )}
  </div>
);
