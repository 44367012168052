import { Button, makeStyles } from "@material-ui/core";
import { CallMade } from "@material-ui/icons";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { useServices } from "services";

const useStyles = makeStyles((_) => ({
  buttonContainer: {
    alignItems: "center",
    display: "flex",
    flexShrink: 0,
  },
  mainContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  messageContainer: {
    color: "red",
    display: "flex",
  },
}));

export const ExistingContactMeanError: FC<{
  contactId: string;
  contactMeanType: "phone" | "email";
}> = ({ contactId, contactMeanType }) => {
  const history = useHistory();
  const { modal } = useServices();
  const classes = useStyles();

  const message =
    contactMeanType === "email" ? (
      <>
        Un contact avec cet email existe déjà.
        <br></br>Veuillez indiquer un email différent ou annuler la saisie et
        ouvrir la fiche du contact existant.
      </>
    ) : (
      <>
        Un contact avec ce numéro existe déjà.
        <br></br>Veuillez indiquer un numéro différent ou annuler la saisie et
        ouvrir la fiche du contact existant.
      </>
    );

  return (
    <div className={classes.mainContainer}>
      <div className={classes.messageContainer}>{message}</div>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            modal.close();
            history.push(`/app/contacts/contact/${contactId}/identity/view`);
          }}
        >
          <CallMade color={"primary"} />
          Ouvrir la fiche
        </Button>
      </div>
    </div>
  );
};
