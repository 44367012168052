import { LocalPhoneOutlined } from "@material-ui/icons";
import { LinkWithTooltip } from "UI/shared/molecules/LinkWithTooltip";
import React from "react";
import { useServices } from "services";

export interface PhoneLinkProps {
  value: string;
  textSize?: "small" | "large";
}
export const PhoneLink: React.FC<PhoneLinkProps> = ({ value, textSize }) => {
  const { l10n } = useServices();

  return (
    <>
      {value && value?.length > 0 && (
        <LinkWithTooltip
          tooltip={l10n.t("Appeler")}
          href={`tel:${value}`}
          icon={LocalPhoneOutlined}
          content={value}
          textSize={textSize}
        />
      )}
    </>
  );
};
