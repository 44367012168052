import Typography from "@material-ui/core/Typography";
import React from "react";

interface RoomsCaptionProps {
  total: number;
  type: "room" | "bedroom";
}

export const RoomsCaption: React.FC<RoomsCaptionProps> = ({ total, type }) => {
  return (
    <>
      <Typography variant="h3" style={{ fontWeight: "bold" }}>
        {total}
      </Typography>
      <Typography
        variant="h4"
        style={{ fontWeight: "normal", marginBottom: "1px" }}
      >
        &nbsp;{type === "room" ? "p" : "ch"}&nbsp;
      </Typography>
    </>
  );
};
