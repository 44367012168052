import {
  getOptionBarSynthese,
  getOptionRadarSynthese,
} from "theme/EChartsStyles";

export const getOptionRadar = (t, theme, providerData) => {
  const color = [
    theme.palette.graph.color1,
    theme.palette.graph.color2,
    theme.palette.graph.color3,
    theme.palette.graph.color4,
    theme.palette.graph.color5,
    theme.palette.graph.color6,
  ];
  return getOptionRadarSynthese(
    t,
    theme,
    {
      left: "center",
      text: "",
      textStyle: { color: theme.palette.primary.main },
      top: "top",
    },
    color,
    {
      data: providerData ? providerData.radar : [],
      name: "Synthèse",
    }
  );
};

export const getOptionCA = (t, theme, providerData) =>
  getOptionBarSynthese(
    t,
    theme.palette.graph.color1,
    providerData.resultatParAnnee.map((elt) => ({
      annee: elt.annee,
      valeur: parseInt(Math.round(elt.ca / 1000, 0), 10),
    }))
  );

export const getOptionMandat = (t, theme, providerData) =>
  getOptionBarSynthese(
    t,
    theme.palette.graph.color2,
    providerData.resultatParAnnee.map((elt) => ({
      annee: elt.annee,
      valeur: elt.nbMandat,
    }))
  );

export const getOptionPourcentageExclu = (t, theme, providerData) =>
  getOptionBarSynthese(
    t,
    theme.palette.graph.color3,
    providerData.resultatParAnnee.map((elt) => ({
      annee: elt.annee,
      valeur: Number.parseFloat(elt.pourcentageExclu).toFixed(),
    }))
  );
