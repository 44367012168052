import { string } from "api/DTOs/Common/Types";
import Wkt from "wicket/wicket-leaflet";

export interface GeoPolygon {
  id: string;
  nom: string;
  wkt: string;
  code?: string;
  codeInsee?: string;
}

export default function geoPolygonAdapter(
  geoPolygon: GeoPolygon = {} as GeoPolygon
) {
  const wkt = new Wkt.Wkt();
  try {
    if (geoPolygon.wkt) {
      wkt.read(geoPolygon.wkt);
    }

    return {
      code: geoPolygon.code || geoPolygon.codeInsee || string,
      id: geoPolygon.id || string,
      nom: geoPolygon.nom || string,
      wkt: geoPolygon.wkt ? wkt.toObject()._latlngs : [],
    };
  } catch (e) {
    console.log(e);
  }
  return {
    code: geoPolygon.code || geoPolygon.codeInsee || string,
    id: geoPolygon.id || string,
    nom: geoPolygon.nom || string,
    wkt: [],
  };
}
