import { useServices } from "services";

import { useTxHttp } from "./../http/index";
import { ReferenceDto } from "./dto/ReferenceDto";

export const useReferenceAutocomplete = () => {
  const { get } = useTxHttp();
  const { endpoints } = useServices();

  const autocompleteReference: (search: string) => Promise<string[]> = async (
    search
  ) => {
    const { data } = await get<ReferenceDto[] | null>(
      endpoints.profiles.autocompleteReference,
      {
        search,
        size: 10,
      }
    );
    return data?.map((d) => d.value) || [];
  };

  return {
    autocompleteReference,
  };
};
