import React, { useContext } from "react";
import { useGetPropertyForSaleByAdReference } from "services/request-for-informations/GetPropertyForSaleByAdReference";
import { UserContext } from "services/user";
import { RequestForInformations } from "./pages/list";

export const RequestForInformationsContainer: React.FC = () => {
  const {
    requestForInformationsService: {
      requestForInformations,
      loadMore,
      archiveRequestForInformation,
      activateRequestForInformation,
      transformRequestForInformation,
      updateParamsWithFilters,
      updateParamsWithActiveSortOption,
      status,
    },
  } = useContext(UserContext);

  return (
    <RequestForInformations
      requestForInformations={requestForInformations}
      archiveRequestForInformation={archiveRequestForInformation}
      activateRequestForInformation={activateRequestForInformation}
      transformRequestForInformation={transformRequestForInformation}
      updateParamsWithFilters={updateParamsWithFilters}
      updateParamsWithActiveSortOption={updateParamsWithActiveSortOption}
      loadMore={loadMore}
      useGetPropertyForSaleByAdReference={useGetPropertyForSaleByAdReference}
      status={status}
    />
  );
};
