import { breakpoint } from "theme/main";

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case "firstIntersection":
      return {
        ...state,
        firstIntersection: payload,
      };
    case "setLoading":
      return { ...state, loading: payload };
    case "setRefinements":
      return { ...state, page: 1, refinements: payload };
    case "setDisplayMode":
      return { ...state, displayMode: payload, page: 1 };
    case "toggleDrawer":
      return { ...state, drawer: !state.drawer };
    case "setSmAndDown":
      return { ...state, smAndDown: breakpoint().smAndDown };
    case "prixMin":
      return { ...state, prixMin: payload };
    case "prixMax":
      return { ...state, prixMax: payload };
    case "saveMapConfig":
      return { ...state, ...payload };
    case "updateBoundingBox":
      return { ...state, ...payload };
    case "restoreSavedState":
      return { ...state, ...payload };
    case "changePage":
      return { ...state, page: payload };
    case "setMapPoints":
      return { ...state, loading: false, mapPoints: payload };
    default:
      return { ...state };
  }
};
