import { array, bool, number, string } from "api/DTOs/Common/Types";
import villeAdapter from "services/rapprochement/Rapprochement/VilleAdapter";

class RechercheGridOuListeBO {
  constructor() {
    this.id = string;
    this.bu = string;
    this.nombrePhotos = number;
    this.photoPrincipale = string;
    this.surface = number;
    this.surfaceTerrain = number;
    this.nbPieces = number;
    this.nbChambres = number;
    this.prix = number;
    this.familleBienEnum = string;
    this.typeAchatEnum = string;
    this.optionMandatEnum = string;
    this.typeAffaireEnum = string;
    this.conseillers = array;
    this.villeAdapter = villeAdapter();
    this.prestige = bool;
    this.dateEffectiveCompromis = string;
    this.dateEffectiveSignature = string;
    this.neuf = bool;
    this.typeBienNom = string;
    this.isCE = bool;
  }
}

export default RechercheGridOuListeBO;
