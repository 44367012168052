import { Grid } from "@material-ui/core";
import BudgetIcon from "@material-ui/icons/EuroOutlined";
import { useTheme } from "@material-ui/styles";
import { NumberField } from "components/RefinementField/NumberField";
import TitreBloc from "layoutContents/components/ContextualSideBar/components/titreBloc";
import React, { useEffect, useReducer } from "react";

let timeout;

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "prixMin":
      return { ...state, prixMin: payload };
    case "prixMax":
      return { ...state, prixMax: payload };
    case "initialize":
      return payload;
    default:
      return { ...state };
  }
};

const Budget = ({ classes, onUpdateRefinements, prixMin, prixMax, t }) => {
  const theme = useTheme();
  const initialState = {
    prixMax: prixMax.value,
    prixMin: prixMin.value,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const onChangePriceDelayed = (query, value) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      onUpdateRefinements(query, value);
    }, 600);
    dispatch({ payload: value, type: query });
  };

  useEffect(() => {
    if (prixMax.value === "" && prixMin.value === "")
      dispatch({
        payload: {
          prixMax: prixMax.value,
          prixMin: prixMin.value,
        },
        type: "initialize",
      });
  }, [prixMin.value, prixMax.value]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TitreBloc
          label={t("Budget")}
          icon={<BudgetIcon style={{ color: theme.palette.tonique }} />}
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 5 }}>
        <Grid container spacing={2}>
          <Grid className={classes.gridFilter} item xs={12}>
            <NumberField
              label={t("PrixMin")}
              onChange={(price) => onChangePriceDelayed(prixMin.query, price)}
              value={state.prixMin}
              variant={"standard"}
            />
          </Grid>
          <Grid className={classes.gridFilter} item xs={12}>
            <NumberField
              label={t("PrixMax")}
              onChange={(price) => onChangePriceDelayed(prixMax.query, price)}
              value={state.prixMax}
              variant={"standard"}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Budget;
