import { Locations } from "components/RefinementField/types";
import { RealEstatesSearchParams } from "services/realEstates/types";
import { actionTypes } from "./action-types";
import { Action, DisplayMode, RealEstatesResponse } from "./types";

export const clearParams: () => Action = () => ({
  type: actionTypes.CLEAR_PARAMS,
  payload: {},
});

export const updateParams: (
  params: Partial<RealEstatesSearchParams>
) => Action = (params) => ({
  type: actionTypes.UPDATE_PARAMS,
  payload: params,
});

export const clearLocations: () => Action = () => ({
  type: actionTypes.CLEAR_LOCATIONS,
  payload: {},
});

export const updateLocations: (locations: Partial<Locations>) => Action = (
  locations
) => ({
  type: actionTypes.UPDATE_LOCATIONS,
  payload: locations,
});

export const updateResponse: (
  response: Partial<RealEstatesResponse>
) => Action = (response) => ({
  type: actionTypes.UPDATE_RESPONSE,
  payload: response,
});

export const updateDisplayMode: (mode: DisplayMode) => Action = (mode) => ({
  type: actionTypes.UPDATE_DISPLAY_MODE,
  payload: mode,
});
