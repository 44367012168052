import {
  AdaptedRefinements,
  Refinements,
} from "components/RefinementField/types";
import { DisplayMode } from "routes/app/real-estates/containers/network/types";
import { RealEstatesCriteria } from "UI/features/realEstates/organisms/SearchInput";

export const useAdaptedRefinements: (
  refinements: Refinements<RealEstatesCriteria>,
  mode: DisplayMode
) => AdaptedRefinements<RealEstatesCriteria> = (refinements, mode) => ({
  fullText: {
    displayMode: "list",
    moreFiltersCategory: "contact",
    rank: 1,
    refinement: refinements.fullText,
    size: mode === "map" ? 12 : 2,
  },
  locations: {
    displayMode: mode === "map" ? "moreFilters" : "list",
    moreFiltersCategory: "localisations",
    rank: 2,
    refinement: refinements.locations,
    size: 4,
  },
  familleBiens: {
    displayMode: mode === "map" ? "moreFilters" : "list",
    moreFiltersCategory: "characteristics",
    rank: 3,
    refinement: refinements.familleBiens,
    size: 2,
  },
  nbPiecesRange: {
    displayMode: mode === "map" ? "moreFilters" : "list",
    moreFiltersCategory: "characteristics",
    refinement: refinements.nbPiecesRange,
    rank: 4,
    size: 2,
  },
  prixMax: {
    displayMode: mode === "map" ? "moreFilters" : "list",
    moreFiltersCategory: "characteristics",
    refinement: refinements.prixMax,
    rank: 5,
    size: 2,
  },
  withNotes: {
    displayMode: "moreFilters",
    moreFiltersCategory: "display",
    rank: 6,
    refinement: refinements.withNotes,
    size: 2,
  },
  withPool: {
    displayMode: "moreFilters",
    moreFiltersCategory: "other",
    rank: 7,
    refinement: refinements.withPool,
    size: 2,
  },
  withElevator: {
    displayMode: "moreFilters",
    moreFiltersCategory: "other",
    rank: 8,
    refinement: refinements.withElevator,
    size: 2,
  },
  withParking: {
    displayMode: "moreFilters",
    moreFiltersCategory: "other",
    rank: 8,
    refinement: refinements.withParking,
    size: 2,
  },
  withSingleStorey: {
    displayMode: "moreFilters",
    moreFiltersCategory: "other",
    rank: 9,
    refinement: refinements.withSingleStorey,
    size: 2,
  },
  bus: {
    displayMode: "moreFilters",
    moreFiltersCategory: "characteristics",
    rank: 10,
    refinement: refinements.bus,
    size: 2,
  },
  typeAchat: {
    displayMode: "moreFilters",
    moreFiltersCategory: "characteristics",
    rank: 11,
    refinement: refinements.typeAchat,
    size: 2,
  },
  typeAffaires: {
    displayMode: "moreFilters",
    moreFiltersCategory: "characteristics",
    rank: 12,
    refinement: refinements.typeAffaires,
    size: 2,
  },
  surfaceHabitable: {
    displayMode: "moreFilters",
    moreFiltersCategory: "areas",
    rank: 13,
    refinement: refinements.surfaceHabitable,
    size: 2,
  },
  surfaceTerrain: {
    displayMode: "moreFilters",
    moreFiltersCategory: "areas",
    rank: 14,
    refinement: refinements.surfaceTerrain,
    size: 2,
  },
  dateMaj: {
    displayMode: "moreFilters",
    moreFiltersCategory: "dates",
    rank: 15,
    refinement: refinements.dateMaj,
    size: 2,
  },
  dateMiseEnVente: {
    displayMode: "moreFilters",
    moreFiltersCategory: "dates",
    rank: 16,
    refinement: refinements.dateMiseEnVente,
    size: 2,
  },
  prix: {
    displayMode: "moreFilters",
    moreFiltersCategory: "budget",
    rank: 17,
    refinement: refinements.prix,
    size: 2,
  },
  sort: {
    displayMode: "moreFilters",
    refinement: refinements.sort,
    rank: 18,
    size: 2,
    moreFiltersCategory: "other",
  },
  constructionYear: {
    displayMode: "moreFilters",
    refinement: refinements.constructionYear,
    rank: 19,
    size: 2,
    moreFiltersCategory: "characteristics",
  },
});
