import { Paper, Tooltip, useTheme } from "@material-ui/core";
import { Directions } from "@material-ui/icons";
import React from "react";

export const DirectionMapButton: React.FC<{
  latitude: string;
  longitude: string;
}> = ({ latitude, longitude }) => {
  const {
    palette: { primary },
  } = useTheme();

  return (
    <Paper
      elevation={1}
      style={{
        background: "white",
        borderRadius: "50%",
        cursor: "pointer",
        display: "flex",
        padding: 10,
        position: "absolute",
        right: 10,
        top: 60,
        zIndex: 900,
      }}
    >
      <a
        target={"blank"}
        href={`https://www.google.com/maps/place/${latitude},${longitude}`}
        style={{
          color: "white",
          textDecoration: "none",
        }}
      >
        <div
          style={{
            alignItems: "center",
            background: primary.main,
            border: "1px solid black",
            borderRadius: "4px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "right",
          }}
        >
          <Tooltip title={"Itinéraire"}>
            <Directions />
          </Tooltip>
        </div>
      </a>
    </Paper>
  );
};
