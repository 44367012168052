import Grid from "@material-ui/core/Grid";
import React, { FC } from "react";
import { ConsentStatus } from "types";

import { GridContainer } from "../../../../../../components/containers/GridContainer";
import { useContactForm } from "../context/ContactFormContext";
import { ConsentSelect } from "./ConsentSelect";
import { RGPDNotice } from "./RGPDNotice";
import { SectionTitle } from "./Sections";

export const Consent: FC = () => {
  const { contactDto, setContactDto, isDisabled } = useContactForm();

  return (
    <GridContainer>
      <Grid item xs={12}>
        <SectionTitle
          title={"Consentement"}
          mode="obligatoire"
          mention={"(obligation RGPD)"}
        />
        <RGPDNotice />
      </Grid>
      <Grid item xs={12}>
        <ConsentSelect
          status={contactDto.consentStatus}
          disabled={isDisabled}
          onChange={(e) =>
            setContactDto({
              ...contactDto,
              consentStatus: e as ConsentStatus,
            })
          }
        />
      </Grid>
    </GridContainer>
  );
};
