import { Grid, Paper, useMediaQuery, useTheme } from "@material-ui/core";
import CarteContact from "components/CarteContact";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import routesNames from "router/routesNames";
import { useServices } from "services";
import { ReadContactDto } from "services/portfolio/types";
import { usePortfolioActions } from "services/portfolio/usePortfolioActions";
import { ConsentStatus } from "types";

import { ContactForm } from "../ContactForm";

export interface ContactItemProps {
  contact: ReadContactDto;
  onChange: () => void;
}
export const ContactItem: React.FC<ContactItemProps> = ({
  contact,
  onChange,
}) => {
  const { modal } = useServices();
  const {
    archive,
    acceptConsent,
    denyConsent,
    activate,
  } = usePortfolioActions();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const acceptConsentThenRefresh = (id: string) => {
    acceptConsent(id).then((_) => onChange());
    return Promise.resolve();
  };
  const denyConsentThenRefresh = (id: string) => {
    denyConsent(id).then((_) => onChange());
    return Promise.resolve();
  };
  const archiveThenRefresh = (
    id: string,
    content: { consentStatus: ConsentStatus }
  ) => {
    archive(id, content).then((_) => onChange());
    return Promise.resolve();
  };

  const activateThenRefresh = (id: string) => {
    activate(id).then((_) => onChange());
    return Promise.resolve();
  };

  const contactCard = (
    <CarteContact
      contact={contact}
      avecMenu={true}
      handleUpdate={() => {
        modal.show(<ContactForm contact={contact} closeDialog={modal.close} />);
      }}
      handleArchive={archiveThenRefresh}
      handleActivate={activateThenRefresh}
      handleAcceptConsent={acceptConsentThenRefresh}
      handleDenyConsent={denyConsentThenRefresh}
      withPrintButton={true}
      galery={mobile}
    />
  );

  return useMemo(
    () => (
      <Grid item xs={12} sm={6} md={4} lg={3} key={contact.id}>
        <Paper
          elevation={0}
          key={contact.id}
          onClick={() =>
            history.push(
              `${routesNames.app}${routesNames.contact}/${contact.id}/identity/view`
            )
          }
        >
          <React.Fragment key={contact.id}>
            <div key={`${contact.id}`}>{contactCard}</div>
          </React.Fragment>
        </Paper>
      </Grid>
    ),
    [
      contact.id,
      contact.status,
      contact.consentStatus,
      contact.hasForSaleProfile,
      contact.hasSearchProfile,
    ]
  );
};
