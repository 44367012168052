import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: (small) => ({
    width: small ? 80 : 94,
    height: small ? 38 : 50,
    padding: 5,
    margin: small ? 0 : theme.spacing(1),
  }),
  switchBase: {
    "&$checked": {
      transform: "translateX(44px)",
      color: "white",
    },
    "&$checked + $track": {
      backgroundColor: "#303d4a",
      opacity: 1,
      border: "none",
    },
    "&$focusVisible $thumb": {
      color: "#303d4a",
      border: "6px solid #fff",
    },
    color: "white",
  },
  thumb: (small) => ({
    width: small ? 20 : 32,
    height: small ? 20 : 32,
  }),
  track: {
    borderRadius: 20,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}));

const SwitchOuiNon = ({ small = false, toggle = (obj) => {}, value }) => {
  const classes = useStyles(small);

  const handleChecked = () => {
    toggle(!value);
  };

  return (
    <div style={{ position: "relative" }}>
      <Switch
        focusVisibleClassName={classes.focusVisible}
        checked={value}
        onChange={handleChecked}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
      />
      {!value ? (
        <Text texte={"NON"} left={small ? 40 : 60} small={small} />
      ) : (
        <Text texte={"OUI"} left={small ? 20 : 30} small={small} />
      )}
    </div>
  );
};

export default SwitchOuiNon;

const Text = ({ texte, left, small = false }) => (
  <div style={{ position: "absolute", left: left, top: small ? 10 : 25 }}>
    <Typography variant={"h6"} style={{ color: "white" }}>
      {texte}
    </Typography>
  </div>
);
