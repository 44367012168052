import { Button } from "@material-ui/core";
import { Column } from "UI/shared/atoms/containers/Column";
import { Text14pxBoldError } from "UI/shared/atoms/typographies/Text14pxBold";
import React from "react";

export interface ErrorProps {
  onClose: () => void;
}
export const Error: React.FC<ErrorProps> = ({ onClose }) => {
  return (
    <>
      <Column itemJustify="center">
        <Text14pxBoldError>
          Une erreur est survenue durant la transformation de la pige.
        </Text14pxBoldError>
      </Column>
      <Button color="primary" variant="outlined" onClick={onClose}>
        {"Fermer"}
      </Button>
    </>
  );
};
