import { Button, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { CallMadeOutlined } from "@material-ui/icons";
import Exclusivite from "UI/shared/atoms/Badges/Exclusivite";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import routesNames from "router/routesNames";
import { useServices } from "services";
import { usePortfolioSearch } from "services/portfolio/usePortfolioSearch";
import { TRANSFORMED } from "services/request-for-informations/constants/request-for-informations-status";
import { RequestForInformationDto } from "services/request-for-informations/dto/RequestForInformationDto";
import { PropertyForSale } from "services/request-for-informations/GetPropertyForSaleByAdReference";
import { RealEstatePropertyCard } from "UI/features/realEstates/organisms/RealEstatePropertyCard";
import { HorizontalSpace } from "UI/shared/atoms/spacing/HorizontalSpace";
import { Text14px } from "UI/shared/atoms/typographies/Text14px";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import { OrangeWarningNote } from "UI/shared/molecules/OrangeWarningNote";
import getUrlPhoto from "utils/UrlImage";
import { RequestForInformationReference } from "UI/features/requestForInformations/molecules/RquestForInformationReference";
import { TopicTitle } from "UI/features/requestForInformations/molecules/TopicTitle";
import { useStyles } from "../../../../../UI/features/requestForInformations/style";
import { RequestForInformationContact } from "UI/features/requestForInformations/organisms/RequestForInformationContact";
import { RequestForInformationInfos } from "UI/features/requestForInformations/organisms/RequestForInformationInfos";

export interface RequestForInformationDetailsProps {
  rfi: RequestForInformationDto;
  useGetPropertyForSaleByAdReference: (adReference: string) => PropertyForSale;
}

export const RequestForInformationDetails: React.FC<RequestForInformationDetailsProps> = ({
  rfi,
  useGetPropertyForSaleByAdReference,
}) => {
  const {
    l10n: { t },
  } = useServices();
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const classes = useStyles({ mobile });
  const history = useHistory();

  const propertyForSale = useGetPropertyForSaleByAdReference(rfi.adReference);
  const { getContactIdByPhoneNumberOrEmailAddress } = usePortfolioSearch();

  const handleOpenContact = useCallback(async () => {
    const { email, phoneNumber } = rfi.client;
    const contactId = await getContactIdByPhoneNumberOrEmailAddress(
      phoneNumber,
      email
    );
    const redirectUrl = `/app${routesNames.contact}/${contactId}/identity/view`;
    history.push(redirectUrl);
  }, [rfi.id]);

  const tagsList = [];

  if (
    propertyForSale.propertyForSale
      ? propertyForSale.propertyForSale.isExclusive
      : false
  ) {
    tagsList.push(<Exclusivite />);
  }

  return (
    <div className={classes.container}>
      <div
        style={{ display: "flex", flexDirection: mobile ? "column" : "row" }}
      >
        <div className={classes.leftColumn}>
          <RequestForInformationInfos
            status={rfi.status}
            receivedAt={rfi.receivedAt}
            updatedAt={rfi.updatedAt}
          />
          <RequestForInformationContact
            source={rfi.source}
            client={rfi.client}
            emailSender={rfi.emailSender}
          />
          <VerticalSpace size={10} />
          {rfi.status === TRANSFORMED && (
            <Button variant="outlined" onClick={handleOpenContact}>
              <CallMadeOutlined />
              <HorizontalSpace size={8} />
              <Text14px>OUVRIR LA FICHE</Text14px>
            </Button>
          )}
        </div>
        <div className={classes.rightColumn}>
          <TopicTitle label={"mandat"} />
          <div className={classes.propertyForSaleContainer}>
            <RequestForInformationReference reference={rfi.adReference} />
            <RealEstatePropertyCard
              bu={rfi.bu}
              surface={rfi.propertyForSale.livingArea}
              price={rfi.propertyForSale.price}
              cityName={rfi.propertyForSale.city}
              businessType={rfi.propertyForSale.businessType}
              propertyType={rfi.propertyForSale.type}
              bedroomsNb={rfi.propertyForSale.bedRoomsNumber}
              roomsNb={rfi.propertyForSale.roomsNumber}
              cityZipCode={rfi.propertyForSale.zipcode}
              photo={
                propertyForSale.propertyForSale
                  ? getUrlPhoto(
                      rfi.bu,
                      "300x300",
                      propertyForSale.propertyForSale.photo
                    )
                  : ""
              }
              isNew={
                propertyForSale.propertyForSale
                  ? propertyForSale.propertyForSale.isNew
                  : false
              }
              isPrestige={
                propertyForSale.propertyForSale
                  ? propertyForSale.propertyForSale.isPrestige
                  : false
              }
              listeTags={tagsList}
            />
          </div>
          {propertyForSale.status === "Not Found" && (
            <div className={classes.orangeWarningNoteContainer}>
              <OrangeWarningNote
                title={t("ATTENTION")}
                content={
                  "Ce bien n'est actuellement pas présent dans vos biens disponibles en cours de publication."
                }
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <TopicTitle label={"demande"} />
        <Typography variant={"subtitle1"}>{rfi.emailBody}</Typography>
      </div>
    </div>
  );
};
