import { makeStyles, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import React from "react";

import { useServices } from "../../../../../services";
import { ContactStatusType } from "../../../../../services/portfolio/types";
import { ConsentStatus } from "../../../../../types";
import { OptInForm } from "../ContactForm/components/OptInForm";

export interface ContactStatusButtonProps {
  contactStatus: ContactStatusType;
  consentStatus: ConsentStatus;
  archive: (value: ConsentStatus) => Promise<void>;
  activate: () => Promise<void>;
}

export const ContactStatusButton: React.FC<ContactStatusButtonProps> = ({
  children,
  contactStatus,
  consentStatus,
  archive,
  activate,
}) => {
  const { modal } = useServices();

  const onSubmit = (_consentStatus: ConsentStatus) => {
    modal.close();
    archive(_consentStatus);
  };

  const onClickArchive = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();
    modal.show(
      <FormOptIn
        consentStatus={consentStatus}
        submit={onSubmit}
        cancel={modal.close}
      />
    );
  };

  const onActivate = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    activate();
  };

  const button =
    contactStatus === "Archived" ? (
      <IconButton aria-label="réactiver">
        <UnarchiveOutlinedIcon />
      </IconButton>
    ) : (
      <IconButton aria-label="archiver">
        <ArchiveOutlinedIcon />
      </IconButton>
    );

  return (
    <div
      style={{ display: "flex", alignItems: "center" }}
      onClick={contactStatus === "Archived" ? onActivate : onClickArchive}
    >
      {button}
      {children}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  centre: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px",
  },
  paragraphe: {
    display: "flex",
    flexGrow: 1,
    paddingBottom: 10,
    paddingTop: 10,
  },
}));

interface FormOptInProps {
  consentStatus: ConsentStatus;
  submit: (value: ConsentStatus) => void;
  cancel: () => void;
}

const FormOptIn: React.FC<FormOptInProps> = ({
  consentStatus,
  submit,
  cancel,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography
        variant="h4"
        className={`${classes.paragraphe} ${classes.centre}`}
      >
        Confirmer l&apos;archivage de ce contact ?
      </Typography>

      <Typography variant="subtitle1" className={classes.paragraphe}>
        Tous les profils actifs associés seront archivés. A tout moment vous
        pouvez retrouver vos contacts archivés et réactiver un contact.
      </Typography>
      <Typography variant="subtitle1" className={classes.paragraphe}>
        Les contacts archivés sont toujours ciblés par les campagnes marketing
        si les sollicitations sont autorisées.
      </Typography>
      <Typography variant="subtitle1" className={classes.paragraphe}>
        Vous pouvez modifier le consentement avant archivage :
      </Typography>
      <OptInForm
        consentStatus={consentStatus}
        saveConsentStatus={submit}
        cancel={cancel}
      />
    </div>
  );
};
