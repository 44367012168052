import { number, string } from "api/DTOs/Common/Types";

class RadarBO {
  constructor() {
    this.cible = string;
    this.nombreConseillers = number;
    this.caActes = number;
    this.caCompromis = number;
    this.nombreActes = number;
    this.nombreCompromis = number;
    this.nombreMandats = number;
    this.pourcentageExclus = number;
  }
}

export default RadarBO;
