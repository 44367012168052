import { InfoChip } from "UI/shared/atoms/chips/InfoChip";
import React from "react";

interface SearchProfileBusinessTypeInfoChipProps {
  label: string;
}

export const SearchProfileBusinessTypeInfoChip: React.FC<SearchProfileBusinessTypeInfoChipProps> = ({
  label,
}) => {
  return <InfoChip color={"#41B0B6"} label={label} />;
};
