import { useEffect, useState } from "react";
import useHTTPService from "services/dataSources/communicationServices/useHTTPService";

import geoPolygonAdapter from "./geoPolygonAdapter";

const useGeoPolygon = ({ endpoint, guid }) => {
  const [geoPolygon, setGeoPolygon] = useState(geoPolygonAdapter());

  const { status, adaptedData } = useHTTPService({
    adapter: geoPolygonAdapter,
    endpoint: `${endpoint}?id=${guid}`,
    shouldExecute: !!endpoint && !!guid,
  });

  useEffect(() => {
    if (status === "resolved") setGeoPolygon(adaptedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return { geoPolygon };
};

export default useGeoPolygon;
