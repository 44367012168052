import { Locations, Refinements } from "components/RefinementField/types";
import { PigeCriteria } from "components/Searchbar/types";
import { PigesSearchParams } from "services/piges";
import { PigeSearchResult } from "types";

export function toRefinements(
  items: PigeSearchResult,
  params: PigesSearchParams,
  locations?: Locations
): Refinements<PigeCriteria> {
  return {
    avecNotesUniquement: {
      displayedValue: "Avec notes uniquement",
      kind: "checkbox",
      value: params.avecNotesUniquement,
    },
    avecSuiviUniquement: {
      displayedValue: "Suivies",
      kind: "checkbox",
      value: params.avecSuiviUniquement,
    },
    baissesDePrix: {
      displayedValue: "Baisses de prix",
      kind: "checkbox",
      value: params.baissesDePrix,
    },
    contactPhone: {
      kind: "phone-number",
      value: params.contactPhone,
    },
    convertedOnly: {
      displayedValue: "Transformé en profil",
      kind: "checkbox",
      value: params.convertedOnly,
    },
    dateDePublication: {
      kind: "date-range",
      value: {
        max: params.dateDePublicationMax,
        min: params.dateDePublicationMin,
      },
    },
    favorisUniquement: {
      displayedValue: "Favoris",
      kind: "checkbox",
      value: params.favorisUniquement,
    },
    fullText: {
      kind: "single-text",
      value: params?.fullText,
    },
    inactivesIncluses: {
      displayedValue: "Inclure les annonces désactivées",
      kind: "checkbox",
      value: params.inactivesIncluses,
    },

    landArea: {
      kind: "number-range",
      value: {
        max: params.landAreaMax,
        min: params.landAreaMin,
        unit: "m²",
      },
    },

    livingArea: {
      kind: "number-range",
      value: {
        max: params.livingAreaMax,
        min: params.livingAreaMin,
        unit: "m²",
      },
    },
    masqueesIncluses: {
      displayedValue: "Inclure les annonces masquée",
      kind: "checkbox",
      value: params.masqueesIncluses,
    },
    nbChambres: {
      kind: "multiple-text",
      value: ExtractMultipleTextValue(
        items.aggregation?.["NbZones.Chambre"],
        params.nbChambres
      ),
    },
    nbPieces: {
      kind: "multiple-text",
      value: ExtractMultipleTextValue(
        items.aggregation?.["NbPieces"],
        params.nbPieces
      ),
    },
    pigeDuJour: {
      displayedValue: "Pige du jour",
      kind: "checkbox",
      value: params.pigeDuJour,
    },

    prix: {
      displayChip: true,
      kind: "number-range",
      value: {
        max: params.prixMax,
        min: params.prixMin,
        unit: "€",
      },
    },

    prixMax: {
      displayChip: false,
      kind: "price",
      value: params.prixMax,
    },

    sort: {
      kind: "sort",
      value: {
        ascending: false,
        column: "DateModification",
      },
    },

    telephoneUniquement: {
      displayedValue: "Avec tél. uniquement",
      kind: "checkbox",
      value: params.telephoneUniquement,
    },

    typeAnnonceurParticuliers: {
      displayedValue: "Particuliers",
      kind: "checkbox",
      value: params.typeAnnonceurParticuliers,
    },
    typeAnnonceurPro: {
      displayedValue: "Professionnels",
      kind: "checkbox",
      value: params.typeAnnonceurPro,
    },
    typesAnnonce: {
      kind: "multiple-text",
      value: ExtractMultipleTextValue(
        items.aggregation?.["TypeAnnonce"],
        params.typesAnnonce
      ),
    },
    typesDeBien: {
      kind: "multiple-text",
      value: ExtractMultipleTextValue(
        items.aggregation?.["TypeBien"],
        params.typesDeBien
      ),
    },
    typesEtatBien: {
      kind: "multiple-text",
      value: ExtractMultipleTextValue(
        items.aggregation?.["TypeEtatBien"],
        params.typesEtatBien
      ),
    },
    zoneDeTravail: {
      kind: "locations",
      value: {
        departements: locations?.value?.departements || [],
        quartiers: [],
        regions: locations?.value?.regions || [],
        villes: locations?.value?.villes || [],
      },
    },
  };
}

function ExtractMultipleTextValue(
  aggregation?: { key: string; value: number }[],
  params?: string[],
  label: (key: string) => string = (_) => _
) {
  const result = [];
  const keys: Record<string, boolean> = {};
  for (const { key, value } of aggregation || []) {
    result.push({
      count: value,
      isRefined: params?.includes(key) || false,
      label: label(key),
      value: key,
    });
    keys[key] = true;
  }

  const emptyParams = (params || [])
    .filter((_) => !keys[_])
    .map((key) => ({
      count: 0,
      isRefined: true,
      label: label(key),
      value: key,
    }));

  return result.concat(emptyParams);
}
