import Typography from "@material-ui/core/Typography";
import React from "react";
import { useTranslation } from "services/l10n/useTranslation";

interface StatusInfoProps {
  value: string;
  marginLeft?: number;
}

export const StatusInfo: React.FC<StatusInfoProps> = ({
  value,
  marginLeft = 0,
}) => {
  const { t } = useTranslation();
  return (
    <Typography
      variant={"body1"}
      style={{ fontSize: "16px", fontWeight: "bold", marginLeft: marginLeft }}
    >
      {t(value)}
    </Typography>
  );
};
