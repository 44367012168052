import IconButton from "@material-ui/core/IconButton";
import { PrintOutlined } from "@material-ui/icons";
import React from "react";

const BoutonImpression = ({ onPrint }) => (
  <div>
    <IconButton onClick={onPrint} style={{ cursor: "pointer" }}>
      <PrintOutlined />
    </IconButton>
  </div>
);

export default BoutonImpression;
