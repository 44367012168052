import { MultiCheckbox } from "UI/shared/molecules/MultiCheckbox";
import React from "react";

import { AmenityType } from "../../models/amenityType";
import { SubSectionTitle } from "../components/SectionTitle";
import { ProfileInputProps } from "../InputProps";

export const SaleHouseEquipments: React.FC<ProfileInputProps<
  AmenityType[]
>> = ({ onChange, value }) => {
  const options = ["Fiber", "Pool"];
  return (
    <>
      <SubSectionTitle title="Equipements" mode={"non obligatoire"} />
      <MultiCheckbox
        translationPrefix={"amenity"}
        options={options}
        selectedOptions={value}
        onChange={onChange}
      />
    </>
  );
};
