export const historiquePrixBienPigeAdapter = (
  result: Partial<HistoriquePrix> = {}
): Partial<HistoriquePrix> => result;

export interface MiseAJourPrix {
  date: Date;
  prix: number;
}

export interface PublicationSite {
  nom: string;
  url: string;
}
export interface HistoriquePrix {
  misesAJourPrix: MiseAJourPrix[];
  publicationSites: PublicationSite[];
}
