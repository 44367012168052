import { Avatar, Typography } from "@material-ui/core";
import React from "react";
import { useServices } from "services";
import { Agent } from "types";
import getUrlPhoto from "utils/UrlImage";

const AvatarConseiller: React.FC<{
  conseiller: Agent;
  taille?: number;
}> = ({ conseiller, taille = 100 }) => {
  const { authenticationService } = useServices();
  const BU = authenticationService.getUserData()?.Instance;
  const urlPhoto = getUrlPhoto(
    conseiller.bu || BU,
    `${taille * 2}x${taille * 2}`,
    conseiller.photo
  );

  if (
    conseiller.photo !== "00000000-0000-0000-0000-000000000000" &&
    conseiller.photo !== ""
  )
    return (
      <Avatar
        aria-label="photo conseiller"
        style={{ height: taille, width: taille }}
      >
        <div
          style={{
            backgroundImage: `url(${urlPhoto}), url("/static/images/face.png")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: taille,
            width: taille,
          }}
        />
      </Avatar>
    );

  return (
    <Avatar
      aria-label="photo conseiller"
      style={{ height: taille, width: taille }}
    >
      <Typography
        variant={"h1"}
        style={{ fontSize: `${Math.ceil(taille / 2)}px`, fontWeight: "normal" }}
      >
        {`${conseiller.prenom
          .substring(0, 1)
          .toUpperCase()}${conseiller.nom.substring(0, 1).toUpperCase()}` !== ""
          ? `${conseiller.prenom
              .substring(0, 1)
              .toUpperCase()}${conseiller.nom.substring(0, 1).toUpperCase()}`
          : "?"}
      </Typography>
    </Avatar>
  );
};

export default AvatarConseiller;
