import { Grid, GridSize, makeStyles, Typography } from "@material-ui/core";
import { CallMade, CallReceived } from "@material-ui/icons";
import { Option } from "components/SortSelect";
import React, { FC } from "react";
import Select from "react-select";
import { SortOptionLabelAndIcon } from "./SortOptionLabelAndIcon";

export interface SortInputProps {
  onChange: (value: Option) => void;
  options: SortOption[];
  selectedOption: string;
  customSize?: GridSize;
}

export interface SortOption {
  query: string;
  value: string;
  label: string;
  isAscendingOrder: boolean;
  isDefault: boolean;
}

const useStyles = makeStyles(() => ({
  sortComponent: {
    background: "transparent",
    border: "none",
  },
  sortItem: {
    "&:hover": {
      background: "transparent",
      fontWeight: "bold!important",
    },
    alignItems: "center",
    display: "flex",
    fontSize: "14px",
  },
  sortSelect: {
    alignSelf: "flex-end",
    borderBottom: `1px solid #bababa`,
    height: "fit-content",
    marginBottom: "10px",
    marginLeft: "auto",
  },
}));

const extraStyles = (value: any) => ({
  container: (provided: any) => ({
    ...provided,
    flex: 1,
  }),
  control: (provided: any) => ({
    ...provided,
    background: "transparent",
    border: "none",
    boxShadow: "none",
  }),
  indicatorSeparator: () => ({ display: "none" }),
  option: (provided: any, state: any) => ({
    ...provided,
    ":before": {
      content: state.isSelected ? "'\f00c'" : "''",
      fontFamily: "'Font Awesome 5 Free'",
      fontWeight: 900,
      minWidth: "20px",
    },
    alignItems: "center",
    background: "inherit",
    color: "inherit",
    display: "flex",
    fontWeight: state.isFocused ? "bold" : "normal",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontWeight: "bold",
  }),
});

export const SortInput: FC<SortInputProps> = (props) => {
  const classes = useStyles();
  const formatOptionLabel = (option: SortOption) => {
    const dispayedIcon = option.isAscendingOrder ? (
      <CallMade style={{ fontSize: "1.2em", marginLeft: "10px" }} />
    ) : (
      <CallReceived
        style={{
          fontSize: "1.2em",
          marginLeft: "10px",
          transform: "rotate(-90deg)",
        }}
      />
    );

    return (
      <SortOptionLabelAndIcon
        label={option.label}
        icon={dispayedIcon}
        isBold={props.selectedOption === option.value}
      />
    );
  };
  const handleChange = (value: Option) => {
    props.onChange({
      key: "sort",
      value: {
        kind: "sort",
        value: { ascending: value.isAscendingOrder, column: value.query },
      },
    });
  };

  const gridProps = {
    xs: props.customSize || 12,
    sm: props.customSize || 6,
    md: props.customSize || 3,
  };

  return (
    <Grid
      item
      xs={gridProps.xs}
      sm={gridProps.sm}
      md={gridProps.md}
      container
      alignItems={"center"}
      direction={"row"}
      className={classes.sortSelect}
    >
      <Typography style={{ fontSize: "12px", marginBottom: "1px" }}>
        {"Trier par : "}
      </Typography>

      <Select
        className={classes.sortComponent}
        defaultValue={props.options.filter((o) => o.isDefault)}
        options={props.options}
        onChange={handleChange}
        styles={extraStyles(props.selectedOption)}
        formatOptionLabel={formatOptionLabel}
      />
    </Grid>
  );
};
