import { Radio, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  checkedIcon: {
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 22,
    display: "flex",
    height: 44,
    justifyContent: "center",
    width: 44,
  },
  icon: {
    alignItems: "center",
    backgroundColor: "#f2f2f2",
    borderRadius: 22,
    display: "flex",
    height: 44,
    justifyContent: "center",
    width: 44,
  },
}));

const RadioValeur = (props) => {
  const { valeurchoisie, onClick } = props;
  const classes = useStyles();
  const value = props.value ? props.value : 0;

  const StyledIcon = (
    <div className={classes.icon}>
      <Typography variant={"subtitle1"}>{value}&nbsp;+</Typography>
    </div>
  );
  const StyledCheckedIcon = (
    <div className={classes.checkedIcon}>
      <Typography variant={"subtitle1"} style={{ color: "white" }}>
        {value}&nbsp;+
      </Typography>
    </div>
  );
  const _props = {
    ...props,
    handlealreadyselected: "",
  };

  return (
    <Radio
      {..._props}
      icon={StyledIcon}
      checkedIcon={StyledCheckedIcon}
      checked={parseInt(valeurchoisie, 10) === value}
      onClick={onClick}
    />
  );
};

export default RadioValeur;
