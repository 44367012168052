import React from "react";
import AgentsSearchable from "./AgentsSearchable";

const Conseillers = ({
  refinementsObject,
  onUpdateRefinements,
  t,
  endPoint,
  formatOptionsListe,
}) => {
  const updateConseillersChoisis = (options) => {
    const _options = options
      ? options.map((option) => ({
          ...option,
          isRefined: true,
        }))
      : [];
    onUpdateRefinements("conseillers", _options);
  };

  let selectionConseillers = [];
  if (refinementsObject) {
    selectionConseillers = refinementsObject.conseillers.values.filter(
      (value) => value.isRefined === true
    );
  }

  return (
    <AgentsSearchable
      t={t}
      onChange={updateConseillersChoisis}
      selection={selectionConseillers}
      label={t("Conseillers")}
      endPoint={endPoint}
      formatOptionsListe={formatOptionsListe}
    />
  );
};

export default Conseillers;
