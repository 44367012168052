import React from "react";
import { Marker } from "react-leaflet";

import CircleTX from "../../../../../../components/MapX/Map/components/circleTX";
import iconMapConseiller from "./IconMapConseiller";

const MarkerConseiller = ({ item, zoom = 10, handlePointClick }) => {
  if (zoom > 10)
    return (
      <Marker
        key={`${item.id}-badge`}
        position={[item.lat, item.long]}
        icon={iconMapConseiller({
          bu: item.bu,
          role: item.role,
        })}
        onClick={(evt) => handlePointClick(item)}
      />
    );
  return (
    <CircleTX
      item={item}
      radius={Math.ceil(
        (13 / zoom) * (zoom > 7 ? 300 : zoom > 4 ? 3000 : 10000)
      )}
    />
  );
};

export default MarkerConseiller;
