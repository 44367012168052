import { Grid } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { useDebounce } from "react-use";

import { NumberField } from "../NumberField";
import { NumberRange, NumberRangeValue } from "../types";

export interface NumberRangeProps {
  value: NumberRange;
  onChange: (refinement: NumberRange) => void;
  label: string;
}

export const NumberRangeRefinement: FC<NumberRangeProps> = (props) => {
  const [val, setVal] = useState<NumberRangeValue>();
  const [debouncedValue, setDebouncedValue] = useState<NumberRangeValue>();
  const onChange = () => {
    setDebouncedValue(val);
    props.onChange({ kind: "number-range", value: val });
  };

  const unit = props.value.value?.unit;
  const min = props.value.value?.min;
  const max = props.value.value?.max;

  useEffect(() => {
    const s = { max, min, unit };
    setVal(s);
    setDebouncedValue(s);
  }, [unit, min, max]);

  const [, cancel] = useDebounce(onChange, 500, [val?.min, val?.max]);

  const onBlur = () => {
    cancel();
    onChange();
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <NumberField
          isLoading={val?.min !== debouncedValue?.min}
          label={`${props.label} min`}
          onBlur={onBlur}
          onChange={(min) => setVal((prev) => ({ ...prev, min }))}
          unit={val?.unit}
          value={val?.min}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberField
          isLoading={val?.max !== debouncedValue?.max}
          label={`${props.label} max`}
          onBlur={onBlur}
          onChange={(max) => setVal((prev) => ({ ...prev, max }))}
          unit={val?.unit}
          value={val?.max}
        />
      </Grid>
    </Grid>
  );
};
