import { Theme, useMediaQuery } from "@material-ui/core";
import React from "react";
import { RequestForInformationItemProps } from "UI/features/requestForInformations/interfaces/RequestForInformationItemProps";
import { deepEquality } from "utils/objects/deepEquality";
import { LargeRequestForInformationItem } from "./index.large";
import { MediumRequestForInformationItem } from "./index.medium";
import { SmallRequestForInformationItem } from "./index.small";

export const RequestForInformationResponsiveItem: React.FC<RequestForInformationItemProps> = React.memo(
  ({ requestForInformation, actions, clickHandler = () => {} }) => {
    const { sm, md, lg } = {
      lg: useMediaQuery((theme: Theme) => theme.breakpoints.up("lg")),
      md: useMediaQuery((theme: Theme) => theme.breakpoints.only("md")),
      sm: useMediaQuery((theme: Theme) => theme.breakpoints.down("sm")),
    };

    return (
      <>
        {lg && (
          <LargeRequestForInformationItem
            requestForInformation={requestForInformation}
            actions={actions}
            clickHandler={clickHandler}
          />
        )}
        {md && (
          <MediumRequestForInformationItem
            requestForInformation={requestForInformation}
            actions={actions}
            clickHandler={clickHandler}
          />
        )}
        {sm && (
          <SmallRequestForInformationItem
            requestForInformation={requestForInformation}
            actions={actions}
            clickHandler={clickHandler}
          />
        )}
      </>
    );
  },
  deepEquality
);
