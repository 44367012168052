import { makeStyles } from "@material-ui/core";
import React from "react";

import { ITheme } from "theme/ts/main";

const useStyles = makeStyles((theme: ITheme) => ({
  boxStyle: {
    backgroundColor: "#DEA44E1A",
    borderColor: theme.palette.orangeColorTitle,
    borderRadius: "5px",
    borderStyle: "dotted",
    borderWidth: "1px",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
  },
}));

export const OrangeContainer: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.boxStyle}>{children}</div>;
};
