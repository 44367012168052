import { Grid, IconButton, useTheme } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import AddIcon from "@material-ui/icons/PersonAddOutlined";
import { ExportButton } from "UI/shared/atoms/buttons/ExportButton";
import { SortOption } from "UI/shared/molecules/SortInput";
import { InfiniteScrollList } from "UI/shared/organisms/InfiniteScrollList/InfiniteScrollList";
import PanneauResultatsVides from "UI/shared/templates/Panels/NoItemsPanel";
import TitrePage from "components/TitrePage";
import React, { useCallback, useEffect, useState } from "react";
import { useServices } from "services";
import {
  Aggregations,
  ContactsCriteria,
  ReadPortfolioDto,
} from "services/portfolio/types";
import { usePortfolioRefinements } from "services/portfolio/usePortfolioRefinements";
import {
  PortforlioSearchParams,
  usePortfolioSearch,
} from "services/portfolio/usePortfolioSearch";
import { INITIAL_SLUG } from "services/slugs/constants";
import { useSlugs } from "services/slugs/useSlugs";
import { useSortOptions } from "services/sort/useSortOptions";
import { PortfolioQueryParams, useQuery } from "services/useQuery";

import { ButtonWithStartIcon } from "UI/shared/atoms/buttons/ButtonWithStartIcon";
import { ITheme } from "theme/ts/main";
import { ContactForm } from "./components/ContactForm";
import { ContactItem } from "./components/ContactItem/ContactItem";
import { ContactsSearchCriterias } from "./components/ContactSearchCriterias/ContactsSearchCriterias";
import { ExportForm } from "./components/ExportForm";
import useStyles from "./useStyles";

export const PortfolioPage: React.FC = () => {
  const [pagedContacts, setPagedContacts] = useState<ReadPortfolioDto>({
    aggregations: {} as Aggregations,
    documents: [],
    hasMore: false,
    pageNumber: 0,
    pageSize: 0,
    total: 0,
    totalPage: 1,
  });

  const query = useQuery<PortfolioQueryParams>(["slug"]);
  const { storeSlugs, decodeSlug } = useSlugs<ContactsCriteria>("Portfolio");

  const {
    adaptedRefinements,
    refinements,
    onChange: onChangeRefinements,
    onFullChange: onFullChangeRefinements,
    onClear: onClearRefinements,
  } = usePortfolioRefinements(pagedContacts);

  const {
    l10n: { t },
    modal,
  } = useServices();

  const theme = useTheme<ITheme>();
  const defaultSortOption = {
    isAscendingOrder: false,
    isDefault: true,
    label: "Date de Modification",
    query: "updateDate",
    value: "updateDate desc",
  };

  const sortOptionsValues: SortOption[] = [
    defaultSortOption,
    {
      isAscendingOrder: true,
      isDefault: false,
      label: "Date de Modification",
      query: "updateDate",
      value: "updateDate asc",
    },

    {
      isAscendingOrder: true,
      isDefault: false,
      label: "Date de Création",
      query: "creationDate",
      value: "creationDate asc",
    },
    {
      isAscendingOrder: false,
      isDefault: false,
      label: "Date de Création",
      query: "creationDate",
      value: "creationDate desc",
    },
    {
      isAscendingOrder: true,
      isDefault: false,
      label: "Nom",
      query: "lastName",
      value: "lastName asc",
    },
    {
      isAscendingOrder: false,
      isDefault: false,
      label: "Nom",
      query: "lastName",
      value: "lastName desc",
    },
    {
      isAscendingOrder: true,
      isDefault: false,
      label: "Prénom",
      query: "firstName",
      value: "firstName asc",
    },
    {
      isAscendingOrder: false,
      isDefault: false,
      label: "Prénom",
      query: "firstName",
      value: "firstName desc",
    },
  ];

  const { activeSortOption, onChange: changeSortOptions } = useSortOptions(
    sortOptionsValues,
    defaultSortOption
  );

  const { search } = usePortfolioSearch();

  const [params, setParams] = useState<PortforlioSearchParams>({
    page: 1,
    size: 20,
    sortAsc: true,
    sortColumn: "updateDate",
    withArchivedContacts: false,
  });

  const loadData = useCallback(() => {
    search(adaptedRefinements, activeSortOption, params.page).then(
      (response) => {
        setPagedContacts((prevContacts) => {
          const documents =
            params.page === 1
              ? response.result.documents
              : [...prevContacts.documents, ...response.result.documents];
          return { ...response.result, documents: documents };
        });
      }
    );
  }, [activeSortOption, adaptedRefinements, params.page, search]);

  const loadMore = useCallback(() => {
    if (pagedContacts.hasMore) {
      setParams((prevParams) => ({
        ...prevParams,
        page: prevParams.page + 1,
      }));
    }
  }, [pagedContacts.hasMore]);

  useEffect(() => {
    if (query.slug && query.slug !== INITIAL_SLUG) {
      onFullChangeRefinements(decodeSlug(query.slug));
    }
  }, [decodeSlug, onFullChangeRefinements, query.slug]);

  useEffect(() => {
    storeSlugs(adaptedRefinements);
    setParams((prevParams) => ({
      ...prevParams,
      page: 1,
    }));
    setPagedContacts((prevContacts) => ({
      ...prevContacts,
      documents: [],
    }));
  }, [adaptedRefinements, storeSlugs, activeSortOption]);

  useEffect(() => {
    loadData();
  }, [loadData, params, adaptedRefinements]);

  const classes = useStyles({}) as any;

  const AddContactButton = (
    <ButtonWithStartIcon
      onClick={() => {
        modal.show(<ContactForm closeDialog={modal.close} />);
      }}
      label={t("Ajouter un contact")}
      icon={<AddIcon />}
      backColor={theme.palette.tonique}
      noUppercaseLabel={true}
    />
  );
  const exportButton = (
    <ExportButton
      clickHandler={() =>
        modal.show(
          <ExportForm
            refinements={adaptedRefinements}
            sortOption={activeSortOption}
            closeDialog={modal.close}
          />
        )
      }
      label={t("Export Excel")}
    />
  );

  return (
    <div className={classes.root}>
      <TitrePage
        categorie="CRM"
        page={t("MesContacts")}
        nbResultats={`${pagedContacts.total}  ${t("résultat(s)")}`}
        filePath={"/static/markdowns/contacts.md"}
      />

      <ContactsSearchCriterias
        pagedContacts={pagedContacts}
        onChangeRefinements={onChangeRefinements}
        onClearRefinements={onClearRefinements}
        adaptedRefinements={adaptedRefinements}
        refinements={refinements}
        activeSortOption={activeSortOption}
        changeSortOptions={changeSortOptions}
        sortOptions={sortOptionsValues}
      />
      {AddContactButton}
      {exportButton}
      <Grid container style={{ marginTop: "20px" }}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {pagedContacts.documents.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "space-around",
                  marginTop: "25px",
                }}
              >
                <PanneauResultatsVides clearRefinements={onClearRefinements} />
              </div>
            ) : (
              <InfiniteScrollList loadMore={loadMore} pageSize={20}>
                {pagedContacts.documents.map((contact) => {
                  return (
                    <ContactItem
                      key={contact.id}
                      contact={contact}
                      onChange={() => loadData()}
                    />
                  );
                })}
              </InfiniteScrollList>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
