import routesNames from "router/routesNames";

const ascendingUpdateSortOption = (t) => ({
  isAscendingOrder: true,
  label: t("Date de Modification"),
  query: "DateModification",
  value: "Date de Modification Ascending",
});

const descendingUpdateSortOption = (t) => ({
  isAscendingOrder: false,
  label: t("Date de Modification"),
  query: "DateModification",
  value: "Date de Modification Descending",
});

const ascendingCreationDateSortOption = (t) => ({
  isAscendingOrder: true,
  label: t("Date de Création"),
  query: "DateCreation",
  value: "Date de Création Ascending",
});

const descendingCreationDateSortOption = (t) => ({
  isAscendingOrder: false,
  label: t("Date de Création"),
  query: "DateCreation",
  value: "Date de Création Descending",
});

const ascendingCommitmentSortOption = (t) => ({
  isAscendingOrder: true,
  label: t("Date de Signature"),
  query: "DateEffectiveSignature",
  value: "Date de Signature Ascending",
});

const descendingCommitmentSortOption = (t) => ({
  isAscendingOrder: false,
  label: t("Date de Signature"),
  query: "DateEffectiveSignature",
  value: "Date de Signature Descending",
});

const ascendingSalesAgreementSortOption = (t) => ({
  isAscendingOrder: true,
  label: t("Date de Compromis"),
  query: "DateEffectiveCompromis",
  value: "Date de Compromis Ascending",
});

const descendingSalesAgreementSortOption = (t) => ({
  isAscendingOrder: false,
  label: t("Date de Compromis"),
  query: "DateEffectiveCompromis",
  value: "Date de Compromis Descending",
});

const ascendingPriceSortOption = (t) => ({
  isAscendingOrder: true,
  label: t("Prix"),
  query: "Prix",
  value: "Prix Ascending",
});

const descendingPriceSortOption = (t) => ({
  isAscendingOrder: false,
  label: t("Prix"),
  query: "Prix",
  value: "Prix Descending",
});

const forSaleRealEstatesFilterOptions = (t) => [
  ascendingUpdateSortOption(t),
  descendingUpdateSortOption(t),
  ascendingCreationDateSortOption(t),
  descendingCreationDateSortOption(t),
  ascendingPriceSortOption(t),
  descendingPriceSortOption(t),
];

const soldRealEstatesFilterOptions = (t) => [
  ascendingUpdateSortOption(t),
  descendingUpdateSortOption(t),
  ascendingCommitmentSortOption(t),
  descendingCommitmentSortOption(t),
  ascendingSalesAgreementSortOption(t),
  descendingSalesAgreementSortOption(t),
  ascendingCreationDateSortOption(t),
  descendingCreationDateSortOption(t),
  ascendingPriceSortOption(t),
  descendingPriceSortOption(t),
];

export const filterOptions = (route) => (t) =>
  route.startsWith(
    `${routesNames.app}${routesNames.biens}${routesNames.biensVendus}`
  )
    ? soldRealEstatesFilterOptions(t)
    : forSaleRealEstatesFilterOptions(t);
