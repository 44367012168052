import React, { createContext, useCallback, useEffect, useState } from "react";
import { useServices } from "services";
import { useFeatureFlags } from "services/featureFlags";
import { useTxHttp } from "services/http";

export interface PigeFavorisContextProps {
  toggle: (id?: string) => void;
  isFavorite: (id?: string) => boolean;
}

export const PigeFavorisContext = createContext<PigeFavorisContextProps>(
  {} as PigeFavorisContextProps
);

export const PigeFavorisContextProvider: React.FC = ({ children }) => {
  const { get, put, del } = useTxHttp();
  const { endpoints } = useServices();
  const { Pige } = useFeatureFlags();

  const [favorites, setFavorites] = useState<string[]>([]);

  const getFavorites = useCallback(
    () =>
      get<string[]>(endpoints.pige.favoris()).then((response) =>
        setFavorites(response.data)
      ),
    [endpoints.pige, get]
  );

  const like = useCallback(
    (id: string) =>
      put<string[]>(endpoints.pige.favoris(id)).then(getFavorites),
    [endpoints.pige, getFavorites, put]
  );
  const unlike = useCallback(
    (id: string) =>
      del<string[]>(endpoints.pige.favoris(id)).then(getFavorites),
    [del, endpoints.pige, getFavorites]
  );

  const {
    trackingService: { sendCustomEventToGoogle },
  } = useServices();

  const isFavorite = (id?: string) => {
    if (!id) return false;
    return favorites.includes(id);
  };
  const toggle = (id?: string) => {
    if (!id) return;
    if (isFavorite(id)) {
      unlike(id).then(getFavorites);
    } else {
      sendCustomEventToGoogle("Ajout annonce pige en favoris");
      like(id).then(getFavorites);
    }
  };

  useEffect(() => {
    if (Pige) {
      getFavorites();
    }
  }, [getFavorites, Pige]);

  return (
    <PigeFavorisContext.Provider value={{ isFavorite, toggle }}>
      {children}
    </PigeFavorisContext.Provider>
  );
};
