import { Grid } from "@material-ui/core";
import {
  AccountBoxOutlined,
  HomeWorkOutlined,
  PeopleAltOutlined,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { pigeRoutes } from "router/pigeRoutes";
import routesNames from "router/routesNames";
import RechercheGlobaleContactsAdapter from "routes/app/contacts/services//RechercheGlobale/ContactRechercheGlobaleAdapter";
import RechercheGlobaleConseillersAdapter from "services/conseillers/dto/Conseillers/RechercheGlobale/rechercheGlobaleAdapter";
import { useFeatureFlags } from "services/featureFlags";

import MiniCarteInfo from "../../../components/MiniCarteInfo";
import TitrePage from "../../../components/TitrePage";
import { serialize } from "../../../layoutContents/components/HitsContainer/utils";
import { useServices } from "../../../services";
import RechercheGlobaleBiensEnVenteAdapter from "../../../services/rapprochement/Rapprochement/BiensEnVente/RechercheGlobale/rechercheGlobaleAdapter";
import RechercheGlobaleBiensVendusAdapter from "../../../services/rapprochement/Rapprochement/BiensVendus/RechercheGlobale/rechercheGlobaleAdapter";
import PanneauRechercheGlobale from "./components/PanneauRechercheGlobale";

const SECTIONS_IDS = {
  biensDisponibles: "section-biens-disponibles",
  biensVendus: "section-biens-vendus",
  conseillers: "section-conseillers",
  contacts: "section-contacts",
  pige: "section-pige",
};

const totalCompteurs = (compteurs) =>
  Object.keys(compteurs).reduce((sum, key) => sum + +compteurs[key], 0);

export const RechercheGlobale = () => {
  const { fulltext } = useParams();

  const {
    l10n: { t },
    endpoints,
  } = useServices();

  const { Pige, Contacts_V2 } = useFeatureFlags();

  const [compteurs, setCompteurs] = useState({
    biensDisponibles: 0,
    biensVendus: 0,
    conseillers: 0,
    contacts: 0,
    pige: 0,
  });

  const [scrollSectionId, setScrollSectionId] = useState(null);

  useEffect(() => {
    if (scrollSectionId) {
      const section = document.getElementById(scrollSectionId);
      const { y: scrollY } = section.getBoundingClientRect();
      const sectionHeaderOffsetY = 55;
      window.scrollTo({
        behavior: "smooth",
        top: scrollY - sectionHeaderOffsetY,
      });

      setScrollSectionId(null);
    }
  }, [scrollSectionId]);

  const urlBiensEnVenteRechercheGlobale =
    endpoints.bienEnVente.rechercheGlobale;
  const urlBiensVendusRechercheGlobale = endpoints.biensVendus.rechercheGlobale;
  const urlConseillersRechercheGlobale = endpoints.conseillers.rechercheGlobale;
  const urlPigeRechercheGlobale = endpoints.pige.rechercheGlobale;

  const urlBiensEnVente = `${routesNames.app}${routesNames.biens}${routesNames.biensEnVente}/list`;
  const urlBiensVendus = `${routesNames.app}${routesNames.biens}${routesNames.biensVendus}/list`;
  const urlConseillers = `${routesNames.app}${routesNames.monReseau}${routesNames.conseillers}/list`;
  const urlContacts = `${routesNames.app}${routesNames.contacts}`;
  const urlPige = `${routesNames.listeBiensPige}?slug=full-list`;

  const serializedParams = serialize({
    displayMode: "list",
    refinements: [{ query: "fullText", value: fulltext }],
  });

  const _urlBiensEnVente = `${urlBiensEnVente}/${serializedParams}`;
  const _urlBiensVendus = `${urlBiensVendus}/${serializedParams}`;
  const _urlConseillers = `${urlConseillers}/${serializedParams}`;
  const _urlContacts = `${urlContacts}/${serializedParams}`;

  const onItemClick = ({ mode, id }) => {
    switch (mode) {
      case "biensEnVente":
        return `${routesNames.app}${routesNames.biens}${
          routesNames.bienEnVente
        }/${id}/details${"/view"}`;
      case "biensPige":
        return pigeRoutes.toDetail(id, routesNames.dashboard);
      case "biensVendus":
      default:
        return `${routesNames.app}${routesNames.biens}${
          routesNames.bienVendu
        }/${id}/details${"/view"}`;
    }
  };

  const onContactClick = ({ id }) => {
    window.open(
      `${routesNames.app}${routesNames.contact}/${id}/identity/view`,
      "_blank"
    );
  };

  const handleSectionScroll = (id) => {
    setScrollSectionId(id);
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          backgroundColor: "#02a9b9",
          height: "200px",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%",
          zIndex: "-1",
        }}
      >
        &nbsp;
      </div>
      <TitrePage
        categorie={t("Recherche")}
        page={t("Résultats")}
        nbResultats={`
                ${totalCompteurs(compteurs)} ${t("résultat(s)")}
                `}
        filePath={"/static/markdowns/rechercheGlobale.md"}
        color={"white"}
      />
      <div style={{ height: "20px" }}>&nbsp;</div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3} lg={2}>
              <MiniCarteInfo
                Icon={HomeWorkOutlined}
                valeur={compteurs.biensDisponibles}
                labelPrimaire={"Biens Disponibles"}
                labelSecondaire={""}
                isGlobalSearch={true}
                onClick={() =>
                  handleSectionScroll(SECTIONS_IDS.biensDisponibles)
                }
              />
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <MiniCarteInfo
                Icon={HomeWorkOutlined}
                valeur={compteurs.biensVendus}
                labelPrimaire={"Biens Vendus"}
                labelSecondaire={""}
                isGlobalSearch={true}
                onClick={() => handleSectionScroll(SECTIONS_IDS.biensVendus)}
              />
            </Grid>
            {Pige && (
              <Grid item xs={6} md={3} lg={2}>
                <MiniCarteInfo
                  Icon={HomeWorkOutlined}
                  valeur={compteurs.pige}
                  labelPrimaire={"Biens Pige"}
                  labelSecondaire={""}
                  isGlobalSearch={true}
                  onClick={() => handleSectionScroll(SECTIONS_IDS.pige)}
                />
              </Grid>
            )}
            <Grid item xs={6} md={3} lg={2}>
              <MiniCarteInfo
                Icon={PeopleAltOutlined}
                valeur={compteurs.conseillers}
                labelPrimaire={"Conseillers"}
                labelSecondaire={""}
                isGlobalSearch={true}
                onClick={() => handleSectionScroll(SECTIONS_IDS.conseillers)}
              />
            </Grid>
            {Contacts_V2 && (
              <Grid item xs={6} md={3} lg={2}>
                <MiniCarteInfo
                  Icon={AccountBoxOutlined}
                  valeur={compteurs.contacts}
                  labelPrimaire={"Contacts"}
                  labelSecondaire={""}
                  isGlobalSearch={true}
                  onClick={() => handleSectionScroll(SECTIONS_IDS.contacts)}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <div style={{ height: "20px" }}>&nbsp;</div>
        <Grid item xs={12} id={SECTIONS_IDS.biensDisponibles}>
          <PanneauRechercheGlobale
            fullText={fulltext}
            url={urlBiensEnVenteRechercheGlobale}
            urlGridOuListe={_urlBiensEnVente}
            adapter={RechercheGlobaleBiensEnVenteAdapter}
            pageNumber={1}
            pageSize={10}
            mode={"biensEnVente"}
            onItemClick={({ id }) => onItemClick({ id, mode: "biensEnVente" })}
            setCompteur={(valeur) =>
              setCompteurs({ ...compteurs, biensDisponibles: valeur })
            }
          />
        </Grid>
        <Grid item xs={12} id={SECTIONS_IDS.biensVendus}>
          <PanneauRechercheGlobale
            fullText={fulltext}
            url={urlBiensVendusRechercheGlobale}
            urlGridOuListe={_urlBiensVendus}
            adapter={RechercheGlobaleBiensVendusAdapter}
            pageNumber={1}
            pageSize={10}
            mode={"biensVendus"}
            onItemClick={({ id }) => onItemClick({ id, mode: "biensVendus" })}
            setCompteur={(valeur) =>
              setCompteurs({ ...compteurs, biensVendus: valeur })
            }
          />
        </Grid>
        {Pige && (
          <Grid item xs={12} id={SECTIONS_IDS.pige}>
            <PanneauRechercheGlobale
              fullText={fulltext}
              url={urlPigeRechercheGlobale}
              urlGridOuListe={urlPige}
              adapter={(_) => _}
              pageNumber={1}
              pageSize={10}
              mode={"pige"}
              onItemClick={({ id }) => {
                onItemClick({ id, mode: "biensPige" });
              }}
              setCompteur={(val) => setCompteurs({ ...compteurs, pige: val })}
            />
          </Grid>
        )}
        <Grid item sm={12} md={6} id={SECTIONS_IDS.conseillers}>
          <PanneauRechercheGlobale
            fullText={fulltext}
            url={urlConseillersRechercheGlobale}
            urlGridOuListe={_urlConseillers}
            adapter={RechercheGlobaleConseillersAdapter}
            pageNumber={1}
            pageSize={10}
            mode={"conseillers"}
            setCompteur={(valeur) =>
              setCompteurs({ ...compteurs, conseillers: valeur })
            }
          />
        </Grid>
        {Contacts_V2 && (
          <Grid item sm={12} md={6} id={SECTIONS_IDS.contacts}>
            <PanneauRechercheGlobale
              fullText={fulltext}
              url={endpoints.portfolio.contacts.globalSearch}
              urlGridOuListe={_urlContacts}
              adapter={RechercheGlobaleContactsAdapter}
              pageNumber={1}
              pageSize={20}
              mode={"contacts"}
              setCompteur={(valeur) =>
                setCompteurs({ ...compteurs, contacts: valeur })
              }
              onItemClick={(contact) => onContactClick({ id: contact.id })}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
