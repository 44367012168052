export const PAGE_SIZE_LIST = 20;
export const PAGE_SIZE_GALLERY = 20;
export const PAGE_SIZE_MAP = 20;

export const NB_TRIALS_SSO = 10;

// eslint-disable-next-line no-undef
window.modeDemo =
  process.env.REACT_APP_ENVIRONMENT === "Production" ||
  process.env.REACT_APP_ENVIRONMENT === "Staging";

declare global {
  interface Window {
    defaultPage: string;
    modeDemo: boolean;
    cityScanAvailable: boolean;
    version: string;
    testTX: boolean;
  }
}

// flag temporaire pendant indisponibilité CityScan
window.cityScanAvailable = false;

// Version de l'application
window.version = "1.9";

// page par défaut
window.defaultPage = "/app/dashboard";

window.testTX = false;
