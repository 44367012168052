import { Grid, Typography } from "@material-ui/core";
import React from "react";

import { useServices } from "../../../../../services";

const Proximites = (props) => {
  const { proximites } = props;
  const {
    l10n: { t },
  } = useServices();
  return (
    <Grid container spacing={0}>
      {proximites.map((proximite) => (
        <Grid item xs={12} key={proximite[0]}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Typography variant="subtitle2" align="right">
                {t(proximite[0])}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              &nbsp;
            </Grid>
            <Grid item xs={5}>
              <Typography variant="subtitle1">
                {`${proximite[1] === "true" ? "Oui" : proximite[1]}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default Proximites;
