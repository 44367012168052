import {
  CircularProgress,
  Divider,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { ArchiveOutlined, CallMade } from "@material-ui/icons";
import MarkDownDisplay from "components/MarkDownDisplay";
import StyledDialog from "components/StyledDialog";
import changelog from "markdowns/changelog.md";
import moment from "moment";
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { NotificationDto } from "../../../services/notifications/Dto/notificationsDto";
import { ITheme } from "../../../theme/ts/main";
import { Description } from "./Description";

const useStyles = makeStyles<ITheme, { scopeLabel?: string }>(
  (theme: ITheme) => ({
    detail: {
      color: theme.palette.common.dark,
    },
    etiquetteScope: {
      borderRadius: 5,
      paddingLeft: 10,
      paddingRight: 10,
      textAlign: "center",
      width: "50px",
    },
    root: {
      height: "67px",
    },
    scopeLabel: (props) => {
      const backgroundColorSwitch: Record<string, string> = {
        Biens: "#65A867A6",
        DR: "#6ca9e0",
        Pige: "#BFB7B7",
        TX: "#718496A6",
        default: "#2e2e2e",
      };

      const backgroundColor =
        backgroundColorSwitch[props.scopeLabel || "default"];

      return {
        backgroundColor,
        color: "#FFFFFF",
      };
    },
    separator: {
      margin: "auto",
      opacity: "50%",
    },
  })
);

const buildLinkUrl = (scope: string, value: string, redirectUrl?: string) => {
  const defaultLink = "/app/notifications/";
  const redirectPart = redirectUrl ? `&redirectUrl=${redirectUrl}` : "";
  const scopeSwitch: Record<string, string> = {
    Biens: `/app/biens/bien-en-vente/${value}/details/view`,
    DR: `/app/demandes-renseignements?id=${value}`,
    Pige: `/app/biens/pige?id=${value}${redirectPart}`,
  };

  return scopeSwitch[scope] || defaultLink;
};

const getNotificationEquality = (
  prevProps: NotificationItemProps,
  nextProps: NotificationItemProps
) =>
  JSON.stringify(prevProps.notification) ===
  JSON.stringify(nextProps.notification);

export const NotificationItem = React.memo(
  ({ notification, markAsArchived }: NotificationItemProps) => {
    const mobile = useMediaQuery("(max-width: 600px)");

    const [pending, setPending] = useState(false);
    const classes = useStyles({
      scopeLabel: notification.scopeLabel,
    });

    const { dialogComponent, switchStatus } = StyledDialog({
      content: <MarkDownDisplay filePath={changelog} />,
    });

    const { pathname } = useLocation();

    return (
      <>
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.root}
        >
          {!mobile && (
            <Grid container item sm={1} justify={"center"}>
              <ScopeLabel scopeLabel={notification.scopeLabel} />
            </Grid>
          )}
          <Grid item xs={9} sm={8}>
            <Description
              newNotification={notification.state === "New"}
              title={notification.title}
              summary={notification.summary}
              detail={notification.detail}
              mobile={mobile}
            />
            {mobile && (
              <div style={{ display: "flex", marginLeft: 20 }}>
                <ScopeLabel scopeLabel={notification.scopeLabel} />
                <Typography
                  variant={"h6"}
                  color="textSecondary"
                  className={classes.detail}
                  style={{ marginLeft: 10 }}
                >
                  {moment(notification.timestamp).format("DD/MM/YYYY HH:mm")}
                </Typography>
              </div>
            )}
          </Grid>
          {!mobile && (
            <Grid item sm={1}>
              <Typography
                variant={"body1"}
                color="textSecondary"
                className={classes.detail}
              >
                {moment(notification.timestamp).format("DD/MM/YYYY HH:mm")}
              </Typography>
            </Grid>
          )}
          <Grid item xs={3} sm={2}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <NotificationClickHandler
                scopeLabel={notification.scopeLabel}
                scopeValue={notification.scopeValue}
                openChangelog={switchStatus}
                redirectUrl={pathname}
              >
                <Tooltip title={"Ouvrir"} placement={"top"}>
                  <IconButton>
                    <CallMade color={"primary"} />
                  </IconButton>
                </Tooltip>
              </NotificationClickHandler>

              {notification.state !== "Archived" && (
                <Tooltip title={"Archiver"} placement={"top"}>
                  <IconButton
                    onClick={async () => {
                      setPending(true);
                      await markAsArchived(notification.id);
                      setPending(false);
                    }}
                    disabled={pending}
                  >
                    {pending ? (
                      <CircularProgress size={24} />
                    ) : (
                      <ArchiveOutlined color={"primary"} />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </Grid>
        </Grid>
        <Divider className={classes.separator} />
        {dialogComponent}
      </>
    );
  },
  getNotificationEquality
);

interface NotificationClickHandlerProps {
  scopeLabel: string;
  scopeValue: string;
  openChangelog: () => void;

  redirectUrl?: string;
}

const NotificationClickHandler: React.FC<NotificationClickHandlerProps> = ({
  scopeLabel,
  scopeValue,
  openChangelog,
  children,
  redirectUrl,
}) => {
  switch (scopeLabel) {
    case "TX":
      return <div onClick={openChangelog}>{children}</div>;
    default:
      return (
        <NavLink to={buildLinkUrl(scopeLabel, scopeValue, redirectUrl)}>
          {children}
        </NavLink>
      );
  }
};

export interface NotificationItemProps {
  notification: NotificationDto;
  markAsArchived: (notificationId: string) => void;
}

interface ScopeLabelProps {
  scopeLabel: string;
}

const ScopeLabel: React.FC<ScopeLabelProps> = ({ scopeLabel }) => {
  const classes = useStyles({ scopeLabel });
  return (
    <div className={`${classes.scopeLabel} ${classes.etiquetteScope}`}>
      {scopeLabel}
    </div>
  );
};
