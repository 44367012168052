import React, { FC } from "react";
import { BudgetRentalDto } from "routes/app/contacts/contact/components/models/budgetRentalDto";
import { MoreInfos } from "UI/features/profiles/organisms/Search/MoreInfos";
import { useServices } from "services";
import { formatPrix } from "utils/formatage";

export const RentalBudget: FC<{ budget: BudgetRentalDto }> = ({ budget }) => {
  const {
    l10n: { t, formatBudget },
  } = useServices();
  const informations = [
    {
      label: t("Budget"),
      value: formatBudget(budget.min, budget.max),
    },
    {
      label: t("Budget max pour rapprochement"),
      value: formatPrix(budget.maxMatching),
    },
  ];
  return <MoreInfos title="Budget" value={informations} />;
};
