import { Grid } from "@material-ui/core";
import { NumberField } from "components/NumberField";
import React, { FC } from "react";

import { BuildingInfoDto } from "../../models/buildingInfoDto";
import { SubSectionTitle } from "../components/SectionTitle";
import { ValidationNumberField } from "../components/ValidationNumberField";
import { ProfileInputProps } from "../InputProps";

export const SaleBuildingInput: FC<ProfileInputProps<BuildingInfoDto>> = ({
  value,
  onChange,
  disabled = false,
}) => {
  return (
    <>
      <SubSectionTitle title="L'immeuble" />
      <Grid item xs={12}>
        <NumberField
          label="Année de construction"
          onChange={(yearBuilt) => {
            onChange({ ...value, yearBuilt }, true);
          }}
          value={value.yearBuilt}
          format={"####"}
          adornement={""}
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <NumberField
            label="Etage du bien"
            onChange={(propertyFloor) =>
              onChange({ ...value, propertyFloor }, true)
            }
            value={value.propertyFloor}
            format={"###"}
            adornement={""}
            allowZero={true}
          />
        </Grid>
        <Grid item xs={6}>
          <ValidationNumberField
            disabled={disabled}
            label="Nombre d'étages total"
            onChange={(isValid, floorsCount) =>
              onChange({ ...value, floorsCount }, isValid)
            }
            value={value.floorsCount}
            maxLimit={200}
          />
        </Grid>
      </Grid>
    </>
  );
};
