import { RadioGroup } from "@material-ui/core";
import React from "react";

import { useServices } from "../../../../../../../services";
import { VacancyType } from "../../models/vacancyType";
import { ComplementIcon } from "../components/RadioChip";
import { SubSectionTitle } from "../components/SectionTitle";
import { SelectChip } from "../SelectChip";

export const SelectVacancy: React.FC<{
  value: VacancyType;
  onChange: (value: VacancyType) => void;
  disabled: boolean;
}> = ({ disabled, value, onChange }) => {
  const {
    l10n: { t },
  } = useServices();

  const options: VacancyType[] = ["All", "Vacant", "Occupied"];

  return (
    <>
      <SubSectionTitle title="Complément :" />
      <RadioGroup
        row
        value={value}
        onChange={(_) => onChange(_.target.value as VacancyType)}
      >
        {options.map((option) => (
          <SelectChip
            key={option}
            icon={
              <ComplementIcon label={t(`vacancy.${option}`)} checked={false} />
            }
            checkedIcon={
              <ComplementIcon label={t(`vacancy.${option}`)} checked={true} />
            }
            value={option}
          />
        ))}
      </RadioGroup>
    </>
  );
};
