import { Grid, Typography } from "@material-ui/core";
import React from "react";

import { useServices } from "../../../../../services";

const General = (props) => {
  const { general, familleBienEnum } = props;
  const {
    l10n: { t },
  } = useServices();

  const isHouse = familleBienEnum === "Maison";
  return (
    <Grid container spacing={0}>
      <Grid item xs={6}>
        <Typography variant="subtitle1" align="right">
          {t("Référence")}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        &nbsp;
      </Grid>
      <Grid item xs={5}>
        <Typography variant="h4">{general.reference}</Typography>
      </Grid>
      {general.anneeConstruction !== "" && (
        <>
          <Grid item xs={6}>
            <Typography variant="subtitle1" align="right">
              {t("Année de Contruction")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h4">
              {general.anneeConstruction !== ""
                ? general.anneeConstruction
                : "N.C."}
            </Typography>
          </Grid>
        </>
      )}
      {general.exposition !== "" && (
        <>
          <Grid item xs={6}>
            <Typography variant="subtitle1" align="right">
              {t("Exposition")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h4">
              {general.exposition.toUpperCase()}
            </Typography>
          </Grid>
        </>
      )}
      {isHouse &&
        general.singleStorey !== null &&
        general.singleStorey !== undefined && (
          <>
            <Grid item xs={6}>
              <Typography variant="subtitle1" align="right">
                {t("Plain-pied")}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              &nbsp;
            </Grid>
            <Grid item xs={5}>
              <Typography variant="h4">
                {general.singleStorey ? "Oui" : "Non"}
              </Typography>
            </Grid>
          </>
        )}
      {isHouse && general.withPool !== null && general.withPool !== undefined && (
        <>
          <Grid item xs={6}>
            <Typography variant="subtitle1" align="right">
              {t("Piscine")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h4">
              {general.withPool ? "Oui" : "Non"}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default General;
