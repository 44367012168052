import {Button, Typography} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {
    AdsDefaultSearchLocationKind,
    AdsDefaultSearchLocationsSettingsDto,
} from "services/adsDefaultSearchLocations/dto/AdsDefaultSearchLocationsSettingDto";
import UseNotifier from "../../../../../../../components/Notifier/useNotifier";
import {RefinementChip} from "UI/shared/atoms/chips/RefinementChip";
import {OrangeWarningNote} from "UI/shared/molecules/OrangeWarningNote";
import {RefinementField} from "components/RefinementField";
import {Locations} from "components/RefinementField/types";
import TitreSection from "layoutContents/Fiches/components/Details/components/TitreSection";
import {useServices} from "services";
import SaveIcon from "@material-ui/icons/SaveOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/styles";
import {ITheme} from "../../../../../../../theme/ts/main";
import useNotifier from "../../../../../../../components/Notifier/useNotifier";
import {Row} from "../../../../../../../UI/shared/atoms/containers/Row";

export interface PigeSettingsProps {
    conseiller: any;
}

const locationsRefinementFromDefaultSearchLocationsSettings = (
    defaultSearchLocationsSettings: AdsDefaultSearchLocationsSettingsDto
): Locations => {
    return {
        kind: "locations",
        value: {
            regions: defaultSearchLocationsSettings
                .filter((l) => l.kind === "Region")
                .map((l) => ({label: l.label, value: l.id, isRefined: true})),
            departements: defaultSearchLocationsSettings
                .filter((l) => l.kind === "Departement")
                .map((l) => ({label: l.label, value: l.id, isRefined: true})),
            villes: defaultSearchLocationsSettings
                .filter((l) => l.kind === "Ville")
                .map((l) => ({label: l.label, value: l.id, isRefined: true})),
            quartiers: [],
        },
    };
};

const defaultSearchLocationsSettingsFromLocationsRefinement = (
    locationRefinement: Locations
): AdsDefaultSearchLocationsSettingsDto => {
    const regions = locationRefinement.value.regions
        .filter((l) => l.isRefined)
        .map((l) => ({
            id: l.value,
            label: l.label,
            kind: "Region" as AdsDefaultSearchLocationKind,
        }));
    const departements = locationRefinement.value.departements
        .filter((l) => l.isRefined)
        .map((l) => ({
            id: l.value,
            label: l.label,
            kind: "Departement" as AdsDefaultSearchLocationKind,
        }));
    const villes = locationRefinement.value.villes
        .filter((l) => l.isRefined)
        .map((l) => ({
            id: l.value,
            label: l.label,
            kind: "Ville" as AdsDefaultSearchLocationKind,
        }));

    return [...regions, ...departements, ...villes];
};

const useStyles = makeStyles((theme: ITheme) => ({
    button: {
        marginTop: theme.spacing(1),
    },

    buttonProgress: {
        color: theme.palette.tonique,
        left: "50%",
        marginLeft: -12,
        marginTop: -12,
        position: "absolute",
        top: "50%",
    },

    buttonWrapper: {
        margin: 0,
        position: "relative",
    }
}));

export const PigeSettings: React.FC<PigeSettingsProps> = ({conseiller}) => {
    const [zoneDeRecherche, setZoneDeRecherche] = useState<Locations>(
        locationsRefinementFromDefaultSearchLocationsSettings(
            conseiller.adsDefaultSearchLocations
        )
    );
    const {showSnack} = useNotifier();
    const [state, setState] = useState<"none" | "saving" | "saved" | "error">(
        "none"
    );
    
    useEffect(() => {
        switch (state) {
            case "none":
                break;
            case "saving":
                conseiller.setAdsDefaultSearchLocations(defaultSearchLocationsSettingsFromLocationsRefinement(zoneDeRecherche))
                    .then(() => setState("saved"))
                    .catch(() => setState("error"));
                break;
            case "saved":
                showSnack({message: "La zone géographique de pige par défaut est enregistrée.", variant: "success"});
                break;
            case "error":
                showSnack({message: "Une erreur est survenue.", variant: "error"});
                break;
        }
    }, [state]);

    const classes = useStyles();
    const {
        l10n: {t},
    } = useServices();

    const onChange = useCallback(
        (value: Locations) => {
            setZoneDeRecherche(value);
        },
        [conseiller]
    );

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <TitreSection
                titre={t("pige - zone géographique par défaut".toUpperCase())}
            />
            <Typography variant={"h5"}>
                Modifier ma zone géographique par défaut
            </Typography>
            <Typography variant={"body2"}>
                (région, département, ville, code postal)
            </Typography>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 10,
                    marginBottom: 10,
                }}
            >
                <RefinementField
                    value={zoneDeRecherche}
                    onChange={(value) => {
                        onChange(value as Locations);
                    }}
                    label={t("Localisations")}
                    options={{excludedLocationTypes: ["Quartier"]}}
                />
                <div style={{display: "flex", flexWrap: "wrap", marginTop: 5}}>
                    <RefinementChip
                        value={zoneDeRecherche}
                        onChange={(value) => onChange(value as Locations)}
                        format={(_) => _}
                    />
                </div>
            </div>
            <OrangeWarningNote
                title={"ATTENTION"}
                content={
                    "Il est nécessaire d'avoir au moins une localisation renseignée pour pouvoir recevoir des notifications sur les nouveaux biens au sein de la pige."
                }
            />
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <div className={classes.buttonWrapper}>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={() => setState("saving")}
                        disabled={state === "saving"}
                    >
                        <SaveIcon/>
                        {t("Enregistrer")}
                    </Button>
                    {state === "saving" && (
                        <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
