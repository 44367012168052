import {
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { DeleteOutline, EditOutlined, Phone } from "@material-ui/icons";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import MoreIcon from "@material-ui/icons/MoreVertOutlined";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import TodayOutlinedIcon from "@material-ui/icons/TodayOutlined";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import { ActionsMenu } from "components/ActionsMenu";
import { ActionButton } from "UI/shared/atoms/buttons/ActionButton";
import TitreSection from "layoutContents/Fiches/components/Details/components/TitreSection";
import moment from "moment";
import React from "react";
import { useServices } from "services";
import { ContactEventReceived } from "services/contactEventsTracking/dto/ContactEvent";
import { ITheme } from "theme/ts/main";

const useStyles = makeStyles((theme: ITheme) => ({
  a: {
    "&:hover": {
      color: theme.palette.tonique,
    },
    "&:visited": {
      color: theme.palette.buttonSelected,
    },
    color: theme.palette.buttonSelected,
    textDecoration: "none",
  },
  comingEvent: {
    backgroundColor: theme.palette.default,
  },
  icons: {
    color: theme.palette.default,
  },
  previousEvent: {
    backgroundColor: "#5A6776",
  },
}));

const renderIcon = (kind: string) => {
  switch (kind) {
    case "PhoneCall":
      return <Phone />;
    case "SendMail":
      return <DescriptionOutlinedIcon />;
    case "SendEmail":
      return <MailOutlineIcon />;
    case "SendSms":
      return <SmsOutlinedIcon />;
    case "Appointment":
      return <TodayOutlinedIcon />;
    case "Other":
      return <HelpOutlineOutlinedIcon />;
  }
};

interface EventDisplayProps {
  event: ContactEventReceived;
  displayTimelineDot: boolean;
  actions: {
    remove: JSX.Element;
    update: JSX.Element;
  };
}

const EventDisplay: React.FC<EventDisplayProps> = ({
  event,
  displayTimelineDot,
  actions,
}) => {
  const [anchor, setAnchor] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const {
    l10n: { t },
  } = useServices();

  const classes = useStyles();

  const mobile = useMediaQuery("(max-width: 600px)");

  const handleClickMore = (
    htmlEvent: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    htmlEvent.stopPropagation();
    htmlEvent.preventDefault();
    setAnchor(htmlEvent.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchor(null);
  };

  return (
    <TimelineItem>
      <TimelineOppositeContent
        style={{
          padding: mobile ? 5 : "6px 16px",
          width: mobile ? "50%" : "inherit",
        }}
      >
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: mobile ? "flex-start" : "flex-end",
          }}
        >
          <div
            style={{
              alignItems: "flex-end",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "50%",
            }}
          >
            <Typography variant="body2" color="textSecondary">
              {moment(event.date).format("DD/MM/YY")}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {moment(event.date).format("HH:mm")}
            </Typography>
          </div>
        </div>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot
          className={
            moment().isAfter(event.date)
              ? classes.previousEvent
              : classes.comingEvent
          }
        >
          {renderIcon(event.kind)}
        </TimelineDot>
        {displayTimelineDot && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent style={{ width: mobile ? "50%" : "100%" }}>
        <div
          style={{
            alignItems: "flex-start",
            display: "flex",
            flexDirection: mobile ? "column" : "row",
            width: !mobile ? "300px" : "inherit",
          }}
        >
          <div>
            <Typography variant="h6">{t(event.kind)}</Typography>
            <Typography
              variant="body1"
              style={{
                whiteSpace: "pre-wrap",
                width: mobile ? "150px" : "inherit",
              }}
              noWrap
            >
              {event.comment}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "flex-end",
              width: mobile ? "100%" : "50%",
            }}
          >
            {event.calendarEventId !== "" ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://outlook.office.com/calendar/item/${encodeURIComponent(
                  event.calendarEventId
                )}`}
                className={classes.a}
              >
                <div style={{ flexGrow: 1 }}>
                  <img
                    alt="outlook"
                    src={"/static/images/outlook.png"}
                    width={"40px"}
                    height={"auto"}
                  />
                </div>
              </a>
            ) : (
              <></>
            )}
            <Tooltip title={t("Actions")} placement="top">
              <IconButton
                className={classes.icons}
                aria-label="plus"
                onClick={handleClickMore}
              >
                <MoreIcon />
              </IconButton>
            </Tooltip>
            <ActionsMenu
              actions={[actions.update, actions.remove]}
              anchor={anchor}
              onClose={handleCloseMenu}
            />
          </div>
        </div>
      </TimelineContent>
    </TimelineItem>
  );
};

interface CustomizedTimelineProps {
  events: ContactEventReceived[];
  actions: {
    remove: (data: ContactEventReceived) => void;
    update: (data: ContactEventReceived) => void;
  };
}

const CustomizedTimeline: React.FC<CustomizedTimelineProps> = ({
  events,
  actions,
}) => {
  const mobile = useMediaQuery("(max-width: 600px)");
  return (
    <>
      <TitreSection titre={"Actions"} />
      <Timeline style={{ padding: mobile ? 5 : "inherit" }}>
        {events.map((event, index) => (
          <EventDisplay
            key={index}
            event={event}
            displayTimelineDot={index !== events.length - 1}
            actions={{
              remove: (
                <ActionButton
                  icon={<DeleteOutline />}
                  onClick={() => actions.remove(event)}
                  text={!mobile ? "Supprimer" : undefined}
                />
              ),
              update: (
                <ActionButton
                  icon={<EditOutlined />}
                  onClick={() => actions.update(event)}
                  text={!mobile ? "Modifier" : undefined}
                />
              ),
            }}
          />
        ))}
      </Timeline>
    </>
  );
};

export default CustomizedTimeline;
