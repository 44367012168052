import React from "react";
import { Typography, Radio } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  checkedIcon: {
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 27,
    display: "flex",
    flexDirection: "column",
    height: 54,
    justifyContent: "center",
    width: 54,
  },
  icon: {
    alignItems: "center",
    backgroundColor: "#f2f2f2",
    borderRadius: 27,
    display: "flex",
    flexDirection: "column",
    height: 54,
    justifyContent: "center",
    width: 54,
  },
}));

const RadioValeur = ({
  nom,
  valeurChoisie,
  valeur = "",
  handleAlreadySelected,
}) => {
  const classes = useStyles();

  const _valeur = valeur.split(" ");
  const unite = _valeur[_valeur.length - 1];
  const debutDeLibelle = _valeur.reduce(
    (libelle, mot, index) =>
      index === _valeur.length - 1 ? libelle : `${libelle} ${mot}`,
    ""
  );

  const StyledIcon = (
    <div className={classes.icon}>
      <Typography variant={"subtitle1"}>{debutDeLibelle}</Typography>
      <Typography variant={"subtitle1"} style={{ fontSize: 10 }}>
        {unite}
      </Typography>
    </div>
  );
  const StyledCheckedIcon = (
    <div className={classes.checkedIcon}>
      <Typography variant={"subtitle1"} style={{ color: "white" }}>
        {debutDeLibelle}
      </Typography>
      <Typography variant={"subtitle1"} style={{ color: "white", fontSize: 8 }}>
        {unite}
      </Typography>
    </div>
  );

  return (
    <Radio
      style={{ padding: "4px" }}
      color={"primary"}
      icon={StyledIcon}
      checkedIcon={StyledCheckedIcon}
      checked={valeurChoisie === valeur}
      onClick={() => handleAlreadySelected({ nom, valeurChoisie, valeur })}
    />
  );
};

export default RadioValeur;
