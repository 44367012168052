import { useServices } from "services";
import { useTxHttp } from "services/http";
import { string } from "api/DTOs/Common/Types";

export interface BienNotes {
  CodeAffaire: string;
  Id: string;
  Notes: string;
  UniqueIdConseiller: string;
}

function bienNotesAdapter(result: any = {}): BienNotes {
  return {
    CodeAffaire: result.codeAffaire || string,
    Id: result.id || string,
    Notes: result.notes || string,
    UniqueIdConseiller: result.uniqueIdConseiller || string,
  };
}

export const useBienNotes = () => {
  const { authenticationService, endpoints } = useServices();
  const { get, put, del } = useTxHttp();

  const getDefaultNotes = () =>
    bienNotesAdapter({ id: "00000000-0000-0000-0000-000000000000" });

  const loadBienNotes = (codeAffaire: string) => {
    const url = endpoints.bienNotes.obtenir;
    return get<any>(url, { codeAffaire })
      .then((response) => bienNotesAdapter({ ...response.data, codeAffaire }))
      .catch((error) =>
        bienNotesAdapter({
          id: "00000000-0000-0000-0000-000000000000",
          codeAffaire,
        })
      );
  };

  const handleDeleteNote = (bienNotes: BienNotes) => {
    return del<void>(endpoints.bienNotes.supprimer, {
      content: JSON.stringify(bienNotes),
      contentType: "application/json",
    }).then(() =>
      bienNotesAdapter({
        id: "00000000-0000-0000-0000-000000000000",
        codeAffaire: bienNotes.CodeAffaire,
      })
    );
  };

  const handleEditNotes = (_bienNotes: BienNotes) => {
    const userData = authenticationService.getUserData();
    _bienNotes.UniqueIdConseiller = userData.UniqueId;
    return put(endpoints.bienNotes.modifier, {
      content: JSON.stringify(_bienNotes),
      contentType: "application/json",
    }).then((response: any) => {
      return bienNotesAdapter(response.data);
    });
  };

  return {
    getDefaultNotes,
    loadBienNotes,
    handleEditNotes,
    handleDeleteNote,
  };
};
