import { Grid } from "@material-ui/core";
import SearchBar from "UI/shared/organisms/searchbar/SearchBar";
import {
  AdaptedRefinements,
  Refinement,
} from "components/RefinementField/types";
import React from "react";
import { ContactsCriteria } from "services/portfolio/types";

import { MoreFilters } from "../MoreFilters/MoreFilters";

export interface PortfolioSearchBarProps {
  adaptedRefinements: AdaptedRefinements<ContactsCriteria>;
  searchResultCount: number;
  onChange: (value: { key: ContactsCriteria; value: Refinement }) => void;
}

export const PortfolioSearchBar: React.FC<PortfolioSearchBarProps> = ({
  adaptedRefinements,
  searchResultCount,
  onChange,
}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={1} justify="space-between">
          <Grid item xs={11}>
            <SearchBar
              entityName={"Portfolio"}
              displayMode={"list"}
              adaptedRefinements={adaptedRefinements}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={1}>
            <Grid container justify="flex-end">
              <MoreFilters
                adaptedRefinements={adaptedRefinements}
                onChange={onChange}
                searchResultCount={searchResultCount}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
