import "./markdown.css";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useCallback, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles({
  root: {
    "&:before": {
      height: "0px",
    },
  },
});

const MarkDownDisplay: React.FC<{ filePath: string }> = ({ filePath }) => {
  const [mdText, setMDText] = useState("");
  const classes = useStyles();

  useEffect(() => {
    fetch(filePath)
      .then((response) => {
        return response.text();
      })
      .then((text) => setMDText(text))
      .catch(() => alert("Unable to fetch Markdown file"));
  }, [filePath]);

  const blockQuoteRender = useCallback(
    (props) => {
      return (
        <Accordion
          classes={{
            root: classes.root,
          }}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{ padding: 0 }}
          >
            {props.children[0]}
          </AccordionSummary>
          <AccordionDetails
            style={{
              flexDirection: "column",
              padding: "8px 0px",
            }}
          >
            {props.children.slice(1)}
          </AccordionDetails>
        </Accordion>
      );
    },
    [classes.root]
  );

  return (
    <div className={"TX"}>
      <div className={"markdown"}>
        <ReactMarkdown
          source={mdText}
          renderers={{
            blockquote: blockQuoteRender,
          }}
        />
      </div>
    </div>
  );
};

export default MarkDownDisplay;
