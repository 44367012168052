import { Grid } from "@material-ui/core";
import React from "react";
import { AnnonceInList } from "types";
import { If } from "UI/shared/atoms/directives/If";
import { Text12px } from "UI/shared/atoms/typographies/Text12px";
import { Text12pxBold } from "UI/shared/atoms/typographies/Text12pxBold";
import { formatPrix } from "utils/formatage";

const livingAreaLabel = (livingArea: number) => `Surface : ${livingArea} m`;
const roomsLabel = (rooms: number) => `Pièces : ${rooms}`;
const bedRoomsLabel = (bedRooms: number) => `Chambres : ${bedRooms}`;

export const Caracteristiques = ({ hit }: { hit: AnnonceInList }) => (
  <Grid container direction="column" spacing={0}>
    <If condition={hit.surface != null && hit.surface > 0}>
      <Text12pxBold>
        {livingAreaLabel(hit.surface!)}
        <sup>2</sup>
      </Text12pxBold>
    </If>

    <If condition={hit.nbPieces != null && hit.nbPieces > 0}>
      <Text12pxBold>{roomsLabel(hit.nbPieces!)}</Text12pxBold>
    </If>

    <If condition={hit.nbChambres != null && hit.nbChambres > 0}>
      <Text12pxBold>{bedRoomsLabel(hit.nbChambres!)}</Text12pxBold>
    </If>

    <If condition={hit.prixM2 != null && hit.prixM2 > 0}>
      <Text12px>
        Prix au m<sup>2</sup>
        {" : "}
        {formatPrix(Math.round(hit.prixM2!))}
      </Text12px>
    </If>
  </Grid>
);
