import React, { useEffect, useState } from "react";
import { NumberField } from "../../../../../../../../components/NumberField";
import { ValidatorResponse } from "../../validators/types";

export interface ValidationNumberFieldProps {
  disabled: boolean;
  pristine?: boolean;
  adornement?: string;
  decimalScale?: number;
  minLimit?: number;
  maxLimit?: number;
  value?: number;
  required?: boolean;
  label: string;
  onChange: (isValid: boolean, value?: number) => void;
}

const validate = (value = 0, minLimit: number, maxLimit: number) => {
  if (value < minLimit)
    return new ValidatorResponse(
      false,
      `La valeur doit être >= à ${minLimit}.`
    );
  if (value > maxLimit)
    return new ValidatorResponse(false, `La valeur doit être <= à ${maxLimit}`);
  return new ValidatorResponse(true);
};

export const ValidationNumberField: React.FC<ValidationNumberFieldProps> = ({
  pristine = false,
  value,
  label,
  onChange,
  decimalScale = 0,
  required = false,
  disabled = false,
  adornement = "",
  minLimit = 0,
  maxLimit = 30000000,
}) => {
  const [validation, setValidation] = useState<ValidatorResponse>(
    new ValidatorResponse(
      validate(value, minLimit, maxLimit).isValid,
      validate(value, minLimit, maxLimit).reason
    )
  );

  useEffect(() => {
    const _validation = validate(value, minLimit, maxLimit);
    onChange(_validation.isValid, value);
  }, []);

  return (
    <NumberField
      disabled={disabled}
      helperText={
        pristine && required ? "*Champ obligatoire" : validation.reason
      }
      error={pristine ? false : !validation.isValid}
      label={label}
      onChange={(newValue) => {
        const _validation = validate(newValue, minLimit, maxLimit);
        setValidation(_validation);
        onChange(_validation.isValid, newValue);
      }}
      required={required}
      value={value}
      adornement={adornement}
      decimalScale={decimalScale}
    />
  );
};
