import React from "react";
import Evenement from "./Evenement";
import { Typography, Divider } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";

const JourneeSimplifiee = ({ jour = [], styles, onClose }) => {
  let dateFormatee = moment(jour[0].dateDebut).format("dddd DD/MM/YYYY");
  dateFormatee = `${dateFormatee.charAt(0).toUpperCase()}${dateFormatee.slice(
    1
  )}`;

  let evenements = jour.map((rdv) => ({
    type: "Rendez-Vous",
    style: styles.rendezVous,
    ...rdv,
  }));

  return (
    <div style={{ width: 320, padding: 10 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Typography variant={"h6"} style={{ marginBottom: 10 }}>
          {dateFormatee}
        </Typography>
        <Close
          onClick={onClose}
          style={{ cursor: "pointer", fontSize: "16px" }}
        />
      </div>
      <Divider style={{ marginBottom: 10 }} />
      {evenements.map((evt) => (
        <Evenement key={evt.id} evenement={evt} mini />
      ))}
    </div>
  );
};

export default JourneeSimplifiee;
