import React, { useState } from "react";
import { useStyles } from "./styles";
import { Fab, Typography } from "@material-ui/core";
import PhotoIcon from "@material-ui/icons/LocalSeeOutlined";
import ModalCarousel from "../ModalCarousel";

export const Photos = (props: { title: string; urls: string[] }) => {
  const [isModalCarouselDisplayed, setModalCarouselDisplay] = useState(false);

  const togglePhotoButtonClick = () => {
    setModalCarouselDisplay(!isModalCarouselDisplayed);
  };
  const classes = useStyles();
  return (
    <>
      <Fab className={classes.buttonsIcon} onClick={togglePhotoButtonClick}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <PhotoIcon className={classes.icons} />
          <Typography
            variant={"body1"}
            style={{
              color: "white",
              fontSize: "10px",
              marginTop: "-5px",
            }}
          >
            {props.urls.length}
          </Typography>
        </div>
      </Fab>

      <ModalCarousel
        label={"hit-pictures"}
        isDisplayed={isModalCarouselDisplayed}
        urls={props.urls}
        onHide={togglePhotoButtonClick}
        description={props.title}
      />
    </>
  );
};
