export type ParkingInfoKind = "Box" | "InteriorParking" | "ExteriorParking";

export type ParkingProposalKind = ParkingInfoKind | "Unknown";

export const getParkingTypeValue = (
  parkingType: ParkingProposalKind,
  t: (_: string) => string
): string => {
  switch (parkingType) {
    case "InteriorParking":
      return t("parkingKind.InteriorParking");
    case "ExteriorParking":
      return t("parkingKind.ExteriorParking");
    case "Box":
      return t("parkingKind.Box");
    default:
      return t("NC");
  }
};
