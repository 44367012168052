import { AvatarText } from "UI/shared/atoms/AvatarText";
import React from "react";

export interface AvatarContactProps {
  firstName?: string;
  lastName?: string;
}

export function getInitials(firstName?: string, lastName?: string): string {
  const first = firstName?.substring(0, 1) || "";
  const second = lastName?.substring(0, 1) || "";

  return first || second ? (first + second).toUpperCase() : "?";
}

export const ContactAvatar: React.FC<AvatarContactProps> = ({
  firstName,
  lastName,
}) => {
  return <AvatarText>{getInitials(firstName, lastName)}</AvatarText>;
};
