import { makeStyles } from "@material-ui/core/styles";
import { ITheme } from "theme/ts/main";

const useStyles = makeStyles((theme: ITheme) => ({
  addressText: {
    color: "#8e8e8e",
    fontWeight: "normal",
    paddingBottom: 5,
    width: "100%",
  },

  button: {
    margin: theme.spacing(1),
  },

  buttonProgress: {
    color: theme.palette.tonique,
    left: "50%",
    marginLeft: -12,
    marginTop: -12,
    position: "absolute",
    top: "50%",
  },

  buttonWrapper: {
    margin: 0,
    position: "relative",
  },
  container: {
    columnGap: "24px",
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateColumns: "1fr 1fr",
  },
  datePicker: {
    width: "100%",
  },
  emailTypeLabel: { overflowX: "hidden", textOverflow: "ellipsis" },
  formButtonWrapper: {
    alignSelf: "center",
    display: "flex",
  },
  formulaire: {
    "@media(max-width: 600px)": {
      padding: 15,
    },
    padding: 32,
    width: "100%",
  },
  gridFirstColumn: {
    gridColumnStart: 1,
  },
  gridSecondColumn: {
    gridColumnStart: 2,
  },
  narrowTextField: {
    marginTop: "8px",
    width: "100%",
  },
  notchedOutlineRequired: {
    borderColor: "#303d4a",
    borderWidth: 2,
  },
  phoneAndEmailValidation: { color: "red" },
  phoneTypeLabel: { overflowX: "hidden", textOverflow: "ellipsis" },
  professionLabel: {
    minHeight: "14px",
    overflowX: "hidden",
    textOverflow: "ellipsis",
  },
  textField: {
    width: "100%",
  },
}));

export default useStyles;
