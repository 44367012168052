import React, { createContext, useContext, useEffect } from "react";
import {
  defaultNotificationsContext,
  NotificationsContext,
} from "services/notifications/context";
import useNotificationSettingsCenter from "services/notificationSettings";
import {
  defaultNotificationsSettingsCenterContext,
  NotificationsSettingsContext,
} from "services/notificationSettings/context";
import useRequestForInformations from "services/request-for-informations";
import {
  defaultRequestForInformationsContext,
  RequestForInformationsContext,
} from "services/request-for-informations/context";
import { useNotifications } from "../notifications";
import {
  defaultPortfolioContext,
  PortfolioContext,
} from "../portfolio/context";

export type Status = "pending" | "resolved" | "rejected";

export interface ContextProps {
  notificationsService: NotificationsContext;
  notificationSettingsService: NotificationsSettingsContext;
  requestForInformationsService: RequestForInformationsContext;
  contactsService: PortfolioContext;
}

export const useAgentNotifications = () => {
  const {
    notificationsService: {
      newAndReadNotificationsPages,
      archivedNotificationsPages,
      markAsRead,
      markAsArchived,
      markAllAsArchived,
      status,
      newAndReadLoadNewPage,
      archivedLoadNewPage,
      getNewNotificationsCount,
      newNotificationsCount,
    },
  } = useContext(UserContext);
  return {
    archivedLoadNewPage,
    archivedNotificationsPages,
    getNewNotificationsCount,
    markAllAsArchived,
    markAsArchived,
    markAsRead,
    newAndReadLoadNewPage,
    newAndReadNotificationsPages,
    newNotificationsCount,
    status,
  };
};

const contextDefaultValue = {
  AdsDefaultSearchLocationsService: {},
  contactsService: defaultPortfolioContext,
  notificationSettingsService: defaultNotificationsSettingsCenterContext,
  notificationsService: defaultNotificationsContext,
  requestForInformationsService: defaultRequestForInformationsContext,
};

export const UserContext = createContext<ContextProps>(contextDefaultValue);

export const UserContextProvider: React.FC = (props) => {
  const {
    requestForInformations,
    loadMore,
    archiveRequestForInformation,
    activateRequestForInformation,
    transformRequestForInformation,
    activeRequestsCount,
    updateParamsWithFilters,
    updateParamsWithActiveSortOption,
    status,
  } = useRequestForInformations();

  const {
    newAndReadNotificationsPages,
    archivedNotificationsPages,
    markAsRead,
    markAsArchived,
    markAllAsArchived,
    status: notificationsCenterStatus,
    newAndReadLoadNewPage,
    archivedLoadNewPage,
    getNewNotificationsCount,
    newNotificationsCount,
  } = useNotifications();

  const {
    reloadNotifications,
    notificationSettingsCenter,
    update,
  } = useNotificationSettingsCenter();

  useEffect(() => {
    if (!reloadNotifications) return;
    getNewNotificationsCount();
  }, [reloadNotifications]);

  return (
    <UserContext.Provider
      value={{
        contactsService: {
          activesContactsCount: 0,
        },
        notificationSettingsService: {
          notificationSettingsCenter,
          update,
        },
        notificationsService: {
          archivedLoadNewPage,
          archivedNotificationsPages,
          getNewNotificationsCount,
          markAllAsArchived,
          markAsArchived,
          markAsRead,
          newAndReadLoadNewPage,
          newAndReadNotificationsPages,
          newNotificationsCount,
          status: notificationsCenterStatus,
        },
        requestForInformationsService: {
          activateRequestForInformation,
          activeRequestsCount,
          archiveRequestForInformation,
          transformRequestForInformation,
          loadMore,
          requestForInformations,
          status,
          updateParamsWithActiveSortOption,
          updateParamsWithFilters,
        },
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
