import { CircularProgress, InputAdornment } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React, { FC, useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { onlySpaces } from "../../../utils/onlySpaces";
import { SingleText } from "../types";

interface SingleTextProps {
  value: SingleText;
  onChange: (refinement: SingleText) => void;
  label: string;
}
export const SingleTextRefinement: FC<SingleTextProps> = (props) => {
  const [val, setVal] = useState<string>();
  const [debouncedValue, setDebouncedValue] = useState<string>();
  const onChange = () => {
    setDebouncedValue(val);
    props.onChange({
      kind: "single-text",
      value: val ? val.toString() : undefined,
    });
  };
  useEffect(() => {
    const s = props.value.value;
    setVal(s);
    setDebouncedValue(s);
  }, [props.value.value]);

  const [, cancel] = useDebounce(onChange, 500, [val]);

  return (
    <>
      <TextField
        fullWidth
        value={val || ""}
        onChange={(evt) => {
          if (onlySpaces(evt.target.value)) {
            return;
          }
          setVal(evt.target.value);
        }}
        variant={"outlined"}
        label={props.label}
        onBlur={() => {
          cancel();
          onChange();
        }}
        type="search"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {val !== debouncedValue ? <CircularProgress size={20} /> : ""}
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};
