import { Button, makeStyles } from "@material-ui/core";
import React from "react";

import { ITheme } from "../../../../theme/ts/main";

interface ButtonWithStartIconProps {
  onClick: () => void;
  label: string;
  icon: React.ReactNode;
  color?: string;
  backColor?: string;
  noUppercaseLabel?: boolean;
  disabled?: boolean;
  bold?: boolean;
  variant?: "text" | "outlined" | "contained";
}

const useStyles = makeStyles((theme: ITheme) => ({
  label: {
    fontSize: 16,
    fontWeight: ({ bold }: Partial<ButtonWithStartIconProps>) =>
      bold ? "bold" : "normal",
    textAlign: "center",
  },
  noUppercaseLabel: {
    textTransform: "none",
  },
  root: {
    "&:hover": {
      boxShadow:
        "0px 3px 3px 0px rgba(0,0,0,0.34), 0px 1px 1px -1px rgba(0,0,0,0.1)",
    },
    background: ({ backColor }: { color?: string; backColor?: string }) =>
      backColor ?? theme.palette.default,
    color: ({ color }: { color?: string; backColor?: string }) =>
      color ?? "white",
    cursor: "pointer",
  },
}));

export const ButtonWithStartIcon: React.FC<ButtonWithStartIconProps> = ({
  onClick,
  label,
  icon,
  color,
  backColor,
  noUppercaseLabel = false,
  variant = "contained",
  bold = true,
  disabled = false,
}) => {
  const classes = useStyles({ backColor, color, bold });
  return (
    <Button
      color="primary"
      variant={variant}
      onClick={onClick}
      startIcon={icon}
      disabled={disabled}
      classes={{ root: classes.root }}
    >
      <span
        className={
          noUppercaseLabel
            ? `${classes.label} ${classes.noUppercaseLabel}`
            : classes.label
        }
      >
        {label}
      </span>
    </Button>
  );
};
