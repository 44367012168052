import { Card, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";

interface CardPaperItemProps {
  clickHandler?: (_: any) => void;
}

export const CardPaperItem: React.FC<CardPaperItemProps> = ({
  clickHandler = () => {},
  children,
}) => {
  const useStyles = makeStyles(() => ({
    cardPaper: {
      "&:hover": {
        boxShadow:
          "0px 3px 3px 0px rgba(0,0,0,0.34), 0px 1px 1px -1px rgba(0,0,0,0.1)",
      },
    },
  }));

  const handleClick = (event: any) => {
    clickHandler(event);
    event.stopPropagation();
  };

  const classes = useStyles();
  return (
    <Card
      classes={{ root: classes.cardPaper }}
      style={{ position: "relative", width: "100%" }}
      onClick={handleClick}
    >
      <Grid
        container
        direction="row"
        spacing={0}
        alignItems="center"
        style={{ cursor: "pointer", padding: "0.5rem" }}
      >
        {children}
      </Grid>
    </Card>
  );
};
