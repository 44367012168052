import LoadingSquare from "components/Loading/LoadingSquare";
import React from "react";
import { ProfileReadDto } from "routes/app/contacts/contact/components/models/profilesReadDto";
import { HouseForRentProfileDetails } from "UI/features/profiles/templates/profileDetails/Proposal/Rent/House/Read";
import { ErrorPanel } from "UI/shared/templates/Panels/ErrorPanel";

import { ApartmentForRentProfileDetails } from "../../templates/profileDetails/Proposal/Rent/Apartment/Read";
import { ParkingForRentProfileDetails } from "../../templates/profileDetails/Proposal/Rent/Parking/Read";
import { ApartmentForSaleProfileDetails } from "../../templates/profileDetails/Proposal/Seller/Apartment/Read";
import { BuildingForSaleProfileDetails } from "../../templates/profileDetails/Proposal/Seller/Building/Read";
import { HouseForSaleProfileDetails } from "../../templates/profileDetails/Proposal/Seller/House/Read";
import { LandForSaleProfileDetails } from "../../templates/profileDetails/Proposal/Seller/Land/Read";
import { ParkingForSaleProfileDetails } from "../../templates/profileDetails/Proposal/Seller/Parking/Read";
import { PremisesForSaleProfileDetails } from "../../templates/profileDetails/Proposal/Seller/Premises/Read";
import { SearchLifeAnnuityApartmentProfileDetails } from "../../templates/profileDetails/Search/LifeAnnuity/Apartment/Read";
import { SearchLifeAnnuityBuildingProfileDetails } from "../../templates/profileDetails/Search/LifeAnnuity/Building/Read";
import { SearchLifeAnnuityHouseProfileDetails } from "../../templates/profileDetails/Search/LifeAnnuity/House/Read";
import { SearchLifeAnnuityLandProfileDetails } from "../../templates/profileDetails/Search/LifeAnnuity/Land/Read";
import { SearchLifeAnnuityPremisesProfileDetails } from "../../templates/profileDetails/Search/LifeAnnuity/Premises/Read";
import { SearchPurchaseApartmentProfileDetails } from "../../templates/profileDetails/Search/Purchase/Apartment/Read";
import { SearchPurchaseBuildingProfileDetails } from "../../templates/profileDetails/Search/Purchase/Building/Read";
import { SearchPurchaseGoodWillProfileDetails } from "../../templates/profileDetails/Search/Purchase/Goodwill/Read";
import { SearchPurchaseHouseProfileDetails } from "../../templates/profileDetails/Search/Purchase/House/Read";
import { SearchPurchaseLandProfileDetails } from "../../templates/profileDetails/Search/Purchase/Land/Read";
import { SearchPurchaseParkingProfileDetails } from "../../templates/profileDetails/Search/Purchase/Parking/Read";
import { SearchPurchasePremisesProfileDetails } from "../../templates/profileDetails/Search/Purchase/Premises/Read";
import { SearchRentalApartmentProfileDetails } from "../../templates/profileDetails/Search/Rental/Apartment/Read";
import { SearchRentalHouseProfileDetails } from "../../templates/profileDetails/Search/Rental/House/Read";
import { SearchRentalLandProfileDetails } from "../../templates/profileDetails/Search/Rental/Land/Read";
import { SearchRentalParkingProfileDetails } from "../../templates/profileDetails/Search/Rental/Parking/Read";
import { SearchRentalPremisesProfileDetails } from "../../templates/profileDetails/Search/Rental/Premises/Read";

export interface ProfileDetailsTabProps {
  readProfile?: ProfileReadDto;
  profileId: string;
  contactId: string;
}

export const ProfileDetailsTab: React.FC<ProfileDetailsTabProps> = ({
  readProfile,
  profileId,
  contactId,
}) => {
  //TODO : Map creation date in backend + profile account
  if (readProfile) {
    switch (readProfile.kind) {
      case "SearchLifeAnnuityApartmentProfile":
        return (
          <SearchLifeAnnuityApartmentProfileDetails readProfile={readProfile} />
        );

      case "SearchLifeAnnuityBuildingProfile":
        return (
          <SearchLifeAnnuityBuildingProfileDetails readProfile={readProfile} />
        );

      case "SearchLifeAnnuityHouseProfile":
        return (
          <SearchLifeAnnuityHouseProfileDetails readProfile={readProfile} />
        );

      case "SearchLifeAnnuityLandProfile":
        return (
          <SearchLifeAnnuityLandProfileDetails readProfile={readProfile} />
        );

      case "SearchLifeAnnuityPremisesProfile":
        return (
          <SearchLifeAnnuityPremisesProfileDetails readProfile={readProfile} />
        );

      case "PurchaseApartmentProfile":
        return (
          <SearchPurchaseApartmentProfileDetails readProfile={readProfile} />
        );

      case "PurchaseBuildingProfile":
        return (
          <SearchPurchaseBuildingProfileDetails readProfile={readProfile} />
        );

      case "PurchaseHouseProfile":
        return <SearchPurchaseHouseProfileDetails readProfile={readProfile} />;

      case "PurchasePremisesProfile":
        return (
          <SearchPurchasePremisesProfileDetails readProfile={readProfile} />
        );

      case "PurchaseLandProfile":
        return <SearchPurchaseLandProfileDetails readProfile={readProfile} />;

      case "PurchaseParkingProfile":
        return (
          <SearchPurchaseParkingProfileDetails readProfile={readProfile} />
        );

      case "PurchaseGoodwillProfile":
        return (
          <SearchPurchaseGoodWillProfileDetails readProfile={readProfile} />
        );

      case "RentApartmentProfile":
        return (
          <SearchRentalApartmentProfileDetails readProfile={readProfile} />
        );
      case "RentHouseProfile":
        return <SearchRentalHouseProfileDetails readProfile={readProfile} />;

      case "RentPremisesProfile":
        return <SearchRentalPremisesProfileDetails readProfile={readProfile} />;

      case "RentLandProfile":
        return <SearchRentalLandProfileDetails readProfile={readProfile} />;

      case "RentParkingProfile":
        return <SearchRentalParkingProfileDetails readProfile={readProfile} />;

      case "SaleApartmentProfile":
        return (
          <ApartmentForSaleProfileDetails
            readProfile={readProfile}
            profileId={profileId}
            contactId={contactId}
          />
        );

      case "SaleHouseProfile":
        return (
          <HouseForSaleProfileDetails
            readProfile={readProfile}
            profileId={profileId}
            contactId={contactId}
          />
        );

      case "SaleParkingProfile":
        return <ParkingForSaleProfileDetails readProfile={readProfile} />;
      case "SaleLandProfile":
        return <LandForSaleProfileDetails readProfile={readProfile} />;
      case "SaleBuildingProfile":
        return <BuildingForSaleProfileDetails readProfile={readProfile} />;
      case "SalePremisesProfile":
        return <PremisesForSaleProfileDetails readProfile={readProfile} />;
      case "ForRentApartmentProfile":
        return (
          <ApartmentForRentProfileDetails
            readProfile={readProfile}
            profileId={profileId}
            contactId={contactId}
          />
        );
      case "ForRentParkingProfile":
        return (
          <ParkingForRentProfileDetails
            readProfile={readProfile}
            profileId={profileId}
            contactId={contactId}
          />
        );
      case "ForRentHouseProfile":
        return (
          <HouseForRentProfileDetails
            readProfile={readProfile}
            profileId={profileId}
            contactId={contactId}
          />
        );

      default:
        return (
          <ErrorPanel
            title={"Service CRM Profils"}
            text={"Impossible de lire ce profil."}
          />
        );
    }
  } else return <LoadingSquare />;
};
