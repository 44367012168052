import { Theme, useMediaQuery } from "@material-ui/core";
import React from "react";
import { RealEstateDto } from "services/profiles/dto/RealEstateDto";
import { NoAgentRealEstates } from "UI/features/profiles/molecules/NoAgentRealEstates";
import { LoaderContainer } from "UI/shared/atoms/containers/LoaderContainer";
import { If } from "UI/shared/atoms/directives/If";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import { Text12px } from "UI/shared/atoms/typographies/Text12px";
import { Text14pxBold } from "UI/shared/atoms/typographies/Text14pxBold";
import { realEstateAdapter } from "../adapter";
import { RealEstatesContext, RealEstatesProps } from "../types";
import { AgentRealEstatesLarge } from "./index.large";
import { AgentRealEstatesSmall } from "./index.small";

export const AgentRealEstatesContext = React.createContext<RealEstatesContext>({
  loadMore: () => {
    return;
  },
  openDetails: () => {
    return;
  },
  realEstates: [],
});

export const AgentRealEstates: React.FC<RealEstatesProps> = ({
  realEstates,
  loading,
  total,
  loadMore,
  open,
}) => {
  const { desktop, mobile } = {
    desktop: useMediaQuery((theme: Theme) => theme.breakpoints.up("md")),
    mobile: useMediaQuery((theme: Theme) => theme.breakpoints.down("sm")),
  };

  const adaptedRealEstates = realEstates.map((realEstate: RealEstateDto) =>
    realEstateAdapter({
      realEstate,
      clickHandler: () => {
        open(realEstate.id);
      },
    })
  );

  return (
    <div style={{ width: "100%" }}>
      <Text14pxBold>MES BIENS</Text14pxBold>
      <VerticalSpace size={8} />
      <LoaderContainer loading={loading}>
        <Text12px>{total} résultat(s)</Text12px>
        <VerticalSpace size={5} />
        <If condition={realEstates.length === 0}>
          <NoAgentRealEstates />
        </If>

        <If condition={realEstates.length > 0}>
          <AgentRealEstatesContext.Provider
            value={{
              loadMore,
              realEstates: adaptedRealEstates,
              openDetails: open,
            }}
          >
            <If condition={mobile}>
              <AgentRealEstatesSmall />
            </If>
            <If condition={desktop}>
              <AgentRealEstatesLarge />
            </If>
          </AgentRealEstatesContext.Provider>
        </If>
      </LoaderContainer>
    </div>
  );
};
