import { makeStyles } from "@material-ui/core/styles";

import { ITheme } from "../../../../../theme/ts/main";

export const useStyles = makeStyles((theme: ITheme) => ({
  actions: {
    borderTop: "1px solid",
    borderTopColor: theme.palette.greyLight,
    display: "flex",
    justifyContent: "flex-end",
  },
  card: {
    width: "100%",
  },
  media: {
    height: 200,
  },
  switchContainer: {
    alignItems: "center",
    display: "flex",
    height: 38,
    justifyContent: "flex-end",
  },
}));
