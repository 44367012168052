import React, { useEffect } from "react";

import { HitsSkeleton } from "../../../layoutContents/components/HitsContainer/HitsSkeleton";
import { NotificationDto } from "../../../services/notifications/Dto/notificationsDto";
import {
  ScrollToTopButton,
  useInfiniteScroll,
} from "../../../services/useInfiniteScrollSR";
import { useAgentNotifications } from "../../../services/user";
import { NotificationItem } from "./NotificationItem";

const TRIGGER_RANK_FROM_LAST = 5;

const indexState = (index: number, triggerIndex: number) => {
  switch (index) {
    case 0:
      return "First";
    case triggerIndex:
      return "Trigger";
    default:
      return "Normal";
  }
};

export const NotificationsList: React.FC<NotificationsListProps> = (props) => {
  const { notifications, loadPage } = props;

  const {
    ViewObserverFirst,
    ViewObserverLast,
    firstVisible,
    lastVisible,
  } = useInfiniteScroll();

  useEffect(() => {
    if (lastVisible) {
      loadPage();
    }
  }, [lastVisible]);

  const { markAsArchived, status } = useAgentNotifications();

  const triggerIndex =
    notifications.length < TRIGGER_RANK_FROM_LAST
      ? -1
      : notifications.length - TRIGGER_RANK_FROM_LAST;

  return (
    <div>
      {notifications.map((notification, index) => (
        <div key={`${notification.id}${indexState(index, triggerIndex)}`}>
          {indexState(index, triggerIndex) === "First" && (
            <ViewObserverFirst>
              <NotificationItem
                notification={notification}
                markAsArchived={markAsArchived}
              />
            </ViewObserverFirst>
          )}
          {indexState(index, triggerIndex) === "Trigger" && (
            <ViewObserverLast>
              <NotificationItem
                notification={notification}
                markAsArchived={markAsArchived}
              />
            </ViewObserverLast>
          )}
          {indexState(index, triggerIndex) === "Normal" && (
            <NotificationItem
              notification={notification}
              markAsArchived={markAsArchived}
            />
          )}
        </div>
      ))}
      {!firstVisible && <ScrollToTopButton />}
      {status === "pending" && HitsSkeleton(1, "list")}
    </div>
  );
};

export interface NotificationsListProps {
  notifications: NotificationDto[];
  loadPage: () => void;
}
