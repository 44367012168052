import { number } from "api/DTOs/Common/Types";

class ResultatParAnneeBO {
  constructor() {
    this.annee = number;
    this.nbMandat = number;
    this.ca = number;
    this.pourcentageExclu = number;
  }
}

export default ResultatParAnneeBO;
