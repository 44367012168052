import { useCallback } from "react";

export const PIGE_STORAGE_KEY = "tx_pigeSlug";

export const usePigeSlug = () => {
  const storeSlug: (slug: string) => void = useCallback((slug: string) => {
    sessionStorage.setItem(PIGE_STORAGE_KEY, slug);
  }, []);

  const getStoredSlug: () => string | null = useCallback(() => {
    return sessionStorage.getItem(PIGE_STORAGE_KEY);
  }, []);

  const clearSlug: () => void = () => {
    sessionStorage.removeItem(PIGE_STORAGE_KEY);
  };

  return {
    getStoredSlug,
    storeSlug,
    clearSlug,
  };
};
