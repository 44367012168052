import { Grid, Typography } from "@material-ui/core";
import React from "react";

import { useServices } from "../../../../../services";

const Prestations = (props) => {
  const { prestations } = props;
  const {
    l10n: { t },
  } = useServices();
  return (
    <Grid container spacing={0}>
      {prestations.map((prestation) => (
        <Grid item xs={12} key={prestation[0]}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Typography variant="subtitle2" align="right">
                {t(prestation[0])}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              &nbsp;
            </Grid>
            <Grid item xs={5}>
              <Typography variant="subtitle1">
                {`${prestation[1] === "true" ? "Oui" : prestation[1]}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default Prestations;
