import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

import { useServices } from "../../../services";

const useStyles = makeStyles((theme) => ({
  myDividerText: {
    borderColor: theme.palette.neutral.light,
    borderRadius: "10px",
    borderStyle: "solid",
    borderWidth: "2px",
  },
  small: {
    "@media (max-width: 768px)": {
      fontSize: "12px",
    },
  },
}));

const MyDivider = (props) => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();
  return (
    <div className={classes.myDividerText}>
      <Typography variant="h6" className={classes.small}>
        {t(props.title)}
      </Typography>
    </div>
  );
};

export default MyDivider;
