import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { usePrevious } from "react-use";

import { AdaptedRefinements } from "../../components/RefinementField/types";
import { INITIAL_SLUG, SLUG_STORAGE_KEY } from "./constants";
import { parseAsJson } from "./parseAsJson";
import { PageWithSlug, Slugs } from "./types";

const encodeString = (str: string) => btoa(encodeURIComponent(str));
export const decodeString = (str: string) => decodeURIComponent(atob(str));

const getInitialSlugs: () => Slugs = () => {
  const storedSlugs = sessionStorage.getItem(SLUG_STORAGE_KEY);

  if (storedSlugs) return JSON.parse(storedSlugs) as Slugs;

  return {
    DR: INITIAL_SLUG,
    Pige: INITIAL_SLUG,
    Portfolio: INITIAL_SLUG,
    Profiles: INITIAL_SLUG,
    RealEstates: INITIAL_SLUG,
  };
};

export const useSlugs = <T extends string>(pageName: PageWithSlug) => {
  const [slugs, setSlugs] = useState<Slugs>(getInitialSlugs);
  const previousPageSlug = usePrevious(slugs[pageName]);
  const history = useHistory();

  const decodeSlug = useCallback((slug: string) => {
    if (slug === INITIAL_SLUG) return null;
    return parseAsJson<AdaptedRefinements<T>>(decodeString)(slug);
  }, []);

  const storeSlugs = useCallback(
    (adaptedRefinements: AdaptedRefinements<T>) => {
      setSlugs((prev) => ({
        ...prev,
        [pageName]: encodeString(JSON.stringify(adaptedRefinements)),
      }));
    },
    [pageName]
  );

  const getStoredAdaptedRefinements: () => AdaptedRefinements<
    T
  > | null = useCallback(() => {
    const storedSlugs = sessionStorage.getItem(SLUG_STORAGE_KEY);
    if (!storedSlugs) return null;

    const parsedSlugs = parseAsJson<Slugs>()(storedSlugs);
    return decodeSlug(parsedSlugs[pageName]);
  }, []);

  useEffect(() => {
    if (slugs[pageName] !== previousPageSlug) {
      sessionStorage.setItem(SLUG_STORAGE_KEY, JSON.stringify(slugs));
      history.replace({
        search: `?slug=${slugs[pageName]}`,
      });
    }
  }, [slugs]);

  return {
    decodeSlug,
    getStoredAdaptedRefinements,
    slugs,
    storeSlugs,
  };
};
