import React from "react";
import { AnnonceInList } from "types";
import { CarteContactPige } from "../../FicheBienPige/CarteContactPige";

export const Contact = ({ hit }: { hit: AnnonceInList }) => {
  return (
    <CarteContactPige
      sourceName={hit && hit.source ? hit.source.nom : undefined}
      sourceUrl={hit && hit.source ? hit.source.url : undefined}
      name={hit.pseudoContact}
      phone={hit.telephoneContact}
      email={hit.emailContact}
      bloctel={hit.bloctel}
    />
  );
};
