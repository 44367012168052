import { Grid, makeStyles, Tooltip, Typography } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/PhoneOutlined";
import React from "react";
import { ITheme } from "theme/ts/main";

import { useServices } from "../../../../../../../../../services";

const useStyles = makeStyles((theme: ITheme) => ({
  clickable: {
    color: theme.palette.tonique,
    fontSize: 16,
    marginRight: 10,
  },
}));

interface PrintablePhoneNumbersListProps {
  phoneNumbersList: string[];
}

export const PrintablePhoneNumbersList = ({
  phoneNumbersList,
}: PrintablePhoneNumbersListProps) => (
  <Grid container spacing={1} style={{ marginLeft: 20, marginTop: 14 }}>
    {phoneNumbersList.map((phoneNumber, index) => (
      <PhoneNumber
        key={phoneNumber}
        phoneNumber={phoneNumber}
        iconDisplayed={index === 0}
      />
    ))}
  </Grid>
);

interface PhoneNumberProps {
  phoneNumber: string;
  iconDisplayed: boolean;
}

const PhoneNumber = ({ phoneNumber, iconDisplayed }: PhoneNumberProps) => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexWrap: "nowrap",
      }}
    >
      {iconDisplayed && <PhoneIcon classes={{ root: classes.clickable }} />}
      <Tooltip title={t("Appeler")} placement="right">
        <Typography variant="body1" noWrap>
          <span style={{ fontWeight: iconDisplayed ? "bold" : "normal" }}>
            {phoneNumber}
          </span>
        </Typography>
      </Tooltip>
    </div>
  );
};
