import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  IconButton,
  Dialog,
  DialogContent,
  useMediaQuery,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  dialog: {},
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const StyledDialog = ({ content = <div>No content.</div> }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const switchStatus = () => setOpen(!open);

  return {
    dialogComponent: (
      <Dialog
        open={open}
        onClose={switchStatus}
        fullScreen={fullScreen}
        fullWidth={true}
        className={classes.dialog}
      >
        <DialogContent>{content}</DialogContent>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={switchStatus}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
    ),
    switchStatus,
  };
};

export default StyledDialog;
