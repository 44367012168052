import ClassementBO from "services/reporting/dto/Reporting/Synthese/sub/ClassementBO";

class ClassementAdapter {
  static toBO(array = []) {
    return array.map((item) => this.itemToBO(item));
  }

  static itemToBO(item = {}) {
    const bo = new ClassementBO();
    bo.cible = item.cible || bo.cible;
    bo.nombreConseillers = item.nombreConseillers || bo.nombreConseillers;
    bo.classementCA = item.classementCA || bo.classementCA;
    bo.classementMandats = item.classementMandats || bo.classementMandats;
    bo.classementPourcentageExclu =
      item.classementPourcentageExclu || bo.classementPourcentageExclu;

    return bo;
  }
}

export default ClassementAdapter;
