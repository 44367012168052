import React from "react";
import PropType from "prop-types";

export default function DateRangeSubtitle({ date }) {
  const limiteInf = new Date(date.limiteInf);
  limiteInf.setFullYear(limiteInf.getFullYear() - 1);
  const limiteSup = new Date(date.limiteSup);
  limiteSup.setFullYear(limiteSup.getFullYear() - 1);
  return (
    <>
      {`(En comparaison à la période du ${limiteInf.toLocaleDateString()} au ${limiteSup.toLocaleDateString()})`}
    </>
  );
}

DateRangeSubtitle.propTypes = {
  date: PropType.object.isRequired,
};
