import ReactSelect from "components/Searchable";
import React from "react";
import { useServices } from "services";
import { useFrGouvGeocodingService } from "services/dataSources/geolocServices/useFrGouvGeocodingService";

export interface AutocompletePlace {
  adresse: string;
  codePostal: string;
  label: string;
  value: string;
  ville: {
    codeInsee: string;
    nom: string;
  };
}

const AutoCompleteAddress: React.FC<{
  selectedAddress: AutocompletePlace;
  setSelectedAddress: (address: { address: string }) => void;
  disabled: boolean;
  isClearable?: boolean;
}> = ({ selectedAddress, setSelectedAddress, disabled, isClearable }) => {
  const {
    l10n: { t },
  } = useServices();

  const [value, setValue] = React.useState(selectedAddress.adresse);

  function handleSearchChange(inputValue = "") {
    setValue(inputValue);
  }

  const handleChangeReactSelect = (data: { adresse: string }) => {
    setSelectedAddress({
      address: data?.adresse || "",
    });
  };

  const { status, foundAddresses } = useFrGouvGeocodingService({
    addressString: value,
    cityCode: selectedAddress.ville.codeInsee,
  });

  const noOptionMessage =
    status === "pending" ? "recherche à partir de 3 caractères." : "";

  const displayedOptions = foundAddresses.map((item) => ({
    adresse: `${item.properties?.name}`,
    codePostal: item.properties?.postcode,
    label: `${item.properties?.label}`,
    value: `${item.properties?.label}`,
    ville: item.properties?.city,
  }));

  return (
    <ReactSelect
      disabled={disabled}
      placeholder={""}
      label={t("Adresse")}
      messageNoOptions={noOptionMessage}
      onChangeInput={handleSearchChange}
      onChange={handleChangeReactSelect}
      isMulti={false}
      values={displayedOptions}
      isClearable={isClearable}
    />
  );
};

export default AutoCompleteAddress;
