import { Endpoints } from "services/endpoints/interfaces/Endpoints";

import { IHttpService } from "../http";
import { ITrackingService } from "../tracking/tracking";
import { ContactEventReceived, ContactEventSent } from "./dto/ContactEvent";

export const contactEventsTrackingService = (
  endpoints: Endpoints,
  sendCustomEventToGoogle: ITrackingService["sendCustomEventToGoogle"],
  httpService: IHttpService
): IContactEventsTrackingService => {
  const getEvents = async (contactId: string) => {
    const eventsResponse = await httpService.get<{
      contactId: string;
      events: ContactEventReceived[];
    }>(
      endpoints.portfolio.contacts.contact.events.addEventOrGetEvents(contactId)
    );
    return eventsResponse?.data.events;
  };

  const addEvent = async (
    contactId: string,
    event: Omit<ContactEventSent, "id" | "calendarEventId">
  ) => {
    await httpService.post<void>(
      endpoints.portfolio.contacts.contact.events.addEventOrGetEvents(
        contactId
      ),
      {
        content: event,
        contentType: "application/json",
      }
    );
    sendCustomEventToGoogle("Création action suivi contact");
  };

  const updateEvent = async (contactId: string, event: ContactEventSent) => {
    await httpService.put<void>(
      endpoints.portfolio.contacts.contact.events.updateOrDeleteEvent(
        contactId,
        event.id
      ),
      {
        content: event,
        contentType: "application/json",
      }
    );
    sendCustomEventToGoogle("Modification action suivi contact");
  };

  const deleteEvent = async (contactId: string, eventId: string) => {
    await httpService.del<void>(
      endpoints.portfolio.contacts.contact.events.updateOrDeleteEvent(
        contactId,
        eventId
      ),
      {
        content: {},
        contentType: "application/json",
      }
    );
    sendCustomEventToGoogle("Suppression action suivi contact");
  };

  return { addEvent, deleteEvent, getEvents, updateEvent };
};

export interface IContactEventsTrackingService {
  getEvents: (contactId: string) => Promise<ContactEventReceived[]>;
  addEvent: (
    contactId: string,
    event: Omit<ContactEventSent, "id" | "calendarEventId">
  ) => Promise<void>;
  updateEvent: (contactId: string, event: ContactEventSent) => Promise<void>;
  deleteEvent: (contactId: string, eventId: string) => Promise<void>;
}
