import {
  Button,
  Card,
  CardContent,
  Grid,
  Hidden,
  Menu,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { useTheme } from "@material-ui/core/styles";
import LogoutIcon from "@material-ui/icons/PowerSettingsNew";
import { BU } from "components/BU";
import React from "react";
import { withRouter } from "react-router-dom";
import formatToInitialsCapitalized from "utils/formatToInitialsCapitalized";

import routesNames from "router/routesNames";
import { useServices } from "services";
import { ContactSupportButton } from "UI/shared/molecules/Buttons/ContactSupportButton";
import { useStyles } from "./styles";

const StyledMenu = (props) => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      horizontal: "center",
      vertical: "bottom",
    }}
    MenuListProps={{
      disablePadding: true,
    }}
    {...props}
    autoFocus={false}
  />
);

const Profile = (props) => {
  const {
    l10n: { t },
  } = useServices();

  const { authenticationService } = useServices();
  const classes = useStyles();
  const [userPicture, setUserPicture] = React.useState(
    authenticationService.getUserData().picture
  );
  const [anchorProfile, setAnchorProfile] = React.useState(null);

  const nomEntier = `${authenticationService.getUserData().given_name} ${
    authenticationService.getUserData().family_name
  }`;
  const {
    given_name: firstName,
    family_name: lastName,
  } = authenticationService.getUserData();

  function handleClick(event) {
    setAnchorProfile(event.currentTarget);
  }

  const handleOwnProfile = () => {
    sessionStorage.setItem(
      "page courante",
      `${routesNames.app}${routesNames.conseiller}${routesNames.monProfil}/identite`
    );
    props.history.push({
      pathname: `${routesNames.app}${routesNames.conseiller}${routesNames.monProfil}/identite`,
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.iconsContainer}>
        <Hidden xsDown>
          <div style={{ marginRight: 8 }}>
            <Typography
              variant="body1"
              className={classes.bonjour}
              noWrap
              align="right"
            >
              {t("Bonjour")}
            </Typography>

            <Typography
              type="title"
              className={classes.nom}
              align="right"
              noWrap
            >
              {nomEntier}
            </Typography>

            <Typography
              type="title"
              className={classes.texteSimple}
              align="right"
            >
              {authenticationService.getUserData().NiveauElite}
            </Typography>
          </div>
        </Hidden>

        <Tooltip title={t("Votre profil")} placement="left">
          <Avatar
            className={classes.photo}
            src={userPicture}
            onClick={handleClick}
            alt={authenticationService.getUserData().name}
            imgProps={{
              onError: () => {
                setUserPicture(null);
              },
            }}
          >
            {formatToInitialsCapitalized({
              wordsList: [firstName, lastName],
            })}
          </Avatar>
        </Tooltip>
      </div>
      <ProfileDetails
        anchorEl={anchorProfile}
        setAnchorEl={setAnchorProfile}
        handleOwnProfile={handleOwnProfile}
      />
    </div>
  );
};

export default withRouter(Profile);

const ProfileDetails = (props) => {
  const { anchorEl, setAnchorEl, handleOwnProfile } = props;
  const classes = useStyles();
  const theme = useTheme();
  const {
    authenticationService,
    l10n: { t },
  } = useServices();

  const nomEntier = `${authenticationService.getUserData().given_name} ${
    authenticationService.getUserData().family_name
  }`;
  const [userPicture, setUserPicture] = React.useState(
    authenticationService.getUserData().picture
  );

  function handleLogout() {
    authenticationService.logout();
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const _handleOwnProfile = () => {
    handleOwnProfile();
    handleClose();
  };

  const {
    given_name: firstName,
    family_name: lastName,
  } = authenticationService.getUserData();

  return (
    <StyledMenu
      id="customized-menu-profile"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      style={{ marginTop: 20 }}
    >
      <Card
        className={classes.card}
        style={{
          minWidth: useMediaQuery((theme) => theme.breakpoints.up("sm"))
            ? 450
            : 300,
        }}
      >
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Grid container spacing={1}>
              <Grid item xs={2} sm={3}>
                <Avatar
                  className={classes.avatar}
                  src={userPicture}
                  alt={authenticationService.getUserData().name}
                  imgProps={{
                    onError: () => {
                      setUserPicture(null);
                    },
                  }}
                >
                  {formatToInitialsCapitalized({
                    wordsList: [firstName, lastName],
                  })}
                </Avatar>
              </Grid>
              <Grid item xs={10} sm={9}>
                <span>
                  <BU bu={authenticationService.getUserData().Instance} />
                </span>
                <Typography variant="h2" noWrap>
                  {nomEntier}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  {authenticationService.getUserData().name}
                </Typography>
                {authenticationService.getUserData().NiveauElite !== "" ||
                authenticationService.getUserData().Specialite !== "" ? (
                  <div
                    style={{
                      borderBottom: "1px solid",
                      borderTop: "1px solid",
                      paddingBottom: 10,
                      paddingTop: 10,
                    }}
                  >
                    {authenticationService.getUserData().NiveauElite !== "" && (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                        }}
                      >
                        <Typography variant="body2">
                          {`${t("Distinction")}`}
                          &nbsp;:&nbsp;
                        </Typography>
                        <Typography variant="h6" style={{ paddingLeft: 5 }}>
                          {authenticationService.getUserData().NiveauElite}
                        </Typography>
                      </div>
                    )}
                    {authenticationService.getUserData().Specialite !== "" && (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                        }}
                      >
                        <Typography variant="body2">
                          {`${t("Spécialité")}`}
                          &nbsp;:&nbsp;
                        </Typography>
                        <Typography variant="h6" style={{ paddingLeft: 5 }}>
                          {authenticationService.getUserData().Specialite}
                        </Typography>
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                <div
                  style={{ cursor: "pointer", display: "flex", marginTop: 10 }}
                  onClick={_handleOwnProfile}
                >
                  <Typography
                    variant={"h3"}
                    style={{ color: theme.palette.tonique }}
                  >
                    &gt;&nbsp;
                  </Typography>
                  <Typography variant={"h4"}>Consulter mon profil</Typography>
                </div>
              </Grid>
            </Grid>

            <div className={classes.controls}>
              <div style={{ marginRight: 10 }} onClick={handleClose}>
                <ContactSupportButton />
              </div>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleLogout}
              >
                <LogoutIcon
                  style={{
                    height: "18px",
                    marginRight: "3px",
                    width: "18px",
                  }}
                />
                {t("Logout")}
              </Button>
            </div>
          </CardContent>
        </div>
      </Card>
    </StyledMenu>
  );
};
