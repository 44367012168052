import { AccountType } from "routes/app/contacts/contact/components/models/accountType";
import { PlaceDto } from "routes/app/contacts/contact/components/models/placeDto";
import {
  PurchaseApartmentProfileReadDto,
  PurchaseBuildingProfileReadDto,
  PurchaseGoodWillProfileReadDto,
  PurchaseHouseProfileReadDto,
  PurchaseLandProfileReadDto,
  PurchaseParkingProfileReadDto,
  PurchasePremisesProfileReadDto,
  RentApartmentProfileReadDto,
  RentHouseProfileReadDto,
  RentLandProfileReadDto,
  RentParkingProfileReadDto,
  RentPremisesProfileReadDto,
  SearchLifeAnnuityApartmentProfileReadDto,
  SearchLifeAnnuityBuildingProfileReadDto,
  SearchLifeAnnuityHouseProfileReadDto,
  SearchLifeAnnuityLandProfileReadDto,
  SearchLifeAnnuityPremisesProfileReadDto,
  SearchReadProfileDto,
} from "routes/app/contacts/contact/components/models/Search/searchProfilesReadDtoModels";
import { BusinessType } from "routes/app/contacts/contact/components/Profiles/AccountChip";
import { MatchingCriteria } from "routes/app/contacts/contact/components/Profiles/Search/MatchingCriteria";

import { ProfileItemDto } from "../../../../services/profiles/dto/ReadModelProfileDto";
import { FamilyKind, getValueOrUndefined, PropertyType } from "./utils";

const propertyTypeToFamilyKind: Record<
  NonNullable<FamilyKind>,
  PropertyType
> = {
  Appartement: "Appartement",
  Building: "Immeuble",
  Goodwill: "FondsCommerce",
  House: "Maison",
  Land: "Terrain",
  Parking: "Stationnement",
  Premises: "LocalBureau",
};

const toBusinessType: (type: string) => BusinessType | "" = (type) => {
  switch (true) {
    case !!type.match(/sale/i):
      return "Sale";
    case !!type.match(/searchlifeannuity/i):
      return "SearchLifeAnnuity";
    case !!type.match(/lifeannuity/i):
      return "LifeAnnuity";
    case !!type.match(/rental/i):
      return "Rental";
    case !!type.match(/rent/i):
      return "Rent";
    case !!type.match(/purchase/i):
      return "Purchase";
    default:
      return "";
  }
};

function shouldUseSurfaceTerrain(profile: ProfileItemDto) {
  return (
    propertyTypeToFamilyKind[profile.propertyKind] == "Stationnement" ||
    propertyTypeToFamilyKind[profile.propertyKind] == "Terrain"
  );
}

export const getMatchingCriteria: (
  _: ProfileItemDto
) => MatchingCriteria | undefined = (profile: ProfileItemDto) => {
  if (!profile) return undefined;

  return {
    account: profile.account as AccountType,
    business: toBusinessType(profile.kind),
    familleBiens: propertyTypeToFamilyKind[profile.propertyKind],
    minRooms: getValueOrUndefined<number>(profile.rooms),
    places: profile.places.map((p) => {
      const mapped: PlaceDto = {
        id: p.id,
        name: p.name,
        postalCodes: [],
        wkt: undefined,
      };

      return mapped;
    }),
    prixMax: getValueOrUndefined<number>(profile.budgetMax),
    prixMin: getValueOrUndefined<number>(profile.budgetMin),
    purchaseConstructionYears: profile.constructionYear,
    surfaceHabitableMax: !shouldUseSurfaceTerrain(profile)
      ? getValueOrUndefined<number>(profile.surfaceMax)
      : undefined,
    surfaceHabitableMin: !shouldUseSurfaceTerrain(profile)
      ? getValueOrUndefined<number>(profile.surfaceMin)
      : undefined,
    surfaceTerrainMax: shouldUseSurfaceTerrain(profile)
      ? getValueOrUndefined<number>(profile.surfaceMax)
      : undefined,
    surfaceTerrainMin: shouldUseSurfaceTerrain(profile)
      ? getValueOrUndefined<number>(profile.surfaceMin)
      : undefined,
    withElevator: Boolean(profile.withElevator),
    withParking: Boolean(profile.withParking),
    withPool: Boolean(profile.withPool),
  };
};

export const getCriteria = (
  profile: SearchReadProfileDto
): MatchingCriteria | undefined => {
  if (!profile) return undefined;
  switch (profile.kind) {
    case "PurchaseHouseProfile": {
      const _profile = profile as PurchaseHouseProfileReadDto;
      return {
        account: _profile.account,
        business: "Purchase",
        familleBiens: "Maison",
        minRooms: _profile.criteria.minRooms,
        places: _profile.criteria.places,
        prixMax: _profile.criteria.budget.max,
        prixMin: _profile.criteria.budget.min,
        purchaseConstructionYears: _profile.criteria.constructionYears,
        withElevator: _profile.criteria.withElevator,
        withParking: _profile.criteria.withParking,
        withPool: _profile.criteria.withPool,
      };
    }
    case "SearchLifeAnnuityApartmentProfile": {
      const _profile = profile as SearchLifeAnnuityApartmentProfileReadDto;
      return {
        account: profile.account,
        business: "LifeAnnuity",
        familleBiens: "Appartement",
        minRooms: _profile.criteria.minRooms,
        places: _profile.criteria.places,
        prixMax: _profile.criteria.budget.bouquet.max,
        prixMin: _profile.criteria.budget.bouquet.min,
        withElevator: _profile.criteria.withElevator,
        withParking: _profile.criteria.withParking,
      };
    }
    case "SearchLifeAnnuityBuildingProfile": {
      const _profile = profile as SearchLifeAnnuityBuildingProfileReadDto;
      return {
        account: profile.account,
        business: "LifeAnnuity",
        familleBiens: "Immeuble",
        places: profile.criteria.places,
        prixMax: _profile.criteria.budget.bouquet.max,
        prixMin: _profile.criteria.budget.bouquet.min,
      };
    }
    case "SearchLifeAnnuityHouseProfile": {
      const _profile = profile as SearchLifeAnnuityHouseProfileReadDto;
      return {
        account: profile.account,
        business: "LifeAnnuity",
        familleBiens: "Maison",
        minRooms: _profile.criteria.minRooms,
        places: profile.criteria.places,
        prixMax: _profile.criteria.budget.bouquet.max,
        prixMin: _profile.criteria.budget.bouquet.min,
        withElevator: _profile.criteria.withElevator,
        withParking: _profile.criteria.withParking,
        withPool: _profile.criteria.withPool,
      };
    }
    case "SearchLifeAnnuityLandProfile": {
      const _profile = profile as SearchLifeAnnuityLandProfileReadDto;
      return {
        account: profile.account,
        business: "LifeAnnuity",
        familleBiens: "Terrain",
        places: profile.criteria.places,
        prixMax: _profile.criteria.budget.bouquet.max,
        prixMin: _profile.criteria.budget.bouquet.min,
      };
    }
    case "SearchLifeAnnuityPremisesProfile": {
      const _profile = profile as SearchLifeAnnuityPremisesProfileReadDto;
      return {
        account: profile.account,
        business: "LifeAnnuity",
        familleBiens: "LocalBureau",
        places: profile.criteria.places,
        prixMax: _profile.criteria.budget.bouquet.max,
        prixMin: _profile.criteria.budget.bouquet.min,
      };
    }
    case "PurchaseApartmentProfile": {
      const _profile = profile as PurchaseApartmentProfileReadDto;
      return {
        account: profile.account,
        business: "Purchase",
        familleBiens: "Appartement",
        minRooms: _profile.criteria.minRooms,
        places: profile.criteria.places,
        prixMax: _profile.criteria.budget.max,
        prixMin: _profile.criteria.budget.min,
        purchaseConstructionYears: _profile.criteria.constructionYears,
        withElevator: _profile.criteria.withElevator,
        withParking: _profile.criteria.withParking,
      };
    }
    case "PurchaseBuildingProfile": {
      const _profile = profile as PurchaseBuildingProfileReadDto;
      return {
        account: profile.account,
        business: "Purchase",
        familleBiens: "Immeuble",
        places: profile.criteria.places,
        prixMax: _profile.criteria.budget.max,
        prixMin: _profile.criteria.budget.min,
        purchaseConstructionYears: _profile.criteria.constructionYears,
      };
    }
    case "PurchaseGoodwillProfile": {
      const _profile = profile as PurchaseGoodWillProfileReadDto;
      return {
        account: profile.account,
        business: "Purchase",
        familleBiens: "FondsCommerce",
        places: profile.criteria.places,
        prixMax: _profile.criteria.budget.max,
        prixMin: _profile.criteria.budget.min,
      };
    }
    case "PurchaseLandProfile": {
      const _profile = profile as PurchaseLandProfileReadDto;
      return {
        account: profile.account,
        business: "Purchase",
        familleBiens: "Terrain",
        places: profile.criteria.places,
        prixMax: _profile.criteria.budget.max,
        prixMin: _profile.criteria.budget.min,
      };
    }
    case "PurchaseParkingProfile": {
      const _profile = profile as PurchaseParkingProfileReadDto;
      return {
        account: profile.account,
        business: "Purchase",
        familleBiens: "Stationnement",
        places: profile.criteria.places,
        prixMax: _profile.criteria.budget.max,
        prixMin: _profile.criteria.budget.min,
      };
    }
    case "PurchasePremisesProfile": {
      const _profile = profile as PurchasePremisesProfileReadDto;
      return {
        account: profile.account,
        business: "Purchase",
        familleBiens: "LocalBureau",
        places: profile.criteria.places,
        prixMax: _profile.criteria.budget.max,
        prixMin: _profile.criteria.budget.min,
        purchaseConstructionYears: _profile.criteria.constructionYears,
      };
    }
    case "RentApartmentProfile": {
      const _profile = profile as RentApartmentProfileReadDto;
      return {
        account: profile.account,
        business: "Rental",
        familleBiens: "Appartement",
        minRooms: _profile.criteria.minRooms,
        places: profile.criteria.places,
        prixMax: _profile.criteria.budget.max,
        prixMin: _profile.criteria.budget.min,
        withElevator: _profile.criteria.withElevator,
        withParking: _profile.criteria.withParking,
      };
    }
    case "RentHouseProfile": {
      const _profile = profile as RentHouseProfileReadDto;
      return {
        account: profile.account,
        business: "Rental",
        familleBiens: "Maison",
        minRooms: _profile.criteria.minRooms,
        places: profile.criteria.places,
        prixMax: _profile.criteria.budget.max,
        prixMin: _profile.criteria.budget.min,
        withElevator: _profile.criteria.withElevator,
        withParking: _profile.criteria.withParking,
        withPool: _profile.criteria.withPool,
      };
    }
    case "RentLandProfile": {
      const _profile = profile as RentLandProfileReadDto;
      return {
        account: profile.account,
        business: "Rental",
        familleBiens: "Terrain",
        places: profile.criteria.places,
        prixMax: _profile.criteria.budget.max,
        prixMin: _profile.criteria.budget.min,
      };
    }
    case "RentParkingProfile": {
      const _profile = profile as RentParkingProfileReadDto;
      return {
        account: profile.account,
        business: "Rental",
        familleBiens: "Stationnement",
        places: profile.criteria.places,
        prixMax: _profile.criteria.budget.max,
        prixMin: _profile.criteria.budget.min,
      };
    }
    case "RentPremisesProfile": {
      const _profile = profile as RentPremisesProfileReadDto;
      return {
        account: profile.account,
        business: "Rental",
        familleBiens: "LocalBureau",
        places: profile.criteria.places,
        prixMax: _profile.criteria.budget.max,
        prixMin: _profile.criteria.budget.min,
      };
    }
  }
};
