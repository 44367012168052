import { Grid, Typography } from "@material-ui/core";
import React, { FC, useState } from "react";
import { formatPourcent, formatPrix } from "utils/formatage";

import { PremisesFinancialInfoDto } from "../../../../models/Proposal/ForSale/Premises/premisesFinancialInfoDto";
import { SectionTitle } from "../../../components/SectionTitle";
import { ValidationNumberField } from "../../../components/ValidationNumberField";
import { ProfileInputProps } from "../../../InputProps";

export const SaleBudgetPremises: FC<{
  financialInfo: PremisesFinancialInfoDto;
}> = ({ financialInfo }) => (
  <>
    {financialInfo.price! > 0 && (
      <Typography variant="h5">
        Prix (honoraires inclus) : {formatPrix(financialInfo.price)}
      </Typography>
    )}
    {financialInfo.agentSellerFees! > 0 && (
      <Typography variant="h5">
        Honoraires vendeur : {formatPourcent(financialInfo.agentSellerFees)}
      </Typography>
    )}
    {financialInfo.agentPurchaserFees! > 0 && (
      <Typography variant="body1">
        Honoraires acquéreur : {formatPrix(financialInfo.agentPurchaserFees)}
      </Typography>
    )}
  </>
);

interface SaleBudgetPremisesValidation {
  price: boolean;
  agentSellerFees: boolean;
  agentPurchaserFees: boolean;
}

const getGlobalValidation = (globalValidation: SaleBudgetPremisesValidation) =>
  globalValidation.price &&
  globalValidation.agentSellerFees &&
  globalValidation.agentPurchaserFees;

export const SaleBudgetPremisesInput: FC<ProfileInputProps<
  PremisesFinancialInfoDto
>> = ({ pristine, value, onChange, more, disabled = false }) => {
  const [globalValidation, setGlobalValidation] = useState({
    agentPurchaserFees: (value.agentPurchaserFees ?? 0) > 0,
    agentSellerFees: (value.agentSellerFees ?? 0) > 0,
    price: (value.price ?? 0) > 0,
  });

  return (
    <>
      <SectionTitle title="Quel est le prix de vente ?" />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ValidationNumberField
            disabled={disabled}
            pristine={pristine}
            label="Prix (honoraires inclus)"
            onChange={(isValid: boolean, price: number | undefined) => {
              const _globalValidation = {
                ...globalValidation,
                price: isValid,
              };
              setGlobalValidation((prev) => ({ ...prev, price: isValid }));
              onChange(
                {
                  ...value,
                  price,
                },
                getGlobalValidation(_globalValidation)
              );
            }}
            required={true}
            value={value.price}
            adornement={"€"}
            minLimit={1}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <ValidationNumberField
            disabled={disabled}
            pristine={pristine}
            label="Honoraires vendeur"
            onChange={(
              isValid: boolean,
              agentSellerFees: number | undefined
            ) => {
              const _globalValidation = {
                ...globalValidation,
                agentSellerFees: isValid,
              };
              setGlobalValidation((prev) => ({
                ...prev,
                agentSellerFees: isValid,
              }));
              onChange(
                { ...value, agentSellerFees },
                getGlobalValidation(_globalValidation)
              );
            }}
            required={true}
            value={value.agentSellerFees}
            adornement={"%"}
            decimalScale={2}
            minLimit={0.01}
            maxLimit={100}
          />
        </Grid>
        <Grid item xs={6}>
          <ValidationNumberField
            disabled={disabled}
            pristine={pristine}
            label="Honoraires acquéreur"
            onChange={(
              isValid: boolean,
              agentPurchaserFees: number | undefined
            ) => {
              const _globalValidation = {
                ...globalValidation,
                agentPurchaserFees: isValid,
              };
              setGlobalValidation((prev) => ({
                ...prev,
                agentPurchaserFees: isValid,
              }));
              onChange(
                { ...value, agentPurchaserFees },
                getGlobalValidation(_globalValidation)
              );
            }}
            required={true}
            value={value.agentPurchaserFees}
            adornement={"%"}
            decimalScale={2}
            minLimit={0.01}
            maxLimit={100}
          />
        </Grid>
      </Grid>
      <Typography variant={"body2"}>
        {"Prix (net vendeur) :\u00a0"}
        <span style={{ fontWeight: "bold" }}>
          {!isNaN(value.price!) &&
            !isNaN(value.agentSellerFees!) &&
            !isNaN(value.agentPurchaserFees!) &&
            formatPrix(
              Math.round(
                value.price! /
                  (1 +
                    (value.agentSellerFees! + value.agentPurchaserFees!) / 100)
              )
            )}
        </span>
      </Typography>
    </>
  );
};
