import { Grid, Typography } from "@material-ui/core";
import OptOutIcon from "@material-ui/icons/Close";
import OptInIcon from "@material-ui/icons/Done";
import React from "react";
import { useServices } from "services";
import { ConsentStatus } from "types";

const OptIn: React.FC<{ optIn: ConsentStatus }> = ({ optIn }) => {
  const {
    l10n: { t },
  } = useServices();
  let Icon;
  let label: string;
  switch (optIn) {
    case "Approved":
      Icon = OptInIcon;
      label = t("Accepte d'être sollicité(e)");
      break;
    default:
      Icon = OptOutIcon;
      label = t("N'accepte pas d'être sollicité(e)");
      break;
  }
  return (
    <Grid container spacing={1}>
      {Icon !== null && (
        <Grid item>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <Icon style={{ fontSize: 16, marginRight: 10 }} />
            <Typography variant="body2" noWrap>
              {label}
            </Typography>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default OptIn;
