import { Fab, makeStyles, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import AddIcon from "@material-ui/icons/TuneOutlined";
import { Rubrique } from "UI/shared/molecules/Rubrique";
import React from "react";

const useStyles = makeStyles(() => ({
  button: {
    fontSize: "16px",
    height: "45px",
    margin: "3px 3px 3px 3px",
    minWidth: "45px",
    padding: 0,
  },
}));

export interface NoItemsPanelProps {
  clearRefinements?: () => void;
  setOpenDrawer?: () => void;
  moreFilters?: React.ReactElement;
}

export const NoItemsPanel: React.FC<NoItemsPanelProps> = ({
  clearRefinements = () => {
    return;
  },
  setOpenDrawer = () => {
    return;
  },
  moreFilters,
}) => {
  const classes = useStyles();

  const boutonCriteresSupp = moreFilters ? (
    moreFilters
  ) : (
    <Button
      variant={"contained"}
      color={"primary"}
      size={"small"}
      className={classes.button}
      onClick={setOpenDrawer}
    >
      <AddIcon />
    </Button>
  );

  const texteCriteresSupp =
    "Ouvrir le panel de recherche avancée pour modifier les critères.";

  const boutonEffacer = (
    <Fab
      variant="extended"
      size="small"
      color="primary"
      aria-label="delete"
      style={{
        boxShadow: "none",
        fontSize: "0.61rem",
        height: 24,
        padding: "0px 8px",
      }}
      onClick={clearRefinements}
    >
      <DeleteIcon fontSize="small" />
      Effacer
    </Fab>
  );

  const texteEffacer = "Effacer tous les critères sélectionnés.";

  return (
    <Paper style={{ padding: "16px" }}>
      <Grid container direction={"column"} spacing={2}>
        <Grid item xs={12}>
          <Grid container justify={"center"}>
            <Typography variant={"h4"}>Aucun résultat disponible</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Rubrique bouton={boutonCriteresSupp} texte={texteCriteresSupp} />
        </Grid>
        <Grid item xs={12}>
          <Rubrique bouton={boutonEffacer} texte={texteEffacer} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default NoItemsPanel;
