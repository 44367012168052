import "./css/leaflet-custom.css";

import { Paper, Tooltip, Typography, useMediaQuery } from "@material-ui/core";
import { CropFree } from "@material-ui/icons";
import LoadingSquare from "components/Loading/LoadingSquare";
import L, { Layer } from "leaflet";
import React, {
  ChangeEvent,
  memo,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
} from "react";
import {
  FeatureGroup,
  Map,
  Marker,
  ScaleControl,
  TileLayer,
  ZoomControl,
} from "react-leaflet";
import { buColors } from "utils/formatage";

import { useServices } from "../../../services";
import { BUType } from "../../../services/tracking/tracking";
import { breakpoint } from "../../../theme/ts/main";
import CustomCheckbox from "../../CustomCheckbox/CustomCheckbox";
import { Action, initialState, reducer } from "./reducer";
import useGeoPolygons, {
  convertGeoPolygons2LeafletPolygons,
} from "./services/useGeoPolygons/useGeoPolygonsEndpoint";
import { useStyles } from "./styles";
import getNormalizedBounds from "./utils/getNormalizedBounds";

export interface LocationsGUIDs {
  regions: string[];
  departements: string[];
  villes: string[];
  quartiers: string[];
}

export interface Location {
  lat: number;
  lng: number;
}

export interface BoundingBox {
  se: string;
  nw: string;
}

export interface MapProps {
  points: any[];
  onMarkerClick: (
    e: ChangeEvent<HTMLInputElement>
  ) => Promise<{ html: string; circleRadius: number; defer: boolean }>;
  onPopupClick: ({ hit, id }: { hit: any; id: string }) => void;
  center: Location;
  sendBoundingBoxToParent: ({
    boundingBox,
    rechercheBounds,
  }: {
    boundingBox: BoundingBox;
    rechercheBounds: boolean;
  }) => void;
  onMapConfigSave: (params: MapConfigSaveParams) => void;
  toggleBoundsSearchLabel: string;
  selectedLocations: LocationsGUIDs;
  loading: boolean;
  bottomLeftPanel?: React.ReactNode;
  createMarkers: ({
    items,
    map,
    handlePointClick,
  }: {
    items: any[];
    map: Map | null;
    handlePointClick: (item: any) => void;
  }) => Marker[];
  fullscreen: boolean;
  containerOffsetY?: number;
}

export interface MapConfigSaveParams {
  boundingBox: BoundingBox;
  center: Location;
  zoom: number;
}

let debounce: NodeJS.Timeout;

const computedBottomLeftStyle: (mobile: boolean) => any = (mobile) => {
  return {
    background: "white",
    bottom: mobile ? 60 : 10,
    left: 10,
    padding: 10,
    position: "absolute",
    zIndex: 900,
  };
};

const computedMapStyle: (mobile: boolean) => any = (mobile) => {
  return {
    height: mobile ? "calc(100vh - 180px)" : "calc(100vh - 150px)",
    position: "relative",
    width: "100%",
  };
};

const invalidateMapSize: (map: L.Map | null) => void = (map) => {
  if (map) {
    map.invalidateSize();
  }
};

const initMap: (map: L.Map | null) => void = (map) => {
  if (map) {
    L.control
      .zoom({
        position: "bottomright",
      })
      .addTo(map);
  }
};

const saveMap: (
  map: L.Map | null,
  onMapConfigSave: (params: MapConfigSaveParams) => void
) => void = (map, onMapConfigSave) => {
  if (!map) return;
  const _zoom = map.getZoom();
  const { lat, lng } = map.getCenter();

  const boundingBox = getNormalizedBounds(map);
  onMapConfigSave({
    boundingBox,
    center: {
      lat,
      lng,
    },
    zoom: _zoom,
  });
};

const createMapMarkers: (
  mapRef: React.RefObject<Map>,
  points: any[],
  dispatch: React.Dispatch<Action>,
  createMarkers: any
) => void = (mapRef, points, dispatch, createMarkers) => {
  const map = getLeafletMapFromRef(mapRef);

  if (!map) return;
  const markers = createMarkers({
    handlePointClick: (item: any) => {
      dispatch({ payload: item, type: "selectItem" });
    },
    items: points,
    map: mapRef.current,
  });
  dispatch({
    payload: markers,
    type: "updateMarkers",
  });
};

const getLeafletMapFromRef: (mapRef: React.RefObject<Map>) => L.Map | null = (
  mapRef
) => (mapRef.current?.leafletElement ? mapRef.current.leafletElement : null);

const fitMap: (
  map: L.Map | null,
  markerGroupRef: React.RefObject<FeatureGroup>,
  geoPolygons: any[] | undefined
) => void = (map, markerGroupRef, geoPolygons) => {
  if (!map) return;
  if (geoPolygons && geoPolygons.length > 0) {
    map.fitBounds(
      geoPolygons.reduce(
        (positions, currentPolygon) => [...positions, ...currentPolygon.wkt],
        []
      )
    );
    return;
  }

  if (markerGroupRef.current)
    map.fitBounds(markerGroupRef.current.leafletElement.getBounds());
};

const MapBiens = memo(function MapBiens(props: MapProps) {
  const {
    points,
    onMarkerClick,
    onPopupClick,
    center,
    sendBoundingBoxToParent,
    onMapConfigSave,
    selectedLocations,
    toggleBoundsSearchLabel,
    loading,
    bottomLeftPanel,
    createMarkers,
    fullscreen,
    containerOffsetY = 0,
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  const leafletMapRef = useRef<Map>(null);
  const groupOfMarkersRef = useRef<FeatureGroup>(null);
  const groupOfPolygonsRef = useRef<FeatureGroup>(null);
  const groupOfSelectedFeatures = useRef<Layer>();
  const mapContainerRef = useRef<HTMLDivElement>(null);

  const leafletMap = getLeafletMapFromRef(leafletMapRef);

  useEffect(() => {
    createMapMarkers(leafletMapRef, points, dispatch, createMarkers);
  }, [points, leafletMap]);

  useEffect(() => {
    if (state.selectedItem && leafletMap) {
      onMarkerClick(state.selectedItem).then((response) => {
        if (!response.defer) {
          const content = document.createElement("content");
          content.innerHTML = response.html;

          if (groupOfSelectedFeatures.current)
            leafletMap.removeLayer(groupOfSelectedFeatures.current);

          const popup = L.popup()
            .setLatLng([state.selectedItem.lat, state.selectedItem.long])
            .setContent(content);

          const approximationCircle = L.circle(
            [state.selectedItem.lat, state.selectedItem.long],
            response.circleRadius,
            {
              color: buColors[state.selectedItem.bu as BUType],
              interactive: false,
            }
          );
          groupOfSelectedFeatures.current = L.featureGroup([
            popup,
            approximationCircle,
          ]);

          leafletMap.addLayer(groupOfSelectedFeatures.current);

          leafletMap.openPopup(popup);

          L.DomEvent.addListener(content, "click", () => {
            onPopupClick({
              hit: state.selectedItem,
              id: state.selectedItem.id,
            });
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedItem]);

  useLayoutEffect(() => {
    const mapContainerObserver = new ResizeObserver(() => {
      const map = getLeafletMapFromRef(leafletMapRef);
      map?.invalidateSize();
    });

    if (mapContainerRef.current)
      mapContainerObserver.observe(mapContainerRef.current);

    return () => {
      mapContainerObserver.disconnect();
    };
  }, []);

  // initialisation de la carte
  useEffect(() => {
    initMap(leafletMap);
    return () => {
      saveMap(getLeafletMapFromRef(leafletMapRef), onMapConfigSave);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleZoomMove = () => {
    if (!leafletMap || loading) return;
    if (debounce) clearTimeout(debounce);
    debounce = (setTimeout(
      () =>
        sendBoundingBoxToParent({
          boundingBox: getNormalizedBounds(leafletMap),
          rechercheBounds: state.rechercheBounds,
        }),
      1000
    ) as unknown) as NodeJS.Timeout;
  };

  const { geoPolygons } = useGeoPolygons(selectedLocations);

  useEffect(() => {
    fitMap(leafletMap, groupOfMarkersRef, geoPolygons);
  }, [geoPolygons]);

  useEffect(() => {
    invalidateMapSize(leafletMap);
  }, [fullscreen]);

  const centerMap = () => {
    if (
      leafletMap &&
      leafletMap.getZoom() !== 3 &&
      geoPolygons &&
      geoPolygons.length === 0
    ) {
      leafletMap.setZoom(3);
      dispatch({ payload: true, type: "fitMapRequested" });
      return;
    }

    fitMap(leafletMap, groupOfMarkersRef, geoPolygons);
    handleZoomMove();
  };

  const toggleRechercheBounds = () => {
    sendBoundingBoxToParent({
      boundingBox: getNormalizedBounds(leafletMap),
      rechercheBounds: !state.rechercheBounds,
    });
    dispatch({
      payload: !state.rechercheBounds,
      type: "toggleRechercheBounds",
    });
  };

  const mobile = useMediaQuery("(max-width: 768px)");

  return (
    <div
      ref={mapContainerRef}
      style={{
        position: "relative",
        zIndex: 0,
        bottom: containerOffsetY,
      }}
    >
      <Map
        ref={leafletMapRef}
        center={center}
        zoom={6}
        style={computedMapStyle(mobile)}
        preferCanvas={true}
        ondragend={handleZoomMove}
        onzoomend={handleZoomMove}
        id={"map"}
        zoomControl={false}
      >
        <TileLayer
          url={
            "https://api.mapbox.com/styles/v1/sylvainromiguier/ckb6iapxz244p1ip9dizfczuq/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic3lsdmFpbnJvbWlndWllciIsImEiOiJjazduM3lmZTAwbmN3M2RwNm1iejgxOGJ4In0.qIS70Q-XV35PCPJvVm-CCQ"
          }
          attribution={
            'Map data <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>'
          }
          minZoom={3}
          maxZoom={16}
        />
        <ScaleControl imperial={false} position={"bottomright"} />
        <ZoomControl position={"bottomright"} />
        {state.markers.length > 0 && (
          <FeatureGroup ref={groupOfMarkersRef}>
            {state.markers.map(
              (marker: { component: any }) => marker.component
            )}
          </FeatureGroup>
        )}
        {geoPolygons && geoPolygons.length > 0 && (
          <FeatureGroup ref={groupOfPolygonsRef}>
            {convertGeoPolygons2LeafletPolygons(geoPolygons)}
          </FeatureGroup>
        )}
      </Map>
      {loading && (
        <div
          style={{
            height: "200px",
            left: "calc(50% - 100px)",
            position: "absolute",
            top: "calc(50% - 100px)",
            width: "200px",
            zIndex: 900,
          }}
        >
          <LoadingSquare />
        </div>
      )}
      {bottomLeftPanel && (
        <Paper elevation={1} style={computedBottomLeftStyle(mobile)}>
          {bottomLeftPanel}
        </Paper>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          position: "absolute",
          right: 10,
          top: 10,
          zIndex: 800,
        }}
      >
        <div style={{ marginRight: 10 }}>
          <ToggleRechercheSurBounds
            rechercheBounds={state.rechercheBounds}
            setRechercheBounds={toggleRechercheBounds}
            label={toggleBoundsSearchLabel}
          />
        </div>
        <EtiquetteRecentrage reinit={centerMap} />
      </div>
    </div>
  );
});
export default MapBiens;

interface EtiquetteRecentrageProps {
  reinit: () => void;
}

const EtiquetteRecentrage: React.FC<EtiquetteRecentrageProps> = (props) => {
  const { reinit } = props;
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();
  return (
    <div onClick={reinit}>
      <Tooltip title={t("recentrer sur mes critères")} placement={"left"}>
        <div className={classes.resetContainer}>
          <CropFree />

          {breakpoint().smAndUp && (
            <Typography style={{ marginLeft: "5px" }}>
              {t("RECENTRER SUR MES CRITERES")}
            </Typography>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

interface ToggleRechercheSurBoundsProps {
  rechercheBounds: boolean;
  setRechercheBounds: () => void;
  label: string;
}

const ToggleRechercheSurBounds: React.FC<ToggleRechercheSurBoundsProps> = (
  props
) => {
  const { rechercheBounds, setRechercheBounds, label } = props;
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();
  return (
    <div
      className={`${classes.resetContainer} ${classes.toggleBoundsContainer}`}
    >
      <CustomCheckbox checked={rechercheBounds} onChange={setRechercheBounds} />
      <Typography style={{ marginLeft: "5px" }}>{t(label)}</Typography>
    </div>
  );
};
