import { useEffect, useState } from "react";
import { RequestForInformationDto } from "services/request-for-informations/dto/RequestForInformationDto";
import { useServices } from "..";
import { useTxHttp } from "../http";

export const useSelectedRequestForInformations = (id: string) => {
  const [current, setCurrent] = useState<RequestForInformationDto | null>(
    () => null
  );

  const { get } = useTxHttp();
  const { endpoints } = useServices();

  useEffect(() => {
    const getRequestForInformations = async (id: string) => {
      const { data } = await get<RequestForInformationDto>(
        endpoints.requestForInformations.getRequestForInformationById(id)
      );

      setCurrent(data);
    };

    if (!id) return;
    getRequestForInformations(id);
  }, [id]);

  return current;
};
