import { Typography, useTheme } from "@material-ui/core";
import React from "react";
import { ITheme } from "theme/ts/main";

interface OptionType {
  id: string;
  libelle: string;
}

export interface OptionProps {
  option: OptionType;
  onClick: () => void;
  optionSelected: boolean;
}

export const Option: React.FC<OptionProps> = ({
  option,
  onClick,
  optionSelected,
}) => {
  const theme = useTheme<ITheme>();
  return (
    <div
      style={{ cursor: "pointer", display: "flex" }}
      key={option.id}
      onClick={onClick}
    >
      <Typography variant={"h3"} style={{ color: theme.palette.tonique }}>
        &gt;&nbsp;
      </Typography>
      <Typography
        variant={"h4"}
        style={{
          color: optionSelected ? theme.palette.tonique : "inherit",
        }}
      >
        {option.libelle}
      </Typography>
    </div>
  );
};
