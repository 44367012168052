import { Card, TextField } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Loading from "components/Loading/LoadingSkeleton";
import useNotifier from "components/Notifier/useNotifier";
import TitrePage from "components/TitrePage";
import ReactEcharts from "echarts-for-react";
import React, { useEffect, useState } from "react";
import { getOptionStatsPublication } from "theme/EChartsStyles";

import { ErrorPanel } from "UI/shared/templates/Panels/ErrorPanel";
import { useServices } from "services";
import { useTxHttp } from "services/http";

const useStyle = makeStyles((theme) => ({
  container: {},
  loaderGraph: {
    height: "300px",
  },
  root: {},
}));

const StatsPublication = (props) => {
  const { endpoints } = useServices();
  const { get } = useTxHttp();
  const classes = useStyle();
  const theme = useTheme();
  const {
    l10n: { t },
  } = useServices();
  const { showSnack } = useNotifier();
  const chartsStyle = { height: "500px", width: "100%" };

  //loader
  const [selectLoading, setSelectLoading] = useState(false);
  const [statsLoading, setStatsLoading] = useState(true);

  // autocomplete data
  const [clients, setClients] = useState([]);
  const [passerelles, setPasserelles] = useState([]);

  // autocomplete choice
  const [selectedPasserelle, setSelectedPasserelle] = useState("");
  const [selectedClient, setSelectedClient] = useState("");

  // data
  const [stats, setStats] = useState(null);

  // fetch func
  const fetchRef = async (urls) => {
    return Promise.all(urls.map((url) => get(url).then((_) => _.data)));
  };
  const fetchStats = async (affaireId, passerelleId) => {
    return get(endpoints.reporting.publication.stat, {
      affaireId,
      passerelleId,
    }).then((_) => _.data);
  };

  // side effects
  useEffect(() => {
    if (sessionStorage.getItem("ref_publication")) {
      const data = JSON.parse(sessionStorage.getItem("ref_publication"));
      setPasserelles(data.passerelles);
      setClients(data.clients);

      setSelectedPasserelle(data.passerelles[0] || null);
      setSelectedClient(data.clients[0] || null);
    } else {
      setSelectLoading(true);
      fetchRef([
        endpoints.reporting.publication.passerelle,
        endpoints.reporting.publication.client,
      ])
        .then((responses) => {
          const passerelles = responses[0].map((item) => ({
            label: item.nom,
            value: item.id,
          }));
          const clients = responses[1].map((item) => ({
            label: item.nom,
            value: item.id,
          }));
          setPasserelles(passerelles);
          setClients(clients);
          sessionStorage.setItem(
            "ref_publication",
            JSON.stringify({ clients, passerelles })
          );
          setSelectedPasserelle(passerelles[0] || null);
          setSelectedClient(clients[0] || null);
        })
        .catch((e) => setStats("erreur"))
        .finally(() => setSelectLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedPasserelle && selectedClient) {
      setStatsLoading(true);

      fetchStats(selectedClient.value, selectedPasserelle.value)
        .then((data) => {
          setStats(data);
        })
        .catch(() =>
          showSnack({
            message: `${t("ErreurRecuperationDonnees")}. ${t(
              "EssayezRafraichir"
            )}.`,
            variant: "error",
            withLoader: false,
          })
        )
        .finally(() => setStatsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPasserelle, selectedClient]);

  if (stats === "erreur")
    return (
      <ErrorPanel
        title={"Données non disponibles."}
        text={"Nous n'avons pas reçu les données statistiques."}
      />
    );

  return (
    <div className={classes.root}>
      <TitrePage
        categorie="Reporting"
        page={t("Publications")}
        filePath={"/static/markdowns/Reporting/reportingPublications.md"}
      />
      <Grid container spacing={1} className={classes.container}>
        <Grid item xs={12} sm={6} lg={4}>
          {!selectLoading ? (
            <TextField
              fullWidth
              select
              SelectProps={{
                onChange: (evt) => setSelectedClient(evt.target.value),
                placeholder: t("ChoisirClient"),
                value: selectedClient,
              }}
              label={t("Client")}
              variant={"outlined"}
            >
              {clients.map((item) => (
                <MenuItem key={item.label} value={item}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <Loading />
          )}
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          {!selectLoading ? (
            <TextField
              fullWidth
              select
              SelectProps={{
                onChange: (evt) => setSelectedPasserelle(evt.target.value),
                placeholder: t("ChoisirPasserelle"),
                value: selectedPasserelle,
              }}
              label={t("Passerelle")}
              variant={"outlined"}
            >
              {passerelles.map((item) => (
                <MenuItem key={item.label} value={item}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <Loading />
          )}
        </Grid>
        <Grid item xs={12}>
          {!statsLoading ? (
            <Card>
              <CardContent>
                <ReactEcharts
                  option={getOptionStatsPublication(t, theme, {
                    data: stats,
                    name: "Publication",
                  })}
                  style={chartsStyle}
                />
              </CardContent>
            </Card>
          ) : (
            <Loading styles={{ height: 500 }} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default StatsPublication;
