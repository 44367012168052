import { EndpointsProvider } from "./interfaces/EndpointsProvider";

export interface RapprochementEndpoints {
  compterLesAcquereursPourMonBien: string;
  trouverMesAcquereursPourMonBien: string;
  trouverDesAcquereursPourMonBien: string;
}

const endpoints: EndpointsProvider<RapprochementEndpoints> = (
  microServices
) => ({
  compterLesAcquereursPourMonBien: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/Rapprochement/CompterTousLesAcquereursPourMonBien`,

  trouverDesAcquereursPourMonBien: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/Rapprochement/TrouverDesAcquereursPourMonBien`,

  trouverMesAcquereursPourMonBien: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/Rapprochement/TrouverMesAcquereursPourMonBien`,
});

export default endpoints;
