import { Typography } from "@material-ui/core";
import ZoneTextfieldMulti from "components/GeoLocSearchable";
import { isEmpty, some } from "lodash";
import React, { useEffect } from "react";

import { useServices } from "../../../../../../services";
import { PlaceDto } from "../models/placeDto";
import { SectionTitle } from "./components/SectionTitle";
import { ProfileInputProps } from "./InputProps";

export interface PlacesProps {
  places: PlaceDto[];
}

export const Places: React.FC<PlacesProps> = ({ places }) => {
  const {
    l10n: { compare },
  } = useServices();
  return (
    <>
      {places
        .sort((a: PlaceDto, b: PlaceDto) => compare(a.name, b.name))
        .map((place) => (
          <div
            key={place.id}
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Typography
              variant="h5"
              style={{
                color:
                  place.id === "00000000-0000-0000-0000-000000000000"
                    ? "red"
                    : "inherit",
              }}
            >
              {place.name}
            </Typography>
            {place.id === "00000000-0000-0000-0000-000000000000" && (
              <Typography variant="body1" style={{ color: "red" }}>
                cette ville saisie à partir de Tremplin n&apos;est plus valide,
                veuillez la corriger.
              </Typography>
            )}
            {place.postalCodes.map((postalCode: string, index: number) => (
              <Typography key={index} variant="body1">
                &nbsp;({postalCode})
              </Typography>
            ))}
          </div>
        ))}
    </>
  );
};

export const SelectPLaces: React.FC<ProfileInputProps<PlaceDto[]>> = ({
  pristine,
  value,
  onChange,
}) => {
  const {
    l10n: { t },
  } = useServices();

  // formatage des zones de recherche pour affichage
  const selection = (value || []).map((place) => ({
    id: place.id,

    label: `${place.name} ${
      some(place.postalCodes)
        ? place.postalCodes.map((_) => `(${_})`).join(" ")
        : ""
    }`,
    value: place.id,
  }));

  const isValid = !isEmpty(value);
  useEffect(() => {
    onChange(value, isValid);
  }, [isValid]);

  const formatPlaces = (places: any[]) => {
    const newPlaces: any[] = [];
    (places || []).forEach((element) => {
      const pattern = new RegExp(
        "^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$"
      );
      if (!pattern.test(element.id)) {
        element.raw.forEach((district: any) => {
          newPlaces.push({
            id: district.id,
            name: district.nom,
            postalCodes: district.codesPostaux,
          });
        });
      } else {
        newPlaces.push({
          id: element.id,
          name: element.label || element.nom,
        });
      }
    });
    return newPlaces;
  };

  return (
    <>
      <SectionTitle title={t("Où recherchez-vous ?")} />
      <ZoneTextfieldMulti
        onChange={(places) => {
          onChange(formatPlaces(places), !isEmpty(places));
        }}
        selection={selection}
        label={t("Zones de recherche")}
        disabled={false}
        required={true}
        helperText={"*Au moins une zone de recherche obligatoire"}
        error={pristine ? false : isEmpty(value)}
      />
    </>
  );
};
