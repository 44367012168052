import { Button, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/styles";
import {
  and_now,
  available_real_estate_sold,
  cartography,
  crm,
  marketing_campaign,
  my_real_estate,
  reporting,
  responsive,
  welcome,
} from "assets/images/onboarding";
import ResponsiveCarousel from "components/ResponsiveCarousel/ResponsiveCarousel";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { breakpoint } from "theme/main";

import { useServices } from "../../services";
import OnboardingSlide from "./OnboardingSlide";

const useStyles = makeStyles((theme) => ({
  buttonExplore: {
    [theme.breakpoints.up("md")]: {
      marginTop: "15%",
    },

    marginTop: "6%",
  },
  carousel: {
    background: theme.palette.common.white,
    height: "100%",
  },
  closeButton: {
    position: "absolute",
    right: "1%",
    top: "1%",
    zIndex: 99999999,
  },
  root: {},
}));

const CloseOnboardingButton = ({ onCloseButtonClick, containerClass }) => (
  <Button className={containerClass} onClick={onCloseButtonClick}>
    <Close />
  </Button>
);
const ExplorerButton = ({ onExploreButtonClick, containerClass, label }) => (
  <Button
    variant="contained"
    color="primary"
    onClick={onExploreButtonClick}
    className={containerClass}
    size={breakpoint().smAndDown ? "small" : "medium"}
  >
    {label}
  </Button>
);

export const Onboarding = () => {
  const [isAtLast, setIsAtLast] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles();
  const history = useHistory();

  const closeOnboarding = () => {
    localStorage.setItem("isOnboarded", true);
    history.replace(window.defaultPage);
  };
  const slides = [
    {
      isGenericSlide: true,
      picture: { name: "welcome", url: welcome },
      renderContent: () => (
        <>
          <Typography
            variant="h1"
            style={{
              fontSize: "2rem",
              lineHeight: 1,
              marginTop: "6%",
            }}
          >
            {t("Bienvenue")}
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontSize: "1rem",
              marginTop: "2%",
            }}
          >
            {t("Vous venez de vous connecter pour la première fois")}
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontSize: "1rem",
            }}
          >
            {t("à la nouvelle version de TremplinX")}
          </Typography>
          <Typography
            variant="h4"
            style={{
              fontSize: "1.1rem",
              marginTop: "2%",
            }}
          >
            {t("Découvrez ses fonctionnalités !")}
          </Typography>
        </>
      ),
    },
    {
      picture: { name: "crm", url: crm },
      renderContent: () => (
        <>
          <Typography
            variant="h1"
            style={{
              fontSize: "37px",
              lineHeight: 1,
              marginTop: "6%",
            }}
          >
            {t("CRM")}
          </Typography>
          <Typography
            variant="h4"
            style={{
              fontSize: "23px",
              lineHeight: 1,
            }}
          >
            {t("Gérez vos contacts")}
          </Typography>
          <div
            style={{
              marginTop: "8%",
            }}
          >
            <div style={{ display: "flex" }}>
              <Check style={{ color: "green" }} />
              <Typography
                variant="body1"
                style={{
                  fontSize: "15px",
                  marginLeft: "2%",
                  textAlign: "start",
                }}
              >
                {t("Suivez et saisissez rapidement un contact")}
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Check style={{ color: "green" }} />
              <Typography
                variant="body1"
                style={{
                  fontSize: "15px",
                  marginLeft: "2%",
                  textAlign: "start",
                }}
              >
                {t("Ajoutez un profil acquéreur / locataire à un contact")}
              </Typography>
            </div>
          </div>
        </>
      ),
    },
    {
      picture: { name: "my real estate", url: my_real_estate },
      renderContent: () => (
        <>
          <Typography
            variant="h1"
            style={{
              fontSize: "37px",
              lineHeight: 1,
              marginTop: "6%",
            }}
          >
            {t("MES BIENS")}
          </Typography>
          <Typography
            variant="h4"
            style={{
              fontSize: "23px",
              lineHeight: 1,
            }}
          >
            {t("en cours de publication")}
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontSize: "15px",
              marginTop: "8%",
              textAlign: "start",
            }}
          >
            {t("Visualisez les acquéreurs potentiels :")}
          </Typography>
          <div>
            <div style={{ display: "flex" }}>
              <Check style={{ color: "green" }} />
              <Typography
                variant="body1"
                style={{
                  fontSize: "15px",
                  marginLeft: "2%",
                  textAlign: "start",
                }}
              >
                {t("au sein de mes contacts")}
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Check style={{ color: "green" }} />
              <Typography
                variant="body1"
                style={{
                  fontSize: "15px",
                  marginLeft: "2%",
                  textAlign: "start",
                }}
              >
                {t("au sein des contacts des conseillers du groupe")}
              </Typography>
            </div>
          </div>
        </>
      ),
    },
    {
      picture: {
        name: "available real estate sold",
        url: available_real_estate_sold,
      },
      renderContent: () => (
        <>
          <Typography
            variant="h1"
            style={{
              fontSize: "37px",
              lineHeight: 1,
              marginTop: "6%",
            }}
          >
            {t("LES BIENS")}
          </Typography>
          <Typography
            variant="h4"
            style={{
              fontSize: "23px",
              lineHeight: 1,
            }}
          >
            {t("disponible / vendus au sein du groupe")}
          </Typography>
          <div style={{ display: "flex", marginTop: "8%" }}>
            <Check style={{ color: "green" }} />
            <Typography
              variant="body1"
              style={{
                fontSize: "15px",
                marginLeft: "2%",
                textAlign: "start",
              }}
            >
              {t("Contactez un conseiller du groupe en 1 clic")}
            </Typography>
          </div>
        </>
      ),
    },
    {
      picture: {
        name: "cartography",
        url: cartography,
      },
      renderContent: () => (
        <>
          <Typography
            variant="h1"
            style={{
              fontSize: "37px",
              lineHeight: 1,
              marginTop: "6%",
            }}
          >
            {t("CARTOGRAPHIE")}
          </Typography>
          <Typography
            variant="h4"
            style={{
              fontSize: "23px",
              lineHeight: 1,
            }}
          >
            {t("des biens")}
          </Typography>
          <div style={{ display: "flex", marginTop: "8%" }}>
            <Check style={{ color: "green" }} />
            <Typography
              variant="body1"
              style={{
                fontSize: "15px",
                marginLeft: "2%",
                textAlign: "start",
              }}
            >
              {t("Recherchez un bien facilement sur une carte")}
            </Typography>
          </div>
        </>
      ),
    },
    {
      picture: {
        name: "reporting",
        url: reporting,
      },
      renderContent: () => (
        <>
          <Typography
            variant="h1"
            style={{
              fontSize: "37px",
              lineHeight: 1,
              marginTop: "6%",
            }}
          >
            {t("REPORTING")}
          </Typography>
          <Typography
            variant="h4"
            style={{
              fontSize: "23px",
              lineHeight: 1,
            }}
          >
            {t("synthèse et détails")}
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "8%",
            }}
          >
            <div style={{ display: "flex" }}>
              <Check style={{ color: "green" }} />
              <Typography
                variant="body1"
                style={{
                  fontSize: "15px",
                  marginLeft: "2%",
                  textAlign: "start",
                }}
              >
                {t(
                  "Retrouvez toujours vos reporting personnalisés et interactifs :"
                )}
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Check style={{ opacity: 0 }} />
              <Typography
                variant="body1"
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  marginLeft: "2%",
                  textAlign: "start",
                }}
              >
                {t("biens, mandats, actes, publications et classements")}
              </Typography>
            </div>
          </div>
        </>
      ),
    },
    {
      picture: {
        name: "campaign",
        url: marketing_campaign,
      },
      renderContent: () => (
        <>
          <Typography
            variant="h1"
            style={{
              fontSize: "37px",
              lineHeight: 1,
              marginTop: "6%",
            }}
          >
            {t("CAMPAGNES MARKETING")}
          </Typography>

          <div style={{ display: "flex", marginTop: "8%" }}>
            <Check style={{ color: "green" }} />
            <Typography
              variant="body1"
              style={{
                fontSize: "15px",
                marginLeft: "2%",
                textAlign: "start",
              }}
            >
              {t("Programmez vos campagnes emailings en 1 clic")}
            </Typography>
          </div>
        </>
      ),
    },
    {
      picture: {
        name: "responsive",
        url: responsive,
      },
      renderContent: () => (
        <>
          <Typography
            variant="h1"
            style={{
              fontSize: "37px",
              lineHeight: 1,
              marginTop: "6%",
            }}
          >
            {t("RESPONSIVE")}
          </Typography>
          <Typography
            variant="h4"
            style={{
              fontSize: "23px",
            }}
          >
            {t("100% web")}
          </Typography>
          <div style={{ display: "flex", marginTop: "8%" }}>
            <Check style={{ color: "green" }} />
            <Typography
              variant="body1"
              style={{
                fontSize: "15px",
                marginLeft: "2%",
                textAlign: "start",
              }}
            >
              {t(
                "Bureau, tablette, mobile, en mode paysage, les fonctionnalités sont toujours là"
              )}
            </Typography>
          </div>
        </>
      ),
    },
    {
      isGenericSlide: true,
      picture: { name: "and now", url: and_now },
      renderContent: () => (
        <>
          <Typography
            variant="h1"
            style={{
              fontSize: "1.6rem",
              lineHeight: 1,
              marginTop: "6%",
            }}
          >
            {t("Et maintenant ?")}
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontSize: "0.9rem",
              marginTop: "6%",
            }}
          >
            {t("Cette nouvelle version de l'application")}
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontSize: "0.9rem",
              fontWeight: "bold",
            }}
          >
            {t("va régulièrement s'enrichir.")}
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontSize: "0.9rem",
            }}
          >
            {t("N'hésitez pas à nous faire vos retours !")}
          </Typography>
          <ExplorerButton
            onExploreButtonClick={closeOnboarding}
            containerClass={classes.buttonExplore}
            label={t("Démarrez")}
          />
        </>
      ),
    },
  ];

  const handlePictureChange = useCallback(
    (index) => {
      const isAtLastPicture = index === slides.length - 1;
      // fix from this issue : https://github.com/leandrowd/react-responsive-carousel/issues/251#issuecomment-423266363
      setSelectedItemIndex(index);

      if (isAtLast && !isAtLastPicture) {
        setIsAtLast(false);
      } else if (!isAtLast && isAtLastPicture) {
        setIsAtLast(true);
      }
    },
    [isAtLast, slides.length, setSelectedItemIndex, setIsAtLast]
  );

  return (
    <>
      <CloseOnboardingButton
        onCloseButtonClick={closeOnboarding}
        containerClass={classes.closeButton}
      />

      <ResponsiveCarousel
        showIndicators
        onPictureChange={handlePictureChange}
        //  TODO : this should be in the state
        selectedItem={selectedItemIndex}
        containerClass={classes.carousel}
      >
        {slides.map(({ renderContent, picture, isGenericSlide }) => (
          <OnboardingSlide
            onClick={() => {
              if (!isAtLast) {
                setSelectedItemIndex(selectedItemIndex + 1);
              }
            }}
            key={picture.name}
            {...{ isGenericSlide, picture, renderContent }}
          />
        ))}
      </ResponsiveCarousel>
    </>
  );
};
