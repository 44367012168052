import { Theme, Typography, useMediaQuery } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";
import React, { useContext } from "react";
import { useServices } from "services";
import { useFeatureFlags } from "services/featureFlags";
import notificationDomains from "services/notifications/notificationDomains";
import { NotificationSettingDto } from "services/notificationSettings/dto/NotificationSettingDto";

import TitreSection from "../../../../../../../layoutContents/Fiches/components/Details/components/TitreSection";
import NotificationSetting from "./NotificationSetting";
import { NotificationSettingsCenterContext } from "./NotificationSettingsCenterProvider";

const sortByType = (a: NotificationSettingDto, b: NotificationSettingDto) => {
  if (a.type < b.type) return -1;
  if (a.type > b.type) return 1;
  return 0;
};

const groupByDomain = (
  notificationSettings: NotificationSettingDto[],
  isDRActive: boolean
) => {
  const domains = Object.values(notificationDomains).filter(
    (d) =>
      d !== notificationDomains.DR ||
      (d === notificationDomains.DR && isDRActive)
  );

  return notificationSettings.reduce(
    (
      groups: Record<string, NotificationSettingDto[]>,
      setting: NotificationSettingDto
    ) => {
      const settingDomain = domains.find((d) => setting.type.match(d));
      if (settingDomain) {
        if (groups[settingDomain]) groups[settingDomain].push(setting);
        else groups[settingDomain] = [setting];
      }
      return groups;
    },
    {} as Record<string, NotificationSettingDto[]>
  );
};

const formatForTranslation = (type: string, key: "Title" | "Description") =>
  `Notifications.${type}.${key}`;

const getStyles: ({
  isExtraSmall,
}: {
  isExtraSmall: boolean;
}) => Record<string, CSSProperties> = ({ isExtraSmall }) => {
  return {
    ...(isExtraSmall === false && {
      container: {
        marginLeft: "4rem",
      },
    }),
    mainTitleDiv: {
      marginLeft: "",
    },
    notificationSettingsDiv: {
      marginTop: "1rem",
    },
  };
};

const NotificationSettingsCenter = () => {
  const {
    notificationSettingsCenter: { notificationSettings = [] },
  } = useContext(NotificationSettingsCenterContext);

  const { DR } = useFeatureFlags();

  const notificationSettingGroups = groupByDomain(notificationSettings, DR);

  const isExtraSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const styles = getStyles({ isExtraSmall });

  const {
    l10n: { t },
  } = useServices();

  return (
    <>
      <div style={styles.container}>
        <div style={styles.mainTitleDiv}>
          <Typography variant={"h2"} component={"h4"}>
            Choisissez les notifications que vous souhaitez recevoir au sein de
            l&apos;application
          </Typography>
        </div>
        <div style={styles.notificationSettingsDiv}>
          {Object.keys(notificationSettingGroups).map((key) => {
            return (
              <div key={key}>
                <TitreSection titre={t(key)} />
                {notificationSettingGroups[key]
                  .sort(sortByType)
                  .map(({ type, enabled }: NotificationSettingDto) => (
                    <NotificationSetting
                      type={type}
                      title={t(formatForTranslation(type, "Title"))}
                      description={t(formatForTranslation(type, "Description"))}
                      enabled={enabled}
                      key={type}
                    />
                  ))}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default NotificationSettingsCenter;
