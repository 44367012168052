import React from "react";
import Section from "../Section";
import { Typography } from "@material-ui/core";

const TextSection = ({ title, content }: { title: string; content: string }) =>
  content === undefined ? (
    <></>
  ) : (
    <Section title={title}>
      <Typography variant="subtitle1" align="left">
        {content}
      </Typography>
    </Section>
  );

export default TextSection;
