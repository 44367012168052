import { RealEstateDto } from "services/profiles/dto/RealEstateDto";
import { RealEstatePropertyCardProps } from "UI/features/realEstates/organisms/RealEstatePropertyCard";
import getUrlPhoto from "utils/UrlImage";

export const toRealEstatePropertyCardProps: (
  item: RealEstateDto,
  onClick: () => void
) => RealEstatePropertyCardProps = (item, onClick) => ({
  bedroomsNb: item.nbChambres,
  bu: item.bu,
  businessType: item.typeAffaireEnum,
  cityName: item.localisation.ville || "",
  cityZipCode: (item.localisation.codesPostaux || [])[0] || "",
  hasOffer: item.hasOffer,
  id: item.id,
  isNew: item.neuf,
  isPrestige: item.prestige,
  isUnderOfferToSell: item.underOfferToSell,
  price: item.prix,
  listeTags: [],
  surface: item.surface,
  onClick,
  photo: getUrlPhoto(item.bu, "300x300", item.photoPrincipale),
  propertyType: item.typeBienNom,
  roomsNb: item.nbPieces,
});
