import { MatchingProfileRequestDto } from "services/matching/MatchingProfileRequestDto";

import { LandFeaturesDto } from "../../../../models/Proposal/Features/Land/landFeaturesDto";

export default (features: LandFeaturesDto): MatchingProfileRequestDto => {
  return new MatchingProfileRequestDto({
    budget: features.financialInfo.price,
    cityId: features.address.city?.id,
    constructionYear: undefined,
    nbRooms: undefined,
    profileKind: "Purchase",
    propertyKind: "Land",
    surface: features.landPropertyInfo.squareMeter,
    withElevator: undefined,
    withParking: undefined,
    withPool: undefined,
  });
};
