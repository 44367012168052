import { Grid, Typography, useTheme } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { PhoneInTalkOutlined } from "@material-ui/icons";
import ConteneurCarte from "components/ConteneurCarte";
import React from "react";

import { useServices } from "../../../../../services";

const Assistance = () => {
  const {
    trackingService: { sendCustomEventToGoogle },
  } = useServices();
  const theme = useTheme();
  const tailleIcone = 36;
  const classes = {
    a: {
      cursor: "pointer",
      textDecoration: "none",
    },
    conteneur: {
      backgroundColor: theme.palette.default,
    },
    icone: {
      color: "#E7C534",
      fontSize: tailleIcone,
    },
    label: {
      color: "white",
    },
    valeur: {
      color: theme.palette.tonique,
      textAlign: "center",
    },
  };
  return (
    <div style={{ width: "200px" }}>
      <Tooltip title={"Contacter l'assistance"} placement={"top"}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`tel:0499642222`}
          style={classes.a}
          onClick={() => sendCustomEventToGoogle("Clic téléphone Assistance")}
        >
          <ConteneurCarte backgroundColor={theme.palette.default} padding={5}>
            <Grid
              container
              spacing={2}
              justify={"center"}
              alignItems={"center"}
            >
              <Grid item xs={3}>
                <PhoneInTalkOutlined style={classes.icone} />
              </Grid>
              <Grid item xs={7}>
                <Grid container direction={"column"} spacing={0}>
                  <Typography variant={"body1"} style={classes.label}>
                    Assistance
                  </Typography>
                  <Typography
                    variant={"h3"}
                    style={{
                      ...classes.label,
                      fontWeight: "bold",
                    }}
                    noWrap
                  >
                    04 99 64 22 22
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </ConteneurCarte>
        </a>
      </Tooltip>
    </div>
  );
};

export default Assistance;
