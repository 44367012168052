import { Sort } from "components/RefinementField/types";
import { useCallback, useState } from "react";
import { SortOption } from "UI/shared/molecules/SortInput";

const defaultSortOption = {
  isAscendingOrder: false,
  isDefault: true,
  label: "Date de modification",
  query: "LastModificationDate",
  value: "LastModificationDate descending",
};

const sortOptions: SortOption[] = [
  defaultSortOption,
  {
    isAscendingOrder: true,
    isDefault: false,
    label: "Date de modification",
    query: "LastModificationDate",
    value: "LastModificationDate ascending",
  },
  {
    isAscendingOrder: true,
    isDefault: false,
    label: "Date de création",
    query: "CreationDate",
    value: "CreationDate ascending",
  },
  {
    isAscendingOrder: false,
    isDefault: false,
    label: "Date de création",
    query: "CreationDate",
    value: "CreationDate descending",
  },
  {
    isAscendingOrder: true,
    isDefault: false,
    label: "Budget",
    query: "BudgetMax",
    value: "BudgetMax ascending",
  },
  {
    isAscendingOrder: false,
    isDefault: false,
    label: "Budget",
    query: "BudgetMax",
    value: "BudgetMax descending",
  },
  {
    isAscendingOrder: true,
    isDefault: false,
    label: "Nom",
    query: "ContactLastName",
    value: "ContactLastName ascending",
  },
  {
    isAscendingOrder: false,
    isDefault: false,
    label: "Nom",
    query: "ContactLastName",
    value: "ContactLastName descending",
  },
];

export const useSortOptions = () => {
  const [activeSortOption, setActiveSortOption] = useState<SortOption>(
    defaultSortOption
  );

  const onChange = useCallback(
    ({ value }: { key: "sort"; value: Sort }) => {
      const sortOption = sortOptions.find(
        (so) =>
          so.query === value.value.column &&
          so.isAscendingOrder === value.value.ascending
      );

      if (!sortOption) return;

      setActiveSortOption(sortOption);
    },
    [sortOptions]
  );

  return {
    defaultSortOption,
    activeSortOption,
    onChange,
    sortOptions,
  };
};
