import { useEffect, useReducer } from "react";
import updateRefinements from "utils/refinementsUpdateHandler";

import { useTxHttp } from "./http";
import { useServices } from "./index";

const initialState = {
  error: false,
  hasMore: true,
  items: { data: [] },
  loading: false,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "loading":
      return { ...state, error: false, loading: true };
    case "setError":
      return { ...state, error: payload, loading: false };
    case "setItems":
      return { ...state, loading: false, ...payload };
    case "setHasMore":
      return { ...state, hasMore: payload };
    default:
      return state;
  }
};

export default function useItemsSearch({
  url,
  adapter,
  refinements,
  pageNumber = null,
  pageSize = null,
  boundingBox = null,
  resetPageNumber = () => {},
  rechercheBounds = false,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { get } = useTxHttp();
  const {
    l10n: { t },
  } = useServices();

  useEffect(() => {
    resetPageNumber();
    dispatch({
      payload: {
        items: {},
      },
      type: "setItems",
    });
    // eslint-disable-next-line
  }, [refinements, boundingBox]);

  useEffect(() => {
    const refinementsParams = refinements.createParams(rechercheBounds);

    dispatch({ payload: true, type: "loading" });

    let params = {
      ...refinementsParams,
      ...boundingBox,
      page: pageNumber,
      size: pageSize,
    };

    if (pageNumber === null) {
      params = {
        ...refinementsParams,
        ...boundingBox,
      };
    }

    get(url, params)
      .then((res) => {
        if (res.data) {
          let data = res.data?.result ?? res.data;

          if (data.count > 0) {
            updateRefinements(refinements, data.aggregation, t);
          }
          const newItems = adapter(data);
          dispatch({
            payload: {
              hasMore: data.hasMore,
              items: {
                ...newItems,
                data:
                  pageNumber > 1
                    ? [...state.items.data, ...newItems.data]
                    : newItems.data,
              },
            },
            type: "setItems",
          });
        } else {
          dispatch({ payload: true, type: "setError" });
        }
      })
      .catch((e) => {
        dispatch({ payload: true, type: "setError" });
      });

    // eslint-disable-next-line
  }, [pageNumber, refinements, boundingBox]);

  return {
    error: state.error,
    hasMore: state.hasMore,
    items: state.items,
    loading: state.loading,
  };
}
