import { Marker } from "react-leaflet";

export interface MapState {
  points: any[];
  markers: Marker[];
  selectedItem: any;
  rechercheBounds: boolean;
  loading: boolean;
  fitMapRequested: boolean;
}

type SelectedItem = any;
type Markers = any[];
type Points = any[];

export type Action =
  | { type: "selectItem"; payload: SelectedItem }
  | { type: "setPoints"; payload: Points }
  | { type: "updateMarkers"; payload: Markers }
  | { type: "setLoading"; payload: boolean }
  | { type: "toggleRechercheBounds"; payload: boolean }
  | { type: "fitMapRequested"; payload: boolean };

export const reducer = (state: MapState, { type, payload }: Action) => {
  switch (type) {
    case "selectItem":
      return {
        ...state,
        selectedItem: payload,
      };
    case "updateMarkers":
      return {
        ...state,
        loading: false,
        markers: payload,
      };
    case "setPoints":
      return {
        ...state,
        loading: false,
        points: payload,
      };
    case "setLoading":
      return {
        ...state,
        loading: payload,
      };
    case "toggleRechercheBounds":
      return { ...state, rechercheBounds: payload };
    case "fitMapRequested":
      return {
        ...state,
        fitMapRequested: payload,
        rechercheBounds: false,
        markers: [],
      };
    default:
      return { ...state };
  }
};

export const initialState: MapState = {
  fitMapRequested: true,
  loading: true,
  markers: [],
  points: [],
  rechercheBounds: false,
  selectedItem: null,
};
