import { RefinementsFactory } from "factory/RefinementFactory";

export const Refinements = RefinementsFactory()
  .create()
  .addMultipleValue({
    aggregation: "NbPiecesRange",
    query: "nbPiecesRange",
    values: [],
  })
  .addMultipleValue({ aggregation: "Bu", query: "bus", values: [] })
  .addMultipleValue({
    aggregation: "FamilleBienEnum",
    query: "familleBiens",
    values: [],
  })
  .addMultipleValue({
    aggregation: "TypeAchatEnum",
    query: "typeAchat",
    values: [],
  })
  .addMultipleValue({
    aggregation: "TypeAffaireEnum",
    query: "typeAffaires",
    values: [],
  })
  .addMultipleValue({ aggregation: "none", query: "quartiers", values: [] })
  .addMultipleValue({ aggregation: "none", query: "regions", values: [] })
  .addMultipleValue({ aggregation: "none", query: "villes", values: [] })
  .addMultipleValue({
    aggregation: "none",
    query: "departements",
    values: [],
  })
  .addMultipleValue({
    aggregation: "none",
    query: "constructionYear",
    values: [
      { isRefined: false, label: "avant 2000", value: "[0@2000]" },
      { isRefined: false, label: "2000-2010", value: "[2000@2010]" },
      { isRefined: false, label: "après 2010", value: "[2010+]" },
    ],
  })
  .addValue({ query: "fullText" })
  .addValue({ query: "prixMin" })
  .addValue({ query: "prixMax" })
  .addValue({ query: "surfaceHabitableMin" })
  .addValue({ query: "surfaceHabitableMax" })
  .addValue({ query: "surfaceTerrainMin" })
  .addValue({ query: "surfaceTerrainMax" })
  .addValue({ query: "sortColumn", value: "DateModification" })
  .addValue({ query: "sortAsc", value: false })
  .addValue({ query: "withParking", value: false })
  .addValue({ query: "withElevator", value: false })
  .addValue({ query: "withPool", value: false })
  .addValue({ query: "withSingleStorey", value: false })
  .addValue({ query: "dateMajMin" })
  .addValue({ query: "dateMajMax" })
  .addValue({ query: "dateMiseEnVenteMin" })
  .addValue({ query: "dateMiseEnVenteMax" });
