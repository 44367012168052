import { makeStyles } from "@material-ui/core";
import React from "react";

const usesStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "flex-end",
    padding: "5px",
  },
  padding: {
    padding: "0px 2px 0px 2px",
  },
}));

export const InfoChipsList: React.FC = ({ children }) => {
  const classes = usesStyles();
  return (
    <div className={classes.container}>
      {children &&
        Array.isArray(children) &&
        children.map((child: React.ReactNode, index: number) => (
          <div key={index} className={classes.padding}>
            {child}
          </div>
        ))}
    </div>
  );
};
