import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import { useServices } from "services";

import { CloseButton } from "UI/shared/atoms/buttons/CloseButton";
import { Text16px } from "UI/shared/atoms/typographies/Text16px";
import { Text16pxBold } from "UI/shared/atoms/typographies/Text16pxBold";
import { Campaign } from "services/campagnes/types";
import { IModalService } from "services/modal";
import { CampaignPreview } from "../../atoms/CampaignPreview";
import { CampaignDescriptionSection } from "../../molecules/CampaignDetailsSection/variants/CampaignDescriptionSection";
import { useStyles } from "../../styles/CampaignFooterButtonStyle";
import { CampaignStatusPanel } from "../../molecules/CampaignStatusPanel";
import { CampaignTargetSection } from "../../molecules/CampaignDetailsSection/variants/CampaignTargetSection";
import { CampaignTriggerEventSection } from "../../molecules/CampaignDetailsSection/variants/CampaignTriggerEventSection";

export const CampaignDetailsButton: React.FC<{ campaign: Campaign }> = ({
  campaign,
}) => {
  const { modal } = useServices();
  const content = <CampaignDetailsContent campaign={campaign} modal={modal} />;
  return (
    <Tooltip title="Détails">
      <IconButton onClick={() => modal.show(content)}>
        <SearchIcon />
      </IconButton>
    </Tooltip>
  );
};

export const CampaignDetailsContent: React.FC<{
  campaign: Campaign;
  modal: IModalService;
}> = ({ campaign, modal }) => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();
  return (
    <div style={{ width: "100%" }}>
      <HeaderDetails>
        <div className={classes.headerTitle}>
          <Text16pxBold>{t("Détails")}</Text16pxBold>
          <Text16px>&nbsp;|&nbsp;{campaign.label}</Text16px>
        </div>
        <CloseButton clickHandler={modal.close} />
      </HeaderDetails>
      <ContentDetails campaign={campaign} />
      <FooterDetails>
        <Button variant="outlined" onClick={modal.close}>
          FERMER
        </Button>
      </FooterDetails>
    </div>
  );
};

const HeaderDetails: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.header}>{children}</div>;
};

const ContentDetails: React.FC<{ campaign: Campaign }> = ({ campaign }) => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();
  return (
    <div className={classes.content}>
      <div className={classes.contentBody}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <CampaignPreview pictureUrl={campaign.details?.preview} />
          </Grid>
          <Grid item xs={12} md={5}>
            <div
              className={classes.contentTitle}
              style={{
                borderBottom: "1px solid lightGray",
                marginBottom: "10px",
              }}
            >
              <Text16pxBold>{t("détails").toUpperCase()}</Text16pxBold>
            </div>
            <CampaignStatusPanel
              status={campaign.state}
              type={campaign.campaignType}
              creationDate={new Date(campaign.startDate)}
            />
            <CampaignDescriptionSection content={campaign.description} />
            <CampaignTargetSection content={campaign.details?.target ?? ""} />
            <CampaignTriggerEventSection
              content={campaign.details?.triggerEvent ?? ""}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const FooterDetails: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.footer}>{children}</div>;
};
