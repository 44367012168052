import React, { useState, useEffect, useRef } from "react";
import ReactSelect, { components as ReactSelectComponents } from "react-select";
import { Done } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

/**
 *
 * @typedef {(string|ReactNode)} Option
 */

/**
 * @typedef {object} Props
 * @property {Array.<Option>} options - a list of options
 * @property {boolean} isDisabled - whether or not the select is disabled
 * @property {Option} defaultValue - default value
 * @property {object} containerStyle - style of container
 * @property {object} controlStyle - style of control
 * @property {({option: Option}) => Option} renderOption - custom render option method
 * @property {({options: Option}) => void} onSelect - triggered method when another value is selected
 * @property {({options: Option}) => void} onFocus - triggered method when select is focused
 * @property {({options: Option}) => void} onBlur - triggered method when select is blurred
 */

//  TODO : investigate on this 'issue' where option style can't be override here
const useStyles = makeStyles(() => ({
  option: {
    color: "red",
    fontWeight: "normal",
  },
}));

const Option = (props) => {
  const { isSelected, getStyles } = props;
  const classes = useStyles();

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
      }}
    >
      <span style={{ marginLeft: "5px", width: "40px" }}>
        {isSelected && <Done />}
      </span>
      <ReactSelectComponents.Option
        {...{
          ...props,
          className: classes.option,
          styles: getStyles("clearIndicator", props),
        }}
      />
    </div>
  );
};

const SortSelect = ({
  filters,
  customFilterOption = ({ option }) => option,
  isDisabled = false,
  value,
  onSelect,
  containerStyle,
  controlStyle,
  onFocus,
  onBlur,
}) => {
  const selectRef = useRef();
  const extraStyles = {
    container: (provided) => ({
      ...provided,
      ...containerStyle,
    }),
    control: (provided) => ({
      ...provided,
      ...controlStyle,
      boxShadow: "none",
    }),
    indicatorSeparator: () => ({ display: "none" }),
    option: (provided, state) => ({
      ...provided,
      background: "inherit",
      color: "inherit",
      fontWeight: state.isSelected || state.isFocused ? "bold" : "normal",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontWeight: "bold",
    }),
  };
  const [currentOption, setCurrentOption] = useState(value);
  const handleSelectedValueChanged = (evt) => {
    const optionValue = evt;

    setCurrentOption(optionValue);
    onSelect({ option: optionValue });
  };

  useEffect(() => {
    setCurrentOption(value);
  }, [value]);

  return (
    <ReactSelect
      ref={selectRef}
      // this is a workaround watch issues progress : https://github.com/JedWatson/react-select/pull/3813
      onMenuOpen={() => {
        setTimeout(() => {
          selectRef.current.select.scrollToFocusedOptionOnUpdate = true;
          selectRef.current.select.setState({
            focusedOption: filters.find((option) => option.value === value),
          });
        }, 0);
      }}
      tabSelectsValue={false}
      isSearchable={false}
      onFocus={onFocus}
      onBlur={onBlur}
      components={{ Option }}
      formatOptionLabel={customFilterOption}
      options={filters}
      onChange={handleSelectedValueChanged}
      value={currentOption}
      isDisabled={isDisabled}
      styles={extraStyles}
    />
  );
};

export default SortSelect;
