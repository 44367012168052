import { Grid } from "@material-ui/core";
import React from "react";
import { ProfileOriginType } from "routes/app/contacts/contact/components/models/profileOriginType";
import { ForRentParkingProfileReadDto } from "routes/app/contacts/contact/components/models/Proposal/ForRent/Parking/ForRentParkingProfileReadDto";
import { useTranslation } from "services/l10n/useTranslation";
import { ProfileLocalisation } from "UI/features/profiles/organisms/ProfileLocalisation";
import { ProfileProject } from "UI/features/profiles/organisms/ProfileProject";
import { ProfileStatusCard } from "UI/features/profiles/organisms/ProfileStatusCard";
import { BlocContainer } from "UI/shared/atoms/containers/BlocContainer";
import { Column } from "UI/shared/atoms/containers/Column";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";
import { Row } from "UI/shared/atoms/containers/Row";
import { HorizontalSpace } from "UI/shared/atoms/spacing/HorizontalSpace";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import { Text12pxBold } from "UI/shared/atoms/typographies/Text12pxBold";
import { Text14px } from "UI/shared/atoms/typographies/Text14px";
import { Text14pxBold } from "UI/shared/atoms/typographies/Text14pxBold";
import { LabelledValue } from "UI/shared/molecules/LabelledValue";
import { TitleAndHorizontalDivider } from "UI/shared/molecules/TitleAndHorizontalDivider";
import {
  RentalParkingCharacteristicsSummary,
  Sections,
} from "../../Seller/Parking/RentalParkingCharacteristics";
import { RentalSummary } from "../../Seller/Parking/RentalSummary";

interface Props {
  profileId: string;
  contactId: string;
  readProfile: ForRentParkingProfileReadDto;
}

export const ParkingForRentProfileDetails: React.FC<Props> = ({
  readProfile,
}) => {
  const {
    features,
    archivingDate,
    creationDate,
    lastModificationDate,
    maturity,
    origin,
    reference,
    account,
  } = readProfile;

  const { t } = useTranslation();

  const rentalSummary = RentalSummary.from(features.financialInfo);
  const filteredCharacteristicSections = RentalParkingCharacteristicsSummary.from(
    features,
    t
  ).withoutNC();

  console.log({ filteredCharacteristicSections });

  const sectionKeys = Object.keys(
    filteredCharacteristicSections
  ) as (keyof Sections)[];

  return (
    <PaddingContainer left={16} top={16} right={12}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <ProfileStatusCard
            status={archivingDate ? "Archived" : "Active"}
            creationDate={creationDate || lastModificationDate}
            updateDate={archivingDate || lastModificationDate}
            maturity={maturity}
            origin={origin as ProfileOriginType}
            reference={reference}
          />

          <ProfileProject
            separatorSize={20}
            chipDiameter={56}
            profileAccount={account}
            businessType="Location"
            propertyCategory="Parking"
          />
          <VerticalSpace size={16} />
          <BlocContainer title={t("PRIX")}>
            <Row rowJustify="flex-start">
              <Column itemJustify="flex-end" columnJustify="flex-end">
                {rentalSummary.informations.map((info) => (
                  <Text14px key={`rental-summary-label-${info.label}`}>
                    {t(info.label)}
                  </Text14px>
                ))}
              </Column>
              <HorizontalSpace size={24} />
              <Column itemJustify="flex-start" columnJustify="flex-start">
                {rentalSummary.informations.map((info) => (
                  <Text14pxBold key={`rental-summary-value-${info.label}`}>
                    {info.value}
                  </Text14pxBold>
                ))}
              </Column>
            </Row>
          </BlocContainer>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ProfileLocalisation address={features.address} mapHeight={225} />
          <VerticalSpace size={8} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TitleAndHorizontalDivider
            title="CARACTÉRISTIQUES"
            spaceBetween={8}
            dividerColor={"#8E8E8E"}
          />
          {sectionKeys.map((section) => {
            const filteredSection = filteredCharacteristicSections[section];
            const infos = Object.keys(
              filteredSection
            ) as (keyof Sections[typeof section])[];
            return (
              <>
                <PaddingContainer top={8}>
                  <Text12pxBold>{section}</Text12pxBold>
                </PaddingContainer>
                <BlocContainer>
                  {infos.map((info) => (
                    <LabelledValue
                      key={`characteristics-${info}`}
                      label={t(info)}
                      value={t(filteredSection[info])}
                      size={"14"}
                    />
                  ))}
                </BlocContainer>
              </>
            );
          })}
        </Grid>
      </Grid>
    </PaddingContainer>
  );
};
