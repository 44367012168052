import { Chip, Fab, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import moment from "moment";
import React from "react";
import { formatPrix } from "utils/formatage";

function IsValidNumericValue(objectValeur) {
  return (
    objectValeur.value && objectValeur.value > 0 && objectValeur.value !== ""
  );
}

const ListeCriteres = (props) => {
  const {
    items,
    clearRefinements,
    onChange = (query, values) => {
      console.log(query, values);
    },
  } = props;

  let dateMaj = "";
  let dateMiseEnVente = "";
  let dateVente = "";
  let dateDentree = "";

  const refinements = Object.entries(items);

  function onDelete({ key, label }) {
    if (
      key === "dateMaj" ||
      key === "dateVente" ||
      key === "dateMiseEnVente" ||
      key === "dateDentree"
    ) {
      onChange(items[`${key}Min`].query, "");
      onChange(items[`${key}Max`].query, "");
    } else {
      const refinement = items[key];
      const { ...cpy } = refinement;
      if (refinement.values) {
        if (
          key !== "regions" &&
          key !== "departements" &&
          key !== "villes" &&
          key !== "quartiers"
        ) {
          cpy.values = cpy.values.map((current) => {
            if (label === current.label)
              return { ...current, isRefined: false };
            else return current;
          });
        } else {
          const indexTrouve = refinement.values.findIndex(
            (elt) => elt.label === label
          );
          if (indexTrouve >= 0) cpy.values.splice(indexTrouve, 1);
        }
        onChange(items[key].query, cpy.values);
      } else {
        if (
          key === "withElevator" ||
          key === "withSingleStorey" ||
          key === "withPool" ||
          key === "withParking" ||
          key === "withNotes"
        ) {
          cpy.value = false;
          onChange(items[key].query, cpy.value);
        } else {
          cpy.value = "";
          onChange(items[key].query, cpy.value);
        }
      }
    }
  }

  const renderChip = ({ key, labelToDisplay, label }) => (
    <Chip
      key={`${key}${label}`}
      label={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "0 5px",
            width: "fit-content",
          }}
        >
          <Typography variant="body1" noWrap>
            {labelToDisplay}
          </Typography>
        </div>
      }
      size="small"
      style={{ margin: 3 }}
      onDelete={() => onDelete({ key, label })}
    />
  );

  let refinementsToDisplay = [];
  
  function display(objectKey,label){
      refinementsToDisplay.push(
          renderChip({
              key: objectKey,
              labelToDisplay: label,
          })
      );
  }
  
  refinements.map((refinement) => {
    const objectKey = refinement[0];
    const objectValeur = refinement[1];
    let labelToDisplay = "none";
    if (objectValeur.values) {
      objectValeur.values
        .filter((elt) => elt.isRefined === true)
        .map((elt) => {
          switch (objectKey) {
            case "nbChambresRange":
              labelToDisplay = `${elt.label} ch`;
              break;
            case "nbPiecesRange":
              labelToDisplay = `${elt.label} p`;
              break;
            default:
              labelToDisplay = elt.label;
          }
          refinementsToDisplay.push(
            renderChip({
              key: objectKey,
              label: elt.label,
              labelToDisplay,
            })
          );
          return elt;
        });
    } else {
      switch (objectKey) {
        case "prixMin":
          labelToDisplay = IsValidNumericValue(objectValeur)
            ? `Min : ${formatPrix(objectValeur.value)}`
            : "";
          break;
        case "prixMax":
          labelToDisplay = IsValidNumericValue(objectValeur)
            ? `Max : ${formatPrix(objectValeur.value)}`
            : "";
          break;
        case "surfaceHabitableMin":
          labelToDisplay = IsValidNumericValue(objectValeur)
            ? `Surf. Hab. Min : ${objectValeur.value} m²`
            : "";
          break;
        case "surfaceHabitableMax":
          labelToDisplay = IsValidNumericValue(objectValeur)
            ? `Surf. Hab. Max : ${objectValeur.value} m²`
            : "";
          break;
        case "surfaceTerrainMin":
          labelToDisplay = IsValidNumericValue(objectValeur)
            ? `Surf. Ter. Min : ${objectValeur.value} m²`
            : "";
          break;
        case "surfaceTerrainMax":
          labelToDisplay = IsValidNumericValue(objectValeur)
            ? `Surf. Ter. Max : ${objectValeur.value} m²`
            : "";
          break;
        case "dateEvt":
          labelToDisplay = "";
          break;
        case "topPerformer":
          labelToDisplay = "";
          break;
        case "dateMajMin":
          labelToDisplay = "";
          dateMaj = moment(objectValeur.value).isValid()
            ? `Màj du ${moment(objectValeur.value).format("DD/MM/YYYY")}`
            : "";
          break;
        case "dateMajMax":
          labelToDisplay = "";
          dateMaj = moment(objectValeur.value).isValid()
            ? `${dateMaj} au ${moment(objectValeur.value).format("DD/MM/YYYY")}`
            : "";
          break;
        case "dateDentreeMin":
          labelToDisplay = "";
          dateDentree = moment(objectValeur.value).isValid()
            ? `date d'entrée du ${moment(objectValeur.value).format(
                "DD/MM/YYYY"
              )}`
            : "";
          break;
        case "dateDentreeMax":
          labelToDisplay = "";
          dateDentree = moment(objectValeur.value).isValid()
            ? `${dateDentree} au ${moment(objectValeur.value).format(
                "DD/MM/YYYY"
              )}`
            : "";
          break;
        case "dateMiseEnVenteMin":
          labelToDisplay = "";
          dateMiseEnVente = moment(objectValeur.value).isValid()
            ? `MeV du ${moment(objectValeur.value).format("DD/MM/YYYY")}`
            : "";
          break;
        case "dateMiseEnVenteMax":
          labelToDisplay = "";
          dateMiseEnVente = moment(objectValeur.value).isValid()
            ? `${dateMiseEnVente} au ${moment(objectValeur.value).format(
                "DD/MM/YYYY"
              )}`
            : "";
          break;
        case "dateVenteMin":
          labelToDisplay = "";
          dateVente = moment(objectValeur.value).isValid()
            ? `Vendu du ${moment(objectValeur.value).format("DD/MM/YYYY")}`
            : "";
          break;
        case "dateVenteMax":
          labelToDisplay = "";
          dateVente = moment(objectValeur.value).isValid()
            ? `${dateVente} au ${moment(objectValeur.value).format(
                "DD/MM/YYYY"
              )}`
            : "";
          break;
        default:
          labelToDisplay = objectValeur.value;
      }
      
      switch(true){
          case objectKey === "withParking" && objectValeur.value:
              display(objectKey,"Stationnement");
              break;
          case objectKey === "withElevator" && objectValeur.value:
              display(objectKey,"Ascenseur");
              break;
          case objectKey === "withSingleStorey" && objectValeur.value :
              display(objectKey,"Plain-pied");
              break;
          case objectKey === "withPool" && objectValeur.value:
              display(objectKey,"Piscine");
              break;
          case objectKey === "withNotes" && objectValeur.value:
              display(objectKey,"Avec Notes");
              break;
      }
    

      if (
        labelToDisplay !== "" &&
        ![
          "sortColumn",
          "sortAsc",
          "withParking",
          "withElevator",
          "withSingleStorey",
          "withPool",
          "withNotes",
        ].includes(objectKey)
      )
        refinementsToDisplay.push(
          renderChip({
            key: objectKey,
            label: objectValeur.value,
            labelToDisplay,
          })
        );
    }
    return refinement;
  });

  // traitement particulier des dates range
  if (dateMaj !== "")
    refinementsToDisplay.push(
      renderChip({
        key: "dateMaj",
        label: dateMaj,
        labelToDisplay: dateMaj,
      })
    );
  if (dateMiseEnVente !== "")
    refinementsToDisplay.push(
      renderChip({
        key: "dateMiseEnVente",
        label: dateMiseEnVente,
        labelToDisplay: dateMiseEnVente,
      })
    );
  if (dateVente !== "")
    refinementsToDisplay.push(
      renderChip({
        key: "dateVente",
        label: dateVente,
        labelToDisplay: dateVente,
      })
    );
  if (dateDentree !== "") {
    refinementsToDisplay.push(
      renderChip({
        key: "dateDentree",
        label: dateDentree,
        labelToDisplay: dateDentree,
      })
    );
  }

  return (
    <>
      {refinementsToDisplay.length > 0 ? (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
            marginBottom: 15,
          }}
        >
          {refinementsToDisplay.map((elt) => elt)}
          {refinementsToDisplay.length > 0 && (
            <Fab
              variant="extended"
              size="small"
              color="primary"
              aria-label="delete"
              onClick={clearRefinements}
              style={{
                boxShadow: "none",
                fontSize: "0.61rem",
                height: 24,
                padding: "0px 8px",
              }}
            >
              <DeleteIcon fontSize="small" />
              Effacer
            </Fab>
          )}
        </div>
      ) : (
        <div>&nbsp;</div>
      )}
    </>
  );
};

export default ListeCriteres;
