import { MicroServicesConfiguration } from "services/configuration/configuration";

import { EndpointsProvider } from "./interfaces/EndpointsProvider";

export interface ContactEndpoints {
  get: string;
  contacts: {
    globalSearch: string;
    byPhoneNumber: string;
    byEmailAddress: string;
    contact: {
      get: (id: string) => string;
      consentstatus: (id: string) => string;
      activateContact: (id: string) => string;
      archiveContact: (id: string) => string;
      events: {
        addEventOrGetEvents: (contactId: string) => string;
        updateOrDeleteEvent: (contacId: string, eventId: string) => string;
      };
    };
    all: string;
    export: string;
  };
}

const endpoints: EndpointsProvider<ContactEndpoints> = (
  microServices: MicroServicesConfiguration
) => ({
  contacts: {
    all: `${microServices.tx("crm")}/v1/portfolio/contacts`,
    byEmailAddress: `${microServices.tx(
      "crm"
    )}/v1/portfolio/contacts/byemailaddress`,
    byPhoneNumber: `${microServices.tx(
      "crm"
    )}/v1/portfolio/contacts/byphonenumber`,
    contact: {
      activateContact: (id: string) =>
        `${microServices.tx("crm")}/v1/portfolio/contacts/${id}/activate`,
      archiveContact: (id: string) =>
        `${microServices.tx("crm")}/v1/portfolio/contacts/${id}/archive`,
      consentstatus: (id: string) =>
        `${microServices.tx("crm")}/v1/portfolio/contacts/${id}/consentstatus`,
      events: {
        addEventOrGetEvents: (contactId: string) =>
          `${microServices.tx("crm")}/v1/eventsTracking/${contactId}`,
        updateOrDeleteEvent: (contactId: string, eventId: string) =>
          `${microServices.tx(
            "crm"
          )}/v1/eventsTracking/${contactId}/${eventId}`,
      },
      get: (id: string) =>
        `${microServices.tx("crm")}/v1/portfolio/contacts/${id}`,
    },
    export: `${microServices.tx("crm")}/v1/portfolio/contacts/export`,
    globalSearch: `${microServices.tx(
      "crm"
    )}/v1/portfolio/contacts/globalSearch`,
  },
  get: `${microServices.tx("crm")}/v1/portfolio`,
});
export default endpoints;
