import { Grid, Paper, Typography, useTheme } from "@material-ui/core";
import PlaceIcon from "@material-ui/icons/RoomOutlined";
import { ResetMapButton } from "UI/shared/atoms/buttons/ResetMapButton";
import MapPinIcon from "components/MapX/MapPinIcon";
import L, { LatLng } from "leaflet";
import React, { useCallback, useEffect, useRef } from "react";
import { Map, Marker, Polygon, ScaleControl, TileLayer } from "react-leaflet";
import Wkt from "wicket/wicket-leaflet";

import TitreSection from "../../../../../../../layoutContents/Fiches/components/Details/components/TitreSection";
import { useServices } from "../../../../../../../services";
import { useFrGouvGeocodingService } from "../../../../../../../services/dataSources/geolocServices/useFrGouvGeocodingService";
import { ITheme } from "../../../../../../../theme/ts/main";

const wkt = new Wkt.Wkt();

interface AgentAddressProps {
  agentProAddress: {
    number: string;
    address1: string;
    address2: string;
    zipCode: string;
    city: string;
    country: string;
  };
  agentPosition: LatLng;
  workAreaPolygon?: string;
}

export const AgentAddress: React.FC<AgentAddressProps> = ({
  agentPosition,
  workAreaPolygon,
  agentProAddress,
}) => {
  const {
    l10n: { t },
    authenticationService,
  } = useServices();

  const userBU = authenticationService.getUserData().Instance;

  const map = useRef<Map>(null);
  const theme: ITheme = useTheme();

  const leafletMap = map.current?.leafletElement
    ? map.current.leafletElement
    : null;

  const { position } = useFrGouvGeocodingService({
    addressString: `${agentProAddress.number}, ${agentProAddress.address1} ${agentProAddress.address2} ${agentProAddress.zipCode} ${agentProAddress.city}`,
  });

  let computedPolygon: any = null; // pas de fichier de définition de type pour Wicket

  if (workAreaPolygon) {
    try {
      wkt.read(workAreaPolygon);
      computedPolygon = wkt.toObject()._latlngs;
    } catch (e) {
      console.log(e);
    }
  }

  const resetMap = useCallback(() => {
    if (
      map.current &&
      position.lat !== 0 &&
      position.lng !== 0 &&
      computedPolygon
    ) {
      const allPoints = [...computedPolygon];
      allPoints.push(position);
      map.current.leafletElement.fitBounds(allPoints);
    }
  }, [computedPolygon, position]);

  useEffect(() => {
    resetMap();
  }, [resetMap]);

  // initialisation de la carte
  useEffect(() => {
    if (leafletMap) {
      L.control
        .zoom({
          position: "bottomright",
        })
        .addTo(leafletMap);
    }
  }, [leafletMap]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TitreSection titre={"Adresse"} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={1}>
            <PlaceIcon />
          </Grid>
          <Grid item xs={11}>
            <Typography variant={"subtitle1"}>
              {agentProAddress.number} {agentProAddress.address1}
            </Typography>
            <Typography variant={"subtitle1"}>
              {agentProAddress.address2}
            </Typography>
            <Typography variant={"subtitle1"}>
              {agentProAddress.zipCode}&nbsp;
              {agentProAddress.city.toUpperCase()},&nbsp;
              {agentProAddress.country.toUpperCase()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ position: "relative" }}>
        <Map
          ref={map}
          center={agentPosition}
          zoom={6}
          style={{ height: "400px", width: "100%" }}
          zoomControl={false}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <ScaleControl imperial={false} position={"bottomright"} />
          {computedPolygon && (
            <Polygon
              positions={computedPolygon}
              color={theme.palette.tonique}
            />
          )}
          <Marker
            icon={MapPinIcon(userBU)}
            position={position}
            title={t("Mon adresse professionnelle")}
          />
        </Map>
        {computedPolygon && (
          <Paper
            elevation={1}
            style={{
              alignItems: "center",
              background: "white",
              bottom: 10,
              display: "flex",
              justifyContent: "space-between",
              left: 10,
              padding: 10,
              position: "absolute",
              width: 160,
              zIndex: 900,
            }}
          >
            <div
              style={{
                backgroundColor: `${theme.palette.tonique}80`,
                borderColor: theme.palette.tonique,
                borderRadius: 15,
                borderStyle: "solid",
                borderWidth: 3,
                height: 30,
                width: 30,
              }}
            />
            <Typography variant={"h4"}>Zone de travail</Typography>
          </Paper>
        )}
        <ResetMapButton
          tooltip={t("Recentrer sur ma zone de travail")}
          reset={resetMap}
        />
      </Grid>
    </Grid>
  );
};
