import { IconButton, makeStyles, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import React, { useCallback, useEffect, useReducer } from "react";
import { useSwipeable } from "react-swipeable";

import { ITheme } from "../../theme/ts/main";

const useStyles = makeStyles((theme: ITheme) => ({
  arrows: { fontSize: "2em" },
  arrowsIconButton: {
    padding: 0,
    color: theme.palette.default,
  },
  buttonArrowLeft: {
    height: 24,
    width: 24,
  },
  buttonArrowRight: {
    height: 24,
    width: 24,
  },
  footerContainer: {
    bottom: 20,
    left: 0,
    marginLeft: "auto",
    marginRight: "auto",
    position: "absolute",
    right: 0,
    width: "160px",
    height: "50px",
  },
  picture: {
    // TODO: we might need to calculate the highest size to set keep the good proportions and prevent cropping
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      width: "100vw",
    },
    [theme.breakpoints.up("sm")]: {
      height: "100vh",
      width: "auto",
    },
    position: "absolute",
  },
  picturesContainer: {
    alignItems: "center",
    display: "flex",

    height: "calc(100vh - 70px)",
    //height: "60vh",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
    width: "100vw",
    //width: "80vw",
  },
  root: {
    alignItems: "center",
    display: "flex",
    maxWidth: "100%",
  },
}));

const init = ({ currentPictureIndex, currentPictureUrl }: CarouselState) => ({
  currentPictureIndex,
  currentPictureUrl,
});

interface CarouselState {
  currentPictureIndex: number;
  currentPictureUrl: string;
}

type Action = "goLeft" | "goRight";

const reducer = (
  prevState: CarouselState,
  { action }: { action: Action }
): CarouselState => {
  switch (action) {
    case "goLeft":
      return {
        ...prevState,
        currentPictureIndex: prevState.currentPictureIndex - 1,
      };
    case "goRight":
      return {
        ...prevState,
        currentPictureIndex: prevState.currentPictureIndex + 1,
      };
    default:
      throw new Error("Unknown type");
  }
};

interface CarouselProps {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onChange: (pictureIndex: number) => void;
  urls: string[];
}

export const Carousel: React.FC<CarouselProps> = ({
  onClick,
  onChange,
  urls,
}) => {
  const [{ currentPictureIndex }, dispatch] = useReducer(
    reducer,
    { currentPictureIndex: 0, currentPictureUrl: urls[0] },
    init
  );
  const classes = useStyles();

  const goLeft = useCallback(() => {
    if (currentPictureIndex > 0) {
      dispatch({ action: "goLeft" });
    }
  }, [currentPictureIndex]);
  const goRight = useCallback(() => {
    if (currentPictureIndex + 1 < urls.length) {
      dispatch({ action: "goRight" });
    }
  }, [currentPictureIndex, urls.length]);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      goRight();
    },
    onSwipedRight: () => {
      goLeft();
    },
  });

  useEffect(() => {
    onChange(currentPictureIndex);
  }, [currentPictureIndex, onChange]);

  return (
    <div className={classes.root} onClick={onClick}>
      <div className={classes.picturesContainer} {...handlers}>
        {urls.map((url) => (
          <img
            key={url}
            // TODO : this is an injected label from picture : {url, label}
            alt={"real estate"}
            loading="lazy"
            src={url}
            className={classes.picture}
            style={{
              visibility:
                urls[currentPictureIndex] === url ? "inherit" : "hidden",
            }}
          />
        ))}
      </div>

      <div className={classes.footerContainer}>
        <Paper
          elevation={1}
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            padding: 4,
            height: "100%",
          }}
        >
          <IconButton
            className={classes.arrowsIconButton}
            disabled={currentPictureIndex === 0}
            onClick={goLeft}
          >
            <KeyboardArrowLeft className={classes.arrows} />
          </IconButton>
          <Typography
            onClick={(event) => {
              event.stopPropagation();
            }}
            variant={"h5"}
          >{`${currentPictureIndex + 1} / ${urls.length}`}</Typography>
          <IconButton
            className={classes.arrowsIconButton}
            disabled={currentPictureIndex + 1 === urls.length}
            onClick={goRight}
          >
            <KeyboardArrowRight className={classes.arrows} />
          </IconButton>
        </Paper>
      </div>
    </div>
  );
};

/*
<img
    key={url}
    // TODO : this is an injected label from picture : {url, label}
    alt={"real estate"}
    loading="lazy"
    src={url}
    className={classes.picture}
    style={{
      visibility:
          urls[currentPictureIndex] === url ? "inherit" : "hidden",
    }}
/>*/
