import { Typography, useTheme } from "@material-ui/core";
import { Directions } from "@material-ui/icons";
import React from "react";
import { breakpoint } from "theme/main";

export const DirectionBigMapButton: React.FC<{
  latitude: string;
  longitude: string;
}> = ({ latitude, longitude }) => {
  const {
    palette: { primary },
  } = useTheme();

  return (
    <a
      target={"blank"}
      href={`https://www.google.com/maps/place/${latitude},${longitude}`}
      style={{ color: "white", textDecoration: "none" }}
    >
      <div
        style={{
          alignItems: "center",
          background: primary.main,
          border: "1px solid black",
          borderRadius: "4px",
          cursor: "pointer",
          display: "flex",
          justifyContent: "right",
          marginTop: "10px",
          padding: "10px",
        }}
      >
        <Directions />
        {/* TODO : it might not be the right place, but there's something about this wording*/}
        {breakpoint().smAndUp && (
          <Typography style={{ marginLeft: "5px" }}>{"ITINERAIRE"}</Typography>
        )}
      </div>
    </a>
  );
};
