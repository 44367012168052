export default function download(filename, text) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function downloadImage(filename, text) {
  const image = new Image();
  image.src = text;
  image.style.display = "none";
  document.body.appendChild(image);

  console.log(image);

  const element = document.createElement("a");
  element.setAttribute("href", text);
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
