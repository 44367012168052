import { Text16pxBoldEllipsis } from "UI/shared/atoms/typographies/Text16pxBoldEllipsis";
import React from "react";

import { useServices } from "services";

export interface CitiesListTrailingCaptionProps {
  count: number;
}

export const CitiesListTrailingCaption: React.FC<CitiesListTrailingCaptionProps> = ({
  count,
}) => {
  const {
    l10n: { t },
  } = useServices();
  return count === 0 ? (
    <></>
  ) : (
    <Text16pxBoldEllipsis>{`+${count} ${
      count > 1 ? t("autres zones") : t("autre zone")
    }`}</Text16pxBoldEllipsis>
  );
};
