import React from "react";
import { Typography } from "@material-ui/core";
import {
  ARCHIVED,
  NEW_OR_READ,
} from "../../../services/notifications/notificationStates";

interface EmptyNotificationsProps {
  notificationState: string;
}

const EmptyNotifications: React.FC<EmptyNotificationsProps> = ({
  notificationState,
}) => (
  <>
    {notificationState === NEW_OR_READ && (
      <div>
        <Typography variant={"h4"}>Aucune notification disponible</Typography>
        <Typography>
          Vous pouvez consulter à tout moment les notifications archivées
        </Typography>
      </div>
    )}
    {notificationState === ARCHIVED && (
      <div>
        <Typography variant={"h4"}>Aucune notification archivée</Typography>
      </div>
    )}
  </>
);

export default EmptyNotifications;
