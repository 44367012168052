import { Grid } from "@material-ui/core";
import React, { FC } from "react";

import { PropertyInfoDto } from "../../models/propertyInfoDto";
import { ValidationNumberField } from "../components/ValidationNumberField";
import { ProfileInputProps } from "../InputProps";

export const SaleSizeInput: FC<ProfileInputProps<PropertyInfoDto>> = ({
  value,
  onChange,
  pristine,
  disabled = false,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <ValidationNumberField
          disabled={disabled}
          pristine={pristine}
          label="Surface habitable"
          required={true}
          onChange={(isValid, squareMeter) => {
            onChange({ ...value, squareMeter }, isValid);
          }}
          value={value.squareMeter || 0}
          adornement={"m²"}
          minLimit={1}
          maxLimit={100000}
        />
      </Grid>
    </>
  );
};
