import { Row } from "UI/shared/atoms/containers/Row";
import { SquareStatusIcon } from "UI/shared/atoms/SquareStatusIcon";
import { StatusInfo } from "UI/shared/atoms/StatusInfo";
import React from "react";
import { baseTheme } from "theme/ts/main";

const status: Record<string, string> = {
  ACTIVE: "Active",
  ARCHIVED: "Archived",
  TO_BE_TREATED: "ToBeTreated",
  TRANSFORMED: "Transformed",
};

const colors: Record<string, string> = {
  [status.ARCHIVED]: baseTheme.palette.common.dark,
  [status.ACTIVE]: baseTheme.palette.status.active,
  [status.TO_BE_TREATED]: baseTheme.palette.common.orange,
  [status.TRANSFORMED]: baseTheme.palette.common.dark,
};

export interface StatusProps {
  status: string;
}

export const Status: React.FC<StatusProps> = ({ status }) => {
  return (
    <Row rowAlignItems="center">
      <SquareStatusIcon color={colors[status]} />
      <StatusInfo value={status} marginLeft={10} />
    </Row>
  );
};
