import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import { useServices } from "../../services";

export const CallbackPage = () => {
  const [from, setFrom] = useState<string>();
  const {
    authenticationService,
    trackingService: { sendCustomEventToGoogle },
  } = useServices();

  useEffect(() => {
    authenticationService
      .processSigninResponse()
      .then((response) => {
        sendCustomEventToGoogle("Connexion TX", {
          BU: response.user.Instance,
          email: response.user.name,
        });
        setFrom(response.from);
      })
      .catch((e) => {
        console.log("Callback Page e : ", e);
      });
  }, [authenticationService, sendCustomEventToGoogle]);

  return <>{from && <Redirect to={from} />}</>;
};
