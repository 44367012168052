import React, { createContext, useCallback, useEffect, useState } from "react";
import { useServices } from "services";
import { useFeatureFlags } from "services/featureFlags";
import { useTxHttp } from "services/http";

export interface PigeVisibilityContextProps {
  toggleAdVisibility: (id?: string) => void;
  isAdVisible: (id?: string) => boolean;
}

export const PigeVisibilityContext = createContext<PigeVisibilityContextProps>(
  {} as PigeVisibilityContextProps
);

export const PigeVisibilityContextProvider: React.FC = ({ children }) => {
  const { get, put, del } = useTxHttp();
  const { endpoints } = useServices();
  const [exclusions, setExclusions] = useState<string[]>([]);
  const { Pige } = useFeatureFlags();

  const getExcluded = useCallback(
    () =>
      get<string[]>(endpoints.pige.exclusions()).then((response) =>
        setExclusions(response.data)
      ),
    [endpoints.pige, get]
  );

  const hide = useCallback(
    (annonceId: string) =>
      put<string[]>(endpoints.pige.exclusions(annonceId)).then(getExcluded),
    [endpoints.pige, getExcluded, put]
  );
  const show = useCallback(
    (annonceId: string) =>
      del<string[]>(endpoints.pige.exclusions(annonceId)).then(getExcluded),
    [del, endpoints.pige, getExcluded]
  );

  const isAdVisible = (id?: string) => {
    return !!id && !exclusions.includes(id);
  };

  const toggleAdVisibility = (id?: string) => {
    if (!id) return;
    if (isAdVisible(id)) {
      hide(id).then(getExcluded);
    } else {
      show(id).then(getExcluded);
    }
  };

  useEffect(() => {
    if (Pige) {
      getExcluded();
    }
  }, [Pige, getExcluded]);

  return (
    <PigeVisibilityContext.Provider value={{ isAdVisible, toggleAdVisibility }}>
      {children}
    </PigeVisibilityContext.Provider>
  );
};
