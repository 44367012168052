import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { MatchingProfileRequestDto } from "services/matching/MatchingProfileRequestDto";

import useNotifier from "../../../../../../../../../components/Notifier/useNotifier";
import { useServices } from "../../../../../../../../../services";
import { useProfiles } from "../../../../../../../../../services/profiles";
import { AccountType } from "../../../../models/accountType";
import { ProfileOriginType } from "../../../../models/profileOriginType";
import { ProfileReadDto } from "../../../../models/profilesReadDto";
import { PremisesLocation } from "../../../../models/Proposal/ForSale/Premises/PremisesLocation";
import { PremisesLocationAttractiveness } from "../../../../models/Proposal/ForSale/Premises/PremisesLocationAttractiveness";
import { PremisesType } from "../../../../models/Proposal/ForSale/Premises/premisesType";
import { SalePremisesProfileReadDto } from "../../../../models/Proposal/ForSale/Premises/salePremisesProfileReadDto";
import { SalePremisesProfileWriteDto } from "../../../../models/Proposal/ForSale/Premises/salePremisesProfileWriteDto";
import { SectionTitle } from "../../../components/SectionTitle";
import { ValidationNumberField } from "../../../components/ValidationNumberField";
import { WriteSellerMatchingNumber } from "../../../MatchingNumber/WriteSellerMatchingNumber";
import { SelectMaturity } from "../../../Maturity";
import { NotesInput } from "../../../Notes";
import { SelectPropertyAge } from "../../../Search/Purchase/SelectPropertyAge";
import { Write } from "../../../Write";
import { PremisesAmenitiesSelection } from "../../Features/Premises/PremisesAmenitiesSelection";
import { PremisesForSaleRentedInput } from "../../Features/Premises/PremisesForSaleRented";
import { PremisesLocationAttractivenessSelection } from "../../Features/Premises/PremisesLocationAttractivenessSelection";
import { PremisesLocationSelection } from "../../Features/Premises/PremisesLocationSelection";
import { PremisesParkingInfo } from "../../Features/Premises/PremisesParkingInfo";
import { PremisesProximitiesSelection } from "../../Features/Premises/PremisesProximitiesSelection";
import { PremisesTypeSelection } from "../../Features/Premises/PremisesTypeSelection";
import { SalePlaceInput } from "../../SalePlace";
import { SelectGeneralStateType } from "../../SelectGeneralStateType";
import MatchingAdaptater from "./MatchingAdapter";
import { SaleBudgetPremisesInput } from "./SaleBudgetPremises";
import classes from "./Write.module.css";

interface PremisesValidationState {
  address: boolean;
  financialInfo: boolean;
  propertyInfo: boolean;
  location: boolean;
  locationAttractiveness: boolean;
  totalArea: boolean;
  premisesType: boolean;
}

function updateTotalArea(
  setIsFormValid: (
    value:
      | ((prevState: PremisesValidationState) => PremisesValidationState)
      | PremisesValidationState
  ) => void,
  setPayload: (
    value:
      | ((
          prevState: SalePremisesProfileWriteDto
        ) => SalePremisesProfileWriteDto)
      | SalePremisesProfileWriteDto
  ) => void
) {
  return (isValid: boolean, totalArea?: number) => {
    setIsFormValid((previous) => ({
      ...previous,
      totalArea: isValid,
    }));
    setPayload((prev) => ({
      ...prev,
      features: {
        ...prev.features,
        propertyInfo: {
          ...prev.features.propertyInfo,
          totalArea: totalArea,
        },
      },
    }));
  };
}

function updatePremisesType(
  setIsFormValid: (
    value:
      | ((prevState: PremisesValidationState) => PremisesValidationState)
      | PremisesValidationState
  ) => void,
  setPayload: (
    value:
      | ((
          prevState: SalePremisesProfileWriteDto
        ) => SalePremisesProfileWriteDto)
      | SalePremisesProfileWriteDto
  ) => void
) {
  return (isValid: boolean, premisesType: PremisesType) => {
    setIsFormValid((previous) => ({
      ...previous,
      premisesType: isValid,
    }));
    setPayload((prev) => ({
      ...prev,
      features: {
        ...prev.features,
        propertyInfo: {
          ...prev.features.propertyInfo,
          type: premisesType,
        },
      },
    }));
  };
}

function updateLocationAttractiveness(
  setIsFormValid: (
    value:
      | ((prevState: PremisesValidationState) => PremisesValidationState)
      | PremisesValidationState
  ) => void,
  setPayload: (
    value:
      | ((
          prevState: SalePremisesProfileWriteDto
        ) => SalePremisesProfileWriteDto)
      | SalePremisesProfileWriteDto
  ) => void
) {
  return (
    isValid: boolean,
    locationAttractiveness: PremisesLocationAttractiveness
  ) => {
    setIsFormValid((previous) => ({
      ...previous,
      locationAttractiveness: isValid,
    }));
    setPayload((prev) => ({
      ...prev,
      features: {
        ...prev.features,
        locationAttractiveness: locationAttractiveness,
      },
    }));
  };
}

function updateLocation(
  setIsFormValid: (
    value:
      | ((prevState: PremisesValidationState) => PremisesValidationState)
      | PremisesValidationState
  ) => void,
  setPayload: (
    value:
      | ((
          prevState: SalePremisesProfileWriteDto
        ) => SalePremisesProfileWriteDto)
      | SalePremisesProfileWriteDto
  ) => void
) {
  return (isValid: boolean, location: PremisesLocation) => {
    setIsFormValid((previous) => ({
      ...previous,
      location: isValid,
    }));
    setPayload((prev) => ({
      ...prev,
      features: {
        ...prev.features,
        location: location,
      },
    }));
  };
}

export const PremisesForSaleProfileWrite: FC<{
  contactId: string;
  account: AccountType;
  initial?: SalePremisesProfileReadDto;
  more: boolean;
  showMore: () => void;
  sendUpdatedProfile?: (profile: ProfileReadDto | undefined) => void;
  handleAfterUpdate?: () => void;
}> = ({
  contactId,
  account,
  initial,
  more,
  showMore,
  sendUpdatedProfile,
  handleAfterUpdate,
  children,
}) => {
  const { add, update } = useProfiles(contactId);
  const [payload, setPayload] = useState(create(account, initial));
  const [state, setState] = useState<"none" | "saving" | "saved" | "error">(
    "none"
  );

  const { modal } = useServices();
  const { showSnack } = useNotifier();
  const mode = initial ? "update" : "add";
  const [pristine, setPristine] = useState(true);
  const [isFormValid, setIsFormValid] = useState<PremisesValidationState>({
    address: false,
    financialInfo:
      (payload.features.financialInfo.price || 0) > 0 &&
      (payload.features.financialInfo.agentPurchaserFees || 0) > 0 &&
      (payload.features.financialInfo.agentSellerFees || 0) > 0,
    location: payload.features.location !== "Unknown",
    locationAttractiveness:
      payload.features.locationAttractiveness !== "Unknown",
    premisesType:
      payload.features.propertyInfo.type !== undefined &&
      payload.features.propertyInfo.type !== "Unknown",
    propertyInfo: true,
    totalArea: payload.features.propertyInfo.totalArea! > 0 || false,
  });

  const disabled = state === "saving";
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down("sm"));

  const [matchingCriteria, setMatchingCriteria] = useState<
    MatchingProfileRequestDto | undefined
  >(undefined);

  const onPropertyInfoChangedHandler = (key: string) => (
    isValid: boolean,
    value: any
  ) => {
    setPayload((prev) => ({
      ...prev,
      features: {
        ...prev.features,
        propertyInfo: {
          ...prev.features.propertyInfo,
          [key]: value,
        },
      },
    }));
  };

  useEffect(() => {
    setMatchingCriteria(MatchingAdaptater(payload.features));
  }, [payload]);

  useEffect(() => {
    switch (state) {
      case "none":
        break;
      case "saving":
        if (Object.entries(isFormValid).some(([_, b]) => !b)) {
          setPristine(false);
          setState("none");
        } else {
          (mode === "add"
            ? add("SalePremisesProfile", payload)
            : update(
                "SalePremisesProfile",
                initial!.id,
                payload,
                sendUpdatedProfile
              )
          )
            .then(() => {
              if (handleAfterUpdate) handleAfterUpdate();
              setState("saved");
            })
            .catch(() => setState("error"));
        }
        break;
      case "saved":
        modal.close();
        showSnack({ message: "Le profil est enregistré.", variant: "success" });
        break;
      case "error":
        showSnack({ message: "Une erreur est survenue.", variant: "danger" });
        break;
    }
  }, [state]);

  return (
    <Write
      pending={state === "saving"}
      save={() => setState("saving")}
      mode={mode}
      more={more}
      matching={<WriteSellerMatchingNumber criteria={matchingCriteria} />}
      showMore={showMore}
    >
      <div className={more && !mediaQuery ? classes.container : ""}>
        <div className={more ? classes.itemA : ""}>
          {children}
          <Grid item xs={12}>
            <SelectPropertyAge
              value={payload.features?.propertyInfo?.built || "All"}
              onChange={(built) =>
                setPayload((prev) => ({
                  ...prev,
                  features: {
                    ...prev.features,
                    propertyInfo: {
                      ...prev.features.propertyInfo,
                      built,
                    },
                  },
                }))
              }
              disabled={disabled}
            />
          </Grid>
          {!more && (
            <>
              <SectionTitle title="Quelles sont les caractéristiques ?" />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ValidationNumberField
                    disabled={disabled}
                    pristine={pristine}
                    label="Superficie totale"
                    required={true}
                    onChange={updateTotalArea(setIsFormValid, setPayload)}
                    value={payload.features.propertyInfo.totalArea}
                    adornement={"m²"}
                    minLimit={1}
                    maxLimit={1000000}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <PremisesTypeSelection
                    pristine={pristine}
                    onChange={updatePremisesType(setIsFormValid, setPayload)}
                    value={payload.features.propertyInfo.type}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <PremisesLocationSelection
                    pristine={pristine}
                    onChange={updateLocation(setIsFormValid, setPayload)}
                    value={payload.features.location}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <PremisesLocationAttractivenessSelection
                    pristine={pristine}
                    onChange={updateLocationAttractiveness(
                      setIsFormValid,
                      setPayload
                    )}
                    value={payload.features.locationAttractiveness}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <SalePlaceInput
              value={payload.features?.address}
              onChange={(address, isValid) => {
                setIsFormValid((previous) => ({
                  ...previous,
                  address: isValid,
                }));
                setPayload((prev) => ({
                  ...prev,
                  features: {
                    ...prev.features,
                    address,
                  },
                }));
              }}
              disabled={disabled}
              pristine={pristine}
            />
          </Grid>
        </div>

        <div className={more ? classes.itemB : ""}>
          <Grid item xs={12}>
            <SaleBudgetPremisesInput
              pristine={pristine}
              more={more}
              value={payload.features.financialInfo}
              onChange={(financialInfo, isValid) => {
                setIsFormValid((previous) => ({
                  ...previous,
                  financialInfo: isValid,
                }));
                setPayload((prev) => ({
                  ...prev,
                  features: {
                    ...prev.features,
                    financialInfo,
                  },
                }));
              }}
            />
          </Grid>

          {more && (
            <>
              <SectionTitle title="Quelles sont les caractéristiques ?" />
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <PremisesTypeSelection
                    pristine={pristine}
                    onChange={updatePremisesType(setIsFormValid, setPayload)}
                    value={payload.features.propertyInfo.type}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <PremisesLocationSelection
                    pristine={pristine}
                    onChange={updateLocation(setIsFormValid, setPayload)}
                    value={payload.features.location}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <PremisesLocationAttractivenessSelection
                    pristine={pristine}
                    onChange={updateLocationAttractiveness(
                      setIsFormValid,
                      setPayload
                    )}
                    value={payload.features.locationAttractiveness}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <SelectGeneralStateType
                    value={payload.features.propertyInfo.generalState}
                    onChange={(generalState) => {
                      setPayload((prev) => ({
                        ...prev,
                        features: {
                          ...prev.features,
                          propertyInfo: {
                            ...prev.features.propertyInfo,
                            generalState,
                          },
                        },
                      }));
                    }}
                  />
                </Grid>

                <Grid item xs={6} md={6}>
                  <ValidationNumberField
                    disabled={disabled}
                    pristine={pristine}
                    label="Superficie totale"
                    required={true}
                    onChange={updateTotalArea(setIsFormValid, setPayload)}
                    value={payload.features.propertyInfo.totalArea}
                    adornement={"m²"}
                    minLimit={1}
                    maxLimit={1000000}
                  />
                </Grid>

                <Grid item xs={6} md={6}>
                  <ValidationNumberField
                    disabled={disabled}
                    pristine={pristine}
                    label="Nb de niveaux"
                    required={false}
                    onChange={onPropertyInfoChangedHandler("numberOfLevels")}
                    value={payload.features.propertyInfo.numberOfLevels}
                    minLimit={0}
                    maxLimit={1000000}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <ValidationNumberField
                    disabled={disabled}
                    pristine={pristine}
                    label="Pièce principale"
                    required={false}
                    onChange={onPropertyInfoChangedHandler("mainRoomArea")}
                    value={payload.features.propertyInfo.mainRoomArea}
                    adornement={"m²"}
                    minLimit={0}
                    maxLimit={1000000}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <ValidationNumberField
                    disabled={disabled}
                    pristine={pristine}
                    label="Nb d'espaces"
                    required={false}
                    onChange={onPropertyInfoChangedHandler("lotSize")}
                    value={payload.features.propertyInfo.lotSize}
                    minLimit={0}
                    maxLimit={1000000}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <ValidationNumberField
                    disabled={disabled}
                    pristine={pristine}
                    decimalScale={2}
                    label="Hauteur sous plafond"
                    required={false}
                    onChange={onPropertyInfoChangedHandler("height")}
                    value={payload.features.propertyInfo.height}
                    adornement={"m"}
                    minLimit={0}
                    maxLimit={1000000}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </div>

        <div className={more ? classes.itemC : ""}>
          {more && (
            <>
              <PremisesAmenitiesSelection
                onChange={(amenities) => {
                  setPayload((prev) => ({
                    ...prev,
                    features: {
                      ...prev.features,
                      amenities,
                    },
                  }));
                }}
                value={payload.features.amenities}
              />
              <PremisesProximitiesSelection
                onChange={(proximities) => {
                  setPayload((prev) => ({
                    ...prev,
                    features: {
                      ...prev.features,
                      proximities,
                    },
                  }));
                }}
                value={payload.features.proximities}
              />
              <PremisesParkingInfo
                value={payload.features.parkingInfo}
                onChange={(parkingInfo) =>
                  setPayload((prev) => ({
                    ...prev,
                    features: {
                      ...prev.features,
                      parkingInfo,
                    },
                  }))
                }
              />
              <PremisesForSaleRentedInput
                value={{
                  ...payload.features.rentedInfo,
                  salePrice: payload.features.financialInfo.price || 0,
                }}
                onChange={(value, isValid) => {
                  setIsFormValid((previous) => ({
                    ...previous,
                    parkingRentedInfo: isValid,
                  }));
                  setPayload((prev) => ({
                    ...prev,
                    features: {
                      ...prev.features,
                      rentedInfo: {
                        rent: value.rent,
                        rented: value.rented,
                      },
                    },
                  }));
                }}
              />
            </>
          )}
          <Grid item xs={12}>
            <NotesInput
              value={payload.notes}
              onChange={(notes) => setPayload((prev) => ({ ...prev, notes }))}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectMaturity
              onChange={(maturity) => {
                setPayload((prev) => ({
                  ...prev,
                  maturity,
                }));
              }}
              value={payload.maturity}
              disabled={disabled}
            />
          </Grid>
        </div>
      </div>
    </Write>
  );
};

function create(
  account: AccountType,
  initial?: SalePremisesProfileReadDto
): SalePremisesProfileWriteDto {
  return {
    account,
    creationDate: new Date(),
    features: initial
      ? initial.features
      : {
          address: {},
          amenities: [],
          financialInfo: {
            agentPurchaserFees: 0,
            agentSellerFees: 0,
            price: 0,
          },
          location: "Unknown",
          locationAttractiveness: "Unknown",
          parkingInfo: {
            isClosed: false,
            isCovered: false,
            isOutdoor: false,
            numberOfParking: 0,
          },
          propertyInfo: {},
          proximities: [],
          rentedInfo: { rent: 0, rented: false },
        },
    maturity: initial?.maturity || "None",
    notes: initial?.notes || "",
    origin: initial?.origin
      ? (initial?.origin as ProfileOriginType)
      : "TXProfile",
    reference: initial?.reference || "",
  };
}
