import { MultiCheckbox } from "UI/shared/molecules/MultiCheckbox";
import React from "react";

import { LandConnection } from "../../../../models/Proposal/Features/Land/landConnection";
import { SubSectionTitle } from "../../../components/SectionTitle";
import { ProfileInputProps } from "../../../InputProps";

export const LandConnectionsSelection: React.FC<ProfileInputProps<
  LandConnection[]
>> = ({ onChange, value }) => {
  const options = ["Sanitation", "Water", "Electricity", "Phone"];
  return (
    <>
      <SubSectionTitle
        title="Raccordements existants"
        mode={"non obligatoire"}
      />
      <MultiCheckbox
        translationPrefix={"landConnection"}
        options={options}
        selectedOptions={value}
        onChange={onChange}
      />
    </>
  );
};
