import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import { useServices } from "services";

const useStyles = makeStyles(() => ({
  background: {
    backgroundColor: "white",
    border: "1px solid #E3564C",
  },
  chipLabel: {
    color: "#E3564C",
    fontSize: "0.9rem",
    fontWeight: "bold",
  },
}));

export default function AgenciesUnwanted() {
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles();

  return (
    <Chip
      label={t("Agence s'abstenir")}
      size="small"
      className={classes.background}
      classes={{ label: classes.chipLabel }}
    />
  );
}
