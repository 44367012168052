import Grid from "@material-ui/core/Grid";
import ReactEcharts from "echarts-for-react";
import PropType from "prop-types";
import React from "react";

import PaperX from "../../../../../../components/PaperX";
import { useServices } from "../../../../../../services";
import {
  getOptionRadarActifs,
  getOptionRadarVendus,
} from "../../logic/chartsOptions";

export default function RadarBiens({ theme, data, chartsStyle }) {
  const {
    l10n: { t },
  } = useServices();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <PaperX>
          {data && (
            <ReactEcharts
              option={getOptionRadarActifs(t, theme, data)}
              style={chartsStyle}
            />
          )}
        </PaperX>
      </Grid>
      <Grid item xs={12} md={6}>
        <PaperX>
          {data && (
            <ReactEcharts
              option={getOptionRadarVendus(t, theme, data)}
              style={chartsStyle}
            />
          )}
        </PaperX>
      </Grid>
    </Grid>
  );
}

RadarBiens.propTypes = {
  chartsStyle: PropType.object,
  data: PropType.object,
  t: PropType.func,
  theme: PropType.object,
  userData: PropType.object,
};
