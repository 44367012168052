import Typography from "@material-ui/core/Typography";
import React from "react";

interface PriceCaptionProps {
  price: number;
}

export const PriceCaption: React.FC<PriceCaptionProps> = ({ price }) => {
  return (
    <Typography variant="h2" noWrap>{`${price
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} €`}</Typography>
  );
};
