import { Chip } from "@material-ui/core";
import React from "react";
import { MultipleText } from "components/RefinementField/types";
import { RefinementChipProps } from "../RefinementChip";

export const MultipleTextChip = ({
  value,
  format,
  onChange,
}: RefinementChipProps<MultipleText>) => (
  <>
    {value.value
      .filter((choice) => choice.isRefined)
      .map((choice) => (
        <Chip
          key={choice.value}
          style={{ marginRight: 6, marginBottom: 6 }}
          label={format(choice.label)}
          size="small"
          onDelete={() =>
            onChange({
              kind: "multiple-text",
              value: value.value.map((_) => ({
                ..._,
                isRefined: _.value === choice.value ? false : _.isRefined,
              })),
            })
          }
        />
      ))}
  </>
);
