import { AxiosResponse } from "axios";
import React, { createContext, useCallback, useContext } from "react";

import { useTxHttp } from "../http";
import { useServices } from "../index";
import { PagedResultDto } from "./dto/PagedResultDto";
import { ProfileItemDto, ReadModelProfileDto } from "./dto/ReadModelProfileDto";

interface IReadModelProfilesContext {
  getAll: (
    params: any
  ) => Promise<AxiosResponse<PagedResultDto<ProfileItemDto>>>;
  getById: (
    profileId: string,
    contactId: string
  ) => Promise<AxiosResponse<ReadModelProfileDto | undefined>>;
}

export const ReadModelProfilesContext = createContext<
  IReadModelProfilesContext
>(null as any);

export const ReadModelProfilesContextProvider: React.FC = (props) => {
  const { get } = useTxHttp();
  const { endpoints } = useServices();

  const getAll = useCallback(
    (params: any) =>
      get<PagedResultDto<ProfileItemDto>>(
        `${endpoints.profiles.allAgentProfiles}`,
        params
      ),
    [endpoints.profiles, get]
  );

  const getById = useCallback(
    (profileId: string, contactId: string) =>
      get<ReadModelProfileDto>(
        `${endpoints.profiles.byId(profileId, contactId)}`
      ),
    [endpoints.profiles, get]
  );

  return (
    <ReadModelProfilesContext.Provider
      value={{
        getAll,
        getById,
      }}
    >
      {props.children}
    </ReadModelProfilesContext.Provider>
  );
};

export const useReadModelProfiles = (): IReadModelProfilesContext => {
  const { getAll, getById } = useContext(ReadModelProfilesContext);

  return {
    getAll,
    getById,
  };
};
