import { MultiCheckbox } from "UI/shared/molecules/MultiCheckbox";
import React from "react";

import { LandAmenityType } from "../../../../models/Proposal/Features/Land/landAmenityType";
import { SubSectionTitle } from "../../../components/SectionTitle";
import { ProfileInputProps } from "../../../InputProps";

export const LandAmenitiesSelection: React.FC<ProfileInputProps<
  LandAmenityType[]
>> = ({ onChange, value }) => {
  const options = ["Sported", "Fencing", "SwimmingPool", "Drilling"];
  return (
    <>
      <SubSectionTitle title="Terrain" mode={"non obligatoire"} />
      <MultiCheckbox
        translationPrefix={"amenity"}
        options={options}
        selectedOptions={value}
        onChange={onChange}
      />
    </>
  );
};
