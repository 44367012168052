import SendIcon from "@material-ui/icons/EmailOutlined";
import { LinkWithTooltip } from "UI/shared/molecules/LinkWithTooltip";
import React from "react";
import { useServices } from "services";

export interface EmailLinkProps {
  value: string;
  textSize?: "small" | "large";
}
export const EmailLink: React.FC<EmailLinkProps> = ({ value, textSize }) => {
  const { l10n } = useServices();

  return (
    <>
      {value && value.length > 0 && (
        <LinkWithTooltip
          tooltip={l10n.t("Envoyer un mail")}
          href={`mailto:${value}`}
          icon={SendIcon}
          content={value}
          textSize={textSize}
        />
      )}
    </>
  );
};
