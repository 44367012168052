import { makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Switch from "@material-ui/core/Switch";
import React from "react";

export interface GreenSwitchProps {
  checked?: boolean;
  onChange: () => void;
  value?: boolean;
}

const useStyles = makeStyles(() => ({
  checked: {},
  switchBase: {
    "&$checked": {
      color: green[500],
    },
    "&$checked + $track": {
      backgroundColor: green[300],
    },
    color: "lightgrey",
  },
  track: {},
}));

export const GreenSwitch: React.FC<GreenSwitchProps> = ({
  checked,
  onChange,
  value,
}) => {
  const classes = useStyles();
  return (
    <Switch
      classes={classes}
      checked={checked}
      onChange={onChange}
      value={value}
    />
  );
};
