import React from "react";

import { AmenityType } from "../../../../models/amenityType";
import { AnnexDto } from "../../../../models/annexDto";
import { ExposureType } from "../../../../models/exposureType";
import { FurnitureType } from "../../../../models/furnitureType";
import { GeneralStateType } from "../../../../models/generalStateType";
import { JoinOwnershipType } from "../../../../models/JoinOwnershipType";
import { MaturityType } from "../../../../models/maturityType";
import { ParkingsDto } from "../../../../models/partSummariesDto";
import { ProfileAddressDto } from "../../../../models/profileAddressDto";
import { PropertyInfoDto } from "../../../../models/propertyInfoDto";
import { ForRentHouseProfileWriteDto } from "../../../../models/Proposal/ForRent/House/forRentHouseProfileWriteDto";
import { RentHouseFinancialInfoDto } from "../../../../models/Proposal/ForRent/House/rentHouseFinancialInfoDto";
import { SummariesDto } from "../../../../models/summariesDto";

export const furnitureUpdater: (
  furniture: FurnitureType
) => React.SetStateAction<ForRentHouseProfileWriteDto> = (furniture) => (
  prev
) => ({
  ...prev,
  features: { ...prev.features, furniture },
});

export const placeUpdater: (
  address: ProfileAddressDto
) => React.SetStateAction<ForRentHouseProfileWriteDto> = (address) => (
  prev
) => ({
  ...prev,
  features: {
    ...prev.features,
    address,
  },
});

export const budgetUpdater: (
  financialInfo: RentHouseFinancialInfoDto
) => React.SetStateAction<ForRentHouseProfileWriteDto> = (financialInfo) => (
  prev
) => ({
  ...prev,
  features: {
    ...prev.features,
    financialInfo,
  },
});

export const yearBuiltUpdater: (
  yearBuilt: number
) => React.SetStateAction<ForRentHouseProfileWriteDto> = (yearBuilt) => (
  prev
) => ({
  ...prev,
  features: {
    ...prev.features,
    propertyInfo: {
      ...prev.features.propertyInfo,
      buildingInfo: {
        ...prev.features.propertyInfo.buildingInfo,
        yearBuilt,
      },
    },
  },
});

export const generalStateUpdater: (
  generalState: GeneralStateType
) => React.SetStateAction<ForRentHouseProfileWriteDto> = (generalState) => (
  prev
) => ({
  ...prev,
  features: {
    ...prev.features,
    propertyInfo: {
      ...prev.features.propertyInfo,
      generalState,
    },
  },
});

export const exposureUpdater: (
  exposure: ExposureType
) => React.SetStateAction<ForRentHouseProfileWriteDto> = (exposure) => (
  prev
) => ({
  ...prev,
  features: {
    ...prev.features,
    propertyInfo: {
      ...prev.features.propertyInfo,
      exposure,
    },
  },
});

export const sizeUpdater: (
  propertyInfo: PropertyInfoDto
) => React.SetStateAction<ForRentHouseProfileWriteDto> = (propertyInfo) => (
  prev
) => ({
  ...prev,
  features: {
    ...prev.features,
    propertyInfo,
  },
});

export const summariesUpdater: (
  summaries: SummariesDto
) => React.SetStateAction<ForRentHouseProfileWriteDto> = (summaries) => (
  prev
) => ({
  ...prev,
  features: {
    ...prev.features,
    parts: { ...prev.features.parts, summaries },
  },
});

export const joinOwnershipUpdater: (
  joinOwnership: JoinOwnershipType
) => React.SetStateAction<ForRentHouseProfileWriteDto> = (joinOwnership) => (
  prev
) => ({
  ...prev,
  features: {
    ...prev.features,
    propertyInfo: {
      ...prev.features.propertyInfo,
      joinOwnership,
    },
  },
});

export const amenitiesUpdater: (
  amenities: AmenityType[]
) => React.SetStateAction<ForRentHouseProfileWriteDto> = (amenities) => (
  prev
) => ({
  ...prev,
  features: {
    ...prev.features,
    amenities,
  },
});

export const parkingsUpdater: (
  parkings: ParkingsDto
) => React.SetStateAction<ForRentHouseProfileWriteDto> = (parkings) => (
  prev
) => ({
  ...prev,
  features: {
    ...prev.features,
    parts: {
      ...prev.features.parts,
      summaries: {
        ...prev.features.parts.summaries,
        parkings,
      },
    },
  },
});

export const annexesUpdater: (
  annexes: AnnexDto[]
) => React.SetStateAction<ForRentHouseProfileWriteDto> = (annexes) => (
  prev
) => ({
  ...prev,
  features: {
    ...prev.features,
    parts: {
      ...prev.features.parts,
      annexes,
    },
  },
});

export const notesUpdater: (
  notes: string
) => React.SetStateAction<ForRentHouseProfileWriteDto> = (notes) => (prev) => ({
  ...prev,
  notes,
});

export const maturityTypeUpdater: (
  maturity: MaturityType
) => React.SetStateAction<ForRentHouseProfileWriteDto> = (maturity) => (
  prev
) => ({
  ...prev,
  maturity,
});
