import { makeStyles } from "@material-ui/core";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import React from "react";
import { Gender } from "services/portfolio/types";
import { EmailLink } from "UI/shared/molecules/EmailLink";
import { PhoneLink } from "UI/shared/molecules/PhoneLink";
import { ContactAvatar } from "../../molecules/ContactAvatar";
import { ContactFullName } from "../../molecules/ContactFullName";

export interface ProfileContactProps {
  gender?: Gender;
  firstName?: string;
  lastName?: string;
  mainEmailAddress?: string;
  mainPhoneNumber?: string;
  withAvatar?: boolean;
}

const useStyles = makeStyles(() => ({
  contactInfosContainer: {
    marginLeft: (props: { withAvatar: boolean }) => (props.withAvatar ? 16 : 0),
    width: "100%",
  },
  profileContactContainer: {
    display: "flex",
    width: "100%",
  },
}));

export const ProfileContact: React.FC<ProfileContactProps> = ({
  gender,
  firstName,
  lastName,
  mainEmailAddress,
  mainPhoneNumber,
  withAvatar = true,
}) => {
  const classes = useStyles({ withAvatar });
  return (
    <div className={classes.profileContactContainer}>
      {withAvatar && (
        <ContactAvatar firstName={firstName} lastName={lastName} />
      )}
      <div className={classes.contactInfosContainer}>
        <div>
          <ContactFullName
            gender={gender}
            firstName={firstName}
            lastName={lastName}
          />
          <PhoneLink
            value={
              parsePhoneNumberFromString(
                mainPhoneNumber || ""
              )?.formatInternational() || ""
            }
          />
          <EmailLink value={mainEmailAddress || ""} />
        </div>
      </div>
    </div>
  );
};
