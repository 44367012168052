import Conseiller from "components/Conseiller";
import ConteneurCarte from "components/ConteneurCarte";
import React from "react";

const BienConseiller = (props) => {
  const { bien, t, source } = props;
  return (
    <ConteneurCarte>
      <Conseiller
        conseiller={bien.conseillerPrincipal}
        bu={bien.bu}
        t={t}
        source={source}
      />
    </ConteneurCarte>
  );
};

export default BienConseiller;
