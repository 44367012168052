import Typography from "@material-ui/core/Typography";
import React from "react";

const LABEL = "Pas d’acquéreur potentiel dans mes contacts sur ce bien";

export const NoAgentAcquirers: React.FC = () => {
  return (
    <Typography variant="body1" style={{ marginBottom: 10, marginTop: 30 }}>
      {LABEL}
    </Typography>
  );
};
