import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/SaveOutlined";
import { makeStyles } from "@material-ui/styles";
import React, { FC, ReactElement, useEffect } from "react";
import { ITheme } from "theme/ts/main";

import { useServices } from "../../../../../../services";

export const Write: FC<{
  mode: "add" | "update";
  more: boolean;
  showMore: () => void;
  pending: boolean;
  save?: () => void;
  matching?: any;
}> = ({ matching, mode, pending, more, children, save, showMore }) => {
  const { l10n, modal } = useServices();
  useEffect(() => {
    if (more) {
      modal.resize("xl");
    } else {
      modal.resize("sm");
    }
  }, [more]);

  return (
    <>
      <DialogTitle>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">
            {mode === "add"
              ? l10n.t("Nouveau profil")
              : l10n.t("Modification du profil")}
          </Typography>
          <IconButton onClick={() => modal.close()}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent dividers>{children}</DialogContent>
      <Actions
        pending={pending}
        showMore={showMore}
        more={more}
        save={save}
        matching={matching || <></>}
      />
    </>
  );
};

const useStyles = makeStyles((theme: ITheme) => ({
  ///
  boutonRadio: {
    color: "red",
  },

  button: {
    margin: theme.spacing(1),
  },

  buttonProgress: {
    color: theme.palette.tonique,
    left: "50%",
    marginLeft: -12,
    marginTop: -12,
    position: "absolute",
    top: "50%",
  },

  buttonWrapper: {
    margin: 0,
    position: "relative",
  },
}));

const Actions: FC<{
  save?: () => void;
  showMore: () => void;
  more: boolean;
  pending: boolean;
  matching: ReactElement;
}> = ({ matching, pending, more, showMore, save }) => {
  const classes = useStyles();
  const { l10n, modal } = useServices();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <DialogActions>
      <Grid container justify="space-between" alignItems={"center"}>
        <Grid item xs={12}>
          {matching}
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div className={classes.buttonWrapper}>
              {!more ? (
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.button}
                  onClick={showMore}
                >
                  {l10n.t(mobile ? "détail" : "Fiche détaillée")}
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.button}
                  onClick={modal.close}
                >
                  {l10n.t("Annuler")}
                </Button>
              )}
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={save}
                disabled={!save}
              >
                <SaveIcon />
                {l10n.t("Enregistrer")}
              </Button>
              {pending && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </DialogActions>
  );
};
