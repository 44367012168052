import { EmailSupportDto } from "services/office365/useSendEmailToSupport";
import { BrowserInfos } from "./utils/getBrowserInfos";

interface Dictionary<T> {
  [Key: string]: T;
}

const liste = (_liste: Dictionary<string>) => {
  let response = "";
  for (const key in _liste) {
    if (Object.prototype.hasOwnProperty.call(_liste, key)) {
      response = `${response} \r\n ${key} : ${_liste[key]}`;
    }
  }
  return `${response}\r\n`;
};

interface AppContextInfos {
  pathname: string;
  agentLoggedIn: string;
}

interface ContactSupportProps {
  appInfos: AppContextInfos;
  browserInfos: BrowserInfos;
  screenshot: string;
  message: string;
  send: (emailSupport: EmailSupportDto) => Promise<boolean>;
}

export const contactSupport = ({
  appInfos,
  browserInfos,
  screenshot,
  message,
  send,
}: ContactSupportProps) => {
  const _emailToSend: EmailSupportDto = {
    captureEcran: screenshot,
    message: `Message du conseiller : \r\n \r\n ${message} \r\n \r\n --------------------------- \r\n \r\n Informations application : \r\n ${liste(
      (appInfos as unknown) as Dictionary<string>
    )} \r\n Informations Navigateur : \r\n ${liste(
      (browserInfos as unknown) as Dictionary<string>
    )}`,
    objet: "TremplinX - demande d'assistance",
  };
  return send(_emailToSend);
};
