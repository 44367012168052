import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { Launch } from "@material-ui/icons";
import React from "react";
import { PublicationSite } from "services/piges/adapters/historiquePrixBienPigeAdapter";

interface AdPublicationSitesProps {
  publicationSites: PublicationSite[];
}

export const AdPublicationSites: React.FC<AdPublicationSitesProps> = ({
  publicationSites,
}) => {
  const openLink = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <Table aria-label="simple table">
      <TableBody>
        {publicationSites?.map((row, index) => (
          <TableRow key={index}>
            <TableCell align="left">{row.nom}</TableCell>
            <TableCell align="right">
              <Launch
                style={{ cursor: "pointer" }}
                onClick={() => openLink(row.url)}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
