import React from "react";

import { useServices } from "../../../../../../../../services";
import {
  ConstructionYears,
  YearOfConstruction,
} from "../../../models/constructionYears";
import { ComplementIcon } from "../../components/RadioChip";
import { SubSectionTitle } from "../../components/SectionTitle";
import { SelectChip } from "../../SelectChip";

const yearOfConstructionOptions: YearOfConstruction[] = [
  "Before2000",
  "Between2000and2010",
  "After2010",
];

export interface YearOfConstructionProps {
  values: ConstructionYears;
  onChange: (value: ConstructionYears) => void;
}

export const SelectYearOfConstruction: React.FC<YearOfConstructionProps> = ({
  values,
  onChange,
}) => {
  const {
    l10n: { t },
  } = useServices();
  const _onChange = (value: YearOfConstruction, checked: boolean) => {
    if (checked) {
      onChange([...values, value]);
    } else {
      const newValues = [...values];
      const index = newValues.indexOf(value);
      if (index > -1) {
        newValues.splice(index, 1);
      }
      onChange(newValues);
    }
  };

  return (
    <>
      <SubSectionTitle title="Date de construction :" mode="non obligatoire" />
      {yearOfConstructionOptions.map((option: YearOfConstruction) => (
        <SelectChip
          key={option}
          icon={
            <ComplementIcon label={t(`buildAge.${option}`)} checked={false} />
          }
          checkedIcon={
            <ComplementIcon label={t(`buildAge.${option}`)} checked={true} />
          }
          value={option}
          onChange={(value, checked) =>
            _onChange(value as YearOfConstruction, checked)
          }
          checked={values.includes(option)}
        />
      ))}
    </>
  );
};
