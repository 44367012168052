import { ForRentParkingFinancialInfo } from "./../../../../../../../../routes/app/contacts/contact/components/models/Proposal/ForRent/Parking/ForRentParkingFinancialInfoDto";
import { formatPrix } from "utils/formatage";

const RENT_EXCLUDING_CHARGES = "Loyer HC";
const RENT_INCLUDING_CHARGES = "Loyer TCC";
const RENT_CHARGES = "Provision mensuelle de charges";

const rentalInformationLabels = <const>[
  RENT_CHARGES,
  RENT_EXCLUDING_CHARGES,
  RENT_INCLUDING_CHARGES,
];

type RentalInformationLabel = typeof rentalInformationLabels[number];

interface RentalInformation {
  value: string;
  label: RentalInformationLabel;
}

export class RentalSummary {
  informations: RentalInformation[] = [];
  with(label: RentalInformationLabel, value: number = 0): RentalSummary {
    this.informations.push({
      value: formatPrix(value),
      label,
    });

    return this;
  }
  static from(financialInfo: ForRentParkingFinancialInfo): RentalSummary {
    const rentIncludingCharges =
      (financialInfo.rentChargesExcluded || 0) + (financialInfo.charges || 0);

    return new RentalSummary()
      .with("Loyer HC", financialInfo.rentChargesExcluded)
      .with("Provision mensuelle de charges", financialInfo.charges)
      .with("Loyer TCC", rentIncludingCharges);
  }
}
