/* eslint-disable react/prop-types */
import {
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Map from "components/MapX/Map";
import { flattenGeolocRefinementsValues } from "components/MapX/Map/utils/flattenGeolocRefinementsValues";
import TitrePage from "components/TitrePage";
import BiensDisplayMode from "layoutContents/Biens/components/BiensDisplayMode";
import ContextualSideBar from "layoutContents/Biens/components/ContextualSideBar";
import { createPropertiesMarkers } from "layoutContents/Biens/components/MapComponents/createPropertiesMarkers";
import Searchbar from "layoutContents/Biens/components/Searchbar";
import RenderHits from "layoutContents/components/HitsContainer/renderHits";
import { pageSizeFromMode } from "layoutContents/components/HitsContainer/utils";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import routesNames from "router/routesNames";
import { useServices } from "services";
import { useTxHttp } from "services/http";
import obtenirParIdAdapter from "services/rapprochement/Rapprochement/BiensEnVente/ObtenirParId/obtenirParIdAdapter";
import obtenirParIdCarteAdapter from "services/rapprochement/Rapprochement/BiensEnVente/ObtenirParIdCarte/obtenirParIdCarteAdapter";
import rechercheCarteAdapter from "services/rapprochement/Rapprochement/BiensEnVente/RechercheCarte/rechercheCarteAdapter";
import RechercheGridOuListeAdapter from "services/rapprochement/Rapprochement/BiensEnVente/RechercheGridOuListe/RechercheGridOuListeAdapter";
import useInfiniteScroll from "services/useInfiniteScroll";
import useItemsSearch from "services/useItemsSearch";
import { breakpoint } from "theme/main";
import { ErrorPanel } from "UI/shared/templates/Panels/ErrorPanel";
import { worldBoundingBox } from "utils/formatage";

import {
  handleMapConfigSaveBuilder,
  handleMapInitializationBuilder,
  onMapSelectItemFactory,
  resetPageNumberBuilder,
  setDisplayModeBuilder,
} from "../utils/commonLogic";
import { filterOptions } from "../utils/filterOptions";
import {
  computeDetailedRealtyURLFactory,
  computedHitsStyles,
  computedMapPaneBreakpoint,
  computedStateBoundingBox,
  computedTotalItemsTitle,
  DEFAULT_MAP_CENTER,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_ZOOM_LEVEL,
  isSmallList,
  isSmallScreenMap,
} from "../utils/formatter";
import { getScrollToTopButton } from "../utils/getScrollToTopButton";
import { getToggleHitsPaneButton } from "../utils/getToggleHitsPaneButton";
import {
  clearRefinementsBuilder,
  handleRefinementsFromParameters,
  onUpdateLocalisationsBuilder,
  onUpdateRefinementsBuilder,
  onUpdateSortingBuilder,
} from "../utils/refinements";
import { reducer } from "./logic/reducer";
import { Refinements } from "./models/refinements";
import { useStyles } from "./style";

const SNAPSHOT_KEY = "Snapshot State Biens En Vente";
const PAGE_BASE_URL = `${routesNames.app}${routesNames.biens}${routesNames.biensEnVente}`;

const initialState = {
  boundingBox: worldBoundingBox,
  center: DEFAULT_MAP_CENTER,
  displayDetailedRealty: false,
  displayMode: "list",
  drawer: false,
  firstIntersection: true,
  hit: obtenirParIdAdapter(),
  loading: false,
  mapPoints: [],
  page: DEFAULT_PAGE_NUMBER,
  prixMax: null,
  prixMin: null,
  rechercheBounds: false,
  refinements: Refinements,
  shouldUpdateMapBounds: true,
  smAndDown: breakpoint().smAndDown,
  zoom: DEFAULT_ZOOM_LEVEL,
};

export const BiensDisponibles = (props) => {
  const history = useHistory();

  const handleFilterOptions = filterOptions(history.location.pathname);

  const { params, mode } = useParams();
  const classes = useStyles();

  const mobile = useMediaQuery("(max-width: 768px)");

  const [hideHitPane, setHideHitPane] = useState(false);
  const toggleHitPane = () => {
    setHideHitPane(!hideHitPane);
  };

  const {
    endpoints,
    l10n: { t },
  } = useServices();

  const { get } = useTxHttp();

  async function fetchPropertiesForSaleMapData() {
    dispatch({ payload: true, type: "setLoading" });
    const refinementsParams = {
      ...state.refinements.createParams(state.rechercheBounds),
      ...state.boundingBox,
    };
    const points = await get(
      endpoints.bienEnVente.rechercheCarte,
      refinementsParams
    );
    return rechercheCarteAdapter(points.data);
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const containerHeight = useMemo(() => window.innerHeight - 150, []);

  useEffect(() => {
    if (state.displayMode === "map")
      fetchPropertiesForSaleMapData().then((responsePoints) => {
        dispatch({ payload: responsePoints.data, type: "setMapPoints" });
      });
  }, [state.refinements, state.boundingBox, state.displayMode]);

  const resetPageNumber = useCallback(resetPageNumberBuilder(dispatch), []);

  const onUpdateLocalisations = useCallback(
    onUpdateLocalisationsBuilder(state, dispatch, props, PAGE_BASE_URL),
    [state.refinements, state.displayMode, props.history]
  );

  const onUpdateRefinements = useCallback(
    onUpdateRefinementsBuilder(state, dispatch, props, PAGE_BASE_URL),
    [state.refinements, state.displayMode, props.history]
  );
  const onUpdateSorting = useCallback(
    onUpdateSortingBuilder(state, dispatch, props, PAGE_BASE_URL),
    [props.history, state.displayMode, state.refinements]
  );

  const clearRefinements = useCallback(
    clearRefinementsBuilder(state, dispatch, props, PAGE_BASE_URL),
    []
  );

  const setDisplayMode = useCallback(
    setDisplayModeBuilder(state, dispatch, props, PAGE_BASE_URL),
    [props.history, state.refinements]
  );

  const setOpenDrawer = useCallback(() => {
    dispatch({ type: "toggleDrawer" });
  }, []);

  const handleDisplayOfDetailedRealty = ({ id, print = false }) => {
    history.replace(
      computeDetailedRealtyURLFactory(
        `${routesNames.app}${routesNames.biens}${routesNames.bienEnVente}`
      )({ id, print })
    );
  };

  const handleMapConfigSave = handleMapConfigSaveBuilder(
    state,
    dispatch,
    SNAPSHOT_KEY
  );

  const handleMapInitialization = handleMapInitializationBuilder(dispatch);

  useEffect(() => {
    const size = () => dispatch({ type: "setSmAndDown" });
    window.addEventListener("resize", size);
    handleRefinementsFromParameters(
      params,
      mode,
      Refinements,
      dispatch,
      SNAPSHOT_KEY
    );

    return function cleanup() {
      window.removeEventListener("resize", size);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // hook pour chargement des biens
  const { error, hasMore, loading, items } = useItemsSearch({
    adapter: (res) => RechercheGridOuListeAdapter.toBO(res),
    boundingBox: computedStateBoundingBox(state),
    pageNumber: state.page,
    pageSize: pageSizeFromMode.get(state.displayMode).size,
    rechercheBounds: state.rechercheBounds,
    refinements: state.refinements,
    resetPageNumber,
    url: endpoints.bienEnVente.rechercheGridOuListe,
  });

  const CustomSearchbar = (
    <Searchbar
      displayMode={state.displayMode}
      onChange={onUpdateRefinements}
      onClickMore={() => {
        dispatch({ type: "toggleDrawer" });
      }}
      onUpdateLocalisations={onUpdateLocalisations}
      prixMin={state.refinements.prixMin}
      prixMax={state.refinements.prixMax}
      refinements={state.refinements.toObject()}
      t={t}
    />
  );

  if (isSmallList(window.innerWidth, state.displayMode))
    setDisplayMode("gallery");

  const handleChangePage = useCallback(
    function () {
      dispatch({ payload: state.page + 1, type: "changePage" });
    },
    [state.page]
  );

  const setFirstIntersection = useCallback(function (intersection) {
    dispatch({ payload: intersection, type: "firstIntersection" });
  }, []);

  const { firstRef, triggerRef } = useInfiniteScroll({
    handleChangePage,
    hasMore,
    loading,
    setFirstIntersection,
  });

  // ====================================

  const hitsRef = React.useRef(null);
  let hitsToDisplay = !isSmallScreenMap(state) ? (
    <Grid
      style={computedHitsStyles(state.displayMode, containerHeight)}
      item
      xs
      ref={hitsRef}
    >
      <RenderHits
        onHitClick={handleDisplayOfDetailedRealty}
        onDetailPageURL={computeDetailedRealtyURLFactory(
          `${routesNames.app}${routesNames.biens}${routesNames.bienEnVente}`
        )}
        filterOptions={handleFilterOptions(t)}
        onUpdateSorting={onUpdateSorting}
        clearRefinements={clearRefinements}
        containerHeight={containerHeight}
        displayMode={state.displayMode}
        firstRef={firstRef}
        triggerRef={triggerRef}
        loading={loading}
        mode={"biensEnVente"}
        onUpdateRefinements={onUpdateRefinements}
        items={items}
        refinements={state.refinements}
        searchbar={CustomSearchbar}
        smAndDown={state.smAndDown}
        setOpenDrawer={setOpenDrawer}
      />
    </Grid>
  ) : (
    <></>
  );

  const setBoundingBox = useCallback(({ boundingBox, rechercheBounds }) => {
    dispatch({
      payload: { boundingBox, rechercheBounds },
      type: "updateBoundingBox",
    });
  }, []);

  if (error)
    return (
      <ErrorPanel
        title={"Biens Disponibles"}
        text={"Serveur non disponible."}
      />
    );

  return (
    <div className={classes.root}>
      <TitrePage
        categorie={t("Biens")}
        page={t("Biens disponibles")}
        nbResultats={computedTotalItemsTitle(t, items.total)}
        filePath={"/static/markdowns/biensEnVente.md"}
        Composant={() => (
          <BiensDisplayMode
            onChange={setDisplayMode}
            selected={state.displayMode}
          />
        )}
      />
      <Grid container spacing={2}>
        {state.displayMode === "map" && (
          <Grid
            item
            xs={12}
            md={computedMapPaneBreakpoint(hideHitPane)}
            className={classes.toggleSidePaneButtonContainer}
          >
            <Hidden mdUp>{CustomSearchbar}</Hidden>
            <Map
              points={state.mapPoints}
              onPopupClick={handleDisplayOfDetailedRealty}
              onMarkerClick={onMapSelectItemFactory(
                endpoints.bienEnVente.rechercheParIdCarte,
                get,
                t,
                obtenirParIdCarteAdapter
              )}
              onMapConfigSave={handleMapConfigSave}
              onMapInitialization={handleMapInitialization}
              zoom={state.zoom}
              sendBoundingBoxToParent={setBoundingBox}
              center={state.center}
              toggleBoundsSearchLabel={`Voir les biens autour de ma zone`}
              selectedLocations={flattenGeolocRefinementsValues({
                refinements: state.refinements,
              })}
              loading={state.loading}
              bottomLeftPanel={<MapLegend />}
              createMarkers={createPropertiesMarkers}
              fullscreen={hideHitPane}
            />
            {getToggleHitsPaneButton(
              hideHitPane,
              toggleHitPane,
              mobile,
              classes
            )}
          </Grid>
        )}
        {!hideHitPane && hitsToDisplay}
      </Grid>
      {getScrollToTopButton(
        state.firstIntersection,
        state.displayMode,
        classes,
        hitsRef.current
      )}
      <ContextualSideBar
        biensTotal={items.total}
        drawer={state.drawer}
        setOpenDrawer={setOpenDrawer}
        refinements={state.refinements}
        clearRefinements={clearRefinements}
        t={t}
        onUpdateRefinements={onUpdateRefinements}
        onUpdateLocalisations={onUpdateLocalisations}
        classes={classes}
        mode={"biensEnVente"}
      />
    </div>
  );
};

const MapLegend = () => {
  const theme = useTheme();
  return (
    <Typography
      variant="h4"
      style={{
        color: theme.palette.default,
      }}
    >
      Localisation dans un rayon de 650m.
    </Typography>
  );
};
