import { LabelledValue } from "UI/shared/molecules/LabelledValue";
import React, { FC } from "react";

export interface PremisesProps {
  value?: boolean;
}
export const Premises: FC<PremisesProps> = ({ value }) => {
  return (
    <LabelledValue label={"Avec les murs"} value={value ? "Oui" : "Non"} />
  );
};
