import { Typography } from "@material-ui/core";
import React, { ReactElement } from "react";

export interface TitleWithIconProps {
  icon: ReactElement;
  title: string;
}

export const TitleWithIcon: React.FC<TitleWithIconProps> = ({
  icon,
  title,
}) => {
  return (
    <div style={{ display: "flex", paddingBottom: 2 }}>
      <div style={{ marginRight: "5px" }}>{icon}</div>
      <Typography variant="h4">{title}</Typography>
    </div>
  );
};
