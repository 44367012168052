import { Typography } from "@material-ui/core";
import {
  DetailedEntityTabsLayout,
  Tab,
} from "components/Fiche/DetailedEntityTabsLayout";
import TitrePage from "components/TitrePage";
import React from "react";
import { useParams } from "react-router-dom";
import routesNames from "router/routesNames";
import obtenirConseillerParIdAdapter from "services/conseillers/dto/Conseiller/ObtenirParId/obtenirConseillerParIdAdapter";
import { useFeatureFlags } from "services/featureFlags";

import { ErrorPanel } from "UI/shared/templates/Panels/ErrorPanel";
import { useTabs } from "hooks/useTabs";
import { useServices } from "services";
import useAdsDefaultSearchLocationsSettingsCenter from "services/adsDefaultSearchLocations";
import CarteConseiller from "./components/CarteConseiller";
import MenuSettings from "./components/Parametres/components/menu";
import Profil from "./components/Profil";
import { useConseiller } from "./request";

type TabName = "identite" | "parameters";

export const Conseiller: React.FC = () => {
  const {
    l10n: { t },
  } = useServices();

  const { Notifications } = useFeatureFlags();

  const { tabName } = useParams<{ tabName: TabName }>();
  const { status, adaptedData: conseiller } = useConseiller({
    adapter: obtenirConseillerParIdAdapter,
  });
  const {
    adsDefaultSearchLocations,
    set,
  } = useAdsDefaultSearchLocationsSettingsCenter();

  const tabs: Tab[] = [];

  const { onSelectedTabChanged, activeTabIndex } = useTabs<TabName>(
    tabs,
    tabName,
    (tabValue) =>
      `${routesNames.app}${routesNames.conseiller}${routesNames.monProfil}/${tabValue}`
  );

  switch (status) {
    case "resolved":
      tabs.push({
        content: <Profil conseiller={conseiller} />,
        helpFilePath: "/static/markdowns/Profile/profile.md",
        titre: t("PROFIL"),
        value: "identite",
      });

      if (Notifications) {
        tabs.push({
          content: (
            <MenuSettings
              conseiller={{
                ...conseiller,
                adsDefaultSearchLocations,
                setAdsDefaultSearchLocations: set,
              }}
            />
          ),
          helpFilePath: "/static/markdowns/Profile/parameters.md",
          titre: t("PARAMETRES"),
          value: "parameters",
        });
      }
      break;
    case "rejected":
      return (
        <ErrorPanel
          title={"Données non disponibles."}
          text={"Nous n'avons pas reçu les données de profil."}
        />
      );
    // i.e. 'pending'
    default:
      return <Typography variant="h2">Loading ...</Typography>;
  }

  if (window.modeDemo && tabs[1]) tabs.pop();

  return (
    <DetailedEntityTabsLayout
      composantsBarreGauche={[
        {
          composant: <CarteConseiller conseiller={conseiller} t={t} />,
        },
      ]}
      titre={
        <TitrePage
          categorie="Conseiller"
          page={"Mon profil"}
          nbResultats=""
          filePath={tabs[activeTabIndex].helpFilePath}
        />
      }
      tabs={tabs}
      tabActiveIndex={activeTabIndex}
      onSelectedTabChanged={onSelectedTabChanged}
    />
  );
};
