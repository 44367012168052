import { makeStyles, SvgIcon, Tooltip, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import IconAnchorAction from "components/IconAnchorAction/index";
import React, { SyntheticEvent } from "react";
import { useServices } from "services";
import download from "utils/createAndDownloadFile";
import { buColors } from "utils/formatage";

import createVCard from "./createVCard";

const adapter = (item: any) => ({
  birthday: item.birthday || "",
  facebook: item.facebook || "",
  firstName: item.firstName || "",
  lastName: item.lastName || "",
  middleName: item.middleName || "",
  namePrefix: item.namePrefix || "",
  note: item.note || "",
  organization: item.organization || "",
  title: item.title || "",
  url: item.url || "",
  urlPhoto: item.urlPhoto || "",
  workEmail: item.workEmail || "",
  workPhone: item.workPhone || "",
});

export const downloadVCard = ({
  item,
  nameId = false,
}: {
  item: { firstName: string; lastName: string; id: string };
  nameId: boolean;
}): void => {
  let fileName = `${item.firstName}-${item.lastName}`;
  if (nameId) fileName = `${item.id}`;
  download(`${fileName}.vcf`, createVCard(adapter(item)));
};

const VCardIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
      >
        <g>
          <rect fill="none" height="24" width="24" y="0" />
        </g>
        <g>
          <g>
            <rect height="1.5" width="4" x="14" y="12" />
            <rect height="1.5" width="4" x="14" y="15" />
            <path d="M20,7h-5V4c0-1.1-0.9-2-2-2h-2C9.9,2,9,2.9,9,4v3H4C2.9,7,2,7.9,2,9v11c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V9 C22,7.9,21.1,7,20,7z M11,7V4h2v3v2h-2V7z M20,20H4V9h5c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2h5V20z" />
            <circle cx="9" cy="13.5" r="1.5" />
            <path d="M11.08,16.18C10.44,15.9,9.74,15.75,9,15.75s-1.44,0.15-2.08,0.43C6.36,16.42,6,16.96,6,17.57V18h6v-0.43 C12,16.96,11.64,16.42,11.08,16.18z" />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};

const vcardActionButtonStyles = makeStyles((_) => ({
  icon: { height: "15px", width: "auto" },
}));

export const VCardActionButton: React.FC<{
  conseiller: { bu: string };
  exportVCard: () => void;
}> = ({ conseiller, exportVCard }) => {
  const {
    l10n: { t },
  } = useServices();
  const classes = vcardActionButtonStyles();
  return (
    <div>
      <IconAnchorAction
        action={"click"}
        icon={<VCardIcon className={classes.icon} />}
        tooltip={t("Exporter la vCard")}
        placement={"bottom"}
        couleur={(buColors as any)[conseiller.bu]}
        onClick={exportVCard}
      >
        <Typography variant="body2" noWrap>
          vCard
        </Typography>
      </IconAnchorAction>
    </div>
  );
};

const vcardButtonStyles = makeStyles((_) => ({
  container: { paddingTop: "3px" },
}));

const VCardButton: React.FC<{
  item: { firstName: string; lastName: string; id: string };
  nameId: boolean;
  tooltipPlacement?: "bottom" | "right";
  withLabel?: boolean;
}> = ({
  item,
  nameId = false,
  tooltipPlacement = "right",
  withLabel = false,
}) => {
  function onSaveVCard(e: SyntheticEvent) {
    e.stopPropagation();
    e.preventDefault();
    downloadVCard({ item, nameId });
  }
  const {
    l10n: { t },
  } = useServices();
  const classes = vcardButtonStyles();
  return (
    <div className={classes.container} onClick={onSaveVCard}>
      <Tooltip title={"Exporter la vCard"} placement={tooltipPlacement}>
        <IconButton style={{ cursor: "pointer" }}>
          <VCardIcon />
        </IconButton>
      </Tooltip>
      {withLabel && (
        <span style={{ paddingTop: "6px" }}>{t("ExportVCardButton")}</span>
      )}
    </div>
  );
};

export default VCardButton;
