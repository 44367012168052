import React from "react";
import { BudgetRentalDto } from "routes/app/contacts/contact/components/models/budgetRentalDto";
import { ConstructionYears } from "../../../../models/constructionYears";
import { FurnitureType } from "../../../../models/furnitureType";
import { MaturityType } from "../../../../models/maturityType";
import { PlaceDto } from "../../../../models/placeDto";
import { CheckedCriteria } from "../../../../models/Search/rentHouseCriteriaDto";
import { RentHouseProfileWriteDto } from "../../../../models/Search/rentHouseProfileWriteDto";
export type FormValidity = {
  budget: boolean;
  homeSize: boolean;
  lotSize: boolean;
  places: boolean;
};

const furnitureUpdater: (
  furniture: FurnitureType
) => React.SetStateAction<RentHouseProfileWriteDto> = (furniture) => (
  prev
) => ({
  ...prev,
  criteria: {
    ...prev.criteria,
    furniture,
  },
});

const constructionYearsUpdater: (
  constructionYers: ConstructionYears
) => React.SetStateAction<RentHouseProfileWriteDto> = (constructionYears) => (
  prev
) => ({
  ...prev,
  criteria: {
    ...prev.criteria,
    constructionYears,
  },
});

const placesUpdater: (
  places: PlaceDto[]
) => React.SetStateAction<RentHouseProfileWriteDto> = (places) => (prev) => ({
  ...prev,
  criteria: { ...prev.criteria, places },
});

const formValidityUpdater: (
  key: keyof FormValidity,
  isValid: boolean
) => React.SetStateAction<FormValidity> = (key, isValid) => (prev) => ({
  ...prev,
  [key]: isValid,
});

const budgetUpdater: (
  budget: BudgetRentalDto
) => React.SetStateAction<RentHouseProfileWriteDto> = (budget) => (prev) => ({
  ...prev,
  criteria: { ...prev.criteria, budget },
});

const homeSizeUpdater: (homeSize: {
  min?: number;
  max?: number;
}) => React.SetStateAction<RentHouseProfileWriteDto> = (homeSize) => (
  prev
) => ({
  ...prev,
  criteria: { ...prev.criteria, homeSize },
});

const lotSizeUpdater: (lotSize: {
  min?: number;
  max?: number;
}) => React.SetStateAction<RentHouseProfileWriteDto> = (lotSize) => (prev) => ({
  ...prev,
  criteria: { ...prev.criteria, lotSize },
});

const minRoomsUpdater: (
  minRooms: number
) => React.SetStateAction<RentHouseProfileWriteDto> = (minRooms) => (prev) => ({
  ...prev,
  criteria: { ...prev.criteria, minRooms },
});

const minBeedRoomsUpdater: (
  minBedRooms: number
) => React.SetStateAction<RentHouseProfileWriteDto> = (minBedRooms) => (
  prev
) => ({
  ...prev,
  criteria: { ...prev.criteria, minBedRooms },
});

const checkedCriteriaUpdater: (
  key: CheckedCriteria
) => React.SetStateAction<RentHouseProfileWriteDto> = (key) => (prev) => ({
  ...prev,
  criteria: {
    ...prev.criteria,
    [key]: !prev.criteria[key],
  },
});

const maturityTypeUpdater: (
  maturity: MaturityType
) => React.SetStateAction<RentHouseProfileWriteDto> = (maturity) => (prev) => ({
  ...prev,
  criteria: { ...prev.criteria, maturity },
});

export const useChanges = (
  setPayload: (value: React.SetStateAction<RentHouseProfileWriteDto>) => void,
  setFormValidity: (value: React.SetStateAction<FormValidity>) => void
) => {
  const onFurnitureChange = React.useCallback(
    (furniture: FurnitureType) => {
      setPayload(furnitureUpdater(furniture));
    },
    [setPayload]
  );

  const onConstructionYearsChange = React.useCallback(
    (constructionYears: ConstructionYears) => {
      setPayload(constructionYearsUpdater(constructionYears));
    },
    [setPayload]
  );

  const onPlacesChange = React.useCallback(
    (places: PlaceDto[], isValid: boolean) => {
      setFormValidity(formValidityUpdater("places", isValid));
      setPayload(placesUpdater(places));
    },
    [setPayload, setFormValidity]
  );

  const onBudgetChange = React.useCallback(
    (budget: BudgetRentalDto, isValid: boolean) => {
      setFormValidity(formValidityUpdater("budget", isValid));
      setPayload(budgetUpdater(budget));
    },
    [setPayload, setFormValidity]
  );

  const onHomeSizeChange = React.useCallback(
    (homeSize: { min?: number; max?: number }, isValid: boolean) => {
      setFormValidity(formValidityUpdater("homeSize", isValid));
      setPayload(homeSizeUpdater(homeSize));
    },
    [setPayload, setFormValidity]
  );

  const onLotSizeChange = React.useCallback(
    (lotSize: { min?: number; max?: number }, isValid: boolean) => {
      setFormValidity(formValidityUpdater("lotSize", isValid));
      setPayload(lotSizeUpdater(lotSize));
    },
    [setPayload, setFormValidity]
  );

  const onMinRoomsChange = React.useCallback(
    (minRooms: number) => {
      setPayload(minRoomsUpdater(minRooms));
    },
    [setPayload]
  );

  const onMinBedRoomsChange = React.useCallback(
    (minBedRooms: number) => {
      setPayload(minBeedRoomsUpdater(minBedRooms));
    },
    [setPayload]
  );

  const onCheckedCriteriaChange = React.useCallback(
    (key: CheckedCriteria) => {
      setPayload(checkedCriteriaUpdater(key));
    },
    [setPayload]
  );

  const onNotesChange: (notes: string) => void = React.useCallback(
    (notes) => {
      setPayload((prev) => ({ ...prev, notes }));
    },
    [setPayload]
  );

  const onMaturityChange = React.useCallback(
    (maturity: MaturityType) => {
      setPayload(maturityTypeUpdater(maturity));
    },
    [setPayload]
  );

  return {
    onPlacesChange,
    onFurnitureChange,
    onBudgetChange,
    onCheckedCriteriaChange,
    onConstructionYearsChange,
    onHomeSizeChange,
    onLotSizeChange,
    onMaturityChange,
    onNotesChange,
    onMinBedRoomsChange,
    onMinRoomsChange,
  };
};
