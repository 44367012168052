import { Grid } from "@material-ui/core";
import React, { useState } from "react";

import menuMockup from "../../menuParametrableMockup.json";
// les composants disponibles
import TableauDeBord from "../affichage/tableaudebord";
import AgendaSettings from "../agenda";
import NotificationSettingsCenter from "../notifications/NotificationSettingsCenter";
import NotificationSettingsCenterProvider from "../notifications/NotificationSettingsCenterProvider";
import { PigeSettings } from "../pige/PigeSettings";
import RapprochementAcquereur from "../rapprochements/rapprochementAcquereur";
import RapprochementBien from "../rapprochements/rapprochementBien";
import { Option } from "./Option";

const MenuSettings = ({ conseiller }) => {
  const enabledMenuItems = menuMockup.filter((_) => _.enabled);
  const [optionSelected, setOptionSelected] = useState(enabledMenuItems[0].id);

  const handleOptionClick = (_optionSelected) =>
    setOptionSelected(_optionSelected);

  const referentielComposants = [
    {
      composant: <TableauDeBord conseiller={conseiller} />,
      id: "tableauDeBord",
    },
    {
      composant: <AgendaSettings conseiller={conseiller} />,
      id: "agenda",
    },
    {
      composant: <PigeSettings conseiller={conseiller} />,
      id: "pige",
    },
    {
      composant: <RapprochementBien conseiller={conseiller} />,
      id: "rapprochementBien",
    },
    {
      composant: <RapprochementAcquereur conseiller={conseiller} />,
      id: "rapprochementAcquereur",
    },
    {
      composant: (
        <NotificationSettingsCenterProvider>
          <NotificationSettingsCenter />
        </NotificationSettingsCenterProvider>
      ),
      id: "notifications",
    },
  ];

  const composantsIds = menuMockup.find(
    (_option) => _option.id === optionSelected
  ).composants;

  const composantsAAfficher = referentielComposants.reduce((acc, current) => {
    if (composantsIds.includes(current.id)) acc.push(current);
    return acc;
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4} md={2}>
        {enabledMenuItems.map((_option) => (
          <Option
            key={_option.id}
            option={_option}
            onClick={() => handleOptionClick(_option.id)}
            optionSelected={optionSelected === _option.id}
          />
        ))}
      </Grid>
      <Grid item xs={12} sm={8} md={10}>
        <Grid container spacing={1}>
          {composantsAAfficher.map((_composant) => (
            <Grid item xs={12} key={_composant.id}>
              {_composant.composant}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MenuSettings;
