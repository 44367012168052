import React from "react";
import { RouteComponentProps, Switch, useRouteMatch } from "react-router-dom";
import { PrivateRoute } from "services/routing/PrivateRoute";

import { BienEnVente } from "./biens/bien-en-vente/BienEnVente";
import { BienVendu } from "./biens/bien-vendu";
import { BiensDisponibles } from "./biens/biens-disponibles";
import { BiensVendus } from "./biens/biens-vendus";
import { MesBiensEnVente } from "./biens/mes-biens";
import { MonBienEnVente } from "./biens/mon-bien-en-vente";
import { Pige } from "./biens/pige";
import { Campagnes } from "./campagnes/pages/CampaignsListPage";
import { Conseiller } from "./conseiller";
import { PortfolioPage } from "./contacts";
import { FicheContact } from "./contacts/contact";
import { Dashboard } from "./dashboard";
import { Conseillers } from "./mon-reseau/conseillers";
import { NotificationsCenter } from "./notificationsCenter";
import { ProfilesFeatures } from "./profiles";
import { ProfileDetailsPage } from "./profiles/pages/ProfileDetailsPage";
import { RealEstates } from "./real-estates";
import { RechercheGlobale } from "./recherche-globale";
import { Reporting } from "./reporting";
import { RequestForInformationsContainer } from "./request-for-informations";

export const AppRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {routes.map((route) => {
        return (
          <PrivateRoute
            key={route.path}
            path={`${path}${route.path}`}
            component={route.component}
            analytics={route.analytics}
          />
        );
      })}
    </Switch>
  );
};

export const routes: {
  path: string;
  component: React.FC<RouteComponentProps>;
  analytics?: (params: Record<string, string>) => string;
}[] = [
  {
    analytics: (): string => `Reporting Dashboard`,
    component: Dashboard,
    path: "/dashboard",
  },
  { component: Reporting, path: "/reporting" },
  {
    analytics: (): string => `Recherche Globale`,
    component: RechercheGlobale,
    path: "/recherche-globale/:fulltext",
  },
  {
    analytics: (params: Record<string, string>): string =>
      `Conseillers mode ${params.mode}`,
    component: Conseillers,
    path: "/mon-reseau/conseillers/:mode/:params",
  },
  {
    analytics: (): string => `Mon Profil`,
    component: Conseiller,
    path: "/conseiller/monProfil/:tabName",
  },
  { component: Pige, path: "/biens/pige/:id/details/view" },
  { component: Pige, path: "/biens/pige" },
  {
    analytics: (params: Record<string, string>): string =>
      `Biens Disponibles mode ${params.mode}`,
    component: BiensDisponibles,
    path: "/biens/biens-disponibles/:mode/:params",
  },
  {
    analytics: (params: Record<string, string>): string =>
      `Biens Disponibles mode ${params.mode}`,
    component: RealEstates,
    path: "/biens/biens-disponibles-new",
  },
  {
    analytics: (): string => "Fiche Bien En Vente",
    component: BienEnVente,
    path: "/biens/bien-en-vente/:id/:tab/:display",
  },
  {
    analytics: (params: Record<string, string>): string =>
      `Biens Vendus mode ${params.mode}`,
    component: BiensVendus,
    path: "/biens/biens-vendus/:mode/:params",
  },
  {
    analytics: (): string => "Fiche Bien Vendu",
    component: BienVendu,
    path: "/biens/bien-vendu/:id/:tab/:display",
  },
  {
    analytics: (params: Record<string, string>): string =>
      `Mes Biens En Vente mode ${params.mode}`,
    component: MesBiensEnVente,
    path: "/biens/mes-biens/:mode/:params",
  },
  {
    analytics: (): string => "Fiche Mon Bien En Vente",
    component: MonBienEnVente,
    path: "/biens/mon-bien-en-vente/:id/:tab/:display",
  },

  {
    analytics: (): string => "Fiche Contact",
    component: FicheContact,
    path: "/contacts/contact/:id/:tab/:display",
  },
  {
    analytics: (): string => "Contacts",
    component: PortfolioPage,
    path: "/contacts/:slug",
  },
  {
    analytics: (): string => "Marketing Distribué",
    // eslint-disable-next-line react/display-name
    component: () => <Campagnes />,
    path: "/marketing-distribue",
  },
  {
    analytics: (): string => "Marketing Distribué - stats",
    // eslint-disable-next-line react/display-name
    component: () => <Campagnes kind="List" />,
    path: "/marketing-distribue-stats",
  },
  {
    analytics: (): string => "Centre de notifications",
    component: NotificationsCenter,
    path: "/notifications/:tab?",
  },
  {
    analytics: (): string => "Demandes de renseignements",
    component: RequestForInformationsContainer,
    path: "/demandes-renseignements",
  },
  {
    analytics: (): string => "Fiche profil",
    component: ProfileDetailsPage,
    path: "/profils/contact/:contactId/profil/:id/:tab/:display",
  },
  {
    analytics: (): string => "Profils",
    component: ProfilesFeatures,
    path: "/profils/:slug",
  },
];
