import { Grid } from "@material-ui/core";
import { BlocContainer } from "UI/shared/atoms/containers/BlocContainer";
import React, { FC } from "react";
import { ProfileReadDto } from "routes/app/contacts/contact/components/models/profilesReadDto";
import { RentPremisesCriteriaDto } from "routes/app/contacts/contact/components/models/Search/rentPremisesCriteriaDto";
import { RentPremisesProfileReadDto } from "routes/app/contacts/contact/components/models/Search/rentPremisesProfileReadDto";
import { ProfilePlaces } from "UI/features/profiles/organisms/ProfilePlaces";
import { ProfileProject } from "UI/features/profiles/organisms/ProfileProject";
import { ProfileStatusCard } from "UI/features/profiles/organisms/ProfileStatusCard";
import { Furniture } from "UI/features/profiles/organisms/Search/Furniture";
import { MoreInfos } from "UI/features/profiles/organisms/Search/MoreInfos";
import { useServices } from "services";
import { L10nService } from "services/l10n";

import { RentalBudget } from "../../../../../organisms/Search/RentalBudget";

export interface SearchRentalPremisesProfileDetailsProps {
  readProfile: ProfileReadDto;
}
export const SearchRentalPremisesProfileDetails: FC<SearchRentalPremisesProfileDetailsProps> = ({
  readProfile,
}) => {
  const { l10n } = useServices();
  const profile = readProfile as RentPremisesProfileReadDto;
  const moreInformations = more(l10n, profile.criteria);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={4}>
        <ProfileStatusCard
          status={profile.archivingDate ? "Archived" : "Active"}
          creationDate={profile.archivingDate || profile.lastModificationDate}
          updateDate={profile.creationDate || profile.lastModificationDate}
          maturity={profile.criteria.maturity}
          origin={profile.origin}
          reference={profile.reference}
        />
        <ProfileProject
          profileAccount={profile.account}
          businessType={"Location"}
          propertyCategory={"Premises"}
        />
        <BlocContainer paddingTop={20}>
          <Furniture value={profile.criteria.furniture} />
        </BlocContainer>
        <RentalBudget budget={profile.criteria.budget} />
      </Grid>
      <Grid item xs={12} lg={8}>
        {profile.criteria.places && (
          <ProfilePlaces places={profile.criteria.places} />
        )}
        <MoreInfos
          title={"Informations supplémentaires"}
          value={moreInformations}
        />
      </Grid>
    </Grid>
  );
};

function more(
  { t, formatSurfacesRange, formatRoomsRange }: L10nService,
  criteria: RentPremisesCriteriaDto
) {
  return [
    {
      label: t("Surface habitable"),
      value: formatSurfacesRange(criteria.homeSize.min, criteria.homeSize.max),
    },
    {
      label: t("Surface terrain"),
      value: formatSurfacesRange(criteria.lotSize.min, criteria.lotSize.max),
    },
    {
      label: t("Pièces"),
      value: formatRoomsRange(criteria.minRooms),
    },
  ];
}
