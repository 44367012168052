import {
  FormControlLabel,
  Paper,
  Typography,
  useTheme,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { ChangeEventHandler, useCallback } from "react";
import { ITheme } from "theme/ts/main";
import { BorderedContainer } from "UI/shared/atoms/containers/BorderedContainer";
import { ToniqueCheckbox } from "UI/shared/atoms/inputs/ToniqueCheckbox";
import {
  RealEstatePropertyCard,
  RealEstatePropertyCardProps,
} from "../RealEstatePropertyCard";

export interface CheckableRealEstatePropertyCardProps {
  id: string;
  isChecked: boolean;
  isShared: boolean;
  realEstateProps: RealEstatePropertyCardProps;
  handleCheck: (id: string, checked: boolean) => void;
}

export const CheckableRealEstatePropertyCard: React.FC<CheckableRealEstatePropertyCardProps> = React.memo(
  ({ id, isChecked, isShared, handleCheck, realEstateProps }) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        handleCheck(id, event.target.checked);
      },
      [id, handleCheck]
    );

    const theme = useTheme() as ITheme;

    return (
      <BorderedContainer
        all={isChecked ? 3 : 0}
        radius={5}
        color={theme.palette.tonique}
      >
        <Paper
          elevation={2}
          style={{
            backgroundColor:
              isChecked || isShared ? theme.palette.greyLight : "",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <FormControlLabel
                    style={{ marginLeft: 8 }}
                    control={
                      <ToniqueCheckbox
                        checked={isChecked}
                        onChange={handleChange}
                        name={`checkable-real-estate-${id}`}
                      />
                    }
                    label=""
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    style={{ marginLeft: -16 }}
                  >
                    {isShared ? "Déjà partagée" : "À partager"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <RealEstatePropertyCard {...realEstateProps} />
            </Grid>
          </Grid>
        </Paper>
      </BorderedContainer>
    );
  },
  (prev, next) => {
    return prev.id === next.id && prev.isChecked === next.isChecked;
  }
);
