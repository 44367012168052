import { MoreHorizOutlined } from "@material-ui/icons";
import React from "react";
import {
  DisplayedProfileForDetails,
  ProfileType,
} from "routes/app/profiles/adapters/profileAdapterDetails";
import {
  DONE,
  FAILED,
  IN_PROGRESS,
  NONE,
  REQUESTED,
} from "services/profiles/constants/exportProfileStatus";
import { ITheme } from "theme/ts/main";
import { ColoredPaperButtonProps } from "UI/shared/atoms/buttons/ColoredPaperButton";

const buttonBackColorHandler = (theme: ITheme) => (currentStatus?: string) => {
  if (currentStatus !== undefined) {
    switch (currentStatus) {
      case FAILED:
        return theme.palette.failed.main;
      case REQUESTED:
      case IN_PROGRESS:
        return theme.palette.white;
    }
  }

  return theme.palette.tonique;
};

const buttonColorHandler = (theme: ITheme) => (currentStatus?: string) => {
  if (currentStatus !== undefined) {
    switch (currentStatus) {
      case REQUESTED:
      case IN_PROGRESS:
        return theme.palette.common.dark;
    }
  }

  return theme.palette.white;
};

const doNothing = () => {
  return;
};

export const getProfileDetailsActionButtonProps: (
  status: string,
  profileId: string,
  bu: string,
  t: (value: string) => string,
  theme: ITheme,
  openLegacyProfile: (legacyId: string, bu: string, type: ProfileType) => void,
  exportProfile: (profileId: string) => Promise<any>,
  setCheckExportStatus: (value: boolean) => void,
  exportStatus?: { legacyId: string; status: string },
  profile?: DisplayedProfileForDetails
) => ColoredPaperButtonProps = (
  status,
  profileId,
  bu,
  t,
  theme,
  openLegacyProfile,
  exportProfile,
  setCheckExportStatus,
  exportStatus,
  profile
) => {
  const getButtonBackColor = buttonBackColorHandler(theme);
  const getButtonColor = buttonColorHandler(theme);

  const clickHandlerSwitch: Record<string, () => void> = {
    [DONE]: () => {
      if (exportStatus && profile)
        openLegacyProfile(exportStatus?.legacyId, bu, profile?.profileType);
    },
    [FAILED]: () => {
      exportProfile(profileId);
    },
    [IN_PROGRESS]: doNothing,
    [NONE]: () => {
      setCheckExportStatus(true);
      exportProfile(profileId);
    },
    [REQUESTED]: doNothing,
  };

  const isRequestedOrInProgress = [REQUESTED, IN_PROGRESS].includes(status);
  const buttonColor = getButtonColor(exportStatus?.status);

  return {
    clickHandler: clickHandlerSwitch[status] || doNothing,
    label: t(`profileExportStatus.labels.${bu}.${status}`),
    subLabel: t(`profileExportStatus.subLabels.${bu}.${status}`),
    ...(isRequestedOrInProgress && {
      icon: <MoreHorizOutlined style={{ color: buttonColor }} />,
    }),
    backcolor: getButtonBackColor(exportStatus?.status),
    color: buttonColor,
  };
};
