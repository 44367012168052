import { MenuItem, Paper, TextField, Typography } from "@material-ui/core";
import { emphasize, makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import Select, { components } from "react-select";

import { useTxHttp } from "../../../../../services/http";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  divider: {
    height: theme.spacing(2),
  },
  input: {
    display: "flex",
    height: "auto",
    padding: 4,
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  paper: {
    left: 0,
    marginTop: theme.spacing(1),
    position: "absolute",
    right: 0,
    zIndex: 2,
  },
  placeholder: {
    bottom: 22,
    left: 17,
    position: "absolute",
  },
  root: {
    flexGrow: 1,
    marginBottom: 8,
    marginTop: 6,
    padding: 1,
  },
  valueContainer: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    //overflow: "hidden",
  },
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      style={{ paddingLeft: 2, width: "100%" }}
      InputProps={{
        inputComponent,
        inputProps: {
          children,
          className: classes.input,
          ref: innerRef,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {
  const item = props.data;
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      {...props.innerProps}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
    >
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" noWrap>
          {item.label}
        </Typography>
        <Typography variant="body2" style={{ color: "#8e8e8e" }} noWrap>
          {item.parent}
        </Typography>
      </div>
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

// className={props.selectProps.classes.placeholder}
// {...props.innerProps}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      variant="body1"
      style={{ paddingLeft: 10 }}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
  return (
    <Typography
      variant="subtitle1"
      {...props.innerProps}
      style={{ paddingLeft: 10 }}
    >
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
  return (
    <div
      className={props.selectProps.classes.valueContainer}
      style={{ alignItems: "center", display: "flex" }}
    >
      {props.children}
    </div>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
  return (
    <Typography variant={"body1"} key={props.data.id} tabIndex={-1} noWrap>
      {props.children}&nbsp;&nbsp;
    </Typography>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

const Input = (props) => {
  return (
    <div>
      <components.Input {...props} />
    </div>
  );
};

ReactSelect.propTypes = {
  data: PropTypes.array,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default function ReactSelect(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { get } = useTxHttp();
  const {
    onChange,
    styles,
    inputId,
    messageNoOptions,
    selection,
    disabled = false,
    endPoint,
    formatOptionsListe,
  } = props;

  const [agents, setAgents] = React.useState([]);
  const [searchText, setSearchText] = React.useState(null);

  React.useEffect(() => {
    if (searchText && searchText.length > 2) {
      setAgents([]);
      get(endPoint, { autoComplete: searchText, page: 1, size: 10 })
        .then((response) => {
          if (response.data) setAgents(formatOptionsListe(response.data));
        })
        .catch((e) => console.log(e));
    }
    if ((searchText && searchText.length < 3) || searchText === null) {
      setAgents([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  function customNoOptionsMessage(props) {
    return (
      <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
      >
        {messageNoOptions}
      </Typography>
    );
  }

  const customComponents = {
    Control,
    Input,
    Menu,
    //NoOptionsMessage,
    MultiValue,
    NoOptionsMessage: customNoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
  };

  function handleChange(_values) {
    onChange(_values);
  }

  function handleInputChange(_value) {
    setSearchText(_value);
  }

  const selectStyles = {
    ...styles,
    input: (base) => ({
      ...base,
      "& input": {
        font: "inherit",
      },
      color: theme.palette.text.primary,
      height: "20px",
      paddingLeft: "10px",
    }),
  };

  return (
    <div className={classes.root}>
      <Select
        isDisabled={disabled}
        variant="outlined"
        classes={classes}
        styles={selectStyles}
        inputId={inputId}
        TextFieldProps={{
          InputLabelProps: {
            htmlFor: inputId,
            shrink: true,
          },
          label: "Conseillers",
          variant: "outlined",
        }}
        options={agents}
        placeholder={""}
        components={customComponents}
        value={selection}
        onInputChange={handleInputChange}
        onChange={handleChange}
        closeMenuOnSelect={false}
        defaultOptions={true}
        cacheOptions={true}
        isMulti={true}
      />
    </div>
  );
}
