import React, { useContext } from "react";
import { NotificationSettingDto } from "services/notificationSettings/dto/NotificationSettingDto";
import { UserContext } from "services/user";

import { NotificationSettingsCenterDto } from "../../../../../../../services/notificationSettings/dto/NotificationSettingsCenterDto";

interface NotificationSettingsCenterContextProps {
  notificationSettingsCenter: NotificationSettingsCenterDto;
  update: (updated: NotificationSettingDto) => void;
}

export const NotificationSettingsCenterContext = React.createContext<
  NotificationSettingsCenterContextProps
>({
  notificationSettingsCenter: {} as NotificationSettingsCenterDto,
  update: (updated) => {},
});

const NotificationSettingsCenterProvider = ({
  children,
}: {
  children: any;
}) => {
  const {
    notificationSettingsService: { notificationSettingsCenter, update },
  } = useContext(UserContext);

  const handleUpdate = (notificationSetting: NotificationSettingDto) => {
    const nextNotificationSettings = [
      ...notificationSettingsCenter.notificationSettings.filter(
        (ns) => ns.type !== notificationSetting.type
      ),
      notificationSetting,
    ];

    update({
      ...notificationSettingsCenter,
      notificationSettings: nextNotificationSettings,
    });
  };

  return (
    <NotificationSettingsCenterContext.Provider
      value={{
        notificationSettingsCenter,
        update: handleUpdate,
      }}
    >
      {children}
    </NotificationSettingsCenterContext.Provider>
  );
};

export default NotificationSettingsCenterProvider;
