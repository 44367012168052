import Typography from "@material-ui/core/Typography/Typography";
import React from "react";

interface Props {
  label: string;
  value: string | number;
}

export const Characteristic: React.FC<Props> = ({ label, value }) => {
  return (
    <Typography
      variant="body2"
      style={{
        fontSize: 12,
        fontWeight: "bold",
      }}
    >
      {`${label} : ${value}`}
    </Typography>
  );
};
