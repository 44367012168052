import React, { useEffect, useRef, useState } from "react";
import { IconButton } from "@material-ui/core";
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import CardsCarousel from "components/CardsCarousel";
import HitBien from "../CarteBienMini/HitBien";

const CarrouselBiens = ({ items, onItemClick, mode }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [nombreDeCartes, setNombreDeCartes] = useState(4);
  const containerRef = useRef();

  useEffect(() => {
    const ajusterNombreDeCartes = () => {
      if (
        containerRef &&
        nombreDeCartes !==
          Math.floor(containerRef.current.getBoundingClientRect().width / 200)
      ) {
        setNombreDeCartes(
          Math.floor(containerRef.current.getBoundingClientRect().width / 200)
        );
      }
    };
    window.addEventListener("resize", ajusterNombreDeCartes);
    ajusterNombreDeCartes();
    return () => {
      window.removeEventListener("resize", ajusterNombreDeCartes);
    };
  }, [nombreDeCartes]);

  const leftChevron = (
    <IconButton>
      <ChevronLeftOutlinedIcon />
    </IconButton>
  );
  const rightChevron = (
    <IconButton>
      <ChevronRightOutlinedIcon />
    </IconButton>
  );

  return (
    <div style={{ height: "320px" }} ref={containerRef}>
      <CardsCarousel
        requestToChangeActive={setActiveIndex}
        activeItemIndex={activeIndex}
        activePosition={"center"}
        numberOfCards={nombreDeCartes}
        enablePlaceholder={false}
        numberOfPlaceholderItems={nombreDeCartes}
        minimumPlaceholderTime={1000}
        placeholderItem={
          <div
            style={{
              height: 320,
              width: 169,
              background: "#555",
            }}
          >
            Placeholder
          </div>
        }
        gutter={0}
        showSlither={true}
        firstAndLastGutter={true}
        freeScrolling={false}
        chevronWidth={42}
        leftChevron={leftChevron}
        rightChevron={rightChevron}
        outsideChevron={false}
      >
        {items.data.map((item) => (
          <HitBien
            key={item.id}
            hit={item}
            mode={mode}
            onClick={() => onItemClick({ id: item.id })}
          />
        ))}
      </CardsCarousel>
    </div>
  );
};

export default CarrouselBiens;
