import { MicroServicesConfiguration } from "services/configuration/configuration";

import { EndpointsProvider } from "./interfaces/EndpointsProvider";

export interface Lineup7Endpoints {
  updateCampaignOrder: (orderId: number, storeId: string) => string;
  createCampaignOrder: string;
  campaignsOrders: string;
  campaignsCatalog: string;
  campaignsStats: string;
}

const endpoints: EndpointsProvider<Lineup7Endpoints> = (
  microServices: MicroServicesConfiguration
) => ({
  campaignsCatalog: `${microServices.lineup7}/campaigns-catalog/`,
  campaignsOrders: `${microServices.lineup7}/campaigns-orders/`,
  campaignsStats: `${microServices.lineup7}/campaigns-stats/`,
  createCampaignOrder: `${microServices.lineup7}/campaigns-orders/create-campaign-order/`,
  updateCampaignOrder: (orderId: number, storeId: string) =>
    `${microServices.lineup7}/campaigns-orders/update-campaign-order/${orderId}/?store=${storeId}`,
});

export default endpoints;
