import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useAcquirerProfilesMatching } from "services/matching/useAcquirerProfilesMatching";
import { MatchingProfileRequestDto } from "services/matching/MatchingProfileRequestDto";
import { ITheme } from "theme/ts/main";

export const WriteSellerMatchingNumber: React.FC<{
  criteria: MatchingProfileRequestDto | undefined;
}> = ({ criteria }) => {
  const { getCount } = useAcquirerProfilesMatching();
  const theme: ITheme = useTheme();

  const [count, setCount] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (criteria) {
      getCount(criteria).then((nbResult) => {
        setCount(nbResult);
      });
    } else setCount(undefined);
  }, [criteria, getCount]);

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        margin: "0 auto",
        width: "90%",
      }}
    >
      <Typography
        variant={"subtitle1"}
        style={{
          color: theme.palette.common.black,
          textAlign: "center",
        }}
      >
        {count === undefined ? (
          <CircularProgress
            size={12}
            style={{
              margin: "auto",
            }}
          />
        ) : (
          <>
            {count === 0 ? (
              <Typography variant={"body1"}>Aucune correspondance</Typography>
            ) : (
              <>
                {count && count > 0 && (
                  <>
                    {count > 1 ? (
                      <>
                        <span style={{ fontWeight: "bold" }}>
                          {count} recherches &nbsp;
                        </span>
                        correspondent à vos critères
                      </>
                    ) : (
                      <>
                        <span style={{ fontWeight: "bold" }}>
                          {count} recherche &nbsp;
                        </span>
                        correspond à vos critères
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Typography>
    </div>
  );
};
