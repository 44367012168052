import React, { createContext, useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "services/authentication/authentication";

import { useTxHttp } from "../http";
import { useServices } from "../index";
import { FeaturesName } from "./types";

export const FeaturesContext = createContext<Record<FeaturesName, boolean>>({
  "Buyer-profile-added-features": false,
  Campaigns_enabled: false,
  Campaigns_v2_enabled: false,
  Contacts_V2: false,
  DR: false,
  Export_Rental_Apartment: false,
  Export_Sale_Premises: false,
  Fusion_inProgress: false,
  Maintenance: false,
  Notifications: false,
  Pige: false,
  Pige_Conversion: false,
  Pige_DisabledAd: false,
  Seller_profile: false,
  RealEstates: false,
  RealEstateSharing: false,
});

export const FeaturesContextProvider: React.FC = ({ children }) => {
  const { get } = useTxHttp();
  const [state, setState] = useState<Record<FeaturesName, boolean>>({
    "Buyer-profile-added-features": false,
    Campaigns_enabled: false,
    Campaigns_v2_enabled: false,
    Contacts_V2: false,
    DR: false,
    Export_Rental_Apartment: false,
    Export_Sale_Premises: false,
    Fusion_inProgress: false,
    Maintenance: false,
    Notifications: false,
    Pige: false,
    Pige_Conversion: false,
    Pige_DisabledAd: false,
    Seller_profile: false,
    RealEstates: false,
    RealEstateSharing: false,
  });

  const oneMinute = 60_000;
  const { endpoints } = useServices();
  const { access_token } = useContext(AuthenticationContext);

  useEffect(() => {
    const getFeatures = () => {
      if (access_token) {
        get<Record<FeaturesName, boolean>>(
          endpoints.manager.features
        ).then((response) => setState(response.data));
      }
    };
    getFeatures();
    const intervalRef = setInterval(getFeatures, oneMinute);
    return () => clearInterval(intervalRef);
  }, [get, endpoints.manager.features, access_token]);

  return (
    <FeaturesContext.Provider value={state}>
      {children}
    </FeaturesContext.Provider>
  );
};

export const useFeatureFlags = (): Record<FeaturesName, boolean> =>
  useContext(FeaturesContext);
