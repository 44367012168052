import React from "react";

import { RadioChip } from "UI/shared/molecules/RadioChip";
import { PremisesLocation } from "../../../../models/Proposal/ForSale/Premises/PremisesLocation";
import { SubSectionTitle } from "../../../components/SectionTitle";

export const PremisesLocationSelection: React.FC<{
  pristine?: boolean;
  value?: PremisesLocation;
  onChange: (isValid: boolean, value: PremisesLocation) => void;
}> = ({ value, onChange, pristine = false }) => {
  const options: PremisesLocation[] = [
    "Downtown",
    "IndustrialZone",
    "ActivityZone",
    "CommercialZone",
  ];

  return (
    <>
      <SubSectionTitle title="Situation* :" />
      <RadioChip
        translationPrefix={"premisesLocation"}
        options={options}
        pristine={pristine}
        required={true}
        value={value}
        onChange={(newValue) => onChange(true, newValue)}
      />
    </>
  );
};
