import { Chip } from "@material-ui/core";
import { DateRange } from "components/RefinementField/types";
import React from "react";
import { RefinementChipProps } from "../RefinementChip";

export const DateRangeChip = ({
  value,
  format,
  onChange,
}: RefinementChipProps<DateRange>) => {
  return (
    <>
      {(value.value?.min || value.value?.max) && (
        <Chip
          style={{ marginBottom: 6, marginRight: 6 }}
          label={format(value.value)}
          size="small"
          onDelete={() => onChange({ kind: "date-range", value: {} })}
        />
      )}
    </>
  );
};
