import {
  browserName,
  browserVersion,
  getUA,
  osName,
  osVersion,
} from "react-device-detect";

export interface BrowserInfos {
  browserName: string;
  browserVersion: string;
  osName: string;
  osVersion: string;
  resolution: string;
  userAgent: string;
}

export const getBrowserInfos = (): BrowserInfos => {
  return {
    browserName,
    browserVersion,
    osName,
    osVersion,
    resolution: `${window.screen.width}x${window.screen.height}px`,
    userAgent: getUA,
  };
};
