import { Chip } from "@material-ui/core";
import { YesNo } from "components/RefinementField/types";
import React from "react";

import { RefinementChipProps } from "../RefinementChip";

export const CheckboxChip = ({
  value,
  onChange,
}: RefinementChipProps<YesNo>) => (
  <>
    {value.value && (
      <Chip
        style={{ marginBottom: 6, marginRight: 6 }}
        label={value.displayedValue}
        size="small"
        onDelete={() =>
          onChange({
            kind: "checkbox",
            value: false,
          })
        }
      />
    )}
  </>
);
