import { Grid } from "@material-ui/core";
import React from "react";
import { Campaign } from "../../../../services/campagnes/types";
import { CampaignCard } from "../organisms/CampaignCard";
import { CampaignStatsCard } from "../organisms/CampaignStatsCard";
import { MarketingCampaignsPageKind } from "../types";

interface CampaignsListProps {
  campaigns: Campaign[];
  subscribe: (campaignId: string) => Promise<void>;
  unsubscribe: (campaignId: string) => Promise<void>;
  kind: MarketingCampaignsPageKind;
}

export const CampaignsList: React.FC<CampaignsListProps> = ({
  campaigns,
  subscribe,
  unsubscribe,
  kind,
}) => {
  return (
    <Grid container spacing={2} justify="center" alignItems="center">
      {campaigns.map((campaign) =>
        getCampaignCard(campaign, subscribe, unsubscribe, kind)
      )}
    </Grid>
  );
};

const getCampaignCard = (
  campaign: Campaign,
  subscribe: (campaignId: string) => Promise<void>,
  unsubscribe: (campaignId: string) => Promise<void>,
  kind: MarketingCampaignsPageKind
) =>
  kind === "Gallery" ? (
    <Grid item xs={12} sm={6} md={4} lg={3} key={campaign.id}>
      <CampaignCard
        campaign={campaign}
        subscribe={subscribe}
        unsubscribe={unsubscribe}
      />
    </Grid>
  ) : (
    <Grid item xs={12} key={campaign.id}>
      <CampaignStatsCard
        campaign={campaign}
        subscribe={subscribe}
        unsubscribe={unsubscribe}
      />
    </Grid>
  );
