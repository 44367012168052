import React from "react";
import { useTheme } from "@material-ui/styles";
import { Grid, TextField } from "@material-ui/core";
import TitreBloc from "layoutContents/components/ContextualSideBar/components/titreBloc";
import SurfaceIcon from "@material-ui/icons/CropFreeOutlined";
import SelectX from "components/SelectX";
import Conseillers from "layoutContents/components/ContextualSideBar/components/conseillers";

const Caracteristiques = ({
  classes,
  onUpdateRefinements,
  bus,
  roles,
  specialites,
  _refinementsObject,
  t,
  endPointConseiller,
  formatOptionsListe,
  telephone,
}) => {
  const theme = useTheme();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TitreBloc
          label={t("Caractéristiques")}
          icon={<SurfaceIcon style={{ color: theme.palette.tonique }} />}
        />
      </Grid>
      {endPointConseiller !== "" && (
        <Grid item xs={12}>
          <Conseillers
            refinementsObject={_refinementsObject}
            t={t}
            gridFilter={classes.gridFilter}
            onUpdateRefinements={onUpdateRefinements}
            endPoint={endPointConseiller}
            formatOptionsListe={formatOptionsListe}
          />
        </Grid>
      )}
      <Grid className={classes.gridFilter} item xs={12}>
        <TextField
          fullWidth
          onChange={(e) => onUpdateRefinements(telephone.query, e.target.value)}
          variant={"outlined"}
          value={telephone.value}
          label={t("telephone")}
        />
      </Grid>
      <Grid className={classes.gridFilter} item xs={12}>
        <SelectX
          className={classes.select}
          refinement={bus}
          label={t("Réseaux")}
          onChange={onUpdateRefinements}
          multiple
        />
      </Grid>
      <Grid className={classes.gridFilter} item xs={12}>
        <SelectX
          className={classes.select}
          refinement={roles}
          label={t("Rôles")}
          onChange={onUpdateRefinements}
          multiple
        />
      </Grid>
      <Grid className={classes.gridFilter} item xs={12}>
        <SelectX
          className={classes.select}
          refinement={specialites}
          label={t("Spécialités")}
          onChange={onUpdateRefinements}
          multiple
        />
      </Grid>
    </Grid>
  );
};

export default Caracteristiques;
