import { makeStyles } from "@material-ui/core";
import { Text16px } from "UI/shared/atoms/typographies/Text16px";
import React from "react";

export interface ProfileDetailsCaptionProps {
  propertyCategoryCaption: React.ReactNode;
  propertyTypeCaption?: React.ReactNode;
  surface?: React.ReactNode;
  roomsNumber?: React.ReactNode;
  bedroomsNumber?: React.ReactNode;
  price?: React.ReactNode;
  monthIncome?: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  colonne: {
    display: "flex",
    flexDirection: "column",
  },
  ligne: {
    display: "flex",
    flexWrap: "wrap",
    minHeight: 23,
  },
}));

export const ProfileDetailsCaptionSmall: React.FC<ProfileDetailsCaptionProps> = ({
  propertyTypeCaption,
  propertyCategoryCaption,
  surface,
  roomsNumber,
  bedroomsNumber,
  price,
  monthIncome,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.colonne}>
      <div className={classes.ligne}>
        {propertyCategoryCaption}
        {!!propertyTypeCaption && <PipeSeparator />}
        {!!propertyTypeCaption && propertyTypeCaption}
      </div>
      <div className={classes.ligne}>
        {!!surface && (
          <div className={classes.ligne}>
            {surface}
            {(!!bedroomsNumber || !!roomsNumber) && <VerticalSeparator />}
          </div>
        )}
        {!!roomsNumber && (
          <div className={classes.ligne}>
            {roomsNumber}
            {!!bedroomsNumber && <VerticalSeparator />}
          </div>
        )}
        {!!bedroomsNumber && (
          <div className={classes.ligne}>{bedroomsNumber}</div>
        )}
      </div>
      <div className={classes.ligne}>
        {price}
        {!!monthIncome && (
          <>
            <SimpleSeparator />
            {monthIncome}
          </>
        )}
      </div>
    </div>
  );
};

const PipeSeparator: React.FC = () => <Text16px>&nbsp;-&nbsp;</Text16px>;
const VerticalSeparator: React.FC = () => <Text16px>&nbsp;|&nbsp;</Text16px>;
const SimpleSeparator: React.FC = () => <Text16px>&nbsp;&nbsp;</Text16px>;
