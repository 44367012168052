import { makeStyles } from "@material-ui/core";
import React from "react";

export interface CircleIconProps {
  diameter?: number;
}
const useStylesIcon = makeStyles((theme: any) => {
  return {
    icon: {
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      borderRadius: ({ diameter }: CircleIconProps) => diameter || 0 / 2,
      color: "white",
      display: "flex",
      height: ({ diameter }: CircleIconProps) => diameter,
      justifyContent: "center",
      width: ({ diameter }: CircleIconProps) => diameter,
    },
  };
});

export const CircleIcon: React.FC<CircleIconProps> = ({
  children,
  diameter = 56,
}) => {
  const classes = useStylesIcon({ diameter });
  return <div className={classes.icon}>{children}</div>;
};
