import { useCallback, useState } from "react";
import { useServices } from "services";
import { useTxHttp } from "services/http";
import { RealEstateMatchingDto } from "../dto/RealEstateMatchingDto";

export const useMatching = () => {
  const { get } = useTxHttp();
  const {
    endpoints: { profiles: profileEndpoints },
  } = useServices();

  const fromProfile = useCallback(
    async (profileId: string, contactId: string) => {
      const response = await get<RealEstateMatchingDto>(
        profileEndpoints.matching.realEstates.byAgent(profileId, contactId)
      ).catch((e) => {
        return null;
      });

      return response?.data;
    },
    [profileEndpoints.matching.realEstates.byAgent]
  );

  return {
    fromProfile,
  };
};
