import { Card, CardContent, Typography } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import PhoneIcon from "@material-ui/icons/LocalPhoneOutlined";
import SendIcon from "@material-ui/icons/MailOutlined";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import { makeStyles } from "@material-ui/styles";
import { BU } from "components/BU";
import IconAnchorAction from "components/IconAnchorAction";
import React from "react";

import AvatarConseiller from "../../../mon-reseau/conseillers/components/Hit/components/Avatar";

const useStyles = makeStyles((theme) => ({
  mail: {
    color: theme.palette.default,
  },
  telephone: {
    color: theme.palette.default,
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
}));

const CarteConseiller = ({ conseiller, t }) => {
  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            overflowX: "hidden",
          }}
        >
          <div>
            {/*        <Avatar
                            aria-label="photo conseiller"
                            style={{ height: 100, width: 100 }}
                        >
                            <img
                                src={urlPhoto}
                                width="100"
                                height="auto"
                                alt="face"
                            />
                        </Avatar>*/}
            <AvatarConseiller conseiller={conseiller} />
          </div>
          <div>
            <BU bu={conseiller.bu} />
            <Typography variant="h4">
              {`${conseiller.prenom} ${conseiller.nom.toUpperCase()}`}
            </Typography>
          </div>
          <div
            style={{
              marginTop: "25px",
              overflowX: "hidden",
              width: "100%",
            }}
          >
            <IconAnchorAction
              link={conseiller.telephone}
              icon={<PhoneIcon style={{ fontSize: 15 }} />}
              action="tel"
              tooltip={t("Appeler")}
            >
              <Typography variant="h3" className={classes.telephone}>
                {conseiller.telephone}
              </Typography>
            </IconAnchorAction>

            <IconAnchorAction
              link={conseiller.email}
              icon={<SendIcon style={{ fontSize: 15 }} />}
              action="mailto"
              tooltip={t("Envoyer un mail")}
            >
              <Typography variant="body2" noWrap className={classes.mail}>
                {conseiller.email}
              </Typography>
            </IconAnchorAction>
            <div style={{ alignItems: "center", display: "flex" }}>
              <div>
                <IconAnchorAction
                  link={conseiller.urlMiniSite}
                  icon={<PublicOutlinedIcon style={{ fontSize: 15 }} />}
                  action="link"
                  tooltip={t("Aller sur le site")}
                  placement={"bottom"}
                >
                  <Typography variant="body2" noWrap className={classes.mail}>
                    Site
                  </Typography>
                </IconAnchorAction>
              </div>

              {conseiller.lienFacebook !== "" && (
                <>
                  <Typography variant="body2" noWrap>
                    &nbsp; | &nbsp;
                  </Typography>
                  <div>
                    <IconAnchorAction
                      link={conseiller.lienFacebook}
                      icon={<FacebookIcon style={{ fontSize: 15 }} />}
                      action="link"
                      tooltip={t("Aller sur la page Facebook")}
                      placement={"bottom"}
                    >
                      <Typography
                        variant="body2"
                        noWrap
                        className={classes.mail}
                      >
                        Facebook
                      </Typography>
                    </IconAnchorAction>
                  </div>
                </>
              )}
            </div>
          </div>
          {(conseiller.distinction !== "" || conseiller.specialites !== "") && (
            <div
              style={{
                marginTop: "25px",
                overflowX: "hidden",
                width: "100%",
              }}
            >
              {conseiller.distinction !== "" && (
                <div style={{ display: "flex" }}>
                  <Typography variant="body2">
                    Distinction&nbsp;:&nbsp;
                  </Typography>
                  <Typography variant="h6">{conseiller.distinction}</Typography>
                </div>
              )}
              {conseiller.specialites !== "" && (
                <div style={{ display: "flex" }}>
                  <Typography variant="body2">
                    Spécialités&nbsp;:&nbsp;
                  </Typography>
                  <Typography variant="h6">
                    {conseiller.specialites.join(" | ")}
                  </Typography>
                </div>
              )}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default CarteConseiller;
