import { MicroServicesConfiguration } from "services/configuration/configuration";

import { EndpointsProvider } from "./interfaces/EndpointsProvider";

export interface GeolocEndpoints {
  obtenirUneVilleParId: string;
  obtenirUnDepartementParId: string;
  obtenirUnQuartierParId: string;
  obtenirLesVillesParAutoComplete: string;
  obtenirLocalisationsParIds: (params: string) => string;
  obtenirUneVilleParCodeInsee: (addressString: string) => string;
  AutoComplete: string;
  obtenirUneRegionParId: string;
  places: {
    kind: string;
  };
}

const endpoints: EndpointsProvider<GeolocEndpoints> = (
  microServices: MicroServicesConfiguration
) => ({
  AutoComplete: `${microServices.tx("geoloc")}/v1/Geo/AutoComplete`,
  obtenirLesVillesParAutoComplete: `${microServices.tx(
    "geoloc"
  )}/v1/Ville/ObtenirLesVillesParAutoComplete`,
  obtenirLocalisationsParIds: (params: string) =>
    `${microServices.tx(
      "geoloc"
    )}/v1/Geo/ObtenirRegionsDepartementsVillesEtQuartiersParIds?${params}`,

  obtenirUnDepartementParId: `${microServices.tx(
    "geoloc"
  )}/v1/Departement/ObtenirUnDepartementParId`,
  obtenirUnQuartierParId: `${microServices.tx(
    "geoloc"
  )}/v1/Quartier/ObtenirUnQuartierParId`,
  obtenirUneRegionParId: `${microServices.tx(
    "geoloc"
  )}/v1/Region/ObtenirUneRegionParId`,
  obtenirUneVilleParCodeInsee: (address: string) =>
    `${microServices.tx(
      "geoloc"
    )}/v1/Ville/ObtenirLesVillesParCodeInsee${address}&limit=10`,

  obtenirUneVilleParId: `${microServices.tx(
    "geoloc"
  )}/v1/Ville/ObtenirUneVilleParId`,

  places: { kind: `${microServices.tx("geoloc")}/v1/places/kinds` },
});
export default endpoints;
