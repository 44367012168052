import { Grid } from "@material-ui/core";
import TitreSection from "layoutContents/Fiches/components/Details/components/TitreSection";
import React from "react";
import { HistoriquePrix } from "services/piges/adapters/historiquePrixBienPigeAdapter";

import AnnonceTypeSection from "../Details/sectionsCategories/AnnonceTypeSection";
import { AdPublicationSites } from "./AdPublicationSites";
import PriceHistoryChart from "./PriceHistoryChart";

export interface PriceHistoryPageProps {
  historiquePrix: HistoriquePrix;
  typeAnnonce: string;
  datePublication: Date;
  dateMiseAJour: Date;
  estActive: boolean;
}
export const PriceHistoryPage = ({
  historiquePrix,
  typeAnnonce,
  datePublication,
  dateMiseAJour,
  estActive,
}: PriceHistoryPageProps) => {
  return (
    <Grid container>
      <Grid item xs={12} lg={4}>
        <AnnonceTypeSection
          typeAnnonce={typeAnnonce}
          datePublication={datePublication}
          dateMiseAJour={dateMiseAJour}
          estActive={estActive}
        />
      </Grid>
      <Grid container spacing={2} direction="row" alignItems="flex-start">
        <Grid item xs={12} lg={8}>
          <TitreSection titre={"historique"} />
          <PriceHistoryChart historiquePrix={historiquePrix} />
        </Grid>
        {historiquePrix?.publicationSites && (
          <Grid item xs={12} lg={4}>
            <TitreSection titre={"liste des parutions"} />
            <AdPublicationSites
              publicationSites={historiquePrix?.publicationSites}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
