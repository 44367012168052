import { Grid, Typography } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/LocalPhoneOutlined";
import SendIcon from "@material-ui/icons/MailOutlined";
import IconAnchorAction from "components/IconAnchorAction";
import React from "react";
import { useServices } from "services";
import { Bloctel } from "types";

import { BottomRightBloctelIcon } from "../../Pige/BloctelIcon";
import { showBloctel } from "../../utils";
import { useStyles } from "./styles";

interface UserContext {
  t: (value: string) => string; //translate function
  userData: UserProfile;
}

interface UserProfile {
  Instance: string;
  name: string;
}

export interface CarteContactPigeProps {
  sourceName?: string;
  sourceUrl?: string;
  name?: string;
  email?: string;
  phone?: string;
  bloctel?: Bloctel;
  isDetailsPage?: boolean;
}

export interface GoogleEventProps {
  source: string;
  channel: string;
  userData: UserProfile;
}

export const CarteContactPige: React.FC<CarteContactPigeProps> = ({
  sourceName,
  name,
  email,
  phone,
  bloctel,
  isDetailsPage,
}) => {
  const {
    l10n: { t },
    trackingService: { sendCustomEventToGoogle },
  } = useServices();

  const classes = useStyles();

  return (
    <Grid container>
      <Grid container direction={"column"}>
        <Grid item xs={12}>
          {sourceName && (
            <div style={{ alignItems: "center", display: "flex" }}>
              <div className={classes.sitePicto} />
              <Typography variant={"h6"}>{sourceName}</Typography>
            </div>
          )}
          <Typography variant={"h4"}>{name ? name : ""}</Typography>
          {phone && (
            <IconAnchorAction
              link={phone}
              icon={<PhoneIcon style={{ fontSize: 12 }} />}
              action="tel"
              tooltip={t("Appeler")}
              onClick={() =>
                sendCustomEventToGoogle("téléphone contact depuis bien pige")
              }
            >
              <Typography variant="body1" className={classes.telephone}>
                {phone}{" "}
                {showBloctel(bloctel) && isDetailsPage && (
                  <BottomRightBloctelIcon right={10} bottom={7} />
                )}
              </Typography>
            </IconAnchorAction>
          )}
          {email && (
            <IconAnchorAction
              link={email}
              icon={<SendIcon style={{ fontSize: 12 }} />}
              action="mailto"
              tooltip={t("Envoyer un mail")}
              onClick={() =>
                sendCustomEventToGoogle("email contact depuis bien pige")
              }
            >
              <Typography variant="body2" noWrap className={classes.mail}>
                {email}
              </Typography>
            </IconAnchorAction>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
