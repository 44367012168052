import { Title } from "UI/shared/atoms/Title";
import React from "react";
import { PlaceDto } from "routes/app/contacts/contact/components/models/placeDto";
import { useServices } from "services";
import { ProfileMap } from "../../molecules/ProfileMap";
export interface ProfilePlacesProps {
  places: PlaceDto[];
  mapHeight?: number;
}
export const ProfilePlaces: React.FC<ProfilePlacesProps> = ({
  places,
  mapHeight,
}) => {
  const {
    l10n: { t, formatPolygon },
  } = useServices();

  const polygon = formatPolygon(places?.map((e) => e.wkt) || []);

  const localisation = (
    places?.map((place) => `${place.postalCodes.join(" - ")} ${place.name}`) ||
    []
  ).join(" , ");

  const profileMapProps = {
    polygon,
    ...(mapHeight && { mapHeight }),
    localisation,
  };

  return (
    <>
      <Title title={t("LIEU(X)")} />
      {polygon !== "" ? <ProfileMap {...profileMapProps} /> : <></>}
    </>
  );
};
