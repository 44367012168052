import { string, bool } from "api/DTOs/Common/Types";

export default function conseillerAdapter(item = {}) {
  const bo = {
    email: item.email || string,
    estActif: item.estActif === null || item.estActif === true ? true : false,
    estPrincipal: item.estPrincipal || bool,
    id: item.id || string,
    nom: item.nom || string,
    photo: item.photo || string,
    prenom: item.prenom || string,
    telephone: item.telephone || string,
  };
  return bo;
}
