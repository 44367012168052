import { makeStyles } from "@material-ui/core/styles";

import { ITheme } from "../../../../../theme/ts/main";

const CARD_LG_MIN_WIDTH = 250;
const CARD_SM_MIN_WIDTH = 200;

export const useStyles = makeStyles<ITheme>((theme) => ({
  buttonsIcon: {
    background: theme.palette.primary.main,
    marginRight: "7px",
  },
  buttonsIconContainer: {
    bottom: "-15px",
    position: "absolute",
    right: 0,
  },
  cardContent: {
    marginTop: "10px",
  },
  emphase: {
    color: theme.palette.emphase,
  },
  fab: {
    bottom: "50%",
    left: "calc(100% - 30px)",
  },
  icons: {
    color: "white",
    fontSize: "1.5em",
  },
  image: {
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: window.innerWidth <= 960 ? 240 : 120,
    overflow: "hidden",
    position: "relative",
  },
  imageInformation: {
    visibility: "hidden",
  },
  legend: {
    background: "rgba(0,0,0,0.4)",
    color: "#fff",
    marginTop: "-50px",
    position: "relative",
    textAlign: "center",
    width: "100%",
  },
  nbPhotos: {
    color: "#fff",
    position: "absolute",
    right: 10,
    textAlign: "center",
    top: -140,
    width: "25%",
  },
  propertyType: {
    color: theme.palette.common.dark,
  },
  picturesContainer: {
    backgroundColor: "lightGrey",
    bottom: 0,
    position: "absolute",
    right: 0,
    width: 30,
  },
  propertyInfosContainer: {
    padding: "10px 10px 10px 15px",
  },
  root: {
    "&:hover": {
      boxShadow:
        "0px 3px 3px 0px rgba(0,0,0,0.34), 0px 1px 1px -1px rgba(0,0,0,0.1)",
    },
    "&:hover $imageInformation": {
      alignItems: "center",
      background: "#00000053",
      color: "white",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      visibility: "inherit",
    },
    cursor: "pointer",
    minWidth: CARD_LG_MIN_WIDTH,
    [theme.breakpoints.down("md")]: {
      minWidth: CARD_SM_MIN_WIDTH,
    },
    padding: "8px",
    position: "relative",
    width: "100%",
  },
  rootDisabled: {
    "&:hover": {
      boxShadow:
        "0px 3px 3px 0px rgba(0,0,0,0.34), 0px 1px 1px -1px rgba(0,0,0,0.1)",
    },
    "&:hover $imageInformation": {
      alignItems: "center",
      background: "#00000053",
      color: "white",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      visibility: "inherit",
    },
    cursor: "pointer",
    opacity: 0.5,
    position: "relative",
    width: "100%",
  },
  separator: {
    borderColor: theme.palette.greyLight,
    margin: "auto",
    opacity: "50%",
    width: "90%",
  },
  tagItem: {
    paddingRight: "5px",
  },
  texte: {
    height: "30px",
    marginBottom: "10px",
    marginTop: "10px",
    overflow: "hidden",
  },
  typeBienVille: {
    fontSize: "1rem",
  },
}));
