import { makeStyles } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import NotificationIcon from "@material-ui/icons/NotificationsNone";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useServices } from "services";
import { useAgentNotifications } from "services/user";

import { ITheme } from "../../../theme/ts/main";

const useStyles = makeStyles((theme: ITheme) => ({
  badge: { backgroundColor: theme.palette.tonique },
}));
export const NotificationsStatus: React.FC = () => {
  const { newNotificationsCount } = useAgentNotifications();
  const location = useLocation();
  const classes = useStyles();
  const {
    trackingService: { sendCustomEventToGoogle },
  } = useServices();

  return (
    <NavLink
      to={"/app/notifications/"}
      onClick={() => sendCustomEventToGoogle("Clic sur voir notifications")}
    >
      <Badge
        invisible={
          newNotificationsCount === 0 ||
          location.pathname === "/app/notifications/"
        }
        variant="dot"
        color="primary"
        classes={{
          colorPrimary: classes.badge,
        }}
      >
        <NotificationIcon color="primary" />
      </Badge>
    </NavLink>
  );
};
