import {
  ArchiveOutlined,
  CallMadeOutlined,
  HomeWorkOutlined,
  Notes,
  UnarchiveOutlined,
} from "@material-ui/icons";
import React from "react";

import { RFIMenuOption } from "../../UI/features/requestForInformations/interfaces/RFIMenuOption";
import {
  ARCHIVED,
  RequestForInformationStatus,
  TO_BE_TREATED,
  TRANSFORMED,
} from "./constants/request-for-informations-status";
import { RequestForInformationDto } from "./dto/RequestForInformationDto";

type MenuAction =
  | "consult"
  | "archive"
  | "activate"
  | "transform"
  | "openProfile";

type RequestForInformationHandler = (rfi: RequestForInformationDto) => void;

type Actions = Record<MenuAction, RequestForInformationHandler>;

type Menu = {
  options: (status: RequestForInformationStatus) => RFIMenuOption[];
};

const archiveMenuOption: (
  archive: RequestForInformationHandler
) => RFIMenuOption = (archive) => {
  return {
    action: archive,
    icon: <ArchiveOutlined />,
    label: "Archiver",
  };
};

const activateMenuOption: (
  activate: RequestForInformationHandler
) => RFIMenuOption = (activate) => {
  return {
    action: activate,
    icon: <UnarchiveOutlined />,
    label: "Activer",
  };
};

const consultMenuOption: (
  consult: RequestForInformationHandler
) => RFIMenuOption = (consult) => {
  return {
    action: consult,
    icon: <Notes />,
    label: "Consulter",
  };
};

const openProfileMenuOption: (
  open: RequestForInformationHandler
) => RFIMenuOption = (open) => {
  return {
    action: open,
    icon: <CallMadeOutlined />,
    label: "Ouvrir le profil",
  };
};

const transformMenuOption: (
  transform: RequestForInformationHandler
) => RFIMenuOption = (transform) => {
  return {
    action: transform,
    icon: <HomeWorkOutlined />,
    label: "Transformer",
  };
};

export const useRequestForInformationsMenu: (actions: Actions) => Menu = (
  actions
) => {
  const { consult, transform, activate, archive, openProfile } = actions;

  const consultOption = consultMenuOption(consult);
  const archiveOption = archiveMenuOption(archive);
  const activateOption = activateMenuOption(activate);
  const transformOption = transformMenuOption(transform);
  const openProfileOption = openProfileMenuOption(openProfile);

  const menuOptions: Record<RequestForInformationStatus, RFIMenuOption[]> = {
    [ARCHIVED]: [consultOption, activateOption],
    [TO_BE_TREATED]: [consultOption, archiveOption, transformOption],
    [TRANSFORMED]: [consultOption, openProfileOption],
  };

  return {
    options: (status: RequestForInformationStatus) => menuOptions[status],
  };
};
