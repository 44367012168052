import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import SendIcon from "@material-ui/icons/EmailOutlined";
import PhoneIcon from "@material-ui/icons/LocalPhoneOutlined";
import React from "react";
import { useServices } from "services";
import { genderAdapter, toAvatarContent } from "services/portfolio/helpers";
import {
  getMainEmailOrDefault,
  getMainPhoneOrDefault,
  ReadContactDto,
} from "services/portfolio/types";

import { ContactStatus } from "../../../../../../../../components/CarteContact/ContactStatus";
import { useStyles } from "./styles";

export const PrintableContactCard: React.FC<{
  contact: ReadContactDto;
  numberOfSearchProfiles: number;
}> = ({ contact, numberOfSearchProfiles }) => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();

  const phone = getMainPhoneOrDefault(contact.identity.phones);
  const email = getMainEmailOrDefault(contact.identity.emails);

  return (
    <Card className={classes.root} style={{ zIndex: 0 }}>
      <CardHeader
        avatar={
          <div>
            <Avatar
              aria-label={contact.identity.lastName}
              className={classes.avatar}
            >
              {toAvatarContent(contact.identity)}
            </Avatar>
          </div>
        }
        title={
          <div
            style={{
              height: "100%",
              position: "relative",
              width: "100%",
            }}
          >
            <Typography
              variant="h4"
              style={{
                cursor: "pointer",
                marginBottom: "5px",
              }}
            >
              {`${genderAdapter(contact.identity.gender)} ${
                contact.identity.firstName || ""
              } ${(contact.identity.lastName || "").toUpperCase()}`}
              {!contact.identity.firstName && !contact.identity.lastName && (
                <span style={{ color: "#8e8e8e" }}>{t("Non renseigné")}</span>
              )}
            </Typography>

            <Telephone telephone={phone} />
            <Email email={email} />
          </div>
        }
        titleTypographyProps={{ noWrap: true, variant: "h4" }}
        className={classes.card}
        classes={{
          content: classes.cardHeaderContent,
        }}
      />

      <CardContent classes={{ root: classes.cardContent }}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flex: 1,
            justifyContent: "flex-start",
            marginTop: "5px",
            paddingLeft: 55,
          }}
        >
          {numberOfSearchProfiles > 0 && contact.status !== "Archived" ? (
            <Chip
              label={
                <>
                  <span>{t("Recherche")}&nbsp;:&nbsp;</span>
                  <span style={{ marginLeft: "5px" }}>
                    {numberOfSearchProfiles}
                  </span>
                </>
              }
              size="small"
              className={classes.particulierChip}
              classes={{
                label: classes.chipLabel,
                root: classes.filetChip,
              }}
              variant="outlined"
            />
          ) : null}
          {numberOfSearchProfiles === 0 && <div style={{ height: 3 }} />}
          {contact.status === "Archived" && (
            <ContactStatus contactStatus={contact.status} />
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const Telephone: React.FC<{
  telephone: string | undefined;
}> = ({ telephone }) => {
  const classes = useStyles();
  const exists = !!telephone;
  return (
    <div>
      {exists ? (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <PhoneIcon classes={{ root: classes.clickable }} />
          <Typography
            variant="subtitle1"
            noWrap
            className={classes.clickableText}
          >
            {telephone}
          </Typography>
        </div>
      ) : (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <PhoneIcon classes={{ root: classes.notClickable }} />
          <Typography
            variant="subtitle1"
            noWrap
            className={classes.notClickableText}
          >
            Non renseigné
          </Typography>
        </div>
      )}
    </div>
  );
};

const Email: React.FC<{ email: string | undefined }> = ({ email }) => {
  const classes = useStyles();
  const exists = !!email;
  return (
    <>
      {exists ? (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <SendIcon classes={{ root: classes.clickable }} />
          <Typography variant="body2" noWrap className={classes.clickableText}>
            {email}
          </Typography>
        </div>
      ) : (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <SendIcon classes={{ root: classes.notClickable }} />
          <Typography
            variant="body2"
            noWrap
            className={classes.notClickableText}
          >
            Non renseigné
          </Typography>
        </div>
      )}
    </>
  );
};
