export const welcome = require("./welcome.jpg");
export const responsive = require("./responsive.jpg");
export const reporting = require("./reporting.jpg");
export const my_real_estate = require("./my_real_estate.jpg");
export const marketing_campaign = require("./marketing_campaign.jpg");
export const and_now = require("./and_now.png");
export const onboardingLogo = require("./logo_color.png");
export const crm = require("./crm.jpg");
export const cartography = require("./cartography.jpg");
export const available_real_estate_sold = require("./available_real_estate_sold.jpg");
