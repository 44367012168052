import { AutocompletePlace } from "components/AutoCompleteAddress";
import { Refinements } from "components/RefinementField/types";
import { City } from "components/SingleCitySelect";

import { ConsentStatus } from "../../types";
import { genderAdapter } from "./helpers";

export type Aggregations = Record<
  ContactsCriteria,
  { key: string; value: number; filter?: boolean }[]
>;
export interface ReadPortfolioDto {
  documents: ReadContactDto[];
  aggregations: Aggregations;
  hasMore: boolean;
  pageSize: number;
  pageNumber: number;
  total: number;
  totalPage: number;
}

export const DefaultReadContactDto: ReadContactDto = {
  address: {},
  consentStatus: "Denied",
  creationDate: "",
  hasForSaleProfile: false,
  hasSearchProfile: false,
  id: "",
  identity: { emails: [], gender: "None", phones: [] },
  notes: "",
  profiles: 0,
  saleProfilesCount: 0,
  searchProfilesCount: 0,
  source: "TXContact",
  status: "Active",
  updateDate: "",
};

export interface ReadContactDto {
  id: string;
  consentStatus: ConsentStatus;
  notes?: string;
  creationDate: string;
  updateDate: string;
  identity: IdentityDto;
  address?: AddressDto;
  profiles: number;
  hasSearchProfile: boolean;
  hasForSaleProfile: boolean;
  saleProfilesCount: number;
  searchProfilesCount: number;
  status: ContactStatusType;
  source: ContactSourceType;
}

export interface WriteContactDto {
  consentStatus: ConsentStatus;
  identity: IdentityDto;
  notes?: string;
  birthPlace: string;
  addressValue: string;
  addressSupplement: string;
  addressCity: string;
  addressDepartementId: string;
  addressDepartementName: string;
  addressRegionId: string;
  addressRegionName: string;
  source: ContactSourceType;
  status: ContactStatusType;
}

export type PhoneKind = "Mobile" | "Landline" | "Professional" | "Personal";

export const PhoneKindValues: PhoneKind[] = [
  "Mobile",
  "Landline",
  "Professional",
  "Personal",
];

export type EmailKind = "Professional" | "Personal";

export const EmailKindValues: PhoneKind[] = ["Professional", "Personal"];

export interface ContactPhone {
  kind: PhoneKind;
  number: string;
}

export const toPlace = (address?: AddressDto): AutocompletePlace => ({
  adresse: address?.value || "",
  codePostal: address?.city?.postalCodes[0] || "",
  label: "",
  value: address?.value || "",
  ville: {
    codeInsee: address?.city?.inseeCode || "",
    nom: address?.city?.name || "",
  },
});

export const toCity = (city: CityDto | undefined): City => ({
  codeInsee: city?.inseeCode || "",
  codesPostaux: city?.postalCodes || [],
  id: city?.id || "",
  nom: city?.name || "",
  ville: city?.name || "",
});

export const getMainPhoneOrDefault = (phones?: ContactPhone[]): string => {
  return phones && phones.length > 0 ? phones[0].number || "" : "";
};

export const getMainEmailOrDefault = (emails?: ContactEmail[]): string => {
  return emails && emails.length > 0 ? emails[0].address || "" : "";
};

export interface ContactVCard {
  firstName: string;
  lastName: string;
  namePrefix: string;
  note: string;
  workEmail: string;
  workPhone: string;
  id: string;
}

export const toVCard = (contact: ReadContactDto): ContactVCard =>
  ({
    firstName: contact.identity.firstName || "",
    id: contact.id,
    lastName: contact.identity.lastName || "",
    namePrefix: genderAdapter(contact.identity.gender),
    note: contact.notes || "",
    workEmail: getMainEmailOrDefault(contact.identity.emails),
    workPhone: getMainPhoneOrDefault(contact.identity.phones),
  } as ContactVCard);

export interface ContactEmail {
  kind: EmailKind;
  address: string;
}
export type Gender = "None" | "Female" | "Male";

export interface IdentityDto {
  gender: Gender;
  firstName?: string;
  lastName?: string;
  emails: ContactEmail[];
  phones: ContactPhone[];
  birthDate?: Date;
  birthPlace?: CityDto;
  professionalInfos?: ProfessionalInfosDto;
}

export interface ProfessionalInfosDto {
  professionalCategory?: string;
  profession?: string;
  company?: string;
  companyRole?: string;
}

export interface AddressDto {
  value?: string;
  supplement?: string;
  city?: CityDto;
  departement?: DepartementDto;
  region?: RegionDto;
}

export interface CityDto {
  id: string;
  name: string;
  postalCodes: string[];
  inseeCode: string;
}

export interface DepartementDto {
  id: string;
  name: string;
}

export interface RegionDto {
  id: string;
  name: string;
}

export type ContactStatusType = "Active" | "Archived";

export type ContactSourceType =
  | "TXMobile"
  | "TXContact"
  | "Tremplin"
  | "Hunters"
  | "Unknown"
  | "TXRequestForInformations";

export type ContactsCriteria =
  | "locations"
  | "withArchivedContacts"
  | "updateDate"
  | "consentStatuses"
  | "profileKinds"
  | "sources"
  | "searchTerm";

export type ContactsRefinements = Refinements<ContactsCriteria>;

export type PortfolioSortColumn =
  | "creationDate"
  | "updateDate"
  | "lastName"
  | "firstName";
