export type TabName = "details" | "historique-prix" | "suivi";
export type Display = "view" | "print";

export interface IPigeState {
  display: Display;
  tab: TabName;
  selectedAdId?: string;
  redirectUrl?: string;
  scrollPosition?: number;
  slug?: string;
}

export class PigeState implements IPigeState {
  display: Display;
  tab: TabName;
  selectedAdId?: string;
  scrollPosition?: number;
  slug?: string;
  redirectUrl?: string;

  constructor(slug?: string, redirectUrl?: string, id?: string) {
    this.display = "view";
    this.tab = "details";
    this.slug = slug;
    this.redirectUrl = redirectUrl;
    this.selectedAdId = id;
  }
}

export type PigeGlobalActionName =
  | "SELECT_TAB"
  | "SELECT_AD_ID"
  | "SELECT_DISPLAY"
  | "BACK_TO_LIST"
  | "SAVE_SCROLL_POSITION"
  | "UPDATE_SLUG"
  | "UPDATE_REDIRECT_URL"
  | "MULTIPLE_UPDATES";
export type PigeGlobalAction = {
  type: PigeGlobalActionName;
  payload: string | number | Display | TabName | Partial<PigeState> | undefined;
};
