import { NotifierProvider } from "components/Notifier";
import React, { createContext, useContext } from "react";
import { BrowserRouter } from "react-router-dom";

import {
  AuthenticationContextProvider,
  AuthenticationService,
  OidcAuthenticationService,
} from "./authentication/authentication";
import { campaignsService, ICampaignsService } from "./campagnes/Campaigns";
import { Configuration } from "./configuration/configuration";
import {
  contactEventsTrackingService,
  IContactEventsTrackingService,
} from "./contactEventsTracking/contactEventsTracking";
import { endpoints } from "./endpoints";
import { Endpoints } from "./endpoints/interfaces/Endpoints";
import { HttpService } from "./http/httpService";
import { L10nService, l10nService } from "./l10n";
import { createServicesCollectionMockup } from "./mockup";
import { IModalService, ModalService } from "./modal";
import { ModalProvider } from "./modal/provider";
import { ProfilesContextProvider } from "./profiles";
import { ThemeProvider } from "./theme";
import { ITrackingService, TrackingService } from "./tracking/tracking";

export interface ServicesCollection {
  appInfo: {
    environnementLabel: string;
  };
  endpoints: Endpoints;
  // TODO: rename into `tracking` or `analytics`
  trackingService: ITrackingService;
  // TODO: rename into `auth`
  authenticationService: AuthenticationService;
  l10n: L10nService;
  modal: IModalService;
  campaigns: ICampaignsService;
  contactEvents: IContactEventsTrackingService;
}

const defaultValue = createServicesCollectionMockup();

export const ServicesContext = createContext<ServicesCollection>(defaultValue);

export const useServices = (): ServicesCollection =>
  useContext(ServicesContext);

export function createServicesCollection(
  configuration: Configuration
): ServicesCollection {
  const authenticationService = new OidcAuthenticationService(
    configuration.authentication
  );

  const trackingService = TrackingService(
    configuration.tracking,
    authenticationService
  );

  const modal = ModalService();

  const l10n = l10nService(() => authenticationService.getUserData()?.language);

  const http = new HttpService();

  const campaigns = campaignsService(
    endpoints(configuration.microServices),
    trackingService.sendCustomEventToGoogle,
    http,
    "V2"
  );

  const contactEvents = contactEventsTrackingService(
    endpoints(configuration.microServices),
    trackingService.sendCustomEventToGoogle,
    http
  );

  return {
    appInfo: {
      environnementLabel: configuration.environnementLabel,
    },
    authenticationService,
    campaigns,
    contactEvents,
    endpoints: endpoints(configuration.microServices),
    l10n,
    modal,
    trackingService,
  };
}

interface ServicesProviderProps {
  servicesCollection: ServicesCollection;
}

export const ServicesProvider: React.FC<ServicesProviderProps> = (props) => {
  const { servicesCollection } = props;

  return (
    <ServicesContext.Provider value={servicesCollection}>
      <AuthenticationContextProvider>
        <BrowserRouter>
          <ThemeProvider>
            <NotifierProvider>
              <ProfilesContextProvider>
                <ModalProvider>{props.children}</ModalProvider>
              </ProfilesContextProvider>
            </NotifierProvider>
          </ThemeProvider>
        </BrowserRouter>
      </AuthenticationContextProvider>
    </ServicesContext.Provider>
  );
};
