import { makeStyles } from "@material-ui/core";
import React from "react";
import { ITheme } from "theme/ts/main";

interface SourcePictographyProps {
  bgColor: string;
}

const useStyles = makeStyles<ITheme, { bgColor: string }>(() => ({
  sitePicto: {
    backgroundColor: (props) => props.bgColor,
    borderRadius: 5,
    height: 10,
    marginRight: 5,
    width: 10,
  },
}));

export const SourcePictography: React.FC<SourcePictographyProps> = ({
  bgColor,
}) => {
  const classes = useStyles({ bgColor });
  return <div className={classes.sitePicto} />;
};
