import React from "react";
import PropType from "prop-types";
import Grid from "@material-ui/core/Grid";
import PaperX from "components/PaperX";

export default function RepartitionBiens({ chartsStyle, data }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <PaperX>{data && <div id="pie_actifs" style={chartsStyle} />}</PaperX>
      </Grid>
      <Grid item xs={12} md={6}>
        <PaperX>{data && <div id="pie_vendus" style={chartsStyle} />}</PaperX>
      </Grid>
    </Grid>
  );
}

RepartitionBiens.propTypes = {
  chartsStyle: PropType.object,
  data: PropType.object,
};
