import { ForRentApartmentProfileReadDto } from "./Proposal/ForRent/Apartment/forRentApartmentProfileReadDto";
import { ForRentHouseProfileReadDto } from "./Proposal/ForRent/House/forRentHouseProfileReadDto";
import { ForRentParkingProfileReadDto } from "./Proposal/ForRent/Parking/ForRentParkingProfileReadDto";
import { SaleApartmentProfileReadDto } from "./Proposal/ForSale/Apartment/saleApartmentProfileReadDto";
import { SaleBuildingProfileReadDto } from "./Proposal/ForSale/Building/saleBuildingProfileReadDto";
import { SaleHouseProfileReadDto } from "./Proposal/ForSale/House/saleHouseProfileReadDto";
import { SaleLandProfileReadDto } from "./Proposal/ForSale/Land/saleLandProfileReadDto";
import { SaleParkingProfileReadDto } from "./Proposal/ForSale/Parking/saleParkingProfileReadDto";
import { SalePremisesProfileReadDto } from "./Proposal/ForSale/Premises/salePremisesProfileReadDto";
import { PurchaseApartmentProfileReadDto } from "./Search/purchaseApartmentProfileReadDto";
import { PurchaseBuildingProfileReadDto } from "./Search/purchaseBuildingProfileReadDto";
import { PurchaseGoodWillProfileReadDto } from "./Search/purchaseGoodWillProfileReadDto";
import { PurchaseHouseProfileReadDto } from "./Search/purchaseHouseProfileReadDto";
import { PurchaseLandProfileReadDto } from "./Search/purchaseLandProfileReadDto";
import { PurchaseParkingProfileReadDto } from "./Search/purchaseParkingProfileReadDto";
import { PurchasePremisesProfileReadDto } from "./Search/purchasePremisesProfileReadDto";
import { RentApartmentProfileReadDto } from "./Search/rentApartmentProfileReadDto";
import { RentHouseProfileReadDto } from "./Search/rentHouseProfileReadDto";
import { RentLandProfileReadDto } from "./Search/rentLandProfileReadDto";
import { RentParkingProfileReadDto } from "./Search/rentParkingProfileReadDto";
import { RentPremisesProfileReadDto } from "./Search/rentPremisesProfileReadDto";
import { SearchLifeAnnuityApartmentProfileReadDto } from "./Search/searchLifeAnnuityApartmentProfileReadDto";
import { SearchLifeAnnuityBuildingProfileReadDto } from "./Search/searchLifeAnnuityBuildingProfileReadDto";
import { SearchLifeAnnuityHouseProfileReadDto } from "./Search/searchLifeAnnuityHouseProfileReadDto";
import { SearchLifeAnnuityLandProfileReadDto } from "./Search/searchLifeAnnuityLandProfileReadDto";
import { SearchLifeAnnuityPremisesProfileReadDto } from "./Search/searchLifeAnnuityPremisesProfileReadDto";

export interface ProfilesReadDto {
  value: ProfileReadDto[];
}

export type SearchLifeAnnuityReadProfileDto =
  | SearchLifeAnnuityApartmentProfileReadDto
  | SearchLifeAnnuityBuildingProfileReadDto
  | SearchLifeAnnuityHouseProfileReadDto
  | SearchLifeAnnuityLandProfileReadDto
  | SearchLifeAnnuityPremisesProfileReadDto;

export type SearchPurchaseReadProfileDto =
  | PurchaseApartmentProfileReadDto
  | PurchaseBuildingProfileReadDto
  | PurchaseGoodWillProfileReadDto
  | PurchaseHouseProfileReadDto
  | PurchaseParkingProfileReadDto
  | PurchaseLandProfileReadDto
  | PurchasePremisesProfileReadDto;

export type SearchRentalReadProfileDto =
  | RentApartmentProfileReadDto
  | RentHouseProfileReadDto
  | RentLandProfileReadDto
  | RentParkingProfileReadDto
  | RentPremisesProfileReadDto;

export type SearchReadProfileDto =
  | SearchLifeAnnuityReadProfileDto
  | SearchPurchaseReadProfileDto
  | SearchRentalReadProfileDto;

export type SellerReadProfileDto =
  | SaleApartmentProfileReadDto
  | SaleHouseProfileReadDto
  | SaleBuildingProfileReadDto
  | SaleLandProfileReadDto
  | SaleParkingProfileReadDto
  | SalePremisesProfileReadDto;

export type ForRentReadProfileDto =
  | ForRentApartmentProfileReadDto
  | ForRentParkingProfileReadDto
  | ForRentHouseProfileReadDto;

export function isSellerProfile(
  profile: ProfileReadDto | undefined
): profile is SellerReadProfileDto {
  return !!profile && !!profile.kind.match(/^(Sale|Rental|LifeAnnuity).*$/);
}

export type ProfileReadDto =
  | SearchReadProfileDto
  | SellerReadProfileDto
  | ForRentReadProfileDto;

export type ProfileKind = ReturnType<typeof f1>;

function f1(arg: Pick<ProfileReadDto, "kind">) {
  return arg.kind;
}
