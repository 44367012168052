import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  typo: {
    marginBottom: 1,
  },
}));

export const SquareMeter = () => {
  const classes = useStyles();
  return (
    <Typography variant="body1" className={classes.typo}>
      &nbsp;{`m\xB2`}&nbsp;
    </Typography>
  );
};
