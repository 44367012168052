import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import SearchBox from "./CustomSearchBox";

const handleChange = (item, type, refinement, onChange) => {
  const { ...localRefinement } = refinement;
  if (type === "min") localRefinement.limiteInf = Number(item.value);
  else localRefinement.limiteSup = Number(item.value);
  onChange(localRefinement, refinement.query);
};

export default function RangeTextfield({ onChange, refinement, options }) {
  // const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item xs>
        <SearchBox
          options={{ ...options.props, label: options.labelMin }}
          refinement={refinement}
          value={refinement.limiteInf}
          onChange={(item) => handleChange(item, "min", refinement, onChange)}
        />
      </Grid>
      <Grid item xs>
        <SearchBox
          options={{ ...options.props, label: options.labelMax }}
          refinement={refinement}
          value={refinement.limiteSup}
          onChange={(item) => handleChange(item, "max", refinement, onChange)}
        />
      </Grid>
    </Grid>
  );
}

RangeTextfield.propTypes = {
  onChange: PropTypes.func,
  refinement: PropTypes.object.isRequired,
  style: PropTypes.object,
};
