import { TextField } from "@material-ui/core";
import React from "react";

import { useServices } from "../../../../../../services";
import { SectionTitle } from "./components/SectionTitle";

export const NotesInput: React.FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ value, onChange }) => {
  const {
    l10n: { t },
  } = useServices();
  return (
    <>
      <SectionTitle title="Notes" mode="non obligatoire" />
      <TextField
        id="notes"
        name="notes"
        multiline
        rows="2"
        rowsMax="4"
        label={t("Notes")}
        style={{
          marginTop: "8px",
          width: "100%",
        }}
        value={value}
        onChange={(_) => onChange(_.target.value)}
        margin="normal"
        variant="outlined"
      />
    </>
  );
};
