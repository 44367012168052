export const dateEquality = (date1: Date, date2: Date) => {
  return date1.getTime() === date2.getTime();
};

const isDate = (value: any) =>
  typeof value === "object" && value instanceof Date;

const cast = <T>(value: any) => (value as unknown) as T;

const isObjectButNotDate = (value: any) =>
  typeof value === "object" && !(value instanceof Date);

export const deepEquality: <T>(prev: T, next: T) => boolean = <T>(
  prev: T = {} as T,
  next: T = {} as T
) => {
  const prevKeys = Object.keys(prev) as (keyof T)[];
  const nextKeys = Object.keys(next) as (keyof T)[];

  if (nextKeys.length !== prevKeys.length) return false;

  return nextKeys.every((key) => {
    switch (true) {
      case isDate(next[key]):
        const nextDate = cast<Date>(next[key]);
        const prevDate = cast<Date>(prev[key]);
        return dateEquality(nextDate, prevDate);
      case isObjectButNotDate(next[key]):
        return deepEquality(next[key], prev[key]);
      default:
        return next[key] === prev[key];
    }
  });
};
