import MomentUtils from "@date-io/moment";
import { Grid } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import React, { FC, useState } from "react";

import { NumberField } from "../../../../../../../../../components/NumberField";
import { useServices } from "../../../../../../../../../services";
import { GeneralStateType } from "../../../../models/generalStateType";
import { ValidationNumberField } from "../../../components/ValidationNumberField";
import { ProfileInputProps } from "../../../InputProps";
import { SelectGeneralStateType } from "../../SelectGeneralStateType";

interface BuildingPropertyInfoProps {
  squareMeter: number;
  constructionYear?: number;
  lotsNumber?: number;
  floorsNumber?: number;
  lastRenovationDate?: Date;
  generalState?: GeneralStateType;
  jointOwnership?: boolean;
  ongoingProcedure?: boolean;
}

interface BuildingPropertyInfoValidation {
  squareMeter: boolean;
  lotsNumber: boolean;
  floorsNumber: boolean;
}

const getGlobalValidation = (
  globalValidation: BuildingPropertyInfoValidation
) =>
  globalValidation.squareMeter &&
  globalValidation.lotsNumber &&
  globalValidation.floorsNumber;

export const BuildingPropertyInfoInput: FC<ProfileInputProps<
  BuildingPropertyInfoProps
>> = ({ value, onChange, more, pristine, disabled = false }) => {
  const [globalValidation, setGlobalValidation] = useState({
    floorsNumber: true,
    lotsNumber: true,
    squareMeter: true,
  });

  const {
    l10n: { t },
  } = useServices();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <ValidationNumberField
          disabled={disabled}
          pristine={pristine}
          label={t("Surface totale")}
          onChange={(isValid, newValue) => {
            const _globalValidation = {
              ...globalValidation,
              squareMeter: isValid,
            };
            setGlobalValidation(_globalValidation);
            onChange(
              { ...value, squareMeter: newValue || 0 },
              getGlobalValidation(_globalValidation)
            );
          }}
          value={value.squareMeter || 0}
          required={true}
          adornement={"m\xB2"}
          minLimit={1}
          maxLimit={100000}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ValidationNumberField
          disabled={disabled}
          pristine={pristine}
          label={t("Nombre de lots")}
          onChange={(isValid, newValue) => {
            const _globalValidation = {
              ...globalValidation,
              lotsNumber: isValid,
            };
            setGlobalValidation(_globalValidation);
            onChange(
              { ...value, lotsNumber: newValue },
              getGlobalValidation(_globalValidation)
            );
          }}
          value={value.lotsNumber || 0}
        />
      </Grid>
      {more && (
        <>
          <Grid item xs={12} md={6}>
            <NumberField
              label="Année de construction"
              onChange={(newValue) => {
                onChange({ ...value, constructionYear: newValue }, true);
              }}
              value={value.constructionYear}
              format={"####"}
              adornement={""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ValidationNumberField
              disabled={disabled}
              pristine={pristine}
              label={t("Nombre d'étages total")}
              onChange={(isValid, newValue) => {
                const _globalValidation = {
                  ...globalValidation,
                  floorsNumber: isValid,
                };
                setGlobalValidation(_globalValidation);
                onChange(
                  { ...value, floorsNumber: newValue },
                  getGlobalValidation(_globalValidation)
                );
              }}
              value={value.floorsNumber || 0}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              locale="fr"
              libInstance={moment}
            >
              <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Dernière rénovation"
                invalidDateMessage={"Format de date invalide"}
                minDateMessage={"Date inférieure à la date minimum saisisable"}
                maxDateMessage={"Date supérieure à la date maximum saisisable"}
                format="DD/MM/YYYY"
                value={value.lastRenovationDate || null}
                InputAdornmentProps={{ position: "start" }}
                onChange={(date) =>
                  onChange(
                    {
                      ...value,
                      lastRenovationDate: date ? date.toDate() : undefined,
                    },
                    true
                  )
                }
                onFocus={() => {
                  return;
                }}
                onBlur={() => {
                  return;
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <SelectGeneralStateType
              onChange={(newValue) =>
                onChange({ ...value, generalState: newValue }, true)
              }
              value={value.generalState || "None"}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
