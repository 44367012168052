export interface ProfileLocation {
  codeInsee: string;
  codesPostaux: string[];
  id: string;
  latitude: number;
  longitude: number;
  nom: string;
  polygone: string;
  typeDeZoneDeRechercheEnum?: string;
}

export interface ExtendedProfileLocation extends ProfileLocation {
  address: string;
}

export const hasLocationValueAndNoInvalidGUID = (
  location: ExtendedProfileLocation
) => {
  if (location.id === "") return "La ville est obligatoire.";
  return "";
};

export const hasMandatoryNumberNotEmpty = (value: number) =>
  "*Champ obligatoire.";

export const isMandatory = (value: unknown) => "*Champ obligatoire.";

export const isPositiveNumber = (value: string) => {
  if (value === "") return "";
  const result = Number(value);
  if (isNaN(result) || (!isNaN(result) && result < 0))
    return "Valeur numérique positive obligatoire ";
  return "";
};

export const isNonZeroPositiveNumber = (value: string | number) => {
  const result = Number(value);
  if (isNaN(result) || result <= 0)
    return "Valeur numérique supérieure à zéro obligatoire ";
  return "";
};

export const isPositiveNumberLeastThan100 = (value: string) => {
  if (value === "") return "";
  const result = Number(value);
  if (isNaN(result) || (!isNaN(result) && (result < 0 || result > 100)))
    return "Valeur numérique positive inférieure ou égale à 100 obligatoire ";
  return "";
};

export const isStrictlyPositive = (value?: number) =>
  value && value > 0 ? "" : "Valeur numérique supérieure à zéro obligatoire.";

export const isGreaterThanOrEquals = (min?: number, max?: number) =>
  min && max && max >= min
    ? ""
    : "La valeur Max doit être supérieure ou égale à la valeur Min.";

export const isLessThanOrEqualsThreshold = (
  threshold: number,
  value?: number
) =>
  value && value <= threshold
    ? ""
    : `La valeur doit être inférieure ou égale à ${threshold}`;

export const isNonZeroPositiveNumberOrEmpty = (value: string) => {
  if (value === "") return "";
  const result = Number(value);
  if (isNaN(result) || result <= 0)
    return "Valeur numérique supérieure à zéro obligatoire.";
  return "";
};

const minBuildingYear = 1800;
const maxBuildingYear = new Date().getUTCFullYear();
export const isValidBuildingYear = (value: string) => {
  if (value === "") return "";

  const result = Number(value);
  if (isNaN(result) || (!isNaN(result) && result) <= 0)
    return "Valeur numérique supérieure à zéro obligatoire.";
  if (result < minBuildingYear || result > maxBuildingYear)
    return "Cette valeur n'est pas une année valide.";
  return "";
};
