import { RadioGroup } from "@material-ui/core";
import React from "react";

import { useServices } from "../../../../../../../services";
import { FurnitureType } from "../../models/furnitureType";
import { ComplementIcon } from "../components/RadioChip";
import { SubSectionTitle } from "../components/SectionTitle";
import { SelectChip } from "../SelectChip";

export const SelectFurniture: React.FC<{
  value: FurnitureType;
  onChange: (value: FurnitureType) => void;
  disabled: boolean;
}> = ({ value, onChange }) => {
  const {
    l10n: { t },
  } = useServices();

  const options: FurnitureType[] = ["All", "With", "Without"];

  return (
    <>
      <SubSectionTitle title="Complément :" />
      <RadioGroup
        row
        value={value}
        onChange={(_) => onChange(_.target.value as FurnitureType)}
      >
        {options.map((option) => (
          <SelectChip
            key={option}
            icon={
              <ComplementIcon
                label={t(`furniture.${option}`)}
                checked={false}
              />
            }
            checkedIcon={
              <ComplementIcon label={t(`furniture.${option}`)} checked={true} />
            }
            value={option}
          />
        ))}
      </RadioGroup>
    </>
  );
};
