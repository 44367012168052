//cleanArray removes all duplicated elements
export default function cleanRefinementsArray(array) {
  var i,
    j,
    len = array.length,
    out = [],
    obj = {};
  for (i = 0; i < len; i++) {
    obj[array[i].key] = i;
  }
  for (j in obj) {
    out.push(array[obj[j]]);
  }
  return out;
}
