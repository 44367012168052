import { Grid, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import MapBiens from "components/MapX/Map";
import React, { createRef, useLayoutEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { networkRealEstatesRoutes } from "router/networkRealEstatesRoutes";
import { useMap } from "services/realEstates/useMap";
import ToggleHitsPaneButton from "UI/shared/atoms/buttons/ToggleHitsPaneButton";
import { SearchInput } from "../../organisms/SearchInput";
import { MapTemplateProps } from "../props";
import { RealEstatesMap } from "../SearchResult/map";

const useStyles = makeStyles(() => ({
  toggleButton: {
    position: "relative",
    bottom: "50%",
    left: "98%",
    zIndex: 99,
  },
}));

const calculateListHeightFrom = ({
  top,
  height,
  offsetY,
}: DOMRect & { offsetY: number }) => {
  return `calc(100vh - (${top + height + offsetY}px)`;
};

export const MapTemplate: React.FC<MapTemplateProps> = ({
  searchResponse,
  loadMore,
  onLocationChange,
  onChange,
  params,
  locations,
  displayMode,
  onClear,
}) => {
  const [realEstatesListHeight, setRealEstatesListHeight] = useState<
    string | number
  >(0);

  const [listToggled, setListToggled] = useState(false);
  const searchInputContainer = createRef<HTMLDivElement>();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { mapProps } = useMap({ params, locations });
  const memoizedMap = useMemo(
    () => <MapBiens {...mapProps} containerOffsetY={8} />,
    [mapProps]
  );
  const classes = useStyles();

  useLayoutEffect(() => {
    const inputDimensions = searchInputContainer.current?.getBoundingClientRect();
    if (inputDimensions) {
      const nextHeight = calculateListHeightFrom({
        ...inputDimensions.toJSON(),
        offsetY: 16,
      });

      setRealEstatesListHeight(nextHeight);
    }
  }, []);

  const toggleList = () => {
    setListToggled((toggled) => !toggled);
  };

  const showList = useMemo(() => !isSmallScreen && !listToggled, [
    isSmallScreen,
    listToggled,
  ]);

  const history = useHistory();
  const onClick = (id: string) => {
    history.push(networkRealEstatesRoutes.details(id));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={listToggled ? 12 : 7}>
        {memoizedMap}
        <div className={classes.toggleButton}>
          <ToggleHitsPaneButton
            toggleHandler={toggleList}
            toggled={listToggled}
          />
        </div>
      </Grid>

      {showList && (
        <Grid item md={5}>
          <div ref={searchInputContainer}>
            <SearchInput
              mode={displayMode}
              result={searchResponse}
              locations={locations}
              params={params}
              onChange={onChange}
              onClear={onClear}
              onLocationChange={onLocationChange}
            />
          </div>
          <RealEstatesMap
            items={searchResponse.data}
            loadMore={loadMore}
            height={realEstatesListHeight}
            onClick={onClick}
          />
        </Grid>
      )}
    </Grid>
  );
};
