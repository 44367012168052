import { makeStyles } from "@material-ui/core";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";
import { Row } from "UI/shared/atoms/containers/Row";
import { HorizontalSpace } from "UI/shared/atoms/spacing/HorizontalSpace";
import { SpacedLabelValue14Px } from "UI/shared/molecules/SpacedLabelValue";
import React from "react";
import { MaturityType } from "routes/app/contacts/contact/components/models/maturityType";
import { ProfileOriginType } from "routes/app/contacts/contact/components/models/profileOriginType";
import { Maturity } from "routes/app/contacts/contact/components/Profiles/Maturity";
import { ProfileDates } from "../../molecules/ProfileDates";
import { ProfileOrigin } from "../../molecules/ProfileOrigin";
import { ProfileStatusChip } from "../../molecules/ProfileStatusChip/index.stories";

export interface ProfileStatusCardProps {
  status: "Archived" | "Active";
  updateDate: Date;
  creationDate: Date;
  maturity?: MaturityType;
  origin?: ProfileOriginType;
  reference?: string;
}

const useStyles = makeStyles(() => ({
  card: {
    backgroundColor: "#f2f2f2",
    padding: "5px 5px 5px 5px",
    borderRadius: "0px 10px 10px 10px",
    position: "relative",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  rowLeft: {
    alignItems: "center",
    display: "flex",
    flex: "1 1 50%",
  },
}));

export const ProfileStatusCard: React.FC<ProfileStatusCardProps> = ({
  status,
  creationDate,
  updateDate,
  maturity,
  origin,
  reference,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.card}>
      <div className={classes.container}>
        <div className={classes.rowLeft}>
          <ProfileStatusChip status={status} />
        </div>
        <Maturity value={maturity} withLabel={true} />
        <ProfileOrigin origin={origin} />
        {reference && (
          <PaddingContainer left={16}>
            <Row rowAlignItems="center">
              <span style={{ fontSize: 20, fontWeight: "bold" }}>#</span>
              <HorizontalSpace size={25} />
              <SpacedLabelValue14Px
                label={"Référence : "}
                value={reference}
                spaceSize={5}
              />
            </Row>
          </PaddingContainer>
        )}
        <ProfileDates
          status={status}
          creationDate={creationDate}
          updateDate={updateDate}
        />
      </div>
    </div>
  );
};
