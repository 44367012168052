import { Grid } from "@material-ui/core";
import React, { useMemo } from "react";
import { AgentMatchingAcquirers } from "UI/features/profiles/templates/matching/AgentAcquirersTab";
import { AgentAcquirers } from "UI/features/profiles/types";
import { If } from "UI/shared/atoms/directives/If";

import TitreSection from "../../../../../layoutContents/Fiches/components/Details/components/TitreSection";
import AnnonceTypeSection from "../../../../../routes/app/biens/pige/FicheBienPige/Details/sectionsCategories/AnnonceTypeSection";
import { NoAgentAcquirers } from "../../atoms/matching/NoAgentAcquirers";

interface PigeMatchingProps {
  byAgent: AgentAcquirers;
  typeAnnonce: string;
  datePublication: Date;
  dateMiseAJour: Date;
  estActive: boolean;
}

export const PigeMatching: React.FC<PigeMatchingProps> = ({
  byAgent,
  typeAnnonce,
  datePublication,
  dateMiseAJour,
  estActive,
}) => {
  const agentAcquirersLabel = useMemo(
    () => `Mes acquéreurs (${byAgent.total})`,
    [byAgent.total]
  );

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} lg={4}>
        <AnnonceTypeSection
          typeAnnonce={typeAnnonce}
          datePublication={datePublication}
          dateMiseAJour={dateMiseAJour}
          estActive={estActive}
        />
      </Grid>
      <Grid item xs={12}>
        <TitreSection titre={agentAcquirersLabel} />
        <If condition={byAgent.total === 0}>
          <NoAgentAcquirers />
        </If>
        <If condition={byAgent.total > 0}>
          <AgentMatchingAcquirers {...byAgent} />
        </If>
      </Grid>
    </Grid>
  );
};
