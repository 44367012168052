import { Grid } from "@material-ui/core";
import React from "react";
import { PlaceDto } from "routes/app/contacts/contact/components/models/placeDto";
import { ParkingFeaturesDto } from "routes/app/contacts/contact/components/models/Proposal/Features/Parking/parkingFeaturesDto";
import { SaleParkingFinancialInfoDto } from "routes/app/contacts/contact/components/models/Proposal/ForSale/Parking/saleParkingFinancialInfoDto";
import { SaleParkingProfileReadDto } from "routes/app/contacts/contact/components/models/Proposal/ForSale/Parking/saleParkingProfileReadDto";
import { useServices } from "services";
import { formatPrix } from "utils/formatage";
import { ProfileOriginType } from "../../../../../../../../routes/app/contacts/contact/components/models/profileOriginType";
import { getParkingTypeValue } from "../../../../../../../../routes/app/contacts/contact/components/models/Proposal/Features/Parking/parkingInfoKind";
import { BlocContainer } from "../../../../../../../shared/atoms/containers/BlocContainer";
import { Column } from "../../../../../../../shared/atoms/containers/Column";
import { PaddingContainer } from "../../../../../../../shared/atoms/containers/PaddingContainer";
import { Row } from "../../../../../../../shared/atoms/containers/Row";
import { HorizontalSpace } from "../../../../../../../shared/atoms/spacing/HorizontalSpace";
import { VerticalSpace } from "../../../../../../../shared/atoms/spacing/VerticalSpace";
import { Text12pxBold } from "../../../../../../../shared/atoms/typographies/Text12pxBold";
import { Text14px } from "../../../../../../../shared/atoms/typographies/Text14px";
import { Text14pxBold } from "../../../../../../../shared/atoms/typographies/Text14pxBold";
import { LabelledValue } from "../../../../../../../shared/molecules/LabelledValue";
import { TitleAndHorizontalDivider } from "../../../../../../../shared/molecules/TitleAndHorizontalDivider";
import { ProfileLocalisation } from "../../../../../organisms/ProfileLocalisation";
import { ProfileProject } from "../../../../../organisms/ProfileProject";
import { ProfileStatusCard } from "../../../../../organisms/ProfileStatusCard";

export interface ParkingForSaleProfileDetailsProps {
  readProfile: SaleParkingProfileReadDto;
}

const getPriceSection: (
  a: ParkingFeaturesDto<SaleParkingFinancialInfoDto>
) => Record<string, string> = (features) => ({
  ...{
    "Prix (net vendeur)": `${formatPrix(
      (features.financialInfo.price || 0) /
        (1 + (features.financialInfo.agentFees || 0) / 100)
    )}`,
    "Prix de vente": `${formatPrix(features.financialInfo.price)}`,
    "Taux honoraires": `${features.financialInfo.agentFees || "NC"}%`,
  },
  ...(features.financialInfo.taxProperty && {
    "Taxe foncière": `${formatPrix(features.financialInfo.taxProperty)}/an`,
  }),
  ...(features.financialInfo.condominiumFees && {
    "Charges de copropriété": `${formatPrix(
      features.financialInfo.condominiumFees
    )}/an`,
  }),
});

const getCharacteristicSections: (
  a: ParkingFeaturesDto<SaleParkingFinancialInfoDto>,
  t: (_: string) => string
) => Record<string, Record<string, string | number | undefined>> = (
  features,
  t
) => ({
  "Le bien": {
    Hauteur: features.parkingPropertyInfo.height
      ? `${features.parkingPropertyInfo.height} m`
      : "NC",
    Largeur: features.parkingPropertyInfo.width
      ? `${features.parkingPropertyInfo.width} m`
      : "NC",
    Profondeur: features.parkingPropertyInfo.length
      ? `${features.parkingPropertyInfo.length} m`
      : "NC",
    Surface: `${features.parkingPropertyInfo.squareMeter} m²`,
    Type: getParkingTypeValue(features.parkingKind, t),
  },
  Loué: features.parkingRentedInfo.rented
    ? {
        "Loyer mensuel": `${features.parkingRentedInfo.rent} euros`,
      }
    : { "Loyer mensuel": "NC" },
  Équipements: {
    Prestations: (features.parkingAmenities || [])
      .map((a) => t(`amenity.${a}`))
      .join(", "),
  },
});

export const ParkingForSaleProfileDetails: React.FC<ParkingForSaleProfileDetailsProps> = ({
  readProfile: {
    features,
    archivingDate,
    lastModificationDate,
    maturity,
    origin,
    account,
    creationDate,
    reference,
  },
}) => {
  const {
    l10n: { t },
  } = useServices();

  const priceSection = getPriceSection(features);

  const characteristicSections = getCharacteristicSections(features, t);

  const filteredCharacteristicSections: Record<
    string,
    Record<string, string | number>
  > = Object.keys(characteristicSections).reduce((acc, key) => {
    const property = Object.keys(characteristicSections[key]).reduce(
      (propAcc, propertyKey) => {
        const val = characteristicSections[key][propertyKey];
        return !!val && val !== "NC"
          ? { ...propAcc, [propertyKey]: val }
          : propAcc;
      },
      {}
    );
    return Object.keys(property).length > 0 ? { ...acc, [key]: property } : acc;
  }, {});

  const places: PlaceDto[] = [];
  if (features.address.city?.wkt) places.push(features.address.city);

  return (
    <PaddingContainer left={16} top={16} right={12}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <ProfileStatusCard
            status={archivingDate ? "Archived" : "Active"}
            creationDate={creationDate || lastModificationDate}
            updateDate={archivingDate || lastModificationDate}
            maturity={maturity}
            origin={origin as ProfileOriginType}
            reference={reference}
          />

          <ProfileProject
            separatorSize={20}
            chipDiameter={56}
            profileAccount={account}
            businessType="Vente"
            propertyCategory="Parking"
          />
          <VerticalSpace size={16} />
          <BlocContainer title={t("PRIX")}>
            <Row rowJustify="flex-start">
              <Column itemJustify="flex-end" columnJustify="flex-end">
                {(Object.keys(priceSection) as string[]).map((k) => (
                  <Text14px key={k}>{t(k)}</Text14px>
                ))}
              </Column>
              <HorizontalSpace size={24} />
              <Column itemJustify="flex-start" columnJustify="flex-start">
                {Object.values(priceSection).map((v) => (
                  <Text14pxBold key={v}>{v}</Text14pxBold>
                ))}
              </Column>
            </Row>
          </BlocContainer>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ProfileLocalisation address={features.address} mapHeight={225} />
          <VerticalSpace size={8} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TitleAndHorizontalDivider
            title="CARACTÉRISTIQUES"
            spaceBetween={8}
            dividerColor={"#8E8E8E"}
          />
          {(Object.keys(filteredCharacteristicSections) as string[]).map(
            (section) => {
              return (
                <>
                  <PaddingContainer top={8}>
                    <Text12pxBold>{section}</Text12pxBold>
                  </PaddingContainer>
                  <BlocContainer>
                    {Object.keys(filteredCharacteristicSections[section]).map(
                      (k) => (
                        <LabelledValue
                          key={k}
                          label={t(k)}
                          value={filteredCharacteristicSections[section][k]}
                          size={"14"}
                        />
                      )
                    )}
                  </BlocContainer>
                </>
              );
            }
          )}
        </Grid>
      </Grid>
    </PaddingContainer>
  );
};
