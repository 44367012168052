import { Grid, Typography } from "@material-ui/core";
import { BusinessCenterOutlined } from "@material-ui/icons";
import DataProvider from "components/DataProviderSR";
import { serialize } from "layoutContents/components/HitsContainer/utils/index";
import moment from "moment";
import React from "react";
import routesNames from "router/routesNames";
import CarteConseiller from "routes/app/mon-reseau/conseillers/components/Hit/components/CarteConseiller/index";
import { PanelRechercheGlobale } from "routes/app/recherche-globale/components/PanneauRechercheGlobale";
import { useConseillersListe } from "services/conseillers/useConseillersListe";
import { ElasticResult } from "types";

const NB_MOIS_NOUVEAU_CONSEILLER = 6;

export interface NouveauxConseillersDisplayProps {
  items: Conseiller[];
  onSeeMoreClick: () => void;
}

type Conseiller = {
  id: number;
  bu: string;
  dateAjout: string;
  dateModification: string;
  dateEntreeDansLeReseau: string;
  moisAnciennete: number;
  prenom: string;
  photo: string;
  nom: string;
  civilite: string;
  telephone: string;
  email: string;
  roles: string[];
  specialites: string[];
  ville: string;
  codePostal: string;
  latitude: number;
  longitude: number;
  urlMiniSite: string;
  lienFacebook: string;
  position: { lat: number; lng: number };
};

const entete = {
  sousTitre: "dans mon secteur",
  titre: "Nouveaux Conseillers",
};

export const NouveauxConseillersDisplayed: React.FC<NouveauxConseillersDisplayProps> = ({
  items,
  onSeeMoreClick,
}: NouveauxConseillersDisplayProps) => {
  const responseDisplayed =
    items.length === 0 ? (
      <Typography variant={"body2"}>
        {`Pas de nouveaux conseillers depuis moins de ${NB_MOIS_NOUVEAU_CONSEILLER} mois sur votre secteur.`}
      </Typography>
    ) : (
      <Grid container spacing={1}>
        {items.map((item: Conseiller) => (
          <Grid item xs={12} key={item.id}>
            <CarteConseiller item={item} type={"galerieWithoutWrapper"} />
          </Grid>
        ))}
      </Grid>
    );

  return (
    <PanelRechercheGlobale
      Icone={BusinessCenterOutlined}
      entete={entete}
      voirTout={onSeeMoreClick}
      titreVoirTout={"Voir plus"}
    >
      {responseDisplayed}
    </PanelRechercheGlobale>
  );
};

type Bounds = {
  se: {
    lat: number;
    long: number;
  };
  nw: {
    lat: number;
    long: number;
  };
};

interface NouveauxConseillersProps {
  mapBounds?: Bounds;
}

type Refinement = {
  query: string;
  value: boolean | string | { lat: number; long: number };
};

const defaultTimeParams = {
  dateDentreeMax: moment().format("YYYY-MM-DD"),
  dateDentreeMin: moment()
    .subtract(NB_MOIS_NOUVEAU_CONSEILLER, "months")
    .format("YYYY-MM-DD"),
  page: 1,
  size: 3,
  sortAsc: true,
  sortColumn: "MoisAnciennete",
};

export const NouveauxConseillers: React.FC<NouveauxConseillersProps> = ({
  mapBounds,
}: NouveauxConseillersProps) => {
  const params = React.useMemo(() => {
    const validMapBounds =
      mapBounds && mapBounds.se && mapBounds.nw ? mapBounds : {};

    return {
      ...defaultTimeParams,
      ...validMapBounds,
    };
  }, [JSON.stringify(mapBounds)]);

  const goToAgentsList = () => {
    const paramsToBeSerialized: {
      boundingBox?: Bounds;
      refinements: Refinement[];
    } = {
      boundingBox: mapBounds,
      refinements: [
        { query: "sortColumn", value: params.sortColumn },
        { query: "sortAsc", value: params.sortAsc },
        { query: "dateDentreeMin", value: params.dateDentreeMin },
        { query: "dateDentreeMax", value: params.dateDentreeMax },
      ],
    };

    if (
      mapBounds &&
      mapBounds.hasOwnProperty("se") &&
      mapBounds.hasOwnProperty("nw")
    ) {
      paramsToBeSerialized.refinements.push({
        query: "se",
        value: mapBounds.se,
      });
      paramsToBeSerialized.refinements.push({
        query: "nw",
        value: mapBounds.nw,
      });
    }

    const serializedParams = serialize(paramsToBeSerialized);

    window.open(
      `${routesNames.app}${routesNames.monReseau}${routesNames.conseillers}/map/${serializedParams}`,
      "_blank"
    );
  };

  return (
    <DataProvider
      dataReader={useConseillersListe}
      params={params}
      erreur={{
        mini: true,
        texte: "Serveur non disponible.",
        titre: "Conseillers",
      }}
    >
      {(items: ElasticResult<Conseiller, any>) => (
        <NouveauxConseillersDisplayed
          items={items.data || []}
          onSeeMoreClick={goToAgentsList}
        />
      )}
    </DataProvider>
  );
};
