import axios, { AxiosResponse, CancelToken, ResponseType } from "axios";
import Qs from "qs";
import { useCallback, useContext } from "react";
import { AuthenticationContext } from "services/authentication/authentication";

export interface IHttpService {
  del: <T>(endpoint: string, payload?: Payload) => Promise<AxiosResponse<T>>;
  get: <T>(
    endpoint: string,
    params?: unknown,
    cancelToken?: CancelToken,
    headers?: Record<string, string>
  ) => Promise<AxiosResponse<T>>;
  put: <T>(
    endpoint: string,
    payload?: Payload,
    headers?: Record<string, string>
  ) => Promise<AxiosResponse<T>>;
  post: <T>(
    endpoint: string,
    payload: Payload,
    headers?: Record<string, string>,
    responseType?: ResponseType
  ) => Promise<AxiosResponse<T>>;
}

export type Payload =
  | { contentType: "application/json"; content: unknown }
  | { contentType: "multipart/form-data"; content: FormData };

export const useHttpWithAccessToken = (token?: string): IHttpService => {
  const { access_token: TXAccessToken } = useContext(AuthenticationContext);
  const access_token = token || TXAccessToken;

  return {
    del: useCallback(
      <T>(endpoint: string, payload?: Payload): Promise<AxiosResponse<T>> => {
        return !access_token
          ? new Promise<AxiosResponse<T>>((_) => _)
          : payload
          ? createAxiosInstance().delete<T>(endpoint, {
              data: payload.content,
              headers: createHeaders(true, access_token, payload.contentType),
            })
          : createAxiosInstance().delete<T>(endpoint, {
              headers: createHeaders(true, access_token),
            });
      },
      [access_token]
    ),
    get: useCallback(
      <T>(
        endpoint: string,
        params?: unknown,
        cancelToken?: CancelToken,
        headers?: Record<string, string>
      ): Promise<AxiosResponse<T>> => {
        return !access_token
          ? new Promise<AxiosResponse<T>>((_) => _)
          : createAxiosInstance().get<T>(endpoint, {
              cancelToken: cancelToken,
              headers: createHeaders(true, access_token, undefined, headers),
              params,
              // TODO: this should not exist but there is a global setting on axios
              paramsSerializer: (params) =>
                Qs.stringify(params, { skipNulls: true }),
            });
      },
      [access_token]
    ),
    post: useCallback(
      <T>(
        endpoint: string,
        payload: Payload,
        headers?: Record<string, string>,
        responseType?: ResponseType
      ): Promise<AxiosResponse<T>> => {
        return !access_token
          ? new Promise<AxiosResponse<T>>((_) => _)
          : createAxiosInstance().post<T>(endpoint, payload.content, {
              headers: createHeaders(
                true,
                access_token,
                payload.contentType,
                headers
              ),
              ...(responseType && { responseType }),
            });
      },
      [access_token]
    ),
    put: useCallback(
      <T>(
        endpoint: string,
        payload?: Payload,
        headers?: Record<string, string>
      ): Promise<AxiosResponse<T>> => {
        return !access_token
          ? new Promise<AxiosResponse<T>>((_) => _)
          : createAxiosInstance().put<T>(endpoint, payload?.content, {
              headers: createHeaders(
                true,
                access_token,
                payload?.contentType,
                headers
              ),
            });
      },
      [access_token]
    ),
  };
};

export const useTxHttp = (): IHttpService => {
  return useHttpWithAccessToken();
};

export const useNoAuthHttp = (): Pick<IHttpService, "get"> => {
  return {
    get: <T>(endpoint: string, params?: unknown): Promise<AxiosResponse<T>> =>
      createAxiosInstance().get<T>(endpoint, {
        params,
        // TODO: this should not exist but there is a global setting on axios
        paramsSerializer: (params) => Qs.stringify(params, { skipNulls: true }),
      }),
  };
};

function createHeaders(
  authenticated: boolean,
  access_token: string,
  content_type = "application/json",
  headers?: Record<string, string>
) {
  let _headers: Record<string, string> = { "content-type": content_type };

  if (authenticated) {
    _headers["Authorization"] = `Bearer ${access_token}`;
  }

  if (headers) {
    _headers = { ..._headers, ...headers };
  }

  return _headers;
}

function createAxiosInstance() {
  return axios.create();
}
