import React from "react";
import PropType from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import PathLink from "./PathLink";
import { makeStyles } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.white,
    minWidth: 40,
  },
  iconSelected: {
    color: theme.palette.tonique,
    minWidth: 40,
  },
  listItem: {
    "&:hover": {
      "&::before": {
        backgroundColor: theme.palette.tonique,
        content: "''",
        height: "100%",
        left: 0,
        position: "absolute",
        top: 0,
        width: "5px",
      },
      backgroundColor: `#091722 !important`,
    },
    color: theme.palette.white,
  },
  listItemSelected: {
    "&::before": {
      backgroundColor: theme.palette.tonique,
      content: "''",
      height: "100%",
      left: 0,
      position: "absolute",
      top: 0,
      width: "5px",
    },
    backgroundColor: `${theme.palette.buttonSelected} !important`,
    color: theme.palette.tonique,
  },
  section: {},
  sectionSelected: {
    "&::before": {
      backgroundColor: theme.palette.tonique,
      content: "''",
      height: "100%",
      left: 0,
      position: "absolute",
      top: 0,
      width: "5px",
    },
    backgroundColor: theme.palette.buttonSelected,
  },
  subMenuText: {
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
}));

export default function SubMenu({ route, sidebarState, dispatch, location }) {
  const classes = useStyles();

  function handleClick() {
    dispatch({ load: route.id, type: "clic submenu" });
  }

  let _sectionSelected = false;

  if (route.subList.find((_route) => _route.path === location.pathname))
    _sectionSelected = true;
  return (
    <div style={{ position: "relative" }}>
      <div
        className={_sectionSelected ? classes.sectionSelected : classes.section}
      >
        <ListItem
          button
          onClick={handleClick}
          classes={{
            root: classes.listItem,
            selected: classes.listItemSelected,
          }}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            {route.icon}
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.subMenuText }}
            primary={route.name}
          />
          {sidebarState.currentSubmenu === route.id ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )}
        </ListItem>
        <Collapse
          in={sidebarState.currentSubmenu === route.id}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {route.subList.map(
              (_route) =>
                _route.active && (
                  <PathLink
                    key={_route.name}
                    route={_route}
                    location={location}
                    nested={true}
                    sidebarState={sidebarState}
                    dispatch={dispatch}
                    extraStyle={{
                      linkTextSize: (theme) => ({
                        [theme.breakpoints.down("md")]: {
                          fontSize: "16px",
                        },
                      }),
                    }}
                  />
                )
            )}
          </List>
        </Collapse>
      </div>
    </div>
  );
}

SubMenu.propTypes = {
  handleDrawerOpen: PropType.func,
  route: PropType.object,
};
