import { makeStyles } from "@material-ui/core";
import React, { ReactElement } from "react";

export interface SortOptionLabelAndIconProps {
  isBold: boolean;
  label: string;
  icon: ReactElement;
}

const useStyles = makeStyles(() => ({
  sortItem: {
    "&:hover": {
      background: "transparent",
      fontWeight: "bold!important",
    },
    alignItems: "center",
    display: "flex",
    fontSize: "12px",
  },
}));

export const SortOptionLabelAndIcon: React.FC<SortOptionLabelAndIconProps> = ({
  isBold,
  label,
  icon,
}) => {
  const classes = useStyles();
  return (
    <div
      className={classes.sortItem}
      style={{
        fontWeight: isBold ? "bold" : "normal",
      }}
    >
      {label}
      {icon}
    </div>
  );
};
