import { makeStyles } from "@material-ui/core";
import React from "react";

interface HorizontalDividerProps {
  color: string;
  height: number;
}

const useStyles = makeStyles(() => ({
  divider: {
    width: "100%",
    height: ({ height }: HorizontalDividerProps) => `${height}px`,
    backgroundColor: ({ color }: HorizontalDividerProps) => color,
    opacity: 0.3,
  },
}));

export const HorizontalDivider: React.FC<HorizontalDividerProps> = ({
  color,
  height,
}) => {
  const classes = useStyles({ color, height });
  return <div className={classes.divider} />;
};
