import React, { createContext, useCallback, useEffect, useState } from "react";
import { useFeatureFlags } from "services/featureFlags";
import { useAdConversion } from "services/piges/useAdConversion";
import { usePortfolioSearch } from "services/portfolio/usePortfolioSearch";
import { ConsentStatus } from "types";

import { AddProfileToExistingContactModal } from "../modals/AddProfileToExistingContactModal";
import { CreateContactAndProfileModal } from "../modals/CreateContactAndProfileModal";

export type ModalName =
  | "createContactAndProfile"
  | "addProfileToExistingContact";

export interface AdModalState {
  adId?: string;
  contactId?: string;
  display: boolean;
  pending: boolean;
  hasError: boolean;
}

export type ModalsState = Record<ModalName, AdModalState>;

export type ContactQuery = (
  phoneNumber: string,
  emailAddress: string
) => Promise<string | undefined>;

const defaultModalState = {
  display: false,
  hasError: false,
  pending: false,
};

const nextModalsState = (
  prev: ModalsState,
  modalName: ModalName,
  payload: Partial<AdModalState>
) => {
  return { ...prev, [modalName]: { ...prev[modalName], ...payload } };
};

export interface PigeConversionContextProps {
  handleTransform: (adId: string, email?: string, phoneNumber?: string) => void;
  isConverted: (adId?: string) => boolean;
}

export const PigeConversionContext = createContext<PigeConversionContextProps>(
  {} as PigeConversionContextProps
);

export const PigeConversionContextProvider: React.FC = ({ children }) => {
  const { convertAd, getConvertedAds } = useAdConversion();
  const { getContactIdByPhoneNumberOrEmailAddress } = usePortfolioSearch();
  const { Pige_Conversion } = useFeatureFlags();

  const [modals, setModals] = useState<ModalsState>(() => ({
    addProfileToExistingContact: {
      ...defaultModalState,
    },
    createContactAndProfile: {
      ...defaultModalState,
    },
  }));

  const [adProfiles, setAdProfiles] = useState<string[]>([]);

  const isConverted = (adId?: string) => {
    return !!adId && adProfiles.includes(adId);
  };

  const loadConvertedAds = useCallback(async () => {
    const data = await getConvertedAds();
    setAdProfiles(data);
  }, [getConvertedAds]);

  const handleCloseModal = useCallback(
    (modalName: ModalName) => () => {
      setModals((prev) => nextModalsState(prev, modalName, defaultModalState));
      loadConvertedAds();
    },
    [loadConvertedAds]
  );

  const handleTransform = useCallback(
    async (adId: string, email?: string, phoneNumber?: string) => {
      const existingContactId = await getContactIdByPhoneNumberOrEmailAddress(
        phoneNumber ?? "",
        email ?? ""
      );

      if (existingContactId) {
        setModals((prev) =>
          nextModalsState(prev, "addProfileToExistingContact", {
            adId: adId,
            contactId: existingContactId,
            display: true,
          })
        );

        return;
      }

      setModals((prev) =>
        nextModalsState(prev, "createContactAndProfile", {
          adId: adId,
          display: true,
        })
      );
    },
    [getContactIdByPhoneNumberOrEmailAddress]
  );

  const handleTransformConfirmation = useCallback(
    (
      modalName: ModalName,
      id: string,
      contactId?: string,
      consentStatus: ConsentStatus = "Denied"
    ) => {
      setModals((prev) => nextModalsState(prev, modalName, { pending: true }));

      convertAd(id, contactId, consentStatus).then(
        () => {
          setModals((prev) =>
            nextModalsState(prev, modalName, { pending: false })
          );
          handleCloseModal(modalName)();
        },
        () => {
          setModals((prev) =>
            nextModalsState(prev, modalName, { hasError: true, pending: false })
          );
        }
      );
    },
    [convertAd, handleCloseModal]
  );

  useEffect(() => {
    if (Pige_Conversion) loadConvertedAds();
  }, [Pige_Conversion, loadConvertedAds]);

  return (
    <PigeConversionContext.Provider
      value={{
        handleTransform,
        isConverted,
      }}
    >
      {children}
      <CreateContactAndProfileModal
        isOpen={modals?.createContactAndProfile.display}
        pending={modals?.createContactAndProfile.pending}
        hasError={modals?.createContactAndProfile.hasError}
        confirm={(consentStatus: ConsentStatus) =>
          handleTransformConfirmation(
            "createContactAndProfile",
            modals?.createContactAndProfile.adId ?? "",
            undefined,
            consentStatus
          )
        }
        onClose={handleCloseModal("createContactAndProfile")}
      />
      <AddProfileToExistingContactModal
        isOpen={modals?.addProfileToExistingContact.display}
        confirm={() =>
          handleTransformConfirmation(
            "addProfileToExistingContact",
            modals?.addProfileToExistingContact.adId ?? "",
            modals?.addProfileToExistingContact.contactId
          )
        }
        onClose={handleCloseModal("addProfileToExistingContact")}
        pending={modals?.addProfileToExistingContact.pending}
        hasError={modals?.addProfileToExistingContact.hasError}
      />
    </PigeConversionContext.Provider>
  );
};
