import { Locations } from "components/RefinementField/types";
import { PigesSearchParams } from "services/piges";

function unicodeToA(str: string) {
  return btoa(encodeURIComponent(str));
}
function aToUnicode(str: string) {
  return decodeURIComponent(atob(str));
}
export const paramsToSlug = (
  params: PigesSearchParams,
  locations: Locations
): string => {
  return unicodeToA(JSON.stringify({ locations: locations, params: params }));
};

export const slugToParams = (
  slug: string
): { params: PigesSearchParams; locations: Locations } => {
  return JSON.parse(aToUnicode(slug));
};
