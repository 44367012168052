import { makeStyles, useMediaQuery } from "@material-ui/core";

import React from "react";
import { onboardingLogo } from "assets/images/onboarding";

const useStyle = makeStyles((theme) => ({
  contentContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      justifyContent: "inherit",
      marginTop: "5%",
    },
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
      marginTop: "inherit",
      whiteSpace: ({ isGenericSlide }) => (isGenericSlide ? "pre" : "inherit"),
    },
  },
  contentTextContainer: {
    padding: "0 5%",
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      width: "43vw !important",
    },
    [theme.breakpoints.up("sm")]: {
      width: "20vw !important",
    },
  },
  picture: {
    [theme.breakpoints.down("sm")]: {
      width: ({ smallHeight }) =>
        smallHeight ? "35vw !important" : "60vw !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "35vw !important",
    },
    [theme.breakpoints.up("md")]: {
      width: "40vw !important",
    },
  },
  picturesContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: ({ isGenericSlide }) =>
      isGenericSlide ? "column" : "column-reverse",
    justifyContent: ({ isGenericSlide }) =>
      isGenericSlide ? "flex-end" : "space-between",
    [theme.breakpoints.between("sm", "md")]: {
      paddingTop: ({ isGenericSlide }) => (isGenericSlide ? "7%" : "inherit"),
    },
    [theme.breakpoints.up("md")]: {
      margin: "inherit",
      width: "inherit",
    },
  },
  slideContainer: {
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      display: "flex",
      flexDirection: ({ smallHeight }) => (smallHeight ? "row" : "column"),
      height: "inherit",
      justifyContent: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      height: "100%",
      justifyContent: "space-evenly",
    },
  },
}));

const OnboardingSlide = ({
  isGenericSlide = false,
  picture: { name: pictureName, url: pictureUrl },
  renderContent,
  onClick,
}) => {
  const smallHeight = useMediaQuery("(orientation: landscape)");
  const classes = useStyle({ isGenericSlide, smallHeight });

  return (
    <div className={classes.slideContainer} onClick={onClick}>
      <div className={classes.picturesContainer}>
        {isGenericSlide && (
          <img
            className={classes.logo}
            src={onboardingLogo}
            alt="onboarding logo"
          />
        )}
        <img
          className={classes.picture}
          src={pictureUrl}
          alt={`onboarding ${pictureName}`}
        />
      </div>
      <div className={classes.contentContainer}>
        {!isGenericSlide && (
          <img
            className={classes.logo}
            src={onboardingLogo}
            alt="onboarding logo"
          />
        )}
        <div className={classes.contentTextContainer}>{renderContent()}</div>
      </div>
    </div>
  );
};

export default OnboardingSlide;
