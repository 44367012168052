import { Grid } from "@material-ui/core";
import { PrintablePage } from "components/PrintablePage/PrintablePage";
import TitreSection from "layoutContents/Fiches/components/Details/components/TitreSection";
import { chunk } from "lodash";
import React, { createContext, useContext, useEffect } from "react";
import { ProfileItemSmallCard } from "UI/features/profiles/templates/profile/ProfileItemSmallCard";
import { ProfilesContext } from "../../../../../../../../services/profiles";

interface PrintablePropertySearchProfilesProps {
  contactId: string;
  header: JSX.Element;
  footer: JSX.Element;
}

export const PrintablePropertySearchProfiles = ({
  contactId,
  header,
  footer,
}: PrintablePropertySearchProfilesProps) => {
  const { profiles, getAll } = useContext(ProfilesContext);
  useEffect(() => {
    if (contactId) {
      getAll(contactId)();
    }
  }, [contactId, getAll]);

  return (
    <PrintingContextProvider isPrintingMode={true}>
      {chunk(profiles, 4).map((page, index) => (
        <PrintablePage header={header} footer={footer} key={index}>
          <>
            {index === 0 && <TitreSection titre={"Profils"} />}
            <Grid
              container
              spacing={2}
              justify={"center"}
              alignItems={"flex-start"}
            >
              {page.map((profile) => (
                <Grid key={profile.id} item xs={6}>
                  <ProfileItemSmallCard
                    profileItem={profile}
                    actionsMenu={[]}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        </PrintablePage>
      ))}
    </PrintingContextProvider>
  );
};

interface ContextProps {
  isPrintingMode: boolean;
}

const contextDefaultValue = {
  isPrintingMode: false,
};

export const PrintingContext = createContext<ContextProps>(contextDefaultValue);

interface PrintingContextProviderProps {
  isPrintingMode: boolean;
}

export const PrintingContextProvider: React.FC<PrintingContextProviderProps> = (
  props
) => {
  return (
    <PrintingContext.Provider
      value={{
        isPrintingMode: props.isPrintingMode,
      }}
    >
      {props.children}
    </PrintingContext.Provider>
  );
};
