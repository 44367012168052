import { Dialog, IconButton, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Localisation from "layoutContents/Fiches/components/Localisation";
import React from "react";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  dialogContent: {
    overflow: "hidden",
    padding: 0,
  },
}));

const ModalMap = ({
  point,
  onHide,
  isDisplayed,
  label,
  mapPointLabel,
  mapMode,
  polygon,
}) => {
  const classes = useStyles();
  const renderMapCloseButton = () => (
    <IconButton onClick={onHide} className={classes.closeButton}>
      <Close />
    </IconButton>
  );

  return (
    <Dialog
      fullScreen
      aria-labelledby={label}
      open={isDisplayed}
      onClose={onHide}
      classes={{ paperFullScreen: classes.paperFullScreen }}
    >
      <div className={classes.dialogContent}>
        {/* TODO : we might want to replace this component with a pure ui one */}
        <Localisation
          etiquetteCarte={mapPointLabel}
          mode={mapMode}
          bien={point}
          renderMapCloseButton={renderMapCloseButton}
          isMapFullScreen
          polygon={polygon}
        />
      </div>
    </Dialog>
  );
};

export default ModalMap;
