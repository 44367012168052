import { createMuiTheme } from "@material-ui/core";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";

import { baseTheme, IThemeOptions } from "./main";

export default createMuiTheme(({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    // primary: {
    //   main: ""#303d4a"",
    //   light: blue[300],
    //   dark: blue[700],
    //   transparent: fade(blue[700], 0.6)
    // },
    secondaire: "#e83278",
    secondary: {
      dark: orange[700],
      light: orange[300],
      main: orange[500],
    },
    secondaryLighter: orange[100],
    tertiary: {
      dark: red[300],
      light: red[300],
      main: red[300],
    },
    tonique: "#0093f7",
  },
} as unknown) as IThemeOptions);
