import {
  State,
  ActionPayload,
  DisplayMode,
  MultiplePayloads,
  PayloadByStateKey,
} from "./types";

export const singleUpdateHandler = (state: State) => (
  key: keyof State,
  payload: ActionPayload,
  replace: boolean = false
) => {
  if (key === "displayMode") return { ...state, [key]: payload as DisplayMode };

  return {
    ...state,
    [key]: {
      ...(!replace && state[key]),
      ...(payload as typeof state[typeof key]),
    },
  };
};
