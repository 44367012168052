import { Grid, makeStyles } from "@material-ui/core";
import TimeIcon from "@material-ui/icons/AccessTimeOutlined";
import { Text14px } from "UI/shared/atoms/typographies/Text14px";
import moment from "moment";
import React from "react";

import { ProfileStatusType } from "routes/app/profiles/adapters/profileAdapterDetails";

const useStyles = makeStyles((_) => ({
  leftContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "10px!important",
    width: "50px",
  },
}));

export interface ProfileDatesProps {
  creationDate: Date;
  updateDate: Date;
  status: ProfileStatusType;
}

export const ProfileDates: React.FC<ProfileDatesProps> = ({
  creationDate,
  updateDate,
  status,
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item className={classes.leftContainer}>
        <TimeIcon />
      </Grid>

      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Text14px>
              {"Créé le "}
              {moment(creationDate).format("DD/MM/YYYY")}
            </Text14px>
            <Text14px>
              {status === "Active" ? "Mis à jour le " : "Archivé le "}
              {moment(updateDate).format("DD/MM/YYYY")}
            </Text14px>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
