import { FormControlLabel, Radio } from "@material-ui/core";
import { CSSProperties, makeStyles } from "@material-ui/styles";
import React, { FC, ReactNode } from "react";
import { ITheme } from "theme/ts/main";

export interface SelectOptionProps<T> {
  icon: JSX.Element;
  checkedIcon: JSX.Element;
  value: T;
  disabled?: boolean;
  label?: ReactNode | string;
  itemStyle?: CSSProperties;
}

export const SelectOption: FC<SelectOptionProps<unknown>> = ({
  value,
  checkedIcon,
  icon,
  disabled,
  label,
  itemStyle = { margin: 0 },
}) => (
  <FormControlLabel
    value={value}
    control={
      <Radio
        value={value}
        color="primary"
        icon={Icon(icon)}
        checkedIcon={CheckedIcon(checkedIcon)}
      />
    }
    label={label}
    labelPlacement="bottom"
    style={itemStyle}
    disabled={disabled}
  />
);
const useStylesIcon = makeStyles((theme: ITheme) => {
  const common = {
    alignItems: "center",
    borderRadius: 28,
    display: "flex",
    height: 56,
    justifyContent: "center",
    width: 56,
  };
  return {
    checkedIcon: {
      ...common,
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    icon: {
      ...common,
      borderStyle: "dashed",
      borderWidth: "1px",
    },
  };
});

function CheckedIcon(elem: JSX.Element) {
  const classes = useStylesIcon();
  return <div className={classes.checkedIcon}>{elem}</div>;
}

function Icon(elem: JSX.Element) {
  const classes = useStylesIcon();
  return <div className={classes.icon}>{elem}</div>;
}
