import { useCallback, useEffect, useState } from "react";
import { useFeatureFlags } from "services/featureFlags";

import { useTxHttp } from "../http";
import { useServices } from "../index";
import { NotificationSettingsCenterDto } from "./dto/NotificationSettingsCenterDto";

const FETCH_ERROR_MESSAGE = "Error while fetching notification settings";
const UPDATE_ERROR_MESSAGE =
  "Error while updating current notification settings";

const useNotificationSettingsCenter = () => {
  const { get, put } = useTxHttp();
  const { endpoints } = useServices();

  const { Notifications } = useFeatureFlags();

  const [notificationSettingsCenter, setNotificationSettingsCenter] = useState(
    {} as NotificationSettingsCenterDto
  );

  const [reloadNotifications, setReloadNotifications] = useState(false);

  const getNotificationSettingsCenter = useCallback(
    () =>
      get<NotificationSettingsCenterDto>(
        endpoints.notifications.notificationSettingsCenter
      )
        .then((response) => {
          setNotificationSettingsCenter(response.data);
          setReloadNotifications(false);
        })
        .catch(() => {
          console.error(FETCH_ERROR_MESSAGE);
        }),
    [get]
  );

  const updateNotificationSettingsCenter = useCallback(
    (updated: NotificationSettingsCenterDto) =>
      put<NotificationSettingsCenterDto>(
        endpoints.notifications.updateNotificationSettingsCenter,
        { content: updated, contentType: "application/json" }
      )
        .then((_) => {
          getNotificationSettingsCenter();
          setReloadNotifications(true);
        })
        .catch(() => {
          console.error(UPDATE_ERROR_MESSAGE);
        }),
    [put]
  );

  useEffect(() => {
    if (!Notifications) return;

    const loadNotificationSettingsCenter = async () => {
      await getNotificationSettingsCenter();
    };

    loadNotificationSettingsCenter();
  }, [Notifications]);

  return {
    notificationSettingsCenter,
    reloadNotifications,
    update: updateNotificationSettingsCenter,
  };
};

export default useNotificationSettingsCenter;
