import TitreSection from "../Details/components/TitreSection";
import { Typography } from "@material-ui/core";
import React from "react";

export const DescriptionSection = ({ descriptif }: { descriptif: string }) => (
  <>
    <TitreSection titre="Descriptif" />
    <Typography variant="subtitle1" align="left">
      {descriptif}
    </Typography>
  </>
);
