import React from "react";
import { Button } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import EyeIcon from "@material-ui/icons/RemoveRedEyeOutlined";

export type SeeButtonProps = {
  smaller?: boolean;
  children: JSX.Element | JSX.Element[];
} & ButtonProps;

export default function SeeButton(props: SeeButtonProps) {
  const { children, smaller } = props;
  const defaultProps = {
    ...props,
    size: props.size || "small",
    color: props.color || "primary",
    variant: props.variant || "outlined",
    style: {
      ...props.style,
      height: smaller ? "20px" : props.style?.height,
    },
  };

  return (
    <>
      <Button {...defaultProps}>
        <EyeIcon style={{ marginRight: "5px" }} />
        {children}
      </Button>
    </>
  );
}
