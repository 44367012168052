import { Avatar, Grid, Typography } from "@material-ui/core";
import React from "react";
import { genderAdapter } from "services/portfolio/helpers";
import { ReadContactDto } from "services/portfolio/types";
import { baseTheme } from "theme/main";
import { getInitialsCapitalized } from "utils/formatage";

import { useServices } from "../../../../../../../../../services";

export const PrintableShortContactInfos: React.FC<{
  contact: ReadContactDto;
}> = ({ contact }) => {
  const classes = {
    avatar: {
      backgroundColor: baseTheme.palette.default,
    },
  };

  const {
    l10n: { t },
  } = useServices();

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={2}>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Avatar
            aria-label={contact.identity.firstName}
            style={classes.avatar}
          >
            {getInitialsCapitalized([
              contact.identity.firstName || "",
              contact.identity.lastName || "",
            ])}
          </Avatar>
        </div>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="body1">
          {t(genderAdapter(contact.identity.gender))}
        </Typography>
        <Typography variant="h4">{`${contact.identity.firstName} ${
          contact.identity.lastName?.toUpperCase() || ""
        }`}</Typography>
      </Grid>
    </Grid>
  );
};
