import { Grid, makeStyles, Typography } from "@material-ui/core";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import React from "react";
import { ProfileOriginType } from "routes/app/contacts/contact/components/models/profileOriginType";

import { useServices } from "../../../../../services";

const useStyles = makeStyles((_) => ({
  leftContainer: {
    display: "flex",
    justifyContent: "center",
    width: "50px",
  },
}));

export interface ProfileOriginProps {
  origin?: ProfileOriginType;
}

export const ProfileOrigin: React.FC<ProfileOriginProps> = ({
  origin = "Unknown",
}) => {
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item className={classes.leftContainer}>
        <LocationSearchingIcon />
      </Grid>

      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="body1">
              {t("Source : ")}
              <span style={{ fontWeight: "bold" }}>
                {t(`profileSource.${origin}`)}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
