import {
  AdaptedRefinements,
  MultipleText,
} from "../../../components/RefinementField/types";
import { RequestForInformationsCriteria } from "../../../types";
import { RequestForInformationAggregationsDto } from "../dto/RequestForInformationAggregationsDto";

export const updateRefinementsFromAggregations = (
  prev: AdaptedRefinements<RequestForInformationsCriteria>,
  aggs: RequestForInformationAggregationsDto,
  labelTranslator: (_: string) => string = (_) => _
): AdaptedRefinements<RequestForInformationsCriteria> => {
  return (Object.keys(aggs) as RequestForInformationsCriteria[])
    .filter((k) => prev[k].refinement.kind === "multiple-text")
    .reduce((acc, k) => {
      const relatedRefinementValue = (
        refKey: RequestForInformationsCriteria,
        aggKey: string
      ) =>
        prev[refKey]
          ? (prev[refKey].refinement as MultipleText).value.find(
              (_) => _.value === aggKey
            )
          : null;

      const updated: AdaptedRefinements<RequestForInformationsCriteria> = {
        ...acc,
        [k]: {
          ...acc[k],
          refinement: {
            ...acc[k].refinement,
            value: aggs[k].map(({ key, value }) => ({
              ...acc[k].refinement,
              count: value,
              isRefined: relatedRefinementValue(k, key)?.isRefined || false,
              label: labelTranslator(key),
              value: key,
            })),
          },
        },
      };

      return updated;
    }, prev);
};
