import { makeStyles } from "@material-ui/core/styles";
import { ITheme } from "theme/ts/main";

const useStyles = makeStyles((theme: ITheme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formButtonWrapper: {
    alignSelf: "center",
    display: "flex",
  },
}));

export default useStyles;
