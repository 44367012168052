import { AccessTime } from "@material-ui/icons";
import { Column } from "UI/shared/atoms/containers/Column";
import { Row } from "UI/shared/atoms/containers/Row";
import { LastUpdate } from "UI/shared/atoms/LastUpdate";
import { ReceptionDate } from "UI/shared/atoms/ReceptionDate";
import { Status } from "UI/shared/molecules/Status";
import React from "react";
import {
  ARCHIVED,
  TRANSFORMED,
} from "services/request-for-informations/constants/request-for-informations-status";
import { useStyles } from "../style";

interface RequestForInformationInfosProps {
  status: string;
  receivedAt: string;
  updatedAt: string;
}

export const RequestForInformationInfos: React.FC<RequestForInformationInfosProps> = ({
  status,
  receivedAt,
  updatedAt,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.infos}>
      <div className={classes.status}>
        <Status status={status} />
      </div>
      <Row>
        <div className={classes.infosIcones}>
          <AccessTime className={classes.accessTime} />
        </div>
        <Column>
          <ReceptionDate date={new Date(receivedAt)} />
          {[ARCHIVED, TRANSFORMED].includes(status) && (
            <LastUpdate date={new Date(updatedAt)} />
          )}
        </Column>
      </Row>
    </div>
  );
};
