import React, { useState } from "react";
import { SwipeableDrawer, makeStyles } from "@material-ui/core";
import { SwipeableContainerButton } from "UI/shared/atoms/buttons/SwipeableContainerButton";
import { HeaderWithCloseButton } from "../HeaderWithCloseButton/HeaderWithCloseButton";
import { BorderedContainer } from "UI/shared/atoms/containers/BorderedContainer";
import { useMediaQuery } from "@material-ui/core";

export interface SwipeableContainerProps {
  anchor: "left" | "right" | "top" | "bottom";
  width: number;
  headerTitle: string;
  borderColor: string;
}

const useStyles = makeStyles(() => ({
  content: {
    height: "100vh",
    width: (props: { width: number }) => props.width,
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
    padding: "0 20px",
  },
}));

export const SwipeableContainer: React.FC<SwipeableContainerProps> = ({
  anchor,
  width,
  headerTitle,
  borderColor,
  children,
}) => {
  const mobile = useMediaQuery("(max-width:600px)");
  const newWidth = mobile
    ? window.innerWidth
    : width > window.innerWidth
    ? window.innerWidth
    : width;
  const classes = useStyles({ width: newWidth });
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <SwipeableContainerButton openHandler={() => setIsOpen(true)} />
      <SwipeableDrawer
        anchor={anchor}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
      >
        <BorderedContainer color={borderColor} left={5}>
          <div className={classes.content}>
            <HeaderWithCloseButton
              title={headerTitle}
              closeHandler={() => setIsOpen(false)}
            />
            {children}
          </div>
        </BorderedContainer>
      </SwipeableDrawer>
    </>
  );
};
