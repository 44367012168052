import React from "react";
import Typography from "@material-ui/core/Typography";

interface NameInformationProps {
  value: string;
}

export const NameInformation: React.FC<NameInformationProps> = ({ value }) => {
  return (
    <Typography variant="h4" noWrap style={{ fontWeight: "bold" }}>
      {value ?? "Non renseigné"}
    </Typography>
  );
};
