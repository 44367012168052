interface ProCategories {
  [key: string]: string[];
}
export const professionalCategories: ProCategories = {
  "": [""],
  "Agriculteurs (salari\u00E9s de leur exploitation)": [
    "",
    "Agriculteurs et \u00E9leveurs, salari\u00E9s de leur exploitation",
  ],
  "Artisans (salari\u00E9s de leur entreprise)": [
    "",
    "Artisans salari\u00E9s de leur entreprise",
  ],
  "Cadres administratifs et commerciaux d\u0027entreprises": [
    "",
    "Cadres d\u0027\u00E9tat-major administratifs, financiers, commerciaux des grandes entreprises",
    "Cadres charg\u00E9s d\u0027\u00E9tudes \u00E9conomiques, financi\u00E8res, commerciales",
    "Cadres de l\u0027organisation ou du contr\u00F4le des services administratifs et financiers",
    "Cadres sp\u00E9cialistes des ressources humaines et du recrutement",
    "Cadres sp\u00E9cialistes de la formation",
    "Juristes",
    "Cadres de la documentation, de l\u0027archivage (hors fonction publique)",
    "Cadres des services financiers ou comptables des grandes entreprises",
    "Cadres des autres services administratifs des grandes entreprises",
    "Cadres des services financiers ou comptables des petites et moyennes entreprises",
    "Cadres des autres services administratifs des petites et moyennes entreprises",
    "Cadres de l\u0027exploitation des magasins de vente du commerce de d\u00E9tail",
    "Chefs de produits, acheteurs du commerce et autres cadres de la mercatique",
    "Cadres commerciaux des grandes entreprises (hors commerce de d\u00E9tail)",
    "Cadres commerciaux des petites et moyennes entreprises (hors commerce de d\u00E9tail)",
    "Cadres de la publicit\u00E9",
    "Cadres des relations publiques et de la communication",
    "Cadres des march\u00E9s financiers",
    "Cadres des op\u00E9rations bancaires",
    "Cadres commerciaux de la banque",
    "Chefs d\u0027\u00E9tablissements et responsables de l\u0027exploitation bancaire",
    "Cadres des services techniques des assurances",
    "Cadres des services techniques des organismes de s\u00E9curit\u00E9 sociale et assimil\u00E9s",
    "Cadres de l\u0027immobilier",
    "Cadres de l\u0027h\u00F4tellerie et de la restauration",
  ],
  "Cadres de la fonction publique": [
    "",
    "Personnels de direction de la fonction publique (Etat, collectivit\u00E9s locales, h\u00F4pitaux)",
    "Ing\u00E9nieurs de l\u0027Etat (y.c. ing\u00E9nieurs militaires) et assimil\u00E9s",
    "Ing\u00E9nieurs des collectivit\u00E9s locales et des h\u00F4pitaux",
    "Magistrats*",
    "Inspecteurs et autres personnels de cat\u00E9gorie A des Imp\u00F4ts, du Tr\u00E9sor et des Douanes",
    "Cadres de la Poste*",
    "Cadres administratifs de France T\u00E9l\u00E9com (statut public)",
    "Autres personnels administratifs de cat\u00E9gorie A de l\u0027Etat (hors Enseignement, Patrimoine, Imp\u00F4ts, Tr\u00E9sor, Douanes)",
    "Personnels administratifs de cat\u00E9gorie A des collectivit\u00E9s locales et h\u00F4pitaux publics (hors Enseignement, Patrimoine)",
    "Officiers des Arm\u00E9es et de la Gendarmerie (sauf officiers g\u00E9n\u00E9raux)*",
    "Personnes exer\u00E7ant un mandat politique ou syndical*",
  ],
  Chauffeurs: [
    "",
    "Conducteurs routiers et grands routiers",
    "Conducteurs de v\u00E9hicule routier de transport en commun",
    "Conducteurs de taxi",
    "Conducteurs de voiture particuli\u00E8re",
    "Conducteurs livreurs, coursiers",
    "Conducteurs de v\u00E9hicule de ramassage des ordures m\u00E9nag\u00E8res",
  ],
  "Chefs d\u0027entreprise de 10 salari\u00E9s ou plus (salari\u00E9s de leur entreprise)": [
    "",
    "Chefs de grande entreprise de 500 salari\u00E9s et plus",
    "Chefs de moyenne entreprise, de 50 \u00E0 499 salari\u00E9s",
    "Chefs d\u0027entreprise du b\u00E2timent et des travaux publics, de 10 \u00E0 49 salari\u00E9s",
    "Chefs d\u0027entreprise de l\u0027industrie ou des transports, de 10 \u00E0 49 salari\u00E9s",
    "Chefs d\u0027entreprise commerciale, de 10 \u00E0 49 salari\u00E9s",
    "Chefs d\u0027entreprise de services, de 10 \u00E0 49 salari\u00E9s",
  ],
  "Clerg\u00E9, religieux": [
    "",
    "Clerg\u00E9 s\u00E9culier",
    "Clerg\u00E9 r\u00E9gulier",
  ],
  "Commer\u00E7ants et assimil\u00E9s (salari\u00E9s de leur entreprise)": [
    "",
    "Commer\u00E7ants et assimil\u00E9s, salari\u00E9s de leur entreprise",
  ],
  "Contrema\u00EEtres, agents de ma\u00EEtrise (ma\u00EEtrise administrative exclue)": [
    "",
    "Contrema\u00EEtres et agents d\u0027encadrement (non cadres) en agriculture, sylviculture",
    "Ma\u00EEtres d\u0027\u00E9quipage de la marine marchande et de la p\u00EAche",
    "Conducteurs de travaux (non cadres)",
    "Chefs de chantier (non cadres)",
    "Agents de ma\u00EEtrise en fabrication de mat\u00E9riel \u00E9lectrique, \u00E9lectronique",
    "Agents de ma\u00EEtrise en construction m\u00E9canique, travail des m\u00E9taux",
    "Agents de ma\u00EEtrise en fabrication : agroalimentaire, chimie, plasturgie, pharmacie.",
    "Agents de ma\u00EEtrise en fabrication : m\u00E9tallurgie, mat\u00E9riaux lourds et autres industries de transformation",
    "Agents de ma\u00EEtrise et techniciens en production et distribution d\u0027\u00E9nergie, eau, chauffage",
    "Agents de ma\u00EEtrise en fabrication des autres industries (imprimerie, mat\u00E9riaux souples, ameublement et bois)",
    "Agents de ma\u00EEtrise en maintenance, installation en \u00E9lectricit\u00E9 et \u00E9lectronique",
    "Agents de ma\u00EEtrise en maintenance, installation en \u00E9lectrom\u00E9canique",
    "Agents de ma\u00EEtrise en maintenance, installation en m\u00E9canique",
    "Agents de ma\u00EEtrise en entretien g\u00E9n\u00E9ral, installation, travaux neufs (hors m\u00E9canique, \u00E9lectrom\u00E9canique, \u00E9lectronique)",
    "Responsables d\u0027entrep\u00F4t, de magasinage",
    "Responsables du tri, de l\u0027emballage, de l\u0027exp\u00E9dition et autres responsables de la manutention",
    "Ma\u00EEtrise de restauration  : cuisine/production",
    "Ma\u00EEtrise de restauration  : gestion d\u0027\u00E9tablissement",
  ],
  "Employ\u00E9s administratifs d\u0027entreprise": [
    "",
    "Agents d\u0027accueil qualifi\u00E9s, h\u00F4tesses d\u0027accueil et d\u0027information",
    "Agents d\u0027accueil non qualifi\u00E9s",
    "Standardistes, t\u00E9l\u00E9phonistes",
    "Secr\u00E9taires",
    "Dactylos, st\u00E9nodactylos (sans secr\u00E9tariat), op\u00E9rateurs de traitement de texte",
    "Employ\u00E9s qualifi\u00E9s des services comptables ou financiers",
    "Employ\u00E9s non qualifi\u00E9s des services comptables ou financiers",
    "Employ\u00E9s qualifi\u00E9s des services du personnel et des services juridiques",
    "Employ\u00E9s qualifi\u00E9s des services commerciaux des entreprises (hors vente)",
    "Employ\u00E9s administratifs qualifi\u00E9s des autres services des entreprises",
    "Employ\u00E9s administratifs non qualifi\u00E9s",
    "Employ\u00E9s et op\u00E9rateurs d\u0027exploitation en informatique",
    "Employ\u00E9s administratifs des services techniques de la banque",
    "Employ\u00E9s des services commerciaux de la banque",
    "Employ\u00E9s des services techniques des assurances",
    "Employ\u00E9s des services techniques des organismes de s\u00E9curit\u00E9 sociale et assimil\u00E9s",
    "Contr\u00F4leurs des transports (personnels roulants)",
    "Agents des services commerciaux des transports de voyageurs et du tourisme",
    "Employ\u00E9s administratifs d\u0027exploitation des transports de marchandises",
    "H\u00F4tesses de l\u0027air et stewards",
    "Autres agents et h\u00F4tesses d\u0027accompagnement (transports, tourisme)",
  ],
  "Employ\u00E9s civils et agents de service de la fonction publique": [
    "",
    "Employ\u00E9s de la Poste",
    "Employ\u00E9s de France T\u00E9l\u00E9com (statut public)",
    "Agents de constatation ou de recouvrement des Imp\u00F4ts, du Tr\u00E9sor, des Douanes*",
    "Adjoints administratifs de la fonction publique (y c. enseignement)*",
    "Adjoints administratifs de l\u0027Etat et assimil\u00E9s (sauf Poste, France T\u00E9l\u00E9com)",
    "Adjoints administratifs des collectivit\u00E9s locales",
    "Adjoints administratifs des h\u00F4pitaux publics",
    "Agents administratifs de la fonction publique (y c. enseignement)*",
    "Agents administratifs de l\u0027Etat et assimil\u00E9s (sauf Poste, France T\u00E9l\u00E9com)",
    "Agents administratifs des collectivit\u00E9s locales",
    "Agents administratifs des h\u00F4pitaux publics",
    "Agents de service des \u00E9tablissements primaires",
    "Agents de service des autres \u00E9tablissements d\u0027enseignement",
    "Agents de service de la fonction publique (sauf \u00E9coles, h\u00F4pitaux)",
    "Agents de service hospitaliers",
    "Aides-soignants",
    "Assistants dentaires, m\u00E9dicaux et v\u00E9t\u00E9rinaires, aides de techniciens m\u00E9dicaux",
    "Auxiliaires de pu\u00E9riculture",
    "Aides m\u00E9dico-psychologiques",
    "Ambulanciers salari\u00E9s",
  ],
  "Employ\u00E9s de commerce": [
    "",
    "Employ\u00E9s de libre service du commerce et magasiniers",
    "Caissiers de magasin",
    "Vendeurs polyvalents des grands magasins",
    " Autres vendeurs non sp\u00E9cialis\u00E9s",
    "Vendeurs en alimentation",
    "Vendeurs en ameublement, d\u00E9cor, \u00E9quipement du foyer",
    "Vendeurs en droguerie, bazar, quincaillerie, bricolage",
    "Vendeurs du commerce de fleurs",
    "Vendeurs en habillement et articles de sport",
    "Vendeurs en produits de beaut\u00E9, de luxe (hors biens culturels) et optique",
    "Vendeurs de biens culturels (livres, disques, multim\u00E9dia, objets d\u0027art)",
    "Vendeurs de tabac, presse et articles divers",
    "Pompistes et g\u00E9rants de station-service (salari\u00E9s ou mandataires)",
    "Vendeurs par correspondance, t\u00E9l\u00E9vendeurs",
    "Vendeurs en gros de biens d\u0027\u00E9quipement, biens interm\u00E9diaires",
  ],
  "Ing\u00E9nieurs et cadres techniques d\u0027entreprises": [
    "",
    "Directeurs techniques des grandes entreprises",
    "Ing\u00E9nieurs et cadres d\u0027\u00E9tude et d\u00E9veloppement de l\u0027agriculture, la p\u00EAche, les eaux et for\u00EAts",
    "Ing\u00E9nieurs et cadres de production et d\u0027exploitation de l\u0027agriculture, la p\u00EAche, les eaux et for\u00EAts",
    "Ing\u00E9nieurs et cadres d\u0027\u00E9tude du b\u00E2timent et des travaux publics",
    "Architectes salari\u00E9s",
    "Ing\u00E9nieurs, cadres de chantier et conducteurs de travaux (cadres) du b\u00E2timent et des travaux publics",
    "Ing\u00E9nieurs et cadres technico-commerciaux en b\u00E2timent, travaux publics",
    "Ing\u00E9nieurs et cadres d\u0027\u00E9tude, recherche et d\u00E9veloppement en \u00E9lectricit\u00E9, \u00E9lectronique",
    "Ing\u00E9nieurs et cadres de fabrication en mat\u00E9riel \u00E9lectrique, \u00E9lectronique",
    "Ing\u00E9nieurs et cadres technico-commerciaux en mat\u00E9riel \u00E9lectrique ou \u00E9lectronique professionnel",
    "Ing\u00E9nieurs et cadres d\u0027\u00E9tude, recherche et d\u00E9veloppement en m\u00E9canique et travail des m\u00E9taux",
    "Ing\u00E9nieurs et cadres de fabrication en m\u00E9canique et travail des m\u00E9taux",
    "Ing\u00E9nieurs et cadres technico-commerciaux en mat\u00E9riel m\u00E9canique professionnel",
    "Ing\u00E9nieurs et cadres d\u0027\u00E9tude, recherche et d\u00E9veloppement des industries de transformation (agroalimentaire, chimie, m\u00E9tallurgie, mat\u00E9riaux lourds)",
    "Ing\u00E9nieurs et cadres de fabrication des industries de transformation (agroalimentaire, chimie, m\u00E9tallurgie, mat\u00E9riaux lourds)",
    "Ing\u00E9nieurs et cadres technico-commerciaux des industries de transformations (biens interm\u00E9diaires)",
    "Ing\u00E9nieurs et cadres d\u0027\u00E9tude, recherche et d\u00E9veloppement de la distribution d\u0027\u00E9nergie, eau",
    "Ing\u00E9nieurs et cadres d\u0027\u00E9tude, recherche et d\u00E9veloppement des autres industries (imprimerie, mat\u00E9riaux souples, ameublement et bois)",
    "Ing\u00E9nieurs et cadres de la production et de la distribution d\u0027\u00E9nergie, eau",
    "Ing\u00E9nieurs et cadres de fabrication des autres industries (imprimerie, mat\u00E9riaux souples, ameublement et bois)",
    "Ing\u00E9nieurs et cadres des achats et approvisionnements industriels",
    "Ing\u00E9nieurs et cadres de la logistique, du planning et de l\u0027ordonnancement",
    "Ing\u00E9nieurs et cadres des m\u00E9thodes de production",
    "Ing\u00E9nieurs et cadres du contr\u00F4le-qualit\u00E9",
    "Ing\u00E9nieurs et cadres de la maintenance, de l\u0027entretien et des travaux neufs",
    "Ing\u00E9nieurs et cadres techniques de l\u0027environnement",
    "Ing\u00E9nieurs et cadres d\u0027\u00E9tude, recherche et d\u00E9veloppement en informatique",
    "Ing\u00E9nieurs et cadres d\u0027administration, maintenance, support et services aux utilisateurs en informatique",
    "Chefs de projets informatiques, responsables informatiques",
    "Ing\u00E9nieurs et cadres technico-commerciaux en informatique et t\u00E9l\u00E9communications",
    "Ing\u00E9nieurs et cadres sp\u00E9cialistes des t\u00E9l\u00E9communications",
    "Ing\u00E9nieurs et cadres techniques de l\u0027exploitation des transports",
    "Officiers et cadres navigants techniques et commerciaux de l\u0027aviation civile",
    "Officiers et cadres navigants techniques de la marine marchande",
  ],
  "Ouvriers agricoles et assimil\u00E9s": [
    "",
    "Conducteurs d\u0027engin agricole ou forestier",
    "Ouvriers de l\u0027\u00E9levage",
    "Ouvriers du mara\u00EEchage ou de l\u0027horticulture",
    "Ouvriers de la viticulture ou de l\u0027arboriculture fruiti\u00E8re",
    "Ouvriers agricoles sans sp\u00E9cialisation particuli\u00E8re",
    "Ouvriers de l\u0027exploitation foresti\u00E8re ou de la sylviculture",
    "Marins-p\u00EAcheurs et ouvriers de l\u0027aquaculture",
  ],
  "Ouvriers non qualifi\u00E9s de type artisanal": [
    "",
    "Ouvriers non qualifi\u00E9s du gros oeuvre du b\u00E2timent",
    "Ouvriers non qualifi\u00E9s du second oeuvre du b\u00E2timent",
    "M\u00E9talliers, serruriers, r\u00E9parateurs en m\u00E9canique non qualifi\u00E9s",
    "Apprentis boulangers, bouchers, charcutiers",
    "Nettoyeurs",
    "Ouvriers non qualifi\u00E9s de l\u0027assainissement et du traitement des d\u00E9chets",
    "Ouvriers non qualifi\u00E9s divers de type artisanal",
  ],
  "Ouvriers non qualifi\u00E9s de type industriel": [
    "",
    "Ouvriers non qualifi\u00E9s des travaux publics de l\u0027Etat et des collectivit\u00E9s locales",
    "Ouvriers non qualifi\u00E9s des travaux publics et du travail du b\u00E9ton",
    "Aides-mineurs, ouvriers non qualifi\u00E9s de l\u0027extraction",
    "Ouvriers non qualifi\u00E9s de l\u0027\u00E9lectricit\u00E9 et de l\u0027\u00E9lectronique",
    "Ouvriers de production non qualifi\u00E9s travaillant par enl\u00E8vement de m\u00E9tal",
    "Ouvriers de production non qualifi\u00E9s travaillant par formage de m\u00E9tal",
    "Ouvriers non qualifi\u00E9s de montage, contr\u00F4le en m\u00E9canique et travail des m\u00E9taux",
    "Ouvriers de production non qualifi\u00E9s : chimie, pharmacie, plasturgie",
    "Ouvriers de production non qualifi\u00E9s de la transformation des viandes",
    "Autres ouvriers de production non qualifi\u00E9s : industrie agroalimentaire",
    "Ouvriers de production non qualifi\u00E9s : m\u00E9tallurgie, production verri\u00E8re, c\u00E9ramique, mat\u00E9riaux de construction",
    "Ouvriers de production non qualifi\u00E9s : industrie lourde du bois, fabrication des papiers et cartons",
    "Ouvriers de production non qualifi\u00E9s du textile et de la confection, de la tannerie-m\u00E9gisserie et du travail du cuir",
    "Ouvriers de production non qualifi\u00E9s du travail du bois et de l\u0027ameublement",
    "Ouvriers de production non qualifi\u00E9s de l\u0027imprimerie, presse, \u00E9dition",
    "Manutentionnaires non qualifi\u00E9s",
    "D\u00E9m\u00E9nageurs (hors chauffeurs-d\u00E9m\u00E9nageurs), non qualifi\u00E9s",
    "Ouvriers du tri, de l\u0027emballage, de l\u0027exp\u00E9dition, non qualifi\u00E9s",
    "Agents non qualifi\u00E9s des services d\u0027exploitation des transports",
    "Ouvriers non qualifi\u00E9s divers de type industriel",
  ],
  "Ouvriers qualifi\u00E9s de la manutention, du magasinage et du transport": [
    "",
    "Conducteurs d\u0027engin lourd de levage",
    "Conducteurs d\u0027engin lourd de manoeuvre",
    "Ouvriers qualifi\u00E9s de la manutention, conducteurs de chariots \u00E9l\u00E9vateurs, caristes",
    "Dockers",
    "Magasiniers qualifi\u00E9s",
    "Conducteurs qualifi\u00E9s d\u0027engins de transport guid\u00E9s (sauf remont\u00E9es m\u00E9caniques)",
    "Conducteurs qualifi\u00E9s de syst\u00E8mes de remont\u00E9es m\u00E9caniques",
    "Autres agents et ouvriers qualifi\u00E9s (s\u00E9dentaires) des services d\u0027exploitation des transports",
    "Matelots de la marine marchande",
    "Capitaines et matelots timoniers de la navigation fluviale",
  ],
  "Ouvriers qualifi\u00E9s de type artisanal": [
    "",
    "Jardiniers",
    "Ma\u00E7ons qualifi\u00E9s",
    "Ouvriers qualifi\u00E9s du travail de la pierre",
    "Charpentiers en bois qualifi\u00E9s",
    "Menuisiers qualifi\u00E9s du b\u00E2timent",
    "Couvreurs qualifi\u00E9s",
    "Plombiers et chauffagistes qualifi\u00E9s",
    "Peintres et ouvriers qualifi\u00E9s de pose de rev\u00EAtements sur supports verticaux",
    "Soliers moquetteurs et ouvriers qualifi\u00E9s de pose de rev\u00EAtements souples sur supports horizontaux",
    "Monteurs qualifi\u00E9s en agencement, isolation",
    "Ouvriers qualifi\u00E9s d\u0027entretien g\u00E9n\u00E9ral des b\u00E2timents",
    "Electriciens qualifi\u00E9s de type artisanal (y.c. b\u00E2timent)",
    "D\u00E9panneurs qualifi\u00E9s en radiot\u00E9l\u00E9vision, \u00E9lectrom\u00E9nager, mat\u00E9riel \u00E9lectronique (salari\u00E9s)",
    "Electriciens, \u00E9lectroniciens qualifi\u00E9s en maintenance entretien, r\u00E9paration : automobile",
    "Electriciens, \u00E9lectroniciens qualifi\u00E9s en maintenance, entretien : \u00E9quipements non industriels",
    "Carrossiers d\u0027automobiles qualifi\u00E9s",
    "M\u00E9talliers, serruriers qualifi\u00E9s",
    "M\u00E9caniciens qualifi\u00E9s en maintenance, entretien, r\u00E9paration : automobile",
    "M\u00E9caniciens qualifi\u00E9s de maintenance, entretien : \u00E9quipements non industriels",
    "Tailleurs et couturi\u00E8res qualifi\u00E9s, ouvriers qualifi\u00E9s du travail des \u00E9toffes (sauf fabrication de v\u00EAtements), ouvriers qualifi\u00E9s de type artisanal du travail du cuir",
    "Bouchers (sauf industrie de la viande)",
    "Charcutiers (sauf industrie de la viande)",
    "Boulangers, p\u00E2tissiers (sauf activit\u00E9 industrielle)",
    "Cuisiniers et commis de cuisine",
    "Modeleurs (sauf modeleurs de m\u00E9tal), mouleurs-noyauteurs \u00E0 la main, ouvriers qualifi\u00E9s du travail du verre ou de la c\u00E9ramique \u00E0 la main",
    "Ouvriers d\u0027art",
    "Ouvriers et techniciens des spectacles vivants et audiovisuels",
    "Ouvriers qualifi\u00E9s divers de type artisanal",
  ],
  "Ouvriers qualifi\u00E9s de type industriel": [
    "",
    "Chefs d\u0027\u00E9quipe du gros oeuvre et des travaux publics",
    "Ouvriers qualifi\u00E9s du travail du b\u00E9ton",
    "Conducteurs qualifi\u00E9s d\u0027engins de chantiers du b\u00E2timent et des travaux publics",
    "Ouvriers des travaux publics en installations \u00E9lectriques et de t\u00E9l\u00E9communications",
    "Autres ouvriers qualifi\u00E9s des travaux publics",
    "Ouvriers qualifi\u00E9s des travaux publics (salari\u00E9s de l\u0027Etat et des collectivit\u00E9s locales)",
    "Mineurs de fond qualifi\u00E9s et autres ouvriers qualifi\u00E9s des industries d\u0027extraction (carri\u00E8res, p\u00E9trole, gaz...)",
    "Op\u00E9rateurs qualifi\u00E9s sur machines automatiques en production \u00E9lectrique ou \u00E9lectronique",
    "Monteurs c\u00E2bleurs qualifi\u00E9s en \u00E9lectricit\u00E9",
    "C\u00E2bleurs qualifi\u00E9s en \u00E9lectronique (prototype, unit\u00E9, petite s\u00E9rie)",
    "Autres monteurs c\u00E2bleurs en \u00E9lectronique",
    "Bobiniers qualifi\u00E9s",
    "Plateformistes, contr\u00F4leurs qualifi\u00E9s de mat\u00E9riel \u00E9lectrique ou \u00E9lectronique",
    "Chaudronniers-t\u00F4liers industriels, op\u00E9rateurs qualifi\u00E9s du travail en forge, conducteurs qualifi\u00E9s d\u0027\u00E9quipement de formage, traceurs qualifi\u00E9s",
    "Tuyauteurs industriels qualifi\u00E9s",
    "Op\u00E9rateurs qualifi\u00E9s sur machine de soudage",
    "Soudeurs manuels",
    "Op\u00E9rateurs qualifi\u00E9s d\u0027usinage des m\u00E9taux travaillant \u00E0 l\u0027unit\u00E9 ou en petite s\u00E9rie, moulistes qualifi\u00E9s",
    "Op\u00E9rateurs qualifi\u00E9s d\u0027usinage des m\u00E9taux sur autres machines (sauf moulistes)",
    "Monteurs, metteurs au point tr\u00E8s qualifi\u00E9s d\u0027ensembles m\u00E9caniques travaillant \u00E0 l\u0027unit\u00E9 ou en petite s\u00E9rie",
    "Monteurs qualifi\u00E9s d\u0027ensembles m\u00E9caniques travaillant en moyenne ou en grande s\u00E9rie",
    "Monteurs qualifi\u00E9s en structures m\u00E9talliques",
    "Ouvriers qualifi\u00E9s de contr\u00F4le et d\u0027essais en m\u00E9canique",
    "Ouvriers qualifi\u00E9s des traitements thermiques et de surface sur m\u00E9taux",
    "Autres m\u00E9caniciens ou ajusteurs qualifi\u00E9s (ou sp\u00E9cialit\u00E9 non reconnue)",
    "Pilotes d\u0027installation lourde des industries de transformation : agroalimentaire, chimie, plasturgie, \u00E9nergie",
    "Ouvriers qualifi\u00E9s et agents qualifi\u00E9s de laboratoire : agroalimentaire, chimie, biologie, pharmacie",
    "Autres op\u00E9rateurs et ouvriers qualifi\u00E9s de la chimie (y.c. pharmacie) et de la plasturgie",
    "Op\u00E9rateurs de la transformation des viandes",
    "Autres op\u00E9rateurs travaillant sur installations ou machines : industrie agroalimentaire (hors transformation des viandes)",
    "Autres ouvriers de production qualifi\u00E9s ne travaillant pas sur machine : industrie agroalimentaire (hors transformation des viandes)",
    "Ouvriers qualifi\u00E9s des autres industries (eau, gaz, \u00E9nergie, chauffage)",
    "Pilotes d\u0027installation lourde des industries de transformation : m\u00E9tallurgie, production verri\u00E8re, mat\u00E9riaux de construction",
    "Autres op\u00E9rateurs et ouvriers qualifi\u00E9s : m\u00E9tallurgie, production verri\u00E8re, mat\u00E9riaux de construction",
    "Op\u00E9rateurs et ouvriers qualifi\u00E9s des industries lourdes du bois et de la fabrication du papier-carton",
    "Op\u00E9rateurs qualifi\u00E9s du textile et de la m\u00E9gisserie",
    "Ouvriers qualifi\u00E9s de la coupe des v\u00EAtements et de l\u0027habillement, autres op\u00E9rateurs de confection qualifi\u00E9s",
    "Ouvriers qualifi\u00E9s du travail industriel du cuir",
    "Ouvriers qualifi\u00E9s de scierie, de la menuiserie industrielle et de l\u0027ameublement",
    "Ouvriers de la photogravure et des laboratoires photographiques et cin\u00E9matographiques",
    "Ouvriers de la composition et de l\u0027impression, ouvriers qualifi\u00E9s de la brochure, de la reliure et du fa\u00E7onnage du papier-carton",
    "M\u00E9caniciens qualifi\u00E9s de maintenance, entretien : \u00E9quipements industriels",
    "Electrom\u00E9caniciens, \u00E9lectriciens qualifi\u00E9s d\u0027entretien : \u00E9quipements industriels",
    "R\u00E9gleurs qualifi\u00E9s d\u0027\u00E9quipements de fabrication (travail des m\u00E9taux, m\u00E9canique)",
    "R\u00E9gleurs qualifi\u00E9s d\u0027\u00E9quipements de fabrication (hors travail des m\u00E9taux et m\u00E9canique)",
    "Ouvriers qualifi\u00E9s de l\u0027assainissement et du traitement des d\u00E9chets",
    "Agents qualifi\u00E9s de laboratoire (sauf chimie, sant\u00E9)",
    "Ouvriers qualifi\u00E9s divers de type industriel",
  ],
  "Personnels des services directs aux particuliers": [
    "",
    "Serveurs, commis de restaurant, gar\u00E7ons qualifi\u00E9s",
    "Serveurs, commis de restaurant, gar\u00E7ons non qualifi\u00E9s",
    "Aides de cuisine, apprentis de cuisine et employ\u00E9s polyvalents de la restauration",
    "Employ\u00E9s de l\u0027h\u00F4tellerie : r\u00E9ception et hall",
    "Employ\u00E9s d\u0027\u00E9tage et employ\u00E9s polyvalents de l\u0027h\u00F4tellerie",
    "Manucures, esth\u00E9ticiens ",
    "Coiffeurs salari\u00E9s",
    "Assistantes maternelles, gardiennes d\u0027enfants, familles d\u0027accueil",
    "Aides \u00E0 domicile, aides m\u00E9nag\u00E8res, travailleuses familiales",
    "Employ\u00E9s de maison et personnels de m\u00E9nage chez des particuliers",
    "Concierges, gardiens d\u0027immeubles",
    "Employ\u00E9s des services divers",
  ],
  "Policiers, militaires et agents de surveillance": [
    "",
    "Agents de police de l\u0027Etat*",
    "Agents des polices municipales*",
    "Surveillants de l\u0027administration p\u00E9nitentiaire*",
    "Gendarmes (de grade inf\u00E9rieur \u00E0 adjudant)*",
    "Sergents et sous-officiers de grade \u00E9quivalent des Arm\u00E9es (sauf pompiers militaires)*",
    "Hommes du rang (sauf pompiers militaires)*",
    "Pompiers (y.c. pompiers militaires)",
    "Agents techniques forestiers, gardes des espaces naturels",
    "Agents de surveillance du patrimoine et des administrations",
    "Agents civils de s\u00E9curit\u00E9 et de surveillance",
    "Convoyeurs de fonds, gardes du corps, enqu\u00EAteurs priv\u00E9s et m\u00E9tiers assimil\u00E9s (salari\u00E9s)",
  ],
  "Professeurs des \u00E9coles, instituteurs et professions assimil\u00E9es": [
    "",
    "Instituteurs",
    "Professeurs des \u00E9coles",
    "Professeurs d\u0027enseignement g\u00E9n\u00E9ral des coll\u00E8ges",
    "Professeurs de lyc\u00E9e professionnel",
    "Ma\u00EEtres auxiliaires et professeurs contractuels de l\u0027enseignement secondaire",
    "Conseillers principaux d\u0027\u00E9ducation",
    "Surveillants et aides-\u00E9ducateurs des \u00E9tablissements d\u0027enseignement",
    "Moniteurs d\u0027\u00E9cole de conduite",
    "Formateurs et animateurs de formation continue",
    "Moniteurs et \u00E9ducateurs sportifs, sportifs professionnels",
    "Sous-biblioth\u00E9caires, cadres interm\u00E9diaires du patrimoine",
  ],
  "Professeurs, professions scientifiques": [
    "",
    "Professeurs agr\u00E9g\u00E9s et certifi\u00E9s de l\u0027enseignement secondaire",
    "Chefs d\u0027\u00E9tablissement de l\u0027enseignement secondaire et inspecteurs",
    "Professeurs et ma\u00EEtres de conf\u00E9rences",
    "Professeurs agr\u00E9g\u00E9s et certifi\u00E9s en fonction dans l\u0027enseignement sup\u00E9rieur",
    "Personnel enseignant temporaire de l\u0027enseignement sup\u00E9rieur",
    "Directeurs et charg\u00E9s de recherche de la recherche publique",
    "Ing\u00E9nieurs d\u0027\u00E9tude et de recherche de la recherche publique",
    "Allocataires de la recherche publique",
    "Psychologues sp\u00E9cialistes de l\u0027orientation scolaire et professionnelle",
    "M\u00E9decins hospitaliers sans activit\u00E9 lib\u00E9rale",
    "M\u00E9decins salari\u00E9s non hospitaliers",
    "Internes en m\u00E9decine, odontologie et pharmacie",
    "Pharmaciens salari\u00E9s",
  ],
  "Professions de l\u0027information, des arts et des spectacles": [
    "",
    "Biblioth\u00E9caires, archivistes, conservateurs et autres cadres du patrimoine",
    "Journalistes (y c. r\u00E9dacteurs en chef)",
    "Auteurs litt\u00E9raires, sc\u00E9naristes, dialoguistes",
    "Directeurs de journaux, administrateurs de presse, directeurs d\u0027\u00E9ditions (litt\u00E9raire, musicale, audiovisuelle et multim\u00E9dia)",
    "Directeurs, responsables de programmation et de production de l\u0027audiovisuel et des spectacles",
    "Cadres artistiques et technico-artistiques de la r\u00E9alisation de l\u0027audiovisuel et des spectacles",
    "Artistes plasticiens",
    "Artistes de la musique et du chant",
    "Artistes dramatiques",
    "Artistes de la danse",
    "Artistes du cirque et des spectacles divers",
    "Professeurs d\u0027art (hors \u00E9tablissements scolaires)",
  ],
  "Professions interm\u00E9diaires administratives de la fonction publique": [
    "",
    "Professions interm\u00E9diaires de la Poste",
    "Professions interm\u00E9diaires administratives de France T\u00E9l\u00E9com (statut public)",
    "Contr\u00F4leurs des Imp\u00F4ts, du Tr\u00E9sor, des Douanes et assimil\u00E9s*",
    "Ing\u00E9nieurs du contr\u00F4le de la navigation a\u00E9rienne*",
    "Autres personnels administratifs de cat\u00E9gorie B de l\u0027Etat (hors Enseignement, Patrimoine, Imp\u00F4ts, Tr\u00E9sor, Douanes)",
    "Professions interm\u00E9diaires administratives des collectivit\u00E9s locales",
    "Professions interm\u00E9diaires administratives des h\u00F4pitaux",
    "Inspecteurs et officiers de police*",
    "Adjudants-chefs, adjudants et sous-officiers de rang sup\u00E9rieur de l\u0027Arm\u00E9e et de la Gendarmerie*",
  ],
  "Professions interm\u00E9diaires administratives et commerciales des entreprises": [
    "",
    "Secr\u00E9taires de direction, assistants de direction (non cadres)",
    "Secr\u00E9taires de niveau sup\u00E9rieur (non cadres, hors secr\u00E9taires de direction)",
    "Ma\u00EEtrise et techniciens des services financiers ou comptables",
    "Ma\u00EEtrise et techniciens administratifs des services juridiques ou du personnel",
    "Ma\u00EEtrise et techniciens administratifs des autres services administratifs",
    "Chefs de petites surfaces de vente",
    "Ma\u00EEtrise de l\u0027exploitation des magasins de vente",
    "Acheteurs non class\u00E9s cadres, aides-acheteurs",
    "Animateurs commerciaux des magasins de vente, marchandiseurs (non cadres)",
    "Autres professions interm\u00E9diaires commerciales (sauf techniciens des forces de vente)",
    "Techniciens commerciaux et technico-commerciaux, repr\u00E9sentants en informatique",
    "Techniciens commerciaux et technico-commerciaux, repr\u00E9sentants en biens d\u0027\u00E9quipement, en biens interm\u00E9diaires, commerce interindustriel (hors informatique)",
    "Techniciens commerciaux et technico-commerciaux, repr\u00E9sentants en biens de consommation aupr\u00E8s d\u0027entreprises",
    "Techniciens commerciaux et technico-commerciaux, repr\u00E9sentants en services aupr\u00E8s d\u0027entreprises ou de professionnels (hors banque, assurance, informatique)",
    "Techniciens commerciaux et technico-commerciaux, repr\u00E9sentants aupr\u00E8s de particuliers (hors banque, assurance, informatique)",
    "Assistants de la publicit\u00E9, des relations publiques",
    "Interpr\u00E8tes, traducteurs",
    "Concepteurs et assistants techniques des arts graphiques, de la mode et de la d\u00E9coration salari\u00E9s",
    "Assistants techniques de la r\u00E9alisation des spectacles vivants et audiovisuels salari\u00E9s",
    "Photographes",
    "Responsables commerciaux et administratifs des transports de voyageurs et du tourisme (non cadres)",
    "Responsables commerciaux et administratifs des transports de marchandises (non cadres)",
    "Responsables d\u0027exploitation des transports de voyageurs et de marchandises (non cadres)",
    "Charg\u00E9s de client\u00E8le bancaire",
    "Techniciens des op\u00E9rations bancaires",
    "Professions interm\u00E9diaires techniques et commerciales des assurances",
    "Professions interm\u00E9diaires techniques des organismes de s\u00E9curit\u00E9 sociale",
    "Ma\u00EEtrise de restauration : salle et service",
    "Ma\u00EEtrise de l\u0027h\u00E9bergement : hall et \u00E9tages",
  ],
  "Professions interm\u00E9diaires de la sant\u00E9 et du travail social": [
    "",
    "Cadres infirmiers et assimil\u00E9s",
    "Infirmiers psychiatriques",
    "Pu\u00E9ricultrices",
    "Infirmiers sp\u00E9cialis\u00E9s (autres qu\u0027infirmiers psychiatriques et pu\u00E9ricultrices)",
    "Sages-femmes salari\u00E9es",
    "Infirmiers en soins g\u00E9n\u00E9raux, salari\u00E9s",
    "Masseurs-kin\u00E9sith\u00E9rapeutes r\u00E9\u00E9ducateurs, salari\u00E9s",
    "Autres sp\u00E9cialistes de la r\u00E9\u00E9ducation, salari\u00E9s",
    "Techniciens m\u00E9dicaux",
    "Opticiens lunetiers et audioproth\u00E9sistes salari\u00E9s",
    "Autres sp\u00E9cialistes de l\u0027appareillage m\u00E9dical salari\u00E9s",
    "Pr\u00E9parateurs en pharmacie",
    "Cadres de l\u0027intervention socio-\u00E9ducative",
    "Assistants de service social",
    "Conseillers en \u00E9conomie sociale familiale",
    "Educateurs sp\u00E9cialis\u00E9s",
    "Moniteurs \u00E9ducateurs",
    "Educateurs techniques sp\u00E9cialis\u00E9s, moniteurs d\u0027atelier",
    "Educateurs de jeunes enfants",
    "Directeurs de centres socioculturels et de loisirs",
    "Animateurs socioculturels et de loisirs",
  ],
  "Professions lib\u00E9rales (exerc\u00E9es sous statut de salari\u00E9)": [
    "",
    "Chirurgiens dentistes",
    "Psychologues, psychanalystes, psychoth\u00E9rapeutes (non m\u00E9decins)",
    "V\u00E9t\u00E9rinaires",
    "Avocats",
  ],
  "Techniciens (sauf techniciens tertiaires)": [
    "",
    "Techniciens d\u0027\u00E9tude et de conseil en agriculture, eaux et for\u00EAt",
    "Techniciens d\u0027exploitation et de contr\u00F4le de la production en agriculture, eaux et for\u00EAt",
    "Dessinateurs en b\u00E2timent, travaux publics",
    "G\u00E9om\u00E8tres, topographes",
    "M\u00E9treurs et techniciens divers du b\u00E2timent et des travaux publics",
    "Techniciens des travaux publics de l\u0027Etat et des collectivit\u00E9s locales",
    "Dessinateurs en \u00E9lectricit\u00E9, \u00E9lectrom\u00E9canique et \u00E9lectronique",
    "Techniciens de recherche-d\u00E9veloppement et des m\u00E9thodes de fabrication en \u00E9lectricit\u00E9, \u00E9lectrom\u00E9canique et \u00E9lectronique",
    "Techniciens de fabrication et de contr\u00F4le-qualit\u00E9 en \u00E9lectricit\u00E9, \u00E9lectrom\u00E9canique et \u00E9lectronique",
    "Dessinateurs en construction m\u00E9canique et travail des m\u00E9taux",
    "Techniciens de recherche-d\u00E9veloppement et des m\u00E9thodes de fabrication en construction m\u00E9canique et travail des m\u00E9taux",
    "Techniciens de fabrication et de contr\u00F4le-qualit\u00E9 en construction m\u00E9canique et travail des m\u00E9taux",
    "Techniciens de recherche-d\u00E9veloppement et des m\u00E9thodes de production des industries de transformation",
    "Techniciens de production et de contr\u00F4le-qualit\u00E9 des industries de transformation",
    "Assistants techniques, techniciens de l\u0027imprimerie et de l\u0027\u00E9dition",
    "Techniciens de l\u0027industrie des mat\u00E9riaux souples, de l\u0027ameublement et du bois",
    "Techniciens de la logistique, du planning et de l\u0027ordonnancement",
    "Techniciens d\u0027installation et de maintenance des \u00E9quipements industriels (\u00E9lectriques, \u00E9lectrom\u00E9caniques, m\u00E9caniques, hors informatique)",
    "Techniciens d\u0027installation et de maintenance des \u00E9quipements non industriels (hors informatique et t\u00E9l\u00E9communications)",
    "Techniciens de l\u0027environnement et du traitement des pollutions",
    "Techniciens d\u0027\u00E9tude et de d\u00E9veloppement en informatique",
    "Techniciens de production, d\u0027exploitation en informatique",
    "Techniciens d\u0027installation, de maintenance, support et services aux utilisateurs en informatique",
    "Techniciens des t\u00E9l\u00E9communications et de l\u0027informatique des r\u00E9seaux",
    "Techniciens des laboratoires de recherche publique ou de l\u0027enseignement",
    "Experts salari\u00E9s de niveau technicien, techniciens divers",
  ],
};
