import { MatchingProfileRequestDto } from "services/matching/MatchingProfileRequestDto";

import { ForRentApartmentFeaturesDto } from "../../../../models/Proposal/ForRent/Apartment/forRentApartmentFeaturesDto";

export default (
  features: ForRentApartmentFeaturesDto
): MatchingProfileRequestDto => {
  return new MatchingProfileRequestDto({
    budget: features.financialInfo.rentChargesExcluded,
    cityId: features.address.city?.id,
    constructionYear: features.propertyInfo.buildingInfo.yearBuilt,
    nbRooms: features.parts.summaries.rooms?.count,
    profileKind: "Rent",
    propertyKind: "Appartement",
    surface: features.propertyInfo.squareMeter,
    withElevator: features.amenities
      ? features.amenities.indexOf("Elevator") >= 0
      : undefined,
    withParking: features.parts?.summaries?.parkings?.count
      ? features.parts.summaries.parkings.count > 0
      : undefined,
    withPool: undefined,
  });
};
