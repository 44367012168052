import { ReadContactDto, WriteContactDto } from "./types";

export const contactReadToWriteDto = (c: ReadContactDto): WriteContactDto => {
  if (c.identity.professionalInfos?.professionalCategory === "") {
    c.identity.professionalInfos.profession = "";
  }
  return {
    addressCity: c.address?.city?.id || "",
    addressDepartementId: c.address?.departement?.id || "",
    addressDepartementName: c.address?.departement?.name || "",
    addressRegionId: c.address?.region?.id || "",
    addressRegionName: c.address?.region?.name || "",
    addressSupplement:
      (c.address?.city?.id !== "" ? c.address?.supplement : "") || "",
    addressValue: (c.address?.city?.id !== "" ? c.address?.value : "") || "",

    birthPlace: c.identity.birthPlace?.id || "",
    consentStatus: c.consentStatus,
    identity: {
      ...c.identity,
      firstName: c.identity.firstName || "",
      lastName: c.identity.lastName || "",
    },
    notes: c.notes,
    source: c.source,
    status: c.status,
  };
};

export const contactReadDtoAdapter = (
  contact: ReadContactDto
): ReadContactDto => ({
  ...contact,
  hasForSaleProfile: contact.saleProfilesCount > 0,
  hasSearchProfile: contact.searchProfilesCount > 0,
});
