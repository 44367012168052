type BienProps = any;

export const smallPrintablePropertyCardAdapter = (bien: BienProps) => ({
  bu: bien.bu,
  exclusivite:
    bien.optionMandatEnum === "Exclusif" || bien.exclusivite === true,
  familleBienEnum: bien.typeBienNom || bien.familleBienEnum,
  id: bien.id,
  latitude: bien.lat || bien.latitude,
  localisation: bien.localisation,
  longitude: bien.long || bien.longitude,
  nbChambres: bien.nbChambres,
  nbPieces: bien.nbPieces,
  neuf: bien.neuf,
  nombrePhotos: bien.photoGuid.length,
  photoPrincipale: bien.photoGuid.length > 0 ? bien.photoGuid[0] : "",
  picturesIds: bien.photoGuid,
  prestige: bien.prestige,
  prixNormalized: bien.prix,
  surface: bien.surface,
  typeAffaireEnum: bien.typeAffaireEnum,
  villeCodePostal: bien.localisation.codePostal,
  villeNom: bien.localisation.villeNom,
  tvb: !!bien.isTvb,
  isCE: bien.isCE,
  underOfferToSell: bien.underOfferToSell,
  hasOffer: bien.hasOffer,
});
