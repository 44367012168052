import { makeStyles } from "@material-ui/core/styles";
import { ITheme } from "theme/ts/main";

const useStyles = makeStyles((theme: ITheme) => ({
  boutonRadio: {
    color: "red",
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    color: theme.palette.tonique,
    left: "50%",
    marginLeft: -12,
    marginTop: -12,
    position: "absolute",
    top: "50%",
  },
  buttonWrapper: {
    margin: 0,
    position: "relative",
  },
  formulaire: {
    width: "100%",
    // "@media(max-width: 600px)": {
    //   padding: 15,
    // },
  },
  notchedOutlineNotRequired: {
    borderColor: "inherit",
    borderWidth: "inherit",
  },
  notchedOutlineRequired: {
    borderColor: "#303d4a",
    borderWidth: 2,
  },
  parkingType: {
    padding: "15px 0px",
  },
  selectTextField: {
    marginRight: "15px",
    marginTop: "8px",
    width: "40%",
  },
  textField: {
    marginTop: "8px",
    width: "100%",
  },
}));

export default useStyles;
