import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import useNotifier from "../useNotifier";

const variantIcon = {
  error: ErrorIcon,
  info: InfoIcon,
  success: CheckCircleIcon,
  warning: WarningIcon,
};
const styles = makeStyles((theme) => ({
  error: {
    backgroundColor: theme.palette.error.main,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    marginRight: theme.spacing(1),
    opacity: 0.9,
  },
  info: {
    backgroundColor: theme.palette.info.main,
  },
  message: {
    alignItems: "center",
    display: "flex",
  },
  progress: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
}));

const CloseBtn = (props) => {
  const { onClose, classes } = props;
  return (
    <IconButton href="" aria-label="Close" color="inherit" onClick={onClose}>
      <CloseIcon className={classes.icon} />
    </IconButton>
  );
};

const Display = (props) => {
  const { classes, variant, message, withloader } = props;
  const Icon = variantIcon[variant];
  return (
    <span id="client-snackbar" className={classes.message}>
      {withloader && (
        <CircularProgress
          className={classes.progress}
          color={"inherit"}
          size={25}
        />
      )}
      <Icon className={clsx(classes.icon, classes.iconVariant)} />
      {message}
    </span>
  );
};

export const NotifierSnackbarContent = React.forwardRef((props, ref) => {
  const classes = styles();
  const { className, variant } = props;
  const { hideSnack } = useNotifier();
  return (
    <SnackbarContent
      ref={ref}
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={<Display {...props} classes={classes} />}
      action={[
        <CloseBtn
          {...props}
          classes={classes}
          key={"close"}
          onClose={hideSnack}
        />,
      ]}
    />
  );
});

NotifierSnackbarContent.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
};

// eslint-disable-next-line react/prop-types
export const SimpleSnackbarContent = ({ variant, message, onClose }) => {
  const classes = styles();
  return (
    <SnackbarContent
      className={clsx(classes[variant])}
      aria-describedby="client-snackbar"
      message={
        <Display classes={classes} variant={variant} message={message} />
      }
      action={<CloseBtn classes={classes} key={"close"} onClose={onClose} />}
    />
  );
};
