import { Grid } from "@material-ui/core";
import {
  LocationItem,
  Locations,
  Refinement,
} from "components/RefinementField/types";
import React, { FC, useMemo } from "react";
import { DisplayMode } from "routes/app/real-estates/containers/network/types";
import { RealEstatesSearchParams } from "services/realEstates/types";
import { RealEstatesSearchResult } from "types";
import { Criterias } from "UI/shared/molecules/Criterias";
import { SortInput, SortInputProps } from "UI/shared/molecules/SortInput";

import { Searchbar } from "../SearchBar";
import styles from "./SearchInput.module.css";
import { getSortFields } from "./sortFields";
import { toParams } from "./toParams";
import { toRefinements } from "./toRefinements";

export interface SearchInputProps {
  result: RealEstatesSearchResult;
  params: RealEstatesSearchParams;
  locations?: Locations;

  onChange(params: RealEstatesSearchParams): void;
  onClear(): void;
  onLocationChange?(
    value: {
      villes: LocationItem[];
      departements: LocationItem[];
      regions: LocationItem[];
      quartiers: LocationItem[];
    },
    isDeletion?: boolean
  ): void;

  className?: string;
  mode: DisplayMode;
}

const selectedOptionFor = (
  params: RealEstatesSearchParams,
  direction: string,
  defaultOption: string
) => {
  const hasOptionFromParams =
    params.sortAsc !== undefined && params.sortColumn !== undefined;

  return hasOptionFromParams
    ? `${params.sortColumn} ${direction}`
    : defaultOption;
};

const sortDirectionFor = (params: RealEstatesSearchParams) =>
  params.sortAsc ? "ascending" : "descending";

const defaultOption = "DateModification descending";

export type RealEstatesCriteria =
  | "fullText"
  | "nbPiecesRange"
  | "familleBiens"
  | "typeAchat"
  | "typeAffaires"
  | "constructionYear"
  | "prix"
  | "bus"
  | "prixMax"
  | "surfaceHabitable"
  | "surfaceTerrain"
  | "withParking"
  | "withElevator"
  | "withPool"
  | "withSingleStorey"
  | "withNotes"
  | "dateMaj"
  | "dateMiseEnVente"
  | "sort"
  | "locations";

export const SearchInput: FC<SearchInputProps> = (props) => {
  const refinements = toRefinements(
    props.result,
    props.params,
    props.locations
  );

  const onChange = ({
    key,
    value,
  }: {
    key: RealEstatesCriteria;
    value: Refinement;
  }) => {
    if (key === "locations") {
      const villes = (value as Locations).value?.villes;
      const departements = (value as Locations).value?.departements;
      const regions = (value as Locations).value?.regions;
      const quartiers = (value as Locations).value?.quartiers;

      props.onLocationChange?.({ departements, quartiers, regions, villes });
    }
    props.onChange({ ...props.params, ...toParams(key, value) });
  };

  const sortOptions = getSortFields();
  const sortDirection = sortDirectionFor(props.params);

  const selectedOption = useMemo(
    () => selectedOptionFor(props.params, sortDirection, defaultOption),
    [props.params, sortDirection]
  );

  const sortInputProps: SortInputProps = {
    onChange,
    options: sortOptions,
    selectedOption,
    ...(props.mode === "map" && { customSize: 12 }),
  };

  return (
    <div className={props.className}>
      <Grid container>
        <Grid item xs={12}>
          <Searchbar
            className={styles.searchbar}
            refinements={refinements}
            onChange={onChange}
            total={props.result.total}
            displayMode={props.mode}
          />
        </Grid>
        <Grid item xs={12}>
          <Criterias
            refinements={refinements}
            onChange={onChange}
            onClear={props.onClear}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <SortInput {...sortInputProps} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
