import { useServices } from "../../../../services";
import { useTxHttp } from "../../../../services/http";

export const useMonBienEnVente = () => {
  const { get } = useTxHttp();
  const { endpoints } = useServices();
  return {
    getRealEstate: (id: string) => {
      return get(endpoints.mesBiensEnVente.rechercheParId(id)).then(
        (_) => _.data
      );
    },
  };
};
