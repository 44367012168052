import { useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LoadingSquare from "components/Loading/LoadingSquare";
import MiniCarteInfo from "components/MiniCarteInfo";
import useNotifier from "components/Notifier/useNotifier";
import TitrePage from "components/TitrePage";
import React, { useCallback, useEffect, useState } from "react";
import { useServices } from "services";
import { Campaign } from "services/campagnes/types";
import { ITheme } from "theme/ts/main";
import { CampaignsList } from "UI/features/campaigns/templates/CampaignsList";
import { MarketingCampaignsPageKind } from "UI/features/campaigns/types";
import { CustomErrorPanel } from "UI/shared/templates/Panels/ErrorPanel";

import { useStyles } from "./styles";

interface State {
  campaigns: Campaign[];
  loading: boolean;
  error: string;
}

interface MarketingCampaignsProps {
  kind?: MarketingCampaignsPageKind;
}

export const Campagnes: React.FC<MarketingCampaignsProps> = ({
  kind = "Gallery",
}) => {
  const [state, setState] = useState<State>({
    campaigns: [],
    error: "",
    loading: false,
  });
  const {
    l10n: { t },
    campaigns: { getCurrentCampaigns, subscribe, unsubscribe },
  } = useServices();

  const theme: ITheme = useTheme();

  const { showSnack } = useNotifier();

  const classes = useStyles();

  const onGetCampaigns = useCallback(
    async ({ loader }: { loader: boolean }) => {
      setState((prev) => ({
        ...prev,
        error: "",
        loading: loader,
      }));
      try {
        const campaigns = await getCurrentCampaigns();
        setState((prev) => ({
          ...prev,
          campaigns: campaigns ?? [],
          loading: false,
        }));
      } catch (ex) {
        showSnack({
          message: t("Impossible de charger les campagnes."),
          variant: "error",
        });
        setState((prev) => ({
          ...prev,
          error: (ex as Error).message,
          loading: false,
        }));
      }
    },
    []
  );

  const onSubscribe = useCallback(
    async (campaignId: string) => {
      try {
        await subscribe(campaignId);
        await onGetCampaigns({ loader: false });
        showSnack({
          message: t("Vous êtes abonné."),
          variant: "success",
        });
      } catch (ex) {
        showSnack({
          message: t("Abonnement impossible."),
          variant: "error",
        });
      }
    },
    [showSnack, subscribe, t]
  );

  const onUnsubscribe = useCallback(
    async (campaignId: string) => {
      try {
        await unsubscribe(campaignId);
        await onGetCampaigns({ loader: false });
        showSnack({
          message: t("Vous êtes désabonné."),
          variant: "success",
        });
      } catch (ex) {
        showSnack({
          message: t("Désabonnement impossible."),
          variant: "error",
        });
      }
    },
    [showSnack, t, unsubscribe]
  );

  useEffect(() => {
    (async () => {
      await onGetCampaigns({ loader: true });
    })();
  }, [onGetCampaigns]);

  return (
    <div className={classes.root}>
      {getHeader(
        kind,
        t,
        theme.palette.yellow,
        state.campaigns.filter((c) => c.state !== "NotSubscribed").length,
        state.campaigns.length
      )}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={2} justify="center" alignItems="center">
            {getContent(
              state.loading,
              state.error,
              state.campaigns,
              onSubscribe,
              onUnsubscribe,
              kind
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const getContent = (
  loading: boolean,
  error: string,
  campaigns: Campaign[],
  subscribe: (campaignId: string) => Promise<void>,
  unsubscribe: (campaignId: string) => Promise<void>,
  kind: MarketingCampaignsPageKind
) => {
  if (loading) return <LoadingSquare />;
  if (error !== "") return <NoContactCustomPanel />;
  if (campaigns.length < 1) return <NoContactCustomPanel />;
  return (
    <CampaignsList
      campaigns={campaigns}
      subscribe={subscribe}
      unsubscribe={unsubscribe}
      kind={kind}
    />
  );
};

const NoContactCustomPanel = () => {
  return (
    <>
      <CustomErrorPanel title="Pas d'accès aux campagnes marketing">
        <Typography
          variant={"h3"}
          style={{ marginTop: "10px", textAlign: "left" }}
        >
          Afin d&apos;avoir accès aux campagnes marketing , vous devez avoir au
          moins un contact éligible créé il y a plus de 24h. <br />
          Pour cela, assurez-vous d&apos;avoir :
        </Typography>
        <ul style={{ listStyle: "none", textAlign: "left" }}>
          <li>
            <Typography variant={"h3"}>
              - soit un contact avec un profil TremplinX,
            </Typography>
          </li>
          <li>
            <Typography variant={"h3"}>
              - soit une affaire de recherche ou de vente dans iBoost/ETO.
            </Typography>
          </li>
        </ul>
        <Typography
          variant={"h3"}
          style={{ marginTop: "25px", textAlign: "left" }}
        >
          Si le problème persiste, veuillez contacter l&apos;assistance.
        </Typography>
      </CustomErrorPanel>
    </>
  );
};

const getHeader = (
  kind: MarketingCampaignsPageKind,
  t: (word: string) => string,
  backgroundColor: string,
  subscribedCampaignsCount: number,
  campaignsCount: number
) =>
  kind === "Gallery" ? (
    <TitrePage
      categorie="Marketing distribué"
      page={t("Campagnes nationales")}
      filePath={"/static/markdowns/CRM/marketingDistribue.md"}
    />
  ) : (
    <div style={{ marginBottom: "24px" }}>
      <div
        style={{
          backgroundColor: backgroundColor,
          display: "flex",
          height: "160px",
          justifyContent: "flex-end",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%",
          zIndex: -1,
        }}
      >
        &nbsp;
      </div>
      <TitrePage
        categorie="Marketing distribué"
        page={t("Statistiques")}
        filePath={"/static/markdowns/CRM/marketingDistribueStats.md"}
      />
      <div style={{ marginTop: "24px", width: "195px" }}>
        <MiniCarteInfo
          Icon={undefined}
          valeur={
            <span>
              <span style={{ fontSize: "30px" }}>
                {subscribedCampaignsCount}
              </span>
              <span style={{ fontSize: "20px" }}>
                &nbsp;/&nbsp;{campaignsCount}
              </span>
            </span>
          }
          labelPrimaire={"Campagnes nationales activées"}
          labelSecondaire={""}
          onClick={() => {
            return;
          }}
          isGlobalSearch={false}
        />
      </div>
    </div>
  );
