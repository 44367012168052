import { Paper, Tooltip } from "@material-ui/core";
import { CropFree } from "@material-ui/icons";
import React from "react";

export const ResetMapButton: React.FC<{
  tooltip: string;
  reset: () => void;
}> = ({ tooltip, reset }) => (
  <Paper
    elevation={1}
    style={{
      background: "white",
      borderRadius: "50%",
      cursor: "pointer",
      display: "flex",
      padding: 10,
      position: "absolute",
      right: 10,
      top: 10,
      zIndex: 900,
    }}
    onClick={reset}
  >
    <Tooltip title={tooltip}>
      <CropFree />
    </Tooltip>
  </Paper>
);
