import { makeStyles } from "@material-ui/core";
import { Text16px } from "UI/shared/atoms/typographies/Text16px";
import { Text16pxBold } from "UI/shared/atoms/typographies/Text16pxBold";
import React from "react";

import { useServices } from "../../../../../../../services";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
}));
export const ArchivedProfilesIncentive: React.FC = () => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();
  return (
    <div className={classes.container}>
      <Text16pxBold>{t("Aucun profil archivé actuellement.")}</Text16pxBold>
      <Text16px>
        {t(
          "Archivez régulièrement les profils qui peuvent l'être afin de maintenir des rapprochements de qualité."
        )}
      </Text16px>
      <Text16px>
        {t(
          "Les profils archivés peuvent être réactivés à tout moment et sont toujours ciblés par les campagnes marketing activées."
        )}
      </Text16px>
    </div>
  );
};
