import React, { useContext } from "react";
import {
  RealEstatePropertyCard,
  RealEstatePropertyCardProps,
} from "UI/features/realEstates/organisms/RealEstatePropertyCard";
import { InfiniteScrollList } from "UI/shared/organisms/InfiniteScrollList/InfiniteScrollList";
import { AgentRealEstatesContext } from ".";

const SCROLL_CONTAINER = "agent-matching-small-infinite-scroll-view";
export const AgentRealEstatesSmall: React.FC = () => {
  const { realEstates = [], loadMore } = useContext(AgentRealEstatesContext);
  return (
    <InfiniteScrollList
      pageSize={10}
      loadMore={loadMore}
      horizontal={true}
      containerId={SCROLL_CONTAINER}
    >
      {realEstates.map((realEstate: RealEstatePropertyCardProps) => (
        <div key={realEstate.id} style={{ margin: 5, minWidth: 300 }}>
          <RealEstatePropertyCard {...realEstate} />
        </div>
      ))}
    </InfiniteScrollList>
  );
};
