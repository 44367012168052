import React from "react";
import { withStyles, Checkbox } from "@material-ui/core";
import { CheckboxProps } from "@material-ui/core/Checkbox";
import { baseTheme } from "../../theme/main";

export type CustomCheckboxProps = CheckboxProps;

export const CustomCheckbox = withStyles({
  root: {
    "&$checked": {
      color: baseTheme.palette.buttonSelected,
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export default CustomCheckbox;
