import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import PhoneIcon from "@material-ui/icons/LocalPhoneOutlined";
import SendIcon from "@material-ui/icons/MailOutlined";
import { makeStyles } from "@material-ui/styles";
import { BU } from "components/BU";
import IconAnchorAction from "components/IconAnchorAction";
import React from "react";
import { baseTheme } from "theme/main";
import { Agent } from "types";
import getUrlPhoto from "utils/UrlImage";

import { useServices } from "../../../../services";

const useStyles = makeStyles((theme) => ({
  mail: {
    color: baseTheme.palette.default,
  },
  telephone: {
    color: baseTheme.palette.default,
    fontSize: "16px",
  },
}));

export const PrintableAgentDetails = ({
  photo,
  telephone,
  email,
  bu,
  prenom,
  nom,
  estActif,
}: Partial<Agent>): JSX.Element => {
  const {
    l10n: { t },
  } = useServices();
  const urlPhoto = getUrlPhoto(bu, "100x100", photo);
  const classes = useStyles();
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexWrap: "nowrap",
        overflowX: "hidden",
      }}
    >
      <div>
        <Avatar
          aria-label="photo conseiller"
          style={{ height: 50, marginRight: 12, width: 50 }}
        >
          <img src={urlPhoto} width="50" height="auto" alt="face" />
        </Avatar>
      </div>
      <div style={{ overflowX: "hidden" }}>
        <BU bu={bu || "Capifrance"} />
        <Typography variant="h4">{`${prenom} ${nom}`}</Typography>

        {estActif === true ? (
          <>
            <IconAnchorAction
              link={telephone}
              icon={<PhoneIcon style={{ fontSize: 12 }} />}
              action="tel"
              tooltip={t("Appeler")}
            >
              <Typography variant="body1" className={classes.telephone}>
                {telephone}
              </Typography>
            </IconAnchorAction>

            <IconAnchorAction
              link={email}
              icon={<SendIcon style={{ fontSize: 12 }} />}
              action="mailto"
              tooltip={t("Envoyer un mail")}
            >
              <Typography variant="body2" noWrap className={classes.mail}>
                {email}
              </Typography>
            </IconAnchorAction>
          </>
        ) : (
          <Typography variant="body1">{t("Inactif")}</Typography>
        )}
      </div>
    </div>
  );
};
