import React, { useMemo } from "react";
import NoItemsPanel from "UI/shared/templates/Panels/NoItemsPanel";
import { SearchInput } from "../../organisms/SearchInput";
import { ListAndGalleryTemplateProps } from "../props";
import { RealEstatesGallery } from "../SearchResult/gallery";
import { RealEstatesList } from "../SearchResult/list";
import styles from "../realEstates.module.css";
import Loading from "components/Loading/LoadingSkeleton";
import { useHistory } from "react-router-dom";
import { networkRealEstatesRoutes } from "router/networkRealEstatesRoutes";

export const ListAndGalleryTemplate: React.FC<ListAndGalleryTemplateProps> = ({
  onChange,
  onClear,
  onLocationChange,
  searchResponse,
  locations,
  params,
  loadMore,
  displayMode,
  loading,
}) => {
  const hasRealEstates = useMemo(() => searchResponse.data.length > 0, [
    searchResponse.data.length,
  ]);

  const { showList, showGallery } = useMemo(() => {
    return {
      showList: hasRealEstates && displayMode === "list",
      showGallery: hasRealEstates && displayMode === "galery",
    };
  }, [displayMode, hasRealEstates]);

  const history = useHistory();

  const onClick = (id: string) => {
    history.push(networkRealEstatesRoutes.details(id));
  };

  return (
    <>
      <SearchInput
        className={styles.searchInput}
        params={params}
        locations={locations}
        result={searchResponse}
        onChange={onChange}
        onClear={onClear}
        onLocationChange={onLocationChange}
        mode={displayMode}
      />

      {loading && <Loading />}

      {showList && !loading && (
        <RealEstatesList loadMore={loadMore} items={searchResponse.data} />
      )}

      {showGallery && !loading && (
        <RealEstatesGallery
          loadMore={loadMore}
          items={searchResponse.data}
          onClick={onClick}
        />
      )}

      {!hasRealEstates && !loading && (
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "50vh",
            width: "100%",
          }}
        >
          <NoItemsPanel clearRefinements={onClear} />
        </div>
      )}
    </>
  );
};
