import { Grid } from "@material-ui/core";
import TitreSection from "layoutContents/Fiches/components/Details/components/TitreSection";
import React from "react";
import { ReadContactDto } from "services/portfolio/types";

import { PrintableContactAdress } from "./components/PrintableContactAdress";
import { PrintableEmailsList } from "./components/PrintableEmailsList";
import { PrintableOptIn } from "./components/PrintableOptIn";
import { PrintableOtherInfos } from "./components/PrintableOtherInfos";
import { PrintablePhoneNumbersList } from "./components/PrintablePhoneNumbersList";
import { PrintableShortContactInfos } from "./components/PrintableShortContactInfos";

export const PrintableIdentity: React.FC<{
  contact: ReadContactDto;
}> = ({ contact }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TitreSection titre="Identité" />
        <PrintableShortContactInfos contact={contact} />
        {contact.identity.phones && (
          <PrintablePhoneNumbersList
            phoneNumbersList={contact.identity.phones.map((p) => p.number)}
          />
        )}
        {contact.identity.emails && (
          <PrintableEmailsList
            emailAddressesList={contact.identity.emails.map((e) => e.address)}
          />
        )}
        <PrintableOptIn optIn={contact.consentStatus} />
      </Grid>

      <Grid item xs={12}>
        <TitreSection titre="Autres informations" />
        <PrintableOtherInfos
          content={{
            dateCreation: contact.creationDate,
            dateModification: contact.updateDate,
            status: contact.status,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TitreSection titre="Adresse" />
        <PrintableContactAdress
          content={{
            adresse: contact.address?.value || "",
            codePostal: contact.address?.city?.postalCodes[0] || "",
            complementAdresse: contact.address?.supplement || "",
            pays: "France",
            ville: contact.address?.city?.name || "",
          }}
        />
      </Grid>
    </Grid>
  );
};
