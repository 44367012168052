import React from "react";
import CarteInfo from "components/CarteInfo";
import "./loader.css";

const Loading = (props) => (
  <CarteInfo bigCard style={props.styles}>
    <div style={props.styles}>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </CarteInfo>
);

export default Loading;
