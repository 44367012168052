import { useCallback } from "react";
import { useServices } from "services";
import { useTxHttp } from "services/http";

export const useAdConversion = () => {
  const { get, post } = useTxHttp();
  const { endpoints } = useServices();

  const getConvertedAds = useCallback(async () => {
    const response = await get<string[]>(endpoints.pige.conversions);
    return response.data;
  }, [endpoints.pige, get]);

  const convertAd = useCallback(
    async (adId?: string, contactId?: string, consentStatus?: string) => {
      const request = { adId, consentStatus, contactId };
      return new Promise((resolve, reject) => {
        post<boolean>(endpoints.pige.convert, {
          content: request,
          contentType: "application/json",
        }).then(
          () => {
            resolve(adId);
          },
          () => {
            reject();
          }
        );
      });
    },
    [endpoints.pige.convert, post]
  );

  return {
    convertAd,
    getConvertedAds,
  };
};
