import { Typography } from "@material-ui/core";
import { NumberField } from "components/NumberField";
import { isUndefined } from "lodash";
import React, { FC, useEffect } from "react";
import { useServices } from "services";
import { formatPrix } from "utils/formatage";

import { BudgetLifeAnnuityDto } from "../../../models/budgetLifeAnnuityDto";
import {
  isGreaterThanOrEquals,
  isMandatory,
  isStrictlyPositive,
} from "../../components/formValidators";
import { SectionTitle } from "../../components/SectionTitle";
import { ProfileInputProps } from "../../InputProps";

export const LifeAnnuityBudget: FC<{ budget: BudgetLifeAnnuityDto }> = ({
  budget,
}) => (
  <>
    {budget.bouquet.min! > 0 && (
      <Typography variant="h5">
        Bouquet min (TTC) : {formatPrix(budget.bouquet.min)}
      </Typography>
    )}
    {budget.bouquet.max! > 0 && (
      <Typography variant="h5">
        Bouquet (TTC) : {formatPrix(budget.bouquet.max)}
      </Typography>
    )}
    {budget.bouquet.maxMatching! > 0 && (
      <Typography variant="body1">
        Bouquet max. (TTC) : {formatPrix(budget.bouquet.maxMatching)}
      </Typography>
    )}
    {budget.income.max! > 0 && (
      <Typography variant="h5">
        Rente : {formatPrix(budget.income.max)}
      </Typography>
    )}

    {budget.income.maxMatching! > 0 && (
      <Typography variant="body1">
        Rente max. pour rapprochement:
        {formatPrix(budget.income.maxMatching)}
      </Typography>
    )}
  </>
);

function validate(t: (s: string) => string, value: BudgetLifeAnnuityDto) {
  return {
    bouqet: {
      max: {
        isValid: isStrictlyPositive(value.bouquet.max) === "",
        reason: isStrictlyPositive(value.bouquet.max),
      },
      maxMatching: {
        isValid:
          isUndefined(value.bouquet.maxMatching) ||
          isGreaterThanOrEquals(
            value.bouquet.max,
            value.bouquet.maxMatching
          ) === "",
        reason: isUndefined(value.bouquet.maxMatching)
          ? ""
          : isGreaterThanOrEquals(value.bouquet.max, value.bouquet.maxMatching),
      },
      min: {
        isValid:
          isUndefined(value.bouquet.min) ||
          isGreaterThanOrEquals(value.bouquet.min, value.bouquet.max) === "",
        reason: isUndefined(value.bouquet.min)
          ? ""
          : isGreaterThanOrEquals(value.bouquet.min, value.bouquet.max),
      },
    },
    income: {
      max: {
        isValid: isStrictlyPositive(value.income.max) === "",
        reason: isStrictlyPositive(value.income.max),
      },
      maxMatching: {
        isValid:
          isUndefined(value.income.maxMatching) ||
          isGreaterThanOrEquals(value.income.max, value.income.maxMatching) ===
            "",
        reason: isUndefined(value.income.maxMatching)
          ? ""
          : isGreaterThanOrEquals(value.income.max, value.income.maxMatching),
      },
    },
  };
}

export const LifeAnnuityBudgetInput: FC<ProfileInputProps<
  BudgetLifeAnnuityDto
>> = ({ pristine, value, onChange }) => {
  const disabled = false;
  const { l10n } = useServices();

  const errors = validate(l10n.t, value);

  const isValid =
    errors.bouqet.max.isValid &&
    errors.bouqet.maxMatching.isValid &&
    errors.bouqet.min.isValid &&
    errors.income.max.isValid &&
    errors.income.maxMatching.isValid;

  useEffect(() => {
    onChange(value, isValid);
  }, [isValid]);

  return (
    <>
      <SectionTitle title="Quel est votre budget ?" />

      <NumberField
        disabled={disabled}
        helperText={
          pristine ? isMandatory(value.bouquet.max) : errors.bouqet.max.reason
        }
        error={pristine ? false : !errors.bouqet.max.isValid}
        label="Bouquet (TTC)"
        onChange={(max) => {
          onChange(
            {
              ...value,
              bouquet: {
                ...value.bouquet,
                max,
                maxMatching: max ? max * 1.2 : max,
                min: max ? max * 0.8 : max,
              },
            },
            isValid
          );
        }}
        required={true}
        value={value.bouquet.max}
        adornement={"€"}
      />

      <NumberField
        disabled={disabled}
        helperText={errors.bouqet.min.reason}
        error={!errors.bouqet.min.isValid}
        label="Bouquet min. (TTC)"
        onChange={(min) =>
          onChange({ ...value, bouquet: { ...value.bouquet, min } }, isValid)
        }
        value={value.bouquet.min}
        adornement={"€"}
      />

      <NumberField
        disabled={disabled}
        helperText={errors.bouqet.maxMatching.reason}
        error={!errors.bouqet.maxMatching.isValid}
        label="Bouquet max. pour rapprochement (TTC)"
        onChange={(maxMatching) =>
          onChange(
            { ...value, bouquet: { ...value.bouquet, maxMatching } },
            isValid
          )
        }
        value={value.bouquet.maxMatching}
        adornement={"€"}
      />

      <NumberField
        disabled={disabled}
        helperText={
          pristine ? isMandatory(value.income.max) : errors.income.max.reason
        }
        error={pristine ? false : !errors.income.max.isValid}
        label="Rente (TTC)"
        onChange={(max) => {
          onChange(
            { ...value, income: { max, maxMatching: max ? max * 1.2 : max } },
            isValid
          );
        }}
        required={true}
        value={value.income.max}
        adornement={"€"}
      />

      <NumberField
        disabled={disabled}
        helperText={errors.income.maxMatching.reason}
        error={!errors.income.maxMatching.isValid}
        label="Rente max. pour rapprochement (TTC)"
        onChange={(maxMatching) =>
          onChange(
            { ...value, income: { ...value.income, maxMatching } },
            isValid
          )
        }
        value={value.income.maxMatching}
        adornement={"€"}
      />
    </>
  );
};
