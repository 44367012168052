import { Typography } from "@material-ui/core";
import React from "react";

import { useServices } from "../../../../services";
import { useStyles } from "../style";

export const RequestForInformationReference: React.FC<{
  reference: string;
}> = ({ reference }) => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();
  return (
    <div className={classes.reference}>
      <Typography variant={"body2"}>{t("Référence")}</Typography>
      <Typography variant={"h6"}>{reference}</Typography>
    </div>
  );
};
