import { decodeSlug } from "services/slugs/decodeSlug";
import { SLUG_STORAGE_KEY } from "../../slugs/constants";
import { AdaptedRefinements } from "../../../components/RefinementField/types";
import { RequestForInformationsCriteria } from "../../../types";
import { parseAsJson } from "services/slugs/parseAsJson";
import { Slugs } from "services/slugs/types";

export const getStoredState: () => AdaptedRefinements<
  RequestForInformationsCriteria
> | null = () => {
  const storedState = sessionStorage.getItem(SLUG_STORAGE_KEY);
  if (storedState) {
    const toJson = parseAsJson<Slugs>();
    const { DR } = toJson(storedState);
    if (DR) return decodeSlug<RequestForInformationsCriteria>(DR);
  }

  return null;
};
