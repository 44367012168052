import { useTheme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import DoubleArrowRoundedIcon from "@material-ui/icons/DoubleArrowRounded";
import { BlocContainer } from "UI/shared/atoms/containers/BlocContainer";
import React from "react";
import { ITheme } from "theme/ts/main";

import {
  BusinessType,
  ProfileAccountType,
  PropertyCategoryType,
} from "routes/app/profiles/adapters/profileAdapterDetails";
import { ProfileAccountChip } from "../../molecules/ProfileAccountChip";
import { ProfileBusinessTypeChip } from "../../molecules/ProfileBusinessTypeChip";
import { PropertyCategoryChip } from "../../molecules/PropertyCategoryChip";

export interface ProfileProjectProps {
  profileAccount: ProfileAccountType;
  businessType: BusinessType;
  propertyCategory: PropertyCategoryType;
  chipDiameter?: number;
  separatorSize?: number;
}

export const ProfileProject: React.FC<ProfileProjectProps> = ({
  profileAccount,
  businessType,
  propertyCategory,
  separatorSize,
  chipDiameter,
}) => {
  const theme = useTheme<ITheme>();

  const profileAccountChip = (
    <ProfileAccountChip value={profileAccount} chipDiameter={chipDiameter} />
  );
  const businessTypeChip = (
    <ProfileBusinessTypeChip value={businessType} chipDiameter={chipDiameter} />
  );
  const propertyCategoryChip = (
    <PropertyCategoryChip
      value={propertyCategory}
      chipDiameter={chipDiameter}
    />
  );

  const separatorIconStyle: CSSProperties = {
    ...(separatorSize && { fontSize: separatorSize }),
  };
  const separator = (
    <div style={{ alignSelf: "center", color: theme.palette.tonique }}>
      <DoubleArrowRoundedIcon style={separatorIconStyle} />
    </div>
  );

  const projectHeader = (
    <div style={{ alignItems: "flex-start", display: "flex" }}>
      {profileAccountChip}
      {separator}
      {businessTypeChip}
      {separator}
      {propertyCategoryChip}
    </div>
  );
  return (
    <BlocContainer title={"Projet"} paddingTop={20}>
      {projectHeader}
    </BlocContainer>
  );
};
