import { Grid, makeStyles, useTheme } from "@material-ui/core";
import React, { useMemo } from "react";
import routesNames from "router/routesNames";
import { getMatchingRequest } from "routes/app/profiles/adapters/matchingAdapter";
import { profileAdapter } from "routes/app/profiles/adapters/profileAdapter";
import { MonthIncome } from "UI/features/profiles/molecules/MonthIncome";
import { PriceCaption } from "UI/features/profiles/atoms/PriceCaption";
import { ProfileStatus } from "UI/features/profiles/molecules/ProfileStatus";
import { PropertyCategoryCaption } from "UI/features/profiles/molecules/PropertyCategoryCaption";
import { PropertyTypeCaption } from "UI/features/profiles/molecules/PropertyTypeCaption";
import { SurfaceCaption } from "UI/features/profiles/molecules/SurfaceCaption";
import { CitiesList } from "UI/features/profiles/organisms/CitiesList";
import { InfosChipsListProfile } from "UI/features/profiles/organisms/InfosChipsListProfile";
import { ProfileDetailsCaptionSmall } from "UI/features/profiles/organisms/ProfileDetailsCaption/index.small";
import { ProfileSummary } from "UI/features/profiles/organisms/ProfileSummary";
import { MatchingProfileRequestDto } from "services/matching/MatchingProfileRequestDto";
import { ProfileItemDto } from "services/profiles/dto/ReadModelProfileDto";
import { ReadModelProfilesContextProvider } from "services/profiles/readModelProfilesContext";
import { ITheme } from "theme/ts/main";
import { CardPaperItemSmall } from "UI/shared/atoms/containers/CardPaperItem/index.small.tsx";
import { HorizontalDivider } from "UI/shared/atoms/HorizontalDivider";
import { BoldNumberUnitLabelNormalCaption } from "UI/shared/molecules/BoldNumberUnitLabelNormalCaption";
import { SpacedLabelValue12Px } from "UI/shared/molecules/SpacedLabelValue";
import { ReadSellerProfileMatchingNumber } from "./MatchingNumber/ReadSellerProfileMatchingNumber";
import { Maturity } from "./Maturity";
import { ProfileMenu } from "./ProfileMenu";
import { ReadMatchingNumber } from "./Search/ReadMatchingNumber";

const useStyles = makeStyles(() => ({
  contactContainer: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  profileContainer: {
    padding: 8,
  },
}));

export const ProfileSmallCard: React.FC<{
  profile: ProfileItemDto;
  contactId: string;
  updateLocalContact: () => void;
}> = ({ profile, contactId, updateLocalContact }) => {
  const classes = useStyles();
  const theme: ITheme = useTheme();
  const profileItem = profileAdapter(profile);
  const infosChipsList = (
    <InfosChipsListProfile
      businessType={profileItem.businessType}
      propertyType={profileItem.propertyType}
      profileType={profileItem.profileType}
      propertyCategory={profileItem.propertyCategory}
    />
  );

  const criteriaSale: MatchingProfileRequestDto = useMemo(
    () => getMatchingRequest(profile),
    [profile?.id]
  );

  return (
    <CardPaperItemSmall>
      <div className={classes.profileContainer} style={{ height: "100%" }}>
        <Grid container spacing={1}>
          {profileItem.reference && (
            <Grid item xs={12}>
              <SpacedLabelValue12Px
                label={"Référence"}
                spaceSize={5}
                value={profileItem.reference}
              />
            </Grid>
          )}
          <Grid item xs={11}>
            <ProfileSummary
              profileDetailsCaption={
                <ProfileDetailsCaptionSmall
                  propertyCategoryCaption={
                    <PropertyCategoryCaption
                      label={profileItem.propertyCategory}
                    />
                  }
                  propertyTypeCaption={
                    profileItem.propertyType !== "All" &&
                    profileItem.propertyType !== undefined && (
                      <PropertyTypeCaption label={profileItem.propertyType} />
                    )
                  }
                  monthIncome={
                    profileItem.monthIncome ? (
                      <MonthIncome income={profileItem.monthIncome} />
                    ) : undefined
                  }
                  price={<PriceCaption price={profileItem.budgetMax} />}
                  surface={
                    profileItem.surfaceMin !== undefined &&
                    profileItem.surfaceMax !== undefined && (
                      <SurfaceCaption
                        surfaceMin={profileItem.surfaceMin}
                        surfaceMax={profileItem.surfaceMax}
                      />
                    )
                  }
                  bedroomsNumber={
                    profileItem.bedroomsNumber ? (
                      <BoldNumberUnitLabelNormalCaption
                        total={profileItem.bedroomsNumber}
                        unitLabel={
                          profileItem.profileType === "Search"
                            ? "+\xa0ch"
                            : "ch"
                        }
                      />
                    ) : undefined
                  }
                  roomsNumber={
                    profileItem.roomsNumber ? (
                      <BoldNumberUnitLabelNormalCaption
                        total={profileItem.roomsNumber}
                        unitLabel={
                          profileItem.profileType === "Search" ? "+\xa0p" : "p"
                        }
                      />
                    ) : undefined
                  }
                />
              }
              citiesList={
                <CitiesList
                  cities={profileItem.locations}
                  visibleItemsCount={1}
                />
              }
              infoChipsList={infosChipsList}
            />
          </Grid>
          <Grid item xs={1}>
            <Maturity value={profileItem.maturity} />
          </Grid>
          <Grid item xs={12}>
            <HorizontalDivider height={1} color={theme.palette.common.dark} />
          </Grid>
          <Grid item xs={12}>
            <div style={{ minHeight: "76px" }}>
              {profileItem.status === "Active" && (
                <>
                  {profileItem.profileType === "Search" ? (
                    <ReadMatchingNumber
                      profileId={profile.id}
                      contactId={contactId}
                    />
                  ) : (
                    <ReadSellerProfileMatchingNumber
                      criteria={criteriaSale}
                      url={routesNames.profilePageTab(
                        contactId,
                        profileItem.id,
                        "matching"
                      )}
                    />
                  )}
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <HorizontalDivider height={1} color={theme.palette.common.dark} />
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <ProfileStatus
                  status={profileItem.status}
                  date={profileItem.archivedOrUpdatedDate}
                />
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                  }}
                >
                  <ReadModelProfilesContextProvider>
                    <ProfileMenu
                      profile={profile}
                      contactId={contactId}
                      updateLocalContact={updateLocalContact}
                    />
                  </ReadModelProfilesContextProvider>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </CardPaperItemSmall>
  );
};
