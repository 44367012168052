import conseillerAdapter from "services/rapprochement/Rapprochement/ConseillerAdapter";
import villeAdapter from "services/rapprochement/Rapprochement/VilleAdapter";

import ElasticAdapter from "../../../../../api/DTOs/Common/ElasticSearch/ElasticAdapter";
import RechercheGridOuListeBO from "./RechercheGridOuListeBO";

class RechercheGridOuListeAdapter {
  static toBO(result = {}) {
    const elasticResult = ElasticAdapter.toBO(result);
    elasticResult.data = elasticResult.data.map((item) => this.itemToBO(item));
    return elasticResult;
  }

  static itemToBO(item = {}) {
    const bo = new RechercheGridOuListeBO();
    bo.id = item.id || bo.id;
    bo.bu = item.bu || bo.bu;
    bo.dateAjout = item.dateAjout || bo.dateAjout;
    bo.dateModification = item.dateModification || bo.dateModification;
    bo.nombrePhotos = item.nombrePhotos || bo.nombrePhotos;
    bo.photoPrincipale = item.photoPrincipale || bo.photoPrincipale;
    bo.surface = item.surface || bo.surface;
    bo.surfaceTerrain = item.surfaceTerrain || bo.surfaceTerrain;
    bo.nbPieces = item.nbPieces || bo.nbPieces;
    bo.nbChambres = item.nbChambres || bo.nbChambres;
    bo.prix = item.prix || bo.prix;
    bo.familleBienEnum = item.familleBienEnum || bo.familleBienEnum;
    bo.typeAchatEnum = item.typeAchatEnum || bo.typeAchatEnum;
    bo.typeMandatEnum = item.typeMandatEnum || bo.typeMandatEnum;
    bo.typeAffaireEnum = item.typeAffaireEnum || bo.typeAffaireEnum;
    bo.codeAffaire = item.codeAffaire || bo.codeAffaire;
    bo.conseillers =
      item.conseillers.length > 0
        ? item.conseillers.map((conseiller) => conseillerAdapter(conseiller))
        : bo.conseillers;
    bo.conseillerPrincipal =
      bo.conseillers.length > 0
        ? conseillerAdapter(
            bo.conseillers.filter(
              (conseiller) => conseiller.estPrincipal === true
            )[0]
          )
        : conseillerAdapter();
    bo.localisation = item.localisation
      ? villeAdapter(item.localisation)
      : bo.localisation;
    bo.prestige = item.prestige || bo.prestige;
    bo.neuf = item.neuf || bo.neuf;
    bo.exclusivite = item.exclusivite || bo.exclusivite;
    bo.typeBienNom = item.typeBienNom || bo.typeBienNom;
    bo.isCE = item.isCE || bo.isCE;
    bo.underOfferToSell = item.underOfferToSell || bo.underOfferToSell;
    bo.hasOffer = item.hasOffer || bo.hasOffer;
    bo.avecAscenseur = item.avecAscenseur || bo.avecAscenseur;
    bo.avecStationnement = item.avecStationnement || bo.avecStationnement;
    bo.anneeConstruction = item.anneeConstruction || bo.anneeConstruction;
    bo.withPool = item.withPool || bo.withPool;
    bo.withSingleStorey = item.withSingleStorey || bo.withSingleStorey;
    return bo;
  }
}

export default RechercheGridOuListeAdapter;

const itemToBO = (item) => {
  const bo = new RechercheGridOuListeBO();
  bo.id = item.id || bo.id;
  bo.bu = item.bu || bo.bu;
  bo.dateAjout = item.dateAjout || bo.dateAjout;
  bo.dateModification = item.dateModification || bo.dateModification;
  bo.nombrePhotos = item.nombrePhotos || bo.nombrePhotos;
  bo.photoPrincipale = item.photoPrincipale || bo.photoPrincipale;
  bo.surface = item.surface || bo.surface;
  bo.surfaceTerrain = item.surfaceTerrain || bo.surfaceTerrain;
  bo.nbPieces = item.nbPieces || bo.nbPieces;
  bo.nbChambres = item.nbChambres || bo.nbChambres;
  bo.prix = item.prix || bo.prix;
  bo.familleBienEnum = item.familleBienEnum || bo.familleBienEnum;
  bo.typeAchatEnum = item.typeAchatEnum || bo.typeAchatEnum;
  bo.typeMandatEnum = item.typeMandatEnum || bo.typeMandatEnum;
  bo.typeAffaireEnum = item.typeAffaireEnum || bo.typeAffaireEnum;
  bo.codeAffaire = item.codeAffaire || bo.codeAffaire;
  bo.conseillers =
    item.conseillers.length > 0
      ? item.conseillers.map((conseiller) => conseillerAdapter(conseiller))
      : bo.conseillers;
  bo.conseillerPrincipal =
    bo.conseillers.length > 0
      ? conseillerAdapter(
          bo.conseillers.filter(
            (conseiller) => conseiller.estPrincipal === true
          )[0]
        )
      : conseillerAdapter();
  bo.localisation = item.localisation
    ? villeAdapter(item.localisation)
    : bo.localisation;
  bo.prestige = item.prestige || bo.prestige;
  bo.neuf = item.neuf || bo.neuf;
  bo.exclusivite = item.exclusivite || bo.exclusivite;
  bo.typeBienNom = item.typeBienNom || bo.typeBienNom;
  bo.isCE = item.isCE || bo.isCE;
  bo.underOfferToSell = item.underOfferToSell || bo.underOfferToSell;
  bo.hasOffer = item.hasOffer || bo.hasOffer;
  bo.avecAscenseur = item.avecAscenseur || bo.avecAscenseur;
  bo.avecStationnement = item.avecStationnement || bo.avecStationnement;
  bo.anneeConstruction = item.anneeConstruction || bo.anneeConstruction;
  return bo;
};

export const rechercheGridOuListeAdapter = (result = {}) => {
  const elasticResult = ElasticAdapter.toBO(result);
  elasticResult.data = elasticResult.data.map((item) => itemToBO(item));
  return elasticResult;
};
