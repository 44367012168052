import { Grid, Typography } from "@material-ui/core";
import { CropFree } from "@material-ui/icons";
import { DirectionBigMapButton } from "UI/shared/atoms/buttons/DirectionBigMapButton";
import L from "leaflet";
import React, { memo, useEffect, useRef } from "react";
import { Circle, Map, Marker, Popup, TileLayer } from "react-leaflet";
import { breakpoint } from "theme/main";

import { useServices } from "../../../services";
import MapPinIcon from "../MapPinIcon";

const mapTopBarStyle = () => ({
  alignContent: "stretch",
  alignItems: "center",
  display: "flex",
  height: "50px",
  padding: "0px 4px 0px 20px",
  width: "100vw",
});
const fullScreenMapStyle = (showTopBar) => ({
  height: showTopBar ? `calc(100vh - ${mapTopBarStyle().height})` : "100vh",
  width: "100vw",
});
const mapStyle = (height) => ({
  height,
  width: "100%",
});

const buColors = {
  Capifrance: "#0093f7",
  Digitregroup: "#0d92d2",
  Optimhome: "#FF7B1E",
  Refleximmo: "#1C3765",
};

const MapBienUnique = memo(function MapBiens({
  isFullScreen = false,
  latitude,
  longitude,
  address,
  bu = "Capifrance",
  mode = "diffus",
  height,
  etiquetteCarte = <></>,
  renderCloseButton,
  polygon,
  centrer = true,
  popupContent = "",
}) {
  const {
    l10n: { t },
  } = useServices();
  const gpsPosition = { lat: latitude, lng: longitude };
  const map = useRef(null);
  const zoomControl = useRef(null);
  const showLocationTopBar = address !== undefined && address.length > 0;
  const scaleControl = useRef(null);

  const resetMap = () => {
    map.current.leafletElement.setView(
      {
        lat: latitude,
        lng: longitude,
      },
      13
    );
  };

  useEffect(() => {
    if (map.current.leafletElement) {
      if (!scaleControl.current) {
        scaleControl.current = L.control.scale({
          imperial: false,
          position: "bottomright",
        });
        scaleControl.current.addTo(map.current.leafletElement);
      }

      if (!zoomControl.current) {
        zoomControl.current = L.control.zoom({ position: "bottomright" });
        zoomControl.current.addTo(map.current.leafletElement);
      }
    }
  }, []);

  const initMarker = (ref) => {
    if (ref) {
      ref.leafletElement.openPopup();
    }
  };

  return (
    <Grid container>
      {showLocationTopBar && (
        <Grid item>
          <div style={mapTopBarStyle()}>
            <div style={{ display: "flex", flexGrow: "3" }}>
              <div style={{ marginLeft: "5px" }}>
                <Typography
                  variant={"subtitle2"}
                  style={{
                    fontSize: breakpoint().xs ? "10px" : "14px",
                  }}
                >
                  {t("Localisation")}
                </Typography>
              </div>
              <div style={{ marginLeft: "5px" }}>
                <Typography
                  variant={"subtitle1"}
                  style={{
                    fontSize: breakpoint().xs ? "10px" : "14px",
                  }}
                >
                  | {address.length > 0 ? address : ""}
                </Typography>
              </div>
            </div>
            <div style={{ flexGrow: "0", height: "42px", width: "42px" }}>
              {renderCloseButton && renderCloseButton()}
            </div>
          </div>
        </Grid>
      )}

      <Grid item>
        <div style={{ position: "relative" }}>
          <Map
            ref={map}
            center={gpsPosition}
            zoom={13}
            style={
              isFullScreen
                ? fullScreenMapStyle(showLocationTopBar)
                : mapStyle(height)
            }
            zoomControl={false}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {!!polygon && polygon}
            {bu === "Digitregroup" ? (
              polygon ? (
                <></>
              ) : (
                <Marker ref={initMarker} position={gpsPosition}>
                  {popupContent !== "" && (
                    <Popup minWidth={"300px"}>
                      <Typography
                        variant={"subtitle2"}
                        noWrap
                        style={{
                          padding: "20px",
                          textAlign: "center",
                        }}
                      >
                        <div style={{ alignItems: "center", display: "flex" }}>
                          {popupContent}
                        </div>
                      </Typography>
                    </Popup>
                  )}
                </Marker>
              )
            ) : mode === "diffus" ? (
              <Circle
                radius={650}
                center={gpsPosition}
                fill="true"
                color={buColors[bu]}
                interactive={false}
              />
            ) : (
              <Marker
                icon={MapPinIcon(bu)}
                position={gpsPosition}
                interactive={false}
              />
            )}
          </Map>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              position: "absolute",
              right: 0,
              top: 10,
              width: "100%",
              zIndex: 1200,
            }}
          >
            <div
              style={{
                paddingLeft: "1%",
              }}
            >
              {etiquetteCarte}
            </div>

            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: showLocationTopBar ? 10 : 0,
                }}
              >
                <div
                  style={{
                    alignItems: "center",
                    background: "white",
                    border: "1px solid black",
                    borderRadius: "4px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                  onClick={resetMap}
                >
                  <CropFree />
                  {/* TODO : it might not be the right place, but there's something about this wording*/}
                  {breakpoint().smAndUp && (
                    <Typography style={{ marginLeft: "5px" }}>
                      {t("CENTRER LE BIEN SUR LA CARTE")}
                    </Typography>
                  )}
                </div>

                {mode !== "diffus" &&
                  !polygon &&
                  !!gpsPosition?.lat &&
                  !!gpsPosition?.lng && (
                    <div style={{ alignSelf: "flex-end" }}>
                      <DirectionBigMapButton
                        latitude={gpsPosition.lat}
                        longitude={gpsPosition.lng}
                      />
                    </div>
                  )}
              </div>

              <div>
                {!showLocationTopBar &&
                  renderCloseButton &&
                  renderCloseButton()}
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
});

export default MapBienUnique;
