import { FormControlLabel, makeStyles, RadioGroup } from "@material-ui/core";
import ArrowDownIcon from "@material-ui/icons/ArrowDownward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowUpIcon from "@material-ui/icons/ArrowUpward";
import { SelectOption, SelectOptionProps } from "UI/shared/atoms/SelectOptions";
import React, { FC } from "react";

import { useServices } from "../../../../../../services";
import { MaturityType } from "../models/maturityType";
import { SectionTitle } from "./components/SectionTitle";

export interface MaturityProps {
  value: MaturityType | undefined;
  withLabel?: boolean;
}

const useStyles = makeStyles((theme) => ({
  cold: { color: "blue" },
  hot: { color: "red" },
  nc: { color: (theme.palette.common as any).main },
  selected: { color: "white" },
  warn: { color: "orange" },
}));

export const Maturity: FC<MaturityProps> = ({ value, withLabel }) => {
  const classes = useStyles();
  let label = "NC";
  let control = <ArrowForwardIcon className={classes.nc} />;

  switch (value) {
    case "Hot":
      control = <ArrowUpIcon className={classes.hot} />;
      label = "Chaud";
      break;
    case "Warm":
      control = <ArrowForwardIcon className={classes.warn} />;
      label = "Tiède";
      break;
    case "Cold":
      control = <ArrowDownIcon className={classes.cold} />;
      label = "Froid";
      break;
    default:
      label = "NC";
      control = <ArrowForwardIcon className={classes.nc} />;
      break;
  }

  return withLabel ? (
    <FormControlLabel
      value={value}
      control={control}
      label={label}
      labelPlacement="end"
    />
  ) : (
    control
  );
};

export const SelectMaturity: React.FC<{
  value?: MaturityType;
  onChange: (value: MaturityType) => void;
  disabled: boolean;
}> = ({ value, onChange, disabled }) => {
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles();

  const options: SelectOptionProps<MaturityType>[] = [
    {
      checkedIcon: <ArrowForwardIcon className={classes.selected} />,
      icon: <ArrowForwardIcon className={classes.nc} />,
      value: "None",
    },
    {
      checkedIcon: <ArrowUpIcon className={classes.selected} />,
      icon: <ArrowUpIcon className={classes.hot} />,
      value: "Hot",
    },
    {
      checkedIcon: <ArrowForwardIcon className={classes.selected} />,
      icon: <ArrowForwardIcon className={classes.warn} />,
      value: "Warm",
    },

    {
      checkedIcon: <ArrowDownIcon className={classes.selected} />,
      icon: <ArrowDownIcon className={classes.cold} />,
      value: "Cold",
    },
  ];
  return (
    <>
      <SectionTitle title="Urgence" mode="non obligatoire" />
      <RadioGroup
        row
        aria-label="Urgence"
        value={value}
        onChange={(_) => onChange(_.target.value as MaturityType)}
      >
        {options.map(({ value, icon, checkedIcon }) => (
          <SelectOption
            key={value}
            icon={icon}
            checkedIcon={checkedIcon}
            value={value}
            label={t(`maturity.${value}`)}
          />
        ))}
      </RadioGroup>
    </>
  );
};
