import { Grid } from "@material-ui/core";
import { BlocContainer } from "UI/shared/atoms/containers/BlocContainer";
import { Column } from "UI/shared/atoms/containers/Column";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";
import { Row } from "UI/shared/atoms/containers/Row";
import { HorizontalSpace } from "UI/shared/atoms/spacing/HorizontalSpace";
import { Text12pxBold } from "UI/shared/atoms/typographies/Text12pxBold";
import { Text14px } from "UI/shared/atoms/typographies/Text14px";
import { Text14pxBold } from "UI/shared/atoms/typographies/Text14pxBold";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import { LabelledValue } from "UI/shared/molecules/LabelledValue";
import { TitleAndHorizontalDivider } from "UI/shared/molecules/TitleAndHorizontalDivider";
import React from "react";
import { ParkingsDto } from "routes/app/contacts/contact/components/models/partSummariesDto";
import { PlaceDto } from "routes/app/contacts/contact/components/models/placeDto";
import { SaleApartmentProfileReadDto } from "routes/app/contacts/contact/components/models/Proposal/ForSale/Apartment/saleApartmentProfileReadDto";
import { ProfileLocalisation } from "UI/features/profiles/organisms/ProfileLocalisation";
import { ProfileProject } from "UI/features/profiles/organisms/ProfileProject";
import { ProfileStatusCard } from "UI/features/profiles/organisms/ProfileStatusCard";
import { useServices } from "services";
import { formatPrix } from "utils/formatage";

import { SaleApartmentFeaturesDto } from "../../../../../../../../routes/app/contacts/contact/components/models/Proposal/ForSale/Apartment/saleApartmentFeaturesDto";
import { Estimation } from "../../../../../organisms/Precisio/Estimation";

export interface ApartmentForSaleProfileDetailsProps {
  profileId: string;
  contactId: string;
  readProfile: SaleApartmentProfileReadDto;
}

const getPriceSection: (
  a: SaleApartmentFeaturesDto
) => Record<string, string> = (features) => ({
  ...{
    "Prix (net vendeur)": `${formatPrix(
      (features.financialInfo.price || 0) /
        (1 + (features.financialInfo.agentFees || 0) / 100)
    )}`,
    "Prix de vente": `${formatPrix(features.financialInfo.price)}`,
    "Taux honoraires": `${features.financialInfo.agentFees || "NC"}%`,
  },
  ...(features.financialInfo.taxProperty && {
    "Taxe foncière": `${formatPrix(features.financialInfo.taxProperty)}/an`,
  }),
  ...(features.financialInfo.condominiumFees && {
    "Charge de copropriété": `${formatPrix(
      features.financialInfo.condominiumFees
    )}/an`,
  }),
});

const getParkingTypeValue = (
  parkings: ParkingsDto,
  t: (_: string) => string
): string => {
  let value = "";
  if (parkings.hasInteriorParking) value = t("parkingKind.InteriorParking");
  if (parkings.hasExteriorParking)
    value = `${value !== "" ? `${value},` : ""} ${t(
      "parkingKind.ExteriorParking"
    )}`;
  if (parkings.hasBox)
    value = `${value !== "" ? `${value},` : ""} ${t("parkingKind.Box")}`;
  if (
    !parkings.hasInteriorParking &&
    !parkings.hasExteriorParking &&
    !parkings.hasBox
  )
    value = "NC";
  return value;
};

const getCharacteristicSections: (
  a: SaleApartmentFeaturesDto,
  t: (_: string) => string
) => Record<string, Record<string, string | number>> = (features, t) => ({
  "L'immeuble": {
    Construction: features.propertyInfo?.buildingInfo?.yearBuilt || "NC",
    "Nb d'étages total": features.propertyInfo.buildingInfo.floorsCount || "NC",
    "Équipements communs": (features.amenities || [])
      .map((a: string) => t(`amenity.${a}`))
      .join(", "),
    "Étage du bien": features.propertyInfo.buildingInfo.propertyFloor ?? "NC",
  },
  "Le bien": {
    Chambres: features.parts.summaries.bedRooms?.count || "NC",
    Cuisine: t(
      `kitchenInfoType.${features.parts.summaries.kitchen?.type || "NC"}`
    ),
    Exposition: t(`exposure.${features.propertyInfo.exposure || "NC"}`),
    Niveaux: features.parts.summaries.levels?.count || "NC",
    Pièces: features.parts.summaries.rooms?.count || "NC",
    "Salles de bain": features.parts.summaries.bathRooms?.count || "NC",
    "Surface habitable": `${features.propertyInfo.squareMeter} m²`,
    "Surface séjour": features.parts.summaries.livingRoom?.squareMeter
      ? `${features.parts.summaries.livingRoom?.squareMeter} m²`
      : "NC",
    "WC Séparés": features.parts.summaries.toilets?.count || "NC",
    "État général": t(
      `generalStateType.${features.propertyInfo.generalState || "NC"}`
    ),
  },
  Stationnements: {
    "Stationnements privés": features.parts.summaries.parkings?.count || "NC",
    Type: getParkingTypeValue(features.parts.summaries.parkings, t),
  },
});

export const ApartmentForSaleProfileDetails: React.FC<ApartmentForSaleProfileDetailsProps> = (
  props
) => {
  const {
    profileId,
    contactId,
    readProfile: {
      features,
      archivingDate,
      lastModificationDate,
      maturity,
      origin,
      account,
      creationDate,
      reference,
    },
  } = props;

  const {
    l10n: { t },
  } = useServices();

  const priceSection = getPriceSection(features);

  const annexesSurfaces: Record<string, string[]> = (
    features.parts.annexes || []
  ).reduce((acc, annex) => {
    const vals = acc[t(`annex.${annex.kind}`)] || [];
    return {
      ...acc,
      [t(`annex.${annex.kind}`)]: [...vals, `${annex.squareMeter} m²`],
    };
  }, {} as Record<string, string[]>);

  const characteristicSections = getCharacteristicSections(features, t);

  const filteredCharacteristicSections: Record<
    string,
    Record<string, string | number>
  > = Object.keys(characteristicSections).reduce((acc, key) => {
    const property = Object.keys(characteristicSections[key]).reduce(
      (propAcc, propertyKey) => {
        const val = characteristicSections[key][propertyKey];
        return val !== undefined &&
          val !== "" &&
          (val !== "NC" || (Array.isArray(val) && val.length > 0))
          ? { ...propAcc, [propertyKey]: val }
          : propAcc;
      },
      {}
    );
    return Object.keys(property).length > 0 ? { ...acc, [key]: property } : acc;
  }, {});

  const places: PlaceDto[] = [];
  if (features.address.city?.wkt) places.push(features.address.city);

  return (
    <PaddingContainer left={16} top={16} right={12}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <ProfileStatusCard
            status={archivingDate ? "Archived" : "Active"}
            creationDate={creationDate || lastModificationDate}
            updateDate={archivingDate || lastModificationDate}
            maturity={maturity}
            origin={origin}
            reference={reference}
          />

          <ProfileProject
            separatorSize={20}
            chipDiameter={56}
            profileAccount={account}
            businessType="Vente"
            propertyCategory="Apartment"
          />
          <VerticalSpace size={16} />
          <BlocContainer title={t("PRIX")}>
            <Row rowJustify="flex-start">
              <Column itemJustify="flex-end" columnJustify="flex-end">
                {(Object.keys(priceSection) as string[]).map((k) => (
                  <Text14px key={k}>{t(k)}</Text14px>
                ))}
              </Column>
              <HorizontalSpace size={24} />
              <Column itemJustify="flex-start" columnJustify="flex-start">
                {Object.values(priceSection).map((v, index) => (
                  <Text14pxBold key={index}>{v}</Text14pxBold>
                ))}
              </Column>
            </Row>
          </BlocContainer>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TitleAndHorizontalDivider
            title="ESTIMATION"
            spaceBetween={8}
            dividerColor={"#8E8E8E"}
          />
          <Estimation contactId={contactId} profileId={profileId} />
          <VerticalSpace size={32} />
          <ProfileLocalisation address={features.address} mapHeight={300} />
          <VerticalSpace size={8} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TitleAndHorizontalDivider
            title="CARACTÉRISTIQUES"
            spaceBetween={8}
            dividerColor={"#8E8E8E"}
          />
          {(Object.keys(filteredCharacteristicSections) as string[]).map(
            (section) => {
              return (
                <>
                  <PaddingContainer top={8}>
                    <Text12pxBold>{section}</Text12pxBold>
                  </PaddingContainer>
                  <BlocContainer>
                    {Object.keys(filteredCharacteristicSections[section]).map(
                      (k) => (
                        <LabelledValue
                          key={k}
                          label={t(k)}
                          value={filteredCharacteristicSections[section][k]}
                          size={"14"}
                        />
                      )
                    )}
                  </BlocContainer>
                </>
              );
            }
          )}
          {Object.keys(annexesSurfaces).length > 0 && (
            <>
              <PaddingContainer top={8}>
                <Text12pxBold>Surfaces annexes</Text12pxBold>
              </PaddingContainer>
              <BlocContainer>
                {Object.keys(annexesSurfaces).map((type) =>
                  annexesSurfaces[type].map((surface) => (
                    <LabelledValue
                      key={surface}
                      label={t(type)}
                      value={surface}
                      size={"14"}
                    />
                  ))
                )}
              </BlocContainer>
            </>
          )}
        </Grid>
      </Grid>
    </PaddingContainer>
  );
};
