import { Grid, makeStyles, Tooltip, Typography } from "@material-ui/core";
import SendIcon from "@material-ui/icons/EmailOutlined";
import React from "react";
import { useServices } from "services";
import { ITheme } from "theme/ts/main";

interface PrintableEmailsListProps {
  emailAddressesList: string[];
}

export const PrintableEmailsList = ({
  emailAddressesList,
}: PrintableEmailsListProps) => {
  return (
    <Grid container spacing={1} style={{ marginLeft: 20, marginTop: 14 }}>
      {emailAddressesList.map((emailAddress, index) => (
        <EmailAddress
          key={emailAddress}
          emailAddress={emailAddress}
          iconDisplayed={index === 0}
        />
      ))}
    </Grid>
  );
};

const useStyles = makeStyles((theme: ITheme) => ({
  clickable: {
    color: theme.palette.tonique,
    fontSize: 16,
    marginRight: 10,
  },
}));

interface EmailAddressProps {
  emailAddress: string;
  iconDisplayed: boolean;
}

const EmailAddress = ({ emailAddress, iconDisplayed }: EmailAddressProps) => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexWrap: "nowrap",
      }}
    >
      {iconDisplayed && <SendIcon classes={{ root: classes.clickable }} />}
      <Tooltip title={t("Envoyer un mail")} placement="right">
        <Typography variant="body2" noWrap>
          <span style={{ fontWeight: iconDisplayed ? "bold" : "normal" }}>
            {emailAddress}
          </span>
        </Typography>
      </Tooltip>
    </div>
  );
};
