import { Grid, GridSize, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import React, { FC } from "react";
import { useFeatureFlags } from "services/featureFlags";

import { RefinementField } from "../RefinementField";
import { Refinement, Refinements } from "../RefinementField/types";
import { MoreFilters } from "./MoreFilters";
import styles from "./Searchbar.module.css";
import { PigeCriteria } from "./types";

export interface SearchbarProps {
  refinements: Refinements<PigeCriteria>;
  onChange: (value: { key: PigeCriteria; value: Refinement }) => void;
  displayMode: "list" | "map" | "gallery"; // TODO: remove this (use css responsive)
  className?: string;
  total?: number;
}

export const Searchbar: FC<SearchbarProps> = ({
  refinements,
  onChange,
  displayMode,
  className,
  total,
}) => {
  const theme = useTheme() as any;
  const mediaQuery = useMediaQuery(theme.breakpoints.down("sm")); // TODO: remove this (use css responsive)
  return (
    <div className={className}>
      <div className={styles.searchbar}>
        <div className={styles.searchbarItemFull}>
          {displayMode === "map" || mediaQuery ? (
            <SearchBarMini refinements={refinements} onChange={onChange} />
          ) : (
            <SearchBarLarge refinements={refinements} onChange={onChange} />
          )}
        </div>
        <div className={styles.searchbarItemSmall}>
          <MoreFilters
            total={total || 0}
            refinements={refinements}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

const SearchBarMini = ({
  refinements,
  onChange,
}: Omit<
  SearchbarProps,
  "displayMode" | "isOpenMoreFilter" | "setIsOpenMoreFilter"
>) => {
  const t = (_: string) => _;
  const { typesAnnonce } = refinements;
  return (
    <div>
      <RefinementField
        onChange={(value) => onChange({ key: "typesAnnonce", value })}
        value={typesAnnonce}
        label={t("Type d'affaire")}
      />
    </div>
  );
};

const SearchBarLarge = ({
  refinements,
  onChange,
}: Omit<
  SearchbarProps,
  "displayMode" | "isOpenMoreFilter" | "setIsOpenMoreFilter"
>) => {
  const t = (_: string) => _; // TODO
  const {
    nbPieces,
    typesAnnonce,
    prixMax,
    typesDeBien,
    favorisUniquement,
    avecSuiviUniquement,
    pigeDuJour,
    avecNotesUniquement,
    typeAnnonceurPro,
    typeAnnonceurParticuliers,
    inactivesIncluses,
    zoneDeTravail,
    baissesDePrix,
    telephoneUniquement,
    convertedOnly,
  } = refinements;

  const gridItemSizes: { [key: string]: GridSize } = {
    localisation: 4,
    nbChambres: 2,
    nbPieces: 2,
    prixMax: 2,
    typesAnnonce: 2,
    typesDeBien: 2,
  };

  const { Pige_DisabledAd } = useFeatureFlags();

  return (
    <Grid>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={gridItemSizes.localisation}>
          <RefinementField
            value={zoneDeTravail}
            onChange={(value) => {
              onChange({ key: "zoneDeTravail", value });
            }}
            label={t("Localisations")}
            options={{ excludedLocationTypes: ["Quartier"] }}
          />
        </Grid>

        <Grid item xs={gridItemSizes.typesAnnonce}>
          <RefinementField
            onChange={(value) => onChange({ key: "typesAnnonce", value })}
            value={typesAnnonce}
            label={t("Type d'affaire")}
          />
        </Grid>

        <Grid item xs={gridItemSizes.typesDeBien}>
          <RefinementField
            onChange={(value) => onChange({ key: "typesDeBien", value })}
            value={typesDeBien}
            label={t("Famille de biens")}
          />
        </Grid>

        <Grid item xs={gridItemSizes.nbPieces}>
          <RefinementField
            onChange={(value) => onChange({ key: "nbPieces", value })}
            value={nbPieces}
            label={t("Piece(s)")}
          />
        </Grid>

        <Grid item xs={gridItemSizes.prixMax}>
          <RefinementField
            onChange={(value) => onChange({ key: "prixMax", value })}
            value={prixMax}
            label={t("Prix max")}
          />
        </Grid>
      </Grid>

      <Grid item xs={"auto"}>
        <Grid container>
          <Grid item xs={"auto"}>
            <RefinementField
              onChange={(value) =>
                onChange({ key: "favorisUniquement", value })
              }
              value={favorisUniquement}
              label={"Favoris"}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <RefinementField
              onChange={(value) =>
                onChange({ key: "avecSuiviUniquement", value })
              }
              value={avecSuiviUniquement}
              label={"Suivies"}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <RefinementField
              onChange={(value) => onChange({ key: "pigeDuJour", value })}
              value={pigeDuJour}
              label={"Pige du jour"}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <RefinementField
              onChange={(value) =>
                onChange({ key: "avecNotesUniquement", value })
              }
              value={avecNotesUniquement}
              label={"Avec notes uniquement"}
            />
          </Grid>

          <Grid item xs={"auto"}>
            <RefinementField
              onChange={(value) =>
                onChange({ key: "typeAnnonceurParticuliers", value })
              }
              value={typeAnnonceurParticuliers}
              label={"Particuliers"}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <RefinementField
              onChange={(value) => onChange({ key: "typeAnnonceurPro", value })}
              value={typeAnnonceurPro}
              label={"Professionnels"}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <RefinementField
              onChange={(value) => onChange({ key: "baissesDePrix", value })}
              value={baissesDePrix}
              label={"Baisses de prix"}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <RefinementField
              onChange={(value) =>
                onChange({ key: "telephoneUniquement", value })
              }
              value={telephoneUniquement}
              label={"Avec tél. uniquement"}
            />
          </Grid>
          {Pige_DisabledAd && (
            <Grid item xs={"auto"}>
              <RefinementField
                onChange={(value) =>
                  onChange({ key: "inactivesIncluses", value })
                }
                value={inactivesIncluses}
                label={"Inclure les annonces désactivées"}
              />
            </Grid>
          )}
          <Grid item xs={"auto"}>
            <RefinementField
              onChange={(value) => onChange({ key: "convertedOnly", value })}
              value={convertedOnly}
              label={"Transformé en profil"}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
