import { useDeepEffect } from "hooks/useDeepEffect";
import { useCallback, useEffect, useState } from "react";
import { useTxHttp } from "services/http";
import { BuyerMatchingProfilePresenter } from "../BuyerMatchingProfilePresenter";
import { MatchingProfileRequestDto } from "../MatchingProfileRequestDto";
import { NetworkMatchingProfilePresenter } from "../NetworkMatchingProfilePresenter";
import { useAcquirerProfilesMatching } from "../useAcquirerProfilesMatching";
import { useServices } from "./../../index";

interface AcquirersState<T> {
  acquirers: T[];
  currentPage: number;
  total: number;
}
interface State {
  byAgent: AcquirersState<BuyerMatchingProfilePresenter>;
  byNetwork: AcquirersState<NetworkMatchingProfilePresenter>;
}

const PAGE_SIZE = 6;

export const useAcquirers = (matchingPayload?: MatchingProfileRequestDto) => {
  const memoMatchingPayload = useDeepEffect(matchingPayload);

  const [state, setState] = useState<State>({
    byAgent: {
      acquirers: [],
      currentPage: 1,
      total: 0,
    },
    byNetwork: {
      acquirers: [],
      currentPage: 1,
      total: 0,
    },
  });

  const {
    endpoints: {
      profiles: { matching },
    },
  } = useServices();

  const { get } = useTxHttp();
  const {
    getConsultantRealEstate,
    getNetworkRealEstate,
  } = useAcquirerProfilesMatching();

  const handleAgentAcquirersPage = useCallback(
    async (page: number) => {
      if (!memoMatchingPayload) return;

      const response = await getConsultantRealEstate(
        memoMatchingPayload,
        page,
        PAGE_SIZE
      );

      if (response.status !== 200) return;

      setState((prev) => ({
        ...prev,
        byAgent: {
          ...prev.byAgent,
          currentPage: response.data.pageNumber,
          acquirers: response.data.documents,
          total: response.data.total,
        },
      }));
    },
    [get, matching.mine, memoMatchingPayload]
  );

  const handleNetworkAcquirersPage = useCallback(
    async (page: number) => {
      if (!memoMatchingPayload) return;

      const response = await getNetworkRealEstate(
        memoMatchingPayload,
        page,
        PAGE_SIZE
      );

      if (response.status !== 200) return;

      setState((prev) => ({
        ...prev,
        byNetwork: {
          ...prev.byNetwork,
          currentPage: response.data.pageNumber,
          acquirers: response.data.documents,
          total: response.data.total,
        },
      }));
    },
    [get, matching.mine, memoMatchingPayload]
  );

  useEffect(() => {
    if (!memoMatchingPayload) return;
    handleAgentAcquirersPage(1);
    handleNetworkAcquirersPage(1);
  }, [memoMatchingPayload]);

  return {
    ...state,
    handleAgentAcquirersPage,
    handleNetworkAcquirersPage,
    pageSize: PAGE_SIZE,
  };
};
