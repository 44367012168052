import { CancelToken } from "axios";
import { useCallback } from "react";

import { useTxHttp } from "../http";
import { useServices } from "../index";

interface UseGeolocAutocompleteProps {
  autocomplete: string; // TODO: rename to criteria in API
  size: number;
}

export const useGeoloc = () => {
  const { get } = useTxHttp();
  const { endpoints } = useServices();

  const kind = useCallback(
    (ids: string[], cancelToken: CancelToken) =>
      get<{
        villes: { label: string; value: string }[];
        departements: { label: string; value: string }[];
        quartiers: { label: string; value: string }[];
        regions: { label: string; value: string }[];
      }>(endpoints.geoloc.places.kind, { ids }, cancelToken).then(
        (response) => response.data
      ),
    [endpoints.geoloc.places.kind, get]
  );
  return {
    kind,
    search: (params: UseGeolocAutocompleteProps) =>
      params.autocomplete.length > 0
        ? // TODO type this
          get<any>(endpoints.geoloc.AutoComplete, params).then(
            (response) => response.data
          )
        : Promise.reject("params.critetia.length < 1"),
  };
};
