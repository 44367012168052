import {
  AppBar,
  IconButton,
  makeStyles,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import HamburgerIcon from "@material-ui/icons/MenuOutlined";
import clsx from "clsx";
import Profile from "components/Profile";
import PropType from "prop-types";
import React, { useRef, useState } from "react";

import { useServices } from "../../services";
import { useFeatureFlags } from "../../services/featureFlags";
import { NotificationsStatus } from "./NotificationsStatus/NotificationsStatus";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create(["width", "margin"], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
    zIndex: theme.zIndex.drawer,
  },
  appBarShift: {
    marginLeft: (props) => props.drawerWidth,
    transition: theme.transitions.create(["width", "margin"], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: (props) => `calc(100% - ${props.drawerWidth}px)`,
  },
  grow: {
    flexGrow: 1,
  },
  input: {
    fontSize: "10px",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      "&:focus": {
        width: "100%",
      },
      width: 0,
    },
  },
  inputRoot: {
    color: theme.palette.common.dark,
  },
  search: {
    "&:focus-within": {
      "& button": {
        display: "none",
        /*    background: theme.palette.tonique,
                color: 'white',*/
      },
      "& input": {
        opacity: 1,
      },
      width: 320,
    },
    alignItems: "center",
    background: theme.palette.greyLight,
    borderColor: "transparent",
    borderRadius: 21,
    borderStyle: "none",
    borderWidth: 0,
    display: "flex",
    height: 42,
    overflow: "hidden",
    position: "relative",
    transition: "width 450ms cubic-bezier(0.18, 0.89, 0.32, 1.28)",
    width: 42,
    zIndex: 3,
    // // marginRight: theme.spacing(2),
    // // marginLeft: 0,
    // // width: '100%',
    // // [theme.breakpoints.down('sm')]: {
    // //   width: 50,
    // //   '&:focus': {
    // //     width: "100%",
    // //   },
    // // },
    // backgroundColor: fade(theme.palette.common.main, 0.55),
    // '&:hover': {
    //   backgroundColor: fade(theme.palette.common.dark, 0.25),
    // },
  },
  searchBarInput: {
    "&:focus": {
      outline: 0,
    },
    background: "transparent",
    border: 0,
    bottom: 0,
    cursor: "pointer",
    fontSize: 12,
    left: 20,
    lineHeight: 24,
    opacity: 0,
    padding: "0 0.5em",
    position: "absolute",
    top: 0,
    width: 300,
  },
  searchBarSubmit: {
    background: "transparent",
    borderRadius: 0,
    borderStyle: "none",
    borderWidth: 0,
    color: theme.palette.tonique,
    cursor: "inherit",
    height: 32,
    marginLeft: "auto",
    marginRight: 3,
    transition: "background 200ms ease-out",
    width: 32,
  },
  searchIcon: {
    color: theme.palette.tonique,
  },
  toolbarInAppBar: {
    marginLeft: (props) => (props.drawerOpen ? 0 : props.drawerWidthClosed),
    padding: theme.spacing(0, 2, 0, 2),
    transition: theme.transitions.create(["margin"], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  toolbarInAppBarShift: {
    marginLeft: 0,
    transition: theme.transitions.create(["margin"], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
}));

let timeout;

export default function AppBarX({
  drawerOpen,
  drawerWidth,
  grandEcran,
  openMenu,
  setFullText = () => {},
}) {
  const classes = useStyles({
    drawerOpen,
    drawerWidth: drawerWidth[0],
    drawerWidthClosed: drawerWidth[1],
  });
  const {
    appInfo,
    l10n: { t },
  } = useServices();

  const { Notifications } = useFeatureFlags();

  const [searchText, setSearchText] = useState("");
  /*   const [searchFieldOpen, setSearchFieldOpen] = useState(false);*/

  React.useEffect(() => {
    const _fullText = sessionStorage.getItem("fulltext");
    if (_fullText) {
      setSearchText(_fullText);
    }
  }, []);

  const searchField = useRef();

  const handleChangeText = (e) => {
    const _value = e.target.value;
    clearTimeout(timeout);
    setSearchText(_value);
    timeout = setTimeout(() => {
      setFullText(_value);
    }, 300);
  };

  const clearSearchField = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setSearchText("");
  };

  const mobile = useMediaQuery("(max-width:600px)");

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: drawerOpen && grandEcran,
      })}
    >
      <Toolbar
        className={clsx(classes.toolbarInAppBar, {
          [classes.toolbarInAppBarShift]: drawerOpen && grandEcran,
        })}
      >
        {!grandEcran && (
          <IconButton onClick={openMenu}>
            <HamburgerIcon />
          </IconButton>
        )}

        <TextField
          ref={searchField}
          label={mobile ? t("Recherche rapide") : t("RechercheGlobale")}
          id={"searchTextField"}
          variant={"outlined"}
          onChange={handleChangeText}
          value={searchText}
          size={"small"}
          InputLabelProps={{ style: { fontSize: 12 } }}
          InputProps={{
            endAdornment: (
              <IconButton onMouseDown={clearSearchField}>
                <CloseIcon style={{ fontSize: "12px" }} />
              </IconButton>
            ),
            style: { fontSize: 12 },
          }}
          style={{ width: 300 }}
          autoFocus
        />

        <Typography
          variant="h4"
          style={{ color: "#303d4a", marginLeft: "20px" }}
        >
          {appInfo.environnementLabel}
        </Typography>
        <div className={classes.grow} />
        {Notifications && (
          <>
            <NotificationsStatus />
            <div
              style={{
                borderRight: "1px solid lightGrey",
                height: "40px",
                marginLeft: 10,
                width: 2,
              }}
            />
          </>
        )}
        <Profile />
      </Toolbar>
    </AppBar>
  );
}
// test

AppBarX.propTypes = {
  drawerOpen: PropType.bool,
  drawerWidth: PropType.array,
};

/*   <div className={classes.search}>
                     <input
                         type="text"
                         className={classes.searchBarInput}
                         placeholder={t('RechercheGlobale')}
                         aria-label={t('RechercheGlobale')}
                         onChange={handleChangeText}
                         value={searchText}
                     />
                     <button
                         className={classes.searchBarSubmit}
                         aria-label="Lancer la recherche"
                     >
                         <SearchIcon/>
                     </button>
                 </div>
                        <Slide
                            direction="right"
                            in={searchFieldOpen}
                            mountOnEnter
                            unmountOnExit
                            timeout={300}
                        >

                        </Slide>
                    <IconButton onClick={handleDisplaySearchField}>
                            <SearchIcon />
                        </IconButton>
                     */
