import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useServices } from "services";

import useNotifier from "../../../../../../../../../components/Notifier/useNotifier";
import { useProfiles } from "../../../../../../../../../services/profiles";
import { AccountType } from "../../../../models/accountType";
import { SearchLifeAnnuityBuildingProfileReadDto } from "../../../../models/Search/searchLifeAnnuityBuildingProfileReadDto";
import { SearchLifeAnnuityBuildingProfileWriteDto } from "../../../../models/Search/searchLifeAnnuityBuildingProfileWriteDto";
import {
  SectionTitle,
  SubSectionTitle,
} from "../../../components/SectionTitle";
import { SelectMaturity } from "../../../Maturity";
import { NotesInput } from "../../../Notes";
import { SelectPLaces } from "../../../Places";
import { ProfileProps } from "../../../types";
import { Write } from "../../../Write";
import { MatchingCriteria } from "../../MatchingCriteria";
import { SelectVacancy } from "../../SelectVacancy";
import { SizeInput } from "../../SizeInput";
import { WriteMatchingNumber } from "../../WriteMatchingNumber";
import { LifeAnnuityBudgetInput } from "../LifeAnnuityBudget";
import classes from "./Write.module.css";

export const SearchLifeAnnuityBuildingProfileWrite: FC<ProfileProps<
  SearchLifeAnnuityBuildingProfileReadDto
>> = ({
  contactId,
  account,
  initial,
  more,
  showMore,
  sendUpdatedProfile,
  handleAfterUpdate,
  children,
}) => {
  const { add, update } = useProfiles(contactId);
  const [payload, setPayload] = useState(create(account, initial));
  const [state, setState] = useState<"none" | "saving" | "saved" | "error">(
    "none"
  );
  const { modal } = useServices();
  const { showSnack } = useNotifier();
  const mode = initial ? "update" : "add";
  const [isFormValid, setIsFormValid] = useState({
    budget: false,
    homeSize: true,
    lotSize: true,
    places: false,
  });
  const [pristine, setPristine] = useState(mode === "add");
  const disabled = state === "saving";
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    switch (state) {
      case "none":
        break;
      case "saving":
        if (Object.entries(isFormValid).some(([_, b]) => !b)) {
          setPristine(false);
          setState("none");
        } else {
          (mode === "add"
            ? add("SearchLifeAnnuityBuildingProfile", payload)
            : update(
                "SearchLifeAnnuityBuildingProfile",
                initial!.id,
                payload,
                sendUpdatedProfile
              )
          )
            .then(() => {
              if (handleAfterUpdate) handleAfterUpdate();
              setState("saved");
            })
            .catch((value) => setState("error"));
        }
        break;
      case "saved":
        modal.close();
        showSnack({ message: "Le profil est enregistré.", variant: "success" });
        break;
      case "error":
        showSnack({ message: "Une erreur est survenue.", variant: "error" });
        break;
    }
  }, [state]);

  const criteriaa: MatchingCriteria = useMemo(
    () => ({
      account: payload.account,
      business: "LifeAnnuity",
      familleBiens: "Immeuble",
      places: payload.criteria.places,
      prixMax: payload.criteria.budget.bouquet.maxMatching,
      prixMin: payload.criteria.budget.bouquet.min,
      purchaseConstructionYears: payload.criteria.constructionYears,
      surfaceHabitableMax: payload.criteria.homeSize.max,
      surfaceHabitableMin: payload.criteria.homeSize.min,
    }),
    [
      payload.account,
      payload.criteria.budget.bouquet.maxMatching,
      payload.criteria.budget.bouquet.min,
      payload.criteria.homeSize.min,
      payload.criteria.homeSize.max,
      payload.criteria.constructionYears,
      payload.criteria.places,
    ]
  );
  return (
    <Write
      pending={state === "saving"}
      more={more}
      showMore={showMore}
      save={() => setState("saving")}
      mode={mode}
      matching={<WriteMatchingNumber criteria={criteriaa} />}
    >
      <div className={more && !mediaQuery ? classes.container : ""}>
        <div className={more ? classes.itemA : ""}>
          {children}
          <Grid item xs={12}>
            <SelectVacancy
              value={payload.criteria.vacancy}
              onChange={(vacancy) =>
                setPayload((prev) => ({
                  ...prev,
                  criteria: { ...prev.criteria, vacancy },
                }))
              }
              disabled={disabled}
            />
          </Grid>
        </div>

        <div className={more ? classes.itemB : ""}>
          <Grid item xs={12}>
            <SelectPLaces
              pristine={pristine}
              value={payload.criteria.places}
              onChange={(places, isValid) => {
                setIsFormValid((previous) => ({
                  ...previous,
                  places: isValid,
                }));
                setPayload((prev) => ({
                  ...prev,
                  criteria: { ...prev.criteria, places },
                }));
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <LifeAnnuityBudgetInput
              pristine={pristine}
              value={payload.criteria.budget}
              onChange={(budget, isValid) => {
                setIsFormValid((previous) => ({
                  ...previous,
                  budget: isValid,
                }));
                setPayload((prev) => ({
                  ...prev,
                  criteria: { ...prev.criteria, budget },
                }));
              }}
            />
          </Grid>
          {more && (
            <>
              <Grid item xs={12}>
                <SectionTitle
                  title={"Informations facultatives"}
                  mode="non obligatoire"
                />
              </Grid>

              <Grid item xs={12}>
                <SubSectionTitle
                  title={"Surface habitable"}
                  mode="non obligatoire"
                />
                <SizeInput
                  pristine={pristine}
                  value={payload.criteria.homeSize}
                  onChange={(homeSize, isValid) => {
                    setIsFormValid((previous) => ({
                      ...previous,
                      homeSize: isValid,
                    }));
                    setPayload((prev) => ({
                      ...prev,
                      criteria: { ...prev.criteria, homeSize },
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <SubSectionTitle
                  title={"Surface terrain"}
                  mode="non obligatoire"
                />
                <SizeInput
                  pristine={pristine}
                  value={payload.criteria.lotSize}
                  onChange={(lotSize, isValid) => {
                    setIsFormValid((previous) => ({
                      ...previous,
                      lotSize: isValid,
                    }));
                    setPayload((prev) => ({
                      ...prev,
                      criteria: { ...prev.criteria, lotSize },
                    }));
                  }}
                />
              </Grid>
            </>
          )}
        </div>
        <div className={more ? classes.itemC : ""}>
          <Grid item xs={12}>
            <NotesInput
              value={payload.notes}
              onChange={(notes) => setPayload((prev) => ({ ...prev, notes }))}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectMaturity
              onChange={(maturity) => {
                setPayload((prev) => ({
                  ...prev,
                  criteria: { ...prev.criteria, maturity },
                }));
              }}
              value={payload.criteria.maturity}
              disabled={disabled}
            />
          </Grid>
        </div>
      </div>
    </Write>
  );
};

function create(
  account: AccountType,
  initial?: SearchLifeAnnuityBuildingProfileReadDto
): SearchLifeAnnuityBuildingProfileWriteDto {
  return {
    account,
    creationDate: new Date(),
    criteria: initial
      ? initial.criteria
      : {
          budget: { bouquet: {}, income: {} },
          constructionYears: [],
          homeSize: {},
          lotSize: {},
          maturity: "None",
          places: [],
          vacancy: "All",
        },
    notes: initial?.notes || "",
    origin: initial?.origin || "TXProfile",
    reference: initial?.reference || "",
  };
}
