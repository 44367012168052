import { Grid } from "@material-ui/core";
import { NumberField } from "components/NumberField";
import { isUndefined } from "lodash";
import React, { FC, useEffect } from "react";
import { useServices } from "services";

import {
  isGreaterThanOrEquals,
  isLessThanOrEqualsThreshold,
} from "../components/formValidators";

export const SizeInput: FC<{
  pristine: boolean;
  value: { min?: number; max?: number };
  onChange: (value: { min?: number; max?: number }, isValid: boolean) => void;
}> = ({ pristine, value, onChange }) => {
  const { l10n } = useServices();

  const errors = validate(l10n.t, value);

  const isValid = errors.min.isValid && errors.max.isValid;

  useEffect(() => {
    onChange(value, isValid);
  }, [isValid]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <NumberField
          label={"Min."}
          helperText={pristine ? "" : errors.min.reason}
          value={value.min}
          onChange={(min) => onChange({ ...value, min }, isValid)}
          error={pristine ? false : !errors.min.isValid}
          adornement={"m²"}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberField
          label={"Max."}
          helperText={pristine ? "" : errors.max.reason}
          value={value.max}
          onChange={(max) => onChange({ ...value, max }, isValid)}
          error={pristine ? false : !errors.max.isValid}
          adornement={"m²"}
        />
      </Grid>
    </Grid>
  );
};

function validate(
  t: (s: string) => string,
  value: { min?: number; max?: number }
) {
  return {
    max: {
      isValid:
        (isUndefined(value.max) ||
          isUndefined(value.min) ||
          isGreaterThanOrEquals(value.min, value.max) === "") &&
        isLessThanOrEqualsThreshold(100000, value.max || 1) === "",
      reason:
        isLessThanOrEqualsThreshold(100000, value.max || 1) !== ""
          ? isLessThanOrEqualsThreshold(100000, value.max || 1)
          : isUndefined(value.max) || isUndefined(value.min)
          ? ""
          : isGreaterThanOrEquals(value.min, value.max),
    },

    min: {
      isValid:
        (isUndefined(value.min) ||
          isUndefined(value.max) ||
          isGreaterThanOrEquals(value.min, value.max) === "") &&
        isLessThanOrEqualsThreshold(100000, value.min || 1) === "",
      reason:
        isLessThanOrEqualsThreshold(100000, value.min || 1) !== ""
          ? isLessThanOrEqualsThreshold(100000, value.min || 1)
          : isUndefined(value.min) || isUndefined(value.max)
          ? ""
          : isGreaterThanOrEquals(value.min, value.max),
    },
  };
}
