const aggregateItems = (items) => {
  const aggregateByCoordinates = items.reduce((acc, item) => {
    const _position = {
      lat: Math.ceil(item.lat * 1000000) / 1000000,
      long: Math.ceil(item.long * 1000000) / 1000000,
    };
    if (acc[JSON.stringify(_position)]) {
      acc[JSON.stringify(_position)].role = "Groupe";
      acc[JSON.stringify(_position)].items.push(item);
    } else {
      acc[JSON.stringify(_position)] = {
        id: item.id,
        position: _position,
        lat: _position.lat,
        long: _position.long,
        role: item.roles[0],
        bu: item.bu,
        items: [item],
      };
    }
    return acc;
  }, []);
  const numeric_array = [];
  for (let _item in aggregateByCoordinates) {
    if (aggregateByCoordinates.hasOwnProperty(_item))
      numeric_array.push(aggregateByCoordinates[_item]);
  }
  return numeric_array;
};

export default aggregateItems;
