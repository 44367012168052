import L from "leaflet";
import { useEffect, useRef } from "react";
import { Map } from "react-leaflet";

export const useMap = () => {
  const map = useRef<Map>(null);
  const zoomControl = useRef<L.Control.Zoom>();
  const scaleControl = useRef<L.Control.Scale>();

  useEffect(() => {
    if (map.current?.leafletElement) {
      if (!scaleControl.current) {
        scaleControl.current = L.control.scale({
          imperial: false,
          position: "bottomright",
        });
        scaleControl.current?.addTo(map.current.leafletElement);
      }

      if (!zoomControl.current) {
        zoomControl.current = L.control.zoom({ position: "bottomright" });
        zoomControl.current.addTo(map.current.leafletElement);
      }
    }
  }, []);

  return map;
};
