import { Button, CircularProgress } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Column } from "UI/shared/atoms/containers/Column";
import { Row } from "UI/shared/atoms/containers/Row";
import { HorizontalSpace } from "UI/shared/atoms/spacing/HorizontalSpace";
import { Text12px } from "UI/shared/atoms/typographies/Text12px";
import { Text14pxBold } from "UI/shared/atoms/typographies/Text14pxBold";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import React from "react";

import { Error } from "./Error";
import { useStyles } from "./styles";

interface AddProfileToExistingContactModalProps {
  confirm: () => void;
  onClose: () => void;
  isOpen: boolean;

  pending: boolean;

  hasError: boolean;
}

export const AddProfileToExistingContactModal: React.FC<AddProfileToExistingContactModalProps> = ({
  confirm,
  onClose,
  isOpen,
  pending,
  hasError,
}) => {
  const classes = useStyles();
  const modalStyle = {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose} style={modalStyle}>
        <div className={classes.paper} style={{ textAlign: "center" }}>
          <Column itemJustify="center">
            <Text14pxBold>Le contact existe déjà dans votre CRM</Text14pxBold>
            <Text12px>
              Un contact avec le même numéro et/ou la même adresse email existe
              déjà dans votre base CRM.
            </Text12px>
          </Column>
          <VerticalSpace size={20} />
          <Column>
            <Text14pxBold>
              Souhaitez-vous qu&apos;un nouveau profil vendeur soit associé à ce
              contact ?
            </Text14pxBold>
            <Text12px>
              Le profil vendeur est créé en se basant sur les caractéristiques
              du bien de la pige.
            </Text12px>
          </Column>
          <VerticalSpace size={20} />
          <Text12px>
            (Si le contact concerné était archivé, il sera réactivé
            automatiquement lors de l&apos;ajout du profil)
          </Text12px>

          <VerticalSpace size={32} />
          {pending && (
            <Row rowJustify="center">
              <CircularProgress />
            </Row>
          )}
          {!pending && !hasError && (
            <Row rowJustify="flex-end">
              <Button color="primary" variant="outlined" onClick={onClose}>
                {"Annuler"}
              </Button>
              <HorizontalSpace size={8} />
              <Button color="primary" variant="contained" onClick={confirm}>
                {"Confirmer"}
              </Button>
            </Row>
          )}
          {hasError && <Error onClose={onClose} />}
        </div>
      </Modal>
    </>
  );
};
