import { Locations } from "components/RefinementField/types";
import { useDeepEffect } from "hooks/useDeepEffect";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { networkRealEstatesRoutes } from "router/networkRealEstatesRoutes";
import { onMapSelectItemFactory } from "routes/app/biens/utils/commonLogic";
import { useServices } from "services";
import { useTranslation } from "services/l10n/useTranslation";
import obtenirParIdCarteAdapter from "services/rapprochement/Rapprochement/BiensEnVente/ObtenirParIdCarte/obtenirParIdCarteAdapter";
import {
  PagedResult,
  RealEstatesMapPointsDto,
  RealEstatesSearchParams,
} from "services/realEstates/types";
import {
  BoundingBox,
  LocationsGUIDs,
  MapConfigSaveParams,
  MapProps,
} from "./../../../components/MapX/Map/index";
import { LocationItem } from "./../../../components/RefinementField/types";
import { createPropertiesMarkers } from "./../../../layoutContents/Biens/components/MapComponents/createPropertiesMarkers";
import { useTxHttp } from "./../../http/index";

const DEFAULT_ZOOM = 6;
export const SNAPSHOT_KEY = "REAL_ESTATES_MAP_SNAPSHOT";

const FRANCE_CENTER = { lat: 46.22, lng: 2.21 };

const initialState: RealEstatesMapState = {
  boundingBox: { nw: `90, -180`, se: `-90, 180` },
  center: FRANCE_CENTER,
  points: [],
  shouldUpdateBounds: true,
  zoom: DEFAULT_ZOOM,
  rechercheBounds: false,
  loading: false,
};

export interface RealEstatesMapState {
  points: RealEstatesMapPointsDto[];
  center: { lat: number; lng: number };
  shouldUpdateBounds: boolean;
  boundingBox: BoundingBox;
  zoom: number;
  rechercheBounds: boolean;
  loading: boolean;
}

const toId = (item: LocationItem) => item.value;

const toSelectedLocations: (locations: Locations) => LocationsGUIDs = ({
  value: { villes, regions, departements, quartiers },
}) => ({
  villes: villes.map(toId),
  regions: regions.map(toId),
  departements: departements.map(toId),
  quartiers: quartiers.map(toId),
});

const stateInitializer = () => {
  const storedMap = sessionStorage.getItem(SNAPSHOT_KEY);

  if (!storedMap) return initialState;

  return JSON.parse(storedMap) as RealEstatesMapState;
};

export type UseMapParams = {
  params: RealEstatesSearchParams;
  locations: Locations;
};

export const useMap = ({ params, locations }: UseMapParams) => {
  const { get } = useTxHttp();
  const { endpoints } = useServices();
  const { t } = useTranslation();
  const history = useHistory();
  const memoizedParams = useDeepEffect(params);

  const [state, setState] = useState<RealEstatesMapState>(stateInitializer);

  const searchMap = useCallback(async () => {
    setState((prev) => ({ ...prev, loading: true }));
    const { data: pagedResult } = await get<
      PagedResult<RealEstatesMapPointsDto>
    >(endpoints.bienEnVente.rechercheCarte, {
      ...memoizedParams,
      ...state.boundingBox,
    });

    if (!pagedResult) return;

    setState((prev) => ({ ...prev, points: pagedResult.data, loading: false }));
  }, [
    get,
    endpoints.bienEnVente.rechercheCarte,
    memoizedParams,
    state.boundingBox,
  ]);

  const goToDetails = ({ id }: { id: string }) => {
    history.replace(networkRealEstatesRoutes.details(id));
  };

  const onSelect = onMapSelectItemFactory(
    endpoints.bienEnVente.rechercheParIdCarte,
    get,
    t,
    obtenirParIdCarteAdapter
  );

  const onSnapshot = ({ boundingBox, center, zoom }: MapConfigSaveParams) => {
    const nextState = {
      ...state,
      boundingBox,
      center,
      zoom,
    };

    sessionStorage.setItem(SNAPSHOT_KEY, JSON.stringify(nextState));

    setState((prev) => ({ ...prev, ...nextState }));
  };

  const sendBoundingBox = ({
    boundingBox,
    rechercheBounds,
  }: Pick<RealEstatesMapState, "boundingBox" | "rechercheBounds">) => {
    setState((prev) => ({ ...prev, boundingBox, rechercheBounds }));
  };

  useEffect(() => {
    if (!memoizedParams) return;
    searchMap();
  }, [memoizedParams, ...Object.values(state.boundingBox)]);

  return {
    mapProps: {
      points: state.points,
      onPopupClick: goToDetails,
      onMarkerClick: onSelect,
      onMapConfigSave: onSnapshot,
      sendBoundingBoxToParent: sendBoundingBox,
      zoom: state.zoom,
      center: state.center,
      loading: state.loading,
      createMarkers: createPropertiesMarkers,
      fullscreen: false,
      selectedLocations: toSelectedLocations(locations),
      toggleBoundsSearchLabel: "Voir les biens autour de ma zone",
    } as MapProps,
  };
};
