import { Card, Fab, Grid, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LocationOn } from "@material-ui/icons";
import PhotoIcon from "@material-ui/icons/LocalSeeOutlined";
import PlaceIcon from "@material-ui/icons/PlaceOutlined";
import CommerceEntreprise from "UI/shared/atoms/Badges/C&E";
import Exclusivite from "UI/shared/atoms/Badges/Exclusivite";
import Neuf from "UI/shared/atoms/Badges/Neuf";
import Prestige from "UI/shared/atoms/Badges/Prestige";
import { SousOffre } from "UI/shared/atoms/Badges/SousOffre";
import { BU } from "components/BU";
import ModalCarousel from "components/ModalCarousel";
import ModalMap from "components/ModalMap";
import SeeButton from "UI/shared/molecules/Buttons/SeeButton/SeeButton";
import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { formatPrix } from "utils/formatage";
import getUrlPhoto from "utils/UrlImage";

import { SousCompromis } from "../../../UI/shared/atoms/Badges/SousCompromis";
import routesNames from "../../../router/routesNames";
import { useServices } from "../../../services";
import { useStyles } from "./styles";

const CarteBienReduite = ({
  shouldDisplayCardActions,
  hit,
  mapPointLabel,
  mapMode,
  onClick,
  numberOfBuyers = null,
  mode = "non défini",
  detailedPageURL = "",
}) => {
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles({ isClickable: !shouldDisplayCardActions });
  const [isModalCarouselDisplayed, setModalCarouselDisplay] = useState(false);
  const [isModalMapDisplayed, setModalMapDisplay] = useState(false);
  const pictureSize = Math.round((window.innerWidth / 12) * 8);
  const picturesUrl =
    hit.picturesIds &&
    hit.picturesIds.map((id) =>
      getUrlPhoto(hit.bu, `${pictureSize}x${pictureSize}`, id)
    );

  const hideModalCarousel = () => {
    setModalCarouselDisplay(false);
  };
  const hideModalMap = () => {
    setModalMapDisplay(false);
  };

  const history = useHistory();

  const handleNbrOfBuyersClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    history.push(
      `${routesNames.app}${routesNames.biens}${routesNames.monBienEnVente}/${hit.id}/acquereurs/view`
    );
  };
  const handlePhotoButtonClick = () => {
    setModalCarouselDisplay(true);
  };
  const handleMapButtonClick = () => {
    setModalMapDisplay(true);
  };
  return (
    <Card className={classes.root} onClick={onClick}>
      <Grid container spacing={0} direction="row" alignItems="center">
        <Grid item xs={12}>
          <div
            style={{
              backgroundImage: `url(${getUrlPhoto(
                hit.bu,
                "300x300",
                hit.photoPrincipale
              )}), url("/static/images/no-image-box.png")`,
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              cursor: !shouldDisplayCardActions ? "pointer" : "auto",
              height: 200,
              position: "relative",
            }}
          >
            {!shouldDisplayCardActions && (
              <a href={detailedPageURL} className={classes.photoClickDiv}>
                <Typography className={classes.imageInformation}>
                  {t("Voir la fiche détail")}
                </Typography>
              </a>
            )}

            {shouldDisplayCardActions && (
              <div className={classes.buttonsIconContainer}>
                {picturesUrl && picturesUrl.length > 0 && (
                  <Fab
                    className={classes.buttonsIcon}
                    onClick={handlePhotoButtonClick}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <PhotoIcon className={classes.icons} />
                      <Typography
                        variant={"body1"}
                        style={{
                          color: "white",
                          fontSize: "10px",
                          marginTop: "-5px",
                        }}
                      >
                        {picturesUrl.length}
                      </Typography>
                    </div>
                  </Fab>
                )}

                <Fab
                  className={classes.buttonsIcon}
                  onClick={handleMapButtonClick}
                >
                  <LocationOn className={classes.icons} />
                </Fab>
              </div>
            )}

            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                left: 7,
                position: "absolute",
                top: 2,
                width: 150,
              }}
            >
              {hit.neuf === true && (
                <Grid item xs={12}>
                  <Neuf t={t} />
                </Grid>
              )}

              {hit.prestige === true && (
                <Grid item xs={12}>
                  <Prestige t={t} />
                </Grid>
              )}

              {hit.isCE === true && (
                <Grid item xs={12}>
                  <CommerceEntreprise />
                </Grid>
              )}
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} style={{ padding: "10px 10px 10px 15px" }}>
          <Grid container direction="column">
            <Grid container item xs={12} direction="row" spacing={0}>
              <Typography variant="body2" style={{ color: "#8E8E8E" }}>
                {`${hit.typeAffaireEnum
                  .replace(/([A-Z])/g, " $1")
                  .trim()
                  .toUpperCase()}   ${t(
                  hit.typeBienNom || hit.familleBienEnum
                )}`}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              style={{
                display: "inlineFlex",
                flexWrap: "nowrap",
                marginLeft: "-3px",
                verticalAlign: "middle",
              }}
            >
              <PlaceIcon
                style={{
                  color: "#8E8E8E",
                  fontSize: 16,
                  marginTop: 5,
                }}
              />
              <Typography variant="h4" noWrap style={{ lineHeight: 1.6 }}>
                {hit.villeNom.toUpperCase()}{" "}
                <span
                  style={{
                    fontSize: 10,
                    fontWeight: "regular",
                  }}
                >{` (${hit.villeCodePostal})`}</span>
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <div style={{ display: "flex" }}>
                  <Typography variant="h2" align="left" noWrap>
                    {formatPrix(hit.prixNormalized)}
                  </Typography>
                  {hit.underOfferToSell && (
                    <div style={{ marginLeft: 5 }}>
                      <SousCompromis />
                    </div>
                  )}
                  {hit.hasOffer && (
                    <div style={{ marginLeft: 5 }}>
                      <SousOffre />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="flex-end">
              <Grid item xs={12}>
                <Grid container>
                  {hit.surface > 0 && (
                    <>
                      <Typography variant="h2">{hit.surface}</Typography>
                      <Typography variant="h3">
                        &nbsp;{`m\xB2`}&nbsp;
                        {((hit.nbPieces > 0 && !hit.isCE) ||
                          hit.nbChambres > 0) &&
                          "|"}
                        &nbsp;
                      </Typography>
                    </>
                  )}
                  {hit.nbPieces > 0 && !hit.isCE && (
                    <>
                      <Typography variant="h2">{hit.nbPieces}</Typography>
                      <Typography variant="h3">
                        &nbsp;p&nbsp;
                        {hit.nbChambres > 0 && "|"}
                        &nbsp;
                      </Typography>
                    </>
                  )}
                  {hit.nbChambres > 0 && (
                    <>
                      <Typography variant="h2">{hit.nbChambres}</Typography>
                      <Typography variant="h3">&nbsp;ch</Typography>
                    </>
                  )}
                  {hit.surface === 0 &&
                    hit.nbPieces === 0 &&
                    hit.nbChambres === 0 && (
                      <>
                        <Typography variant="h2">&nbsp;</Typography>
                        <Typography variant="h3">&nbsp;</Typography>
                      </>
                    )}
                </Grid>
              </Grid>

              <Grid
                item
                xs={6}
                container
                justify="flex-start"
                style={{ paddingTop: 5 }}
              >
                {hit.exclusivite === true ||
                hit.optionMandatEnum === "Exclusif" ? (
                  <Exclusivite t={t} />
                ) : (
                  <div style={{ height: 24 }} />
                )}
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <BU bu={hit.bu} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {mode && mode === "mesBiensEnVente" && (
          <>
            <hr className={classes.separator} />

            <Grid
              onClick={handleNbrOfBuyersClick}
              item
              xs={12}
              style={{ cursor: "pointer" }}
            >
              <Grid container direction="row">
                <Grid item xs={6} md={12}>
                  {numberOfBuyers !== null ? (
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                        margin: "6px",
                      }}
                    >
                      <Typography variant="h2" align="center">
                        {numberOfBuyers}
                      </Typography>
                      <Typography
                        style={{ marginLeft: "2%" }}
                        variant="body2"
                        align="center"
                      >
                        {`Acquéreur${numberOfBuyers > 1 ? "s" : ""} potentiel${
                          numberOfBuyers > 1 ? "s" : ""
                        }`}
                      </Typography>
                      {numberOfBuyers > 0 && (
                        <SeeButton
                          style={{ marginLeft: "6px" }}
                          smaller="true"
                          onClick={handleNbrOfBuyersClick}
                        >
                          <Typography variant="body2" align="center">
                            {t("Voir la liste")}
                          </Typography>
                        </SeeButton>
                      )}
                    </div>
                  ) : (
                    <CircularProgress />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

      <ModalMap
        label={"hit-map"}
        mapMode={mapMode}
        mapPointLabel={mapPointLabel}
        isDisplayed={isModalMapDisplayed}
        onHide={hideModalMap}
        point={hit}
      />

      {picturesUrl && picturesUrl.length > 0 && (
        <ModalCarousel
          label={"hit-pictures"}
          isDisplayed={isModalCarouselDisplayed}
          urls={picturesUrl}
          onHide={hideModalCarousel}
          description={`${t(hit.familleBienEnum)} - ${
            hit.villeNom
          } - ${formatPrix(hit.prixNormalized)}`}
        />
      )}
    </Card>
  );
};

export default withRouter(CarteBienReduite);
