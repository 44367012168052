import { MicroServicesConfiguration } from "services/configuration/configuration";

import { EndpointsProvider } from "./interfaces/EndpointsProvider";

export interface BienNotesEndpoints {
  modifier: string;
  obtenir: string;
  supprimer: string;
}

const endpoints: EndpointsProvider<BienNotesEndpoints> = (
  microServices: MicroServicesConfiguration
) => ({
  modifier: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/BienNotes/UpsertBienNotes`,
  obtenir: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/BienNotes/ObtenirBienNotes`,
  supprimer: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/BienNotes/SupprimerBienNotes`,
});
export default endpoints;
