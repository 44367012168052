import { Grid, RadioGroup } from "@material-ui/core";
import React, { FC } from "react";

import RadioValeur from "./components/RadioValeur";
import { SubSectionTitle } from "./components/SectionTitle";

export const SelectMin: FC<{
  label: string;
  value: number;
  onChange: (value: number) => void;
  disabled: boolean;
  required?: boolean;
  options: number[];
}> = ({ label, required, value, onChange, disabled, options }) => (
  <>
    <Grid item xs={12}>
      <SubSectionTitle
        title={label}
        mode={required ? "obligatoire" : "non obligatoire"}
      />
    </Grid>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <RadioGroup
          row
          aria-label={label}
          value={value}
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
          onChange={(event) => onChange(parseInt(event.target.value))}
        >
          {options.map((option) => (
            <RadioValeur
              key={option}
              color="primary"
              valeurchoisie={value}
              value={option}
              onClick={() => {
                if (value === option) {
                  onChange(0);
                }
              }}
            />
          ))}
        </RadioGroup>
      </Grid>
    </Grid>
  </>
);
