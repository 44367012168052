import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/styles";
import React from "react";

import { ITheme } from "../../../../../../../theme/ts/main";
import { MatchingCriteria } from "./MatchingCriteria";
import { usePropertiesMatching } from "./usePropertiesMatching";

export const WriteMatchingNumber: React.FC<{
  criteria: MatchingCriteria;
}> = ({ criteria }) => {
  const { propertiesCount, loading } = usePropertiesMatching(criteria);
  const theme: ITheme = useTheme();

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        margin: "0 auto",
        width: "90%",
      }}
    >
      <Typography
        variant={"subtitle1"}
        style={{
          color: theme.palette.common.black,
          textAlign: "center",
        }}
      >
        {loading && <CircularProgress size={12} />}

        {propertiesCount > 1 ? (
          <>
            <span style={{ fontWeight: "bold" }}>
              {propertiesCount} biens &nbsp;
            </span>
            correspondent à vos critères
          </>
        ) : (
          <>
            <span style={{ fontWeight: "bold" }}>
              {propertiesCount} bien &nbsp;
            </span>
            correspond à vos critères
          </>
        )}
      </Typography>
    </div>
  );
};
