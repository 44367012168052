import { EndpointsProvider } from "./interfaces/EndpointsProvider";

export interface RequestForInformationsEndpoints {
  agentRequestForInformations: string;
  agentActiveRequestForInformationsCount: string;
  getRequestForInformationById: (id: string) => string;
  archiveRequestForInformation: (id: string) => string;
  activateRequestForInformation: (id: string) => string;

  transformRequestForInformations: (id: string) => string;
}

const endpoints: EndpointsProvider<RequestForInformationsEndpoints> = (
  microServices
) => ({
  activateRequestForInformation: (id: string) =>
    `${microServices.tx(
      "request-for-informations"
    )}/v1/requestForInformations/${id}/activate`,
  agentActiveRequestForInformationsCount: `${microServices.tx(
    "request-for-informations"
  )}/v1/requestForInformations/activecount`,
  agentRequestForInformations: `${microServices.tx(
    "request-for-informations"
  )}/v1/requestForInformations`,
  archiveRequestForInformation: (id: string) =>
    `${microServices.tx(
      "request-for-informations"
    )}/v1/requestForInformations/${id}/archive`,
  getRequestForInformationById: (id: string) =>
    `${microServices.tx(
      "request-for-informations"
    )}/v1/requestForInformations/${id}`,
  transformRequestForInformations: (id: string) =>
    `${microServices.tx(
      "request-for-informations"
    )}/v1/requestForInformations/${id}/transform`,
});

export default endpoints;
