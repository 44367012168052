import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CheckboxItem, CheckboxList } from "UI/shared/molecules/CheckboxList";
import { SortOption } from "UI/shared/molecules/SortInput";
import { AdaptedRefinements } from "components/RefinementField/types";
import React, { FC } from "react";
import { useServices } from "services";
import { ContactsCriteria } from "services/portfolio/types";

import { useExport } from "../../../../../services/portfolio/useExport";
import { ExportFormContextProvider } from "./context/ExportFormContext";
import { useExportForm } from "./hooks/useExportForm";
import { useExportSearch } from "./hooks/useExportSearch";
import useStyles from "./styles";

const ExportFormContent: FC<{
  closeDialog: () => void;
  contacts: CheckboxItem[];
  loadMore: () => void;
  getAll: () => Promise<CheckboxItem[]>;
  total: number;
}> = ({ closeDialog, contacts, loadMore, getAll, total }) => {
  const classes = useStyles();
  const {
    l10n: { t },
    modal,
  } = useServices();

  const { selectedContacts, setSelectedContacts } = useExportForm();
  const { download } = useExport();

  const cancelButton = (
    <Button
      color="primary"
      variant="outlined"
      className={classes.button}
      onClick={modal.close}
    >
      {t("Annuler")}
    </Button>
  );

  const downloadButton = (
    <Button
      variant="contained"
      color="primary"
      className={classes.button}
      onClick={() => {
        return download(selectedContacts, "Export_contacts.xlsx");
      }}
    >
      {"Télécharger le fichier"}
    </Button>
  );

  const modalTitle = (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="h2">
        {"Exporter les contacts au format excel"}
      </Typography>
      <IconButton onClick={closeDialog}>
        <CloseIcon />
      </IconButton>
    </div>
  );
  return (
    <>
      <DialogTitle>{modalTitle}</DialogTitle>
      <DialogContent dividers>
        <CheckboxList
          values={contacts}
          loadMore={loadMore}
          onChange={setSelectedContacts}
          getAll={getAll}
          entities={"contacts"}
          total={total}
        />
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between" alignItems={"center"}>
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div className={classes.formButtonWrapper}>
                {cancelButton}
                {downloadButton}
              </div>
            </div>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export const ExportForm: FC<{
  refinements: AdaptedRefinements<ContactsCriteria>;
  sortOption: SortOption;
  closeDialog: () => void;
}> = ({ refinements, sortOption, closeDialog }) => {
  const { contacts, loadMore, getAll } = useExportSearch(
    sortOption,
    refinements
  );

  const formatContacts = contacts.documents.map((contact) => {
    return {
      id: contact.id,
      label: `${contact.identity.firstName} ${contact.identity.lastName}`,
    };
  });

  return (
    <ExportFormContextProvider>
      <ExportFormContent
        closeDialog={closeDialog}
        contacts={formatContacts}
        loadMore={loadMore}
        getAll={getAll}
        total={contacts.total}
      />
    </ExportFormContextProvider>
  );
};
