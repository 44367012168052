import { Fab, Typography } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import EditProfileIcon from "@material-ui/icons/HowToRegOutlined";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import buyerIcon from "assets/icons/buyer_icon.svg";
import sellerIcon from "assets/icons/seller_icon.svg";
import React, { useState } from "react";
import { ITheme } from "theme/ts/main";

import { useFeatureFlags } from "../../../../../services/featureFlags";

interface EditButtonProps {
  actionsButton: actionButtonType[];
  tabActiveIndex: number;
}

interface actionButtonType {
  action: () => void;
  label: string;
  renderIcon: JSX.Element[];
  actionSeller?: () => void;
}

const useStyles = makeStyles((theme: ITheme) => {
  const baseFab = {
    "&:hover": {
      backgroundColor: theme.palette.tonique,
    },
    backgroundColor: theme.palette.tonique,
    color: "white",
  };
  return {
    fab: baseFab,
    fabTab: {
      ...baseFab,
      bottom: 30,
      left: "inherit",
      position: "fixed",
      right: 30,
      zIndex: 1000,
    },
    root: {
      flexGrow: 1,
      height: "100%",
      transform: "translate(0%, -100%, 0px)",
    },
    speedDial: {
      bottom: 30,
      position: "fixed",
      right: 30,
    },
  };
});

export const EditButton: React.FC<EditButtonProps> = ({
  actionsButton,
  tabActiveIndex,
}) => {
  const [openProfilButton, setopenProfilButton] = useState(false);
  const classes = useStyles();
  const sellerProfileFlag = useFeatureFlags()?.Seller_profile;

  const handleOpen = () => {
    setopenProfilButton(true);
  };

  const handleClose = () => {
    setopenProfilButton(false);
  };

  const actions = [
    {
      action: actionsButton[1].action,
      icon: (
        <img alt="buyerIcon" src={buyerIcon} width={"30px"} height={"auto"} />
      ),
      name: (
        <Typography variant={"subtitle2"} noWrap>
          Ajouter profil acquéreur / locataire
        </Typography>
      ),
    },
    {
      action: actionsButton[1].actionSeller,
      icon: (
        <img alt="buyerIcon" src={sellerIcon} width={"30px"} height={"auto"} />
      ),
      name: (
        <Typography variant={"subtitle2"} noWrap>
          Ajouter profil vendeur
        </Typography>
      ),
    },
  ];

  //manage feature toggle on Seller_profile flag
  if (!sellerProfileFlag) {
    return (
      <Fab
        size="large"
        aria-label={`edit ${actionsButton[tabActiveIndex].label}`}
        className={classes.fabTab}
        onClick={actionsButton[tabActiveIndex].action}
      >
        {actionsButton[tabActiveIndex].renderIcon}
      </Fab>
    );
  }

  if (actionsButton[tabActiveIndex].label === "identity")
    return (
      <div className={classes.root}>
        <Fab
          size="large"
          aria-label={`edit ${actionsButton[tabActiveIndex].label}`}
          className={classes.fabTab}
          onClick={actionsButton[tabActiveIndex].action}
        >
          {actionsButton[tabActiveIndex].renderIcon}
        </Fab>
      </div>
    );
  else {
    return (
      <div className={classes.root}>
        <Backdrop open={openProfilButton} style={{ zIndex: 0 }} />
        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          className={classes.speedDial}
          hidden={false}
          icon={
            <SpeedDialIcon
              icon={<EditProfileIcon />}
              openIcon={<CloseIcon />}
            />
          }
          onClose={handleClose}
          onOpen={handleOpen}
          open={openProfilButton}
          FabProps={{
            classes: {
              root: classes.fab,
            },
            color: "primary",
          }}
        >
          {actions.map((action, index) => (
            <SpeedDialAction
              key={index}
              icon={action.icon}
              tooltipOpen
              tooltipTitle={action.name}
              onClick={() => {
                /*      handleClose();*/
                if (action.action) action.action();
              }}
            />
          ))}
        </SpeedDial>
      </div>
    );
  }
};
