import { useCallback, useEffect, useState } from "react";
import { useServices } from "services";
import { useTxHttp } from "services/http";

type EstimationStatus = "Loading" | "Loaded" | "LoadingFailed" | "Creating";

interface Estimation {
  url: string;
  priceMin?: number;
  priceMax?: number;
  reportPdfUrl: string;
  reportWebUrl: string;
}

interface EstimationService {
  create: () => void;
  estimation?: Estimation;
  status: EstimationStatus;
}

export const useEstimation = (
  contactId: string,
  profileId: string
): EstimationService => {
  const { post, get } = useTxHttp();
  const { endpoints } = useServices();
  const [estimation, setEstimation] = useState<Estimation>();
  const [status, setStatus] = useState<EstimationStatus>("Loading");

  const create = useCallback(() => {
    setStatus("Creating");
    post(endpoints.profiles.estimation(profileId), {
      content: null,
      contentType: "application/json",
    }).then((value) => setStatus("Loading"));
  }, [endpoints.profiles, post, profileId]);

  useEffect(() => {
    if (status !== "Loading") return;
    get<Estimation>(endpoints.profiles.estimation(profileId))
      .then((value) => {
        setEstimation(value.data);
        setStatus("Loaded");
      })
      .catch(() => setStatus("LoadingFailed"));
  }, [endpoints.profiles, get, profileId, status]);

  return { create, estimation, status };
};
