import Grid from "@material-ui/core/Grid";
import React, { useCallback } from "react";
import { Text12pxBold } from "UI/shared/atoms/typographies/Text12pxBold";
import { ChipSelect } from "UI/shared/molecules/ChipSelect";
import { MultipleValue, Radio } from "../types";

interface RadioProps {
  value: Radio;
  onChange: (refinement: Radio) => void;
  label: string;
}

export const RadioRefinement: React.FC<RadioProps> = ({
  label,
  value: { value },
  onChange,
}) => {
  const onClick = useCallback(
    (array: any, current: MultipleValue<String>) => () => {
      const optionsRefinement = array.map((option: any) => {
        if (option.value !== current.value) return option;
        return { ...option, isRefined: !option.isRefined };
      });

      onChange({ kind: "radio", value: optionsRefinement });
    },
    [onChange]
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} justify="center">
        <Text12pxBold>{label}</Text12pxBold>
      </Grid>
      {value?.map((option) => (
        <Grid item xs={4}>
          <ChipSelect
            key={option.value}
            label={option.label}
            value={option.value}
            isChecked={option.isRefined}
            onClick={onClick(value, option)}
            disabled={false}
          />
        </Grid>
      ))}
    </Grid>
  );
};
