import { get } from "lodash";
import moment from "moment";
import { formatPrix } from "utils/formatage";

import { en } from "./languages/en";
import { fr } from "./languages/fr";

export interface L10nService {
  t: (word: string) => string;
  compare: (a: string, b: string) => number;
  formatDateRange: (min?: Date, max?: Date) => string;
  formatSurfaces: (min?: number, max?: number) => string;
  formatSurfacesRange: (min?: number, max?: number) => string;
  formatSurface: (value: number) => string;
  formatRoom: (value?: number) => string;
  formatRoomsNumber: (minValue: number, maxValue?: number) => string;
  formatRoomsRange: (minValue: number, maxValue?: number) => string;
  formatYears: (minValue?: number, maxValue?: number) => string;
  formatBudget: (minValue?: number, maxValue?: number) => string;
  formatPolygon: (wktList: (string | undefined)[]) => string;
}

export type Lang = "fr" | "en";

export const l10nService = (language: () => Lang | undefined): L10nService => ({
  compare: (a: string, b: string) =>
    a.localeCompare(b, language() || "fr", { numeric: true }),

  formatBudget: (min?: number, max?: number) =>
    formatBudget(language() || "fr", min, max),

  formatDateRange: (min?: Date, max?: Date) => {
    if (!min && !max) return "";
    if (!min) {
      return `${getText(language() || "fr", "avant le")} ${moment(max).format(
        "DD/MM/YYYY"
      )}`;
    }
    if (!max) {
      return `${getText(language() || "fr", "depuis le")} ${moment(min).format(
        "DD/MM/YYYY"
      )}`;
    }

    return `${getText(language() || "fr", "du")} ${moment(min).format(
      "DD/MM/YYYY"
    )} ${getText(language() || "fr", "au")} ${moment(max).format(
      "DD/MM/YYYY"
    )}`;
  },

  formatPolygon: (wktList: (string | undefined)[]) => formatPolygon(wktList),

  formatRoom: (value?: number) => (value ? `${value}` : ""),

  formatRoomsNumber: (minValue: number, maxValue = 0) =>
    formatMinMax(language() || "fr", "", minValue, maxValue),

  formatRoomsRange: (minValue: number, maxValue = 0) =>
    formatMinMaxRange(language() || "fr", "", minValue, maxValue),

  formatSurface: (value: number) => (value ? `${value}\u00a0m\xB2` : ""),

  formatSurfaces: (min?: number, max?: number) =>
    formatSurfaces(language() || "fr", min, max),

  formatSurfacesRange: (min?: number, max?: number) =>
    formatSurfacesRange(language() || "fr", min, max),

  formatYears: (minValue = 0, maxValue = 0) =>
    formatMinMax(language() || "fr", "", minValue, maxValue),
  t: (word: string) => getText(language() || "fr", word),
});

export const getText = (language: Lang, term: string): string => {
  return get(Languages[language], term, term);
};

const formatMinMax = (
  language: Lang,
  unit: string,
  min?: number,
  max?: number
): string => {
  const labelMin = (min || 0) > 0 ? `${min}${unit}` : "";
  const labelMax = (max || 0) > 0 ? `${max}${unit}` : "";

  if (labelMin !== "" && labelMax !== "")
    return `${getText(language, "de")} ${labelMin} ${getText(
      language,
      "à"
    )} ${labelMax}`;

  if (labelMin !== "" && labelMax === "")
    return `${getText(language, "à partir de")} ${labelMin}`;

  if (labelMin === "" && labelMax !== "")
    return `${getText(language, "jusqu'à")} ${labelMax}`;

  return "";
};

const formatMinMaxRange = (
  language: Lang,
  unit: string,
  min?: number,
  max?: number
): string => {
  const labelMin = (min || 0) > 0 ? `${min}${unit}` : "";
  const labelMax = (max || 0) > 0 ? `${max}${unit}` : "";

  if (labelMin !== "" && labelMax !== "") return `${labelMin} - ${labelMax}`;

  if (labelMin !== "" && labelMax === "") return `${labelMin}+`;

  if (labelMin === "" && labelMax !== "")
    return `${getText(language, "jusqu'à")} ${labelMax}`;

  return "";
};

const formatMinMaxBudgetRange = (
  language: Lang,
  min?: number,
  max?: number
): string => {
  const labelMin = (min || 0) > 0 ? formatPrix(min) : "";
  const labelMax = (max || 0) > 0 ? formatPrix(max) : "";

  if (labelMin !== "" && labelMax !== "")
    return `${getText(language, "de")} ${labelMin} ${getText(
      language,
      "à"
    )} ${labelMax}`;

  if (labelMin !== "" && labelMax === "")
    return `${getText(language, "plus de")} ${labelMin}`;

  if (labelMin === "" && labelMax !== "")
    return `${getText(language, "jusqu'à")} ${labelMax}`;

  return "";
};

const formatSurfaces = (language: Lang, min?: number, max?: number): string =>
  formatMinMax(language, "\u00a0m\xB2", min, max);

const formatSurfacesRange = (
  language: Lang,
  min?: number,
  max?: number
): string => formatMinMaxRange(language, "\u00a0m\xB2", min, max);

const formatBudget = (language: Lang, min?: number, max?: number): string =>
  formatMinMaxBudgetRange(language, min, max);

const formatPolygon = (wktList: (string | undefined)[]): string => {
  const SINGLE_POLYGON = "POLYGON";
  const MULTIPLE_POLYGON = "MULTIPOLYGON";
  const values = wktList
    ? wktList.map((wkt) => wkt).filter((wkt) => wkt && wkt !== "")
    : [];

  if (values.length > 0) {
    if (values.length > 1) {
      const calculatedValues = values.map((v) =>
        v?.replace(SINGLE_POLYGON, "").replace(MULTIPLE_POLYGON, "")
      );
      return `${MULTIPLE_POLYGON} (${calculatedValues.join(",")})`;
    } else {
      return values[0] || "";
    }
  } else return "";
};

const Languages = { en, fr };
