import { ProfileType } from "routes/app/profiles/adapters/profileAdapterDetails";

import { EndpointsProvider } from "./interfaces/EndpointsProvider";

export interface ProfilesEndpoints {
  profile: (contactId: string, kind: string) => string;
  updateNotes: (contactId: string, profileId: string) => string;
  matching: {
    count: string;
    mine: (page: number, size: number) => string;
    theirs: (page: number, size: number) => string;
    realEstates: {
      byAgent: (profileId: string, contactId: string) => string;
    };
  };
  all: (contactId: string) => string;
  summary: string;
  allAgentProfiles: string;
  byId: (id: string, contactId: string) => string;
  estimation: (profileId: string) => string;
  getExport: (contactId: string, profileId: string) => string;
  export: (contactId: string, profileId: string) => string;
  legacyProfile: (legacyId: string, bu: string, type: ProfileType) => string;
  archive: (profileId: string, contactId: string) => string;
  reactivate: (profileId: string, contactId: string) => string;
  sharedRealEstates: (profileId: string) => string;
  shareRealEstates: string;
  autocompleteReference: string;
}
const legacyProfilePath = (type: ProfileType, legacyId: string) =>
  type === "Search" ? `Affaire/${legacyId}/Achat` : `/Affaire/Edit/${legacyId}`;

const endpoints: EndpointsProvider<ProfilesEndpoints> = (microServices) => ({
  all: (contactId: string) =>
    `${microServices.tx("crm")}/v2/contacts/${contactId}/profiles`,
  allAgentProfiles: `${microServices.tx("crm")}/v1/profiles`,
  archive: (profileId: string, contactId: string) =>
    `${microServices.tx(
      "crm"
    )}/v2/contacts/${contactId}/profiles/${profileId}/archive`,
  autocompleteReference: `${microServices.tx(
    "crm"
  )}/v2/profiles/reference/autocomplete`,
  byId: (id: string, contactId: string) =>
    `${microServices.tx("crm")}/v2/profiles/${id}/contact/${contactId}`,
  estimation: (profileId: string) =>
    `${microServices.tx("crm")}/v1/profiles/${profileId}/estimation`,
  export: (contactId: string, profileId: string) =>
    `${microServices.tx("crm")}/v1/contacts/${contactId}/profiles/${profileId}`,
  getExport: (contactId: string, profileId: string) =>
    `${microServices.tx("crm")}/v1/contacts/${contactId}/profiles/${profileId}`,
  legacyProfile: (legacyId: string, bu: string, type: ProfileType) =>
    `${
      bu.toLowerCase() === "capifrance"
        ? microServices.crmLegacyProfileCapifrance
        : microServices.crmLegacyProfileOptimhome
    }/${legacyProfilePath(type, legacyId)}`,
  matching: {
    count: `${microServices.tx("crm")}/v1/profiles/matching/count`,
    mine: (page, size) =>
      `${microServices.tx(
        "crm"
      )}/v1/profiles/matching/mine?page=${page}&size=${size}`,
    theirs: (page, size) =>
      `${microServices.tx(
        "crm"
      )}/v1/profiles/matching/theirs?page=${page}&size=${size}`,
    realEstates: {
      byAgent: (profileId, contactId) =>
        `${microServices.tx(
          "crm"
        )}/v1/matching/realestates?id=${profileId}&contactId=${contactId}`,
    },
  },
  profile: (contactId: string, kind: string) =>
    `${microServices.tx("crm")}/v2/contacts/${contactId}/profiles/${kind}`,
  reactivate: (profileId: string, contactId: string) =>
    `${microServices.tx(
      "crm"
    )}/v2/contacts/${contactId}/profiles/${profileId}/reactivate`,
  sharedRealEstates: (profileId) =>
    `${microServices.tx("crm")}/v1/realestate-sharing/${profileId}`,
  shareRealEstates: `${microServices.tx("crm")}/v1/realestate-sharing/email`,
  summary: `${microServices.tx("crm")}/v1/profiles/summary`,
  updateNotes: (contactId: string, profileId: string) =>
    `${microServices.tx(
      "crm"
    )}/v1/profiles/${profileId}/contact/${contactId}/notes/`,
});

export default endpoints;
