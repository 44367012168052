import React from "react";

import CommerceEntreprise from "UI/shared/atoms/Badges/C&E";
import Neuf from "UI/shared/atoms/Badges/Neuf";
import { SearchProfileBusinessTypeInfoChip } from "../../molecules/SearchProfileBusinessTypeInfoChip";
import { SearchProfileInfoChip } from "../../molecules/SearchProfileInfoChip";
import { SellerProfileBusinessTypeInfoChip } from "../../molecules/SellerProfileBusinessTypeInfoChip";
import { SellerProfileInfoChip } from "../../molecules/SellerProfileInfoChip";
import { InfoChipsList } from "../InfoChipsList";

export interface InfosChipsListProfileProps {
  profileType: string;
  businessType: string;
  propertyType?: string;
  propertyCategory: string;
}

export const InfosChipsListProfile: React.FC<InfosChipsListProfileProps> = ({
  propertyType,
  profileType,
  propertyCategory,
  businessType,
}) => {
  return (
    <InfoChipsList>
      {profileType === "Search" ? (
        <SearchProfileInfoChip />
      ) : (
        <SellerProfileInfoChip />
      )}
      {profileType === "Search" ? (
        <SearchProfileBusinessTypeInfoChip label={businessType} />
      ) : (
        <SellerProfileBusinessTypeInfoChip label={businessType} />
      )}
      {propertyType === "New" && <Neuf />}
      {propertyCategory === "Premises" && <CommerceEntreprise />}
    </InfoChipsList>
  );
};
