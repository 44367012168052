import { Grid, IconButton, Typography } from "@material-ui/core";
import {
  ChevronLeftOutlined,
  ChevronRightOutlined,
  RoomOutlined,
} from "@material-ui/icons";
import CardsCarousel from "components/CardsCarousel";
import ConteneurCarte from "components/ConteneurCarte";
import HitBien from "layoutContents/components/CarteBienMini/HitBien";
import TitrePanneau from "layoutContents/components/TitrePanneau";
import React, { useEffect, useRef, useState } from "react";
import routesNames from "router/routesNames";
import { useBiensDisponibles } from "services/biensDisponibles/useBiensDisponiblesListe";
import { useBiensDisponiblesRechercheGlobale } from "services/biensDisponibles/useBiensDisponiblesRechercheGlobale";
import { useBiensVendus } from "services/biensVendus/useBiensVendusListe";
import { useBiensVendusRechercheGlobale } from "services/biensVendus/useBiensVendusRechercheGlobale";
import { ErrorPanel } from "UI/shared/templates/Panels/ErrorPanel";

const ResumeBiens = ({
  mode = "recherche globale",
  paramsBiensEnVente,
  paramsBiensVendus,
  titreGeneral,
  titreBiensEnVente,
  titreBiensVendus,
}) => (
  <ConteneurCarte>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TitrePanneau
          Icone={RoomOutlined}
          entete={{ sousTitre: "", titre: titreGeneral }}
        />
      </Grid>
      <Grid item xs={12}>
        <ListeBiens
          dataReader={
            mode === "recherche globale"
              ? useBiensDisponiblesRechercheGlobale
              : useBiensDisponibles
          }
          mode={"biensEnVente"}
          titre={titreBiensEnVente}
          params={paramsBiensEnVente}
        />
      </Grid>
      <Grid item xs={12}>
        <ListeBiens
          dataReader={
            mode === "recherche globale"
              ? useBiensVendusRechercheGlobale
              : useBiensVendus
          }
          mode={"biensVendus"}
          titre={titreBiensVendus}
          params={paramsBiensVendus}
        />
      </Grid>
    </Grid>
  </ConteneurCarte>
);
export default ResumeBiens;

const ListeBiens = ({ mode, dataReader, params, titre }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [nombreDeCartes, setNombreDeCartes] = useState(4);
  const containerRef = useRef();

  useEffect(() => {
    const ajusterNombreDeCartes = () => {
      if (
        containerRef &&
        containerRef.current &&
        nombreDeCartes !==
          Math.floor(containerRef.current.getBoundingClientRect().width / 200)
      ) {
        setNombreDeCartes(
          Math.floor(containerRef.current.getBoundingClientRect().width / 200)
        );
      }
    };
    window.addEventListener("resize", ajusterNombreDeCartes);
    ajusterNombreDeCartes();
    return () => {
      window.removeEventListener("resize", ajusterNombreDeCartes);
    };
  }, [nombreDeCartes]);

  const { status, items } = dataReader({
    params,
  });

  const _items = items?.data || [];

  const leftChevron = (
    <IconButton
      style={{
        backgroundColor: "#f2f2f2",
        boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, .3)",
      }}
    >
      <ChevronLeftOutlined />
    </IconButton>
  );
  const rightChevron = (
    <IconButton
      style={{
        backgroundColor: "#f2f2f2",
        boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, .3)",
      }}
    >
      <ChevronRightOutlined />
    </IconButton>
  );

  const onHitClick = ({ id }) => {
    switch (mode) {
      case "biensEnVente":
        return `${routesNames.app}${routesNames.biens}${routesNames.bienEnVente}/${id}/details/view`;
      case "biensVendus":
        return `${routesNames.app}${routesNames.biens}${routesNames.bienVendu}/${id}/details/view`;
      default:
        return;
    }
  };

  if (status === "rejected")
    return (
      <ErrorPanel title={"Biens"} text={"Serveur indisponible."} mini={true} />
    );

  return (
    <div style={{ height: "320px" }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant={"body1"}>{titre}</Typography>
        </Grid>
        <Grid item xs={12} ref={containerRef}>
          <CardsCarousel
            requestToChangeActive={setActiveIndex}
            activeItemIndex={activeIndex}
            activePosition={"center"}
            numberOfCards={nombreDeCartes}
            enablePlaceholder={false}
            numberOfPlaceholderItems={nombreDeCartes}
            minimumPlaceholderTime={1000}
            placeholderItem={
              <div
                style={{
                  background: "#555",
                  height: 320,
                  width: 169,
                }}
              >
                Placeholder
              </div>
            }
            gutter={0}
            showSlither={true}
            firstAndLastGutter={true}
            freeScrolling={false}
            chevronWidth={42}
            leftChevron={leftChevron}
            rightChevron={rightChevron}
            outsideChevron={false}
          >
            {_items.map((item) => (
              <HitBien
                key={item.id}
                hit={item}
                mode={mode}
                onClick={() => onHitClick({ id: item.id })}
              />
            ))}
          </CardsCarousel>
        </Grid>
      </Grid>
    </div>
  );
};
