import {
  AdaptedRefinements,
  Refinement,
} from "../../../components/RefinementField/types";
import { RequestForInformationsCriteria } from "../../../types";

export const updateRefinementsState = (
  current: AdaptedRefinements<RequestForInformationsCriteria>,
  { key, value }: { key: RequestForInformationsCriteria; value: Refinement }
) => {
  return {
    ...current,
    [key]: {
      ...current[key],
      refinement: value,
    },
  };
};
