import { actionTypes } from "./action-types";
import { initLocations, initParams } from "./constants";
import { singleUpdateHandler } from "./state-handler";
import { ActionPayload, ActionType, State } from "./types";

export const reducer: (
  state: State,
  action: { type: ActionType; payload: ActionPayload }
) => State = (state, action) => {
  const newState = singleUpdateHandler(state);
  switch (action.type) {
    case actionTypes.CLEAR_PARAMS:
      return newState("params", initParams, true);
    case actionTypes.UPDATE_PARAMS:
      return newState("params", action.payload);
    case actionTypes.CLEAR_LOCATIONS:
      return newState("locations", initLocations, true);
    case actionTypes.UPDATE_LOCATIONS:
      return newState("locations", action.payload);
    case actionTypes.UPDATE_RESPONSE:
      return newState("response", action.payload);
    case actionTypes.UPDATE_DISPLAY_MODE:
      return newState("displayMode", action.payload);
    default:
      return state;
  }
};
