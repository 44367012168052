import React from "react";
import { RadioChip } from "UI/shared/molecules/RadioChip";
import { ParkingProposalKind } from "../../../../models/Proposal/Features/Parking/parkingInfoKind";
import { SubSectionTitle } from "../../../components/SectionTitle";

interface Props {
  value?: ParkingProposalKind;
  onChange: (value: ParkingProposalKind) => void;
  disabled: boolean;
  options: ParkingProposalKind[];
}

export const ParkingKind: React.FC<Props> = ({ value, onChange, options }) => {
  return (
    <>
      <SubSectionTitle title="Type de stationnement : " mode={"obligatoire"} />
      <RadioChip
        translationPrefix={"parkingKind"}
        options={options}
        value={value}
        onChange={onChange}
      />
    </>
  );
};
