import { Checkbox as C } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const Checkbox = withStyles(
  (theme) => ({
    root: {
      color: theme.palette.primary.main,
    },
  }),
  { withTheme: true }
)(C);
