import { makeStyles } from "@material-ui/core";
import { CommaSeparator } from "UI/shared/atoms/separators/CommaSeparator";
import { LocationCaption } from "UI/shared/organisms/LocationCaption";
import React from "react";

import { CitiesListTrailingCaption } from "../../molecules/CitiesListTrailingCaption";

export interface CityInfos {
  name: string;
  zipcode: string;
}

export interface CitiesListProps {
  cities: CityInfos[];
  visibleItemsCount?: number;
}

const useStyles = makeStyles(() => ({
  citiesListContainer: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  cityContainer: {
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
}));

export const CitiesList: React.FC<CitiesListProps> = ({
  cities,
  visibleItemsCount = 2,
}) => {
  const classes = useStyles();
  const citiesToDisplay =
    cities.length > visibleItemsCount
      ? cities.slice(0, visibleItemsCount)
      : cities;
  const deltaCitiesVsCitiesToDisplay = cities.length - visibleItemsCount;
  return (
    <div>
      <div className={classes.citiesListContainer}>
        {citiesToDisplay.map((city, index) => (
          <div key={`${city.name}-${index}`} className={classes.cityContainer}>
            <LocationCaption
              city={city.name}
              zipcode={city.zipcode}
              withIcon={index === 0}
            />
            {cities.length > 1 &&
              index < cities.length - deltaCitiesVsCitiesToDisplay - 1 && (
                <CommaSeparator />
              )}
          </div>
        ))}
        {deltaCitiesVsCitiesToDisplay > 0 && (
          <CitiesListTrailingCaption count={deltaCitiesVsCitiesToDisplay} />
        )}
      </div>
    </div>
  );
};
