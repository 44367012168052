import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import Nouveau from "../../../../../../../../../UI/shared/atoms/Badges/Nouveau";
import { formatAnciennete } from "../../../../../../../../../utils/formatage";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: "#FFFFFF",
    border: "solid 0px #57556F",
    borderRadius: "20px",
    marginTop: 2,
    opacity: "0.6",
  },
  chipLabel: {
    color: "#57556F",
    fontSize: "0.7rem",
    fontWeight: "bold",
    marginLeft: 7,
    marginRight: 7,
  },
}));

const MoisAnciennete = (props) => {
  const { t, moisAnciennete, mode = "galerie" } = props;
  const classes = useStyles();

  if (moisAnciennete <= 6) return <Nouveau t={t} />;
  return (
    <div
      className={classes.background}
      style={{
        textAlign: mode === "galerie" ? "center" : "left",
        width: mode === "galerie" ? "80px" : "120px",
      }}
    >
      <Typography
        variant={"body2"}
        className={classes.chipLabel}
        style={{ marginLeft: mode === "galerie" ? "7px" : "0px" }}
      >{`${t(formatAnciennete(moisAnciennete))}${
        mode === "liste" ? " d'ancienneté" : ""
      }`}</Typography>
      {mode === "galerie" && (
        <Typography variant={"body2"} style={{ fontSize: "10px" }}>
          {t("d'ancienneté")}
        </Typography>
      )}
    </div>
  );
};

export default MoisAnciennete;
