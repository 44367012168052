import { Dialog } from "@material-ui/core";
import React from "react";
import { useServices } from "services";

import { ModalRegisterProps } from ".";

export const ModalProvider: React.FC = ({ children }) => {
  const initialState: ModalRegisterProps = {
    body: <></>,
    fullScreen: false,
    maxWidth: "sm",
    open: false,
  };
  const [state, setState] = React.useState(initialState);
  const { modal } = useServices();
  modal.register(setState);
  return (
    <>
      {children}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth={true}
        fullScreen={state.fullScreen}
        maxWidth={state.maxWidth}
        onClose={() => setState(initialState)}
        scroll="paper"
        open={state.open}
      >
        {state.body}
      </Dialog>
    </>
  );
};
