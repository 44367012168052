import { Card, Grid, Typography } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import PhoneIcon from "@material-ui/icons/LocalPhoneOutlined";
import SendIcon from "@material-ui/icons/MailOutlined";
import PlaceIcon from "@material-ui/icons/PlaceOutlined";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import { BU } from "components/BU";
import IconAnchorAction from "components/IconAnchorAction";
import { VCardActionButton } from "UI/shared/molecules/Buttons/BoutonVCard";
import React from "react";
import MoisAnciennete from "routes/app/mon-reseau/conseillers/components/Hit/components/CarteConseiller/Anciennete";
import { buColors } from "utils/formatage";

import AvatarConseiller from "../../Avatar";

const CarteConseillerListe = ({
  conseiller,
  t,
  theme,
  classes,
  civiliteEnum,
  exportVCard,
  mini = false,
}) => {
  return (
    <Card style={{ position: "relative" }}>
      <Grid container spacing={1} alignItems="center">
        <Grid item md={2}>
          <div
            style={{
              margin: !mini ? "5px 5px 5px 20px" : "5px",
              width: "100%",
            }}
          >
            <AvatarConseiller
              conseiller={conseiller}
              taille={mini ? 50 : 100}
            />
          </div>
        </Grid>

        <Grid item md={3}>
          <div>
            <Typography variant="h4" noWrap={true}>
              {`${civiliteEnum[conseiller.civilite]} ${
                conseiller.prenom
              } ${conseiller.nom.toUpperCase()}`}
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            <Typography
              variant="h6"
              style={{
                fontWeight: "normal",
                minHeight: "1.43em",
              }}
            >
              {conseiller.specialites.join(" | ")}
            </Typography>
          </div>
          <MoisAnciennete
            t={t}
            moisAnciennete={conseiller.moisAnciennete}
            mode={"liste"}
          />
        </Grid>

        <Grid item md={3}>
          {conseiller.ville !== "" ? (
            <div
              style={{
                display: "flex",
                flexWrap: "nowrap",
                marginLeft: "-3px",
                verticalAlign: "middle",
              }}
            >
              <PlaceIcon
                style={{
                  color: "#8E8E8E",
                  fontSize: 14,
                  marginTop: 5,
                }}
              />
              <Typography variant="h6" noWrap style={{ lineHeight: 2 }}>
                {conseiller.ville.toUpperCase()}{" "}
                <span
                  style={{
                    fontSize: 10,
                    fontWeight: "regular",
                  }}
                >{` (${conseiller.codePostal})`}</span>
              </Typography>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexWrap: "nowrap",
                marginLeft: "-3px",
                verticalAlign: "middle",
              }}
            >
              <Typography variant="h6" noWrap style={{ lineHeight: 2 }}>
                &nbsp;
              </Typography>
            </div>
          )}
          <Grid container>
            <Grid item xs={12}>
              <IconAnchorAction
                link={conseiller.telephone}
                icon={<PhoneIcon style={{ fontSize: 15 }} />}
                action="tel"
                tooltip={t("Appeler")}
                couleur={buColors[conseiller.bu]}
              >
                <Typography variant="h3" className={classes.telephone}>
                  {conseiller.telephone}
                </Typography>
              </IconAnchorAction>
            </Grid>
            <Grid item xs={12}>
              <IconAnchorAction
                link={conseiller.email}
                icon={<SendIcon style={{ fontSize: 15 }} />}
                action="mailto"
                tooltip={t("Envoyer un mail")}
                couleur={buColors[conseiller.bu]}
              >
                <Typography variant="body2" noWrap className={classes.mail}>
                  {conseiller.email}
                </Typography>
              </IconAnchorAction>
            </Grid>
            <Grid item xs={12}>
              <div style={{ alignItems: "center", display: "flex" }}>
                <div>
                  <IconAnchorAction
                    link={conseiller.urlMiniSite}
                    icon={<PublicOutlinedIcon style={{ fontSize: 15 }} />}
                    action="link"
                    tooltip={t("Aller sur le site")}
                    placement={"bottom"}
                    couleur={buColors[conseiller.bu]}
                  >
                    <Typography variant="body2" noWrap className={classes.mail}>
                      Site
                    </Typography>
                  </IconAnchorAction>
                </div>

                {conseiller.lienFacebook !== "" && (
                  <>
                    <Typography variant="body2" noWrap>
                      &nbsp; | &nbsp;
                    </Typography>
                    <div>
                      <IconAnchorAction
                        link={conseiller.lienFacebook}
                        icon={<FacebookIcon style={{ fontSize: 15 }} />}
                        action="link"
                        tooltip={t("Aller sur la page Facebook")}
                        placement={"bottom"}
                        couleur={buColors[conseiller.bu]}
                      >
                        <Typography
                          variant="body2"
                          noWrap
                          className={classes.mail}
                        >
                          Facebook
                        </Typography>
                      </IconAnchorAction>
                    </div>
                  </>
                )}
                <Typography variant="body2" noWrap>
                  &nbsp; | &nbsp;
                </Typography>
                <VCardActionButton
                  conseiller={conseiller}
                  exportVCard={exportVCard}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4}>
          <Typography
            variant="h6"
            style={{
              color: theme.palette.neutral.main,
              fontWeight: "normal",
            }}
          >
            {conseiller.roles.join(" | ")}
          </Typography>
          <BU bu={conseiller.bu} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default CarteConseillerListe;
