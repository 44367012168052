import { IconButton, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect } from "react";

interface ListControlProps {
  onAdd: (index: number) => void;
  onDelete: (index: number) => void;
  maxChildrenCount?: number;
  hasAtLeastOneChildren?: boolean;
  areButtonsCentered?: boolean;
  disabled?: boolean;
}

export const ListControl: React.FC<ListControlProps> = ({
  onAdd,
  onDelete,
  children,
  maxChildrenCount,
  hasAtLeastOneChildren = false,
  areButtonsCentered = false,
  disabled = false,
}) => {
  const message = "Vous avez atteint le nombre maximum de lignes éditables";
  const hasChildren = Array.isArray(children) && children.length > 0;
  const maxCountReached =
    maxChildrenCount !== undefined &&
    Array.isArray(children) &&
    children.length >= maxChildrenCount;

  useEffect(() => {
    if (hasAtLeastOneChildren && !hasChildren) {
      onAdd(-1);
    }
  });

  return (
    <>
      {!hasChildren && (
        <>
          <div style={{ display: "flex" }}>
            <IconButton disabled={disabled} onClick={() => onAdd(-1)}>
              <AddIcon />
            </IconButton>
          </div>
        </>
      )}
      {hasChildren &&
        React.Children.map(children, (child, index) => {
          if (maxChildrenCount && index > maxChildrenCount) return;
          if (React.isValidElement(child)) {
            return (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: areButtonsCentered ? 8 : 0,
                  marginTop: areButtonsCentered ? 8 : 0,
                }}
              >
                <div style={{ flex: 4 }}>{child}</div>
                <div
                  style={{
                    alignSelf: "flex-start",
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    marginTop: areButtonsCentered ? 0 : 8,
                  }}
                >
                  <div>
                    {(!hasAtLeastOneChildren || index > 0) && (
                      <IconButton
                        disabled={disabled}
                        onClick={() => onDelete(index)}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={() => onAdd(index)}
                      disabled={disabled || maxCountReached}
                    >
                      <AddIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            );
          }
          return null;
        })}
      {maxCountReached && (
        <Typography variant={"body2"} style={{ color: "red" }}>
          {message}
        </Typography>
      )}
    </>
  );
};
