import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useServices } from "services";
import ConsultantBuyers from "./ConsultantBuyers";
import NetworkBuyers from "./NetworkBuyers";

const Buyers = ({
  consultantBuyers,
  networkBuyers,
  handleConsultantBuyersChange,
  handleNetworkBuyersChange,
}) => {
  const {
    l10n: { t },
  } = useServices();
  const otherBuyersTotal = networkBuyers.total;
  const buyersTotal = consultantBuyers.total;

  const myAcquirersLabel = `${t("Mes acquéreurs")} (${buyersTotal})`;

  const noBuyersLabel = `${t(
    "Pas d’acquéreur potentiel dans mes contacts sur ce bien"
  )}`;

  const networkAcquirersLabel = `${t(
    "Les acquéreurs des réseaux"
  )} (${otherBuyersTotal})`;

  const noNetworkBuyersLabel = `${t(
    "Pas d’acquéreur potentiel dans les contacts des réseaux sur ce bien"
  )}`;

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          {myAcquirersLabel}
        </Typography>
        {buyersTotal === 0 && (
          <Typography
            variant="body1"
            style={{ marginBottom: 10, marginTop: 30 }}
          >
            {noBuyersLabel}
          </Typography>
        )}
        {buyersTotal > 0 && (
          <ConsultantBuyers
            buyers={consultantBuyers.documents}
            currentPageNumber={consultantBuyers.pageNumber}
            totalPage={consultantBuyers.totalPage}
            handleConsultantBuyersChange={handleConsultantBuyersChange}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" style={{ marginBottom: 10, marginTop: 30 }}>
          {networkAcquirersLabel}
        </Typography>

        {otherBuyersTotal === 0 && (
          <Typography
            variant="body1"
            style={{ marginBottom: 10, marginTop: 30 }}
          >
            {noNetworkBuyersLabel}
          </Typography>
        )}

        {otherBuyersTotal > 0 && (
          <NetworkBuyers
            buyers={networkBuyers.documents}
            currentPageNumber={networkBuyers.pageNumber}
            totalPage={networkBuyers.totalPage}
            handleNetworkBuyersChange={handleNetworkBuyersChange}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Buyers;
