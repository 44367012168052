import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import PhoneIcon from "@material-ui/icons/LocalPhoneOutlined";
import SendIcon from "@material-ui/icons/MailOutlined";
import PlaceIcon from "@material-ui/icons/PlaceOutlined";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import { BU } from "components/BU";
import IconAnchorAction from "components/IconAnchorAction";
import { VCardActionButton } from "UI/shared/molecules/Buttons/BoutonVCard";
import React from "react";
import MoisAnciennete from "routes/app/mon-reseau/conseillers/components/Hit/components/CarteConseiller/Anciennete";
import { buColors } from "utils/formatage";

import AvatarConseiller from "../../Avatar";

export const CarteConseillerGalerieWithCardWrapper = (props) => (
  <Card>
    <CardContent style={{ padding: "8px" }}>
      <CarteConseillerGalerie {...props} />
    </CardContent>
  </Card>
);

export const CarteConseillerGalerie = ({
  conseiller,
  t,
  theme,
  classes,
  civiliteEnum,
  exportVCard,
  type = "",
}) => (
  <div
    style={{
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      overflowX: "hidden",
      position: "relative",
    }}
  >
    <div style={{ left: "0px", position: "absolute", top: "0px", zIndex: 1 }}>
      <MoisAnciennete t={t} moisAnciennete={conseiller.moisAnciennete} />
    </div>

    <div style={{ marginBottom: "5px" }}>
      <AvatarConseiller
        conseiller={conseiller}
        taille={type === "galerieMini" ? 50 : 100}
      />
    </div>
    <div style={{ textAlign: "center", width: "100%" }}>
      <Typography variant={type === "galerieMini" ? "h6" : "h4"} noWrap={true}>
        {`${civiliteEnum[conseiller.civilite]} ${
          conseiller.prenom
        } ${conseiller.nom.toUpperCase()}`}
      </Typography>
    </div>
    <div style={{ display: "flex" }}>
      <Typography
        variant="h6"
        style={{ fontWeight: "normal", minHeight: "1.43em" }}
      >
        {conseiller.specialites.join(" | ")}
      </Typography>
    </div>
    <div
      style={{
        borderTop: "1px solid lightGrey",
        height: type === "galerieMini" ? "1px" : "25px",
        marginTop: "5px",
        width: "50px",
      }}
    >
      &nbsp;
    </div>
    <div
      style={{
        minHeight: "1em",
        overflowX: "hidden",
        width: "100%",
      }}
    >
      {conseiller.ville !== "" ? (
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            marginLeft: "-3px",
            verticalAlign: "middle",
          }}
        >
          <PlaceIcon
            style={{
              color: "#8E8E8E",
              fontSize: 14,
              marginTop: 5,
            }}
          />
          <Typography variant="h6" noWrap style={{ lineHeight: 2 }}>
            {conseiller.ville.toUpperCase()}{" "}
            <span
              style={{
                fontSize: 10,
                fontWeight: "regular",
              }}
            >{` (${conseiller.codePostal})`}</span>
          </Typography>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            marginLeft: "-3px",
            verticalAlign: "middle",
          }}
        >
          <Typography variant="h6" noWrap style={{ lineHeight: 2 }}>
            &nbsp;
          </Typography>
        </div>
      )}
      <IconAnchorAction
        link={conseiller.telephone}
        icon={<PhoneIcon style={{ fontSize: 15 }} />}
        action="tel"
        tooltip={t("Appeler")}
        placement={"top"}
        couleur={buColors[conseiller.bu]}
      >
        <Typography variant="h3" className={classes.telephone}>
          {conseiller.telephone}
        </Typography>
      </IconAnchorAction>

      <IconAnchorAction
        link={conseiller.email}
        icon={<SendIcon style={{ fontSize: 15 }} />}
        action="mailto"
        tooltip={t("Envoyer un mail")}
        placement={"bottom"}
        couleur={buColors[conseiller.bu]}
      >
        <Typography variant="body2" noWrap className={classes.mail}>
          {conseiller.email}
        </Typography>
      </IconAnchorAction>
      <div style={{ alignItems: "center", display: "flex" }}>
        <div>
          <IconAnchorAction
            link={conseiller.urlMiniSite}
            icon={<PublicOutlinedIcon style={{ fontSize: 15 }} />}
            action="link"
            tooltip={t("Aller sur le site")}
            placement={"bottom"}
            couleur={buColors[conseiller.bu]}
          >
            <Typography variant="body2" noWrap className={classes.mail}>
              Site
            </Typography>
          </IconAnchorAction>
        </div>

        {conseiller.lienFacebook !== "" && (
          <>
            <Typography variant="body2" noWrap>
              &nbsp; | &nbsp;
            </Typography>
            <div>
              <IconAnchorAction
                link={conseiller.lienFacebook}
                icon={<FacebookIcon style={{ fontSize: 15 }} />}
                action="link"
                tooltip={t("Aller sur la page Facebook")}
                placement={"bottom"}
                couleur={buColors[conseiller.bu]}
              >
                <Typography variant="body2" noWrap className={classes.mail}>
                  Facebook
                </Typography>
              </IconAnchorAction>
            </div>
          </>
        )}
        <Typography variant="body2" noWrap>
          &nbsp; | &nbsp;
        </Typography>
        <div>
          <VCardActionButton
            conseiller={conseiller}
            exportVCard={exportVCard}
          />
        </div>
      </div>
    </div>
    <div
      style={{
        marginTop: type === "galerieMini" ? "1px" : "25px",
        overflowX: "hidden",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          style={{
            color: theme.palette.neutral.main,
            fontWeight: "normal",
          }}
        >
          {conseiller.roles.join(" | ")}
        </Typography>
        <BU bu={conseiller.bu} />
      </div>
    </div>
  </div>
);

export const CarteGalerieMiniWithoutWrapper = ({
  conseiller,
  t,
  theme,
  classes,
  civiliteEnum,
  exportVCard,
}) => {
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={3}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <AvatarConseiller conseiller={conseiller} taille={70} />
        </div>
      </Grid>

      <Grid item xs={9}>
        <div>
          <Typography variant="h4" noWrap>
            {`${civiliteEnum[conseiller.civilite]} ${
              conseiller.prenom
            } ${conseiller.nom.toUpperCase()}`}
          </Typography>
        </div>
        {conseiller.specialites.length > 0 && (
          <div style={{ display: "flex" }}>
            <Typography
              variant="h6"
              style={{
                fontWeight: "normal",
                minHeight: "1.43em",
              }}
            >
              {conseiller.specialites.join(" | ")}
            </Typography>
          </div>
        )}
        <Ville conseiller={conseiller} />
        <Telephone conseiller={conseiller} classes={classes} t={t} />
        <Email conseiller={conseiller} classes={classes} t={t} />
        <Liens
          conseiller={conseiller}
          classes={classes}
          t={t}
          exportVCard={exportVCard}
        />
      </Grid>
      <Grid item xs={1}>
        &nbsp;
      </Grid>
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              variant="h6"
              style={{
                color: theme.palette.neutral.main,
                fontWeight: "normal",
              }}
            >
              {conseiller.roles.join(" | ")}
            </Typography>
          </Grid>
          <Grid item xs={6} container justify={"flex-end"}>
            <BU bu={conseiller.bu} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        &nbsp;
      </Grid>
    </Grid>
  );
};

const Ville = ({ conseiller }) => {
  if (conseiller.ville === "")
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          marginLeft: "-3px",
          verticalAlign: "middle",
        }}
      >
        <Typography variant="h6" noWrap style={{ lineHeight: 2 }}>
          &nbsp;
        </Typography>
      </div>
    );
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexWrap: "nowrap",
        marginLeft: "-3px",
      }}
    >
      <PlaceIcon
        style={{
          color: "#8E8E8E",
          fontSize: 14,
          marginTop: "-3px",
        }}
      />
      <Typography variant="h6" noWrap>
        {conseiller.ville.toUpperCase()}{" "}
        <span
          style={{
            fontSize: 10,
            fontWeight: "regular",
          }}
        >{` (${conseiller.codePostal})`}</span>
      </Typography>
    </div>
  );
};

const Telephone = ({ conseiller, classes, t }) => (
  <IconAnchorAction
    link={conseiller.telephone}
    icon={<PhoneIcon style={{ fontSize: 15 }} />}
    action="tel"
    tooltip={t("Appeler")}
    couleur={buColors[conseiller.bu]}
    placement={"left"}
  >
    <Typography
      variant="body2"
      noWrap
      className={classes.telephone}
      style={{ fontSize: "18px" }}
    >
      {conseiller.telephone}
    </Typography>
  </IconAnchorAction>
);

const Email = ({ conseiller, classes, t }) => (
  <IconAnchorAction
    link={conseiller.email}
    icon={<SendIcon style={{ fontSize: 15 }} />}
    action="mailto"
    tooltip={t("Envoyer un mail")}
    couleur={buColors[conseiller.bu]}
    placement={"left"}
  >
    <Typography variant="body2" noWrap className={classes.mail}>
      {conseiller.email}
    </Typography>
  </IconAnchorAction>
);

const Liens = ({ conseiller, exportVCard, t, classes }) => (
  <div style={{ alignItems: "center", display: "flex" }}>
    <div>
      <IconAnchorAction
        link={conseiller.urlMiniSite}
        icon={<PublicOutlinedIcon style={{ fontSize: 15 }} />}
        action="link"
        tooltip={t("Aller sur le site")}
        placement={"bottom"}
        couleur={buColors[conseiller.bu]}
      >
        <Typography variant="body2" noWrap className={classes.mail}>
          Site
        </Typography>
      </IconAnchorAction>
    </div>

    {conseiller.lienFacebook !== "" && (
      <>
        <Typography variant="body2" noWrap>
          &nbsp; | &nbsp;
        </Typography>
        <div>
          <IconAnchorAction
            link={conseiller.lienFacebook}
            icon={<FacebookIcon style={{ fontSize: 15 }} />}
            action="link"
            tooltip={t("Aller sur la page Facebook")}
            placement={"bottom"}
            couleur={buColors[conseiller.bu]}
          >
            <Typography variant="body2" noWrap className={classes.mail}>
              Facebook
            </Typography>
          </IconAnchorAction>
        </div>
      </>
    )}
    <Typography variant="body2" noWrap>
      &nbsp; | &nbsp;
    </Typography>
    <div>
      <VCardActionButton conseiller={conseiller} exportVCard={exportVCard} />
    </div>
  </div>
);
