import { InfoChip } from "UI/shared/atoms/chips/InfoChip";
import React from "react";

interface SellerProfileBusinessTypeInfoChipProps {
  label: string;
}

export const SellerProfileBusinessTypeInfoChip: React.FC<SellerProfileBusinessTypeInfoChipProps> = ({
  label,
}) => {
  return <InfoChip color={"#004680"} label={label} />;
};
