import { Column } from "UI/shared/atoms/containers/Column";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import React, { useMemo } from "react";
import { AgentRealEstates } from "./agentRealEstates";
import { NetworkRealEstates } from "./networkRealEstates";
import { RealEstatesProps, SharingRealEstatesProps } from "./types";
import { RealEstatesSharingModal } from "routes/app/profiles/modals/RealEstatesSharing";
import { RealEstateMatchingDto } from "services/profiles/dto/RealEstateMatchingDto";
import { Grid } from "@material-ui/core";
import { useFeatureFlags } from "services/featureFlags";

export const RealEstates: React.FC<{
  profileId: string;
  byAgent: RealEstatesProps | SharingRealEstatesProps;
  byNetwork: RealEstatesProps | SharingRealEstatesProps;
  matching: RealEstateMatchingDto | undefined;
}> = (props) => {
  const { RealEstateSharing } = useFeatureFlags();

  const realEstatesSharingModal = useMemo(
    () =>
      RealEstateSharing && props.matching ? (
        <>
          <RealEstatesSharingModal
            loading={false}
            matching={props.matching}
            profileId={props.profileId}
          />
          <VerticalSpace size={8} />
        </>
      ) : (
        <></>
      ),
    [props.matching, RealEstateSharing]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        {realEstatesSharingModal}
      </Grid>
      <Grid item xs={12}>
        <AgentRealEstates {...props.byAgent} />
      </Grid>
      <Grid item xs={12}>
        <VerticalSpace size={32} />
      </Grid>
      <Grid item xs={12}>
        <NetworkRealEstates {...props.byNetwork} />
      </Grid>
    </Grid>
  );
};
