import React from "react";
import { ScrollItemChild } from "./types";

export const UnwrappedObservedItem = (
  child: ScrollItemChild,
  Observer: React.FC
) => {
  return (
    <>
      {child}
      <Observer>
        <div />
      </Observer>
    </>
  );
};
