import { makeStyles } from "@material-ui/core";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import React from "react";

import { ITheme } from "../../../../theme/ts/main";

export const useStyles = makeStyles((theme: ITheme) => ({
  buttonContainer: {
    alignItems: "center",
    background: "white",
    border: "1px solid lightGrey",
    borderRadius: "4px",
    cursor: "pointer",
    display: "flex",
    height: "32px",
    justifyContent: "center",
    padding: "10px",
    width: "32px",
  },
}));

interface ToggleHitsPaneButtonProps {
  toggleHandler: () => void;
  toggled: boolean;
}

export const ToggleHitsPaneButton: React.FC<ToggleHitsPaneButtonProps> = ({
  toggleHandler,
  toggled,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.buttonContainer} onClick={toggleHandler}>
      {toggled ? <ArrowLeft /> : <ArrowRight />}
    </div>
  );
};

export default ToggleHitsPaneButton;
