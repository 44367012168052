import { getOptionPieBiens, getOptionRadarBiens } from "theme/EChartsStyles";

export const getOptionPieActifs = (t, theme, data) => {
  const colors = [
    "#37C2CE",
    "#283FB2",
    "#FF9E25",
    "#D84466",
    "#F4C500",
    "#5473E8",
  ];
  return getOptionPieBiens(
    t,
    theme,
    [
      {
        left: "center",
        text: "",
        textStyle: { color: theme.palette.primary.main },
        top: "top",
      },
      {
        left: "center",
        text: t("BiensActifs"),
        textStyle: { color: theme.palette.primary.main },
        top: "10%",
      },
    ],
    colors,
    {
      data: data ? data.actifs : [],
      name: t("BiensActifs"),
    }
  );
};

export const getOptionPieVendus = (t, theme, data) => {
  const colors = [
    "#37C2CE",
    "#283FB2",
    "#FF9E25",
    "#D84466",
    "#F4C500",
    "#5473E8",
  ];
  return getOptionPieBiens(
    t,
    theme,
    [
      {
        left: "center",
        text: "",
        textStyle: { color: theme.palette.primary.main },
        top: "top",
      },
      {
        left: "center",
        text: t("BiensVendus"),
        textStyle: { color: theme.palette.primary.main },
        top: "10%",
      },
    ],
    colors,
    {
      data: data ? data.vendus : [],
      name: t("BiensVendus"),
    }
  );
};

export const getOptionRadarActifs = (t, theme, data) => {
  const color = [
    "#37C2CE",
    "#283FB2",
    "#FF9E25",
    "#D84466",
    "#F4C500",
    "#5473E8",
  ];
  return getOptionRadarBiens(
    t,
    theme,
    [
      {
        left: "center",
        text: "",
        textStyle: { color: theme.palette.primary.main },
        top: "top",
      },
      {
        left: "center",
        text: t("BiensActifs"),
        textStyle: { color: theme.palette.primary.main },
        top: "5%",
      },
    ],
    color,
    {
      data: data ? data.actifs : [],
      name: t("BiensActifs"),
    }
  );
};

export const getOptionRadarVendus = (t, theme, data) => {
  const color = [
    "#37C2CE",
    "#283FB2",
    "#FF9E25",
    "#D84466",
    "#F4C500",
    "#5473E8",
  ];
  return getOptionRadarBiens(
    t,
    theme,
    [
      {
        left: "center",
        text: "",
        textStyle: { color: theme.palette.primary.main },
        top: "top",
      },
      {
        left: "center",
        text: t("BiensVendus"),
        textStyle: { color: theme.palette.primary.main },
        top: "5%",
      },
    ],
    color,
    {
      data: data ? data.vendus : [],
      name: t("BiensVendus"),
    }
  );
};
