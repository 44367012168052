import { Grid, LinearProgress, useMediaQuery } from "@material-ui/core";
import AgenciesUnwanted from "components/AgenciesUnwanted/AgenciesUnwanted";
import CommerceEntreprise from "UI/shared/atoms/Badges/C&E";
import { useMoreFiltersContext } from "components/Searchbar/services/MoreFiltersProvider";
import React, { FC, useContext, useEffect } from "react";
import {
  ScrollToTopButton,
  useInfiniteScroll,
} from "services/useInfiniteScrollSR";
import { AnnonceInList } from "types";
import { MarginContainer } from "UI/shared/atoms/containers/MarginContainer";
import { Row } from "UI/shared/atoms/containers/Row";
import NoItemsPanel from "UI/shared/templates/Panels/NoItemsPanel";
import { PigeGlobalContext } from "../..";
import { PigeAdEventsContext } from "../../contexts/PigeAdEventsContextProvider";
import { PigeFavorisContext } from "../../contexts/PigeFavorisContextProvider";
import { PigeVisibilityContext } from "../../contexts/PigeVisibilityContextProvider";
import CarteBienReduite from "../../FicheBienPige/CarteBienReduite";
import { multipleUpdates } from "../../states/global/actions";
import { HitListe } from "../HitListe";
import { PigeFavorite } from "../PigeFavorite";

interface SearchResultProps {
  loadMore: () => void;
  hasNextPage: boolean;
  items: AnnonceInList[];
  onClear: () => void;
  forceGallery: boolean;
  hiddenIncluded: boolean;
  favoritesOnly: boolean;
}

export const SearchResult: FC<SearchResultProps> = (props) => {
  const smallScreenLimit = useMediaQuery("(max-width: 961px)");
  const galerie = props.forceGallery || smallScreenLimit;
  const clearRefinements = () => {
    props.onClear();
  };
  const { setIsOpen } = useMoreFiltersContext();
  const setOpenDrawer = () => {
    setIsOpen(true);
  };
  const {
    ViewObserverFirst,
    ViewObserverLast,
    firstVisible,
    lastVisible,
  } = useInfiniteScroll();

  useEffect(() => {
    if (lastVisible && props.hasNextPage) {
      props.loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastVisible]);
  // eslint-disable-next-line react/display-name
  const { isAdVisible } = useContext(PigeVisibilityContext);
  const { isFavorite } = useContext(PigeFavorisContext);

  const hiddenAdfilter = (annonce: AnnonceInList) =>
    props.hiddenIncluded || isAdVisible(annonce.id);

  const favoriteAdfilter = (annonce: AnnonceInList) =>
    !props.favoritesOnly || isFavorite(annonce.id);
  return (
    <>
      <Grid container spacing={2}>
        {props.items
          .filter(hiddenAdfilter)
          .filter(favoriteAdfilter)
          .map((annonce: AnnonceInList, index) => {
            if (index === 0)
              return (
                <Grid item xs={12} sm={galerie ? 3 : 12} key={annonce.id}>
                  <ViewObserverFirst>
                    <Annonce galerie={galerie} annonce={annonce} />
                  </ViewObserverFirst>
                </Grid>
              );

            if (index === props.items.length - 10)
              return (
                <Grid item xs={12} sm={galerie ? 3 : 12} key={annonce.id}>
                  <ViewObserverLast>
                    <Annonce galerie={galerie} annonce={annonce} />
                  </ViewObserverLast>
                </Grid>
              );

            return (
              <Grid item xs={12} sm={galerie ? 3 : 12} key={annonce.id}>
                <Annonce galerie={galerie} annonce={annonce} />
              </Grid>
            );
          })}
        {props.items.length < 1 && (
          <Row rowJustify="center" rowAlignItems="center">
            <MarginContainer top={25}>
              <NoItemsPanel
                clearRefinements={clearRefinements}
                setOpenDrawer={setOpenDrawer}
              />
            </MarginContainer>
          </Row>
        )}
      </Grid>
      {props.hasNextPage && <LinearProgress />}
      {!firstVisible && <ScrollToTopButton />}
    </>
  );
};

const getAnnonceEquality = (
  prevAnnonce: {
    annonce: AnnonceInList;
    galerie: boolean;
  },
  nextAnnonce: {
    annonce: AnnonceInList;
    galerie: boolean;
  }
) =>
  prevAnnonce.annonce.id === nextAnnonce.annonce.id &&
  prevAnnonce.galerie === nextAnnonce.galerie;

const getTagsList: (ad: AnnonceInList) => JSX.Element[] = (ad) => {
  const tagsList: JSX.Element[] = [];

  if (ad.joignableParAgence === "NonJoignable")
    tagsList.push(<AgenciesUnwanted key={0} />);

  if (ad.isCE) tagsList.push(<CommerceEntreprise key={0} />);

  return tagsList;
};

// eslint-disable-next-line react/display-name
export const Annonce = React.memo(
  (props: { annonce: AnnonceInList; galerie: boolean }) => {
    const { annonce } = props;
    const { dispatch } = useContext(PigeGlobalContext);
    const { adHasEvents } = useContext(PigeAdEventsContext);

    if (annonce.id === undefined) return <></>;

    const onClick = (id?: string) => {
      if (id === undefined) return;

      dispatch(
        multipleUpdates({
          scrollPosition: window.scrollY,
          selectedAdId: annonce.id,
        })
      );
    };

    const prix = annonce.prix || 0;
    const surface = annonce.surface || 0;
    const typeAffaire = annonce.typeAnnonce || "N/C";
    const typeAnnonceur = annonce.typeAnnonceur;
    const typeBien = annonce.typeBien || "N/C";
    const villeNom = annonce.ville || "N/C";

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const hasEvents = adHasEvents(annonce.id);
    const tagsList: JSX.Element[] = getTagsList(annonce);

    return props.galerie ? (
      <CarteBienReduite
        adId={annonce.id}
        photo={annonce.photoPrincipaleUrl}
        prix={prix}
        surface={surface}
        typeAffaire={typeAffaire}
        typeAnnonceur={typeAnnonceur}
        typeBien={typeBien}
        nbChambres={annonce.nbChambres}
        nbPieces={annonce.nbPieces}
        villeNom={villeNom}
        villeCodePostal={annonce.codePostal}
        onClick={() => onClick(annonce.id)}
        actions={[
          <PigeFavorite key={annonce.id} id={annonce.id} mode={"fab"} />,
        ]}
        estActive={annonce.estActive}
        galerie={props.galerie}
        hasEvents={hasEvents}
        typeDerniereEvolutionPrix={annonce.typeDerniereEvolutionPrix}
        listeTags={tagsList}
        bloctel={annonce.bloctel}
      />
    ) : (
      <HitListe
        hit={annonce}
        onClick={() => onClick(annonce.id)}
        hasEvents={hasEvents}
        mobile={props.galerie}
      />
    );
  },
  getAnnonceEquality
);
