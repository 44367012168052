import React from "react";
import Grid, {
  GridItemsAlignment,
  GridJustification,
  GridSize,
} from "@material-ui/core/Grid";

interface RowProps {
  rowAlignItems?: GridItemsAlignment | undefined;
  rowJustify?: GridJustification | undefined;
  itemSize?: GridSize;
}

export const Row: React.FC<RowProps> = ({
  rowAlignItems,
  itemSize,
  rowJustify,
  children,
}) => {
  const props = {
    ...(!!rowAlignItems && { alignItems: rowAlignItems }),
    ...(!!rowJustify && { justify: rowJustify }),
  };

  return (
    <Grid container direction="row" {...props}>
      {React.Children.map(children, (el) => (
        <Grid item xs={itemSize || "auto"}>
          {el}
        </Grid>
      ))}
    </Grid>
  );
};
