import { Typography } from "@material-ui/core";
import React from "react";

import { DescriptionProps } from "./Description";

export const TitleAndSummary: React.FC<Partial<DescriptionProps>> = ({
  title,
  summary,
  mobile,
}) => (
  <>
    {!mobile && (
      <Typography variant={"h4"} noWrap>
        {title}
        {summary && "\u00A0:\u00A0"}
        {summary && <span style={{ fontWeight: "normal" }}>{summary}</span>}
      </Typography>
    )}
    {mobile && (
      <>
        <Typography variant={"h4"} noWrap>
          {title}
        </Typography>
        <Typography variant={"h4"} noWrap>
          <span style={{ fontWeight: "normal" }}>{summary}</span>
        </Typography>
      </>
    )}
  </>
);
