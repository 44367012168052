import { MultiCheckbox } from "UI/shared/molecules/MultiCheckbox";
import React from "react";

import { PremisesAmenityType } from "../../../../models/Proposal/ForSale/Premises/premisesAmenityType";
import { SubSectionTitle } from "../../../components/SectionTitle";
import { ProfileInputProps } from "../../../InputProps";

export const PremisesAmenitiesSelection: React.FC<ProfileInputProps<
  PremisesAmenityType[]
>> = ({ onChange, value }) => {
  const options = [
    "Accessibility",
    "Offices",
    "Toilettes",
    "ChangingRoom",
    "MeetingRoom",
    "ReceptionArea",
    "StaffHousing",
    "WaitingRoom",
  ];
  return (
    <>
      <SubSectionTitle title="Prestations" mode={"non obligatoire"} />
      <MultiCheckbox
        translationPrefix={"amenity"}
        options={options}
        selectedOptions={value}
        onChange={onChange}
      />
    </>
  );
};
