import useNotifier from "components/Notifier/useNotifier";
import React, { createContext, FC, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { contactReadToWriteDto } from "services/portfolio/adapters";
import {
  DefaultReadContactDto,
  getMainEmailOrDefault,
  getMainPhoneOrDefault,
  ReadContactDto,
} from "services/portfolio/types";
import { usePortfolioActions } from "services/portfolio/usePortfolioActions";

import { validateEmail, validatePhoneNumber } from "../validators";

const ContactFormContext = createContext<{
  closeDialog: () => void;
  errors: {
    emails: string[];
    phones: string[];
    phoneNumberExistences: string[];
    emailAddressExistences: string[];
  };
  isDisabled: boolean;
  isLoading: boolean;
  saveContact: (_: ReadContactDto) => Promise<string | undefined>;
  setPhoneNumberExistences: React.Dispatch<React.SetStateAction<string[]>>;
  setEmailAddressExistences: React.Dispatch<React.SetStateAction<string[]>>;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setSubmitted: (_: boolean) => void;
  submit: (_: string) => Promise<void>;
  submitted: boolean;
  setShowMore: (_: boolean) => void;
  showMore: boolean;
  contactDto: ReadContactDto;
  setContactDto: React.Dispatch<React.SetStateAction<ReadContactDto>>;
}>({
  closeDialog: () => {
    return;
  },
  contactDto: DefaultReadContactDto,
  errors: {
    emailAddressExistences: [],
    emails: [],
    phoneNumberExistences: [],
    phones: [],
  },
  isDisabled: false,
  isLoading: false,
  saveContact: () => {
    return Promise.resolve(undefined);
  },
  setContactDto: () => {
    return;
  },
  setEmailAddressExistences: () => {
    return;
  },
  setIsDisabled: () => {
    return;
  },
  setPhoneNumberExistences: () => {
    return;
  },
  setShowMore: () => {
    return;
  },
  setSubmitted: () => {
    return;
  },
  showMore: false,
  submit: () => {
    return Promise.resolve();
  },
  submitted: false,
});

export const ContactFormContextProvider: FC<{
  initContact?: ReadContactDto;
  closeDialog: () => void;
}> = ({ initContact, closeDialog, children }) => {
  const [contactDto, setContactDto] = useState(
    initContact || DefaultReadContactDto
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [phoneNumberExistences, setPhoneNumberExistences] = useState(
    new Array(contactDto.identity.phones.length)
  );
  const [emailAddressExistences, setEmailAddressExistences] = useState<
    string[]
  >(new Array(contactDto.identity.emails.length));
  const [showMore, setShowMore] = useState(!!initContact);
  const history = useHistory();
  const errors = {
    emailAddressExistences,
    emails: contactDto.identity.emails.map((e) => {
      return e?.address && validateEmail(e?.address);
    }),
    phoneNumberExistences,
    phones: contactDto.identity.phones.map((p) => {
      return p?.number && validatePhoneNumber(p?.number);
    }),
  };

  const submit = async (actionAfterSave: string) => {
    setSubmitted(true);

    if (
      (getMainEmailOrDefault(contactDto.identity.emails) ||
        getMainPhoneOrDefault(contactDto.identity.phones)) &&
      Object.values(errors)
        .flat()
        .every((e) => !e)
    ) {
      setIsLoading(true);
      const createdContactId = await saveContact(contactDto);
      closeDialog();

      if (!createdContactId) return;

      switch (actionAfterSave) {
        case "GoToContactsList":
          history.push("/app/contacts/full-list");
          history.go(0);
          break;
        case "GoToSearchProfileCreation":
          history.push(
            `/app/contacts/contact/${createdContactId}/profils/searchProfile`
          );
          break;
        case "GoToForSaleProfileCreation":
          history.push(
            `/app/contacts/contact/${createdContactId}/profils/forSaleProfile`
          );
          break;
        default:
          history.push(
            `/app/contacts/contact/${createdContactId}/identity/view`
          );
          history.go(0);
      }
    }
  };

  const saveContact = async (
    contact: ReadContactDto
  ): Promise<string | undefined> => {
    const payload = contactReadToWriteDto(contact);
    const response = await (contact.id
      ? update(contact.id, payload)
      : add(payload));
    if (response) {
      showSnack({
        message: "Le contact est enregistré.",
        variant: "success",
      });
    } else {
      showSnack({
        message: "Une erreur est survenue.",
        variant: "error",
      });
    }

    return new Promise((resolve, reject) => {
      if (response) {
        resolve(response);
      }
      reject(undefined);
    });
  };

  const { showSnack } = useNotifier();
  const { add, update } = usePortfolioActions();

  return (
    <ContactFormContext.Provider
      value={{
        closeDialog,
        contactDto,
        errors,
        isDisabled: isDisabled || isLoading,
        isLoading,
        saveContact,
        setContactDto,
        setEmailAddressExistences,
        setIsDisabled,
        setPhoneNumberExistences,
        setShowMore,
        setSubmitted,
        showMore,
        submit,
        submitted,
      }}
    >
      {children}
    </ContactFormContext.Provider>
  );
};

export const useContactForm = () => useContext(ContactFormContext);
