import TitreSection from "../Details/components/TitreSection";
import General from "../Details/components/General";
import Surfaces from "../Details/components/Surfaces";
import AspectsFinanciers from "../Details/components/AspectsFinanciers";
import Proximites from "../Details/components/Proximites";
import Composition from "../Details/components/Composition";
import Prestations from "../Details/components/Prestations";
import DPEGES from "../Details/components/DPEGES";
import React from "react";
import { Grid } from "@material-ui/core";

export const OthersSections = ({ details }: { details: any }): JSX.Element => (
  <Grid container spacing={1}>
    <Grid item xs={6}>
      <TitreSection titre="Général" />
      <General general={details.general} />
    </Grid>
    {(details.surfaces.surfaceHabitable > 0 ||
      details.surfaces.superficieTerrain > 0 ||
      details.surfaces.surfacesInterieures.length > 0) && (
      <Grid item xs={6}>
        <TitreSection titre="Surfaces" />
        <Surfaces surfaces={details.surfaces} />
      </Grid>
    )}
    <Grid item xs={6}>
      <TitreSection titre="Aspects financiers" />
      <AspectsFinanciers aspectsFinanciers={details.aspectsFinanciers} />
    </Grid>
    {details.proximites && details.proximites.length > 0 && (
      <Grid item xs={6}>
        <TitreSection titre="Proximités" />
        <Proximites proximites={details.proximites} />
      </Grid>
    )}

    {details.composition.nbPieces > 0 && details.composition.nbChambres > 0 && (
      <Grid item xs={6}>
        <TitreSection titre="Composition" />
        <Composition composition={details.composition} />
      </Grid>
    )}

    {details.prestations && details.prestations.length > 0 && (
      <Grid item xs={6}>
        <TitreSection titre="Prestations" />
        <Prestations prestations={details.prestations} />
      </Grid>
    )}
    {details.dpeges.dpe !== " | " && (
      <Grid item xs={6}>
        <TitreSection titre="DPE/GES" />
        <DPEGES dpeges={details.dpeges} />
      </Grid>
    )}
  </Grid>
);
