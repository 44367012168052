import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { RequestForInformationDetails } from "routes/app/request-for-informations/pages/details";
import { useServices } from "services";
import { RequestForInformationDto } from "services/request-for-informations/dto/RequestForInformationDto";
import { PropertyForSale } from "services/request-for-informations/GetPropertyForSaleByAdReference";
const useStyles = makeStyles(() => ({
  dialogTitle: {
    boxShadow: "0px 3px 5px 0px rgba(0,0,0,0.2)",
    padding: "8px 24px",
    position: "relative",
    zIndex: 1,
  },
  titleContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
}));

export const RequestForInformationsDetailsModal: React.FC<{
  rfi: RequestForInformationDto;
  onClose: () => void;
  useGetPropertyForSaleByAdReference: (adReference: string) => PropertyForSale;
}> = ({ rfi, onClose, useGetPropertyForSaleByAdReference }) => {
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles();
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  return (
    <Dialog open={!!rfi} onClose={onClose} fullWidth={true} fullScreen={mobile}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.titleContainer}>
          <Typography variant={"h4"}>
            {t("Demande de renseignements")}
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {rfi ? (
          <RequestForInformationDetails
            rfi={rfi}
            useGetPropertyForSaleByAdReference={
              useGetPropertyForSaleByAdReference
            }
          />
        ) : (
          <CircularProgress />
        )}
      </DialogContent>
    </Dialog>
  );
};
