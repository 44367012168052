import { string, number, bool } from "api/DTOs/Common/Types";

function ObtenirParIdCarteAdapter(result = {}) {
  return {
    id: result.id || string,
    photoPrincipale: result.photoPrincipale || string,
    surface: result.surface || number,
    nbPieces: result.nbPieces || number,
    prix: result.prix || number,
    familleBienEnum: result.familleBienEnum || string,
    statutTransactionEnum: result.statutTransactionEnum || string,
    villeNom: result.villeNom || string,
    villeCodePostal: result.villeCodePostal || string,
    bu: result.bu || string,
    typeAffaireEnum: result.typeAffaireEnum || string,
    exclusivite: result.exclusivite || bool,
  };
}

export default ObtenirParIdCarteAdapter;
