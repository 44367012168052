import { makeStyles, useMediaQuery } from "@material-ui/core";
import React, { useEffect } from "react";
import { ProfileItemDto } from "services/profiles/dto/ReadModelProfileDto";

import {
  ScrollToTopButton,
  useInfiniteScroll,
} from "../../../../../services/useInfiniteScrollSR";
import { ProfileReadDto } from "../../../../../routes/app/contacts/contact/components/models/profilesReadDto";
import { DisplayedContactForList } from "../../../../../routes/app/profiles/adapters/contactAdapter";
import { DisplayedProfileForList } from "../../../../../routes/app/profiles/adapters/profileAdapter";
import { ProfileItem } from "../profile";

export interface ProfileReadExtendedDto {
  contact: DisplayedContactForList;
  profile: DisplayedProfileForList;
  profileRaw?: ProfileReadDto;
}

export interface ProfilesListProps {
  profiles: ProfileItemDto[];
  loadMore: () => void;
  pageSize: number;
}

const useStyles = makeStyles(() => ({
  profileContainer: {
    marginBottom: 8,
    marginRight: (props: { smallScreenMode: boolean }) =>
      props.smallScreenMode ? 4 : 0,
    marginLeft: (props: { smallScreenMode: boolean }) =>
      props.smallScreenMode ? 4 : 0,
  },
  profilesContainer: {
    display: "flex",
    flexDirection: (props: { smallScreenMode: boolean }) =>
      props.smallScreenMode ? "row" : "column",
    flexWrap: (props: { smallScreenMode: boolean }) =>
      props.smallScreenMode ? "wrap" : "nowrap",
    justifyContent: "center",
  },
}));

export const ProfilesList: React.FC<ProfilesListProps> = ({
  profiles,
  loadMore,
  pageSize,
}) => {
  const smallScreenMode = useMediaQuery("(max-width: 961px)");
  const classes = useStyles({ smallScreenMode });
  const {
    firstVisible,
    lastVisible,
    ViewObserverFirst,
    ViewObserverLast,
  } = useInfiniteScroll();

  useEffect(() => {
    if (lastVisible) loadMore();
  }, [lastVisible]);

  const triggerItemRank = Math.max(
    0,
    profiles.length - Math.round(pageSize / 3)
  );

  return (
    <div className={classes.profilesContainer}>
      {profiles.map((profile, index) => (
        <React.Fragment key={profile.id}>
          {index === 0 && (
            <div
              key={`${profile.id}-first`}
              className={classes.profileContainer}
            >
              <ProfileItem
                profileItem={profile}
                smallScreenMode={smallScreenMode}
              />
              <ViewObserverFirst>
                <></>
              </ViewObserverFirst>
            </div>
          )}
          {triggerItemRank !== 0 && index === triggerItemRank && (
            <div
              key={`${profile.id}-last`}
              className={classes.profileContainer}
            >
              <ViewObserverLast>
                <ProfileItem
                  profileItem={profile}
                  smallScreenMode={smallScreenMode}
                />
              </ViewObserverLast>
            </div>
          )}
          {index !== 0 && index !== triggerItemRank && (
            <div key={`${profile.id}`} className={classes.profileContainer}>
              <ProfileItem
                profileItem={profile}
                smallScreenMode={smallScreenMode}
              />
            </div>
          )}
        </React.Fragment>
      ))}
      {!firstVisible && <ScrollToTopButton />}
    </div>
  );
};
