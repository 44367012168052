import RenderHitBien from "layoutContents/Biens/components/Hit/renderHit";
import React from "react";
import RenderHitConseiller from "routes/app/mon-reseau/conseillers/components/Hit/renderHit";

import HitsContainer from "./HitsContainer";
import { pageSizeFromMode } from "./utils";

const RenderHits = ({
  clearRefinements,
  displayMode,
  firstRef,
  BienAvecAcquereurs = <></>,
  triggerRef,
  loading,
  mode,
  onUpdateRefinements,
  items,
  onUpdateSorting,
  refinements,
  searchbar,
  filterOptions,
  onHitClick = () => {},
  setOpenDrawer,
  onDetailPageURL = () => {},
}) => {
  const renderHit = (mode) => {
    if (mode === "conseillers")
      return (
        <RenderHitConseiller
          items={items}
          displayMode={displayMode}
          firstRef={firstRef}
          triggerRef={triggerRef}
          loading={loading}
          pageSize={pageSizeFromMode.get(displayMode).size}
          clearRefinements={clearRefinements}
          setOpenDrawer={setOpenDrawer}
        />
      );
    return (
      <RenderHitBien
        onHitClick={onHitClick}
        BienAvecAcquereurs={BienAvecAcquereurs}
        items={items}
        displayMode={displayMode}
        firstRef={firstRef}
        triggerRef={triggerRef}
        loading={loading}
        pageSize={pageSizeFromMode.get(displayMode).size}
        clearRefinements={clearRefinements}
        setOpenDrawer={setOpenDrawer}
        mode={mode}
        onDetailPageURL={onDetailPageURL}
      />
    );
  };
  return (
    <HitsContainer
      sortSelectSize={displayMode === "map" ? "big" : "small"}
      filterOptions={filterOptions}
      pagination={<div>&nbsp;</div>}
      searchbar={searchbar}
      refinements={refinements}
      clearRefinements={clearRefinements}
      onChange={onUpdateRefinements}
      onChangeSorting={onUpdateSorting}
    >
      {renderHit(mode)}
    </HitsContainer>
  );
};

export default RenderHits;
