import { ProfileType } from "routes/app/profiles/adapters/profileAdapterDetails";

type Bu = "Capifrance" | "Optimhome";

type SellerPage = Bu | "matching";

const sellerHelpPages: Record<SellerPage, string> = {
  Capifrance: "/static/markdowns/CRM/sellerProfileCapi.md",
  Optimhome: "/static/markdowns/CRM/sellerProfileOH.md",
  matching: "/static/markdowns/CRM/sellerProfileMatching.md",
};

const searchHelpPages: Record<Bu, string> = {
  Capifrance: "/static/markdowns/CRM/searchProfileCapi.md",
  Optimhome: "/static/markdowns/CRM/searchProfileOH.md",
};

const helpPages = {
  Seller: sellerHelpPages,
  Search: searchHelpPages,
};

export const getHelpPage = (
  profileType: ProfileType | undefined,
  bu: string,
  activeTabIndex: number
) => {
  if (!profileType) return "";
  switch (true) {
    case activeTabIndex === 0:
      return helpPages[profileType as ProfileType][bu as Bu];
    case activeTabIndex === 1 && profileType === "Seller":
      return helpPages["Seller"].matching;
    default:
      return "";
  }
};
