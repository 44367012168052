import React from "react";
import Typography from "@material-ui/core/Typography";

interface SourceTitleProps {
  title: string;
}

export const SourceTitle: React.FC<SourceTitleProps> = ({ title }) => (
  <Typography variant={"body1"} noWrap>
    {title && title.length > 0 && title !== "None" ? title : "N/C"}
  </Typography>
);
