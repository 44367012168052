import { BlocContainer } from "UI/shared/atoms/containers/BlocContainer";
import { LabelledValue } from "UI/shared/molecules/LabelledValue";
import React from "react";

export interface MoreInfosProps {
  title: string;
  value: { label: string; value: string }[];
}

export const MoreInfos: React.FC<MoreInfosProps> = ({ title, value }) => {
  const filtered = value.filter((info) => info.value !== "");
  const rows = filtered.map((info) => (
    <LabelledValue key={info.label} label={info.label} value={info.value} />
  ));
  return filtered.length <= 0 ? null : (
    <BlocContainer title={title} paddingTop={20}>
      {rows}
    </BlocContainer>
  );
};
