import { RadioGroup } from "@material-ui/core";
import { Phone } from "@material-ui/icons";
import CallIcon from "@material-ui/icons/Call";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import TodayOutlinedIcon from "@material-ui/icons/TodayOutlined";
import React from "react";
import { useServices } from "services";
import { EventKind } from "services/contactEventsTracking/dto/ContactEvent";

import { SelectOption, SelectOptionProps } from "../atoms/SelectOptions";

export const SelectEventKind: React.FC<{
  value?: EventKind;
  onChange: (value: EventKind) => void;
  groupDisabled?: boolean;
}> = ({ value, onChange, groupDisabled = false }) => {
  const {
    l10n: { t },
  } = useServices();
  const options: SelectOptionProps<EventKind>[] = [
    {
      checkedIcon: <CallIcon />,
      icon: <Phone />,
      value: "PhoneCall",
    },
    {
      checkedIcon: <SmsOutlinedIcon />,
      icon: <SmsOutlinedIcon />,
      value: "SendSms",
    },
    {
      checkedIcon: <MailOutlineIcon />,
      icon: <MailOutlineIcon />,
      value: "SendEmail",
    },
    {
      checkedIcon: <DescriptionOutlinedIcon />,
      icon: <DescriptionOutlinedIcon />,
      value: "SendMail",
    },
    {
      checkedIcon: <TodayOutlinedIcon />,
      icon: <TodayOutlinedIcon />,
      value: "Appointment",
    },
    {
      checkedIcon: <HelpOutlineOutlinedIcon />,
      icon: <HelpOutlineOutlinedIcon />,
      value: "Other",
    },
  ];

  return (
    <>
      <RadioGroup
        row
        value={value}
        onChange={(_) => onChange(_.target.value as EventKind)}
      >
        {options.map(({ value: _optionValue, icon, checkedIcon, disabled }) => (
          <SelectOption
            key={_optionValue}
            icon={icon}
            checkedIcon={checkedIcon}
            value={_optionValue}
            label={t(_optionValue)}
            disabled={disabled ?? groupDisabled}
          />
        ))}
      </RadioGroup>
    </>
  );
};
