import { Grid } from "@material-ui/core";
import { CardPaperItemSmall } from "UI/shared/atoms/containers/CardPaperItem/index.small.tsx";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";
import { BoldNumberUnitLabelNormalCaption } from "UI/shared/molecules/BoldNumberUnitLabelNormalCaption";
import { PriceCaption } from "UI/shared/molecules/PriceCaption";
import React from "react";
import { Maturity } from "routes/app/contacts/contact/components/Profiles/Maturity";

import { DisplayedProfileForList } from "routes/app/profiles/adapters/profileAdapter";
import { MonthIncome } from "UI/features/profiles/molecules/MonthIncome";
import { PropertyCategoryCaption } from "UI/features/profiles/molecules/PropertyCategoryCaption";
import { PropertyTypeCaption } from "UI/features/profiles/molecules/PropertyTypeCaption";
import { SurfaceCaption } from "UI/features/profiles/molecules/SurfaceCaption";
import { CitiesList } from "../CitiesList";
import { InfosChipsListProfile } from "../InfosChipsListProfile";
import { ProfileDetailsCaptionSmall } from "../ProfileDetailsCaption/index.small";
import { ProfileSummary } from "../ProfileSummary";

export const ProfileSummaryCard: React.FC<DisplayedProfileForList> = (
  props
) => {
  const infosChipsList = (
    <InfosChipsListProfile
      businessType={props.businessType}
      propertyType={props.propertyType}
      profileType={props.profileType}
      propertyCategory={props.propertyCategory}
    />
  );
  return (
    <CardPaperItemSmall>
      <PaddingContainer bottom={8} top={8} left={8} right={8}>
        <Grid container spacing={1}>
          <Grid item xs={11}>
            <ProfileSummary
              profileDetailsCaption={
                <ProfileDetailsCaptionSmall
                  propertyCategoryCaption={
                    <PropertyCategoryCaption label={props.propertyCategory} />
                  }
                  propertyTypeCaption={
                    props.propertyType !== "All" &&
                    props.propertyType !== undefined && (
                      <PropertyTypeCaption label={props.propertyType} />
                    )
                  }
                  monthIncome={
                    props.monthIncome ? (
                      <MonthIncome income={props.monthIncome} />
                    ) : undefined
                  }
                  price={<PriceCaption price={props.budgetMax} />}
                  surface={
                    props.surfaceMin !== undefined &&
                    props.surfaceMax !== undefined && (
                      <SurfaceCaption
                        surfaceMin={props.surfaceMin}
                        surfaceMax={props.surfaceMax}
                      />
                    )
                  }
                  bedroomsNumber={
                    props.bedroomsNumber ? (
                      <BoldNumberUnitLabelNormalCaption
                        total={props.bedroomsNumber}
                        unitLabel={
                          props.profileType === "Search" ? "+\xa0ch" : "ch"
                        }
                      />
                    ) : undefined
                  }
                  roomsNumber={
                    props.roomsNumber ? (
                      <BoldNumberUnitLabelNormalCaption
                        total={props.roomsNumber}
                        unitLabel={
                          props.profileType === "Search" ? "+\xa0p" : "p"
                        }
                      />
                    ) : undefined
                  }
                />
              }
              citiesList={
                <div>
                  <CitiesList cities={props.locations} visibleItemsCount={1} />
                </div>
              }
              infoChipsList={infosChipsList}
            />
          </Grid>
          <Grid item xs={1}>
            <Maturity value={props.maturity} />
          </Grid>
        </Grid>
      </PaddingContainer>
    </CardPaperItemSmall>
  );
};
