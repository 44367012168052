import { useReducer, useEffect } from "react";

const reducer = (state, action) => ({
  ...state,
  status: action.type,
  adaptedData: action.payload,
});

const useTestService = ({
  mockup,
  adapter,
  shouldExecute = true,
  params = null,
  erreur = false,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    status: "pending",
    adaptedData: adapter(),
  });

  useEffect(() => {
    if (!shouldExecute) return;
    setTimeout(() => {
      if (erreur) {
        dispatch({ type: "rejected", payload: adapter() });
      } else {
        dispatch({ type: "resolved", payload: adapter(mockup.result) });
      }
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mockup, params, shouldExecute]);

  return { status: state.status, adaptedData: state.adaptedData };
};

export default useTestService;
