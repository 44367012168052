import { Grid, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { FC, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import routesNames from "router/routesNames";
import { ProfilesContext } from "services/profiles";

import { useServices } from "../../../../../../services";
import { UpdateLocalContactContext } from "../..";
import { ArchivedProfilesIncentive } from "./components/ArchivedProfilesIncentive";
import { Form } from "./Form";
import { ForSaleForm } from "./ForSaleForm";
import { ProfilesIncentive } from "./ProfilesIncentive";
import { ProfileSmallCard } from "./ProfileSmallCard";

export interface ProfilesListProps {
  contactId: string;
  contactArchived: boolean;
}

const useStyles = makeStyles(() => ({
  activeProfilesTitle: { paddingBottom: "15px" },
  archivedProfilesTitle: { paddingBottom: "15px", paddingTop: "60px" },
}));

export const ProfilesList: FC<ProfilesListProps> = ({
  contactId,
  contactArchived,
}) => {
  const { getAll, profiles } = useContext(ProfilesContext);

  const { updateLocalContact } = useContext(UpdateLocalContactContext);

  useEffect(() => {
    if (contactId) getAll(contactId)();
  }, [contactId, getAll]);

  const {
    modal,
    l10n: { t },
  } = useServices();

  const classes = useStyles();

  const activeProfiles = profiles.filter((bp) => !bp.archivingDate);
  const archivedProfiles = profiles.filter((bp) => bp.archivingDate);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.activeProfilesTitle}>
          {`${t("ProfilsActifs")} `}({activeProfiles.length})
        </Typography>
      </Grid>
      {activeProfiles.length > 0 && (
        <>
          {activeProfiles.map((profile) => (
            <Grid key={profile.id} item xs={12} sm={6} lg={4} xl={3}>
              <NavLink
                style={{ textDecoration: "none" }}
                to={routesNames.profilePageTab(
                  contactId,
                  profile.id,
                  "details"
                )}
              >
                <ProfileSmallCard
                  profile={profile}
                  contactId={contactId}
                  updateLocalContact={updateLocalContact}
                />
              </NavLink>
            </Grid>
          ))}
        </>
      )}
      {profiles.length < 6 && !contactArchived && (
        <ProfilesIncentive
          onOpenFormulaireSimple={{
            buyerSimpleForm: () => {
              modal.show(<Form contactId={contactId} />);
            },
            sellerSimpleForm: () => {
              modal.show(<ForSaleForm contactId={contactId} />);
            },
          }}
        />
      )}
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.archivedProfilesTitle}>
          {`${t("ProfilsArchives")} `}({archivedProfiles.length})
        </Typography>
      </Grid>
      {archivedProfiles.length > 0 ? (
        <>
          <Grid container spacing={2}>
            {archivedProfiles.map((profile) => (
              <Grid key={profile.id} item xs={12} sm={6} lg={4} xl={3}>
                <NavLink
                  style={{ textDecoration: "none" }}
                  to={routesNames.profilePageTab(
                    contactId,
                    profile.id,
                    "details"
                  )}
                >
                  <ProfileSmallCard
                    profile={profile}
                    contactId={contactId}
                    updateLocalContact={updateLocalContact}
                  />
                </NavLink>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <ArchivedProfilesIncentive />
      )}
    </Grid>
  );
};
