import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { ReactElement } from "react";
import { ClearRefinementsButton } from "UI/shared/atoms/buttons/ClearRefinementsButton";
import { Rubrique } from "UI/shared/molecules/Rubrique";

export interface NoItemsPanelWithMoreFiltersProps {
  clearRefinements?: () => void;
  moreFiltersComponent: ReactElement;
}

export const NoItemsPanelWithMoreFilters: React.FC<NoItemsPanelWithMoreFiltersProps> = ({
  clearRefinements = () => {
    return;
  },
  moreFiltersComponent,
}) => {
  const moreFiltersText =
    "Ouvrir le panel de recherche avancée pour modifier les critères.";

  const clearRefinementsText = "Effacer tous les critères sélectionnés.";

  return (
    <Paper style={{ padding: "16px" }}>
      <Grid container direction={"column"} spacing={2}>
        <Grid item xs={12}>
          <Grid container justify={"center"}>
            <Typography variant={"h4"}>Aucun résultat disponible</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Rubrique bouton={moreFiltersComponent} texte={moreFiltersText} />
        </Grid>
        <Grid item xs={12}>
          <Rubrique
            bouton={<ClearRefinementsButton handleClear={clearRefinements} />}
            texte={clearRefinementsText}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
