import { IconButton } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import React from "react";

import { computedScrollToTopButtonStyle } from "./formatter";

const handleScrollToTop = (displayMode: string, currentHit: any) => {
  if (displayMode === "map") {
    currentHit.scrollTo({ behavior: "smooth", top: 0 });
  } else {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }
};

export const getScrollToTopButton = (
  firstIntersection: boolean,
  displayMode: string,
  classes: any,
  currentHit: any
): React.ReactNode =>
  !firstIntersection ? (
    <IconButton
      aria-label="go up"
      className={computedScrollToTopButtonStyle(displayMode, classes)}
      size="medium"
      onClick={() => handleScrollToTop(displayMode, currentHit)}
    >
      <ArrowUpwardIcon />
    </IconButton>
  ) : (
    <></>
  );
