interface GenerateKeyOptions {
  feature: string;
  id: string;
  customName?: string;
}

const generateKey: (_: GenerateKeyOptions) => string = ({
  feature,
  id,
  customName,
}) => {
  const customPart = customName ? `-${customName}` : "";
  const prefixPart = `${feature}${customPart}-list-item`;
  return `${prefixPart}-${id}`;
};

export const generateListKeys = <T extends { id: string }>(
  options: Omit<GenerateKeyOptions, "id">
) => (item: T) => generateKey({ ...options, id: item.id });
