import { Grid } from "@material-ui/core";
import {
  AdaptedRefinements,
  Refinement,
} from "components/RefinementField/types";
import React from "react";
import { ProfilesCriteria } from "types";
import SearchBar from "UI/shared/organisms/searchbar/SearchBar";
import { deepEquality } from "utils/objects/deepEquality";
import { MoreFilters } from "../MoreFilters/MoreFilters";

export interface ProfilesSearchBarProps {
  adaptedRefinements: AdaptedRefinements<ProfilesCriteria>;
  searchResultCount: number;
  onChange: (value: { key: ProfilesCriteria; value: Refinement }) => void;
}

export const ProfilesSearchBar: React.FC<ProfilesSearchBarProps> = React.memo(
  ({ adaptedRefinements, searchResultCount, onChange }) => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={1} justify="space-between">
            <Grid item xs={11}>
              <SearchBar
                entityName={"Profile"}
                displayMode={"list"}
                adaptedRefinements={adaptedRefinements}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={1}>
              <Grid container justify="flex-end">
                <MoreFilters
                  adaptedRefinements={adaptedRefinements}
                  onChange={onChange}
                  searchResultCount={searchResultCount}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  },
  (prev, next) => {
    return (
      deepEquality(prev.adaptedRefinements, next.adaptedRefinements) &&
      prev.searchResultCount === next.searchResultCount
    );
  }
);
