import { makeStyles } from "@material-ui/core";
import { LastUpdate } from "UI/shared/atoms/LastUpdate";
import { Status } from "UI/shared/molecules/Status";
import React from "react";

export interface ProfileStatusProps {
  status: "Archived" | "Active";
  date: Date;
}

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  ligne: {
    alignItems: "center",
    display: "flex",
  },
}));

export const ProfileStatus: React.FC<ProfileStatusProps> = ({
  status,
  date,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Status status={status} />
      <LastUpdate date={new Date(date)} />
    </div>
  );
};
