import { Typography } from "@material-ui/core";
import React from "react";

const TitreSection = (props) => {
  const { titre, isUpperCase = true } = props;

  return (
    <Typography
      variant="subtitle2"
      style={{
        borderBottom: "1px solid #e2e2e2",
        marginBottom: 10,
        paddingBottom: 5,
        paddingTop: 15,
        width: "100%",
      }}
    >
      {isUpperCase ? titre.toUpperCase() : titre}
    </Typography>
  );
};

export default TitreSection;
