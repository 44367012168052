import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useServices } from "services";

const useStyles = makeStyles(() => ({
  background: {
    backgroundColor: "#FFFFFF",
    border: "solid 1px #E82E75",
    borderRadius: "20px",
    marginTop: 2,
    textAlign: "center",
    width: 60,
  },
  chipLabel: {
    color: "#E82E75",
    fontSize: "0.7rem",
    fontWeight: "bold",
  },
}));

const Nouveau = () => {
  const {
    l10n: { t },
  } = useServices();

  const classes = useStyles();

  return (
    <div className={classes.background}>
      <Typography variant={"body2"} className={classes.chipLabel}>
        {t("Nouveau")}
      </Typography>
    </div>
  );
};

export default Nouveau;
