import { AppBar, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import GrosConteneurCarte from "UI/shared/atoms/containers/LargePaperContainer/index";
import { BackIcon } from "UI/shared/atoms/icons/BackIcon";
import React from "react";
import { breakpoint } from "theme/main";

import { MobileMenus } from "./MobileMenus";
import { Panel } from "./PanelComponents";
import { useStyles } from "./styles";

export type Tab = {
  content: JSX.Element;
  titre: string | JSX.Element;
  value?: string;
  name?: string;
  helpFilePath?: string;
};

interface DetailedEntityTabsLayoutProps {
  onClose?: () => void;
  composantsBarreGauche: { composant: JSX.Element }[];
  tabs: Tab[];
  actions?: JSX.Element[];
  titre: JSX.Element;
  tabActiveIndex?: number;
  onSelectedTabChanged?: ({ tabIndex }: { tabIndex: number }) => void;
}

const DesktopDisplay = ({
  composantsBarreGauche,
  tabs,
  tabActiveIndex = 0,
  onSelectedTabChanged,
  actions,
}: DetailedEntityTabsLayoutProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3} lg={2}>
        <Grid container direction="column" justify="space-between">
          <Grid item xs={12}>
            {composantsBarreGauche.map((composant, index) => (
              <div key={`desktop-sidebar-${index}`}>{composant.composant}</div>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={9} lg={10}>
        <AppBar position="static" color="default">
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
            }}
          >
            <Tabs
              value={tabActiveIndex}
              onChange={(evt, tabIndex) =>
                onSelectedTabChanged && onSelectedTabChanged({ tabIndex })
              }
              classes={{
                indicator: classes.indicator,
                root: classes.tabsRoot,
              }}
              aria-label={`fiche détail`}
              variant="fullWidth"
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={`full-width-tab-${index}`}
                  label={<Typography variant="h6">{tab.titre}</Typography>}
                  id={`full-width-tab-${index}`}
                  classes={{ root: classes.tabRoot }}
                />
              ))}
            </Tabs>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {actions}
            </div>
          </div>
        </AppBar>

        <GrosConteneurCarte>
          {tabs.map((tab, index) => (
            <Panel
              key={`full-width-tab-content-${index}`}
              id={index}
              tabValue={tabActiveIndex}
              content={tab.content}
            />
          ))}
        </GrosConteneurCarte>
      </Grid>
    </Grid>
  );
};

export const DetailedEntityTabsLayout = (
  props: DetailedEntityTabsLayoutProps
): JSX.Element => {
  const { onClose, tabs, titre, composantsBarreGauche } = props;
  const classes = useStyles();

  const handleBackButtonClick = () => {
    onClose?.();
  };

  const content = breakpoint().smAndDown ? (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3} lg={2}>
        <Grid container direction="column" justify="space-between">
          <Grid item xs={12}>
            {composantsBarreGauche.map((composant, index) => (
              <div key={`sidebar-${index}`}>{composant.composant}</div>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <MobileMenus tabs={tabs} />
    </Grid>
  ) : (
    <DesktopDisplay {...props} />
  );
  return (
    <div className={classes.root}>
      <div className={classes.titre}>
        {onClose && (
          <span
            id="pige-details-back-button"
            onClick={handleBackButtonClick}
            className={classes.retourListing}
          >
            <BackIcon />
          </span>
        )}
        {titre}
      </div>
      {content}
    </div>
  );
};
