const regex = /(\d)(?=(\d{3})+(?!\d))/g;

export const getInitialsCapitalized = (wordsList = []) => {
  if (Array.isArray(wordsList)) {
    const initials = wordsList
      .map((value) => value.substring(0, 1).toUpperCase())
      .join("");
    if (initials.length > 0) return initials;
  }

  return "?";
};

export function formatPourcent(prix) {
  return `${formatNull(
    prix != null && !isNaN(prix) ? prix.toFixed(2) : undefined
  )
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1\u00a0")}\u00a0%`;
}

export function formatPrix(prix) {
  return `${formatNull(prix ? Math.round(prix) : 0)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1\u00a0")}\u00a0€`;
}

export const formatPriceWithoutDecimals = (price) =>
  price.toLocaleString("fr-FR", {
    currency: "EUR",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: "currency",
  });

export const formatIntegerPriceAbove5K = (price) => {
  let _price = price;
  let currency = "\u20AC";
  if (_price > 5000) {
    _price = Math.ceil(_price / 1000);
    currency = "k\u20AC";
  }
  return `${_price.toLocaleString("fr-FR", {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })} ${currency}`;
};

export function formatNull(valeur) {
  return valeur === null || valeur === undefined ? "N/A" : valeur;
}

export function formatPriceAbove5k(price) {
  let currency = "";
  let formatedPrice = price;

  if (price > 5000) {
    currency = "K";
    formatedPrice = Math.ceil(formatedPrice / 1000);
  }

  return `${formatedPrice.toString().replace(regex, "$1 ")} ${currency}€`;
}

export function formatSurfaceRange(surfaceRange) {
  return `${surfaceRange
    .replace("[", "")
    .replace("@", " à ")
    .replace("]", " m\xB2")}`;
}

export const buColors = {
  Capifrance: "#0093f7",
  Digitregroup: "#CF1B8D",
  Optimhome: "#FF7B1E",
  Refleximmo: "#1C3765",
};

export const worldBoundingBox = {
  nw: `90, -180`,
  se: `-90, 180`,
};

export function formatAnciennete(moisAnciennete) {
  const nbAnnees = Math.floor(moisAnciennete / 12);
  if (moisAnciennete === 0) return "ce mois";
  if (nbAnnees === 0) return `${moisAnciennete} mois`;
  return `+ ${nbAnnees} an${nbAnnees > 1 ? "s" : ""}`;
}
