import React, { ReactElement } from "react";

import Loading from "../Loading/LoadingSkeleton";
import { ErrorPanel } from "UI/shared/templates/Panels/ErrorPanel";

interface error {
  titre: string;
  texte: string;
  mini: boolean;
}

interface DataProviderSRProps {
  dataReader: ({
    params,
    erreur,
  }: {
    params: any;
    erreur: boolean;
  }) => { status: "rejected" | "resolved" | "pending"; items: any };
  params: object | undefined;
  children: JSX.Element | JSX.Element[] | Function;
  erreur: error;
  provoqueErreur?: boolean;
}

const DataProviderSR = ({
  dataReader,
  params = {},
  children,
  erreur = { mini: false, texte: "Serveur indisponible", titre: "Erreur" },
  provoqueErreur = false,
}: DataProviderSRProps) => {
  const { status, items } = dataReader({
    erreur: provoqueErreur,
    params,
  });
  if (status === "pending") return <Loading />;
  if (status === "rejected")
    return (
      <ErrorPanel text={erreur.texte} title={erreur.titre} mini={erreur.mini} />
    );

  if (typeof children === "function") {
    return children(items);
  } else {
    const _newChildren = React.Children.map(children, (child) => {
      if (React.isValidElement(child))
        return React.cloneElement(child as ReactElement<any>, { items });
      return child;
    });

    return <>{_newChildren}</>;
  }
};

export default DataProviderSR;
