import { makeStyles } from "@material-ui/styles";
import React from "react";

import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";
import { Text12px } from "UI/shared/atoms/typographies/Text12px";
import { Text12pxBold } from "UI/shared/atoms/typographies/Text12pxBold";
import { useServices } from "services";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    paddingBottom: "10px",
    paddingTop: "5px",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));
export const CampaignDetailsSection: React.FC<{
  icon: React.ReactNode;
  title: string;
  content: string;
}> = ({ icon, title, content }) => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();
  return (
    <div className={classes.container}>
      <PaddingContainer right={5} width={""}>
        {icon}
      </PaddingContainer>
      <div className={classes.textContainer}>
        <PaddingContainer bottom={3}>
          <Text12pxBold>{t(title)}</Text12pxBold>
        </PaddingContainer>
        <Text12px>{content}</Text12px>
      </div>
    </div>
  );
};
