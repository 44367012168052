import { Grid } from "@material-ui/core";
import { Text12px } from "UI/shared/atoms/typographies/Text12px";
import { Text12pxBold } from "UI/shared/atoms/typographies/Text12pxBold";
import { Text14px } from "UI/shared/atoms/typographies/Text14px";
import { Text14pxBold } from "UI/shared/atoms/typographies/Text14pxBold";
import { Text16px } from "UI/shared/atoms/typographies/Text16px";
import { Text16pxBold } from "UI/shared/atoms/typographies/Text16pxBold";
import { Text18px } from "UI/shared/atoms/typographies/Text18px";
import { Text18pxBold } from "UI/shared/atoms/typographies/Text18pxBold";
import React from "react";

export interface LabelledValueProps {
  label: string;
  value: string | number;
  size?: "12" | "14" | "16" | "18";
}

export const LabelledValue: React.FC<LabelledValueProps> = ({
  label,
  value,
  size = "12",
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <div style={{ textAlign: "right" }}>
          {size === "12" && <Text12px>{label}</Text12px>}
          {size === "14" && <Text14px>{label}</Text14px>}
          {size === "16" && <Text16px>{label}</Text16px>}
          {size === "18" && <Text18px>{label}</Text18px>}
        </div>
      </Grid>
      <Grid item xs={6}>
        {size === "12" && <Text12pxBold>{value}</Text12pxBold>}
        {size === "14" && <Text14pxBold>{value}</Text14pxBold>}
        {size === "16" && <Text16pxBold>{value}</Text16pxBold>}
        {size === "18" && <Text18pxBold>{value}</Text18pxBold>}
      </Grid>
    </Grid>
  );
};
