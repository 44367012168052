import React from "react";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import { HorizontalDivider } from "UI/shared/atoms/HorizontalDivider";
import { Typography } from "@material-ui/core";

interface TitleAndHorizontalDividerProps {
  spaceBetween: number;
  title: string;
  dividerColor: string;
}

export const TitleAndHorizontalDivider: React.FC<TitleAndHorizontalDividerProps> = ({
  spaceBetween,
  title,
  dividerColor,
}) => {
  return (
    <>
      <Typography variant="subtitle2" noWrap>
        {title}
      </Typography>
      <VerticalSpace size={spaceBetween} />
      <HorizontalDivider color={dividerColor} height={1} />
    </>
  );
};
