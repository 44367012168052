import React from "react";
import { ProfileReadDto } from "routes/app/contacts/contact/components/models/profilesReadDto";
import { ProfileDetailsTab } from "UI/features/profiles/organisms/Search/ProfileDetailsTab";

export const getProfileDetailsTab = (
  readProfile: ProfileReadDto | undefined,
  profileId: string,
  contactId: string,
  t: (value: string) => string
) => ({
  content: (
    <ProfileDetailsTab
      key={0}
      readProfile={readProfile}
      profileId={profileId}
      contactId={contactId}
    />
  ),
  titre: <>{t("Détails").toUpperCase()}</>,
  value: "details",
});
