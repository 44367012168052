import { makeStyles } from "@material-ui/core";
import { Text16px } from "UI/shared/atoms/typographies/Text16px";
import React from "react";

export interface ProfileDetailsCaptionProps {
  propertyCategoryCaption: React.ReactNode;
  propertyTypeCaption?: React.ReactNode;
  surface?: React.ReactNode;
  roomsNumber?: React.ReactNode;
  bedroomsNumber?: React.ReactNode;
  price?: React.ReactNode;
  monthIncome?: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  ligne: {
    display: "flex",
  },
}));

export const ProfileDetailsCaptionLarge: React.FC<ProfileDetailsCaptionProps> = ({
  propertyTypeCaption,
  propertyCategoryCaption,
  surface,
  roomsNumber,
  bedroomsNumber,
  price,
  monthIncome,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.ligne}>
      {propertyCategoryCaption}
      {!!propertyTypeCaption && <PipeSeparator />}
      {!!propertyTypeCaption && propertyTypeCaption}
      <PipeSeparator />
      {!!surface && (
        <div className={classes.ligne}>
          {surface} <VerticalSeparator />
        </div>
      )}
      {!!roomsNumber && (
        <div className={classes.ligne}>
          {roomsNumber}
          <VerticalSeparator />
        </div>
      )}
      {!!bedroomsNumber && (
        <div className={classes.ligne}>
          {bedroomsNumber}
          <VerticalSeparator />
        </div>
      )}
      {price}
      <SimpleSeparator />
      {!!monthIncome && monthIncome}
    </div>
  );
};

const PipeSeparator: React.FC = () => <Text16px>&nbsp;-&nbsp;</Text16px>;
const VerticalSeparator: React.FC = () => <Text16px>&nbsp;|&nbsp;</Text16px>;
const SimpleSeparator: React.FC = () => <Text16px>&nbsp;&nbsp;</Text16px>;
