import React from "react";
import CarteInfo from "components/CarteInfo";
import "./loaderSquare.css";

const LoadingSquare = (props) => (
  <CarteInfo bigCard style={props.styles}>
    <div style={{ height: "100%" }}>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flex: 1,
          height: "100%",
          justifyContent: "center",
        }}
      >
        <div className="lds-grid">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </CarteInfo>
);

export default LoadingSquare;
