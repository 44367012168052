import { MicroServicesConfiguration } from "services/configuration/configuration";

import { EndpointsProvider } from "./interfaces/EndpointsProvider";

export interface ConseillerEndpoints {
  rechercheConseiller: string;
  monProfil: string;
  rechercheGlobale: string;
  rechercheGridOuListe: string;
  rechercheCarte: string;
  obtenirParId: string;
}

const endpoints: EndpointsProvider<ConseillerEndpoints> = (
  microServices: MicroServicesConfiguration
) => ({
  monProfil: `${microServices.tx("agents-queries")}/v1/Profil/MonProfil`,
  obtenirParId: `${microServices.tx(
    "agents-queries"
  )}/v1/Conseillers/ObtenirParId`,
  rechercheCarte: `${microServices.tx(
    "agents-queries"
  )}/v1/Conseillers/RechercheCarte`,
  rechercheConseiller: `${microServices.tx(
    "agents-queries"
  )}/v1/Conseillers/RechercheConseiller`,
  rechercheGlobale: `${microServices.tx(
    "agents-queries"
  )}/v1/Conseillers/RechercheGlobale`,
  rechercheGridOuListe: `${microServices.tx(
    "agents-queries"
  )}/v1/Conseillers/RechercheGridOuListe`,
});
export default endpoints;
