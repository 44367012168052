import { useCallback, useEffect, useState } from "react";
import { useServices } from "services";
import { ProfileItemDto } from "services/profiles/dto/ReadModelProfileDto";
import { decodeSlug } from "services/slugs/decodeSlug";
import { parseAsJson } from "services/slugs/parseAsJson";
import { Slugs } from "services/slugs/types";
import { getCriteriaKeys } from "utils/adaptedRefinements";
import {
  isAutoComplete,
  restoreAutoComplete,
} from "utils/adaptedRefinements/autocompleteText";

import {
  AdaptedRefinements,
  Refinement,
  Refinements,
} from "../../components/RefinementField/types";
import { ProfilesCriteria } from "../../types";
import { SLUG_STORAGE_KEY } from "./../slugs/constants";
import { PagedResultDto } from "./dto/PagedResultDto";
import { useReferenceAutocomplete } from "./useReferenceAutocomplete";
import { clearRefinements } from "./utils/clearRefinements";
import { updateRefinementsFromAggregations } from "./utils/updateRefinementsFromAggregations";

export const initialState: (
  getOptions: (_: string) => Promise<string[]>
) => AdaptedRefinements<ProfilesCriteria> = (getOptions) => ({
  budgetMax: {
    displayMode: "list",
    moreFiltersCategory: "budget",
    rank: 5,
    refinement: {
      kind: "single-text",
      value: "",
    },
    size: 2,
  },
  contactName: {
    displayMode: "list",
    moreFiltersCategory: "contact",
    rank: 3,
    refinement: {
      kind: "single-text",
      value: "",
    },
    size: 2,
  },
  familyKinds: {
    displayMode: "list",
    moreFiltersCategory: "characteristics",
    rank: 4,
    refinement: {
      kind: "multiple-text",
      value: [],
    },
    size: 2,
  },
  profileKinds: {
    displayMode: "list",
    moreFiltersCategory: "characteristics",
    rank: 2,
    refinement: {
      kind: "multiple-text",
      value: [],
    },
    size: 2,
  },
  reference: {
    displayMode: "list",
    moreFiltersCategory: "contact",
    rank: 6,
    refinement: {
      getOptions,
      kind: "autocomplete-text",
      value: "",
    },
    size: 2,
  },
  sources: {
    displayMode: "list",
    moreFiltersCategory: "characteristics",
    rank: 1,
    refinement: {
      kind: "multiple-text",
      value: [],
    },
    size: 2,
  },
  withArchivedProfiles: {
    displayMode: "moreFilters",
    moreFiltersCategory: "display",
    rank: 1,
    refinement: {
      displayChip: true,
      displayedValue: "inclure les éléments archivés",
      kind: "checkbox",
      value: false,
    },
    size: 2,
  },
});

const getStoredState: (
  getOptions: (_: string) => Promise<string[]>
) => AdaptedRefinements<ProfilesCriteria> | null = (
  getOptions: (_: string) => Promise<string[]>
) => {
  const storedState = sessionStorage.getItem(SLUG_STORAGE_KEY);
  if (storedState) {
    const { Profiles } = parseAsJson<Slugs>()(storedState);
    if (Profiles) {
      const decodedState = decodeSlug<ProfilesCriteria>(Profiles);
      return restoreState(initialState(getOptions), decodedState || {});
    }
  }
  return null;
};

const getInitialState = (getOptions: (_: string) => Promise<string[]>) =>
  getStoredState(getOptions) || initialState(getOptions);

const restoreState = (
  initial: AdaptedRefinements<ProfilesCriteria>,
  next: Partial<AdaptedRefinements<ProfilesCriteria>>
) => {
  const prevCriterias = getCriteriaKeys(initial);
  const nextCriterias = Object.keys(next) as ProfilesCriteria[];
  return prevCriterias.reduce((current, criteria) => {
    const isNextCriteria = nextCriterias.includes(criteria);
    const isAutoCompleteCriteria = isAutoComplete(initial, criteria);
    switch (true) {
      case !isNextCriteria:
        return {
          ...current,
          [criteria]: initial[criteria],
        };
      case isNextCriteria && isAutoCompleteCriteria:
        return {
          ...current,
          [criteria]: {
            ...next[criteria],
            refinement: restoreAutoComplete(initial, next, criteria),
          },
        };
      case isNextCriteria && !isAutoCompleteCriteria:
        return {
          ...current,
          [criteria]: {
            ...next[criteria],
            refinement:
              next[criteria]?.refinement || initial[criteria].refinement,
          },
        };
      default:
        return current;
    }
  }, {} as AdaptedRefinements<ProfilesCriteria>);
};

const updateRefinementsState = (
  current: AdaptedRefinements<ProfilesCriteria>,
  { key, value }: { key: ProfilesCriteria; value: Refinement }
) => ({
  ...current,
  [key]: {
    ...current[key],
    refinement: { ...current[key].refinement, ...value },
  },
});

const getRefinements = (
  adaptedRefinements: AdaptedRefinements<ProfilesCriteria>
) =>
  Object.keys(adaptedRefinements).reduce(
    (acc, k) => ({
      ...acc,
      [k]: adaptedRefinements[k as ProfilesCriteria].refinement,
    }),
    {} as Refinements<ProfilesCriteria>
  );

export const useAdaptedRefinements = (
  pagedResult: PagedResultDto<ProfileItemDto>
) => {
  const {
    l10n: { t },
  } = useServices();

  const { autocompleteReference } = useReferenceAutocomplete();

  const [adaptedRefinements, setAdaptedRefinements] = useState<
    AdaptedRefinements<ProfilesCriteria>
  >(getInitialState(autocompleteReference));

  const onChange = ({
    key,
    value,
  }: {
    key: ProfilesCriteria;
    value: Refinement;
  }) => {
    const nextState = updateRefinementsState(
      key === "reference"
        ? clearRefinements(adaptedRefinements)
        : adaptedRefinements,
      {
        key,
        value,
      }
    ) as AdaptedRefinements<ProfilesCriteria>;

    if (JSON.stringify(nextState) === JSON.stringify(adaptedRefinements))
      return;

    setAdaptedRefinements(nextState);
  };

  const onFullChange = useCallback(
    (next: AdaptedRefinements<ProfilesCriteria> | null) => {
      if (!next) return;

      setAdaptedRefinements((prev) =>
        restoreState(initialState(autocompleteReference), { ...prev, ...next })
      );
    },
    [adaptedRefinements]
  );

  const onClear = () => {
    setAdaptedRefinements((prev) => clearRefinements(prev));
  };

  useEffect(() => {
    if (!pagedResult || !pagedResult.aggregations) return;
    if (pagedResult.pageNumber === -1) return;

    const nextState = updateRefinementsFromAggregations(
      adaptedRefinements,
      pagedResult.aggregations,
      t
    );

    if (JSON.stringify(nextState) === JSON.stringify(adaptedRefinements))
      return;

    setAdaptedRefinements(nextState);
  }, [pagedResult.aggregations]);

  return {
    adaptedRefinements,
    onChange,
    onClear,
    onFullChange,
    refinements: getRefinements(adaptedRefinements),
  };
};
