import { uppercasedFirstLetter } from "utils/string/index";
import { useEffect, useState } from "react";

const getInitials = (firstname?: string, lastname?: string) =>
  [firstname || "", lastname || ""].map(uppercasedFirstLetter);

export const useAvatarOrQuestionMark = (
  firstname?: string,
  lastname?: string
) => {
  const [avatar, setAvatar] = useState<string>("");

  useEffect(() => {
    if (!firstname && !lastname) {
      setAvatar("?");
      return;
    }

    setAvatar(getInitials(firstname, lastname).join(""));
  }, [firstname, lastname]);

  return avatar;
};
