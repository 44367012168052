import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import MoreIcon from "@material-ui/icons/MoreVertOutlined";
import PrintIcon from "@material-ui/icons/PrintOutlined";
import RemoveCircleOutlinedIcon from "@material-ui/icons/RemoveCircleOutline";
import SubjectIcon from "@material-ui/icons/Subject";
import { LastUpdate } from "UI/shared/atoms/LastUpdate";
import { EmailLink } from "UI/shared/molecules/EmailLink";
import { PhoneLink } from "UI/shared/molecules/PhoneLink";
import React, { SyntheticEvent } from "react";
import { useHistory } from "react-router-dom";
import routesNames from "router/routesNames";
import { genderAdapter, toAvatarContent } from "services/portfolio/helpers";
import {
  getMainEmailOrDefault,
  getMainPhoneOrDefault,
  ReadContactDto,
  toVCard,
} from "services/portfolio/types";
import { ITheme } from "theme/ts/main";
import { ConsentStatus } from "types";

import { ContactStatusButton } from "../../routes/app/contacts/components/ContactStatusButton/ContactStatusButton";
import { useServices } from "../../services";
import { useFeatureFlags } from "../../services/featureFlags";
import VCardButton from "../Buttons/BoutonVCard";
import { ContactStatus } from "./ContactStatus";

const useStyles = makeStyles((theme: ITheme) => ({
  a: {
    "&:visited": {
      color: "inherit",
    },
    color: "inherit",
    textDecoration: "none",
  },
  acceptIcon: {
    marginRight: "10px",
  },
  acceptLegend: {
    marginRight: "10px",
  },
  acceptMenu: {
    marginBottom: "10px",
  },
  avatar: {
    backgroundColor: theme.palette.default,
    cursor: "pointer",
  },
  card: {
    paddingBottom: 0,
  },
  cardContent: {
    "&:last-child": {
      paddingBottom: 8,
    },
    padding: 0,
    paddingLeft: 16,
    paddingRight: 0,
  },
  cardHeaderContent: {
    height: "90px",
    overflowX: "hidden",
    textOverflow: "ellipsis",
  },
  chipLabel: {
    fontSize: "0.7rem",
    fontWeight: "bolder",
    marginLeft: 7,
    marginRight: 7,
  },
  clickable: {
    color: theme.palette.tonique,
    fontSize: 16,
    marginRight: 10,
  },
  clickableText: {
    "&:hover": {
      color: theme.palette.tonique,
    },
  },
  consentApproved: {
    "&:hover": {
      color: "#8ECC90",
    },
    color: "#C8E6C9",
  },
  consentDenied: {
    "&:hover": {
      color: "#BFB9B9",
    },
    color: "#E3E3E3",
  },
  consentNone: {
    "&:hover": {
      color: "#BFB9B9",
    },
    color: "#E3E3E3",
  },
  denyIcon: { marginRight: "10px" },
  denyMenu: {
    marginTop: "10px",
  },
  icons: {
    color: theme.palette.default,
  },
  menuItem: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    padding: 4,
  },
  notClickable: {
    color: theme.palette.common.dark,
    fontSize: 16,
    marginRight: 10,
  },
  notClickableText: {
    "&:hover": {
      color: theme.palette.common.dark,
    },
    color: theme.palette.common.dark,
  },
  proChip: {
    color: "#53868D",
    marginRight: 5,
  },
  root: {
    "&:hover": {
      boxShadow:
        "0px 3px 3px 0px rgba(0,0,0,0.34), 0px 1px 1px -1px rgba(0,0,0,0.1)",
    },
    position: "relative",
  },
  searchChip: {
    color: "#47B0B5",
    marginRight: 5,
  },
  searchCountChip: {
    borderColor: "#47B0B5",
  },
  sellerChip: {
    color: "#004680",
    marginRight: 5,
  },
  sellerCountChip: {
    borderColor: "#004680",
  },
  separatorMenu: {
    borderBottom: "1px solid #c3c3c3",
  },
}));

const StyledMenuItem = React.forwardRef<any, any>((props, ref) => {
  const classes = useStyles();
  return (
    <MenuItem {...props} classes={{ root: classes.menuItem }} innerRef={ref}>
      {props.children}
    </MenuItem>
  );
});
StyledMenuItem.displayName = "StyledMenuItem";

export type ProfileKind = "Seller" | "Search";

const ProfileKindChip: React.FC<{
  exists: boolean;
  profileKind: ProfileKind;
}> = ({ exists, profileKind }) => {
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles();
  return (
    <>
      {exists && (
        <Chip
          label={
            <>
              <span>{t(profileKind === "Seller" ? "Bien" : "Recherche")}</span>
            </>
          }
          size="small"
          className={
            profileKind === "Seller" ? classes.sellerChip : classes.searchChip
          }
          classes={{
            label: classes.chipLabel,
            root:
              profileKind === "Seller"
                ? classes.sellerCountChip
                : classes.searchCountChip,
          }}
          variant="outlined"
        />
      )}
      {!exists && <div style={{ height: 3 }} />}
    </>
  );
};

export const ConsentAction: React.FC<{
  contactId: string;
  consentStatus: ConsentStatus;
  denyContactAction: (contactId: string) => Promise<void>;
  acceptContactAction: (contactId: string) => Promise<void>;
}> = ({ contactId, consentStatus, denyContactAction, acceptContactAction }) => {
  const {
    l10n: { t },
  } = useServices();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const variant = (status: ConsentStatus) =>
    consentStatus === status ? "h4" : "subtitle1";

  const handleAcceptContact = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (consentStatus !== "Approved") {
      acceptContactAction(contactId);
    }
    setAnchorEl(null);
  };
  const handleDenyContact = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (consentStatus !== "Denied") {
      denyContactAction(contactId);
    }
    setAnchorEl(null);
  };
  const handleCloseMenu = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };
  const handleClickOpenDialog = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget as any);
  };

  return (
    <>
      <Tooltip title={""} placement="top">
        <>
          {consentStatus === "Denied" && (
            <RemoveCircleOutlinedIcon
              className={classes.consentDenied}
              onClick={handleClickOpenDialog}
            />
          )}
          {consentStatus === "Approved" && (
            <CheckCircleOutlinedIcon
              className={classes.consentApproved}
              onClick={handleClickOpenDialog}
            />
          )}
        </>
      </Tooltip>
      <Menu
        id="consent-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <StyledMenuItem
          onClick={handleAcceptContact}
          className={classes.acceptMenu}
        >
          <CheckCircleOutlinedIcon className={classes.acceptIcon} />
          <Typography
            variant={variant("Approved")}
            className={classes.acceptLegend}
          >
            {t("Accepte d'être contacté")}
          </Typography>
        </StyledMenuItem>
        <div className={classes.separatorMenu} />
        <StyledMenuItem
          onClick={handleDenyContact}
          className={classes.denyMenu}
        >
          <RemoveCircleOutlinedIcon className={classes.denyIcon} />
          <Typography variant={variant("Denied")}>
            {t("Refuse d'être contacté")}
          </Typography>
        </StyledMenuItem>
      </Menu>
    </>
  );
};

const CarteContact: React.FC<{
  contact: ReadContactDto;
  handleAcceptConsent: (id: string) => Promise<void>;
  handleDenyConsent: (id: string) => Promise<void>;
  avatar?: boolean;
  avecMenu?: boolean;
  handleShow?: () => void;
  handleUpdate?: (c: any) => void;
  handleActivate?: (id: any) => Promise<any>;
  handleArchive?: (
    id: any,
    { consentStatus: ConsentStatus }: { consentStatus: ConsentStatus }
  ) => Promise<any>;
  withPrintButton?: boolean;
  galery?: boolean;
}> = ({
  contact,
  handleAcceptConsent,
  handleDenyConsent,
  avatar = true,
  avecMenu = false,
  handleShow = (_: ReadContactDto) => {
    return;
  },
  handleUpdate = () => {
    return;
  },
  handleActivate = () => Promise.resolve(),
  handleArchive = () => Promise.resolve(),
  withPrintButton = false,
  galery = false,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    l10n: { t },
  } = useServices();
  const history = useHistory();
  const { Seller_profile } = useFeatureFlags();

  const handleClickOpenDialog = () => {
    handleShow(contact);
  };
  const handleEdit = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    handleUpdate(contact);
  };

  const archiveContact = async (consentStatus: ConsentStatus) => {
    await handleArchive(contact.id, {
      consentStatus,
    });
    setAnchorEl(null);
  };

  const activateContact = async () => {
    await handleActivate(contact.id);
    setAnchorEl(null);
  };

  const handleClickMore = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget as any);
  };
  const handleCloseMenu = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  const handlePrint = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    history.push(
      `${routesNames.app}${routesNames.contact}/${contact.id}/identity/print`
    );
  };

  const itemForVCard = toVCard(contact);

  return (
    <Card className={classes.root} style={{ zIndex: 0 }}>
      <CardHeader
        avatar={
          avatar ? (
            <div>
              <Avatar
                aria-label={contact.identity.lastName}
                className={classes.avatar}
              >
                {toAvatarContent(contact.identity)}
              </Avatar>
            </div>
          ) : null
        }
        title={
          <div
            style={{
              cursor: "pointer",
              height: "100%",
              position: "relative",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="h4"
                style={{
                  cursor: "pointer",
                  marginBottom: "5px",
                }}
                noWrap
              >
                {`${genderAdapter(contact.identity.gender)} ${
                  contact.identity.firstName || ""
                } ${(contact.identity.lastName || "").toUpperCase()}`}
                {!contact.identity.firstName && !contact.identity.lastName && (
                  <span style={{ color: "#8e8e8e" }}>{t("Non renseigné")}</span>
                )}
              </Typography>
              {contact.status !== "Archived" && (
                <div style={{ justifyContent: "flex-end" }}>
                  <ConsentAction
                    contactId={contact.id}
                    consentStatus={contact.consentStatus}
                    acceptContactAction={handleAcceptConsent}
                    denyContactAction={handleDenyConsent}
                  />
                </div>
              )}
            </div>

            <PhoneLink value={getMainPhoneOrDefault(contact.identity.phones)} />
            <EmailLink value={getMainEmailOrDefault(contact.identity.emails)} />
            {avecMenu && <LastUpdate date={new Date(contact.updateDate)} />}
          </div>
        }
        titleTypographyProps={{ noWrap: true, variant: "h4" }}
        className={classes.card}
        classes={{
          content: classes.cardHeaderContent,
        }}
      />

      <CardContent classes={{ root: classes.cardContent }}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flex: 1,
            justifyContent: "flex-start",
            marginTop: "5px",
            paddingLeft: 55,
          }}
        >
          {contact.status === "Archived" ? (
            <ContactStatus contactStatus={contact.status} />
          ) : (
            <>
              <ProfileKindChip
                exists={contact.hasSearchProfile}
                profileKind="Search"
              />
              {Seller_profile && (
                <ProfileKindChip
                  exists={contact.hasForSaleProfile}
                  profileKind="Seller"
                />
              )}
            </>
          )}
          {avecMenu && (
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              {contact.status !== "Archived" && (
                <Tooltip title={t("ModifierContact")} placement="right">
                  <IconButton onClick={handleEdit} aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title={t("AutresOptionsContact")} placement="top">
                <IconButton
                  className={classes.icons}
                  onClick={handleClickMore}
                  aria-label="plus"
                >
                  <MoreIcon />
                </IconButton>
              </Tooltip>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <StyledMenuItem
                  onClick={handleClickOpenDialog}
                  aria-label="voir"
                >
                  <IconButton className={classes.icons}>
                    <SubjectIcon />
                  </IconButton>
                  {!galery && <span>{t("ViewContactButton")}</span>}
                </StyledMenuItem>

                <StyledMenuItem>
                  <ContactStatusButton
                    consentStatus={contact.consentStatus}
                    contactStatus={contact.status}
                    activate={activateContact}
                    archive={archiveContact}
                  >
                    {!galery && (
                      <span style={{ paddingTop: "2px" }}>
                        {contact.status === "Archived"
                          ? t("Réactiver")
                          : t("Archiver")}
                      </span>
                    )}
                  </ContactStatusButton>
                </StyledMenuItem>

                {contact.status !== "Archived" && (
                  <StyledMenuItem onClick={handleEdit} aria-label="edit">
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                    {!galery && <span>{t("UpdateContactButton")}</span>}
                  </StyledMenuItem>
                )}

                <StyledMenuItem>
                  <VCardButton item={itemForVCard} nameId withLabel={!galery} />
                </StyledMenuItem>

                {withPrintButton && (
                  <StyledMenuItem onClick={handlePrint}>
                    <IconButton aria-label="print">
                      <PrintIcon />
                    </IconButton>
                    {!galery && <span>{t("PrintContactButton")}</span>}
                  </StyledMenuItem>
                )}
              </Menu>
            </Grid>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default CarteContact;
