import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

import { useServices } from "../../services";
import { ContactStatusType } from "../../services/portfolio/types";
import { ITheme } from "../../theme/ts/main";

const useStyles = makeStyles((theme: ITheme) => ({
  container: {
    alignItems: "center",
    display: "flex",
  },
  puce: {
    backgroundColor: theme.palette.greyMedium,
    borderRadius: "3px",
    height: "10px",
    margin: "10px",
    width: "10px",
  },
}));

export const ContactStatus: React.FC<{ contactStatus: ContactStatusType }> = ({
  contactStatus,
}) => {
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.puce} />
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        {t(contactStatus)}
      </Typography>
    </div>
  );
};
