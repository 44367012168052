import { RealEstateMatchingMode } from "./types";

export const initialParams = (mode: RealEstateMatchingMode) => ({
  page: 1,
  size: mode === "count" ? 1 : 20,
});

export const initialState = (mode: RealEstateMatchingMode) => ({
  pages: {},
  params: initialParams(mode),
  total: 0,
  loading: false,
  currentPage: 1,
  lastIds: [],
});
