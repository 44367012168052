import { EndpointsProvider } from "./interfaces/EndpointsProvider";

export interface MarketingCampaignsEndpoints {
  getCurrentCampaigns: string;
  subscribeOrUnsubscribe: (campaignId: string) => string;
}

export const endpoints: EndpointsProvider<MarketingCampaignsEndpoints> = (
  microServices
) => ({
  getCurrentCampaigns: `${microServices.tx(
    "marketing-campaigns"
  )}/v1/Marketing/Campaigns`,
  subscribeOrUnsubscribe: (campaignId: string) =>
    `${microServices.tx(
      "marketing-campaigns"
    )}/v1/Marketing/Campaigns/${campaignId}`,
});

export const endpointsV2: EndpointsProvider<MarketingCampaignsEndpoints> = (
  microServices
) => ({
  getCurrentCampaigns: `${microServices.tx(
    "marketing-campaigns"
  )}/v2/Marketing/Campaigns`,
  subscribeOrUnsubscribe: (campaignId: string) =>
    `${microServices.tx(
      "marketing-campaigns"
    )}/v2/Marketing/Campaigns/${campaignId}`,
});
