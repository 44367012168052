import { NotificationSettingsCenterDto } from "../dto/NotificationSettingsCenterDto";

export interface NotificationsSettingsContext {
  notificationSettingsCenter: NotificationSettingsCenterDto;
  update: (notificationSettingsCenter: NotificationSettingsCenterDto) => void;
}

export const defaultNotificationsSettingsCenterContext = {
  notificationSettingsCenter: {} as NotificationSettingsCenterDto,
  update: (notificationSettingsCenter: NotificationSettingsCenterDto) => {
    return;
  },
};
