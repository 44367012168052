import MomentUtils from "@date-io/moment";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";
import {
  KeyboardDateTimePicker,
  MaterialUiPickersDate,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Comment } from "UI/shared/atoms/inputs/Comment";
import moment from "moment";
import React, { useEffect, useReducer, useState } from "react";
import {
  ContactEventSent,
  EventKind,
} from "services/contactEventsTracking/dto/ContactEvent";

import { SelectEventKind } from "../../molecules/SelectEventKind";
import { reducer } from "./stateHandler";

interface EventFormProps {
  saveEvent: (event: ContactEventSent) => void;
  onCancel: () => void;
  initialValues: ContactEventSent;
  displayCancel?: boolean;
}

export const EventForm: React.FC<EventFormProps> = ({
  saveEvent,
  onCancel,
  initialValues,
  displayCancel,
}) => {
  const [isFormValid, setIsFormValid] = useState(true);
  const [state, dispatch] = useReducer(reducer, initialValues);

  const mobile = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    dispatch({ payload: initialValues, type: "setEvent" });
  }, [initialValues]);

  const onEventKindChange = (type: EventKind) => {
    dispatch({
      payload: type,
      type: "setKind",
    });
  };

  const onDateChange = (date: MaterialUiPickersDate) => {
    if (date === null || (date && !date.isValid()))
      return setIsFormValid(false);
    setIsFormValid(true);
    dispatch({
      payload: moment(date).toDate(),
      type: "setDate",
    });
  };

  const onIsOutlookSentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      payload: e.target.checked,
      type: "setHaveToBeAddedToCalendar",
    });
  };

  const onSaveClick = () => {
    const event: ContactEventSent = {
      calendarEventId: state.calendarEventId,
      comment: state.comment,
      date: state.date,
      haveToBeAddedToCalendar: state.haveToBeAddedToCalendar,
      id: state.id,
      kind: state.kind,
    };
    saveEvent(event);
    onCancel();
  };

  const setComment = (comment: string) => {
    dispatch({
      payload: comment,
      type: "setComment",
    });
  };

  return (
    <Grid container direction={"column"} spacing={2}>
      <Grid item xs={12} style={{ alignItems: "center", display: "flex" }}>
        <SelectEventKind
          value={state.kind}
          onChange={onEventKindChange}
          groupDisabled={state.id !== ""}
        />
      </Grid>
      <Grid item xs={12}>
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          locale={"fr"}
          libInstance={moment}
        >
          <KeyboardDateTimePicker
            ampm={false}
            value={state.date}
            onChange={onDateChange}
            format="DD/MM/YYYY HH:mm"
            InputAdornmentProps={{ position: "start" }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12}>
        <Comment commentaire={state.comment} setComment={setComment} />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color={"primary"}
              name="addEventToOffice365"
              value={state.haveToBeAddedToCalendar}
              checked={state.haveToBeAddedToCalendar}
              onChange={onIsOutlookSentChange}
            />
          }
          label="Ajouter cet évènement sur mon agenda Outlook (conseillé pour les relances et rendez-vous)"
        />
      </Grid>
      <Grid item xs={12}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {(displayCancel || mobile) && (
            <Button
              color="primary"
              variant="outlined"
              style={{ marginRight: "8px" }}
              onClick={onCancel}
            >
              {"Annuler"}
            </Button>
          )}
          <Button
            disabled={!isFormValid}
            color="primary"
            variant="contained"
            onClick={onSaveClick}
          >
            <Save />
            {"Enregistrer"}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};
