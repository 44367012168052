import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import {
  RelationEvent,
  RelationEventDto,
} from "routes/app/biens/pige/FicheBienPige/AdActions/types";
import { useServices } from "services";
import { useTxHttp } from "services/http";

export const PigeEventErrors = {
  OFFICE365_ERROR: "Office365Error",
  TREMPLINX_ERROR: "TremplinXError",
};

type PrisesContactResult = RelationEvent[];

const sortDateArray = (a: RelationEvent, b: RelationEvent) =>
  moment(b.date).valueOf() - moment(a.date).valueOf();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePigeEvents = (annonceId: string, fromDetails?: boolean) => {
  const { get, put, post, del } = useTxHttp();
  const { endpoints } = useServices();

  const add = useCallback(
    async (
      annonceId: string,
      relationEvent: Omit<
        RelationEventDto,
        "id" | "calendrierExterneEvenementId"
      >
    ) => {
      try {
        await post<void>(endpoints.pige.suivi(annonceId), {
          content: relationEvent,
          contentType: "application/json",
        });
        getEvents();
      } catch (e: any) {
        if (e.response?.data?.errorType === PigeEventErrors.OFFICE365_ERROR) {
          throw new Error(PigeEventErrors.OFFICE365_ERROR);
        }
        throw new Error(PigeEventErrors.TREMPLINX_ERROR);
      }
    },
    [endpoints.pige, post]
  );
  const deleteAd = useCallback(
    async (annonceId: string, relationEvent: RelationEventDto) => {
      try {
        await del<void>(endpoints.pige.suivi(annonceId), {
          content: relationEvent,
          contentType: "application/json",
        });
        getEvents();
      } catch (e: any) {
        if (e.response?.data?.errorType === PigeEventErrors.OFFICE365_ERROR) {
          throw new Error(PigeEventErrors.OFFICE365_ERROR);
        }
        throw new Error(PigeEventErrors.TREMPLINX_ERROR);
      }
    },
    [del, endpoints.pige]
  );
  const getAll = useCallback(
    (annonceId: string) =>
      get<PrisesContactResult>(endpoints.pige.suivi(annonceId)).then(
        (response) => {
          return response.data;
        }
      ),
    [endpoints.pige, get]
  );

  const update = useCallback(
    async (annonceId: string, relationEvent: RelationEventDto) => {
      try {
        await put<void>(endpoints.pige.suivi(annonceId), {
          content: relationEvent,
          contentType: "application/json",
        });
        getEvents();
      } catch (e: any) {
        if (e.response?.data?.errorType === PigeEventErrors.OFFICE365_ERROR) {
          throw new Error(PigeEventErrors.OFFICE365_ERROR);
        }
        throw new Error(PigeEventErrors.TREMPLINX_ERROR);
      }
    },
    [endpoints.pige, put]
  );

  const [events, setEvents] = useState<PrisesContactResult>([]);
  const [eventsCount, setEventsCount] = useState(0);

  const getEvents = useCallback(async () => {
    const newEvents = await getAll(annonceId);
    setEventsCount(newEvents.length);
    setEvents(newEvents.sort(sortDateArray));
  }, [annonceId, getAll]);

  useEffect(() => {
    if (fromDetails) {
      getEvents();
    }
  }, [annonceId, getEvents]);

  return {
    add,
    deleteAd,
    events,
    eventsCount,
    update,
  };
};
