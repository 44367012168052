import ResultatParAnneeBO from "services/reporting/dto/Reporting/Synthese/sub/ResultatParAnneeBO";

class ResultatParAnneeAdapter {
  static toBO(array = []) {
    return array.map((item) => this.itemToBO(item));
  }

  static itemToBO(item = {}) {
    const bo = new ResultatParAnneeBO();
    bo.annee = item.annee || bo.annee;
    bo.nbMandat = item.nbMandat || bo.nbMandat;
    bo.ca = item.ca || bo.ca;
    bo.pourcentageExclu = item.pourcentageExclu || bo.pourcentageExclu;

    return bo;
  }
}

export default ResultatParAnneeAdapter;
