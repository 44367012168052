import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { ITheme } from "theme/ts/main";

const useStyles = makeStyles((theme: ITheme) => {
  return {
    titleSection: {
      borderBottom: "1px solid",
      borderColor: `${theme.palette.common.main} !important`,
      marginBottom: 20,
      paddingBottom: 5,
      paddingTop: 15,
      width: "100%",
    },
  };
});

export const SectionTitle: React.FC<{ title: string }> = ({ title }) => {
  const classes = useStyles();
  return (
    <Typography variant="h4" className={classes.titleSection}>
      {title.toUpperCase()}
    </Typography>
  );
};
