import { Grid, TextField } from "@material-ui/core";
import SurfaceIcon from "@material-ui/icons/CropFreeOutlined";
import { useTheme } from "@material-ui/styles";
import { NumberField } from "components/RefinementField/NumberField";
import TitreBloc from "layoutContents/components/ContextualSideBar/components/titreBloc";
import React, { useReducer } from "react";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";

let timeout;

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "surfaceHabitableMin":
      return { ...state, surfaceHabitableMin: payload };
    case "surfaceHabitableMax":
      return { ...state, surfaceHabitableMax: payload };
    case "surfaceTerrainMin":
      return { ...state, surfaceTerrainMin: payload };
    case "surfaceTerrainMax":
      return { ...state, surfaceTerrainMax: payload };
    case "initialize":
      return payload;
    default:
      return { ...state };
  }
};

const Surfaces = ({
  classes,
  onUpdateRefinements,
  surfaceHabitableMin,
  surfaceHabitableMax,
  surfaceTerrainMin,
  surfaceTerrainMax,
  t,
}) => {
  const theme = useTheme();
  const inputPropsStyle = { style: { textAlign: "left" } };
  const initialState = {
    surfaceHabitableMax: surfaceHabitableMax.value,
    surfaceHabitableMin: surfaceHabitableMin.value,
    surfaceTerrainMax: surfaceTerrainMax.value,
    surfaceTerrainMin: surfaceTerrainMin.value,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const onChangeDelayed = (query, value) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      onUpdateRefinements(query, value);
    }, 600);
    dispatch({ payload: value, type: query });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TitreBloc
          label={t("Surfaces")}
          icon={<SurfaceIcon style={{ color: theme.palette.tonique }} />}
        />
      </Grid>
      <PaddingContainer bottom={10}>
        <Grid container spacing={1}>
          <Grid className={classes.gridFilter} item xs={6}>
            <NumberField
              value={state.surfaceHabitableMin}
              onChange={(surface) =>
                onChangeDelayed(surfaceHabitableMin.query, surface)
              }
              variant={"standard"}
              label={t("SurfaceHabitableMin")}
            />
          </Grid>
          <Grid className={classes.gridFilter} item xs={6}>
            <NumberField
              value={state.surfaceHabitableMax}
              onChange={(surface) =>
                onChangeDelayed(surfaceHabitableMax.query, surface)
              }
              variant={"standard"}
              label={t("SurfaceHabitableMax")}
            />
          </Grid>
        </Grid>
      </PaddingContainer>
      <PaddingContainer bottom={10}>
        <Grid container spacing={1}>
          <Grid className={classes.gridFilter} item xs={6}>
            <NumberField
              value={state.surfaceTerrainMin}
              onChange={(surface) =>
                onChangeDelayed(surfaceTerrainMin.query, surface)
              }
              variant={"standard"}
              label={t("SurfaceTerrainMin")}
            />
          </Grid>
          <Grid className={classes.gridFilter} item xs={6}>
            <NumberField
              value={state.surfaceTerrainMax}
              onChange={(surface) =>
                onChangeDelayed(surfaceTerrainMax.query, surface)
              }
              variant={"standard"}
              label={t("SurfaceTerrainMax")}
            />
          </Grid>
        </Grid>
      </PaddingContainer>
    </Grid>
  );
};

export default Surfaces;
