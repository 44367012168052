import { Chip } from "@material-ui/core";
import React from "react";

import { AutoCompleteText } from "../../../../../components/RefinementField/types";
import { RefinementChipProps } from "../RefinementChip";

export const AutoCompleteTextChip = ({
  value,
  format,
  onChange,
}: RefinementChipProps<AutoCompleteText>) => (
  <>
    {(value.value || "").trim().length > 0 && (
      <Chip
        style={{ marginBottom: 6, marginRight: 6 }}
        label={format(value.value)}
        size="small"
        onDelete={() =>
          onChange({
            getOptions: value.getOptions,
            kind: "autocomplete-text",
            value: "",
          })
        }
      />
    )}
  </>
);
