import { makeStyles, Radio } from "@material-ui/core";
import comingImg from "assets/icons/coming.svg";
import React, { ReactElement } from "react";

export interface OptionProfile {
  label: string;
  active: boolean;
}

export class ComingIcon extends React.Component {
  render() {
    return <img alt="coming" src={comingImg} width={"50px"} height={"auto"} />;
  }
}

export interface RadioItem extends OptionProfile {
  icon: ReactElement;
  checkedIcon: ReactElement;
}

interface CustomProfilRadioProps {
  value?: RadioItem;
  color: "default" | "primary" | "secondary" | undefined;
}

const useStyles = makeStyles((theme) => ({
  checkedIcon: {
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 28,
    color: "white",
    display: "flex",
    height: 56,
    justifyContent: "center",
    width: 56,
  },
  icon: {
    alignItems: "center",
    borderRadius: 28,
    borderStyle: "dashed",
    borderWidth: "1px",
    display: "flex",
    height: 56,
    justifyContent: "center",
    width: 56,
  },
}));

const CustomProfilRadio = ({ color, value }: CustomProfilRadioProps) => {
  const classes = useStyles();
  const comingIcon = <ComingIcon />;

  const StyledIcon = (
    <div className={classes.icon}>
      {value?.active ? value?.icon : comingIcon}
    </div>
  );
  const StyledCheckedIcon = (
    <div className={classes.checkedIcon}>{value?.checkedIcon}</div>
  );

  return (
    <Radio
      value={value?.label}
      color={color}
      icon={StyledIcon}
      checkedIcon={StyledCheckedIcon}
      disabled={!value?.active}
    />
  );
};

export default CustomProfilRadio;
