import { useEffect, useState } from "react";
import obtenirParIdAdapter from "services/conseillers/dto/Conseillers/ObtenirParId/obtenirParIdAdapter";

import { useServices } from "../../../../../../services";
import { useTxHttp } from "../../../../../../services/http";

export default function useConseillerObtenirParId({ id }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [item, setItem] = useState({});

  const { get } = useTxHttp();
  const { endpoints } = useServices();

  useEffect(() => {
    setLoading(true);
    setError(false);
    get(endpoints.conseillers.obtenirParId, { id })
      .then((res) => {
        if (res.data) {
          // console.log('retour api : ', res);
          const receivedItem = obtenirParIdAdapter(res.data);
          setItem(receivedItem);
        }
      })
      .catch((e) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line
  }, []);

  return { error, item, loading };
}
