import { Chip, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PlaceIcon from "@material-ui/icons/PlaceOutlined";
import React from "react";
import { renderToString } from "react-dom/server";
import Loading from "react-loading-skeleton";
import { getUrlPhoto } from "utils/UrlImage";

export const regex = /(\d)(?=(\d{3})+(?!\d))/g;

export function getContent({ bien, translate }) {
  if (!bien) return "";
  return renderToString(<Bubble bien={bien} t={translate} />);
}

const Bubble = (props) => {
  const { bien, t } = props;
  return (
    <Grid
      container
      spacing={0}
      direction="row"
      style={{ cursor: "pointer", maxHeight: "135px", minWidth: "300px" }}
    >
      <Grid item xs={4}>
        <div
          style={{
            backgroundImage: `url(${getUrlPhoto(
              bien.bu,
              "300x300",
              bien.photoPrincipale
            )}), url("/static/images/no-image-box.png")`,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            borderBottomLeftRadius: "10px",
            borderTopLeftRadius: "10px",
            height: 135,
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            style={{
              left: 7,
              position: "absolute",
              top: 2,
              width: 150,
            }}
          >
            {bien.neuf === true && (
              <Grid item xs={12}>
                <BubbleNeuf label={t("Neuf")} />
              </Grid>
            )}
            {bien.prestige === true && (
              <Grid item xs={12}>
                <BubblePrestige label={t("Prestige")} />
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
      <Grid item xs={8}>
        <div style={{ padding: "10px" }}>
          <Typography
            variant="body2"
            style={{ color: "#8E8E8E", fontSize: 12 }}
          >
            {/* {`${bien.typeAffaireEnum.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}   ${t1(bien.typeBienNom || bien.familleBienEnum)}`} */}
            {`${t(bien.typeAffaireEnum)} ${t(
              bien.typeBienNom || bien.familleBienEnum
            )}`}
          </Typography>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              style={{
                display: "inlineFlex",
                flexWrap: "nowrap",
                marginLeft: "-3px",
                verticalAlign: "middle",
              }}
            >
              <PlaceIcon
                style={{
                  color: "#8E8E8E",
                  fontSize: 16,
                  marginTop: 3,
                }}
              />
              <Typography
                variant="h4"
                noWrap
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  lineHeight: 1.43,
                }}
              >
                {bien.villeNom.toUpperCase()}{" "}
                <span
                  style={{
                    fontSize: 10,
                    fontWeight: "regular",
                  }}
                >{` (${bien.villeCodePostal})`}</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  align="left"
                  noWrap
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    lineHeight: "1.43",
                  }}
                >{`${bien.prix
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} €`}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="flex-end">
              <Grid item xs={12}>
                <Grid container>
                  {bien.surface > 0 && (
                    <>
                      <Typography
                        variant="h2"
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          lineHeight: "1.43",
                        }}
                      >
                        {bien.surface}
                      </Typography>
                      <Typography
                        variant="h3"
                        style={{
                          fontSize: "16px",
                          lineHeight: "1.43",
                        }}
                      >
                        &nbsp;{`m\xB2`}&nbsp;
                        {(bien.nbPieces > 0 || bien.nbChambres > 0) && "|"}
                        &nbsp;
                      </Typography>
                    </>
                  )}
                  {bien.nbPieces > 0 && (
                    <>
                      <Typography
                        variant="h2"
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          lineHeight: "1.43",
                        }}
                      >
                        {bien.nbPieces}
                      </Typography>
                      <Typography
                        variant="h3"
                        style={{
                          fontSize: "16px",
                          lineHeight: "1.43",
                        }}
                      >
                        &nbsp;p&nbsp;
                        {bien.nbChambres > 0 && "|"}
                        &nbsp;
                      </Typography>
                    </>
                  )}
                  {bien.nbChambres > 0 && (
                    <>
                      <Typography
                        variant="h2"
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          lineHeight: "1.43",
                        }}
                      >
                        {bien.nbChambres}
                      </Typography>
                      <Typography
                        variant="h3"
                        style={{
                          fontSize: "16px",
                          lineHeight: "1.43",
                        }}
                      >
                        &nbsp;ch
                      </Typography>
                    </>
                  )}
                  {bien.surface === 0 &&
                    bien.nbPieces === 0 &&
                    bien.nbChambres === 0 && (
                      <>
                        <Typography
                          variant="h2"
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            lineHeight: "1.43",
                          }}
                        >
                          &nbsp;
                        </Typography>
                        <Typography
                          variant="h3"
                          style={{
                            fontSize: "16px",
                            lineHeight: "1.43",
                          }}
                        >
                          &nbsp;
                        </Typography>
                      </>
                    )}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                justify="flex-start"
                style={{ paddingTop: 5 }}
              >
                {bien.exclusivite === true ||
                bien.optionMandatEnum === "Exclusif" ? (
                  <BubbleExclusivite label={t("Exclusivité")} />
                ) : (
                  <div style={{ height: "24px" }} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export function getContentLoading(bien = {}) {
  return renderToString(
    <div style={{ display: "flex", width: "300px" }}>
      <Loading width={100} height={80} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0 10px 0 10px",
          width: "100%",
        }}
      >
        <Loading width="100%" />
        <Loading width="100%" />
        <h3 style={{ textAlign: "right" }}>
          {bien.prix.toString().replace(regex, "$1 ")} €
        </h3>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  chipLabelExclusif: {
    color: "#FF7B1E",
    fontSize: "0.9rem",
    fontWeight: "bold",
  },
  chipLabelNeuf: {
    color: "#E82E75",
    fontSize: "0.9rem",
    fontWeight: "bold",
  },
  chipLabelPrestige: {
    color: "#57556F",
    fontSize: "0.9rem",
    fontWeight: "bold",
  },
  exclusif: {
    backgroundColor: "#FFD2B3",
    border: "solid 1px #FFD2B3",
    marginTop: 2,
  },
  neuf: {
    backgroundColor: "#FFFFFF",
    border: "solid 1px #E82E75",
    marginTop: 2,
    width: 60,
  },
  prestige: {
    backgroundColor: "#FFFFFF",
    border: "solid 1px #57556F",
    marginTop: 2,
    width: 80,
  },
}));

const BubbleExclusivite = (props) => {
  const classes = useStyles();
  return (
    <Chip
      label={props.label}
      size="small"
      className={classes.exclusif}
      classes={{ label: classes.chipLabelExclusif }}
    />
  );
};

const BubbleNeuf = (props) => {
  const classes = useStyles();
  return (
    <Chip
      label={props.label}
      size="small"
      className={classes.neuf}
      classes={{ label: classes.chipLabelNeuf }}
    />
  );
};

const BubblePrestige = (props) => {
  const classes = useStyles();
  return (
    <Chip
      label={props.label}
      size="small"
      className={classes.prestige}
      classes={{ label: classes.chipLabelPrestige }}
    />
  );
};
