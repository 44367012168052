import { Grid, makeStyles, Typography } from "@material-ui/core";
import { GridContainer } from "components/containers/GridContainer";
import React, { FC } from "react";
import { ReadContactDto } from "services/portfolio/types";

const useStyles = makeStyles((_) => ({
  leftSubContainer: {
    display: "flex",
    flexBasis: "40%",
    justifyContent: "flex-end",
    marginRight: "10px",
  },
  mainContainer: {
    display: "flex",
  },
  rightSubContainer: {
    display: "flex",
    flexBasis: "60%",
  },
}));

export const OtherInformations: FC<{ contact: ReadContactDto }> = ({
  contact,
}) => {
  const infos: { title: string; data: string | undefined }[] = [
    {
      data: contact.identity.birthDate
        ? new Date(contact.identity.birthDate).toLocaleDateString()
        : "",
      title: "Date de naissance",
    },
    {
      data: contact.identity.birthPlace?.name,
      title: "Lieu de naissance",
    },
    {
      data: contact.identity.professionalInfos?.professionalCategory,
      title: "Catégorie socio-professionnelle",
    },
    {
      data: contact.identity.professionalInfos?.profession,
      title: "Profession",
    },
    {
      data: contact.identity.professionalInfos?.company,
      title: "Société",
    },
    {
      data: contact.identity.professionalInfos?.companyRole,
      title: "Société-Rôle",
    },
  ];

  const classes = useStyles();

  return (
    <GridContainer>
      {infos.map(({ title, data }) => {
        if (!data) return null;
        return (
          <Grid key={title} item xs={12} className={classes.mainContainer}>
            <div className={classes.leftSubContainer}>
              <div>
                <Typography variant={"body1"} style={{ textAlign: "end" }}>
                  {title}
                </Typography>
              </div>
            </div>
            <div className={classes.rightSubContainer}>
              <Typography variant={"h5"}>{data}</Typography>
            </div>
          </Grid>
        );
      })}
    </GridContainer>
  );
};
