import { bool, number, string } from "api/DTOs/Common/Types";

function obtenirParIdCarteAdapter(result) {
  if (!result) return undefined;

  return {
    bu: result.bu || string,
    exclusivite: result.exclusivite || bool,
    familleBienEnum: result.familleBienEnum || string,
    id: result.id || string,
    nbChambres: result.nbChambres || number,
    nbPieces: result.nbPieces || number,
    neuf: result.neuf || bool,
    photoPrincipale: result.photoPrincipale || string,
    prestige: result.prestige || bool,
    prix: result.prix || number,
    surface: result.surface || number,
    typeAffaireEnum: result.typeAffaireEnum || string,
    villeCodePostal: result.villeCodePostal || string,
    villeNom: result.villeNom || string,
  };
}

export default obtenirParIdCarteAdapter;
