import { Grid } from "@material-ui/core";
import { BlocContainer } from "UI/shared/atoms/containers/BlocContainer";
import React, { FC } from "react";
import { ProfileReadDto } from "routes/app/contacts/contact/components/models/profilesReadDto";
import { SearchLifeAnnuityApartmentCriteriaDto } from "routes/app/contacts/contact/components/models/Search/searchLifeAnnuityApartmentCriteriaDto";
import { SearchLifeAnnuityApartmentProfileReadDto } from "routes/app/contacts/contact/components/models/Search/searchLifeAnnuityApartmentProfileReadDto";
import { ProfilePlaces } from "UI/features/profiles/organisms/ProfilePlaces";
import { ProfileProject } from "UI/features/profiles/organisms/ProfileProject";
import { ProfileStatusCard } from "UI/features/profiles/organisms/ProfileStatusCard";
import { useServices } from "services";
import { L10nService } from "services/l10n";

import { ConstructionYears } from "../../../../../organisms/Search/ConstructionYears";
import { LifeAnnuityBudget } from "../../../../../organisms/Search/LifeAnnuityBudget";
import { MoreInfos } from "../../../../../organisms/Search/MoreInfos";
import { Vacancy } from "../../../../../organisms/Search/Vacancy";

export interface SearchLifeAnnuityApartmentProfileDetailsProps {
  readProfile: ProfileReadDto;
}
export const SearchLifeAnnuityApartmentProfileDetails: FC<SearchLifeAnnuityApartmentProfileDetailsProps> = ({
  readProfile,
}) => {
  const { l10n } = useServices();
  const profile = readProfile as SearchLifeAnnuityApartmentProfileReadDto;
  const moreInformations = more(l10n, profile.criteria);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={4}>
        <ProfileStatusCard
          status={profile.archivingDate ? "Archived" : "Active"}
          creationDate={profile.creationDate || profile.lastModificationDate}
          updateDate={profile.archivingDate || profile.lastModificationDate}
          maturity={profile.criteria.maturity}
          origin={profile.origin}
          reference={profile.reference}
        />
        <ProfileProject
          profileAccount={profile.account}
          businessType={"Viager"}
          propertyCategory={"Apartment"}
        />
        <BlocContainer paddingTop={20}>
          <Vacancy value={profile.criteria.vacancy} />
          <ConstructionYears values={profile.criteria.constructionYears} />
        </BlocContainer>
        <LifeAnnuityBudget budget={profile.criteria.budget} />
      </Grid>
      <Grid item xs={12} lg={8}>
        {profile.criteria.places && (
          <ProfilePlaces places={profile.criteria.places} />
        )}
        <MoreInfos
          title={"Informations supplémentaires"}
          value={moreInformations}
        />
      </Grid>
    </Grid>
  );
};

function more(
  { t, formatSurfacesRange, formatRoomsRange }: L10nService,
  criteria: SearchLifeAnnuityApartmentCriteriaDto
) {
  return [
    {
      label: t("Surface habitable"),
      value: formatSurfacesRange(criteria.homeSize.min, criteria.homeSize.max),
    },
    {
      label: t("Surface terrain"),
      value: formatSurfacesRange(criteria.lotSize.min, criteria.lotSize.max),
    },
    {
      label: t("Pièces"),
      value: formatRoomsRange(criteria.minRooms),
    },
    {
      label: t("Chambres"),
      value: formatRoomsRange(criteria.minBedrooms),
    },
    {
      label: t("Autre"),
      value: [
        criteria.withElevator ? "Ascenseur obligatoire" : "",
        criteria.withParking ? "Stationnement obligatoire" : "",
      ]
        .filter((value) => value !== "")
        .map((option) => option)
        .join(", "),
    },
  ];
}
