import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/TuneOutlined";
import { NumberField } from "components/RefinementField/NumberField";
import SearchbarX from "components/SearchbarX";
import SelectX from "components/SelectX";
import Localisation from "layoutContents/components/ContextualSideBar/components/localisation";
import React, { useEffect, useReducer } from "react";
import { breakpoint } from "theme/main";

import { onlySpaces } from "../../../../utils/onlySpaces";

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: "16px",
    height: "45px",
    margin: "3px 3px 3px 3px",
    minWidth: "45px",
    padding: 0,
  },
  chambre: {
    margin: "3px 3px 3px 3px",
    maxWidth: "150px",
    minWidth: "120px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 3px 10px 3px",
      maxWidth: "100%",
    },
  },
  locationSelectRoot: {
    margin: 0,
  },
  prixContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  prixRange: {
    margin: "3px 3px 3px 3px",
    maxWidth: "100px",
    minWidth: "90px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 3px 10px 3px",
      maxWidth: "100%",
    },
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  search: {
    margin: "3px 3px 3px 3px",
    maxWidth: "400px",
    minWidth: "200px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 3px 10px 3px",
      maxWidth: "100%",
    },
  },
}));

let timeout;

const reducer = (state, action) => {
  switch (action.query) {
    case "fullText":
      return { ...state, search: action.value };
    case "prixMin":
      return { ...state, prixMin: action.value };
    case "prixMax":
      return { ...state, prixMax: action.value };
    case "surfaceHabitableMin":
      return { ...state, surfaceHabitableMin: action.value };
    case "surfaceHabitableMax":
      return { ...state, surfaceHabitableMax: action.value };
    case "surfaceTerrainMin":
      return { ...state, surfaceTerrainMin: action.value };
    case "surfaceTerrainMax":
      return { ...state, surfaceTerrainMax: action.value };
    case "initialize":
      return action.value;
    default:
      return { ...state };
  }
};

function Searchbar({
  refinements,
  onChange,
  t,
  onClickMore,
  displayMode,
  onUpdateLocalisations,
}) {
  const {
    nbChambresRange,
    nbPiecesRange,
    fullText,
    prixMin,
    prixMax,
    familleBiens,
  } = refinements;
  const classes = useStyles();
  const initialState = {
    prixMax: prixMax.value,
    prixMin: prixMin.value,
    search: fullText.value,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const onChangeDelayed = (query, value) => {
    if (onlySpaces(value)) {
      return;
    }
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      onChange(query, value);
    }, 600);
    dispatch({ query, value });
  };

  const onChangePriceDelayed = (query, value) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      onChange(query, value);
    }, 600);
    dispatch({ query, value });
  };

  useEffect(() => {
    dispatch({
      query: "initialize",
      value: {
        prixMax: prixMax.value,
        prixMin: prixMin.value,
        search: fullText.value,
      },
    });
  }, [fullText.value, prixMax.value, prixMin.value]);

  return (
    <SearchbarX>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={10} sm={11} md={displayMode !== "map" ? 2 : 11}>
          <TextField
            fullWidth
            value={state.search}
            onChange={(evt) =>
              onChangeDelayed(fullText.query, evt.target.value)
            }
            variant={"outlined"}
            label={t("Rechercher")}
            type={"search"}
          />
        </Grid>

        {displayMode !== "map" && !breakpoint().smAndDown ? (
          <Grid item md={9}>
            <Grid container spacing={1} alignItems="center">
              <Grid item md={6}>
                <Localisation
                  selectRootClassName={classes.locationSelectRoot}
                  withLabel={false}
                  refinementsObject={refinements}
                  t={t}
                  gridFilter={classes.location}
                  onUpdateLocalisations={onUpdateLocalisations}
                  showSeparator={false}
                  shouldGatherSelection
                />
              </Grid>

              <Grid item md={2}>
                <SelectX
                  refinement={familleBiens}
                  label={t("FamilleBiens")}
                  onChange={onChange}
                  multiple
                />
              </Grid>

              {nbPiecesRange && (
                <Grid item md={2}>
                  <SelectX
                    refinement={nbPiecesRange}
                    label={t("Pieces")}
                    onChange={onChange}
                    multiple
                  />
                </Grid>
              )}
              {nbChambresRange && (
                <Grid item md={2}>
                  <SelectX
                    refinement={nbChambresRange}
                    label={t("Chambres")}
                    onChange={onChange}
                    multiple
                  />
                </Grid>
              )}
              <Grid item md={2}>
                <NumberField
                  label={t("PrixMax")}
                  onChange={(price) =>
                    onChangePriceDelayed(prixMax.query, price)
                  }
                  value={state.prixMax}
                  variant={"outlined"}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        <Grid item xs={2} sm={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              variant={"contained"}
              color={"primary"}
              size={"small"}
              onClick={onClickMore}
              className={classes.button}
            >
              <AddIcon />
            </Button>
          </div>
        </Grid>
      </Grid>
    </SearchbarX>
  );
}

export default Searchbar;
