import { Grid } from "@material-ui/core";
import React, { FC, useState } from "react";

import { useServices } from "../../../../../../../../../services";
import { SubSectionTitle } from "../../../components/SectionTitle";
import { ValidationNumberField } from "../../../components/ValidationNumberField";
import { ProfileInputProps } from "../../../InputProps";

interface BuildingAnnexesProps {
  basementsNumber?: number;
  serviceRoomsNumber?: number;
}

interface BuildingAnnexesValidation {
  basementsNumber: boolean;
  serviceRoomsNumber: boolean;
}

const getGlobalValidation = (globalValidation: BuildingAnnexesValidation) =>
  globalValidation.basementsNumber && globalValidation.serviceRoomsNumber;

export const BuildingAnnexes: FC<ProfileInputProps<BuildingAnnexesProps>> = ({
  value,
  onChange,
  pristine,
  disabled = false,
}) => {
  const {
    l10n: { t },
  } = useServices();
  const [globalValidation, setGlobalValidation] = useState({
    basementsNumber: true,
    serviceRoomsNumber: true,
  });
  return (
    <>
      <SubSectionTitle title="Annexes" />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ValidationNumberField
            disabled={disabled}
            pristine={pristine}
            label={t("Nb caves")}
            onChange={(isValid, newValue) => {
              const _globalValidation = {
                ...globalValidation,
                basementsNumber: isValid,
              };
              setGlobalValidation(_globalValidation);
              onChange(
                { ...value, basementsNumber: newValue || 0 },
                getGlobalValidation(_globalValidation)
              );
            }}
            value={value.basementsNumber || 0}
            maxLimit={100}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ValidationNumberField
            disabled={disabled}
            pristine={pristine}
            label={t("Nb chambres de service")}
            onChange={(isValid, newValue) => {
              const _globalValidation = {
                ...globalValidation,
                basementsNumber: isValid,
              };
              setGlobalValidation(_globalValidation);
              onChange(
                { ...value, serviceRoomsNumber: newValue },
                getGlobalValidation(_globalValidation)
              );
            }}
            value={value.serviceRoomsNumber || 0}
            maxLimit={100}
          />
        </Grid>
      </Grid>
    </>
  );
};
