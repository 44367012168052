import {
  ArchiveOutlined,
  EditOutlined,
  Notes,
  UnarchiveOutlined,
} from "@material-ui/icons";
import { ConfirmationDialog } from "UI/shared/organisms/ConfirmationDialog";
import React, { useCallback, useMemo, useState } from "react";
import routesNames from "router/routesNames";
import { usePortfolioSearch } from "services/portfolio/usePortfolioSearch";
import { useProfiles } from "services/profiles";
import { ProfileItemDto } from "services/profiles/dto/ReadModelProfileDto";
import { useReadModelProfiles } from "services/profiles/readModelProfilesContext";
import { deepEquality } from "utils/objects/deepEquality";

import {
  ActionMenuItemProps,
  ActionsMenu,
} from "../../../../shared/organisms/ActionsMenu";
import { useServices } from "../../../../../services";
import { ProfileReadDto } from "../../../../../routes/app/contacts/contact/components/models/profilesReadDto";
import { Form } from "../../../../../routes/app/contacts/contact/components/Profiles/Form";
import { ForSaleForm } from "../../../../../routes/app/contacts/contact/components/Profiles/ForSaleForm";
import { getProfileType } from "../../../../../routes/app/profiles/adapters/utils";
import { ProfileItemLargeCard } from "./ProfileItemLargeCard";
import { ProfileItemSmallCard } from "./ProfileItemSmallCard";

export interface ProfileItemProps {
  profileItem: ProfileItemDto;
  smallScreenMode?: boolean;
}

export const ProfileItem: React.FC<ProfileItemProps> = React.memo(
  ({ profileItem, smallScreenMode = false }) => {
    const [optimisticProfileItem, setOptimisticProfileItem] = useState(
      profileItem
    );

    const actionsMenu = useMemo(
      () => (
        <ProfileMenu
          optimisticProfileItem={optimisticProfileItem}
          setOptimisticProfileItem={setOptimisticProfileItem}
        />
      ),
      [optimisticProfileItem]
    );

    return smallScreenMode ? (
      <ProfileItemSmallCard
        profileItem={optimisticProfileItem}
        actionsMenu={actionsMenu}
      />
    ) : (
      <ProfileItemLargeCard
        profileItem={optimisticProfileItem}
        actionsMenu={actionsMenu}
      />
    );
  },
  (prev, next) => {
    return (
      deepEquality(prev.profileItem, next.profileItem) &&
      prev.smallScreenMode === next.smallScreenMode
    );
  }
);

const ProfileMenu: React.FC<{
  optimisticProfileItem: ProfileItemDto;
  setOptimisticProfileItem: (newOptimisticProfileItem: ProfileItemDto) => void;
}> = ({ optimisticProfileItem, setOptimisticProfileItem }) => {
  const {
    modal,
    l10n: { t },
  } = useServices();

  const optimisticUpdateProfile = (profile: ProfileReadDto | undefined) => {
    if (profile) {
      setOptimisticProfileItem({
        ...optimisticProfileItem,
        archivingDate: profile.archivingDate
          ? new Date(profile.archivingDate).toISOString()
          : null,
        lastModificationDate: new Date(
          profile.lastModificationDate
        ).toLocaleDateString(),
      });
    }
  };

  const { archive, reactivate } = useProfiles(optimisticProfileItem.contactId);
  const { getContact } = usePortfolioSearch();

  const { getById } = useReadModelProfiles();
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    getContact(optimisticProfileItem.contactId).then((_) => {
      if (_?.status === "Archived") setOpenDialog(true);
      else {
        reactivateProfile(
          optimisticProfileItem.id,
          optimisticProfileItem.contactId
        );
      }
    });
  };

  const handleConfirmation = () => {
    setOpenDialog(false);
    reactivateProfile(
      optimisticProfileItem.id,
      optimisticProfileItem.contactId
    );
  };

  const archiveProfile = useCallback(
    (profileId: string, contactId: string) =>
      archive(profileId, contactId).then(async (_) => {
        const getByIdResponse = await getById(profileId, contactId);
        const profile = getByIdResponse.data?.profile;
        if (!profile) return;

        optimisticUpdateProfile(profile);
      }),
    [archive, getById, optimisticUpdateProfile]
  );
  const reactivateProfile = useCallback(
    (profileId: string, contactId: string) =>
      reactivate(profileId, contactId).then(async (_) => {
        const getByIdResponse = await getById(profileId, contactId);

        const profile = getByIdResponse.data?.profile;

        if (!profile) return;

        optimisticUpdateProfile(profile);
      }),
    [getById, optimisticUpdateProfile, reactivate]
  );

  const menuOptions: ActionMenuItemProps[] = [];

  menuOptions.push({
    action: () => {
      window.open(
        routesNames.profilePageTab(
          optimisticProfileItem.contactId,
          optimisticProfileItem.id,
          "details"
        ),
        "_self"
      );
    },
    icon: <Notes />,
    label: "Consulter",
  });
  if (optimisticProfileItem.archivingDate) {
    menuOptions.push({
      action: () => handleClickOpen(),
      icon: <UnarchiveOutlined />,
      label: "Réactiver",
    });
  } else {
    menuOptions.push({
      action: async () => {
        const profileDetails = await getById(
          optimisticProfileItem.id,
          optimisticProfileItem.contactId
        );
        modal.show(
          getProfileType(optimisticProfileItem.kind) === "Seller" ? (
            <ForSaleForm
              contactId={optimisticProfileItem.contactId}
              profile={profileDetails.data?.profile}
              mode={"update"}
              sendUpdatedProfile={optimisticUpdateProfile}
            />
          ) : (
            <Form
              contactId={optimisticProfileItem.contactId}
              profile={profileDetails.data?.profile}
              mode="update"
              sendUpdatedProfile={optimisticUpdateProfile}
            />
          )
        );
      },
      icon: <EditOutlined />,
      label: "Modifier",
    });

    menuOptions.push({
      action: () =>
        archiveProfile(
          optimisticProfileItem.id,
          optimisticProfileItem.contactId
        ),
      icon: <ArchiveOutlined />,
      label: "Archiver",
    });
  }

  return (
    <>
      <ConfirmationDialog
        text={t("ReactivationProfil")}
        handleCancel={() => setOpenDialog(false)}
        handleConfirmation={handleConfirmation}
        openDialog={openDialog}
      />
      <ActionsMenu menuOptions={menuOptions} />
    </>
  );
};
