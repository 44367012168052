import { Grid, Tooltip, Typography } from "@material-ui/core";
import bloctelIconSvg from "assets/icons/bloctel_icon.svg";
import React from "react";

const BloctelIcon = () => (
  <Grid item xs={12}>
    <Tooltip title="Démarchage interdit" placement="top">
      <img
        alt="bloctelIcon"
        src={bloctelIconSvg}
        width={"40px"}
        height={"auto"}
      />
    </Tooltip>
    <Typography variant={"h6"}>Bloctel</Typography>
  </Grid>
);

interface BottomRightBloctelIconProps {
  right: number;
  bottom: number;
}

export const BottomRightBloctelIcon: React.FC<BottomRightBloctelIconProps> = ({
  right,
  bottom,
}) => (
  <div style={{ bottom, position: "absolute", right }}>
    <BloctelIcon />
  </div>
);

export default BloctelIcon;
