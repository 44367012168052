export const flattenGeolocRefinementsValues = ({ refinements }) => {
  const _refinements = refinements.toObject();
  return {
    departements: _refinements.departements.values
      .filter((elt) => elt.isRefined)
      .map((elt) => elt.value),
    quartiers: _refinements.quartiers.values
      .filter((elt) => elt.isRefined)
      .map((elt) => elt.value),
    regions: _refinements.regions.values
      .filter((elt) => elt.isRefined)
      .map((elt) => elt.value),
    villes: _refinements.villes.values
      .filter((elt) => elt.isRefined)
      .map((elt) => elt.value),
  };
};
