import { Grid } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { InfiniteScrollItem } from "UI/features/realEstates/organisms/InfiniteScrollItem";
import { RealEstateDto } from "services/profiles/dto/RealEstateDto";
import {
  ScrollToTopButton,
  useInfiniteScroll,
} from "services/useInfiniteScrollSR";
import { generateListKeys } from "utils/component-lists/generateKey";
import { SearchResultProps } from "../../props";

export const RealEstatesList: React.FC<SearchResultProps> = ({
  items,
  loadMore,
}) => {
  const {
    firstVisible,
    lastVisible,
    ViewObserverFirst,
    ViewObserverLast,
  } = useInfiniteScroll();

  const triggerItemRank = useMemo(
    () => Math.max(0, items.length - Math.round(20 / 3)),
    [items.length]
  );

  useEffect(() => {
    if (!lastVisible) return;
    loadMore();
  }, [lastVisible]);

  const generateUniqueKey = generateListKeys<RealEstateDto>({
    feature: "realestates",
  });

  return (
    <Grid container spacing={2}>
      {items.map((item, index) => (
        <Grid item xs={12} key={generateUniqueKey(item)}>
          <InfiniteScrollItem
            item={item}
            itemIndex={index}
            ViewObserverFirst={ViewObserverFirst}
            ViewObserverLast={ViewObserverLast}
            triggerIndex={triggerItemRank}
          />
        </Grid>
      ))}
      {!firstVisible && <ScrollToTopButton />}
    </Grid>
  );
};
