import { Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const GreenSwitch = withStyles({
  checked: {},
  switchBase: {
    "&$checked": {
      color: "#303d4a",
    },
    "&$checked + $track": {
      backgroundColor: "#303d4a",
    },
    color: "lightgrey",
  },
  track: {},
})(Switch);

export default GreenSwitch;
