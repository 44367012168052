import { EndpointsProvider } from "./interfaces/EndpointsProvider";
import getPublicationEndpoints, {
  PublicationEndpoints,
} from "./PublicationEndpoints";

export interface ReportingEndpoints {
  publication: PublicationEndpoints;
  acte: string;
  bien: string;

  referentiel: string;
  synthese: string;

  classement: string;

  mandat: string;
}

const endpoints: EndpointsProvider<ReportingEndpoints> = (microServices) => ({
  synthese: `${microServices.tx("reporting")}/v1/Synthese/Reporting`,
  referentiel: `${microServices.tx("reporting")}/v1/Referentiel/GetReferentiel`,
  publication: getPublicationEndpoints(microServices),
  acte: `${microServices.tx("reporting")}/v1/Acte/Reporting`,
  bien: `${microServices.tx("reporting")}/v1/Bien/Reporting`,
  classement: `${microServices.tx("reporting")}/v1/Classement/Reporting`,
  mandat: `${microServices.tx("reporting")}/v1/Mandat/Reporting`,
});

export default endpoints;
