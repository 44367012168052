import { Typography } from "@material-ui/core";
import { NumberField } from "components/NumberField";
import { isUndefined } from "lodash";
import React, { FC, useEffect } from "react";
import { useServices } from "services";
import { formatPrix } from "utils/formatage";

import { BudgetRentalDto } from "../../../models/budgetRentalDto";
import {
  isGreaterThanOrEquals,
  isMandatory,
  isStrictlyPositive,
} from "../../components/formValidators";
import { SectionTitle } from "../../components/SectionTitle";
import { ProfileInputProps } from "../../InputProps";

export const RentalBudget: FC<{ budget: BudgetRentalDto }> = ({ budget }) => (
  <>
    {budget.max! > 0 && (
      <Typography variant="h5">
        Loyer (CC) : {formatPrix(budget.max)}
      </Typography>
    )}
    {budget.min! > 0 && (
      <Typography variant="h5">
        Loyer min. (CC) : {formatPrix(budget.min)}
      </Typography>
    )}
    {budget.maxMatching! > 0 && (
      <Typography variant="body1">
        Loyer max (CC) : {formatPrix(budget.maxMatching)}
      </Typography>
    )}
  </>
);

function validate(t: (s: string) => string, value: BudgetRentalDto) {
  return {
    max: {
      isValid: isStrictlyPositive(value.max) === "",
      reason: isStrictlyPositive(value.max),
    },
    maxMatching: {
      isValid:
        isUndefined(value.maxMatching) ||
        value.maxMatching === 0 ||
        isGreaterThanOrEquals(value.max, value.maxMatching) === "",
      reason:
        isUndefined(value.maxMatching) || value.maxMatching === 0
          ? ""
          : isGreaterThanOrEquals(value.max, value.maxMatching),
    },
    min: {
      isValid:
        isUndefined(value.min) ||
        value.min === 0 ||
        isGreaterThanOrEquals(value.min, value.max) === "",
      reason:
        isUndefined(value.min) || value.min === 0
          ? ""
          : isGreaterThanOrEquals(value.min, value.max),
    },
  };
}

export const RentalBudgetInput: FC<ProfileInputProps<BudgetRentalDto>> = ({
  pristine,
  value,
  onChange,
}) => {
  const disabled = false;
  const { l10n } = useServices();

  const errors = validate(l10n.t, value);

  const isValid = errors.max.isValid && errors.maxMatching.isValid;

  useEffect(() => {
    onChange(value, isValid);
  }, [isValid]);

  return (
    <>
      <SectionTitle title="Quel est votre budget ?" />

      <NumberField
        disabled={disabled}
        helperText={pristine ? isMandatory(value.max) : errors.max.reason}
        error={pristine ? false : !errors.max.isValid}
        label="Loyer (CC)"
        onChange={(max) => {
          onChange(
            {
              ...value,
              max,
              maxMatching: max ? max * 1.2 : max,
              min: max ? max * 0.8 : max,
            },
            isValid
          );
        }}
        required={true}
        value={value.max}
        adornement={"€"}
      />
      <NumberField
        disabled={disabled}
        helperText={errors.min.reason}
        error={!errors.min.isValid}
        label="Loyer min. (CC)"
        onChange={(min) => onChange({ ...value, min }, isValid)}
        value={value.min}
        adornement={"€"}
      />
      <NumberField
        disabled={disabled}
        helperText={errors.maxMatching.reason}
        error={!errors.maxMatching.isValid}
        label="Loyer max. pour rapprochement (CC)"
        onChange={(maxMatching) => onChange({ ...value, maxMatching }, isValid)}
        value={value.maxMatching}
        adornement={"€"}
      />
    </>
  );
};
