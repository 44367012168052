import { Annonce } from "../../../../../../services/piges/types/Annonce";
import { formatPrix } from "../../../../../../utils/formatage";
import { Information } from "../Details/sectionsCategories/InformationLinesSection";

function getTitle(annonce: Annonce, t: (mot: string) => string): string {
  const titreSurface =
    annonce.bien.surfaces.habitable > 0
      ? ` ${annonce.bien.surfaces.habitable} m\xB2 `
      : "";
  const titreVille = ` ${t("à")} ${annonce.bien.ville}`;
  return `${annonce.infosFinancieres.typeAnnonce} ${t(
    annonce.bien.type
  )}${titreSurface}${titreVille}`;
}

const getComposition = (annonce: Annonce): Information[] =>
  [
    {
      key: "Nbr de pièces",
      value: annonce.bien.composition.nbPieces
        ? annonce.bien.composition.nbPieces.toString()
        : undefined,
    },
    {
      key: "Nbr de chambres",
      value: annonce.bien.composition.nbChambres
        ? annonce.bien.composition.nbChambres.toString()
        : undefined,
    },
    {
      key: "Nbr de salles de bains",
      value: annonce.bien.composition.nbSalleDeBain
        ? annonce.bien.composition.nbSalleDeBain.toString()
        : undefined,
    },
    {
      key: "Nbr de WC",
      value: annonce.bien.composition.nbWc
        ? annonce.bien.composition.nbWc.toString()
        : undefined,
    },
    {
      key: "Nbr de salles d'eau",
      value: annonce.bien.composition.nbSalleDEau
        ? annonce.bien.composition.nbSalleDEau.toString()
        : undefined,
    },
    {
      key: "Terrasse",
      value: annonce.bien.composition.nbTerrasse
        ? annonce.bien.composition.nbTerrasse.toString()
        : undefined,
    },
  ].filter((element) => element.value !== undefined);

const getSurfaces = (annonce: Annonce): Information[] =>
  [
    {
      key: "Surface habitable",
      value: annonce.bien.surfaces.habitable
        ? `${annonce.bien.surfaces.habitable.toString()} m2`
        : undefined,
    },
    {
      key: "Surface terrain",
      value: annonce.bien.surfaces.terrain
        ? `${annonce.bien.surfaces.terrain.toString()} m2`
        : undefined,
    },
  ].filter((element) => element.value !== undefined);

const getAnnexes = (annonce: Annonce): Information[] =>
  [
    {
      key: "Nbr caves",
      value: annonce.bien.composition.nbCaves
        ? annonce.bien.composition.nbCaves.toString()
        : undefined,
    },
    {
      key: "Nbr parkings",
      value: annonce.bien.composition.nbParkings
        ? annonce.bien.composition.nbParkings.toString()
        : undefined,
    },
  ].filter((element) => element.value !== undefined);

const getAspectsFinanciers = (annonce: Annonce): Information[] =>
  [
    {
      key: "Prix actuel",
      value: annonce.infosFinancieres.prixActuel
        ? formatPrix(annonce.infosFinancieres.prixActuel).toString()
        : undefined,
    },
    {
      key: "Prix au m2",
      value: annonce.infosFinancieres.prixM2
        ? formatPrix(Math.round(annonce.infosFinancieres.prixM2)).toString()
        : undefined,
    },
    {
      key: "Prix initial",
      value: annonce.infosFinancieres.prixInitial
        ? formatPrix(annonce.infosFinancieres.prixInitial).toString()
        : undefined,
    },
  ].filter((element) => element.value !== undefined);

export {
  getTitle,
  getComposition,
  getSurfaces,
  getAnnexes,
  getAspectsFinanciers,
};
