import { amber } from "@material-ui/core/colors";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";

const defaultDark = "#091722";

export const baseTheme = {
  overrides: {
    MuiButton: {
      containedPrimary: {
        "&:hover": {
          backgroundColor: defaultDark,
        },
      },
      outlinedPrimary: {
        border: "1px solid #303d4a",
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: "inherit",
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          color: "#303d4a",
        },
        padding: 9,
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 15px) scale(1)",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "13px",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow:
          "0px 3px 3px 0px rgba(0,0,0,0.1), 0px 1px 1px -1px rgba(0,0,0,0.1)",
      },
      elevation4: {
        boxShadow:
          "0px 3px 3px 0px rgba(0,0,0,0.05), 0px 1px 1px -1px rgba(0,0,0,0.05)",
      },
      root: {
        color: "#303d4a",
      },
      rounded: {
        borderRadius: 6,
      },
    },
  },
  palette: {
    beige: "#988886",
    beigeFonce: "#665B5A",
    buttonSelected: "#091722",
    capi: "#0093f7",
    common: {
      black: "#091722",
      dark: "#8e8e8e",
      light: "#f2f2f2",
      main: "#e2e2e2",
      mediumDark: "#191919",
      white: "#fff",
    },
    default: "#303d4a",
    emphase: "#f96854",
    graph: {
      color1: "#37C2CE",
      color2: "#283FB2",
      color3: "#FF9E25",
      color4: "#D84466",
      color5: "#F4C500",
      color6: "#5473E8",
    },
    greyLight: "#f2f2f2",
    greyMedium: "#c2c2c2",
    info: {
      dark: blue[700],
      light: blue[300],
      main: blue[500],
    },
    neutral: {
      dark: grey[700],
      light: grey[300],
      main: grey[500],
    },
    notSelected: {
      grey: "#bababa",
    },
    opti: "#ff7b1e",
    pinBackground: "#cceae5",
    primary: {
      main: "#303d4a",
    },
    redLight: red[100],
    refl: "#1c3765",
    success: {
      dark: green[700],
      light: green[300],
      lighter: green[100],
      main: green[500],
    },
    warning: {
      dark: amber[700],
      light: amber[300],
      main: amber[500],
    },
    white: "#fff",
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  spacing: 8,
  typography: {
    body1: {
      fontSize: "14px",
      lineHeight: "1.43",
    },
    body2: {
      fontSize: "12px",
      lineHeight: "1.43",
    },
    button: {
      fontSize: "16px",
    },
    caption: {
      fontSize: "16px",
    },
    fontFamily: [
      "Source Sans Pro",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: "1.43",
    },
    h2: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "1.43",
    },
    h3: {
      fontSize: "18px",
      lineHeight: "1.43",
    },
    h4: {
      fontSize: "16px",
      fontWeight: "bold",
      lineHeight: "1.43",
    },
    h5: {
      fontSize: "14px",
      fontWeight: "bold",
      lineHeight: "1.43",
    },
    h6: {
      fontSize: "12px",
      fontWeight: "bold",
      lineHeight: "1.43",
    },
    subtitle1: {
      fontSize: "16px",
      lineHeight: "1.43",
    },
    subtitle2: {
      fontSize: "16px",
      fontWeight: "bold",
      lineHeight: "1.43",
    },
    useNextVariants: true,
  },
};

const theme = createMuiTheme({ ...baseTheme });

const getScreenWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

export const breakpoint = () => {
  const { sm, md, lg, xl } = theme.breakpoints.values;
  return {
    lg: getScreenWidth() < xl,
    lgAndDown: getScreenWidth() <= xl - 1,
    lgAndUp: getScreenWidth() >= lg,
    md: getScreenWidth() < lg,
    mdAndDown: getScreenWidth() <= lg - 1,
    mdAndUp: getScreenWidth() >= md,
    sm: getScreenWidth() < md,
    smAndDown: getScreenWidth() <= md - 1,
    smAndUp: getScreenWidth() >= sm,
    xl: getScreenWidth() >= xl,
    xs: getScreenWidth() < sm,
  };
};

export default function Main(props) {
  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
}
