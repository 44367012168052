import useNotifier from "components/Notifier/useNotifier";
import { useCallback, useEffect, useState } from "react";
import { useServices } from "services";
import { useProfiles } from "services/profiles";
import {
  ProfileKind,
  ProfileReadDto,
} from "../../../../../models/profilesReadDto";
import { ProfileWriteDto } from "../../../../../models/profileWriteDto";

type FormState = "none" | "saving" | "saved" | "error";
type ProfileOptions<T> = {
  initialId?: string;
  kind: ProfileKind;
  payload: T;
  actions: {
    handleAfterUpdate?: () => void;
    sendUpdatedProfile?: (profile?: ProfileReadDto) => void;
  };
};
type FormOptions<T> = {
  profileOptions: ProfileOptions<T>;
  isFormValid: boolean;
  contactId: string;
  mode: "add" | "update";
};

const successProfileSavingSnack = {
  message: "Le profil est enregistré.",
  variant: "success",
};
const errorProfileSavingSnack = {
  message: "Une erreur est survenue.",
  variant: "danger",
};

export const useFormActions = <TProfile extends ProfileWriteDto>({
  isFormValid,
  contactId,
  mode,
  profileOptions,
}: FormOptions<TProfile>) => {
  const { add, update } = useProfiles(contactId);
  const { modal } = useServices();
  const { showSnack } = useNotifier();

  const [state, setState] = useState<FormState>("none");
  const [pristine, setPristine] = useState(true);

  const { initialId, payload, actions, kind } = profileOptions;

  const displaySuccess = useCallback(() => {
    modal.close();
    showSnack(successProfileSavingSnack);
  }, [modal, showSnack]);

  const displayError = useCallback(() => {
    modal.close();
    showSnack(errorProfileSavingSnack);
  }, [modal, showSnack]);

  const handleSuccessFulSaving = useCallback(() => {
    actions.handleAfterUpdate?.();
    setState("saved");
  }, [...Object.values(actions)]);

  const handleInvalidFormWhileSaving = useCallback(() => {
    setState("none");
    setPristine(false);
  }, []);

  const setError = () => setState("error");
  const setSaving = () => setState("saving");

  const addOrUpdate = useCallback(async () => {
    if (mode === "add") {
      add(kind, payload).then(handleSuccessFulSaving).catch(setError);
      return;
    }

    update(kind, initialId!, payload, actions.sendUpdatedProfile)
      .then(handleSuccessFulSaving)
      .catch(setError);
  }, [add, update, ...Object.values(profileOptions)]);

  useEffect(() => {
    switch (true) {
      case state === "saving" && !isFormValid:
        handleInvalidFormWhileSaving();
        break;
      case state === "saving" && isFormValid:
        addOrUpdate();
        break;
      case state === "saved":
        displaySuccess();
        break;
      case state === "error":
        displayError();
        break;
    }
  }, [state]);

  return { state, pristine, setSaving };
};
