import { Chip } from "@material-ui/core";
import React from "react";

import { NumberRange } from "../../../../../components/RefinementField/types";
import { RefinementChipProps } from "../RefinementChip";

export const NumberRangeChip = ({
  value,
  format,
  onChange,
}: RefinementChipProps<NumberRange>) => {
  return (
    <>
      {(value.value?.min || value.value?.max) && (
        <Chip
          style={{ marginBottom: 6, marginRight: 6 }}
          label={format(value.value)}
          size="small"
          onDelete={() => onChange({ kind: "number-range" })}
        />
      )}
    </>
  );
};
