import { Grid } from "@material-ui/core";
import LocationIcon from "@material-ui/icons/LocationOnOutlined";
import React from "react";
import TitreBloc from "./titreBloc";
import ZoneTextfieldMulti from "components/GeoLocSearchable";
import { useTheme } from "@material-ui/styles";

const Localisation = ({
  refinementsObject,
  shouldGatherSelection,
  showSeparator,
  gridFilter,
  onUpdateLocalisations,
  selectRootClassName,
  t,
  // TODO: this might be outside of this componant when we will create generic one
  withLabel = true,
}) => {
  const theme = useTheme();

  const updateZonesChoisies = (zones) => {
    const _zones = zones
      ? zones.map((zone) => ({
          ...zone,
          isRefined: true,
        }))
      : [];
    const regions = _zones.filter((zone) => zone.typeGeoEnum === "Region");
    const departements = _zones.filter(
      (zone) => zone.typeGeoEnum === "Departement"
    );
    const villes = _zones.filter((zone) => zone.typeGeoEnum === "Ville");
    const quartiers = _zones.filter((zone) => zone.typeGeoEnum === "Quartier");
    let villesAvecArrondissements = _zones.filter(
      (zone) => zone.typeGeoEnum === "VilleAvecArrondissements"
    );

    let arrondissements = [];
    villesAvecArrondissements.map((ville) => {
      const _arrondissements = ville.raw;
      _arrondissements.map((arrondissement) => {
        const dejaPresent = villes.filter(
          (ville) => ville.value === arrondissement.id
        );
        if (dejaPresent.length === 0) {
          arrondissements.push({
            id: arrondissement.id,
            isRefined: true,
            label: arrondissement.nom,
            typeGeoEnum: "Ville",
            value: arrondissement.id,
          });
        }
        return true;
      });
      return true;
    });

    onUpdateLocalisations({
      departements,
      quartiers,
      regions,
      villes: villes.concat([...arrondissements]),
    });
    /*  onUpdateRefinements('regions', regions);
        onUpdateRefinements('departements', departements);
        onUpdateRefinements('villes', villes.concat([...arrondissements]));
        onUpdateRefinements('quartiers', quartiers);*/
  };

  let selectionZones = [];
  if (refinementsObject) {
    selectionZones = refinementsObject.regions.values
      .filter((value) => value.isRefined === true)
      .concat(
        refinementsObject.departements.values
          .filter((value) => value.isRefined === true)
          .concat(
            refinementsObject.villes.values
              .filter((value) => value.isRefined === true)
              .concat(
                refinementsObject.quartiers.values.filter(
                  (value) => value.isRefined === true
                )
              )
          )
      );
  }

  return (
    <Grid container spacing={1}>
      {!withLabel ? null : (
        <Grid item xs={12}>
          <TitreBloc
            label={t("Lieu(x)")}
            icon={<LocationIcon style={{ color: theme.palette.tonique }} />}
          />
        </Grid>
      )}

      <Grid className={gridFilter} item xs={12}>
        <ZoneTextfieldMulti
          rootClassName={selectRootClassName}
          t={t}
          onChange={updateZonesChoisies}
          selection={selectionZones}
          label={t("Zones de recherche")}
          showSeparator={showSeparator}
          shouldGatherSelection={shouldGatherSelection}
        />
      </Grid>
    </Grid>
  );
};

export default Localisation;
