import { Refinement } from "components/RefinementField/types";

export function toParams(key: string, value: Refinement) {
  switch (value.kind) {
    case "locations": {
      return Object.entries(value.value).reduce((a, [l, p]) => {
        return {
          ...a,
          [l]: p.filter((_) => _ && _.isRefined).map((_) => _.value),
        };
      }, {});
    }
    case "single-text":
      return { [key]: value.value };
    case "multiple-text":
      return {
        [key]: value.value.filter((_) => _.isRefined).map((_) => _.value),
      };
    case "price":
      return { [key]: value.value };
    case "number-range":
      return {
        [`${key}Min`]: value.value?.min,
        [`${key}Max`]: value.value?.max,
      };
    case "date-range":
      return {
        [`${key}Min`]: value.value?.min,
        [`${key}Max`]: value.value?.max,
      };
    case "date-range-options":
      return {
        [`${key}Min`]: value.value?.min,
        [`${key}Max`]: value.value?.max,
      };
    case "checkbox":
      return {
        [key]: !!value.value,
      };
    case "sort":
      return {
        [`${key}Asc`]: value.value.ascending,
        [`${key}Column`]: value.value.column,
      };
    case "phone-number":
      return { [key]: value.value };
    case "radio":
      return {
        [key]: value.value?.filter((_) => _.isRefined).map((_) => _.value),
      };
    default:
      return {};
  }
}
