import TargetIcon from "@material-ui/icons/TrackChanges";
import React from "react";
import { CampaignDetailsSection } from "../..";

export const CampaignTargetSection: React.FC<{ content: string }> = ({
  content,
}) => (
  <CampaignDetailsSection
    icon={<TargetIcon style={{ fontSize: "16px" }} />}
    title={"Cible"}
    content={content}
  />
);
