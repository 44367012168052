import { Sort } from "components/RefinementField/types";
import { useCallback, useState } from "react";
import { SortOption } from "UI/shared/molecules/SortInput";

const defaultSortOption = {
  isAscendingOrder: false,
  isDefault: true,
  label: "Date de Reception",
  query: "ReceivedAt",
  value: "ReceivedAt descending",
};

const sortOptions: SortOption[] = [
  defaultSortOption,
  {
    isAscendingOrder: true,
    isDefault: false,
    label: "Date de Reception",
    query: "ReceivedAt",
    value: "ReceivedAt ascending",
  },
  {
    isAscendingOrder: true,
    isDefault: false,
    label: "Date de mise à jour",
    query: "LastUpdate",
    value: "LastUpdate ascending",
  },
  {
    isAscendingOrder: false,
    isDefault: false,
    label: "Date de mise à jour",
    query: "LastUpdate",
    value: "LastUpdate descending",
  },
];

export const useSortOptions = () => {
  const [activeSortOption, setActiveSortOption] = useState<SortOption>(
    defaultSortOption
  );

  const onChange = useCallback(({ value }: { key: "sort"; value: Sort }) => {
    const sortOption = sortOptions.find(
      (so) =>
        so.query === value.value.column &&
        so.isAscendingOrder === value.value.ascending
    );

    if (!sortOption) return;

    setActiveSortOption(sortOption);
  }, []);

  return {
    activeSortOption,
    onChange,
    sortOptions,
  };
};
