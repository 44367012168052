import { Grid } from "@material-ui/core";
import React, { useCallback } from "react";
import { RealEstatePropertyCard } from "UI/features/realEstates/organisms/RealEstatePropertyCard";
import { InfiniteScrollList } from "UI/shared/organisms/InfiniteScrollList/InfiniteScrollList";
import { generateUniqueKey } from ".";
import { SearchResultProps } from "../../props";
import { toRealEstatePropertyCardProps } from "../adapters/toRealEstatePropertyCardProps";

export const RealEstatesGallerySmall: React.FC<SearchResultProps> = ({
  items,
  loadMore,
  onClick,
}) => {
  const handleClick = useCallback(
    (id: string) => () => {
      onClick?.(id);
    },
    [onClick]
  );

  return (
    <Grid container spacing={2}>
      <InfiniteScrollList pageSize={10} loadMore={loadMore} wrapItems={false}>
        {items.map((item) => (
          <Grid item xs={12} sm={6} md={3} key={generateUniqueKey(item)}>
            <RealEstatePropertyCard
              {...toRealEstatePropertyCardProps(item, handleClick(item.id))}
            />
          </Grid>
        ))}
      </InfiniteScrollList>
    </Grid>
  );
};
