import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import React, { FC } from "react";

import { useContactForm } from "../context/ContactFormContext";
import { professionalCategories } from "../data";
import useStyles from "../styles";

export const Profession: FC = () => {
  const classes = useStyles();
  const { contactDto, setContactDto, isDisabled } = useContactForm();
  const { professionalInfos } = contactDto.identity;
  const categories = Object.keys(professionalCategories);
  const professions =
    professionalCategories[
      professionalInfos?.professionalCategory || categories[0]
    ];

  const onProfessionalInfosChange = ({
    target: { name, value },
  }: {
    target: { name: string; value: string };
  }) => {
    setContactDto({
      ...contactDto,
      identity: {
        ...contactDto.identity,
        professionalInfos: {
          ...contactDto.identity.professionalInfos,
          [name]: value,
        },
      },
    });
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <TextField
          variant="outlined"
          name="professionalCategory"
          disabled={isDisabled}
          label="Catégorie socio-professionnelle"
          className={classes.textField}
          value={professionalInfos?.professionalCategory || ""}
          onChange={onProfessionalInfosChange}
          select
        >
          {categories.map((category) => (
            <MenuItem key={category} value={category}>
              <Typography variant="body1" className={classes.professionLabel}>
                {category}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          variant="outlined"
          name="profession"
          label="Profession"
          disabled={isDisabled}
          value={professionalInfos?.profession || ""}
          onChange={onProfessionalInfosChange}
          className={classes.textField}
          select
        >
          {professions.map((profession) => (
            <MenuItem key={profession} value={profession}>
              <Typography variant="body1" className={classes.professionLabel}>
                {profession}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </>
  );
};
