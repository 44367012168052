import { CancelToken } from "axios";
import { useCallback } from "react";
import { useServices } from "services";
import { useTxHttp } from "services/http";
import { RealEstatesSearchParams } from "../types";

export const useRealEstates = () => {
  const { get } = useTxHttp();
  const { endpoints } = useServices();

  return {
    search: useCallback(
      (params: RealEstatesSearchParams, cancelToken: CancelToken) =>
        get<any>(
          endpoints.bienEnVente.rechercheGridOuListe,
          params,
          cancelToken
        ).then((response) => {
          return response.data;
        }),
      [get]
    ),
  };
};
