import { useCallback, useRef } from "react";

export default function useInfiniteScroll({
  handleChangePage,
  loading,
  hasMore,
  setFirstIntersection,
}) {
  const observerTriggerRef = useRef();
  const observerFirstRef = useRef();

  const triggerRef = useCallback(
    (node) => {
      if (loading) return;
      if (observerTriggerRef.current) observerTriggerRef.current.disconnect();
      observerTriggerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          handleChangePage();
        }
      });
      if (node) observerTriggerRef.current.observe(node);
    },
    [hasMore, loading, handleChangePage]
  );

  const firstRef = useCallback(
    (node) => {
      if (observerFirstRef.current) observerFirstRef.current.disconnect();
      observerFirstRef.current = new IntersectionObserver((entries) => {
        setFirstIntersection(entries[0].isIntersecting);
      });
      if (node) observerFirstRef.current.observe(node);
    },
    [setFirstIntersection]
  );

  return { firstRef, triggerRef };
}
