import React from "react";

import { AmenityType } from "../../../../models/amenityType";
import { AnnexDto } from "../../../../models/annexDto";
import { ExposureType } from "../../../../models/exposureType";
import { FurnitureType } from "../../../../models/furnitureType";
import { GeneralStateType } from "../../../../models/generalStateType";
import { JoinOwnershipType } from "../../../../models/JoinOwnershipType";
import { MaturityType } from "../../../../models/maturityType";
import { ParkingsDto } from "../../../../models/partSummariesDto";
import { ProfileAddressDto } from "../../../../models/profileAddressDto";
import { PropertyInfoDto } from "../../../../models/propertyInfoDto";
import { ForRentHouseProfileWriteDto } from "../../../../models/Proposal/ForRent/House/forRentHouseProfileWriteDto";
import { RentHouseFinancialInfoDto } from "../../../../models/Proposal/ForRent/House/rentHouseFinancialInfoDto";
import { SummariesDto } from "../../../../models/summariesDto";
import {
  amenitiesUpdater,
  annexesUpdater,
  budgetUpdater,
  exposureUpdater,
  furnitureUpdater,
  generalStateUpdater,
  joinOwnershipUpdater,
  maturityTypeUpdater,
  notesUpdater,
  parkingsUpdater,
  placeUpdater,
  sizeUpdater,
  summariesUpdater,
  yearBuiltUpdater,
} from "./changeHandler";

export type FormValidity = {
  address: boolean;
  annexes: boolean;
  financialInfo: boolean;
  parts: boolean;
  propertyInfo: boolean;
};

export const formValidityUpdater: (
  key: keyof FormValidity,
  isValid: boolean
) => React.SetStateAction<FormValidity> = (key, isValid) => (prev) => ({
  ...prev,
  [key]: isValid,
});

export const useChanges = (
  setPayload: (
    value: React.SetStateAction<ForRentHouseProfileWriteDto>
  ) => void,
  setFormValidity: (value: React.SetStateAction<FormValidity>) => void
) => {
  const onFurnitureChange = React.useCallback(
    (furniture: FurnitureType) => {
      setPayload(furnitureUpdater(furniture));
    },
    [setPayload]
  );

  const onPlaceChange = React.useCallback(
    (address: ProfileAddressDto, isValid: boolean) => {
      setFormValidity(formValidityUpdater("address", isValid));
      setPayload(placeUpdater(address));
    },
    [setPayload, setFormValidity]
  );

  const onBudgetChange = React.useCallback(
    (budget: RentHouseFinancialInfoDto, isValid: boolean) => {
      setFormValidity(formValidityUpdater("financialInfo", isValid));
      setPayload(budgetUpdater(budget));
    },
    [setPayload, setFormValidity]
  );

  const onYearBuiltChange = React.useCallback(
    (yearBuilt: number) => {
      setPayload(yearBuiltUpdater(yearBuilt));
    },
    [setPayload]
  );

  const onGeneralStateChange = React.useCallback(
    (generalState: GeneralStateType) => {
      setPayload(generalStateUpdater(generalState));
    },
    [setPayload]
  );

  const onExposureChange = React.useCallback(
    (exposure: ExposureType) => {
      setPayload(exposureUpdater(exposure));
    },
    [setPayload]
  );

  const onSizeChange = React.useCallback(
    (propertyInfo: PropertyInfoDto, isValid: boolean) => {
      setFormValidity(formValidityUpdater("propertyInfo", isValid));
      setPayload(sizeUpdater(propertyInfo));
    },
    [setPayload, setFormValidity]
  );

  const onSummariesChange = React.useCallback(
    (summaries: SummariesDto, isValid: boolean) => {
      setFormValidity(formValidityUpdater("parts", isValid));
      setPayload(summariesUpdater(summaries));
    },
    [setPayload, setFormValidity]
  );

  const onJoinOwnershipChange = React.useCallback(
    (joinOwnership: JoinOwnershipType) => {
      setPayload(joinOwnershipUpdater(joinOwnership));
    },
    [setPayload]
  );

  const onAmenitiesChange = React.useCallback(
    (amenities: AmenityType[]) => {
      setPayload(amenitiesUpdater(amenities));
    },
    [setPayload]
  );

  const onParkingsChange = React.useCallback(
    (parkings: ParkingsDto) => {
      setPayload(parkingsUpdater(parkings));
    },
    [setPayload]
  );

  const onAnnexesChange = React.useCallback(
    (annexes: AnnexDto[], isValid: boolean) => {
      setFormValidity(formValidityUpdater("annexes", isValid));
      setPayload(annexesUpdater(annexes));
    },
    [setPayload, setFormValidity]
  );

  const onNotesChange = React.useCallback(
    (notes: string) => {
      setPayload(notesUpdater(notes));
    },
    [setPayload]
  );

  const onMaturityChange = React.useCallback(
    (maturity: MaturityType) => {
      setPayload(maturityTypeUpdater(maturity));
    },
    [setPayload]
  );

  return {
    onAmenitiesChange,
    onAnnexesChange,
    onBudgetChange,
    onExposureChange,
    onFurnitureChange,
    onGeneralStateChange,
    onJoinOwnershipChange,
    onMaturityChange,
    onNotesChange,
    onParkingsChange,
    onPlaceChange,
    onSizeChange,
    onSummariesChange,
    onYearBuiltChange,
  };
};
