import FormControlLabel from "@material-ui/core/FormControlLabel";
import React, { ChangeEventHandler } from "react";
import { ToniqueCheckbox } from "UI/shared/atoms/inputs/ToniqueCheckbox";

interface Props {
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  label: string;
  name?: string;
}
export const LabelledToniqueCheckbox: React.FC<Props> = ({
  checked,
  onChange,
  name,
  label,
}) => (
  <FormControlLabel
    control={
      <ToniqueCheckbox checked={checked} onChange={onChange} name={name} />
    }
    label={label}
  />
);
