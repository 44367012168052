import React from "react";
import { Typography } from "@material-ui/core";

export interface PrintableContactNotesProps {
  notes: string;
}

export const PrintableContactNotes: React.FC<PrintableContactNotesProps> = ({
  notes,
}) => {
  return (
    <div
      style={{
        marginTop: "20mm",
        width: "100%",
        border: "2px solid",
        borderColor: "#47B0B5",
        borderRadius: "5px",
        padding: "10px",
      }}
    >
      <Typography variant={"h4"} style={{ marginBottom: "5px" }}>
        Notes
      </Typography>
      <Typography variant={"body1"} style={{ whiteSpace: "pre-line" }} noWrap>
        {notes}
      </Typography>
    </div>
  );
};
