import React, { useContext } from "react";
import { CheckableRealEstatePropertyCard } from "UI/features/realEstates/organisms/CheckableRealEstatePropertyCard";
import { InfiniteScrollList } from "UI/shared/organisms/InfiniteScrollList/InfiniteScrollList";
import { SharingNetworkRealEstatesContext } from "./index";
export const SCROLL_CONTAINER =
  "sharing-network-realestates-small-infinite-scroll-view";

export const SharingNetworkRealEstatesSmall: React.FC = () => {
  const {
    realEstates,
    isChecked,
    isShared,
    handleCheck,
    loadMore,
  } = useContext(SharingNetworkRealEstatesContext);
  return (
    <InfiniteScrollList
      pageSize={10}
      loadMore={loadMore}
      horizontal={true}
      containerId={SCROLL_CONTAINER}
    >
      {realEstates.map((realEstate) => (
        <div key={realEstate.id} style={{ margin: 5, minWidth: 300 }}>
          <CheckableRealEstatePropertyCard
            realEstateProps={realEstate}
            id={realEstate.id || ""}
            handleCheck={handleCheck}
            isChecked={isChecked(realEstate.id || "")}
            isShared={isShared(realEstate.page || 0, realEstate.id || "")}
          />
        </div>
      ))}
    </InfiniteScrollList>
  );
};
