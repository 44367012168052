import useNotifier from "components/Notifier/useNotifier";
import { EventsDetailedView } from "UI/shared/organisms/Events/EventsDetailedView";
import { emptyContactEventSent } from "UI/shared/organisms/Events/stateHandler";
import React, { useCallback, useEffect, useState } from "react";
import { useServices } from "services";
import {
  ContactEventReceived,
  ContactEventSent,
} from "services/contactEventsTracking/dto/ContactEvent";

interface ContactEventsTrackingProps {
  contactId: string;
  events: ContactEventReceived[];
  loadEvents: () => void;
  simplifiedView?: boolean;
}

export const ContactEventsTracking: React.FC<ContactEventsTrackingProps> = ({
  contactId,
  events,
  loadEvents,
  simplifiedView = false,
}) => {
  const [selectedEvent, setSelectedEvent] = useState<ContactEventSent>(
    emptyContactEventSent
  );
  const { showSnack } = useNotifier();
  const {
    contactEvents: { addEvent, updateEvent, deleteEvent },
    l10n: { t },
  } = useServices();

  const onAddEvent = useCallback(
    async (contactEvent: ContactEventSent) => {
      const addedEvent: Omit<ContactEventSent, "id" | "calendarEventId"> = {
        comment: contactEvent.comment,
        date: contactEvent.date,
        haveToBeAddedToCalendar: contactEvent.haveToBeAddedToCalendar,
        kind: contactEvent.kind,
      };
      const add = () => addEvent(contactId, addedEvent);
      await errorHandling(
        showSnack,
        loadEvents,
        t,
        add,
        `Votre ${t(addedEvent.kind)} a bien été ajouté.`
      );
    },
    [addEvent, contactId, loadEvents, showSnack, t]
  );

  const onUpdateEvent = useCallback(
    async (contactEvent: ContactEventSent) => {
      const update = () => updateEvent(contactId, contactEvent);
      await errorHandling(
        showSnack,
        loadEvents,
        t,
        update,
        `Votre ${t(contactEvent.kind)} a bien été modifié.`
      );
    },
    [contactId, loadEvents, showSnack, t, updateEvent]
  );

  const onDeleteEvent = useCallback(
    async (contactEvent: ContactEventReceived) => {
      const remove = () => deleteEvent(contactId, contactEvent.id);
      await errorHandling(
        showSnack,
        loadEvents,
        t,
        remove,
        `Votre ${t(contactEvent.kind)} a bien été supprimé.`
      );
    },
    [contactId, deleteEvent, loadEvents, showSnack, t]
  );

  const onSelectEvent = (event: ContactEventReceived) => {
    const _selectedEvent: ContactEventSent = {
      ...event,
      haveToBeAddedToCalendar: event.calendarEventId !== "",
    };
    setSelectedEvent(_selectedEvent);
  };

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  return (
    <EventsDetailedView
      events={events}
      selectedEvent={selectedEvent}
      addEvent={onAddEvent}
      updateEvent={onUpdateEvent}
      selectEvent={onSelectEvent}
      deleteEvent={onDeleteEvent}
      simplifiedView={simplifiedView}
    />
  );
};

type ShowSnack = ({
  message,
  variant,
  withLoader,
}: {
  message: string;
  variant: string;
  withLoader?: boolean;
}) => void;

const errorHandling = async (
  showSnack: ShowSnack,
  loadEvents: () => void,
  t: (word: string) => string,
  eventMutation: () => Promise<void>,
  successMessage: string
) => {
  showSnack({
    message: "Enregistrement en cours.",
    variant: "info",
    withLoader: true,
  });
  try {
    await eventMutation();
    loadEvents();
    showSnack({
      message: successMessage,
      variant: "success",
    });
  } catch (error) {
    showSnack({
      message: t((error as Error).message),
      variant: "error",
    });
  }
};
