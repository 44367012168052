import { string, number } from "api/DTOs/Common/Types";

function ObtenirLieuParIdAdapter(result = {}) {
  return {
    id: result.id || string,
    nom: result.nom || string,
    photo: result.photo || string,
    telephone: result.telephone || string,
    email: result.email || string,
    latitude: result.latitude || number,
    longitude: result.longitude || number,
  };
}

export default ObtenirLieuParIdAdapter;
