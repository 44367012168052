import { Grid } from "@material-ui/core";
import React from "react";

import { AgentAddress } from "./components/adresse";
import ContactsPrivilegies from "./components/contactsPrivilegies";
import Lieux from "./components/lieux/lieux";
import QRCodeProfil from "./components/QRCode";
import Resume from "./components/resume";

const Profil = ({ conseiller }) => {
  const agentPosition = { lat: conseiller.latitude, lng: conseiller.longitude };
  const workAreaPolygon = conseiller.zoneDeTravail?.ville?.polygone;
  const agentProAddress = {
    address1: conseiller.adressePro.adresse,
    address2: conseiller.adressePro.complementAdresse,
    city: conseiller.adressePro.ville,
    country: conseiller.localisation.pays,
    number: conseiller.adressePro.numero,
    zipCode: conseiller.adressePro.codePostal,
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        <Resume conseiller={conseiller} />
        <QRCodeProfil profil={conseiller} />
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container spacing={1}>
          {(conseiller.responsableRegional !== "" ||
            conseiller.coach !== "") && (
            <Grid item xs={12}>
              <ContactsPrivilegies conseiller={conseiller} />
            </Grid>
          )}
          <Grid item xs={12}>
            <AgentAddress
              agentPosition={agentPosition}
              workAreaPolygon={workAreaPolygon}
              agentProAddress={agentProAddress}
            />
          </Grid>
          <Grid item xs={12}>
            <Lieux conseiller={conseiller} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Profil;
