import React from "react";

import { RadioChip } from "UI/shared/molecules/RadioChip";
import { LandForSaleInfoKind } from "../../../../models/Proposal/Features/Land/landInfoKind";
import { SubSectionTitle } from "../../../components/SectionTitle";

export const LandKindSelection: React.FC<{
  value?: LandForSaleInfoKind;
  onChange: (value: LandForSaleInfoKind) => void;
}> = ({ value, onChange }) => {
  const options: LandForSaleInfoKind[] = [
    "All",
    "Arranged",
    "Buildable",
    "NotBuildable",
    "Pond",
    "Leisure",
  ];

  return (
    <>
      <SubSectionTitle title="Type de terrain" mode={"non obligatoire"} />
      <RadioChip
        translationPrefix={"landKind"}
        options={options}
        value={value}
        onChange={onChange}
      />
    </>
  );
};
