import { useServices } from "services";

type TranslateFunction = (_: string) => string;

const defaultTranslate: TranslateFunction = (value) => value;
export const useTranslation: () => { t: TranslateFunction } = () => {
  const services = useServices();

  if (!services?.l10n) return { t: defaultTranslate };

  return {
    t: services.l10n.t,
  };
};
