import { IconButton, Tooltip } from "@material-ui/core";
import { VisibilityOffTwoTone, VisibilityTwoTone } from "@material-ui/icons";
import React, { useContext } from "react";
import { useServices } from "services";

import { PigeVisibilityContext } from "../../contexts/PigeVisibilityContextProvider";

export const AdVisibility: React.FC<{
  id?: string;
  withText?: boolean;
}> = ({ id, withText }) => {
  const { isAdVisible, toggleAdVisibility } = useContext(PigeVisibilityContext);
  const {
    l10n: { t },
  } = useServices();
  const isVisible = isAdVisible(id);

  const onClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    toggleAdVisibility(id);
  };

  return !withText ? (
    <>
      <Tooltip
        title={
          isVisible ? t("Masquer l'annonce") : t("Ne plus masquer l'annonce")
        }
      >
        <IconButton onClick={onClick}>
          {isVisible ? <VisibilityTwoTone /> : <VisibilityOffTwoTone />}
        </IconButton>
      </Tooltip>
    </>
  ) : (
    <>
      <div onClick={onClick}>
        <IconButton>
          {isVisible ? <VisibilityTwoTone /> : <VisibilityOffTwoTone />}
        </IconButton>
        <span>{isVisible ? t("Masquer") : t("Ne plus masquer")}</span>
      </div>
    </>
  );
};
