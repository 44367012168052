import { Text16px } from "UI/shared/atoms/typographies/Text16px";
import { Text16pxBold } from "UI/shared/atoms/typographies/Text16pxBold";
import React from "react";

export interface RoomsCaptionProps {
  total: number;
  unitLabel?: string;
}

export const BoldNumberUnitLabelNormalCaption: React.FC<RoomsCaptionProps> = ({
  total,
  unitLabel = "",
}) => {
  return (
    <>
      <Text16pxBold>{total}</Text16pxBold>
      <Text16px>&#8239;{unitLabel}&#8239;</Text16px>
    </>
  );
};
