const MONTHS = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];
const WEEKDAYS_LONG = [
  "Dimanche",
  "Lundì",
  "Mardì",
  "Mercredì",
  "Jeudì",
  "Vendredì",
  "Samedi",
];
const WEEKDAYS_SHORT = ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"];

export { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT };
