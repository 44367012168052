import Bookmark from "@material-ui/icons/Bookmark";
import React from "react";

import { useStyles } from "./styles";

interface HasEventsBookmarkProps {
  offsetRight: number;
}

export const HasEventsBookmark: React.FC<HasEventsBookmarkProps> = ({
  offsetRight,
}) => {
  const classes = useStyles({ offsetRight });
  return (
    <div className={classes.bookmarkIcon}>
      <div className={classes.container}>
        <Bookmark className={classes.outsideBookmarkIcon} />
        <Bookmark className={classes.insideBookmarkIcon} />
      </div>
    </div>
  );
};
