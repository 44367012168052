import geoPolygonAdapter, { GeoPolygon } from "./geoPolygonAdapter";

interface GeoLocations {
  regions: GeoPolygon[];
  departements: GeoPolygon[];
  villes: GeoPolygon[];
  quartiers: GeoPolygon[];
}

type GeoLocationType = "regions" | "departements" | "villes" | "quartiers";

const convert = (result: GeoLocations, type: GeoLocationType) =>
  result[type].map((elt) => ({
    ...geoPolygonAdapter(elt),
    type,
  }));

export default function geoPolygonsAdapter(result: GeoLocations) {
  const regions = result.regions ? convert(result, "regions") : [];
  const departements = result.departements
    ? convert(result, "departements")
    : [];
  const villes = result.villes ? convert(result, "villes") : [];
  const quartiers = result.quartiers ? convert(result, "quartiers") : [];
  return regions.concat(departements.concat(villes.concat(quartiers)));
}
