import { EndpointsProvider } from "./interfaces/EndpointsProvider";

export interface MesBiensEnVenteEndpoints {
  rechercheParIdCarte: string;
  rechercheGridOuListe: string;
  rechercheCarte: string;
  rechercheParId: (id: string) => string;
}

const endpoints: EndpointsProvider<MesBiensEnVenteEndpoints> = (
  microServices
) => ({
  rechercheCarte: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/MesBiensEnVente/RechercheCarte`,
  rechercheGridOuListe: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/MesBiensEnVente/RechercheGridOuListe`,
  rechercheParId: (id) =>
    `${microServices.tx(
      "rapprochement-queries"
    )}/v1/MesBiensEnVente/ObtenirParId?id=${id}`,
  rechercheParIdCarte: `${microServices.tx(
    "rapprochement-queries"
  )}/v1/MesBiensEnVente/ObtenirParIdCarte`,
});

export default endpoints;
