import { Grid, makeStyles, Paper } from "@material-ui/core";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";
import React from "react";
import { ReactElement } from "react-router/node_modules/@types/react";

export interface ColoredPaperButtonProps {
  icon?: ReactElement;
  backcolor: string;
  color: string;
  label: string;
  subLabel: string;
  clickHandler: () => void;
}

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
  },
  root: {
    "&:hover": {
      boxShadow:
        "0px 3px 3px 0px rgba(0,0,0,0.34), 0px 1px 1px -1px rgba(0,0,0,0.1)",
    },
    background: ({ backcolor }: { color: string; backcolor: string }) =>
      backcolor,
    color: ({ color }: { color: string; backcolor: string }) => color,
    cursor: "pointer",
  },
  subLabel: {
    fontSize: 12,
    textAlign: "center",
  },
}));

export const ColoredPaperButton: React.FC<ColoredPaperButtonProps> = ({
  icon,
  backcolor,
  color,
  label,
  subLabel,
  clickHandler,
}) => {
  const classes = useStyles({ backcolor, color });
  return (
    <Paper
      classes={{ root: classes.root }}
      onClick={clickHandler}
      elevation={3}
    >
      <PaddingContainer top={12} bottom={12} left={16} right={16}>
        <Grid container alignItems="center">
          {icon && (
            <Grid item xs={1}>
              <div>{icon}</div>
            </Grid>
          )}
          <Grid item xs={icon ? 11 : 12}>
            <div className={classes.label}>{label}</div>
            <div className={classes.subLabel}>{subLabel}</div>
          </Grid>
        </Grid>
      </PaddingContainer>
    </Paper>
  );
};
