import Typography from "@material-ui/core/Typography";
import React from "react";

export interface TitleProps {
  title: string;
}
export const Title: React.FC<TitleProps> = ({ title }) => (
  <Typography
    variant="subtitle2"
    style={{
      borderBottom: "1px solid #e2e2e2",
      marginBottom: 10,
      paddingBottom: 5,
      width: "100%",
    }}
  >
    {title.toUpperCase()}
  </Typography>
);
