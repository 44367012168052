import { Grid, Typography, useTheme } from "@material-ui/core";
import AccessTimeOutlined from "@material-ui/icons/AccessTimeOutlined";
import TypeAnnonceur from "components/TypeAnnonceur";
import moment from "moment";
import React from "react";
import { SquareStatusIcon } from "UI/shared/atoms/SquareStatusIcon";
import { ITheme } from "theme/ts/main";

interface AnnonceTypeSectionProps {
  typeAnnonce: string;
  datePublication: Date;
  dateMiseAJour: Date;
  estActive: boolean;
}

const AnnonceTypeSection = ({
  typeAnnonce,
  datePublication,
  dateMiseAJour,
  estActive,
}: AnnonceTypeSectionProps) => {
  const theme = useTheme<ITheme>();

  if (typeAnnonce === undefined && datePublication === undefined) return <></>;

  return (
    <div
      style={{
        backgroundColor: "#f2f2f2",
        borderBottomLeftRadius: "6px",
        borderBottomRightRadius: "6px",
        borderTopRightRadius: "6px",
        padding: "18px 5px 10px",
        position: "relative",
      }}
    >
      <Grid container spacing={0}>
        <Grid item xs={12} container>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              width: 40,
            }}
          >
            <SquareStatusIcon
              color={
                estActive
                  ? theme.palette.status.active
                  : theme.palette.status.archived
              }
            />
          </div>
          <div>&nbsp;</div>
          <Typography variant="h4">
            {estActive ? "Actif" : "Désactivé"}
          </Typography>
        </Grid>

        <Grid item xs={12} container>
          <div style={{ textAlign: "center", width: 40 }}>
            <AccessTimeOutlined fontSize="small" />
          </div>
          <div>&nbsp;</div>
          <Typography variant={"body1"}>
            Paru le : {moment(datePublication).format("DD/MM/YYYY")}
          </Typography>
        </Grid>

        <Grid item xs={12} container>
          <div style={{ width: 40 }} />
          <div>&nbsp;</div>
          <Typography variant={"body1"}>
            Mis à jour le : {moment(dateMiseAJour).format("DD/MM/YYYY")}
          </Typography>
        </Grid>
      </Grid>

      <div style={{ left: 0, position: "absolute", top: 0 }}>
        <TypeAnnonceur type={typeAnnonce === "Pro" ? "PRO" : "PART"} />
      </div>
    </div>
  );
};

export default AnnonceTypeSection;
