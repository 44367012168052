import { ElasticAdapter } from "api/DTOs/Common/ElasticSearch/ElasticAdapters";
import { useCallback } from "react";
import { useServices } from "services";
import { useTxHttp } from "services/http";
import { BuyerMatchingProfilePresenter } from "../BuyerMatchingProfilePresenter";
import { MatchingProfileRequestDto } from "../MatchingProfileRequestDto";
import { NetworkMatchingProfilePresenter } from "../NetworkMatchingProfilePresenter";

export const useAcquirerProfilesMatching = () => {
  const { post } = useTxHttp();
  const { endpoints } = useServices();
  const getConsultantRealEstate = useCallback(
    (payload: MatchingProfileRequestDto, page: number, size: number) => {
      return post<ElasticAdapter<BuyerMatchingProfilePresenter>>(
        endpoints.profiles.matching.mine(page, size),
        {
          content: payload,
          contentType: "application/json",
        }
      );
    },
    [endpoints.profiles.matching, post]
  );

  const getNetworkRealEstate = useCallback(
    (payload: MatchingProfileRequestDto, page: number, size: number) => {
      return post<ElasticAdapter<NetworkMatchingProfilePresenter>>(
        endpoints.profiles.matching.theirs(page, size),
        {
          content: payload,
          contentType: "application/json",
        }
      );
    },
    [endpoints.profiles.matching, post]
  );

  const getCount = useCallback(
    async (payload: MatchingProfileRequestDto) => {
      try {
        const _ = await post(endpoints.profiles.matching.count, {
          content: payload,
          contentType: "application/json",
        });
        return _.data as number;
      } catch (e) {
        return undefined;
      }
    },
    [endpoints.profiles.matching.count, post]
  );

  return {
    getConsultantRealEstate,
    getCount,
    getNetworkRealEstate,
  };
};
