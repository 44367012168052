import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { useCallback, useState } from "react";

import noImage from "assets/images/no-image-box.png";
import { useServices } from "services";
import { Campaign } from "services/campagnes/types";
import { CampaignStatsButton } from "../../molecules/CampaignStatsButton";
import { GreenSwitch } from "../../molecules/GreenSwitch";
import { SubscribedByDefault } from "../../molecules/SubscribedByDefault";
import { useStyles } from "./index.style";
import { CampaignDetailsButton } from "../CampaignDetailsButton";

export interface CampaignCardProps {
  campaign: Campaign;
  subscribe: (campaignId: string) => Promise<void>;
  unsubscribe: (campaignId: string) => Promise<void>;
}

export const CampaignCard: React.FC<CampaignCardProps> = ({
  campaign,
  subscribe,
  unsubscribe,
}) => {
  const [loading, setLoading] = useState(false);
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles();
  const handleChange = useCallback(async () => {
    setLoading(true);
    if (campaign.state === "NotSubscribed") {
      await subscribe(campaign.id);
      return setLoading(false);
    }
    await unsubscribe(campaign.id);
    setLoading(false);
  }, [campaign.id, campaign.state, subscribe, unsubscribe]);

  return (
    <Card className={classes.card} style={{ position: "relative" }}>
      <CardMedia
        className={classes.media}
        image={
          campaign.thumbnail &&
          campaign.thumbnail !== "/nl/img/thumbnails/defaultCampaign.png"
            ? campaign.thumbnail
            : noImage
        }
        title={campaign.label}
      />
      <CardContent>
        <Grid container direction="column">
          <Grid item xs={12}>
            <Typography variant="h2">{campaign.label}</Typography>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                fontSize: "12px",
                height: "120px",
                lineHeight: "1.43",
                overflowY: "hidden",
                textAlign: "left",
              }}
            >
              {campaign.description}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="body2">{t("Canal")}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5">{t("Email")}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2">
              {campaign.state === "Subscribed" ||
              campaign.state === "SubscribedByDefault"
                ? t("Campagne active")
                : t("Campagne désactivée")}
            </Typography>
            {campaign.state === "SubscribedByDefault" ? (
              <div style={{ marginLeft: "10px" }}>
                <SubscribedByDefault />
              </div>
            ) : (
              <></>
            )}
            {loading ? (
              <div className={classes.switchContainer}>
                <CircularProgress size={20} style={{ color: "lightgray" }} />
              </div>
            ) : (
              <div style={{ marginRight: -12 }}>
                <GreenSwitch
                  checked={
                    campaign.state === "Subscribed" ||
                    campaign.state === "SubscribedByDefault"
                  }
                  onChange={handleChange}
                  value={
                    campaign.state === "Subscribed" ||
                    campaign.state === "SubscribedByDefault"
                  }
                />
              </div>
            )}
          </div>
        </Grid>
      </CardContent>
      <CardActions className={classes.actions}>
        {campaign.stats && campaign.stats.length > 0 ? (
          <CampaignStatsButton title={campaign.label} stats={campaign.stats} />
        ) : (
          <div style={{ height: "42px" }}>&nbsp;</div>
        )}
        {campaign.details ? (
          <CampaignDetailsButton campaign={campaign} />
        ) : (
          <div style={{ height: "42px" }}>&nbsp;</div>
        )}
      </CardActions>
    </Card>
  );
};
