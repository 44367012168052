import { Grid, Typography } from "@material-ui/core";
import PlaceIcon from "@material-ui/icons/PlaceOutlined";
import CommerceEntreprise from "UI/shared/atoms/Badges/C&E";
import Exclusivite from "UI/shared/atoms/Badges/Exclusivite";
import Neuf from "UI/shared/atoms/Badges/Neuf";
import Prestige from "UI/shared/atoms/Badges/Prestige";
import { SousOffre } from "UI/shared/atoms/Badges/SousOffre";
import React from "react";
import { formatPrix } from "utils/formatage";
import getUrlPhoto from "utils/UrlImage";
import { SousCompromis } from "../../../../UI/shared/atoms/Badges/SousCompromis";

import { useServices } from "../../../../services";
import { useStyles } from "./styles";

interface Hit {
  bu: string;
  exclusivite: boolean;
  familleBienEnum: string;
  id: string;
  latitude: number;
  localisation: string;
  longitude: number;
  nbChambres: number;
  nbPieces: number;
  neuf: boolean;
  photoPrincipale: string;
  prestige: boolean;
  prixNormalized: number;
  surface: number;
  typeAffaireEnum: string;
  villeCodePostal: string;
  villeNom: string;
  isTvb?: boolean;
  isCE: boolean;
  underOfferToSell: boolean;
  hasOffer: boolean;
}

interface SmallPrintableRealtyCardProps {
  hit: Hit;
}

export const SmallPrintablePropertyCard = ({
  hit,
}: SmallPrintableRealtyCardProps): JSX.Element => {
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={0} direction="row" alignItems="center">
        <Grid item xs={6}>
          <div
            style={{
              backgroundImage: `url(${getUrlPhoto(
                hit.bu,
                "300x300",
                hit.photoPrincipale
              )}), url("/static/images/no-image-box.png")`,
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: 150,
              position: "relative",
            }}
          />
        </Grid>

        <Grid
          item
          xs={6}
          style={{
            backgroundColor: "#f2f2f2",
            height: "150px",
            padding: "10px 10px 10px 15px",
          }}
        >
          <Grid container direction="column">
            <Grid container item xs={12} direction="row" spacing={0}>
              <Typography variant="body2" style={{ color: "#8E8E8E" }}>
                {`${hit.typeAffaireEnum
                  .replace(/([A-Z])/g, " $1")
                  .trim()
                  .toUpperCase()}   ${t(hit.familleBienEnum)}`}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              style={{
                display: "inlineFlex",
                flexWrap: "nowrap",
                marginLeft: "-3px",
                verticalAlign: "middle",
              }}
            >
              <PlaceIcon
                style={{
                  color: "#8E8E8E",
                  fontSize: 16,
                  marginTop: 5,
                }}
              />
              <Typography variant="h4" noWrap style={{ lineHeight: 1.6 }}>
                {hit.villeNom.toUpperCase()}{" "}
                <span
                  style={{
                    fontSize: 10,
                    fontWeight: "normal",
                  }}
                >{` (${hit.villeCodePostal})`}</span>
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <div style={{ display: "flex" }}>
                  <Typography variant="h2" align="left" noWrap>
                    {formatPrix(hit.prixNormalized)}
                  </Typography>
                  {hit.underOfferToSell && (
                    <div style={{ marginLeft: 5 }}>
                      <SousCompromis />
                    </div>
                  )}
                  {hit.hasOffer && (
                    <div style={{ marginLeft: 5 }}>
                      <SousOffre />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="flex-end">
              <Grid item xs={12}>
                <Grid container>
                  {hit.surface > 0 && (
                    <>
                      <Typography variant="h2">{hit.surface}</Typography>
                      <Typography variant="h3">
                        &nbsp;{`m\xB2`}&nbsp;
                        {(hit.nbPieces > 0 || hit.nbChambres > 0) && "|"}
                        &nbsp;
                      </Typography>
                    </>
                  )}
                  {hit.nbPieces > 0 && (
                    <>
                      <Typography variant="h2">{hit.nbPieces}</Typography>
                      <Typography variant="h3">
                        &nbsp;p&nbsp;
                        {hit.nbChambres > 0 && "|"}
                        &nbsp;
                      </Typography>
                    </>
                  )}
                  {hit.nbChambres > 0 && (
                    <>
                      <Typography variant="h2">{hit.nbChambres}</Typography>
                      <Typography variant="h3">&nbsp;ch</Typography>
                    </>
                  )}
                  {hit.surface === 0 &&
                    hit.nbPieces === 0 &&
                    hit.nbChambres === 0 && (
                      <>
                        <Typography variant="h2">&nbsp;</Typography>
                        <Typography variant="h3">&nbsp;</Typography>
                      </>
                    )}
                </Grid>
              </Grid>

              <Grid item xs={8} style={{ paddingTop: 5 }}>
                <div style={{ alignItems: "center", display: "flex" }}>
                  {hit.exclusivite && (
                    <div style={{ marginRight: 5 }}>
                      <Exclusivite />
                    </div>
                  )}
                  {hit.neuf && (
                    <div style={{ marginRight: 5 }}>
                      <Neuf />
                    </div>
                  )}
                  {hit.prestige && (
                    <div style={{ marginRight: 5 }}>
                      <Prestige />
                    </div>
                  )}
                  {hit.isCE && (
                    <div style={{ marginRight: 5 }}>
                      <CommerceEntreprise />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
