import { LocalPhoneOutlined } from "@material-ui/icons";
import { LinksList } from "UI/shared/molecules/LinksList";
import React from "react";
import { useServices } from "services";

interface Phone {
  kind: "Mobile" | "Landline" | "Professional" | "Personal";
  value: string;
}

export interface PhonesListProps {
  value: Phone[];
}
export const PhonesList: React.FC<PhonesListProps> = ({ value }) => {
  const { l10n } = useServices();
  return (
    <LinksList
      icon={LocalPhoneOutlined}
      tooltip={l10n.t("Appeler")}
      value={value.map((phone) => ({
        content: `${phone.value} - ${l10n.t(`PhoneKind.${phone.kind}`)}`,
        href: `tel:${phone.value}`,
      }))}
    />
  );
};
