import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  a: {
    "&:hover": {
      color: theme.palette.tonique,
    },
    "&:visited": {
      color: theme.palette.buttonSelected,
    },
    color: theme.palette.buttonSelected,
    textDecoration: "none",
  },
}));

const IconAnchorAction = (props) => {
  const {
    icon,
    link,
    action,
    tooltip = "rien",
    placement = "right",
    onClick = () => {},
    couleur = null,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  switch (action) {
    case "":
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flex: 1,
            flexWrap: "nowrap",
          }}
        >
          <div
            style={{
              color: couleur ? couleur : theme.palette.tonique,
              marginRight: 5,
              marginTop: 6,
            }}
          >
            {icon}
          </div>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "noWrap",
            }}
          >
            {props.children}
          </div>
        </div>
      );
    case "click":
      return (
        <Tooltip title={tooltip} placement={placement}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flex: 1,
              flexWrap: "nowrap",
              cursor: "pointer",
            }}
            onClick={onClick}
          >
            <div
              style={{
                color: couleur ? couleur : theme.palette.tonique,
                marginRight: 5,
                marginTop: 6,
              }}
            >
              {icon}
            </div>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "noWrap",
              }}
            >
              {props.children}
            </div>
          </div>
        </Tooltip>
      );
    case "link":
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={link}
          className={classes.a}
        >
          <Tooltip title={tooltip} placement={placement}>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flex: 1,
                flexWrap: "nowrap",
              }}
            >
              <div
                style={{
                  color: couleur ? couleur : theme.palette.tonique,
                  marginRight: 5,
                  marginTop: 6,
                }}
              >
                {icon}
              </div>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "noWrap",
                }}
              >
                {props.children}
              </div>
            </div>
          </Tooltip>
        </a>
      );
    default:
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${action}:${link}`}
          className={classes.a}
          onClick={onClick}
        >
          <Tooltip title={tooltip} placement={placement}>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flex: 1,
                flexWrap: "nowrap",
              }}
            >
              <div
                style={{
                  color: couleur ? couleur : theme.palette.tonique,
                  marginRight: 5,
                  marginTop: 6,
                }}
              >
                {icon}
              </div>
              <div
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "noWrap",
                }}
              >
                {props.children}
              </div>
            </div>
          </Tooltip>
        </a>
      );
  }
};

export default IconAnchorAction;
