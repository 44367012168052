import { Button, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CallMade } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";

import { If } from "UI/shared/atoms/directives/If";
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { useAcquirerProfilesMatching } from "services/matching/useAcquirerProfilesMatching";
import { MatchingProfileRequestDto } from "services/matching/MatchingProfileRequestDto";
import { ITheme } from "theme/ts/main";

export const ReadSellerProfileMatchingNumber: React.FC<{
  criteria: MatchingProfileRequestDto | undefined;
  url?: string;
}> = ({ criteria, url }) => {
  const { getCount } = useAcquirerProfilesMatching();
  const history = useHistory();

  const [count, setCount] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!criteria) return;
    getCount(criteria).then((nbResult) => {
      setCount(nbResult);
    });
  }, [criteria]);

  const theme: ITheme = useTheme();

  const open: MouseEventHandler = useCallback(
    (e) => {
      if (!url) return;

      e.stopPropagation();
      e.preventDefault();

      history.push(url);
    },
    [url]
  );

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant={"subtitle1"}
        style={{
          color: theme.palette.common.black,
        }}
      >
        RAPPROCHEMENT AVEC VOS CRITERES&nbsp;:
      </Typography>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginTop: "5px",
        }}
      >
        <If condition={count == null}>
          <CircularProgress
            size={12}
            style={{
              margin: "auto",
            }}
          />
        </If>
        <If condition={count === 0}>
          <Typography variant={"body1"}>Aucune correspondance</Typography>
        </If>
        <If condition={(count ?? 0) > 0}>
          <Button
            style={{ flex: 0.7, width: "300px" }}
            variant="outlined"
            color="primary"
            onClick={open}
          >
            <CallMade style={{ marginRight: "5px" }} />
            {count! > 1 ? `${count} Recherches` : `${count} Recherche`}
          </Button>
        </If>
      </div>
    </div>
  );
};
