/* eslint-disable */
export const en = {
  AnneeEnCours: "Current Year",
  Aout: "Aug",
  Avr: "Apr",
  CaActesHT: "T.O acts without VAT",
  Compromis: "Sale Agreement",
  CaCompromisHT: "T.O. sale agreements without VAT",
  DatesLibre: "Free Dates",
  Dec: "Dec",
  de: "from",
  à: "to",
  au: "to",
  "à partir de": "starting from",
  "jusqu'à": "to",
  "avant le": "before",
  DelaiMoyenDeVente: "Avg. time to sell",
  Fev: "Feb",
  Jan: "Jan",
  Eme: "th",
  Jui: "Jul",
  Canal: "Broadcast channel",
  Juin: "Jun",
  CaTTC: "Sales with VAT in K€",
  Mai: "May",
  Biens: "Real Estate",
  MoisEnCours: "Current Month",
  Actes: "Acts",
  TrouverDesAcquereurs: "Find buyers",
  Classements: "Rankings",
  EnCours: "Current",
  Top20Regional: "District Top 20",
  Contacts: "Contacts",
  Top50National: "National Top 50",
  HistoriqueDesBiensVendus: "sold Real Estate history",
  Top5Departemental: "County Top 5",
  Logout: "Logout",
  Mandats: "Mandates",
  Mar: "Mar",
  MandatsActifs: "Current mandates",
  MandatsPris: "Signed mandates",
  Clics: "Views",
  Oct: "Oct",
  Demandes: "Requests",
  Sept: "Sept",
  Moyenne: "Average",
  Nov: "Nov",
  FamilleBiens: "Real estate category",
  NbClics: "Number of views",
  CaActesTTC: "Acts turnover with VAT",
  PourcentageMandatsElectroniques: "% electronic mandates",
  NbDemandes: "Number of requests",
  PourcentageTransfo: "% Conversion",
  CaCompromisTTC: "Sale agreements with VAT",
  DelaisVente: "Time to sell",
  PourcentageHonorairesMoyens: "% average fees",
  Max: "Max",
  Jours: "Days",
  NbVisites: "Number of clicks",
  Actifs: "Current",
  Synthese: "Summary",
  BiensActifs: "Current real estate",
  Publications: "Publications",
  BiensVendus: "Sold real estate",
  Reporting: "Reporting",
  MAISON: "House",
  RechercheInterAgence: "Inter-agencies search",
  APPARTEMENT: "Appartment",
  LOCAL_BUREAU: "Local/Office",
  ToolboxDataView: "Data",
  IMMEUBLE: "Building",
  ToolboxRestore: "Restore",
  FONDS_COMMERCE: "Commercial property",
  ToolboxSaveAsImage: "Download",
  Mademoiselle: "Ms.",
  ConnecteEnTantQue: "Connected as",
  NombreActes: "Number of acts",
  ChoisirClient: "Select client...",
  // key format exception
  Visites: "Clicks",
  ChoisirPasserelle: "Select gateway...",
  ErreurRecuperationFiltres: "Unable to get filters",
  OptionMandat: "Agreement category",
  ErreurRecuperationDonnees: "Unable to get data",
  OrigineAffaire: "Source of business",
  AucunResultats: "No results",
  AucuneDonneesAfficher: "No data to display",
  NombreMandats: "Number of mandates",
  ChoisirClientPasserelle: "Please choose client / gateway",
  Min: "Min",
  EssayezRafraichir: "Try to refresh the page",
  NombreBiens: "Number of real estate",
  Bu: "Network",
  PourcentageExclu: "% Exclusives",
  EffacerFiltres: "Clean filters",
  NbChambres: "Room count",
  Vendus: "Sold",
  LienAnnonce: "Ad link",
  Conseiller: "Advisor",
  PROPRIETE: "Property",
  Email: "Email",
  PrixVenteMoyen: "Average sale price",
  Mandat: "Mandate",
  TERRAIN: "Land",
  Exclusif: "Exclusive",
  Commune: "Township",
  VoirLaFicheContact: "Contact details",
  NbPieces: "Room count",
  SurfaceMoyenne: "Average surface",
  Chambre: "Bedroom",
  PrixMoyenM2: "Average price per sqm",
  Dont: "Whose",
  AnneeDeConstruction: "Year of contruction",
  NouvelleTentative: "Retry again",
  Diagnostique: "Diagnotic",
  RecuperationFiltres: "Fetching filters",
  DetailsAnnonce: "Ad details",
  Au: "To",
  Pour: "for",
  AutresOptionsContact: "Others options",
  PrixMax: "Max price",
  Du: "From",
  "depuis le": "starting from",
  du: "from",
  PrixMin: "Min price",
  Appeler: "Call",
  Recherche: "Search",
  EffacerContact: "Delete this contact",
  RechercheGlobale: "Quick search (real estates, agents, contacts, ...)",
  EnvoyerEmail: "Send email",
  Rechercher: "Search",
  AdressePresenteDansContacts:
    "This email address is already present in your contacts",
  Resultat: "Result",
  AdressePresentePourContact:
    "This email address is already present for another contact",
  TypeAchat: "Type of purchase",
  ExporterVersTremplin: "Export to Tremplin",
  ATraiter: "Treat",
  TelPro: "Pro. phone",
  Acquereur: "Buyer",
  TypeDeTransaction: "Transaction type",
  FicheContact: "Contact form",
  Voir: "See",
  Civilite: "Civility",
  Simple: "Simple",
  FormatInvalide: "Invalid format",
  Surface: "Surface",
  Imprimer: "Print",
  Oui: "Yes",
  Adresse: "Address",
  Prestige: "Prestige",
  CodePostal: "Zip code",
  Non: "No",
  EmailsExistantsDansRepertoire: "Existing mails in your address book",
  SurfaceTerrain: "Ground surface",
  AccepteEtreSollicite: "Accepts to be solicited",
  Madame: "Mrs",
  Commentaire: "Comment",
  ModifierContact: "Edit contact",
  ProfilsActifs: "Active Profiles",
  ProfilsArchives: "Archived Profiles",
  ReactivationProfil:
    "By reactivating this profile, the associated contact will also be reactivated.",
  ArchiverProfil: "Archive profile",
  ActiverProfil: "Activate profile",
  Archive: "Archived",
  ModifierProfil: "Edit profile",
  Enregistrer: "Save",
  Monsieur: "Mr",
  FiltrerPar: "Filter by",
  Nom: "Name",
  Aucun: "None",
  NumeroPresentDansContact:
    "This phone number is already present in your contacts",
  AucunContactTrouve: "No contact found",
  Obligatoire: "Needed",
  ClassementDepartemental: "Departmental ranking",
  ClassementNational: "National ranking",
  NumeroPresentPourContact:
    "This phone number is already present for another contact",
  ClassementRegional: "Regional ranking",
  Particulier: "Individual",
  Departement: "Department",
  TypeAffaire: "Type of business",
  HonoraireMoyen: "Average honorary",
  Client: "Client",
  Vendeur: "Seller",
  FormatDateLocal: "EN-en",
  Professionnel: "Professionnal",
  Annee: "Year",
  // Classement: "Classement",
  NumerosExistantsDansRepertoire: "Phone num. existing in your phonebook",
  DateDArret: "End date",
  Prenom: "Nickname",
  DateDeDepart: "Start date",
  Telephone: "Phone",
  FamilleBien: "Real Estate category",
  BU: "Network",
  Pays: "Country",
  AlgolianomVilleCP: "Location",
  Ville: "City",
  FiltrerParPeriode: "Filter by period",
  MesContacts: "My contacts",
  MandatExclusifs: "Exclusive mandate(s)",
  NAcceptePasEtreSollicite: "Does not accept to be sollicited",
  MandatsExcluPris: "Exclu. signed mandates",
  NbChambresRange: "Nb. of bedrooms",
  TrierPar: "Sort by",
  Chambres: "Room(s)",
  NbPiecesRange: "Nb. of rooms",
  Achat: "Acquisition",
  NomVilleCp: "Location",
  MiseEnLocation: "Proposing a rental",
  NombreCompromis: "Number of compromises",
  None: "None",
  StockCompromisEnCours: "Current compromise stock",
  CessionDroitAuBail: "Disposal of leasehold rights",
  TauxDeTransformation: "Conversion rate",
  DateCreation: "Date of creation",
  DateModification: "Date of modification",
  Region: "Region",
  IsNeuf: "Type of purchase",
  Passerelle: "Gateway",
  Accepted: "Accepted",
  PlusDeFiltres: "More filters",
  Declined: "Declined",
  RechercheLocation: "Looking for a rental",
  "En Attente": "Still awaiting response",
  SurfaceHabitableRange: "Surface",
  FondsCommerce: "Commercial Property",
  familleBienNom: "Real Estate category",
  Invitations: "Invitations",
  isNeuf: "Type of purchase",
  LocalBureau: "Local / Office",
  nbChambresRange: "Nb. of bedrooms",
  PlusDeCriteres: "More filters",
  nbPiecesRange: "Nb. of rooms",
  Propriete: "Property",
  surfaceHabitableRange: "Surface",
  Reseau: "Network",
  reseau: "Network",
  TentativelyAccepted: "Tentatively accepted",
  typeAffaire: "Type of business",
  Vente: "Sale",
  VenteEnViager: "Life annuity sale",
  false: "No",
  true: "Yes",
  AppelTelephonique: "Phone call",
  EnvoiMail: "Send email",
  RendezVous: "Appointment",
  EnvoiSms: "Send SMS",
  EnvoiCourrier: "Send mail",
  Autre: "Other way to get in touch",
  TremplinXError: "An error has occurred.",
  Office365Error: "An error occurred, the outlook event was not recorded.",
  FavorisError:
    "An error has occurred, the ad has not been added to the favourites.",
  ModifierNotes: "Edit your notes",
  profileExportStatus: {
    labels: {
      Capifrance: {
        Done: "Open iBoost business",
        Failed: "Export failure",
        None: "Export to iBoost business",
        InProgress: "Export in progress",
        Requested: "Export in progress",
      },
      Optimhome: {
        Done: "Open ETO business",
        Failed: "Export failure",
        None: "Export to ETO business",
        InProgress: "Export in progress",
        Requested: "Export in progress",
      },
    },
    subLabels: {
      Capifrance: {
        Done: "for mandate taking",
        Failed: "Retry",
        None: "for mandate taking",
        InProgress: "Waiting for iBoost answer",
        Requested: "Waiting for iBoost answer",
      },
      Optimhome: {
        Done: "for mandate taking",
        Failed: "Retry",
        None: "for mandate taking",
        InProgress: "Waiting for ETO answer",
        Requested: "Waiting for ETO answer",
      },
    },
  },
  SaisirNotes: "Enter your note here...",
  SupprimerNotes: "Delete your notes",
  ValiderNotes: "Validate your notes",
  "Notifications.PigeFavoritePriceReduction.Title": "Price reduction",
  "Notifications.PigeFavoritePriceReduction.Description":
    "Be notified when an ad in your favorites undergoes a price drop.",
  "Notifications.PigeFavoriteDeactivation.Title": "Disabled ad",
  "Notifications.PigeFavoriteDeactivation.Description":
    "Be notified when a favorite ad is deactivated.",
  "Notifications.PigeNewIndividualAdOnWorkingArea.Title":
    "New Ad from a private individual",
  "Notifications.PigeNewIndividualAdOnWorkingArea.Description":
    "Be notified when a new private individual ad is published in my work area.",
  "Notifications.PigeNewProfessionalAdOnWorkingArea.Title":
    "New Ad from a professional",
  "Notifications.PigeNewProfessionalAdOnWorkingArea.Description":
    "Be notified when a new pro ad is published in my work area.",
  ToBeTreated: "To be processed",
  "RequestForInformations.Criteria.source": "Source",
  "RequestForInformations.Criteria.status": "Status",
  "RequestForInformations.Criteria.adReference": "Ad Reference",
  "RequestForInformations.Criteria.contact": "Contact",
  "RequestForInformations.Criteria.receivedAt": "Reception",
  "RequestForInformations.EntityName.singular": "Request for informations",
  "RequestForInformations.EntityName.plural": "Requests for informations",
  parkingKind: {
    Box: "Box",
    ExteriorParking: "Outside parking",
    InteriorParking: "Inside parking",
    Unknown: "All",
  },
  amenities: {
    Power: "Power",
    Water: "Water supply",
    Light: "Light",
    MotorizedGate: "Motorized gate",
    ManualGate: "Manual gate",
    ParkingBarrier: "Parking barrier",
    GroundSecurityLock: "Ground security lock",
    UndergroundLocation: "Underground location",
  },
  parking: {
    RentedParking: "Rented parking",
  },
  InteriorParking: "Inside parking",
  ExteriorParking: "Outside parking",
  Box: "Box",
  BuildingToCut: "Building to cut",
  OfficeBuilding: "Office building",
  InvestmentBuilding: "Investment building",
  buildingForSaleInfoKind: {
    All: "Any",
    Building: "Building",
    BuildingToCut: "Building to cut",
    OfficeBuilding: "Office building",
    InvestmentBuilding: "Investment building",
  },
  forSaleBuilding: {
    propertyInfo: {
      jointOwnership: "Immeuble en copropriété",
      ongoingProcedure: "Procédure en cours",
    },
    lotsType: {
      Apartment: "Apartment",
      Shop: "Shop",
      CommercialPremises: "Commercial premises",
      IndustrialPremises: "Industrial premises",
      ProfessionalPremises: "Professional premises",
    },
    RentedLots: "Rented lot(s)",
  },
  With: "Furnished",
  Without: "Unfurnished",
  Target: "Target",
  PhoneCall: "Call",
  SendSms: "SMS",
  SendEmail: "Email",
  SendMail: "Mail",
  Appointment: "Appointment",
  Other: "Other",
};
