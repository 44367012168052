import React, { FC } from "react";

import { AutoCompleteRefinement } from "./AutoCompleteRefinement";
import { CheckboxRefinement } from "./CheckboxRefinement";
import { CityRefinement } from "./CitiesRefinement";
import { DateRangeOptionsRefinement } from "./DateRangeOptionsRefinement";
import { DateRangeRefinement } from "./DateRangeRefinement";
import { LocationRefinement } from "./LocationsRefinement";
import { TypeGeoEnum } from "./LocationsRefinement/toSelectOptions";
import { MultipleTextRefinement } from "./MultipleTextRefinement";
import { NumberRangeRefinement } from "./NumberRangeRefinement";
import { NumberRefinement } from "./NumberRefinement";
import { PhoneNumberRefinement } from "./PhoneNumberRefinement";
import { RadioRefinement } from "./RadioRefinement";
import { SingleTextRefinement } from "./SingleTextRefinement";
import { Refinement } from "./types";

export interface RefinementFieldProps {
  value: Refinement;
  onChange(value: Refinement): void;
  label: string;
  autoComplete?: {
    getOptions: (val: string) => Promise<string[]>;
  };
  options?: {
    excludedLocationTypes: TypeGeoEnum[];
  };
}

const defaultAutoComplete = {
  getOptions: (s: string) =>
    new Promise<string[]>((r) => {
      r([]);
    }),
};

export const RefinementField: FC<RefinementFieldProps> = ({
  label,
  onChange,
  value,
  autoComplete,
  options,
}) => {
  switch (value.kind) {
    case "cities":
      return <CityRefinement onChange={onChange} label={label} value={value} />;
    case "locations":
      return (
        <LocationRefinement
          onChange={onChange}
          label={label}
          value={value}
          excludedTypes={options?.excludedLocationTypes}
        />
      );
    case "single-text":
      return (
        <SingleTextRefinement onChange={onChange} label={label} value={value} />
      );
    case "multiple-text":
      return (
        <MultipleTextRefinement
          onChange={onChange}
          label={label}
          value={value}
        />
      );
    case "price":
      return (
        <NumberRefinement onChange={onChange} label={label} value={value} />
      );
    case "number-range":
      return (
        <NumberRangeRefinement
          onChange={onChange}
          label={label}
          value={value}
        />
      );
    case "date-range":
      return (
        <DateRangeRefinement onChange={onChange} label={label} value={value} />
      );
    case "date-range-options":
      return (
        <DateRangeOptionsRefinement
          onChange={onChange}
          label={label}
          value={value}
        />
      );
    case "checkbox":
      return (
        <CheckboxRefinement onChange={onChange} label={label} value={value} />
      );
    case "autocomplete-text":
      return (
        <AutoCompleteRefinement
          onChange={onChange}
          label={label}
          getOptions={
            autoComplete?.getOptions || defaultAutoComplete.getOptions
          }
        />
      );
    case "phone-number":
      return (
        <PhoneNumberRefinement
          onChange={onChange}
          label={label}
          value={value}
        />
      );
    case "radio":
      return (
        <RadioRefinement onChange={onChange} label={label} value={value} />
      );
    default:
      return <></>;
  }
};
