import useHTTPService from "services/dataSources/communicationServices/useHTTPService";

import { useServices } from "../index";
import { historiquePrixBienPigeAdapter } from "./adapters/historiquePrixBienPigeAdapter";

interface useHistoriquePrixBienPigeProps {
  params: { id: string };
  erreur: boolean;
}

export const useHistoriquePrixBienPige = ({
  params,
  erreur = false,
}: useHistoriquePrixBienPigeProps) => {
  const { endpoints } = useServices();

  const { status, adaptedData } = useHTTPService({
    adapter: historiquePrixBienPigeAdapter,
    endpoint: endpoints.pige.historiquePrix(params.id),
    params: null,
  });
  return { items: adaptedData, status: status };
};
