import { makeStyles, RadioGroup } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import RemoveIcon from "@material-ui/icons/Remove";
import { SelectOption } from "UI/shared/atoms/SelectOptions";
import React, { ChangeEvent, FC } from "react";
import { ConsentStatus } from "types";

export const ConsentSelect: FC<{
  status: ConsentStatus;
  disabled: boolean;
  onChange: (value: string) => void;
}> = ({ status, onChange, disabled }) => {
  const classes = makeStyles(() => ({
    selected: { color: "white" },
  }))();

  const options = [
    {
      checkedIcon: <CheckIcon className={classes.selected} />,
      icon: <CheckIcon />,
      label: (
        <span>
          Accepte <br></br>d&apos;être sollicité(e)
        </span>
      ),
      value: "Approved",
    },
    {
      checkedIcon: <RemoveIcon className={classes.selected} />,
      icon: <RemoveIcon />,
      label: (
        <span>
          N&apos;accepte pas <br></br>d&apos;être sollicité(e)
        </span>
      ),
      value: "Denied",
    },
  ];

  return (
    <RadioGroup
      row
      name="consentStatus"
      aria-label="Consent"
      value={status}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
    >
      {options.map(({ label, value, icon, checkedIcon }) => (
        <SelectOption
          disabled={disabled}
          key={value}
          icon={icon}
          checkedIcon={checkedIcon}
          value={value}
          label={label}
          itemStyle={{ margin: "5px", textAlign: "center" }}
        />
      ))}
    </RadioGroup>
  );
};
