import { RefinementField } from "components/RefinementField";
import {
  AdaptedRefinements,
  Refinement,
} from "components/RefinementField/types";
import React from "react";
import { useServices } from "services";
import { ContactsCriteria } from "services/portfolio/types";
import { MoreFiltersCategory } from "types";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";

export interface MoreFiltersSectionProps {
  adaptedRefinements: AdaptedRefinements<ContactsCriteria>;
  sectionKey: MoreFiltersCategory;
  onChange: (value: { key: ContactsCriteria; value: Refinement }) => void;
}

const getRefinementsSection = (
  key: MoreFiltersCategory,
  adaptedRefinements: AdaptedRefinements<ContactsCriteria>
) => {
  return (Object.keys(adaptedRefinements) as ContactsCriteria[]).filter(
    (k) => adaptedRefinements[k].moreFiltersCategory === key
  );
};

const getL10nCriteriaKey = (key: ContactsCriteria) =>
  `Portfolio.Criteria.${key}`;

export const MoreFiltersSection: React.FC<MoreFiltersSectionProps> = ({
  adaptedRefinements,
  sectionKey,
  onChange,
}) => {
  const {
    l10n: { t },
  } = useServices();

  return (
    <>
      {getRefinementsSection(sectionKey, adaptedRefinements).map(
        (key: ContactsCriteria) => {
          return (
            <PaddingContainer bottom={10} key={`more-filters-${key}`}>
              <RefinementField
                onChange={(v) => onChange({ key, value: v })}
                value={adaptedRefinements[key].refinement}
                label={t(getL10nCriteriaKey(key))}
              />
            </PaddingContainer>
          );
        }
      )}
    </>
  );
};
