import { CheckboxItem } from "UI/shared/molecules/CheckboxList";
import { SortOption } from "UI/shared/molecules/SortInput";
import { AdaptedRefinements } from "components/RefinementField/types";
import { useCallback, useEffect, useState } from "react";
import {
  Aggregations,
  ContactsCriteria,
  ReadPortfolioDto,
} from "services/portfolio/types";
import {
  PortforlioSearchParams,
  usePortfolioSearch,
} from "services/portfolio/usePortfolioSearch";

export const useExportSearch = (
  sortOption: SortOption,
  refinements: AdaptedRefinements<ContactsCriteria>
) => {
  const MAX_EXPORT_ROW = 10000;
  const [contacts, setContacts] = useState<ReadPortfolioDto>({
    aggregations: {} as Aggregations,
    documents: [],
    hasMore: false,
    pageNumber: 0,
    pageSize: 0,
    total: 0,
    totalPage: 1,
  });
  const [params, setParams] = useState<PortforlioSearchParams>({
    page: 1,
    size: 20,
    sortAsc: true,
    sortColumn: "updateDate",
    withArchivedContacts: false,
  });

  const { search } = usePortfolioSearch();
  const loadMore = useCallback(() => {
    if (contacts.hasMore) {
      setParams((prevParams) => ({
        ...prevParams,
        page: prevParams.page + 1,
      }));
    }
  }, [contacts.hasMore]);

  const loadData = useCallback(() => {
    search(refinements, sortOption, params.page).then((response) => {
      setContacts((prevContacts) => {
        const documents =
          params.page === 1
            ? response.result.documents
            : [...prevContacts.documents, ...response.result.documents];
        return { ...response.result, documents: documents };
      });
    });
  }, [sortOption, refinements, params, search]);

  const getAll = useCallback(() => {
    return search(refinements, sortOption, 1, MAX_EXPORT_ROW).then(
      (response) => {
        return Promise.resolve(
          response.result.documents.map((contact) => {
            return {
              id: contact.id,
              label: `${contact.identity.firstName || ""} ${
                contact.identity.lastName || ""
              }`,
            } as CheckboxItem;
          })
        );
      }
    );
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData, params, refinements]);

  return {
    contacts,
    getAll,
    loadMore,
  };
};
