import { Grid } from "@material-ui/core";
import MapBienUnique from "components/MapX/MapBienUnique";
import React from "react";
import { breakpoint } from "theme/main";

const Localisation = (props) => {
  const {
    bien,
    mode = "précis",
    etiquetteCarte = <></>,
    isMapFullScreen = false,
    renderMapCloseButton,
    polygon,
  } = props;
  const localisation = {
    bu: bien.bu,
    codePostal: bien.localisation.codePostal,
    guid: bien.id,
    latitude: bien.lat || bien.latitude,
    longitude: bien.long || bien.longitude,
    villeNom: bien.localisation.villeNom,
  };

  const getAddress = () => {
    if (bien.localisation.rue) {
      return `${bien.localisation.numeroRue} ${bien.localisation.rue}, ${bien.localisation.codePostal} ${bien.localisation.villeNom}`;
    }

    return undefined;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div style={{ position: "relative" }}>
          <MapBienUnique
            renderCloseButton={renderMapCloseButton}
            isFullScreen={isMapFullScreen}
            latitude={localisation.latitude}
            longitude={localisation.longitude}
            address={getAddress()}
            id="localisation"
            mode={mode}
            height={
              breakpoint().smAndDown
                ? window.innerWidth
                : window.innerHeight - 260
            }
            etiquetteCarte={etiquetteCarte}
            bu={bien.bu}
            polygon={polygon}
            popupContent={bien.localisation.adresse || ""}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Localisation;
