import { Theme, useMediaQuery } from "@material-ui/core";
import React from "react";
import { NoNetworkRealEstates } from "UI/features/profiles/molecules/NoNetworkRealEstates";
import { LoaderContainer } from "UI/shared/atoms/containers/LoaderContainer";
import { If } from "UI/shared/atoms/directives/If";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import { Text12px } from "UI/shared/atoms/typographies/Text12px";
import { Text14pxBold } from "UI/shared/atoms/typographies/Text14pxBold";
import { realEstateAdapter } from "../adapter";
import { RealEstatesContext, RealEstatesProps } from "../types";
import { NetworkRealEstatesLarge } from "./index.large";
import { NetworkRealEstatesSmall } from "./index.small";

export const NetworkRealEstatesContext = React.createContext<
  RealEstatesContext
>({
  loadMore: () => {
    return;
  },
  openDetails: () => {
    return;
  },
  realEstates: [],
});

export const NetworkRealEstates: React.FC<RealEstatesProps> = ({
  total,
  loadMore,
  loading,
  realEstates,
  open,
}) => {
  const { desktop, mobile } = {
    desktop: useMediaQuery((theme: Theme) => theme.breakpoints.up("md")),
    mobile: useMediaQuery((theme: Theme) => theme.breakpoints.down("sm")),
  };

  const adaptedRealEstates = realEstates.map((realEstate) =>
    realEstateAdapter({
      realEstate,
      clickHandler: () => {
        open(realEstate.id);
      },
      withAgent: true,
    })
  );

  return (
    <div style={{ width: "100%" }}>
      <Text14pxBold>LES BIENS DU RÉSEAU</Text14pxBold>
      <VerticalSpace size={8} />
      <LoaderContainer loading={loading}>
        <Text12px>{total} résultat(s)</Text12px>
        <VerticalSpace size={5} />

        <If condition={realEstates.length === 0}>
          <NoNetworkRealEstates />
        </If>

        <If condition={realEstates.length > 0}>
          <NetworkRealEstatesContext.Provider
            value={{
              loadMore,
              realEstates: adaptedRealEstates,
              openDetails: open,
            }}
          >
            <If condition={mobile}>
              <NetworkRealEstatesSmall />
            </If>
            <If condition={desktop}>
              <NetworkRealEstatesLarge />
            </If>
          </NetworkRealEstatesContext.Provider>
        </If>
      </LoaderContainer>
    </div>
  );
};
