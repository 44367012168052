import { CircularProgress, InputAdornment } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React, { FC, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useDebounce } from "react-use";

import { onlySpaces } from "../../../utils/onlySpaces";
import { PhoneNumber } from "../types";

interface PhoneNumberProps {
  value: PhoneNumber;
  onChange: (refinement: PhoneNumber) => void;
  label: string;
}
export const PhoneNumberRefinement: FC<PhoneNumberProps> = (props) => {
  const FRENCH_NUMBER_MASK = "+33 9 99 99 99 99";
  const MINIMUM_PHONE_LENGTH = 10;
  const [val, setVal] = useState<string>();
  const [debouncedValue, setDebouncedValue] = useState<string>();

  const cleanPhoneNumber = () => {
    return val?.toString().replaceAll("_", "").trimEnd();
  };

  const onChange = () => {
    const cleanedNumber = cleanPhoneNumber();
    if (cleanedNumber && cleanedNumber.length >= MINIMUM_PHONE_LENGTH) {
      setDebouncedValue(val);
      props.onChange({
        kind: "phone-number",
        value: val ? cleanedNumber : undefined,
      });
    }
  };
  useEffect(() => {
    const s = props.value.value;
    setVal(s);
    setDebouncedValue(s);
  }, [props.value.value]);

  const [, cancel] = useDebounce(onChange, 500, [val]);

  return (
    <InputMask
      mask={FRENCH_NUMBER_MASK}
      maskPlaceholder={" "}
      value={val}
      onChange={(evt) => {
        if (onlySpaces(evt.target.value)) {
          return;
        }
        setVal(evt.target.value);
      }}
      onBlur={() => {
        cancel();
        onChange();
      }}
    >
      {() => (
        <TextField
          fullWidth
          label={props.label}
          variant={"outlined"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {val !== debouncedValue ? <CircularProgress size={20} /> : ""}
              </InputAdornment>
            ),
          }}
        />
      )}
    </InputMask>
  );
};
