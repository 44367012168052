import React from "react";
import { SmallPrintablePropertyCard } from "./SmallPrintablePropertyCard";
import { smallPrintablePropertyCardAdapter } from "./smallPrintablePropertyCardAdapter";

interface SmallPrintableRealtyCardAdaptedProps {
  bien: any;
}

export const SmallPrintablePropertyCardAdapted = ({
  bien,
}: SmallPrintableRealtyCardAdaptedProps) => {
  return (
    <SmallPrintablePropertyCard hit={smallPrintablePropertyCardAdapter(bien)} />
  );
};
