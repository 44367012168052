import { useCallback, useEffect, useState } from "react";
import { useServices } from "services";
import {
  AdaptedRefinements,
  Refinement,
} from "../../components/RefinementField/types";
import { RequestForInformationsCriteria } from "../../types";
import { PagedResultDto } from "./dto/PagedResultDto";
import { RequestForInformationDto } from "./dto/RequestForInformationDto";
import { clearRefinements } from "./utils/clearRefinements";
import { updateRefinementsFromAggregations } from "./utils/updateRefinementsFromAggregations";
import { initialState } from "./constants/initialState";
import { updateRefinementsState } from "./utils/updateRefinementsState";
import { getStoredState } from "./utils/getStoredState";
import { getRefinements } from "./utils/getRefinements";

const getInitialState = () => getStoredState() || initialState;

export const useAdaptedRefinements = (
  pagedResult: PagedResultDto<RequestForInformationDto>
) => {
  const {
    l10n: { t },
  } = useServices();

  const [adaptedRefinements, setAdaptedRefinements] = useState<
    AdaptedRefinements<RequestForInformationsCriteria>
  >(getInitialState);

  const onChange = useCallback(
    ({
      key,
      value,
    }: {
      key: RequestForInformationsCriteria;
      value: Refinement;
    }) => {
      const nextState = updateRefinementsState(adaptedRefinements, {
        key,
        value,
      }) as AdaptedRefinements<RequestForInformationsCriteria>;

      if (JSON.stringify(nextState) === JSON.stringify(adaptedRefinements))
        return;

      setAdaptedRefinements(nextState);
    },
    [adaptedRefinements]
  );

  const onFullChange = useCallback(
    (next: AdaptedRefinements<RequestForInformationsCriteria> | null) => {
      if (!next) return;
      setAdaptedRefinements((prev) => ({ ...next }));
    },
    [adaptedRefinements]
  );

  const onClear = useCallback(() => {
    setAdaptedRefinements((prev) => clearRefinements(prev));
  }, [adaptedRefinements]);

  useEffect(() => {
    if (!pagedResult) return;
    if (pagedResult.currentPageNumber === -1) return;

    const nextState = updateRefinementsFromAggregations(
      adaptedRefinements,
      pagedResult.aggregations,
      t
    );

    if (JSON.stringify(nextState) === JSON.stringify(adaptedRefinements))
      return;

    setAdaptedRefinements(nextState);
  }, [pagedResult.aggregations]);

  return {
    adaptedRefinements,
    refinements: getRefinements(adaptedRefinements),
    onChange,
    onFullChange,
    onClear,
  };
};
