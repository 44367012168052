import { EndpointsProvider } from "./interfaces/EndpointsProvider";

export interface PublicationEndpoints {
  stat: string;
  client: string;
  passerelle: string;
}

const endpoints: EndpointsProvider<PublicationEndpoints> = (microServices) => ({
  client: `${microServices.tx("reporting")}/v1/Publication/GetClients`,
  passerelle: `${microServices.tx("reporting")}/v1/Publication/GetPasserelles`,
  stat: `${microServices.tx("reporting")}/v1/Publication/GetStatistiques`,
});

export default endpoints;
