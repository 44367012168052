import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./react-responsive-carousel-override.css";

import { Carousel } from "react-responsive-carousel";
import React from "react";

const ResponsiveCarousel = ({
  children,
  onPictureChange = () => null,
  showIndicators = false,
  autoPlay = false,
  intervalAutoPlay = 4000,
  showArrows = true,
  showStatus = false,
  showThumbs = false,
  infiniteLoop = false,
  selectedItem = null,
  containerClass,
}) => (
  <div className={containerClass}>
    <Carousel
      interval={intervalAutoPlay}
      autoPlay={autoPlay}
      showStatus={showStatus}
      showArrows={showArrows}
      selectedItem={selectedItem}
      onChange={onPictureChange}
      showIndicators={showIndicators}
      showThumbs={showThumbs}
      // TODO: investigate if this can be settled without a check on showStatus state
      // statusFormatter={hasFormatter ? formatter : noop}
      infiniteLoop={infiniteLoop}
      className="carousel-container-override"
    >
      {children}
    </Carousel>
  </div>
);

export default ResponsiveCarousel;
