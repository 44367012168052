import React from "react";
// import PropType from 'prop-types'
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  searchBar: {
    backgroundColor: "transparent",
    marginBottom: 6,
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(2),
  },
}));

export default function SearchbarX(props) {
  const classes = useStyles();
  return <div className={classes.searchBar}>{props.children}</div>;
}

// SearchbarX.propTypes = {};
