import { Grid } from "@material-ui/core";
import React, { FC, useState } from "react";

import { SummariesDto } from "../../models/summariesDto";
import { ValidationNumberField } from "../components/ValidationNumberField";
import { ProfileInputProps } from "../InputProps";
import { SelectKitchenType } from "./SelectKitchenType";

interface SaleSummariesValidation {
  bedrooms: boolean;
  bathRooms: boolean;
  levels: boolean;
  toilets: boolean;
  livingRoomSquareMeter: boolean;
}

const getGlobalValidation = (globalValidation: SaleSummariesValidation) =>
  globalValidation.bedrooms &&
  globalValidation.bathRooms &&
  globalValidation.levels &&
  globalValidation.toilets &&
  globalValidation.livingRoomSquareMeter;

export const SaleSummariesInput: FC<ProfileInputProps<SummariesDto>> = ({
  value,
  onChange,
  pristine,
  disabled = false,
  more,
}) => {
  const [globalValidation, setGlobalValidation] = useState({
    bathRooms: true,
    bedrooms: true,
    levels: true,
    livingRoomSquareMeter: true,
    toilets: true,
  });

  return (
    <>
      {more && (
        <>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <ValidationNumberField
                disabled={disabled}
                pristine={pristine}
                label="Surface séjour"
                onChange={(isValid, livingRoom) => {
                  const _globalValidation = {
                    ...globalValidation,
                    livingRoomSquareMeter: isValid,
                  };
                  setGlobalValidation(_globalValidation);
                  onChange(
                    { ...value, livingRoom: { squareMeter: livingRoom || 0 } },
                    getGlobalValidation(_globalValidation)
                  );
                }}
                value={value.livingRoom?.squareMeter}
                adornement={"m²"}
                maxLimit={1000}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ValidationNumberField
                    disabled={disabled}
                    pristine={pristine}
                    label="Nombre de chambres"
                    onChange={(isValid, bedRoomsCount) => {
                      const _globalValidation = {
                        ...globalValidation,
                        bedrooms: isValid,
                      };
                      setGlobalValidation(_globalValidation);
                      onChange(
                        { ...value, bedRooms: { count: bedRoomsCount || 0 } },
                        getGlobalValidation(_globalValidation)
                      );
                    }}
                    value={value.bedRooms?.count}
                    maxLimit={1000}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ValidationNumberField
                    disabled={disabled}
                    pristine={pristine}
                    label="Nombre de niveaux"
                    onChange={(isValid, levelsCount) => {
                      const _globalValidation = {
                        ...globalValidation,
                        levels: isValid,
                      };
                      setGlobalValidation(_globalValidation);
                      onChange(
                        { ...value, levels: { count: levelsCount || 0 } },
                        getGlobalValidation(_globalValidation)
                      );
                    }}
                    value={value.levels?.count}
                    maxLimit={1000}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ValidationNumberField
                    disabled={disabled}
                    pristine={pristine}
                    label="Nombre de salles de bain/d'eau"
                    onChange={(isValid, bathRoomsCount) => {
                      const _globalValidation = {
                        ...globalValidation,
                        bathRooms: isValid,
                      };
                      setGlobalValidation(_globalValidation);
                      onChange(
                        { ...value, bathRooms: { count: bathRoomsCount || 0 } },
                        getGlobalValidation(_globalValidation)
                      );
                    }}
                    value={value.bathRooms?.count}
                    maxLimit={100}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ValidationNumberField
                    disabled={disabled}
                    pristine={pristine}
                    label="Nombre de wc séparés"
                    onChange={(isValid, toiletsCount) => {
                      const _globalValidation = {
                        ...globalValidation,
                        toilets: isValid,
                      };
                      setGlobalValidation(_globalValidation);
                      onChange(
                        { ...value, toilets: { count: toiletsCount || 0 } },
                        getGlobalValidation(_globalValidation)
                      );
                    }}
                    value={value.toilets?.count}
                    maxLimit={100}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <SelectKitchenType
            value={value.kitchen?.type}
            onChange={(kitchenType) => {
              onChange({ ...value, kitchen: { type: kitchenType } }, true);
            }}
            disabled={false}
          />
        </>
      )}
    </>
  );
};
