import { Grid, RadioGroup } from "@material-ui/core";
import {
  Apartment,
  ApartmentOutlined,
  Business,
  BusinessOutlined,
  DirectionsCar,
  DirectionsCarOutlined,
  House,
  HouseOutlined,
  LocalFlorist,
  LocalFloristOutlined,
  LocalOffer,
  LocalOfferOutlined,
  Loyalty,
  LoyaltyOutlined,
  Store,
  Storefront,
  StorefrontOutlined,
  StoreOutlined,
  VpnKey,
  VpnKeyOutlined,
} from "@material-ui/icons";
import { SelectOption, SelectOptionProps } from "UI/shared/atoms/SelectOptions";
import { first } from "lodash";
import React, { FC, useState } from "react";
import {
  PurchaseApartmentProfileReadDto,
  PurchaseBuildingProfileReadDto,
  PurchaseGoodWillProfileReadDto,
  PurchaseHouseProfileReadDto,
  PurchaseLandProfileReadDto,
  PurchaseParkingProfileReadDto,
  PurchasePremisesProfileReadDto,
  RentApartmentProfileReadDto,
  RentHouseProfileReadDto,
  RentLandProfileReadDto,
  RentParkingProfileReadDto,
  RentPremisesProfileReadDto,
  SearchLifeAnnuityApartmentProfileReadDto,
  SearchLifeAnnuityBuildingProfileReadDto,
  SearchLifeAnnuityHouseProfileReadDto,
  SearchLifeAnnuityLandProfileReadDto,
  SearchLifeAnnuityPremisesProfileReadDto,
} from "routes/app/contacts/contact/components/models/Search/searchProfilesReadDtoModels";

import { useServices } from "../../../../../../services";
import { AccountType } from "../models/accountType";
import { ProfileKind, ProfileReadDto } from "../models/profilesReadDto";
import { BusinessType, SelectAccountType } from "./AccountChip";
import { SectionTitle, SubSectionTitle } from "./components/SectionTitle";
import { SearchLifeAnnuityApartmentProfileWrite } from "./Search/LifeAnnuity/Apartment/Write";
import { SearchLifeAnnuityBuildingProfileWrite } from "./Search/LifeAnnuity/Building/Write";
import { SearchLifeAnnuityHouseProfileWrite } from "./Search/LifeAnnuity/House/Write";
import { SearchLifeAnnuityLandProfileWrite } from "./Search/LifeAnnuity/Land/Write";
import { SearchLifeAnnuityPremisesProfileWrite } from "./Search/LifeAnnuity/Premises/Write";
import { SearchPurchaseApartmentProfileWrite } from "./Search/Purchase/Apartment/Write";
import { SearchPurchaseBuildingProfileWrite } from "./Search/Purchase/Building/Write";
import { SearchPurchaseGoodWillProfileWrite } from "./Search/Purchase/Goodwill/Write";
import { SearchPurchaseHouseProfileWrite } from "./Search/Purchase/House/Write";
import { SearchPurchaseLandProfileWrite } from "./Search/Purchase/Land/Write";
import { SearchPurchaseParkingProfileWrite } from "./Search/Purchase/Parking/Write";
import { SearchPurchasePremisesProfileWrite } from "./Search/Purchase/Premises/Write";
import { SearchRentalApartmentProfileWrite } from "./Search/Rental/Apartment/Write";
import { SearchRentalHouseProfileWrite } from "./Search/Rental/House/Write";
import { SearchRentalLandProfileWrite } from "./Search/Rental/Land/Write";
import { SearchRentalParkingProfileWrite } from "./Search/Rental/Parking/Write";
import { SearchRentalPremisesProfileWrite } from "./Search/Rental/Premises/Write";
import { Write } from "./Write";

export const Form: FC<{
  contactId: string;
  profile?: ProfileReadDto;
  mode?: string;
  sendUpdatedProfile?: (profile: ProfileReadDto | undefined) => void;
  handleAfterUpdate?: () => void;
}> = ({
  contactId,
  profile,
  mode,
  sendUpdatedProfile = () => {
    return;
  },
  handleAfterUpdate,
}) => {
  const [state, setState] = useState<{
    account: AccountType;
    business: BusinessType;
    kind?: ProfileKind;
  }>({
    account: profile?.account || "Customer",
    business: getBusiness(profile?.kind) || "Purchase",
    kind: profile?.kind || "PurchaseApartmentProfile",
  });
  const [more, showMore] = useState<boolean>(mode === "update");

  const options = profileOptions(state.account, state.business);
  const selected =
    options.find((_) => _.value === state.kind)?.value || first(options)?.value;
  return (
    <WriteProfile
      contactId={contactId}
      value={profile}
      account={state.account}
      kind={selected}
      more={more}
      showMore={() => showMore(true)}
      sendUpdatedProfile={sendUpdatedProfile}
      handleAfterUpdate={handleAfterUpdate}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SelectAccountType
            value={state.account}
            onChange={(account) =>
              setState(() => ({
                account,
                business: "Purchase",
                kind: undefined,
              }))
            }
          />
        </Grid>
        <Grid item xs={12}>
          <SelectBusinessType
            account={state.account}
            value={state.business}
            onChange={(business) =>
              setState((previous) => ({
                ...previous,
                business,
                kind: undefined,
              }))
            }
          />
        </Grid>
        <Grid item xs={12}>
          <SelectKind
            options={options}
            value={selected}
            onChange={(kind) => setState((previous) => ({ ...previous, kind }))}
          />
        </Grid>
      </Grid>
    </WriteProfile>
  );
};

const SelectBusinessType: React.FC<{
  account: AccountType;
  value?: BusinessType;
  onChange: (value: BusinessType) => void;
}> = ({ account, value, onChange }) => {
  const {
    l10n: { t },
  } = useServices();

  const options: SelectOptionProps<BusinessType>[] = [
    {
      checkedIcon: <LoyaltyOutlined />,
      icon: <Loyalty />,
      value: "Purchase",
    },
    {
      checkedIcon: <VpnKeyOutlined />,
      icon: <VpnKey />,
      value: "Rent",
    },
  ];

  if (account === "Customer") {
    options.push({
      checkedIcon: <LocalOfferOutlined />,
      icon: <LocalOffer />,
      value: "SearchLifeAnnuity",
    });
  }

  return (
    <>
      <SubSectionTitle title="Type d'affaire" />
      <RadioGroup
        row
        aria-label="type d'affaire"
        value={
          options.find((_) => _.value === value)?.value ||
          first(options)?.value ||
          ""
        }
        onChange={(_) => onChange(_.target.value as BusinessType)}
      >
        {options.map(({ value, icon, checkedIcon }) => (
          <SelectOption
            key={value}
            icon={icon}
            checkedIcon={checkedIcon}
            value={value}
            label={t(`business.${value}`)}
            itemStyle={{ margin: "0px 20px", width: "40px" }}
          />
        ))}
      </RadioGroup>
    </>
  );
};

const SelectKind: React.FC<{
  options: SelectOptionProps<ProfileKind>[];
  value?: ProfileKind;
  onChange: (value: ProfileKind) => void;
}> = ({ options, value, onChange }) => {
  const {
    l10n: { t },
  } = useServices();

  return (
    <>
      <SectionTitle title="Que recherchez-vous ?" />
      <RadioGroup
        row
        aria-label="Que recherchez-vous ?"
        value={value}
        onChange={(_) => onChange(_.target.value as ProfileKind)}
      >
        {options.map(({ value, icon, checkedIcon }) => (
          <SelectOption
            key={value}
            icon={icon}
            checkedIcon={checkedIcon}
            value={value}
            label={t(`profile.${value}`)}
            itemStyle={{ margin: "0px 20px", width: "40px" }}
          />
        ))}
      </RadioGroup>
    </>
  );
};

export function profileOptions(
  account: AccountType,
  business: BusinessType
): SelectOptionProps<ProfileKind>[] {
  const options: SelectOptionProps<ProfileKind>[] = [];

  if (account === "Customer" && business === "SearchLifeAnnuity") {
    options.push(
      {
        checkedIcon: <ApartmentOutlined />,
        icon: <Apartment />,
        value: "SearchLifeAnnuityApartmentProfile",
      },
      {
        checkedIcon: <HouseOutlined />,
        icon: <House />,
        value: "SearchLifeAnnuityHouseProfile",
      },
      {
        checkedIcon: <BusinessOutlined />,
        icon: <Business />,
        value: "SearchLifeAnnuityBuildingProfile",
      },
      {
        checkedIcon: <StoreOutlined />,
        icon: <Store />,
        value: "SearchLifeAnnuityPremisesProfile",
      },
      {
        checkedIcon: <LocalFloristOutlined />,
        icon: <LocalFlorist />,
        value: "SearchLifeAnnuityLandProfile",
      }
    );
  }

  if (account === "Customer" && business === "Purchase") {
    options.push(
      {
        checkedIcon: <ApartmentOutlined />,
        icon: <Apartment />,
        value: "PurchaseApartmentProfile",
      },
      {
        checkedIcon: <HouseOutlined />,
        icon: <House />,
        value: "PurchaseHouseProfile",
      },
      {
        checkedIcon: <ApartmentOutlined />,
        icon: <Apartment />,
        value: "PurchaseBuildingProfile",
      },
      {
        checkedIcon: <StoreOutlined />,
        icon: <Store />,
        value: "PurchasePremisesProfile",
      },
      {
        checkedIcon: <LocalFloristOutlined />,
        icon: <LocalFlorist />,
        value: "PurchaseLandProfile",
      },
      {
        checkedIcon: <DirectionsCarOutlined />,
        icon: <DirectionsCar />,
        value: "PurchaseParkingProfile",
      }
    );
  }

  if (account === "Customer" && business === "Rent") {
    options.push(
      {
        checkedIcon: <ApartmentOutlined />,
        icon: <Apartment />,
        value: "RentApartmentProfile",
      },
      {
        checkedIcon: <HouseOutlined />,
        icon: <House />,
        value: "RentHouseProfile",
      },
      {
        checkedIcon: <StoreOutlined />,
        icon: <Store />,
        value: "RentPremisesProfile",
      },
      {
        checkedIcon: <LocalFloristOutlined />,
        icon: <LocalFlorist />,
        value: "RentLandProfile",
      },
      {
        checkedIcon: <DirectionsCarOutlined />,
        icon: <DirectionsCar />,
        value: "RentParkingProfile",
      }
    );
  }

  if (account === "Professional" && business === "Purchase") {
    options.push(
      {
        checkedIcon: <StorefrontOutlined />,
        icon: <Storefront />,
        value: "PurchaseGoodwillProfile",
      },
      {
        checkedIcon: <ApartmentOutlined />,
        icon: <Apartment />,
        value: "PurchaseBuildingProfile",
      },
      {
        checkedIcon: <StoreOutlined />,
        icon: <Store />,
        value: "PurchasePremisesProfile",
      },
      {
        checkedIcon: <LocalFloristOutlined />,
        icon: <LocalFlorist />,
        value: "PurchaseLandProfile",
      }
    );
  }

  if (account === "Professional" && business === "Rent") {
    options.push(
      {
        checkedIcon: <StoreOutlined />,
        icon: <Store />,
        value: "RentPremisesProfile",
      },
      {
        checkedIcon: <LocalFloristOutlined />,
        icon: <LocalFlorist />,
        value: "RentLandProfile",
      }
    );
  }

  return options;
}

const WriteProfile: FC<{
  contactId: string;
  account: AccountType;
  kind?: ProfileKind;
  value?: ProfileReadDto;
  more: boolean;
  showMore: () => void;
  sendUpdatedProfile?: (profile: ProfileReadDto | undefined) => void;
  handleAfterUpdate?: () => void;
}> = ({
  contactId,
  account,
  kind,
  value,
  more,
  showMore,
  sendUpdatedProfile = () => {
    return;
  },
  handleAfterUpdate,
  children,
}) => {
  const initial = value?.kind === kind ? value : undefined;
  switch (kind) {
    case "SearchLifeAnnuityApartmentProfile":
      return (
        <SearchLifeAnnuityApartmentProfileWrite
          contactId={contactId}
          initial={
            initial as SearchLifeAnnuityApartmentProfileReadDto | undefined
          }
          account={account}
          more={more}
          showMore={showMore}
          sendUpdatedProfile={sendUpdatedProfile}
          handleAfterUpdate={handleAfterUpdate}
        >
          {children}
        </SearchLifeAnnuityApartmentProfileWrite>
      );

    case "SearchLifeAnnuityBuildingProfile":
      return (
        <SearchLifeAnnuityBuildingProfileWrite
          contactId={contactId}
          initial={
            initial as SearchLifeAnnuityBuildingProfileReadDto | undefined
          }
          account={account}
          more={more}
          showMore={showMore}
          sendUpdatedProfile={sendUpdatedProfile}
          handleAfterUpdate={handleAfterUpdate}
        >
          {children}
        </SearchLifeAnnuityBuildingProfileWrite>
      );
    case "SearchLifeAnnuityHouseProfile":
      return (
        <SearchLifeAnnuityHouseProfileWrite
          contactId={contactId}
          initial={initial as SearchLifeAnnuityHouseProfileReadDto | undefined}
          account={account}
          more={more}
          showMore={showMore}
          sendUpdatedProfile={sendUpdatedProfile}
          handleAfterUpdate={handleAfterUpdate}
        >
          {children}
        </SearchLifeAnnuityHouseProfileWrite>
      );
    case "SearchLifeAnnuityLandProfile":
      return (
        <SearchLifeAnnuityLandProfileWrite
          contactId={contactId}
          initial={initial as SearchLifeAnnuityLandProfileReadDto | undefined}
          account={account}
          more={more}
          showMore={showMore}
          sendUpdatedProfile={sendUpdatedProfile}
          handleAfterUpdate={handleAfterUpdate}
        >
          {children}
        </SearchLifeAnnuityLandProfileWrite>
      );
    case "SearchLifeAnnuityPremisesProfile":
      return (
        <SearchLifeAnnuityPremisesProfileWrite
          contactId={contactId}
          initial={
            initial as SearchLifeAnnuityPremisesProfileReadDto | undefined
          }
          account={account}
          more={more}
          showMore={showMore}
          sendUpdatedProfile={sendUpdatedProfile}
          handleAfterUpdate={handleAfterUpdate}
        >
          {children}
        </SearchLifeAnnuityPremisesProfileWrite>
      );
    case "PurchaseApartmentProfile":
      return (
        <SearchPurchaseApartmentProfileWrite
          contactId={contactId}
          initial={initial as PurchaseApartmentProfileReadDto | undefined}
          account={account}
          more={more}
          showMore={showMore}
          sendUpdatedProfile={sendUpdatedProfile}
          handleAfterUpdate={handleAfterUpdate}
        >
          {children}
        </SearchPurchaseApartmentProfileWrite>
      );
    case "PurchaseBuildingProfile":
      return (
        <SearchPurchaseBuildingProfileWrite
          contactId={contactId}
          initial={initial as PurchaseBuildingProfileReadDto | undefined}
          account={account}
          more={more}
          showMore={showMore}
          sendUpdatedProfile={sendUpdatedProfile}
          handleAfterUpdate={handleAfterUpdate}
        >
          {children}
        </SearchPurchaseBuildingProfileWrite>
      );
    case "PurchaseGoodwillProfile":
      return (
        <SearchPurchaseGoodWillProfileWrite
          initial={initial as PurchaseGoodWillProfileReadDto | undefined}
          account={account}
          contactId={contactId}
          more={more}
          showMore={showMore}
          sendUpdatedProfile={sendUpdatedProfile}
          handleAfterUpdate={handleAfterUpdate}
        >
          {children}
        </SearchPurchaseGoodWillProfileWrite>
      );
    case "PurchaseHouseProfile":
      return (
        <SearchPurchaseHouseProfileWrite
          initial={initial as PurchaseHouseProfileReadDto | undefined}
          account={account}
          contactId={contactId}
          more={more}
          showMore={showMore}
          sendUpdatedProfile={sendUpdatedProfile}
          handleAfterUpdate={handleAfterUpdate}
        >
          {children}
        </SearchPurchaseHouseProfileWrite>
      );
    case "PurchaseParkingProfile":
      return (
        <SearchPurchaseParkingProfileWrite
          initial={initial as PurchaseParkingProfileReadDto | undefined}
          account={account}
          contactId={contactId}
          more={more}
          showMore={showMore}
          sendUpdatedProfile={sendUpdatedProfile}
          handleAfterUpdate={handleAfterUpdate}
        >
          {children}
        </SearchPurchaseParkingProfileWrite>
      );
    case "PurchaseLandProfile":
      return (
        <SearchPurchaseLandProfileWrite
          initial={initial as PurchaseLandProfileReadDto | undefined}
          account={account}
          contactId={contactId}
          more={more}
          showMore={showMore}
          sendUpdatedProfile={sendUpdatedProfile}
          handleAfterUpdate={handleAfterUpdate}
        >
          {children}
        </SearchPurchaseLandProfileWrite>
      );
    case "PurchasePremisesProfile":
      return (
        <SearchPurchasePremisesProfileWrite
          initial={initial as PurchasePremisesProfileReadDto | undefined}
          account={account}
          contactId={contactId}
          more={more}
          showMore={showMore}
          sendUpdatedProfile={sendUpdatedProfile}
          handleAfterUpdate={handleAfterUpdate}
        >
          {children}
        </SearchPurchasePremisesProfileWrite>
      );
    case "RentApartmentProfile":
      return (
        <SearchRentalApartmentProfileWrite
          contactId={contactId}
          initial={initial as RentApartmentProfileReadDto | undefined}
          account={account}
          more={more}
          showMore={showMore}
          sendUpdatedProfile={sendUpdatedProfile}
          handleAfterUpdate={handleAfterUpdate}
        >
          {children}
        </SearchRentalApartmentProfileWrite>
      );
    case "RentHouseProfile":
      return (
        <SearchRentalHouseProfileWrite
          contactId={contactId}
          initial={initial as RentHouseProfileReadDto | undefined}
          account={account}
          more={more}
          showMore={showMore}
          sendUpdatedProfile={sendUpdatedProfile}
          handleAfterUpdate={handleAfterUpdate}
        >
          {children}
        </SearchRentalHouseProfileWrite>
      );
    case "RentLandProfile":
      return (
        <SearchRentalLandProfileWrite
          contactId={contactId}
          initial={initial as RentLandProfileReadDto | undefined}
          account={account}
          more={more}
          showMore={showMore}
          sendUpdatedProfile={sendUpdatedProfile}
          handleAfterUpdate={handleAfterUpdate}
        >
          {children}
        </SearchRentalLandProfileWrite>
      );
    case "RentParkingProfile":
      return (
        <SearchRentalParkingProfileWrite
          contactId={contactId}
          initial={initial as RentParkingProfileReadDto | undefined}
          account={account}
          more={more}
          showMore={showMore}
          sendUpdatedProfile={sendUpdatedProfile}
          handleAfterUpdate={handleAfterUpdate}
        >
          {children}
        </SearchRentalParkingProfileWrite>
      );
    case "RentPremisesProfile":
      return (
        <SearchRentalPremisesProfileWrite
          contactId={contactId}
          initial={initial as RentPremisesProfileReadDto | undefined}
          account={account}
          more={more}
          showMore={showMore}
          sendUpdatedProfile={sendUpdatedProfile}
          handleAfterUpdate={handleAfterUpdate}
        >
          {children}
        </SearchRentalPremisesProfileWrite>
      );
  }

  return (
    <Write
      pending={false}
      showMore={showMore}
      more={more}
      save={() => void 0}
      mode={initial ? "update" : "add"}
    >
      {children}
    </Write>
  );
};

function getBusiness(kind?: ProfileKind): BusinessType | undefined {
  switch (kind) {
    case "SearchLifeAnnuityApartmentProfile":
    case "SearchLifeAnnuityBuildingProfile":
    case "SearchLifeAnnuityHouseProfile":
    case "SearchLifeAnnuityLandProfile":
    case "SearchLifeAnnuityPremisesProfile":
      return "SearchLifeAnnuity";

    case "PurchasePremisesProfile":
    case "PurchaseApartmentProfile":
    case "PurchaseBuildingProfile":
    case "PurchaseGoodwillProfile":
    case "PurchaseHouseProfile":
    case "PurchaseParkingProfile":
    case "PurchaseLandProfile":
      return "Purchase";

    case "RentApartmentProfile":
    case "RentHouseProfile":
    case "RentLandProfile":
    case "RentParkingProfile":
    case "RentPremisesProfile":
      return "Rent";
  }
}
