import "moment/locale/fr";

import MomentUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React from "react";

import { useServices } from "../../../../../services";

const useStyles = makeStyles(() => ({
  dateLine: {
    alignItems: "center",
    display: "flex",
    flex: 0,
    justifyContent: "flex-end",
    paddingLeft: 10,
    paddingRight: 10,
  },
  searchField: {
    width: "80px",
  },
}));

moment.locale("fr");

const TremplinFourchetteDate = (props) => {
  const { fourchette, setFourchette } = props;
  const {
    l10n: { t },
  } = useServices();

  const classes = useStyles();

  const handleDateChange = (type, valeur) => {
    const _fourchette = { ...fourchette };
    _fourchette[type] = valeur;
    if (_fourchette["dateDebut"] > _fourchette["dateFin"]) {
      const inter = _fourchette["dateDebut"];
      _fourchette["dateDebut"] = _fourchette["dateFin"];
      _fourchette["dateFin"] = inter;
    }
    setFourchette(_fourchette);
  };

  return (
    <MuiPickersUtilsProvider
      utils={MomentUtils}
      locale="fr"
      libInstance={moment}
    >
      <div className={classes.dateLine}>
        <Typography style={{ marginRight: "10px" }} variant="h6">
          {t("Du")}
        </Typography>
        <DatePicker
          variant="inline"
          autoOk
          className={classes.searchField}
          value={fourchette.dateDebut}
          onChange={(value) => handleDateChange("dateDebut", value)}
          format="DD/MM/YYYY"
        />
        <Typography style={{ margin: "0 10px 0 10px" }} variant="h6">
          {t("Au")}
        </Typography>
        <DatePicker
          variant="inline"
          autoOk
          className={classes.searchField}
          value={fourchette.dateFin}
          onChange={(value) => handleDateChange("dateFin", value)}
          format="DD/MM/YYYY"
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default TremplinFourchetteDate;
