import { Grid, Typography } from "@material-ui/core";
import DataProvider from "components/DataProviderSR";
import moment from "moment";
import React from "react";
import { useEvenements } from "services/office365/useEvenements";

import Evenement from "./Evenement";

const aujourdhui = new Date();

const WrappedPlanningJour = ({ items, styles }) => {
  let evenements = items.map((rdv) => ({
    style: styles.rendezVous,
    type: "Rendez-Vous",
    ...rdv,
  }));

  // on ne choisit que les événements dont la date de fin
  // est supérieure à la date du jour
  const maintenant = moment();
  const evenementsAVenir = evenements.filter((evt) => {
    return (
      moment(evt.dateFin).isAfter(maintenant) ||
      moment(evt.dateFin).isSame(maintenant)
    );
  });

  // Tri par date de la plus récente à la plus éloignée
  const sixProchainsEvenements = evenementsAVenir
    .sort(function (a, b) {
      return new Date(a.dateDebut) - new Date(b.dateDebut);
    })
    .slice(0, 6);

  return (
    <div style={{ width: "100%" }}>
      <Typography variant={"h5"} style={{ marginBottom: 16 }}>
        Vos 6 prochains évènements
      </Typography>
      <Grid container spacing={2}>
        {sixProchainsEvenements.map((evenement) => (
          <Grid item xs={6} key={evenement.id}>
            <Evenement evenement={evenement} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const params = {
  dateDebutUtc: moment(aujourdhui).utc().format("YYYY-MM-DDTHH:mm:ss"),
  dateFinUtc: moment(aujourdhui)
    .add(1, "year")
    .endOf("month")
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss"),
};

const PlanningJour = ({ styles }) => {
  return (
    <DataProvider
      dataReader={useEvenements}
      params={params}
      erreur={{
        mini: true,
        texte: "Serveur non disponible.",
        titre: "Tâches à venir",
      }}
    >
      <WrappedPlanningJour styles={styles} />
    </DataProvider>
  );
};

export default PlanningJour;
