interface Ville {
  codeInsee: string;
  codesPostaux: string[];
  nom: string;
  polygone: string;
  id: string;
}
interface Conseiller {
  bu: string;
  civilite: string;
  coach: string;
  dateEntreeDansLeReseau: string;
  departement: string;
  niveauElite: string;
  adressePro: {
    adresse: string;
    numero: string;
    complementAdresse: string;
    codePostal: string;
    ville: string;
  };
  adresse?: string;
  adresseComplementaire?: string;
  adresseEmail: string;
  codePostal?: string;
  id: string;
  latitude: number;
  longitude: number;
  lienFacebook: string;
  lieux: string;
  localisation: {
    adresse: string;
    adresseComplementaire: string;
    codePostal: string;
    pays: string;
    ville: string;
  };
  nom: string;
  pack: string;
  photo: string;
  prenom: string;
  region: string;
  directeurRegional?: string;
  roles: string[];

  specialites: string;
  telephone: string;
  type: string;
  miniSiteUrl: string;
  ville?: string;
  zoneDeTravail: {
    ville: Ville;
  };
}

function obtenirConseillerParIdAdapter(result: Partial<Conseiller>) {
  return {
    bu: result.bu || "",
    civilite: result.civilite || "",
    coach: result.coach || "",
    dateEntree: result.dateEntreeDansLeReseau || "",
    departement: result.departement || "",
    distinction: result.niveauElite || "",
    adressePro: {
      adresse: result.adressePro?.adresse || "",
      numero: result.adressePro?.numero || "",
      complementAdresse: result.adressePro?.complementAdresse || "",
      codePostal: result.adressePro?.codePostal || "",
      ville: result.adressePro?.ville || "",
    },
    email: result.adresseEmail || "",
    id: result.id || "",
    latitude: result.latitude || 0,
    lienFacebook: result.lienFacebook || "",
    lieux: result.lieux || [],
    localisation: {
      adresse: result.adresse || "",
      adresseComplementaire: result.adresseComplementaire || "",
      codePostal: result.codePostal || "",
      pays: "France",
      ville: result.ville || "",
    },
    longitude: result.longitude || 0,
    nom: result.nom || "",
    pack: result.pack || "",
    photo: result.photo || "",
    prenom: result.prenom || "",
    region: result.region || "",
    responsableRegional: result.directeurRegional || "",
    roles: result.roles || [],
    specialites: result.specialites || "",
    telephone: result.telephone || "",
    type: result.type || "",
    urlMiniSite: result.miniSiteUrl || "",
    ville: result.ville || "",
    zoneDeTravail: {
      ville: villeAdapter(result.zoneDeTravail?.ville),
    },
  };
}

export type AdaptedAgent = ReturnType<typeof obtenirConseillerParIdAdapter>;

export default obtenirConseillerParIdAdapter;

const villeAdapter = (ville?: Ville) => ({
  codeInsee: ville?.codeInsee || "",
  codesPostaux: ville?.codesPostaux || [],
  nom: ville?.nom || "",
  polygone: ville?.polygone || "",
  id: ville?.id || "",
});
