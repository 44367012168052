import React, { FC } from "react";
import { BudgetLifeAnnuityDto } from "routes/app/contacts/contact/components/models/budgetLifeAnnuityDto";
import { useServices } from "services";
import { formatPrix } from "utils/formatage";

import { MoreInfos } from "./MoreInfos";

export const LifeAnnuityBudget: FC<{ budget: BudgetLifeAnnuityDto }> = ({
  budget,
}) => {
  const {
    l10n: { t, formatBudget },
  } = useServices();
  const informations = [
    {
      label: t("Bouquet"),
      value: formatBudget(budget.bouquet.min, budget.bouquet.max),
    },
    {
      label: t("Bouquet max pour rapprochement"),
      value: formatPrix(budget.bouquet.maxMatching),
    },
    {
      label: t("Rente"),
      value: formatPrix(budget.income.max),
    },
    {
      label: t("Rente max pour rapprochement"),
      value: formatPrix(budget.income.maxMatching),
    },
  ];
  return <MoreInfos title="Budget" value={informations} />;
};
