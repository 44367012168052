import React from "react";
import { Typography, Grid } from "@material-ui/core";
import moment from "moment";

const Evenement = ({ evenement, mini = false }) => {
  let formatDateDebut = mini ? "HH:mm" : "DD/MM/YYYY HH:mm";
  let formatDateFin = mini ? "HH:mm" : "DD/MM/YYYY HH:mm";
  if (
    moment(evenement.dateDebut).format("DD/MM/YYYY") ===
    moment(evenement.dateFin).format("DD/MM/YYYY")
  )
    formatDateFin = "HH:mm";

  return (
    <Grid container spacing={1}>
      <Grid item xs={1}>
        <div
          style={{
            borderRadius: 5,
            height: 10,
            margin: 5,
            width: 10,
            ...evenement.style,
            backgroundColor:
              evenement.style.backgroundColor &&
              evenement.style.backgroundColor.length > 7
                ? evenement.style.backgroundColor.substring(0, 7)
                : evenement.style.backgroundColor,
          }}
        />
      </Grid>
      <Grid item xs={11}>
        <Typography variant={"h6"}>{evenement.objet}</Typography>
        <Typography variant={"body1"}> {evenement.nomOrganisateur}</Typography>
        <Typography variant={"body1"} noWrap>
          {evenement.lieu}
        </Typography>
        <Typography variant={"body1"} noWrap>
          {evenement.messageSlim}
        </Typography>
        <Typography variant={"body1"}>
          {moment(evenement.dateDebut).format(formatDateDebut)} -{" "}
          {moment(evenement.dateFin).format(formatDateFin)}
        </Typography>
      </Grid>
      {mini && (
        <Grid item xs={12}>
          &nbsp;
        </Grid>
      )}
    </Grid>
  );
};

export default Evenement;
