import { MaturityType } from "routes/app/contacts/contact/components/models/maturityType";
import { ProfileReadDto } from "routes/app/contacts/contact/components/models/profilesReadDto";
import { SaleApartmentProfileReadDto } from "routes/app/contacts/contact/components/models/Proposal/ForSale/Apartment/saleApartmentProfileReadDto";
import { SaleHouseProfileReadDto } from "routes/app/contacts/contact/components/models/Proposal/ForSale/House/saleHouseProfileReadDto";
import { SaleLandProfileReadDto } from "routes/app/contacts/contact/components/models/Proposal/ForSale/Land/saleLandProfileReadDto";
import { SaleParkingProfileReadDto } from "routes/app/contacts/contact/components/models/Proposal/ForSale/Parking/saleParkingProfileReadDto";
import { PurchaseApartmentProfileReadDto } from "routes/app/contacts/contact/components/models/Search/purchaseApartmentProfileReadDto";
import { PurchaseBuildingProfileReadDto } from "routes/app/contacts/contact/components/models/Search/purchaseBuildingProfileReadDto";
import { PurchaseGoodWillProfileReadDto } from "routes/app/contacts/contact/components/models/Search/purchaseGoodWillProfileReadDto";
import { PurchaseHouseProfileReadDto } from "routes/app/contacts/contact/components/models/Search/purchaseHouseProfileReadDto";
import { PurchaseLandProfileReadDto } from "routes/app/contacts/contact/components/models/Search/purchaseLandProfileReadDto";
import { PurchaseParkingProfileReadDto } from "routes/app/contacts/contact/components/models/Search/purchaseParkingProfileReadDto";
import { PurchasePremisesProfileReadDto } from "routes/app/contacts/contact/components/models/Search/purchasePremisesProfileReadDto";
import { RentApartmentProfileReadDto } from "routes/app/contacts/contact/components/models/Search/rentApartmentProfileReadDto";
import { RentHouseProfileReadDto } from "routes/app/contacts/contact/components/models/Search/rentHouseProfileReadDto";
import { RentLandProfileReadDto } from "routes/app/contacts/contact/components/models/Search/rentLandProfileReadDto";
import { RentParkingProfileReadDto } from "routes/app/contacts/contact/components/models/Search/rentParkingProfileReadDto";
import { RentPremisesProfileReadDto } from "routes/app/contacts/contact/components/models/Search/rentPremisesProfileReadDto";
import { SearchLifeAnnuityApartmentProfileReadDto } from "routes/app/contacts/contact/components/models/Search/searchLifeAnnuityApartmentProfileReadDto";
import { SearchLifeAnnuityBuildingProfileReadDto } from "routes/app/contacts/contact/components/models/Search/searchLifeAnnuityBuildingProfileReadDto";
import { SearchLifeAnnuityHouseProfileReadDto } from "routes/app/contacts/contact/components/models/Search/searchLifeAnnuityHouseProfileReadDto";
import { SearchLifeAnnuityLandProfileReadDto } from "routes/app/contacts/contact/components/models/Search/searchLifeAnnuityLandProfileReadDto";
import { SearchLifeAnnuityPremisesProfileReadDto } from "routes/app/contacts/contact/components/models/Search/searchLifeAnnuityPremisesProfileReadDto";
import { ProfileItemDto } from "services/profiles/dto/ReadModelProfileDto";

import { SaleBuildingProfileReadDto } from "../../contacts/contact/components/models/Proposal/ForSale/Building/saleBuildingProfileReadDto";
import { CityInfos } from "UI/features/profiles/organisms/CitiesList";
import { getBusinessType, getProfileType } from "./utils";

export type ProfileBusinessType =
  | "Location"
  | "Viager"
  | "Achat"
  | "Vente"
  | "Bail"
  | "";

export interface DisplayedProfileForList {
  id: string;
  reference?: string;
  profileType: "Search" | "Seller";
  bedroomsNumber?: number;
  budgetMax: number;
  propertyCategory: string;
  propertyType?: string;
  roomsNumber?: number;
  surfaceMax?: number;
  surfaceMin?: number;
  monthIncome?: number;
  maturity?: MaturityType;
  locations: CityInfos[];
  businessType: ProfileBusinessType;
  status: "Archived" | "Active";
  archivedOrUpdatedDate: Date;
  notes?: string;
}

export const profileAdapter = (
  profile: ProfileItemDto
): DisplayedProfileForList => {
  return {
    archivedOrUpdatedDate: new Date(
      profile.archivingDate ?? profile.lastModificationDate
    ),
    bedroomsNumber: profile.bedRooms ?? 0,
    budgetMax: profile.budgetMax,
    businessType: getBusinessType(profile.kind),
    id: profile.id,
    locations: profile.places.map(({ postalCode, name }) => ({
      name,
      zipcode: postalCode,
    })),
    maturity: profile.maturity ?? "",
    monthIncome: profile.monthIncome ?? 0,
    profileType: getProfileType(profile.kind),
    propertyCategory: profile.propertyKind,
    propertyType: profile.propertyType,
    reference: profile.reference ?? "",
    roomsNumber: profile.rooms ?? 0,
    status: profile.archivingDate ? "Archived" : "Active",
    surfaceMax: profile.surfaceMax ?? 0,
    surfaceMin: profile.surfaceMin ?? 0,
  };
};

export const fromProfileReadDto = (
  profile: ProfileReadDto
): DisplayedProfileForList => {
  switch (profile.kind) {
    case "SaleApartmentProfile":
      return apartmentForSaleAdapter(profile);
    case "SaleHouseProfile":
      return houseForSaleAdapter(profile);
    case "SaleParkingProfile":
      return parkingForSaleAdapter(profile);
    case "SaleBuildingProfile":
      return buildingForSaleAdapter(profile);
    case "SaleLandProfile":
      return landForSaleAdapter(profile);
    case "SearchLifeAnnuityApartmentProfile":
      return searchLifeAnnuityApartmentAdapter(profile);
    case "SearchLifeAnnuityHouseProfile":
      return searchLifeAnnuityHouseAdapter(profile);
    case "SearchLifeAnnuityBuildingProfile":
      return searchLifeAnnuityBuildingAdapter(profile);
    case "SearchLifeAnnuityLandProfile":
      return searchLifeAnnuityLandAdapter(profile);
    case "SearchLifeAnnuityPremisesProfile":
      return searchLifeAnnuityPremisesAdapter(profile);
    case "PurchaseApartmentProfile":
      return searchPurchaseApartmentAdapter(profile);
    case "PurchaseBuildingProfile":
      return searchPurchaseBuildingAdapter(profile);
    case "PurchaseGoodwillProfile":
      return searchPurchaseGoodWillAdapter(profile);
    case "PurchaseHouseProfile":
      return searchPurchaseHouseAdapter(profile);
    case "PurchaseLandProfile":
      return searchPurchaseLandAdapter(profile);
    case "PurchaseParkingProfile":
      return searchPurchaseParkingAdapter(profile);
    case "PurchasePremisesProfile":
      return searchPurchasePremisesAdapter(profile);
    case "RentApartmentProfile":
      return searchRentalApartmentAdapter(profile);
    case "RentHouseProfile":
      return searchRentalHouseAdapter(profile);
    case "RentLandProfile":
      return searchRentalLandAdapter(profile);
    case "RentParkingProfile":
      return searchRentalParkingAdapter(profile);
    case "RentPremisesProfile":
      return searchRentalPremisesAdapter(profile);
    default:
      throw new Error(`Unknown profile kind : ${profile.kind}`);
  }
};

const apartmentForSaleAdapter = (
  profile: SaleApartmentProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  bedroomsNumber: profile.features.parts.summaries.bedRooms?.count,
  budgetMax: profile.features.financialInfo.price || 0,
  businessType: "Vente",
  id: profile.id,
  locations: [
    {
      name: profile.features.address.city?.name || "",
      zipcode: profile.features.address.city?.postalCodes[0] || "",
    },
  ],
  maturity: profile.maturity,
  profileType: "Seller",
  propertyCategory: "Apartment",
  propertyType: profile.features.propertyInfo.built || "",
  reference: profile.reference,
  roomsNumber: profile.features.parts.summaries.rooms?.count,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.features.propertyInfo.squareMeter,
});

const houseForSaleAdapter = (
  profile: SaleHouseProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  bedroomsNumber: profile.features.parts.summaries.bedRooms?.count,
  budgetMax: profile.features.financialInfo?.price || 0,
  businessType: "Vente",
  id: profile.id,
  locations: [
    {
      name: profile.features.address.city?.name || "",
      zipcode: profile.features.address.city?.postalCodes[0] || "",
    },
  ],
  maturity: profile.maturity,
  profileType: "Seller",
  propertyCategory: "House",
  propertyType: profile.features.propertyInfo.built || "",
  reference: profile.reference,
  roomsNumber: profile.features.parts.summaries.rooms?.count,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.features.propertyInfo.squareMeter,
});

const parkingForSaleAdapter = (
  profile: SaleParkingProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.features?.financialInfo?.price || 0,
  businessType: "Vente",
  id: profile.id,
  locations: [
    {
      name: profile.features?.address.city?.name || "",
      zipcode: profile.features?.address.city?.postalCodes[0] || "",
    },
  ],
  maturity: profile.maturity,
  profileType: "Seller",
  propertyCategory: "Parking",
  propertyType: profile.features?.parkingKind || "",
  reference: profile.reference,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.features?.parkingPropertyInfo.squareMeter || 0,
});

const landForSaleAdapter = (
  profile: SaleLandProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.features?.financialInfo?.price || 0,
  businessType: "Vente",
  id: profile.id,
  locations: [
    {
      name: profile.features?.address.city?.name || "",
      zipcode: profile.features?.address.city?.postalCodes[0] || "",
    },
  ],
  maturity: profile.maturity,
  profileType: "Seller",
  propertyCategory: "Land",
  propertyType: profile.features?.landKind || "",
  reference: profile.reference,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.features?.landPropertyInfo.squareMeter || 0,
});

const buildingForSaleAdapter = (
  profile: SaleBuildingProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.features?.financialInfo?.price || 0,
  businessType: "Vente",
  id: profile.id,
  locations: [
    {
      name: profile.features?.address.city?.name || "",
      zipcode: profile.features?.address.city?.postalCodes[0] || "",
    },
  ],
  maturity: profile.maturity,
  profileType: "Seller",
  propertyCategory: "Building",
  propertyType: profile.features?.buildingKind || "",
  reference: profile.reference,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.features?.buildingPropertyInfo.squareMeter || 0,
});

const searchLifeAnnuityApartmentAdapter = (
  profile: SearchLifeAnnuityApartmentProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  bedroomsNumber: profile.criteria.minBedrooms,
  budgetMax: profile.criteria.budget.bouquet.max || 0,

  businessType: "Viager",
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  monthIncome: profile.criteria.budget.income.max,
  profileType: "Search",
  propertyCategory: "Apartment",
  propertyType: profile.criteria.vacancy,
  reference: profile.reference,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchLifeAnnuityHouseAdapter = (
  profile: SearchLifeAnnuityHouseProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  bedroomsNumber: profile.criteria.minBedrooms,
  budgetMax: profile.criteria.budget.bouquet.max || 0,

  businessType: "Viager",
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  monthIncome: profile.criteria.budget.income.max,
  profileType: "Search",
  propertyCategory: "House",
  propertyType: profile.criteria.vacancy,
  reference: profile.reference,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchLifeAnnuityBuildingAdapter = (
  profile: SearchLifeAnnuityBuildingProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.bouquet.max || 0,
  businessType: "Viager",
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  monthIncome: profile.criteria.budget.income.max,
  profileType: "Search",
  propertyCategory: "Building",
  propertyType: profile.criteria.vacancy,
  reference: profile.reference,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchLifeAnnuityLandAdapter = (
  profile: SearchLifeAnnuityLandProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.bouquet.max || 0,
  businessType: "Viager",
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  monthIncome: profile.criteria.budget.income.max,
  profileType: "Search",
  propertyCategory: "Land",
  reference: profile.reference,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.lotSize.max,
  surfaceMin: profile.criteria.lotSize.min,
});

const searchLifeAnnuityPremisesAdapter = (
  profile: SearchLifeAnnuityPremisesProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.bouquet.max || 0,
  businessType: "Viager",
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  monthIncome: profile.criteria.budget.income.max,
  profileType: "Search",
  propertyCategory: "Premises",
  propertyType: profile.criteria.vacancy,
  reference: profile.reference,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchPurchaseApartmentAdapter = (
  profile: PurchaseApartmentProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  bedroomsNumber: profile.criteria.minBedrooms,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Achat",
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  profileType: "Search",
  propertyCategory: "Apartment",
  propertyType: profile.criteria.propertyAge,
  reference: profile.reference,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchPurchaseHouseAdapter = (
  profile: PurchaseHouseProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  bedroomsNumber: profile.criteria.minBedrooms,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Achat",
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  profileType: "Search",
  propertyCategory: "House",
  propertyType: profile.criteria.propertyAge,
  reference: profile.reference,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchPurchaseBuildingAdapter = (
  profile: PurchaseBuildingProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Achat",
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  profileType: "Search",
  propertyCategory: "Building",
  propertyType: profile.criteria.propertyAge,
  reference: profile.reference,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchPurchaseGoodWillAdapter = (
  profile: PurchaseGoodWillProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Achat",
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  profileType: "Search",
  propertyCategory: "Goodwill",
  propertyType: profile.criteria.withPremises ? "WithWalls" : undefined,
  reference: profile.reference,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchPurchasePremisesAdapter = (
  profile: PurchasePremisesProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Achat",
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  profileType: "Search",
  propertyCategory: "Premises",
  propertyType: profile.criteria.propertyAge,
  reference: profile.reference,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchPurchaseLandAdapter = (
  profile: PurchaseLandProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Achat",
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  profileType: "Search",
  propertyCategory: "Land",
  reference: profile.reference,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.lotSize.max,
  surfaceMin: profile.criteria.lotSize.min,
});

const searchPurchaseParkingAdapter = (
  profile: PurchaseParkingProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Achat",
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  profileType: "Search",
  propertyCategory: "Parking",
  reference: profile.reference,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchRentalApartmentAdapter = (
  profile: RentApartmentProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  bedroomsNumber: profile.criteria.minBedrooms,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Location",
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  profileType: "Search",
  propertyCategory: "Apartment",
  propertyType: profile.criteria.furniture === "With" ? "Meublé" : undefined,
  reference: profile.reference,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchRentalHouseAdapter = (
  profile: RentHouseProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  bedroomsNumber: profile.criteria.minBedrooms,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Location",
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  profileType: "Search",
  propertyCategory: "House",
  propertyType: profile.criteria.furniture === "With" ? "Meublé" : undefined,
  reference: profile.reference,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchRentalPremisesAdapter = (
  profile: RentPremisesProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Location",
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  profileType: "Search",
  propertyCategory: "Premises",
  propertyType: profile.criteria.furniture === "With" ? "Meublé" : undefined,
  reference: profile.reference,
  roomsNumber: profile.criteria.minRooms,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});

const searchRentalLandAdapter = (
  profile: RentLandProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Location",
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  profileType: "Search",
  propertyCategory: "Land",
  reference: profile.reference,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.lotSize.max,
  surfaceMin: profile.criteria.lotSize.min,
});

const searchRentalParkingAdapter = (
  profile: RentParkingProfileReadDto
): DisplayedProfileForList => ({
  archivedOrUpdatedDate: profile.archivingDate
    ? profile.archivingDate
    : profile.lastModificationDate,
  budgetMax: profile.criteria.budget.max || 0,
  businessType: "Location",
  id: profile.id,
  locations: profile.criteria.places.map((place) => ({
    name: place.name,
    zipcode: place.postalCodes[0] || "",
  })),
  maturity: profile.criteria.maturity,
  profileType: "Search",
  propertyCategory: "Parking",
  reference: profile.reference,
  status: profile.archivingDate ? "Archived" : "Active",
  surfaceMax: profile.criteria.homeSize.max,
  surfaceMin: profile.criteria.homeSize.min,
});
