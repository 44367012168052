import React from "react";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";
import { Row } from "UI/shared/atoms/containers/Row";

export const NoNetworkRealEstates: React.FC = () => (
  <PaddingContainer top={16}>
    <Row rowJustify="center">
      Aucun bien du réseau ne correspond à la recherche de mon acquéreur.
    </Row>
  </PaddingContainer>
);
