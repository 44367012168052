import Grid from "@material-ui/core/Grid";
import ValidationTextField from "components/ValidationTextField";
import React, { FC } from "react";
import {
  getMainEmailOrDefault,
  getMainPhoneOrDefault,
} from "services/portfolio/types";

import { GridContainer } from "../../../../../../components/containers/GridContainer";
import { useServices } from "../../../../../../services";
import { useContactForm } from "../context/ContactFormContext";
import { useContactEmails } from "../hooks/useContactEmails";
import { useContactPhones } from "../hooks/useContactPhones";
import useStyles from "../styles";
import { ExistingContactMeanError } from "./ExistingContactMeanError";
import { OnePhoneOrMailMandatoryMessage } from "./OnePhoneOrMailMandatoryMessage";
import { SectionTitle } from "./Sections";

export const PhoneAndMail: FC = () => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();
  const {
    errors,
    isDisabled,
    contactDto: {
      identity: { phones, emails },
    },
  } = useContactForm();

  const { onEmailChange, validateEmailAddressExistence } = useContactEmails();
  const { onPhoneChange, validatePhoneNumberExistence } = useContactPhones();

  const phone = getMainPhoneOrDefault(phones);
  const email = getMainEmailOrDefault(emails);

  return (
    <GridContainer>
      <Grid item xs={12}>
        <SectionTitle
          title={"Moyens de contact*"}
          mode="obligatoire"
          mention={"(1 obligatoire)"}
        />
      </Grid>
      <Grid item xs={12}>
        <ValidationTextField
          id="telephone"
          name="number"
          label={t("Telephone")}
          error={!!errors["phones"][0] || !!errors["phoneNumberExistences"][0]}
          helperText={errors["phones"][0]}
          className={classes.narrowTextField}
          value={phone}
          onChange={onPhoneChange(0)}
          onBlur={validatePhoneNumberExistence(0)}
          margin="normal"
          variant="outlined"
          disabled={isDisabled && !errors["phoneNumberExistences"][0]}
        />
      </Grid>
      <Grid item xs={12}>
        {errors["phoneNumberExistences"][0] && (
          <ExistingContactMeanError
            contactId={errors["phoneNumberExistences"][0]}
            contactMeanType={"phone"}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <ValidationTextField
          id="email"
          name="address"
          label={t("Email")}
          error={!!errors["emails"][0] || !!errors["emailAddressExistences"][0]}
          helperText={errors["emails"][0]}
          className={classes.textField}
          value={email}
          onChange={onEmailChange(0)}
          onBlur={validateEmailAddressExistence(0)}
          margin="normal"
          variant="outlined"
          disabled={isDisabled && !errors["emailAddressExistences"][0]}
        />
        <Grid item xs={12}>
          {errors["emailAddressExistences"][0] && (
            <ExistingContactMeanError
              contactId={errors["emailAddressExistences"][0]}
              contactMeanType={"email"}
            />
          )}
        </Grid>
        <OnePhoneOrMailMandatoryMessage />
      </Grid>
    </GridContainer>
  );
};
