import {
  Divider,
  Drawer,
  IconButton,
  List,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { InfoOutlined } from "@material-ui/icons";
import ContactsIcon from "@material-ui/icons/AccountBoxOutlined";
import MandatIcon from "@material-ui/icons/AssignmentReturned";
import ActeIcon from "@material-ui/icons/AssignmentTurnedIn";
import BuildingIcon from "@material-ui/icons/Business";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import HouseIcon from "@material-ui/icons/HomeOutlined";
import PublicationsIcon from "@material-ui/icons/MultilineChart";
import ReportingIcon from "@material-ui/icons/PieChartOutlined";
import SearchIcon from "@material-ui/icons/Search";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import ConseillersIcon from "@material-ui/icons/SupervisorAccountOutlined";
import ClassementIcon from "@material-ui/icons/TrendingUp";
import capi_logoRaccourci from "assets/images/favicon_capifrance.png";
import oh_logoRaccourci from "assets/images/favicon_oh.png";
import reflex_logoRaccourci from "assets/images/favicon_reflex.png";
import capi_logo from "assets/images/tremplinx_capifrance.png";
import oh_logo from "assets/images/tremplinx_oh.png";
import reflex_logo from "assets/images/tremplinx_reflex.png";
import clsx from "clsx";
import MarkDownDisplay from "components/MarkDownDisplay";
import StyledDialog from "components/StyledDialog";
import changelog from "markdowns/changelog.md";
import PropType from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import routesNames from "router/routesNames";
import { useFeatureFlags } from "services/featureFlags";
import { usePortfolioSearch } from "services/portfolio/usePortfolioSearch";
import { INITIAL_SLUG } from "services/slugs/constants";
import { UserContext } from "services/user";

import { useServices } from "../../services";
import { useTxHttp } from "../../services/http";
import PathLink from "./PathLink";
import SubMenu from "./SubMenu";

const useStyles = makeStyles((theme) => ({
  brand: {
    height: "100%",
    width: "auto",
  },

  brandWrapper: {
    alignItems: "center",
    backgroundColor: theme.palette.buttonSelected,
    cursor: "pointer",
    display: "flex",
    flexGrow: 1,
    height: "66px",
    justifyContent: "center",
    padding: theme.spacing(1),
  },

  carret: {
    backgroundColor: theme.palette.buttonSelected,
    bottom: 0,
    cursor: "pointer",
    left: 0,
    overflowX: "hidden",
    position: "absolute",
    width: "100%",
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: "noWrap",
    width: (props) => props.drawerWidth,
  },
  drawerClose: {
    backgroundColor: theme.palette.default,
    overflowX: "hidden",
    transition: "width 450ms cubic-bezier(0.18, 0.89, 0.32, 1.28)",
    width: (props) => props.drawerWidthClosed,
  },
  drawerOpen: {
    backgroundColor: theme.palette.default,
    overflowX: "hidden",
    transition: "width 450ms cubic-bezier(0.18, 0.89, 0.32, 1.28)",
    width: (props) => props.drawerWidth,
  },
  iconChevronClose: {
    color: theme.palette.white,
  },
  iconChevronOpen: {
    color: theme.palette.white,
  },
  listItem: {
    "&:hover": {
      "&::before": {
        backgroundColor: theme.palette.tonique,
        content: "''",
        height: "100%",
        left: 0,
        position: "absolute",
        top: 0,
        width: "5px",
      },
      backgroundColor: `#091722 !important`,
    },
    color: theme.palette.white,
  },
  listItemSelected: {
    "&::before": {
      backgroundColor: theme.palette.tonique,
      content: "''",
      height: "100%",
      left: 0,
      position: "absolute",
      top: 0,
      width: "5px",
    },
    backgroundColor: `${theme.palette.buttonSelected} !important`,
    color: theme.palette.tonique,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedShort: {
    paddingLeft: theme.spacing(4),
  },
  notNested: {},
  test: {
    backgroundColor: "none",
  },
  toolbar: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  version: {
    bottom: 60,
    color: "white",
    cursor: "pointer",
    left: 0,
    position: "absolute",
    width: "100%",
  },
}));

const SideBar = ({
  sidebarState,
  dispatch,
  location,
  drawerWidth,
  grandEcran,
}) => {
  const classes = useStyles({
    drawerWidth: drawerWidth[0],
    drawerWidthClosed: drawerWidth[1],
  });
  const {
    l10n: { t },
    authenticationService,
    trackingService: { sendCustomEventToGoogle },
    endpoints,
  } = useServices();

  const userData = authenticationService.getUserData();

  const features = useFeatureFlags();
  let logo, logoRaccourci;

  const {
    requestForInformationsService: { activeRequestsCount },
  } = useContext(UserContext);

  const { getActiveContactCount } = usePortfolioSearch();

  const { get } = useTxHttp();
  const [profilesCount, setProfilesCount] = useState(0);
  const [activeContactsCount, setActiveContactsCount] = useState(0);

  useEffect(() => {
    if (sidebarState.drawerOpen) {
      get(
        `${endpoints.profiles.allAgentProfiles}?pageNumber=1&size=1`
      ).then((response) => setProfilesCount(response.data.total));
      getActiveContactCount().then((result) => setActiveContactsCount(result));
    }
  }, [sidebarState.drawerOpen]);

  switch (userData.Instance) {
    case "Refleximmo":
      logo = reflex_logo;
      logoRaccourci = reflex_logoRaccourci;
      break;
    case "Optimhome":
      logo = oh_logo;
      logoRaccourci = oh_logoRaccourci;
      break;
    default:
      logo = capi_logo;
      logoRaccourci = capi_logoRaccourci;
  }

  const routes = [
    {
      icon: <DashboardIcon />,
      id: "Dashboard",
      name: t("Tableau de bord"),
      path: "/app/dashboard",
    },
    {
      icon: <HouseIcon />,
      id: "Biens",
      name: t("Biens"),
      subList: [
        {
          active: true,
          icon: <SearchIcon style={{ fontSize: "1rem" }} />,
          name: t("Mes biens"),
          path: `${routesNames.app}${routesNames.biens}${routesNames.mesBiensEnVente}/list/full-list`,
        },
        {
          active: !features.AvailableProperties,
          icon: <SearchIcon style={{ fontSize: "1rem" }} />,
          name: t("Biens disponibles"),
          path: `${routesNames.app}${routesNames.biens}${routesNames.biensEnVente}/list/full-list`,
        },
        {
          active: features.AvailableProperties,
          icon: <SearchIcon style={{ fontSize: "1rem" }} />,
          name: t("Biens disponibles"),
          path: `${routesNames.app}${routesNames.biens}${routesNames.availableProperties}/list/full-list`,
        },
        {
          active: true,
          icon: <SearchIcon style={{ fontSize: "1rem" }} />,
          name: t("Biens vendus"),
          path: `${routesNames.app}${routesNames.biens}${routesNames.biensVendus}/list/full-list`,
        },
        {
          active: features.Pige,
          icon: <BuildingIcon style={{ fontSize: "1rem" }} />,
          name: t("Pige"),
          path: `${routesNames.listeBiensPige}?slug=full-list`,
        },
      ],
    },
    {
      icon: <ContactsIcon />,
      id: "CRM",
      name: "CRM",
      subList: [
        {
          active: features.Contacts_V2,
          icon: <ContactsIcon style={{ fontSize: "1rem" }} />,
          name: `${t("Contacts_V2")} (${activeContactsCount ?? 0})`,
          path: `${routesNames.app}${routesNames.contacts}/full-list`,
        },
        {
          active: features.Contacts_V2,
          icon: <></>,
          name: `${t("Profils")} (${profilesCount ?? 0})`,
          path: `${routesNames.app}${routesNames.profilesPage}/${INITIAL_SLUG}`,
        },
        {
          active: features.DR,
          icon: <></>,
          name: `${t("DR")} (${activeRequestsCount})`,
          path: `${routesNames.app}${routesNames.demandesRenseignements}`,
        },
      ],
    },
    {
      icon: <SendOutlinedIcon />,
      id: "Campagnes",
      name: "Marketing distribué",
      subList: [
        {
          active: features.Campaigns_enabled,
          icon: <SendOutlinedIcon style={{ fontSize: "1rem" }} />,
          name: t("Campagnes nationales"),
          path: "/app/marketing-distribue",
        },
        {
          active: features.Campaigns_enabled,
          icon: <SendOutlinedIcon style={{ fontSize: "1rem" }} />,
          name: t("Statistiques"),
          path: "/app/marketing-distribue-stats",
        },
      ],
    },
    {
      icon: <ConseillersIcon />,
      id: "Mon réseau",
      name: t("Mon réseau"),
      subList: [
        {
          active: true,
          icon: <ConseillersIcon style={{ fontSize: "1rem" }} />,
          name: t("Conseillers"),
          path: `${routesNames.app}${routesNames.monReseau}${routesNames.conseillers}/list/full-list`,
        },
      ],
    },
    {
      icon: <ReportingIcon />,
      id: "Reporting",
      name: t("Reporting"),
      subList: [
        {
          active: true,
          icon: <DashboardIcon style={{ fontSize: "1rem" }} />,
          name: t("Synthese"),
          path: "/app/reporting/synthese",
        },
        {
          active: true,
          icon: <BuildingIcon style={{ fontSize: "1rem" }} />,
          name: t("Biens"),
          path: "/app/reporting/biens",
        },
        {
          active: true,
          icon: <MandatIcon style={{ fontSize: "1rem" }} />,
          name: t("Mandats"),
          path: "/app/reporting/mandats",
        },
        {
          active: true,
          icon: <ActeIcon style={{ fontSize: "1rem" }} />,
          name: t("Actes"),
          path: "/app/reporting/actes",
        },
        // not migrated from SQL BI into snowflake
        // {
        //   active: true,
        //   icon: <PublicationsIcon style={{ fontSize: "1rem" }} />,
        //   name: t("Publications"),
        //   path: "/app/reporting/publications",
        // },
        {
          active: true,
          icon: <ClassementIcon style={{ fontSize: "1rem" }} />,
          name: t("Classements"),
          path: "/app/reporting/classements",
        },
      ],
    },
  ];

  const handleClicCarret = () => dispatch({ type: "clic carret" });

  const { dialogComponent, switchStatus } = StyledDialog({
    content: <MarkDownDisplay filePath={changelog} />,
  });

  const versionComponent = (
    <div
      className={classes.version}
      onClick={() => {
        sendCustomEventToGoogle("Clic sur Changelog");
        switchStatus();
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {sidebarState.drawerOpen ? (
          <div style={{ alignItems: "center", display: "flex" }}>
            <Typography
              variant={"body1"}
            >{`Version ${window.version}`}</Typography>
            &nbsp;
            <InfoOutlined />
          </div>
        ) : (
          <InfoOutlined />
        )}
      </div>
    </div>
  );
  const history = useHistory();

  const allerAPageParDefaut = () => {
    sessionStorage.setItem("page courante", window.defaultPage);
    history.push(window.defaultPage);
  };

  return (
    <>
      {grandEcran ? (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: sidebarState.drawerOpen,
            [classes.drawerClose]: !sidebarState.drawerOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: sidebarState.drawerOpen,
              [classes.drawerClose]: !sidebarState.drawerOpen,
            }),
          }}
          open={sidebarState.drawerOpen}
        >
          <div className={classes.list}>
            <div className={classes.brandWrapper} onClick={allerAPageParDefaut}>
              <img
                src={sidebarState.drawerOpen ? logo : logoRaccourci}
                alt="Tremplin X"
                className={classes.brand}
              />
            </div>
            <Divider />
            <List>
              {routes.map((route) => (
                <div key={route.name}>
                  {route.path ? (
                    <PathLink
                      route={route}
                      location={location}
                      nested={false}
                      dispatch={dispatch}
                    />
                  ) : (
                    <SubMenu
                      key={route.name}
                      route={route}
                      location={location}
                      sidebarState={sidebarState}
                      dispatch={dispatch}
                    />
                  )}
                </div>
              ))}
            </List>
          </div>
          {versionComponent}
          <div className={classes.carret} onClick={handleClicCarret}>
            <IconButton>
              {sidebarState.drawerOpen ? (
                <ChevronLeftIcon
                  classes={{ root: classes.iconChevronOpen }}
                  fontSize="large"
                />
              ) : (
                <ChevronRightIcon
                  classes={{ root: classes.iconChevronClose }}
                  fontSize="large"
                />
              )}
            </IconButton>
          </div>
        </Drawer>
      ) : (
        <Drawer
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: sidebarState.drawerOpen,
            [classes.drawerClose]: !sidebarState.drawerOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: sidebarState.drawerOpen,
              [classes.drawerClose]: !sidebarState.drawerOpen,
            }),
          }}
          open={sidebarState.drawerOpen}
          onClose={handleClicCarret}
        >
          <div className={classes.list}>
            <div className={classes.brandWrapper}>
              <img
                src={sidebarState.drawerOpen ? logo : logoRaccourci}
                alt="Tremplin X"
                className={classes.brand}
              />
            </div>
            <Divider />
            <List>
              {routes.map((route) => (
                <div key={route.name}>
                  {route.path ? (
                    <PathLink
                      route={route}
                      location={location}
                      nested={false}
                      dispatch={dispatch}
                    />
                  ) : (
                    <SubMenu
                      key={route.name}
                      route={route}
                      location={location}
                      sidebarState={sidebarState}
                      dispatch={dispatch}
                    />
                  )}
                </div>
              ))}
            </List>
          </div>
          {versionComponent}
          <div className={classes.carret} onClick={handleClicCarret}>
            <IconButton>
              {sidebarState.drawerOpen ? (
                <ChevronLeftIcon
                  classes={{ root: classes.iconChevronOpen }}
                  fontSize="large"
                />
              ) : (
                <ChevronRightIcon
                  classes={{ root: classes.iconChevronClose }}
                  fontSize="large"
                />
              )}
            </IconButton>
          </div>
        </Drawer>
      )}
      {dialogComponent}
    </>
  );
};

export default withRouter(SideBar);

SideBar.propTypes = {
  dispatch: PropType.func,
  drawerWidth: PropType.array,
  grandEcran: PropType.bool,
  location: PropType.object,
  routes: PropType.array,
  sidebarState: PropType.object,
};
