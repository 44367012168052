import { Grid } from "@material-ui/core";
import React from "react";

import TitreSection from "../../../../../../layoutContents/Fiches/components/Details/components/TitreSection";
import CarteContactPrivilegie from "./carteContactPrivilegie";

const ContactsPrivilegies = ({ conseiller }) => {
  if (conseiller.responsableRegional === "" && conseiller.coach === "")
    return <></>;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TitreSection
          titre={
            conseiller.responsableRegional === "" || conseiller.coach === ""
              ? "Contact privilégié"
              : "Contacts privilégiés"
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {conseiller.responsableRegional !== "" && (
            <Grid item xs={12} md={6}>
              <CarteContactPrivilegie
                contact={conseiller.responsableRegional}
                type={"Responsable Régional"}
              />
            </Grid>
          )}
          {conseiller.coach !== "" && (
            <Grid item xs={12} md={6}>
              <CarteContactPrivilegie
                contact={conseiller.coach[0]}
                type={"Coach"}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactsPrivilegies;
