import { Button, Grid } from "@material-ui/core";
import { KeyboardArrowDownOutlined } from "@material-ui/icons";
import { useBuckets } from "hooks/useBuckets";
import React, { MouseEventHandler, useContext, useState } from "react";
import {
  RealEstatePropertyCard,
  RealEstatePropertyCardProps,
} from "UI/features/realEstates/organisms/RealEstatePropertyCard";
import { If } from "UI/shared/atoms/directives/If";
import { Text14pxBold } from "UI/shared/atoms/typographies/Text14pxBold";
import { InfiniteScrollList } from "UI/shared/organisms/InfiniteScrollList/InfiniteScrollList";

import { AgentRealEstatesContext } from ".";

const Preview: React.FC<{
  realEstates: RealEstatePropertyCardProps[];
}> = ({ realEstates }) => {
  return (
    <Grid container spacing={2}>
      {realEstates.map((realEstate, i) => (
        <Grid item xs={4} key={`preview-${realEstate.id}`}>
          <RealEstatePropertyCard {...realEstate} />
        </Grid>
      ))}
    </Grid>
  );
};

export const Complete: React.FC<{
  realEstates: RealEstatePropertyCardProps[];
}> = ({ realEstates }) => {
  const { loadMore } = useContext(AgentRealEstatesContext);

  const realEstatesBuckets = useBuckets<RealEstatePropertyCardProps>({
    data: realEstates,
    size: 3,
  });

  return (
    <InfiniteScrollList
      pageSize={1}
      loadMore={loadMore}
      inTabs={true}
      wrapItems={true}
    >
      {realEstatesBuckets.map((realEstateBucket, index) => (
        <Grid
          container
          spacing={2}
          key={`agent-realestates-lg-bucket-${index}`}
        >
          {realEstateBucket.map((realEstate) => (
            <Grid item xs={4} key={`agent-realestates-lg-${realEstate.id}`}>
              <RealEstatePropertyCard {...realEstate} />
            </Grid>
          ))}
        </Grid>
      ))}
    </InfiniteScrollList>
  );
};

export const AgentRealEstatesLarge: React.FC = () => {
  const [mode, setMode] = useState<"preview" | "complete">("preview");

  const seeAll: MouseEventHandler = (e) => {
    setMode("complete");
  };

  const { realEstates = [] } = useContext(AgentRealEstatesContext);

  return (
    <>
      <If condition={mode === "preview"}>
        <Preview realEstates={realEstates.slice(0, 3)} />
        <Grid container justify="center">
          <If condition={realEstates.length > 3}>
            <Button
              onClick={seeAll}
              style={{ backgroundColor: "transparent", textTransform: "none" }}
              endIcon={<KeyboardArrowDownOutlined />}
            >
              <Text14pxBold>Voir tout</Text14pxBold>
            </Button>
          </If>
        </Grid>
      </If>
      <If condition={mode === "complete"}>
        <Complete realEstates={realEstates} />
      </If>
    </>
  );
};
