import { Locations } from "components/RefinementField/types";
import { PigesSearchParams } from "services/piges";

import { slugToParams } from "./../../Pige/SearchInput/slugHandler";

export enum DisplayMode {
  List = "list",
  Gallery = "gallery",
}

export const initLocations: Locations = {
  kind: "locations",
  value: {
    departements: [],
    quartiers: [],
    regions: [],
    villes: [],
  },
};

export const initParams: PigesSearchParams = {
  page: 1,
  searchAfter: [],
  size: 20,
};

interface IState {
  paginationCall: boolean;
  currentDisplayMode: string;
  params: PigesSearchParams;
  locations: Locations;
  searchEnabled: boolean;
}

type SlugToParamsHandler = (
  slug: string
) => { params: PigesSearchParams; locations: Locations };

export class ListState implements IState {
  paginationCall: boolean;
  currentDisplayMode: string;
  params: PigesSearchParams;
  locations: Locations;
  searchEnabled: boolean;

  slugToParams: SlugToParamsHandler = slugToParams;

  constructor(slug: string) {
    this.paginationCall = false;
    this.currentDisplayMode = DisplayMode.List;
    this.slugToParams = slugToParams;
    this.searchEnabled = false;
    this.params = this.initParams(slug);
    this.locations = this.initLocations(slug);
  }
  private initParams(slug: string): PigesSearchParams {
    if (slug !== "full-list")
      return { ...initParams, ...slugToParams(slug).params };

    return initParams;
  }
  private initLocations(slug: string): Locations {
    if (slug !== "full-list") return slugToParams(slug).locations;
    return initLocations;
  }

  static nextLocations = (
    state: ListState,
    valuePayload: Partial<Locations["value"]>
  ) => {
    return {
      ...state.locations,
      value: {
        ...state.locations.value,
        ...valuePayload,
      },
    } as Locations;
  };

  static nextParams = (
    state: ListState,
    paramsPayload: Partial<PigesSearchParams>
  ) => {
    return {
      ...state.params,
      ...paramsPayload,
    } as PigesSearchParams;
  };

  static next: (
    payload: Partial<ListState>
  ) => React.SetStateAction<ListState> = (payload) => (prev) =>
    ({
      ...prev,
      ...payload,
    } as ListState);
}
