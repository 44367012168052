import React from "react";
import { ClientDto } from "services/request-for-informations/dto/ClientDto";
import { NameInformation } from "UI/shared/atoms/NameInformation";
import { Source } from "UI/shared/molecules/Source";
import { EmailLink } from "UI/shared/molecules/EmailLink";
import { PhoneLink } from "UI/shared/molecules/PhoneLink";
import { TopicTitle } from "../molecules/TopicTitle";

export interface RequestForInformationContactProps {
  source: string;
  client: ClientDto;
  emailSender: string;
}

export const RequestForInformationContact: React.FC<RequestForInformationContactProps> = ({
  source,
  client,
  emailSender,
}) => {
  return (
    <div>
      <TopicTitle label={"contact"} />
      <Source title={source} bgColor={"#BFB7B7"} />
      <NameInformation value={emailSender} />
      <PhoneLink value={client.phoneNumber || ""} />
      <EmailLink value={client.email || ""} />
    </div>
  );
};
