import { EmailOutlined } from "@material-ui/icons";
import { LinksList } from "UI/shared/molecules/LinksList";
import React from "react";
import { useServices } from "services";

interface Email {
  value: string;
  kind: "Professional" | "Personal";
}

export interface EmailsListProps {
  value: Email[];
}

export const EmailsList: React.FC<EmailsListProps> = ({ value }) => {
  const { l10n } = useServices();
  return (
    <LinksList
      icon={EmailOutlined}
      tooltip={l10n.t("Envoyer un mail")}
      value={value.map((email) => ({
        content: email.value,
        href: `mailto:${email.value}`,
      }))}
    />
  );
};
