import { isMultipleText } from "./../../../utils/adaptedRefinements/multipleText/index";
import { getCriteriaKeys } from "utils/adaptedRefinements";
import {
  AdaptedRefinements,
  MultipleText,
  MultipleValue,
} from "../../../components/RefinementField/types";
import { ProfilesCriteria } from "../../../types";
import { ProfilesFiltersDto } from "../dto/ProfilesFiltersDto";
import { isDefaultValue } from "./isDefaultValue";

const onlyRefined = (multipleValue: MultipleValue<string>) =>
  multipleValue.isRefined;
const toValue = (multipleValue: MultipleValue<string>) => multipleValue.value;
export const getFiltersFromRefinements = (
  adaptedRefinements: AdaptedRefinements<ProfilesCriteria>
) => {
  const criteriaKeys = getCriteriaKeys(adaptedRefinements);
  return criteriaKeys.reduce((current, criteria) => {
    if (isDefaultValue(adaptedRefinements[criteria].refinement.value))
      return current;

    if (isMultipleText(adaptedRefinements, criteria)) {
      const refinedValues = (adaptedRefinements[criteria]
        .refinement as MultipleText).value
        .filter(onlyRefined)
        .map(toValue);

      return {
        ...current,
        [criteria]: refinedValues,
      };
    }
    return {
      ...current,
      [criteria]: adaptedRefinements[criteria].refinement.value,
    };
  }, {}) as ProfilesFiltersDto;
};
