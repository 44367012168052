import { Chip, makeStyles, RadioGroup } from "@material-ui/core";
import {
  BusinessCenter,
  BusinessCenterOutlined,
  HomeWork,
  HomeWorkOutlined,
} from "@material-ui/icons";
import { SelectOption, SelectOptionProps } from "UI/shared/atoms/SelectOptions";
import { includes } from "lodash";
import React, { FC, useContext } from "react";

import { useServices } from "../../../../../../services";
import { AccountType } from "../models/accountType";
import { PrintingContext } from "../PrintableContactSheet/components/PrintablePropertySearchProfiles/PrintablePropertySearchProfiles";
import { ComingIcon } from "./components/CustomProfilRadio";
import { SectionTitle } from "./components/SectionTitle";

const useStyles = makeStyles(() => ({
  customer: {
    backgroundColor: "#47B0B5",
    color: "white",
    marginRight: 5,
  },
  invertedCustomer: {
    backgroundColor: "white",
    border: "1px solid #47B0B5",
    color: "#47B0B5",
    marginRight: 5,
  },
  invertedPro: {
    backgroundColor: "white",
    border: "1px solid #53868D",
    color: "#53868D",
    marginRight: 5,
  },
  invertedSeller: {
    backgroundColor: "white",
    border: "1px solid #004680",
    color: "#004680",
    marginRight: 5,
  },
  label: {
    fontSize: "0.6rem",
    marginLeft: 7,
    marginRight: 7,
  },
  pro: {
    backgroundColor: "#53868D",
    color: "white",
    marginRight: 5,
  },
  seller: {
    backgroundColor: "#004680",
    color: "white",
    marginRight: 5,
  },
}));

export interface AccountChipProps {
  account: AccountType;
  business: BusinessType;
}

export type BusinessType =
  | "LifeAnnuity"
  | "Purchase"
  | "Rent"
  | "Sale"
  | "SearchLifeAnnuity"
  | "Rental";

export const AccountChip: FC<AccountChipProps> = ({ account, business }) => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();
  const { isPrintingMode } = useContext(PrintingContext);
  const sellerStyle = isPrintingMode ? classes.invertedSeller : classes.seller;
  const customerStyle = isPrintingMode
    ? classes.invertedCustomer
    : classes.customer;
  const proStyle = isPrintingMode ? classes.invertedPro : classes.pro;
  return (
    <Chip
      label={`${t(`account.${account}`)} / ${t(`business.${business}`)}`}
      size="small"
      className={
        business === "Sale"
          ? sellerStyle
          : account === "Customer"
          ? customerStyle
          : proStyle
      }
      classes={{ label: classes.label }}
    />
  );
};

export const SelectAccountType: React.FC<{
  value?: AccountType;
  onChange: (value: AccountType) => void;
  disabledOptions?: AccountType[];
}> = ({ value, onChange, disabledOptions }) => {
  const {
    l10n: { t },
  } = useServices();
  const options: SelectOptionProps<AccountType>[] = [];

  if (includes(disabledOptions, "Customer")) {
    options.push({
      checkedIcon: <ComingIcon />,
      disabled: true,
      icon: <ComingIcon />,
      value: "Customer",
    });
  } else {
    options.push({
      checkedIcon: <HomeWorkOutlined />,
      icon: <HomeWork />,
      value: "Customer",
    });
  }

  if (includes(disabledOptions, "Professional")) {
    options.push({
      checkedIcon: <ComingIcon />,
      disabled: true,
      icon: <ComingIcon />,
      value: "Professional",
    });
  } else {
    options.push({
      checkedIcon: <BusinessCenterOutlined />,
      icon: <BusinessCenter />,
      value: "Professional",
    });
  }
  return (
    <>
      <SectionTitle title="Quel est votre projet ?" />
      <RadioGroup
        row
        aria-label="type de profil"
        value={value}
        onChange={(_) => onChange(_.target.value as AccountType)}
      >
        {options.map(({ value, icon, checkedIcon, disabled }) => (
          <SelectOption
            key={value}
            icon={icon}
            checkedIcon={checkedIcon}
            value={value}
            label={t(`account.${value}`)}
            disabled={disabled}
            itemStyle={{ margin: "0px 20px", width: "40px" }}
          />
        ))}
      </RadioGroup>
    </>
  );
};
