import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import { useServices } from "../../../../../services";

const useStyles = makeStyles(() => ({
  background: {
    backgroundColor: "#FFFFFF",
    border: "solid 1px #7F4B98",
    marginTop: 2,
  },
  chipLabel: {
    color: "#7F4B98",
    fontSize: "0.9rem",
    fontWeight: "bold",
  },
}));

const CommerceEntreprise = () => {
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles();

  return (
    <Chip
      label={t("C&E")}
      size="small"
      className={classes.background}
      classes={{ label: classes.chipLabel }}
    />
  );
};

export default CommerceEntreprise;
