import { MatchingProfileRequestDto } from "services/matching/MatchingProfileRequestDto";

import { SaleHouseFeaturesDto } from "../../../../models/Proposal/ForSale/House/saleHouseFeaturesDto";

export default (features: SaleHouseFeaturesDto): MatchingProfileRequestDto => {
  return new MatchingProfileRequestDto({
    budget: features.financialInfo.price,
    cityId: features.address.city?.id,
    constructionYear: features.propertyInfo.buildingInfo.yearBuilt,
    nbRooms: features.parts.summaries.rooms?.count,
    profileKind: "Purchase",
    propertyKind: "House",
    surface: features.propertyInfo.squareMeter,
    withElevator: features.amenities
      ? features.amenities.indexOf("Elevator") >= 0
      : undefined,
    withParking: features.parts?.summaries?.parkings?.count
      ? features.parts.summaries.parkings.count > 0
      : undefined,
    withPool: features.amenities
      ? features.amenities.indexOf("Pool") >= 0
      : undefined,
  });
};
