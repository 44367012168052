import { ForRentParkingProfileWriteDto } from "./ForRentParkingProfileWriteDto";
import { AccountType } from "../../../accountType";
import { MaturityType } from "../../../maturityType";
import {
  emptyFeatures,
  ForRentParkingFeaturesDto,
} from "./ForRentParkingFeaturesDto";
import { ProfileOriginType } from "../../../profileOriginType";
import { Maybe } from "types/utilityTypes";

export interface ForRentParkingProfileReadDto {
  reference: string;
  kind: "ForRentParkingProfile";
  account: AccountType;
  maturity: MaturityType;
  features: ForRentParkingFeaturesDto;
  id: string;
  creationDate: Date;
  lastModificationDate: Date;
  archivingDate?: Date;
  notes: string;
  origin?: string;
}

export class ForRentParkingFeaturesDtoBuilder {
  static create(
    account: AccountType,
    initial?: ForRentParkingProfileReadDto
  ): ForRentParkingProfileWriteDto {
    return {
      account,
      creationDate: new Date(),
      maturity: initial?.maturity || "None",
      notes: initial?.notes || "",
      origin: (initial?.origin as Maybe<ProfileOriginType>) || "TXProfile",
      features: initial?.features || emptyFeatures(),
      reference: initial?.reference || "",
    };
  }
}
