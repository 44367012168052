import { Grid, makeStyles, Paper } from "@material-ui/core";
import { MailOutlined } from "@material-ui/icons";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { ITheme } from "theme/ts/main";
import { RealEstatesSharingModalHeader } from "UI/features/profiles/organisms/RealEstatesSharingModalHeader";
import { SharingAgentRealEstates } from "UI/features/profiles/templates/realEstates/sharingAgentRealEstates";
import { SharingNetworkRealEstates } from "UI/features/profiles/templates/realEstates/sharingNetworkRealEstates";
import { ButtonWithStartIcon } from "UI/shared/atoms/buttons/ButtonWithStartIcon";
import { Column } from "UI/shared/atoms/containers/Column";
import { Row } from "UI/shared/atoms/containers/Row";
import { SelectedItems } from "UI/shared/atoms/SelectedItems";
import { HorizontalSpace } from "UI/shared/atoms/spacing/HorizontalSpace";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import { Text12pxBold } from "UI/shared/atoms/typographies/Text12pxBold";
import { LabelledToniqueCheckbox } from "UI/shared/molecules/LabelledToniqueCheckbox";
import { SharingRealEstatesModalContext } from ".";

const useStyles = makeStyles((theme: ITheme) => ({
  modalSubHeader: {
    padding: theme.spacing(2),
    background: theme.palette.greyLight,
  },
  progressBarLeft: {
    background: theme.palette.tonique,
  },
  progressBarRight: {
    background: theme.palette.greyLight,
  },
}));

export const SelectionStep: React.FC = () => {
  const {
    byAgent,
    byNetwork,
    handleStepChange,
    selectionTotal,
    closeModal,
  } = useContext(SharingRealEstatesModalContext);

  const classes = useStyles();
  const toMailStep = useCallback(() => {
    handleStepChange("mail");
  }, [handleStepChange]);

  const isNextStepDisabled = useMemo(() => selectionTotal === 0, [
    selectionTotal,
  ]);

  return (
    <>
      <RealEstatesSharingModalHeader close={closeModal} />
      <Grid container style={{ height: 3 }}>
        <Grid item xs={6} className={classes.progressBarLeft}></Grid>
        <Grid item xs={6} className={classes.progressBarRight}></Grid>
      </Grid>
      <Grid container>
        <Grid xs={12} className={classes.modalSubHeader}>
          <Text12pxBold>
            Sélectionnez les biens dont vous souhaitez partager les fiches
            commerciales par email
          </Text12pxBold>
          <LabelledToniqueCheckbox
            checked={false}
            onChange={() => {}}
            label={"Voir uniquement les fiches non partagées"}
          />
        </Grid>
      </Grid>
      <VerticalSpace size={8} />
      <Grid container style={{ padding: 16 }}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <SharingAgentRealEstates {...byAgent} />
            </Grid>
            <Grid item xs={12}>
              <VerticalSpace size={32} />
            </Grid>
            <Grid item xs={12}>
              <SharingNetworkRealEstates {...byNetwork} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Paper elevation={2}>
        <Grid container style={{ padding: 16 }}>
          <Grid item xs={12}>
            <Row rowJustify="flex-end" rowAlignItems="center">
              <SelectedItems count={selectionTotal} name={"Bien"} />
              <HorizontalSpace size={8} />
              <ButtonWithStartIcon
                bold={false}
                noUppercaseLabel={true}
                icon={<MailOutlined />}
                label={"Visualiser l'email"}
                disabled={isNextStepDisabled}
                onClick={toMailStep}
              />
            </Row>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
