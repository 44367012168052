import { MenuItem, TextField, Typography } from "@material-ui/core";
import React from "react";

import { useServices } from "../../../../../../../services";
import { ExposureType } from "../../models/exposureType";
import useStyles from "../components/styles";

export const SelectExposureType: React.FC<{
  value?: ExposureType;
  onChange: (value: ExposureType) => void;
}> = ({ value, onChange }) => {
  const {
    l10n: { t },
  } = useServices();

  const classes = useStyles();

  const options: ExposureType[] = [
    "Southern",
    "Northern",
    "Eastern",
    "Western",
    "SouthNorthCrossingFacing",
    "EastWestCrossingFacing",
  ];

  return (
    <TextField
      className={classes.textField}
      style={{ marginBottom: "8px" }}
      variant="outlined"
      label="Exposition"
      select
      name={"exposition"}
      value={value}
      onChange={(_) => onChange(_.target.value as ExposureType)}
    >
      {options.map((_) => (
        <MenuItem value={_} key={_}>
          <Typography variant="body2">{t(`exposure.${_}`)}</Typography>
        </MenuItem>
      ))}
    </TextField>
  );
};
