import { makeStyles } from "@material-ui/core";
import { Title } from "UI/shared/atoms/Title";
import React from "react";

export interface BlocContainerProps {
  title?: string;
  paddingTop?: number;
}
const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingTop: ({ paddingTop }: BlocContainerProps) => paddingTop,
  },
  row: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
}));

export const BlocContainer: React.FC<BlocContainerProps> = ({
  title,
  children,
  paddingTop,
}) => {
  const classes = useStyles({ paddingTop });
  return (
    <div className={classes.container}>
      {title && <Title title={title} />}
      {React.Children.map(children, (el) => (
        <div key={el?.toString()} className={classes.row}>
          {el}
        </div>
      ))}
    </div>
  );
};
