import { useCallback } from "react";
import { MaturityType } from "routes/app/contacts/contact/components/models/maturityType";
import { ProfileAddressDto } from "routes/app/contacts/contact/components/models/profileAddressDto";
import { ForRentParkingFinancialInfo } from "routes/app/contacts/contact/components/models/Proposal/ForRent/Parking/ForRentParkingFinancialInfoDto";
import { ParkingAmenityType } from "../../../../../../models/Proposal/Features/Parking/parkingAmenityType";
import { ParkingProposalKind } from "../../../../../../models/Proposal/Features/Parking/parkingInfoKind";
import { ParkingDimensionProps } from "../../../../Features/Parking/ParkingSaleDimension";
import {
  FormValidity,
  PropertyInfoCheckBox,
  PropertyInfoDimension,
  StateHandler,
} from "../types";
import {
  addressUpdater,
  amenitiesUpdater,
  financialInfoUpdater,
  formValidityUpdater,
  maturityTypeUpdater,
  notesUpdater,
  parkingKindUpdater,
  propertyInfoCheckboxUpdater,
  propertyInfoUpdater,
} from "./updaters";
export const useChanges = (
  setPayload: (value: StateHandler) => void,
  setFormValidity: (value: React.SetStateAction<FormValidity>) => void
) => {
  const onNotesChange = useCallback(
    (notes: string) => {
      setPayload(notesUpdater(notes));
    },
    [setPayload]
  );

  const onAmenitiesChange = useCallback(
    (parkingAmenities: ParkingAmenityType[]) => {
      setPayload(amenitiesUpdater(parkingAmenities));
    },
    [setPayload]
  );

  const onAddressChange = useCallback(
    (address: ProfileAddressDto, isValid: boolean) => {
      setFormValidity(formValidityUpdater("address", isValid));
      setPayload(addressUpdater(address));
    },
    [setPayload, setFormValidity]
  );

  const onMaturityTypeChange = useCallback(
    (maturityType: MaturityType) => {
      setPayload(maturityTypeUpdater(maturityType));
    },
    [setPayload]
  );

  const onParkingKindChange = useCallback(
    (parkingKind: ParkingProposalKind) => {
      setPayload(parkingKindUpdater(parkingKind));
    },
    [setPayload]
  );

  const onFinancialInfoChange = useCallback(
    (financialInfo: ForRentParkingFinancialInfo, isValid: boolean) => {
      setFormValidity(formValidityUpdater("financialInfo", isValid));
      setPayload(financialInfoUpdater(financialInfo));
    },
    [setPayload, setFormValidity]
  );

  const onDimensionChange = useCallback(
    (info: PropertyInfoDimension) => (
      dimension: ParkingDimensionProps,
      isValid: boolean
    ) => {
      setFormValidity(formValidityUpdater("parkingPropertyInfo", isValid));
      setPayload(propertyInfoUpdater(dimension.value, info));
    },
    [setPayload, setFormValidity]
  );

  const onCheck = useCallback(
    (info: PropertyInfoCheckBox) => {
      setPayload(propertyInfoCheckboxUpdater(info));
    },
    [setPayload]
  );

  return {
    onAddressChange,
    onAmenitiesChange,
    onMaturityTypeChange,
    onNotesChange,
    onParkingKindChange,
    onFinancialInfoChange,
    onDimensionChange,
    onCheck,
  };
};
