import React from "react";
import { useServices } from "services";
import { Text16px } from "UI/shared/atoms/typographies/Text16px";
import { formatPrix } from "utils/formatage";

export interface MonthIncomeProps {
  income: number;
}

export const MonthIncome: React.FC<MonthIncomeProps> = ({ income }) => {
  const {
    l10n: { t },
  } = useServices();
  return (
    <Text16px>
      {`(${t("rente")} ${formatPrix(income)})`.replace(" ", "\xa0")}
    </Text16px>
  );
};
