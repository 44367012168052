import { makeStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import React, { ReactElement } from "react";

export const HoverLessMenuItem = React.forwardRef<ReactElement, any>(
  (props, ref) => {
    const useStyles = makeStyles(() => ({
      menuItem: {
        "&:hover": {
          backgroundColor: "transparent",
        },
        padding: 4,
      },
    }));

    const classes = useStyles();

    return (
      <MenuItem {...props} classes={{ root: classes.menuItem }} innerRef={ref}>
        {props.children}
      </MenuItem>
    );
  }
);
