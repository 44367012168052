import { IconButton, Tooltip } from "@material-ui/core";
import { Launch } from "@material-ui/icons";
import EventNoteIcon from "@material-ui/icons/EventNote";
import React from "react";
import { useServices } from "services";
import { usePigeEvents } from "services/piges/events";
import { EventsContext } from "../../FicheBienPige";
import { AdActions } from "../../FicheBienPige/AdActions/AdActions";

export const EventsAd: React.FC<{ id: string; withText?: boolean }> = ({
  id,
  withText,
}) => {
  const {
    l10n: { t },
    modal,
  } = useServices();

  const { add, update, deleteAd, events } = usePigeEvents(id);

  const onCancelModal = () => {
    modal.close();
  };

  const onClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    modal.show(
      <EventsContext.Provider value={{ add, deleteAd, events, update }}>
        <AdActions
          annonceId={id}
          estActive={true}
          simplified={true}
          onCancelModal={onCancelModal}
        />
      </EventsContext.Provider>
    );
  };

  return !withText ? (
    <>
      <Tooltip title={t("Ajouter une action de suivi")}>
        <IconButton onClick={onClick}>
          <Launch />
        </IconButton>
      </Tooltip>
    </>
  ) : (
    <>
      <div onClick={onClick}>
        <IconButton>
          <EventNoteIcon />
        </IconButton>
        <span>{t("Suivi")}</span>
      </div>
    </>
  );
};
