import {
  IconButton,
  makeStyles,
  Paper,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/EditOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, { useCallback, useState } from "react";

const styles = makeStyles((theme) => ({
  header: {
    padding: 0,
  },
  content: {
    padding: "16px 24px",
  },
}));

const useStyles = makeStyles((theme) => ({
  arrows: {
    alignSelf: "center",
  },
  root: {
    background: (theme.palette as any).pinBackground,
    display: "flex",
    flexDirection: "column",
    minHeight: "92px",
    padding: "10px",
  },
  textArea: {
    "-moz-box-shadow": "none",
    "-webkit-box-shadow": "none",
    "background-color": "#dcf1ed",
    blockSize: "inherit",
    border: "dotted",
    borderColor: "#9e9e9e",
    borderWidth: "thin",
    "box-shadow": "none",
    marginLeft: 8,
    outline: "none",
    overflow: "auto",
    resize: "none",
  },
  title: {
    marginBottom: 15,
  },
}));

export interface NotesProps {
  notes: string;
  deleteNotes: () => void;
  editNotes: (note: string) => void;
  t: (value: string) => string;
}

const Notes: React.FC<NotesProps> = (notesProps) => {
  const classes = useStyles();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isInEditMode, setEditMode] = useState(false);
  const [notes, setNotes] = useState("");
  const updateNotes = useCallback(() => {
    if (notes !== "") {
      notesProps.editNotes(notes);
    } else {
      notesProps.deleteNotes();
    }
  }, [notes, notesProps]);

  const deleteNotes = useCallback(() => {
    notesProps.deleteNotes();
  }, [notesProps]);

  const renderContent = () => (
    <>
      <Typography
        style={{ overflowWrap: "break-word", whiteSpace: "pre-line" }}
        variant="body1"
        noWrap
      >
        {isCollapsed ? (
          notesProps.notes.length > 45 ? (
            `${notesProps.notes.substring(45, 0)}...`
          ) : notesProps.notes.length > 0 ? (
            `${notesProps.notes}`
          ) : (
            notesProps.t("SaisirNotes")
          )
        ) : isInEditMode ? (
          <TextareaAutosize
            placeholder={notesProps.t("SaisirNotes")}
            className={classes.textArea}
            defaultValue={notesProps.notes}
            onChange={(ev) => {
              setNotes(ev.target.value);
            }}
          />
        ) : (
          `${notesProps.notes}`
        )}
      </Typography>
      {notesProps.notes.length < 45 ? null : isCollapsed ? (
        <ExpandMore
          className={classes.arrows}
          onClick={() => setIsCollapsed(false)}
        />
      ) : (
        <ExpandLess
          className={classes.arrows}
          onClick={() => {
            setIsCollapsed(true);
            setEditMode(false);
          }}
        />
      )}
    </>
  );

  return (
    <Paper elevation={1} className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        <div>
          <span style={{ display: "inline-flex", verticalAlign: "middle" }}>
            <LockOutlinedIcon fontSize="small" />
            <div style={{ marginRight: 8 }} />
            {notesProps.t("Notes")}
          </span>
          {!isInEditMode ? (
            <span
              style={{
                float: "right",
                right: "0px",
              }}
            >
              <Tooltip title={notesProps.t("ModifierNotes")} placement="right">
                <IconButton
                  style={{ cursor: "pointer", padding: "0 0 0 0" }}
                  aria-label="edit"
                  onClick={() => {
                    setEditMode(true);
                    setIsCollapsed(false);
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </span>
          ) : (
            <span
              style={{
                float: "right",
                right: "0px",
              }}
            >
              <Tooltip title={notesProps.t("SupprimerNotes")} placement="right">
                <IconButton
                  style={{ cursor: "pointer", padding: "0 0 0 0" }}
                  aria-label="Close"
                  onClick={() => {
                    setEditMode(false);
                    setIsCollapsed(true);
                    deleteNotes();
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title={notesProps.t("ValiderNotes")} placement="right">
                <IconButton
                  style={{ cursor: "pointer", padding: "0 0 0 0" }}
                  aria-label="save"
                  onClick={() => {
                    setEditMode(false);
                    setIsCollapsed(true);
                    updateNotes();
                  }}
                >
                  <DoneIcon />
                </IconButton>
              </Tooltip>
            </span>
          )}
        </div>
      </Typography>
      {renderContent()}
    </Paper>
  );
};

export default Notes;
