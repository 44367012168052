/* eslint-disable */
export const fr = {
  Arranged: "Aménagé",
  ATraiter: "A traiter",
  AccepteEtreSollicite: "Accepte d'être sollicité(e)",
  Accepted: "Accepté(e)",
  Achat: "Achat",
  AchatCession: "Achat - Cession",
  Acquereur: "Acquéreur",
  Active: "Actif",
  ActiverProfil: "Activer profil",
  Adresse: "Adresse",
  AdressePresenteDansContacts:
    "Cette adresse email est déjà présente dans vos contacts",
  AdressePresentePourContact:
    "Cette adresse email est déjà présente pour un autre contact",
  AlgolianomVilleCP: "Lieu",
  Annee: "Année",
  AnneeDeConstruction: "Année de contruction",
  AnneeEnCours: "Année en cours",
  Aout: "Août",
  Appartement: "Appartement",
  AppelTelephonique: "Appel",
  Appeler: "Appeler",
  Archive: "Archivé",
  ArchiveContactButton: "Archiver",
  Archived: "Archivé",
  ArchiverProfil: "Archiver profil",
  Au: "Au",
  Aucun: "Aucun",
  AucunContactTrouve: "Aucun contact trouvé",
  AucunResultats: "Aucun résultats",
  Autre: "Autre",
  AutresOptionsContact: "Autres options",
  Avr: "Avr",
  BU: "Réseau",
  Buildable: "Constructible",
  Biens: "Biens",
  BiensActifs: "Biens actifs",
  BiensVendus: "Biens vendus",
  Bu: "Réseau",
  CaActesHT: "CA Actes HT",
  CaActesTTC: "CA Actes TTC",
  CaCompromisHT: "CA Compromis HT",
  CaCompromisTTC: "CA Compromis TTC",
  CaTTC: "CA TTC en K€",
  Actes: "Actes",
  // "NombreActes": "NOMBRE D'ACTES",
  // "NombreCompromis": "NOMBRE DE COMPROMIS",
  // "AnneeN": "année N",
  // "AnneeNMoins1": "année N-1",
  // "% DE MANDATS EXCLUSIFS": "% DE MANDATS EXCLUSIFS",
  // "TAUX DE TRANSFORMATION": "TAUX DE TRANSFORMATION",
  // "% DE MANDATS ELECTRONIQUES": "% DE MANDATS ELECTRONIQUES",
  // "MandatsActifs": "MANDATS ACTIFS",
  // "HonorairesMoyens": "HONORAIRES MOYENS",
  Contacts_V2: "Mes contacts",
  Sept: "Sept",

  APPARTEMENT: "Appartement",

  Publications: "Publications",

  Reporting: "Reporting",

  Canal: "Canal de diffusion",
  CessionDroitAuBail: "Cession droit au bail",
  Chambre: "Chambre",
  Chambres: "Chambre(s)",
  Chaud: "Chaud",
  ChoisirClient: "Choisir un client...",
  ChoisirPasserelle: "Choisir la passerelle...",
  Civilite: "Civilité",
  ClassementDepartemental: "Classement départemental",
  ClassementNational: "Classement national",
  ClassementRegional: "Classement régional",
  Classements: "Classements",
  Clics: "Vues",
  Client: "Client",
  CodePostal: "Code postal",
  Commentaire: "Commentaire",
  Commune: "Commune",
  Compromis: "Compromis",
  ConnecteEnTantQue: "Connecté en tant que",
  Conseiller: "Conseiller",
  ConsentStatus: "Consentement",
  ContactSource: {
    Hunters: "Chasseur immo",
    TXMobile: "TX Pocket",
    TXContact: "TX Contact",
    Tremplin: "iBoost - ETO",
    Unknown: "Non défini",
    TXPige: "TX Pige",
    TXRequestForInformations: "TX DR",
  },
  Contacts: "Mes contacts",
  Portfolio: {
    Criteria: {
      searchTerm: "Rechercher",
      profileKinds: "Profils",
      sources: "Source",
      consentStatuses: "Consentement",
      locations: "Localisations",
      withArchivedContacts: "Inclure les éléments archivés",
      updateDate: "Mise à jour",
    },
    EntityName: {
      plural: "contacts",
      singular: "contact",
    },
  },
  DateCreation: "Date de création",
  DateDArret: "Date d'arrêt",
  DateDeDepart: "Date de départ",
  DateModification: "Date de modification",
  DatesLibre: "Dates libres",
  Dec: "Déc",
  Declined: "Refusé(e)",
  DelaiMoyenDeVente: "Délais moyen de vente",
  DelaisVente: "Délai de vente",
  Demandes: "Demandes",
  Departement: "Département",
  DetailsAnnonce: "Détails de l'annonce",
  Diagnostique: "Diagnostique",
  Dont: "Dont",
  Du: "Du",
  EffacerContact: "Effacer ce contact",
  EffacerFiltres: "Effacer les filtres",
  Email: "Email",
  EmailKind: {
    Personal: "Personnel",
    Professional: "Professionnel",
  },
  EmailsExistantsDansRepertoire: "Emails existants dans votre répertoire",
  Eme: "ème",
  "En Attente": "En attente",
  EnCours: "En cours",
  Enregistrer: "Enregistrer",
  EnvoiCourrier: "Courrier",
  EnvoiMail: "Mail",
  EnvoiSms: "SMS",
  EnvoyerEmail: "Envoyer un email",
  ErreurRecuperationDonnees: "Impossible de récupérer les données",
  ErreurRecuperationFiltres: "Impossible de récupérer les filtres",
  EssayezRafraichir: "Essayez de rafraichir la page",
  Exclusif: "Exclusif",
  ExportVCardButton: "Exporter la vCard",
  ExporterVersTremplin: "Exporter vers Tremplin",
  "FONDS DE COMMERCE": "Fonds de commerce",
  FONDS_COMMERCE: "Fonds de commerce",
  FamilleBien: "Famille de biens",
  FamilleBiens: "Famille de biens",
  FavorisError:
    "Une erreur est survenue, l'annonce n'a pas été ajoutée aux favoris",
  Female: "Madame",
  Fev: "Fév",
  FicheContact: "Fiche contact",
  FiltrerPar: "Filtrer par",
  FiltrerParPeriode: "Filtrer par période",
  FondsCommerce: "Fonds de commerce",
  FondsDeCommerce: "Fonds commerce",
  FormatDateLocal: "FR-fr",
  FormatInvalide: "Format invalide",
  Froid: "Froid",
  HistoriqueDesBiensVendus: "Biens vendus",
  HonoraireMoyen: "Taux de commission",
  IMMEUBLE: "Immeuble",
  Immeuble: "Immeuble",
  Imprimer: "Imprimer",
  Invitations: "Invitations",
  Jan: "Jan",
  Jours: "Jours",
  Jui: "Jui",
  Juin: "Juin",
  "LOCAL/BUREAU": "Local/Bureau",
  LOCAL_BUREAU: "Local/Bureau",
  LienAnnonce: "Lien annonce",
  Local: "Local",
  LocalBureau: "Local / Bureau",
  Location: "Location",
  Logout: "Déconnexion",
  Leisure: "Loisir",
  M: "Monsieur",
  "M.": "Monsieur",
  MAISON: "Maison",
  Madame: "Mme",
  Mademoiselle: "Mlle",
  Mai: "Mai",
  Maison: "Maison",
  Male: "Monsieur",
  Mandat: "Mandat",
  MandatExclusifs: "Mandat(s) exclusif(s)",
  Mandats: "Mandats",
  MandatsActifs: "Mandat(s) actif(s)",
  MandatsExcluPris: "Mandats Exclu. rentrés",
  MandatsPris: "Mandat(s) rentré(s)",
  Mar: "Mar",
  Max: "Max.",
  MesContacts: "Mes contacts",
  Meuble: "Meublé",
  Min: "Min.",
  MiseEnLocation: "Mise en location",
  Mme: "Mme",
  ModifierContact: "Modifier",
  ModifierNotes: "Modifier vos notes",
  ModifierProfil: "Modifier profil",
  MoisEnCours: "Mois en cours",
  Monsieur: "M.",
  Moyenne: "Moyenne",
  NAcceptePasEtreSollicite: "N'accepte pas d'être sollicité(e)",
  NbChambres: "Nombre de chambre(s)",
  NbChambresRange: "Nb. de chambres",
  NbClics: "Nombre de vues",
  NbDemandes: "Nombre de demandes",
  NbPieces: "Nombre de pièce(s)",
  NbPiecesRange: "Nb. de pièces",
  NbVisites: "Nombre de clics",
  Nom: "Nom",
  NomVilleCp: "Lieu",
  NombreActes: "Nombre d'actes",
  NombreBiens: "Nombre de biens",
  NombreCompromis: "Nombre de compromis",
  NombreMandats: "Nombre de mandats",
  Non: "Non",
  NonMeuble: "Non meublé",
  NonPrisEnCharge: "Non pris en charge",
  None: "Aucun(e)",
  "Notifications.PigeFavoriteDeactivation.Description":
    "Etre prévenu(e) lorsqu'une annonce Pige en favori est désactivée.",
  "Notifications.PigeFavoriteDeactivation.Title": "Annonce désactivée",
  "Notifications.PigeFavoritePriceReduction.Description":
    "Etre prévenu(e) lorsqu'une annonce Pige en favori subit une baisse de prix.",
  "Notifications.PigeFavoritePriceReduction.Title": "Baisse de prix",
  "Notifications.PigeNewIndividualAdOnWorkingArea.Description":
    "Etre prévenu(e) lorsqu'une nouvelle annonce est publiée par un particulier sur ma zone de travail.",
  "Notifications.PigeNewIndividualAdOnWorkingArea.Title":
    "Nouvelle annonce particulier",
  "Notifications.PigeNewProfessionalAdOnWorkingArea.Description":
    "Etre prévenu(e) lorsqu'une nouvelle annonce est publiée par un professionnel de l'immobilier (agence, mandataire) sur ma zone de travail.",
  "Notifications.PigeNewProfessionalAdOnWorkingArea.Title":
    "Nouvelle annonce professionnel",
  "Notifications.PigeNewWorkingArea.Description":
    "Etre prévenu(e) lorsqu'une nouvelle annonce est publiée sur ma zone de travail.",
  "Notifications.PigeNewWorkingArea.Title": "Nouvelle annonce",
  NouvelleTentative: "Nouvelle tentative",
  Nov: "Nov",
  NotBuildable: "Non constructible",
  NumeroPresentDansContact:
    "Ce n° de téléphone est déjà présent dans vos contacts",
  NumeroPresentPourContact:
    "Ce n° de téléphone est déjà présent pour un autre contact",
  NumerosExistantsDansRepertoire: "N° de tél. existants dans votre répertoire",
  Obligatoire: "Obligatoire",
  Occupe: "Occupé",
  Oct: "Oct",
  Office365Error:
    "Une erreur est survenue, l'événement outlook n'a pas été enregistré.",
  Oui: "Oui",
  PROPRIETE: "Propriété",
  Particulier: "Particulier",
  ParticulierAchat: "Particulier / Achat",
  ParticulierLocation: "Particulier / Location",
  ParticulierViager: "Particulier / Viager",
  Passerelle: "Passerelle",
  Pays: "Pays",
  Pieces: "Pièce(s)",
  PhoneKind: {
    Landline: "Fixe",
    Mobile: "Portable",
    Personal: "Personnel",
    Professional: "Professionnel",
  },
  PlusDeCriteres: "+ de critères",
  PlusDeFiltres: "+ de critères",
  Pond: "Étang",
  PourcentHonoraires: "taux de commission",
  PourcentageExclu: "% Exclusifs",
  PourcentageHonorairesMoyens: "Taux de commission",
  PourcentageMandatsElectroniques: "% mandats électroniques",
  PourcentageTransfo: "% Transfo.",
  Prenom: "Prénom",
  Prestige: "Prestige",
  PrintContactButton: "Imprimer",
  PrixMax: "Prix max",
  PrixMin: "Prix min",
  SurfaceHabitableMin: "Surface habitable min",
  SurfaceHabitableMax: "Surface habitable max",
  SurfaceTerrainMin: "Surface terrain min",
  SurfaceTerrainMax: "Surface terrain max",
  PrixMoyenM2: "Prix moyen au m2",
  PrixVenteMoyen: "Prix de vente moyen",
  Professionnel: "Professionnel",
  ProfessionnelAchatCession: "Pro / Achat-Cession",
  ProfessionnelLocation: "Pro / Location",
  Profile: {
    Criteria: {
      budgetMax: "Prix max.",
      contactName: "Nom ou prénom",
      familyKinds: "Famille de bien",
      profileKinds: "Profils",
      sources: "Source",
      reference: "Référence",
      withArchivedProfiles: "Inclure les éléments archivés",
    },
    EntityName: {
      plural: "profils",
      singular: "profil",
    },
  },
  Profiles: "TX Profils",
  ProfilsActifs: "Profils actifs",
  ProfilsArchives: "Profils archivés",
  Propriete: "Propriété",
  ReactivationProfil:
    "En réactivant ce profil, le contact associé va être réactivé lui aussi.",
  RealEstates: {
    Criteria: {
      fullText: "Rechercher",
      locations: "Localisations",
      familleBiens: "Famille de biens",
      nbPiecesRange: "Pièce(s)",
      prixMax: "Prix max",
    },
  },
  Recherche: "Recherche",
  RechercheGlobale: "Recherche rapide (biens, conseillers, contacts, ...)",
  RechercheInterAgence: "Biens disponibles",
  RechercheLocation: "Recherche location",
  Rechercher: "Rechercher",
  RecuperationFiltres: "Récupération des filtres",
  Region: "Région",
  RendezVous: "Rendez-vous",
  Reseau: "Réseau",
  Resultat: "Résultat",
  SaisirNotes: "Saisissez votre note ici...",
  Simple: "Simple",
  Stationnement: "Stationnement",
  StockCompromisEnCours: "Stock de compromis",
  SuperficieTotale: "Superficie Totale",
  SupprimerNotes: "Supprimer vos notes",
  Surface: "Surface",
  SurfaceHabitableRange: "Surface",
  SurfaceMoyenne: "Surface moyenne",
  SurfaceTerrain: "Surface terrain",
  FrontLength: "Longueur de façade côté rue",
  Synthese: "Synthèse",
  TERRAIN: "Terrain",
  TauxDeTransformation: "Taux de transformation mandats en actes",
  TelPro: "Tél pro.",
  Telephone: "Téléphone",
  TentativelyAccepted: "Accepté(e) provisoirement",
  Terrain: "Terrain",
  Tiede: "Tiède",
  ToBeTreated: "A Traiter",
  ToolboxDataView: "Données",
  ToolboxRestore: "Réinitialiser",
  ToolboxSaveAsImage: "Télécharger",
  Top20Regional: "Top 20 régional",
  Top50National: "Top 50 national",
  Top5Departemental: "Top 5 départemental",
  Transformed: "Transformée",
  TremplinXError: "Une erreur est survenue.",
  TrierPar: "Trier par",
  TrouverDesAcquereurs: "Mes biens",
  TypeAchat: "Type d'achat",
  TypeAffaire: "Type d'affaire",
  TypeDeTransaction: "Type de transaction",
  UpdateContactButton: "Modifier",
  ValiderNotes: "Validez vos notes",
  Vendeur: "Vendeur",
  Vente: "Vente",
  VenteEnViager: "Vente en viager",
  Viager: "Viager",
  ViewContactButton: "Consulter",
  Ville: "Ville",
  Visites: "Clics",
  Voir: "Voir",
  "Voir la liste": "Voir la liste",
  VoirLaFicheContact: "Consulter la fiche contact",
  account: {
    Customer: "Particulier",
    Professional: "Professionnel",
  },
  amenity: {
    BuildingCaretaker: "Gardien",
    DigitCode: "DigiCode",
    Elevator: "Ascenseur",
    Fiber: "Fibre",
    Garden: "Jardin",
    Interphone: "Interphone",
    Pool: "Piscine",
    Power: "Electricité",
    Water: "Eau",
    Light: "Lumière",
    MotorizedGate: "Portail électrique",
    ManualGate: "Portail manuel",
    ParkingBarrier: "Barrière parking",
    GroundSecurityLock: "Cadenas de sécurité au sol",
    UndergroundLocation: "Emplacement en sous-sol",
    Sported: "Arboré",
    Fencing: "Clôturé",
    SwimmingPool: "Piscinable",
    Accessibility: "Accessibilité PRM",
    Offices: "Bureaux",
    Toilettes: "Toilettes",
    ChangingRoom: "Vestiaires",
    MeetingRoom: "Salle de réunion",
    ReceptionArea: "Espace accueil",
    StaffHousing: "Logement de fonction",
    WaitingRoom: "Salle d'attente",
  },
  proximity: {
    Airports: "Aéroport",
    Animations: "Animations",
    Beach: "Plage",
    Downtown: "Centre-ville",
    Highways: "Autoroute",
    Shops: "Commerce",
    Subway: "Métro",
    Tramway: "Tramway",
    BusStation: "Bus",
    TrainStation: "Gare",
  },
  annex: {
    Balcony: "Balcon",
    Cave: "Cave",
    Garden: "Jardin",
    Terrace: "Terrasse",
  },
  buildAge: {
    After2010: "après 2010",
    All: "Tous",
    Before2000: "avant 2000",
    Between2000and2010: "2000-2010",
    New: "Neuf",
    Old: "Ancien",
  },
  business: {
    Sale: "Vente",
    LifeAnnuity: "Viager",
    Purchase: "Achat",
    Rental: "Location",
    SearchLifeAnnuity: "Viager",
    Rent: "Location",
  },
  consentStatuses: {
    Approved: "Accepte d'être sollicité(e) ",
    Denied: "N'accepte pas d'être sollicité(e)",
  },
  criteria: {
    constructionYear: "Date de construction",
    homeSize: "Surface habitable",
    lotSize: "Surface terrain",
    nbBedrooms: "Nbr de chambres",
    nbRooms: "Nbr de pièces",
    purchase: {
      build: {
        All: "Tous",
        New: "Neuf",
        Old: "Ancien",
      },
    },
    size: "Surface",
    withElevator: "Ascenseur obligatoire",
    withParking: "Parking obligatoire",
    withPremises: "Avec les murs",
    withPool: "Piscine obligatoire",
    withFiber: "Fibre",
    withSingleStorey: "Plain-pied",
  },
  exposure: {
    EastWestCrossingFacing: "Traversant est/ouest",
    Eastern: "Est",
    None: "",
    Northern: "Nord",
    SouthNorthCrossingFacing: "Traversant sud/nord",
    Southern: "Sud",
    Western: "Ouest",
  },
  false: "Non",
  familleBienNom: "Famille de biens",
  furniture: {
    All: "Tous",
    With: "Meublé",
    Without: "Non meublé",
  },
  generalStateType: {
    Good: "Bon",
    New: "Neuf",
    None: "",
    ToUpdate: "À rafraîchir",
    WithRepairs: "Travaux",
    title: "Etat général",
  },
  isNeuf: "Type d'achat",
  kitchenInfoType: {
    None: "",
    OpenPlan: "Ouverte",
    SemiOpenPlan: "Semi-ouverte",
    Separate: "Séparée",
  },
  maturity: {
    Cold: "Froid",
    Hot: "Chaud",
    None: "NC",
    Warm: "Tiède",
  },
  nbChambresRange: "Nb. de chambres",
  nbPiecesRange: "Nb. de pièces",
  parkingPropertyInfo: {
    joinOwnership: "Stationnement en copropriété",
    boxableParking: "Stationnement boxable",
  },
  parkingKind: {
    Box: "Garage fermé",
    ExteriorParking: "Parking extérieur",
    InteriorParking: "Parking couvert",
    Unknown: "Tous",
  },
  landKind: {
    All: "Tous",
    Arranged: "Aménagé",
    Buildable: "Constructible",
    NotBuildable: "Non constructible",
    Pond: "Etang",
    Leisure: "Loisir",
  },
  landRelief: {
    ToArrange: "A aménager",
    Sported: "Arboré",
    Sloping: "Pentu",
    Flat: "Plat",
  },
  landConnection: {
    Sanitation: "Assainissement",
    Water: "Eau",
    Electricity: "Electricité",
    Phone: "Téléphone",
  },
  premisesLocation: {
    CommercialZone: "Zone commerciale",
    ActivityZone: "Zone d'activité",
    Downtown: "Centre ville",
    IndustrialZone: "Zone industrielle",
    Unknown: "Inconnue",
  },
  premisesType: {
    Commercial: "Local commercial",
    Artisanal: "Local artisanal",
    Industrial: "Local industriel",
    Business: "Locaux d'activité",
    Shop: "Atelier",
    Offices: "Bureaux",
    Cellar: "Cave",
    IndustrialShed: "Hangar",
    Warehouse: "Entrepôt",
    AgriculturalShed: "Remise",
    Unknown: "Inconnu",
  },
  premisesLocationAttractiveness: {
    Zone1: "Zone 1",
    Zone1Bis: "Zone 1 bis",
    Zone2: "Zone 2",
    Unknown: "Inconnue",
  },
  premises: {
    RentedPremises: "Local loué",
  },
  Apartment: "Appartement",
  profile: {
    SaleApartmentProfile: "Appartement",
    SaleBuildingProfile: "Immeuble",
    SaleHouseProfile: "Maison",
    SaleLandProfile: "Terrain",
    SaleParkingProfile: "Stationnement",
    SalePremisesProfile: "Local/Bureau",
    SearchLifeAnnuityApartmentProfile: "Appartement",
    SearchLifeAnnuityBuildingProfile: "Immeuble",
    SearchLifeAnnuityHouseProfile: "Maison",
    SearchLifeAnnuityLandProfile: "Terrain",
    SearchLifeAnnuityPremisesProfile: "Local/Bureau",
    PurchaseApartmentProfile: "Appartement",
    PurchaseBuildingProfile: "Immeuble",
    PurchaseGoodwillProfile: "Fonds de commerce",
    PurchaseHouseProfile: "Maison",
    PurchaseLandProfile: "Terrain",
    PurchaseParkingProfile: "Stationnement",
    PurchasePremisesProfile: "Local/Bureau",
    RentApartmentProfile: "Appartement",
    RentHouseProfile: "Maison",
    RentLandProfile: "Terrain",
    RentParkingProfile: "Stationnement",
    RentPremisesProfile: "Local/Bureau",
    ForRentApartmentProfile: "Appartment",
    ForRentParkingProfile: "Stationnement",
    ForRentHouseProfile: "Maison",
  },
  Rental: "Bien - Location",
  Sale: "Bien - Vente",
  LifeAnnuity: "Bien - Viager",
  SearchLifeAnnuity: "Recherche - Viager",
  Purchase: "Recherche - Achat",
  Rent: "Recherche - Location",
  With: "Meublé",
  Without: "Non meublé",
  profileSource: {
    Hunters: "Chasseur immo",
    TXProfile: "TX Profil",
    Tremplin: "iBoost - ETO",
    TXMobile: "TX Pocket",
    TXRequestForInformations: "TX DR",
    Unknown: "Non défini",
    TXPige: "TX Pige",
  },
  profileKind: {
    Sale: "Bien - Vente",
    SearchLifeAnnuity: "Recherche - Viager",
    Purchase: "Recherche - Achat",
    Rent: "Recherche - Location",
    NoProfile: "Aucun profil",
    Rental: "Bien - Location",
  },
  profileKinds: {
    Sale: "Bien - Vente",
    SearchLifeAnnuity: "Recherche - Viager",
    Purchase: "Recherche - Achat",
    Rent: "Recherche - Location",
    NoProfile: "Aucun profil",
    Rental: "Bien - Location",
  },
  parking: {
    RentedParking: "Stationnement loué",
  },
  InteriorParking: "Parking intérieur",
  ExteriorParking: "Parking extérieur",
  Box: "Box",
  Unknown: "Non défini",
  Hunters: "Chasseur immo",
  TXProfile: "TX Profil",
  TXMobile: "TX Pocket",
  TXPige: "TX Pige",
  TXRequestForInformations: "TX DR",
  House: "Maison",
  Goodwill: "Fonds de commerce",
  property: {
    Apartment: "Appartement",
    Building: "Immeuble",
    Goodwill: "Fonds de commerce",
    House: "Maison",
    Land: "Terrain",
    Parking: "Stationnement",
    Premises: "Local/Bureau",
  },
  JoinOwnershipType: {
    NC: "",
    None: "Aucune",
    One: "1",
    Two: "2",
    Three: "3",
  },
  Building: "Immeuble",
  reseau: "Réseau",
  Land: "Terrain",
  sources: {
    Hunters: "Chasseur immo",
    TXMobile: "TX Pocket",
    TXContact: "TX Contact",
    Tremplin: "iBoost - ETO",
    Unknown: "Inconnu",
    TXPige: "TX Pige",
    TXRequestForInformations: "TX DR",
  },
  New: "neuf",
  surfaceHabitableRange: "Surface",
  "Notifications.RFINewRequestForInformation.Title":
    "Nouvelle demande de renseignements",
  telephone: "Numéro de téléphone (ex: 0619254632)",
  "Notifications.RFINewRequestForInformation.Description":
    "Etre prévenu(e) lorsqu'une nouvelle demande de renseignements a été reçue.",
  true: "Oui",
  Occupied: "Occupé",
  typeAffaire: "Type d'affaire",
  Old: "ancien",
  vacancy: {
    All: "Tous",
    Occupied: "Occupé",
    Vacant: "Libre",
  },
  Parking: "Stationnement",
  Premises: "Local/Bureau",
  RFI: "Demandes de renseignements",
  "RequestForInformations.Criteria.adReference": "Référence mandat",
  "RequestForInformations.Criteria.contact": "Contact",
  "RequestForInformations.Criteria.receivedAt": "Reception",
  "RequestForInformations.Criteria.source": "Source",
  WithWalls: "avec les murs",
  "RequestForInformations.Criteria.status": "Statut",
  "RequestForInformations.EntityName.plural": "Demandes de renseignements",
  "RequestForInformations.EntityName.singular": "Demande de renseignements",
  profileExportStatus: {
    labels: {
      Capifrance: {
        Done: "Ouvrir l'affaire iBoost",
        Failed: "Échec de l'export",
        None: "Exporter en affaire iBoost",
        InProgress: "Export en cours",
        Requested: "Export en cours",
      },
      Optimhome: {
        Done: "Ouvrir l'affaire ETO",
        Failed: "Échec de l'export",
        None: "Exporter en affaire ETO",
        InProgress: "Export en cours",
        Requested: "Export en cours",
      },
    },
    subLabels: {
      Capifrance: {
        Done: "pour prise de mandat",
        Failed: "Réssayer",
        None: "pour prise de mandat",
        InProgress: "En attente de réponse iBoost",
        Requested: "En attente de réponse iBoost",
      },
      Optimhome: {
        Done: "pour prise de mandat",
        Failed: "Réssayer",
        None: "pour prise de mandat",
        InProgress: "En attente de réponse ETO",
        Requested: "En attente de réponse ETO",
      },
    },
  },
  Vacant: "Libre",
  BuildingToCut: "Immeuble à découper",
  OfficeBuilding: "Immeuble de bureaux",
  InvestmentBuilding: "Immeuble de rapport",
  buildingForSaleInfoKind: {
    All: "Tous",
    Building: "Immeuble",
    BuildingToCut: "Immeuble à découper",
    OfficeBuilding: "Immeuble de bureaux",
    InvestmentBuilding: "Immeuble de rapport",
  },
  forSaleBuilding: {
    propertyInfo: {
      jointOwnership: "Immeuble en copropriété",
      ongoingProcedure: "Procédure en cours",
    },
    lotsType: {
      Apartment: "Appartement",
      Shop: "Boutique",
      CommercialPremises: "Local Commercial",
      IndustrialPremises: "Local industriel",
      ProfessionalPremises: "Local professionnel",
    },
    RentedLots: "Lot(s) loué(s)",
  },
  Target: "Cible",
  PhoneCall: "Appel",
  SendSms: "SMS",
  SendEmail: "Mail",
  SendMail: "Courrier",
  Appointment: "Rendez-vous",
  Other: "Autre",
};
