export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_ZOOM_LEVEL = 6;
export const DEFAULT_MAP_CENTER = { lat: 46.71109, lng: 1.7191036 };

export const computedTotalItemsTitle = (t, totalItems = 0) =>
  `${totalItems > 10000 ? "+ de 10000" : totalItems} ${t("résultat(s)")}`;

export const computeDetailedRealtyURLFactory = (baseURL) => ({
  id,
  print = false,
}) => {
  return `${baseURL}/${id}/details${print ? "/print" : "/view"}`;
};

export const isSmallList = (screenWidth, mode) =>
  screenWidth < 961 && mode === "list";

export const isSmallScreenMap = (state) =>
  state.smAndDown && state.displayMode === "map";

export const computedMapPaneBreakpoint = (hidden) => (hidden ? 12 : 8);

export const computedStateBoundingBox = (state) =>
  state.displayMode === "map" ? state.boundingBox : null;

export const computedScrollToTopButtonStyle = (mode, classes) =>
  mode !== "map" ? classes.goUpButton : classes.goUpButtonMap;

export const computedHitsStyles = (mode, containerHeight) => {
  return mode === "map"
    ? {
        height: containerHeight,
        overflowX: "hidden",
        overflowY: "auto",
      }
    : { height: "inherit" };
};
