import Typography from "@material-ui/core/Typography";
import React from "react";
import { makeStyles } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const useStyles = makeStyles((theme) => ({
  circle: {
    alignItems: "center",
    backgroundColor: (props) =>
      props.number ? theme.palette.tonique : theme.palette.greyMedium,
    borderRadius: "100px",
    color: "white",
    display: "flex",
    height: "70px",
    justifyContent: "center",
    width: "70px",
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  miniLetter: {
    fontWeight: "bold",
    marginBottom: "5px",
    marginLeft: "0.5px",
  },
  noData: {
    marginTop: "0.95em",
  },
}));

export default ({ number, subtitle }) => {
  const classes = useStyles({ number });
  return (
    <div className={classes.container}>
      <div className={classes.circle}>
        <Typography variant={"h4"}>{number}</Typography>
        {number && (
          <Typography className={classes.miniLetter} variant={"subtitle2"}>
            e
          </Typography>
        )}
        {!number && <MoreHorizIcon />}
      </div>
      <Typography variant={"h6"} className={!number ? classes.noData : ""}>
        {subtitle}
      </Typography>
    </div>
  );
};
