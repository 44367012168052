import L from "leaflet";

const MapPinIcon = (bu: string) => {
  const buColors = {
    capifrance: "#0093f7",
    digitregroup: "#0d92d2",
    optimhome: "#FF7B1E",
    refleximmo: "#1C3765",
  };

  let pinColor = "#000";

  switch (bu.toLowerCase()) {
    case "capifrance":
      pinColor = buColors.capifrance;
      break;
    case "optimhome":
      pinColor = buColors.optimhome;
      break;
    case "refleximmo":
      pinColor = buColors.refleximmo;
      break;
    default:
      pinColor = buColors.digitregroup;
  }

  const pinBaseSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="450" height="450"><path d="M206.549,0L206.549,0c-82.6,0-149.3,66.7-149.3,149.3c0,28.8,9.2,56.3,22,78.899l97.3,168.399c6.1,11,18.4,16.5,30,16.5c11.601,0,23.3-5.5,30-16.5l97.3-168.299c12.9-22.601,22-49.601,22-78.901C355.849,66.8,289.149,0,206.549,0z M206.549,193.4c-30,0-54.5-24.5-54.5-54.5s24.5-54.5,54.5-54.5s54.5,24.5,54.5,54.5C261.049,169,236.549,193.4,206.549,193.4z" fill="${pinColor}"/></svg>`;

  const pinIcon = encodeURI("data:image/svg+xml," + pinBaseSVG).replace(
    "#",
    "%23"
  );

  return new L.Icon({
    iconAnchor: [22, 45],
    iconRetinaUrl: pinIcon,
    iconSize: [45, 45],
    iconUrl: pinIcon,
  });
};

export default MapPinIcon;
