const getPolygonIsobarycenter = (polygon: { lat: number; lng: number }[]) => {
  const totalPoints: number = polygon.length;
  if (totalPoints === 0) return { latitude: 0, longitude: 0 };

  const averageCoordinates: number[] = polygon
    .reduce(
      ([currentX, currentY], { lat, lng }) => {
        return [currentX + lat, currentY + lng];
      },
      [0, 0]
    )
    .map((coordinate: number) => coordinate / totalPoints);

  const [latitude, longitude] = averageCoordinates;
  return { latitude, longitude };
};

export default getPolygonIsobarycenter;
