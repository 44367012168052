import { Typography } from "@material-ui/core";
import React, { CSSProperties } from "react";

const boxStyle = {
  backgroundColor: "#DEA44E1A",
  borderColor: "#DEA44E",
  borderRadius: "5px",
  borderStyle: "dotted",
  borderWidth: "1px",
  display: "flex",
  flexDirection: "column",
  marginTop: "8px",
  padding: "10px",
} as CSSProperties;

const titleStyle = {
  color: "#DEA44E",
  fontSize: "9px",
  fontWeight: "bold",
  paddingBottom: "5px",
} as CSSProperties;

export const RGPDNotice: React.FC = () => {
  return (
    <div style={boxStyle}>
      <Typography variant={"h6"} style={titleStyle}>
        RAPPEL
      </Typography>
      <Typography variant={"body2"} align={"justify"}>
        Dès que vous intégrez les coordonnées d&apos;un de vos prospects, vous
        devez obligatoirement avoir obtenu son consentement oral. Un email lui
        sera directement envoyé en votre nom afin de l&apos;informer que ses
        données seront stockées en toute sécurité en conformité avec la
        règlementation RGPD.
      </Typography>
    </div>
  );
};
