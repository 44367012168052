import { Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import { ITheme } from "theme/ts/main";

const useStyles = makeStyles(() => ({
  bu: {
    borderRadius: 5,
    height: 10,
    margin: 5,
    width: 10,
  },
}));

export const BU: React.FC<{ bu: string }> = ({ bu }) => {
  const classes = useStyles();
  const theme = useTheme() as ITheme;

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexWrap: "nowrap",
      }}
    >
      <div
        className={classes.bu}
        style={{
          backgroundColor:
            bu === "Capifrance" ? theme.palette.capi : theme.palette.opti,
        }}
      />
      <Typography variant="body2">{bu}</Typography>
    </div>
  );
};
