import biensVendusGridOuListe from "mockups/biens/biensVendusListe_occitanie.json";
import useHTTPService from "services/dataSources/communicationServices/useHTTPService";
import useTestService from "services/dataSources/testServices/useTestService";
import { rechercheGridOuListeAdapter } from "services/rapprochement/Rapprochement/BiensVendus/RechercheGridOuListe/RechercheGridOuListeAdapter";

import { useServices } from "../index";

export const useBiensVendusAPI = ({ params, erreur = false }) => {
  const { endpoints } = useServices();
  const { status, adaptedData } = useHTTPService({
    adapter: rechercheGridOuListeAdapter,
    endpoint: endpoints.biensVendus.rechercheGridOuListe,
    params,
  });
  return { items: adaptedData, status: status };
};

export const useBiensVendusTest = ({ params, erreur = false }) => {
  const { status, adaptedData } = useTestService({
    adapter: rechercheGridOuListeAdapter,
    erreur,
    mockup: biensVendusGridOuListe,
    params,
  });
  return { items: adaptedData, status: status };
};

export const useBiensVendus = window.testTX
  ? useBiensVendusTest
  : useBiensVendusAPI;
