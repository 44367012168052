import {
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  useMediaQuery,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { MailOutlined } from "@material-ui/icons";
import React, { useCallback, useState } from "react";

import noImage from "assets/images/no-image-box.png";
import { useServices } from "services";
import { Campaign, Channel } from "services/campagnes/types";
import { CampaignCreationDate } from "../../atoms/CampaignCreationDate";
import { CampaignStats } from "../../molecules/CampaignStatsButton";
import { GreenSwitch } from "../../molecules/GreenSwitch";
import { SubscribedByDefault } from "../../molecules/SubscribedByDefault";
import { CampaignDetailsContent } from "../CampaignDetailsButton";
import { useStyles } from "./index.style";

export interface CampaignCardProps {
  campaign: Campaign;
  subscribe: (campaignId: string) => Promise<void>;
  unsubscribe: (campaignId: string) => Promise<void>;
}

export const CampaignStatsCard: React.FC<CampaignCardProps> = ({
  campaign,
  subscribe,
  unsubscribe,
}) => {
  const [loading, setLoading] = useState(false);
  const {
    l10n: { t },
    modal,
  } = useServices();
  const classes = useStyles();
  const handleChange = useCallback(async () => {
    setLoading(true);
    if (campaign.state === "NotSubscribed") {
      await subscribe(campaign.id);
      return setLoading(false);
    }
    await unsubscribe(campaign.id);
    setLoading(false);
  }, [campaign.id, campaign.state, subscribe, unsubscribe]);

  const stat =
    campaign.stats && campaign.stats.length > 0
      ? campaign.stats[0]
      : {
          channel: "HTML" as Channel,
          clickRate: 0,
          id: "All",
          nationalClickRate: 0,
          nationalOpeningRate: 0,
          nbClick: 0,
          nbOpening: 0,
          nbOptOut: 0,
          nbSent: 0,
          openingRate: 0,
          optOutRate: 0,
        };

  const onClick = () => {
    modal.show(<CampaignDetailsContent campaign={campaign} modal={modal} />);
  };

  const mobile = useMediaQuery("(max-width:700px)");

  return (
    <Card
      className={mobile ? classes.cardMobile : classes.card}
      style={{ position: "relative" }}
    >
      <CardMedia
        className={mobile ? classes.mediaMobile : classes.media}
        image={
          campaign.thumbnail &&
          campaign.thumbnail !== "/nl/img/thumbnails/defaultCampaign.png"
            ? campaign.thumbnail
            : noImage
        }
        title={campaign.label}
        onClick={onClick}
      >
        <Typography className={classes.imageInformation}>
          <span>{t("Voir le détail")}</span>
        </Typography>
      </CardMedia>
      <CardContent style={{ width: "100%" }}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Typography variant="h2">{campaign.label}</Typography>
            <CampaignCreationDate creationDate={new Date(campaign.startDate)} />
            <MailOutlined />
          </Grid>
          <Grid item xs={12} md={6}>
            <CampaignStats stat={stat} />
          </Grid>
          <Grid item xs={12} md={2}>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              {loading ? (
                <div className={classes.switchContainer}>
                  <CircularProgress size={20} style={{ color: "lightgray" }} />
                </div>
              ) : (
                <div style={{ marginRight: -12 }}>
                  <GreenSwitch
                    checked={
                      campaign.state === "Subscribed" ||
                      campaign.state === "SubscribedByDefault"
                    }
                    onChange={handleChange}
                    value={
                      campaign.state === "Subscribed" ||
                      campaign.state === "SubscribedByDefault"
                    }
                  />
                </div>
              )}
              {campaign.state === "SubscribedByDefault" ? (
                <div style={{ marginLeft: "10px" }}>
                  <SubscribedByDefault />
                </div>
              ) : (
                <></>
              )}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
