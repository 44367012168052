import { makeStyles, useTheme } from "@material-ui/styles";
import VCardButton, {
  downloadVCard,
} from "UI/shared/molecules/Buttons/BoutonVCard";
import React from "react";

import { useServices } from "../../../../../../../../services";
import {
  CarteConseillerGalerieWithCardWrapper,
  CarteGalerieMiniWithoutWrapper,
} from "./CarteConseillerGalerie";
import CarteConseillerGalerieParId from "./CarteConseillerGalerieParId";
import CarteConseillerListe from "./CarteConseillerListe";

const civiliteEnum = {
  M: "M.",
  Melle: "Melle",
  Mlle: "Melle",
  Mme: "Mme",
};

const useStyles = makeStyles((theme) => ({
  mail: {
    color: theme.palette.default,
  },
  telephone: {
    color: theme.palette.default,
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
}));

const CarteConseiller = ({ type = "liste", item, mini = false }) => {
  const {
    l10n: { t },
  } = useServices();
  const theme = useTheme();
  const classes = useStyles();

  const vCardInfo = {
    facebook: item.lienFacebook,
    firstName: item.prenom,
    lastName: item.nom,
    namePrefix: item.civilite,
    note:
      item.specialites && Array.isArray(item.specialites)
        ? item.specialites.join(", ")
        : "",

    organization: item.bu,

    title: item.roles.join(" | "),

    url: item.urlMiniSite,

    workEmail: item.email,
    /*     urlPhoto: getUrlPhotoFromFileManager(item.bu, `50x50`, item.photo),*/
    workPhone: item.telephone,
  };

  const exportVCard = () => {
    downloadVCard({ item: vCardInfo });
  };

  const boutonVCard = <VCardButton item={vCardInfo} />;

  if (type === "liste")
    return (
      <CarteConseillerListe
        t={t}
        conseiller={item}
        classes={classes}
        theme={theme}
        civiliteEnum={civiliteEnum}
        mini={mini}
        exportVCard={exportVCard}
      />
    );
  if (type === "obtenirParId") {
    return (
      <CarteConseillerGalerieParId
        id={item.id}
        t={t}
        classes={classes}
        theme={theme}
        civiliteEnum={civiliteEnum}
      />
    );
  }
  if (type === "galerieWithoutWrapper")
    return (
      <CarteGalerieMiniWithoutWrapper
        t={t}
        conseiller={item}
        classes={classes}
        theme={theme}
        civiliteEnum={civiliteEnum}
        exportVCard={exportVCard}
        boutonVCard={boutonVCard}
      />
    );
  return (
    <CarteConseillerGalerieWithCardWrapper
      type={type}
      t={t}
      conseiller={item}
      classes={classes}
      theme={theme}
      civiliteEnum={civiliteEnum}
      exportVCard={exportVCard}
      boutonVCard={boutonVCard}
    />
  );
};

export default CarteConseiller;
