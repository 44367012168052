import { Grid } from "@material-ui/core";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";
import { Criterias } from "UI/shared/molecules/Criterias";
import { SortInput, SortOption } from "UI/shared/molecules/SortInput";
import {
  AdaptedRefinements,
  Refinement,
  Refinements,
  Sort,
} from "components/RefinementField/types";
import React from "react";
import { ContactsCriteria, ReadPortfolioDto } from "services/portfolio/types";

import { PortfolioSearchBar } from "../PortfolioSearchBar/PortfolioSearchBar";

export interface ContactsSearchCriteriasProps {
  pagedContacts: ReadPortfolioDto;
  adaptedRefinements: AdaptedRefinements<ContactsCriteria>;
  refinements: Refinements<ContactsCriteria>;
  onChangeRefinements: ({
    key,
    value,
  }: {
    key: ContactsCriteria;
    value: Refinement;
  }) => void;
  onClearRefinements: () => void;
  sortOptions: SortOption[];
  activeSortOption: SortOption;
  changeSortOptions: ({ value }: { key: "sort"; value: Sort }) => void;
}
export const ContactsSearchCriterias: React.FC<ContactsSearchCriteriasProps> = ({
  pagedContacts,
  adaptedRefinements,
  refinements,
  onChangeRefinements,
  onClearRefinements,
  sortOptions,
  activeSortOption,
  changeSortOptions,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <PaddingContainer top={10} bottom={4}>
          <PortfolioSearchBar
            searchResultCount={pagedContacts.total}
            adaptedRefinements={adaptedRefinements}
            onChange={onChangeRefinements}
          />
        </PaddingContainer>
      </Grid>
      <Grid container alignItems="center">
        <Criterias
          refinements={refinements}
          onChange={onChangeRefinements}
          onClear={onClearRefinements}
        />
        <SortInput
          options={sortOptions}
          onChange={changeSortOptions}
          selectedOption={activeSortOption.value}
        />{" "}
      </Grid>
    </>
  );
};
