import { Grid } from "@material-ui/core";
import {
  AdaptedRefinements,
  Refinement,
} from "components/RefinementField/types";
import React from "react";
import { RequestForInformationsCriteria } from "types";
import SearchBar from "UI/shared/organisms/searchbar/SearchBar";
import { MoreFilters } from "../MoreFilters/MoreFilters";

export interface RequestForInformationsSearchBarProps {
  adaptedRefinements: AdaptedRefinements<RequestForInformationsCriteria>;
  searchResultCount: number;
  onChange: (value: {
    key: RequestForInformationsCriteria;
    value: Refinement;
  }) => void;
}

export const RequestForInformationsSearchBar: React.FC<RequestForInformationsSearchBarProps> = ({
  adaptedRefinements,
  searchResultCount,
  onChange,
}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={1} justify="space-between">
          <Grid item xs={11}>
            <SearchBar
              entityName={"RequestForInformations"}
              displayMode={"list"}
              adaptedRefinements={adaptedRefinements}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={1}>
            <Grid container justify="flex-end">
              <MoreFilters
                adaptedRefinements={adaptedRefinements}
                onChange={onChange}
                searchResultCount={searchResultCount}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
