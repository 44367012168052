import { Typography } from "@material-ui/core";
import React from "react";

interface SectionTitleProps {
  title: string;
  mode?: "obligatoire" | "non obligatoire";
}

export const SectionTitle: React.FC<SectionTitleProps> = (props) => {
  const { title, mode = "obligatoire" } = props;

  const style =
    mode === "non obligatoire"
      ? {
          color: "#8e8e8e",
          paddingTop: 20,
          width: "100%",
        }
      : { paddingTop: 20, width: "100%" };

  return (
    <Typography variant="h4" style={style}>
      {title}
    </Typography>
  );
};

export const SubSectionTitle: React.FC<SectionTitleProps> = (props) => {
  const { title, mode = "obligatoire" } = props;

  let style = {
    color: "inherit",
    paddingBottom: 5,
    paddingTop: 15,
    width: "100%",
  };
  if (mode === "non obligatoire") {
    style = { ...style, color: "#8e8e8e" };
  }

  return (
    <Typography variant="h6" style={style}>
      {title}
    </Typography>
  );
};
