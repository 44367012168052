import { DisplayedProfileForDetails } from "routes/app/profiles/adapters/profileAdapterDetails";
import { getLocations } from "./index";

export const getPageTitle = (
  formatSurfaces: (min?: number, max?: number) => string,
  formatSurface: (value: number) => string,
  t: (value: string) => string,
  profile?: DisplayedProfileForDetails
) => {
  const isSearchType = profile?.profileType === "Search";
  const profileType = isSearchType ? "Recherche" : "";
  const businessType = profile?.businessType.toLowerCase() || "";
  const propertyCategory =
    t(profile?.propertyCategory || "").toLowerCase() || "";
  const surface = isSearchType
    ? formatSurfaces(profile?.surfaceMin, profile?.surfaceMax) || ""
    : formatSurface(profile?.surfaceMax || 0);
  const locations = getLocations(profile?.locations || []) || "";
  return `${profileType} ${businessType} ${propertyCategory} ${surface} ${
    locations.length > 0 ? "à" : ""
  } ${locations}`;
};
