import { ContactEmail } from "services/portfolio/types";
import { usePortfolioSearch } from "services/portfolio/usePortfolioSearch";

import { useContactForm } from "../context/ContactFormContext";

export const useContactEmails = () => {
  const {
    errors,
    isDisabled,
    setIsDisabled,
    setEmailAddressExistences,
    contactDto,
    setContactDto,
  } = useContactForm();
  const { getContactIdByEmailAddress } = usePortfolioSearch();

  const { emails } = contactDto.identity;

  const onEmailsChange = (emails: ContactEmail[]) => {
    setContactDto({
      ...contactDto,
      identity: { ...contactDto.identity, emails },
    });
  };

  const onEmailChange = (index: number) => ({
    target: { name, value },
  }: {
    target: { name: string; value: string };
  }) => {
    const newEmails = [...contactDto.identity.emails];
    newEmails[index] = { ...newEmails[index], [name]: value };

    setIsDisabled(false);
    setEmailAddressExistences((prv) => {
      const next = [...prv];
      next[index] = "";
      return next;
    });

    setContactDto({
      ...contactDto,
      identity: { ...contactDto.identity, emails: newEmails },
    });
  };

  const validateEmailAddressExistence = (index: number) => async () => {
    if (errors.emails[index] || contactDto.identity.emails.length === 0) return;
    const emailAddress = contactDto.identity.emails[index].address;

    if (!emailAddress) return;

    const existingAddressContactId = await getContactIdByEmailAddress(
      contactDto.id,
      emailAddress
    );

    if (existingAddressContactId) {
      setIsDisabled(true);
    }
    setEmailAddressExistences((prv) => {
      const next = [...prv];
      next[index] = existingAddressContactId || "";
      return next;
    });
  };

  return {
    emails,
    errors,
    isDisabled,
    onEmailChange,
    onEmailsChange,
    validateEmailAddressExistence,
  };
};
