import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { formatPrix } from "utils/formatage";

import { useServices } from "../../../../../services";

const AspectsFinanciers = (props) => {
  const { aspectsFinanciers } = props;
  const {
    l10n: { t },
  } = useServices();
  return (
    <>
      {aspectsFinanciers.bienEnCopropriete &&
        aspectsFinanciers.bienEnCopropriete === true && (
          <Copropriete aspectsFinanciers={aspectsFinanciers} t={t} />
        )}
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" align="right">
            {t("Prix")}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          &nbsp;
        </Grid>
        <Grid item xs={5}>
          <Typography variant="h4">
            {formatPrix(aspectsFinanciers.prix)}
          </Typography>
        </Grid>
      </Grid>
      {aspectsFinanciers.dateDeVente && (
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" align="right">
              {t("Date de vente")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h4">
              {aspectsFinanciers.dateDeVente}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AspectsFinanciers;

const Copropriete = (props) => {
  const { aspectsFinanciers, t } = props;
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" align="right">
            {t("Copropriété")}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          &nbsp;
        </Grid>
        <Grid item xs={5}>
          <Typography variant="h4">Oui</Typography>
        </Grid>
      </Grid>
      {aspectsFinanciers.chargesCopropriete !== undefined &&
        aspectsFinanciers.chargesCopropriete > 0 && (
          <CoproprieteRubrique
            title={"Charges copropriété"}
            value={formatPrix(aspectsFinanciers.chargesCopropriete)}
            t={t}
          />
        )}
      {aspectsFinanciers.nbLotsCopropriete !== undefined &&
        aspectsFinanciers.nbLotsCopropriete > 0 && (
          <CoproprieteRubrique
            title={"Nb lots"}
            value={aspectsFinanciers.nbLotsCopropriete}
            t={t}
          />
        )}
    </>
  );
};

const CoproprieteRubrique = (props) => {
  const { title, value, t } = props;
  return (
    <Grid container spacing={0}>
      <Grid item xs={6}>
        <Typography variant="subtitle1" align="right">
          {t(title)}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        &nbsp;
      </Grid>
      <Grid item xs={5}>
        <Typography variant="h4">{value}</Typography>
      </Grid>
    </Grid>
  );
};
