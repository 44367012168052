import { IconButton, makeStyles, Paper, Typography } from "@material-ui/core";
import {
  Close,
  Done,
  EditOutlined,
  ExpandLess,
  ExpandMore,
  LockOutlined,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";

import { useServices } from "../../../../../services";
import { ITheme } from "../../../../../theme/ts/main";

const useStyles = makeStyles((theme: ITheme) => ({
  arrows: {
    alignSelf: "center",
  },
  placeHolder: {
    color: theme.palette.default,
  },
  root: {
    background: theme.palette.pinBackground,
    display: "flex",
    flexDirection: "column",
    minHeight: "92px",
    padding: "10px",
  },
  textarea: {
    background: "RGB(255,255,255,0.2)",
    border: "dotted 1px grey",
    padding: 5,
  },
  title: {
    alignItems: "center",
    display: "flex",
    height: 42,
    marginBottom: 15,
  },
}));

interface NotesProps {
  content: string;
  saveContent: (content: string) => void;
}

const PLACEHOLDER = "Saisissez ici votre note ...";

const Notes: React.FC<NotesProps> = ({ content, saveContent }) => {
  const classes = useStyles();
  const [updatedContent, setUpdatedContent] = useState("");
  useEffect(() => setUpdatedContent(content), [content]);
  const [isEditRequested, setIsEditRequested] = useState(false);
  const _saveContent = () => {
    saveContent(updatedContent);
    setIsEditRequested(false);
  };
  const resetContent = () => setUpdatedContent(content);

  return (
    <Paper elevation={1} className={classes.root}>
      <Title
        handleForm={(open: boolean) => setIsEditRequested(open)}
        isFormOpen={isEditRequested}
        saveContent={_saveContent}
        resetContent={resetContent}
      />
      {isEditRequested ? (
        <WriteNote content={updatedContent} setContent={setUpdatedContent} />
      ) : (
        <DisplayNote content={updatedContent} />
      )}
    </Paper>
  );
};

export default Notes;

const Title: React.FC<{
  isFormOpen: boolean;
  handleForm: (open: boolean) => void;
  saveContent: () => void;
  resetContent: () => void;
}> = ({ isFormOpen, handleForm, saveContent, resetContent }) => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();
  return (
    <div className={classes.title}>
      <div
        style={{ alignItems: "center", display: "flex", height: 25, width: 30 }}
      >
        <LockOutlined fontSize="small" />
      </div>
      <Typography variant="h2">{t("Notes")}</Typography>
      <div style={{ flexGrow: 1 }} />
      {isFormOpen ? (
        <div style={{ display: "flex" }}>
          <IconButton
            aria-label="cancel"
            onClick={() => {
              resetContent();
              handleForm(false);
            }}
          >
            <Close />
          </IconButton>
          <IconButton aria-label="valid" onClick={saveContent}>
            <Done />
          </IconButton>
        </div>
      ) : (
        <IconButton aria-label="edit" onClick={() => handleForm(true)}>
          <EditOutlined />
        </IconButton>
      )}
    </div>
  );
};

const DisplayNote: React.FC<{ content: string }> = ({ content }) => {
  const classes = useStyles();
  const {
    l10n: { t },
  } = useServices();
  const [isCollapsed, setIsCollapsed] = useState(true);
  return (
    <>
      <Typography style={{ whiteSpace: "pre-line" }} variant="body1" noWrap>
        {isCollapsed && content.length > 45 ? (
          `${content.substring(45, 0)}...`
        ) : content.length === 0 ? (
          <span className={classes.placeHolder}>{t(PLACEHOLDER)}</span>
        ) : (
          content
        )}
      </Typography>
      {content.length < 45 ? null : isCollapsed ? (
        <ExpandMore
          className={classes.arrows}
          onClick={() => setIsCollapsed(false)}
        />
      ) : (
        <ExpandLess
          className={classes.arrows}
          onClick={() => setIsCollapsed(true)}
        />
      )}
    </>
  );
};

const WriteNote: React.FC<{
  content: string;
  setContent: (content: string) => void;
}> = ({ content, setContent }) => {
  const classes = useStyles();
  return (
    <textarea
      rows={5}
      onChange={(evt) => setContent(evt.target.value)}
      className={classes.textarea}
      value={content}
    />
  );
};
