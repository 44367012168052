import { RealEstateDto } from "services/profiles/dto/RealEstateDto";
import { RealEstatePropertyProps } from "UI/features/realEstates/organisms/RealEstateProperty";
import getUrlPhoto from "utils/UrlImage";

export const toRealEstatePropertyProps: (
  item: RealEstateDto
) => RealEstatePropertyProps = (item) => ({
  bedroomsNb: item.nbChambres,
  bu: item.bu,
  businessType: item.typeAffaireEnum,
  cityName: item.localisation.ville || "",
  cityZipCode: (item.localisation.codesPostaux || [])[0] || "",
  hasOffer: item.hasOffer,
  id: item.id,
  date: new Date(item.dateModification).toString(),
  isNew: item.neuf,
  isCE: item.isCE,
  price: item.prix,
  agent: {
    bu: item.bu,
    email: item.conseillers[0].email,
    firstName: item.conseillers[0].prenom,
    lastName: item.conseillers[0].nom,
    phoneNumber: item.conseillers[0].telephone,
    photo: getUrlPhoto(item.bu, "100x100", item.conseillers[0].photo),
  },
  surface: item.surface,
  isExlusive: item.exclusivite,
  nbPictures: item.nombrePhotos,
  isPrestige: item.prestige,
  propertyType: item.typeBienNom,
  isUnderOfferToSell: item.underOfferToSell,
  roomsNb: item.nbPieces,
  listeTags: [],
  photo: getUrlPhoto(item.bu, "300x300", item.photoPrincipale),
});
