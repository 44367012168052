import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";

import { useServices } from "../../../../../../../../../services";
import { ITheme } from "../../../../../../../../../theme/ts/main";
import { formatPourcent } from "../../../../../../../../../utils/formatage";
import { PremisesRentedInfoDto } from "../../../../models/Proposal/ForSale/Premises/premisesRentedInfoDto";
import { SubSectionTitle } from "../../../components/SectionTitle";
import { ValidationNumberField } from "../../../components/ValidationNumberField";
import { ProfileInputProps } from "../../../InputProps";

interface PremisesForSaleRentedProps extends PremisesRentedInfoDto {
  salePrice: number;
}

const useStyles = makeStyles((theme: ITheme) => {
  return {
    checkbox: {
      color: theme.palette.primary.main,
    },
  };
});

const onRentedChanged = (
  onChange: (value: PremisesForSaleRentedProps, isValid: boolean) => void,
  value: PremisesForSaleRentedProps
) => {
  return () => {
    onChange(
      {
        ...value,
        rented: !value.rented,
      },
      true
    );
  };
};

const onRentChanged = (
  onChange: (value: PremisesForSaleRentedProps, isValid: boolean) => void,
  value: PremisesForSaleRentedProps
) => {
  return (isValid: boolean, rent: number | undefined) => {
    onChange(
      {
        ...value,
        rent: rent || 0,
      },
      isValid
    );
  };
};

export const PremisesForSaleRentedInput: FC<ProfileInputProps<
  PremisesForSaleRentedProps
>> = ({ pristine, value, onChange, disabled = false }) => {
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles();

  return (
    <>
      <SubSectionTitle title="Location" />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                color={"default"}
                classes={{ root: classes.checkbox }}
                checked={value.rented}
                onChange={onRentedChanged(onChange, value)}
                inputProps={{ "aria-label": "checkbox" }}
              />
            }
            label={
              <Typography variant={"body2"}>
                {t("premises.RentedPremises")}
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={6}>
          {value.rented && (
            <>
              <ValidationNumberField
                disabled={disabled}
                pristine={pristine}
                label="Loyer mensuel"
                onChange={onRentChanged(onChange, value)}
                value={value.rent}
                adornement={"€"}
              />
              {value.rent &&
              value.salePrice &&
              (value.salePrice || 0) > 0 &&
              (value.rent || 0) > 0 ? (
                <Typography variant={"body2"}>
                  {"Rentabilité brute :\u00a0"}
                  <span style={{ fontWeight: "bold" }}>
                    {formatPourcent(
                      (((value.rent || 0) * 12) / value.salePrice) * 100
                    )}
                  </span>
                </Typography>
              ) : null}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};
