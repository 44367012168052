import React, { FC } from "react";
import {
  getMainEmailOrDefault,
  getMainPhoneOrDefault,
} from "services/portfolio/types";

import { useContactForm } from "../context/ContactFormContext";
import useStyles from "../styles";

export const OnePhoneOrMailMandatoryMessage: FC = () => {
  const classes = useStyles();
  const {
    submitted,
    contactDto: {
      identity: { phones, emails },
    },
  } = useContactForm();
  const phone = getMainPhoneOrDefault(phones);
  const email = getMainEmailOrDefault(emails);
  const showErrorAfterSubmit = submitted && !email && !phone;

  return (
    <>
      {showErrorAfterSubmit && (
        <span className={classes.phoneAndEmailValidation}>
          *Téléphone OU Email obligatoire
        </span>
      )}
    </>
  );
};
