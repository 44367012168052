import html2canvas from "html2canvas";

export const getScreenshot = (): Promise<string> =>
  html2canvas(document.querySelector("#capture") as HTMLElement)
    .then((canvas) => {
      return canvas.toDataURL();
    })
    .catch((e) => {
      console.log("Erreur de screenshot support : ", e);
      return "KO";
    });
