import { Grid } from "@material-ui/core";
import HitReduitSkeleton from "./Hit/HitReduitSkeleton";
import HitListeSkeleton from "./Hit/HitListeSkeleton";
import React from "react";

export function HitsSkeleton(count, mode) {
  const arr = new Array(count);
  let component;
  if (mode === "map")
    component = ({ key }) => (
      <Grid key={key} item xs={6}>
        <HitReduitSkeleton />
      </Grid>
    );
  else if (mode === "gallery")
    component = ({ key }) => (
      <Grid key={key} item xs={3}>
        <HitReduitSkeleton />
      </Grid>
    );
  else
    component = ({ key }) => (
      <Grid key={key} item xs={12}>
        <HitListeSkeleton />
      </Grid>
    );
  for (let i = 0; i < count; i++) {
    arr[i] = component({ key: i });
  }
  return arr;
}
