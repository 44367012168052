import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { ITheme } from "theme/ts/main";

const useStyles = makeStyles((theme: ITheme) => ({
  container: {
    alignItems: "center",
    display: "flex",
    height: "17px",
  },
  number: {
    alignItems: "center",
    backgroundColor: theme.palette.tonique,
    borderRadius: "6px",
    display: "flex",
    height: "12px",
    justifyContent: "center",
    minWidth: "16px",
  },
  numberContainer: {
    padding: "1px 5px 3px 5px",
  },
}));

export const TitleWithNumberBadge: FC<{ title: string; number: number }> = ({
  title,
  number,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          fontSize: "12px",
          fontWeight: "bold",
        }}
      >
        {title}
      </div>
      <div className={classes.numberContainer}>
        <div className={classes.number}>
          <div
            style={{
              color: "white",
              fontSize: "9px",
              fontWeight: "bold",
            }}
          >
            {number}
          </div>
        </div>
      </div>
    </div>
  );
};
