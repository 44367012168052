import { ElasticResult } from "../../../../types";

export const formatOptionsListeConseillers = (
  result: ElasticResult<any, any>
) => {
  return (
    result?.data?.map((option) => {
      return {
        ...option,
        label: `${option.prenom} ${option.nom}`,
        value: option.id,
      };
    }) || []
  );
};

export const filterOptions = (t: (word: string) => string) => [
  {
    isAscendingOrder: true,
    label: t("Nom"),
    query: "Nom",
    value: "Nom Ascending",
  },
  {
    isAscendingOrder: false,
    label: t("Nom"),
    query: "Nom",
    value: "Nom Descending",
  },
  {
    isAscendingOrder: true,
    label: t("Ancienneté"),
    query: "MoisAnciennete",
    value: "Ancienneté Ascending",
  },
  {
    isAscendingOrder: false,
    label: t("Ancienneté"),
    query: "MoisAnciennete",
    value: "Ancienneté Descending",
  },
];
