import { CircularProgress, InputAdornment } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React, { FC } from "react";
import NumberFormat from "react-number-format";

export const NumberField: FC<{
  isLoading: boolean;
  label: string;
  onBlur: () => void;
  onChange: (value?: number) => void;
  unit?: string;
  value?: number;
}> = (props) => (
  <TextField
    fullWidth
    value={props.value || ""}
    onChange={(e) => props.onChange(parseInt(e.target.value) || undefined)}
    variant={"outlined"}
    label={props.label}
    onBlur={props.onBlur}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          {props.isLoading ? <CircularProgress size={20} /> : <></>}
        </InputAdornment>
      ),
      inputComponent: NumberFormatCustom as any,
      inputProps: { suffix: props.unit },
    }}
  />
);

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      decimalScale={0}
      getInputRef={inputRef}
      isNumericString
      onValueChange={(values) => {
        onChange({ target: { name: props.name, value: values.value } });
      }}
      thousandSeparator=" "
    />
  );
}
