import CarteBienReduite from "layoutContents/components/CarteBienReduite";
import React from "react";

const BienCarteReduite = ({
  bien,
  mapPointLabel,
  mapMode,
  shouldDisplayCardActions = false,
}) => {
  const bienPourCarteReduite = {
    DateAjout: bien.dateModification,
    bu: bien.bu,
    exclusivite:
      bien.optionMandatEnum === "Exclusif" || bien.exclusivite === true,
    familleBienEnum: bien.familleBienEnum,
    id: bien.id,
    latitude: bien.lat || bien.latitude,
    localisation: bien.localisation,
    longitude: bien.long || bien.longitude,
    nbChambres: bien.nbChambres,
    nbPieces: bien.nbPieces,
    neuf: bien.neuf,
    nombrePhotos: bien.photoGuid.length,
    photoPrincipale: bien.photoGuid.length > 0 ? bien.photoGuid[0] : "",
    picturesIds: bien.photoGuid,
    prestige: bien.prestige,
    prixNormalized: bien.prix,
    surface: bien.surface,
    typeAffaireEnum: bien.typeAffaireEnum,
    villeCodePostal: bien.localisation.codePostal,
    villeNom: bien.localisation.villeNom,
    isCE: bien.isCE,
    underOfferToSell: bien.underOfferToSell,
    hasOffer: bien.hasOffer,
    rue: bien.rue,
    numeroRue: bien.numeroRue,
  };

  if (bien.isTvb) bienPourCarteReduite.tvb = bien.isTvb;

  return (
    <CarteBienReduite
      hit={bienPourCarteReduite}
      mapMode={mapMode}
      mapPointLabel={mapPointLabel}
      shouldDisplayCardActions={shouldDisplayCardActions}
    />
  );
};

export default BienCarteReduite;
