import { Grid } from "@material-ui/core";
import React from "react";

import TitreSection from "../../../../../../../layoutContents/Fiches/components/Details/components/TitreSection";

const AgendaSettings = ({ conseiller }) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <TitreSection titre={"agenda"} />
    </Grid>
  </Grid>
);

export default AgendaSettings;
