import { HelpOutline } from "@material-ui/icons";
import React from "react";

import { useServices } from "../../services";
import MarkDownDisplay from "../MarkDownDisplay";
import StyledDialog from "../StyledDialog";

const useContextualHelp: ({
  filePath,
  color,
}: {
  filePath: string;
  color?: string;
}) => { iconeAide: JSX.Element; dialogComponent: React.ReactNode } = ({
  filePath,
  color = "inherit",
}) => {
  const { dialogComponent, switchStatus } = StyledDialog({
    content: <MarkDownDisplay filePath={filePath} />,
  });
  const {
    trackingService: { sendCustomEventToGoogle },
  } = useServices();
  const iconeAide = (
    <HelpOutline
      onClick={() => {
        sendCustomEventToGoogle("Clic sur Aide");
        switchStatus();
      }}
      style={{
        color,
        cursor: "pointer",
        fontSize: "16px",
        marginLeft: 5,
      }}
    />
  );

  return { dialogComponent, iconeAide };
};

export default useContextualHelp;
