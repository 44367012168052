import { InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import NumberFormat from "react-number-format";

export const NumberField: React.FC<{
  error?: boolean;
  required?: boolean;
  adornement?: string;
  helperText?: string;
  label?: string;
  disabled?: boolean;
  value?: number;
  format?: string;
  onChange(value?: number): void;
  decimalScale?: number;
  allowZero?: boolean;
}> = ({
  disabled,
  onChange,
  value,
  label,
  helperText = "",
  required = false,
  adornement = "",
  error,
  format,
  decimalScale,
  allowZero,
}) => {
  const classes = makeStyles({
    notchedOutlineNotRequired: {
      borderColor: "inherit",
    },
    notchedOutlineRequired: {
      borderColor: "#303d4a",
      borderWidth: 2,
    },
    textField: {
      marginTop: "8px",
      width: "100%",
    },
  })();

  return (
    <NumberFormat
      customInput={TextField}
      onValueChange={({ floatValue }) => onChange(floatValue)}
      allowNegative={false}
      decimalScale={decimalScale || 0}
      decimalSeparator={","}
      thousandSeparator={" "}
      thousandsGroupStyle="thousand"
      isNumericString={true}
      format={format}
      required={required}
      label={label}
      helperText={helperText}
      className={classes.textField}
      margin="normal"
      variant="outlined"
      disabled={disabled}
      error={error}
      value={allowZero ? (value === undefined ? "" : value) : value || ""}
      InputProps={{
        classes: {
          notchedOutline: required
            ? classes.notchedOutlineRequired
            : classes.notchedOutlineNotRequired,
        },
        endAdornment: (
          <InputAdornment position="end">{adornement}</InputAdornment>
        ),
      }}
    />
  );
};
