import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useServices } from "services";
import {
  ContactEventReceived,
  ContactEventSent,
} from "services/contactEventsTracking/dto/ContactEvent";

import { ButtonWithStartIcon } from "../../atoms/buttons/ButtonWithStartIcon";
import { If } from "../../atoms/directives/If";
import CustomizedTimeline from "./CustomTimeLine";
import { EventForm } from "./EventForm";
import { EventsSimplifiedView } from "./EventsSimplifiedView";
import { emptyContactEventSent } from "./stateHandler";

type FormState = "closed" | "edit" | "create";

export interface EventsDetailedViewProps {
  events: ContactEventReceived[];
  selectedEvent: ContactEventSent;
  addEvent: (contactEvent: ContactEventSent) => Promise<void>;
  updateEvent: (contactEvent: ContactEventSent) => Promise<void>;
  selectEvent: (contactEvent: ContactEventReceived) => void;
  deleteEvent: (contactEvent: ContactEventReceived) => Promise<void>;
  simplifiedView?: boolean;
  onCancel?: () => void;
}

export const EventsDetailedView: React.FC<EventsDetailedViewProps> = ({
  events,
  selectedEvent,
  selectEvent,
  addEvent,
  updateEvent,
  deleteEvent,
  simplifiedView = false,
  onCancel,
}) => {
  const {
    l10n: { t },
  } = useServices();

  const [isDisplayedForm, setIsDisplayedForm] = useState<FormState>("closed");
  const toggleForm = (newState: FormState) => {
    setIsDisplayedForm(newState);
  };
  const onClose = useCallback(() => {
    toggleForm("closed");
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const onAddEvent = useCallback(
    async (contactEvent: ContactEventSent) => {
      await addEvent(contactEvent);
      onClose();
    },
    [addEvent, onClose]
  );

  const onUpdateEvent = useCallback(
    async (contactEvent: ContactEventSent) => {
      await updateEvent(contactEvent);
      onClose();
    },
    [updateEvent, onClose]
  );

  const onDeleteEvent = useCallback(
    async (contactEvent: ContactEventReceived) => {
      await deleteEvent(contactEvent);
      onClose();
    },
    [deleteEvent, onClose]
  );

  const onSelectEvent = useCallback(
    (contactEvent: ContactEventReceived) => {
      selectEvent(contactEvent);
      toggleForm("edit");
    },
    [selectEvent]
  );

  const mobile = useMediaQuery("(max-width: 600px)", { noSsr: true });
  useEffect(() => {
    if (!mobile && isDisplayedForm === "closed") toggleForm("create");
  }, [mobile, isDisplayedForm]);

  if (simplifiedView)
    return <EventsSimplifiedView onAddEvent={onAddEvent} onClose={onClose} />;

  emptyContactEventSent.date = new Date();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} md={6} style={{ paddingRight: "30px" }}>
            <Grid item xs={12}>
              <Typography variant="h2">
                Ajoutez toutes les actions de prise de contact et prise de
                rendez-vous (passés ou à venir) avec ce contact.
              </Typography>
            </Grid>

            <If condition={events.length === 0}>
              <Grid item xs={12}>
                <Typography variant="h2">
                  Aucune action n&apos;a été entreprise avec ce contact.
                </Typography>
              </Grid>
            </If>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <If condition={isDisplayedForm === "closed"}>
                  <ButtonWithStartIcon
                    onClick={() => toggleForm("create")}
                    label={t("Ajouter un événement")}
                    icon={<AddCircleOutline />}
                  />
                </If>
              </Grid>

              <Grid item xs={12}>
                <If condition={isDisplayedForm === "create"}>
                  <EventForm
                    initialValues={emptyContactEventSent}
                    saveEvent={onAddEvent}
                    onCancel={() => toggleForm("closed")}
                  />
                </If>
                <If condition={isDisplayedForm === "edit"}>
                  <EventForm
                    initialValues={selectedEvent}
                    saveEvent={onUpdateEvent}
                    onCancel={() => toggleForm("closed")}
                  />
                </If>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} style={{ paddingLeft: "30px" }}>
            <CustomizedTimeline
              events={events}
              actions={{ remove: onDeleteEvent, update: onSelectEvent }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
