import { Grid, Typography } from "@material-ui/core";
import PinIconOutlined from "@material-ui/icons/RoomOutlined";
import React from "react";

interface PrintableContactAdressProps {
  content: {
    adresse: string;
    codePostal: string;
    complementAdresse: string;
    pays: "France";
    ville: string;
  };
}

export const PrintableContactAdress = ({
  content,
}: PrintableContactAdressProps) => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid container spacing={1}>
          <Grid item>
            <PinIconOutlined />
          </Grid>

          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="body1">{content.adresse}</Typography>
              </Grid>

              <Grid item>
                <Typography variant="body1">
                  {content.complementAdresse}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="body1">{`${
                  content.codePostal
                } ${content.ville.toUpperCase()}`}</Typography>
              </Grid>

              <Grid item>
                <Typography variant="body1">
                  {content.pays.toUpperCase()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
