import {
  isAutoComplete,
  resetAutoComplete,
} from "utils/adaptedRefinements/autocompleteText";
import {
  isMultipleText,
  resetMultipleText,
} from "utils/adaptedRefinements/multipleText";
import { AdaptedRefinements } from "../../../components/RefinementField/types";
import { ProfilesCriteria } from "../../../types";
import { initialState } from "../useRefinements";

const defaultSearchValues = () => Promise.resolve<string[]>([]);

const getCriteriaKeys = (
  adaptedRefinements: AdaptedRefinements<ProfilesCriteria>
) => <ProfilesCriteria[]>Object.keys(adaptedRefinements);

export const clearRefinements = (
  adaptedRefinements: AdaptedRefinements<ProfilesCriteria>
): AdaptedRefinements<ProfilesCriteria> => {
  const criteriaKeys = getCriteriaKeys(adaptedRefinements);
  const emptyState = initialState(defaultSearchValues);
  return criteriaKeys.reduce((current, criteria) => {
    switch (true) {
      case isMultipleText(adaptedRefinements, criteria):
        return resetMultipleText(adaptedRefinements, current, criteria);
      case isAutoComplete(adaptedRefinements, criteria):
        return resetAutoComplete(adaptedRefinements, current, criteria);
      default:
        return {
          ...current,
          [criteria]: emptyState[criteria],
        };
    }
  }, {}) as AdaptedRefinements<ProfilesCriteria>;
};
