import { Button, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CallMade } from "@material-ui/icons";
import { Column } from "UI/shared/atoms/containers/Column";
import { If } from "UI/shared/atoms/directives/If";
import { Row } from "UI/shared/atoms/containers/Row";
import { Text16px } from "UI/shared/atoms/typographies/Text16px";
import { VerticalSpace } from "UI/shared/atoms/spacing/VerticalSpace";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { MouseEventHandler } from "react-select";
import { profileRoutes } from "router/profileRoutes";
import { useAgentRealEstatesMatching } from "services/profiles/realEstatesMatching/agent";
import { useNetworkRealEstatesMatching } from "services/profiles/realEstatesMatching/network";
import { useMatching } from "services/profiles/realEstatesMatching/useMatching";

const propertiesButtonLabel = (count: number) => (count > 1 ? "Biens" : "Bien");

interface Props {
  disableTitle?: boolean;
  contactId: string;
  profileId: string;
}

export const ReadMatchingNumber: React.FC<Props> = ({
  disableTitle = false,
  profileId,
  contactId,
}) => {
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const matchingService = useMatching();
  const agentMatching = useAgentRealEstatesMatching({ mode: "count" });
  const networkMatching = useNetworkRealEstatesMatching({ mode: "count" });

  const handleOpenMatchings: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(profileRoutes.matching(profileId, contactId));
  };

  const matchingTotal = useMemo(
    () => agentMatching.total + networkMatching.total,
    [agentMatching.total, networkMatching.total]
  );

  const displayNoMatchingsTitle = useMemo(
    () => !loading && matchingTotal === 0,
    [loading, matchingTotal]
  );

  const displayMatchingsOpenButton = useMemo(
    () => !loading && matchingTotal > 0,
    [loading, matchingTotal]
  );

  const init = useCallback(async () => {
    setLoading(true);

    const matchingPayload = await matchingService.fromProfile(
      profileId,
      contactId
    );

    setLoading(false);

    agentMatching.updateParams(matchingPayload);
    networkMatching.updateParams(matchingPayload);
  }, [profileId, contactId]);

  useEffect(() => {
    init();
  }, [profileId, contactId]);

  return (
    <Column itemJustify="center" columnJustify="center" itemSize={"auto"}>
      <VerticalSpace size={4} />
      <If condition={!disableTitle}>
        <Text16px>RAPPROCHEMENT AVEC VOS CRITERES&nbsp;:</Text16px>
      </If>
      <Row rowAlignItems="center" rowJustify="center">
        <VerticalSpace size={8} />
        <If condition={loading}>
          <CircularProgress
            size={12}
            style={{
              margin: "auto",
            }}
          />
        </If>
        <If condition={displayNoMatchingsTitle}>
          <Typography variant={"body1"}>Aucune correspondance</Typography>
        </If>
        <If condition={displayMatchingsOpenButton}>
          <Button
            style={{ flex: 1, marginRight: "10px" }}
            variant="outlined"
            color="primary"
            onClick={handleOpenMatchings}
          >
            <CallMade style={{ marginRight: "10px" }} />
            {matchingTotal} {propertiesButtonLabel(matchingTotal)}
          </Button>
        </If>
      </Row>
    </Column>
  );
};
