import { FormControlLabel } from "@material-ui/core";
import { BusinessCenterOutlined, HomeWorkOutlined } from "@material-ui/icons";
import { CircleIcon } from "UI/shared/atoms/icons/CircleIcon";
import React from "react";
import { ProfileAccountType } from "routes/app/profiles/adapters/profileAdapterDetails";

export interface ProfileAccountChipProps {
  value: ProfileAccountType;
  chipDiameter?: number;
}

export const ProfileAccountChip: React.FC<ProfileAccountChipProps> = ({
  value,
  chipDiameter,
}) => {
  const circleIconProps = { ...(chipDiameter && { diameter: chipDiameter }) };
  const professional = (
    <FormControlLabel
      value={value}
      control={
        <CircleIcon {...circleIconProps}>
          <BusinessCenterOutlined />
        </CircleIcon>
      }
      label={"Professionnel"}
      labelPlacement="bottom"
    />
  );

  const customer = (
    <FormControlLabel
      value={value}
      control={
        <CircleIcon {...circleIconProps}>
          <HomeWorkOutlined />
        </CircleIcon>
      }
      label={"Particulier"}
      labelPlacement="bottom"
    />
  );
  return value === "Customer" ? customer : professional;
};
