import SubjectIcon from "@material-ui/icons/Subject";
import React from "react";

import { CampaignDetailsSection } from "../..";

export const CampaignDescriptionSection: React.FC<{ content: string }> = ({
  content,
}) => (
  <CampaignDetailsSection
    icon={<SubjectIcon style={{ fontSize: "16px" }} />}
    title={"Description"}
    content={content}
  />
);
