import { IAuthenticationConfiguration } from "../authentication/authentication";
import { ITrackingConfiguration } from "../tracking/tracking";

type ServiceName =
  | "agents-queries"
  | "contact-commands"
  | "contact-queries"
  | "crm"
  | "geoloc"
  | "manager"
  | "marketing-campaigns"
  | "notifications"
  | "office365"
  | "pige"
  | "rapprochement-queries"
  | "rcu"
  | "reporting"
  | "request-for-informations";

export interface MicroServicesConfiguration {
  lineup7: string;
  crmLegacyProfileCapifrance: string;
  crmLegacyProfileOptimhome: string;

  tx: (service: ServiceName) => string;
}

type Keys =
  | "REACT_APP_AUTH_CLIENT_ID"
  | "REACT_APP_AUTH_SCOPE"
  | "REACT_APP_CRM_LEGACY_PROFILE_CAPIFRANCE_URL"
  | "REACT_APP_CRM_LEGACY_PROFILE_OPTIMHOME_URL"
  | "REACT_APP_ENVIRONNEMENT_LABEL"
  | "REACT_APP_LINEUP7"
  | "REACT_APP_TRACKING_GOOGLETAGKEY"
  | "REACT_APP_TREMPLINX_DOMAIN";

const getEnv = (key: Keys) => process.env[key] || "";

const txDomain = getEnv("REACT_APP_TREMPLINX_DOMAIN");
export const configuration: Configuration = {
  authentication: {
    // TODO: remove this and use Configuration.tx("sso") instead
    authority: `https://sso.${txDomain}`,
    automaticSilentRenew: true,
    client_id: getEnv("REACT_APP_AUTH_CLIENT_ID"),
    loadUserInfo: true,
    post_logout_redirect_uri: `${window.location.origin}/logout`,
    redirect_uri: `${window.location.origin}/callback`,
    response_type: "id_token token",
    revokeAccessTokenOnSignout: true,
    scope: getEnv("REACT_APP_AUTH_SCOPE"),
    silent_redirect_uri: `${window.location.origin}/callback`,
  },
  environnementLabel: getEnv("REACT_APP_ENVIRONNEMENT_LABEL"),
  microServices: {
    // TODO: this should be done server side
    crmLegacyProfileCapifrance: getEnv(
      "REACT_APP_CRM_LEGACY_PROFILE_CAPIFRANCE_URL"
    ),

    // TODO: this should be done server side
    crmLegacyProfileOptimhome: getEnv(
      "REACT_APP_CRM_LEGACY_PROFILE_OPTIMHOME_URL"
    ),
    // TODO: this should be done server side
    lineup7: getEnv("REACT_APP_LINEUP7"),
    tx: (service: ServiceName): string => {
      return (
        process.env[`REACT_APP_SERVICE_${service.toUpperCase()}`] ??
        `https://${service}.${txDomain}`
      );
    },
  },
  tracking: {
    googleTagKey: getEnv("REACT_APP_TRACKING_GOOGLETAGKEY"),
  },
};

export interface Configuration {
  environnementLabel: string;
  authentication: IAuthenticationConfiguration;
  tracking: ITrackingConfiguration;
  microServices: MicroServicesConfiguration;
}
