import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { MatchingProfileRequestDto } from "services/matching/MatchingProfileRequestDto";

import useNotifier from "../../../../../../../../../components/Notifier/useNotifier";
import { useServices } from "../../../../../../../../../services";
import { useProfiles } from "../../../../../../../../../services/profiles";
import { AccountType } from "../../../../models/accountType";
import { ProfileOriginType } from "../../../../models/profileOriginType";
import { ProfileReadDto } from "../../../../models/profilesReadDto";
import { SaleLandProfileReadDto } from "../../../../models/Proposal/ForSale/Land/saleLandProfileReadDto";
import { SaleLandProfileWriteDto } from "../../../../models/Proposal/ForSale/Land/saleLandProfileWriteDto";
import { SectionTitle } from "../../../components/SectionTitle";
import { ValidationNumberField } from "../../../components/ValidationNumberField";
import { WriteSellerMatchingNumber } from "../../../MatchingNumber/WriteSellerMatchingNumber";
import { SelectMaturity } from "../../../Maturity";
import { NotesInput } from "../../../Notes";
import { Write } from "../../../Write";
import { LandAmenitiesSelection } from "../../Features/Land/LandAmenitiesSelection";
import { LandConnectionsSelection } from "../../Features/Land/LandConnectionsSelection";
import { LandKindSelection } from "../../Features/Land/LandKindSelection";
import { LandReliefSelection } from "../../Features/Land/LandReliefSelection";
import { SaleBudgetLandInput } from "./SaleBudgetLand";
import { SalePlaceInput } from "../../SalePlace";
import MatchingAdaptater from "./MatchingAdapter";
import classes from "./Write.module.css";

export const LandForSaleProfileWrite: FC<{
  contactId: string;
  account: AccountType;
  initial?: SaleLandProfileReadDto;
  more: boolean;
  showMore: () => void;
  sendUpdatedProfile?: (profile: ProfileReadDto | undefined) => void;
  handleAfterUpdate?: () => void;
}> = ({
  contactId,
  account,
  initial,
  more,
  showMore,
  sendUpdatedProfile,
  handleAfterUpdate,
  children,
}) => {
  const { add, update } = useProfiles(contactId);
  const [payload, setPayload] = useState(create(account, initial));
  const [state, setState] = useState<"none" | "saving" | "saved" | "error">(
    "none"
  );
  const { modal } = useServices();
  const { showSnack } = useNotifier();
  const mode = initial ? "update" : "add";
  const [pristine, setPristine] = useState(true);
  const [isFormValid, setIsFormValid] = useState({
    address: false,
    financialInfo: false,
    landPropertyInfo: true,
  });

  const disabled = state === "saving";
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down("sm"));

  const [matchingCriteria, setMatchingCriteria] = useState<
    MatchingProfileRequestDto | undefined
  >(undefined);
  useEffect(() => {
    setMatchingCriteria(MatchingAdaptater(payload.features));
  }, [payload]);

  useEffect(() => {
    switch (state) {
      case "none":
        break;
      case "saving":
        if (Object.entries(isFormValid).some(([_, b]) => !b)) {
          setPristine(false);
          setState("none");
        } else {
          (mode === "add"
            ? add("SaleLandProfile", payload)
            : update(
                "SaleLandProfile",
                initial!.id,
                payload,
                sendUpdatedProfile
              )
          )
            .then(() => {
              if (handleAfterUpdate) handleAfterUpdate();
              setState("saved");
            })
            .catch(() => setState("error"));
        }
        break;
      case "saved":
        modal.close();
        showSnack({ message: "Le profil est enregistré.", variant: "success" });
        break;
      case "error":
        showSnack({ message: "Une erreur est survenue.", variant: "danger" });
        break;
    }
  }, [state]);

  return (
    <Write
      pending={state === "saving"}
      save={() => setState("saving")}
      mode={mode}
      more={more}
      matching={<WriteSellerMatchingNumber criteria={matchingCriteria} />}
      showMore={showMore}
    >
      <div className={more && !mediaQuery ? classes.container : ""}>
        <div className={more ? classes.itemA : ""}>
          {children}
          <Grid item xs={12}>
            <LandKindSelection
              value={payload.features.landKind}
              onChange={(kind) => {
                setPayload((prev) => ({
                  ...prev,
                  features: {
                    ...prev.features,
                    landKind: kind,
                  },
                }));
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <SalePlaceInput
              value={payload.features.address}
              onChange={(address, isValid) => {
                setIsFormValid((previous) => ({
                  ...previous,
                  address: isValid,
                }));
                setPayload((prev) => ({
                  ...prev,
                  features: {
                    ...prev.features,
                    address,
                  },
                }));
              }}
              disabled={disabled}
              pristine={pristine}
            />
          </Grid>
        </div>

        <div className={more ? classes.itemB : ""}>
          <Grid item xs={12}>
            <SaleBudgetLandInput
              pristine={pristine}
              more={more}
              value={payload.features.financialInfo}
              onChange={(financialInfo, isValid) => {
                setIsFormValid((previous) => ({
                  ...previous,
                  financialInfo: isValid,
                }));
                setPayload((prev) => ({
                  ...prev,
                  features: {
                    ...prev.features,
                    financialInfo,
                  },
                }));
              }}
            />
          </Grid>

          <SectionTitle title="Quelles sont les caractéristiques ?" />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ValidationNumberField
                disabled={disabled}
                pristine={pristine}
                label="Surface terrain"
                required={true}
                onChange={(isValid, squareMeter) => {
                  setPayload((prev) => ({
                    ...prev,
                    features: {
                      ...prev.features,
                      landPropertyInfo: {
                        ...prev.features.landPropertyInfo,
                        squareMeter: squareMeter,
                      },
                    },
                  }));
                }}
                value={payload.features.landPropertyInfo.squareMeter}
                adornement={"m²"}
                minLimit={0}
                maxLimit={1000000}
              />
            </Grid>
            {more && (
              <>
                <Grid item xs={12}>
                  <LandReliefSelection
                    onChange={(relief) => {
                      setPayload((prev) => ({
                        ...prev,
                        features: {
                          ...prev.features,
                          landRelief: relief,
                        },
                      }));
                    }}
                    value={payload.features.landRelief}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LandAmenitiesSelection
                    onChange={(amenities) => {
                      setPayload((prev) => ({
                        ...prev,
                        features: {
                          ...prev.features,
                          landAmenities: amenities,
                        },
                      }));
                    }}
                    value={payload.features.landAmenities ?? []}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LandConnectionsSelection
                    onChange={(connections) => {
                      setPayload((prev) => ({
                        ...prev,
                        features: {
                          ...prev.features,
                          landConnections: connections,
                        },
                      }));
                    }}
                    value={payload.features.landConnections ?? []}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ValidationNumberField
                    disabled={disabled}
                    pristine={pristine}
                    label="Longueur de façade côté rue"
                    required={false}
                    onChange={(isValid, frontLength) => {
                      setPayload((prev) => ({
                        ...prev,
                        features: {
                          ...prev.features,
                          landPropertyInfo: {
                            ...prev.features.landPropertyInfo,
                            frontLength: frontLength,
                          },
                        },
                      }));
                    }}
                    value={payload.features.landPropertyInfo.frontLength}
                    adornement={"m"}
                    minLimit={0}
                    maxLimit={1000000}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </div>

        <div className={more ? classes.itemC : ""}>
          <Grid item xs={12}>
            <NotesInput
              value={payload.notes}
              onChange={(notes) => setPayload((prev) => ({ ...prev, notes }))}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectMaturity
              onChange={(maturity) => {
                setPayload((prev) => ({
                  ...prev,
                  maturity,
                }));
              }}
              value={payload.maturity}
              disabled={disabled}
            />
          </Grid>
        </div>
      </div>
    </Write>
  );
};

function create(
  account: AccountType,
  initial?: SaleLandProfileReadDto
): SaleLandProfileWriteDto {
  return {
    account,
    creationDate: new Date(),
    features: initial
      ? initial.features
      : {
          address: {},
          landAmenities: [],
          landConnections: [],
          financialInfo: {
            agentFees: 0,
            price: 0,
          },
          landKind: "Unknown",
          landPropertyInfo: {},
          landRelief: "Unknown",
        },
    maturity: initial?.maturity || "None",
    notes: initial?.notes || "",
    origin: initial?.origin
      ? (initial?.origin as ProfileOriginType)
      : "TXProfile",
    reference: initial?.reference || "",
  };
}
