import { Chip } from "@material-ui/core";
import React from "react";
import { Price } from "../../../../../components/RefinementField/types";
import { RefinementChipProps } from "../RefinementChip";

export const PriceChip = ({
  value,
  format,
  onChange,
}: RefinementChipProps<Price>) => {
  return (
    <>
      {value.value && (
        <Chip
          style={{ marginRight: 6, marginBottom: 6 }}
          label={format(value.value)}
          size="small"
          onDelete={() => onChange({ kind: "price" })}
        />
      )}
    </>
  );
};
