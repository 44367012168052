import { LabelledValue } from "UI/shared/molecules/LabelledValue";
import React, { FC } from "react";
import { useServices } from "services";

export interface ConstructionYearsProps {
  values: string[];
}
export const ConstructionYears: FC<ConstructionYearsProps> = ({ values }) => {
  const {
    l10n: { t },
  } = useServices();

  return values.length > 0 ? (
    <LabelledValue
      label={"Date de construction"}
      value={values.map((option) => t(`buildAge.${option}`)).join(", ")}
    />
  ) : (
    <></>
  );
};
