import { makeStyles } from "@material-ui/core";
import Down from "@material-ui/icons/ArrowDownward";
import Up from "@material-ui/icons/ArrowUpward";
import Hyphen from "@material-ui/icons/Remove";
import PropType from "prop-types";
import React from "react";

import PaperX from "../../../../components/PaperX";
import PaperTitle from "./PaperTitle";

const useStyles = makeStyles((theme) => ({
  renderPercentContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(3),
    textAlign: "right",
  },
  renderPercentNumber: {
    color: ({ percent }) => {
      if (percent === 0) return theme.palette.warning.light;
      else if (percent > 0) return theme.palette.success.light;
      else return theme.palette.error.light;
    },
  },
}));

function calcVariantPercent(n, nMoins1) {
  return nMoins1 === 0 ? n * 100 : ((n - nMoins1) / nMoins1) * 100;
}

function format(string, type) {
  let _string = "-";
  if (string) {
    _string = Number.isInteger(string) ? string : string.toFixed();
  } else return _string;

  switch (type) {
    case "percent":
      _string += " %";
      break;
    case "money":
      _string =
        _string.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + " €";
      break;
    default:
      break;
  }
  return _string;
}

export default function PaperKpi({ n, nMoins1, title, titleType, t }) {
  let string = format(n, titleType);

  return (
    <PaperX>
      {Number.isNaN(n) ? (
        <div>No data</div>
      ) : (
        <div>
          <PaperTitle title={string} subtitle={title} />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {nMoins1 !== undefined && (
              <RenderPercent
                t={t}
                percent={calcVariantPercent(n || 0, nMoins1)}
              />
            )}
          </div>
        </div>
      )}
    </PaperX>
  );
}

function RenderPercent({ percent, t }) {
  const classes = useStyles({ percent });
  const render = (
    <>
      <div className={classes.renderPercentNumber}>
        {percent < 0 ? <Down /> : percent > 0 ? <Up /> : <Hyphen />}
        {Number.isInteger(percent) ? percent : percent.toFixed()} %
      </div>
      <span>{t("Annee")} n-1</span>
    </>
  );

  return (
    <PaperTitle className={classes.renderPercentContainer} subtitle={render} />
  );
}

PaperKpi.propTypes = {
  n: PropType.number,
  nMoins1: PropType.number,
  title: PropType.string,
  titleType: PropType.oneOf(["money", "percent"]),
};
