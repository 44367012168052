import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

const ValidationTextField = withStyles({
  root: {
    "& input:invalid + fieldset": {
      borderColor: "#303d4a",
      borderWidth: 2,
    },
    "& input:valid + fieldset": {
      borderColor: "#303d4a",
      borderWidth: 2,
    },
    "&fieldset": {
      borderColor: "#303d4a",
      borderWidth: 2,
    },
  },
})(TextField);

export default ValidationTextField;
