import React from "react";
import Typography from "@material-ui/core/Typography";

interface ReceptionDateProps {
  date: Date;
}

export const ReceptionDate: React.FC<ReceptionDateProps> = ({ date }) => (
  <Typography variant="body1">
    Reçu le {new Date(date).toLocaleDateString()}
  </Typography>
);
