import React from "react";
import { RadioChip } from "UI/shared/molecules/RadioChip";
import { LandForSaleRelief } from "../../../../models/Proposal/Features/Land/landRelief";
import { SubSectionTitle } from "../../../components/SectionTitle";

export const LandReliefSelection: React.FC<{
  value?: LandForSaleRelief;
  onChange: (value: LandForSaleRelief) => void;
}> = ({ value, onChange }) => {
  const options: LandForSaleRelief[] = [
    "ToArrange",
    "Sported",
    "Sloping",
    "Flat",
  ];

  return (
    <>
      <SubSectionTitle title="Relief/Aménagement" mode={"non obligatoire"} />
      <RadioChip
        translationPrefix={"landRelief"}
        options={options}
        value={value}
        onChange={onChange}
      />
    </>
  );
};
