import { Grid, makeStyles, Typography } from "@material-ui/core";
import TimeIcon from "@material-ui/icons/AccessTimeOutlined";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import moment from "moment";
import React from "react";
import { useServices } from "services";
import { ContactStatusType } from "services/portfolio/types";

const useStyles = makeStyles((_) => ({
  activeStatusBadge: {
    alignSelf: "center",
    backgroundColor: "#388E3C",
    borderRadius: "3px",
    height: "10px",
    width: "10px",
  },
  leftContainer: {
    display: "flex",
    justifyContent: "center",
    width: "50px",
  },
  mainContainer: {
    backgroundColor: "#f2f2f2",
    borderRadius: "0 8px 8px 8px",
    marginTop: "10px",
    padding: "10px",
  },
}));

const StatusInfos: React.FC<{
  contactStatus: ContactStatusType;
  dateCreation: string;
  dateModification: string;
  source: string;
}> = ({ contactStatus, dateCreation, dateModification, source }) => {
  const {
    l10n: { t },
  } = useServices();
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.mainContainer}>
      {contactStatus === "Active" && (
        <Grid container spacing={1}>
          <Grid item className={classes.leftContainer}>
            <div className={classes.activeStatusBadge}></div>
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>{`${t(
              contactStatus
            )}`}</Typography>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1}>
        <Grid item className={classes.leftContainer}>
          <LocationSearchingIcon />
        </Grid>

        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="body1">
                {t("Source : ")}
                <span style={{ fontWeight: "bold" }}>
                  {t(`ContactSource.${source}`)}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item className={classes.leftContainer}>
          <TimeIcon />
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="body1">{`${t("Créé le ")} ${moment(
                dateCreation
              ).format("DD/MM/YYYY")}`}</Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1">{`${
                contactStatus === "Archived"
                  ? t("Archivé le ")
                  : t("Mis à jour le ")
              } ${moment(dateModification).format("DD/MM/YYYY")}`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StatusInfos;
