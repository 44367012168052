import { string, bool } from "api/DTOs/Common/Types";

export default function evenementAdapter(item = {}) {
  const bo = {
    id: item.id || 0,
    dateDebut: item.dateDebut || string,
    dateFin: item.dateFin || string,
    estAnnule: item.estAnnule || bool,
    lieu: item.lieu || string,
    message: item.message || string,
    messageSlim: item.messageSlim || string,
    nomOrganisateur: item.nomOrganisateur || string,
    objet: item.objet || string,
    participants: item.participants || [],
    touteLaJournee: bool,
  };
  return bo;
}
