import { Typography } from "@material-ui/core";
import React from "react";
import { useServices } from "services";

import GreenSwitch from "../../../components/GreenSwitch";

export interface WithPremisesProps {
  value: boolean;
  onChange: (value: boolean) => void;
}

export const WithPremises: React.FC<WithPremisesProps> = ({
  onChange,
  value,
}) => {
  const {
    l10n: { t },
  } = useServices();
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
      }}
    >
      <GreenSwitch
        name="avecLesMurs"
        checked={value}
        onChange={(e, checked) => onChange(checked)}
        value="avecLesMurs"
      />
      <Typography variant="body2">{t("Avec les murs")}</Typography>
    </div>
  );
};
