import { Text16pxBold } from "UI/shared/atoms/typographies/Text16pxBold";
import React from "react";
import { useTranslation } from "services/l10n/useTranslation";

export interface ProperyTypeCaptionProps {
  label: string;
}

export const PropertyCategoryCaption: React.FC<ProperyTypeCaptionProps> = ({
  label,
}) => {
  const { t } = useTranslation();
  return <Text16pxBold>{t(label)}</Text16pxBold>;
};
