import "./App.css";
import "@fortawesome/fontawesome-free/css/all.css";

import React from "react";
import { hot } from "react-hot-loader";

import { Home } from "./routes/Home";
import { createServicesCollection, ServicesProvider } from "./services";
import { configuration } from "./services/configuration/configuration";

const App = () => {
  return (
    <ServicesProvider
      servicesCollection={createServicesCollection(configuration)}
    >
      <Home />
    </ServicesProvider>
  );
};

// eslint-disable-next-line no-undef
export default hot(module)(App);
