import { getContent } from "layoutContents/Biens/components/MapComponents/BubbleContent";
import { serialize } from "layoutContents/components/HitsContainer/utils";

export const resetPageNumberBuilder = (dispatch) => () => {
  dispatch({ payload: 1, type: "changePage" });
};

export const setDisplayModeBuilder = (state, dispatch, props, url) => (
  mode
) => {
  const parameters = serialize({
    refinements: state.refinements.toURLParams(),
  });
  props.history.push({
    pathname: `${url}/${mode}/${parameters}`,
  });
  dispatch({
    payload: mode,
    type: "setDisplayMode",
  });
};

export const handleMapConfigSaveBuilder = (state, dispatch, key) => ({
  boundingBox,
  center,
  zoom,
  shouldUpdateMapBounds,
}) => {
  const newState = {
    ...state,
    boundingBox,
    center,
    shouldUpdateMapBounds,
    zoom,
  };
  sessionStorage.setItem(key, serialize(newState));
  dispatch({
    payload: {
      center,
      shouldUpdateMapBounds,
      zoom,
    },
    type: "saveMapConfig",
  });
};

export const handleMapInitializationBuilder = (dispatch) => ({ map }) => {
  dispatch({
    payload: {
      map,
    },
    type: "initializeMap",
  });
};

export const onMapSelectItemFactory = (url, get, translate, cardAdapter) => (
  item
) => {
  return get(url, {
    id: item.id,
  })
    .then((response) => {
      const bien = cardAdapter(response.data);
      return {
        circleRadius: 650,
        defer: false,
        html: getContent({ bien, translate }),
      };
    })
    .catch((e) => {
      return {
        circleRadius: 650,
        defer: false,
        html: undefined,
      };
    });
};
