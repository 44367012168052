import React from "react";
// import PropType from 'prop-types'
import Loading from "react-loading-skeleton";
import PaperX from "../../../../components/PaperX";
import { Grid } from "@material-ui/core";

export default function HitReduitSkeleton() {
  return (
    <PaperX style={{ padding: 0 }}>
      <Grid container spacing={1}>
        <Grid style={{ paddingBottom: 0, paddingTop: 0 }} item xs={12}>
          <Loading height={120} width={"100%"} />
        </Grid>
        <Grid
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
          item
          xs={12}
        >
          <Loading />
          <Loading count={2} width={"80%"} />
        </Grid>
      </Grid>
    </PaperX>
  );
}

// HitReduitSkeleton.propTypes = {};
