import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/SaveOutlined";
import { ButtonWithOptions } from "components/Buttons/ButtonWithOptions";
import React, { FC, useEffect } from "react";
import { ReadContactDto } from "services/portfolio/types";

import { useServices } from "../../../../../services";
import { Address } from "./components/Address";
import { Consent } from "./components/Consent";
import { ContactMeans } from "./components/ContactMeans";
import { Identity } from "./components/Identity";
import { Notes } from "./components/Notes";
import { OtherInformations } from "./components/OtherInformations";
import { PhoneAndMail } from "./components/PhoneAndMail";
import {
  ContactFormContextProvider,
  useContactForm,
} from "./context/ContactFormContext";
import useStyles from "./styles";

const ContactFormContent: FC = () => {
  const {
    contactDto,
    setShowMore,
    showMore,
    submit,
    isLoading,
    isDisabled,
    closeDialog,
  } = useContactForm();

  const {
    l10n: { t },
  } = useServices();

  const { modal } = useServices();
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => modal.resize(showMore && !isMobile ? "xl" : "sm"), [
    isMobile,
    modal,
    showMore,
  ]);

  const isDetailedDisplay = showMore || !!contactDto.id;

  const actionsAfterSave = [
    {
      action: () => {
        submit("GoToForSaleProfileCreation");
      },
      subtitle: "et ajouter un bien",
    },
    {
      action: () => {
        submit("GoToSearchProfileCreation");
      },
      subtitle: "et ajouter une recherche",
    },
    {
      action: () => {
        submit("GoToContactsList");
      },
      subtitle: "et retour à la liste",
    },
  ];

  return (
    <>
      <DialogTitle>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">
            {contactDto.id ? t("Modification contact") : t("Nouveau contact")}
          </Typography>
          <IconButton onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {isDetailedDisplay ? <DetailedDisplay /> : <SimpleDisplay />}
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between" alignItems={"center"}>
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div className={classes.formButtonWrapper}>
                {!showMore ? (
                  <Button
                    disabled={isDisabled}
                    color="primary"
                    variant="outlined"
                    className={classes.button}
                    onClick={() => setShowMore(true)}
                  >
                    {t(isMobile ? "détail" : "Fiche détaillée")}
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    variant="outlined"
                    className={classes.button}
                    onClick={modal.close}
                  >
                    {t("Annuler")}
                  </Button>
                )}
              </div>
              <div className={classes.formButtonWrapper}>
                <ButtonWithOptions
                  disabled={isDisabled}
                  title={"enregistrer"}
                  icon={<SaveIcon />}
                  options={actionsAfterSave}
                  mainAction={() => submit("")}
                />
                {isLoading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export const ContactForm: FC<{
  contact?: ReadContactDto;
  closeDialog: () => void;
}> = ({ contact, closeDialog }) => {
  return (
    <ContactFormContextProvider initContact={contact} closeDialog={closeDialog}>
      <ContactFormContent />
    </ContactFormContextProvider>
  );
};

const SimpleDisplay: FC = () => (
  <div>
    <Identity />
    <PhoneAndMail />
    <Consent />
    <Address titleWithPadding />
    <Notes />
  </div>
);

const DetailedDisplay: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return isMobile ? (
    <div>
      <Identity />
      <ContactMeans />
      <Consent />
      <Address />
      <OtherInformations />
      <Notes />
    </div>
  ) : (
    <div className={classes.container}>
      <div className={classes.gridFirstColumn}>
        <Identity />
        <ContactMeans />
        <Consent />
      </div>
      <div className={classes.gridSecondColumn}>
        <Address />
        <OtherInformations />
        <Notes />
      </div>
    </div>
  );
};
