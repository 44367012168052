import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import React from "react";
import { useServices } from "services";

import { CloseButton } from "UI/shared/atoms/buttons/CloseButton";
import { Text12px } from "UI/shared/atoms/typographies/Text12px";
import { Text12pxBold } from "UI/shared/atoms/typographies/Text12pxBold";
import { Text16px } from "UI/shared/atoms/typographies/Text16px";
import { Text16pxBold } from "UI/shared/atoms/typographies/Text16pxBold";
import { Stat } from "services/campagnes/types";
import { formatPourcent } from "utils/formatage";
import { useStyles } from "../../styles/CampaignFooterButtonStyle";

export const CampaignStatsButton: React.FC<{
  title: string;
  stats: Stat[];
}> = ({ title, stats }) => {
  const { modal } = useServices();
  const classes = useStyles();
  const content = (
    <div style={{ width: "100%" }}>
      <HeaderStats>
        <div className={classes.headerTitle}>
          <Text16pxBold>Statistiques</Text16pxBold>
          <Text16px>&nbsp;|&nbsp;{title}</Text16px>
        </div>
        <CloseButton clickHandler={modal.close} />
      </HeaderStats>
      <ContentStats stats={stats} />
      <FooterStats>
        <Button variant="outlined" onClick={modal.close}>
          FERMER
        </Button>
      </FooterStats>
    </div>
  );
  return (
    <Tooltip title="Statistiques">
      <IconButton onClick={() => modal.show(content)}>
        <BarChartOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
};

const HeaderStats: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.header}>{children}</div>;
};

export const CampaignStats: React.FC<{ stat: Stat }> = ({ stat }) => (
  <Grid container spacing={2}>
    <Grid item xs={4} md={2}>
      <CampaignStat
        title={"Nombre d'emails délivrés"}
        stat={`${stat.nbSent}`}
      />
    </Grid>
    <Grid item xs={4} md={2}>
      <CampaignStat
        title={"Nombre d'emails ouverts"}
        stat={`${stat.nbOpening}`}
      />
    </Grid>
    <Grid item xs={4} md={2}>
      <CampaignStat
        title={"Taux d'ouverture"}
        stat={formatPourcent(stat.openingRate * 100)}
      />
    </Grid>
    <Grid item xs={4} md={2}>
      <CampaignStat title={"Nombre de clics"} stat={`${stat.nbClick}`} />
    </Grid>
    <Grid item xs={4} md={2}>
      <CampaignStat
        title={"Taux de clics"}
        stat={formatPourcent(stat.clickRate * 100)}
      />
    </Grid>
    <Grid item xs={4} md={2}>
      <CampaignStat
        title={"Nombre de désabonnements"}
        stat={`${stat.nbOptOut}`}
      />
    </Grid>
  </Grid>
);

const ContentStats: React.FC<{ stats: Stat[] }> = ({ stats }) => {
  const classes = useStyles();
  const stat = stats[0] ?? {
    channel: "HTML",
    clickRate: 0,
    id: "All",
    nationalClickRate: 0,
    nationalOpeningRate: 0,
    nbClick: 0,
    nbOpening: 0,
    nbOptOut: 0,
    nbSent: 0,
    openingRate: 0,
    optOutRate: 0,
  };
  return (
    <div className={classes.content}>
      <div className={classes.contentTitle}>
        <Text16pxBold>VOS STATISTIQUES</Text16pxBold>
      </div>
      <div className={classes.contentBody}>
        <CampaignStats stat={stat} />
        <div className={classes.explanations}>
          <Text12px>
            <span style={{ fontWeight: "bold" }}>
              Nombre d&apos;emails délivrés
            </span>{" "}
            : nombre total d&apos;emails délivrés et reçus par vos contacts OU
            nombre total d&apos;emails délivrés avec succès à vos contacts
          </Text12px>
          <Text12px>
            <span style={{ fontWeight: "bold" }}>
              Nombre d&apos;emails ouverts
            </span>{" "}
            : nombre d&apos;emails ouverts par rapport au nombre d&apos;emails
            délivrés
          </Text12px>
          <Text12px>
            <span style={{ fontWeight: "bold" }}>Taux d&apos;ouverture</span> :
            pourcentage du nombre d&apos;emails ouverts par rapport au nombre
            d&apos;emails délivrés (pour information, la moyenne en France est
            de 20%)
          </Text12px>
          <Text12px>
            <span style={{ fontWeight: "bold" }}>Nombre de clics</span> : nombre
            total de clics sur un des liens contenus dans les emails délivrés
            (exemples : site conseiller, page estimation, autre lien etc…)
          </Text12px>
          <Text12px>
            <span style={{ fontWeight: "bold" }}>Taux de clics</span> :
            pourcentage du nombre total de clics par rapport au nombre
            d&apos;emails délivrés (pour information, la moyenne en France est
            de 1 à 2%)
          </Text12px>
          <Text12px>
            <span style={{ fontWeight: "bold" }}>Nombre de désabonnements</span>{" "}
            : Nombre de personnes qui se sont désabonnées de l&apos;ensemble des
            campagnes via le lien de désabonnement
          </Text12px>
        </div>
      </div>
    </div>
  );
};

export const CampaignStat: React.FC<{ title: string; stat: string }> = ({
  title,
  stat,
}) => {
  const classes = useStyles();
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <div className={classes.statTitle}>
        <Text12px>{title}</Text12px>
      </div>
      <div className={classes.statValue}>
        <Text12pxBold>{stat}</Text12pxBold>
      </div>
    </div>
  );
};

const FooterStats: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.footer}>{children}</div>;
};
