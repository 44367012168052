import { Avatar, Card, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/PersonOutlined";
import React from "react";
import { useServices } from "services";
import { ITheme } from "theme/ts/main";
import { LastUpdate } from "UI/shared/atoms/LastUpdate";
import { SpacedLabelValue14Px } from "UI/shared/molecules/SpacedLabelValue";
import Agent from "UI/shared/organisms/Agent";

const useStyles = makeStyles((theme: ITheme) => ({
  avatar: {
    backgroundColor: theme.palette.default,
    marginRight: 15,
  },
  buyerRoot: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10px",
    },
  },
  card: {
    backgroundColor: theme.palette.greyLight,
    padding: 10,
  },
}));

export interface NetworkMatchingAcquirerProps {
  reference: string;
  businessUnit: string;
  agent: any;
  lastModificationDate?: string;
}

const NetworkMatchingAcquirer: React.FC<NetworkMatchingAcquirerProps> = ({
  reference,
  businessUnit,
  agent,
  lastModificationDate,
}) => {
  const classes = useStyles();
  const { authenticationService } = useServices();
  authenticationService.getUserData();

  return (
    <Card className={classes.card}>
      <Grid container alignItems="center">
        <Grid classes={{ root: classes.buyerRoot }} item xs={12} lg={6}>
          <Grid container alignItems="center">
            <Avatar aria-label="acquéreur" className={classes.avatar}>
              <PersonIcon style={{ fontSize: "24px" }} />
            </Avatar>
            <div>
              <SpacedLabelValue14Px
                label={"Référence"}
                value={reference}
                spaceSize={5}
              />
              {lastModificationDate && (
                <LastUpdate date={new Date(lastModificationDate)} />
              )}
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Agent
            lastname={agent.nom}
            firstname={agent.prenom}
            phoneNumber={agent.telephone}
            photo={agent.photo}
            isActive={agent.estActif}
            email={agent.email}
            businessUnit={businessUnit}
            source={"acq. réseaux"}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default NetworkMatchingAcquirer;
