import { useTheme } from "@material-ui/core";
import {
  AssignmentOutlined,
  DateRangeOutlined,
  ImportantDevicesOutlined,
} from "@material-ui/icons";
import { RefinementField } from "components/RefinementField";
import {
  AdaptedRefinements,
  Refinement,
} from "components/RefinementField/types";
import React from "react";
import { useServices } from "services";
import { ITheme } from "theme/ts/main";
import { MoreFiltersCategory, RequestForInformationsCriteria } from "types";
import { PaddingContainer } from "UI/shared/atoms/containers/PaddingContainer";
import { MoreFiltersFooter } from "UI/shared/atoms/MoreFiltersFooter";
import { WithToniqueColor } from "UI/shared/atoms/WithToniqueColor";
import { SwipeableContainer } from "UI/shared/molecules/SwipeableContainer/SwipeableContainer";
import { TitleWithIcon } from "UI/shared/molecules/TitleWithIcon";

export interface MoreFiltersProps {
  adaptedRefinements: AdaptedRefinements<RequestForInformationsCriteria>;
  searchResultCount: number;
  onChange: (value: {
    key: RequestForInformationsCriteria;
    value: Refinement;
  }) => void;
}

const getRefinementsSection = (
  key: MoreFiltersCategory,
  adaptedRefinements: AdaptedRefinements<RequestForInformationsCriteria>
) => {
  return (Object.keys(
    adaptedRefinements
  ) as RequestForInformationsCriteria[]).filter(
    (k) => adaptedRefinements[k].moreFiltersCategory === key
  );
};

const getL10nCriteriaKey = (key: RequestForInformationsCriteria) =>
  `RequestForInformations.Criteria.${key}`;

export const MoreFilters: React.FC<MoreFiltersProps> = ({
  searchResultCount,
  adaptedRefinements,
  onChange,
}) => {
  const {
    l10n: { t },
  } = useServices();

  const theme = useTheme() as ITheme;

  const Section = React.useCallback(
    (
      sectionKey: MoreFiltersCategory,
      adaptedRefinements: AdaptedRefinements<RequestForInformationsCriteria>
    ) => (
      <>
        {getRefinementsSection(sectionKey, adaptedRefinements).map(
          (key: RequestForInformationsCriteria) => {
            return (
              <div style={{ padding: 10 }} key={`more-filters-${key}`}>
                <RefinementField
                  onChange={(v) => onChange({ key, value: v })}
                  value={adaptedRefinements[key].refinement}
                  label={t(getL10nCriteriaKey(key))}
                />
              </div>
            );
          }
        )}
      </>
    ),
    [adaptedRefinements]
  );

  return (
    <SwipeableContainer
      anchor="right"
      width={400}
      headerTitle={"Recherche détaillée"}
      borderColor={theme.palette.tonique}
    >
      <div style={{ height: "100%" }}>
        <PaddingContainer bottom={10} top={10}>
          <TitleWithIcon
            icon={WithToniqueColor(ImportantDevicesOutlined)}
            title={"Affichage"}
          ></TitleWithIcon>
          {Section("display", adaptedRefinements)}
        </PaddingContainer>

        <PaddingContainer bottom={10} top={10}>
          <TitleWithIcon
            icon={WithToniqueColor(DateRangeOutlined)}
            title={"Dates"}
          ></TitleWithIcon>
          {Section("dates", adaptedRefinements)}
        </PaddingContainer>

        <PaddingContainer bottom={10} top={10}>
          <TitleWithIcon
            icon={WithToniqueColor(AssignmentOutlined)}
            title={"Caractéristiques"}
          ></TitleWithIcon>
          {Section("characteristics", adaptedRefinements)}
        </PaddingContainer>
      </div>

      <MoreFiltersFooter
        entityName={t(
          `RequestForInformations.EntityName.${
            searchResultCount <= 1 ? "singular" : "plural"
          }`
        )}
        total={searchResultCount}
        onClose={() => {}}
      />
    </SwipeableContainer>
  );
};
