import {
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import EditContactIcon from "@material-ui/icons/EditOutlined";
import EditProfileIcon from "@material-ui/icons/HowToRegOutlined";
import { TitleWithNumberBadge } from "UI/shared/atoms/Badges/TitleWithNumberBadge";
import BoutonImpression from "components/Buttons/BoutonImpression";
import VCardButton from "components/Buttons/BoutonVCard";
import CarteContact from "components/CarteContact";
import {
  DetailedEntityTabsLayout,
  Tab,
} from "components/Fiche/DetailedEntityTabsLayout";
import TitrePage from "components/TitrePage";
import { useTabs } from "hooks/useTabs";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import routesNames from "router/routesNames";
import { useServices } from "services";
import { ContactEventReceived } from "services/contactEventsTracking/dto/ContactEvent";
import { contactReadToWriteDto } from "services/portfolio/adapters";
import { toFullName } from "services/portfolio/helpers";
import {
  getMainEmailOrDefault,
  getMainPhoneOrDefault,
  ReadContactDto,
  toVCard,
} from "services/portfolio/types";
import { usePortfolioActions } from "services/portfolio/usePortfolioActions";
import { usePortfolioSearch } from "services/portfolio/usePortfolioSearch";
import { ProfilesContext } from "services/profiles";
import { ProfileItemDto } from "services/profiles/dto/ReadModelProfileDto";
import { ConsentStatus } from "types";
import { ActionsMenu } from "UI/shared/organisms/ActionsMenu";
import { ContactForm } from "../components/ContactForm";
import { ContactStatusButton } from "../components/ContactStatusButton/ContactStatusButton";
import { EditButton } from "./components/EditButton";
import { ContactEventsTracking } from "./components/Events/Events/ContactEventsTracking";
import Identite from "./components/Identite";
import Notes from "./components/Notes";
import { PrintableContactSheet } from "./components/PrintableContactSheet/PrintableContactSheet";
import { Form } from "./components/Profiles/Form";
import { ForSaleForm } from "./components/Profiles/ForSaleForm";
import { ProfilesList } from "./components/Profiles/ProfilesList";

const isSearchProfile = (profile: ProfileItemDto) =>
  profile.kind.substring(0, 6) === "Search";

interface IUpdateLocalContactContext {
  updateLocalContact: () => void;
}

const initUpdateLocalContactContext = {
  updateLocalContact: () => {
    return;
  },
};

export const UpdateLocalContactContext = createContext<
  IUpdateLocalContactContext
>(initUpdateLocalContactContext);

const UpdateLocalContactProvider: React.FC<{
  children?: React.ReactNode;
  updateLocalContact: () => void;
}> = ({ children, updateLocalContact }) => {
  return (
    <UpdateLocalContactContext.Provider value={{ updateLocalContact }}>
      {children}
    </UpdateLocalContactContext.Provider>
  );
};

type TabName = "identity" | "profils" | "events";

export const FicheContact: React.FC = () => {
  const { id: contactId, tab, display } = useParams<{
    id: string;
    tab: TabName;
    display: string;
  }>();

  const [events, setEvents] = useState<ContactEventReceived[]>([]);

  const {
    modal,
    l10n: { t },
    contactEvents: { getEvents },
  } = useServices();

  const loadEvents = useCallback(async () => {
    setEvents(await getEvents(contactId));
  }, [contactId, getEvents]);

  const { profiles, getAll } = useContext(ProfilesContext);
  useEffect(() => {
    if (contactId) {
      getAll(contactId)();
      loadEvents();
    }
  }, [contactId, getAll, loadEvents]);

  const {
    update,
    archive,
    activate,
    acceptConsent,
    denyConsent,
  } = usePortfolioActions();
  const { getContact } = usePortfolioSearch();
  const profilesCount = profiles.filter((bp) => bp.archivingDate === undefined)
    .length;
  const componentToPrintRef = useRef(null);
  const history = useHistory();
  const [contact, setContact] = useState<ReadContactDto | undefined>();
  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const archiveContact = (consentStatus: ConsentStatus) => {
    return archive(contactId, {
      consentStatus,
    }).then(async () => {
      await updateLocalContact();
      return getAll(contactId)();
    });
  };

  const onConsentStatusChange = (
    onChange: (contactId: string) => Promise<void>
  ) => async (contactId: string) => {
    await onChange(contactId);
    updateLocalContact();
  };

  const onAcceptConsent = onConsentStatusChange(acceptConsent);
  const onDenyConsent = onConsentStatusChange(denyConsent);

  const activateContact = () => {
    return activate(contactId).then(async () => {
      if (contact) setContact({ ...contact, status: "Active" });
    });
  };

  const updateLocalContact = () => {
    return getContact(contactId).then((_response) => {
      setContact(_response);
    });
  };

  const goToPreviousPage = () => {
    history.goBack();
  };

  const onPrint = () => {
    history.push(
      `${routesNames.app}${routesNames.contact}/${contactId}/identity/print`
    );
  };

  useEffect(() => {
    getContact(contactId).then((_) => setContact(_));
  }, [getContact, contactId]);

  useEffect(() => {
    if (
      display !== "print" ||
      !handlePrint ||
      !contact ||
      componentToPrintRef.current === null
    )
      return;
    handlePrint();
    history.replace(
      `${routesNames.app}${routesNames.contact}/${contactId}/${tab}/view`
    );
  }, [display, contact, profiles, handlePrint]);

  useEffect(() => {
    if (display === "searchProfile") {
      modal.show(<Form contactId={contactId} />);
    }
    if (display === "forSaleProfile") {
      modal.show(<ForSaleForm contactId={contactId} />);
    }
  }, [display]);

  const openEditContactModal = () =>
    modal.show(<ContactForm contact={contact} closeDialog={modal.close} />);

  const getTabsFromContact = (readContact?: ReadContactDto) => {
    if (!readContact) return [];

    return [
      {
        content: <Identite contact={readContact} />,
        titre: t("Identité").toUpperCase(),
        value: "identity",
      },
      {
        content: (
          <UpdateLocalContactProvider updateLocalContact={updateLocalContact}>
            <ProfilesList
              contactId={contactId}
              contactArchived={readContact.status === "Archived"}
            />
          </UpdateLocalContactProvider>
        ),
        titre: (
          <>
            <TitleWithNumberBadge number={profilesCount} title={t("PROFILS")} />
          </>
        ),
        value: "profils",
      },
      {
        content: (
          <ContactEventsTracking
            contactId={contactId}
            events={events}
            loadEvents={loadEvents}
          />
        ),
        titre: (
          <>
            <TitleWithNumberBadge number={events.length} title={t("SUIVI")} />
          </>
        ),
        value: "events",
      },
    ];
  };

  const tabs: Tab[] = getTabsFromContact(contact);

  const { onSelectedTabChanged, activeTabIndex } = useTabs<TabName>(
    tabs,
    tab,
    (tabValue) =>
      `${routesNames.app}${routesNames.contact}/${contactId}/${tabValue}/${display}`
  );

  if (!contact) {
    return <div className="loading">&nbsp;</div>;
  }

  const archiveOrReactiveLabel =
    contact.status === "Archived" ? t("Reactiver") : t("Archiver");

  const actionsResults = [
    <Tooltip key={0} title={"Modifier"} placement={"bottom"}>
      <IconButton onClick={openEditContactModal} aria-label="edit">
        <EditContactIcon />
      </IconButton>
    </Tooltip>,
    <VCardButton
      key={1}
      item={toVCard(contact)}
      nameId
      tooltipPlacement={"bottom"}
    />,
    <ActionsMenu menuOptions={[]} key={2}>
      <ContactStatusButton
        consentStatus={contact.consentStatus}
        contactStatus={contact.status}
        activate={activateContact}
        archive={archiveContact}
      >
        {!isMobile && (
          <span style={{ paddingTop: "2px" }}>{archiveOrReactiveLabel}</span>
        )}
      </ContactStatusButton>
      <BoutonImpression onPrint={onPrint}>
        {!isMobile && (
          <span style={{ paddingRight: "5px" }}>{t("Imprimer")}</span>
        )}
      </BoutonImpression>
    </ActionsMenu>,
  ];

  if (contact.status === "Archived") {
    actionsResults.splice(0, 1);
  }

  const actions = () => actionsResults;
  const actionsButton = [
    {
      // TOOD: a better way to do this is to lift up this logic here
      action: openEditContactModal,
      label: "identity",
      renderIcon: <EditContactIcon />,
    },
    {
      action: () => modal.show(<Form contactId={contactId} />),
      actionSeller: () => modal.show(<ForSaleForm contactId={contactId} />),
      label: "profile",
      renderIcon: <EditProfileIcon />,
    },
    {
      action: openEditContactModal,
      label: "identity",
      renderIcon: <EditContactIcon />,
    },
  ];

  const saveNotes = (notes: string) => {
    const contactToUpdate: ReadContactDto = { ...contact, notes: notes };
    update(contactToUpdate.id, contactReadToWriteDto(contactToUpdate));
    setContact(contactToUpdate);
  };

  return (
    <div>
      <PrintableContactSheet
        contact={contact}
        numberOfSearchProfiles={
          profiles.filter((profile) => isSearchProfile(profile)).length
        }
        componentToPrintRef={componentToPrintRef}
        titre={
          <TitrePage categorie="CRM | Contact" page={"Fiche"} nbResultats="" />
        }
      />
      <DetailedEntityTabsLayout
        composantsBarreGauche={[
          {
            composant: (
              <CarteContact
                contact={contact}
                handleDenyConsent={onDenyConsent}
                handleAcceptConsent={onAcceptConsent}
              />
            ),
          },
          {
            composant: <div style={{ marginBottom: 20 }} />,
          },
          {
            composant: (
              <Notes content={contact.notes || ""} saveContent={saveNotes} />
            ),
          },
        ]}
        titre={
          <TitrePage
            categorie="CRM | Contact"
            page={
              toFullName(contact.identity) ||
              getMainEmailOrDefault(contact.identity.emails) ||
              getMainPhoneOrDefault(contact.identity.phones)
            }
            nbResultats=""
            filePath={"/static/markdowns/ficheContact.md"}
          />
        }
        tabs={tabs}
        actions={actions()}
        tabActiveIndex={activeTabIndex}
        onSelectedTabChanged={onSelectedTabChanged}
        onClose={goToPreviousPage}
      />
      {contact.status !== "Archived" && (
        <EditButton
          tabActiveIndex={activeTabIndex}
          actionsButton={actionsButton as any}
        />
      )}
    </div>
  );
};
