import React from "react";
import { Map } from "react-leaflet";

import aggregateItems from "./aggregateItems";
import MarkerConseiller from "./markerConseiller";

export interface CreateMarkersProps {
  items: any;
  map: Map | null;
  handlePointClick: (item: any) => void;
}

export const createAgentsMarkers = ({
  items,
  map,
  handlePointClick,
}: CreateMarkersProps) => {
  if (!map) return [];
  const _zoom = map?.leafletElement?.getZoom();
  const aggregatedItems = aggregateItems(items);
  return aggregatedItems.map((item) => {
    return {
      component: (
        <MarkerConseiller
          key={item.id}
          item={item}
          handlePointClick={handlePointClick}
          zoom={_zoom ? _zoom : 6}
        />
      ),
      item,
      type: "normal",
    };
  });
};
